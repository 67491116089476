import React from 'react';
import config from '../../../../config';

class Card extends React.Component {
    state = {}
    componentWillMount() {
        this.setState({ player: this.props.player });
    }
    onError = () => {
        const { player } = this.state;
        player.Image = `/images/defaults/${player.IdGender === 2 ? 'female' : 'male'}.png`;
        this.setState({ player });
    }
    render() {
        const { player } = this.state;
        return (
            <figure className="player-card d-flex flex-column">
                <div className="bg-danger w-100">
                    <span className="white line1 anton text-center" style={{ fontSize: 42, whiteSpace: 'nowrap' }}>CHALLENGE SPORTS</span>
                </div>
                <div className="h-100 d-flex flex-row justify-content-center">
                    {/* Profile Picture */}
                    <div style={{ width: 140, position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 15, left: 10, width: 70, height: 110 }} className="bg-danger" />
                        <div style={{ position: 'absolute', top: 25, left: 20, width: 70, height: 110, background: `url("${player.Image}") no-repeat center center`, backgroundSize: 'cover' }} />
                        <img alt="" src={player.Image} onError={this.onError} style={{ width: 1, height: 1, opacity: 0 }} />
                    </div>
                    <div className="w-100 d-flex flex-column justify-content-center">
                        <span className="anton font-12 black idented-half">{player.NameFirst || player.Name} {player.NameLast}</span>
                        <div style={{ height: 40 }} className="align-self-center margin-top">
                            <img alt="" style={{ width: 100 }} src={`${config.apiEndpoint}/api/v4/common/barcode/${player.IdUser}`} />
                        </div>
                        <div className="d-flex flex-row justify-content-around align-self-center w-100 margin-top">
                            <span className="black font-6 text-left line1">MEMBER YEAR<br /><span className="toBold hind font-10">2019</span></span>
                            <span className="black font-6 text-left line1">DOB<br /><span className="toBold hind font-10">{player.DateBirth}</span></span>
                            <span className="black font-6 text-left line1">GENDER<br /><span className="toBold hind font-10">{player.Gender}</span></span>
                        </div>
                    </div>
                </div>
            </figure>)
    }
}

export default Card;
