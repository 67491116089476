import React from 'react';
import _ from 'lodash';
import DivisionListItem from './item';
import { Jumbotron } from 'reactstrap';

class DivisionsTable extends React.Component {

    state = {
        up: true,
        by: 'IdDivision'
    }

    // lifecycle

    //

    sort = newBy => {
        const { up, by } = this.state;
        if (by === newBy) {
            this.setState({
                up: !up
            });
        } else {
            this.setState({
                up: false,
                by: newBy
            });
        }
    }


    renderTable = () => {
        const { divisions, search } = this.props;
        let filtered_divisions = _.chain(divisions).filter(d => {
            if (search) return d.Name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            return true;
        }).value();
        return <table className="table table-sm">
            <thead>
                <tr className="black">
                    <th scope="col">Name</th>
                    <th scope="col" className="text-center">Gender</th>
                    <th scope="col" className="text-center">Cap</th>
                    <th scope="col" className="text-center">Date Range</th>
                    <th scope="col" className="text-center">Status</th>
                    <th className="text-right">Actions</th>
                </tr>
            </thead>
            <tbody>
                {divisions && _.chain(filtered_divisions).value().map((division, i) =>
                    <DivisionListItem {...this.props} division={division} index={_.findIndex(divisions, d => (d.IdDivision ? d.IdDivision === division.IdDivision : d.temporalId === division.temporalId))} key={(division.IdDivision || division.temporalId)} />)}                    
                {divisions && !filtered_divisions.length && <tr>
                    <td colSpan={6}><Jumbotron><h1>This season has no divisions created yet!</h1></Jumbotron>
                    </td></tr>}
            </tbody>
        </table>
    }

    render() {
        return this.renderTable();
    }
}

export default DivisionsTable;
