import React from 'react';

const Comp = (props) => {
  const { player } = props;
  return (<tr>
    <td className="text-center">
      {player.Number}
    </td>
    <td style={{ paddingLeft: '0.5em' }}>
      {player.Name}
    </td>
    <td className={`${player.Goals !== null ? 'black text-center' : "watermark"}`}>
      {player.Goals || player.watermark1}
    </td>
    <td className={`${player.Assists !== null ? 'black text-center' : "watermark"}`}>
      {player.Assists || player.watermark2}
    </td>
    <td className={`${player.PenaltyAbbr !== null ? 'black text-center' : "watermark"}`}>
      {player.PenaltyAbbr || player.watermark3}
    </td>
  </tr>)
}


export default Comp;