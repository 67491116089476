import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Coach from './coach';

const SignaturePad = require('react-signature-pad');

class Signature extends React.Component {

  state = {
    signed: false,
    signatureUrl: null,
    coach: null,
    team: null
  }

  componentWillMount() {
    this.setState({ team: this.props.team }, () => {
      const { team } = this.state;
      if (team.Coaches.length === 1) {
        team.Coaches[0].selected = true;
        this.setState({ team, coach: team.Coaches[0] });
      }
    });
  }

  signatureEnd = () => {
    const signature = this.refs.mySignature;
    this.setState({ signed: true, signatureUrl: signature.toDataURL() });
  }

  clearSignature = () => {
    const signature = this.refs.mySignature;
    signature.clear();
    this.setState({ signed: false, signatureUrl: null });
  }

  selectCoach = coach => {
    const { team } = this.state;
    team.Coaches.forEach(c => { c.selected = c.IdUser === coach.IdUser; });
    this.setState({ coach, team });
  };

  save = () => {
    const { doSign } = this.props, { coach, signatureUrl } = this.state;
    doSign && doSign(signatureUrl, coach);
  }

  render() {
    const { isOpen, toggle } = this.props, { coach, team } = this.state;
    return <Modal isOpen={isOpen} style={{ width: 450 }}>
      <ModalHeader toggle={toggle}>{team.TeamName}</ModalHeader>
      <ModalBody className="d-flex flex-row justify-content-center">
        {team.Coaches && team.Coaches.map((coach, i) => <Coach coach={coach} key={i}
          fnSelect={() => this.selectCoach(coach)} />)}
      </ModalBody>
      <ModalBody className="bg-info d-flex flex-row justify-content-center white">
        <i className={`fa fa-hand-point-${coach ? 'down' : 'up'} align-self-center margin-right-half`} />
        <span className="align-self-center">{coach ? 'Sign here' : 'Select who\'s signing'}</span>
      </ModalBody>
      {coach && <ModalBody>
        <SignaturePad onEnd={this.signatureEnd} ref="mySignature" className='w-100' />
      </ModalBody>}
      {coach && <ModalFooter>
        <button className="btn btn-link" onClick={this.clearSignature}>Clear Signature</button>
        {' '}
        {this.state.signed && <button className="btn btn-success" onClick={this.save}>Save{' '}<i className="fa fa-arrow-right m-l-7" /></button>}
      </ModalFooter>}

    </Modal>
  }
}

export default Signature;
