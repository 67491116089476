import React from 'react';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';
import CardApplied from './card.applied';
import { ItemTypes } from '../../../../../helpers';
import Masonry from 'react-masonry-component';

const target = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }

        const { season, seasonActions } = props;
        // Evaluate if the division already has a registration program. If it does, prompt for confirmation of replace.
        const { group, type, } = monitor.getItem();
        switch (type) {
            case ItemTypes.QUESTIONGROUP:
                // TODO: call api to add the questiongroup to the season
                seasonActions && seasonActions.assignQuestionGroup(season.IdSeason, group.IdQuestionLibraryGroup, component.state.activeTab);
                break;
            default: break;
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

const masonryOptions = {
    transitionDuration: 1
};

class LeftSide extends React.Component {

    state = { activeTab: 1 }

    redraw = () => {
        this.forceUpdate();
    }
    changeTab = (activeTab) => this.setState({ activeTab });

    render() {

        const { season, connectDropTarget, isOverCurrent, canDrop, appliedQuestions } = this.props, { activeTab } = this.state;
        const filteredByTypeQuestions = _.filter(appliedQuestions, aq => aq.IdQuestionGroupLocation === activeTab);
        
        return connectDropTarget(
            <div className="bg-white">

                {/* TITLE */}
                <div className="d-flex flex-row justify-content-center" style={{ backgroundColor: '#c8c8c8', height: 48 }}>
                    <h3 className="align-self-center black">Season Questions</h3>
                </div>

                {/* TABS */}
                <ul className="nav nav-tabs font-16">
                    <li className="nav-item">
                        <button className={`d-flex flex-row justify-content-center nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
                            <i className="far fa-question-circle blue align-self-center"></i>
                            {true ? <span className="half-idented font-10 align-self-center">Player / Team</span> : ''}</button>
                    </li>
                    <li className="nav-item">
                        <button className={`d-flex flex-row justify-content-center nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}>
                            <i className="far fa-question-circle orange align-self-center"></i>
                            {true ? <span className="half-idented font-10 align-self-center">Coach / Admin</span> : ''}</button>
                    </li>
                    <li className="nav-item">
                        <button className={`d-flex flex-row justify-content-center nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}>
                            <i className="far fa-question-circle green align-self-center"></i>
                            {true ? <span className="half-idented font-10 align-self-center">Scouts</span> : ''}</button>
                    </li>
                </ul>

                <ul className="p-4 w-100 no-margin no-padding" style={{ top: '5.5em' }}>

                    <li className={`${canDrop ? '' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>

                        <div className={`droppable ${canDrop ? 'action mb-2' : 'm-0 p-0'} ${isOverCurrent ? 'toBold' : 'toNormal'} d-flex flex-row`}>
                            {canDrop && <span className="line1 d-flex flex-row"><i className="mr-2 align-self-center fas fa-arrow-left red" />
                                <span className="align-self-center">Drop here to use this question group on the season</span></span>}
                        </div>

                        <Masonry
                            key="masonry"
                            elementType={'ul'}
                            options={masonryOptions}
                            disableImagesLoaded={false}
                            updateOnEachImageLoad={false}
                        >
                            {/* Questions */}
                            {filteredByTypeQuestions && filteredByTypeQuestions.reverse().map((group, i) =>
                                <CardApplied group={group} key={i} type={ItemTypes.ROSTEREDPLAYER} mode={activeTab} redraw={this.redraw} 
                                fnAction={this.props.archiveGroup}
                                fnEdit={this.props.selectGroup}>{JSON.stringify(group)}</CardApplied>)}

                            {/* No questions */}
                            {(filteredByTypeQuestions && !filteredByTypeQuestions.length) && (
                                <div className="jumbotron text-center ">
                                    <i className="fas fa-exclamation-circle red font-60 mb-2" />
                                    <h2 className="line1">There are no Question Groups assigned to the season</h2>
                                    <p className="line1">Drag and Drop groups from the right section here</p>
                                </div>
                            )}
                        </Masonry>
                    </li>
                </ul>
            </div>
        )
    }
}

export default DropTarget([ItemTypes.QUESTIONGROUP], target, collect)(LeftSide);
