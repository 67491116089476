function reducer(state = [], action) {
    switch (action.type) {
        case 'CONTEST_TYPE/SET':
            return action.contest_type;
        case 'SEASON/CLEAR_POINT_SYSTEM':
            return null;
        default:
            return state;
    }
}

export default reducer;
