import React from 'react';
import { Animated } from 'react-animated-css';
import moment from 'moment';
import _, { filter } from 'lodash';
import fakeAuth from '../../../../fakeAuth';
import Attributes from './Attributes';
import Note from './Note';
import Evaluation from './Evaluation';
import Review from './Review';
import AddAttributes from './adds/Attributes';
import AddNote from './adds/Notes';
import ShareMatch from './ShareMatch';
import AddReview from './adds/Review';
import AddEvaluation from './adds/Evaluation';


class PlayerEvaluations extends React.Component {
    state = {
        IdTeam: this.props.params.idTeam
    }

    toggle_menu = (index) => {
        const { team_evaluations } = this.props;
        _.find(team_evaluations, t => t.IdMatch === index).menu = !_.find(team_evaluations, t => t.IdMatch === index).menu;
        this.forceUpdate();
    }
    onDelete = (IdMatch) => {
        const { idTeam } = this.props.params;
        this.props.teamActions && this.props.teamActions.delete_match(IdMatch, idTeam);
    }
    onToggleVisibility = (index) => {
        const { team_evaluations } = this.props;
        _.find(team_evaluations, t => t.IdMatch === index).VISIBLE = !_.find(team_evaluations, t => t.IdMatch === index).VISIBLE;
        this.forceUpdate();
        // Call API
        this.props.teamActions && this.props.teamActions.save_match({ ..._.find(team_evaluations, t => t.IdMatch === index), menu: false });
    }

    render() {

        const { player, params, team, season, custom_attributes_templates } = this.props, { idTeam } = params, { team_evaluations } = this.props;
        let filtered_notes = _.chain(team_evaluations)
            .filter(e => (e.IdUserTarget || e.IdAssignedUser) === player.IdUser)
            .filter(note => {
                if ((!team.IsTeamAdmin && !team.IsTeamOwner)) return note.VISIBLE;
                return true;
            })
            //.filter(e => e.IdTeam === (team.Id || team.IdTeam))
            /*.filter(e => {
                if (season.IsTeamDivisionAdmin) return true;
                else return e.VISIBLE;
            })*/
            .value();        

        return <section className="w3-container-fluid">
            <div style={{ height: 50 }} />
            <div className="w3-content mt-4">
                <h2 className="black">EVALUATIONS</h2>
            </div>
            {player && <div className="w3-content mt-2 mx-auto card shadow d-flex flex-row px-3 py-2">
                <div style={{ width: 80 }} className="d-flex flex-row">
                    <div className="align-self-center cover" style={{ width: 60, height: 60, borderRadius: 30, border: '1px solid black', background: `url(${player.ImageUrl || player.Image}) no-repeat center center` }} />
                </div>
                <div className="d-flex flex-column align-self-center">
                    <span className="montserrat black font-18 line">{`${player.NameFirst} ${player.NameLast}`}</span>
                    <span className="montserrat black font-10 line">{`Playing at ${team.TeamName}`}</span>
                </div>
            </div>}
            {team_evaluations && <div className="w3-content">

                {/* YES */}
                {filtered_notes && filtered_notes.length > 0 && <div className="h-100 row mt-2">
                    {_.chain(filtered_notes)
                        .sortBy('DateMatch')
                        .value().map((evaluation, index) => {

                            // Notes
                            if (evaluation.ReportType === 10) {
                                return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 py-0 px-1 m-0 mt-2">
                                    <div style={{ height: 100 }} className="pl-4 py-3 tile card d-flex flex-row pointer">
                                        <div onClick={() => this.setState({ note: evaluation })} className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                            <i className="align-self-center icon-bookmark1 font-30" style={{ color: `${evaluation.Color || 'FF0000'}` }} />
                                        </div>
                                        <div onClick={() => this.setState({ note: evaluation })} className="w-100 d-flex flex-column black ml-3 align-self-center">
                                            <span className="line1 font-18">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                            <span className="max-lines-1 line1 mt-1">{evaluation.Note.length > 50 ? `${evaluation.Note.substring(0, 50)}...` : evaluation.Note}</span>
                                            <span className="black font-8 mt-1">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.DateMatch).format('MMM Do YYYY')}</span>
                                        </div>

                                        {/* MENU */}
                                        {season.IsTeamDivisionAdmin === 1 && <div className="px-2 ml-auto pointer" style={{ width: 50 }} onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="ml-auto black icon-more-vertical" /></div>}

                                        <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 8, right: 8 }}>

                                            <div className="d-flex flex-column">
                                                <a target="_blank" title="PDF Version" href={`https://matchreports.thesqwad.com/scout/note/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                                                <button onClick={() => this.onToggleVisibility(evaluation.IdMatch)} className="btn btn-default"><i className={`icon-eye${evaluation.VISIBLE ? '' : '-off'} black`} /> Visible to Athlete</button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <button onClick={() => this.setState({ share_match: evaluation }, () => {
                                                    this.toggle_menu(evaluation.IdMatch);
                                                })} className="btn btn-default"><i className="icon-share black" /> Share Note</button>
                                                <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /> Delete Note</button>
                                            </div>

                                            <button className="btn btn-default align-self-start" onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="black icon-more-vertical" /></button>

                                        </Animated>
                                    </div>
                                </Animated>
                            }
                            //

                            // Attributes
                            if (evaluation.ReportType === 11) {
                                return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 py-0 px-1 m-0 mt-2">
                                    <div style={{ height: 100 }} className="pl-4 py-3 tile card d-flex flex-row pointer">
                                        <div onClick={() => this.setState({ attributes: evaluation })} className=" align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                            <i className="black align-self-center icon-recreational font-30" />
                                        </div>
                                        <div onClick={() => this.setState({ attributes: evaluation })} className="w-100 d-flex flex-column  black px-3 align-self-center">
                                            <span className="line1 font-16">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                            <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.DateMatch).format('MMM Do YYYY')}</span>
                                            <span className="line1 mt-1 font-16 syncopate">Avg. {(evaluation.average || 0).toFixed(1)}</span>
                                        </div>
                                        {season.IsTeamDivisionAdmin === 1 && <div className="px-2 ml-auto pointer" style={{ width: 50 }} onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="ml-auto black icon-more-vertical" /></div>}
                                        <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 8, right: 8 }}>

                                            <div className="d-flex flex-column">
                                                <a target="_blank" title="PDF Version" href={`https://matchreports.thesqwad.com/scout/attributes/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                                                <button onClick={() => this.onToggleVisibility(evaluation.IdMatch)} className="btn btn-default"><i className={`icon-eye${evaluation.VISIBLE ? '' : '-off'} black`} /> Visible to Athlete</button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <button onClick={() => this.setState({ share_match: evaluation }, () => {
                                                    this.toggle_menu(evaluation.IdMatch);
                                                })} className="btn btn-default"><i className="icon-share black" /> Share Eval</button>
                                                <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /> Delete Eval</button>
                                            </div>

                                            <button className="btn btn-default align-self-start" onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="black icon-more-vertical" /></button>

                                        </Animated>
                                    </div>
                                </Animated>
                            }
                            //

                            // Review
                            if (evaluation.ReportType === 12) {
                                return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 py-0 px-1 m-0 mt-2">
                                    <div style={{ height: 100 }} className="pl-4 py-3 tile card d-flex flex-row pointer" >
                                        <div onClick={() => this.setState({ review: evaluation })} className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                            <i className="black align-self-center icon-bar-chart font-30" />
                                        </div>
                                        <div onClick={() => this.setState({ review: evaluation })} className="w-100 d-flex flex-column black ml-3 align-self-center ">
                                            <span className="line1 toBold">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                            <span className="line1 ">{evaluation.NextSteps || ''}</span>
                                            <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.DateMatch).format('MMM Do YYYY')}</span>
                                        </div>
                                        {season.IsTeamDivisionAdmin === 1 && <div className="px-2 ml-auto pointer" style={{ width: 50 }} onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="ml-auto black icon-more-vertical" /></div>}
                                        <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 8, right: 8 }}>

                                            <div className="d-flex flex-column">
                                                <a target="_blank" title="PDF Version" href={`https://matchreports.thesqwad.com/scout/pr/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                                                <button onClick={() => this.onToggleVisibility(evaluation.IdMatch)} className="btn btn-default"><i className={`icon-eye${evaluation.VISIBLE ? '' : '-off'} black`} /> Visible to Athlete</button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <button onClick={() => this.setState({ share_match: evaluation }, () => {
                                                    this.toggle_menu(evaluation.IdMatch);
                                                })} className="btn btn-default"><i className="icon-share black" /> Share Review</button>
                                                <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /> Delete Review</button>
                                            </div>

                                            <button className="btn btn-default align-self-start" onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="black icon-more-vertical" /></button>
                                        </Animated>
                                    </div>
                                </Animated>
                            }
                            //

                            // Evaluation
                            if (evaluation.ReportType === 1) {
                                return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 py-0 px-1 m-0 mt-2">
                                    <div style={{ height: 100 }} className="pl-4 py-3 tile card  d-flex flex-row pointer">
                                        <div onClick={() => this.setState({ profile: evaluation })} className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                            <i className="black align-self-center icon-git-pull-request font-30" />
                                        </div>
                                        <div onClick={() => this.setState({ profile: evaluation })} className="w-100 d-flex flex-column black ml-3 align-self-center">
                                            <span className="line1 toBold">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                            <span className="line1 ">{evaluation.Position}</span>
                                            <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.DateMatch).format('MMM Do YYYY')}</span>
                                        </div>

                                        {/* 3 Dots */}
                                        {season.IsTeamDivisionAdmin === 1 && <div className="px-2 ml-auto pointer" style={{ width: 50 }} onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="ml-auto black icon-more-vertical" /></div>}

                                        {/* Menu */}
                                        <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut' isVisible={evaluation.menu ? true : false}
                                            className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 8, right: 8 }}>

                                            <div className="d-flex flex-column">
                                                <a target="_blank" title="PDF Version" href={`https://matchreports.thesqwad.com/scout/profile/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                                                <button onClick={() => this.onToggleVisibility(evaluation.IdMatch)} className="btn btn-default"><i className={`icon-eye${evaluation.VISIBLE ? '' : '-off'} black`} /> Visible to Athlete</button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <button onClick={() => this.setState({ share_match: evaluation }, () => {
                                                    this.toggle_menu(evaluation.IdMatch);
                                                })} className="btn btn-default"><i className="icon-share black" /> Share Profile</button>
                                                <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /> Delete Profile</button>
                                            </div>

                                            <button className="btn btn-default align-self-start" onClick={() => this.toggle_menu(evaluation.IdMatch)}><i className="black icon-more-vertical" /></button>
                                        </Animated>
                                    </div>
                                </Animated>
                            }
                            //

                        })}
                    <div style={{ height: 200 }} className="col-12 " />
                </div>}

                {/* NO */}
                {filtered_notes && !filtered_notes.length && <div className="d-flex w-100 flex-column justify-content-center">
                    <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" style={{ maxWidth: 200 }} />
                    <span className=" font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no attribute evaluations in this area{this.state.search_text ? ` for '${this.state.search_text}'` : ''}</span>
                </div>}

                {/* ADD */}
                {season.IsTeamDivisionAdmin === 1 && <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 70, height: 70, borderRadius: '50%', border: `1px solid black`, position: 'fixed', bottom: 30, right: 20 }} className="btn-info d-flex flex-row justify-content-center" >
                    <i className="font-30 fas fa-plus white align-self-center" />
                </div>}

                {/* 1st MENU */}
                <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.menu_toggled ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, right: 20 }}>

                    <button className="btn btn-default" onClick={() => this.setState({ adding_evaluation: true, menu_toggled: false, mode: 1 })}>
                        <div className="d-flex flex-column">
                            <i className="icon-git-pull-request black font-30" />
                            <span>Eval</span>
                        </div>
                    </button>
                    <button className="btn btn-default" onClick={() => this.setState({ adding_note: true, menu_toggled: false, mode: 1 })}>
                        <div className="d-flex flex-column">
                            <i className="icon-file-plus black font-30" />
                            <span>Note</span>
                        </div>
                    </button>

                    <button className="btn btn-default" onClick={() => this.setState({ adding_attributes: true, menu_toggled: false, mode: 1 })}>
                        <div className="d-flex flex-column">
                            <i className="icon-recreational black font-30" />
                            <span>Attr.</span>
                        </div>
                    </button>
                    <button className="btn btn-default" onClick={() => this.setState({ adding_review: true, menu_toggled: false, mode: 1 })}>
                        <div className="d-flex flex-column">
                            <i className="icon-bar-chart black font-30" />
                            <span>Review</span>
                        </div>
                    </button>
                    <div className="align-self-center mb-2 mt-2" onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden' }}>
                        <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                            <i className="fas fa-times white font-30 align-self-center" />
                        </div>
                    </div>
                </Animated>
            </div>}

            {/* MODALS */}
            <Attributes {...this.state} topics={this.props.topics} customAttributesTemplates={this.props.customAttributesTemplates} scout_actions={this.props.teamActions} toggle={() => this.setState({ attributes: false })} />
            <Note {...this.state} scout_actions={this.props.teamActions} toggle={() => this.setState({ note: false })} />
            <Evaluation {...this.state} scout_actions={this.props.teamActions} toggle={() => this.setState({ profile: false })} />
            <Review {...this.state} scout_actions={this.props.teamActions} toggle={() => this.setState({ review: false })} />

            {/* ADDS */}
            <AddAttributes {...this.state} topics={this.props.topics}
                collaborators={this.props.collaborators}
                network={this.props.network}
                customAttributesTemplates={this.props.customAttributesTemplates}
                selected_user={player} scout_actions={this.props.teamActions}
                toggle={() => this.setState({ adding_attributes: false })}
                onShare={match => this.setState({ share_match: match })} />
            <AddNote {...this.state} selected_user={player} scout_actions={this.props.teamActions} toggle={() => this.setState({ adding_note: false })} />
            <AddReview {...this.state} selected_user={player} scout_actions={this.props.teamActions} toggle={() => this.setState({ adding_review: false })} />
            <AddEvaluation {...this.state} questionaries={this.props.questionaries} selected_user={player} scout_actions={this.props.teamActions} toggle={() => this.setState({ adding_evaluation: false })} />

            {/* SHARE */}
            <ShareMatch {...this.state} scout_actions={this.props.teamActions} toggle={() => this.setState({ share_match: false })} />



        </section>
    }
}

export default PlayerEvaluations;