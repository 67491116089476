import React from 'react';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import { formatPrice } from '../../../../helpers';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


class Comp extends React.Component {
  state = {
    bootstrapped: false
  }

  // Lifecycle
  componentWillMount() {
    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    this.props.reportsActions && this.props.reportsActions.fetchTransactions(params.idSeason);

    setTitle && setTitle({
      main: 'Report | Revenue'
    });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;

      setTitle && setTitle({
        sub: 'Report | Revenue',
        main: nextProps.season.Name || null
      });
    }
    if (nextProps.report) {
      this.setState({
        bootstrapped: true
      });
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //


  render() {
    const { bootstrapped } = this.state,
      { report, season } = this.props;    

    return <section>
      <div className="filters-wrapper d-flex flex-row">
        <div className="ml-auto p-2">
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Revenue ${season.Name}`}
            sheet="AutoPay" buttonText="Export as XLS" />
        </div>
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            <table id="table-to-xls" className="table table-sm font-10">
              <thead>
                <tr className="toBold">
                  <td>User Id</td>
                  <td>User Name</td>
                  <td align="center">Date</td>
                  <td align="center">Payment Method</td>

                  <td align="right">Amount</td>
                  <td>Transaction</td>
                  <td>Payee Name</td>
                  <td>Payee Email</td>
                  <td>Team Name</td>
                  <td>Division</td>
                  <td>Season Name</td>
                </tr>
              </thead>
              <tbody>
                {report && report.map((record, i) => (
                  <tr key={i}>
                    <td>
                      {record.IdUser}
                    </td>
                    <td>
                      {record.UserName}
                    </td>

                    <td align="center">
                      {moment(record.PaymentDate).format('MM/DD/YYYY')}
                    </td>
                    <td align="center">
                      {record.PaymentMethod}
                    </td>
                    <td align="right">
                      {formatPrice(record.Amount * 100)}
                    </td>
                    <td className="font-8">
                      {record.TransactionRef}
                    </td>

                    <td>{record.Payer}</td>
                    <td>{record.EmailPayer || record.PayerEmail}</td>
                    <td>{record.TeamName}</td>
                    <td>{record.DivisionName}</td>
                    <td>{record.SeasonName}</td>

                  </tr>)
                )}
              </tbody>
              <tfoot>
                <tr className="bg-info font-12 white">
                  <td></td>
                  <td></td>
                  <td align="right">Total:</td>
                  <td align="right">
                    {formatPrice(_.reduce(_.map(report, (i) => {
                      return i.Amount;
                    }), function (sum, n) {
                      return sum + n;
                    }, 0) * 100)}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
              </tfoot>
            </table>
          </section>)}
      </div>
    </section>
  }
}

export default Comp;
