import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, generateRandomId } from '../../../../../helpers';
import QandA from './created_questions/item';

const source = {
  beginDrag(props) {
    return props;
  }
}

class QuestionCard extends React.Component {
  state = {
    expanded: false
  }
  expand = () => {
    this.setState({ expanded: !this.state.expanded }, () => {
      // If I don't force the update of the Masonry parent, it all get screwd+
      this.props.redraw && this.props.redraw();
    });
  }
  renderThing = () => {
    const { group, unmovable, isDragging, fnEdit } = this.props, { expanded } = this.state;
    return <li style={{ marginRight: unmovable ? 0 : '1em', borderRadius: 5 }} className={`question-group-card w-100 bg-gray p-3 d-flex flex-column ${isDragging ? 'dragging' : ''}`}>
      <span className="font-12 toUpper sqwad-font line1 mb-2 text-left toBold">{group.name}</span>
      {group.qandas.length > 0 && group.qandas.map((q, i) =>
        <QandA hideAnswers={!expanded} expandable={true} className="margin-bottom-half font-8" question={q} key={generateRandomId(10)} selectable={false} draggable={false} type={ItemTypes.QANDA} deletable={false} />
      )}
      {!unmovable && <span style={{ padding: '0 0.5em', border: '1px solid #e5e5e5' }} className="btn btn-success btn-sm movable-hint font-8" onClick={() => fnEdit(group)}><i className="fas fa-edit" />{' '}Edit</span>}
      <span style={{ padding: '0 0.5em', border: '1px solid #e5e5e5' }} className="btn btn-link btn-sm right-hint font-8" onClick={this.expand}><i className={`fas fa-arrow-${expanded ? 'up' : 'down'}`} />{' '}{expanded ? 'Fold' : 'Expand'}</span>
    </li>
  }
  render() {
    const { connectDragPreview, connectDragSource, unmovable } = this.props;
    if (unmovable) return this.renderThing();
    else return connectDragPreview(connectDragSource(this.renderThing()));
  }
}

export default flow(
  DragSource(ItemTypes.QUESTIONGROUP, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(QuestionCard);
