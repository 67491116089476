import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, generateRandomId } from '../../../../../helpers';
import QandA from './created_questions/item';

const source = {
  beginDrag(props) {
    return props;
  }
}

class CardApplied extends React.Component {
  state = {
    expanded: false
  }
  expand = () => {
    this.setState({ expanded: !this.state.expanded }, () => {
      // If I don't force the update of the Masonry parent, it all get screwd+
      this.props.redraw && this.props.redraw();
    });
  }
  renderThing = () => {    
    
    const { group, unmovable, isDragging, fnEdit, mode, fnAction } = this.props, { expanded } = this.state;

    let cn = 'info';
    switch(mode){
      case 2: cn = 'orange'; break;
      case 3: cn = 'success'; break;
      default: break;
    }

    return <li style={{ marginRight: unmovable ? 0 : '1em', borderRadius: 5 }} mode={JSON.stringify(mode)} className={`w-100 question-group-card bg-${cn} p-3 mb-3 d-flex flex-column ${isDragging ? 'dragging' : ''}`}>
      <span className="font-12 line1 toUpper sqwad-font text-left toBold mb-2">{group.name}</span>
      {group.qandas.length > 0 && group.qandas.map((q, i) =>
        <QandA hideAnswers={!expanded} expandable={true} className="margin-bottom-half font-8" question={q} key={generateRandomId(10)} selectable={false} draggable={false} type={ItemTypes.QANDA} deletable={false} />
      )}
      {!unmovable && fnAction && <span style={{ padding: '0 0.5em', border: '1px solid #e5e5e5' }} className="btn btn-danger btn-sm movable-hint font-8" onClick={() => fnAction(group)}><i className="fas fa-times" />{' '}Archive</span>}
      <span style={{ padding: '0 0.5em', border: '1px solid #e5e5e5' }} className="btn btn-link btn-sm right-hint font-8" onClick={this.expand}><i className={`fas fa-arrow-${expanded ? 'up' : 'down'}`} />{' '}{expanded ? 'Fold' : 'Expand'}</span>
    </li>
  }
  render() {
    const { connectDragPreview, connectDragSource, unmovable } = this.props;
    if (unmovable) return this.renderThing();
    else return connectDragPreview(connectDragSource(this.renderThing()));
  }
}

export default flow(
  DragSource(ItemTypes.APPLIEDQUESTION, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(CardApplied);
