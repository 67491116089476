import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from '../../../../../../helpers';
import _ from 'lodash';
import QuestionIcon from '../icon';

const target = {
    canDrop(props) {
        // TODO: evaluate props to see if it can be dropped
        return true;
    },
    drop(props, monitor, component) {
        // Dropped in another a child component that also accepts this type of items
        if (monitor.didDrop()) return;
        const { division, divisionsActions, params } = props, { group, type, mode } = monitor.getItem();
        // Evaluate the type of item that's being dropped
        switch (type) {
            case ItemTypes.APPLIEDQUESTION:
                divisionsActions && divisionsActions.assignQuestionGroup &&
                    divisionsActions.assignQuestionGroup(division.IdDivision,
                        group.IdQuestionGroup, mode, division.IdSeason || params.idSeason);
                break;
            default:
                break;
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class DivisionListItem extends React.Component {

    static propTypes = {
        appliedQuestions: PropTypes.array,
    }

    static defaultProps = {
        appliedQuestions: [],
    }

    state = {
        collapsed: true,
        playerQuestions: [],
        coachQuestions: []
    }

    componentWillMount() {
        this.setup(this.props);
    }
    componentWillReceiveProps = (nextProps) => {
        this.setup(nextProps);
    }

    setup = (props) => {
        const { appliedQuestions, division, } = props;
        this.setState({
            playerQuestions: _.filter(appliedQuestions, program => {
                return _.find(division.QuestionGroups, qg => {
                    return (qg.IdQuestionGroup === program.IdQuestionGroup && qg.IdQuestionGroupLocation === 1);
                });
            }),
            coachQuestions: _.filter(appliedQuestions, program => {
                return _.find((division.QuestionGroups), qg => {
                    return (qg.IdQuestionGroup === program.IdQuestionGroup && qg.IdQuestionGroupLocation === 2);
                });
            }),
        });
    }

    render() {
        const { connectDropTarget, isOverCurrent, canDrop, division } = this.props,
            { collapsed, playerQuestions, coachQuestions, } = this.state;
        return connectDropTarget(
            <li style={{ borderTop: '1px solid #e5e5e5' }} className={`${collapsed ? 'off' : 'on'} mr-3 division p-1 block font-10 droppable ${canDrop ? 'action' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>
                {/*<li style={{ borderBottom: '1px solid #e5e5e5' }} className={`${collapsed ? 'off' : 'on'} division p-1 block font-10 ${canDrop ? '' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>*/}
                <span className="idented-half margin-right-half align-self-center">{division.Name}</span>
                {playerQuestions && playerQuestions.map((program, i) => <QuestionIcon activeTab={1} color="blue" key={i} index={i} program={program} {...this.props} division={division} />)}
                {coachQuestions && coachQuestions.map((program, i) => <QuestionIcon activeTab={2} color="orange" key={i} index={i} program={program} {...this.props} division={division} />)}
            </li>)
    }
}

export default DropTarget([ItemTypes.APPLIEDQUESTION], target, collect)(DivisionListItem);
