import React from 'react';
import { Animated } from 'react-animated-css';
import VideoPlayer from './partials/video';
import { TwitterPicker } from 'react-color';
import Season from './partials/season';
import PremiumModal from '../modals/premium';

import _ from 'lodash';

class Brochure extends React.Component {
    state = {
        boostrapped: false,
        roster: [],
        index: 0,
        bg1: '#000000',
        bg2: '#880E1A',
        editing: false,
        selectedTeam: null,
        atp: null,
        colors: [
            '#000000',
            '#FFFFFF',
            '#FF0000',
            '#FF4000',
            '#FF8000',
            '#FFBF00',
            '#FFFF00',
            '#BFFF00',
            '#80FF00',
            //'#40FF00',
            //'#00FF00',
            '#00FF40',
            '#00FF80',
            '#00FFBF',
            '#00FFFF',
            '#00BFFF',
            '#0080FF',
            '#0040FF',
            '#0000FF',
            '#4000FF',
            '#8000FF',
            '#BF00FF',
            '#FF00FF',
            '#FF00BF',
            '#FF0080',
            '#FF0040',
            '#880E1A',
        ]
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, teamActions, params, familyActions } = this.props;        

        setTitle && setTitle({
            main: 'Team Brochure',
            //sub: (selectedTeam && selectedTeam.Name) ? selectedTeam.Name : `${family_lastname}'s`
        });

        teamActions && teamActions.fetchTeamSeasons && teamActions.fetchTeamSeasons(params.idTeam);
        teamActions.fetchVideos && teamActions.fetchVideos(params.idTeam);
        familyActions.getFamilySubscriptions && familyActions.getFamilySubscriptions();
        familyActions.getTeamSubscriptions && familyActions.getTeamSubscriptions(params.idTeam);
        this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(1);
        teamActions.fetchTeam(params.idTeam);
    }    
    componentWillReceiveProps = nextProps => {
        const { roster_actions, colors, setTitle, family_lastname, atp } = nextProps;

        if (nextProps.seasons && nextProps.selectedTeam && nextProps.seasons.length && !this.state.seasons) {            
            this.setState({
                seasons: nextProps.seasons,
                selectedTeam: nextProps.selectedTeam
            }, () => {
                const { selectedTeam } = this.state;
                setTitle && setTitle({
                    main: 'Team Brochure',
                    sub: (selectedTeam && selectedTeam.Name) ? selectedTeam.Name : `${family_lastname}'s`
                });

                if (nextProps.seasons.length) {
                    roster_actions && roster_actions.fetchPublic(nextProps.seasons[this.state.index].IdTeamDivision);
                }
            });
        }

        if (atp && !this.state.boostrapped) {
            this.setState({
                boostrapped: true,
                atp,
                bg1: (_.find(colors || [], c => c.position === 1) || { ColorCode: '#000000' }).ColorCode,
                bg2: (_.find(colors || [], c => c.position === 2) || { ColorCode: '#880E1A' }).ColorCode,
            });
        }
    }
    componentWillUnmount = () => {
        this.props.teamActions.clearSeasons();
        this.props.roster_actions.clearRoster();
        this.props.roster_actions.clearColors();
    }
    //

    show = () => this.setState({ boostrapped: true });
    selectUser = (selectedUser) => this.setState({ selectedUser, video_player: true });
    unselect = () => this.setState({ video_player: false }, () => {
        setTimeout(() => { this.setState({ selectedUser: null }) }, 1000)
    });

    handleChangeBg1 = (color) => {
        this.setState({ bg1: color.hex });
    };
    handleChangeBg2 = (color) => {
        this.setState({ bg2: color.hex });
    };

    onToggleEdit = () => this.setState({ editing: !this.state.editing }, () => {
        const { roster_actions } = this.props,
            { bg1, bg2, selectedTeam } = this.state;
        roster_actions.updateBrochureColors({
            idTeam: selectedTeam.IdTeam,
            color1: bg1,
            color2: bg2
        });
    });    

    onEnroll = (plan) => {
        const { selectedTeam } = this.state;
        selectedTeam.subscription = plan;
        this.setState({ boostrapped: true, selectedTeam });
    }

    render() {

        const { boostrapped, selectedUser, video_player, seasons, selectedTeam, atp } = this.state;

        return (

            <section className="pt-4">
                {boostrapped && selectedTeam && seasons && <section>
                    {seasons.map((season, i) => <section key={i} className="mb-4">
                        <Season key={i} season={season} index={i} {...this.props} {...this.state} />
                    </section>)}
                </section>}

                <div style={{ position: 'fixed', bottom: 20, right: 30 }} className="d-flex flex-column">
                    {this.state.editing && <section className="d-flex flex-column">
                        <i className="black font-14 pb-2">Banner Color</i>
                        <TwitterPicker
                            colors={this.state.colors}
                            color={this.state.bg1}
                            onChangeComplete={this.handleChangeBg1} />
                    </section>}

                    {selectedTeam && selectedTeam.subscription && <button onClick={this.onToggleEdit} className={`mt-2 btn btn-${this.state.editing ? 'success' : 'info'}`}><i className="fas fa-edit" /> {this.state.editing ? 'Save Changes' : 'Toggle Edit Mode'}</button>}
                    {selectedTeam && !selectedTeam.subscription && <button onClick={() => this.setState({ premium: true })} className={`mt-2 btn btn-danger`}>UNLOCK<br />BROCHURE</button>}
                </div>

                <Animated animateOnMount={false} animationOut="fadeOutRight" className={`bg-black d-flex flex-row justify-content-center w-100`} style={{ position: 'absolute', top: 0, bottom: 0 }} isVisible={!boostrapped}>
                    {!this.state.fuck && <i className="heart white align-self-center icon-logo-header" style={{ fontSize: '50pt' }} onClick={this.show} />}
                    {this.state.fuck && <h3 className="white m-auto">This team has no roster for the Brochure</h3>}
                </Animated>

                {selectedUser && <VideoPlayer color={this.state.color2} player={selectedUser} {...this.props} toggle={this.unselect} isOpen={video_player ? true : false} />}

                {this.state.premium && <PremiumModal
                    toggle={() => this.setState({ premium: false })}
                    success={this.onEnroll}
                    isOpen={this.state.premium}
                    selectedTeam={selectedTeam}
                    disclaimer={this.props.disclaimer}
                    target={_.find(atp, p => p.Amount)} />}
            </section>
        );
    }
}

export default Brochure;
