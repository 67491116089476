import React from 'react';
import PantoneClip from './PantoneClip';
import PantoneMarker from './PantoneMarker';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from '../../../helpers';
import _ from 'lodash';

const target = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {
        const hasDroppedOnChild = monitor.didDrop();
        props.onDrop && props.onDrop(monitor.getItem().index)
        if (hasDroppedOnChild) { return; }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

class Drop extends React.Component {

    render() {
        const { connectDropTarget, isOver, initialLeft, left, pantone, factor, markers, onMarker } = this.props;


        return connectDropTarget(
            <div style={{ height: 160, position: 'relative', overflow: 'hidden', backgroundColor: '#e5e5e5', borderRadius: 5, border: isOver ? '1px dotted red' : '1px dotted gray' }}>
                
                {pantone.length > 0 && (
                    <div style={{ position: 'absolute', width: initialLeft, top: 0, bottom: 0, left: left * -1 }} className="">
                        <div style={{ position: 'absolute', height: 90, marginTop: 10, width: 'auto', right: 10 }} className={`d-flex flex-row ${pantone.length > 0 ? '' : 'justify-content-center'} `}>
                            <span className="black align-self-center">Reel <i className="fas fa-arrow-right" /></span>
                        </div>
                        <div style={{ position: 'absolute', height: 50, bottom: 10, width: 'auto', right: 10 }} className={`d-flex flex-row ${pantone.length > 0 ? '' : 'justify-content-center'} `}>
                            <span className="black align-self-center">Markers <i className="fas fa-arrow-right" /></span>
                        </div>
                    </div>
                )}

                {pantone.length > 0 && (
                    <div>
                        <div style={{ position: 'absolute', height: 90, marginTop: 10, width: 'auto', left: initialLeft - left }} className={`d-flex flex-row ${pantone.length > 0 ? '' : 'justify-content-center'} `}>
                            {pantone && pantone.map((clip, index) => <PantoneClip key={index} clip={clip} factor={factor}
                                onRemove={() => this.props.onRemove(index)} onMoveLeft={() => this.props.onMoveLeft(index)} onMoveRight={() => this.props.onMoveRight(index)} />)}
                        </div>
                        <div style={{ position: 'absolute', height: 50, bottom: 10, width: 'auto', left: initialLeft - left }} className={`d-flex flex-row ${pantone.length > 0 ? '' : 'justify-content-center'} `}>
                            {pantone && pantone.map((clip, index) => <PantoneMarker onClickMarker={onMarker} key={index} markers={_.filter(markers, m => m.currentIndex === index)} clip={clip} factor={factor} />)}
                        </div>
                    </div>
                )}

                {pantone.length === 0 && <div style={{ height: 160 }} className="d-flex flex-row justify-content-center"><span className="align-self-center black font-20">Drag 'n' Drop clips here to start building your video</span></div>}

                {/* RED LINE */}
                {pantone.length > 0 && <div style={{ position: 'absolute', height: 150, width: 5, border: '2px solid red', left: initialLeft }} />}
            </div>
        )
    }
}

export default DropTarget(ItemTypes.FLIGHT, target, collect)(Drop);