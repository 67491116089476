import React from 'react';
import _ from 'lodash';
import TeamSelection from './TeamSelection';
import UserSelection from './UserSelection';
import './css.css';
import PlayerDetails from '../../common/modals/PlayerDetails';
import request from 'superagent';
import config from '../../../config';
import Entity from './Entity';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import RedishHeader from '../../common/things/RedishHeader';
import { Animated } from 'react-animated-css';
import { humanize_user } from '../../../helpers';

class Comp extends React.Component {

    state = {
        searching: false,
        bootstrapped: false,
        users: null,
        selectedPlayer: null,
        searchText: null,
        fetchingTeams: false
    }

    componentWillMount() {
        const { setTitle, selectedTeam, selectedUser } = this.props;

        setTitle && setTitle({
            main: 'Add New User',
            sub: selectedTeam ? selectedTeam.TeamName : null,
            season: selectedTeam ? selectedTeam.SeasonName : null,
            division: selectedTeam ? selectedTeam.DivisionName : null
        });

        // Fetch family teams for roster
        if (selectedUser && !selectedTeam) {
            this.setState({ fetchingTeams: true }, () => {
                this.props.rosterActions && this.props.rosterActions.fetchTeams(selectedUser.IdUser);
            });

        } else if (!selectedUser) {
            // This will also set the 1st user as selected - this is when reaching the page directly through URL
            this.props.familyActions && this.props.familyActions.fetchMembers();
        }
    }

    componentWillReceiveProps = (nextProps) => {
        const { setTitle } = this.props;

        // If the selectedUser has changed, search the teams of that user
        if ((!this.props.selectedUser && nextProps.selectedUser) ||
            (this.props.selectedUser && this.props.selectedUser.IdUser !== nextProps.selectedUser.IdUser)) {
            this.setState({ fetchingTeams: true }, () => {
                this.props.rosterActions && this.props.rosterActions.fetchTeams(nextProps.selectedUser.IdUser);
            });

        }

        // If the selectedTeam has changed, search its roster
        if (nextProps.selectedTeam && nextProps.selectedUser &&
            ((this.props.selectedTeam || {}).IdTeamDivision !== nextProps.selectedTeam.IdTeamDivision)) {

            this.props.rosterActions &&
                this.props.rosterActions.fetchRoster(nextProps.selectedUser.IdUser, nextProps.selectedTeam.IdTeamDivision);

            setTitle && setTitle({
                main: 'Add New User',
                sub: nextProps.selectedTeam ? nextProps.selectedTeam.TeamName : null,
                season: nextProps.selectedTeam ? nextProps.selectedTeam.SeasonName : null,
                division: nextProps.selectedTeam ? nextProps.selectedTeam.DivisionName : null
            });

            this.setState({
                users: null
            }, () => {
                this.state.bootstrapped && this.search();
            });
        }

        if (nextProps.teams && nextProps.teams.length === 0 && this.state.fetchingTeams) {
            this.setState({ fetchingTeams: false, searching: false })
        }

        if (!nextProps.selectedTeam) {
            this.setState({
                users: null,
                bootstrapped: false
            });
        }
    }
    //

    search = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { selectedTeam } = this.props;
        this.setState({
            searching: true,
            bootstrapped: false,
            selectedPlayer: null
        });
        if (selectedTeam) {
            request.get(`${config.apiEndpoint}/api/v6/roster/search/${selectedTeam.IdTeamDivision}/${this.txtSearch.value}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(({ body: { users }}) => {
                    // Decrypt
                    users.forEach(u => humanize_user(u));
                    //
                    this.setState({
                        searching: false,
                        bootstrapped: true,
                        users
                    });

                }, reject => {

                    this.setState({
                        searching: false,
                        bootstrapped: true,
                        users: null
                    });
                });
        } else {
            this.setState({ bootstrapped: true, users: null })
        }
    }

    toogleTeamSelection = () => {
        this.props.teamActions && this.props.teamActions.toggleTeamSelection(false);
    }
    toggleUserSelection = () => {
        this.props.familyActions && this.props.familyActions.toggleUserSelection(false);
    }

    added = (newUser) => {
        var { users } = this.state;

        // Search the user on the list, and update it
        var userIndex = _.findIndex(users, (u) => {
            return u.IdUser === newUser.IdUser
        });
        if (userIndex !== -1) {
            this.setState({
                users: [
                    ...users.slice(0, userIndex),
                    newUser,
                    ...users.slice(userIndex + 1)
                ],
                selectedPlayer: null
            });
            this.props.rosterActions && this.props.rosterActions.fetchRoster(this.props.selectedUser.IdUser, this.props.selectedTeam.IdTeamDivision);
        }

    }



    selectPlayer = (selectedPlayer) => {
        this.setState({
            selectedPlayer
        });
    }
    deselectPlayer = () => {
        this.setState({
            selectedPlayer: null
        });
    }

    handleSearchText = () => {
        this.setState({
            searchText: this.txtSearch.value
        });
    }

    render() {
        const { isTeamSelectionOpen, isUserSelectionOpen, selectedTeam, permissions, limits } = this.props,
            { searching, bootstrapped, users, selectedPlayer } = this.state;

        var partition = _.partition(users, (u) => {
            return (u.overrides && u.overrides.length > 0);
        });

        var coaches = _.filter(_.first(partition), (user) => {
            return _.find(user.overrides, (o) => o.IdRole === 4)
        }),
            players = _.reject(_.first(partition), (user) => {
                return _.find(user.overrides, (o) => o.IdRole === 4)
            });

        return (<ReactCSSTransitionGroup component="section" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
            transitionEnter={true} transitionLeave={false}>
            { /* Loader */}
            {(!selectedTeam && searching) && <div className="d-flex justify-content-center centered-message" key="loader">
                <h1 className="white align-self-center block text-center"><i className="fa fa-spin fa-cog" /></h1></div>}

            {(!selectedTeam && !searching) && <div className="d-flex justify-content-center centered-message" key="loader">
                <h1 className="white align-self-center block text-center" key="no_teams">
                    Sorry you have no teams to roster</h1></div>}

            { /* Search tool */}
            {selectedTeam && limits && <section key="search-tool">
                <form>
                    <div className="m-auto d-flex flex-column">
                        <label className="white align-self-center text-center margin-top">Enter the player's email address
                                                                                                  <br />to enlist them on
                                                                                                  {' '}<span className="yellow">{selectedTeam.TeamName}</span>
                        </label>
                        <div className="input-group input-group-sm m-auto" style={{ maxWidth: 400 }}>
                            <input defaultValue={this.state.searchText} onChange={this.handleSearchText} type="text" className="form-control" placeholder="Email..." ref={(i) => this.txtSearch = i} />
                            <span className="input-group-btn"><button className="btn btn-info" type="submit" onClick={this.search}>{searching ? 'Searching...' : 'Search'}</button></span>
                        </div>
                    </div>
                </form>
            </section>}
            {bootstrapped && <section key="wrapper">
                { /* Users */}
                {bootstrapped && (users && users.length) && (
                    <div className="container">
                        { /* Already rostered */}
                        <ReactCSSTransitionGroup id="roster" className="row" style={{ paddingTop: '3em' }} component="ul" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
                            transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
                            {coaches.map((entity, i) => (
                                <li key={i} className={` col-xs-12 col-sm-6 col-md-4 col-lg-3 hoverable pointer`} style={{ flexBasis: 'auto' }}>
                                    <span onClick={() => this.selectPlayer(entity)}>
                                        <Entity entity={entity} />
                                    </span>
                                </li>))}
                            {players.map((entity, i) => (
                                <li key={i} className={` col-xs-12 col-sm-6 col-md-4 col-lg-3 hoverable pointer`} style={{ flexBasis: 'auto' }}>
                                    <span onClick={() => this.selectPlayer(entity)}>
                                        <Entity entity={entity} />
                                    </span>
                                </li>))}
                        </ReactCSSTransitionGroup>
                        <hr />
                        {!limits.CanEditRoster_Allowed && <Animated animationIn="fadeIn" animationOut="fadeOutRight">
                            <RedishHeader message={`${limits.Reason || "Maximun number of Players has been reached"}`} />
                        </Animated>}

                        { /* Divider only if there are 2 partitions */}
                        {_.first(partition).length && _.last(partition).length && <hr />}
                        { /* Non-rostered */}
                        <ReactCSSTransitionGroup id="others" className="row" component="ul" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
                            transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
                            {_.last(partition).map((entity, i) => (
                                <li key={i} className={` col-xs-12 col-sm-6 col-md-4 col-lg-3 hoverable pointer`} style={{ flexBasis: 'auto' }}>
                                    <span onClick={() => this.selectPlayer(entity)}>
                                        <Entity entity={entity} />
                                    </span>
                                </li>))}
                        </ReactCSSTransitionGroup>
                    </div>)}
                { /* None */}
                {bootstrapped && (!users || !users.length) && (
                    <div className="d-flex justify-content-center centered-message" style={{ marginTop: '7em' }}>
                        <h1 className="white align-self-center block text-center" key="loader">{permissions.CanEditRoster_Allowed ? 'Sorry, we could not find any players under that email' : permissions.CanEditRoster_AlertMessage}</h1></div>)}
            </section>}
            { /* Modals */}
            {isUserSelectionOpen && <UserSelection key="user-selection" {...this.props} isOpen={isUserSelectionOpen} toggle={this.toggleUserSelection} />}
            {isTeamSelectionOpen && <TeamSelection key="team-selection" {...this.props} isOpen={isTeamSelectionOpen} toggle={this.toogleTeamSelection} />}
            {selectedPlayer && <PlayerDetails key="player-details" permissions={this.props.permissions}
                entity={selectedPlayer} isOpen={selectedPlayer ? true : false} toggle={this.deselectPlayer}
                roles={this.props.roles}
                selectedTeam={selectedTeam} fnSuccess={this.added} limits={limits} />}
        </ReactCSSTransitionGroup>)
    }
}

export default Comp;