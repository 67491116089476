function reducer(state = [], action) {

    switch (action.type) {
        case 'NETWORK/COACHES/SET':
            return action.network_coaches;
        default:
            return state;
    }
}

export default reducer;