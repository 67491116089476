import React from 'react';
import moment from 'moment';
import { Animated } from 'react-animated-css';

class Entity extends React.Component {
    state = {
        entity: null
    }

    // Lifecycle
    componentWillMount() {
        const { entity } = this.props;
        this.setState({ entity });
    }
    componentWillReceiveProps = (newProps) => this.setState({ entity: newProps.entity });
    //    

    render() {
        const { entity } = this.state, { index } = this.props;

        return <Animated animationIn="fadeInRight" animationInDelay={100 * index} className={`card tile shadow-box d-flex flex-row tile`} style={{ position: 'relative', height: 160 }}>
            <div className="cover" style={{ maxWidth: '50%', width: 160, height: '100%', background: ['url(', (entity.CURRENT_IMAGE_100 || `/images/defaults/${entity.IdGender === 2 ? 'female' : 'male'}.png`), ') no-repeat center center black'].join('') }} />
            <div style={{ lineHeight: 1 }} className={`px-3 d-flex flex-column justify-content-center w-100`}>
                <span className="block  font-20">{entity.NameFirst}</span>
                <span className="block ">{entity.NameLast}</span>
                <span className="block  margin-top-half">{moment(entity.DateBirth, 'MM/DD/YYYY').utc().format('MMM Do YYYY')}</span>
                <span className="block  font-10">{entity.Gender}</span>
                <span className="block mt-2  font-10">{entity.FullPhoneNumber}</span>
                { entity.MedicalNotes && <span className="text-right"><i className="fas fa-heartbeat red" /></span> }
            </div>
            { /* This is a hack to generate the error if the image doesn't exist, in order to call errorImage and update the fucking thing≈ */}
            {/*<img alt="" onError={this.errorImage} className="m-auto pixel-hack" src={(entity.UserImage)} />*/}
        </Animated>
    }
}

export default Entity;
