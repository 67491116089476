import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actionCreators from '../redux-sagas/actions/actionCreators.js';
import * as couponActions from '../redux-sagas/actions/couponActions.js';
import * as divisionsActions from '../redux-sagas/actions/divisionsActions.js';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as spotlightActions from '../redux-sagas/actions/sportlightActions';
import * as teamActions from '../redux-sagas/actions/teamActions';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as messagesActions from '../redux-sagas/actions/messagesActions';
import * as geoActions from '../redux-sagas/actions/geoActions.js';

import Main from '../components/Main.Admin';


function mapStateToProps(state) {
    return {
        auth: state.auth,
        ageGroups: state.ageGroups,
        archived_teams: state.archived_teams,
        campaigns: state.campaigns,
        campaign: state.campaign,
        communities: state.communities,
        coupons: state.coupons,
        countries: state.countries,        
        couponTypes: state.couponTypes,
        fetchingData: state.fetchingData,
        fetchingSpotlight: state.fetchingSpotlight,
        generic: state.generic,
        league: state.league,
        fees: state.fees,
        feeTypes: state.feeTypes,
        leagueImage: state.leagueImage,
        leaguePoints: state.leaguePoints,
        leagues: state.leagues,  
        leads: state.leads,      
        loggedIn: state.loggedIn,
        messages_received: state.messages_received,
        messages_sent: state.messages_sent,
        membership_programs: state.membership_programs,
        medals: state.medals,
        micro: state.micro,
        notes: state.notes,
        positions: state.positions,
        processes: state.processes,
        seasons: state.seasons,
        spotlight: state.spotlight,
        spotlightOn: state.spotlightOn,
        states: state.states,
        teams: state.teams,
        family: state.family,
        templates: state.templates,
        title: state.title,
        users: state.users,
        user: state.user,
        spread: state.spread,
        tournamentPointsTypes: state.tournamentPointsTypes,        
        tournamentSeasons: state.tournamentSeasons,
        time_zones: state.time_zones,
        validations: state.validations,
        validations_summary: state.validations_summary,
        violations: state.violations,
        violation_types: state.violation_types,
        //...state
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        couponActions: bindActionCreators(couponActions, dispatch),
        divisionsActions: bindActionCreators(divisionsActions, dispatch),
        leagueActions: bindActionCreators(leagueActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch),
        spotlightActions: bindActionCreators(spotlightActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        messagesActions: bindActionCreators(messagesActions, dispatch),
        familyActions: bindActionCreators(familyActions, dispatch),  
        geoActions: bindActionCreators(geoActions, dispatch),      
    }, bindActionCreators(actionCreators, dispatch))
}

const App_Admin = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App_Admin;

