function game(state = [], action) {

    switch (action.type) {
        case 'GAME/SET':
            return action.game;
        case 'GAME/CLEAR':
            return null;
        default:
            return state;
    }
}

export default game;
