import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FileModal extends React.Component {

    state = {
        page: 0,
        max: 1
    }

    componentWillMount() {
        const { file } = this.props;
        if (!file) return;
        this.setState({
            page: 0,
            max: file.instruments.length,
            images: file.instruments
        });
    }
    componentWillReceiveProps = nextProps => {
        const { file } = nextProps;
        if (!file) return;        
        this.setState({
            page: 0,
            max: file.instruments.length,
            images: file.instruments
        });
    }

    up = () => {
        let { max, page } = this.state;
        if (page < max - 1) { page++; }
        else page = 0;
        this.setState({ page });
    }

    down = () => { 
        let { max, page } = this.state;
        if (page !== 0) { page--; }
        else page = max-1;
        this.setState({ page });
    }

    render() {
        const { isOpen, toggle } = this.props, { max, images, page } = this.state;

        return (
            <Modal isOpen={isOpen} style={{ width: 700 }}>
                <ModalHeader className="bg-blue-dark white" toggle={toggle}>
                    {`Uploaded document`}
                </ModalHeader>
                <ModalBody className="container-fluid no-margin no-padding" >
                    {images && <img alt="" src={`${images[page].InstrumentImage}?full=100`} className="w-100" />}
                </ModalBody>
                {max > 1 && <ModalFooter className="d-flex flex-row">
                    <button onClick={this.up} className="btn btn-link"><i className="fas fa-arrow-left black font-40" /></button>
                    <span className="ml-auto font-20 black">File #{page+1}</span>
                    <button onClick={this.down} className="btn btn-link ml-auto"><i className="fas fa-arrow-right black font-40" /></button>
                </ModalFooter>}
            </Modal>
        )
    }
}

export default FileModal;
