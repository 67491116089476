import React from 'react';
import './css.css';
import config from '../../config';
import request from 'superagent';
import { Animated } from 'react-animated-css';
import { Badge } from 'reactstrap';
import Select from 'react-select';

class Capture extends React.Component {

  state = {
    captured: false,
    representative: null,
    missingName: false,
    missingLastName: false,
    missingEmail: false,
    microTransac: false,
    leads: [],
    done: false,
    showingLeads: false
  }

  componentWillMount = () => {
    this.props.fetchLeads && this.props.fetchLeads();
  }

  validateForm = () => {
    var { missingName, missingLastName, missingEmail } = this.state;
    missingName = this.txtName.value ? false : true;
    missingLastName = this.txtLastName.value ? false : true;
    missingEmail = this.txtEmail.value ? false : true;
    this.setState({
      missingName,
      missingLastName,
      missingEmail
    });
    return (!missingName && !missingLastName && !missingEmail);
  }

  clearForm = () => {
    this.txtName.value = null;
    this.txtLastName.value = null;
    this.txtEmail.value = null;
    this.txtPhone.value = null;
    this.txtOrganization.value = null;
    this.txtLocation.value = null;
    this.txtMembers.value = null;
    this.txtNotes.value = null;
    this.txtSeasons.value = null;
  }


  save = () => {
    const { representative } = this.state;

    if (this.validateForm()) {

      var lead = {
        FirstName: this.txtName.value,
        LastName: this.txtLastName.value,
        EmailAddress: this.txtEmail.value,
        PhoneNumber: this.txtPhone.value,
        Organization: this.txtOrganization.value,
        Location: this.txtLocation.value,
        NumberOfUsers: this.txtMembers.value,
        NumberOfSeasons: this.txtSeasons.value,
        Notes: this.txtNotes.value,
        Representative: representative
      }


      this.setState({
        microTransac: true
      });

      request.post(`${config.apiEndpoint}/api/v5/leads`)
        .send(lead)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then((data) => {
          // "Updated" badge feedback
          this.setState({
            captured: true, done: true
          });
          setTimeout(() => {
            this.setState({
              captured: false
            });
          }, 1500)
          this.setState({
            microTransac: false
          });
          this.clearForm();
          this.props.addLead && this.props.addLead(lead);
        }, (reject) => {
          alert('There is something wrong');
          this.setState({
            microTransac: false
          });
        });
    } else {
      alert('Please check the required (*) fields');
    }
  }

  handleRepresentativeChange = (obj) => {
    let representative = obj ? obj.value : 0;
    this.setState({
      representative
    });
  }

  toggleLeads = () => {
    this.setState({
      showingLeads: !this.state.showingLeads
    });
  }

  render() {
    const { captured, representative, missingName, missingLastName, missingEmail, microTransac, showingLeads } = this.state,
      { leads } = this.props;

    const representativesOptions = [
      {
        "label": "Jeffrey Lawson",
        "value": "Jeffrey Lawson"
      },
      {
        "label": "Patrick Johnston",
        "value": "Patrick Johnston"
      },
      {
        "label": "Al Dobles",
        "value": "Al Dobles"
      },
      {
        "label": "Medon Michaelides",
        "value": "Medon Michaelides"
      }
    ];

    return <section id="content-wrapper" className="login">
      <div className="bg-blue-dark p-4">
        <img alt="" src="/images/logos/logo_white.png" height="50px" />
      </div>
      <div className="wrapper my-4">
        <div className="container-fluid no-margin">
          <div className="row no-padding no-margin">
            <div className="col-12 no-padding no-margin">
              <div className="form-group" key="1">
                <label className="black font-16">First Name (*)</label>
                <input type="text" ref={(input) => this.txtName = input} className={`form-control ${missingName ? 'bg-danger' : ''}`} />
              </div>
              <div className="form-group" key="2">
                <label className="black font-16">Last Name (*)</label>
                <input type="text" ref={(input) => this.txtLastName = input} className={`form-control ${missingLastName ? 'bg-danger' : ''}`} />
              </div>
              <div className="form-group" key="3">
                <label className="black font-16">Email address (*)</label>
                <input type="email" ref={(input) => this.txtEmail = input} className={`form-control ${missingEmail ? 'bg-danger' : ''}`} />
              </div>
              <div className="form-group" key="4">
                <label className="black font-16">Phone</label>
                <input type="text" ref={(input) => this.txtPhone = input} className="form-control" />
              </div>
              <div className="form-group" key="5">
                <label className="black font-16">Organization / Club</label>
                <input type="text" ref={(input) => this.txtOrganization = input} className="form-control" />
              </div>
              <div className="form-group" key="6">
                <label className="black font-16">Location</label>
                <input type="text" ref={(input) => this.txtLocation = input} className="form-control" />
              </div>
              <div className="form-group" key="7">
                <label className="black font-16"># Members</label>
                <input type="text" ref={(input) => this.txtMembers = input} className="form-control" />
              </div>
              <div className="form-group" key="11">
                <label className="black font-16"># Seasons</label>
                <input type="text" ref={(input) => this.txtSeasons = input} className="form-control" />
              </div>
              <div className="form-group" key="8">
                <label className="black font-16">Representative</label>
                <Select options={representativesOptions} value={representative} name="selectDivision" onChange={this.handleRepresentativeChange} />
              </div>
              <div className="form-group" key="9">
                <label className="black font-16">Notes</label>
                <textarea className="form-control" rows="3" ref={(input) => this.txtNotes = input}></textarea>
              </div>
              {!microTransac && <button type="submit" key="10" className="btn btn-info btn-block" onClick={this.save}>Save Lead {captured && <Badge key="11" className="align-self-center margin-left" color="success">Saved!</Badge>}</button>}
              {microTransac && <button type="button" key="10" className="btn btn-info btn-block"><i className="fa fa-cog fa-2x fa-spin font-10" />
                {' '}Saving, please wait...</button>}

            </div>
            <button onClick={this.toggleLeads} className="col-12 btn btn-default btn-sm margin-top">{showingLeads ? 'Hide' : 'Show'} all leads <i className={`fa fa-caret-${showingLeads ? 'down' : 'right'}`} /></button>
          </div>
        </div>
      </div>
      <Animated animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.state.showingLeads} style={{ position: 'fixed', right: 0, top: 0, bottom: 0, left: 0, overflowY: 'auto' }} className="bg-blue-dark">
        <div className="p-4" onClick={() => this.setState({ showingLeads: false })}>
          <i className="fa fa-times font-20 white" />
        </div>
        <table className="table white font-8 no-padding no-margin">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact</th>
              <th>Organization</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, i) => (
              <tr key={i}>
                <td>
                  {[lead.FirstName, lead.LastName].join(' ')}
                </td>
                <td>
                  {lead.EmailAddress}
                  <br />
                  {lead.PhoneNumber}
                </td>
                <td>
                  {lead.Organization || '-'}
                  <br /> (
                  {lead.NumberOfUsers} members)
                </td>
                <td>
                  {lead.Location}
                </td>
              </tr>))}
          </tbody>
        </table>
      </Animated>
      <Animated animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.state.done} style={{ position: 'fixed', right: 0, top: 0, bottom: 0, left: 100 }} className="bg-blue-dark">
        <div onClick={() => this.setState({ done: false })} className="d-flex flex-row justify-content-center h-100">
          <div className="align-self-center">
            <i className="far fa-check-circle font-80 green" />
          </div>
        </div>
      </Animated>
    </section>
  }
}

export default Capture;
