import React from 'react';
import { DropTarget } from 'react-dnd';
import Tryout from './tryout';
import TryoutTeam from './tryout_team';
import _ from 'lodash';
import { ItemTypes } from '../../../../../helpers';

const target = {
  canDrop(props, monitor) {
    return true
  },
  drop(props, monitor, component) {

    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }

    const { waitlistsActions, selectedDivision, season } = props, { item, index } = monitor.getItem();
    [3,5].indexOf(season.IdSeasonType) === -1 && waitlistsActions && waitlistsActions.returnToWaitlist(item, index, selectedDivision.IdDivision);
    [3,5].indexOf(season.IdSeasonType) !== -1 && waitlistsActions && waitlistsActions.returnTeamToWaitlist(item, index, selectedDivision.IdDivision);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class LowerList extends React.Component {


  state = {
    filters: localStorage.getItem("COACHES.FILTERBY") ? JSON.parse(localStorage.getItem("COACHES.FILTERBY")) : {
      rostered: 1,
      nonRostered: 1
    }
  }

  toggleFilter = (args) => {
    const { filters } = this.state;
    filters[args.name] = !filters[args.name];
    localStorage.setItem("COACHES.FILTERBY", JSON.stringify(filters));
    this.setState({
      filters
    });
  }

  render() {

    const { connectDropTarget, isOver, canDrop, items, selectedDivision, season } = this.props,
      { filters } = this.state;

    let filteredItems = _.filter(items, (item) => {
      if (!filters.rostered && item.IdTeamUserRole !== null) return false;
      if (!filters.nonRostered && item.IdTeamUserRole === null) return false;
      return true;
    });

    return connectDropTarget(
      <figure style={{ borderTop: '20px solid gray', position: 'absolute', bottom: 0, top: '50%', left: 0, right: 0, border: null }} className={`bg-white m-0 ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}`}>
        {items && <div className={`d-flex bg-${canDrop ? 'danger' : 'blue-dark'} px-3 d-flex flex-row white`} style={{ position: 'absolute', top: 0, height: 50, width: '100%' }}>
          <span className="align-self-center">Waitlisted</span>
          <span className="align-self-center ml-2">({items.length} available)</span>
          <span className="align-self-center ml-auto"></span>
        </div>}

        {[3, 5].indexOf(season.IdSeasonType) === -1 && <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={{ position: 'absolute', top: 50, bottom: 0, overflow: 'auto' }}>
          <tbody className="w-100" style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Status</th>
              <th>Registration Date</th>
              <th>Contact</th>
            </tr>
            {this.props.items && selectedDivision && _.filter(filteredItems, (t) => {
              return t.IdDivision !== selectedDivision.IdDivision;
            }).map((item, index) => (
              <Tryout key={index} waitlistsActions={this.props.waitlistsActions} season={this.props.season} item={item} index={index} />
            ))}
          </tbody>
        </table>}


        {[3, 5].indexOf(season.IdSeasonType) !== -1 && <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={{ position: 'absolute', top: 50, bottom: 0, overflow: 'auto' }}>
          <tbody className="w-100" style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Team Name</th>              
              <th>Status</th>
              <th>Registration Date</th>
              <th>Contact</th>
            </tr>
            {this.props.items && selectedDivision && _.filter(filteredItems, (t) => {
              return t.IdDivision !== selectedDivision.IdDivision;
            }).map((item, index) => (
              <TryoutTeam key={index} waitlistsActions={this.props.waitlistsActions} season={this.props.season} item={item} index={index} />
            ))}
          </tbody>
        </table>}
      </figure>

    )
  }
}

export default DropTarget([ItemTypes.ROSTEREDPLAYER], target, collect)(LowerList);
