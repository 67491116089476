import React from 'react';
import { Link } from 'react-router';

class Navigation extends React.Component {
    render() {
        const { season } = this.props;
        return <div className="d-flex flex-row justify-content-between w-100 mt-2" style={{ height: 50, backgroundColor: '#4469C7', borderRadius: 5, overflow: 'hidden' }}>
            
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/season`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Season</Link>
            
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/divisions`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Divisions</Link>
            
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/registration_fees`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center line1">Fees</Link>
            
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/print_roster_manager`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Print Roster</Link>
            
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/custom_questions`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Questions</Link>
            
            <Link className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Awards</Link>
            
            <Link className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Award Cards</Link>
            
            <Link className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">T-Shirt</Link>
            
            {season && season.IdLeague === 11826 && <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/player_cards`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Player Cards</Link> }
            
            {season && season.IdLeague === 25678 && <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/say_cards`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Player Cards</Link> }
            
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/travel_rosters`} className="h-100 px-3 white text-center d-flex sqwad-font toUpper flex-column justify-content-center">Travel</Link>
        </div>
    }
}

export default Navigation;