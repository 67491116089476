export function fetchPayments(idUser, idSeason, mode) {
    return {
        type: 'PAYMENTS/FETCH',
        idUser,
        idSeason,
        mode
    }
}
export function clearPayments() {
    return {
        type: 'PAYMENTS/SET',
        payments: null
    }
}
export function clearBalance() {
    return {
        type: 'BALANCE/SET',
        balance: null
    }
}
export function clearDues() {
    return {
        type: 'DUES/SET',
        dues: null
    }
}
export function clearPastDues() {
    return {
        type: 'PAST_DUES/SET',
        pastDues: null
    }
}
export function fetchPrograms(idSeason) {
    return { type: 'REGISTRATION_PROGRAMS/FETCH', idSeason }
}
export function clearPrograms() {
    return { type: 'PAYMENT_PLANS/CLEAR' }
}
export function setDues(dues) {
    return { type: 'DUES/SET', dues }
}
export function addDue(due) {
    return { type: 'DUES/ADD', due }
}
export function removeDue(index) {
    return { type: 'DUES/REMOVE', index }
}
export function updateDue(due, index) {
    return { type: 'DUES/UPDATE', due, index }
}
export function popDue() {
    return { type: 'DUES/POP' }
}
export function deleteDue(IdCatalogItemPaymentPlan) {
    return { type: 'DUES/DELETE', IdCatalogItemPaymentPlan }
}
export function setZipCodes(zipCodes) {
    return { type: 'ZIP_CODES/SET', zipCodes }
}
export function addZipCode(code) {
    return { type: 'ZIP_CODE/ADD', code }
}
export function updateCode(code, index) {
    return { type: 'ZIP_CODE/UPDATE', code, index }
}
export function deleteFamilyIndex(IdCatalogItemPriceAdjustmentByItemIndex) {
    return { type: 'FAMILY_ADJUSTMENT/REMOVE_INDEX', IdCatalogItemPriceAdjustmentByItemIndex }
}
export function deletePayment(payment) {
    return { type: 'PAYMENT/DELETE', payment }
}