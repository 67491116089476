import React from 'react';
import { Link } from 'react-router';
import '../common/style.css';
import { Animated } from 'react-animated-css';
import Loader from '../../common/Loader';
import CartPopup from '../common/CartPopup';
import _ from 'lodash';

class Leagues extends React.Component {

  state = {
    microTransac: false,
    bootstrapped: false
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle } = this.props;
    setTitle && setTitle({ main: 'Registration' });
    this.props.leagueActions && this.props.leagueActions.clearLeague();
    this.props.leagueActions && this.props.leagueActions.fetchLeaguesForRegistration();
    this.props.leagueActions && this.props.leagueActions.fetchPendingRegistrations();
  }
  componentDidMount() {
    this.setState({ bootstrapped: true });
  }
  //

  setLeague = (league) => {
    this.props.leagueActions &&
      this.props.leagueActions.setLeague &&
      this.props.leagueActions.setLeague(league);

    // Here we set the currency code
    this.props.leagueActions && this.props.leagueActions.fetchCurrency(league.IdLeague);
    //
  }

  search = (e) => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    this.props.leagueActions && this.props.leagueActions.fetchLeaguesForRegistration(this.txtSearch.value);
  }

  onProceed = () => {

    const { pending_registration } = this.state;

    this.props.pouchActions &&
      this.props.pouchActions.set({
        selectedDivision: pending_registration,
        selectedTeam: pending_registration,
        selectedUser: pending_registration,
        selectedRole: pending_registration,
        selectedSeason: pending_registration,
        selectedLeague: pending_registration,
        mode: 1
      });

    // Detect if registering a team or a player / coach
    this.props.router && this.props.router.push('/registration/pending');
  }

  onReject = () => {
    const { pending_registration } = this.state;
    this.setState({ pending_registration: null }, () => {
      // TODO: call API, then refresh
      this.props.leagueActions && this.props.leagueActions.rejectPendingRegistration(pending_registration.IdPendingRegistration);
    })
  }

  toggleCart = () => this.setState({ isCartOpen: !this.state.isCartOpen });

  onPendingRegistration = (pending_registration) => {
    const { cart } = this.props;
    if (_.find(cart, item => item.selectedLeague && item.selectedLeague.IdPendingRegistration === pending_registration.IdPendingRegistration)) {
      this.setState({ isCartOpen: true, league: pending_registration });
    } else {
      if (_.find(cart, item => item.selectedSeason.IdSeason === pending_registration.IdSeason &&
        item.selectedSeason.IdUser === pending_registration.IdUser &&
        item.selectedSeason.IdRole === pending_registration.IdRole)) {
        this.setState({ repeated_registration: true });
      } else {
        this.setState({ pending_registration });
      }
    }
  }

  render() {
    const { leagues, registrations, cart } = this.props,
      { bootstrapped } = this.state;

    return (
      <section>
        {/* PENDING REGISTRATIONS */}
        {registrations && registrations.length > 0 && <div className="pl-2 pr-2">
          <div className="w3-container mt-4">
            <div className="w3-content d-flex flex-column montserrat">

              <div className="w-100 black">
                <h1>Pending Registrations</h1>
                <h6 className="block">You've been invited to join the following tournaments</h6>
              </div>

              <div className="d-flex flex-wrap justify-content-center league-list margin-bottom mt-4">
                <ul className="w-100">
                  {registrations.map((r, i) => <li key={i} className="card shadow-box mt-2 p-4 w-100">
                    <div onClick={() => this.onPendingRegistration(r)} className="d-flex flex-row w-100 pointer">
                      <div className="align-self-center contain contains d-flex flex-column" style={{ width: 100, height: 100, background: `url(${r.TeamLogo}?full=80) no-repeat center center` }}>
                        <div className="mt-auto mr-1 mb-1 align-self-end cover" style={{ width: 30, height: 30, borderRadius: 15, background: `url(${r.UserImageUrl}?full=80) no-repeat center center` }} />
                      </div>
                      <div className="d-flex flex-column align-self-center ml-4">
                        <span className="font-12">{r.SeasonName}</span>
                        <span className="font-14 toBold">{r.TeamName}</span>
                        <span className="font-10">Player: {`${r.NameFirst} ${r.NameLast}`}</span>
                        {_.find(cart, item => item.selectedLeague && item.selectedLeague.IdPendingRegistration === r.IdPendingRegistration) && <span className="blue font-10">In Cart</span>}
                        {!_.find(cart, item => item.selectedLeague && item.selectedLeague.IdPendingRegistration === r.IdPendingRegistration) && <span className="red font-10">Pending Registration</span>}
                      </div>
                      <i className="ml-auto fas fa-chevron-right font-20 black align-self-center" />
                    </div>
                  </li>)}
                </ul>
              </div>
            </div>

          </div>

          <hr />

        </div>}

        <div className="pl-2 pr-2">
          <div className="w3-container mt-4">
            <div className="w3-content d-flex flex-column flex-md-row montserrat">
              <div className="w-100 black align-self-center mb-3">
                <h1 className="sqwad-font toUpper">Leagues</h1>
                {bootstrapped && leagues && leagues.length > 0 && (<h6 className="block">Select a league you'd like to register</h6>)}
              </div>
              {bootstrapped && leagues && leagues.length > 0 && (<form className="w-100 d-flex flex-row align-self-center">
                <input type="text" className="form-control" placeholder="Search for other Leagues" ref={(i) => this.txtSearch = i} />
                <span className="input-group-btn">
                  <button type="submit" onClick={this.search} style={{ width: 120, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center">
                    <i className="fas fa-plus margin-right-half" />Search</button>
                </span>
              </form>)}
            </div>
          </div>
        </div>

        {bootstrapped && leagues && leagues.length > 0 && (<section key="leagues" className="w3-container w3-content mx-auto">
          <div className="d-flex flex-wrap justify-content-center my-4">
            {leagues.map((league, i) => <li className="mx-1 my-1 tile card shadow-box" style={{ width: 220, height: 250 }} key={i}>
              <Animated animationIn="bounceIn" animationInDelay={i * 50} className="w-100 h-100">
                <Link onClick={() => this.setLeague(league)} to={`/registration/${league.Id || league.IdLeague}`} className="white align-self-center justify-content-center d-flex flex-column w-100 h-100">
                  <div className="d-flex flex-row align-self-center justify-content-center contain contains" style={{ height: 100, width: 100, background: `url(${league.LeagueImage}) no-repeat center center` }} />
                  <span className="my-3 mx-2 montserrat black align-self-center line1 text-center" style={{ fontSize: (league.Name || league.LeagueName).length > 20 ? 12 : 16 }}>{league.Name || league.LeagueName}</span>
                </Link>
              </Animated>
            </li>)}
          </div>
        </section>)}

        { /* Loader */}
        {!bootstrapped && <Loader key="loader" message={`Loading Leagues, please wait...`} />}

        {bootstrapped && leagues && leagues.length === 0 && (
          <div key="no-leagues" style={{ maxWidth: 600 }} className="d-flex flex-column justify-content-center centered-message p-4 m-auto">
            <span className="black font-20">Search for a league to register</span>
            <div className="w-100  d-flex flex-row">
              <input type="text" className="form-control" placeholder="Search for Leagues" ref={(i) => this.txtSearch = i} />
              <span className="input-group-btn">
                <button onClick={this.search} style={{ width: 150, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center">
                  <i className="fas fa-plus margin-right-half" />Search</button>
              </span>
            </div>
          </div>
        )}

        {this.state.pending_registration && <div className="" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#000000', opacity: 0.5 }} />}
        <Animated animationIn="slideInRight" animationOut='slideOutRight' isVisible={this.state.pending_registration ? true : false} animateOnMount={false} className=" bg-gray d-flex flex-column" style={{ width: 600, top: 0, bottom: 0, right: 0, position: 'fixed' }}>
          <div className="d-flex flex-row p-3">
            <i className="fas fa-arrow-left black font-16" onClick={() => this.setState({ pending_registration: null })} />
            <span className="ml-auto conf-16 black">Invitation</span>
            <i className="ml-auto fa-arrow-left font-16" style={{ color: '#e5e5e5' }} />
          </div>
          <div className="card shadow-box p-3 d-flex flex-column mx-3">
            <div className="d-flex flex-row  p-2">
              <div className="d-flex flex-row justify-content-center" style={{ width: 50, height: 50 }}>
                {this.state.pending_registration && <i className={`icon-${this.state.pending_registration.SeasonType.toLowerCase()} align-self-center font-30 color-${this.state.pending_registration.SeasonType}`} />}
              </div>
              <div className="d-flex flex-column align-self-center ml-2">
                <span className="font-16 black line1">{(this.state.pending_registration || {}).SeasonName}</span>
                <span className="font-10 black line1">{(this.state.pending_registration || {}).SeasonType}</span>
              </div>
            </div>
            <div className="d-flex flex-row  p-2">
              <div className="cover" style={{ width: 50, height: 50, background: `url(${(this.state.pending_registration || {}).TeamLogo}?full=50) no-repeat center center` }}></div>
              <div className="d-flex flex-column align-self-center ml-2">
                <span className="font-16 black line1">{(this.state.pending_registration || {}).TeamName}</span>
              </div>
            </div>
            <div className="d-flex flex-row  p-2">
              <div className="cover" style={{ width: 50, height: 50, background: `url(${(this.state.pending_registration || {}).UserImageUrl}?full=50) no-repeat center center` }}></div>
              <div className="d-flex flex-column align-self-center ml-2">
                <span className="font-16 black line1">{`${(this.state.pending_registration || {}).NameFirst} ${(this.state.pending_registration || {}).NameLast}`}</span>
                <span className="font-10 black line1">Player</span>
              </div>
            </div>
            <div className="d-flex flex-row  p-2">
              <div className="d-flex flex-row justify-content-center" style={{ width: 50, height: 50 }}>
                {this.state.pending_registration && <i className={`icon-${this.state.pending_registration.DivisionIdGender === 1 ? 'man' : 'woman'} align-self-center font-30 blue`} />}
              </div>
              <div className="d-flex flex-column align-self-center ml-2">
                <span className="font-16 black line1">{(this.state.pending_registration || {}).DivisionName}</span>
              </div>
            </div>
          </div>

          {!this.state.rejecting && <div className="d-flex flex-column">
            <button onClick={this.onProceed} style={{ width: 250, border: '1px solid #2cad3c', borderRadius: 20 }} className="green p-2 mt-4 align-self-center">Proceed</button>
            <button onClick={() => this.setState({ rejecting: true })} style={{ width: 250, borderRadius: 20 }} className="red p-2 align-self-center">Reject Invitation</button>
          </div>}

          {this.state.rejecting && <div className="d-flex flex-column">
            <button onClick={this.onReject} style={{ width: 250, border: '1px solid #dc3545', borderRadius: 20 }} className="red p-2 mt-4 align-self-center">Confirm, reject it!</button>
            <button onClick={() => this.setState({ rejecting: false })} style={{ width: 250, borderRadius: 20 }} className="black p-2 align-self-center">No, cancel</button>
          </div>}

        </Animated>

        {this.state.repeated_registration && <div onClick={() => this.setState({ repeated_registration: null })} className="" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#000000', opacity: 0.5 }} />}
        <Animated animationIn="slideInRight" animationOut='slideOutRight' isVisible={this.state.repeated_registration ? true : false} animateOnMount={false} className=" bg-gray d-flex flex-row justify-content-center" style={{ width: 400, top: 0, bottom: 0, right: 0, position: 'fixed' }}>
          <div className="align-self-center font-16 p-4" onClick={() => this.setState({ repeated_registration: null })}>
            You currently are in the cart for the same season. Please remove that item if you would like to register this new item.
          </div>
        </Animated>

        {this.state.isCartOpen && <CartPopup {...this.props} league={this.state.league} toggle={this.toggleCart} fnOk={this.toggleCart} isOpen={this.state.isCartOpen} />}

      </section>
    )

  }
}

export default Leagues;
