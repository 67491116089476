function reducer(state = [], action) {
    switch (action.type) {
        case 'APP_PLANS/SET':
            return action.plans;
        default:
            return state;
    }
}

export default reducer;
