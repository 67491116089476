function reducer(state = [], action) {
    switch (action.type) {
        case 'AUTO_PAY_SETTINGS/SET':
            return action.autoPaySettings;
        default:
            return state;
    }
}

export default reducer;
