import React from 'react';

class SelectableTeamRole extends React.Component {

    render(){
        const { role } = this.props;
        return <button className="d-flex btn btn-link btn-block">
            <span className="align-self-center">{ role.Name }</span>
            <i className={`align-self-center idented-half ${role.selected ? 'fa-check-circle fa green' : 'fa-circle far'}`} />
        </button>
    }
}

export default SelectableTeamRole;