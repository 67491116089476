import React from 'react';
import TeamDroppableArea from './TeamDroppableArea';
import request from 'superagent';
import config from '../../../../config';
import _ from 'lodash';
import { calculateBWContrast, ItemTypes } from '../../../../helpers';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';

const source = {
    beginDrag(props) {
        return props;
    }
};

const target = {
    canDrop(props, a) {
        return true; //props.flight.IdFlight === a.getItem().flight.IdFlight;
    },
    drop(props, monitor) {
        const fromIndex = monitor.getItem().index,
            thisIndex = props.index, { selectedDivision } = props;
        props.flightsActions && props.flightsActions.sortFlights(fromIndex, thisIndex, selectedDivision.IdDivision);
    }
}

class Flight extends React.Component {

    state = {
        editMode: false,
        teams: [],
        flight: null,
        microTransact: false
    }

    // Lifecycle
    componentWillMount = () => {
        const { flight, selectedDivision, divisions } = this.props;

        // WORKFLOW: If its a brand new flight, we call the API to create it straight up with default data
        if (!flight.IdFlight) {

            this.setState({ flight, microTransact: true });

            // Save call API            
            request.post(`${config.apiEndpoint}/api/v4/flights/${selectedDivision.IdDivision}`)
                .send({
                    flightName: flight.FlightName,
                    minimumGamesPerTeam: flight.MinimumGamesPerTeam,
                    position: this.props.index
                })
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    // TODO: Handle possible errors
                    flight.IdFlight = data.body.IdFlight;
                    flight.teams = [];
                    this.setState({ flight, microTransact: false, divisions });
                });
        } else {
            this.setState({ flight, divisions });
        }
    }

    componentWillReceiveProps = nextProps => {
        const { flightedTeams } = nextProps, { flight } = this.state;
        flight.teams = _.chain(flightedTeams).filter(team => team.IdFlight === flight.IdFlight)
            .orderBy('Position').value();
        this.setState({ flight });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { editMode } = this.state;
        if (editMode) {
            this.txtFlightName.focus();
            this.txtFlightName.select();
        }
    }
    //

    toggleEditMode = () => this.setState({ editMode: !this.state.editMode });

    headerButtonAction = () => {

        const { editMode, flight } = this.state,
            { fnRemoveFlight, index, } = this.props;

        if (editMode) {

            flight.FlightName = this.txtFlightName.value;
            flight.MinimumGamesPerTeam = this.txtMinimumGamesPerTeam.value;

            // Update flight title
            if (flight.IdFlight) {

                this.setState({ editMode: false, flight, microTransact: true });
                request.patch(`${config.apiEndpoint}/api/v4/flights/${flight.IdFlight}`)
                    .send({
                        flightName: flight.FlightName,
                        minimumGamesPerTeam: flight.MinimumGamesPerTeam,
                        position: this.props.index
                    })
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then(data => {
                        // TODO: Handle possible errors
                        this.setState({ flight, microTransact: false });
                    });
            }
            //

        } else {
            // Delete the flight
            // TODO: confirmations
            fnRemoveFlight && fnRemoveFlight(flight, index) // weak
        }
    }

    addTeam = args => {        

        const { team } = args,
            { flight, divisions } = this.state, { selectedDivision, flights } = this.props;

        // Validate for dropping teams that are already flighted here or somewhere else
        // TODO: this validation by color is very unreliable. Need to be by some kind of ID
        if (team.Color) {
            const originalDivision = _.find(divisions, d => d.Color === team.Color);
            if (originalDivision) {
                if (originalDivision.IdDivision === selectedDivision.IdDivision) {
                    //alert('This team is already flighted in this division');
                } else {
                    alert(`This team is already flighted in ${originalDivision.Name}. To continue, first you need to remove it from its previous flight`)
                    return;
                }
            }
        }
        //

        request.post(`${config.apiEndpoint}/api/v4/flights/flight/team/${flight.IdFlight}`)
            .send({
                position: flight.teams.length + 1,
                idTeam: team.IdTeam,
                idTeamDivision: team.IdTeamDivision || team.TeamDivision
            })
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then(({ body }) => {

                // This will replace the 'temporal flighted team' with the actual record                       
                flight.teams.push(body.record);                
                team.Color = selectedDivision.Color;
                this.props.flightsActions && this.props.flightsActions.addFlightedTeam(body.record);                
                
                // TODO: why not re-sort all the fligths ?
                flights.forEach(flight => {
                    this.props.flightsActions && this.props.flightsActions.forceResort(flight.IdFlight);
                });
            });
    }

    removeTeam = (teamFlighted) => {

        this.props.flightsActions && this.props.flightsActions.removeFlightedTeam(teamFlighted);
        request.del(`${config.apiEndpoint}/api/v4/flights/team/flight/${teamFlighted.Id}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then(data => {
                // TODO: any errors?
                this.props.fnUpdateTeam && this.props.fnUpdateTeam(teamFlighted);
                this.sortTeams({ from: 0, to: 0 });
            });
    }

    sortTeams = ({ from, to }) => {

        const { flight } = this.state, { flightedTeams } = this.props;        
        // dispatch
        this.props.flightsActions.moveFlightedTeamsInFlight && this.props.flightsActions.moveFlightedTeamsInFlight({
            flightedTeams,
            teams: flight.teams,
            IdFlight: flight.IdFlight,
            from, to
        });
    }

    moveTeam = ({ source, to, of }) => {
        // source
        // to  = index
        // of = idFlight   
        
        
        const { flight } = this.state, { selectedDivision, flights } = this.props;;        
        
        request.post(`${config.apiEndpoint}/api/v4/flights/flight/team/${flight.IdFlight}`)
            .send({
                position: to+1,
                idTeam: source.IdTeam,
                idTeamDivision: source.IdTeamDivision || source.TeamDivision
            })
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then(({ body }) => {

                // This will replace the 'temporal flighted team' with the actual record                       
                flight.teams.push(body.record);                
                source.Color = selectedDivision.Color;
                this.props.flightsActions && this.props.flightsActions.addFlightedTeam(body.record);                               
                // CHANGE: why not re-sort all the fligths ?
                flights.forEach(flight => {
                    this.props.flightsActions && this.props.flightsActions.forceResort(flight.IdFlight);
                });
            });
        
    }

    render() {

        const { index, selectedDivision, connectDragSource, connectDropTarget, isOverCurrent, canDrop } = this.props,
            { editMode, microTransact, flight } = this.state;

        const POSITION = { left: ((index * 260)) + 'px', borderRadius: 4 };

        const HEADER_STYLE = {
            backgroundColor: selectedDivision ? selectedDivision.Color : null,
            color: selectedDivision ? calculateBWContrast(selectedDivision.Color, true) : null
        }

        const accept = [ItemTypes.FLIGHTEDTEAMS, ItemTypes.TEAM];

        return connectDragSource(connectDropTarget(

            <div className={`flight ${isOverCurrent ? 'isOver' : ''} ${canDrop ? 'debug' : ''}`} style={POSITION}>
                <form>
                    {/* HEADER */}
                    <div className={`d-flex flex-row ${!flight.IdFlight ? '' : null}`} style={HEADER_STYLE}>
                        <div className="d-flex flex-column hundred-percent">
                            {!editMode && (
                                <button type="button" className="btn btn-link text-left d-flex flex-row " onClick={this.toggleEditMode}>
                                    <span className={`${flight.FlightName && flight.FlightName.length > 15 ? 'font-10' : ''}`}>{flight.FlightName}</span>
                                    {!microTransact && <i className={`fas fa-pencil-alt align-self-center ml-auto`} />}
                                </button>
                            )}
                            {editMode &&
                                <input type="text" defaultValue={flight.FlightName} ref={(input) => {
                                    this.txtFlightName = input;
                                }} className="form-control align-self-center control-sm" placeholder="Name of the flight"
                                />}
                            <div className="d-flex flex-row">
                                <button type="button" className="btn btn-link btn-sm align-self-center " >{isOverCurrent} Games per team:</button>
                                {editMode && <input className="form-control form-control-sm ml-auto" style={{ width: 40 }} type="number" min="0" defaultValue={flight.MinimumGamesPerTeam} ref={(input) => {
                                    this.txtMinimumGamesPerTeam = input;
                                }} />}
                                {!editMode && <span className="ml-auto align-self-center">{flight.MinimumGamesPerTeam}</span>}
                            </div>
                        </div>
                        <div className="ml-auto">
                            {!microTransact &&
                                <button type={editMode ? `submit` : `button`} className={`btn-${editMode ? 'success' : 'link'} align-self-center btn ml-auto `} onClick={this.headerButtonAction}>
                                    <i className={`fa fa-${editMode ? "check" : "times"}`} />
                                </button>}
                            {microTransact &&
                                <button className="btn-link align-self-center btn ml-auto ">
                                    <i className="fa fa-spin fa-2x fa-hourglass-o font-12" />
                                </button>}
                        </div>
                    </div>
                </form>

                {/* WHITE DROPPABLE AREA */}
                <TeamDroppableArea accept={accept} index={index} addTeam={this.addTeam} removeTeam={this.removeTeam} sortTeams={this.sortTeams}
                    moveTeam={this.moveTeam} flight={flight} />

            </div>
        ))
    }
}

export default flow(
    DropTarget(ItemTypes.FLIGHT, target, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true })
    })),
    DragSource(ItemTypes.FLIGHT, source, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }))
)(Flight);
