import React from 'react';
import _ from 'lodash';
import SelectableTeam from './partials/selectableTeam';

class DivisionListItem extends React.Component {

    state = {
        collapsed: true
    }

    toggle = () => this.setState({ collapsed: !this.state.collapsed });

    checkDivision = () => {
        const { division, divisions } = this.props;
        divisions.forEach(d => {
            if (parseInt(d.IdDivision, 10) !== parseInt(division.IdDivision, 10)) d.checked = false;
            else d.checked = !d.checked;
            _.chain(d.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value().forEach(function (team) {
                team.checked = d.checked;
            });
        });
        this.setState({ collapsed: false });
        this.props.notify && this.props.notify();
    }

    checkTeam = (d, i) => {
        const { divisions } = this.props;
        divisions.forEach(division => {
            division.checked = false;
            _.chain(division.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value().forEach((team, index) => {
                if (index !== i || parseInt(d.IdDivision, 10) !== parseInt(division.IdDivision, 10)) team.checked = false;
                else if (parseInt(d.IdDivision, 10) === parseInt(division.IdDivision)) team.checked = !team.checked;
            });
        });
        this.setState({ collapsed: false });
        this.props.notify && this.props.notify();
    }

    renderTeam = (team, i) => {
        const { games, division } = this.props;
        let teamGames = _.filter(games, (g) => { return (g.HomeTeam.IdTeamDivision === team.IdTeamDivision || g.AwayTeam.IdTeamDivision === team.IdTeamDivision); });
        return <SelectableTeam key={i} team={team} index={i} games={teamGames} fnCheckTeam={() => this.checkTeam(division, i)} />

    }

    render() {
        const { division } = this.props,
            { collapsed } = this.state;
        var hasTeams = _.chain(division.Flights).map((f) => {
            return f.FlightedTeams;
        }).flatten().value().length;
        return (<li className={`${collapsed ? 'off' : 'on'} division block no-margin no-padding`}>
            <div className="d-flex flex-row font-8">
                <button className="btn btn-sm btn-default" onClick={this.toggle} style={{ width: '20px' }}><i className={`fa fa-caret-${collapsed ? 'right' : 'down'} ${hasTeams ? '' : 'white'}`} /></button>
                <button className={`btn btn-sm btn-default d-flex flex-row`} onClick={this.checkDivision}>
                    <i className={`fa-${division.checked ? 'check-circle green fas' : 'circle far'} align-self-center`} />
                    <i className="fa fa-square align-self-center margin-left font-16" style={{ color: division.Color }} />
                    <span className="margin-left align-self-center">{division.Name}</span></button>
            </div>
            {!collapsed && <ul className="flights-wrapper no-style-lists no-margin">
                {_.chain(division.Flights).map((f) => {
                    return f.FlightedTeams;
                }).flatten().value().map((team, i) => this.renderTeam(team, i))}
            </ul>}
        </li>);
    }
}

export default DivisionListItem;
