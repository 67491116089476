import React from 'react';
import ScoreSection from './Partials/ScoreSection';
import RosterSection from './Partials/RosterSection';
import SignatureSection from './Partials/SignatureSection';
import FullScore from './Partials/FullScore';
import config from '../../../../config';

class Sheet3v3 extends React.Component {

  render() {
    const { game, rosterCap, community } = this.props;

    return <section className={`postcard sheet ml-auto mr-auto container-fluid black font-10 padding-bottom no-padding ${this.props.className}`} style={{ position: 'relative' }}>
      {(game.IsPlayoff) && <div style={{ position: 'absolute', bottom: '2.6em', left: '12em' }}>
        <button className="btn-danger btn">Playoffs</button>
      </div>}
      <div className="row margin-bottom padding-top no-margin no-padding">
        <div className="col-1 no-margin no-padding" style={{ background: 'url(' + game.LeagueImage + ') no-repeat center center', backgroundSize: 'contain' }}>
          <img alt="" src={game.LeagueImage} style={{ width: 1, height: 1 }} />
        </div>
        <div className="col-11 font-12 ">
          <div className="d-flex flex-row">
            <div><span>Date:</span>
              {' '}<strong>{game.DateTimeStart_Date_Format}</strong></div>
            <div className="ml-auto">
              <span>Time:</span>
              {' '}<strong>{game.DateTimeStart_Time_Format}</strong>
            </div>
            <div className="ml-auto">
              <span>Location:</span>
              {' '}<strong>{game.Location.Name}</strong></div>
          </div>
          <div className="d-flex flex-row font-9">
            <div className="">
              <span>Division:</span>
              {' '}<strong>{game.Division}</strong>
            </div>
            <div className="ml-auto"><span>Game Id:</span>
              {' '}<strong>{game.IdGame}</strong></div>
          </div>
          {game.IsPlayoff && <div className="d-flex flex-row font-9">
            <div className="">
              <span>Bracket:</span>
              {' '}<strong>{game.Title}</strong>
            </div>
          </div>}
        </div>
        <div className="col-6">
          {game.Teams[0] && <ScoreSection team={game.Teams[0]} />}
        </div>
        <div className="col-6">
          {game.Teams[1] && <ScoreSection team={game.Teams[1]} />}
        </div>
        {(community === 3 || community === 4) && <div className="col-12"><FullScore /></div>}
        <div className="col-6">
          {game.Teams[0] && <RosterSection team={game.Teams[0]} rosterCap={rosterCap} community={community} />}
          <div style={{ height: 10 }} />
          {game.Teams[0] && <SignatureSection team={game.Teams[0]} signature={game.signatures ? game.signatures.CoachHomeTeam_Signature : null} />}
        </div>
        <div className="col-6" style={{ borderLeft: community !== 1 ? '1px solid gray' : null }}>
          {game.Teams[1] && <RosterSection team={game.Teams[1]} rosterCap={rosterCap} community={community} />}
          <div style={{ height: 10 }} />
          {game.Teams[1] && <SignatureSection team={game.Teams[1]} signature={game.signatures ? game.signatures.CoachAwayTeam_Signature : null} />}
        </div>
        <div className='col-12' style={{ height: 10 }} />
        <div className="col-4">
        <img alt="" src="https://matchreports.thesqwad.com/images/logos/logo_black.png" width="100px" />
        </div>
        <div className="col-4 text-center">
          <img alt="" src={[config.apiEndpoint, 'api/v4/common/barcode', game.IdGame].join('/')} />
        </div>
        <div className="col-4 text-right" style={{ lineHeight: 1 }}>
          <strong>{game.Season}</strong>
        </div>
      </div>
    </section >
  }
}

export default Sheet3v3;
