function title(state = [], action) {

  switch (action.type) {
    case 'TOURNAMENT/TOURNAMENT_SEASONS/SET':
      return action.tournamentSeasons;
    default:
      return state;
  }
}

export default title;