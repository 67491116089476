import React from 'react';
import UserViolation from './UserViolation';
import _ from 'lodash';
import { generateRandomId } from '../../../helpers';

class Violations extends React.Component {
    state = {
        violations: []
    }

    componentWillMount() {
        const { seasonActions, idTeamUserRole, violations, idCommunity } = this.props;
        seasonActions && seasonActions.fetchSeasonViolations(idTeamUserRole);
        seasonActions && seasonActions.fetchSeasonViolationTypes(idCommunity);
        violations && violations.forEach(note => { note.LocalId = generateRandomId(5); });
        this.setState({ violations });
    }
    componentWillReceiveProps = nextProps => {
        const { violations } = nextProps;
        violations && violations.forEach(note => { note.LocalId = generateRandomId(5); });
        this.setState({ violations });
    }

    onAddViolation = () => {
        const { violations } = this.state, { idUser, idSeason, idTeamUserRole } = this.props;
        this.setState({ violations: [{ LocalId: generateRandomId(5), editing: true, IdUser: idUser, IdSeason: idSeason, IdTeamUserRole: idTeamUserRole }, ...violations] })
    }

    onDeleteNote = (violation) => {
        const { violations } = this.state, { seasonActions } = this.props;
        violation.IdViolation && seasonActions && seasonActions.removeViolation(violation);
        this.setState({ violations: _.reject(violations, n => n.LocalId === violation.LocalId) });
    }

    render() {
        const { violations } = this.state;

        return <section className="p-4">
            <h3 className="d-flex flex-row">
                <span>Violations</span>
                <button onClick={this.onAddViolation} style={{ width: 'auto', border: '2px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center ml-auto font-10 px-3">Add Violation</button>
            </h3>

            <div className="m-3">
                <table className="table">
                    <thead>
                        <tr>
                            <th>What</th>
                            <th>When</th>
                            <th>Suspended until?</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {violations && violations.map((v, i) => <UserViolation violation_types={this.props.violation_types} violation={v} key={v.LocalId || v.IdViolation} seasonActions={this.props.seasonActions} fnDelete={() => this.onDeleteNote(v)} />)}

                    </tbody>
                </table>
                
            </div>

        </section>
    }
}

export default Violations;