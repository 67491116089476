import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../../../helpers';
import moment from 'moment';

const source = {
  beginDrag(props) {
    return props;
  }
}

class TryoutCard extends React.Component {

  renderThing = () => {
    const { program, unmovable, isDragging, fnEdit } = this.props;
    return <li style={{ marginRight: unmovable ? 0 : '1em' }} className={`registration-program-card p-2 bg-danger d-flex flex-column ${isDragging ? 'dragging' : ''}`}>
      <span className="white font-16 text-left toBold">{program.Name}</span>
      <span className="white font-10">From {moment(program.StartDate).format('MMM Do YYYY')} to {moment(program.EndDate).format('MMM Do YYYY')}</span>
      {!unmovable && <span style={{ padding: '0 0.5em' }} className="btn btn-success btn-sm movable-hint font-8" onClick={() => fnEdit(program)}><i className="fas fa-edit" />{' '}Edit</span>}
    </li>
  }
  render() {
    const { connectDragPreview, connectDragSource, unmovable } = this.props;
    if (unmovable) return this.renderThing();
    else return connectDragPreview(connectDragSource(this.renderThing()));
  }
}

export default flow(
  DragSource(ItemTypes.TRYOUT, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(TryoutCard);