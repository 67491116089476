import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Loader from '../common/Loader';
import LineLoader from '../common/LineLoader';
import moment from 'moment';
import User from './item';
import _ from 'lodash';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class DOB extends React.Component {

  state = {
    dates: [],
    mode: 1,
    date: null,
    bootstrapped: false,
    softLoading: false,
    dropdownOpen: false,
    other: false,
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'DOB',
      main: 'Validation'
    });
    const { dates } = this.state;
    for (var i = 0; i < 97; i++) {
      dates.push(moment().add((3 + i) * -1, 'years').format('YYYY'));
    }
    this.setState({ dates });

    this.props.leagueActions && this.props.leagueActions.fetchDOBs(params.idLeague, params.letter || 'A');
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.params.letter !== this.props.params.letter) {
      this.setState({ softLoading: true })
      this.props.leagueActions && this.props.leagueActions.fetchDOBs(nextProps.params.idLeague, nextProps.params.letter);
    } else
      if (nextProps.users) {
        this.setState({ bootstrapped: true, softLoading: false });
      }
  }
  //

  toggleMode = (mode) => {
    this.setState({ mode });
  }
  selectDate = (date) => {
    this.setState({ date, other: false });
  }
  selectOtherDate = (date) => {
    this.setState({ date, other: true });
  }

  toggleOtherDates = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  render() {
    const { dates, mode, bootstrapped, date, softLoading, other } = this.state, { users } = this.props;

    const filteredUsers = _.chain(users || [])
      .filter(u => { return u.IdGender === mode; })
      .filter(u => {
        if (date)
          return moment(u.DateOfBirth).format('YYYY') === date;
        return true;
      })
      .value();

    return (<ReactCSSTransitionGroup className="p-2" component="section" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
      transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false} style={{ backgroundColor: '#333'}}>
      {softLoading && <LineLoader key="soft" />}
      {bootstrapped && <section key="data">
        <div key="modes" className="d-flex justify-content-center">
          <button onClick={() => this.toggleMode(1)} className={`btn btn-${mode === 1 ? 'success' : 'link blue'} margin-right-half`}><i className="fas fa-male margin-right-half"></i>Males</button>
          <button onClick={() => this.toggleMode(2)} className={`btn btn-${mode === 2 ? 'success' : 'link blue'}`}><i className="fas fa-female margin-right-half"></i>Females</button>
        </div>
        <div key="with-data" className="d-flex flex-row justify-content-around margin-top">
          <div className="no-margin no-padding" style={{ borderRight: '5px solid #002549' }}>
            {/* All */}
            <button onClick={() => this.selectDate(null)} className={`btn btn-${date ? 'info' : 'success'} btn-sm margin-right-half margin-bottom-half`}>All</button>
            { /* Dates */}
            {dates && _.take(dates, 20).map((year, i) => <button onClick={() => this.selectDate(year)} key={i} className={`btn btn-${(date && date) === year ? 'success' : 'info'} btn-sm margin-right-half margin-bottom-half`}>{year}</button>)}
            {/* Other Dates */}
            <Dropdown style={{ display: 'inline' }} isOpen={this.state.dropdownOpen} toggle={this.toggleOtherDates}>
              <DropdownToggle className={`btn btn-${other ? 'success' : 'info'} btn-sm margin-right-half margin-bottom-half`} caret>
                {other ? date : 'Other'}
              </DropdownToggle>
              <DropdownMenu>
                {dates && _.takeRight(dates, 77).map((date, i) => <DropdownItem key={i} onClick={() => this.selectOtherDate(date)}>{date}</DropdownItem>)}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <table className="table white">
          <thead>
            <tr>
              <th>Users <span className="yellow">({filteredUsers.length})</span></th>
              <th>Birth Date</th>
              <th>Contact</th>
              <th>Validated</th>
              <th>Validated By</th>
              <th>Validated Date</th>
            </tr>
          </thead>
          <ReactCSSTransitionGroup component="tbody" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
            transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
            {filteredUsers.map((user, i) => <User key={user.IdUser} user={user} leagueActions={this.props.leagueActions}
              idLeague={this.props.params.idLeague} usersActions={this.props.usersActions} />)}
          </ReactCSSTransitionGroup>
        </table>
      </section>}
      {!bootstrapped && <Loader key="loader" message={`Loading Assets...`} />}
    </ReactCSSTransitionGroup>)
  }
}

export default DOB;