function reducer(state = [], action) {

    switch (action.type) {
        case 'TEAM/REGISTRATION/SET':
        
            return action.teams;
        default:
            return state;
    }
}

export default reducer;