import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import _ from 'lodash';


class List extends React.Component {
  state = {
    sort: 'RegistrationDate',
    up: true
  };
  componentWillMount() {
    const { report } = this.props;
    this.setState({ report });
  }
  componentWillReceiveProps = nextProps => {
    const { report } = nextProps;
    this.setState({ report });
  }
  onSort = (sort) => {
    if (this.state.sort === sort) {
      this.setState({ up: !this.state.up });
    } else {
      this.setState({ sort });
    }
  }
  render() {

    const { report, sort, up } = this.state, { params } = this.props, { idSeason } = params;
    let sorted = _.chain(report)
      .sortBy(sort).value();
    if (up) sorted = sorted.reverse();

    return (
      <table id="table-to-xls" className="table table-sm font-10">
        <thead>
          <tr>
            <th>Id</th>
            <th className={`pointer underlined ${sort === 'NameFirst' ? 'blue' : 'black'}`} onClick={() => this.onSort('NameFirst')}>{sort === 'NameFirst' && <i className={`fas fa-caret-${up ? 'up' : 'down'}`} />}Name</th>
            <th className={`pointer underlined ${sort === 'NameLast' ? 'blue' : 'black'}`} onClick={() => this.onSort('NameLast')}>{sort === 'NameLast' && <i className={`fas fa-caret-${up ? 'up' : 'down'}`} />}Last Name</th>
            <th>Team Name</th>
            <th>Division</th>
            <th className={`pointer underlined ${sort === 'RegistrationDate' ? 'blue' : 'black'}`} onClick={() => this.onSort('RegistrationDate')}>{sort === 'RegistrationDate' && <i className={`fas fa-caret-${up ? 'up' : 'down'}`} />}Reg. Date</th>
            <th>Role</th>
            <th>DOBV</th>
            <th>COACH</th>
            <th>SAFE</th>
            <th>BGC</th>
            <th>FINGER</th>
            <th>CONC</th>
          </tr>
        </thead>
        <tbody>
          {sorted && sorted.map((record, i) => (
            <tr key={i}>
              <td className="font-8">
                {record.IdUser}
              </td>
              <td>
                <Link to={`/season/${idSeason}/player/${record.IdUser}/${record.IdTeamUserRole}`} className="blue underlined">{record.NameFirst}</Link>
              </td>
              <td>
                <Link to={`/season/${idSeason}/player/${record.IdUser}/${record.IdTeamUserRole}`} className="blue underlined">{record.NameLast}</Link>
              </td>
              <td>{record.Team || record.TeamName}</td>
              <td>
                {record.DivisionName}
              </td>
              <td>
                {moment(record.RegistrationDate).format('MM/DD/YYYY')}
              </td>
              <td>
                {record.RoleOverride}
              </td>
              <td>{record.DOBV}</td>
              <td>{record.COACH}</td>
              <td>{record.SAFE}</td>
              <td>{record.BGC}</td>
              <td>{record.FINGER}</td>
              <td>{record.CONC}</td>
            </tr>)
          )}
        </tbody>
      </table>
    )
  }
}

export default List;
