export function fetchFieldAvailabilityRules(idSeason){
    return {
        type: 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/FETCH',
        idSeason
    }
}

export function addFieldAvailabilityRule(rule){
    return {
        type: 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/ADD',
        rule
    }
}

export function updateFieldAvailabilityRule(index, rule){
    return {
        type: 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/UPDATE',
        index, rule
    }
}

export function deleteFieldAvailabilityRule(index){
    return {
        type: 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/DELETE',
        index
    }
}

export function associateWithFlight(idRule, idFlight){
    return {
        type: 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/ASSOCIATE_WITH_FLIGHT',
        idRule, idFlight
    }
}

export function removeAssociation(idRuleAssociation){
    return {
        type: 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/REMOVE_ASSOCIATION_FROM_FLIGHT', 
        idRuleAssociation
    }
}

