import React from 'react';
import { Animated } from 'react-animated-css';
import AdminSelection from './AdminSelection';
import _ from 'lodash';
import RoleSelection from './RoleSelection';

class TeamSelection extends React.Component {
    state = {
        bootstrapped: false,
        searchTerm: null,
        coachRoles: [
            {
                Id: 4,
                Name: 'Coach',
                selected: true
            },
            {
                Id: 4,
                Name: 'Assistant Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Team Manager',
                selected: false
            },
        ]
    }

    componentWillMount() {
        this.setState({ bootstrapped: true, stage: 1 });
    }

    onClose = () => {
        this.setState({ bootstrapped: false }, () => {
            const { close } = this.props;
            setTimeout(() => { close && close() }, 500);
        });
    }

    onBack = () => {
        switch (this.state.stage) {
            case 1: this.onClose(); break;
            case 2: this.setState({ stage: 1 }); break;
            case 3: this.setState({ stage: 2 }); break;
            default: break;
        }
    }

    onOk = ({ selectedUser, selectedRole, mode }) => {
        if (selectedUser) { this.setState({ selectedUser, stage: 3 }); }
    }

    onRole = ({ selectedRole }) => {
        const { selectedUser, selectedTeam } = this.state;
        if (selectedRole) {
            this.props.fnOk && this.props.fnOk({
                selectedUser,
                selectedRole,
                selectedTeam,
                selectedSeason: this.props.season,
                selectedLeague: this.props.league,
                mode: 3
            });
        }
    }

    filter = e => this.setState({ searchTerm: e.target.value });

    stage2 = () => this.setState({ stage: 2 });

    stage3 = () => this.setState({ stage: 3 });

    addTeam = () => {
        this.props.fnAddTeam && this.props.fnAddTeam();
    }

    selectTeam = (selectedTeam) => this.setState({ stage: 2, selectedTeam })

    render() {

        const { bootstrapped, stage, searchTerm } = this.state, { teamRegistration, teams, cart, season } = this.props;

        let inCartTeams = _.values(_.groupBy(
            _.chain(teams)
                .filter(team => {
                    return _.find(cart, c => c.selectedTeam && c.selectedTeam.IdTeam === team.IdTeam && c.selectedSeason.IdSeason === season.IdSeason)
                })
                .filter(team => {
                    if (searchTerm) return (team.Name || team.TeamName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
                    return true;
                })
                .sortBy('IdTeam').value().reverse(), t => t.IdTeam));

        let registeredTeams = _.values(_.groupBy(
            _.chain(teams)
                .filter(team => {
                    return _.find(teamRegistration, c => c.IdTeam === team.IdTeam && c.IdSeason === season.IdSeason)
                })
                .filter(team => {
                    if (searchTerm) return (team.Name || team.TeamName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
                    return true;
                })
                .sortBy('IdTeam').value().reverse(), t => t.IdTeam));

        let groupedTeams = _.values(_.groupBy(
            _.chain(teams)
                .filter(team => {
                    return !_.find(cart, c => c.selectedTeam && c.selectedTeam.IdTeam === team.IdTeam && c.selectedSeason.IdSeason === season.IdSeason) &&
                        !_.find(teamRegistration, c => c.IdTeam === team.IdTeam && c.IdSeason === season.IdSeason);
                })
                .filter(team => {
                    if (searchTerm) return (team.Name || team.TeamName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
                    return true;
                })
                .sortBy('IdTeam').value().reverse(), t => t.IdTeam));



        return <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
            <div className="dark-overlay" onClick={this.onClose} />
            <Animated style={{ width: 400, position: 'absolute', top: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray d-flex flex-column montserrat"
                isVisible={bootstrapped} animationOut="slideOutRight" animationIn='slideInRight' animationInDelay={200} animationInDuration={500} animationOutDuration={500}>

                <div style={{ height: 50 }} className="w-100 p-2 d-flex flex-row">
                    <i className="icon-arrow-left jeff_blue font-20 align-self-center" onClick={this.onBack} />
                    {stage === 1 && <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Pick your team</span>}
                    {stage === 2 && <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Who's Coaching | Admin</span>}
                    {stage === 3 && this.state.selectedUser
                        && <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Select {this.state.selectedUser.NameFirst}'s Role</span>}
                    <i className="shopping_cart white-ish font-24 ml-auto align-self-center" />
                </div>

                {stage === 1 && <div className="d-flex flex-column dp-2 h-100 ">
                    <div className="h-100">
                        {/*<div className="p-2">
                            <div className="card shadow-box">
                                <input defaultValue={searchTerm} onChange={this.filter} type="text" className="form-control" style={{ borderRadius: 0 }} placeholder="Search team..." ref={(i) => this.txtSearch = i} />
                            </div>
                        </div>*/}

                        <div className="px-3">
                            <div className="w-100 mt-3">
                                <div className={`black-input d-flex mt-2`}>
                                    <i className="fas fa-search font-16 align-self-center ml-3 black" />
                                    <input defaultValue={searchTerm} onChange={this.filter} className="w-100 p-3 " type="text" name="address" placeholder="Search team..." ref={(input) => this.txtSearch = input} />
                                </div>
                            </div>
                        </div>

                        <div className="mx-3 d-flex flex-wrap justify-content-center mt-4">

                            <div onClick={this.addTeam} style={{ height: 150 }} className="align-self-center card tile shadow-box d-flex flex-column justify-content-center w-50 m-0">
                                <i className="fas fa-plus font-30 align-self-center" />
                                <span className="align-self-center font-8 idented margin-right text-center mt-2">ADD TEAM</span>
                            </div>

                            {inCartTeams && inCartTeams.reverse().map(([team], i) => (
                                <div style={{ height: 150 }} className="align-self-center card tile shadow-box d-flex flex-column justify-content-center w-50" key={i} >
                                    <img alt="" src={team.TeamImageUrl || team.ImageUrl} className="align-self-center" style={{ width: 50 }} />
                                    <span className="align-self-center font-8 idented margin-right text-center mt-2 line1">{team.Name || team.TeamName}</span>
                                    <span className="green align-self-center font-8 line1">In Cart</span>
                                </div>))}

                            {registeredTeams && registeredTeams.reverse().map(([team], i) => (
                                <div style={{ height: 150 }} className="align-self-center card tile shadow-box d-flex flex-column justify-content-center w-50" key={i} >
                                    <img alt="" src={team.TeamImageUrl || team.ImageUrl} className="align-self-center" style={{ width: 50 }} />
                                    <span className="align-self-center font-8 idented margin-right text-center mt-2 line1">{team.Name || team.TeamName}</span>
                                    <span className="green align-self-center font-8 line1">Registered</span>

                                </div>))}

                            {groupedTeams && groupedTeams.reverse().map(([team], i) => (
                                <div onClick={() => this.selectTeam(team)} style={{ height: 150 }} className="align-self-center card tile shadow-box d-flex flex-column justify-content-center w-50 m-0" key={i} >
                                    <img alt="" src={team.TeamImageUrl || team.ImageUrl} className="align-self-center" style={{ width: 50 }} />
                                    <span className="align-self-center font-8 idented margin-right text-center mt-2">{team.Name || team.TeamName}</span>
                                </div>))}

                        </div>
                        <div style={{ height: 100 }} />
                    </div>
                </div>}

                {stage === 2 && <AdminSelection {...this.props} onOk={this.onOk} team={this.state.selectedTeam} />}
                {stage === 3 && <RoleSelection {...this.props} onOk={this.onRole} team={this.state.selectedTeam} user={this.state.selectedUser} />}
            </Animated>
        </section>
    }
}

export default TeamSelection;
