import React from 'react';
import DivisionsList from './divisions/DivisionsList';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';


const seasonTarget = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }

        const { divisions } = props;

        switch (monitor.getItem().rule.type) {
            case 'GameDay':
                divisions.forEach(function (division) {
                    division.Flights.forEach(function (f) {

                        // Evaluate if the rule isn't already there
                        if (!_.find(f.GameDayRules, function (r) {
                            return (r.IdRule === monitor.getItem().rule.Id);
                        })) {
                            monitor.getItem().gameDayRulesActions &&
                                monitor.getItem().gameDayRulesActions.associateWithFlight(monitor.getItem().rule.Id, f.IdFlight);
                            f.GameDayRules.push({
                                IdFlight: f.IdFlight,
                                FlightName: (f.Name || f.FlightName),
                                IdRule: monitor.getItem().rule.Id,
                                RuleName: monitor.getItem().rule.Name
                            });
                        }

                    });
                });
                break;
            case 'exception/time':

                divisions.forEach(function (division) {
                    division.Flights.forEach(function (f) {

                        f.FlightedTeams.forEach(function (team) {

                            // Evaluate if the rule isn't already there
                            if (!_.find(team.ExceptionRules, function (r) {
                                return (r.IdRule) === monitor.getItem().rule.Id;
                            })) {

                                team.ExceptionRules.push({
                                    IdScheduleFlightTeam: team.IdScheduleFlightTeam,
                                    TeamName: team.TeamName,
                                    IdRule: monitor.getItem().rule.Id,
                                    RuleName: monitor.getItem().rule.Name,
                                    Type: monitor.getItem().rule.type
                                });

                                monitor.getItem().exceptionRulesActions &&
                                    monitor.getItem().exceptionRulesActions.associateDateWithTeam(monitor.getItem().rule.Id, team.IdScheduleFlightTeam);

                            }

                        });
                    });
                });
                break;
            case 'FieldAvailability':
                divisions.forEach(function (division) {
                    division.Flights.forEach(function (f) {

                        // Evaluate if the rule isn't already there
                        if (!_.find(f.FieldAvailabilityRules, function (r) {
                            return (r.IdScheduleFieldAvailability || r.IdRule) === monitor.getItem().rule.IdScheduleFieldAvailability;
                        })) {
                            monitor.getItem().fieldAvailabilityRulesActions &&
                                monitor.getItem().fieldAvailabilityRulesActions.associateWithFlight(monitor.getItem().rule.IdScheduleFieldAvailability, f.IdFlight);
                            f.FieldAvailabilityRules.push({
                                IdFlight: f.IdFlight,
                                FlightName: (f.Name || f.FlightName),
                                IdRule: monitor.getItem().rule.IdScheduleFieldAvailability,
                                RuleName: 'Field Availability Rule'
                            });
                        }
                    });
                });
                break;
            default:
                break;
        }

    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class LeftWorkArea extends React.Component {

    state = {
        collapsed: false,
        currentDivisionsListMode: 1 // [1: flights, 2: teams]
    }

    // Lifecycle
    componentWillMount = () => {
        this.setState({ currentDivisionsListMode: this.props.currentDivisionsListMode });
    }
    //

    render() {
        const { connectDropTarget, isOverCurrent, canDrop, season } = this.props;
        return (
            <div className="bg-white h-100 left-side">
                {/*<h2 className="text-center pt-2">Divisions</h2>*/}
                <ul className="seasons-wrapper no-margin no-padding w-100">
                    {connectDropTarget(<li className={`droppable mx-2 ${canDrop ? 'action mt-2' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>
                        <span>{canDrop ? 'Drop here to apply to all the divisions' : season.Name}
                            {canDrop && <i className="idented-half fas fa-arrow-left red" />}</span>
                    </li>)}
                    <li className={`p-3 ${isOverCurrent ? 'toBold' : 'toNormal'}`} style={{ maxHeight: 800, overflowY: 'auto' }}>
                        <DivisionsList {...this.props} />
                    </li>
                </ul>
            </div>
        )

    }
}

export default DropTarget(props => props.accept, seasonTarget, collect)(LeftWorkArea);
