import React from 'react';

class TournamentPosition extends React.Component {
  state = {
    entity: null,
  }

  onChange = (e) => {
    const { entity } = this.props;
    entity.Points = e.target.value;
  }

  render() {

    const { entity } = this.props;
    return <div>
      <div className="d-flex flex-row margin-bottom-half">
        <span className="align-self-center font-10">{entity.TournamentDivisionResults}</span>
        <input key={entity.randomId} onChange={this.onChange} type="number" className="form-control form-control-sm ml-auto w-50"
          defaultValue={entity.Points} />
      </div>
    </div>
  }
}

export default TournamentPosition;
