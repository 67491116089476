import React from 'react';
import { calculateBWContrast } from '../../../../helpers';
class PairingsGame extends React.Component {
    render() {
        const { game, division, colorCode } = this.props;
        const textColor = calculateBWContrast((division || {}).Color);
        return <div id={game.IdSchedulePairings} className="margin-bottom-half d-flex flex-column draggable-game"
            style={{
                width: '100%',
                backgroundColor: game.IdGame ? 'gray' : (colorCode || division.Color), border: '1px solid black', borderRadius: 2, opacity: game.IdGame ? 0.6 : 1
            }}>
            <div className="d-flex w-100 flex-column" style={{ color: textColor }}>
                <div className="d-flex flex-row w-100">
                    <span className="align-self-center font-8 idented-half">(H)</span>
                    <span className="align-self-center w-100 text-center font-8 idented-half">{game.Home_Team}</span>
                </div>
                <div className="d-flex flex-row w-100">
                    <span className="align-self-center font-8 idented-half">(A)</span>
                    <span className="align-self-center w-100 text-center font-8 idented-half">{game.Away_Team}</span>
                </div>
            </div>
            {game.IdGame && false && <span className="font-8 align-self-center">(ID: {game.IdGame})</span>}
        </div>
    }
}

export default PairingsGame;
