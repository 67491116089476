import React from 'react';
import { Popover, PopoverBody, } from 'reactstrap';

class Game extends React.Component {
  state = {
    game: null,
    isScoreOpen: false,
    notify: false
  }
  componentWillMount() {
    this.setState({ game: this.props.game });
  }
  errorImgAway = () => {
    const { game } = this.state;
    (game.away || {}).TeamImageUrl = '/images/defaults/teamLogo.png';
    this.setState({ game });
  }
  errorImgHome = () => {
    const { game } = this.state;
    (game.home || {}).TeamImageUrl = '/images/defaults/teamLogo.png';
    this.setState({ game });
  }

  toggleScore = () => this.setState({ isScoreOpen: !this.state.isScoreOpen });
  save = () => {
    const { game, notify } = this.state;
    (game.home || {}).Score = this.txtHomeScore.value;
    (game.away || {}).Score = this.txtAwayScore.value;
    this.setState({ game, isScoreOpen: false });

    // TODO: call api    
    const { gamesActions } = this.props;
    gamesActions && gamesActions.updateScore(game, notify);
  }
  clearScore = () => {
    const { game } = this.state;
    (game.home || {}).Score = null;
    (game.away || {}).Score = null;
    this.txtHomeScore.value = null;
    this.txtAwayScore.value = null;
    this.setState({ game });
  }
  toggleNotify = () => this.setState({ notify: !this.state.notify });

  render() {
    const { game, notify } = this.state;
    return <tr className="font-10">
      <td>{game.IdGame}</td>
      <td>{game.DivisionFlight}</td>
      <td>{game.IsPlayoff ? 'Playoff' : 'Poolplay'}</td>
      <td>{game.LocationName}</td>
      <td>
        <span className="block">{game.DateTimeStart_Date_Format}</span>
        <span className="block">{game.DateTimeStart_Time_Format} {game.TimeZone_Abbreviation}</span>
      </td>
      <td className="text-right">{(game.home || {}).Name} <img alt="" onError={this.errorImgHome} src={(game.home || {}).TeamImageUrl} className="smallPic" /></td>
      <td id={`btn-score-${game.IdGame}`} className="text-center blue link container-fluid" style={{ width: 170 }}>
        <button onClick={this.toggleScore} className="btn bg-white black d-flex flex-row justify-content-center" style={{ width: 150 }}>
          <span className="align-self-center white font-20">.</span>
          <div className="font-20 text-center w-100 align-self-center">{(game.home || {}).Score}</div>
          <span className="align-self-center">-</span>
          <div className="font-20 text-center w-100 align-self-center">{(game.away || {}).Score}</div>
          <span className="align-self-center white font-20">.</span>
          {/*<input disabled="disabled" type="number" className="square-box no-padding" value={(game.home || {}).Score} style={{ marginRight: 10 }} />
          <input disabled="disabled" type="number" className="square-box no-padding" value={(game.away || {}).Score} />*/}
        </button>
        <Popover placement="top" isOpen={this.state.isScoreOpen} target={`btn-score-${game.IdGame}`} toggle={this.toggleScore}>
          <PopoverBody className="no-margin no-padding" style={{ minWidth: 300 }}>
            <button className="btn btn-sm btn-link float-left margin-left-half " onClick={this.toggleScore}><i className="fa fa-times" /></button>
            <div className="text-center clearfix p-1 margin-top-half">
              Game ID: {game.IdGame}
            </div>
            <div className="d-flex flex-row p-2">
              <img alt="" onError={this.errorImgHome} src={(game.home || {}).TeamImageUrl} className="smallPic align-self-center" />
              <div className="d-flex flex-column idented-half">
                <span>{(game.home || {}).Name}</span>
                <span className="green">Home</span>
              </div>
              <input ref={(i) => this.txtHomeScore = i} className="square-box ml-auto align-self-center" type="number" defaultValue={(game.home || {}).Score} />
            </div>
            <div className="d-flex flex-row p-2">
              <img alt="" onError={this.errorImgAway} src={(game.away || {}).TeamImageUrl} className="smallPic align-self-center" />
              <div className="d-flex flex-column idented-half">
                <span>{(game.away || {}).Name}</span>
                <span className="green">Away</span>
              </div>
              <input ref={(i) => this.txtAwayScore = i} className="square-box ml-auto align-self-center" type="number" defaultValue={(game.away || {}).Score} />
            </div>
            <div className="d-flex flex-row justify-content-center btn btn-link" onClick={this.toggleNotify}>
              <i className={`font-20 align-self-center margin-right-half fas fa-toggle-${notify ? 'on green' : 'off black'}`} />
              <span className="font-12 align-self-center">Notify Participants</span>
            </div>
            <div className="d-flex flex-row">
              <button onClick={this.clearScore} type="button" className="w-100 text-center btn btn-link bg-gray-darkish text-danger">Clear Score</button>
              <button onClick={this.save} type="button" className="w-100 text-center btn btn-success">Save</button>
            </div>

          </PopoverBody>
        </Popover>
      </td>
      <td><img alt="" onError={this.errorImgAway} src={(game.away || {}).TeamImageUrl} className="smallPic" /> {(game.away || {}).Name}</td>
    </tr>
  }
}

export default Game;