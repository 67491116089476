// a reducer takes in 2 things:

// 1: the action (info about what happened)

// 2: a copy of the current state
import _ from 'lodash';

function teams(state = [], action) {
    // call saga to fetch the leagues from API ?
    let index = 0;
    switch (action.type) {
        case 'TEAMS/SET':
            return action.teams;
        case 'TEAMS/CLEAR':
            return null;
        case 'TEAM/RANKING_AGE_GROUP/COMPLETE':
            index = _.findIndex(state, s => s.randomId === action.randomId);
            if (index !== -1) {
                const element = state[index];
                element.IdTournamentRank = action.IdTournamentRank;
                return [...state.slice(0, index), element, ...state.slice(index + 1)];
            }
            return state;
        default:
            return state;
    }
}

export default teams;