function reducer(state = [], action) {

    switch (action.type) {
        case 'ROLES/SET':
            return action.roles;
        case 'ROLES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;