 import React from 'react';
import { Popover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import request from 'superagent';
import config from '../../../config';
import _ from 'lodash';
import { Link } from 'react-router';

class TeamSkeak extends React.Component {

  state = {
    confirming: false,
    deleting: false, team: null
  }

  componentWillMount() {
    const { team } = this.props;
    this.setState({ team }, () => {
      const { team } = this.state;
      if (!team.roster) {

        // TODO: move this service to version 4
        const url = _.compact([config.apiEndpoint, 'api/v3/teams/roster', team.IdTeamDivision]).join('/');
        request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            if (data.body.success) {
              team.roster = data.body.roster;
              this.forceUpdate();
            }
          });
      }
    });


  }

  delete = () => {
    // TODO: Validate if the team can be deleted!
    if (this.state.deleting) return;
    const { fnDelete } = this.props, { team } = this.state;
    this.setState({ deleting: true, error: null, confirming: false }, () => {
      request.delete(`${config.apiEndpoint}/api/v4/teams/${team.IdTeamDivision}`)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then(data => {
          if (data.body.success) {
            fnDelete && fnDelete();
          } else {
            this.setState({
              error: data.body.error,
              deleting: false
            });
          }
        }, reject => {
          this.setState({
            error: reject.response.body.error,
            deleting: false
          });
        });
    });
  }

  toggleDeleteConfirm = () => {
    this.setState({
      confirming: !this.state.confirming
    })
  }

  errorImage = () => {
    const { team } = this.state;
    team.ImageUrl = '/images/defaults/teamLogo.png';
    this.setState({ team });
  }

  render() {
    const { team, isOpen, toggle, season } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <img alt="" onError={this.errorImage} style={{ height: 30 }} src={team.ImageUrl || '/images/defaults/teamLogo.png'} /> {team.TeamName || team.Name}
        </ModalHeader>
        <ModalBody className="container-fluid">
          <ReactCSSTransitionGroup className="tab-content" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
            transitionEnter={true} transitionLeave={false}>
            <div className="container-fluid margin-top-half">
              <div className="row">
                <div className="col-5">
                  <h2>Id: {team.IdTeamDivision}</h2>
                  <hr />
                  <h3>Contact Information</h3>
                  {team.roster && team.roster.coaches.length > 0 && (
                    <div>
                      <div>
                        {[team.roster.coaches[0].LastName, team.roster.coaches[0].Name].join(', ')}
                      </div>
                      {team.roster.coaches[0].PhoneNumber && <div>
                        {team.roster.coaches[0].PhoneNumber.PhoneNumber_Format}
                      </div>}
                      {team.roster.coaches[0].Email && <div>
                        {team.roster.coaches[0].Email}
                      </div>}
                    </div>)}
                  {team.roster && !team.roster.coaches.length && <div className="jumbotron">
                    <h1>No contact information found</h1></div>}
                </div>
                <div className="col-7">
                  <h3>Roster</h3>
                  {team.roster && team.roster.coaches.length > 0 && <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Admins</th>
                        <th>Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {team.roster && team.roster.coaches.map((coach, i) => (
                        <tr key={i}>
                          <td>
                            {[coach.LastName, coach.Name].join(', ')}
                          </td>
                          <td>
                            {coach.RoleName}
                          </td>
                        </tr>))}
                    </tbody>
                  </table>}
                  {team.roster && !team.roster.coaches.length && <div className="jumbotron">
                    <h1>No admins found</h1></div>}
                  {team.roster && team.roster.players.length > 0 && <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Players</th>
                        <th>#</th>
                      </tr>
                    </thead>
                    <tbody>
                      {team.roster && team.roster.players.map((player, i) => (
                        <tr key={i}>
                          <td>
                            {[player.LastName, player.Name].join(', ')}
                          </td>
                          <td>
                            {player.Number}
                          </td>
                        </tr>))}
                    </tbody>
                  </table>}
                  {team.roster && !team.roster.players.length && <div className="jumbotron">
                    <h1>No players found</h1></div>}
                </div>
              </div>
            </div>
          </ReactCSSTransitionGroup>
        </ModalBody>
        {this.state.error && <ModalBody className="bg-danger white p-2 font-10">{this.state.error.message || 'An error has occurred'}</ModalBody>}
        {true && (
          <ModalFooter className="d-flex flex-row">
            {team.IdTeamDivision && (team.CanDelete !== 0) && <button className="btn btn-sm btn-link red mr-auto" onClick={this.toggleDeleteConfirm} id={`btnDeleteTeamModal-${team.IdTeamDivision}`}>{this.state.deleting ? 'Deleting, please wait...' : 'Delete'}</button>}
            {team.IdTeamDivision && (team.CanDelete !== 0) && <Popover placement="bottom" isOpen={this.state.confirming} target={`btnDeleteTeamModal-${team.IdTeamDivision}`} toggle={this.toggleDeleteConfirm}>
              <PopoverBody className="d-flex flex-column justify-content-center">
                <span className="align-self-center">Are you sure?</span>
                <button className="btn btn-sm btn-danger" onClick={this.toggleDeleteConfirm}>No, cancel</button>
                <button className="btn btn-sm btn-success" onClick={this.delete}>Yes, delete it!</button>
              </PopoverBody>
            </Popover>}
            <Link to={`/season/${season.IdSeason}/team/${team.IdTeam}`} className="btn btn-sm btn-info">Go to the Team's Page</Link>

          </ModalFooter>)}
      </Modal>
    )
  }
}

export default TeamSkeak;
