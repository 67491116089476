function red(state = [], action) {
    switch (action.type) {
        case "SPOTLIGHT/FETCH":
            return true;
        case "SPOTLIGHT/FETCH_FAILED":
            return false;
        case "SPOTLIGHT/SET":
            return false;
        default:
            return state;
    }
}

export default red;
