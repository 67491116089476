import React from 'react';
import './divisions.css';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';

import ExceptionRuleIcon from '../exceptions/ExceptionRuleIcon';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const teamTarget = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {
        if (monitor.didDrop()) {
            return;
        }

        const { team } = props;

        switch (monitor.getItem().rule.type) {

            case 'exception/time':

                // Evaluate if the rule isn't already there
                if (!_.find(team.ExceptionRules, function (r) {
                    return ((r.IdRule) === monitor.getItem().rule.Id);
                })) {

                    team.ExceptionRules.push({
                        IdScheduleFlightTeam: team.IdScheduleFlightTeam,
                        TeamName: team.TeamName,
                        IdRule: monitor.getItem().rule.Id,
                        RuleName: monitor.getItem().rule.Name,
                        Type: monitor.getItem().rule.type
                    });

                    monitor.getItem().exceptionRulesActions &&
                        monitor.getItem().exceptionRulesActions.associateDateWithTeam(monitor.getItem().rule.Id, team.IdScheduleFlightTeam);

                }

                break;

            case 'exception/matchup':

                // Evaluate if the rule isn't already there
                //if (!_.find(team.ExceptionRules, function(r) {
                //  return (r.Id || r.IdRule) == monitor.getItem().rule.Id;
                //})) {
                //monitor.getItem().fieldAvailabilityRulesActions &&
                //monitor.getItem().fieldAvailabilityRulesActions.associateWithFlight(monitor.getItem().rule.IdScheduleFieldAvailability, f.IdFlight);
                team.ExceptionRules.push({
                    IdTeamDivision: team.IdTeamDivision,
                    TeamName: team.TeamName,
                    IdRule: monitor.getItem().rule.Id,
                    RuleName: monitor.getItem().rule.Name,
                    Type: monitor.getItem().rule.type
                });

                break;

            default:                
                break;
        }

    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class TeamListItem extends React.Component {
    render() {
        const { connectDropTarget, isOverCurrent, canDrop, team } = this.props;
        const { exceptionRules, exceptionRulesActions } = this.props;
        return connectDropTarget(
            <li className={`flight d-flex flex-row no-margin no-padding block ${canDrop ? 'debug' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>
                <i className="fa fa-shield-alt align-self-center idented" />
                <span className="idented-half align-self-center">{(team.TeamName || team.Name).length > 25 ? `${(team.TeamName || team.Name).substring(0, 25)}...` : (team.TeamName || team.Name)}
                    <ReactCSSTransitionGroup component="section" className="icons-wrapper" transitionName="example" transitionAppear={true}
                        transitionAppearTimeout={200} transitionEnterTimeout={200} transitionLeaveTimeout={200} transitionEnter={true} transitionLeave={true}>
                        {team.ExceptionRules && team.ExceptionRules.map((rule, i) => <ExceptionRuleIcon key={i} index={i} rule={rule} rules={exceptionRules} exceptionRulesActions={exceptionRulesActions}
                        />)}
                    </ReactCSSTransitionGroup>
                </span>
            </li>
        )
    }
}

export default DropTarget(props => props.accept, teamTarget, collect)(TeamListItem);
