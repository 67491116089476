import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router';
import LineLoader from '../LineLoader';
import _ from 'lodash';

class TeamsModal extends React.Component {

  state = {
    teams: null,
    fetchingTeams: true
  }

  // Lifecycle
  componentWillMount() {
    const { division } = this.props;
    this.props.divisionsActions &&
      this.props.divisionsActions.fetchTeams(division.IdDivision);
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.teams) {      
      this.setState({
        teams: nextProps.teams,
        fetchingTeams: false
      });
    }
  }
  componentWillUnmount = () => {
    this.props.divisionsActions && this.props.divisionsActions.clearTeams();
  }
  //

  renderTeam = team => {
    return (
      <div className="d-flex flex-row w-100 p-2 sans">
        <div className="w-25">
          <div className="cover align-self-center ml-2" style={{ width: 40, height: 40, borderRadius: 20, background: `url(${(team.TeamImageUrl || team.Logo)}?full=50) no-repeat center center` }} />
        </div>
        <div className="w-100  d-flex flex-column justify-content-center">
          <span className="toUpper toBold font-12 black line1">{team.TeamName}{' '}{team.Status ? '(' + team.Status + ')' : ''} </span>
          <div className="d-flex flex-row">
            <span className="font-10 align-self-center">ID: {team.IdTeam}</span>
            <span className="font-8 ml-4 align-self-center" style={{ opacity: 0.7 }}>Players: {team.NumberOfPlayers || 0}</span>
            <span className="font-8 ml-4 align-self-center" style={{ opacity: 0.7 }}>Coaches: {team.NumberOfCoaches || 0}</span>
          </div>
        </div>
        <div className="w-100 d-flex flex-column justify-content-center">
          <span className="font-10 align-self-center">{team.DivisionName}</span>
        </div>
        <div className="w-50 d-flex flex-row">
          <button style={{ width: 'auto', border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2 align-self-center">View Profile</button>
        </div>        
      </div>
    )
  }

  onChange = () => { this.setState({ search: this.txtSearch.value }) }

  render() {
    const { division, isOpen, toggle } = this.props,
      { fetchingTeams, teams, search } = this.state;

    let status = _.uniq(_.map(teams, t => t.Status));

    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{ width: 1000 }}>
        <ModalHeader toggle={toggle} className="bg-blue-dark white">
          Teams on
          {' '}
          {division.Name || division.DivisionName}
          {' - '}
          {teams ? teams.length : 0}
        </ModalHeader>
        <ModalBody className="p-4">
          <input placeholder="Search" className="form-control " ref={i => this.txtSearch = i} onChange={this.onChange} />
          {fetchingTeams && <LineLoader />}
        </ModalBody>
        {status && status.map(status => <ModalBody key={status}>
          <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>{status}</h3>
          <ul>
            {teams && _.chain(teams).filter(t => t.Status === status).filter(t => {
              if (search) return (t.TeamName).toLowerCase().indexOf(search.toLowerCase()) !== -1;
              return true;
            }).value().map((t, i) => (
              <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                <Link to={`/season/${division.IdSeason}/team/${t.IdTeam}`} className="d-flex flex-row p-1">
                  {this.renderTeam(t)}
                </Link>
              </li>))}
          </ul>
        </ModalBody>)}
      </Modal>
    )
  }
}

export default TeamsModal;