import React from 'react';
import RoundGame from './RoundGame';
import { Draggable } from '@fullcalendar/interaction';
import { generateRandomId } from '../../../../helpers';
import Bye from './Bye';
import _ from 'lodash';

class Round extends React.Component {
    state = {
        randomId: generateRandomId(10), byes: []
    }

    setup = props => {
        // We're showing the byes automatically, not depending on the DB to generate them but counting the number of teams in the flight, and
        // seeing how many of them have no games in the round
        let byes = _.filter(props.flight.teams, t => _.chain(props.round.games)
            .map(r => { return [r.Away_IdTeam, r.Home_IdTeam]; })
            .flatten()
            .value().indexOf(t.IdTeam) === -1);

        this.setState({ byes });
    }
    componentDidMount = () => {
        new Draggable(document.getElementById(this.state.randomId), { itemSelector: '.draggable-game' });
        this.setup(this.props);
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps);
    }

    render() {
        const { round, index, division, colorCode } = this.props, { byes } = this.state;
        return <section id={this.state.randomId} style={{ width: 200, minWidth: 200, height: 300 }} className="d-flex flex-column margin-right-half">
            <span className="white font-12">Round {index + 1}</span>
            {round.games.map((game, index) => <RoundGame game={game} key={index} index={index} division={division} colorCode={colorCode} />)}
            {byes.map((bye, index) => <Bye key={index} bye={bye} />)}
        </section>
    }
}

export default Round;
