import React from 'react';
import './css.css';
import moment from 'moment';

class Comp extends React.Component {

  state = {
    league: null,
    waiver: null
  }

  componentWillMount() {
    const { params, leagueActions } = this.props;
    leagueActions && leagueActions.fetchLeagueNonSecure(params.idLeague);
    leagueActions && leagueActions.fetchWaiverNonSecure(params.idLeague, params.idUser);
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      league: nextProps.league,
      waiver: nextProps.waiver
    });
  }

  render() {

    const { league, waiver } = this.state;

    return <section id="waiver" backgroundColor="white">
      {league && waiver && <div className="d-flex flex-column">
        <img alt="" src={league.NewLeagueImage || league.LeagueImage} className="m-auto" style={{ height: 80 }} />
        <h2 className="m-auto text-center">{league.LeagueName}</h2>
        <hr />
        <h4 className="text-left blue">Participant (player) Information</h4>
        <div className="d-flex flex-row">
          <span>Full Name</span>
          <span className="ml-auto">{waiver.UserName}</span>
        </div>
        <div className="d-flex flex-row">
          <span>DOB</span>
          <span className="ml-auto">{moment(waiver.DateBirth).utc().format('MMM Do YYYY')} </span>
        </div>
        <div className="d-flex flex-row">
          <span>Gender</span>
          <span className="ml-auto">{waiver.Gender}</span>
        </div>
        <div className="d-flex flex-row">
          <span>Emergency Contact Number</span>
          <span className="ml-auto">{waiver.PhoneNumber}</span>
        </div>
        <hr />
        <h4 className="blue">Terms and Conditions of Participation </h4>
        {waiver.IsPending && <h6 className="red">Read before Signing</h6>}
        <hr />
        <p>
          {waiver.WaiverText}
        </p>
        {waiver && !waiver.IsPending && <div className="m-auto">
          {waiver.SignatureImage.indexOf('base64') !== -1 && <img src={waiver.SignatureImage} />}
          {waiver.SignatureImage.indexOf('base64') === -1 && <div dangerouslySetInnerHTML={{ __html: waiver.SignatureImage }} />}
          <div className="text-center">Signed by{' '}{waiver.SignedName}
          </div>
          <div className="text-center">on{' '}{moment(waiver.DateModified).format('MMM Do YYYY')}
          </div>
        </div>}
      </div>}
    </section>
  }
}

export default Comp;