import React from 'react';
import { Animated } from 'react-animated-css';
import { TabContent, TabPane, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../common/CustomDatePicker2';
import InAppPurchase from './partials/inapppurchase';

class Earnings extends React.Component {
    state = {
        activeTab: '1', //1: [2020, 2021, ....], 2: [JAN, FEB, MAR, MAY....], 3: [?]
        overlay: false,
        months: [
            `${moment().add(0, 'months').format('MMM YYYY')}`,
            `${moment().add(-1, 'months').format('MMM YYYY')}`,
            `${moment().add(-2, 'months').format('MMM YYYY')}`,
            `${moment().add(-3, 'months').format('MMM YYYY')}`,
            `${moment().add(-4, 'months').format('MMM YYYY')}`,
            `${moment().add(-5, 'months').format('MMM YYYY')}`,
        ],
        monthTo: moment().format('MMM YYYY'),
        yearTo: moment().format('YYYY'),
        yearFrom: moment().format('YYYY'),
        yearRange: [
            moment().format('YYYY'),
            moment().add(-1, 'years').format('YYYY'),
            moment().add(-2, 'years').format('YYYY'),
            moment().add(-3, 'years').format('YYYY')],
        from: moment().add(-7, 'days'),
        to: moment(),
        snapshot_mode: 1, //[1: today, 2: week, 3: month]
    }

    applyFilters = () => {
        this.setState({ overlay: false })
    }

    handleChangeFrom = (date) => {
        const { to } = this.state;
        this.setState({
            from: moment(date),
            to: moment(date).isAfter(moment(to)) ? moment(date).add(1, 'day') : moment(to) // range validation
        });
    }

    handleChangeTo = (date) => {
        const { from } = this.state;
        this.setState({
            to: moment(date),
            from: moment(date).isBefore(moment(from)) ? moment(date).add(-1, 'day') : moment(from) // range validation
        });
    }    

    render() {
        const { activeTab, yearRange, from, to, months } = this.state;
        return <section>
            <div className="w3-container" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflowY: 'auto' }}>

                <div className="w3-content mt-4 d-flex flex-row mb-4">
                    <h1>In-App Purchases</h1>
                    <button className="btn btn-default ml-auto" onClick={() => this.setState({ overlay: true })}><i className="fas fa-filter mr-1" />Filters</button>
                </div>

                <InAppPurchase {...this.props} {...this.state} />

                <div style={{ height: 100 }} />

            </div>

            {this.state.overlay && <div onClick={() => this.setState({ overlay: false })} className={`overlay ${this.state.overlay ? 'show' : ''}`} />}
            <Animated animationIn="slideInRight" animateOnMount={false} animationOut="slideOutRight" isVisible={this.state.overlay ? true : false} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: 500, zIndex: 1041 }} className="bg-white px-3 " >

                <div className="d-flex flex-row mt-2">
                    <span className="font-20 align-self-center">Filters</span>
                    {/*<button className="ml-auto align-self-center">Clear All</button>*/}
                </div>
                <div className="card mt-2 p-0">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({
                                active: activeTab === '1'
                            })} onClick={() => this.setState({ activeTab: '1' })}>
                                Yearly
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({
                                active: activeTab === '2'
                            })} onClick={() => this.setState({ activeTab: '2' })}>
                                Monthly
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({
                                active: activeTab === '3'
                            })} onClick={() => this.setState({ activeTab: '3' })}>
                                Custom
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="bg-white  p-3">

                            {activeTab === '1' && <Dropdown className="align-self-center btn-link mt-2" size="sm" style={{ border: '1px solid #CCC', borderRadius: 3 }} isOpen={this.state.isMonthlyOpen} toggle={() => this.setState({ isMonthlyOpen: !this.state.isMonthlyOpen })}>
                                <DropdownToggle color="default" className="w-100" caret>
                                    JAN 1st to DEC 31st {this.state.yearTo || ''}
                                </DropdownToggle>

                                <DropdownMenu className="w-100">
                                    {yearRange.map((year) => <DropdownItem key={year} onClick={() => this.setState({ isMonthlyOpen: false, yearTo: year, yearFrom: year })}>
                                        {year}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>}

                            <button className="btn btn-success btn-block mt-2" onClick={this.applyFilters}>Apply</button>
                        </TabPane>
                        <TabPane tabId="2" className="bg-white p-3">

                            {activeTab === '2' && <Dropdown className="align-self-center btn-link mt-2" size="sm" style={{ border: '1px solid #CCC', borderRadius: 3 }} isOpen={this.state.isMonthlyOpen} toggle={() => this.setState({ isMonthlyOpen: !this.state.isMonthlyOpen })}>
                                <DropdownToggle color="default" className="w-100" caret>
                                    {this.state.monthTo || ''}
                                </DropdownToggle>

                                <DropdownMenu className="w-100">
                                    {months.map((monthTo, i) => <DropdownItem key={monthTo} onClick={() => this.setState({ monthTo, isMonthlyOpen: false })}>
                                        {monthTo}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>}

                            <button className="btn btn-success btn-block mt-2" onClick={this.applyFilters}>Apply</button>
                        </TabPane>
                        <TabPane tabId="3" className="bg-white p-3">
                            <div className="d-flex flex-row justify-content-center">
                                <div className="align-self-center">
                                    <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={from} selectsStart
                                        startDate={from} endDate={to} onChange={this.handleChangeFrom} />
                                </div>
                                <span className=" idented-half align-self-center margin-right-half ">to</span>
                                <div className="align-self-center">
                                    <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={to}
                                        selectsEnd startDate={from} endDate={to} onChange={this.handleChangeTo} />
                                </div>
                            </div>
                            <button className="btn btn-success btn-block mt-2" onClick={this.applyFilters}>Apply</button>
                        </TabPane>

                    </TabContent>
                </div>
            </Animated>


        </section >
    }
}

export default Earnings;