import React from 'react';
import _ from 'lodash';

const Coach = ({ coach, onClick }) => {
    return (

        /*
        
            let dude_on_the_left = _.first(chunk), dude_on_the_right = _.last(chunk);
                let coach_validations_left = {
                    text: CONFIG.keys[(dude_on_the_left.validations && dude_on_the_left.validations.length) ? 'warning' : 'clock'],
                    color: COLORS[(dude_on_the_left.validations && dude_on_the_left.validations.length) ? 'red' : 'blue'],
                    visible: ((dude_on_the_left.validations && dude_on_the_left.validations.length) || _.find(dude_on_the_left.all_validations, v => !v.Solved && v.InstrumentImage && v.InstrumentImage.length)) ? true : false
                }, coach_validations_right = {
                    text: CONFIG.keys[(dude_on_the_right.validations && dude_on_the_right.validations.length) ? 'warning' : 'clock'],
                    color: COLORS[(dude_on_the_right.validations && dude_on_the_right.validations.length) ? 'red' : 'blue'],
                    visible: ((dude_on_the_right.validations && dude_on_the_right.validations.length) || _.find(dude_on_the_right.all_validations, v => !v.Solved && v.InstrumentImage && v.InstrumentImage.length)) ? true : false
                }
        */

        <figure onClick={onClick} className="px-2 hoverable w-25  h-auto align-self-start" >
            <div className="card tile shadow-box w3-card d-flex flex-column" style={{ overflow: 'hidden', borderRadius: 10, position: 'relative' }}>
                <div style={{ height: 220, background: `url(${coach.UserImage || coach.Image || coach.ImageUrl}) no-repeat center center black` }} className="cover w-100 d-flex flex-column justify-content-end">
                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 30 }} className="w-100 d-flex flex-row justify-content-center">
                        <span className="white  align-self-center">{coach.RoleOverrideName}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center bg-white p-2">
                    <span className={`black align-self-center font-${(`${coach.NameFirst} ${coach.NameLast}`.length > 22 ? '8' : '10')}`}>{coach.NameFirst} {coach.NameLast}</span>
                    <i className="ml-auto" />
                    {coach.validations && coach.validations.length > 0 && <i className="fas fa-warning text-danger font-16" />}
                    {_.find(coach.all_validations, v => !v.Solved && v.InstrumentImage && v.InstrumentImage.length > 0) && <i className="fas fa-clock text-info ml-2 font-16" />}
                </div>
            </div>
        </figure>
    );
};

export default Coach;