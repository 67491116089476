import _ from 'lodash';
import moment from 'moment';

function events(state = [], action) {
    var index;
    switch (action.type) {
        case 'EVENTS/SET':
            action.events && action.events.forEach((g) => {
                g.GameDate = moment((g.GameDate || g.DateTimeStart).split('T')[0] + ' ' + (g.GameStartHour || g.DateTimeStart_Time_Format), 'YYYY-MM-DD hmm');
                g.GameStartHour = moment(g.GameStartHour || g.DateTimeStart_Time_Format, 'hmm');
            });
            return [...action.events];
        case 'EVENTS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default events;
