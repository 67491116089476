import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert, Jumbotron } from 'reactstrap';
import './schedule_master_view.css';
import GamesTable from './GamesTable';
import moment from 'moment';
import _ from 'lodash';
import config from '../../../../config';
import request from 'superagent';
import GameDetails from '../../../common/modals/GameDetails';
import GameCardModal from '../../../common/modals/GameCard';
import Navigation from '../navigation';
import UnflightedGamesTable from './UnflightedGamesTable';
import FriendlyGame from '../../../common/modals/FriendlyGameDetails';

class ScheduleMasterView extends React.Component {

    state = {
        modal: false,
        deleting: false,
        microTransac: false,
        bootstrapped: false,
        fields: null,
        newGame: null,
        selectedFlight: null,
        popoverStartTimeOpen: false,
        isDivisionFilterOpen: false,
        isTeamsFilterOpen: false,
        selectedDivision: null,
        selectedTeam: null,
        selectedGame: null,
        notes: '',
        hiddenGames: null,
        homeAway: 0,
        mode: 1 // [ 1: list, 2: tiles ] <= not being used
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, params } = this.props;
        setTitle && setTitle({
            sub: 'Schedule Maste View',
            main: this.props.season.Name || null
        });

        this.props.scheduleManagerDivisionsActions &&
            this.props.scheduleManagerDivisionsActions.fetchDivisions(params.idSeason)

        this.props.fetchLocations &&
            this.props.fetchLocations(params.idSeason);

        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);

    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Schedule Master View',
                main: nextProps.season.Name || null
            });
            this.props.fetchLocations && this.props.fetchLocations(nextProps.season.IdLeague);
            this.setState({ hiddenGames: nextProps.season.IsHidden });
        }

        if (nextProps.locations &&
            nextProps.locations.length &&
            nextProps.divisions &&
            nextProps.divisions.length &&
            !this.state.fields) {
            let fields = [];

            (nextProps.locations || []).forEach(location => {
                fields = [...fields, {
                    Name: location.Name,
                    IdLocation: location.IdLocation,
                    selected: false
                }, ..._.map(location.Fields || location.fields, function (f) {
                    return {
                        Name: f.Name,
                        IdLocation: f.IdLocation,
                        selected: false,
                        child: true
                    };
                })];
            });

            // The division might have been selected on other sections of the app
            const idDivision = parseInt(localStorage.getItem("GLOBAL.SELECTED_DIVISION"), 10);
            const selectedDivision = _.find(nextProps.divisions, division => parseInt(division.IdDivision, 10) === idDivision) || _.first(nextProps.divisions);
            //

            this.setState({
                fields
            }, () => {
                this.selectDivision(selectedDivision)
            });
        }

        if (nextProps.games && !this.props.games) {
            this.setState({ bootstrapped: true });
        }
    }

    componentWillUnmount = () => {
        this.props.scheduleManagerDivisionsActions.clearDivisions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions();
        this.props.clearFlights && this.props.clearFlights();
        this.props.gamesActions && this.props.gamesActions.clearGames && this.props.gamesActions.clearGames();
    }
    //

    selectDivision = division => {
        const { params } = this.props;
        this.props.gamesActions.clearGames();

        // Treat the division to remove teams that are not in flights
        division.Teams = _.filter(division.Teams, t => _.find(division.Flights, f => f.IdFlight === t.IdFlight));

        this.setState({
            selectedDivision: division,
            selectedFlight: _.first(division.Flights),
            selectedTeam: null
        }, () => {
            this.props.fetchFlights && this.props.fetchFlights(params.idSeason, division.IdDivision);
            this.props.gamesActions && this.props.gamesActions.fetchGames(division.IdDivision);
            localStorage.setItem("GLOBAL.SELECTED_DIVISION", division.IdDivision);
        });
    }

    selectTeam = team => this.setState({ selectedTeam: team });

    // CRUD
    save = () => this.setState({ newGame: null, modal: false });

    edit = (game) => {
        const { selectedDivision } = this.state;
        const selectedFlight = _.find(selectedDivision.Flights, (f) => {
            return f.IdFlight === game.IdFlight;
        });

        this.setState({
            newGame: { ...game },
            selectedFlight,
            selectedGame: null,
            friendlyGame: null
        }, () => {
            this.toggleModal();
        });
    }

    edit_friendly = game => {
        this.setState({
            friendlyGame: { ...game },
            newGam: null,
        }, () => { this.toggleModal(); });
    }

    add = () => {
        const { selectedDivision } = this.state;

        this.setState({
            newGame: {
                IdDivision: selectedDivision.IdDivision,
                GameDate: moment(),
                GameStartHour: moment(900, 'hmm'),
                HomeTeam: {},
                AwayTeam: {},
                IdScheduleGameType: 1, // TODO: what is this number ?
                Location: {},
                GameDurationMinutes: 90
            },
            selectedFlight: _.first(selectedDivision.Flights),
            modal: true,
            selectedGame: null
        });
    }

    delete = () => {
        this.setState({
            deleting: false,
            modal: false,
            newGame: null
        })
    }
    //

    // Handlers
    handleChangeStart = (date) => {
        const { newGame } = this.state;
        newGame.StartDate = moment(date);
        this.setState({ newGame }, () => { this.toggleCalendarStart(); });
    }
    handleChangeNotes = event => this.setState({ notes: event.target.value });

    // Toggles
    toggleList = () => this.setState({ mode: 1 });
    toggleTiles = () => this.setState({ mode: 2 });
    toggleDivisionPicker = () => this.setState({ isDivisionFilterOpen: !this.state.isDivisionFilterOpen });
    toggleTeamsPicker = () => this.setState({ isTeamsFilterOpen: !this.state.isTeamsFilterOpen });
    toggleModal = () => this.setState({ modal: !this.state.modal });

    showGames = () => {
        const { season } = this.props;
        request.patch(`${config.apiEndpoint}/api/v4/games/hide/${season.IdSeason}`)
            .send({ hide: false })
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => { this.setState({ hiddenGames: false }); });
    }
    hideGames = () => {
        const { season } = this.props;
        request.patch(`${config.apiEndpoint}/api/v4/games/hide/${season.IdSeason}`)
            .send({ hide: true })
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => { this.setState({ hiddenGames: true }); });
    }
    toggleHome = () => {
        let { homeAway } = this.state;
        if (homeAway === 1) homeAway = 0;
        else homeAway = 1;
        this.setState({ homeAway });
    }
    toggleAway = () => {
        let { homeAway } = this.state;
        if (homeAway === 2) homeAway = 0;
        else homeAway = 2;
        this.setState({ homeAway });
    }

    game_card = game => {
        this.setState({ selectedGame: game, modal: true, newGame: null });
    }

    onChange = () => { }

    render() {
        const { selectedDivision, selectedTeam, mode, newGame, selectedFlight, fields,
            bootstrapped, modal, hiddenGames, homeAway, selectedGame, error, friendlyGame } = this.state,
            { divisions, flights, games = [], league, season } = this.props;

        let teams_dropdown = [];
        if (selectedDivision) {           

            _.chain(selectedDivision.Flights)
                .sortBy('FlightName')
                .value()
                .forEach(flight => {                    
                    teams_dropdown.push({ header: flight.FlightName || 'NO FLIGHT' });
                    flight.FlightedTeams.forEach(team => {
                        teams_dropdown.push(team);
                    });
                });

            /*_.chain(selectedDivision.Teams)
                .sortBy('FlightName')
                .groupBy('IdFlight')
                .each(g => {
                    if (_.find(selectedDivision.Flights, f => f.IdFlight === _.first(g).IdFlight)) {
                        teams_dropdown.push({ header: _.first(g).FlightName || 'NO FLIGHT' });
                        g.forEach(team => { teams_dropdown.push(team); });
                    }
                }).value()*/
            /*selectedDivision.GroupedTeams.forEach(g => {
                teams_dropdown.push({ header: _.first(g).FlightName || 'NO FLIGHT' });
                g.forEach(team => { teams_dropdown.push(team); });
            });*/
        }        

        let unflighted_games = _.filter(games || [], g => g.IdFlight === null);

        return (
            <section>

                <div className="w3-container mt-4">

                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                    </div>

                    <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                        <div className="w-100 black">
                            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
                            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
                        </div>
                    </div>

                    <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
                        <h5>Schedule Manager</h5>

                        <Navigation season={season} />

                        {bootstrapped && divisions && <Animated animationIn="slideInUp" className="card shadow mt-3 mb-4">
                            <div className="d-flex flex-row montserrat pt-3 px-3" style={{ maxWidth: 1285 }}>
                                <div className="black">
                                    <h1>Master-View</h1>
                                </div>
                                {games && <span className="font-20 align-self-center idented black ml-auto mr-1">Total Games: <strong>{games.length}</strong></span>}
                                {/*<input style={{ maxWidth: 300 }} placeholder="Search for a team" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />*/}
                            </div>

                            {/* FILTERS */}
                            <div className="d-flex flex-row montserrat p-3" style={{ maxWidth: 1285 }}>

                                <div className="d-flex flex-row w-100">

                                    <span key="1" className="black align-self-center margin-left margin-right">Division</span>

                                    { /* Divisions Filter */}

                                    <Dropdown className="align-self-center btn-link" isOpen={this.state.isDivisionFilterOpen} toggle={this.toggleDivisionPicker} style={{ border: '1px solid #CCC', borderRadius: 3 }}>
                                        <DropdownToggle color="default" className="w-100" caret>
                                            {selectedDivision ? (selectedDivision.Name) : 'Select a division'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem header>------- With Flight</DropdownItem>
                                            {_.filter(divisions, d => d.HasFlights).map((division, i) =>
                                                <DropdownItem active={selectedDivision && selectedDivision.IdDivision === division.IdDivision ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                                    <i className={`fas fa-plane margin-right-half`} />{division.Name}
                                                </DropdownItem>)}
                                            <DropdownItem header>------- Without Flight</DropdownItem>
                                            {_.filter(divisions, d => !d.HasFlights).map((division, i) =>
                                                <DropdownItem active={selectedDivision && selectedDivision.IdDivision === division.IdDivision ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                                    {division.Name}
                                                </DropdownItem>)}
                                        </DropdownMenu>
                                    </Dropdown>

                                    { /* Teams Filter */}
                                    {selectedDivision && <span key="3" className="black align-self-center margin-left margin-right">Teams</span>}
                                    {selectedDivision && <Dropdown className="align-self-center btn-link" key="4" isOpen={this.state.isTeamsFilterOpen} toggle={this.toggleTeamsPicker} style={{ border: '1px solid #CCC', borderRadius: 3 }}>
                                        <DropdownToggle color="default" caret>
                                            {selectedTeam ? (selectedTeam.Name || selectedTeam.TeamName) : 'All Teams'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem active={(!selectedTeam)} onClick={() => this.selectTeam(null)}>
                                                All Teams
                                            </DropdownItem>
                                            {teams_dropdown.map((team, i) => {
                                                if (team.header) return <DropdownItem className="blue" header key={i}>------------------- {team.header}</DropdownItem>
                                                else return <DropdownItem active={(selectedTeam && selectedTeam.IdTeam === team.IdTeam)} key={i} onClick={() => this.selectTeam(team)}>
                                                    {team.Name || team.TeamName} ({_.chain(games).filter((g) => {
                                                        return parseInt(g.HomeTeam.IdTeamDivision, 10) === parseInt(team.IdTeamDivision, 10) || parseInt(g.AwayTeam.IdTeamDivision, 10) === parseInt(team.IdTeamDivision, 10);
                                                    }).value().length} gms)
                                                </DropdownItem>
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>}

                                    {/* Home and Away Filters */}
                                    {selectedTeam && <div className="d-flex flex-row">
                                        <button onClick={this.toggleHome} className="btn btn-link align-self-center margin-left">
                                            <i className={`fa fa-home ${homeAway === 1 ? 'green' : 'black'}`} />
                                            {' '}
                                            <span className="font-10 black">({_.filter(games, g => parseInt(g.HomeTeam.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10)).length})</span>
                                        </button>
                                        <button onClick={this.toggleAway} className="btn btn-link align-self-center">
                                            <i className={`fa fa-road ${homeAway === 2 ? 'green' : 'black'}`} />
                                            {' '}
                                            <span className="font-10 black">({_.filter(games, g => parseInt(g.AwayTeam.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10)).length})</span>
                                        </button>
                                    </div>}


                                    <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 150 }} className="green ml-2 p-2 align-self-center block ml-auto" onClick={this.add}><i className="fa fa-plus"></i> Add Game</button>

                                    <button style={{ border: `1px solid ${hiddenGames ? 'black' : '#2cad3c'}`, borderRadius: 20, width: 200 }} className={`${hiddenGames ? 'black' : 'green'} ml-2 p-2 align-self-center block ml-2`} onClick={hiddenGames ? this.showGames : this.hideGames}><i className={`fa fa-${hiddenGames ? 'eye-slash' : 'thumbs-up'}`}></i> {hiddenGames ? 'Games Hidden' : 'Games Published'}</button>
                                </div>
                            </div>

                            {/* GAMES */}
                            {flights && games && <div className="ml-4 mr-4" key="table">
                                {bootstrapped && flights.length > 0 && _.filter(flights, f => {
                                    if (selectedTeam) return f.IdFlight === (selectedTeam.IdFlight || selectedTeam.IdScheduleFlight);
                                    return true;
                                }).map((flight, i) => <GamesTable selectedTeam={selectedTeam} flight={flight} key={i} homeAway={homeAway}
                                    selectedDivision={selectedDivision} {...this.props} mode={mode}
                                    fnEdit={this.edit} fnGameCard={this.game_card} />)}

                                {bootstrapped && selectedDivision && !flights.length &&
                                    <Jumbotron className="mb-4" key="noFlightsDetected">
                                        <h1 className="black align-self-center block text-center">Division {selectedDivision.Name} have no Flights in it.</h1>
                                    </Jumbotron>}

                                {unflighted_games && unflighted_games.length > 0 && <UnflightedGamesTable selectedTeam={selectedTeam} homeAway={homeAway}
                                    selectedDivision={selectedDivision} games={unflighted_games} mode={mode} fnEdit={this.edit_friendly} fnGameCard={this.game_card} />}
                            </div>}

                            {flights && !games && <div className="ml-4 mr-4" key="table">
                                <Jumbotron className="mb-4 card shadow" key="noFlightsDetected">
                                    <h1 className="black align-self-center block text-left">Fetching games.</h1>
                                    <h3 className="black align-self-center block text-left">Please wait.</h3>
                                </Jumbotron>
                            </div>}

                        </Animated>}
                    </div>

                </div>


                {!bootstrapped && (
                    <div className="w3-container mt-4">
                        <div className="w3-content" style={{ maxWidth: 1285 }}>
                            <Jumbotron className="mt-2 card shadow">
                                <h1 className="black block text-left">Loading Schedules</h1>
                                <h3 className="black block text-left">Please wait...</h3>
                            </Jumbotron>
                        </div>
                    </div>)}

                { /* Modal */}
                {newGame && modal && (
                    <GameDetails fnSave={this.save} fnDelete={this.delete} flight={selectedFlight} fields={fields} isOpen={modal} toggle={this.toggleModal}
                        game={newGame} division={selectedDivision} gamesActions={this.props.gamesActions} divisions={divisions} />)}
                {selectedGame && modal && (
                    <GameCardModal idGame={selectedGame.IdGame} idSeason={selectedGame.IdSeason} game={this.props.game} isOpen={modal} toggle={this.toggleModal} gamesActions={this.props.gamesActions} />
                )}
                {friendlyGame && modal && <FriendlyGame fnSave={this.save} fnDelete={this.delete} teams={this.props.teams} fields={fields} isOpen={modal} toggle={this.toggleModal}
                    game={friendlyGame} gamesActions={this.props.gamesActions} divisions={divisions} />}

            </section>
        )
    }

}

export default ScheduleMasterView;
