import React from 'react';
import { Animated } from 'react-animated-css';
import CreateUser from '../../common/modals/CreateUser';
import EmptyPayload from '../../EmptyPayload';
import Entity from './entity';
import Loader from '../../common/Loader';
import _ from 'lodash';

import './css.css';

class Profile extends React.Component {

  state = {
    bootstrapped: false,
    modal: false,
    selectedUser: null,
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle } = this.props;

    setTitle && setTitle({
      main: 'Profile',
      sub: 'Family Members'
    });
        
    this.props.fetchCarriers && this.props.fetchCarriers();
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.family && !this.state.bootstrapped) {
      this.setState({ bootstrapped: true }, () => {
        const { params } = this.props, { id } = params;
        if (id){
          let user = _.find(nextProps.family, u => parseInt(u.IdUser, 10) === parseInt(id, 10));
          if(user){
            this.selectUser(user);
          }
        }
      });
    }
  }
  //

  toggleModal = () => {
    this.setState({ modal: !this.state.modal }, () => {
      if (!this.state.modal) {
        this.setState({ selectedUser: null });
      }
    });
  }

  selectUser = (selectedUser) => {
    this.setState({ selectedUser, modal: true });
  }

  render() {
    const { family, carriers } = this.props, { bootstrapped, selectedUser, modal } = this.state;
    return (
      <section id="profile">
        <Animated animationIn="fadeInRight" animationOut="fadeOutRight" isVisible={!modal} className="right-actions d-flex flex-row-reverse" key="rightActions">
          <button className="btn btn-sm btn-success" onClick={this.toggleModal}>
            <i className="fa fa-plus margin-right-half" />Add Family Member
          </button>
        </Animated>
        {bootstrapped && family.length && <Animated animationIn="fadeIn" className="container margin-bottom">
          <ul id="roster" className="row" style={{ paddingTop: '3em' }}>
            {family && family.map && family.map((entity, i) => (
              <li onClick={() => this.selectUser(entity)} key={entity.IdUser || i} className={` col-sm-12 col-md-6 col-lg-4 hoverable pointer`} style={{ flexBasis: 'auto' }}>
                <Entity entity={entity} index={i} />
              </li>))}
          </ul>
        </Animated>}
        {bootstrapped && !family.length && <EmptyPayload message="No family? This seems to be wrong!" />}
        {!bootstrapped && <Loader message="Please wait" />}
        {modal && <CreateUser {...this.props} toggle={this.toggleModal} isOpen={modal} carriers={carriers}
          user={selectedUser || {}}   />}
      </section>)
  }
}

export default Profile;
