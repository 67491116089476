import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux-sagas/actions/leadsActions';
import Signature from '../components/externals/signature';

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch)
}

const App = connect(mapStateToProps, mapDispatchToProps)(Signature);

export default App;
