import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../helpers';

const divisionSource = {
    beginDrag(props) { return props; }
}

class CustomGroupPlacementDraggable extends React.Component {

    render() {
        const { connectDragSource, connectDragPreview, index, isUsingNextBest, placements, group } = this.props;
        const selected = isUsingNextBest ?
            (placements.indexOf(`# ${index} Next-Best`) !== -1) :
            (placements.indexOf(`# ${index} in ${group.CustomGroupName || group.Name}`) !== -1);

        return connectDragPreview(connectDragSource(
            <li className="placement-item d-flex flex-row">
                {selected && <i className="fas fa-check green margin-right-half" />}
                <span className="align-self-center font-10">{isUsingNextBest ? `# ${index} Next-Best` : `# ${index} in ${group.CustomGroupName || group.Name}`}</span>
                <i className="align-self-center ml-auto fas fa-arrows-alt font-8 blue"></i>
            </li>)
        )
    }
}

export default flow(
    DragSource(ItemTypes.CUSTOMGROUP, divisionSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }))
)(CustomGroupPlacementDraggable)
