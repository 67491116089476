function countries(state = [], action) {
    switch (action.type) {
        case 'COUNTRIES/SET':
            return action.countries;
        default:
            return state;
    }
}

export default countries;
