import React from 'react';
import './menu.css';
import { Link } from 'react-router';
import _ from 'lodash';

class SQWADMenu extends React.Component {

    state = {
        tree: null,
        lastPath: ''
    }

    render() {
        const { routes = [], family_lastname } = this.props;

        // This routine will get the leaf of the url: 
        // Example: api/v4/seasons/bola => bola
        let leafPath = _.last(_.chain(routes).filter(route => {
            return route.path;
        }).last().value().path.split('/'));

        return (<section id="left-menu" className="bg-dark-blue">

            <div className={`menu-wrapper`}>
                <Link key={88} className={`btn d-flex flex-row drop btn-of btn-block`} to={`/recruit/alpha`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon fa fa-arrow-left`} />
                    <span className="white align-self-center font-8 toUpper name">Back</span>
                </Link>
            </div>

            <div className={`menu-wrapper`}>
                <Link key={2} className={`btn d-flex flex-row drop ${leafPath === 'dashboard' ? 'btn-on' : 'btn-off'} btn-block`} to={`/sqwad/family/dashboard`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon fa fa-chart-pie`} />
                    <span className="white align-self-center font-8 toUpper name">Dashboard</span>
                </Link>
            </div>

            <div className={`menu-wrapper`}>
                <Link key={3} className={`btn d-flex flex-row drop ${leafPath === 'videos' ? 'btn-on' : 'btn-off'} btn-block`} to={`/sqwad/family/videos`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon icon-RetroVideoRecorder_icon`} />
                    <span className="white align-self-center font-8 toUpper name">Videos</span>
                </Link>
            </div>

            <div className={`menu-wrapper`}>
                <Link key={4} className={`btn d-flex flex-row drop ${leafPath === 'clips' ? 'btn-on' : 'btn-off'} btn-block`} to={`/sqwad/family/clips`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon icon-film-reel-1`} />
                    <span className="white align-self-center font-8 toUpper name">Clips</span>
                </Link>
            </div>

            <div className={`menu-wrapper`}>
                <Link key={4} className={`btn d-flex flex-row drop ${leafPath === 'highlights' ? 'btn-on' : 'btn-off'} btn-block`} to={`/sqwad/family/highlights`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon icon-ReelWheelStar_Outlines_icon`} />
                    <span className="white align-self-center font-8 toUpper name">Highlights</span>
                </Link>
            </div>

            <div className={`menu-wrapper ${false ? 'on' : 'off'}`}>
                <Link key={5} className={`btn d-flex flex-row drop btn-off btn-block`} to={`/sqwad/family/studio`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon icon-video-player-1`} />
                    <span className="white align-self-center font-8 toUpper name">SQWAD Studio</span>
                </Link>
            </div>

            <div className={`menu-wrapper ${false ? 'on' : 'off'}`}>
                <Link key={5} className={`btn d-flex flex-row drop btn-off btn-block`} to={`/sqwad/family/college_profile`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <div className="text-center" style={{ position: 'relative', width: 45, }}>
                        <div style={{ position: 'absolute' }} className="d-flex flex-row justify-content-center h-100 w-100">
                            <i className="icon-CrestOnly_icon-2 white font-20 align-self-center" />
                        </div>
                        <div style={{ position: 'absolute' }} className="d-flex flex-row justify-content-center h-100 w-100">
                            <span className="white font-10 align-self-center">{family_lastname ? family_lastname.substring(0, 1) : ''}</span>
                        </div>
                    </div>
                    <span className="white align-self-center font-8 toUpper name">College Profile</span>
                </Link>
            </div>

            <div className={`menu-wrapper ${false ? 'on' : 'off'}`}>
                <Link key={6} className={`btn d-flex flex-row drop btn-off btn-block`} to={`/sqwad/family/subscriptions`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon icon-recreational`} />
                    <span className="white align-self-center font-8 toUpper name">Subscriptions</span>
                </Link>
            </div>

        </section>)
    }
}

export default SQWADMenu;
