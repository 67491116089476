import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../../common/CustomDatePicker3';
import request from 'superagent';
import config from '../../../../../../config';

class AddAdjustmentModal extends React.Component {

  state = {
    saving: false,
    isOpenFrom: false,
    isOpenTo: false,
    from: moment(),
    to: moment().add(1, 'day'),
    plus: null,
    selected: false
  }

  // Lifecycle
  componentWillMount() {
    if (this.props.generic) {
      const { generic } = this.props;
      this.setState({
        from: generic.start,
        to: generic.end,
        selected: generic.id ? true : false,
        plus: generic.plus
      });
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.generic) {
      const { generic } = nextProps;
      this.setState({
        from: generic.start,
        to: generic.end
      });
    }
  }
  //

  toggleCalendarFrom = () => {
    this.setState({
      isOpenFrom: !this.state.isOpenFrom
    })
  }
  toggleCalendarTo = () => {
    this.setState({
      isOpenTo: !this.state.isOpenTo
    });
  }

  handleChangeFrom = (date) => {
    const { to } = this.state;
    this.setState({
      from: moment(date),
      to: moment(date).isAfter(moment(to)) ? moment(date).add(1, 'day') : moment(to) // range validation
    }, function () {
      //this.toggleCalendarFrom()
    });
  }
  handleChangeTo = (date) => {
    const { from } = this.state;
    this.setState({
      to: moment(date),
      from: moment(date).isBefore(moment(from)) ? moment(date).add(-1, 'day') : moment(from) // range validation
    }, function () {
      //this.toggleCalendarTo()
    });
  }

  handleChangeBase = (e) => {
    const { generic } = this.props;
    generic.base = (e.target.value && parseFloat(e.target.value) > 0) ? parseFloat(e.target.value) : 0;

    this.props.setGeneric && this.props.setGeneric(generic);
    this.forceUpdate(); // If I don't do this, the change wont reflect :(
  }

  save = () => {
    const { season, generic } = this.props, { saving, from, to } = this.state;
    if (saving) return;

    this.setState({ saving: true }, () => {
      request[generic.id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/paymentPlans/adjustment${generic.id ? ('/' + generic.id) : ''}`)
        .send({
          name: this.txtName.value,
          from, to, idSeason: season.IdSeason,
          amount: generic.base * (this.state.plus ? 1 : -1)
        })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then(resolve => {
          if (resolve.body.success) {
            this.props.paymentsActions &&
              this.props.paymentsActions.fetchPrograms(season.IdSeason);
            this.props.toggle && this.props.toggle();
          }
        }, reject => {
          this.setState({ saving: false });
        });
    });

  }

  togglePlus = (flag) => {
    this.setState({
      plus: flag,
      selected: true
    });
  }

  render() {
    const { isOpen, toggle, generic } = this.props,
      { from, to, saving } = this.state;

    return <Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
      <ModalHeader className="bg-purple white"><i className="fas fa-wrench margin-right-half" />{generic.id ? 'Edit' : 'New'}{' '}Adjustment Program</ModalHeader>
      <ModalBody className="container-fluid p-4">
        {generic && (
          <div className="row">

            { /* Name and Dates */}
            <div className="col-12 d-flex flex-row">
              <input className="form-control align-self-center" type="text" placeholder="Program Name" defaultValue={generic.name} ref={(i) => this.txtName = i} />
            </div>

            { /* From */}
            <div className="col-12 d-flex flex-row justify-content-center margin-top-half">
              <span className="idented-half align-self-center margin-right-half text-right" style={{ width: 100 }}>Effective from</span>
              <div className="align-self-center">
                <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={from} selectsStart
                  startDate={from} endDate={to} onChange={this.handleChangeFrom} />
              </div>
              <span className="idented-half align-self-center margin-right-half ">@ 12:01am EST</span>
            </div>

            { /* To */}
            <div className="col-12 d-flex flex-row justify-content-center margin-top-half">
              <span className=" idented-half align-self-center margin-right-half text-right" style={{ width: 100 }}>to</span>
              <div className="align-self-center">
                <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={to}
                  selectsEnd startDate={from} endDate={to} onChange={this.handleChangeTo} />
              </div>
              <span className="idented-half align-self-center margin-right-half ">@ 11:59pm EST</span>
            </div>
            <div className="col-12 d-flex flex-row justify-content-center margin-top-half">
            <span className="idented-half align-self-center margin-right-half ">Adjustment Type</span>
              <button onClick={() => this.togglePlus(false)} className={`margin-right-half btn btn-sm btn-${this.state.plus === false ? 'danger' : 'link bordered'}`}>Discount
                {' '}<i className={`fas fa-minus ${this.state.plus === false ? 'white' : 'red'}`} /></button>
              <button onClick={() => this.togglePlus(true)} className={`btn btn-sm btn-${this.state.plus === true ? 'success' : 'link bordered'}`}>Fee
                {' '}<i className={`fas fa-plus ${this.state.plus === true ? 'white' : 'green'}`} /></button>
            </div>

            {this.state.selected && <div className="col-12 d-flex flex-row margin-top-half justify-content-center">
              <span className=" align-self-center idented-half">Amount</span>
              <div className="input-group idented-half" style={{ width: 180 }}>
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <div className="input-group-prepend">
                  <span className={`input-group-text bg-${this.state.plus ? 'success' : 'danger'}`}><i className={`fas fa-${this.state.plus ? 'plus' : 'minus'}`} /></span>
                </div>
                <input onChange={this.handleChangeBase} defaultValue={generic.base} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
              </div>
            </div>}
          </div>
        )}
      </ModalBody>
      <ModalFooter className="">
        {!saving && <button className="btn btn-link" onClick={toggle}>Cancel</button>}
        <button style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} className="green pl-3 pr-3" onClick={this.save}>{saving ? 'Saving, please wait...' : 'Save adjustment'}</button>
      </ModalFooter>
    </Modal>
  }
}

AddAdjustmentModal.defaultProps = {
  generic: {
    start: moment(),
    end: moment().add(1, 'month'),
    base: 0,
    fee: 0,
    initial: 0,
    late: 0,
    usePP: false
  },
}

export default AddAdjustmentModal;