import React from 'react';

const BlueishHeader = (props) => {
  return (
    <div className="d-flex justify-content-center bg-info p-2" style={ props.style }>
      <span className="white align-self-center block text-center">{ props.message }</span>
    </div>
    );

};

export default BlueishHeader;