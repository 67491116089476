import React from 'react';
import './menu.css';
import paths from '../../ioc/upgradesPaths';
import { Link } from 'react-router';
import _ from 'lodash';

class Comp extends React.Component {

    state = {
        tree: null,
        lastPath: '',
        selectedTeam: null,
        selectedUser: null
    }

    handlePath = () => {
        if (this.props.routes && this.props.routes.length) {
            var leafPath = _.last(_.chain(this.props.routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'));

            Object.keys(paths).forEach(function (key) {
                var path = paths[key];

                if (path.sub_nav) {
                    var subFound = path.sub_nav[leafPath];
                    if (subFound) {
                        path.on = true;
                        subFound.on = true;
                    } else {
                        path.on = false;
                    }
                } else {
                    path.on = (key === leafPath);
                }
            });
        }

        localStorage.setItem('sportslogic.upgradesMenuState', JSON.stringify(paths));
    }

    // Lifecycle
    componentWillMount = () => {
        this.handlePath();

        // Determine which of the 'paths' should be 'on'
        this.setState({
            tree: paths,
            leafPath: _.last(_.chain(this.props.routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'))
        });
    }

    componentWillReceiveProps = (nextProps) => {
        this.handlePath();
        this.setState({
            tree: paths,
            leafPath: _.last(_.chain(this.props.routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'))
        });
    }
    //

    openMenu = (key) => {

        const { tree } = this.state,
            leaf = tree[key];

        Object.keys(tree).forEach(k => {
            if (k !== key) {
                tree[k].on = false;
            }
        })
        leaf.on = !leaf.on;
        this.setState({ tree });
        localStorage.setItem('sportslogic.upgradesMenuState', JSON.stringify(tree));
    }

    closeAllMenu = () => {
        const { tree } = this.state;

        Object.keys(tree).forEach(k => {
            tree[k].on = false;
        });
    }

    renderButton = (key) => {

        const { tree } = this.state,
            path = tree[key];

        if (path.sub_nav) {
            return (
                <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'}`}>
                    <button className={`btn d-flex flex-row drop btn-${path.on ? 'on' : 'off'} btn-block`} onClick={() => this.openMenu(key)}>
                        <i className={` align-self-center big-font icon fa fa-${path.icon}`} />
                        <span className=" align-self-center font-8 toUpper name">{path.name}</span>
                        <i className={`align-self-center fa fa-caret-${path.on ? 'up' : 'down'} ml-auto`} />
                    </button>
                    <div className="sub-menu-wrapper">
                        {Object.keys(path.sub_nav).map((subKey) => {
                            return this.renderSubButton(key, subKey);
                        })}
                    </div>
                </div>
            );
        } else {

            return (
                <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'}`}>
                    <Link className={`btn d-flex flex-row drop btn-off btn-block`} to={`${path.to}`} activeClassName="btn-on" onlyActiveOnIndex={true} onClick={() => this.closeAllMenu()}>
                        <i className={` align-self-center big-font icon fa fa-${path.icon}`} />
                        <span className=" align-self-center font-8 toUpper name">{path.name}</span>
                    </Link>
                </div>
            );
        }

    }

    renderSubButton = (key, subKey) => {

        const { tree } = this.state,
            path = tree[key],
            subPath = path.sub_nav[subKey];

        if (subPath) {
            return (
                <Link key={subKey} to={`/${key}/${subPath.path || ''}`} className="btn btn-link d-flex flex-row" activeStyle={{ backgroundColor: subPath.path ? '#cccccc' : 'transparent', borderTop: subPath.path ? '1px solid #ccc' : '0px', borderBottom: subPath.path ? '1px solid #ccc' : '0px' }}>
                    <i className={` align-self-center big-font icon ${subPath.color || 'black'} fa fa-${subPath.icon}`} />
                    <span className=" align-self-center font-8 toUpper black name">{subPath.name}</span>
                </Link>
            )
        }
    }

    render() {
        return (<section id="left-menu">
            <div key="home" className={`menu-wrapper off`}>
                <Link className={`btn d-flex flex-row drop btn-off btn-block`} to={`/family`} activeClassName="btn-on" onlyActiveOnIndex={true}>
                    <i className={` align-self-center big-font icon fa fa-arrow-left`} />
                    <span className=" align-self-center font-8 toUpper name">Home</span>
                </Link>
            </div>
            {Object.keys(paths)
                .map(this.renderButton)}
        </section>)
    }
}

export default Comp;
