import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import axios from 'axios';
import _ from 'lodash';
import request from 'superagent';
import { generateRandomId } from '../../helpers';

export function* uploadVideoSaga() {
    yield* takeEvery("VIDEO/UPLOAD", function* (action) {

        try {
            yield call(({ args }) => {

                // IDEA: save the newborn file in the store (assign an ID)
                // then the upload progress updates the store by id
                const { actualFile, progress, cancelTokenSource, idTeam, id, videoActions, admin } = args;

                videoActions.addFile({
                    progress: 0, id,
                    total: progress.loaded,
                    actualFile,
                    cancelTokenSource
                })

                const formData = new FormData();
                const fileName = `${generateRandomId(20)}.${_.last(actualFile.name.split('.'))}`;

                // Update the formData object 
                formData.append("the_file", actualFile, fileName);
                formData.append('file_name', fileName);
                formData.append('admin', admin)

                axios.post(`${config.wowzaEndpoint}/api/v4/video/${idTeam}`, formData, {
                    headers: { 'auth_token': localStorage.getItem('sportslogic.authtoken') },
                    cancelToken: cancelTokenSource.token,
                    onUploadProgress: (progress) => {
                        videoActions.updateFile({
                            progress: Math.round(progress.loaded * 100 / progress.total),
                            id,
                            total: progress.loaded,
                            actualFile,
                            cancelTokenSource
                        });
                    }
                }).then(({ data }) => {
                    videoActions.updateFile({
                        progress: 100,
                        id,
                        total: progress.loaded,
                        actualFile,
                        cancelTokenSource
                    });
                    videoActions.fetchVideos(idTeam);
                });

            }, action);


        } catch (e) {
            put({ type: 'WAITLISTS/FETCH_FAILED', e });
        }
    });
}

//EXTERNAL/HIGHLIGHT/UPLOAD
export function* uploadExternalLinkSaga() {
    yield* takeEvery("EXTERNAL/HIGHLIGHT/UPLOAD", function* (action) {

        try {
            yield call(({ name, link }) => {
                return request.post(`${config.apiEndpoint}/api/v4/video/external`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .send({
                        name, link
                    })
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            // IDEA: save the newborn file in the store (assign an ID)
            // then the upload progress updates the store by id
            const { idTeam, videoActions } = action;
            videoActions.fetchVideos(idTeam);
        } catch (e) {
            yield put({
                type: 'EXTERNAL/HIGHLIGHT/UPLOAD_FAILED',
                e
            });
        }
    });
}