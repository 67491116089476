import React from 'react';
import { formatPrice } from '../../../../helpers';
import moment from 'moment';


const TournamentList = ({ questions, report }) => {

  return (
    <table id="table-to-xls" className="table table-sm font-10">
      <thead>
        <tr>
          <th>Id Team</th>
          <th>Team Name</th>
          <th>Id Div.</th>
          <th>Team Gender</th>
          <th>Division</th>
          <th>Reg. Date</th>
          <th>Status</th>
          <th>Transaction ID</th>
          <th>Charges</th>
          <th>Payments</th>
          <th>Balance</th>
          <th>Refund</th>
          <th>Coupon Used</th>
          <th>Amount per coupon</th>
          <th>Admin First</th>
          <th>Admin Last</th>
          <th>Admin Gender</th>
          <th>Role</th>
          <th>Admin Email</th>
          <th>Admin Phone</th>
          <th>Contact First</th>
          <th>Contact Last</th>
          <th>Contact Gender</th>
          <th>Contact Email</th>
          <th>Contact Phone</th>
          <th>Address</th>
          <th>City</th>
          <th>State</th>
          <th>ZipCode</th>
          {questions && questions.map((q, i) => (
            <th key={i}>
              {q.QuestionText}
            </th>))}
        </tr>
      </thead>
      <tbody>
        {report && report.map((record, i) => (
          <tr key={i}>
            <td className="font-8">
              {record.IdTeam}
            </td>
            <td>
              {record.TeamName}
            </td>
            <td>{record.IdTeamDivision}</td>
            <td>
              {record.TeamGender}
            </td>
            <td>
              {record.DivisionName}
            </td>
            <td>
              {moment(record.RegistrationDate).format('MM/DD/YYYY')}
            </td>
            <td className={record.RoleStatus}>
              {record.RoleStatus}
            </td>
            <td>{record.TransactionId || record.ExternaId || record.IdTransaction}</td>
            <td align="right">
              {formatPrice(record.Charges * 100)}
            </td>
            <td align="right">
              {formatPrice(record.Payments * 100)}
            </td>
            <td align="right">
              {formatPrice(record.Balance * 100)}
            </td>
            <td align="right">
              {formatPrice((record.Refund || 0) * 100)}
            </td>
            <td>
              {record.CouponName}
            </td>
            <td align="right">
              {formatPrice((record.AmountPerCoupon || 0) * 100)}
            </td>
            <td>
              {record.NameFirst}
            </td>
            <td>
              {record.NameLast}
            </td>
            <td>
              {record.Gender}
            </td>
            <td>
              {record.RoleOverride}
            </td>
            <td>
              {record.EmailAddress}
            </td>
            <td>
              {record.PhoneNumber}
            </td>
            <td>
              {record.ContactNameFirst}
            </td>
            <td>
              {record.ContactNameLast}
            </td>
            <td>
              {record.ContactGender}
            </td>

            <td>
              {record.ContactEmailAddress}
            </td>
            <td>
              {record.ContactPhoneNumber}
            </td>
            <td>
              {record.ContactAddress}
            </td>
            <td>{record.ContactCity}</td>
            <td>{record.ContactState}</td>
            <td>{record.ContactZipCode}</td>
            {questions && questions.map((q, i) => (
              <td key={i}>
                {record[`question-${q.IdQuestion}`]}
              </td>))}
          </tr>)
        )}
      </tbody>
    </table>
  );
}

export default TournamentList;
