import React from 'react';
import UpperList from './upper';
import LowerList from './lower';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ItemTypes } from '../../../../../helpers';
import LineLoader from '../../../../common/LineLoader';

class List extends React.Component {

  state = {
    players: null
  }  

  render() {

    const accept = [ItemTypes.NONROSTEREDPLAYER, ItemTypes.ROSTEREDPLAYER],
      { waitlists, accepted } = this.props;          

    return (
      <section style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        {this.props.fetchingTryouts && <LineLoader />}
        { /* Top List */}
        <UpperList {...this.props} accept={accept} items={accepted} />
        { /* Bottom List */}
        <LowerList {...this.props} accept={accept} items={waitlists} total={0} />
      </section>
    )
  }
}

export default DragDropContext(HTML5Backend)(List);