import React from 'react';
import { DropTarget } from 'react-dnd';
import ListItem from './RosteredPlayer';
import { ItemTypes } from '../../../../../helpers';
import _ from 'lodash';

const target = {
  canDrop(props, monitor) {
    const { selectedTeam } = props,
      { item } = monitor.getItem();
    return (item.IdTeamDivision !== selectedTeam.IdTeamDivision) ? true : false;
  },
  drop(props, monitor, /*component*/) {

    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }

    props.fnDrop && props.fnDrop(monitor.getItem().item, monitor.getItem().index);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class UpperList extends React.Component {

  render() {

    const { connectDropTarget, isOver, canDrop, items } = this.props;

    let unique = _.chain(items)
      .groupBy('IdTeamUserRole')
      .values().value();

    let players = _.chain(items)
      .filter(u => u.IdRole === 3)
      .groupBy('IdTeamUserRole')
      .values()
      .sortBy(([t]) => t.NameLast).value(),
      coaches = _.chain(items)
        .filter(u => u.IdRole === 4)
        .groupBy('IdTeamUserRole')
        .values()
        .sortBy(([t]) => t.NameLast).value();

    return connectDropTarget(
      <figure style={{ borderTop: '20px solid gray', position: 'absolute', top: 0, bottom: '50%', left: 0, right: 0, border: null }} className={`bg-white m-0 ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}`}>
        {unique && <div className={`d-flex bg-${canDrop ? 'danger' : 'blue-dark'} px-3 d-flex flex-row white`} style={{ position: 'absolute', top: 0, height: 50, width: '100%', borderStartEndRadius: 10, borderStartStartRadius: 10 }}>
          <span className="align-self-center">Team Roster</span>
          <span className="align-self-center ml-2">({players.length} athletes - {coaches.length} coaches)</span>
          {canDrop && <i className="ml-auto fas fa-arrow-down white font-20 align-self-center" />}
        </div>}
        <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={{ position: 'absolute', top: 50, bottom: 0, overflow: 'auto' }}>
          <tbody className="w-100" style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th></th>
              <th>Id</th>
              <th>Full Name</th>
              <th>Status</th>
              <th>Role</th>
              <th>DoB</th>
              <th>Current Team</th>
              <th>Gender</th>
              <th>Contact</th>
              <th>PCR</th>
              <th>City</th>
              <th>State</th>
              <th>Balance</th>
              <th>Paid</th>
              {this.props.questions && this.props.questions.map((q, i) => (
                <th key={i}>
                  {q.QuestionText}
                </th>))}
            </tr>
          
            {coaches && coaches.map(([item], index) => (
              <ListItem {...this.props} key={index} item={item} index={index} />
            ))}
            {players && players.map(([item], index) => (
              <ListItem {...this.props} key={index} item={item} index={index} />
            ))}
          </tbody >
        </table>
      </figure>

    )
  }
}

export default DropTarget(ItemTypes.NONROSTEREDPLAYER, target, collect)(UpperList);
