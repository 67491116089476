import _ from 'lodash';

function reducer(state = [], action) {
  let index = 0;
  switch (action.type) {
    case 'PHONES/SET':
      return action.phones;
    case 'PHONES/CLEAR':
      return null;
    case 'PHONES/ADD':
      return [...state, action.phone];
    case 'PHONES/SAVE':
      index = _.findIndex(state, phone => phone.temporalId === action.phone.temporalId);
      if (index !== -1)
        return [...state.slice(0, index), action.phone, ...state.slice(index + 1)];
      else return state;
    case 'PHONES/UPDATE':
      index = _.findIndex(state, phone => phone.IdPhoneNumber === action.phone.IdPhoneNumber);
      if (index !== -1)
        return [...state.slice(0, index), _.extend(action.phone, {
          OriginalIdPhoneNumber: action.phone.IdPhoneNumber,
          IdPhoneNumber: action.newId
        }), ...state.slice(index + 1)];
      else return state;
    case 'PHONES/DELETE':
      index = _.findIndex(state, phone => phone.IdPhoneNumber === action.idPhoneNumber);
      if (index !== -1)
        return [...state.slice(0, index), ...state.slice(index + 1)];
      else return state;
    default:
      return state;
  }
}

export default reducer;