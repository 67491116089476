import React from 'react';

class DivisionGroupName extends React.Component {
    state = {
        pristine: true, division: {}
    }
    componentWillMount() {
        const { division } = this.props;
        this.setState({ division });
    }
    onSave = (e) => {
        e.preventDefault && e.preventDefault();
        this.setState({ pristine: true });        
        const { division } = this.state;
        this.props.divisionsActions && this.props.divisionsActions.setDivisionGroupName(division.IdDivision, this.txtGroupName.value, this.props.idSeason);
    }
    render() {
        const { pristine, division = {} } = this.state;
        return <div>
            <form className="d-flex flex-row w-100">
            <input disabled={true} className="form-control w-100 ml-2" type="text" defaultValue={division.DivisionName || division.Name} ref={i => this.txtName = i} onChange={() => this.setState({ pristine: false })} />
            <input placeholder='Age Group Label' className="form-control w-75 ml-2" type="text" defaultValue={division.DivisionAgeGroupName} ref={i => this.txtGroupName = i} onChange={() => this.setState({ pristine: false })} />
            <button onClick={this.onSave} type="submit" className={`btn btn-${pristine ? 'link' : 'success'}`}><i className="fas fa-check" /></button>
            </form>
        </div>
    }
}

export default DivisionGroupName;