import _ from 'lodash';

function family(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'FAMILY/SET':
            localStorage.setItem('sportslogic.family', JSON.stringify(action.family));
            return action.family;
        case 'FAMILY/CLEAR':
            return null;
        case 'MEMBERS/FAMILY/REMOVE':
            index = _.findIndex(state, user => {
                return user.IdUser === action.user.IdUser;
            });
            if (index !== -1)
                return [...state.slice(0, index), ...state.slice(index + 1)];
            else return state;
        case 'MEMBERS/FAMILY/ADD':
            return [...state, action.user];
        case 'MEMBERS/FAMILY/SET_ID':
            index = _.findIndex(state, user => { return user.temporalId === action.user.temporalId; });
            if (index !== -1)
                return [...state.slice(0, index), action.user, ...state.slice(index + 1)];
            else return state;
        case 'MEMBERS/FAMILY/UPDATE':
            index = _.findIndex(state, user => {
                return user.IdUser === action.user.IdUser;
            });
            if (index !== -1)
                return [...state.slice(0, index), action.user, ...state.slice(index + 1)];
            else return state;
        default:
            return state;
    }
}

export default family;
