import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import Team from '../partials/Team';

class AdminSelection extends React.Component {
    state = {
        family: [],
        coachRoles: [
            {
                Id: 4,
                Name: 'Coach',
                selected: true
            },
            {
                Id: 4,
                Name: 'Assistant Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Team Manager',
                selected: false
            },
        ]
    }

    componentWillMount() {
        const { family } = this.props;
        // Clear
        family.forEach(u => u.selected = false);
        this.setState({ family: [...family] });
    }
    selectUser = (idUser) => {
        const { family } = this.state;
        family.forEach(f => f.selected = f.IdUser === idUser);
        this.setState({ family });
    }
    onOk = () => {
        const { family } = this.state;
        let selectedUser = _.find(family, f => f.selected);
        if (selectedUser) {
            this.props.onOk && this.props.onOk({
                selectedUser, mode: 3
            });
        }
    }
    render() {
        const { team } = this.props, { family } = this.state;
        return <div className="p-4 h-100 montserrat">

            <div className="pointer tile card shadow-box d-flex flex-column justify-content-center m-auto h-auto">
                <Team selectedTeam={team} />                
            </div>

            <Animated animationIn="fadeInRight" className="d-flex flex-column mt-4">

                {family.length > 0 && <section className="d-flex flex-column">

                    {family.map((user, i) => (
                        <div key={i} className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-2 ${user.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}
                            onClick={() => this.selectUser(user.IdUser)}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <span className="align-self-center font-12">{user.NameFirst} {user.NameLast}</span>
                            <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                        </div>))}

                </section>}

                <button onClick={this.onOk} className={`btn btn-${_.find(family, u => u.selected) ? 'success' : 'default'} mt-4`}>Continue</button>

                <div style={{ height: 100 }} />

            </Animated>
        </div>
    }
}

export default AdminSelection;
