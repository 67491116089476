import React from 'react';
import moment from 'moment';
import fakeAuth from '../../../fakeAuth';

class UserNote extends React.Component {

    state = {
        editing: false
    }

    componentWillMount() {
        const { note } = this.props;
        this.setState({ note });
    }

    onColor = color => {
        const { note } = this.state;
        note.Color = color;
        this.setState({ note });
    }

    onSave = () => {
        const { note } = this.state, { leagueActions } = this.props;
        // TODO: call action        
        note.Notes = this.txt.value;
        note.DateModified = moment().format('YYYY-MM-DD');
        note.NameFirst = fakeAuth.getUser().NameFirst;
        note.NameLast = fakeAuth.getUser().NameLast;
        note.editing = false;
        leagueActions && leagueActions.addUserNote(note);
        this.setState({ note });
    }

    onDelete = () => {
        const { note } = this.state;
        note.deleting = !note.deleting;
        this.setState({ note });
    }

    renderNormal = () => {
        const { note } = this.props;
        return <div className="card shadow p-4 w-100 m-3 d-flex flex-column" style={{ position: 'relative' }}>
            <i className={`fas fa-bookmark font-16 ${note.Color}`} style={{ position: 'absolute', top: -10, left: 20 }} />
            <div className="d-flex flex-row">
                <button className="ml-auto" onClick={() => this.onDelete()}>
                    <i className="fas fa-times font-12 black" />
                </button>
            </div>
            <div className='h-100 mb-3 mt-2'><span className="font-14 black">{note.Notes}</span></div>
            <div className="d-flex flex-row mt-auto">
                <span className="gray font-10">{note.NameFirst} {note.NameLast}</span>
                <span className="gray font-10 ml-auto">{moment(note.DateModified.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</span>
            </div>
        </div>
    }



    renderEditing = () => {
        const { note, fnDelete } = this.props;
        return <div className="card shadow p-4 w-100 m-3 d-flex flex-column" style={{ position: 'relative' }}>
            <div className="d-flex flex-row">
                <button className="ml-auto" onClick={() => fnDelete(note)}>
                    <i className="fas fa-times font-12 black" />
                </button>
            </div>
            <div className='h-100 mb-3 mt-2'>
                <textarea
                    className="bordered p-2 w-100"
                    rows="10"
                    ref={i => this.txt = i}
                />
            </div>
            <div className="d-flex flex-row">
                <button onClick={() => this.onColor('red')} className={`btn btn-link w-100 ${note.Color === 'red' ? 'bordered' : ''}`}><i className="fas fa-bookmark red font-16" /></button>
                <button onClick={() => this.onColor('blue')} className={`btn btn-link w-100 ${note.Color === 'blue' ? 'bordered' : ''}`}><i className="fas fa-bookmark blue font-16" /></button>
                <button onClick={() => this.onColor('yellow')} className={`btn btn-link w-100 ${note.Color === 'yellow' ? 'bordered' : ''}`}><i className="fas fa-bookmark yellow font-16" /></button>
                <button onClick={() => this.onColor('green')} className={`btn btn-link w-100 ${note.Color === 'green' ? 'bordered' : ''}`}><i className="fas fa-bookmark green font-16" /></button>
            </div>
            <div className="d-flex flex-row mt-2">
                <button className="btn btn-link red" onClick={() => fnDelete(note)}>Cancel</button>
                <button onClick={this.onSave} style={{ width: 'auto', border: '2px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center ml-auto font-10 px-3">Save Note</button>
            </div>
        </div>
    }

    renderDeleting = () => {
        const { note, fnDelete } = this.props;
        return <div className="card shadow p-4 w-100 m-3 d-flex flex-column" style={{ position: 'relative' }}>
            <i className="fas fa-bookmark red font-16" style={{ position: 'absolute', top: -10, left: 20 }} />
            <div className="d-flex flex-row">
                <i className="fas fa-times font-12 ml-auto black" />
            </div>
            <div className='h-100 mb-3 d-flex flex-column'>
                <div style={{ height: 50 }} />
                <span className='black align-self-center'>Are you sure?</span>
                <button onClick={() => fnDelete(note)} className="btn btn-danger">Yes, delete it</button>
                <button onClick={() => this.onDelete()} className="btn btn-link red">No, cancel</button>
                <div style={{ height: 50 }} />
            </div>            
        </div>
    }

    render() {
        const { note } = this.state;
        if (note.LocalId === undefined) return <div className="p-4 w-100 m-3 d-flex flex-column" />
        if (note.deleting) return this.renderDeleting();
        if (note.editing) return this.renderEditing();
        if (!note.editing) return this.renderNormal();
    }
}

export default UserNote;