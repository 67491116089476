import React from 'react';
import { Link } from 'react-router';
import './nav.css';

class Nav extends React.Component {

    render() {
        const {currentDetails, backPath} = this.props;
        return (
            <nav className="d-flex flex-row align-items-center">
              <Link to="/family" className="nav-home-button d-flex flex-row align-items-center justify-content-center">
              <i className="fa fa-home white" />
              </Link>
              { currentDetails && <Link to={ backPath || '/leagues' } className="btn btn-link white nav-back-button"><i className="fa fa-caret-left"></i> Back</Link> }
            </nav>
        )
    }
}

export default Nav;