import React from 'react';
import DivisionListItem from './DivisionListItem';
import _ from 'lodash';

class DivisionsFilter extends React.Component {

    checkAll = () => {
        const { divisions } = this.props;
        divisions.forEach((division) => {
            division.checked = true;
            _.chain(division.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value().forEach((team) => {
                team.checked = true;
            });
        });
        this.props.notify && this.props.notify();
    }

    uncheckAll = () => {
        const { divisions } = this.props;
        divisions.forEach((division) => {
            division.checked = false;
            _.chain(division.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value().forEach((team) => {
                team.checked = false;
            });
        });
        this.props.notify && this.props.notify();
    }

    render() {
        const { divisions, games } = this.props;
        return (
            <div key="divisions" className="scrollable" style={{ top: '55px' }}>
                <div className="d-flex flex-row">
                    <button className={`btn btn-sm btn-info d-flex flex-row w-100 justify-content-center`} onClick={this.uncheckAll}>
                        <i className={`fas fa-times align-self-center`} />
                        <span className="margin-left align-self-center">Clear Team Filters</span></button>
                </div>
                <ul className=" no-style-lists no-margin no-padding ">
                    {divisions.map((division, i) => <DivisionListItem division={division} divisions={divisions}
                        games={games} key={i} index={i} notify={this.props.notify} />)}
                </ul>
            </div>
        );
    }
}

export default DivisionsFilter;
