function couponTypes(state = [], action) {
    switch (action.type) {
        case 'COUPON_TYPES/SET':
            return action.couponTypes;
        default:
            return state;
    }
}

export default couponTypes;
