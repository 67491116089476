import React from 'react';
import { Link } from 'react-router';
import Navigation from '../navigation';
import { Animated } from 'react-animated-css';
import COLORS from '../../../../colors';
import Pairings from './partials/pairings';
import Automatic from './partials/automatic';
import Summary from './partials/summary';
import { Alert, Jumbotron } from 'reactstrap';
import _ from 'lodash';

class BuildTypes extends React.Component {

    state = {
        mode: 1
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, params, } = this.props;
        setTitle && setTitle({
            sub: 'Schedule Manager',
            main: this.props.season.Name || null
        });
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
        this.props.scheduleManagerDivisionsActions &&
            this.props.scheduleManagerDivisionsActions.fetchDivisions(params.idSeason)
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Schedule Manager',
                main: nextProps.season.Name || null
            });
        }

        if (nextProps.divisions) {
            this.setState({
                divisionsWithFlights: _.filter(nextProps.divisions, d => d.Flights.length > 0),
                divisionsWithoutFlights: _.filter(nextProps.divisions, d => d.Flights.length === 0)
                , bootstrapped: true
            });
        }
    }
    //

    nope = (message) => {
        this.setState({ error: message });
    }

    render() {
        const { league, season } = this.props, { mode, error, updated } = this.state;

        return (
            <section>

                <div style={{ position: 'fixed', zIndex: 1051, right: 50 }} className="w3-content">
                    <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                        {this.state.error}
                    </Alert>
                    <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
                        Season updated!
                    </Alert>
                </div>

                <div className="w3-container mt-4">
                    {this.props.season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                        <div className="w-100 black">
                            <h3>{this.props.season.Name || this.props.season.SeasonName}</h3>
                            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
                        </div>
                    </div>}
                    <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
                        <h5>Schedule Management</h5>
                        {this.props.season && <Navigation season={this.props.season} />}

                        {(!season || !season.IdSeason) && <Jumbotron className="mt-4 card shadow">
                            <h1 className="margin-top-half">Gathering resources</h1>
                            <h4>Please wait....</h4>
                        </Jumbotron>
                        }
                    </div>

                </div>

                <Animated animationIn="slideInRight" animationInDuration={300} animateOnMount={false} isVisible={(season && season.IdSeason) ? true : false}
                    className="montserrat" id="user-modules">
                    <div className={`d-flex justify-content-center card shadow w3-content mt-3 px-4 py-${mode === 1 ? 4 : 3}`} style={{ maxWidth: 1285 }}>

                        {mode === 1 && <span className="align-self-center mb-4">Select <strong>what type of build</strong> do you want to perform</span>}

                        {mode === 1 && season && season.IdSeason && <ul className="white align-self-center text-center d-flex flex-wrap">
                            <li className="tile bg-white card shadow-box mx-2" style={{ borderRadius: 6, height: 150, width: 150 }}>
                                <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                                    <button onClick={() => this.setState({ mode: 2 })} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                        <i className="fas fa-hand-paper align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                        <span className=" align-self-center margin-top-half">Pairings</span>
                                    </button>
                                </Animated>
                            </li>
                            <li className="tile bg-white card shadow-box mx-2" style={{ borderRadius: 6, height: 150, width: 150 }}>
                                <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                                    <button onClick={() => this.setState({ mode: 3 })} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                        <i className="fa fa-cogs fas align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                        <span className=" align-self-center margin-top-half">Automatic</span>
                                    </button>
                                </Animated>
                            </li>
                            <li className="tile bg-white card shadow-box mx-2" style={{ borderRadius: 6, height: 150, width: 150 }}>
                                <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                                    <button onClick={() => this.setState({ mode: 4 })} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                        <i className="fa fa-list fas align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                        <span className=" align-self-center margin-top-half">Summary</span>
                                    </button>
                                </Animated>
                            </li>
                        </ul>}

                        {mode !== 1 && <div className="p-0 d-flex flex-row">

                            {mode === 2 && <i className="fas fa-circle mr-2 align-self-center" style={{ color: COLORS.male }} />}
                            {mode === 2 && <span className="align-self-center mr-2">Male</span>}
                            {mode === 2 && <i className="fas fa-circle mr-2 align-self-center" style={{ color: COLORS.female }} />}
                            {mode === 2 && <span className="align-self-center mr-2">Female</span>}
                            {mode === 2 && <i className="fas fa-circle mr-2 align-self-center" style={{ color: COLORS.coed }} />}
                            {mode === 2 && <span className="align-self-center mr-2">COED</span>}

                            <button style={{ width: 60, color: mode === 2 ? COLORS.white : COLORS.jeff_blue, backgroundColor: mode === 2 ? COLORS.jeff_blue : COLORS.transparent }} onClick={() => this.setState({ mode: 2 })} className="card ml-auto align-self-center justify-content-center d-flex flex-row p-2">
                                <i className="fas fa-hand-paper fas align-self-center font-14" />
                            </button>

                            <button style={{ width: 60, color: mode === 3 ? COLORS.white : COLORS.jeff_blue, backgroundColor: mode === 3 ? COLORS.jeff_blue : COLORS.transparent }} onClick={() => this.setState({ mode: 3 })} className="card ml-2 align-self-center justify-content-center d-flex flex-row p-2">
                                <i className="fa fa-cogs fas align-self-center font-14" />
                            </button>

                            <button style={{ width: 60, color: mode === 4 ? COLORS.white : COLORS.jeff_blue, backgroundColor: mode === 4 ? COLORS.jeff_blue : COLORS.transparent }} onClick={() => this.setState({ mode: 4 })} className="card ml-2 align-self-center justify-content-center d-flex flex-row p-2">
                                <i className="fa fa-list fas align-self-center font-14" />
                            </button>

                        </div>}
                    </div>

                    {mode === 2 && <Animated isVisible={mode === 2} animateOnMount={true} animationIn="fadeInRight" animationOut="fadeOutRight" className="d-flex justify-content-center card shadow w3-content mt-3 p-4" style={{ maxWidth: 1285 }}>
                        {this.props.divisions && <Pairings {...this.props} nope={this.nope} onSummary={() => this.setState({ mode: 4 })} />}
                    </Animated>}

                    {mode === 3 && <Animated isVisible={mode === 3} animateOnMount={true} animationIn="fadeInRight" animationOut="fadeOutRight" className="d-flex justify-content-center card shadow w3-content mt-3" style={{ maxWidth: 1285 }}>
                        <Automatic {...this.props} nope={this.nope} onSummary={() => this.setState({ mode: 4 })} />
                    </Animated>}

                    {mode === 4 && <Animated isVisible={mode === 4} animateOnMount={true} animationIn="fadeInRight"
                        animationOut="fadeOutRight" className="d-flex justify-content-center card shadow w3-content mt-3"
                        style={{ maxWidth: 1285 }}>
                        <Summary {...this.props} nope={this.nope} />
                    </Animated>}

                    <div style={{ height: 100 }} />
                </Animated>


            </section>
        )
    }
}

export default BuildTypes;
