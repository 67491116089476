import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actionCreators from '../redux-sagas/actions/actionCreators.js';
import * as crossFlightRulesActions from '../redux-sagas/actions/schedule_manager/crossFlightRulesActions';
import * as customGroupsActions from '../redux-sagas/actions/customGroupsActions';
import * as divisionsActions from '../redux-sagas/actions/divisionsActions';
import * as exceptionRulesActions from '../redux-sagas/actions/schedule_manager/exceptionRulesActions';
import * as fieldAvailabilityRulesActions from '../redux-sagas/actions/schedule_manager/fieldAvailabilityRulesActions';
import * as flightsActions from '../redux-sagas/actions/flightsActions';
import * as gameDayRulesActions from '../redux-sagas/actions/schedule_manager/gameDayRulesActions';
import * as gamesActions from '../redux-sagas/actions/gamesActions';
import * as genericActions from '../redux-sagas/actions/genericActions';
import * as geoActions from '../redux-sagas/actions/geoActions';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as locationsActions from '../redux-sagas/actions/locationsActions';
import * as locksActions from '../redux-sagas/actions/locksActions';
import * as paymentsActions from '../redux-sagas/actions/paymentsActions';
import * as pouchActions from '../redux-sagas/actions/pouchActions';
import * as questionsActions from '../redux-sagas/actions/questionsActions';
import * as reportsActions from '../redux-sagas/actions/reportsActions';
import * as rosterActions from '../redux-sagas/actions/rosterActions';
import * as scheduleManagerDivisionsActions from '../redux-sagas/actions/schedule_manager/divisionsActions';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import * as spotlightActions from '../redux-sagas/actions/sportlightActions';
import * as teamActions from '../redux-sagas/actions/teamActions';
import * as waitlistsActions from '../redux-sagas/actions/waitlistsActions';
import * as tryoutActions from '../redux-sagas/actions/tryoutActions';
import Season from '../components/Main.Season';

function mapStateToProps(state) {
    return {       
        
        accepted: state.accepted,
        adjustments: state.adjustments,
        auth: state.auth,
        answers: state.answers,
        appliedQuestions: state.appliedQuestions,
        bracket_group_types: state.bracket_group_types,
        campaigns: state.campaigns,
        campaign: state.campaign,
        coaches: state.coaches,
        crossFlightRules: state.crossFlightRules,
        contest_type: state.contest_type,
        countries: state.countries,
        coupons: state.coupons,
        customGroups: state.customGroups,
        details: state.details,
        defaultGameDuration: state.defaultGameDuration,
        divisions: state.divisions,
        disclaimer: state.disclaimer,
        dues: state.dues,
        exceptionRules: state.exceptionRules,
        familyAdjustments: state.familyAdjustments,
        fields: state.fields,
        fields_layout: state.fields_layout,
        filters: state.filters,
        fetchingData: state.fetchingData,
        fetchingSpotlight: state.fetchingSpotlight,
        fieldAvailabilityRules: state.fieldAvailabilityRules,
        flights: state.flights,
        flightedTeams: state.flightedTeams,
        gameDayRules: state.gameDayRules,
        games: state.games,
        game: state.game,
        game_types: state.game_types,
        genders: state.genders,
        generic: state.generic,
        leagues: state.leagues,
        leaguePoints: state.leaguePoints,
        library: state.library,
        locations: state.locations,
        locks: state.locks,
        loggedIn: state.loggedIn,
        medals: state.medals,
        micro: state.micro,
        notes: state.notes,
        players: state.players,
        paymentPlans: state.paymentPlans,
        positions: state.positions,
        pouch: state.pouch,
        points: state.points,
        processes: state.processes,
        questions: state.questions,
        questionGroups: state.questionGroups,
        questionTexts: state.questionTexts,
        report: state.report,
        registrations: state.registrations,
        roles: state.roles,
        scout_registrations: state.scout_registrations,
        season: state.season,
        selectedAnswers: state.selectedAnswers,
        settings: state.settings,
        sizes: state.sizes,
        slots: state.slots,
        spotlight: state.spotlight,
        spotlightOn: state.spotlightOn,
        statuses: state.statuses,
        states: state.states,
        spread: state.spread,
        teams: state.teams,
        archived_teams: state.archived_teams,
        title: state.title,
        templates: state.templates,
        tournamentPointsTypes: state.tournamentPointsTypes,
        membership_programs: state.membership_programs,
        tournamentSeasons: state.tournamentSeasons,
        tryouts: state.tryouts,
        users: state.users,
        user: state.user,
        waitlists: state.waitlists,
        zipCodes: state.zipCodes,
        validations: state.validations,
        violations: state.violations,
        violation_types: state.violation_types,
        zipCodesAdjustments: state.zipCodesAdjustments,
        validations_summary: state.validations_summary,
        //...state, 
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        divisionsActions: bindActionCreators(divisionsActions, dispatch),
        crossFlightRulesActions: bindActionCreators(crossFlightRulesActions, dispatch),
        customGroupsActions: bindActionCreators(customGroupsActions, dispatch),
        exceptionRulesActions: bindActionCreators(exceptionRulesActions, dispatch),
        fieldAvailabilityRulesActions: bindActionCreators(fieldAvailabilityRulesActions, dispatch),
        flightsActions: bindActionCreators(flightsActions, dispatch),
        gameDayRulesActions: bindActionCreators(gameDayRulesActions, dispatch),
        gamesActions: bindActionCreators(gamesActions, dispatch),
        genericActions: bindActionCreators(genericActions, dispatch),
        geoActions: bindActionCreators(geoActions, dispatch),
        leagueActions: bindActionCreators(leagueActions, dispatch),
        locationsActions: bindActionCreators(locationsActions, dispatch),
        locksActions: bindActionCreators(locksActions, dispatch),
        paymentsActions: bindActionCreators(paymentsActions, dispatch),
        pouchActions: bindActionCreators(pouchActions, dispatch),
        questionsActions: bindActionCreators(questionsActions, dispatch),
        reportsActions: bindActionCreators(reportsActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch),
        scheduleManagerDivisionsActions: bindActionCreators(scheduleManagerDivisionsActions, dispatch),
        spotlightActions: bindActionCreators(spotlightActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        tryoutActions: bindActionCreators(tryoutActions, dispatch),
        waitlistsActions: bindActionCreators(waitlistsActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Season);

export default App;
