import React from 'react';
import { formatPrice } from '../../../../helpers';
import { Popover, PopoverBody, PopoverHeader, } from 'reactstrap';
import moment from 'moment';
import fakeAuth from '../../../../fakeAuth';

class Charge extends React.Component {
    state = {
        deleting: false
    }

    toggleDelete = () => this.setState({ deleting: !this.state.deleting });
    doDelete = () => {
        const { payment, index } = this.props;
        this.setState({ deleting: false }, () => {
            this.props.fnDeletePayment(payment, index)
        });
    }

    render() {
        const { payment } = this.props;
        let faIcon = 'fa-credit-card fa';

        switch (payment.PaymentMethodName) {
            case 'Visa': faIcon = 'fa-cc-visa fab'; break;
            case 'Master Card': faIcon = 'fa-cc-mastercard fab'; break;
            case 'Discover': faIcon = 'fa-cc-discover fab'; break;
            case 'Amex': faIcon = 'fa-cc-amex fab'; break;
            case 'Cash': faIcon = 'fa-money-check-alt fa'; break;
            case 'Check': faIcon = 'fas fa-money-check-alt'; break;
            case 'Cash Received': faIcon = 'far fa-money-bill-alt'; break;
            case 'Check Received': faIcon = 'fas fa-money-check-alt'; break;
            default: break;
        }
        return (
            <div key={payment.IdOrderItemCharge} className={`d-flex flex-row w-100 ${payment.IsRefund ? 'red' : ''}`}>
                {(payment.Payment > 0 || payment.IsRefund) && <i className={`${faIcon} align-self-center bigest-font`} />}
                {!payment.IsRefund && payment.Charge < 0 && payment.Payment === 0 && <i className={`fa-minus-square red fas align-self-center font-20`} />}
                {!payment.IsRefund && payment.Charge > 0 && payment.Payment === 0 && <i className={`fa-plus-square green fas align-self-center font-20`} />}
                {!payment.IsRefund && payment.Charge === 0 && payment.Payment === 0 && <i className={`fa-cogs yellow fas align-self-center font-20`} />}
                <div className="d-flex flex-column align-self-center idented font-8">
                    <span>{payment.RecordDescription}{' '}{(payment.Charge < 0 && payment.Payment === 0) ? '(credit)' : ((payment.Charge > 0 && payment.Payment === 0) ? '(charge)' : '')}</span>
                    {payment.last4Digits > 0 && <span>**** {payment.last4Digits}</span>}
                    {(payment.Payment > 0 || payment.IsRefund) && <span>{payment.Confirmation}</span>}
                    <span>{moment(payment.DateDue).format('MMM Do YYYY @ hh:mmA')}</span>
                </div>
                {payment.CanRefund && payment.MaxRefund > 0 && !payment.IsFee &&
                    <button onClick={() => this.props.fnMakeRefund(payment)} style={{ width: 90, border: '2px solid #2cad3c', borderRadius: 20 }} className="align-self-center ml-auto green p-2">Refund</button>
                }
                {payment.CanRefund && payment.MaxRefund > 0 && payment.IsFee && fakeAuth.getUser().IdUser === 10 && <button onClick={() => this.props.fnMakeRefund(payment)} style={{ width: 120, border: '2px solid #2cad3c', borderRadius: 20 }} className="align-self-center ml-auto green p-2">Refund Fee</button>}
                <span className="align-self-center ml-auto">{formatPrice((payment.Charge || payment.Payment) * 100)}</span>
                {/* Delete */}
                {payment.CanDelete === 1 && <button id={`btn-delete-${payment.Index}`} onClick={this.toggleDelete} className="align-self-center btn btn-link btn-sm"><i className="fa fa-times red" /></button>}
                {payment.CanDelete === 1 && <Popover placement="bottom" isOpen={this.state.deleting} target={`btn-delete-${payment.Index}`} toggle={this.toggleDelete}>
                    <PopoverHeader>Are you sure?</PopoverHeader>
                    <PopoverBody className="d-flex flex-row justify-content-center">
                        <button className="btn btn-success btn-sm" onClick={this.doDelete}>Yes, Delete it</button>
                        <button className="btn btn-danger btn-sm" onClick={this.toggleDelete}>No, Cancel</button>
                    </PopoverBody>
                </Popover>}
            </div>
        );
    }
}

export default Charge;
