import React from 'react';
import DatePicker from 'react-datepicker';
import CustomTimeScroller from '../../../common/CustomTimeScroller';
import moment from 'moment';
import _ from 'lodash';
import { Popover, PopoverBody, Input } from 'reactstrap';
import { generateRandomId, ItemTypes } from '../../../../helpers';
import HomeTeamDropSection from './HomeTeamDropSection';
import AwayTeamDropSection from './AwayTeamDropSection';
import Loader from '../../../common/Loader';
import request from 'superagent';
import config from '../../../../config';

class Game extends React.Component {

  state = {
    popoverStartTimeOpen: false,
    popoverLocationOpen: false,
    editingName: false,
    randomIdStart: generateRandomId(10),
    dropAccepts: [ItemTypes.DIVISION, ItemTypes.TEAM, ItemTypes.FLIGHT, ItemTypes.BRACKETPLACEMENT, ItemTypes.CUSTOMGROUP],
    rankingOptions: [],
    game: null
  }

  // Lifecycle
  componentWillMount() {
    const { game, idDivision, positions, game_types } = this.props;
    this.setState({ game, idDivision, positions, rankingOptions: game_types });
  }
  componentWillReceiveProps = nextProps => {
    const { game } = nextProps;
    this.setState({ game });
  }
  //

  callSetTeamAPI = (s) => {
    const { game } = this.state, { idDivision, fnNotify, defaultGameDuration } = this.props;
    request.patch(`${config.apiEndpoint}/api/v4/games/placeholder/${game.IdGame}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .send({
        ...game, RoundNumber: game.Group, IdDivision: idDivision,
        DateStart: `${game.GameDate.format('YYYY-MM-DD')}T${game.GameStartTime.format('HH:mm:ss')}`,
        DateEnd: `${game.GameDate.format('YYYY-MM-DD')}T${moment(game.GameStartTime).add(s || defaultGameDuration, 'minutes').format('HH:mm:ss')}`,
      })
      .then(() => { fnNotify && fnNotify(game); });
  }
  callSetPlacementAPI = (team) => {
    const { game } = this.state, { fnNotify } = this.props;
    request.patch(`${config.apiEndpoint}/api/v4/games/placement/${game.IdGame}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .send({ team })
      .then(() => { fnNotify && fnNotify(game); });
  }

  fnAddHomeTeam = (team) => {
    const { game } = this.state;
    game.GameTeams[0] = team;
    this.setState({ game }, () => { this.callSetTeamAPI(); });
  }
  fnAddAwayTeam = (team) => {
    const { game } = this.state;
    game.GameTeams[1] = team;
    this.setState({ game }, () => { this.callSetTeamAPI(); });
  }
  fnNotify = (team, teamIndex) => {
    const { fnNotify } = this.props;
    const { game } = this.state;
    game.GameTeams[teamIndex] = team;
    this.setState({ game }, () => {
      fnNotify && fnNotify(game);
    });
  }

  addParentGame = (top) => {
    const { index, indexBracket } = this.props, { game } = this.state;

    if (top) {

      if (game.GameTeams[0].comesFrom.Id) return;

      // Visual Feedback
      game.GameTeams[0].adding = true;
      this.setState({ game }, () => {
        this.props.fnAddParentGame &&
          this.props.fnAddParentGame({
            idGame: game.IdGame,
            indexTeam: 0,
            indexGroup: game.Group,
            indexGame: index,
            indexBracket: indexBracket
          });
      });


    } else {

      if (game.GameTeams[1].comesFrom.Id) return;

      game.GameTeams[1].adding = true;
      this.setState({ game }, () => {
        this.props.fnAddParentGame &&
          this.props.fnAddParentGame({
            idGame: game.IdGame,
            indexTeam: 1,
            indexGroup: game.Group,
            indexGame: index,
            indexBracket: indexBracket
          });
      });

    }
  }
  deleteParentGame = (idGame, top) => {
    const { indexBracket } = this.props, { game } = this.state;

    if (top) {
      //game.GameTeams[0].comesFrom.Id = null;
      game.GameTeams[0].deleting = true;
    } else {
      //game.GameTeams[1].comesFrom.Id = null;
      game.GameTeams[1].deleting = true;
    }

    this.setState({ game }, () => {
      this.props.fnDeleteParentGame &&
        this.props.fnDeleteParentGame({
          idGame,
          indexGroup: game.Group + 1,
          indexBracket
        });
    });
  }
  deleteThisGame = () => {
    const { game } = this.state, { indexBracket } = this.props;
    game.deleting = true;
    this.setState({ game }, () => {
      this.props.fnDeleteParentGame &&
        this.props.fnDeleteParentGame({
          idGame: game.IdGame,
          indexGroup: game.Group,
          indexBracket
        });
    });
  }


  gameContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation && e.stopPropagation();
  }

  handleChangeDate = (d) => {
    const { game } = this.state;
    game.GameDate = moment(d);
    this.setState({ game }, () => { this.callSetTeamAPI(); });
  }
  handleChangeTime = (d) => {
    const { game } = this.state;
    game.GameStartTime = moment(d);
    this.setState({ game }, () => { this.callSetTeamAPI(); });
  }

  toggleTimeStart = () => this.setState({ popoverStartTimeOpen: !this.state.popoverStartTimeOpen });
  toggleLocations = () => this.setState({ popoverLocationOpen: !this.state.popoverLocationOpen });
  toggleEditingName = () => {
    const { game } = this.state;

    if (this.txtGameName) {
      game.Notes = this.txtGameName.value;
    }

    this.setState({
      editingName: !this.state.editingName
    }, () => {
      if (this.state.editingName) {
        this.txtGameName.focus();
        this.txtGameName.select();
      } else {
        this.setState({ game }, () => { this.callSetTeamAPI(); });
      }
    });
  }

  selectLocation = e => {
    const { fields } = this.props, { game } = this.state;
    game.Location = _.find(fields, f => parseInt(f.IdLocation, 10) === parseInt(e.target.value, 10));
    game.LocationName = game.Location.Name;
    this.setState({ game }, () => { this.callSetTeamAPI(); });
  }

  selectGameType = e => {
    const { game } = this.state;
    const aux = _.find(this.props.game_types, p => p.IdGameType === e.target.value);
    game.IdGameType = (aux || {}).IdGameType;
    game.GameType = (aux || {}).GameType;
    this.setState({ game }, () => {
      this.callSetTeamAPI();
    });
  }

  selectDivisionResult = e => {
    let { game } = this.state;
    const aux = _.find(this.props.positions, p => p.IdTournamentDivisionResults === e.target.value);
    game.IdTournamentDivisionResults = (aux || {}).IdTournamentDivisionResults;
    game.TournamentDivisionResults = (aux || {}).TournamentDivisionResults;
    game.IdGameType = null;
    game.GameType = null;
    this.setState({ game }, () => {
      this.callSetTeamAPI();
    });
  }

  changeGameDuration = (add) => {
    let { defaultGameDuration } = this.props;
    if (add) {
      defaultGameDuration += 5;
    } else {
      if (defaultGameDuration > 5) {
        defaultGameDuration -= 5;
      }
    }
    this.props.setDefaultGameDuration &&
      this.props.setDefaultGameDuration(defaultGameDuration);

    this.callSetTeamAPI(defaultGameDuration);
  }

  render() {

    const { fields, idDivision, defaultGameDuration, positions } = this.props,
      { editingName, dropAccepts, randomIdStart, rankingOptions, game } = this.state;
    let IdLocation = game.Location ? (game.Location.IdLocation || '') : '';
    return (
      <div className="bracket-game-wrapper align-self-center d-flex flex-row">
        <div className={`connections-wrapper d-flex flex-row ${game.IdGame ? '' : 'fully-transparent'}`}>
          {/* Left */}
          <div className="left d-flex flex-column">
            <div className="connector-box-top align-self-center d-flex flex-column-reverse">
              {game.GameTeams[0].comesFrom.Id && <div className={`connector d-flex flex-row w-100`}>
                <div className="L w-100" />
                <div className="Floor w-100" />
              </div>}
            </div>
            <div className="connector-box-bottom align-self-center d-flex flex-column">
              {game.GameTeams[1].comesFrom.Id && <div className={`connector d-flex flex-row w-100`}>
                <div className="L w-100" />
                <div className="Floor w-100" />
              </div>}
            </div>
          </div>
          {/* Right */}
          <div className="right d-flex flex-column justify-content-center">

            { /* TOP GAME TEAM CONTROLS */}
            <div className="d-flex flex-row">
              {!game.GameTeams[0].comesFrom.Id && (
                <button onClick={() => this.addParentGame(true)} className={`white w-100 font-16 bg-success add-bracket-node-button align-self-center ${game.IdGame ? '' : 'hide'}`}><i className={`fa fa-${game.GameTeams[0].adding ? 'plus fa-spin' : 'plus'} font-16 white`} /></button>)}
              {game.GameTeams[0].comesFrom.Id && (
                <button onClick={() => this.deleteParentGame(game.GameTeams[0].comesFrom.Id, true)}
                  className={`white w-100 font-16 bg-danger add-bracket-node-button align-self-center`}><i className={`fa fa-${game.GameTeams[0].deleting ? 'minus fa-spin' : 'minus'} font-16 white`} /></button>)}
              <div className=" connector-box-center align-self-center" />
            </div>

            <div className="gap align-self-center d-flex flex-row">
              {game.Group === 0 && <button onClick={() => this.deleteThisGame()} className={`white w-100 font-16 bg-danger add-bracket-node-button align-self-center`}><i className={`fa fa-${game.deleting ? 'times fa-spin' : 'times'} font-10 white`} /></button>
              }
              {game.Group === 0 && <div className=" connector-box-center align-self-center" />}
            </div>

            { /* BOTTOM GAME TEAM CONTROLS */}
            <div className="d-flex flex-row">
              {!game.GameTeams[1].comesFrom.Id && (
                <button onClick={() => this.addParentGame(false)} className={`white font-16 bg-success add-bracket-node-button ${game.IdGame ? '' : 'hide'}`}><i className={`fa fa-${game.GameTeams[1].adding ? 'plus fa-spin' : 'plus'} font-16 white`} /></button>)}
              {game.GameTeams[1].comesFrom.Id && (
                <button onClick={() => this.deleteParentGame(game.GameTeams[1].comesFrom.Id, false)}
                  className={`white font-16 bg-danger add-bracket-node-button`}><i className={`fa fa-${game.GameTeams[1].deleting ? 'minus fa-spin' : 'minus'} font-16 white`} /></button>)}
              <div className=" connector-box-center align-self-center" />
            </div>


          </div>
        </div>
        <form>
          <div className="bracket-game d-flex flex-row">
            {game.IdGame && <div className={`clearfix game align-self-center`} onContextMenu={(e) => this.gameContextMenu(e)}>
              <div className="game-name d-flex flex-row">
                {!editingName && <button onClick={this.toggleEditingName} className="btn font-8 btn-info btn-sm btn-block text-left">
                  {game.Notes || 'Game Name'}
                </button>}
                {editingName && <input type="text" defaultValue={game.Notes} ref={(input) => {
                  this.txtGameName = input;
                }} className="form-control align-self-center control-sm" placeholder="Game Name" />}
                {game.IdGame && !editingName && (<span className="align-self-center ml-auto font-8">{game.IdGame}</span>)}
                {editingName && <button type="submit" className={`btn-success align-self-center btn ml-auto`} onClick={this.toggleEditingName}>
                  <i className={`fa fa-check`} />
                </button>}
              </div>
              <HomeTeamDropSection home={0} game={game} accept={dropAccepts} team={game.GameTeams[0]} idDivision={idDivision} fnAddHomeTeam={this.fnAddHomeTeam}
                fnNotify={this.fnNotify} />
              {game.Location && <Input style={{ border: !IdLocation ? '2px solid red' : null }} className={`font-10`} type="select"
                value={IdLocation}
                onChange={this.selectLocation} bsSize="sm">
                {[{ Name: 'Select' }, ...fields].map((f, i) => <option value={f.IdLocation} key={i}>
                  {f.child && ' - '}
                  {f.Name}
                </option>)}
              </Input>}
              <div className="d-flex flex-row justify-content-center font-10 bg-warning">
                <button type="button" onClick={this.toggleTimeStart} id={randomIdStart} className={`btn font-10 btn-sm btn-info btn-block`}>
                  {moment(game.GameDate).format('MMM Do YYYY')}
                  {' @ '}
                  {moment(game.GameStartTime).format('h:mmA')}
                </button>
                <Popover placement="left" isOpen={this.state.popoverStartTimeOpen} target={randomIdStart} toggle={this.toggleTimeStart}>
                  <PopoverBody className="d-flex flex-column justify-content-end">
                    <DatePicker inline selected={game.GameDate} onChange={this.handleChangeDate} />
                    <CustomTimeScroller time={game.GameStartTime} fnChange={(d) => this.handleChangeTime(d)} />

                    <div className="d-flex flex-row bg-success">
                      <span className="black font-10 idented-half align-self-center">Duration (mins.)</span>
                      <button className="btn btn-link btn-sm black font-10 ml-auto" onClick={() => this.changeGameDuration(false)}><i className="fa fa-caret-left" /></button>
                      <button className="btn btn-link btn-sm black font-10">{defaultGameDuration}</button>
                      <button className="btn btn-link btn-sm black font-10" onClick={() => this.changeGameDuration(true)}><i className="fa fa-caret-right" /></button>
                    </div>

                  </PopoverBody>
                </Popover>
              </div>
              <AwayTeamDropSection game={game} accept={dropAccepts} team={game.GameTeams[1]} idDivision={idDivision}
                fnAddAwayTeam={this.fnAddAwayTeam} fnNotify={this.fnNotify} />


              {/* Rankings */}
              {rankingOptions && !game.IdGameBracket && <Input className="font-10" type="select" value={game.IdGameType || ''} bsSize="sm" onChange={this.selectGameType}>
                {[{
                  IdGameType: 0,
                  GameType: 'Game Type'
                }, ...rankingOptions].map((f, i) => <option value={f.IdGameType} key={i}>
                  {f.GameType}
                </option>)}
              </Input>}

              {/* Looser Position */}
              {positions && game.IdGameBracket && <Input className="font-10" type="select" value={game.IdTournamentDivisionResults || ''} bsSize="sm" onChange={this.selectDivisionResult}>
                {[{
                  IdTournamentDivisionResults: 0,
                  TournamentDivisionResults: 'Loser Rankings'
                }, ...positions].map((f, i) => <option value={f.IdTournamentDivisionResults} key={i}>
                  {f.TournamentDivisionResults}
                </option>)}
              </Input>}

            </div>}
            {(!game || (!game.IdGame && !game.phantom)) && <div className="clearfix game align-self-center justify-content-center" style={{ backgroundColor: '#303030', position: 'relative' }}>
              <Loader message="Wait.." />
            </div>}
          </div>
        </form>
      </div>
    )
  }
}

export default Game;
