import React from 'react';

const RedishHeader = ({message, align = 'center'}) => {
    return (
      <div className={`d-flex justify-content-${align} bg-danger p-2`}>
       <span className="white align-self-center block text-center">{message}</span>
      </div>
      );
  
};

export default RedishHeader;