

import React from 'react';
import moment from 'moment';

class CustomDatePicker2 extends React.Component {

    render() {
        const { value, className } = this.props;
        return (
            <button onClick={this.props.onClick} className={`d-flex flex-column justify-content-center p-2 ${className}`} style={{ width: 80 }}>
                <i className="far fa-calendar-alt font-20 align-self-center" />
                <span className="font-8 line1 mt-2 text-center w-100">{value ? moment(this.props.value).format('MMM Do') : 'Select'}</span>
                <span className="font-8 line1 text-center w-100">{value ? moment(this.props.value).format('YYYY') : 'Date'}</span>
            </button>
        )
    }
}

export default CustomDatePicker2;
