import React from 'react';
import '../common/style.css';
import moment from 'moment';
import { Animated } from 'react-animated-css';
import PaymentsTeamSelection from '../common/PaymentsTeamSelection';
import PaymentsPlayerSelection from '../common/PaymentsPlayerSelection';
import PaymentsHybridSelection from '../common/PaymentsHybridSelection';
import CartPopup from '../common/CartPopup';
import { Link } from 'react-router';
import _ from 'lodash';

class Seasons extends React.Component {

    state = {
        bootstrapped: false,
        isTeamSelectionOpen: false,
        season: null
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, league } = this.props;

        setTitle && setTitle({
            main: 'Payments',
            sub: league ? league.Name : null
        });

        this.props.seasonActions &&
            this.props.seasonActions.fetchSeasonsForPayments(params.idLeague);

        this.props.leagueActions &&
            this.props.leagueActions.fetchTeamRegistration(params.idLeague);

        this.props.leagueActions &&
            this.props.leagueActions.fetchFamilyRegistration(params.idLeague);

        if (!league || !league.IdLeague) {
            this.props.leagueActions &&
                this.props.leagueActions.fetchLeague(params.idLeague)
        }
    }

    componentDidMount() {
        this.setState({ bootstrapped: true });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.league !== this.props.league) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                main: 'Payments',
                sub: nextProps.league.Name || nextProps.league.LeagueName
            })
        }
    }
    //

    selectSeason = (season) => {
        this.setState({
            season,
            isHybridSelectionOpen: season.SeasonType === 'Hybrid',
            isTeamSelectionOpen: season.SeasonType === 'Tournament',
            isPlayerSelectionOpen: (season.SeasonType === 'Tournament' || season.SeasonType === 'Hybrid') ? false : true
        });
    }

    toogleTeamSelection = () => this.setState({ isTeamSelectionOpen: !this.state.isTeamSelectionOpen });

    togglePlayerSelection = () => this.setState({ isPlayerSelectionOpen: !this.state.isPlayerSelectionOpen });

    setOnPouchAndGo = (args) => {       

        this.props.pouchActions &&
            this.props.pouchActions.set(args);

        // Detect if registering a team or a player / coach
        if (args.selectedTeam) {
            this.props.router && this.props.router.push('/payments/team');
        } else {
            this.props.router && this.props.router.push('/payments/user');
        }
    }

    toggleCart = () => {
        this.props.cartActions &&
            this.props.cartActions.close();
    }

    render() {
        const { fetchingData, seasons, league, family, cart, teamRegistration, familyRegistration, isCartOpen } = this.props,
            { bootstrapped, isTeamSelectionOpen, isPlayerSelectionOpen, season, isHybridSelectionOpen } = this.state;

        return (
            <section id="registration-seasons">
                <div className="right-actions d-flex flex-row-reverse pr-2" key="rightActions">
                    {league && <div style={{ width: 'auto' }} className="tile card shadow-box h-100 d-flex flex-row">
                        <div className="align-self-center ml-2" style={{ width: 50, height: 40, background: `url(${league.LeagueImage}) no-repeat center center`, backgroundSize: 'contain' }} />
                        {/*<img alt="" src={league.LeagueImage} style={{ width: 'auto' }} className="align-self-center margin-bottom-half" />*/}
                        <span className="ml-2 black align-self-center">{league && (league.LeagueName || league.Name)}</span>
                        <div style={{ width: 100 }} />
                        <Link to="/payments" className="btn btn-link ml-auto" style={{ zIndex: 10 }}>
                            <span className="blue font-8">Change</span>
                        </Link>
                    </div>}
                </div>
                <div style={{ height: 50 }} />
                <div style={{ zIndex: 9 }}>
                    { /* Loader */}
                    {bootstrapped && fetchingData &&
                        <div className="d-flex justify-content-center centered-message">
                            <h1 className="white align-self-center block text-center" key="loader"><i className="fa fa-spin fa-cog" /></h1></div>}
                    { /* Seasons */}
                    {bootstrapped && !fetchingData &&
                        <Animated animationIn="fadeInRight" className="container-fluid margin-top">
                            <ul className="white text-center d-flex flex-wrap pb-4 justify-content-center" key="list">
                                {_.orderBy(seasons || [], 'RegistrationEnd').reverse().map((season, i) => (

                                    <li className="tile card shadow-box" style={{ marginTop: '1em', marginRight: '1em', width: 300, height: 150 }} key={i}>
                                        <Animated animationIn="bounceIn" animationInDelay={i * 50} className="w-100 h-100">
                                            <span onClick={() => this.selectSeason(season)} className="align-self-center justify-content-center d-flex flex-row" style={{ width: '100%', height: '100%' }}>

                                                <div style={{ width: 60 }} className=" h-100 pt-4">
                                                    <i className={`icon-${season.SeasonType.toLowerCase()} font-20 color-${season.SeasonType}`} />
                                                </div>

                                                <div className="w-100 d-flex flex-column justify-content-center h-100 ">
                                                    <span className="text-left gray mr-2">{season.SeasonType}</span>
                                                    <span className="text-left mt-2 black toBold line1 mr-2">{season.Name || season.LeagueName}</span>
                                                    <span className="text-left mt-2 gray font-8 mr-2">{[moment(season.DateStart).format('MMM DD, YYYY').toUpperCase(), moment(season.DateEnd).format('MMM DD, YYYY').toUpperCase()].join(' to ')}</span>
                                                </div>
                                            </span>
                                        </Animated>
                                    </li>
                                ))}
                            </ul>
                        </Animated>}


                    { /* No Seasons */}
                    {bootstrapped && seasons && !fetchingData && !seasons.length &&
                        <div className="d-flex justify-content-center centered-message" style={{ top: '3em' }}>
                            <h1 className="white align-self-center block text-center" key="none">There are no Leagues available for Registration</h1></div>}
                </div>

                {isPlayerSelectionOpen && <PaymentsPlayerSelection cart={cart} league={league} season={season} family={family} isOpen={isPlayerSelectionOpen}
                    toggle={this.togglePlayerSelection} fnOk={this.setOnPouchAndGo} familyRegistration={familyRegistration} />}

                {isTeamSelectionOpen && <PaymentsTeamSelection cart={cart} league={league} season={season} teams={_.filter(teamRegistration, (fr) => { return fr.IdSeason === season.IdSeason })} family={family} isOpen={isTeamSelectionOpen} toggle={this.toogleTeamSelection} fnOk={this.setOnPouchAndGo} teamRegistration={teamRegistration} />}

                {isHybridSelectionOpen && <PaymentsHybridSelection cart={cart} league={league} season={season} teams={_.filter(teamRegistration, (fr) => { return fr.IdSeason === season.IdSeason })} family={family} isOpen={isTeamSelectionOpen} toggle={() => this.setState({ isHybridSelectionOpen: false })} fnOk={this.setOnPouchAndGo} teamRegistration={teamRegistration} familyRegistration={familyRegistration} />}

                {isCartOpen && <CartPopup {...this.props} toggle={this.toggleCart} fnOk={this.toggleCart} isOpen={this.props.isCartOpen} />}

            </section>
        )
    }
}

export default Seasons;