export function toggleCheckForSchedule() { return { type: 'SEASON/TOGGLE_CHECK_FOR_SCHEDULE' } }
export function add(season) { return { type: 'SEASONS/ADD', season } }
export function update(idSeason, season) { return { type: 'SEASONS/UPDATE', idSeason, season } }
export function updateSeason(season) { return { type: 'SEASON/SET', season } }
export function fetchGames(idSeason, date) { return { type: 'SEASON/FETCH_GAMES', idSeason, date } }
export function fetchScoreEntryGames(idSeason, date) { return { type: 'SEASON/SCORE_ENTRY/FETCH_GAMES', idSeason, date } }
export function clearGames() { return { type: 'GAMES/CLEAR' } }
export function fetchCoaches(idSeason, flag) { return { type: flag ? 'SEASON/COACHES/FETCH' : 'SEASON/FETCH_COACHES', idSeason } }
export function clearCoaches() { return { type: 'COACHES/CLEAR' } }
export function fetchSeasonsForRegistration(idLeague) { return { type: 'SEASONS/REGISTRATION/FETCH', idLeague } }
export function fetchSeasonsForPayments(idLeague) { return { type: 'SEASONS/PAYMENTS/FETCH', idLeague } }
export function fetchDisclaimer(idSeason) { return { type: 'DISCLAIMER/FETCH', idSeason } }
export function fetchSeason(idSeason) { return { type: 'SEASON/FETCH', idSeason } }
export function fetchDashboard(idSeason) { return { type: 'SEASON/DASHBOARD/FETCH', idSeason } }
export function fetchSeasonValidations(idSeason) { return { type: 'SEASON/VALIDATIONS/FETCH', idSeason } }
export function updateProgram(program, division) { return { type: 'PROGRAM/UPDATE', program, division } }
export function updateTryoutProgram(program) { return { type: 'TRYOUT/UPDATE', program } }
export function updateAdjustmentProgram(program) { return { type: 'ADJUSTMENT/UPDATE', program } }
export function updateZipCodeProgram(program) { return { type: 'ADJUSTMENTS/ZIP/UPDATE', program } }
export function updateFamilyAdjustment(program) { return { type: 'ADJUSTMENTS/FAMILY_ADJUSTMENTS/UPDATE', program } }

export function assignQuestionGroup(idSeason, idQuestionLibraryGroup, idQuestionGroupLocation) { return { type: 'SEASON/QUESTION_LIBRARY_GROUP/ASSIGN', idSeason, idQuestionLibraryGroup, idQuestionGroupLocation } }
export function archiveQuestionGroup(IdQuestionGroup, idSeason) { return { type: 'SEASON/QUESTION_LIBRARY_GROUP/ARCHIVE', idSeason, IdQuestionGroup } }

export function fetchScoutRegistrations(IdSeason) { return { type: 'SEASON/SCOUT_REGISTRATIONS/FETCH', IdSeason } }

export function assignScoutProgram({ IdSeason, IdCatalogItem }) { return { type: 'SEASON/SCOUT_PROGRAM/ASSIGN', IdSeason, IdCatalogItem } }
export function removeScoutProgram({ IdSeason }) { return { type: 'SEASON/SCOUT_PROGRAM/REMOVE', IdSeason } }
export function assignScoutAdjustment({ IdSeason, IdCatalogItemPriceAdjustmentByDate }) { return { type: 'SEASON/SCOUT_ADJUSTMENT/ASSIGN', IdSeason, IdCatalogItemPriceAdjustmentByDate } }
export function removeScoutAdjustment({ IdSeason, IdCatalogItemPriceAdjustmentByDate }) { return { type: 'SEASON/SCOUT_ADJUSTMENT/REMOVE', IdSeason, IdCatalogItemPriceAdjustmentByDate } }
export function assignQuestionGroupToSeason({ IdSeason, IdQuestionGroup }) { return { type: 'SEASON/QUESTION_GROUP/ASSIGN', IdSeason, IdQuestionGroup } }
export function removeQuestionGroupFromSeason({ IdSeason, IdQuestionGroup }) { return { type: 'SEASON/QUESTION_GROUP/REMOVE', IdSeason, IdQuestionGroup } }


export function fetchFilters(idSeason) { return { type: 'SEASON/FILTERS/FETCH', idSeason } }
export function fetchTournamentPoints(idCommunity) { return { type: 'TORUNAMENT/POINT_TYPES/FETCH', idCommunity } }
export function fetchAwards(idSeason, letter) { return { type: 'AWARDS/FETCH', idSeason, letter } }
export function fetchShirtsSignature(idSeason, letter) { return { type: 'SHIRTS/SIGNATURE/FETCH', idSeason, letter } }
export function clearSignatures() { return { type: 'TEAMS/CLEAR' } }
export function fetchFieldLayouts(idSeason) { return { type: 'SEASON/FIELD_LAYOUT/FETCH', idSeason } }
export function fetchPointSystem(idSeason) { return { type: 'SEASON/POINT_SYSTEM/FETCH', idSeason } }
export function clearPoints() { return { type: 'SEASON/CLEAR_POINT_SYSTEM' } }
export function fetchSeasons(idLeague) { return { type: 'SEASONS/FETCH', idLeague } }
export function fetchSlots(idSeason) { return { type: 'SLOTS/FETCH', idSeason } }
export function clearSlots() { return { type: 'SLOTS/CLEAR' } }
export function fetchDisclaimerNonSecure(idOrder, idUser, idOrderItem) { return { type: 'DISCLAIMER/NON_SECURE/FETCH', idOrder, idUser, idOrderItem } }
export function fetchPCVs(idSeason, letter) { return { type: 'SEASON/PCV/FETCH', idSeason, letter } }
export function validatePCV(idUser, idSeason) { return { type: 'SEASON/PCV/VALIDATE', idUser, idSeason } }
export function unValidatePCV(idUser, idSeason) { return { type: 'SEASON/PCV/UNVALIDATE', idUser, idSeason } }
export function fetchMedals(idSeason) { return { type: 'MEDALS/FETCH', idSeason } }
export function fetchRoles(idSeason) { return { type: 'SEASON/ROLES/FETCH', idSeason } }

// Violations
export function fetchSeasonViolations(idTeamUserRole) { return { type: 'SEASON/VIOLATIONS/USER/FETCH', idTeamUserRole } }
export function addUserViolation(violation) { return { type: 'USER/VIOLATION/ADD', violation } }
export function fetchSeasonViolationTypes(idCommunity) { return { type: 'SEASON/VIOLATIONS/TYPES/FETCH', idCommunity } }
export function removeViolation({ IdViolation, IdTeamUserRole }) { return { type: 'USER/VIOLATION/REMOVE', IdViolation, IdTeamUserRole } }
export function fetchValidationsByLetter(idSeason, letter, validation_type) { return { type: 'SEASON/VALIDATIONS/TYPE/FETCH', idSeason, letter, validation_type } }
export function fetchValidationsSummary({ idLeague, idSeason }) { return { type: 'SEASON/VALIDATIONS/SUMMARY/FETCH', idSeason, idLeague } }
export function fetchSummaryByType(idSeason, validation_type) { return { type: 'VALIDATIONS/SEASON_SUMMARY/TYPE/FETCH', idSeason, validation_type } }