import React from 'react';

class Check extends React.Component {
    state = {
        answer: null
    }

    toggle = () => {
        const { question } = this.props;
        question.answer = !this.state.answer ? {
            Amount: 0,
            AnswerText: 'Yes'
        } : null;
        this.setState({
            answer: !this.state.answer
        });
    }

    render() {
        const { question } = this.props,
            { answer } = this.state;
        return <span className="pb-2 idented margin-right margin-top-half d-flex flex-row black" onClick={this.toggle}>            
            <i className={`margin-right align-self-center ${answer ? 'icon-check-circle green' : 'far fa-circle'}`} />
            <span className="text-left w-100 align-self-center">{question.QuestionText}</span>
            {question.Required && <i className="fa fa-asterisk red align-self-start ml-auto" />}
        </span>
    }
}

export default Check;