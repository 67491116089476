module.exports = {
    purchase: {
        name: "Purchase",
        icon: "shopping-cart",
        on: false,
        to: '/plans/purchase'
    },
    manage: {
        name: "Manage",
        icon: "cog",
        on: false,
        to: '/plans/manage'
    }/*,
    uniform: {
        name: "Uniforms",
        icon: "hashtag",
        on: false,
        to: '/roster/uniforms'
    }*/
};