import React from 'react';

class Comp extends React.Component {
    render() {
        return <section className="white">
            <section>
                {React.cloneElement(this.props.children, this.props)}
            </section>
        </section>
    }
}

export default Comp;