import React from 'react';
//import { DragDropContext } from 'react-beautiful-dnd';
import UpperList from './UpperList';
import LowerList from './LowerList';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ItemTypes } from '../../../../../helpers';
import LineLoader from '../../../../common/LineLoader';
import _ from 'lodash';

class Lists extends React.Component {

  state = {
    players: null
  }

  rosterUp = item => {    
    item.IdTeamDivision = this.props.selectedTeam.IdTeamDivision;
    item.Team = this.props.selectedTeam.Name;
    if (item.IdRole && item.IdRole === 4) {
      item.IdRoleStatus = 2;
      item.RoleStatus = 'Active';
    }
    item.New = true;
    this.props.divisionsActions &&
      this.props.divisionsActions.updatePlayers(item, _.findIndex(this.props.players, (p) => {
        return p.IdUser === item.IdUser && p.IdTeamUserRole === item.IdTeamUserRole;
      }));
  }

  rosterOut = item => {
    item.IdTeamDivision = null;
    item.Team = null;
    if (item.IdRole && item.IdRole === 4) {
      item.IdRoleStatus = 1;
      item.RoleStatus = 'Pending';
    }
    item.New = false;
    this.props.divisionsActions &&
      this.props.divisionsActions.updatePlayers(item, _.findIndex(this.props.players, (p) => {
        return p.IdUser === item.IdUser && p.IdTeamUserRole === item.IdTeamUserRole;
      }));
  }

  render() {

    const accept = [ItemTypes.NONROSTEREDPLAYER, ItemTypes.ROSTEREDPLAYER],
      { players, selectedTeam } = this.props;

    let filteredPlayers = _.chain(players || [])
      .filter(p => { return parseInt(p.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10); })
      .value();

    return (

      <section className="clearfix" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        {this.props.fetchingPlayers && <LineLoader />}
        { /* Top List */}
        {selectedTeam && <UpperList {...this.props} accept={accept}
          items={filteredPlayers} fnDrop={this.rosterUp} />}
        { /* Bottom List */}
        {selectedTeam && <LowerList {...this.props} accept={accept} items={players} fnDrop={this.rosterOut} />}
      </section>
    )
  }
}

export default DragDropContext(HTML5Backend)(Lists);