function reducer(state = [], action) {

    switch (action.type) {
        case 'PAYMENTS/SET':
            return action.payments;
        case 'PAYMENT/DELETE':
            const { payment } = action;
            if (state)
                return [...state.slice(0, payment.Index), ...state.slice(payment.Index + 1)];
            return state;
        default:
            return state;
    }
}

export default reducer;