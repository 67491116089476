import React from 'react';
import PaymentTypeItem from './item';

const PaymentTypeChart = (props) => {
  const { season } = props  
  return (
    <section className=" margin-bottom">
      <h6 className="black toUpper sqwad-font text-left mt-4 ml-4">Payment Methods</h6>
      {season.transactions && season.transactions.length > 0 && <div className="d-flex flex-row justify-content-around margin-top">
        {season.transactions.map((item, index) => <PaymentTypeItem item={item} index={index} key={index} />)}
      </div>}
      {season.transactions && season.transactions.length === 0 && <div className="no-margin bg-white d-flex flex-column justify-content-center">
        <i className="far fa-meh-blank font-30 black align-self-center" />
        <span className="black font-20 align-self-center">No payments have been detected!</span>
      </div>}
    </section>
  );
}

export default PaymentTypeChart;
