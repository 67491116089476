import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';


class Organization extends React.Component {
    state = {

    }

    // Lifecycle
    componentDidMount = () => {
        const { setTitle } = this.props;
        setTitle && setTitle({ main: 'Organizational Settings' });
    }
    componentWillReceiveProps = nextProps => {

    }
    componentWillUnmount = () => {

    }
    //

    render() {
        return <section className="viewport">
            <div className="d-flex justify-content-center centered-message p-2" id="user-modules">
                <ul className="white align-self-center text-center d-flex flex-wrap px-3 justify-content-center">

                    <li className="tile bg-white card shadow-box bordered rounded" style={{ height: 250 }}>
                        <Animated animationIn="bounceIn" animationInDelay={0} className="w-100 h-100">
                            <Link to="/organization_settings/fees" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                <i className="fas fa-dollar-sign align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                <span className=" align-self-center margin-top-half line1">SL Registration<br/>Fees</span>
                            </Link>
                        </Animated>
                    </li>

                    <li className="tile bg-white card shadow-box bordered rounded" style={{ height: 250 }}>
                        <Animated animationIn="bounceIn" animationInDelay={0} className="w-100 h-100">
                            <Link to="/organization_settings/dashboard" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                <i className="fas fa-chart-bar align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                <span className=" align-self-center margin-top-half line1">Dashboard and Tracking</span>
                            </Link>
                        </Animated>
                    </li>

                    <li className="tile bg-white card shadow-box bordered rounded" style={{ height: 250 }}>
                        <Animated animationIn="bounceIn" animationInDelay={0} className="w-100 h-100">
                            <Link to="/organization_settings/earnings" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                <i className="fas fa-mobile align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                <span className=" align-self-center margin-top-half line1">In-App Purchases</span>
                            </Link>
                        </Animated>
                    </li>

                    <li className="tile bg-white card shadow-box bordered rounded" style={{ height: 250 }}>
                        <Animated animationIn="bounceIn" animationInDelay={0} className="w-100 h-100">
                            <Link to="/organization_settings/ads" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                <i className="fas fa-audio-description align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                <span className=" align-self-center margin-top-half line1">Ads</span>
                            </Link>
                        </Animated>
                    </li>

                    <li className="tile bg-white card shadow-box bordered rounded" style={{ height: 250 }}>
                        <Animated animationIn="bounceIn" animationInDelay={0} className="w-100 h-100">
                            <Link to="/organization_settings/sessions" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                                <i className="icon-calendar align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                                <span className=" align-self-center margin-top-half line1">Sessions</span>
                            </Link>
                        </Animated>
                    </li>                    
                    
                </ul>
            </div>
        </section>
    }
}

export default Organization;