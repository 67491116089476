import _ from 'lodash';

function flightedTeams(state = [], action) {
    let index = -1;
    switch (action.type) {
        case 'FLIGHTED_TEAMS/SET':
            return action.teams;
        case 'FLIGHTED_TEAMS/ADD':
            index = state.findIndex(item => (parseInt(item.IdTeam, 10) === parseInt(action.flightedTeam.IdTeam, 10)));
            // If the team is already flighted, replace it
            if (index !== -1) {                
                return [
                    ...state.slice(0, index),
                    _.extend({}, state[index], action.flightedTeam),
                    ...state.slice(index + 1)
                ];
            }
            // Otherwise, insert it as new            
            return _.union([], state, [action.flightedTeam]);
        case 'FLIGHTED_TEAMS/REMOVE':
            index = state.findIndex(item => (parseInt(item.IdTeam, 10) === parseInt(action.flightedTeam.IdTeam, 10)));
            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    ...state.slice(index + 1)
                ];
            } else {
                alert('not found in the reducer')
            }
            return state;
        default:
            return state;
    }
}

export default flightedTeams;
