import React from 'react';
import request from 'superagent';
import { Animated } from 'react-animated-css';
import { Popover, PopoverBody } from 'reactstrap';
import config from '../../config';
import Loader from '../common/Loader';
import FamilyCard from './partials/FamilyCard';
import _ from 'lodash';
import AddChargeModal from './partials/AddChargeModal';
import AddCreditModal from './partials/AddCreditModal';
import MovePlayerModal from '../common/modals/MovePlayerModal';
import MakePaymentModal from '../common/modals/MakePayment';
import MakeRefundModal from '../common/modals/MakeRefund';
import moment from 'moment';
import ChangePlanModal from '../common/modals/ChangePlanModal';
import DisclaimerTab from './partials/DisclaimerTab';
import RegistrationTab from './partials/RegistrationTab2';
import AccountingTab from './partials/AccountingTab';
import ShirtNumberEdit from './partials/ShirtNumberEdit';
import UserValidations from '../validations/user_validations';
import PlayerTeams from './partials/PlayerTeams';
import UserNotes from './partials/UserNotes';
import Violations from './partials/Violations';
import { humanize_user } from '../../helpers';

class AdminUserProfile extends React.Component {

    state = {
        user: null,
        bootstrapped: false,
        family: null,
        currentTab: '2',
        selectedSeason: null,
        originalSeason: null,
        originalDivision: null,
        seasons: null,
        dues: null,
        allSeasons: null,
        questions: null,
        addingCharge: false,
        addingCredit: false,
        payments: null,
        balance: null,
        paid: null,
        pastDue: null,
        futurePayments: null,
        idOrderItem: null,
        fetchingPayments: false,
        moving: false,
        updating: false,
        makingPayment: false,
        makeRefund: false,
        totalAmount: null,
        selectedPayment: null,
        paying: false,
        tab: 2
    }

    // Lifecycle
    componentWillMount() {
        const { params, setTitle, router } = this.props, { location } = router;
        if (location && location.query && location.query.tab) this.setState({ currentTab: `${location.query.tab}`, tab: parseInt(location.query.tab, 10) })
        if (this.props.season && this.props.season.IdSeason) this.setup(this.props.season, params.idUser);
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
        this.props.fetchStatuses && this.props.fetchStatuses();
        setTitle && setTitle({ main: 'Profile' });
    }

    componentWillReceiveProps = nextProps => {
        //                                              v this is for when it changes the user on the same page
        if (nextProps.season.IdSeason !== this.props.season.IdSeason
            || nextProps.params.idUser !== this.props.params.idUser) {
            this.setup(nextProps.season, nextProps.params.idUser);
        }
    }
    //

    setup = (season, idUser) => {

        const { params, setTitle } = this.props;

        // TODO: do this with Redux ?
        request.get(`${config.apiEndpoint}/api/v4/family/accounting/${idUser}/${season.IdLeague || this.props.season.IdLeague}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
                if (data.body.success) {

                    this.setState({
                        bootstrapped: true,
                        user: data.body.user,
                        family: data.body.family,
                        seasons: data.body.seasons,
                        allSeasons: data.body.allSeasons,
                        selectedSeason: _.find(data.body.seasons, (s) => {
                            return s.IdSeason === parseInt(season.IdSeason, 10)
                        }) || _.first(data.body.seasons),
                        originalSeason: _.find(data.body.seasons, (s) => {
                            return s.IdSeason === parseInt(params.idSeason, 10)
                        }) || _.first(data.body.seasons)
                    }, () => {

                        this.fetchPayments();
                        this.fetchQuestions();

                        setTitle && setTitle({
                            main: 'Profile',
                            sub: data.body.user.NameFirst,
                            under: this.state.selectedSeason.Name || null,
                            updating: false
                        });
                    });
                }
            }, (reject) => {
                this.setState({
                    bootstrapped: true
                });
            });
    }

    fetchQuestions = () => {
        const { selectedSeason } = this.state;

        // Also fetch the questions at the moment of registration
        request.get(`${config.apiEndpoint}/api/v4/registration/questions/player/summary/${selectedSeason.IdTeamUserRole}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
                if (data.body.success) {
                    this.setState({
                        questions: data.body.questions
                    });
                }
            });
    }

    fetchPayments = () => {

        // Also fetch the balance
        const { params, seasonActions } = this.props;

        this.setState({
            fetchingPayments: true
        }, function () {
            // TODO: refactor this to redux
            request.get([config.apiEndpoint, 'api/v6/payments/balance',
            params.idUser, this.state.selectedSeason.IdSeason, 1].join('/'))
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    if (data.body.success) {

                        // Decrypt
                        [...data.body.dues, ...data.body.payments].forEach(u => humanize_user(u));
                        //

                        seasonActions && seasonActions.fetchDisclaimerNonSecure(null, params.idUser, data.body.balance.IdOrderItem);

                        this.setState({
                            // TODO: probably need here the total by adjustments
                            updating: false,
                            fetchingPayments: false,
                            payments: data.body.payments,
                            totalDiscounts: data.body.balance.TotalAdjustmentCredits,
                            dues: data.body.dues,
                            balance: data.body.balance.TotalDue,
                            paid: data.body.balance.TotalPaid,
                            pastDue: data.body.balance.IsPaymentPastDue ? data.body.balance.TodayBalance : 0,
                            futurePayments: _.filter(data.body.payments, (due) => due.IsPaymentDue && !due.IsPaymentPastDue),
                            totalAmount: data.body.balance.TotalAmount,
                            fullBalance: data.body.balance,
                            idOrderItem: data.body.balance.IdOrderItem,
                            autoPaySettings: data.body.autoPaySettings
                        });
                    }
                });
        });

    }

    toggleTab = (tab) => {
        if (this.state.currentTab !== tab) {
            this.setState({ currentTab: tab });
        }
    }

    selectSeason = (selectedSeason) => {
        this.setState({
            selectedSeason,
            payments: null,
            balance: null,
            pastDue: null,
            futurePayments: null
        }, function () {
            this.fetchPayments();
            this.fetchQuestions();
        });
    }

    toggleAddCharge = () => {
        this.setState((current) => {
            return {
                addingCharge: !current.addingCharge
            }
        });
    }

    toggleAddCredit = () => {
        this.setState((current) => {
            return {
                addingCredit: !current.addingCredit
            }
        });
    }

    toggleMoving = () => this.setState({ moving: !this.state.moving });
    toggleRefund = () => this.setState({ makeRefund: !this.state.makeRefund });
    toggleMakePayment = () => this.setState({ makingPayment: !this.state.makingPayment });
    togglePaying = () => this.setState({ paying: !this.state.paying });

    fnMoved = (newSeason) => {
        this.setState({ moving: false, updating: true }, () => {
            this.setup(newSeason, this.props.params.idUser);
        });
    }

    fnMakeRefund = (selectedPayment) => {
        this.setState({ selectedPayment }, () => { this.toggleRefund(); });
    }

    fnDeletePayment = (payment, index) => {
        const { paymentsActions } = this.props;
        paymentsActions && paymentsActions.deletePayment(payment);
        const { payments } = this.state;
        this.setState({ payments: [...payments.slice(0, index), ...payments.slice(index + 1)] });
    }

    fnNotify = () => {
        const { selectedSeason, user } = this.state;
        this.setState({ updating: true }, () => { this.setup(selectedSeason, user.IdUser); });
    }

    fnBlockUI = (updating) => this.setState({ updating });

    fnChangePlan = (plan) => {
        const { season } = this.props;        
        this.setState({ changingPlan: false });
        request.post(`${config.apiEndpoint}/api/v4/registration/changePlan/${this.state.idOrderItem}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({ newPlan: plan })
            .then(() => {
                this.setup(season, this.state.user.IdUser);

            });
    }

    assignStatus = (status) => {
        var { team, user, selectedSeason } = this.state;

        if (team) {
            team.IdRoleStatus = status.IdRoleStatus;
            team.RoleStatus = status.RoleStatusName;
            team.RegistrationStatus = status.RoleStatusName;
        }
        if (user) {
            selectedSeason.IdRoleStatus = status.IdRoleStatus;
            selectedSeason.RegistrationStatus = status.RoleStatusName;
        }

        this.setState({
            team,
            user,
            selectedSeason,
            changing_status: false
        });

        // Call API
        // TODO: Might need to refactor this for using on other places ?
        if (team) {
            request.patch([config.apiEndpoint, 'api/v4/teams/status',
            team.IdTeamDivision, status.IdRoleStatus].join('/'))
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    // Nothing to do here ?
                });
        } else {
            request.patch([config.apiEndpoint, 'api/v4/registration/status',
            user.IdUser, selectedSeason.IdDivision, status.IdRoleStatus].join('/'))
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    // Nothing to do here ?
                });
        }
    }

    render() {
        const { disclaimer, season } = this.props, { user, bootstrapped, family, selectedSeason,
            seasons, questions, addingCharge, addingCredit, payments, balance, paid, fullBalance,
            pastDue, moving, updating, makingPayment, makeRefund,
            allSeasons, selectedPayment, tab, changingPlan, autoPaySettings } = this.state;        

        return <section className="">

            {user && bootstrapped && questions && selectedSeason && (
                <Animated animationIn="fadeInDown" className="w-100 p-2 bg-blue-dark d-flex flex-row">
                    <div className="d-flex flex-row h-100 p-2 sans w-100">

                        <div className="cover align-self-center" style={{ width: 120, height: 120, borderRadius: 60, background: `url(${(user.UserImage || user.UserImageUrl)}?full=120) no-repeat center center` }} />

                        <div className="d-flex flex-column justify-content-center ml-3">
                            <span className="toUpper toBold font-16 white">{user.NameFirst} {user.NameLast}</span>
                            <span className="font-10 white">Gender: {user.Gender}</span>
                            <span className="font-10 white">DOB: {moment(user.DateBirth.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}{' '}<span className="font-7 align-self-center">({moment().diff(user.DateBirth, 'years')} yrs)</span></span>
                            <span className="white">{user.UserPhone}</span>
                            {user.PersonalEmailAddress && <a href={`mailto:${user.PersonalEmailAddress}`}><span className="blue">{user.PersonalEmailAddress}</span></a>}
                            <div className="d-flex flex-row mt-2">
                                <button onClick={this.toggleMoving} style={{ width: 120, border: '2px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center">Move Player</button>
                            </div>
                        </div>

                    </div>


                    <div className="w-100 p-2 h-100">

                        <div className="ml-4 d-flex flex-column justify-content-center pl-4 h-100" style={{ borderLeft: '1px solid white' }}>
                            <span className="blue">{selectedSeason.SeasonName || selectedSeason.Name}</span>
                            <span className="white">ID: {user.IdUser}</span>
                            <span className="white">Order: {this.state.idOrderItem}</span>
                            <span className="white">Auto-Pay: {autoPaySettings && autoPaySettings.IsAutoPay ? 'ON' : 'OFF'}</span>
                            <div className="d-flex flex-row white">
                                <i className="fa fa-tshirt align-self-center" />
                                <span className="align-self-center">Shirt Size:{' '}{user.ShirtSize || 'N/a'}<br />Short Size:{' '}{user.ShortSize || 'N/a'}</span>
                            </div>

                            <div className="w-100 mt-2">

                                { /* Status */}
                                <button className="btn btn-link d-flex flex-row align-self-center pl-0" id="btnStatus" onClick={() => this.setState({ changing_status: true })}>
                                    <i className={`fas fa-circle align-self-center`} style={{ color: (_.find(config.roleStatuses, (st) => { return st.IdRoleStatus === (selectedSeason || user).IdRoleStatus }) || { color: 'white' }).color }} />
                                    <span className="idented-half align-self-center white">Status: {(selectedSeason || user).RegistrationStatus || (selectedSeason || user).RoleStatus}</span>
                                    <i className="fa fa-caret-down ml-2 align-self-center white" />
                                </button>

                                { /* Status Popover */}
                                <Popover placement="bottom" isOpen={this.state.changing_status} target="btnStatus" toggle={() => this.setState({ changing_status: false })}>
                                    <PopoverBody className="d-flex flex-column">
                                        {this.props.statuses && this.props.statuses.map((s, i) => (
                                            <button key={i} className="btn btn-link btn-sm btn-block d-flex flex-row" onClick={() => this.assignStatus(s)}>
                                                <i className={`fas fa-circle align-self-center margin-right`} style={{ color: _.find(config.roleStatuses, (st) => { return st.IdRoleStatus === s.IdRoleStatus }).color }} />
                                                {' '}
                                                {s.RegistrationStatus}
                                            </button>))}
                                    </PopoverBody>
                                </Popover>
                            </div>
                        </div>

                    </div>

                    <div className="w-50 p-2 align-self-center">

                        <div className="ml-4 d-flex flex-column justify-content-center pl-4 h-100">
                            <PlayerTeams {...this.props.params} />
                        </div>

                    </div>
                </Animated>
            )}

            {user && bootstrapped && questions && (
                <div className="w3-container mt-2  mb-4">

                    <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>

                        <div className="d-flex flex-row mt-3">
                            <div style={{ width: 300 }}>
                                <Animated animationIn="fadeInUp" style={{ overflow: 'hidden', borderRadius: 5, minWidth: 300, }} className="card shadow">

                                    <ShirtNumberEdit user={user} season={selectedSeason} />

                                    <div onClick={() => this.setState({ tab: 1 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 1 ? 'gray-darkish' : 'white'}`}>
                                        <span className="black font-14 ml-3 align-self-center">Registration</span>
                                        <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                    </div>
                                    <div onClick={() => this.setState({ tab: 2 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 2 ? 'gray-darkish' : 'white'}`}>
                                        <span className="black font-14 ml-3 align-self-center">Accounting</span>
                                        <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                    </div>
                                    <div onClick={() => this.setState({ tab: 4 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 4 ? 'gray-darkish' : 'white'}`}>
                                        <span className="black font-14 ml-3 align-self-center">Disclaimer</span>
                                        <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                    </div>
                                    <div onClick={() => this.setState({ tab: 5 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 5 ? 'gray-darkish' : 'white'}`}>
                                        <span className="black font-14 ml-3 align-self-center">Validations</span>
                                        <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                    </div>
                                    <div onClick={() => this.setState({ tab: 6 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 6 ? 'gray-darkish' : 'white'}`}>
                                        <span className="black font-14 ml-3 align-self-center">Notes</span>
                                        <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                    </div>
                                    <div onClick={() => this.setState({ tab: 7 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 7 ? 'gray-darkish' : 'white'}`}>
                                        <span className="black font-14 ml-3 align-self-center">Violations</span>
                                        <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                    </div>
                                </Animated>

                                <Animated animationIn="fadeInUp" style={{ overflow: 'hidden', borderRadius: 5, minWidth: 300 }} className="card shadow mt-4" animationInDelay={1000}>
                                    <FamilyCard user={user} family={family} />
                                </Animated>
                            </div>

                            <div style={{ width: 30 }} className="" />

                            <div className="w-100">
                                <Animated animationIn="fadeInRight" className="w-100 d-flex flex-column card shadow" style={{ borderRadius: 5 }}>
                                    {tab === 1 && <RegistrationTab seasons={seasons} selectedSeason={selectedSeason} questions={questions}
                                        statuses={this.props.statuses} user={user} />}

                                    {tab === 2 && fullBalance && <AccountingTab user={user} payments={payments} balance={balance} pastDue={pastDue}
                                        fnSelectSeason={this.selectSeason}
                                        selectedSeason={selectedSeason} seasons={seasons}
                                        fnAddCharge={this.toggleAddCharge} fnAddCredit={this.toggleAddCredit}
                                        fnMakePayment={this.toggleMakePayment} fnDeletePayment={this.fnDeletePayment}
                                        fnMakeRefund={this.fnMakeRefund} fnChangePlan={() => this.setState({ changingPlan: true })}
                                        fullBalance={fullBalance} dues={this.state.dues} {...this.props} />}
                                    {tab === 4 && <DisclaimerTab disclaimer={disclaimer} />}

                                    {tab === 5 && <UserValidations idUser={user.IdUser} idLeague={selectedSeason.IdLeague} {...this.props} />}

                                    {tab === 6 && <UserNotes idUser={user.IdUser} idLeague={selectedSeason.IdLeague} {...this.props} />}

                                    {tab === 7 && <Violations idUser={user.IdUser} 
                                    idTeamUserRole={user.IdTeamUserRole || selectedSeason.IdTeamUserRole} 
                                    idSeason={selectedSeason.IdSeason} idCommunity={season.IdCommunity} {...this.props} />}

                                </Animated>
                            </div>
                        </div>
                    </div>

                </div>
            )}

            {!bootstrapped && <Loader message="Loading Profile" />}

            {updating && <Loader className="overlay" message="Updating Profile" />}

            {addingCharge && <AddChargeModal isOpen={addingCharge} toggle={this.toggleAddCharge} idOrderItem={this.state.idOrderItem} fnRefreshPayments={this.fetchPayments} />}

            {addingCredit && <AddCreditModal isOpen={addingCredit} toggle={this.toggleAddCredit} idOrderItem={this.state.idOrderItem} fnRefreshPayments={this.fetchPayments} />}

            {moving && <MovePlayerModal idOrderItem={this.state.idOrderItem} fnMoved={this.fnMoved} balance={this.state.totalAmount} {...this.props} isOpen={moving} toggle={this.toggleMoving} paid={paid} fullBalance={fullBalance}
                seasons={allSeasons} user={user} selectedSeason={selectedSeason} />}

            {makingPayment && <MakePaymentModal fnUpdate={this.fetchPayments} isOpen={makingPayment}
                toggle={this.toggleMakePayment} idOrderItem={this.state.idOrderItem} idSeason={this.state.selectedSeason.IdSeason}
                idLeague={this.props.season.IdLeague} idUser={user.IdUser} max={balance} />}

            {makeRefund && <MakeRefundModal fnUpdate={this.fetchPayments} isOpen={makeRefund} toggle={this.toggleRefund} selectedPayment={selectedPayment} fnMakeRefund={this.fnMakeRefund} fnDeletePayment={this.fnDeletePayment} />}

            {changingPlan && <ChangePlanModal fnChangePlan={this.fnChangePlan} isOpen={changingPlan} toggle={() => this.setState({ changingPlan: false })} season={selectedSeason} fullBalance={fullBalance} />}

        </section>
    }
}

export default AdminUserProfile;
