import React from 'react';
import Player from './player';
import PlayerLong from './player_long';

class Players extends React.Component {
    render() {
        const { players, onClick, width = 'w-25', long = false, game, team, rosterActions, season } = this.props;
        return <section>
            <div style={{ minHeight: long ? null : 200 }} className="d-flex flex-wrap justify-content-start px-2">
                {long === false && players.map((user, i) => <Player season={season} width={width} key={i} player={user} onClick={() => onClick(user)} color="#CCCCCC" />)}
                {long === true && players.map((user, i) => <PlayerLong season={season} rosterActions={rosterActions} team={team} game={game} key={i} player={user} onClick={() => onClick(user)} color="#CCCCCC" />)}
            </div>
        </section>
    }
}

export default Players;