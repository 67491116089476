import React from 'react';
import './css.css';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import TeamsTable from './table';
import TeamSkeak from '../../../common/modals/TeamSkeak';
import Navigation from '../navigation';

class EditTeams extends React.Component {

    state = {
        modal: false,
        mode: 1, // [ 1: list, 2: tiles ]
        bootstrapped: false,
        idDivision: null,
        selectedDivision: null,
        isDivisionFilterOpen: false,
        selectedTeam: null,
        search_text: null
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, params } = this.props;
        setTitle && setTitle({
            sub: 'Teams',
            main: this.props.season.Name || null
        });

        this.props.fetchPositions && this.props.fetchPositions();
        this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Teams',
                main: nextProps.season.Name || null
            });

            this.props.leagueActions &&
                this.props.leagueActions.fetchTournamentSeasons(nextProps.season.IdLeague);
        }

        if (nextProps.divisions && nextProps.divisions.length) {
            // See if the division is pre-selected from other sections
            let idDivision = parseInt(localStorage.getItem("GLOBAL.SELECTED_DIVISION"), 10);
            let selectedDivision = _.find(nextProps.divisions, (division) => division.IdDivision === idDivision) || _.first(nextProps.divisions);
            this.setState({
                bootstrapped: true,
                selectedDivision,
                idDivision: (selectedDivision || {}).IdDivision
            });
        }
    }
    componentWillUnmount = () => {
        this.props.scheduleManagerDivisionsActions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions();
    }
    //

    // Toggles
    toggleList = () => {
        this.setState({
            mode: 1
        });
    }
    toggleTiles = () => {
        this.setState({
            mode: 2
        })
    }
    toggleDivisionPicker = () => {
        this.setState({
            isDivisionFilterOpen: !this.state.isDivisionFilterOpen
        })
    }

    selectDivision = (division) => {
        this.setState({
            selectedDivision: division,
            idDivision: division.IdDivision
        });

        localStorage.setItem("GLOBAL.SELECTED_DIVISION", division.IdDivision);
    }

    scrollToBottom = () => this.bracketsEnd.scrollIntoView({ behavior: "smooth" });

    addTeam = () => {
        const { selectedDivision } = this.state;
        selectedDivision.Teams.push({
            editing: true,
            IdDivision: selectedDivision.IdDivision
        });
        this.forceUpdate();
    }

    onChange = () => { this.setState({ search_text: this.txtSearch.value }); }

    toggleModal = () => this.setState({ modal: !this.state.modal });

    selectTeam = (team) => this.setState({ selectedTeam: team, modal: true });

    deleteTeam = () => {
        const { selectedTeam, selectedDivision } = this.state;
        selectedDivision.Teams = _.reject(selectedDivision.Teams, (t) => { return t.IdTeamDivision === selectedTeam.IdTeamDivision });
        this.setState({
            selectedDivision,
            selectedTeam: null,
            modal: false
        });
    }

    render() {
        const { bootstrapped, idDivision, selectedDivision, mode, selectedTeam, modal, error, updated, search_text } = this.state,
            { divisions, season, positions } = this.props;        

        return (<section>

            <div className="w3-container mt-4">

                <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                    <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                        {this.state.error}
                    </Alert>
                    <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
                        Season updated!
                    </Alert>
                </div>

                {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                    <div className="w-100 black">
                        <h3>{season.Name || season.SeasonName}</h3>
                        <Link to={`/leagues/${season.IdLeague}`} className="blue"><h6 className="block">{season.LeagueName} Seasons</h6></Link>
                    </div>
                </div>}

                <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
                    <h5>Team Management</h5>

                    {this.props.season && <Navigation season={this.props.season} />}

                    {(bootstrapped) && <Animated isVisible={bootstrapped ? true : false} animationIn="fadeIn" className="card shadow mt-3">

                        <div className="d-flex flex-row montserrat p-3" style={{ maxWidth: 1285 }}>

                            <div className="black">
                                <h1>Teams</h1>
                            </div>

                            <div className="ml-auto d-flex flex-row">
                                <input placeholder="Search Team" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />

                                <Dropdown className="align-self-center ml-2" isOpen={this.state.isDivisionFilterOpen} toggle={this.toggleDivisionPicker}>
                                    <DropdownToggle color="info" caret>
                                        {selectedDivision ? `${selectedDivision.Name} - (${selectedDivision.Teams.length} team${selectedDivision.Teams.length === 1 ? '' : 's'})` : 'Select a division'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {divisions.map((division, i) =>
                                            <DropdownItem active={idDivision === division.IdDivision ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                                {division.Name} - <span className={idDivision === division.IdDivision ? 'white' : 'blue'}>({division.Teams.length} team{division.Teams.length === 1 ? '' : 's'})</span>
                                            </DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>

                                <button onClick={this.addTeam} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 300 }} className="green ml-2 p-2 align-self-center block">
                                    <i className="fas fa-plus margin-right-half" />Add Team</button>
                            </div>
                        </div>

                        { /* Divisions */}
                        <div className="ml-4 mr-4" key="table">
                            <TeamsTable search={search_text} division={selectedDivision} mode={mode} season={season}
                                fnSelectTeam={this.selectTeam} positions={positions} leaguePoints={this.props.leaguePoints}
                                teamActions={this.props.teamActions} />

                            <button onClick={this.addTeam} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 300 }} className="green mb-4 mx-auto p-2 align-self-center block">
                                <i className="fas fa-plus margin-right-half" />Add Team</button>
                        </div>
                        <div key="scrollTo" ref={(el) => { this.bracketsEnd = el; }} />
                    </Animated>}

                    <div style={{ height: 100 }} />
                </div>
            </div>

            {!bootstrapped && <Loader key="loader" message={`Loading Divisions`} />}
            { /* Modal */}
            {selectedTeam && modal && (
                <TeamSkeak season={season} team={selectedTeam} isOpen={modal} toggle={this.toggleModal} fnDelete={this.deleteTeam} />)}

        </section>

        )
    }
}

export default EditTeams;
