import React from 'react';
import './signature-pad.css';

var SignaturePad = require('react-signature-pad');


class Signature extends React.Component {

    render(){
        return <div><SignaturePad ref="signaturePad" clearButton="true" /></div>
    } 
}

export default Signature;