import React from 'react';
import { Link } from 'react-router';
import { formatPrice } from '../../../../helpers';
import moment from 'moment';
import _ from 'lodash';


class NonTournamentList extends React.Component {
  state = {
    sort: 'OrderDateCreated',
    up: false
  };
  componentWillMount() {
    const { questions, report } = this.props;

    this.setState({ questions, report });
  }
  onSort = (sort) => {
    if (this.state.sort === sort) {
      this.setState({ up: !this.state.up });
    } else {
      this.setState({ sort });
    }
  }
  render() {
    const { report, sort, up } = this.state, { questions } = this.props;
    let sorted = _.chain(report)
      .sortBy(sort).value();
    if (up) sorted = sorted.reverse();
    return (
      <table id="table-to-xls" className="table table-sm font-10">
        <thead>
          <tr>
            <th>Id</th>
            <th className={`pointer underlined ${sort === 'NameFirst' ? 'blue' : 'black'}`} onClick={() => this.onSort('NameFirst')}>{sort === 'NameFirst' && <i className={`fas fa-caret-${up ? 'up' : 'down'}`} />}Name</th>
            <th className={`pointer underlined ${sort === 'NameLast' ? 'blue' : 'black'}`} onClick={() => this.onSort('NameLast')}>{sort === 'NameLast' && <i className={`fas fa-caret-${up ? 'up' : 'down'}`} />}Last Name</th>
            <th>DoB</th>
            <th>Gender</th>
            <th>Team</th>
            <th>Role</th>
            <th>Status</th>
            <th>Transaction Id</th>
            <th className={`pointer underlined ${sort === 'OrderDateCreated' ? 'blue' : 'black'}`} onClick={() => this.onSort('OrderDateCreated')}>{sort === 'OrderDateCreated' && <i className={`fas fa-caret-${up ? 'up' : 'down'}`} />}Reg. Date</th>
            <th>Division</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>ZipCode</th>
            <th>Charges</th>
            <th>Payments</th>
            <th>Balance</th>
            <th>Refund</th>
            <th>Reg. Status</th>
            <th>Coupon Used</th>
            <th>Amount per coupon</th>
            {questions && questions.map((q, i) => <th key={i}>
              {q.QuestionText}
            </th>)}
          </tr>
        </thead>
        <tbody>
          {report && sorted.map((record, i) => (
            <tr key={i}>
              <td className="font-8">
                {record.IdUser}
              </td>
              <td>
                <Link to={`/season/${record.IdSeason}/player/${record.IdUser}/${record.IdTeamUserRole}`} className="blue underlined">{record.NameFirst}</Link>
              </td>
              <td>
                <Link to={`/season/${record.IdSeason}/player/${record.IdUser}/${record.IdTeamUserRole}`} className="blue underlined">{record.NameLast}</Link>
              </td>
              <td>
                {moment(record.DateBirth).utc().format('MM/DD/YYYY')}
              </td>
              <td>
                {record.Gender}
              </td>
              <td>
                {record.TeamName}
              </td>
              <td>
                {record.RoleOverride}
              </td>
              <td>{record.RoleStatus}</td>
              <td>{record.IdTransaction || record.TransactionId || record.ExternalId}</td>
              <td>
                {record.OrderDateCreated ? moment(record.OrderDateCreated).utc().format('MM/DD/YYYY') : 'N/a'}
              </td>
              <td>
                {record.DivisionName}
              </td>
              <td>
                {record.EmailAddress}
              </td>
              <td>
                {record.PhoneNumber}
              </td>
              <td>
                {record.Address}
              </td>
              <td>
                {record.City}
              </td>
              <td>
                {record.State}
              </td>
              <td>{record.ZipCode}</td>
              <td align="right">
                {formatPrice(record.Charges * 100)}
              </td>
              <td align="right">
                {formatPrice(record.Payments * 100)}
              </td>
              <td align="right">
                {formatPrice(record.Balance * 100)}
              </td>
              <td align="right">
                {formatPrice((record.Refund || 0) * 100)}
              </td>
              <td className={record.OrderStatus}>
                {record.OrderStatus}
              </td>
              <td>
                {record.CouponName}
              </td>
              <td align="right">
                {formatPrice((record.AmountPerCoupon || 0) * 100)}
              </td>
              {questions && questions.map((q, i) => <td key={i}>
                {record[`question-${q.IdQuestion}`]}
              </td>)}
            </tr>)
          )}
        </tbody>
      </table>
    )
  }
}

export default NonTournamentList;
