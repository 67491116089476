import React from 'react';
import moment from 'moment';

class CustomDatePickerLabel extends React.Component {

    render() {
        const { value, className, format = 'ddd, MMM Do YYYY' } = this.props;        
        return (
            <span onClick={this.props.onClick} className={`${className}`}>
                {value ? moment(this.props.value).format(format) : 'Select Date'}
            </span>
        )
    }
}

export default CustomDatePickerLabel;