import React from 'react';
import FlightPlacementDraggable from './FlightPlacementDraggable';

class FlightPlacements extends React.Component {

    render

    render() {
        const { division, teams, placements } = this.props;

        return (
            <ul className="block d-flex flex-column no-style-lists no-margin no-padding bg-white margin-bottom-half">
                {teams.length > 0 && division.Flights.map((flight, i) => <section key={i}>
                    {flight.FlightedTeams.map((team, j) => <FlightPlacementDraggable key={j} index={j + 1} flight={flight} placements={placements} />)}</section>)}
                {!teams.length && <div className="jumbotron no-margin"><i className="fa fa-exclamation"></i> There are no Flights with Teams in {division.Name}.</div>}
            </ul>
        )
    }

}

export default FlightPlacements;