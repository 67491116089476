import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import _ from 'lodash';
import Tab from './tab';
import Preview from './preview';
import { ItemTypes, generateRandomId } from '../../../../../../helpers';

class Grouper extends React.Component {

  state = {
    activeTab: 1,
    error: null,
  }

  componentWillMount() {
    const { group } = this.props;
    this.props.pouchActions &&
      this.props.pouchActions.set({
        id: group ? group.IdQuestionLibraryGroup : generateRandomId(10),
        IdQuestionLibraryGroup: group ? group.IdQuestionLibraryGroup : null,
        name: group ? group.name : '',
        qandas: group ? group.qandas : []
      });
  }

  changeTab = (tab) => {
    this.setState({
      activeTab: tab
    });
    this.props.notifyTabChange &&
      this.props.notifyTabChange(tab)
  }

  saveGroup = () => {
    const { pouch, toggle, group, season } = this.props;

    if (pouch.IdQuestionLibraryGroup) {

      // TODO: check what changed: Potentially update the name, delete the 'deleted' qandas, and add the brand new ones
      group.qandas = _.reject(pouch.qandas, q => { return q.deleted });
      group.name = pouch.name;
      this.props.questionsActions &&
        this.props.questionsActions.updateGroup(group);

    } else {

      pouch.IdLeague = season.IdLeague;
      this.props.questionsActions &&
        this.props.questionsActions.addGroup(pouch);

    }
    toggle();
  }

  onDelete = () => {
    const { toggle, pouch, season } = this.props;
    // TODO: call api
    this.props.questionsActions &&
        this.props.questionsActions.removeGroup(pouch.IdQuestionLibraryGroup, pouch.IdLeague || season.IdLeague);
    toggle && toggle();
  }

  renderModal = () => {
    const { isOpen, toggle, pouch } = this.props,
      { error } = this.state;
    return <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody >
        {this.renderTheThing()}
      </ModalBody>
      {error && <ModalBody className="bg-danger white p-2 font-10">{error.message || 'An error has occurred'}</ModalBody>}
      { !this.state.deleting && <ModalFooter>
        { pouch && pouch.IdQuestionLibraryGroup && <button onClick={() => this.setState({ deleting: true })} className="btn btn-danger btn-sm mr-auto">Delete</button> }
        <button className="btn btn-link" onClick={toggle}>Close</button>
        <button className="btn btn-success btn-sm" onClick={this.saveGroup}>Save Group</button>
      </ModalFooter> }

      { this.state.deleting && <ModalFooter>
        <span className="mr-auto black">Are you sure?</span>
        <button className="btn btn-link" onClick={() => this.setState({ deleting: false })}>No, cancel</button>
        <button className="btn btn-danger btn-sm" onClick={this.onDelete}>Yes, delete it</button>
      </ModalFooter> }
    </Modal>
  }

  renderTheThing = () => {
    const { activeTab } = this.state;

    return <div className="d-flex flex-row no-margin no-padding clearfix h-100" >
      <div className="p-2 flex-shrink-1 bg-info d-flex flex-column white justify-content-center font-11">
        <span className="align-self-center">Q</span>
        <span className="align-self-center">U</span>
        <span className="align-self-center">E</span>
        <span className="align-self-center">S</span>
        <span className="align-self-center">T</span>
        <span className="align-self-center">I</span>
        <span className="align-self-center">O</span>
        <span className="align-self-center">N</span>
        <span className="align-self-center margin-top"></span>
        <span className="align-self-center">G</span>
        <span className="align-self-center">R</span>
        <span className="align-self-center">O</span>
        <span className="align-self-center">U</span>
        <span className="align-self-center">P</span>
      </div>
      <section className="w-100 d-flex flex-row">
        <div className="w-100">
          {/* Tabs */}
          <ul className="nav nav-tabs font-16">
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
                <i className="fas fa-font blue"></i> {activeTab === 1 ? <span className="half-idented">Free Text</span> : ''}</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}>
                <i className="far fa-dot-circle purple"></i> {activeTab === 2 ? <span className="half-idented">Radio Button</span> : ''}</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}>
                <i className="far fa-check-square red"></i> {activeTab === 3 ? <span className="half-idented">Check Box</span> : ''}</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={() => this.changeTab(4)}>
                <i className="far fa-caret-square-down black"></i> {activeTab === 4 ? <span className="half-idented">Dropdown</span> : ''}</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 5 ? 'active' : ''}`} onClick={() => this.changeTab(5)}>
                <i className="fas fa-list green"></i> {activeTab === 5 ? <span className="half-idented">Check List</span> : ''}</button>
            </li>
          </ul>
          <ReactCSSTransitionGroup className="tab-content" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
            transitionEnter={true} transitionLeave={false}>
            {/* Free Text */}
            {activeTab === 1 && <Tab {...this.props} itemType={ItemTypes.FREETEXT} />}
            {/* Radio */}
            {activeTab === 2 && <Tab {...this.props} itemType={ItemTypes.RADIO} />}
            {/* Check */}
            {activeTab === 3 && <Tab {...this.props} itemType={ItemTypes.CHECK} />}
            {/* Select */}
            {activeTab === 4 && <Tab {...this.props} itemType={ItemTypes.SELECT} />}
            {/* Check List */}
            {activeTab === 5 && <Tab {...this.props} itemType={ItemTypes.CHECKLIST} />}
          </ReactCSSTransitionGroup>
        </div>
        {/* Group Preview */}
        <Preview  {...this.props} />
      </section>
    </div>
  }

  render() {
    const { modal } = this.props;
    if (modal)
      return this.renderModal();
    return this.renderTheThing();
  }
}

export default Grouper;
