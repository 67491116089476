import _ from 'lodash';

function reducer(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'QUESTION_TEXTS/SET':
            return action.questions;
        case 'QUESTION_TEXTS/ADD':
            return [...(state || []), action.question];
        case 'QUESTION_TEXT/REMOVE':
            index = _.findIndex(state, questionText => { return (questionText.IdQuestionLibrary || questionText.id) === action.id });
            if (index !== -1) {
                return [...state.slice(0, index), ...state.slice(index + 1)];
            } else return state;
        case 'QUESTION_TEXT/UPDATE':
            index = _.findIndex(state, questionText => { return questionText.id === (action.question.id || action.question.IdQuestionLibrary) });
            if (index !== -1) {
                return [...state.slice(0, index), action.question, ...state.slice(index + 1)];
            } else return state;
        case 'QUESTION_TEXT/SELECT':
            state && state.forEach(q => {
                if (q.id !== action.question.id)
                    q.selected = false;
            });
            index = _.findIndex(state, questionText => { return questionText.id === (action.question.id || action.question.IdQuestionLibrary) });
            if (index !== -1) {
                return [...state.slice(0, index), action.question, ...state.slice(index + 1)];
            } else return state;
        case 'QUESTIONS/UNSELECT_ALL':
            state && state.forEach(q => {
                q.selected = false;
            });
            return state;
        default:
            return state;
    }
}

export default reducer;
