function reducer(state = [], action) {

  switch (action.type) {
    case 'REPORT/SET':
      return action.report;
    case 'REPORT/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;