import React from 'react';
import { Link } from 'react-router';

class TopMenu extends React.Component {
    render() {
        const { idLeague, letter } = this.props;
        return <div className="d-flex flex-row w-100 mt-2" style={{ height: 50, backgroundColor: '#4469C7', borderRadius: 5, overflow: 'hidden' }}>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/dob/${letter || 'A'}`} 
            className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Date of Birth</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/coaches/${letter || 'A'}`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Coach Certificate</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/safe/${letter || 'A'}`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Safe Sport</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/concussion/${letter || 'A'}`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Intro C&C</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/background/${letter || 'A'}`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Background Check</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/fingerprints/${letter || 'A'}`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Fingerprint</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/photos/${letter || 'A'}`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Photo</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/validations/${idLeague}/medical/${letter || 'A'}`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Medical</Link>
        </div>
        
    }
}

export default TopMenu;