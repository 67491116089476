import _ from 'lodash';

function reducer(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'QUESTION_GROUPS/SET':
            return action.groups;
        case 'QUESTION_GROUPS/ADD':
            return [...(state || []), action.group];
        case 'QUESTION_GROUPS/UPDATE':
            index = _.findIndex(state, q => { return q.id === (action.group.id || action.group.IdQuestionLibraryGroup) });
            if (index !== -1) {
                return [...state.slice(0, index), action.group, ...state.slice(index + 1)];
            } else return state;
        case 'QUESTION_GROUP/SET':
            index = _.findIndex(state, q => { return q.id === (action.group.id || action.group.IdQuestionLibraryGroup) });
            if (index !== -1) {
                return [...state.slice(0, index), action.group, ...state.slice(index + 1)];
            } else return state;
        case 'QUESTION_GROUPS/CLEAR':
            return null;
        case 'QUESTION_GROUPS/REMOVE':
            return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        default:
            return state;
    }
}

export default reducer;
