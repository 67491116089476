import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import Loader from '../../../common/Loader';
import _ from 'lodash';
import Lists from './partials/list';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import Navigation from '../navigation';


class Waitlists extends React.Component {

  state = {
    bootstrapped: false,
    selectedDivision: null,
    isDivisionsOpen: false,
    fetchingWaitlists: false,
    coaches: null
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Waitlists',
      main: this.props.season ? this.props.season.Name : null
    });

    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {

    if (nextProps.season !== this.props.season) {

      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Waitlists',
        main: nextProps.season.Name || null
      });
    }

    if (nextProps.divisions && nextProps.season && nextProps.season.IdSeason && !this.state.bootstrapped) {
      this.setState({
        bootstrapped: true,
        divisions: _.reject(nextProps.divisions, d => { return false; /* d.IsTryout;*/ }),
        selectedDivision: _.find(nextProps.divisions, d => { return true; /*!d.IsTryout;*/ }),
      }, () => {
        if (this.state.divisions.length)
          this.fetchWaitlists();
        else {
          alert('there are no tryout divisions');
        }
      });
    }

    if (nextProps.waitlists && nextProps.accepted) {
      this.setState({ fetchingWaitlists: false });
    }
  }

  componentWillUnmount = () => {
    this.props.clearDivisions && this.props.clearDivisions();
    this.props.waitlistsActions && this.props.waitlistsActions.cleanup();
  }
  //

  fetchWaitlists = () => {
    const { selectedDivision } = this.state, { season } = this.props;
    if ([3, 5].indexOf(season.IdSeasonType !== -1)) {
      this.props.waitlistsActions && this.props.waitlistsActions.fetchTeams(selectedDivision.IdDivision);
    } else {
      this.props.waitlistsActions && this.props.waitlistsActions.fetch(selectedDivision.IdDivision);
    }
  }

  toggleDivisions = () => {
    this.setState({ isDivisionsOpen: !this.state.isDivisionsOpen });
  }

  selectDivision = (selectedDivision) => {
    this.setState({ selectedDivision, isDivisionsOpen: false }, () => {
      this.fetchWaitlists();
    });
  }

  render() {

    const { bootstrapped, selectedDivision, fetchingWaitlists, divisions, error, updated } = this.state,
      { waitlists, season } = this.props;

    let filteredDivisions = divisions; //_.reject(divisions, d => d.IsTryout);

    return (
      <section>
        <div className="w3-container mt-4">

          <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
            <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
              {this.state.error}
            </Alert>
            <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
              Season updated!
            </Alert>
          </div>

          {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
            <div className="w-100 black">
              <h3>{season.Name || season.SeasonName}</h3>
              <Link to={`/leagues/${season.IdLeague}`} className="blue"><h6 className="block">{season.LeagueName} Seasons</h6></Link>
            </div>
          </div>}

          <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
            <h5>Team Management</h5>

            {this.props.season && <Navigation season={this.props.season} />}

            {bootstrapped && selectedDivision && <Animated isVisible={bootstrapped ? true : false} animationIn="fadeIn" className="card shadow mt-3">

              <div className="d-flex flex-row montserrat p-3" style={{ maxWidth: 1285 }}>

                <div className="black">
                  <h1>Waitlists</h1>
                </div>

                <div className="d-flex flex-row w-100">
                  <span className="align-self-center black ml-auto">Division</span>
                  <Dropdown className="ml-2 align-self-center" isOpen={this.state.isDivisionsOpen} toggle={this.toggleDivisions}>
                    <DropdownToggle caret className="btn-white black bordered btn align-self-center">
                      {selectedDivision.Name}
                    </DropdownToggle>
                    <DropdownMenu>
                      {filteredDivisions && filteredDivisions.map((division, i) => (
                        <DropdownItem key={i} onClick={() => this.selectDivision(division)}>
                          {division.Name} (
                          {division.Teams.length} teams)
                        </DropdownItem>))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>

              { /* Divisions */}
              <div className="h-100 ml-4 mr-4" style={{ position: 'relative', minHeight: 650 }}>
                {bootstrapped && waitlists && season && (
                  <Lists {...this.props} selectedDivision={selectedDivision} />)}
              </div>

              <div key="scrollTo" ref={(el) => { this.bracketsEnd = el; }} />

            </Animated>}
          </div>

        </div>

        {(!bootstrapped || fetchingWaitlists) && <Loader message="Fetching Assets" />}

      </section>)
  }
}

export default Waitlists;
