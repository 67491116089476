import React from 'react';
import './build.css';
import _ from 'lodash';
import moment from 'moment';


class FlightListItem extends React.Component {

    state = {
        flight: null,
        bootstrapped: false
    }

    setup = props => {
        const { flight, fieldAvailabilityRules, gameDayRules } = props;
        let fars = _.filter(fieldAvailabilityRules, far => _.map(flight.FieldAvailabilityRules, f => f.IdRule).indexOf(far.IdScheduleFieldAvailability) !== -1);
        let gdrs = _.filter(gameDayRules, grd => _.map(flight.GameDayRules, f => f.IdRule).indexOf(grd.Id) !== -1);
        let total_slots_per_season = _.chain(fars).map(r => r.TOTAL_SLOTS || 0).reduce((a, b) => a + b, 0).value();
        flight.TOTAL_SLOTS_FOR_SEASON = total_slots_per_season;

        // stablish gdrs weeks
        let CALCULATIONS = {};
        CALCULATIONS.WEEKS_RANGES = [];
        fars.forEach(gdr => {
            gdr.WEEKS_RANGE = [moment(gdr.Availability_Start).week(), moment(gdr.Availability_End).week()];
            for (var i = gdr.WEEKS_RANGE[0]; i <= gdr.WEEKS_RANGE[1]; i++) {
                CALCULATIONS.WEEKS_RANGES.push(i);
            }
            gdr.TOTAL_WEEKS = (moment(gdr.Availability_End).week() - moment(gdr.Availability_Start).week()) + 1;
        });
        CALCULATIONS.WEEKS_RANGES = _.uniq(CALCULATIONS.WEEKS_RANGES);
        CALCULATIONS.WEEKS_AVAILABLE = CALCULATIONS.WEEKS_RANGES.length;
        // Some calculations
        flight.fars = fars;
        flight.gdrs = gdrs;

        CALCULATIONS.POSSIBLE_GAMES_PER_WEEK = (_.first(gdrs) || {}).PossibleGamesPerWeek;

        CALCULATIONS.TOTAL_SLOTS_PER_WEEK = flight.TOTAL_SLOTS_FOR_SEASON / CALCULATIONS.WEEKS_RANGES.length;
        CALCULATIONS.ROUNDS_NEEDED_TO_COMPLETE = flight.MinimumGamesPerTeam + (flight.FlightedTeams.length % 2 ? 1 : 0);
        CALCULATIONS.WEEKS_NEEDED_TO_COMPLETE = Math.round(CALCULATIONS.ROUNDS_NEEDED_TO_COMPLETE / CALCULATIONS.POSSIBLE_GAMES_PER_WEEK);
        if (flight.HasPairings) {
            CALCULATIONS.ERROR_MESSAGE = `This flight already has pairings`;
        }
        if (CALCULATIONS.WEEKS_NEEDED_TO_COMPLETE > CALCULATIONS.WEEKS_AVAILABLE) {
            CALCULATIONS.ERROR_MESSAGE = `Not enough weeks (${CALCULATIONS.WEEKS_AVAILABLE}) to complete rounds needed (${CALCULATIONS.ROUNDS_NEEDED_TO_COMPLETE})`;
        }
        flight.blocked = false;
        if (!flight.GameDayRules.length && !this.props.pairings) {
            CALCULATIONS.ERROR_MESSAGE = `This flight has no Game Day rules applied`;
            flight.blocked = true;
        }
        flight.CALCULATIONS = CALCULATIONS;
        //

        this.setState({ flight: props.flight, result: _.find(props.results, r => r.IdFlight === props.flight.IdFlight) }, () => {
            if (!this.state.bootstrapped) {
                this.setState({ bootstrapped: true });
            }
        })
    }

    componentWillMount() {
        this.setup(this.props);
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps);
    }

    toggleFlightCheckedForSchedule = () => {
        const { flight } = this.state;
        if (!flight.blocked) {
            flight.checkedForSchedule = !flight.checkedForSchedule;
            this.setState({ flight });
        }
    }

    render() {
        const { flight } = this.state;

        return (
            <li className={`p-1 no-margin no-padding block d-flex flex-row`} style={{ borderBottom: '1px solid #e5e5e5' }}>
                <button className="btn btn-link btn-sm" onClick={this.toggleFlightCheckedForSchedule}>
                    {!flight.blocked && <i className={`far fa-${flight.checkedForSchedule ? 'check-square' : 'square'}`} />}
                    {flight.blocked && <i className={`fas fa-warning red`} />}
                </button>
                <span className="ml-2 align-self-center">{`${flight.FlightName}`} {flight.CALCULATIONS.ERROR_MESSAGE && <span className="font-8 align-self-center red"><i className="idented fas fa-exclamation orange mr-1" />{flight.CALCULATIONS.ERROR_MESSAGE}</span>}</span>
                <span className="font-8 align-self-center ml-auto mr-2 jeff_blue">{`(Slots assigned: ${flight.TOTAL_SLOTS_FOR_SEASON})`}</span>
            </li>
        )
    }
}

export default FlightListItem;