import React from 'react';
import './menu.css';
import { Link } from 'react-router';

class RosterMenu extends React.Component {

    state = {
        tree: null,
        lastPath: ''
    }

    render() {
        const { selectedTeam = {}, params } = this.props, { idTeam } = params;

        return (<section id="left-menu" className="bg-dark-blue">            

            {idTeam && <div className="d-flex flex-column">
                <div style={{ height: 30 }} />
                {selectedTeam && <div className="cover align-self-center" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid black', background: `url(${selectedTeam.TeamImageUrl}) no-repeat center center white` }}></div>}
                {selectedTeam && <span className="white mt-3 line1 font-14 align-self-center text-center ml-2 mr-2">{selectedTeam.Name || selectedTeam.TeamName}</span>}
                <div style={{ height: 30 }} />
            </div>}

            <div key="home" className={`menu-wrapper off`}>
                <Link className={`btn d-flex flex-row drop btn-off btn-block white`} to={idTeam ? `/roster` : '/family'} activeClassName="btn-on" onlyActiveOnIndex={true}>
                    <i className={` align-self-center big-font icon fa fa-arrow-left`} />
                    <span className=" align-self-center font-8 toUpper name">{idTeam ? 'Teams' : 'Home'}</span>
                </Link>
            </div>

        </section>)
    }
}

export default RosterMenu;
