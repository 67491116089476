import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import { Link } from 'react-router';
import { formatPrice } from '../../../helpers';

class Details extends React.Component {

    state = {
        teams: null,
        fetchingTeams: true,
        searchText: null
    }

    onChange = e => {
        this.setState({ searchText: this.txtSearch.value });
    }

    renderUser = group => {
        let user = _.first(group);
        let amount = _.chain(group).map(c => c.Amount).reduce((a,b) => a+b, 0).value();
        return (
            <div className="d-flex flex-row w-100 p-2">
                <div className="w-25">
                    <div className="cover align-self-center ml-2" style={{ width: 40, height: 40, borderRadius: 20, background: `url(${user.UserImageUrl}?full=50) no-repeat center center` }} />
                </div>
                <div className="w-100  d-flex flex-column justify-content-center">
                    <span className="toUpper toBold font-12 black line1">{user.UserName}</span>
                    <span className="font-10">ID: {user.IdUser}</span>
                </div>
                <div className="w-100 d-flex flex-column justify-content-center">
                    <span className="font-10 align-self-center">{user.RoleStatus}</span>
                </div>
                <div className="w-50 ">
                    <button style={{ width: 'auto', border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2 align-self-center">View Profile</button>
                </div>
                <div className="w-50  d-flex flex-row justify-content-end">
                    <span style={{ color: '#DD9231' }} className={`w-100 text-right font-16 align-self-center`}>{formatPrice((amount || 0) * 100)}</span>
                </div>
            </div>
        )
    }

    renderTeam = group => {
        let team = _.first(group);
        let amount = _.chain(group).map(c => c.Amount).reduce((a,b) => a+b, 0).value();
        return (
            <div className="d-flex flex-row w-100 p-2">
                <div className="w-25">
                    <div className="cover align-self-center ml-2" style={{ width: 40, height: 40, borderRadius: 20, background: `url(${(team.TeamImageUrl || team.Logo)}?full=50) no-repeat center center` }} />
                </div>
                <div className="w-100  d-flex flex-column justify-content-center">
                    <span className="toUpper toBold font-12 black line1">{team.TeamName}{' '}{team.Status ? '(' + team.Status + ')' : ''} </span>
                    <span className="font-10">ID: {team.IdTeam}</span>
                </div>
                <div className="w-100 d-flex flex-column justify-content-center">
                    <span className="font-10 align-self-center">{team.DivisionName}</span>
                </div>
                <div className="w-50 ">
                    <button style={{ width: 'auto', border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2 align-self-center">View Profile</button>
                </div>
                <div className="w-50  d-flex flex-row justify-content-end">
                    <span style={{ color: '#DD9231' }} className={`w-100 text-right font-16 align-self-center`}>{formatPrice((amount || 0) * 100)}</span>
                </div>
            </div>
        )
    }

    render() {
        const { isOpen, toggle, details } = this.props, { searchText } = this.state;        
        const filtered = _.chain(details).groupBy((details.length && _.first(details).IdUser) ? 'IdUser' : 'IdTeam').values().filter(([d]) => {
            if (searchText && (d.TeamName || d.UserName).toLowerCase().indexOf(searchText.toLowerCase()) === -1) return false;
            return true;
        }).value();
        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 1000 }}>
                <ModalHeader toggle={toggle} className="bg-blue-dark white">
                    {(_.first(details) || {}).BLOCK}
                </ModalHeader>
                <ModalBody className="p-4">
                    <input ref={(i) => this.txtSearch = i} onChange={this.onChange} className="form-control" type="text" placeholder="Search..." />
                </ModalBody>
                <ModalBody>
                    <ul>
                        {details && filtered.map((group, i) => (
                            <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                                <Link to={`/season/${group[0].IdSeason}/${group[0].IdTeam ? 'team' : 'player'}/${group[0].IdTeam || group[0].IdUser}`} className="d-flex flex-row p-1">
                                    {group[0].IdUser && this.renderUser(group)}
                                    {group[0].IdTeam && this.renderTeam(group)}
                                </Link>
                            </li>))}
                    </ul>
                </ModalBody>
            </Modal>
        )
    }
}

export default Details;
