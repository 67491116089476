function crossFlightRules(state = [], action) {
    switch (action.type) {
        case 'CROSS_FLIGHT_RULES/SET':
            return action.rules || [];
        case 'CROSS_FLIGHT_RULES/CLEAR':
            return [];
        case 'CROSS_FLIGHT_RULES/DELETE':
            return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        case 'CROSS_FLIGHT_RULES/ADD':
            return [...state, action.rule];
        case 'CROSS_FLIGHT_RULES/UPDATE':
            return [...state.slice(0, action.index), action.rule, ...state.slice(action.index + 1)];
        default:
            return state;
    }
}

export default crossFlightRules;
