import React from 'react';
import './build.css';
import DivisionListItem from './DivisionListItem';
import _ from 'lodash';

class DivisionsList extends React.Component {

    render() {

        const { divisions = [], accept = () => { }, results } = this.props;        

        return (<ul className="divisions-wrapper d-flex flex-column m-0 p-0">
            <span className="gray font-10 mb-2 mt-2 pl-3">-- Divisions with Flights --</span>
            {_.chain(divisions)
                .filter(d => d.Flights && d.Flights.length > 0).value()
                .map((division, i) => <DivisionListItem {...this.props} results={results} accept={accept} key={i} index={i} division={division}
                />)}
            <span className="gray font-10 mb-2 mt-2 pl-3">-- Divisions without Flights --</span>
            {_.chain(divisions)
                .filter(d => d.Flights && d.Flights.length === 0).value()
                .map((division, i) => <DivisionListItem {...this.props} accept={accept} key={i} index={i} division={division}
                />)}
        </ul>)
    }
}

export default DivisionsList;