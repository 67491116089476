import React from 'react';

class JerseyPlayer extends React.Component {

    state = {
        apiCalled: false
    }
    componentWillMount() {
        const { user } = this.props;
        this.setState({ user });
    }
    componentWillReceiveProps = nextProps => {
        const { trigger, season } = nextProps;
        if (trigger && !this.state.apiCalled) {
            this.setState({ apiCalled: true }, () => {
                // TODO: calL API to save
                const { user, dirty } = this.state;

                if (dirty)
                    this.props.rosterActions && this.props.rosterActions.updateJersey({
                        IdUser: user.IdUser,
                        IdTeamUserRole: user.IdTeamUserRole,
                        JerseyNumber: this.txtNumber.value,
                        Position: this.txtPosition.value,
                        IdTeamDivision: season.IdTeamDivision
                    });

            });
        }
    }
    render() {
        const { user } = this.state;
        return (
            <div className="d-flex flex-row pr-4 pl-4" style={{ height: 80 }}>
                {user && <div style={{ width: 80 }} className="d-flex flex-row">
                    <div className="align-self-center cover" style={{ width: 60, height: 60, borderRadius: 30, border: '1px solid black', background: `url(${user.ImageUrl || user.Image}) no-repeat center center` }} />
                </div>}
                {user && <div className="d-flex flex-column align-self-center">
                    <span>{user.NameFirst} {user.NameLast}</span>
                    <div className="d-flex flex-row">
                        <input className="form-control w-100" type="text" name="position" ref={i => this.txtPosition = i} placeholder="Position" defaultValue={user.Position} onChange={() => this.setState({ dirty: true })} />
                        <input className="form-control w-25" type="text" name="number" ref={i => this.txtNumber = i} placeholder="#" defaultValue={user.JerseyNumber} onChange={() => this.setState({ dirty: true })} />
                    </div>
                </div>}
            </div>
        )
    }
}

export default JerseyPlayer;