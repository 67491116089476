export function fetchDivisions(idSeason){
    return {
        type: 'DIVISIONS/FETCH',
        idSeason,
        module: 'Schedule_Manager'
    }
}

export function clearDivisions(){
    return { type: 'DIVISIONS/CLEAR' }
}

