import React from 'react';

class RosterSize extends React.Component {
  state = { sizes: null }

  componentWillMount() {
    this.setState({ sizes: this.props.sizes });
  }

  setSize = size => {
    const { entity, rosterActions } = this.props;
    entity.ShirtSize = entity.ShirtSize === size ? null : size;
    // TODO: call api
    rosterActions && rosterActions.updateShirtSize(entity.IdUser, entity.ShirtSize);
    this.forceUpdate();
  }

  render() {
    const { entity, sizes } = this.props;
    return <div className="d-flex flex-row margin-top-half">
      <img alt="" className="align-self-center smallPic margin-right-half" src="/images/defaults/male.png" />
      <span className="align-self-center font-10 ">{entity.UserName}{entity.IdRole === 4 ? ' (C)' : ''}</span>
      <div className="align-self-center d-flex flex-row ml-auto">
        {sizes.map((size, j) => <div key={j} onClick={() => this.setSize(size.Abbr)} className={`shirt-size-box d-flex flex-row justify-content-center margin-left-half ${(entity.ShirtSize === size.Size || entity.ShirtSize === size.Abbr) ? `white bg-${entity.IdRole === 4 ? 'info' : 'success'}` : ''}`}>
          <span className="align-self-center font-10">{size.Abbr}</span>
        </div>)}
      </div>
    </div>
  }
}

export default RosterSize;
