import React from 'react';
import moment from 'moment';
import config from '../../../../config';
import request from 'superagent';
import _ from 'lodash';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './css.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Loader from '../../../common/Loader';
import Sheetx1 from './Sheetx1';
import Sheetx3 from './Sheetx3';
import Sheet3v3 from './Sheet3v3';

class GameCards extends React.Component {

    state = {
        bootstrapped: false,
        filters: {},
        appliedFilters: {},
        ddDateOpen: false,
        ddTimeOpen: false,
        season: null,
        games: [],
        fetchingGames: false,
        fetchedOnce: false,
        fullSheet: true,
        sheetMode: 1,
        downloading: false
    }

    // Lifecycle
    componentWillMount() {

        const { setTitle, params } = this.props;
        setTitle && setTitle({
            sub: 'Game Cards',
            main: this.props.season.Name || null
        });

        // Fetch season
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);

        // Fetch filters (only locally.. I know is less clean but there's no reason to set this on a saga)        
        request.get(`${config.apiEndpoint}/api/v4/seasons/filters2/${params.idSeason}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
                if (data.body.success) {
                    // Set filters on state and then set the 1st applied filterss                    
                    this.setState({
                        filters: _.pick(data.body.filters, 'DateFilters', 'TimeFilters', 'LocationFilters', 'DivisionFilters')
                    }, () => {
                        const { filters } = this.state;
                        if (filters.LocationFilters.length) _.first(filters.LocationFilters).selected = true;
                        if (filters.DivisionFilters.length) _.first(filters.DivisionFilters).selected = true;
                        this.setState({
                            bootstrapped: true,
                            filters,
                            appliedFilters: {
                                entity: 1, //[1: Divisions, 2: Locations, 3: Teams]
                                type: 3, // [1: Poolplay, 2: Playoffs, 3: All]
                                date: _.find(filters.DateFilters, (df) => {
                                    return moment(df.DateStart, 'MM/DD/YYYY').isSame(moment())
                                }) || _.first(filters.DateFilters)
                            }
                        })
                    });
                } else {
                    alert('??')
                }
            });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Game Cards',
                main: nextProps.season.Name || null
            });
            this.setState({ season: nextProps.season, sheetMode: (nextProps.season.IdCommunity === 3 || nextProps.season.IdCommunity === 4) ? 2 : 1 });
        }
    }
    //

    // Toggle filters
    toggleDateDD = () => this.setState({ ddDateOpen: !this.state.ddDateOpen });
    toggleTimeDD = () => this.setState({ ddTimeOpen: !this.state.ddTimeOpen });
    toggleEntity = i => {
        const { appliedFilters } = this.state;
        appliedFilters.entity = i;
        this.setState({ appliedFilters });
    }
    toggleType = i => {
        const { appliedFilters } = this.state;
        appliedFilters.type = i;
        this.setState({ appliedFilters });
    }
    toggleLocation = index => {
        const { filters } = this.state;
        filters.LocationFilters.forEach((l, i) => {
            l.selected = index === i;
        });
        this.setState({ filters });
    }
    toggleAllSeason = () => {
        const { filters, season } = this.state;
        season.selected = !season.selected;
        filters.DivisionFilters.forEach((division) => {
            division.selected = season.selected;
            division.Teams.forEach((team) => {
                team.selected = season.selected;
            })
        });
        filters.LocationFilters.forEach((location) => {
            location.selected = season.selected;
        })
        this.setState({ season, filters });
    }
    toggleDivision = (index) => {
        const { filters } = this.state;
        filters.DivisionFilters.forEach((l, i) => {
            l.selected = index === i;
        });
        this.setState({ filters });
    }
    toggleTeam = (di, ti) => {
        const { filters, season } = this.state;
        filters.DivisionFilters[di].Teams[ti].selected = !filters.DivisionFilters[di].Teams[ti].selected;
        filters.DivisionFilters[di].selected = _.find(filters.DivisionFilters[di].Teams, (team) => !team.selected) ? false : true;
        season.selected = _.find(filters.DivisionFilters, (division) => !division.selected) ? false : true;
        this.setState({ filters, season });
    }
    toggleSheetMode = mode => this.setState({ sheetMode: mode });
    selectDate = (index) => {
        const { appliedFilters, filters } = this.state;
        appliedFilters.date = filters.DateFilters[index];
        this.setState({ appliedFilters });
    }
    //

    preview = e => {
        e.preventDefault();
        const { filters, fetchingGames, appliedFilters, } = this.state,
            { params } = this.props;

        if (fetchingGames) return;

        this.setState({
            fetchingGames: true
        }, () => {

            // Fetch games with filters applied            
            request.post(`${config.apiEndpoint}/api/v4/games/${params.idSeason}`)
                .send({
                    searchTerm: this.txtSearch.value,
                    //seasonName: (season.Name || season.SeasonName),
                    selectedDate: appliedFilters.date,
                    filterByGameType: appliedFilters.type === 1 ? 'poolplay' : (appliedFilters.type === 2 ? 'playoff' : null),
                    idDivision: appliedFilters.entity === 1 ? _.chain(filters.DivisionFilters).filter((d) => {
                        return d.selected;
                    }).map((d) => {
                        return d.IdDivision;
                    }).first().value() : null,
                    idLocation: appliedFilters.entity === 2 ? _.chain(filters.LocationFilters).filter((d) => {
                        return d.selected;
                    }).map((d) => {
                        return d.IdLocation;
                    }).first().value() : null
                })
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {

                    // Watermarks for 10th and 18th slot 
                    data.body.games && data.body.games.forEach((game) => {
                        if (game.Teams[0]) {
                            game.Teams[0].Players[9] = _.extend(game.Teams[0].Players[9], {
                                watermark1: 'Roster',
                                watermark2: 'Slot',
                                watermark3: 10
                            });
                            game.Teams[0].Players[17] = _.extend(game.Teams[0].Players[17], {
                                watermark1: 'Roster',
                                watermark2: 'Slot',
                                watermark3: 18
                            });
                        }

                        if (game.Teams[1]) {
                            game.Teams[1].Players[9] = _.extend(game.Teams[1].Players[9], {
                                watermark1: 'Roster',
                                watermark2: 'Slot',
                                watermark3: 10
                            });

                            game.Teams[1].Players[17] = _.extend(game.Teams[1].Players[17], {
                                watermark1: 'Roster',
                                watermark2: 'Slot',
                                watermark3: 18
                            });
                        }
                    })
                    //

                    this.setState({
                        games: data.body.games || [],
                        fetchingGames: false,
                        fetchedOnce: true
                    });

                });
        })
    }

    download = () => {

        const { filters, downloading, appliedFilters, season, sheetMode } = this.state,
            { params } = this.props;

        if (downloading) {
            return;
        }

        let finalSheetMode = sheetMode;
        // Baseball and Softball have special game cards
        // TODO: map this to some kind of CONST for more readability
        if (season.IdCommunity === 3 || season.IdCommunity === 4) {
            switch (sheetMode) {
                case 1: finalSheetMode = 1; break;
                case 2: finalSheetMode = 4; break;
                case 3: finalSheetMode = 5; break;
                default: break;
            }
        }

        this.setState({
            downloading: true
        }, () => {

            // Fetch games with filters applied            
            request.post(`${config.apiEndpoint}/api/v4/games/download/${params.idSeason}`)
                .send({
                    sheetMode: finalSheetMode,
                    searchTerm: this.txtSearch.value,
                    selectedDate: appliedFilters.date,
                    filterByGameType: appliedFilters.type === 1 ? 'poolplay' : (appliedFilters.type === 2 ? 'playoff' : null),
                    idDivision: appliedFilters.entity === 1 ? _.chain(filters.DivisionFilters).filter((d) => {
                        return d.selected;
                    }).map((d) => {
                        return d.IdDivision;
                    }).first().value() : null,
                    idLocation: appliedFilters.entity === 2 ? _.chain(filters.LocationFilters).filter((d) => {
                        return d.selected;
                    }).map((d) => {
                        return d.IdLocation;
                    }).first().value() : null
                })
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .responseType('arraybuffer')
                .then((data) => {

                    var fileName = [];
                    if (appliedFilters.entity === 1) {
                        fileName = [_.chain(filters.DivisionFilters).filter((d) => {
                            return d.selected;
                        }).map((d) => {
                            return (d.DivisionName || d.Name);
                        }).first().value(), 'GameCard.pdf']
                    }
                    if (appliedFilters.entity === 2) {
                        fileName = [_.chain(filters.LocationFilters).filter((d) => {
                            return d.selected;
                        }).map((d) => {
                            return (d.LocationName || d.Name);
                        }).first().value(), 'GameCard.pdf']
                    }

                    let file = new Blob([data.body], { type: 'application/pdf' });

                    require("downloadjs")(file, fileName.join('-'));

                    this.setState({
                        downloading: false,
                    });

                });
        })
    }

    render() {

        const { bootstrapped, filters, appliedFilters, season, fetchingGames, fetchedOnce, games, downloading } = this.state,
            { fetchingData } = this.props;

        const groups = _.chunk(games, 3);

        return <section id="game-cards" className="canvas white" style={{ backgroundColor: '#121b32'}}>
            {!fetchingData && (
                <div className="filters-wrapper d-flex flex-row idented margin-top-half">
                    { /* FORM filters */}
                    {bootstrapped &&
                        <ReactCSSTransitionGroup key="something" className="d-flex flex-row w-100" transitionName="example" component="form" transitionAppear={true} transitionAppearTimeout={200}
                            transitionEnterTimeout={200} transitionEnter={true} transitionLeave={true} transitionLeaveTimeout={200}>
                            <span key="1" className="white align-self-center margin-left margin-right-half">Date</span>
                            {appliedFilters.date && (
                                <div key="date" className="align-self-center">
                                    <Dropdown size="sm" isOpen={this.state.ddDateOpen} toggle={this.toggleDateDD}>
                                        <DropdownToggle caret className="btn-info">
                                            {moment(appliedFilters.date.DateStart, 'MM/DD/YYYY').format('MMM Do YYYY')}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.filters.DateFilters.map((i, j) => <DropdownItem key={j} onClick={() => this.selectDate(j)}>
                                                {moment(i.DateStart, 'MM/DD/YYYY').format('MMM Do YYYY')}
                                            </DropdownItem>)}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>)}
                            { /* Entity */}
                            <div key="4" className="align-self-center">
                                <button type="button" className="btn btn-link btn-sm" onClick={() => this.toggleEntity(1)}>
                                    <i className={`${appliedFilters.entity === 1 ? 'fa fa-check-circle green' : 'far fa-circle white'}`} />
                                    <span className="idented-half">Divisions</span>
                                </button>
                                <button type="button" className="btn btn-link btn-sm" onClick={() => this.toggleEntity(2)}>
                                    <i className={`${appliedFilters.entity === 2 ? 'fa fa-check-circle green' : 'far fa-circle white'}`} />
                                    <span className="idented-half">Locations</span>
                                </button>
                                {/*<button type="button" className="btn btn-link btn-sm" onClick={ () => this.toggleEntity(3) }>
                             <i className={ `${appliedFilters.entity === 3 ? 'fa fa-check-circle green' : 'far fa-circle white'}` } />
                             <span className="idented-half">Teams</span>
                           </button>*/}
                            </div>
                            <span key="5" className="white align-self-center margin-left-half margin-right-half">|</span>
                            { /* Type */}
                            <div key="6" className="align-self-center">
                                <button type="button" className="btn btn-link btn-sm" onClick={() => this.toggleType(1)}>
                                    <i className={`${appliedFilters.type === 1 ? 'fa fa-check-circle green' : 'far fa-circle white'}`} />
                                    <span className="idented-half">Poolplay</span>
                                </button>
                                <button type="button" className="btn btn-link btn-sm" onClick={() => this.toggleType(2)}>
                                    <i className={`${appliedFilters.type === 2 ? 'fa fa-check-circle green' : 'far fa-circle white'}`} />
                                    <span className="idented-half">Playoffs</span>
                                </button>
                                <button type="button" className="btn btn-link btn-sm" onClick={() => this.toggleType(3)}>
                                    <i className={`${appliedFilters.type === 3 ? 'fa fa-check-circle green' : 'far fa-circle white'}`} />
                                    <span className="idented-half">All</span>
                                </button>
                            </div>
                            <span key="7" className="white font-8 align-self-center margin-left-half margin-right-half">- or -</span>
                            { /* Search tool */}
                            <div key="8" className="align-self-center">
                                <input style={{ width: 100 }} type="text" className="form-control" placeholder="Game ID" ref={(i) => this.txtSearch = i} />
                            </div>
                            { /* PREVIEW BUTTON */}
                            {filters.DateFilters && <button type="submit" key="previewbtn" onClick={this.preview} className="btn btn-success margin-right ml-auto">
                                <i className="fa fa-eye" />
                                {' '}
                                <span>{fetchingGames ? 'Fetching' : 'Preview'}</span>
                            </button>}
                        </ReactCSSTransitionGroup>}
                </div>
            )}
            { /* Season */}
            <section className="tree-section" style={{ overflowY: 'auto' }}>
                {season && filters.DateFilters && season.Name && false && <button onClick={() => this.toggleAllSeason()} className="btn btn-link text-left btn-sm" style={{ whiteSpace: 'normal' }}>
                    <i className={`${season.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'}`} />
                    {' '}
                    {season.Name || season.SeasonName}
                </button>}
                { /* Divisions | Teams */}
                {(appliedFilters.entity === 3 || appliedFilters.entity === 1) && filters.DivisionFilters && filters.DivisionFilters.map((df, i) => (
                    <section key={i}>
                        <button onClick={() => this.toggleDivision(i)} className="btn btn-link text-left btn-sm" style={{ whiteSpace: 'normal', marginLeft: '2em' }}>
                            <i className={`${df.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'}`} />
                            {' '}
                            {df.Name || df.IdDivision}
                        </button>
                        {appliedFilters.entity === 3 && false && df.Teams.map((team, j) => (
                            <button onClick={() => this.toggleTeam(i, j)} key={j} className="btn btn-link text-left btn-sm" style={{ whiteSpace: 'normal', marginLeft: '4em' }}>
                                <i className={`${team.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'}`} />
                                {' '}
                                {team.Name || team.TeamName}
                            </button>))}
                    </section>))}
                { /* Locations */}
                {(appliedFilters.entity === 2) && filters.LocationFilters && filters.LocationFilters.map((lf, i) => (
                    <section key={i}>
                        <button onClick={() => this.toggleLocation(i)} className="btn btn-link text-left btn-sm" style={{ whiteSpace: 'normal', marginLeft: '2em' }}>
                            <i className={`${lf.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'}`} />
                            {' '}
                            {lf.Name}
                        </button>
                    </section>))}
            </section>
            { /* Sheets */}
            <section className={`sheet-section ${(games.length || true) ? 'bg-gray-dark inner-shadow' : null}`} style={{ overflowY: 'auto' }}>
                {/* Standard Letter */}
                {games.length > 0 && !fetchingGames && this.state.sheetMode === 1 && games.map((game, i) => <Sheetx1 key={i} game={game} />)}
                {/* 3v3 - 3x Sheet */}
                {games.length > 0 && !fetchingGames && this.state.sheetMode === 2 && groups.map((group, i) => <Sheetx3 key={i} games={group} community={season.IdCommunity} />)}
                {/* 3v3 - 1x Sheet */}
                {games.length > 0 && !fetchingGames && this.state.sheetMode === 3 && games.map((game, i) => <Sheet3v3 key={i} game={game} rosterCap={6} className="margin-top" community={season.IdCommunity} />)}

                {fetchingGames && <Loader key="loader" message={`Fetching Games, please wait...`} />}
                {!bootstrapped && <Loader key="loader" message={`Loading Assets for Schedule Building`} />}
                {!fetchingGames && fetchedOnce && !games.length && <div className="d-flex justify-content-center centered-message">
                    <h1 className="white align-self-center block text-center">No games were found!</h1>
                </div>}
            </section>
            { /* Print controls */}
            {games.length > 0 && !fetchingGames && (
                <section style={{ position: 'fixed', bottom: 0, right: 0 }} className="d-flex flex-column p-2">
                    <span className="text-right yellow font-8 shadow">{(games && games.length && !fetchingGames) ? '(' + games.length + ') games found!' : ''}</span>
                    {season.IdCommunity !== 3 && season.IdCommunity !== 4 && <button className="btn btn-link btn-sm text-right margin-top" onClick={() => this.toggleSheetMode(1)}>
                        <i className={`${this.state.sheetMode === 1 ? 'fa fa-check-circle green' : 'far fa-circle white'} shadow`} />
                        <span className="idented-half shadow">Full Sheet</span>
                    </button>}
                    <button className="btn btn-link btn-sm text-right" onClick={() => this.toggleSheetMode(2)}>
                        <i className={`${this.state.sheetMode === 2 ? 'fa fa-check-circle green' : 'far fa-circle white'} shadow`} />
                        <span className="idented-half shadow">{season.IdCommunity === 1 ? '3v3 - ' : ''}3x Sheet</span>
                    </button>
                    <button className="btn btn-link btn-sm text-right" onClick={() => this.toggleSheetMode(3)}>
                        <i className={`${this.state.sheetMode === 3 ? 'fa fa-check-circle green' : 'far fa-circle white'} shadow`} />
                        <span className="idented-half shadow">{season.IdCommunity === 1 ? '3v3 - ' : ''}1x Sheet</span>
                    </button>
                    <button className="btn btn-success btn-lg margin-top" type="button" onClick={this.download}>
                        <i className="fas fa-download" />
                        {' '}<span>{downloading ? 'Downlading...' : 'Download'}</span> (
                       {this.state.sheetMode === 2 ? groups.length : games.length}) sheets</button>
                </section>)}
        </section>
    }
}

export default GameCards;
