

function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'SELECTED_USER/SET':
            return action.user;
        case 'SELECTED_USER/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;