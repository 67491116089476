import _ from 'lodash';

function fieldAvailabilityRules(state = [], action) {
    switch (action.type) {
        case 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/SET':
            // Fix the timeslots of each rule

            action.rules.forEach(rule => {
                rule.Availability_Start = `${rule.Availability_Start.split('.')[0]}`;
                rule.Availability_End = `${rule.Availability_End.split('.')[0]}`;
            })            
            return action.rules;
        case 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/ADD':
            return [...state, action.rule];
        case 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/UPDATE':
            return [
                ...state.slice(0, action.index),
                _.extend({}, state[action.index], action.rule),
                ...state.slice(action.index + 1)
            ];
        case 'SCHEDULE_MANAGER/FIELD_AVAILABILITY_RULES/DELETE':
            return [
                ...state.slice(0, action.index),
                ...state.slice(action.index + 1)
            ];
        default:
            return state;
    }
}

export default fieldAvailabilityRules;