import React from 'react';
import DivisionsList from './divisions';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from '../../../../../helpers';
import CardApplied from './card.applied';
import _ from 'lodash';

const target = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }

        let { tab } = component.state;
        if (tab === 1) {

            const { divisions, divisionsActions, params } = props;
            // Evaluate if the division already has a registration program. If it does, prompt for confirmation of replace.
            const { group, type, mode } = monitor.getItem();
            switch (type) {
                case ItemTypes.APPLIEDQUESTION:
                    divisions.forEach(division => {
                        divisionsActions && divisionsActions.assignQuestionGroup &&
                            divisionsActions.assignQuestionGroup(division.IdDivision,
                                group.IdQuestionGroup, mode, division.IdSeason || params.idSeason);
                    });
                    break;
                default: break;
            }
        }

        if (tab === 2) {
            const { seasonActions, params } = props;
            const { group, type } = monitor.getItem();
            switch (type) {
                case ItemTypes.APPLIEDQUESTION:
                    seasonActions && seasonActions.assignQuestionGroupToSeason({ IdSeason: params.idSeason, IdQuestionGroup: group.IdQuestionGroup });
                    break;
                default: break;
            }
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class LeftSide extends React.Component {
    state = {
        tab: 1
    }
    render() {
        const { season, connectDropTarget, isOverCurrent, canDrop, appliedQuestions } = this.props, { tab } = this.state;
        
        return connectDropTarget(
            <div className="bg-white h-100" style={{ overflowY: 'auto' }}>

                {/* TABS FOR PLAYER / SCOUT */}
                <div className="d-flex flex-row">
                    <button onClick={() => this.setState({ tab: 1 })} className={`w-50 btn btn-${tab === 1 ? 'info' : 'link'} sqwad-font`}>PLAYERS</button>
                    <button onClick={() => this.setState({ tab: 2 })} className={`w-50 btn btn-${tab === 2 ? 'info' : 'link'} sqwad-font`}>SCOUTS</button>
                </div>

                {/* TAB 1 */}
                {tab === 1 && <section>
                    <div className="d-flex flex-row justify-content-center" style={{ backgroundColor: '#c8c8c8', height: 48 }}>
                        <h3 className="align-self-center black">Divisions</h3>
                    </div>
                    <ul className="w-100 m-0 p-0 seasons-wrapper">
                        <li className={`${canDrop ? '' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>

                            {/* DROP */}
                            <div className={`mr-3 droppable ${canDrop ? 'action mb-2' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'} d-flex flex-row`}>
                                {canDrop && <span className="line1 d-flex flex-row p-4"><i className="mr-2 align-self-center fas fa-arrow-left red" />
                                    <span className="align-self-center">Drop here to apply to all the divisions</span></span>}
                                {!canDrop && <span className="line1 d-flex flex-row">
                                    <span className="align-self-center">{season.SeasonName || season.Name}</span></span>}
                            </div>

                            {/* DIVISIONS */}
                            <DivisionsList {...this.props} />

                            {/* NO DIVISIONS */}
                            {this.props.divisions.length === 0 && <div className="jumbotron text-center">
                                <i className="fas fa-exclamation-circle red font-60 mb-2" />
                                <h2 className="line1">There are no divisions on this season</h2>
                                <p className="line1">Navigate to the divisions section and create some</p>
                            </div>}

                        </li>
                    </ul>
                </section>}

                {/* TAB 2 */}
                {tab === 2 && <section>
                    <div className={`tile card p-4 m-3 d-flex flex-row justify-content-center drop-area ${canDrop ? 'action' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'} `} style={{ borderStyle: 'dashed', borderWidth: '3px' }} >
                        <span className="black font-14 line1 text-center">Drop questions <i className="far fa-question-circle blue" /> for<br />scout registration here</span>
                    </div>
                    <section className="mx-3">
                        {season && season.questions && season.questions.map((question, i) => {
                            let qs = _.find(appliedQuestions, qs => qs.IdQuestionGroup === question.IdQuestionGroup);
                            if (qs) return <CardApplied group={qs} key={i} type={ItemTypes.APPLIEDQUESTION} mode={3}
                                redraw={() => { }}                                
                                fnAction={() => {
                                    this.props.seasonActions.removeQuestionGroupFromSeason({
                                        IdSeason: season.IdSeason,
                                        IdQuestionGroup: qs.IdQuestionGroup
                                    });
                                }} />
                            else return <i key={i} />
                        })}
                    </section>
                </section>}
            </div>
        )
    }
}

export default DropTarget([ItemTypes.APPLIEDQUESTION], target, collect)(LeftSide);
