import React from 'react';
import FlightPreBuildWidget from './FlightPreBuildWidget';

class FlightTile extends React.Component {

    render() {
        const { division } = this.props;

        return <li className={`d-flex flex-column margin-top margin-left float-left tile-rainbow card`}
            style={{ height: 'auto', border: '1px solid white' }}>
            <section className="header white p-2 bg-green">
                <span className="white">{division.Name}</span>
            </section>
            <section className="d-flex flex-row">
                {division.Flights.map((flight, i) =>
                    <FlightPreBuildWidget flight={flight} key={i} fnSelectFlight={this.props.fnSelectFlight} />)}
            </section>
        </li>
    }
}

export default FlightTile;
