import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { formatPrice } from '../../../../helpers';

class Comp extends React.Component {

    state = {
        team: null,
        dropdownOpen: false
    }

    // Lifecycle
    componentWillMount() {
        this.setState({
            team: this.props.team
        });
    }
    componentWillReceiveProps = (newProps) => {
        this.setState(() => {
            return {
                team: newProps.team
            }
        });
    }
    //

    errorImage = () => {
        const {team} = this.state;
        team.TeamImageUrl = '/images/defaults/teamLogo.png';
        this.setState(() => {
            return {
                team
            }
        });
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    selectPlan = (selectedPlan) => {
        const {team} = this.state;
        if (team.IdAppPlanPrice === selectedPlan.IdAppPlanPrice || (team.selectedPlan && team.selectedPlan.IdAppPlanPrice === selectedPlan.IdAppPlanPrice)) {
            //  Its the originally-selected plan. so we clear up the team from new selected plans
            this.props.fnUpdate && this.props.fnUpdate(this.state.team, null);
        } else {
            this.props.fnUpdate && this.props.fnUpdate(this.state.team, selectedPlan);
        }

        this.setState({
            dropdownOpen: false
        });
    }

    render() {
        const {team} = this.state,
            {plans} = this.props;

        return (
            <li className="d-flex flex-row p-1 pointer clickable" style={ { borderTop: '1px solid white' } } onClick={ this.toggle }>
              { /* Image */ }
              <img alt="" src={ team.TeamImageUrl.replace('https', 'http') } className="smallPic margin-right-half align-self-center" style={ { borderRadius: 5 } } onError={ this.errorImage } />
              { /* Name */ }
              <span className="d-flex flex-column align-self-center">
                <span className="font-12">{ team.Name || team.TeamName }</span>
                <span className="font-8">{ team.SeasonName }</span>
              </span>
              { /* Plans */ }
              <button className={ `btn btn-link ml-auto ${team.selectedPlan ? 'yellow' : (team.IdAppPlan ? 'green' : null)}` } id={ `button-${team.IdTeam}` }>
                { team.selectedPlan ? [team.selectedPlan.AppPlanDescription, team.selectedPlan.DurationName].join(' ') : (team.AppPlanDescription ? [team.AppPlanDescription, team.Duration === 1 ? 'Monthly' : 'Yearly'].join(' ') : 'None') }
                { ' ' }<i className="fa fa-caret-down" /></button>
              { /* Popover */ }
              <Popover placement="bottom" isOpen={ this.state.dropdownOpen } target={ `button-${team.IdTeam}` } toggle={ this.toggle }>
                <PopoverHeader>Plans</PopoverHeader>
                <PopoverBody className="no-padding">
                  <ul>
                    { plans.map((p, i) => (
                          <li key={ i } style={ { width: 300 } } className="no-margin no-padding" onClick={ () => this.selectPlan(p) }>
                            <button style={ { borderBottom: '1px solid #e5e5e5' } } className={ `btn btn-${(team.selectedPlan && team.selectedPlan.IdAppPlanPrice === p.IdAppPlanPrice) ? 'warning' : (team.IdAppPlanPrice === p.IdAppPlanPrice ? 'success' : 'link')} btn-sm d-flex flex-row btn-block` }>
                              <span>{ p.AppPlanDescription }{ ' ' }{ p.DurationName }</span>
                              <span className="ml-auto">{ ' (' + formatPrice(p.Price * 100) + ')' }</span>
                            </button>
                          </li>)) }
                  </ul>
                </PopoverBody>
              </Popover>
            </li>)
    }
}

export default Comp;