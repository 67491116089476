module.exports = {
    registration: {
        name: "Leagues for Registration",
        icon: "icon-enter",
        on: false,
        to: '/registration'
    },
    payments: {
        name: "Payments",
        icon: "far fa-money-bill-alt",
        on: false,
        to: '/payments'
    }/*,
    wallet: {
        name: "Wallet",
        icon: "wallet",
        on: false,
        to: '/wallet'
    }*/
};