import React from 'react';

const Comp = (props) => {
  return (
    <div className="row margin-bottom">
      <div className="col-md-12 col-sm-12 col-lg container-fluid white font-16">
        <div className="row">
          <div className="col-12 white">
            <h2 className="green text-left">Premier Coach</h2></div>
          <span className="font-12 col-12 margin-bottom">$5.99/monthly or $64.70/yearly per team</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-users blue" />{ ' ' }Unlimited Rosters</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-clipboard-list blue" />{ ' ' }Track Attendance</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-comments blue" />{ ' ' }Team Chat</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-camera blue" />{ ' ' }Team Photos (1GB)</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-tshirt blue" />{ ' ' }Manage Uniforms</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-calculator blue" />{ ' ' }Team Accounting</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fas fa-apple-alt blue" />{ ' ' }Snack Day</span>
          <span style={ { width: 280 } } className="font-8 margin-top col-12">Plus features of the Free Version</span>
        </div>
      </div>
      
      <div className="col-md-12 col-sm-12 col-lg container-fluid white font-16">
        <div className="row">
          <div className="col-12 white">
            <h2 className="green text-left">Premier Coach Plus</h2></div>
          <span className="font-12 col-12 margin-bottom">$19.99/monthly or $215.89/yearly per team</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-video blue" />{ ' ' }SL Live Video</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-draw-polygon blue" />{ ' ' }Stat Tracker</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-cut blue" />{ ' ' }Create Highlights</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-users blue" />{ ' ' }Unlimited Rosters</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-clipboard-list blue" />{ ' ' }Track Attendance</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-comments blue" />{ ' ' }Team Chat</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-camera blue" />{ ' ' }Team Photos (1GB)</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-tshirt blue" />{ ' ' }Manage Uniforms</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fa fa-calculator blue" />{ ' ' }Team Accounting</span>
          <span style={ { width: 280 } } className="col-lg-12 col-xl-6 zoom"><i style={ { width: 40 } } className="text-center fas fa-apple-alt blue" />{ ' ' }Snack Day</span>
          <span style={ { width: 280 } } className="font-8 margin-top col-12">Plus features of the Free Version</span>
          <span style={ { width: 280 } } className="font-8 margin-top col-12">Stat tracker available for soccer, basketball, hockey and lacrosse. Streaming video and highlights available for all sports.</span>
        </div>
      </div>
    </div>
  )
};

export default Comp;