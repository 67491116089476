import React from 'react';
import _ from 'lodash';

class Row extends React.Component {
    render() {
        const { team, showCoaches } = this.props;
        return <tr>
            <td align="left">{team.Name || team.TeamName}</td>
            <td align="left">{team.DivisionName}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "Youth Small").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'Youth Small') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "Youth Medium").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'Youth Medium') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "Youth Large").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'Youth Large') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "Youth XL").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'Youth XL') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "Small").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'Small') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "Medium").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'Medium') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "Large").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'Large') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "X-Large").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'X-Large') ? 1 : 0)}</td>
            <td align="center">{_.filter(team.Players, p => p.ShirtSize === "XX-Large").length + (showCoaches && _.find(team.Coaches, c => c.ShirtSize === 'XX-Large') ? 1 : 0)}</td>
            <td align="center">{team.UserName_CoachSigned}</td>
        </tr>
    }
}

export default Row;
