import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';

class Start extends React.Component {

    state = {
        plans: null
    }

    // Lifecycle
    componentWillMount() {
        const { plans } = this.props;
        this.setState({ plans });
    }
    //

    upgrade = () => {
        this.props.upgrade && this.props.upgrade();
        this.props.toggle();
    }

    start = () => {
        this.props.start && this.props.start();
        this.props.toggle();
    }

    render() {
        const { isOpen, toggle, } = this.props,
            { plans } = this.state;
            let trial = _.find(plans, p => !p.Amount),
            full = _.find(plans, p => p.Amount);

        return (
            <Modal isOpen={isOpen} style={{ width: 400 }}>
                <ModalBody className="container-fluid no-margin no-padding" style={{ height: 800, background: `url(/images/backgrounds/grass.png) no-repeat center center`, backgroundSize: 'cover', border: '1px solid white', borderRadius: 5 }}>
                    <div className="h-100 d-flex flex-row justify-content-center" style={{ position: 'relative' }}>
                        <div className="h-auto w-100 align-self-center d-flex flex-column justify-content-center">
                            <i className="fa icon-logo-header font-60 white block text-center"></i>
                            <span className="white toUpper font-16 align-self-center">{trial.Name}</span>
                            <span className="white font-12 text-center align-self-center p-2">Start your ({trial.Duration}-day) {trial.PlanType === 1 ? 'Team' : 'Family'} Trial of Sqwad Studio (begins the moment you upload your first video). Trial Version {trial.StorageLabel}</span>
                            <span className="white font-12 align-self-center p-2">Upgrade to Premium and get {full.StorageLabel}</span>
                            
                            <div style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 3, width: 150 }} className="m-auto p-2 text-center pointer" onClick={this.upgrade}><span className="white">Upgrade</span></div>
<div style={{ height: 30 }} />
                            <div style={{ width: 300 }} className="m-auto p-2 text-center pointer" onClick={this.start}><span className="white underlined">Start using free trial</span></div>
                        </div>
                        <i className="fa fa-times font-20 white" style={{ position: 'absolute', top: 10, right: 10 }} onClick={toggle} />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default Start;
