import React from 'react';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../../common/CustomDatePicker3';
import { getOrdinal, formatPrice } from '../../../../../../helpers';
import moment from 'moment';

class Due extends React.Component {

  handleChangeDate = (date) => {
    const { index, due } = this.props;
    due.DateDue = moment(date);
    this.props.paymentsActions && this.props.paymentsActions.updateDue(due, index);
  }

  remove = () => {
    const { index, due } = this.props;

    // If it has an Id, delete from the database also...
    if (due.IdCatalogItemPaymentPlan) {
      this.props.paymentsActions &&
        this.props.paymentsActions.deleteDue(due.IdCatalogItemPaymentPlan);
    }

    if (index) {
      this.props.paymentsActions &&
        this.props.paymentsActions.popDue(index);
    }
  }

  render() {
    const { due, index, generic, dues } = this.props;
    return <div className="d-flex flex-row margin-top-half" style={{ borderBottom: '1px solid #e5e5e5'}}>
      <span className="align-self-center ">{getOrdinal(index + 1)} Payment {formatPrice(((generic.base + generic.fee - generic.initial) / dues.length) * 100)}</span>
      <span className="ml-auto align-self-center  margin-right-half">Due</span>
      <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={moment(due.DateDue).utc()} onChange={this.handleChangeDate}
      />
      {true && <button style={{ width: 20 }} className="btn btn-link margin-left-half  no-padding" onClick={this.remove}>
        <i className={`fa fa-${index ? 'times ' : ''}`} />
      </button>}
    </div>
  }
}

export default Due;