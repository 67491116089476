import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { generateRandomId } from '../../../../../helpers';
import _ from 'lodash';
import FieldAvailabilityRule from './FieldAvailabilityRule';

class FieldAvailabilityRuleIcon extends React.Component {
    state = {
        popupverOpen: false,
        removed: false,
        randomId: generateRandomId(5)
    }

    removeFromElement = () => {
        const { rule, fieldAvailabilityRulesActions } = this.props;
        fieldAvailabilityRulesActions &&
            fieldAvailabilityRulesActions.removeAssociation &&
            fieldAvailabilityRulesActions.removeAssociation(rule.Id);

        this.setState({
            popoverOpen: false
        });
    }

    toggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }    

    componentDidUpdate = () => {
        const { rule } = this.props;        
        // Shake feedback for when the Rule is re-droped on the same Flight
        if (rule.shake) {
            setTimeout(() => {
                rule.shake = false;
                this.forceUpdate();
            }, 1500)
        }
    }

    render() {
        const { rule, rules } = this.props, { randomId } = this.state,
            targetRule = _.find(rules, r => r && (r.IdScheduleFieldAvailability === rule.IdRule)), index = _.findIndex(rules, r => r && (r.IdScheduleFieldAvailability === rule.IdRule));
        
        const { removed } = this.state;

        if (targetRule) {            
            const locations = _.union(targetRule.Locations, targetRule.Fields);
            const tooltipCopy = (locations.length) ? (locations[0].Name + (locations.length > 1 ? [' +', locations.length - 1, 'others'].join(' ') : '')) : (targetRule.Name || targetRule.RuleName);
            return (
                <i title={tooltipCopy} onClick={this.toggle} className={`fas fa-map-marked-alt ${(!rule.IdRule || removed) ? 'gray' : (targetRule.LIGHT_UP ? 'red' : 'green')} ${rule.shake ? 'shake' : ''}`}
                    data-tip={`${tooltipCopy}`} data-for={`rule-${targetRule.IdScheduleFieldAvailability}`} id={`btn-far-${randomId}`}>
                    {/*<ReactTooltip effect="solid" place="right" id={`rule-${targetRule.IdScheduleFieldAvailability}`} />*/}
                    <Popover placement="auto" isOpen={this.state.popoverOpen} target={`btn-far-${randomId}`} toggle={this.toggle}>
                        <PopoverBody className="d-flex flex-column justify-content-end">
                            <button className="btn btn-link btn-sm blue no-margin no-padding text-right" onClick={this.toggle}>close</button>
                            <FieldAvailabilityRule fieldAvailabilityRulesActions={this.props.fieldAvailabilityRulesActions} divisions={this.props.divisions} rule={targetRule} readOnly={true} index={index} />
                            <button className="btn btn-danger btn-sm btn-block font-8" onClick={this.removeFromElement}>Remove this Field Availability from Flight ?</button>
                        </PopoverBody>
                    </Popover>
                </i>
            );
        } else {
            return (<i className="fa fa-map-marker gray" />)
        }
    }
}

export default FieldAvailabilityRuleIcon;