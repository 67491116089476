import React from 'react';
import './css.css';
import MessageListItem from './partials/message';
import Loader from '../common/Loader';
import LineLoader from '../common/LineLoader';
import { Animated } from 'react-animated-css';
import moment from 'moment';
import Pic from './partials/pic';
import NewMessage from '../common/modals/NewMessage';
import config from '../../config';
import request from 'superagent';
import Menu from '../leagues/menu';

class MessageCenter extends React.Component {

  state = {
    bootstrapped: false,
    selectedMessage: null,
    selectedIndex: null,
    addingMessage: false,
    mode: 1 //[1: received, 2: sent]
  }

  componentWillMount() {
    const { setTitle, messagesActions, params, familyActions, leagueActions } = this.props, { idLeague } = params;
    setTitle && setTitle({ main: 'Message Center' });
    messagesActions && messagesActions.fetchReceived(idLeague);
    messagesActions && messagesActions.fetchSent(idLeague);
    leagueActions && leagueActions.fetchLeague(idLeague);
    familyActions && familyActions.fetchMembers();
  }

  componentWillReceiveProps = nextProps => {
    const { messages_received, messages_sent } = nextProps;
    this.setState({ messages_received, messages_sent, bootstrapped: true });
  }

  renderMessageItem = (message, index) => {
    const { selectedIndex } = this.state;
    return (<MessageListItem key={index} index={index} selected={selectedIndex === index} message={message} onSelect={() => this.onSelect(index)} onDelete={() => this.onDelete(index)} />)
  }

  renderMessage = () => {
    const { selectedMessage } = this.state;
    if (!selectedMessage) return (<section></section>);
    return (<Animated animationIn="fadeInRight" className="message-container">
      <div className="message-header-wrapper d-flex flex-row align-self-center">
        <div className="d-flex flex-column">
          <div className="blue-dark toBold">{selectedMessage.Subject}</div>
          <div className="blue-dark toBold">From: {selectedMessage.From}</div>
          <div className="blue-dark">{moment(selectedMessage.DateCreated).format('MMM Do YYYY @ H:mmA')}</div>
        </div>
        <div className="ml-auto align-self-center">
          <Pic key={selectedMessage.IdMessage} url={`${selectedMessage.LeagueImage || selectedMessage.UserImage}`} />
        </div>
      </div>
      <div className="message-text-wrapper black">
        <p dangerouslySetInnerHTML={{ __html: selectedMessage.Body }} />
      </div>
    </Animated>)
  }

  onSelect = (index) => {
    const { messages_received, messages_sent, mode } = this.state;
    this.setState({ selectedIndex: index, selectedMessage: mode === 1 ? messages_received[index] : messages_sent[index] }, () => {
      const { selectedMessage } = this.state;
      if (!selectedMessage.WasRead) {
        // Call api to set the message as 'read'      
        request.patch(`${config.apiEndpoint}/api/v4/messages/markAsRead/${selectedMessage.IdMessage}`)
          .set('auth_token', localStorage.getItem
            ('sportslogic.authtoken'))
          .send({})
          .then((data) => {
            selectedMessage.WasRead = true;
            this.setState({ selectedMessage, messages_received, messages_sent });
          });
      }
    });
  }
  onDelete = (index) => {
    this.setState({ microTransac: true }, () => {
      let { mode, messages_received, messages_sent } = this.state;
      // Call api to set the message as 'read'    
      let selectedMessage = mode === 1 ? messages_received[index] : messages_sent[index];
      request.delete(`${config.apiEndpoint}/api/v4/messages/${selectedMessage.IdMessage}`)
        .set('auth_token', localStorage.getItem
          ('sportslogic.authtoken'))
        .then(() => {
          switch (mode) {
            case 1:
              messages_received = [...messages_received.slice(0, index), ...messages_received.slice(index + 1)];
              break;
            case 2:
              messages_sent = [...messages_sent.slice(0, index), ...messages_sent.slice(index + 1)];
              break;
            default: break;
          }
          this.setState({ messages_received, messages_sent, microTransac: false });
        });
    });
  }
  onMode = mode => this.setState({ mode, selectedIndex: null, selectedMessage: null });
  onAddMessage = () => this.setState({ addingMessage: !this.state.addingMessage });

  render() {
    const { bootstrapped, selectedMessage, messages_received, messages_sent, mode, addingMessage, microTransac } = this.state,
      { family, params } = this.props, { idLeague } = params;
    return (
      <section id="message_center" className="w-100 h-100">
        {microTransac && <LineLoader />}

        {/* LEFT MENU */}
        <Menu option="message_center" idLeague={idLeague} />


        <div className="d-flex flex-row" style={{ position: 'absolute', top: 0, height: '3em', left: 100, right: 0 }}>
          <button onClick={() => this.onMode(1)} className={`btn btn-sm btn-${mode === 1 ? 'info' : 'link'} align-self-center white font-10`}><i className="fa fa-arrow-down" /> Received</button>
          <button onClick={() => this.onMode(2)} className={`btn btn-sm btn-${mode === 2 ? 'info' : 'link'} align-self-center white font-10`}><i className="fa fa-arrow-up" /> Sent</button>
          <div className="d-flex flex-row align-self-center ml-auto">
            <button className="btn-info btn btn-sm margin-right-half" onClick={this.onAddMessage}><i className="fa fa-plus"></i> New Message</button>
          </div>
        </div >

        <div id='message_wrapper' className="white">
          {!bootstrapped && <Loader message={`Loading messages`} />}
          {bootstrapped && selectedMessage && this.renderMessage()}
        </div>

        {messages_received && mode === 1 && <ul id="messages_list" className="">
          {messages_received.map((message, index) => this.renderMessageItem(message, index))}
        </ul>}

        {messages_sent && mode === 2 && <ul id="messages_list" className="" >
          {messages_sent.map((message, index) => this.renderMessageItem(message, index))}
        </ul>}

        {addingMessage && <NewMessage toggle={this.onAddMessage} isOpen={addingMessage}
          family={family} idLeague={idLeague} {...this.props} />}
      </section >)
  }
}

export default MessageCenter;
