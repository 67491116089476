import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../../helpers';
import moment from 'moment';
import { Link } from 'react-router';

const source = {
  beginDrag(props) {
    return props;
  }
};

class AcceptedTeam extends React.Component {

  state = {
    popoverTeamsOpen: false,
    popoverRoleOpen: false
  }


  togglePopoverTeams = () => {
    this.setState({
      popoverTeamsOpen: !this.state.popoverTeamsOpen
    })
  }

  togglePopoverRole = () => {
    this.setState({
      popoverRoleOpen: !this.state.popoverRoleOpen
    })
  }   

  render() {
    const
      { connectDragSource, item, season } = this.props; // isDragging    

    return connectDragSource(
      <tr className={`${item.New ? 'bg-success white' : ''}`}>
        <td>
          {item.IdUser}
        </td>
        <td className="font-14">
          <Link className={`btn btn-link ${item.New ? 'white' : 'blue'} underlined`} to={`/season/${season.IdSeason}/player/${item.IdUser}`}>
            {item.TeamName}
          </Link>
        </td>
        <td>{item.RoleStatus}</td>
        <td>
          {moment(item.RegistrationDate).format('MMM Do YYYY')}
        </td>
        <td className="d-flex flex-column">
          <span className="block font-10">{`${item.FirstName} ${item.LastName}`}</span>
          <span className="block font-8">{item.EmailAddress}</span>
          <span className="block font-8">{item.PhoneNumber}</span>
        </td>
      </tr>
    );
  }
}

export default flow(
  DragSource(ItemTypes.ROSTEREDPLAYER, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(AcceptedTeam);
