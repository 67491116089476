module.exports = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'A',
    E: 'B',
    F: 'C',
    G: 'A',
    H: 'B',
    I: 'C',
    J: 'A',
    K: 'B',
    L: 'C',
    M: 'A',
    N: 'B',
    O: 'C',
    P: 'A',
    Q: 'B',
    R: 'C',
    S: 'A',
    T: 'B',
    U: 'C',
    V: 'A',
    W: 'B',
    X: 'C',
    Y: 'A',
    Z: 'B',
    
};