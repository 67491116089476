import React from 'react';
import './exceptions.css';
import TimeExceptions from './TimeExceptions';
import MatchupExceptions from './MatchupExceptions';

class Exceptions extends React.Component {

  state = {
    activeTab: 1
  }

  changeTab = activeTab => this.setState({ activeTab });

  render() {
    const { activeTab } = this.state;
    return (
      <div>
        <ul className="nav nav-tabs font-16">
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}><i className="far fa-clock red"></i> {activeTab === 1 ? <span className="half-idented">Time Exceptions</span> : ''}</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}><i className="fa fa-random red"></i> {activeTab === 2 ? <span className="half-idented">Matchup Exceptions</span> : ''}</button>
          </li>
        </ul>
        <div className="tab-content" style={{ left: 0, right: 0 }}>
          {activeTab === 1 && <TimeExceptions {...this.props} />}
          {activeTab === 2 && <MatchupExceptions {...this.props} />}
        </div>
      </div>
    )
  }
}

export default Exceptions;
