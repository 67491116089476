import React from 'react';
import { Link } from 'react-router';
import { PieChart, Legend } from 'react-easy-chart';
import './css.css';
import config from '../../../../config';
import { Animated } from 'react-animated-css';

const TeamsChart = props => {

    const { season } = props,
        palette = config.palette;

    // Build pie dashboard and legend
    const pieData = [],
        pieConfig = [];

    if ((season.IdSeasonType === 3 ? season.TotalTeams_Active : season.TotalPlayers_Active)) {
        pieData.push({
            key: (season.IdSeasonType === 3 ? season.TotalTeams_Active : season.TotalPlayers_Active) || 0,
            value: (season.IdSeasonType === 3 ? season.TotalTeams_Active : season.TotalPlayers_Active) || 0,
            color: palette.green,
            legend: 'Active'
        });
        pieConfig.push({ color: palette.green });
    }

    if ((season.IdSeasonType === 3 ? season.TotalTeams_Pending : season.TotalPlayers_Pending)) {
        pieData.push({
            key: (season.IdSeasonType === 3 ? season.TotalTeams_Pending : season.TotalPlayers_Pending) || 0,
            value: (season.IdSeasonType === 3 ? season.TotalTeams_Pending : season.TotalPlayers_Pending) || 0,
            color: palette.blue,
            legend: 'Pending'
        });
        pieConfig.push({ color: palette.blue });
    }

    if ((season.IdSeasonType === 3 ? season.TotalTeams_Inactive : season.TotalPlayers_Inactive)) {
        pieData.push({
            key: (season.IdSeasonType === 3 ? season.TotalTeams_Inactive : season.TotalPlayers_Inactive) || 0,
            value: (season.IdSeasonType === 3 ? season.TotalTeams_Inactive : season.TotalPlayers_Inactive) || 0,
            color: palette.red, //'#dc3545',
            legend: 'Inactive'
        });
        pieConfig.push({ color: palette.red });
    }

    if ((season.IdSeasonType === 3 ? season.TotalTeams_Waiting : season.TotalPlayers_Waiting)) {
        pieData.push({
            key: (season.IdSeasonType === 3 ? season.TotalTeams_Waiting : season.TotalPlayers_Waiting) || 0,
            value: (season.IdSeasonType === 3 ? season.TotalTeams_Waiting : season.TotalPlayers_Waiting) || 0,
            color: palette.yellow, //'#dc3545',
            legend: 'Waitlisted'
        });
        pieConfig.push({ color: palette.yellow });
    }
    //

    let isThereAnyData = season.IdSeasonType === 3 ? season.TotalTeams : season.TotalPlayers_ActivePendingWaiting;

    return (
        <div className="toUpper d-flex flex-column no-padding justify-content-middle" id="team_chart">
            <h4 className="black sqwad-font text-center m-4">{season.Label}</h4>
            <div className="mt-2 row">

                {/* left */}
                {season.IdSeasonType === 3 && <Animated animationIn="fadeIn" className="col-3 black d-flex flex-column text-right align-self-center no-padding">
                    <span className="font-8">Total<br />Teams</span>
                    <span className="green font-20">{season.TotalTeams}</span>
                    <span className="margin-top font-8">Participating<br />
                        Teams</span>
                    <span className="green font-20">{season.TotalParticipatingTeams || 0}</span>
                </Animated>}
                {season.IdSeasonType !== 3 && <Animated animationIn="fadeIn" className="col-3 black d-flex flex-column text-right align-self-center no-padding">
                    <span className="font-8">Total<br />Players</span>
                    <span className="green font-20">{season.TotalPlayers_ActivePendingWaiting || 0}</span>
                    <span className="margin-top font-8">Participating<br />
                        Players</span>
                    <span className="green font-20">{season.TotalPlayers_ActivePending || 0}</span>
                </Animated>}

                {/* middle */}
                <div className={`col-6 no-margin no-padding ${!isThereAnyData ? 'd-flex h-100 flex-row' : ''}`} id="teams-chart">
                    {isThereAnyData !== 0 &&
                        <PieChart labels size={200} data={pieData} />}
                    {isThereAnyData === 0 && <i className="fa fa-question green m-auto align-self-center" style={{ fontSize: '170px' }} />}
                    {isThereAnyData !== 0 && <div className='d-flex flex-row justify-content-center' style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        <div style={{ width: 80, height: 80, borderRadius: 40, backgroundColor: 'white' }} className="align-self-center d-flex flex-row justify-content-center">
                            <span className="black font-12 align-self-center">{isThereAnyData}</span>
                        </div>
                    </div>}
                </div>

                {/* right */}
                {season.IdSeasonType === 3 && <Animated animationIn="fadeIn" className="col-3 black d-flex flex-column align-self-center no-padding">
                    <span className="font-8">Total<br />Divisions</span>
                    <span className="green font-20">{season.TotalDivisions || 0}</span>
                    <span className="margin-top font-8">Participating<br />Players</span>
                    <span className="green font-20">{season.TotalPlayers_ActivePending || 0}</span>
                </Animated>}

                {season.IdSeasonType !== 3 && <Animated animationIn="fadeIn" className="col-3 black d-flex flex-column align-self-center no-padding">
                    <span className="font-8">Total<br />Divisions</span>
                    <span className="green font-20">{season.TotalDivisions || 0}</span>
                    {season.TotalTeams > 0 && <span className="margin-top font-8">Teams</span>}
                    {season.TotalTeams > 0 && <Link to={`/season/${season.IdSeason}/teams`} className="green font-20">{season.TotalTeams || 0}</Link>}
                </Animated>}
            </div>
            <div className=" mt-4">
                <Animated animationIn="zoomIn" animationInDelay={900} className="col-12">
                    <Legend data={pieData} dataId={'legend'} horizontal config={pieConfig} />
                </Animated>
            </div>
        </div>
    )
}

export default TeamsChart;
