import React from 'react';
import _ from 'lodash';
import { generateRandomId } from '../../../../../../helpers';
import Question from './question';
import Answer from './answer';
import { Jumbotron } from 'reactstrap';

class Comp extends React.Component {

  addQuestion = () => {
    const { itemType } = this.props;
    this.props.questionsActions &&
      this.props.questionsActions.addQuestion({
        question_type: itemType,
        editing: true,
        id: generateRandomId(10),
      });
  }

  addAnswer = () => {
    const { itemType } = this.props;
    this.props.questionsActions &&
      this.props.questionsActions.addAnswer({
        answer_type: itemType,
        editing: true,
        id: generateRandomId(10),
        Amount: 0
      });
  }

  render() {
    const { questionTexts, answers, lock, itemType } = this.props;
    const questions = _.filter(questionTexts, qt => {
      return parseInt((qt.question_type || qt.IdQuestionType), 10) ===
        parseInt(itemType, 10);
    });
    const typeAnswers = _.filter((answers || []), a => {
      return parseInt((a.answer_type || a.IdQuestionType), 10) === parseInt(itemType, 10);
    });

    return <section className="w-100 d-flex flex-row p-2">
      <div className="w-100">
        <h4>Question Texts</h4>
        <hr className="no-margin" />
        <ul>
          {questions.map((questionText, i) => <li className="card margin-top-half" key={i}>
            <Question question={questionText} {...this.props} />
          </li>)}
        </ul>
        {lock && questions.length === 0 && <Jumbotron className="margin-top-half">
          <h2>You have no question texts on this sub-type</h2>
          <p className="font-10">You can always add question text on the Glossary section at the top</p>
        </Jumbotron>}
        {!lock && <button className="btn btn-sm btn-block btn-warning margin-top" onClick={this.addQuestion}>
          <i className="fa fa-plus margin-right-half" />Add Question Text</button>}
      </div>
      <div className="w-100" style={{ paddingLeft: '1em' }}>
        <h4 className="d-flex flex-row">Possible Answers</h4>
        <hr className="no-margin" />
        <ul>
          {(typeAnswers || []).map((answer, i) => <li className="card margin-top-half" key={i}><Answer answer={answer} {...this.props} /></li>)}
        </ul>
        {lock && typeAnswers.length === 0 && <Jumbotron className="margin-top-half">
          <h2>You have no possible answers on this sub-type</h2>
          <p className="font-10">You can always add answers on the Glossary section at the top</p>
        </Jumbotron>}
        {!lock && <button className="btn btn-sm btn-block btn-warning margin-top" onClick={this.addAnswer}>
          <i className="fa fa-plus margin-right-half" />Add Answer</button>}
      </div>
    </section >
  }
}

export default Comp;
