import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import ReactTooltip from 'react-tooltip';
import { ItemTypes } from '../../../../../../helpers';
import _ from 'lodash';
import Icon from '../icon';
import { Popover, PopoverBody, } from 'reactstrap';

const target = {
    canDrop(props) {
        // TODO: evaluate props to see if it can be dropped
        return true;
    },
    drop(props, monitor, component) {
        // Dropped in another a child component that also accepts this type of items
        if (monitor.didDrop()) return;
        const { division, divisionsActions, params } = props, { lock, type } = monitor.getItem();
        // Evaluate the type of item that's being dropped
        switch (type) {
            case ItemTypes.ROSTERLOCK:
                divisionsActions &&
                    divisionsActions.assignRosterLock(division.IdDivision, lock.IdTeamUserRoleLock, params.idSeason);
                break;
            default: break;
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class DivisionListItem extends React.Component {

    static propTypes = {
        locks: PropTypes.array,
    }

    static defaultProps = {
        locks: [],
    }

    state = {
        collapsed: true,
        division: null,
        locks: [],
        settingLimit: false
    }

    // Lifecycle
    componentWillMount() {
        this.setup(this.props);
    }
    componentWillReceiveProps = (nextProps) => {
        this.setup(nextProps);
    }
    //

    setup = (props) => {
        const { division, locks, } = props;
        this.setState({
            division, locks: _.filter(locks, lock => {
                return _.find((division.locks || []), dl => {
                    return (dl.IdTeamUserRoleLock === lock.IdTeamUserRoleLock);
                });
            })
        });
    }

    setlimit = (e) => {
        e.preventDefault && e.preventDefault();
        const { division } = this.state, { divisionsActions, params } = this.props;
        division.RosterLimit = this.txtLimit.value;
        divisionsActions && divisionsActions.setLimit({ idDivision: division.IdDivision, limit: this.txtLimit.value, idSeason: params.idSeason });
        this.setState({ division, settingLimit: false });
    }

    toggleLock = () => {
        const { division } = this.state, { divisionsActions } = this.props;
        divisionsActions && divisionsActions[division.IsLocked ? 'unlock' : 'lock'](division.IdDivision);
        division.IsLocked = !division.IsLocked;
        this.setState({ division });
    }
    toggleSetLimit = () => this.setState({ settingLimit: !this.state.settingLimit });

    render() {
        const { connectDropTarget, isOverCurrent, canDrop, division, } = this.props,
            { collapsed, locks, settingLimit } = this.state;

        return connectDropTarget(
            <li style={{ borderBottom: '1px solid #e5e5e5' }} className={`${collapsed ? 'off' : 'on'} division p-1 block font-10 ${canDrop ? 'debug' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>
                <span className="idented-half margin-right-half align-self-center">{division.Name}</span>
                {locks && locks.map((program, i) => <Icon location={1} color="red" key={i} index={i} program={program} {...this.props} />)}
                
                <i onClick={this.toggleLock} className={`margin-right-half fas fa-lock${division.IsLocked ? ' red' : '-open black'}`}
                    data-tip={`${division.IsLocked ? 'Locked' : 'Open'}`} data-for={`lock-${division.IdDivision}`}>
                    <ReactTooltip effect="solid" place="top" id={`lock-${division.IdDivision}`} />
                </i>
                
                <button onClick={this.toggleSetLimit} id={`id-${division.IdDivision}-limit`} className={`btn btn-sm font-8 no-padding align-self-center ${division.RosterLimit ? 'red' : 'blue'}`}>{division.RosterLimit ? `Limit ${division.RosterLimit} players` : 'Set Limit'}</button>
                
                <Popover placement="bottom" isOpen={settingLimit} target={`id-${division.IdDivision}-limit`} toggle={this.toggleSetLimit}>
                    <PopoverBody>
                        <form className="d-flex flex-row">
                            <input defaultValue={division.RosterLimit || 0} ref={(i) => this.txtLimit = i} style={{ width: 80 }} type="number" className="form-control" />
                            <button type="submit" onClick={this.setlimit} className="btn btn-success"><i className="fa fa-check white" /></button>
                            <button type="button" onClick={this.toggleSetLimit} className="btn btn-danger"><i className="fa fa-times white" /></button>
                        </form>
                    </PopoverBody>
                </Popover>
            </li>)
    }
}

export default DropTarget([ItemTypes.ROSTERLOCK], target, collect)(DivisionListItem);
