import React from 'react';
import _ from 'lodash';

class CoachListItem extends React.Component {

    state = {
        collapsed: true
    }

    toggle = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    checkCoach = () => {
        const { coach, divisions } = this.props;
        coach.checked = !coach.checked;
        coach.teams.forEach(t => t.checked = coach.checked);
        _.chain(divisions).map((division) => {
            return _.chain(division.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value();
        }).flatten().filter((team) => {
            return _.find(coach.teams, t => t.IdTeam === team.IdTeam);
        }).value().forEach((team) => {
            team.checked = coach.checked;
        })
        this.setState({ collapsed: false });
        this.props.notify && this.props.notify();
    }

    checkTeam = (team) => {
        const { divisions } = this.props;
        team.checked = !team.checked;
        _.chain(divisions).map((division) => {
            return _.chain(division.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value();
        }).flatten().filter((t) => {
            return t.IdTeam === team.IdTeam;
        }).value().forEach((t) => {
            t.checked = team.checked;
        })
        this.setState({ collapsed: false });
        this.props.notify && this.props.notify();
    }

    render() {
        const { coach } = this.props,
            { collapsed } = this.state;
        let coachTeams = coach.teams, hasTeams = coachTeams.length;

        return (<li className={`${collapsed ? 'off' : 'on'} coach block no-margin pl-4 no-padding`}>
            <div className="d-flex flex-row">
                <button className="btn btn-sm btn-default" onClick={this.toggle}>
                    <i className={`fa fa-caret-${collapsed ? 'right' : 'down'} ${hasTeams ? '' : 'white'}`} /></button>
                <button className={`btn btn-sm btn-default d-flex flex-row`} onClick={this.checkCoach}>
                    <i className={`fa-${coach.checked ? 'check-circle green fas' : 'circle far'} align-self-center`} />
                    <span className="margin-left align-self-center">{coach.Name || coach.UserName || coach.CoachName}{hasTeams ? ' (' + coachTeams.length + ' teams)' : ''}</span></button>
            </div>
            {!collapsed && <ul className="flights-wrapper no-style-lists no-margin pl-2">
                {coachTeams.map((team, i) => <li className="no-margin no-padding" key={i}>
                    <button className={`btn btn-sm btn-default d-flex flex-row`} onClick={() => this.checkTeam(team)}>
                        <i className={`fa-${team.checked ? 'check-circle green fas' : 'circle far'} align-self-center`} />
                        <span className="margin-left align-self-center">{(team.Name || team.TeamName)}</span></button>
                </li>)}
            </ul>}
        </li>)
    }
}

export default CoachListItem;