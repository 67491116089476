import React from 'react';

class Coach extends React.Component {
  state = { coach: null }
  componentWillMount = () => {
    this.setState({ coach: this.props.coach });
  }
  onImageError = () => {
    const { coach } = this.state;
    coach.UserImageUrl = '/images/defaults/male.png'; // TODO: add Gender to the service to genderize this
    this.setState({ coach });
  }
  render() {
    const { fnSelect } = this.props, { coach } = this.state;
    return (
      <div onClick={fnSelect} className={`d-flex flex-column p-1 coach-box ${coach.selected ? 'bg-success white' : ''}`}>
        <img alt="" className="smallPic align-self-center" src={coach.UserImageUrl} onError={this.onImageError} />
        <span className="font-8 align-self-center text-center">{coach.UserName}</span>
      </div>)
  }
}

export default Coach;
