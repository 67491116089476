import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../common/CustomDatePicker';
import { Popover, PopoverBody } from 'reactstrap';
class UserViolation extends React.Component {

    state = {
        editing: false
    }

    componentWillMount() {
        const { violation } = this.props;
        this.setState({ violation });
    }

    onColor = color => {
        const { note } = this.state;
        note.Color = color;
        this.setState({ note });
    }

    onSave = () => {
        const { violation } = this.state, { seasonActions } = this.props;
        // TODO: call action        
        violation.Violation = this.txtWhat.value || 'Default Violation';
        violation.editing = false;
        violation.DateCreated = moment().format('YYYY-MM-DD');
        violation.SuspendedUntilDate = moment(violation.SuspendedUntilDate).format('YYYY-MM-DD');
        seasonActions && seasonActions.addUserViolation(violation);
        this.setState({ violation });
    }

    onDelete = () => {
        const { violation } = this.state;
        violation.deleting = !violation.deleting;
        this.setState({ violation });
    }



    handleSuspendedUntilDate = date => {
        const { violation } = this.state;
        violation.SuspendedUntilDate = moment(date);
        this.setState({ violation });
    }

    onSelectType = c => {
        const { violation } = this.state;
        violation.Violation = c.Suspension;
        this.txtWhat.value = c.Suspension;
        this.setState({ violation, picking: false });
    }

    renderNormal = () => {
        const { violation } = this.props;
        return <tr>
            <td>{violation.Violation}</td>
            <td>
                <span className="block">{moment(violation.DateCreated.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</span>
                <span className="font-8 block">{`by ${violation.NameFirst} ${violation.NameLast}`}</span>
            </td>
            <td>{moment(violation.SuspendedUntilDate.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</td>
            <td>
                <button className="btn btn-link red" onClick={this.onDelete}><i className="fas fa-times" /></button>
            </td>
        </tr>
    }

    renderEditing = () => {
        const { violation, fnDelete, violation_types } = this.props;
        return <tr>
            <td colSpan={2} >
                <div className="d-flex flex-row">
                    <input defaultValue={violation.Violation} type="text" className="w3-input" placeholder='What' ref={i => this.txtWhat = i} />
                    <button className="btn btn-link" onClick={() => this.setState({ picking: !this.state.picking })}><i className="fas fa-chevron-down black" /></button>
                    { /* Status Popover */}
                    <Popover placement="bottom" isOpen={this.state.picking} target="btnStatus" toggle={() => this.setState({ picking: false })}>
                        <PopoverBody className="d-flex flex-column">
                            {violation_types && violation_types.map((s, i) => (
                                <button key={i} className="btn btn-link btn-sm btn-block d-flex flex-row" onClick={() => this.onSelectType(s)}>
                                    {s.Suspension}
                                </button>))}
                        </PopoverBody>
                    </Popover>
                </div></td>

            <td><DatePicker className="white" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
                customInput={<CustomDatePicker />} selected={moment(violation.SuspendedUntilDate).utc()} selectsStart
                onChange={this.handleSuspendedUntilDate} /></td>
            <td className="d-flex flex-row">
                <button onClick={this.onSave} className="btn btn-link green"><i className="fas fa-check" /></button>
                <button onClick={fnDelete} className="btn btn-link red"><i className="fas fa-times" /></button>
            </td>
        </tr>
    }

    renderDeleting = () => {
        const { fnDelete } = this.props;
        return <tr>
            <td colSpan={4} >
                <div className="d-flex flex-row w-100">
                    <span className="mr-2 ml-auto align-self-center">Confirm delete?</span>
                    <button onClick={fnDelete} className="btn btn-link green align-self-center"><i className="fas fa-check" /></button>
                    <button className="btn btn-link red align-self-center" onClick={this.onDelete}><i className="fas fa-times" /></button>
                </div>
            </td>
        </tr>
    }

    render() {
        const { violation } = this.state;
        if (violation.LocalId === undefined) return <div className="p-4 w-100 m-3 d-flex flex-column" />
        if (violation.deleting) return this.renderDeleting();
        if (violation.editing) return this.renderEditing();
        if (!violation.editing) return this.renderNormal();
    }
}

export default UserViolation;