import React from 'react';

const Person = ({ selectedUser, selectedRole }) => {    
    return (
        <div className="d-flex flex-row justify-content-start montserrat" style={{ height: 60 }}>
            <div style={{ width: 80 }} className="d-flex flex-row justify-content-center align-self-center">
                <div className="cover" style={{ width: 40, height: 40, borderRadius: 20, background: `url(${selectedUser.UserImage || selectedUser.UserImageUrl}?full=80) no-repeat center center` }} />
            </div>
            <div className="d-flex flex-column align-self-center">
                <span className="black font-12 line1">{selectedUser.NameFirst} {selectedUser.NameLast}</span>
                {selectedRole && <span className="text-left font-10 gray mr-2 line1">{selectedRole.Name || selectedRole.RoleName}</span>}
            </div>
        </div>
    );
}

export default Person;
