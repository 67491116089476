import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, generateRandomId } from '../../../../../helpers';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../common/CustomDatePicker';
import CustomTimeScroller from '../../../../common/CustomTimeScroller';
import { Popover, PopoverBody } from 'reactstrap';
import LineLoader from '../../../../common/LineLoader';

const source = {
  beginDrag(props) {
    return props;
  }
}

class Card extends React.Component {

  state = {
    lock: null,
    editing: false, dateStart: null, dateEnd: null, timeStart: null, timeEnd: null, deleting: false,
    popoverStartTimeOpen: false,
    popoverEndTimeOpen: false,
    randomId: generateRandomId(10)
  }

  // Lifecycle
  componentWillMount() {
    const { lock } = this.props;
    this.setState({
      editing: lock.editing,
      lock,
      dateStart: lock.dateStart ? moment(lock.dateStart).utc() : null,
      timeStart: lock.dateStart ? moment(lock.dateStart).utc() : null
    });
  }
  //

  toggleEdit = () => this.setState({ editing: !this.state.editing });
  toggleTimeStart = () => this.setState({ popoverStartTimeOpen: !this.state.popoverStartTimeOpen });
  toggleTimeEnd = () => this.setState({ popoverEndTimeOpen: !this.state.popoverEndTimeOpen });
  toggleDeleting = () => this.setState({ deleting: !this.state.deleting });

  handleChangeStart = (dateStart) => {
    let { dateEnd } = this.state;
    if (dateEnd && moment(dateStart).isAfter(dateEnd)) {
      dateEnd = moment(dateStart).add(1, 'day');
    }
    this.setState({ dateStart, dateEnd });
  }
  handleChangeEnd = (dateEnd) => {
    let { dateStart } = this.state;
    if (dateStart && moment(dateEnd).isBefore(dateStart)) {
      dateStart = moment(dateEnd).add(-1, 'day');
    }
    this.setState({ dateEnd, dateStart });
  }

  doDelete = () => {
    const { index, locksActions } = this.props, { lock } = this.state;
    locksActions && locksActions.deleteLockSchedule(
      index, lock.IdTeamUserRoleLock
    );
  }

  save = () => {
    // TODO: call api to save
    const { locksActions } = this.props;
    let { dateStart, timeStart, lock } = this.state;
    if (dateStart && timeStart) {
      dateStart = moment(dateStart.format('YYYYMMDD') + timeStart.format('HHmm'), 'YYYYMMDDHHmm');
    }
    lock.dateStart = dateStart;
    if (lock.IdTeamUserRoleLock) locksActions.updateLock(lock);
    else locksActions.saveLock(lock);
    this.setState({ editing: !this.state.editing })
  }


  renderThing = () => {

    const { unmovable, isDragging, } = this.props, { editing, dateStart, dateEnd, fullDay, timeStart, randomId, lock, } = this.state;

    return <li style={{ marginRight: unmovable ? 0 : '1em' }} className={`lock-roster-card bg-danger idented-half d-flex flex-column ${isDragging ? 'dragging' : ''}`}>
      {!editing && !lock.IdTeamUserRoleLock && <LineLoader />}
      <span className="font-12 toBold white d-flex flex-row">
        <i className="fas fa-clock margin-right-half align-self-center" />
        <span className="align-self-center">Lock</span>
        <button onClick={this.toggleDeleting} id={`id-${randomId}-delete`} className="btn btn-sm btn-link ml-auto align-self-center"><i className="fa fa-times" /></button>
        <Popover placement="bottom" isOpen={this.state.deleting} target={`id-${randomId}-delete`} toggle={this.toggleDeleting}>
          <PopoverBody className="d-flex flex-column justify-content-end">
            <span className="white">Are you sure?</span>
            <button onClick={this.doDelete} className="btn btn-success btn-sm">Yes, delete it</button>
            <button onClick={this.toggleDeleting} className="btn btn-danger btn-sm">No, cancel</button>
          </PopoverBody>
        </Popover>
        {editing && <button onClick={this.save} className="btn btn-sm btn-success align-self-center"><i className="fa fa-check" /></button>}
      </span>
      {!editing && <div className="d-flex flex-column">
        {dateStart && <span className="white m-auto">Starts: {dateStart.format('MMM Do YYYY @ HH:mm')}</span>}
        {dateEnd && false && <span className="white m-auto">Ends: {dateEnd.format('MMM Do YYYY @ HH:mm')}</span>}
      </div>}
      {editing && <div className="d-flx flex-column margin-top">
        {/* Starts */}
        <div className="d-flex flex-row justify-content-center">
          <span className="margin-right-half align-self-center white">Starts:</span><DatePicker customInput={<CustomDatePicker />} selected={dateStart} selectsStart startDate={dateStart} endDate={dateEnd}
            onChange={this.handleChangeStart}
            showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} />
          <button onClick={this.toggleTimeStart} id={`id-${randomId}-start`} className={`btn btn-sm btn-${fullDay ? 'secondary' : 'info'}`}>{'@ '}
            {timeStart ? moment(timeStart).format('h:mmA') : 'Select Time'}
          </button>
          <Popover placement="bottom" isOpen={this.state.popoverStartTimeOpen} target={`id-${randomId}-start`} toggle={this.toggleTimeStart}>
            <PopoverBody className="d-flex flex-column justify-content-end">
              <CustomTimeScroller time={timeStart} fnChange={(d) => {
                this.setState({ timeStart: d })
              }} />
            </PopoverBody>
          </Popover>
        </div>
        {/* Ends */}
        {/*<div className="d-flex flex-row justify-content-center margin-top-half">
          <span className="margin-right-half align-self-center white">Ends:</span><DatePicker customInput={<CustomDatePicker />} selected={dateEnd} selectsEnd startDate={dateStart} endDate={dateEnd}
            onChange={this.handleChangeEnd}
            showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} />
          <button onClick={this.toggleTimeEnd} id={`id-${randomId}-end`} className={`btn btn-sm btn-${fullDay ? 'secondary' : 'info'}`}>{'@ '}
            {timeEnd ? moment(timeEnd).format('h:mmA') : 'Select Time'}
          </button>
          <Popover placement="bottom" isOpen={this.state.popoverEndTimeOpen} target={`id-${randomId}-end`} toggle={this.toggleTimeEnd}>
            <PopoverBody className="d-flex flex-column justify-content-end">
              <CustomTimeScroller time={timeEnd} fnChange={(d) => {
                this.setState({ timeEnd: d })
              }} />
            </PopoverBody>
          </Popover>
            </div>*/}
      </div>}
      {!unmovable && !editing && <span style={{ padding: '0 0.5em', border: '1px solid #e5e5e5' }} className="btn btn-success btn-sm movable-hint font-8" onClick={this.toggleEdit}><i className="fas fa-edit" />{' '}Edit</span>}
    </li>
  }
  render() {
    const { connectDragPreview, connectDragSource, unmovable } = this.props;
    if (unmovable) return this.renderThing();
    else return connectDragPreview(connectDragSource(this.renderThing()));
  }
}

export default flow(
  DragSource(ItemTypes.ROSTERLOCK, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(Card);
