import React from 'react';

const Coach = ({coach}) => {    
    return (
        <figure className="card w3-card hoverable w-100 m-2 d-flex flex-column h-auto align-self-center" style={{ maxWidth: 250, }}>
                <div style={{ height: 250, background: `url(${coach.Image || coach.ImageUrl}) no-repeat center center`, backgroundSize: 'cover' }} className="w-100 d-flex flex-column justify-content-end">
                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 50 }} className="w-100 d-flex flex-row justify-content-center">
                        <span className="white  align-self-center">{coach.RoleOverrideName}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center bg-white p-2">
                    <span className="black align-self-center">{coach.NameFirst} {coach.NameLast}</span>
                </div>                
            </figure>
    );
};

export default Coach;