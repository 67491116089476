import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import config from '../../../config';
import _ from 'lodash';
import request from 'superagent';
import LineLoader from '../LineLoader';
import BlueishHeader from '../things/BlueishHeader';
import BlackishHader from '../things/BlackishHeader';
import { formatPrice } from '../../../helpers';

class MoveTeamModal extends React.Component {
    state = {
        team: null,
        divisions: null,
        selectedSeason: null,
        originalSeason: null,
        isSeasonsPaneOpened: false,
        microTransac: false,
        originalDivision: null,
        selectedDivision: null,
        fetchingOriginalBalance: false,
        originalBalance: 0,
        estimatedFee: 0,
        fetchingFees: false,
        moving: false
    }

    componentWillMount() {        
        this.setState({
            selectedSeason: this.props.selectedSeason,
            originalSeason: {
                ...this.props.selectedSeason
            },
            originalDivision: {
                ...this.props.team
            },
            team: this.props.team
        }, () => {
            this.fetchDivisions();
        });
    }

    toggleSeasonsPane = () => {
        this.setState((current) => {
            return {
                isSeasonsPaneOpened: !current.isSeasonsPaneOpened
            }
        });
    }

    selectSeason = (s) => {
        this.setState((current) => {
            return {
                selectedSeason: s,
                selectedDivision: null,
                divisions: null,
                isSeasonsPaneOpened: !current.isSeasonsPaneOpened
            }
        }, () => {
            this.fetchDivisions();
        });
    }

    selectDivision = (selectedDivision) => {
        this.setState({
            selectedDivision,
            fetchingFees: true
        }, () => { this.fetchFees(selectedDivision.IdDivision); });
    }

    fetchFees = (idDivision) => {

        const { selectedSeason } = this.state;

        request.get(selectedSeason.IdSeasonType === 5 ? `${config.apiEndpoint}/api/v4/paymentPlans/hybrid_team/${idDivision}` : `${config.apiEndpoint}/api/v4/paymentPlans/${idDivision}/1`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {

                const { selectedDivision, originalSeason } = this.state, { seasons } = this.props;

                // Calculate estimated fee based on the difference between the 
                // Full payment 'plan' and the current balance
                const plan = _.last(data.body.plans), { fullBalance } = this.props;                
                this.setState({
                    fetchingFees: false,
                    estimatedFee: plan ? (plan.Total - (fullBalance.TotalPaidLessRefunds + (fullBalance.TotalAdjustmentCredits_Adm || 0))) : 0,  // (fullBalance.TotalPaid + (fullBalance.TotalAdjustmentCredits_Adm || 0))) : 0
                    //                       210     -         (230             -                20)
                }, () => {
                    let { estimatedFee } = this.state;

                    // NEW RULE: if its challenge sports and the move is between divisions of the same season, fee is zero (0)
                    if (parseInt(_.find(seasons, s => parseInt(s.IdSeason, 10) === parseInt(originalSeason.IdSeason, 10)).IdLeague, 10) === 11826 &&
                        parseInt(selectedDivision.IdSeason, 10) === parseInt(originalSeason.IdSeason, 10)) estimatedFee = 0;
                    //

                    this.setState({ estimatedFee });
                });
            });
    }

    fetchDivisions = () => {
        const { selectedSeason, team } = this.state;

        this.setState({
            microTransac: true
        }, () => {

            request.get(`${config.apiEndpoint}/api/v4/divisions/${selectedSeason.IdSeason}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {

                    var selectedDivision = null;

                    data.body.divisions.forEach((d) => {
                        if (parseInt(d.IdDivision, 10) === parseInt(team.IdDivision, 10)) {
                            selectedDivision = d;
                        }
                    });

                    this.setState({
                        microTransac: false,
                        selectedDivision,
                        divisions: data.body.divisions
                    });

                });
        });
    }

    move = () => {
        const { fnMoved, team } = this.props;
        if (!this.state.moving) {
            this.setState({
                moving: true,
                movingWithCharges: true,
                error: null
            }, () => {
                request.post(`${config.apiEndpoint}/api/v5/registration/move/team`)
                    .send({
                        idTeamDivision: team.IdTeamDivision,
                        idDivision: this.state.selectedDivision.IdDivision,
                        idOrderItem: this.props.idOrderItem,
                        adjustmentAmount: this.state.estimatedFee
                    })
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        fnMoved && fnMoved(this.state.selectedSeason);
                    }, error => {
                        this.setState({ moving: false, movingWithCharges: false, error: _.last(error.response.body.error.message.split(':')) })
                    });
            });
        }
    }

    moveFree = () => {
        const { fnMoved, team } = this.props;
        if (!this.state.moving) {
            this.setState({
                moving: true,
                movingFree: true,
                error: null
            }, () => {
                request.post(`${config.apiEndpoint}/api/v5/registration/move/team`)
                    .send({
                        idTeamDivision: team.IdTeamDivision,
                        idDivision: this.state.selectedDivision.IdDivision,
                        idOrderItem: this.props.idOrderItem,
                        adjustmentAmount: 0
                    })
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        fnMoved && fnMoved(this.state.selectedSeason);
                    }, error => {
                        this.setState({ moving: false, movingFree: false, error: _.last(error.response.body.error.message.split(':')) })
                    });
            });
        }
    }

    render() {
        const { team, isOpen, toggle, seasons } = this.props,
            { selectedSeason, isSeasonsPaneOpened, divisions, microTransac, selectedDivision,
                originalDivision, originalSeason, fetchingFees, moving } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 650 }}>
                <ModalHeader toggle={toggle}>
                    Move
                    {' '}
                    {team.Name || team.TeamName}
                </ModalHeader>
                <ModalBody className="container-fluid no-padding no-margin">
                    {microTransac && <LineLoader />}
                    <div className="row no-padding no-margin">
                        { /* Seasons */}
                        <div className="col-12 no-padding no-margin">
                            <button onClick={this.toggleSeasonsPane} className="btn btn-link btn-block d-flex flex-row bg-white">
                                <span className="align-self-center font-8">Season:</span>
                                <i className={`fas fa-circle align-self-center idented-half color-${selectedSeason.SeasonType} font-8`} />
                                <span className="align-self-center idented-half font-8">{selectedSeason.SeasonName || selectedSeason.Name}</span>
                                <i className="fa fa-caret-down ml-auto align-self-center" />
                            </button>
                            <div style={{ height: 3, backgroundColor: '#e5e5e5' }} />
                            {isSeasonsPaneOpened && (
                                <ul className="bg-white">
                                    {seasons && seasons.map((s, i) => (
                                        <li key={i} style={{ borderTop: '1px solid #ccc' }} className={`${s.IdSeason === selectedSeason.IdSeason ? 'bg-warning' : 'bg-white'}`}>
                                            <button className="btn btn-link d-flex flex-row btn-block font-8" onClick={() => this.selectSeason(s)}>
                                                <i className={`fas fa-circle align-self-center color-${s.SeasonType}`} />
                                                <span className="idented-half align-self-center">{s.SeasonName || s.Name}</span>
                                            </button>
                                        </li>))}
                                </ul>)}
                            {isSeasonsPaneOpened && <hr style={{ margin: 0 }} />}
                        </div>
                        { /* Left Side */}
                        <div className="col-6 no-padding no-margin" style={{ position: 'relative', maxHeight: 700, overflowY: 'auto' }}>
                            {divisions && divisions.length > 0 && divisions.map((d, i) => (
                                <div key={i}>
                                    <button className={`${originalDivision.IdDivision === d.IdDivision ? 'bg-info' : ''} btn btn-link btn-sm btn-block d-flex flex-row`} style={{ borderBottom: '1px solid #e5e5e5' }} onClick={() => this.selectDivision(d)}>
                                        <i className={`align-self-center ${(selectedDivision && selectedDivision.IdDivision === d.IdDivision) ? 'fas fa-check-square green' : 'far fa-square'}`} />
                                        <span className="align-self-center idented-half">{d.DivisionName || d.Name}</span>
                                    </button>
                                </div>
                            ))}
                        </div>
                        { /* Right Side */}
                        <div className="col-6 no-margin d-flex flex-column no-padding no-margin bg-gray" style={{ borderLeft: '1px solid #ccc' }}>
                            { /* From */}
                            <BlackishHader message="From" />
                            <section className="p-2 d-flex flex-column bg-white">
                                <span className="font-8 blue">Season</span>
                                <span className="margin-bottom">{originalSeason.SeasonName}</span>
                                <span className="font-8 blue">Division</span>
                                <span className="margin-bottom">{originalDivision.DivisionName}</span>
                                <span className="font-8 blue">Fees</span>
                                <span className="margin-bottom">{this.props.balance ? formatPrice(this.props.balance * 100) : 'N/A'}</span>
                            </section>
                            <hr className="no-margin" />
                            <i className="fas fa-arrow-down bigest-font" style={{ margin: '20px auto' }} />
                            { /* To */}
                            <BlueishHeader message="To" />
                            <section className="p-2 d-flex flex-column bg-white">
                                <span className="font-8 blue">Season</span>
                                <span className="margin-bottom">{selectedSeason.SeasonName || selectedSeason.Name}</span>
                                <span className="font-8 blue">Division</span>
                                <span className="margin-bottom">{selectedDivision ? (selectedDivision.DivisionName || selectedDivision.Name) : 'N/A'}</span>
                                {this.state.estimatedFee > 0 && <span className="font-8 blue">Additional Fees</span>}
                                {this.state.estimatedFee > 0 && <span className={`margin-bottom ${this.state.estimatedFee < 0 ? 'red' : 'green'}`}>{fetchingFees ? 'Calculating...' : formatPrice(this.state.estimatedFee * 100)}</span>}
                            </section>

                            {selectedDivision && originalDivision.IdDivision !== selectedDivision.IdDivision && !fetchingFees && this.state.estimatedFee > 0 && (
                                <button onClick={this.move} className={`ml-2 mr-2 mt-2 btn btn-${(moving && this.state.movingWithCharges) ? 'warning' : 'success'}`}><i className="fas fa-exchange-alt" />
                                    {' '}
                                    {(moving && this.state.movingWithCharges) ? 'Moving, please wait...' : `Move applying ${formatPrice(this.state.estimatedFee * 100)} fee`}
                                </button>)}

                            {selectedDivision && originalDivision.IdDivision !== selectedDivision.IdDivision && !fetchingFees && this.state.estimatedFee > 0 && <span className="align-self-center">- OR -</span>}

                            {selectedDivision && originalDivision.IdDivision !== selectedDivision.IdDivision && !fetchingFees && (
                                <button onClick={this.moveFree} className={`ml-2 mr-2 mb-2 ${this.state.estimatedFee > 0 ? '' : 'mt-2'} btn btn-${(moving && this.state.movingFree) ? 'warning' : 'success'}`}><i className="fas fa-exchange-alt" />
                                    {' '}
                                    {(moving && this.state.movingFree) ? 'Moving with NO charges...' : 'Move with NO charges'}
                                </button>)}
                        </div>
                    </div>
                </ModalBody>
                {this.state.error && <ModalBody className="p-2 bg-danger white">
                    {this.state.error}
                </ModalBody>}
            </Modal>)
    }
}

export default MoveTeamModal;