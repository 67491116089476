import React from 'react';
import request from 'superagent';
import config from '../../../config';

class ShirtNumberEdit extends React.Component {

  state = {
    editing: false,
    user: this.props.user,
    season: this.props.season
  }

  // Lifecycle
  componentWillMount() {
    this.setState({
      ...this.props
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      ...nextProps
    });
  }
  //

  toggleEditing = () => {
    this.setState({
      editing: !this.state.editing
    }, () => {
      if (this.state.editing) {
        this.txt.focus();
        this.txt.select();
      }
    });
  }

  save = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { user, season } = this.state;

    season.JerseyNumber = this.txt.value;
    this.setState({
      editing: false,
      user,
      season
    });

    // Also fetch the questions at the moment of registration
    request.patch(`${config.apiEndpoint}/api/v4/registration/shirt_number`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .send({
        idTeamUserRole: season.IdTeamUserRole,
        jerseyNumber: this.txt.value
      }).then((resolve) => {
        // TODO: Some kind of feedback ?
      });

  }

  render() {
    const { editing, season } = this.state;
    if (season) {
      return (<section>
        {!editing && <button onClick={this.toggleEditing} className="m-auto btn btn-link align-self-center btn-block" style={{ fontSize: 72 }}>#
          {season.JerseyNumber}
        </button>}
        {editing && <div className="d-flex flex-column text-center">
          <form>
            <input ref={(i) => this.txt = i} defaultValue={season.JerseyNumber} type="text" className="form-control text-center" style={{ fontSize: 72 }} />
            <button type="submit" className="btn btn-success btn-sm margin-bottom" onClick={this.save}>Save Number</button>
          </form>
        </div>}
      </section>)
    }
    return <section></section>
  }

}

export default ShirtNumberEdit;