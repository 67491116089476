export function fetchCrossFlightRules(idSeason){
    return {
        type: 'CROSS_FLIGHT_RULES/FETCH',
        idSeason
    }
}

export function addCrossFlightRule(rule){
    return {
        type: 'CROSS_FLIGHT_RULES/ADD',
        rule
    }
}

export function updateCrossFlightRule(index, rule){
    return {
        type: 'CROSS_FLIGHT_RULES/UPDATE',
        index, rule
    }
}

export function deleteCrossFlightRule(index){
    return {
        type: 'CROSS_FLIGHT_RULES/DELETE',
        index
    }
}

export function clearCrossFlightRules(){
    return {
        type: 'CROSS_FLIGHT_RULES/CLEAR'
    }
}

