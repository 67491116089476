import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../../../helpers';
import fakeAuth from '../../../../../fakeAuth';
import _ from 'lodash';

class AddNote extends React.Component {
    state = {
        colors: {
            blue: '#008db5',
            red: '#dc3545',
            yellow: '#fc0',
            green: '#00871B',
        },
        match: {
            CreatorNameFirst: fakeAuth.getUser().NameFirst,
            CreatorNameLast: fakeAuth.getUser().NameLast,
            EvaluationDate: moment(),
            IdFolder: null, // TODO
            IdGender: 1,
            IdPlayerEvaluation: generateRandomId(10),
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: this.props.selected_user ? this.props.selected_user.NameFirst : '',
            NameLast: this.props.selected_user ? this.props.selected_user.NameLast : '',
            ReportType: 10,
            Color: '#008db5',
            IdUserTarget: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdUserFollow: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            Note: '',
            IdTeam: this.props.IdTeam
        }
    }

    componentWillReceiveProps = nextProps => {
        const { selected_user, user } = nextProps;
        if (selected_user || user) {
            const { match } = this.state;
            this.setState({
                match: {
                    ...match,
                    NameFirst: (selected_user || user).NameFirst,
                    NameLast: (selected_user || user).NameLast,
                    IdUserTarget: (selected_user || user).IdUserFollow || (selected_user || user).IdUser,
                    IdUserFollow: (selected_user || user).IdUserFollow || (selected_user || user).IdUser,
                }
            });
        }
    }

    onColor = (color) => {
        const { match } = this.state;
        match.Color = color;
        this.setState({ match });
    }

    onSave = () => {
        this.setState({ dirty: true }, () => {
            if (!this.txtName.value || !this.txtNote.value) {
                alert('Please complete the missing fields');
                return;
            }
            this.props.scout_actions.save_match({
                ...this.state.match,
                NameFirst: this.txtName.value.trim(),
                NameLast: '',
                Note: this.txtNote.value
            });
            this.props.toggle();
        });
    }

    render() {
        const { match, colors, dirty } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.adding_note ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                {true && <section className="bg-whiteish d-flex flex-row w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70 }}>

                    {match && <div className="align-self-start d-flex flex-column justify-content-center" style={{ width: 60, paddingLeft: 80 }}>
                        {_.values(colors).map((color, i) => <i onClick={() => this.onColor(color)} key={i} className={`align-self-center icon-bookmark1 mb-2 font-${color === match.Color ? '50' : '30'}`} style={{ color: `${color}` }} />)}
                    </div>}
                    {match && <div className="d-flex flex-column black ms-4 pl-4 align-self-start w-100">
                        <div className="w3-container bg-white p-4 ms-4">
                            <label>Athlete's Name</label>
                            <input type="text" defaultValue={match.NameFirst ? `${match.NameFirst} ${match.NameLast}` : match.NameFirst} ref={i => this.txtName = i} className={`w3-input ${(dirty && !this.txtName.value) ? 'bg-danger' : ''}`} />
                            <label>Note</label>
                            <textarea type="text" defaultValue={match.Note} ref={i => this.txtNote = i} className={`w3-input ${(dirty && !this.txtNote.value) ? 'bg-danger' : ''}`} />

                            {/* SAVE */}
                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Note saved!' : 'Save Note'}</button>
                        </div>
                    </div>}
                </section>}


                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default AddNote;