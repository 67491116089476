function title(state = [], action) {

    switch (action.type) {
        case 'TITLE/SET':
            return action.args;
        default:
            return state;
    }
}

export default title;