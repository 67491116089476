import React from 'react';
import NetworkItem from './network_item';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../config';
import fakeAuth from '../../../../fakeAuth';
import Player from './network_item';
import Role from './role';
import moment from 'moment';

class Network extends React.Component {

    state = {
        searchTerm: null,
        microTransact: false,
        rolling: false
    }

    componentWillMount() {
        const { network, division } = this.props;        
        network.forEach(u => u.selected = false);
        this.setState({ roster: [...this.props.roster], network: [...network], roles: [...this.props.roles] });
    }

    onChange = () => this.setState({ searchTerm: this.txtSearch.value });

    createPlayer = ({ idUser,
        playerRoleOverride,
        coachRoleOverride,
        idTeamDivision, }) => {
        const { rosterActions, season } = this.props;

        request.post(`${config.apiEndpoint}/api/v4/roster/simple/add`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({
                idUser,
                playerRoleOverride,
                coachRoleOverride,
                idTeamDivision,
            })
            .then(data => {
                rosterActions && rosterActions.fetchRoster(fakeAuth.getUser().IdUser, season.IdTeamDivision);
            });
    }

    onSave = () => {
        // TODO: call API
        const { season, adding, roles } = this.props, { network } = this.state;

        this.setState({ microTransact: true }, () => {
            if (adding === 3) {
                _.filter(network, u => u.selected).forEach(user => {
                    this.createPlayer({
                        idUser: user.IdUser,
                        playerRoleOverride: _.find(roles, r => r.IdRole === 3).IdRoleOverride,
                        idTeamDivision: season.IdTeamDivision
                    });
                })
            }
            this.props.onSave && this.props.onSave();
        });
    }

    onSaveCoach = () => {
        const { season } = this.props;
        this.createPlayer({
            idUser: this.state.selectedUser.IdUser,
            coachRoleOverride: this.state.selectedRole.IdRoleOverride,
            idTeamDivision: season.IdTeamDivision
        });
        this.setState({ selectedUser: null, selectedRole: null, rolling: false })
        this.props.onSave && this.props.onSave();
    }

    select = (id) => {
        const { network } = this.state, { adding, division } = this.props;
        let user = _.find(network, r => r.IdUser === id);
        if (adding === 3) {

            if (division && (division.DateBirthMax || division.DateBirthMin)) {
                let user_dob = user.DateBirth.split('T')[0],
                    mask = user_dob.indexOf('/') !== -1 ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
                if (division.DateBirthMin && moment(division.DateBirthMin.split('T')[0], 'YYYY-MM-DD')
                    .isAfter(moment(user_dob, mask))) {
                    alert(`This person is too OLD to be rostered on this division`);
                    return;
                } else if (division.DateBirthMax && moment(division.DateBirthMax.split('T')[0], 'YYYY-MM-DD')
                    .isBefore(user_dob, mask)) {
                    alert(`This person is too YOUNG to be rostered on this division`);
                    return;
                }
            }
            if (user.IsPlayerInThisSeason) {
                alert(`${user.NameFirst} already belongs to a roster on this season`);
                return;
            }
            if (user) user.selected = !user.selected;
            this.setState({ network });
        } else {
            const { roles } = this.state;
            roles.forEach(r => r.selected = false);
            this.setState({ selectedUser: user, rolling: true, roles })
        }
    }

    selectRole = (role) => {
        const { roles } = this.state;
        roles.forEach(r => r.selected = r.IdRoleOverride === role.IdRoleOverride);
        this.setState({ selectedRole: role, roles });
    }

    render() {
        const { adding, division } = this.props, { roster, network, microTransact, rolling, roles } = this.state;
        
        let filteredRoster = _.chain(network)
            .filter(u => {
                if (division.IdGender === 2 && adding === 3) return u.IdGender === 2;
                return true;
            })
            .filter(user => {
                if (this.state.searchTerm) return `${user.NameFirst.toLowerCase()} ${user.NameLast.toLowerCase()}`.indexOf(this.state.searchTerm.toLowerCase()) !== -1;
                return true;
            }).value();
            
        return <section>
            {!rolling && <section>
                <div className="px-3">
                <div className="input-group shadow-box">
                    <input type="text" onChange={this.onChange} className="form-control" placeholder="Search for players" ref={(i) => this.txtSearch = i} />
                </div>
                </div>
                <section className="d-flex flex-wrap justify-content-center mt-2">
                    {network && filteredRoster.map((user, i) => <NetworkItem index={i} onSelect={() => this.select(user.IdUser)}
                        user={user} key={i} rostered={_.find(roster, r => r.IdUser === user.IdUser && r.IdRole === adding)} />)}
                </section>

                <div style={{ height: 100 }} />

                {/* SAVE PLAYER */}
                {_.filter(network, u => u.selected).length > 0 && adding === 3 && (
                    <div style={{ position: 'fixed', bottom: 0, height: 100, width: 350 }} className="ease bg-black d-flex flex-row justify-content-center p-4">
                        <button className={`btn btn-success align-self-center w-100`} style={{ width: 100 }} onClick={this.onSave}>
                            {!microTransact && `Add (${_.filter(network, u => u.selected).length}) Players`}
                            {microTransact && <i className="fa fa-spin fa-cog" />}</button>
                    </div>)}

            </section>}
            {rolling && <section>
                <div className="pr-3 pl-3">
                    {/* TODO: CHANGE THIS */}
                    <Player rostered={false} user={this.state.selectedUser} onSelect={() => this.setState({ rolling: false, selectedUser: null })} />
                    <div style={{ height: 50 }} />
                    {_.filter(roles, r => r.IdRole === 4).map((role, i) => <Role role={role} key={i} onSelect={() => this.selectRole(role)} />)}
                </div>

                {/* SAVE COACH */}
                {this.state.selectedUser && this.state.selectedRole && (
                    <div style={{ position: 'fixed', bottom: 0, height: 100, width: 350 }} className="ease bg-black d-flex flex-row justify-content-center p-4">
                        <button className={`btn btn-success align-self-center w-100`} style={{ width: 100 }} onClick={this.onSaveCoach}>
                            {!microTransact && `Save`}
                            {microTransact && <i className="fa fa-spin fa-cog" />}</button>
                    </div>)}
            </section>}
        </section>
    }
}

export default Network;
