import React from 'react';
import { Alert } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Menu from '../leagues/menu';
import TopMenu from './top_menu';
import DOBValidations from './dob';
import CoachCertificates from './coach_certificates';
import SafeSport from './safesport';
import Concussions from './concussions';
import BackgroundCheck from './background_check';
import Fingerprints from './fingerprints';
import PhotosValidation from './photos';
import Medical from './medical';

class Validations extends React.Component {

    state = {
        bootstrapped: true,
        league: null,
        mode: null,
        all: true,        
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, league } = this.props;
        setTitle && setTitle({
            main: 'Validations',
            sub: league ? league.Name : null
        });
        this.setState({ mode: params.mode });
        this.props.fetchSeasons(params.idLeague);
        this.props.leagueActions && this.props.leagueActions.fetchValidationsSummary(params.idLeague);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.league !== this.props.league) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                main: 'Validations',
                sub: nextProps.league.Name || nextProps.league.LeagueName
            });
        }
        this.setState({ mode: nextProps.params.mode });
    }
    //

    render() {

        const { idLeague, letter } = this.props.params, { league = {} } = this.props, { mode, error } = this.state;        

        return (
            <section>

                {/* LEFT MENU */}
                <Menu idLeague={idLeague} option="validations" />

                <div className="w3-container mt-4" style={{ marginLeft: 90 }}>

                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                    </div>

                    {league && <div className="w3-content  d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                        <div className="w-100 black">
                            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{league.LeagueName || league.Name}</h3>
                        </div>
                    </div>}

                    <div className="montserrat w3-content  w-100" style={{ maxWidth: 1285 }}>
                        <TopMenu idLeague={idLeague} mode={mode} letter={letter} />
                        {mode === 'dob' && <DOBValidations {...this.props} />}
                        {mode === 'coaches' && <CoachCertificates {...this.props} />}
                        {mode === 'safe' && <SafeSport {...this.props} />}
                        {mode === 'concussion' && <Concussions {...this.props} />}
                        {mode === 'background' && <BackgroundCheck {...this.props} />}
                        {mode === 'fingerprints' && <Fingerprints {...this.props} />}
                        {mode === 'photos' && <PhotosValidation {...this.props} />}
                        {mode === 'medical' && <Medical {...this.props} />}
                    </div>
                </div>


            </section>
        )
    }
}

export default Validations;
