import React from 'react';
import _ from 'lodash';
import moment from 'moment';

class GuestNetwork extends React.Component {
    state = {}

    componentWillMount() {
        const { network } = this.props;        
        this.setState({ network: [...network] });
    }

    toggle = (user, selected) => {
        const { network } = this.state;
        _.find(network, n => n.IdUser === user.IdUser).selected = selected;
        this.setState({ network });
    }

    renderNormal = (user, i) => {
        const { roster, guests, game, division } = this.props;
        let rostered = false, color = 'green';

        if (user.IsPlayerInThisSeason) rostered = 'Unavailable';
        if (division && (division.DateBirthMax || division.DateBirthMin)) {
            if (division.DateBirthMin && moment(division.DateBirthMin.split('T')[0], 'YYYY-MM-DD')
                .isAfter(moment(user.DateBirth, 'MM/DD/YYYY'))) {
                rostered = 'Too OLD';
                color = 'red';
            } else if (division.DateBirthMax && moment(division.DateBirthMax.split('T')[0], 'YYYY-MM-DD')
                .isBefore(moment(user.DateBirth, 'MM/DD/YYYY'))) {
                rostered = 'Too YOUNG';
                color = 'red';
            }
        }
        if (_.find(roster, u => u.IdUser === user.IdUser && u.IdRole === 3)) rostered = 'Already rostered';
        if (_.find(guests, u => u.IdUser === user.IdUser && u.games.indexOf(parseInt(game.IdGame, 10)) !== -1)) rostered = 'Already rostered';

        return (<figure onClick={() => rostered ? null : this.toggle(user, !user.selected)} key={i} className={`w-50 p${i % 2 ? 'l' : 'r'}-1 mx-0 mt-2 mb-0 pointer`}>
            <div className={`w-100 card tile shadow-box d-flex flex-row ${user.selected ? 'btn-success' : ''}`}>
                <div style={{ width: 60 }} className="d-flex flex-row justify-content-center align-self-center bg-black">
                    <div className="cover" style={{ width: 60, height: 80, background: `url(${user.Image || user.UserImage || user.ImageUrl}) no-repeat center center` }} />
                </div>
                <div className="d-flex flex-column pl-2 pr-2 justify-content-center montserrat">
                    <span className="font-12 line1">{user.NameFirst} {user.NameLast}</span>
                    <span className="font-10 line1">{moment().diff(moment(user.DateBirth.split('T')[0], user.DateBirth.split('T')[0].indexOf('-') !== -1 ? 'YYYY-MM-DD' : 'MM/DD/YYYY'), 'years')} Years Old</span>
                    {rostered && <span className={`${color} toBold font-10 line1`}>{rostered}</span>}
                </div>
            </div>
        </figure>)
    }

    doSave = () => {
        const { onSave, limits, roster, guests, game } = this.props, { network } = this.state;
        // Validate if the total of users being saved would not pass the division cap
        // TODO: Does the division limits apply to this thing ? if I'm inviting somebody
        // is because perhaps somebody from my own roster is injured, and I need to complement.
        if (limits && limits.Roster_Max) {
            if (_.filter(roster, g => !g.IsInactive).length +
                _.filter(guests, g => g.games.indexOf(parseInt(game.IdGame, 10)) !== -1).length +
                _.filter(network, n => n.selected).length > limits.Roster_Max) {
                alert(`Roster can't have more than ${limits.Roster_Max} players`);                
                return;
            }
        }
        onSave(_.filter(network, n => n.selected));
        network.forEach(u => u.selected = false);
        this.setState({ network });
    }

    render() {
        const { microTransact, network = [], } = this.state;
        let filteredRoster = _.filter(network, user => {
            if (this.state.searchTerm) return `${user.NameFirst.toLowerCase()} ${user.NameLast.toLowerCase()}`.indexOf(this.state.searchTerm.toLowerCase()) !== -1;
            return true;
        });
        return <section className='h-100' style={{ position: 'relative', overflow: 'hidden' }}>
            <div className="h-100" style={{ position: 'absolute', top: 0, bottom: 100, left: 0, right: 0, overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="card tile shadow-box mx-3">
                    <input onChange={() => this.setState({ searchTerm: this.txtSearch.value })} type="text" className="form-control" placeholder={`Search through your network`} ref={(i) => this.txtSearch = i} />
                </div>
                <section className="d-flex flex-wrap mt-2 mx-3">
                    {filteredRoster.map((user, i) => this.renderNormal(user, i))}
                    <div style={{ height: 250 }} />
                </section>
            </div>
            {_.find(network, n => n.selected) && <div style={{ position: 'fixed', bottom: 0, height: 100 }} className="ease bg-black d-flex flex-row justify-content-center px-4 w-100">
                <button className={`btn btn-success align-self-center w-100`} style={{ width: 100 }} onClick={this.doSave}>
                    {!microTransact && `Save`}
                    {microTransact && <i className="fa fa-spin fa-cog" />}</button>
            </div>}
        </section>
    }
}

export default GuestNetwork;