import moment from 'moment';

function reducer(state = [], action) {
    switch (action.type) {
        case 'SLOTS/SET':
            action.slots && action.slots.forEach((g) => {
                const split = g.GameDate.split('T');
                g.GameDate = moment(`${split[0]} ${split[1].split('.')[0]}`, 'YYYY-MM-DD HH:mm:ss');
            });
            return [...action.slots];
        case 'SIZES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;