import React from 'react';
import _ from 'lodash';
import Player from './Player';
import PlayerSquare from './PlayerSquare';

const RosterSection = (props) => {
  const { team, rosterCap, community } = props;
  let players = parseInt(rosterCap, 10) > 0 ? _.take(team.Players, parseInt(rosterCap, 10)) : team.Players;
  if (community === 3 || community === 4) {
    return (
      <section>
        <table border="1" className="w-100 margin-top-half margin-bottom-half text-center">
          <thead>
            <tr>
              <th>H</th>
              <th>#</th>
              <th>Pitcher's Name</th>
              <th>IP</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>2</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>3</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>4</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>5</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </section>
    )
  } else {
    return (
      <section>
        {rosterCap > 0 && (
          <table className="w-100 margin-top-half margin-bottom-half text-center">
            <tbody>
              <tr>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 1) ? 'bg-success' : `score-${team.Score}`}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>1</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 2) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>2</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 3) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>3</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 4) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>4</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 5) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>5</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 6) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>6</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 7) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>7</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 8) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>8</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 9) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>9</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 10) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>10</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 11) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>11</div>
                </td>
                <td style={{ width: '8.33%' }}>
                  <div className={`m-auto ${(team.Score !== null && team.Score !== 0 && parseInt(team.Score, 10) >= 12) ? 'bg-success' : ''}`} style={{ width: 20, height: 20, borderRadius: 10, border: '1px solid black' }}>12</div>
                </td>
              </tr>
            </tbody>
          </table>)}
        {!rosterCap && <table border="1" className="w-100">
          <thead>
            <tr>
              <th className="text-center" style={{ width: '3.5em' }}>#</th>
              <th style={{ paddingLeft: '0.5em' }}>Player</th>
              <th className="text-center" style={{ width: '3em' }}>G</th>
              <th className="text-center" style={{ width: '3em' }}>Asst.</th>
              <th className="text-center" style={{ width: '3em' }}>C/E</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, i) => (
              <Player player={player} key={i} />))}
          </tbody>
        </table>}
        {rosterCap > 0 && <div className="container-fluid no-margin no-padding">
          <div className="row no-margin no-padding">
            {players.map((player, i) => <PlayerSquare player={player} key={i} />
            )}
          </div>
        </div>}
      </section>
    )
  }
}


export default RosterSection;
