function reducer(state = [], action) {

    switch (action.type) {
      case 'SPREAD/SET':
        return action.spread;
      case 'SPREAD/SET/CLEAR':
        return null;
      default:
        return state;
    }
  }
  
  export default reducer;