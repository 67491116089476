import React from 'react';
import _ from 'lodash';
import BracketPlacementDraggable from './BracketPlacementDraggable';

class BracketPlacements extends React.Component {

    state = {
        games: []
    }

    // Lifecycle
    componentWillMount() {
        this.fillGames(this.props.tournaments);
    }
    componentWillReceiveProps = (nextProps) => {
        this.fillGames(nextProps.tournaments);
    }
    //

    fillGames = (tournaments) => {

        var games = [];

        tournaments.forEach((tournament) => {
            tournament.Brackets.forEach((bracket) => {
                bracket.Groups.forEach((group) => {
                    games = [...games, ..._.reject(group.Games,
                        function (g) {
                            return g.phantom === true;
                        })]
                })
            })
        });

        this.setState({
            games
        })
    }

    render() {
        const { games } = this.state, { placements } = this.props;
        return (
            <ul className="block d-flex flex-column no-style-lists no-margin no-padding bg-white margin-bottom-half">
                {games.length > 0 && games.map((game, i) => (
                    <BracketPlacementDraggable key={i} game={game} placements={placements} />))}
                {!games.length && <div className="jumbotron no-margin"><i className="fa fa-exclamation"></i> You have to add at least one (1) Tournament Bracket.</div>}
            </ul>
        )
    }
}

export default BracketPlacements;