import React from 'react';
import { formatPrice } from '../../../helpers';
import moment from 'moment';
import _ from 'lodash';
import { Animated } from 'react-animated-css';

class PaymentHistory extends React.Component {   

    render() {
        const { payment, index = 0 } = this.props;

        var faIcon = 'credit-card fa';

        switch (payment.PaymentMethodName) {
            case 'Visa': faIcon = 'cc-visa fab'; break;
            case 'Master Card': faIcon = 'cc-mastercard fab'; break;
            case 'Discover': faIcon = 'cc-discover fab'; break;
            case 'Amex': faIcon = 'cc-amex fab'; break;
            case 'Cash': faIcon = 'money-bill-alt far'; break;
            case 'Cash Received': faIcon = 'money-bill-alt far'; break;
            case 'Check': faIcon = 'money-check-alt fas'; break;
            case 'Check Received': faIcon = 'money-check-alt fas'; break;
            default: break;
        }

        if (payment.IsRefund) faIcon = faIcon + ' red';

        return <Animated animationIn="fadeInUp" animationInDelay={index * 200} className={`card tile shadow-box w-100 d-flex flex-row p-3 mb-2 no-margin ${this.props.className || null}`} style={{ borderBottom: '1px solid #e5e5e5' }}>
            {(payment.Payment > 0 || payment.IsRefund) && <i className={`fa-${faIcon} align-self-center bigest-font`} />}
            {!payment.IsRefund && payment.Charge < 0 && payment.Payment === 0 && <i className={`fa-minus-square red fas align-self-center font-20`} />}
            {!payment.IsRefund && payment.Charge > 0 && payment.Payment === 0 && <i className={`fa-plus-square green fas align-self-center font-20`} />}
            {!payment.IsRefund && payment.Charge === 0 && payment.Payment === 0 && <i className={`fa-cogs yellow fas align-self-center font-20`} />}
            <div className="d-flex flex-column align-self-center idented font-12">
                <span className="line1">{payment.RecordDescription}</span>
                {payment.last4Digits && <span className="line1">**** {payment.last4Digits}</span>}
                {(payment.Payment > 0 || payment.IsRefund) && <span className="line1">{payment.Confirmation}</span>}
                {/*<span className="line1">{payment.Confirmation}</span>*/}
                <span className="line1 font-10">{moment(_.first(payment.DateDue.split('T')), 'YYYY-MM-DD').format('MMM Do YYYY')}</span>
            </div>
            <span className="align-self-center ml-auto font-16">{formatPrice((payment.Charge || payment.Payment) * 100)}</span>
        </Animated>
    }
}    

export default PaymentHistory;
