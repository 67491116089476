import React from 'react';
import { DropTarget } from 'react-dnd';
import Accepted from './accepted';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { ItemTypes } from '../../../../../helpers';

const target = {

  canDrop(props, monitor) {
    return true;
  },
  drop(props, monitor) {
    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }
    //

    const { tryoutActions } = props, { item, index } = monitor.getItem();
    tryoutActions && tryoutActions.accept(item, index);

  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

class Comp extends React.Component {
  render() {

    const { connectDropTarget, isOver, canDrop, items } = this.props;

    return connectDropTarget(
      <figure style={{ borderTop: '20px solid gray', position: 'absolute', top: 0, bottom: '50%', left: 0, right: 0, border: canDrop ? '3px solid red' : null }} className={`bg-white no-margin ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}`}>
        {items && <div className="d-flex bg-info p-2 d-flex flex-row white" style={{ position: 'absolute', top: 0, width: '100%' }}>
          <span>Accepted Players</span>
          <span className="idented-half">({items.length})</span>
        </div>}
        <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={{ position: 'absolute', top: 40, bottom: 0, overflow: 'auto' }}>
          <ReactCSSTransitionGroup component="tbody" transitionName="example" transitionAppear={true} transitionLeaveTimeout={500} transitionAppearTimeout={500}
            transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false} className="w-100" style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Status</th>
              <th>Registration Date</th>
              <th>Contact</th>
            </tr>
            {this.props.items && this.props.items.map((item, index) => (
              <Accepted {...this.props} key={index} item={item} index={index} />
            ))}
          </ReactCSSTransitionGroup>
        </table>
      </figure>

    )
  }
}

export default DropTarget([ItemTypes.NONROSTEREDPLAYER], target, collect)(Comp);
