import React from 'react';
import moment from 'moment';
import Hls from "hls.js";
import config from '../../../config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import fakeAuth from '../../../fakeAuth';
import _ from 'lodash';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon,
    FacebookIcon,
} from "react-share";

class VideoPlayerFull extends React.Component {
    state = {
        microTransac: false,
        seasons: [],
        bootstrapped: false,
        clipping: false
    }
    componentWillMount() {
        const { video, seasons, height = 240 } = this.props;
        this.setState({ video, seasons, height });

    }
    componentWillReceiveProps = nextProps => {
        const { video } = nextProps;
        this.setState({ video });
    }
    //ID_E8F513A7D0294BC6/clips/ID_53A8D794EBC621F0
    componentDidUpdate() {
        if (!this.state.bootstrapped && this.state.video) {
            const video = this.player;
            const hls = new Hls();
            const url = `${config.wowzaEndpoint}/dispatcher/${this.state.video.description}/playlist.m3u8`;
            //const url = `https://api.local.sportslogic.net:3000/sqwad/encoded/playlist.m3u8`;
            hls.loadSource(url);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, function () { });
            this.setState({ bootstrapped: true });
        }
    }    

    clip = (time) => {
        this.player.pause && this.player.pause();
        this.props.onClip && this.props.onClip({ middle: this.player.currentTime, length: parseInt(time, 10) });
    }

    onClipping = () => this.setState({ clipping: !this.state.clipping }, () => {
        this.player.pause && this.player.pause();
    });

    render() {
        const { video = {}, clipping } = this.state;

        let shareUrl = `https://matchreports.thesqwad.com/highlight2/${video.idVideo || video.IdVideo}`;

        let family_member = null;        
        if (video.playerTaggedId) {
            family_member = _.find(fakeAuth.getFamily(), f => f.IdUser === video.playerTaggedId);
        }

        return (<div className="d-flex flex-column">

            <div className="d-flex flex-row p-4">

                <div className="d-flex flex-column justify-content-center pointer hoverable mr-3">
                    <i className="fas fa-share font-20 align-self-center" onClick={this.props.onShare} />
                    <span className="black font-8 align-self-center">Share</span>
                </div>

                {(video.isClip || video.isHighlight) === 1 && <div className="d-flex flex-column justify-content-center pointer hoverable mr-3">
                    <i className="fas fa-user-tag font-20 align-self-center" onClick={this.props.onTag} />
                    <span className="black font-8 align-self-center">Assign</span>
                </div>
                }

                {video.playerTaggedId > 0 && family_member && <div onClick={this.props.onTag} className="align-self-center cover pointer bg-black mr-3" style={{ width: 50, height: 50, borderRadius: 25, border: '1px solid black', background: `url(${family_member.UserImage}?full=80) no-repeat center center` }} />}

                {!clipping && !video.isClip && <div className="d-flex flex-column ml-auto justify-content-center pointer hoverable">
                    <i onClick={this.onClipping} className="icon icon-film-reel-1 font-20  align-self-center" />
                    <span className="black font-8 align-self-center">Clip</span>
                </div>
                }

                {clipping && !video.isClip && (
                    <div className="d-flex flex-row ml-auto">

                        <span className="black font-14 align-self-center ml-auto">Select the clipping time of your video</span>

                        {video && video.durationInSeconds > 10 && <button onClick={() => this.clip(10)} type="button" className="ml-4 btn btn-rounded btn-link align-self-center" style={{ border: '1px solid green', borderRadius: 30 }}><i className="icon icon-film-reel-1 mr-2" />10 Seconds</button>}

                        {video && video.durationInSeconds > 30 && <button onClick={() => this.clip(30)} type="button" className="ml-4 btn btn-rounded btn-link align-self-center" style={{ border: '1px solid green', borderRadius: 30 }}><i className="icon icon-film-reel-1 mr-2" />30 Seconds</button>}

                        <button onClick={() => this.clip(40)} type="button" className="ml-4 mr-2 btn btn-rounded btn-link align-self-center" style={{ border: '1px solid green', borderRadius: 30 }}><i className="icon icon-film-reel-1 mr-2" />Custom Clip</button>

                    </div>
                )}
            </div>


            <div className="tile card w-100">
                <div className="w-100 h-100 p-2">

                    <video
                        poster={video.thumbnail}
                        ref={player => (this.player = player)}
                        controls={true}
                        width="100%"
                        height="600"
                        style={{ backgroundColor: 'black' }}
                    />

                    <section>
                        <div className="d-flex flex-row p-2">
                            <span className="align-self-center underlined font-20 black" onClick={this.props.onTag}>{video && video.name}</span>
                            {/*<i className="ml-auto far fa-eye font-12 align-self-center" />
                            <span className="ml-2 align-self-center">319</span>
                            <span className="ml-2 align-self-center">|</span>*/}
                            <span className="ml-auto align-self-center">{moment(video.createDate.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</span>
                            {(video.isClip || video.isHighlight) && <span className="ml-2 align-self-center">|</span>}                            
                        </div>
                    </section>

                </div>
            </div>
        </div>)
    }
}
export default VideoPlayerFull;