import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import Library from '../library';
import CreatedQuestions from '../created_questions';
import { ItemTypes, formatPrice } from '../../../../../../helpers';

class Wizzard extends React.Component {

  state = {
    lock: false,
    selectable: false,
    creating: false,
    activeTab: 1,
    required: false,
    donation: false
  }

  addToGroup = () => {
    const { questionTexts, season, selectedAnswers } = this.props;
    const selectedQuestion = _.chain(questionTexts).filter(q => {
      return q.selected === true;
    }).first().value();
    this.props.questionsActions &&
      this.props.questionsActions.addToGroup({
        question: selectedQuestion,
        answers: selectedAnswers,
        required: this.state.required,
        donation: this.state.donation
      }, season.IdLeague);

    //TODO: Clear up the selected questions and answers
    // TODO: Feedback of the created question if its down-below
    this.props.questionsActions.unselectAll();
    this.props.questionsActions.clearSelectedAnswers();
  }

  toggleLock = () => {
    this.setState({ lock: !this.state.lock });
  }

  changeTab = (tab) => {
    this.setState({
      activeTab: tab
    });
    this.props.notifyTabChange &&
      this.props.notifyTabChange(tab)
  }

  toggleRequired = () => {
    this.setState({ required: !this.state.required });
  }

  render() {

    const { isOpen, toggle, questionTexts, selectedAnswers } = this.props,
      { error, activeTab } = this.state;
    const selectedQuestion = _.chain(questionTexts).filter(q => {
      return q.selected === true;
    }).first().value();

    return (
      <Modal id="questions-wizzard" isOpen={isOpen} toggle={toggle} style={{ width: 1000 }}>
        <ModalBody className="d-flex flex-row" style={{ width: 1000 }}>
          <div className="w-100">
            <ul className="nav nav-tabs font-16" style={{ borderBottom: '1px solid gray' }}>
              <li className="nav-item" style={{ width: '50%' }}>
                <span className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                  onClick={() => this.changeTab(1)}><i className="fas fa-edit blue"></i> {true ? <span className="half-idented">Glossary</span> : ''}</span>
              </li>
              <li className="nav-item" style={{ width: '50%' }}>
                <span className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}><i className="fas fa-hammer purple"></i> {true ? <span className="half-idented">Build Questions</span> : ''}</span>
              </li>
            </ul>
            <div className="tab-content" >

              {/* Glossary */}
              {activeTab === 1 && <section className="w-100">
                <Library {...this.props} lock={false} selectable={false} />
              </section>}

              {/* Builder */}
              {activeTab === 2 && <section className="d-flex flex-row">
                <div className="w-100">
                  <Library {...this.props} lock={true} selectable={true} />
                </div>
                <div className="flex-shrink-1 p-2 bg-gray" style={{ width: 500 }}>
                  <button type="button" className="btn btn-sm btn-warning btn-block">Build your questions here</button>
                  {/* Creating a question */}
                  <figure className="card p-1">
                    <span>{selectedQuestion ? selectedQuestion.text : '(Select a question text from your library)'}</span>
                    {selectedQuestion && selectedQuestion.question_type !== ItemTypes.FREETEXT && <div>
                      <hr className="no-margin no-padding" />
                      <div>
                        <ul>
                          {(selectedAnswers || []).map((answer, i) => <li key={i} className="d-flex flex-row font-8">
                            <span>{answer.text}</span>
                            <span className="ml-auto">{formatPrice(answer.Amount * 100)}</span>
                          </li>)}
                        </ul>
                      </div>
                    </div>}
                    {/* Required */}
                    <button className="btn btn-link btn-sm no-padding " onClick={this.toggleRequired}>
                      <i className={`far fa-${this.state.required ? 'check-square green' : 'square'} margin-right-half`} />Required? <i className="fas fa-asterisk red idented-half font-8" /></button>
                    <button className="btn btn-link btn-sm no-padding " onClick={() => this.setState({ donation: !this.state.donation })}>
                      <i className={`far fa-${this.state.donation ? 'check-square green' : 'square'} margin-right-half`} />Donation? 
                      <i className="fas fa-dollar-sign blue idented-half font-8" /></button>
                    {/* Save */}
                    <button className="btn btn-success btn-sm btn-block margin-top-half" onClick={this.addToGroup}><i className="fa fa-arrow-down margin-right-half"></i>Save Question</button>
                  </figure>
                  <hr />
                  {/* Created questions */}
                  <CreatedQuestions {...this.props} />
                </div>
              </section>}

            </div>
          </div>
        </ModalBody>
        {error && <ModalBody className="bg-danger white p-2 font-10" style={{ width: 1000 }}>{error.message || 'An error has occurred'}</ModalBody>}
        <ModalFooter style={{ width: 1000 }}>
          <button className="btn btn-link btn-sm" onClick={toggle}>Close</button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default Wizzard;

