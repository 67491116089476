function depth_charts(state = [], action) {
    switch (action.type) {
        case 'DEPTH_CHARTS/SET':
            return action.depth_charts;
        case 'LINEUPS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default depth_charts;
