import React from 'react';
import { DropTarget } from 'react-dnd';
import Accepted from './accepted';
import AcceptedTeam from './accepted_team';
import { ItemTypes } from '../../../../../helpers';
import _ from 'lodash';

const target = {

  canDrop(props, monitor) {
    return true;
  },
  drop(props, monitor) {
    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }
    //
    const { waitlistsActions, season, selectedDivision } = props, { item, index } = monitor.getItem();
    [3, 5].indexOf(season.IdSeasonType) === -1 && waitlistsActions && waitlistsActions.accept(item, index, selectedDivision.IdDivision);
    [3, 5].indexOf(season.IdSeasonType) !== -1 && waitlistsActions && waitlistsActions.accept_team(item, index, selectedDivision.IdDivision);
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

class UpperList extends React.Component {
  render() {

    const { connectDropTarget, isOver, canDrop, items, season } = this.props;    

    let unique = _.chain(items || [])
      .groupBy('IdTeamUserRole')
      .values().value();      

    return connectDropTarget(
      <figure style={{ borderTop: '20px solid gray', position: 'absolute', top: 0, bottom: '50%', left: 0, right: 0, border: null }} className={`bg-white m-0 ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}`}>
        {items && <div className={`d-flex bg-${canDrop ? 'danger' : 'blue-dark'} px-3 d-flex flex-row white`} style={{ position: 'absolute', top: 0, height: 50, width: '100%', borderStartEndRadius: 10, borderStartStartRadius: 10 }}>
          <span className="align-self-center">Accepted {[3,5].indexOf(season.IdSeasonType) !== -1 ? 'Teams' : 'Players'}</span>
          <span className="ml-2 align-self-center">({items.length})</span>
        </div>}

        {[3, 5].indexOf(season.IdSeasonType) === -1 && <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={{ position: 'absolute', top: 50, bottom: 0, overflow: 'auto' }}>
          <tbody className='w-100' style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Status</th>
              <th>Registration Date</th>
              <th>Contact</th>
            </tr>
            {items && unique.map(([item], index) => (
              <Accepted {...this.props} key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>}

        {[3, 5].indexOf(season.IdSeasonType) !== -1 && <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin"
          style={{ position: 'absolute', top: 50, bottom: 0, overflow: 'auto' }}>
          <tbody className='w-100' style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Team Name</th>
              <th>Status</th>
              <th>Registration Date</th>
              <th>Contact</th>
            </tr>
            {items && unique.map(([item], index) => (
              <AcceptedTeam {...this.props} key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>}

      </figure>

    )
  }
}

export default DropTarget([ItemTypes.NONROSTEREDPLAYER], target, collect)(UpperList);
