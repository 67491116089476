import _ from 'lodash';
function exceptionRules(state = [], action) {
    switch (action.type) {
        case 'SCHEDULE_MANAGER/EXCEPTION_RULES/SET':
            return action.rules;
        case 'SCHEDULE_MANAGER/EXCEPTION_RULES/TIME/ADD':
            return [...state, action.rule];
            case 'SCHEDULE_MANAGER/EXCEPTION_RULES/MATCHUP/ADD':
            return [...state, action.rule];
        case 'SCHEDULE_MANAGER/EXCEPTION_RULES/UPDATE':
            return [
                ...state.slice(0, action.index),
                _.extend({}, state[action.index], action.rule),
                ...state.slice(action.index + 1)
            ];
        case 'SCHEDULE_MANAGER/EXCEPTION_RULES/DELETE':
            return [
                ...state.slice(0, action.index),
                ...state.slice(action.index + 1)
            ];
        default:
            return state;
    }
}

export default exceptionRules;