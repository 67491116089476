import React from 'react';
import { DropTarget } from 'react-dnd';
import GroupedTeam from './GroupedTeam';

const squareTarget = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {
        props.dropTeam && props.dropTeam(component.props.index, monitor.getItem().team);
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

class TeamDroppableArea extends React.Component {

    state = { group: null }
    componentWillMount() {
        this.setState({ group: this.props.group });
    }

    render() {
        const { connectDropTarget, isOver, canDrop, removeTeam, index } = this.props, { group } = this.state;
        return connectDropTarget(
            <div className={`d-flex flex-column h-100 ${isOver ? 'debug' : (canDrop ? 'none' : 'none')}`} style={{ borderRight: '1px solid #e5e5e5' }}>
                {(group.teams.length > 0) && <span className="watermark">{group.teams.length}</span>}
                {group.teams && group.teams.map((team, i) => <GroupedTeam team={team} index={i} key={team.IdTeam} removeTeam={removeTeam} groupIndex={index} />)}
            </div>)
    }
}

export default DropTarget(props => props.accept, squareTarget, collect)(TeamDroppableArea);
