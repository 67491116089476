import React from 'react';
import Card from './card';
import Masonry from 'react-masonry-component';
import { ItemTypes } from '../../../../../../helpers';

const masonryOptions = {
  transitionDuration: 0
};

class Adjustments extends React.Component {

  render() {
    const { adjustments, fnAddAdjustment, fnEditAdjustment } = this.props;
    return <section>

      <div id="registration-fees">

        <Masonry
          className={'registration-fees'}
          elementType={'ul'}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}>
          {adjustments && adjustments.map((program, i) => <Card program={program} key={i} type={ItemTypes.ADJUSTMENT} fnEdit={fnEditAdjustment} />)}
        </Masonry>


        {(!adjustments || !adjustments.length) && (
          <div className="jumbotron text-center margin-top">
            <h1 className="display-3">There are no Adjustments</h1>
            <p className="lead">Click the plus sign above to create your first Adjustment</p>
          </div>)}
      </div>

      <div className="d-flex flex-row" style={{ position: 'absolute', top: 10, right: 15 }}>
        <button onClick={fnAddAdjustment} type="button" style={{ border: '2px solid #2cad3c', borderRadius: 20, width: 40, height: 40 }} className="green align-self-center btn-block d-flex flex-row justify-content-center">
          <i className="fa fa-plus font-20 align-self-center" /></button>
      </div>
    </section>
  }
}

export default Adjustments;
