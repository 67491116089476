import React from 'react';
import _ from 'lodash';

class Sheet extends React.Component {

  state = { team: null, qualifies: true }
  componentWillMount() {
    const { team, season } = this.props;
    this.setState({ team, season }, () => {
      const { team } = this.state;
      if ((!team.players.length || team.players.length < 3 || _.find(team.players, p => { return !p.UserNameSignature || !p.IsValid })) && team.IdLeague === 11826) {
        this.setState({ qualifies: false });
      }
    });    
  }
  errorImage = () => {
    const { team } = this.state;
    team.TeamImage = `/images/defaults/teamLogo.png`;
    this.setState({ team });
  }
  renderPlayer = (player, index) => {    
    return (
      <div className={`col-4 no-padding p-1`} style={{ height: 60, position: 'relative' }} key={index}>
        <div className={`${player.Number === '.' ? 'hidden-content' : null}`} style={{ border: '1px solid black', borderRadius: 5, position: 'relative', overflow: 'hidden', height: 55 }}>
          <div className="p-2" style={{ position: 'absolute', right: 0, left: 0, top: 0, bottom: 0 }}>
            <div className="line1 font-10S">
              {player.NameFirst.toUpperCase()}
            </div>
            <div className="line1 font-14">
              {player.NameLast.toUpperCase()}
            </div>
          </div>
        </div>
      </div>)
  }
  render() {
    const { team } = this.state;

    return <section className={`postcard sheet ml-auto mr-auto mb-4 container-fluid black font-10 padding-bottom no-padding ${this.props.className}`} style={{ position: 'relative' }}>
      <div className="p-4">
        <table className="w-100" style={{ height: 70 }} border="1">
          <tbody>
            <tr>
              <td className="text-center cover" style={{ verticalAlign: 'top', width: 70, background: `url(${team.TeamImage}) no-repeat center center` }} />
              <td className="p-2" style={{ verticalAlign: 'top' }}>
                <div>
                  <strong className="font-20 line1">{team.Name || team.TeamName}</strong>
                </div>
                <div className="font-12">Coach:{' '}{(_.first(team.coaches) || {}).NameFirst}{' '}{(_.first(team.coaches) || {}).NameLast}
                </div>
              </td>
              <td className="text-center p-2" style={{ verticalAlign: 'center', width: 160 }}>
                <span className="font-24 toBold anton">{team.GameBracketGroupType}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex flex-row mt-2">
          <div className="w-100 font-16 d-flex flex-column">
            <span className="line1 font-20 align-self-center mt-4">{team.DivisionName}</span>
            <span className="line1 font-12 align-self-center">{team.City}, {team.StateCode}</span>
          </div>
        </div>
        <div className="d-flex flex-row mt-2">
          <div className="container-fluid no-margin no-padding w-100">
            <div className="row no-margin no-padding">
              {team.players.map((player, index) => this.renderPlayer(player, index))}
            </div>
          </div>
          <div className="text-center " style={{ width: 60 }}>
            <div className="text-center font-20 d-flex flex-row justify-content-center" style={{ width: 60, height: 60, borderRadius: 30, border: '1px solid black' }}>
              <span className="align-self-center anton">{(team.players || []).length}</span>
            </div>
            <span className="font-10">Players</span>
          </div>
        </div>


        <div className="w-100 d-flex flex-row font-20 mt-2">
          <div className="w-100">
            <div className="m-auto text-center font-20 d-flex flex-row justify-content-center" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid black' }}>
              <span className="align-self-center">1ST</span>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className="m-auto text-center font-20 d-flex flex-row justify-content-center" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid black' }}>
              <span className="align-self-center">2ND</span>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className="m-auto text-center font-20 d-flex flex-row justify-content-center" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid black' }}>
              <span className="align-self-center">3RD</span>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className="m-auto text-center font-20 d-flex flex-row justify-content-center" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid black' }}>
              <span className="align-self-center">4TH</span>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className="m-auto text-center font-20 d-flex flex-row justify-content-center" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid black' }}>
              <span className="align-self-center font-14">U08|P</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}

export default Sheet;
