import React from 'react';
import FlightTile from '../divisions/flightTile';
import COLORS from '../../../../../colors';
import _ from 'lodash';
import BuildPreview from '../../../../common/modals/BuildPreview';
import BuildSchedule from '../../../../common/modals/BuildSchedule';
import FlightedTeams from '../../../../common/modals/FlightedTeams';

class Pairings extends React.Component {
    state = {
        modal: false, buildingSchedule: false, isFlightedTeamsOpen: false, selectedFlight: false
    }
    componentWillMount() {
        const { divisions } = this.props;
        this.setState({
            divisionsWithFlights: _.filter(divisions, d => d.Flights.length > 0),
            divisionsWithoutFlights: _.filter(divisions, d => d.Flights.length === 0)
            , bootstrapped: true
        });
    }

    selectFlight = selectedFlight => this.setState({ selectedFlight, isFlightedTeamsOpen: true });
    toggleSeasonCheckedForSchedule = () => {
        const { season } = this.props;
        this.props.divisionsActions && this.props.divisionsActions.toggleCheckForSchedule(!season.checkedForSchedule);
        this.props.seasonActions && this.props.seasonActions.toggleCheckForSchedule();
    }
    toggleModal = () => this.setState({ modal: !this.state.modal });
    toggleBuildPreview = () => this.setState({ isBuildPreviewOpen: !this.state.isBuildPreviewOpen });
    toggleFlightedTeams = () => this.setState({ isFlightedTeamsOpen: !this.state.isFlightedTeamsOpen });
        
    render() {

        const { divisionsWithFlights, divisionsWithoutFlights, isBuildPreviewOpen, isFlightedTeamsOpen, selectedFlight } = this.state, { season } = this.props;
        const individualFlights = _.chain(divisionsWithFlights).map('Flights').flatten().sortBy(f => f.division ? f.division.Name : '').value();
        individualFlights.forEach(f => {
            f.division = _.pick(_.find(divisionsWithFlights, d => d.IdDivision === f.IdDivision), 'Name', 'IdDivision', 'IdGender', 'Gender');
        });
        return <section>

            {/* With */}
            <div className="d-flex flex-row">
                <h2>Divisions with Flights</h2>
                <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: COLORS.jeff_blue }} className="ml-auto py-2 px-3 align-self-center block" onClick={this.props.onSummary}><i className="fa fa-list"></i> Schedule Summary</button>
                <button style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-2 py-2 px-3 align-self-center block" onClick={this.toggleModal}><i className="fa fa-cog"></i> Build Schedule</button>
            </div>
            <section className="clearfix margin-bottom">
                {_.chain(individualFlights).sort(f => f.division.Name).value().map((flight, index) => <FlightTile flight={flight} key={index} division={flight} fnSelectFlight={this.selectFlight} />)}
            </section>

            {/* Without */}
            {divisionsWithoutFlights && divisionsWithoutFlights.length > 0 && <section>
                <div className="d-flex flex-row mt-4">
                    <h2>Divisions without Flights</h2>
                </div>
                <section className="clearfix margin-bottom">
                    {_.chain(divisionsWithoutFlights).sortBy('Name').value().map((division, i) =>
                        <div key={i} className={`d-flex flex-column margin-top margin-left float-left card shadow`}
                            style={{ width: 289, overflow: 'hidden', borderRadius: 5 }}>
                            <section className={`header white p-2 ${division.Gender.toLowerCase()}-header d-flex flex-row justify-content-center`} style={{ height: 70, position: 'relative' }}>
                                <span className="white align-self-center text-center" style={{ maxLines: 1 }}>{division.Name.toUpperCase()}</span>
                            </section>
                        </div>)}
                </section>
            </section>}

            {(this.state.modal || this.state.buildingSchedule) && (
                <BuildSchedule pairings={true} isOpen={this.state.modal || this.state.buildingSchedule} toggle={this.toggleModal} season={season}
                    onToggleSeasonCheckedForSchedule={this.toggleSeasonCheckedForSchedule}
                    {...this.props} />)}

            { /* Build Preview / Summary */}
            {isBuildPreviewOpen && <BuildPreview params={this.props.params} isOpen={isBuildPreviewOpen} toggleBuildPreview={this.toggleBuildPreview} season={season} />}
            {isFlightedTeamsOpen && <FlightedTeams flight={selectedFlight} isOpen={isFlightedTeamsOpen} toggle={this.toggleFlightedTeams} />}

        </section>
    }
}

export default Pairings;
