import React from 'react';
import { Animated } from 'react-animated-css';
//import ReactHlsPlayer from 'react-hls-player';
import { Link } from 'react-router';
import moment from 'moment';
import fakeAuth from '../../../fakeAuth';
import { parseMilliseconds } from '../../../helpers';
import _ from 'lodash';

class ClipListItem extends React.Component {
    state = {
        microTransac: false,
        seasons: []
    }

    componentWillMount() {
        const { video, seasons, height = 'auto' } = this.props;
        this.setState({ video, seasons, height });
    }
    componentWillReceiveProps = ({ video }) => this.setState({ video });

    onEdit = (e) => {
        const { video } = this.state;
        e.preventDefault();
        e.stopPropagation();
        if (fakeAuth.getUser().IdUser !== video.idUser) return;
        this.props.onEdit && this.props.onEdit(this.props.video);
    }

    render() {

        const { video = {} } = this.state, { idTeam, family = [], index } = this.props;

        let family_member = null;        
        if (video.playerTaggedId) {
            family_member = _.find(family, f => f.IdUser === video.playerTaggedId);
        }

        return <Link to={idTeam ? `/sqwad/team/${idTeam}/clip/${video.idVideo}` : `/sqwad/family/clip/${video.idVideo}`} className="mr-4 mb-4" style={{ maxWidth: 300 }}>
            <Animated animationIn="fadeIn" animationInDelay={25*index} className="tile card shadow hoverable" style={{ borderRadius: 10, overflow: 'hidden' }}>

                <div style={{ height: 150, width: 300, background: `url(${video.thumbnail}) no-repeat center center`, backgroundSize: 'cover', backgroundColor: 'black', position: 'relative' }}>
                    <span className="white shadow font-10" style={{ position: 'absolute', bottom: 5, right: 10 }}>{parseMilliseconds(video.durationInSeconds * 1000)}</span>
                    {family_member !== null && family_member !== undefined && <div className="cover" style={{ position: 'absolute', bottom: 5, left: 10, width: 30, height: 30, borderRadius: 15, border: `1px solid white`, background: `url(${family_member.UserImage}?full=80) no-repeat center center black` }}></div>}
                </div>

                <div className="d-flex flex-row pointer montserrat" onClick={this.onEdit}>
                    <div className="d-flex flex-column p-2">
                        <span className="black font-12 trimed-text line1">{video.name || 'No Name'}</span>
                        <span className="gray font-8">{moment(video.createDate).format('MMM Do YYYY')}</span>
                        <span className={`${fakeAuth.getUser().IdUser === video.idUser ? 'blue' : 'white'} font-10`}>Manage Highlight</span>
                    </div>                    
                </div>

            </Animated>
        </Link>
    }
}

export default ClipListItem;
