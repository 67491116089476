import _ from 'lodash';
import config from '../../config';
import moment from 'moment';

function divisions(state = [], action) {

    const { idRule, idFlight, idAssociation } = action;
    // call saga to fetch the leagues from API ?
    let index = 0;
    switch (action.type) {
        case 'DIVISIONS/SET':
            if (action.divisions) {
                action.divisions.forEach && action.divisions.forEach((d) => {
                    if (d.Teams) {
                        d.TotalTeams = d.Teams.length;
                    }
                    if (d.isCapped && d.capType) {
                        d.CapAction = _.find(config.capActions, (ca) => {
                            return ca.Name === d.capType;
                        }) || 0;
                    }
                    if (d.DateBirthMax) d.DateBirthMax = moment(d.DateBirthMax);
                    if (d.DateBirthMin) d.DateBirthMin = moment(d.DateBirthMin);
                });
            }
            return action.divisions;
        case 'DIVISIONS/CLEAR':
            return null;
        case 'DIVISIONS/ADD':
            return [...state, action.division];
        case 'DIVISIONS/UPDATE':
            return [...state.slice(0, action.index), action.division, ...state.slice(action.index + 1)];
        case 'DIVISIONS/REMOVE':

            return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        case 'DIVISIONS/ASSOCIATE/GAMEDAY':

            state.forEach(function (division) {
                var flight = _.find(division.Flights, function (f) {
                    return (f.IdFlight === idFlight);
                });
                if (flight) {
                    var rule = _.find(flight.GameDayRules, function (gdr) {
                        return (gdr.IdRule === idRule);
                    });
                    if (rule) {
                        rule.Id = idAssociation;
                    }
                }
            })
            return [...state];
        case 'DIVISIONS/ASSOCIATE/FIELD_AVAILABILITY':
            state.forEach(function (division) {
                var flight = _.find(division.Flights, function (f) {
                    return (f.IdFlight === idFlight);
                });
                if (flight) {
                    var rule = _.find(flight.FieldAvailabilityRules, function (gdr) {
                        return (gdr.IdRule === idRule);
                    });
                    if (rule) {
                        rule.Id = idAssociation;
                    }
                }
            })
            return [...state];
        case 'DIVISIONS/REMOVE_ASSOCIATION/GAMEDAY':
            state.forEach(function (division) {
                division.Flights.forEach(function (flight) {
                    flight.GameDayRules = _.reject(flight.GameDayRules, function (gdr) {
                        return gdr.Id === action.idAssociation
                    })
                });
            });
            return [...state];
        case 'DIVISIONS/REMOVE_ASSOCIATION/FIELD_AVAILABILITY':
            state.forEach(function (division) {
                division.Flights.forEach(function (flight) {
                    flight.FieldAvailabilityRules = _.reject(flight.FieldAvailabilityRules, function (gdr) {
                        return gdr.Id === action.idAssociation
                    })
                });
            });
            return [...state];
        case 'DIVISION/ASSOCIATE/DATE_EXCEPTION':
            state.forEach(function (division) {
                division.Flights.forEach(function (flight) {
                    flight.FlightedTeams.forEach(function (team) {
                        var targetException = _.find(team.ExceptionRules, function (er) {
                            return er.IdRule === action.idRule && er.IdScheduleFlightTeam === action.IdScheduleFlightTeam
                        });
                        if (targetException) {
                            targetException.Id = action.idAssociation;
                        }
                    });
                });
            });
            return [...state]
        case 'DIVISIONS/REMOVE_ASSOCIATION/DATE_EXCEPTION':
            state.forEach(function (division) {
                division.Flights.forEach(function (flight) {
                    flight.FlightedTeams.forEach(function (team) {
                        team.ExceptionRules = _.reject(team.ExceptionRules, function (ter) {
                            return ter.Id === action.idAssociation;
                        });
                    });
                });
            });
            return [...state]
        case 'DIVISIONS/TOGGLE_CHECK_FOR_SCHEDULE':
            state.forEach(division => {
                division.checkedForSchedule = action.flag;
                division.Flights.forEach(function (flight) {
                    flight.checkedForSchedule = action.flag;
                });
            })
            return [...state];
        case 'DIVISION/ASSIGN_QUESTION_GROUP':
            index = _.findIndex(state, division => {
                return division.IdDivision === action.idDivision;
            });
            if (index !== -1) {
                const item = state[index];
                item.QuestionGroups.push({
                    IdQuestionGroup: action.idQuestionGroup,
                    IdQuestionGroupLocation: action.idQuestionGroupLocation,
                    IdDivision: action.idDivision
                });
                return [
                    ...state.slice(0, index),
                    { ...item },
                    ...state.slice(index + 1)
                ];
            } else
                return state;
        case 'DIVISION/ASSIGN_ROSTER_LOCK':

            index = _.findIndex(state, division => {
                return division.IdDivision === action.idDivision;
            });
            if (index !== -1) {
                const item = state[index];
                item.locks.push({
                    IdTeamUserRoleLock: action.IdTeamUserRoleLock
                });
                return [
                    ...state.slice(0, index),
                    { ...item },
                    ...state.slice(index + 1)
                ];
            } else
                return state;
        case 'DIVISION/REMOVE_QUESTION_GROUP':
            index = _.findIndex(state, division => division.IdDivision === action.idDivision);
            if (index !== -1) {
                const item = state[index];
                let group = _.findIndex(item.QuestionGroups, qg => parseInt(qg.IdQuestionGroupDivision, 10) === parseInt(action.IdQuestionGroupDivision, 10));
                if (group !== -1) {
                    item.QuestionGroups = [...item.QuestionGroups.slice(0, group), ...item.QuestionGroups.slice(group + 1)];
                }
                return [
                    ...state.slice(0, index),
                    item,
                    ...state.slice(index + 1)
                ];
            } else {
                return state;
            }
        default:
            return state;
    }
}

export default divisions;
