import React from 'react';
import { formatPrice } from '../../../../helpers';
import { Popover, PopoverBody, PopoverHeader, } from 'reactstrap';
import moment from 'moment';

class Due extends React.Component {

  state = {
    deleting: false
  }

  toggleDelete = () => this.setState({ deleting: !this.state.deleting });
  doDelete = () => {
    const { payment, index } = this.props;
    this.setState({ deleting: false }, () => {
      this.props.fnDeletePayment(payment, index)
    });
  }

  render() {
    const { payment } = this.props;

    /*due.IsPaymentPastDue === 1 && <span className="align-self-center">Past Due {moment(due.DateDue).format('MMM Do YYYY')}</span>}
                    {due.IsPaymentPastDue === 0 && <span className="align-self-center">Due before {moment(due.DateDue).format('MMM Do YYYY')}</span>}*/

    return (
      <div className="d-flex flex-row w-100">
        <i className={`fas fa-stopwatch ${payment.IsPaymentPastDue ? 'red' : 'yellow'} font-16 align-self-center`} />
        <div className="d-flex flex-column align-self-center idented-half font-12">
          {payment.IsPaymentPastDue === 1 && <span className="align-self-center">Past Due {moment(payment.DateDue).format('MMMM Do YYYY')}</span>}
          {payment.IsPaymentPastDue === 0 && <span className="align-self-center">Due before {moment(payment.DateDue).format('MMMM Do YYYY')}</span>}
          {/*<span>{moment(payment.DateDue.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</span>*/}
        </div>
        <span className="align-self-center ml-auto">{formatPrice((payment.Charge) * 100)}</span>
        {/* Delete */}
        {payment.CanDelete === 1 && <button id={`btn-delete-${payment.Index}`} onClick={this.toggleDelete} className="align-self-center btn btn-link btn-sm"><i className="fa fa-times red" /></button>}
        {payment.CanDelete === 1 && <Popover placement="bottom" isOpen={this.state.deleting} target={`btn-delete-${payment.Index}`} toggle={this.toggleDelete}>
          <PopoverHeader>Are you sure?</PopoverHeader>
          <PopoverBody className="d-flex flex-row justify-content-center">
            <button className="btn btn-success btn-sm" onClick={this.doDelete}>Yes, Delete it</button>
            <button className="btn btn-danger btn-sm" onClick={this.toggleDelete}>No, Cancel</button>
          </PopoverBody>
        </Popover>}
      </div>
    );
  }
}

export default Due;