import React from 'react';
import moment from 'moment';
import fakeAuth from '../../../../fakeAuth';
import _ from 'lodash';

class PhotoItem extends React.Component {
    state = {

    }
    componentWillMount() {
        const { user, idSeason } = this.props;
        if (user.replacements && _.filter(user.replacements, r => parseInt(r.IdSeason, 10) === parseInt(idSeason, 10)).length) {
            user.InvalidPhoto = true;
        }
        this.setState({ user });
    }
    requestReplacement = () => {
        const { user } = this.state, { idLeague, idSeason } = this.props;
        user.InvalidPhoto = !user.InvalidPhoto;
        user.replacements = [{
            NameFirst: fakeAuth.getUser().NameFirst,
            NameLast: fakeAuth.getUser().NameLast,
            DateCreated: moment(),
            IdSeason: idSeason
        }];

        this.props.leagueActions[user.InvalidPhoto ? 'request_picture_replacement' : 'delete_picture_replacement'](user.IdUser, idLeague, idSeason);
        this.setState({ user });
    }
    denyReplacement = () => {
        const { user } = this.state, { idSeason } = this.props;
        user.InvalidPhoto = true;
        user.replacements = [{
            NameFirst: fakeAuth.getUser().NameFirst,
            NameLast: fakeAuth.getUser().NameLast,
            DateCreated: moment(),
            Enforce: true,
            IdSeason: idSeason
        }];
        this.setState({ user });
        this.props.leagueActions['deny_picture_replacement'](user.IdUser, idSeason);
    }
    enforceReplacement = () => {
        const { user } = this.state, { idLeague, idSeason } = this.props;
        user.InvalidPhoto = !user.InvalidPhoto;
        user.replacements = [{
            NameFirst: fakeAuth.getUser().NameFirst,
            NameLast: fakeAuth.getUser().NameLast,
            DateCreated: moment(),
            Enforce: true,
            IdSeason: idSeason
        }]

        this.props.leagueActions[user.InvalidPhoto ? 'enforce_picture_replacement' : 'delete_picture_replacement'](user.IdUser, user.InvalidPhoto ? idSeason : idLeague);
        this.setState({ user });
    }
    render_one = () => {
        const { user } = this.state;
        return <figure className="float-left card shadow d-flex flex-column h-100 mx-2 mt-4" style={{ width: 190 }}>
            <div className="cover w-100" style={{ height: 160, backgroundColor: 'black', background: `url(${user.ImageUrl}) no-repeat center center black` }} />
            <div className="p-2 d-flex flex-column justify-content-center" style={{ height: 80 }}>
                <span className="font-8 align-self-center line1 text-center">{`${user.NameFirst}`}</span>
                <span className="font-10 align-self-center line1 text-center">{user.NameLast}</span>
                {user.RoleName && <span className="font-8 mt-1 align-self-center line1 text-center">({user.RoleName})</span>}
            </div>
            {!user.InvalidPhoto && <button style={{ height: 50 }} className="btn btn-warning d-flex flex-column justify-content-center" onClick={this.requestReplacement}><span className="font-10 line1 align-self-center">Request Update</span></button>}
            {user.InvalidPhoto && <button onClick={this.requestReplacement} style={{ height: 50 }} className="btn btn-danger d-flex flex-column justify-content-center">
                <span className="font-6 line1 align-self-center">Replacement Requested </span>
                <span className="font-6 line1 align-self-center">by {`${_.first(user.replacements).NameFirst} ${_.first(user.replacements).NameLast} `}on{` ${moment(_.first(user.replacements).DateCreated).format('MMM Do YYYY')}`}</span>
                <span className="font-6 line1 align-self-end mt-2">(Click to dismiss)</span>
                </button>}
        </figure>
    }
    
    render_two = ({ Players, Coaches }) => {
        const { user } = this.state, { idSeason } = this.props;

        if (_.find(user.replacements, r => (r.Enforce === 2 && parseInt(r.IdSeason, 10) === parseInt(idSeason, 10)))) {

            return <figure className="float-left card shadow d-flex flex-column h-100 mx-2 mt-4" style={{ width: 190 }}>
                <div className="cover w-100" style={{ height: 160, backgroundColor: 'black', background: `url(${user.ImageUrl}) no-repeat center center black` }} />
                <div className="p-2 d-flex flex-column justify-content-center" style={{ height: 80 }}>
                    <span className="font-8 align-self-center line1 text-center">{`${user.NameFirst}`}</span>
                    <span className="font-10 align-self-center line1 text-center">{user.NameLast}</span>
                    {user.RoleName && <span className="font-8 mt-1 align-self-center line1 text-center">({user.RoleName})</span>}
                </div>
                <button onClick={this.requestReplacement} style={{ height: 50 }} className="btn btn-success d-flex flex-column justify-content-center font-12"><span className="line1 align-self-center"><i className="fas fa-check mr-1"></i>Accept</span></button>
                <button onClick={this.denyReplacement} style={{ height: 50 }} className="btn btn-danger d-flex flex-column justify-content-center font-12"><span className="line1 align-self-center"><i className="fas fa-times mr-1"></i>Deny</span></button>
            </figure>

        } else {

            return <figure className="float-left card shadow d-flex flex-column h-100 mx-2 mt-4" style={{ width: 190 }}>
                <div className="cover w-100" style={{ height: 160, backgroundColor: 'black', background: `url(${user.ImageUrl}) no-repeat center center black` }} />
                <div className="p-2 d-flex flex-column justify-content-center" style={{ height: 80 }}>
                    <span className="font-8 align-self-center line1 text-center">{`${user.NameFirst}`}</span>
                    <span className="font-10 align-self-center line1 text-center">{user.NameLast}</span>
                    {user.RoleName && <span className="font-8 mt-1 align-self-center line1 text-center">({user.RoleName})</span>}
                </div>
                {!user.InvalidPhoto && <button style={{ height: 50 }} className="btn btn-warning d-flex flex-column justify-content-center font-12" onClick={this.requestReplacement}><span className="line1 align-self-center"><i className="far fa-hand-pointer mr-1"></i>Request Update</span></button>}

                {((user.RoleName === 'Player' && Players) || (user.RoleName !== 'Player' && Coaches)) && <div>
                    {!user.InvalidPhoto && <button style={{ height: 50 }} className="btn btn-info w-100 d-flex flex-column justify-content-center font-12" onClick={this.enforceReplacement}><span className=" line1 align-self-center"><i className="far fa-hand-rock mr-1"></i>Require Update</span></button>}
                </div>}

                {user.InvalidPhoto && <button onClick={this.requestReplacement} style={{ height: 50 }} className="btn btn-danger d-flex flex-column justify-content-center">
                    <span className="font-8 line1 align-self-center">Replacement {_.find(user.replacements, r => r.Enforce) ? 'Required' : 'Requested'} </span>
                    <span className="font-6 line1 align-self-center">by {`${_.first(user.replacements).NameFirst} ${_.first(user.replacements).NameLast} `}on{` ${moment(_.first(user.replacements).DateCreated).format('MMM Do YYYY')}`}</span>
                    <span className="font-6 line1 align-self-end mt-2">(Click to dismiss)</span>
                </button>}

            </figure>
        }

    }

    render() {
        const { validations = [] } = this.props;
        let photo_validations = _.find(validations, v => v.ValidationProcessCode === 'PHOTOS');
        if (photo_validations) {
            return this.render_two(photo_validations);
        } else {
            return this.render_one();
        }
    }

}

export default PhotoItem;