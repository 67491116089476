import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

const TeamItem = ({ team, index, teamActions }) => {
    
    const the_team = _.find(team, t => t.IsTeamAdmin || t.IsTeamOwner) || _.first(team);        
    const style = !the_team.TeamImageUrl ? {} : {
        background: `url(${the_team.TeamImageUrl}) no-repeat center center`
    }

    return (
        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={50 * index} className="float-left">
            <div className="league-square card shadow-box hoverable">
            <Link to={`/sqwad/team/${the_team.IdTeam}/dashboard`} onClick={() => { teamActions && teamActions.selectTeam(the_team) }}>                
                { /* Logo */}
                <div className="league-logo text-center mt-4" style={style}>
                    {!the_team.TeamImageUrl && <i className="fa fa-shield-alt align-self-center" style={{ fontSize: '8em', color: '#e5e5e5' }} />}
                </div>
                { /* Name */}
                <span className="gray league-name d-flex flex-row align-items-center text-center justify-content-center toUpper" style={{ lineHeight: 1 }}>{the_team.Name || the_team.TeamName}</span>
                {( the_team.IsTeamAdmin || the_team.IsTeamOwner) && <span style={{position: 'absolute', top: -1, right: 0 }} className="icon-Diagonal-Ribbon-A_icon-1 jeff_blue font-40" />}
            </Link>            
            </div>
        </Animated>
    )
}

export default TeamItem;
