import React from 'react';
import Coach from './coach';

class Coaches extends React.Component {
    render() {
        const { coaches, onClick} = this.props;
        return <section>            
            <div style={{ minHeight: 200 }} className="d-flex flex-wrap justify-content-start px-2">
                {coaches.map((user, i) => <Coach key={i} coach={user} onClick={() => onClick(user)} />)}
            </div>
        </section>
    }
}

export default Coaches;
