import React from 'react';
import Loader from '../../../common/Loader';
import { formatPrice } from '../../../../helpers';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import _ from 'lodash';


class MasterTransactions extends React.Component {
  state = {
    bootstrapped: false, page: 1
  }

  // Lifecycle
  componentWillMount() {
    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    this.props.reportsActions && this.props.reportsActions.fetchMasterTransactions(params.idSeason);

    setTitle && setTitle({
      main: 'Report | Master Transactions'
    });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;

      setTitle && setTitle({
        sub: 'Report | Master Transactions',
        main: nextProps.season.Name || null
      });
    }
    if (nextProps.report) {
      this.setState({
        bootstrapped: true
      });
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //


  render() {
    const { bootstrapped, page } = this.state,
      { report, season } = this.props;

    return <section>
      <div className="filters-wrapper d-flex flex-row">
        <div className="d-flex flex-row py-2">
          <button onClick={() => this.setState({ page: 1 })} className={`ml-2 btn btn-${page === 1 ? 'info' : 'link'} btn-sm`}>TRANSACTIONS</button>
          <button onClick={() => this.setState({ page: 2 })} className={`ml-2 btn btn-${page === 2 ? 'info' : 'link'} btn-sm`}>REGISTRATIONS</button>
          <button onClick={() => this.setState({ page: 3 })} className={`ml-2 btn btn-${page === 3 ? 'info' : 'link'} btn-sm`}>COUPONS</button>
          <button onClick={() => this.setState({ page: 4 })} className={`ml-2 btn btn-${page === 4 ? 'info' : 'link'} btn-sm`}>FEES</button>
          <button onClick={() => this.setState({ page: 5 })} className={`ml-2 btn btn-${page === 5 ? 'info' : 'link'} btn-sm`}>REFUNDS</button>
          <button onClick={() => this.setState({ page: 6 })} className={`ml-2 btn btn-${page === 6 ? 'info' : 'link'} btn-sm`}>USERS ({report ? report.users.length : 0})</button>
        </div>
        <div className="ml-auto p-2">
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Master - ${['Transactions', 'Registrations', 'Coupons', 'Fees', 'Refunds', 'Users'][page - 1]} - ${season.Name}`}
            sheet="AutoPay" buttonText="Export as XLS" />
        </div>
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            {/* TRANSACTIONS */}
            {page === 1 && <table id="table-to-xls" className="table table-sm font-10" style={{ minWidth: 800 }}>
              <thead>
                <tr className="toBold">
                  <td>User</td>
                  <td align="center">Date</td>
                  <td>Transaction ID</td>
                  <td align="right">Charge</td>
                </tr>
              </thead>
              <tbody>
                {report && report.transactions && _.filter(report.transactions, s => s.Amount).map((record, i) => (
                  <tr key={i}>
                    <td>
                      {record.NamePayer}
                    </td>
                    <td align="center">
                      {moment(record.DateModified).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      {record.IdExternal}
                    </td>
                    <td align="right">
                      {formatPrice(record.Amount * 100)}
                    </td>
                  </tr>)
                )}
              </tbody>
            </table>}
            {/* REGISTRATIONS */}
            {page === 2 && <table id="table-to-xls" className="table table-sm font-10" style={{ minWidth: 800 }}>
              <thead>
                <tr className="toBold">
                  <td>User ID</td>
                  <td>Name</td>
                  <td>Role</td>
                  <td>Division</td>
                  <td>Status</td>
                  <td>Team</td>
                  <td align="center">Date</td>
                </tr>
              </thead>
              <tbody>
                {report && report.registrations && report.registrations.map((record, i) => (
                  <tr key={i}>
                    <td>{record.IdUser}</td>
                    <td>
                      {`${record.NameFirst} ${record.NameLast}`}
                    </td>
                    <td>{record.RoleOverrideName}</td>
                    <td>{record.DivisionName}</td>
                    <td>{record.RoleStatusName}</td>
                    <td>{record.TeamName}</td>
                    <td align="center">
                      {moment(record.DateCreated).format('MM/DD/YYYY')}
                    </td>
                  </tr>)
                )}
              </tbody>
            </table>}
            {/* COUPONS */}
            {page === 3 && <table id="table-to-xls" className="table table-sm font-10" style={{ minWidth: 800 }}>
              <thead>
                <tr className="toBold">
                  <td>Coupon</td>
                  <td>User</td>
                  <td align="right">Charge</td>
                </tr>
              </thead>
              <tbody>
                {report && report.coupons && report.coupons.map((record, i) => (
                  <tr key={i}>
                    <td>{record.CouponName}</td>
                    <td>{`${record.NameFirst} ${record.NameLast}`}</td>
                    <td align="right">
                      {formatPrice(record.AmountPerCoupon * 100)}
                    </td>
                  </tr>)
                )}
              </tbody>
            </table>}
            {/* TRANSACTIONS */}
            {page === 4 && <table id="table-to-xls" className="table table-sm font-10" style={{ minWidth: 800 }}>
              <thead>
                <tr className="toBold">
                  <td>User</td>
                  <td align="center">Date</td>
                  <td>Transaction ID</td>
                  <td align="right">Charge</td>
                </tr>
              </thead>
              <tbody>
                {report && report.fees && _.filter(report.fees, s => s.Amount).map((record, i) => (
                  <tr key={i}>
                    <td>
                      {record.NamePayer}
                    </td>
                    <td align="center">
                      {moment(record.DateModified).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      {record.IdExternal}
                    </td>
                    <td align="right">
                      {formatPrice(record.Amount * 100)}
                    </td>
                  </tr>)
                )}
              </tbody>
            </table>}
            {/* REFUNDS */}
            {page === 5 && <table id="table-to-xls" className="table table-sm font-10" style={{ minWidth: 800 }}>
              <thead>
                <tr className="toBold">
                  <td>Date</td>
                  <td>Transaction ID</td>
                  <td align="right">Charge</td>
                </tr>
              </thead>
              <tbody>
                {report && report.refunds && report.refunds.map((record, i) => (
                  <tr key={i}>
                    <td>
                      {moment(record.DateCreated).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      {record.DocumentNumber}
                    </td>
                    <td align="right">
                      {formatPrice(record.Amount * 100)}
                    </td>
                  </tr>)
                )}
              </tbody>
            </table>}
            {/* USERS */}
            {page === 6 && <table id="table-to-xls" className="table table-sm font-10" style={{ minWidth: 800 }}>
              <thead>
                <tr className="toBold">
                  <td>Id</td>
                  <td>Name</td>
                  <td>Last</td>
                  <td>DOB</td>
                </tr>
              </thead>
              <tbody>
                {report && report.users && _.chain(report.users)
                  .uniqBy(u => u.IdUser)                  
                  .sortBy('NameFirst')
                  .value()
                  .map((record, i) => (
                    <tr key={i}>
                      <td>{record.IdUser}</td>
                      <td>{record.NameFirst}</td>
                      <td>{record.NameLast}</td>
                      <td>{moment(record.DateBirth).utc().format('MM/DD/YYYY')}</td>
                    </tr>)
                  )}
              </tbody>
            </table>}
          </section>)}
      </div>
    </section>
  }
}

export default MasterTransactions;
