import React from 'react';
import { DropTarget } from 'react-dnd';
import Tryout from './tryout';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import _ from 'lodash';
import { ItemTypes } from '../../../../../helpers';

const target = {
  canDrop(props, monitor) {
    return true
  },
  drop(props, monitor, component) {

    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }

    const { tryoutActions, selectedDivision } = props, { item, index } = monitor.getItem();
    tryoutActions && tryoutActions.returnToTryout(item, index, selectedDivision.IdDivision);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class Comp extends React.Component {


  state = {
    filters: localStorage.getItem("COACHES.FILTERBY") ? JSON.parse(localStorage.getItem("COACHES.FILTERBY")) : {
      rostered: 1,
      nonRostered: 1
    }
  }

  toggleFilter = (args) => {
    const { filters } = this.state;
    filters[args.name] = !filters[args.name];
    localStorage.setItem("COACHES.FILTERBY", JSON.stringify(filters));
    this.setState({
      filters
    });
  }

  render() {

    const { connectDropTarget, isOver, canDrop, items, selectedDivision } = this.props,
      { filters } = this.state;

    var filteredItems = _.filter(items, (item) => {
      if (!filters.rostered && item.IdTeamUserRole !== null) return false;
      if (!filters.nonRostered && item.IdTeamUserRole === null) return false;
      return true;
    });

    return connectDropTarget(
      <figure style={{ borderTop: '20px solid gray', position: 'absolute', bottom: 0, top: '50%', left: 0, right: 0, border: canDrop ? '3px solid red' : null }} className={`bg-white no-margin ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}`}>
        {items && <div className="d-flex bg-info p-2 d-flex flex-row white" style={{ position: 'absolute', top: 0, width: '100%' }}>
          <span>Tryouts</span>
          <span className="idented-half">({items.length} available)</span>
          <span className="ml-auto"></span>
        </div>}
        <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={{ position: 'absolute', top: 40, bottom: 0, overflow: 'auto' }}>
          <ReactCSSTransitionGroup component="tbody" transitionName="example" transitionAppear={true} transitionLeaveTimeout={500} transitionAppearTimeout={500}
            transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false} className="w-100" style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Status</th>
              <th>Registration Date</th>
              <th>Contact</th>
            </tr>
            {this.props.items && selectedDivision && _.filter(filteredItems, (t) => {
              return t.IdDivision !== selectedDivision.IdDivision;
            }).map((item, index) => (
              <Tryout key={index} tryoutActions={this.props.tryoutActions} season={this.props.season} item={item} index={index} />
            ))}
          </ReactCSSTransitionGroup>
        </table>
      </figure>

    )
  }
}

export default DropTarget([ItemTypes.ROSTEREDPLAYER], target, collect)(Comp);
