import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../helpers';

const teamSource = {
    beginDrag(props) { return props; }
}

class TeamDraggable extends React.Component {
    render() {
        const { connectDragSource, connectDragPreview, team, selected, hideDivision } = this.props;

        return connectDragPreview(connectDragSource(
            <li className="placement-item d-flex flex-row">
                {selected && <i className="fas fa-check green margin-right-half" />}
                <span className="align-self-center font-10">{team.TeamName || team.Name} {!hideDivision && <span className="font-6">({team.RegistrationDivision.Name})</span>}</span>
                <i className="align-self-center ml-auto fas fa-arrows-alt font-8 blue"></i>
            </li>)
        )
    }
}

export default flow(
    DragSource(ItemTypes.TEAM, teamSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }))
)(TeamDraggable)