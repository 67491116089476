function pouch(state = [], action) {

    switch (action.type) {
        case 'POUCH/SET':
            localStorage.setItem('sportslogic.pouch', JSON.stringify(action.pouch));
            return action.pouch;
        default:
            return state;
    }
}

export default pouch;