import React from 'react';
//import { DragDropContext } from 'react-beautiful-dnd';
import UpperList from './upper';
import LowerList from './lower';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ItemTypes } from '../../../../../helpers';
import LineLoader from '../../../../common/LineLoader';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../../config';

class Comp extends React.Component {

  state = {
    players: null
  }

  rosterUp = (item) => {
    item.IdRoleStatus = 2;
    item.RoleStatus = 'Active';
    item.New = true;
    this.props.divisionsActions &&
      this.props.divisionsActions.updateCoach(item, _.findIndex(this.props.coaches, (p) => {
        return p.IdUser === item.IdUser;
      }));

    // Call API
    const { selectedDivision } = this.props;
    request.patch([config.apiEndpoint, 'api/v4/registration/status',
    item.IdUser, selectedDivision.IdDivision, 2].join('/'))
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then(data => {
        // Nothing to do here ?
      });
  }

  rosterOut = (item) => {
    item.IdRoleStatus = 1;
    item.RoleStatus = 'Pending';
    item.New = false;
    this.props.divisionsActions &&
      this.props.divisionsActions.updateCoach(item, _.findIndex(this.props.coaches, (p) => {
        return p.IdUser === item.IdUser;
      }));

    // Call API
    const { selectedDivision } = this.props;
    request.patch([config.apiEndpoint, 'api/v4/registration/status',
    item.IdUser, selectedDivision.IdDivision, 1].join('/'))
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then(data => {
        // Nothing to do here ?
      });
  }

  render() {

    const accept = [ItemTypes.NONROSTEREDPLAYER, ItemTypes.ROSTEREDPLAYER],
      { tryouts, accepted } = this.props;

    /*let actives = _.filter((coaches || []), p => p.IdRoleStatus === 2),
      pendings = _.reject((coaches || []), p => p.IdRoleStatus === 2);*/

    return (
      <section style={{ position: 'absolute', top: '4em', bottom: 0, left: '1em', right: '1em' }}>
        {this.props.fetchingTryouts && <LineLoader />}
        { /* Top List */}
        <UpperList {...this.props} accept={accept} items={accepted} fnDrop={this.rosterUp} />
        { /* Bottom List */}
        <LowerList {...this.props} accept={accept} items={tryouts} total={0} fnDrop={this.rosterOut} />
      </section>
    )
  }
}

export default DragDropContext(HTML5Backend)(Comp);