import React from 'react';
import { calculateBWContrast, ItemTypes } from '../../../../helpers';
import TeamDroppableArea from './GroupTeamDroppableArea';
import request from 'superagent';
import config from '../../../../config';

class CustomGroup extends React.Component {

    state = { group: null, index: null }
    componentWillMount() {
        this.setState({ group: this.props.group });
    }

    toggleEditGroup = () => {
        const { group } = this.state;
        group.editing = !group.editing;
        this.setState({ group });
    }

    save = e => {
        e.preventDefault && e.preventDefault();
        const { group } = this.state;
        group.Name = this.txt.value;
        // CALL API 
        request.patch(`${config.apiEndpoint}/api/v4/brackets/custom_group/${group.Id || group.IdCustomGroup}`)
            .send({ Name: group.Name, IdDivision: group.IdDivision })
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then(data => {
                this.props.fnNotify && this.props.fnNotify();
            });
        this.toggleEditGroup();
    }

    render() {
        const { group, } = this.state, { division, dropTeam, removeTeam, removeGroup, left, index } = this.props;

        const HEADER_STYLE = {
            backgroundColor: division ? division.Color : null,
            color: division ? calculateBWContrast(division.Color, true) : null,
            border: '1px solid gray',
        }
        const accept = [ItemTypes.FLIGHTEDTEAMS, ItemTypes.TEAM];

        return <div style={{ width: 200, position: 'absolute', top: 0, left, }} className="h-100">
            <form>
                {/* HEADER */}
                <div className={`d-flex flex-row`} style={HEADER_STYLE}>
                    <div className="d-flex flex-column hundred-percent">
                        {!group.editing && (
                            <section className="d-flex flex-row">
                                <button type="button" className="btn btn-link text-left d-flex flex-row w-100" onClick={this.toggleEditGroup}>
                                    <span>{group.Name || group.CustomGroupName}</span>
                                    <i className={`fas fa-pencil-alt align-self-center ml-auto`} />
                                </button>
                                <button onClick={removeGroup} type="button" className="btn btn-link ml-auto">
                                    <i className={`fas fa-times red align-self-center`} />
                                </button>
                            </section>
                        )}
                        {group.editing &&
                            <div className="d-flex flex-row">
                                <input type="text" ref={i => this.txt = i} defaultValue={group.Name || group.CustomGroupName}
                                    className="form-control align-self-center control-sm w-100" placeholder="Name of the Group" />
                                <button type="submit" className="btn btn-success ml-auto" onClick={this.save}><i className="fa fa-check" /></button>
                            </div>}
                    </div>
                </div>
            </form>
            <TeamDroppableArea className="d-flex flex-column h-100 bg-success" style={{ borderRight: '1px solid gray' }} accept={accept} group={this.props.group} dropTeam={dropTeam} removeTeam={removeTeam} index={index} />
        </div>
    }
}

export default CustomGroup;
