import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Round from './Round';
import _ from 'lodash';
import config from '../../../../config';
import request from 'superagent';
import { Animated } from 'react-animated-css';

class Pairings extends React.Component {

    state = {
        isDivisionFilterOpen: false,
        isFlightFilterOpen: false,
        selectedDivision: null,
        selectedFlight: null,
        rounds: [],
        colorCode: null
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.games && nextProps.games.length !== this.props.games.length && this.state.selectedFlight) {
            this.fetchPairings(nextProps.games);
        }
    }

    fetchPairings = (games) => {
        const { selectedFlight, selectedDivision } = this.state;

        // Look for the pairings setup for this flight
        if (selectedFlight) {
            request.get(`${config.apiEndpoint}/api/v4/pairings/${selectedFlight.IdFlight}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(({ body }) => {

                    body.pairings.forEach(pairing => {
                        pairing.flight = selectedFlight;
                        // Given the async nature of this calendar thing, the game might be deleted before the refresh of the pairings, 
                        // so the model from the server might be outdated.
                        if (pairing.IdGame && !_.find(games, g => parseInt(g.IdGame, 10) === parseInt(pairing.IdGame, 10))) {
                            pairing.IdGame = null;
                        }
                    });

                    const group = _.chain(body.pairings).groupBy('Round').values().value();
                    const colorCode = selectedDivision.Color;
                    const rounds = [];
                    group.forEach((group, i) => { rounds.push({ games: group, byes: _.filter(body.byes, b => b.Round === i + 1) }); });
                    this.setState({ rounds, colorCode });

                    // This is for the parent to know the pairings, in order to handle the Drag and Drop feature
                    // I know is not the 'react' way, but I'm too deep in this scheme to change it to use Redux.
                    this.props.update && this.props.update(body.pairings);
                });
        }
    }


    toggleDivisionPicker = () => this.setState({ isDivisionFilterOpen: !this.state.isDivisionFilterOpen });
    toggleFlightPicker = () => this.setState({ isFlightFilterOpen: !this.state.isFlightFilterOpen });
    selectDivision = selectedDivision => this.setState({ selectedDivision }, () => {
        const { flights } = this.props, { selectedDivision } = this.state;
        const filteredFlights = selectedDivision ? _.filter(flights, d => d.IdDivision === selectedDivision.IdDivision) : [];
        if (filteredFlights.length) {
            this.selectFlight(filteredFlights[0]);
        } else {
            this.selectFlight(null);
        }
    });

    selectFlight = selectedFlight => this.setState({ selectedFlight }, () => {
        this.fetchPairings(this.props.games);
    });

    deletePairings = () => {
        const { selectedFlight } = this.state;
        this.setState({ rounds: [], delete: false });

        // This is for the parent to know the pairings, in order to handle the Drag and Drop feature
        // I know is not the 'react' way, but I'm too deep in this scheme to change it to use Redux.
        this.props.update && this.props.update([]);

        // TODO: call the API to delete pairings ?
        selectedFlight && request.delete(`${config.apiEndpoint}/api/v4/pairings/${selectedFlight.IdFlight}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then(({ body }) => { });
    }

    changeGameDuration = (add) => {
        let { defaultGameDuration } = this.props;
        if (add) {
            defaultGameDuration += 5;
        } else {
            if (defaultGameDuration > 5) {
                defaultGameDuration -= 5;
            }
        }
        this.props.setDefaultGameDuration &&
            this.props.setDefaultGameDuration(defaultGameDuration);
    }

    render() {
        const { selectedDivision, selectedFlight, rounds, colorCode } = this.state,
            { divisions, flights = [], defaultGameDuration } = this.props;

        let filteredDivisions = _.reject(divisions, d => d.IsTryout),
            filteredFlights = selectedDivision ? _.filter(flights, d => d.IdDivision === selectedDivision.IdDivision) : [];

        return (<section className="h-100 w-100 bg-info" style={{ paddingLeft: '4em' }}>
            <div className="d-flex flex-row margin-top-half" >
                <section className="margin-right-half">
                    <Dropdown className="align-self-top trimmed-dd" size="sm" isOpen={this.state.isDivisionFilterOpen} toggle={this.toggleDivisionPicker}>
                        <DropdownToggle color="info" caret>
                            {selectedDivision ? `${selectedDivision.Name} - (${selectedDivision.Teams.length} team${selectedDivision.Teams.length === 1 ? '' : 's'})` : 'Select a division'}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>------- With Flight</DropdownItem>
                            {_.filter(filteredDivisions, d => d.HasFlights).map((division, i) =>
                                <DropdownItem active={(selectedDivision && selectedDivision.IdDivision === division.IdDivision) ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                    <i className={`fas fa-plane margin-right-half`} />{division.Name}
                                </DropdownItem>)}
                            <DropdownItem header>------- Without Flight</DropdownItem>
                            {_.filter(filteredDivisions, d => !d.HasFlights).map((division, i) =>
                                <DropdownItem active={(selectedDivision && selectedDivision.IdDivision === division.IdDivision) ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                    {division.Name}
                                </DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                    {selectedDivision && <Dropdown className="align-self-top" size="sm" isOpen={this.state.isFlightFilterOpen} toggle={this.toggleFlightPicker}>
                        <DropdownToggle color="info" caret>
                            {selectedFlight ? `${selectedFlight.FlightName}` : 'Select a flight'}
                        </DropdownToggle>
                        <DropdownMenu>
                            {filteredFlights && filteredFlights.map((flight, i) =>
                                <DropdownItem active={(selectedFlight && selectedFlight.IdFlight === flight.IdFlight) ? true : false} key={i}
                                    onClick={() => this.selectFlight(flight)}>
                                    {flight.FlightName}
                                </DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>}
                    {selectedDivision && selectedFlight && <div className="d-flex flex-row bg-success">
                        <span className="black font-10 idented-half align-self-center">Duration (mins.)</span>
                        <button className="btn btn-link btn-sm black font-10 ml-auto" onClick={() => this.changeGameDuration(false)}><i className="fa fa-caret-left" /></button>
                        <button className="btn btn-link btn-sm black font-10">{defaultGameDuration}</button>
                        <button className="btn btn-link btn-sm black font-10" onClick={() => this.changeGameDuration(true)}><i className="fa fa-caret-right" /></button>
                    </div>}
                    <Animated isVisible={selectedFlight ? true : false} animationIn="fadeInLeft" animationOut="fadeOutLeft">
                        {selectedFlight && <section className="d-flex flex-column">
                            <div className="btn-white black btn-sm font-8 text-center">{selectedFlight.teams.length} Teams</div>
                            <div className="btn-white black btn-sm font-8 text-center">{selectedFlight.MinimumGamesPerTeam} Games (min.)</div>
                            <div className="btn-white black btn-sm font-8 text-center">{selectedFlight.teams.length * selectedFlight.MinimumGamesPerTeam / 2} Total Games</div>
                            {rounds && rounds.length > 0 && !this.state.delete && <button className="btn btn-danger btn-sm font-8 mt-2" onClick={() => this.setState({ delete: true })}>Delete Pairings</button>}
                            {rounds && rounds.length > 0 && this.state.delete && <div className="d-flex flex-column mt-2">
                                <button className="btn btn-danger btn-sm font-12" onClick={this.deletePairings}>Confirm Delete</button>
                                <button className="btn btn-link btn-sm font-12 white" onClick={() => this.setState({ delete: false })}>No, cancel</button>
                            </div>}
                        </section>}
                    </Animated>
                </section>

                {selectedFlight && rounds.length > 0 && rounds.map((round, index) => <Round
                    flight={selectedFlight} division={selectedDivision} round={round} key={index} index={index} colorCode={colorCode} />)}
            </div>
        </section>)
    }
}

export default Pairings;
