import React from 'react';
import Header from './common/headers/Header.SQWAD';
import { Animated } from 'react-animated-css';
import fakeAuth from '../fakeAuth';
import RegistrationMenu from './menu/RegistrationMenu';
import NewRosterMenu from './menu/NewRosterMenu';
import UpgradesMenu from './menu/UpgradesMenu';
import ProfileMenu from './menu/ProfileMenu';
import FamilyPicker from './FamilyPicker';
import MobileMenu from './MobileMenu';

class Main extends React.Component {

  state = {
    menuHidden: false,
    section: null,
    picking_family: false
  }

  componentWillMount() {

    this.setState({
      section: this.props.route.path
    });

    if (fakeAuth.isAuthenticated()) {
      this.props.login &&
        this.props.login(fakeAuth.getCredentials());

      this.props.familyActions &&
        this.props.familyActions.fetchTeams();

      this.props.familyActions &&
        this.props.familyActions.fetchArchivedTeams();

      this.props.familyActions &&
        this.props.familyActions.fetchMembers();

    } else {
      fakeAuth.signout(() => {
        this.props.router.push('/login'); //from.pathname);
      });
    }

  }

  componentWillUnmount() {
    this.props.familyActions &&
      this.props.familyActions.clearTeams &&
      this.props.familyActions.clearTeams();
    this.props.teamActions && this.props.teamActions.clearSelectedTeam();
  }

  hideMenu = () => {
    const { menuHidden } = this.state;
    this.setState({
      menuHidden: !menuHidden
    });
  }

  render() {

    const { menuHidden, section } = this.state;

    return (

      <section>

        <Header {...this.props} fnMenu={() => this.setState({ mobile_menu: true, menu_bootstrapped: true })} onFamily={() => this.setState({ picking_family: !this.state.picking_family })} />

        <section id="main-content" style={{ top: '6em' }}>
          {section === '/registration' && <RegistrationMenu {...this.props} />}
          {section === '/payments' && <RegistrationMenu {...this.props} />}
          {section === '/roster' && <NewRosterMenu {...this.props} />}
          {section === '/plans' && <UpgradesMenu {...this.props} />}
          {section === '/profile' && <ProfileMenu {...this.props} />}
          <section id="content-wrapper" className={`${menuHidden ? 'hidden' : 'with-menu'}`}>
            {React.cloneElement(this.props.children, this.props)}
          </section>
        </section>

        <FamilyPicker {...this.props} open={this.state.picking_family} toggle={() => this.setState({ picking_family: false })} />
        {this.state.mobile_menu && <MobileMenu {...this.props} {...this.state} toggle={() => this.setState({ menu_bootstrapped: false }, () => {
          setTimeout(() => this.setState({ mobile_menu: false }), 350);
        })} />}

      </section>

    );
  }
}

export default Main;