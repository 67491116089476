import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { Popover, PopoverBody } from 'reactstrap';
import flow from 'lodash/flow';
import { ItemTypes, toTitleCase } from '../../../../../helpers';
import moment from 'moment';
import { Link } from 'react-router';
import config from '../../../../../config';
import _ from 'lodash';
import request from 'superagent';

const source = {
  beginDrag(props) {
    return props;
  }
};

const target = {
  canDrop(props) {
    return false;
  },
  drop(props, monitor) { }
}
class Comp extends React.Component {

  state = {
    popoverTeamsOpen: false,
    popoverRoleOpen: false
  }


  togglePopoverTeams = () => {
    this.setState({
      popoverTeamsOpen: !this.state.popoverTeamsOpen
    })
  }

  togglePopoverRole = () => {
    this.setState({
      popoverRoleOpen: !this.state.popoverRoleOpen
    })
  }

  assignTeam = (team) => {
    const { item } = this.props;

    item.Team = team.Name;
    item.IdTeamDivision = team.IdTeamDivision;
    item.IdTeam = team.IdTeam;

    // Update the store right away
    this.props.divisionsActions &&
      this.props.divisionsActions.updateCoachRole(item);
    this.setState({
      popoverTeamsOpen: false
    });

    // Call API with new IdDivision (and IdTeamDivision if team was selected), IdUser and the Calculated Fee Difference
    const url = `${config.apiEndpoint}/api/v4/coaches/assign/${team.IdTeamDivision}/${item.IdTeamUserRole}`;
    request.patch(url)
      .send({})
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then((data) => {
        // TODO: update the store
      });
  }

  assignRole = (role) => {
    const { item } = this.props;
    item.IdRoleOverride = role.IdRoleOverride;
    item.RoleOverride = role.RoleName;

    // Update the store right away
    this.props.divisionsActions &&
      this.props.divisionsActions.updateCoachRole(item);
    this.setState({
      popoverRoleOpen: false
    });

    // Call API 
    const url = `${config.apiEndpoint}/api/v4/roles/coach/${item.IdTeamUserRole}/${item.IdRoleOverride}`;
    request.patch(url)
      .send({})
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then((data) => {
        // TODO: update the store 
      });
  }

  render() {
    const { popoverRoleOpen, popoverTeamsOpen } = this.state,
      { connectDragSource, item, connectDropTarget, season, roles } = this.props; // isDragging

    let filteredRoles = _.filter(roles, r => r.IdRole === 4);

    return connectDragSource(connectDropTarget(
      <tr className={`${item.New ? 'bg-success white' : ''}`}>
        <td>
          {item.IdUser}
        </td>
        <td className="font-14">
          <Link className={`btn btn-link ${item.New ? 'white' : 'blue'} underlined`} to={`/season/${season.IdSeason}/player/${item.IdUser}`}>
            {toTitleCase(item.UserName)}
          </Link>
        </td>
        <td>
          <i className={`${item.New ? 'white' : item.RoleStatus} fas fa-circle`}></i>
          {' '}
          <span>{item.RoleStatus}</span>
        </td>
        <td>
          {moment(item.DateBirth).format('MMM Do YYYY')}
        </td>
        <td>
          { /* Team */}
          <button onClick={this.togglePopoverTeams} id={`btn-team-${item.IdUser}`} className="btn btn-sm btn-link">
            {item.Team || 'Select a team'}
            {' '}<i className="fa fa-caret-down" /></button>
          <Popover placement="bottom" isOpen={popoverTeamsOpen} target={`btn-team-${item.IdUser}`} toggle={this.togglePopoverTeams}>
            <PopoverBody className="d-flex flex-column">
              {this.props.selectedDivision && _.chain(this.props.selectedDivision.Teams)
                .sortBy('Name')
                .value().map((team, i) => (
                  <button key={i} className={`font-10 btn btn-${parseInt(item.IdTeamDivision, 10) === parseInt(team.IdTeamDivision, 10) ? 'warning' : 'link'} btn-sm btn-block d-flex flex-row`} onClick={() => this.assignTeam(team)}>
                    {team.Name}
                  </button>))}
            </PopoverBody>
          </Popover>
        </td>
        <td>
          { /* Role */}
          <button onClick={this.togglePopoverRole} id={`btn-role-${item.IdUser}`} className="btn btn-sm btn-link">
            {item.RoleOverride || 'Select a role'}
            {' '}<i className="fa fa-caret-down" /></button>
          <Popover placement="bottom" isOpen={popoverRoleOpen} target={`btn-role-${item.IdUser}`} toggle={this.togglePopoverRole}>
            <PopoverBody className="d-flex flex-column">
              {roles && filteredRoles.map((role, i) => (
                <button key={i} className={`btn btn-${parseInt(item.IdRoleOverride, 10) === parseInt(role.IdRoleOverride, 10) ? 'warning' : 'link'} btn-sm btn-block d-flex flex-row`} onClick={() => this.assignRole(role)}>
                  {role.RoleName}
                </button>))}
            </PopoverBody>
          </Popover>
        </td>
        <td>
          {item.Gender.substring(0, 1)}
        </td>
        <td>
          <span className="block">{item.PhoneNumber}</span>
          <span className="block">{item.EmailAddress}</span>
        </td>
        <td>
          {item.City}
        </td>
        <td>
          {item.State}
        </td>
        {this.props.questions && this.props.questions.map((q, i) => (
          <td key={i}>
            {item[`question-${q.IdQuestion}`]}
          </td>))}
        <td><i className="fas fa-arrows-alt gray"></i></td>
      </tr>
    ));
  }
}

export default flow(
  DropTarget(ItemTypes.NONROSTEREDPLAYER, target, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver()
  })),
  DragSource(ItemTypes.ROSTEREDPLAYER, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(Comp);