import React from 'react';
import './brackets.css';
import _ from 'lodash';
import moment from 'moment';
import { Animated } from 'react-animated-css';
import TournamentBracket from './TournamentBracket';
import Loader from '../../../common/Loader';
import Slider from 'react-rangeslider'
import BracketPlacements from './BracketPlacements';
import DivisionPlacements from './DivisionPlacements';
import CustomGroupsPlacements from './RegistrationPlacements';
import FlightPlacements from './FlightPlacements';
import Teams from './Teams';
import { DragDropContext } from 'react-dnd';
import request from 'superagent';
import config from '../../../../config';
import HTML5Backend from 'react-dnd-html5-backend';
import CustomGroups from '../../../common/modals/CustomGroups';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/*

TODO:

- Group flights placements by Flights
- Group teams placements by Flights
- Add a delete cross to the root game of a bracket for delete
- Save the name of the bracket seems is not working

*/

// To include the default styles
import 'react-rangeslider/lib/index.css'

class Brackets extends React.Component {

    state = {
        zoomFactor: 100,
        bootstrapped: false,
        idDivision: null,
        selectedDivision: null,
        isDivisionFilterOpen: false,
        isCustomGroupsOpen: false,
        accordion: {

            divisions: false,
            flights: false,
            brackets: false,
            teams: false,
            registration: false,
        },
        flightedTeams: [],
        tournaments: [],
        games: [],
        creatingTournament: false
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, params } = this.props;
        setTitle && setTitle({
            sub: 'Brackets Manager',
            main: this.props.season.Name || null
        });

        this.props.fetchPositions && this.props.fetchPositions();
        this.props.fetchGameTypes && this.props.fetchGameTypes();
        this.props.fetchBracketGroupTypes && this.props.fetchBracketGroupTypes();

        this.props.scheduleManagerDivisionsActions &&
            this.props.scheduleManagerDivisionsActions.fetchDivisions(params.idSeason);

        // Fetch the locations of the season
        this.props.fetchLocations && this.props.fetchLocations(params.idSeason);
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Brackets Manager',
                main: nextProps.season.Name || null
            });
            this.props.fetchLocations && this.props.fetchLocations(nextProps.season.IdLeague);
        }

        if (nextProps.divisions && nextProps.divisions.length) {
            
            // Select the 1st division
            let idDivision = parseInt(localStorage.getItem("GLOBAL.SELECTED_DIVISION"), 10);
            this.setState({
                bootstrapped: true                
            }, () => {
                const selectedDivision = _.find(nextProps.divisions, d => d.IdDivision === idDivision);
                if (selectedDivision && !this.state.selectedDivision) {
                    this.selectDivision(selectedDivision);
                }
            });
        }
    }
    componentWillUnmount = () => {
        this.props.clearFlights &&
            this.props.clearFlights();
        this.props.scheduleManagerDivisionsActions.clearDivisions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions();
        this.props.seasonActions &&
            this.props.seasonActions.clearGames &&
            this.props.seasonActions.clearGames();
    }
    //

    fillGroup = (bracket, game, groupIndex) => {

        if (!bracket.Groups[groupIndex]) return;          

        if (game.gameInfo) {

            bracket.Groups[groupIndex].Games.push({
                Group: groupIndex,
                IdGame: parseInt(game.gameInfo.IdGame, 10),
                phantom: false,
                IdGameBracket: game.IdGameBracket,
                GameTeams: [game.gameInfo.home, game.gameInfo.away],
                Location: { ..._.pick(game.gameInfo, 'LocationId', 'LocationName'), IdLocation: game.gameInfo.LocationId },
                GameDate: moment(game.FORMATED_DATE_STRING, 'MM/DD/YYYY h:mmA'),
                GameStartTime: moment(game.FORMATED_DATE_STRING, 'MM/DD/YYYY h:mmA'),
                ...game.gameInfo
            });

            if (game.gameInfo.home.comesFrom.Id ||
                game.gameInfo.away.comesFrom.Id) {                
                this.fillGroup(bracket, game.gameInfo.home.parent || { phantom: true }, groupIndex + 1);                
                this.fillGroup(bracket, game.gameInfo.away.parent || { phantom: true }, groupIndex + 1);
            } else if (groupIndex + 1 < bracket.MAXROUNDS) {                
                this.fillGroup(bracket, { phantom: true }, groupIndex + 1);
                this.fillGroup(bracket, { phantom: true }, groupIndex + 1);
            }

        } else {    

            bracket.Groups[groupIndex].Games.push({
                phantom: true,
                GameTeams: [{ comesFrom: {} }, { comesFrom: {} }]
            });
            if (groupIndex + 1 < bracket.MAXROUNDS){
                this.fillGroup(bracket, { phantom: true }, groupIndex + 1);
                this.fillGroup(bracket, { phantom: true }, groupIndex + 1);
            }   

        }
    }

    toggleDivisionPicker = () => this.setState({ isDivisionFilterOpen: !this.state.isDivisionFilterOpen });

    selectDivision = (division) => {
        let flightedTeams = [];
        if (division && division.Flights) {
            division.Flights.forEach(f => {
                flightedTeams = [...flightedTeams, ...f.FlightedTeams];
            });
            localStorage.setItem("GLOBAL.SELECTED_DIVISION", division.IdDivision);
        }

        this.setState({
            idDivision: division.IdDivision,
            selectedDivision: division,
            flightedTeams,
            fetchingBrackets: division ? true : false
        }, () => {
            if (division.IdDivision) {

                this.fetchBrackets(division);
            }
        });
    }

    toggleAccordion = i => {
        this.setState({
            accordion: {
                divisions: i === 1 ? !this.state.accordion.divisions : this.state.accordion.divisions,
                flights: i === 2 ? !this.state.accordion.flights : this.state.accordion.flights,
                brackets: i === 3 ? !this.state.accordion.brackets : this.state.accordion.brackets,
                teams: i === 4 ? !this.state.accordion.teams : this.state.accordion.teams,
                registration: i === 5 ? !this.state.accordion.registration : this.state.accordion.registration,
            }
        });
    }

    // Add and remove tournament
    addTournament = () => {
        const { tournaments, idDivision } = this.state, { defaultGameDuration } = this.props;

        this.setState({ creatingTournament: true }, () => {

            // 1st create the Tournament Bracket group
            request.post(`${config.apiEndpoint}/api/v4/brackets/group/${idDivision}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({ Name: 'Bracket' })
                .then(({ body: { IdGameBracketGroup } }) => {

                    const firstGame = {
                        Group: 0,
                        Ghost: false,
                        GameTeams: [{ comesFrom: {} }, { comesFrom: {} }],
                        Location: {},
                        GameDate: moment(900, 'hmm'),
                        GameStartTime: moment(900, 'hmm'),
                        GameStartTimeFormated: moment(900, 'hmm').format('HH:mm:ss'),
                        GameEndTime: moment(900, 'hmm').add(defaultGameDuration, 'minutes').format('HH:mm:ss'),
                        IdDivision: idDivision
                    }

                    // Create the 1st game
                    request.post(`${config.apiEndpoint}/api/v4/games/placeholder`)
                        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                        .send(firstGame)
                        .then(({ body: { IdGame } }) => {

                            firstGame.IdGame = IdGame;

                            // Create the 1st bracket ?
                            request.post(`${config.apiEndpoint}/api/v4/brackets/bracket/${IdGameBracketGroup}`) //${idDivision}`)
                                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                                .send({ IdGame })
                                .then(({ body: { Id } }) => {

                                    // TODO: Create the 1st game
                                    tournaments.push({
                                        Name: 'Tournament',
                                        IdTournament: IdGameBracketGroup,
                                        IdDivision: idDivision,
                                        Brackets: [
                                            {
                                                paddingFromTheRoot: 0,
                                                IdBracket: Id,
                                                Groups: [ // This is like the rounds. For each round theres a group with x^2 games in it
                                                    {
                                                        Group: 1,
                                                        Games: [firstGame]
                                                    }
                                                ]
                                            }
                                            /*If there were to be 'Consolation games', then additional 'brackets' will appear here*/
                                        ]
                                    });
                                    this.setState({
                                        tournaments, creatingTournament: false
                                    }, () => {
                                        this.scrollToBottom();
                                    });
                                });
                        });
                });
        });
    }

    removeTournament = (index) => {
        const { tournaments } = this.state;
        const tourney = { ...tournaments[index] };
        // Update state        
        this.setState({ tournaments: [...tournaments.slice(0, index), ...tournaments.slice(index + 1)] }, () => {
            // Optimistically call API            
            request.del(`${config.apiEndpoint}/api/v4/brackets/group/${tourney.IdTournament || tourney.IdGameBracketGroup}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken')).then(() => { });
        });
    }
    //

    // Zoom slider handlers
    handleChange = newValue => {
        const { zoomFactor } = this.state;
        if (newValue !== zoomFactor)
            this.setState({ zoomFactor: newValue });
    };
    //

    scrollToBottom = () => this.bracketsEnd.scrollIntoView({ behavior: "smooth" });

    markTeamsAndPlacements = () => {
        const { tournaments } = this.state;
        let teams = [], placements = [];

        // This routine looks for all the teams in games at the tourney 
        let flagShouldUseNextBest = false;
        tournaments.forEach(t => {
            t.Brackets.forEach(b => {
                b.Groups.forEach(g => {
                    g.Games.forEach(game => {
                        if (_.find(game.GameTeams, gt => {
                            return (gt.placements && gt.placements.IdDivisionFlight);
                        })) { flagShouldUseNextBest = true };
                        let aux = _.chain(game.GameTeams, gt => gt.IdTeamDivision).map(g => {
                            return {
                                IdTeamDivision: g.IdTeamDivision, Placement: (g.placements || {}).GameBracketPlacementName, rest: g
                            }
                        }).value();

                        teams = [...teams, ..._.chain(aux).map(g => g.IdTeamDivision).compact().value()]
                        placements = [...placements, ..._.chain(aux).map(g => g.Placement).compact().value()];
                    });
                });
            });
        });
        this.setState({ teams, placements, isUsingNextBest: flagShouldUseNextBest });
    }

    toggleCustomGroups = () => this.setState({ isCustomGroupsOpen: !this.state.isCustomGroupsOpen });

    notify = () => { this.forceRefresh(); }

    forceRefresh = () => {
        const { selectedDivision } = this.state;
        this.fetchBrackets(selectedDivision);
    }

    fetchBrackets = division => {        

        request.get(`${config.apiEndpoint}/api/v4/divisions/brackets/${division.IdDivision}`) //${idDivision}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .set('show', true) // This forces to show the brackets 
            .then(({ body: { groups, maxRounds, customGroups } }) => {
                this.props.customGroupsActions.set(customGroups);
                let tournaments = [];
                
                groups.forEach(group => {
                    // Here we build the bracket info that our widget requires. 
                    // It group the games by rounds, filling the empty spaces -or gaps- with 'phantom' games                            
                    const tourney = {
                        Name: group.GameBracketGroupName,
                        IdTournament: group.IdGameBracketGroup,
                        IdDivision: group.IdDivision,
                        IdGameBracketGroupType: group.IdGameBracketGroupType,
                        GameBracketGroupType: group.GameBracketGroupType,
                        Brackets: []
                    };
                    group.Brackets.forEach((bracket, index) => {
                        let b = {
                            paddingFromTheRoot: bracket.RoundNumber - 1,
                            IdBracket: index,
                            Groups: [],
                            MAXROUNDS: bracket.MAXROUNDS
                        };
                        for (var i = 0; i < maxRounds; i++) {
                            b.Groups.push({
                                Group: i,
                                Games: []
                            });
                        }
                                                
                        this.fillGroup(b, bracket, bracket.RoundNumber - 1);
                        tourney.Brackets.push(b);
                    });
                    // At the end we push to the state the tourney that we just built/
                    // TODO: we might contruct this at the server also.
                    tournaments.push(tourney);
                });
                this.setState({ fetchingBrackets: false, tournaments, customGroups }, () => {
                    this.markTeamsAndPlacements();
                });
            });
    }

    render() {

        const { divisions = [], locations, customGroups } = this.props,
            { idDivision, accordion, tournaments, bootstrapped, zoomFactor, selectedDivision,
                flightedTeams, fetchingBrackets = false, creatingTournament, teams = [], placements = [], isUsingNextBest = false,
                isCustomGroupsOpen } = this.state;

        let fields = [];
        (locations || []).forEach(function (location) {
            fields = [...fields, {
                Name: location.Name,
                IdLocation: parseInt(location.IdLocation, 10)
            }, ..._.map(location.Fields || location.fields, function (f) {
                return {
                    Name: f.Name,
                    IdLocation: parseInt(f.IdLocation, 10)
                };
            })];
        });

        return (
            <div id="tournaments" className="no-margin no-padding bg-gray-dark h-100">
                {!idDivision && bootstrapped && (
                    <div className="d-flex justify-content-center centered-message">
                        {divisions && divisions.length > 0 && (<h1 className="white align-self-center block text-center">Please select a division<br />at the Top Menu</h1>)}
                    </div>)}
                { /* Actions */}
                {bootstrapped && (
                    <div className="actions-wrapper d-flex flex-row">
                        <span className="align-self-center margin-right-half white">Division:</span>

                        <Dropdown className="align-self-center" size="sm" isOpen={this.state.isDivisionFilterOpen}
                            toggle={this.toggleDivisionPicker}>
                            <DropdownToggle color="info" caret>
                                {selectedDivision ? (selectedDivision.Name) : 'Select a division'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>------- With Flight</DropdownItem>
                                {_.filter(divisions, d => d.HasFlights).map((division, i) =>
                                    <DropdownItem active={idDivision === division.IdDivision ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                        <i className={`fas fa-plane margin-right-half`} />{division.Name}
                                    </DropdownItem>)}
                                <DropdownItem header>------- Without Flight</DropdownItem>
                                {_.filter(divisions, d => !d.HasFlights).map((division, i) =>
                                    <DropdownItem active={idDivision === division.IdDivision ? true : false} key={i} onClick={() => this.selectDivision(division)}>
                                        {division.Name}
                                    </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>

                        {idDivision && <span className="align-self-center idented margin-right-half white">Zoom:</span>}
                        {idDivision && <div className="slider-horizontal align-self-center" style={{ width: '100px' }}>
                            <Slider min={70} max={100} value={zoomFactor} onChange={this.handleChange}></Slider>
                        </div>}
                        {idDivision && (<button onClick={this.toggleCustomGroups} className="align-self-center margin-right-half btn btn-link white btn-sm ml-auto">
                            <i className="fa fa-object-ungroup" />
                            {' '}Custom Groups</button>)}
                        {idDivision && (<button onClick={this.addTournament} className="align-self-center margin-right-half btn btn-success btn-sm"><i className="fa fa-plus" />
                            {' '}Add Tournament Bracket</button>)}
                    </div>)}
                { /* Brackets */}
                {idDivision !== 0 && (
                    <Animated animationIn="fadeIn" className="brackets-wrapper">
                        {tournaments.map((tournament, i) => (
                            <TournamentBracket positions={this.props.positions} game_types={this.props.game_types} fields={fields} notify={this.notify} zoomFactor={zoomFactor} tournament={tournament} index={i} key={tournament.IdTournament} idDivision={idDivision} removeTournament={() => this.removeTournament(i)} setDefaultGameDuration={this.props.setDefaultGameDuration} defaultGameDuration={this.props.defaultGameDuration} bracketGroupTypes={this.props.bracket_group_types} />))}
                        <div ref={(el) => {
                            this.bracketsEnd = el;
                        }}></div>
                    </Animated>)}
                { /* Accordion */}
                {idDivision !== 0 && (
                    <Animated animationIn='fadeIn' className="filters-wrapper">

                        {/* flighted division */}
                        <button onClick={() => this.toggleAccordion(1)} className={`btn btn-${accordion.divisions ? 'info' : 'default'} btn-block d-flex flex-row`}>
                            <i className="fa fa-cog margin-right-half align-self-center"></i>
                            <span className="align-self-center">Division Placements</span>
                            <i className={`fa fa-caret-${accordion.divisions ? 'down' : 'right'} ml-auto align-self-center`}></i>
                        </button>
                        {accordion.divisions && selectedDivision && (
                            <DivisionPlacements teams={flightedTeams} division={selectedDivision} placements={placements} isUsingNextBest={isUsingNextBest} />
                        )}

                        {/* custom groups */}
                        {customGroups && customGroups.length > 0 && <button onClick={() => this.toggleAccordion(5)} className={`btn btn-${accordion.registration ? 'info' : 'default'} btn-block d-flex flex-row`}>
                            <i className="fa fa-object-ungroup margin-right-half align-self-center"></i>
                            <span className="align-self-center">Custom Groups</span>
                            <i className={`fa fa-caret-${accordion.registration ? 'down' : 'right'} ml-auto align-self-center`}></i>
                        </button>}
                        {accordion.registration && selectedDivision && customGroups && customGroups.length > 0 && (
                            <CustomGroupsPlacements teams={flightedTeams} division={selectedDivision} placements={placements} isUsingNextBest={false} customGroups={this.props.customGroups} />
                        )}


                        {/* flight placement */}
                        <button onClick={() => this.toggleAccordion(2)} className={`btn btn-${accordion.flights ? 'info' : 'default'} btn-block d-flex flex-row`}>
                            <i className="fa fa-plane margin-right-half align-self-center"></i>
                            <span className="align-self-center">Flights Placements</span>
                            <i className={`fa fa-caret-${accordion.flights ? 'down' : 'right'} ml-auto align-self-center`}></i>
                        </button>
                        {accordion.flights && selectedDivision && (
                            <FlightPlacements division={selectedDivision} teams={flightedTeams} placements={placements} />)}
                        {/* bracket placement */}
                        <button onClick={() => this.toggleAccordion(3)} className={`btn btn-${accordion.brackets ? 'info' : 'default'} btn-block d-flex flex-row`}>
                            <i className="fa fa-share-alt margin-right-half align-self-center inverted"></i>
                            <span className="align-self-center">Brackets Placements</span>
                            <i className={`fa fa-caret-${accordion.brackets ? 'down' : 'right'} ml-auto align-self-center`}></i>
                        </button>
                        {accordion.brackets && (
                            <BracketPlacements tournaments={tournaments} placements={placements} />)}
                        <button onClick={() => this.toggleAccordion(4)} className={`btn btn-${accordion.teams ? 'info' : 'default'} btn-block d-flex flex-row`}>
                            <i className="fa fa-shield-alt margin-right-half align-self-center"></i>
                            <span className="align-self-center">Teams</span>
                            <i className={`fa fa-caret-${accordion.teams ? 'down' : 'right'} ml-auto align-self-center`}></i>
                        </button>
                        {accordion.teams && (
                            <Teams teams={flightedTeams} teamsInGames={teams} idDivision={idDivision} />
                        )}
                        <hr />
                    </Animated>
                )}
                { /* Loader */}
                {isCustomGroupsOpen && <CustomGroups update={this.forceRefresh} isOpen={isCustomGroupsOpen} toggle={this.toggleCustomGroups} customGroups={customGroups}
                    division={selectedDivision} placements={placements} teams={flightedTeams} customGroupsActions={this.props.customGroupsActions} />}
                {!bootstrapped && <Loader key="loader" message={`Loading Divisions and Brackets`} />}
                {fetchingBrackets && <Loader key="fetching" message="Please wait..." />}
                {creatingTournament && <Loader key="creating_tournament" message="Please wait..." />}
            </div>
        )
    }
}

export default DragDropContext(HTML5Backend)(Brackets);
