import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Due from '../../profile/partials/payment_types/Due';

class Dues extends React.Component {

  state = {
    step: 1,
    message: null,
    total: 0
  }

  render() {
    const { isOpen, toggle, dues } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
        <ModalHeader toggle={toggle} className="bg-blue-dark white">Dues</ModalHeader>
        <ModalBody>
          <div>
            <ul>
              {dues && dues.map((payment, i) => (
                <li key={i} className="d-flex flex-row p-2" style={{ borderBottom: '1px solid #ccc' }}>
                  <Due payment={payment} index={i} />
                </li>))}
            </ul>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default Dues;
