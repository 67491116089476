import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import _ from 'lodash';

class selectableTeam extends React.Component {
    state = { open: false, popoverGames: [] }
    componentWillMount() {
        const { games, team } = this.props, { popoverGames } = this.state;
        _.chain(games).sortBy('GameDate').value().forEach(game => {
            if (game.HomeTeam.IdTeamDivision === team.IdTeamDivision) {
                popoverGames.push(['vs', game.AwayTeam.Name || game.AwayTeam.TeamName, '-', game.GameDate.format('MMM Do @ h:mmA on'), game.LocationName].join(' '))
            } else {
                popoverGames.push(['vs', game.HomeTeam.Name || game.HomeTeam.TeamName, '-', game.GameDate.format('MMM Do @ h:mmA on'), game.LocationName].join(' '))
            }
        });
        this.setState({ popoverGames });
    }
    toggle = () => this.setState({ open: (!this.state.open && this.props.games.length) ? true : false });
    render() {
        const { index, team, fnCheckTeam, games } = this.props, { open, popoverGames } = this.state;
        return (
            <li className="no-margin no-padding d-flex flex-row">
                <button className={`btn btn-sm btn-default d-flex flex-row`} onClick={fnCheckTeam} style={{ marginLeft: '2em' }}>
                    <i className={`fa-${team.checked ? 'check-circle fas green' : 'circle far'} align-self-center`} />
                    <span className="margin-left align-self-center font-8">{(team.Name || team.TeamName)}</span>
                </button>
                <span id={`team-games-${team.IdTeam}-${index}`} onClick={this.toggle} style={{ whiteSpace: 'nowrap' }} className="margin-left-half align-self-center font-8 blue pointer">({games.length} gms)</span>
                <Popover placement="top" isOpen={open} target={`team-games-${team.IdTeam}-${index}`} toggle={this.toggle}>
                    <PopoverHeader>{(team.Name || team.TeamName)}</PopoverHeader>
                    <PopoverBody className="d-flex flex-column font-8">
                        {popoverGames && popoverGames.map((game, i) => <span key={i}>{game}</span>)}
                    </PopoverBody>
                </Popover>
            </li>
        )
    }
}

export default selectableTeam;
