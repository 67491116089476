import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import { Link } from 'react-router';
import LineLoader from '../LineLoader';
import BlueishHeader from '../things/BlueishHeader';
import BlaskishHeader from '../things/BlackishHeader';
import RedishHeader from '../things/RedishHeader';
import PurpleishHeader from '../things/PurpleishHeader';
import GreenishHeader from '../things/GreenishHeader';
import YellowishHeader from '../things/YellowishHeader';

class PlayersModal extends React.Component {

  state = {
    players: null,
    fetchingUsers: true,
    search: null
  }

  componentWillMount() {
    const { division } = this.props;
    this.props.divisionsActions &&
      this.props.divisionsActions.fetchPlayers(division.IdDivision, true);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.players) {
      this.setState({
        players: nextProps.players,
        fetchingUsers: false
      });
    }
  }

  renderPlayer = user => {       
    return (
      <div className="d-flex flex-row w-100 p-2 montserrat">
        <div className="w-25">
          <div className="cover align-self-center ml-2" style={{ width: 40, height: 40, borderRadius: 20, background: `url(${(user.UserImageUrl || user.UserImage)}?full=50) no-repeat center center` }} />
        </div>
        <div className="w-100  d-flex flex-column justify-content-center">
          <span className="toUpper toBold font-12 black line1">{`${user.NameFirst} ${user.NameLast}`}{' '}{user.Status ? '(' + user.Status + ')' : ''} </span>
          <span className="font-10 line1">ID: {user.IdUser}</span>
          <span className="font-10 line1">Team: {user.TeamName || 'N/A'}</span>
        </div>
        <div className="w-100 d-flex flex-column justify-content-center">
          <span className="font-10 align-self-center">{user.DivisionName}</span>
        </div>
        <div className="w-50 d-flex flex-row">
          <button style={{ width: 'auto', border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2 align-self-center">View Profile</button>
        </div>        
      </div>
    )
  }

  onChange = () => { this.setState({ search: this.txtSearch.value }) }

  render() {
    const { division, isOpen, toggle } = this.props,
      { players, fetchingUsers } = this.state;

    // Role status:
    // 1 = > Pending
    // 2 = > Active
    // 3 = > Denied
    // 4 = > Waiting
    // 5 = > Inactive
    // 6 = > Tryout

    let ps = _.chain(players).filter((p) => {
      return p.IdRole === 3 && p.IdRoleStatus === 2 && (this.state.search ? `${p.NameFirst} ${p.NameLast}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : true);
    }).value(),
      pen = _.chain(players).filter((p) => {
        return p.IdRole === 3 && p.IdRoleStatus === 1 && (this.state.search ? `${p.NameFirst} ${p.NameLast}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : true);
      }).value(),
      cs = _.chain(players).filter((p) => {
        return p.IdRole === 4 && (this.state.search ? `${p.NameFirst} ${p.NameLast}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : true);
      }).value(),
      wait = _.chain(players).filter((p) => {
        return p.IdRoleStatus === 4 && p.IdRole === 3 && (this.state.search ? `${p.NameFirst} ${p.NameLast}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : true);
      }).value(),
      ina = _.chain(players).filter((p) => {
        return _.indexOf([3, 5], p.IdRoleStatus) >= 0 && p.IdRole === 3 && (this.state.search ? `${p.NameFirst} ${p.NameLast}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : true);
      }).value(),
      tryouts = _.chain(players).filter((p) => {
        return p.IdRoleStatus === 6 && p.IdRole === 3 && (this.state.search ? `${p.NameFirst} ${p.NameLast}`.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : true);
      }).value();

    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{ width: 1000 }}>
        <ModalHeader toggle={toggle} className="bg-blue-dark white">
          Players on
          {' '}
          {division.Name || division.DivisionName}
        </ModalHeader>
        <ModalBody className="p-4">
          <input placeholder="Search" className="form-control " ref={i => this.txtSearch = i} onChange={this.onChange} />
          {fetchingUsers && <LineLoader />}
        </ModalBody>
        {players && (
          <ModalBody className="montserrat">
            { /* Coaches */}
            {cs.length > 0 && <BlaskishHeader message={`Coaches (${cs.length})`} />}
            <ul>
              {players && cs.map((u, i) => (
                <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                  <Link to={`/season/${division.IdSeason}/player/${u.IdUser}${u.IdTeamUserRole ? `/${u.IdTeamUserRole}` : ''}`} className="d-flex flex-row p-1">
                    {this.renderPlayer(u)}
                  </Link>
                </li>
              ))}
            </ul>
            { /* Actives */}
            {ps.length > 0 && <GreenishHeader message={`Players (${ps.length})`} />}
            <ul>
              {players && ps.map((u, i) => (
                <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                  <Link to={`/season/${division.IdSeason}/player/${u.IdUser}${u.IdTeamUserRole ? `/${u.IdTeamUserRole}` : ''}`} className="d-flex flex-row p-1">
                    {this.renderPlayer(u)}
                  </Link>
                </li>
              ))}
            </ul>
            { /* Pendings */}
            {pen.length > 0 && <BlueishHeader message={`Pending (${pen.length})`} />}
            <ul>
              {players && pen.map((u, i) => (
                <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                  <Link to={`/season/${division.IdSeason}/player/${u.IdUser}${u.IdTeamUserRole ? `/${u.IdTeamUserRole}` : ''}`} className="d-flex flex-row p-1">
                    {this.renderPlayer(u)}
                  </Link>
                </li>
              ))}
            </ul>
            { /* Waitlisted */}
            {wait.length > 0 && <YellowishHeader message={`Waitlisted (${wait.length})`} />}
            <ul>
              {players && wait.map((u, i) => (
                <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                  <Link to={`/season/${division.IdSeason}/player/${u.IdUser}${u.IdTeamUserRole ? `/${u.IdTeamUserRole}` : ''}`} className="d-flex flex-row p-1">
                    {this.renderPlayer(u)}
                  </Link>
                </li>
              ))}
            </ul>
            { /* Tryouts */}
            {tryouts.length > 0 && <PurpleishHeader message={`Tryouts (${tryouts.length})`} />}
            <ul>
              {players && tryouts.map((u, i) => (
                <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                  <Link to={`/season/${division.IdSeason}/player/${u.IdUser}${u.IdTeamUserRole ? `/${u.IdTeamUserRole}` : ''}`} className="d-flex flex-row p-1">
                    {this.renderPlayer(u)}
                  </Link>
                </li>
              ))}
            </ul>
            { /* Inactives */}
            {ina.length > 0 && <RedishHeader message={`Inactives (${ina.length})`} />}
            <ul>
              {players && ina.map((u, i) => (
                <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                  <Link to={`/season/${division.IdSeason}/player/${u.IdUser}${u.IdTeamUserRole ? `/${u.IdTeamUserRole}` : ''}`} className="d-flex flex-row p-1">
                    {this.renderPlayer(u)}
                  </Link>
                </li>
              ))}
            </ul>            
          </ModalBody>)}
      </Modal>
    )
  }
}

export default PlayersModal;
