import React from 'react';
import './css.css';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import request from 'superagent';
import moment from 'moment';
import config from '../../../../config';
import Sheet from './sheet';
import Navigation from '../navigation';
import Filter from './partials/filter';

class PrintRoster extends React.Component {

  state = {
    bootstrapped: false,
    filters: {},
    appliedFilters: {},
    ddDateOpen: false,
    ddTimeOpen: false,
    season: null,
    divisions: [],
    teams: [],
    fetchingGames: false,
    fetchedOnce: false,
    fullSheet: true,
    sheetMode: 1,
    downloading: false,
    filter: {}
  }

  // Lifecycle
  componentWillMount() {

    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Print Roster',
      main: this.props.season.Name || null
    });

    // Fetch season
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.divisionsActions && this.props.divisionsActions.fetchPrintableDivisions(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Print Roster',
        main: nextProps.season.Name || null
      });
      this.setState({ season: nextProps.season });
    }
    if (nextProps.divisions) {
      this.setState({ bootstrapped: true, divisions: nextProps.divisions });
    }
  }

  preview = (e) => {

    e && e.preventDefault && e.preventDefault();
    const { divisions, fetchingGames } = this.state;
    if (fetchingGames) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    this.setState({ fetchingGames: true }, () => {
      request.post(`${config.apiEndpoint}/api/v4/roster/bulk`)
        .send({ teams })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then(data => {
          this.setState({ teams: data.body.teams, fetchingGames: false, fetchedOnce: true });
        });
    });
  }

  reload_silently = () => {
    const { divisions } = this.state;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    request.post(`${config.apiEndpoint}/api/v4/roster/bulk`)
      .send({ teams })
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then(data => {
        this.setState({ teams: data.body.teams });
      });

  }

  download = () => {

    const { divisions, downloading, filter } = this.state;
    if (downloading) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    this.setState({
      downloading: true
    }, () => {

      // Fetch games with filters applied
      request.post(`${config.apiEndpoint}/api/v4/roster/bulk/download`)
        .send({ teams, filter })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .responseType('arraybuffer')
        .then((data) => {
          let file = new Blob([data.body], { type: 'application/pdf' });
          require("downloadjs")(file, 'Rosters.pdf');
          this.setState({ downloading: false, });
        });
    });
  }

  toggleSeason = () => {
    const { season, divisions } = this.state;
    season.selected = !season.selected;
    divisions.forEach(division => {
      division.selected = season.selected;
      division.Teams.forEach(team => {
        team.selected = season.selected;
      });
    });
    this.setState({ season, divisions });
  }

  toggleDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].selected = !divisions[index].selected;
    divisions[index].Teams.forEach(team => {
      team.selected = divisions[index].selected;
    });
    this.setState({ divisions });
  }

  toggleTeam = (di, ti) => {
    const { divisions } = this.state;
    divisions[di].Teams[ti].selected = !divisions[di].Teams[ti].selected;
    divisions[di].selected = _.find(divisions[di].Teams, (team) => !team.selected) ? false : true;
    this.setState({ divisions });
  }

  toggleExpandDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].expanded = !divisions[index].expanded;
    this.setState({ divisions });
  }

  onRequestPictureChange = () => {
    const { season } = this.props, { picture_change } = this.state;
    this.setState({ picture_change: null }, () => {
      request.post(`${config.apiEndpoint}/api/v4/users/request/replacement/${picture_change.IdUser}/${season.IdLeague}`)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .send({})
        .then((data) => {
          // Re-fetch roster
          this.reload_silently();
        });
    });

  }

  render() {

    const { fetchingGames, fetchedOnce, divisions, downloading, teams, bootstrapped, filter } = this.state,
      { league, season } = this.props;

    let filtered_teams = _.chain(teams)
      .filter(team => { if (filter.min_players) return (team.players.length >= filter.min_players); return true; })
      .filter(team => {
        if (filter.coach_any_validation) {
          return _.find(team.coaches, coach => !coach.CONC || !coach.SAFE || !coach.BGC || !coach.COACH);
        }
        return true;
      })
      .filter(team => { if (filter.coach_conc_validation) return _.find(team.coaches, coach => !coach.CONC); return true; })
      .filter(team => { if (filter.coach_safe_validation) return _.find(team.coaches, coach => !coach.SAFE); return true; })
      .filter(team => { if (filter.coach_bgc_validation) return _.find(team.coaches, coach => !coach.BGC); return true; })
      .filter(team => { if (filter.coach_coach_validation) return _.find(team.coaches, coach => !coach.COACH); return true; })
      .filter(team => { if (filter.coach_finger_validation) return _.find(team.coaches, coach => !coach.FINGER); return true; })
      .filter(team => { if (filter.player_dob_validation) return _.find(team.players, player => !player.IsValid); return true; })
      .value();

    return <section id="registration-fees" style={{ overflow: 'hidden' }}>

      <div className="w3-container mt-4 h-100" style={{ position: 'relative' }}>

        {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
          <div className="w-100 black">
            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
          </div>
        </div>}

        <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
          <h5>Administration</h5>
          {season && <Navigation season={season} />}
        </div>

        <div className="m-auto pt-4 w-100" style={{ maxWidth: 1285, position: 'absolute', top: 150, bottom: 0, left: 0, right: 0 }}>
          {divisions && bootstrapped && (
            <div key="with-data" className="d-flex flex-row h-100" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>

              <Animated animationIn="slideInLeft" className="no-margin no-padding bg-white d-flex flex-column" style={{ width: 450 }}>

                <section className="h-100" style={{ overflowY: 'auto', overflowX: 'hidden', borderBottom: '1px solid gray' }}>

                  {season && <button onClick={() => this.toggleSeason()} className="btn btn-link text-left  w-100 mt-2 btn-sm px-3 py-2" style={{ whiteSpace: 'normal' }}>
                    <i className={`mr-2 ${season.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'}`} />
                    <span>{season.Name || season.SeasonName}</span>
                  </button>}

                  {divisions && _.filter(divisions, d => d.Teams.length).map((division, i) => (
                    <section key={i}>

                      <div onClick={(e) => { e.stopPropagation(); this.toggleDivision(i) }} className="btn btn-link text-left  btn-sm w-100" style={{ whiteSpace: 'normal' }}>
                        {division.Teams.length > 0 &&
                          <button className="btn btn-sm btn-link black" onClick={(e) => { e.stopPropagation(); this.toggleExpandDivision(i) }}>
                            <i className={`fa fa-caret-${division.expanded ? 'up' : 'down'}`} />
                          </button>
                        }
                        <i className={`${division.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'} mx-2`} />
                        <span>{division.Name || division.IdDivision}</span>
                      </div>

                      {division.expanded && division.Teams.map((team, j) => (
                        <button onClick={() => this.toggleTeam(i, j)} key={j} className="btn btn-link text-left btn-sm btn-block" style={{ whiteSpace: 'normal', marginLeft: '3em' }}>
                          <i className={`${team.selected ? 'fa fa-check-circle blue' : 'far fa-circle black'}`} />
                          {' '}
                          {team.Name || team.TeamName}
                        </button>))}

                    </section>))}

                  <div style={{ height: 100 }} />
                </section>

                <section className="d-flex flex-column">
                  <Filter filter={this.state.filter} fnUpdate={f => this.setState({ filter: f })} />
                  <div style={{ height: 30 }} />
                </section>
              </Animated>

              <Animated animationIn="slideInRight" className="w-100 no-margin no-padding bg-white h-100 " style={{ borderLeft: '1px solid gray', position: 'relative' }}>

                {/* ACTIONS */}
                <div className="d-flex flex-row p-2 w-100" style={{ height: 60, position: 'absolute', top: 0, borderBottom: '1px solid gray' }}>
                  <button type="submit" key="previewbtn" onClick={this.preview} className="ml-auto align-self-center green pl-3 pr-3" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }}>
                    <i className="fa fa-eye mr-2" />
                    <span>{fetchingGames ? 'Fetching' : 'Preview'}</span>
                  </button>
                  {filtered_teams.length > 0 && <button type="submit" key="downloadbtn" onClick={this.download} className="ml-2 align-self-center green pl-3 pr-3" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }}>
                    <i className="fa fa-download mr-2" />
                    <span>{downloading ? 'Downloading' : 'Download'}</span>
                  </button>}
                </div>

                <section className={`bg-gray-dark inner-shadow`} style={{ overflowY: 'auto', position: 'absolute', top: 60, bottom: 0, left: 0, right: 0 }}>
                  {filtered_teams.length > 0 && !fetchingGames && filtered_teams.map((team, i) => <Sheet fncp={(u) => this.setState({ picture_change: u })} key={team.IdTeamDivision} team={team} season={season} width={900} />)}
                  {filtered_teams.length > 0 && <div style={{ height: 100 }} />}
                  {fetchingGames && <Loader key="loader" className="white" message={`Fetching Games, please wait...`} />}
                  {!fetchingGames && fetchedOnce && !filtered_teams.length && <div className="d-flex justify-content-center centered-message">
                    <h1 className="white align-self-center block text-center">No roster were found with the applied filters!</h1>
                  </div>}
                </section>

              </Animated>
            </div>
          )}
          {!bootstrapped && <Loader className="black" key="loader" message={`Loading Assets for Schedule Building`} />}

          {this.state.picture_change && <div className={`overlay ${this.state.picture_change ? 'show' : ''}`} style={{ position: 'fixed', zIndex: 100 }} />}

          <Animated isVisible={this.state.picture_change ? true : false} animationInDuration={200} animationOutDuration={200} animationIn="slideInRight" animationOut='slideOutRight' style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: 300, zIndex: 200 }} className="bg-white ">
            {this.state.picture_change && <div className="d-flex flex-column align-self-center justify-content-center  my-auto">
              <div className="d-flex flex-row">
                <button className="btn btn-link align-self-center" onClick={() => this.setState({ picture_change: null })}><i className="fas fa-times black font-20" /></button>
              </div>
              <div className="bordered cover align-self-center" style={{ width: 100, height: 100, borderRadius: 50, background: `url(${this.state.picture_change.UserImage}) no-repeat center center` }}></div>
              <h4 className="montserrat black align-self-center mt-1 mx-3 text-center">{`${this.state.picture_change.NameFirst} ${this.state.picture_change.NameLast}`}</h4>
              {!this.state.picture_change.replacements && <section className="d-flex flex-column">
                <span className="m-4 font-10 text-center montserrat black align-self-center">{`Request ${this.state.picture_change.NameFirst} to replace his profile picture?`}</span>
                <button className="btn btn-success mx-3" onClick={this.onRequestPictureChange}>Yes, request it</button>
                <button className="btn btn-link red" onClick={() => this.setState({ picture_change: null })}>No, cancel</button>
              </section>}

              {this.state.picture_change.replacements && <section className="d-flex flex-column">
                <span className="m-4 font-10 text-center montserrat black align-self-center">{`${this.state.picture_change.NameFirst} was requested to change its picture by ${this.state.picture_change.replacements.NameFirst} ${this.state.picture_change.replacements.NameLast} on ${moment(this.state.picture_change.replacements.DateCreated).format('MMM Do YYYY')}`}</span>
              </section>}
            </div>}
          </Animated>
        </div>
      </div>
    </section>
  }
}

export default PrintRoster;
