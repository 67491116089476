import React from 'react';
import Result from './result';

class Item extends React.Component {

  state = {
    team: null
  }

  componentWillMount() {
    this.setState({ team: this.props.team });
  }

  updatePlacement = (place, number) => {
    const { team } = this.state, { params } = this.props, { idSeason, letter } = params;
    team.Placement = place;
    team.Awards = number;
    this.props.teamActions && this.props.teamActions.updateAwards({
      idTeamDivision: team.IdTeamDivision,
      placement: place,
      awards: number,
      idAwardsCoachSignature: team.IdAwardsCoachSignature,
      idSeason, letter
    });
    this.setState({ team });
  }

  popSignature = () => {
    const { fnSign } = this.props, { team } = this.state;
    fnSign && fnSign(team);
  }
  showSignature = () => {
    const { fnShowSignature } = this.props, { team } = this.state;
    fnShowSignature && fnShowSignature(team);
  }

  handleError = () => {
    const { team } = this.state;
    team.TeamImageUrl = '/images/defaults/teamLogo.png';
    this.setState({ team });
  }

  render() {
    const { team } = this.state;
    return <tr style={{ height: 65 }}>
      <td>
        <div className="d-flex flex-row">
          <img alt="" onError={this.handleError} src={team.TeamImageUrl} className="smallPic align-self-center margin-right-half" style={{ backgroundColor: 'white'}} />
          <span className="align-self-center">{team.TeamName}</span>
        </div>
      </td>
      <td >{team.IdTeam}</td>
      <td>{team.DivisionName}</td>
      <td>
        <Result team={team} place={1} fnUpdate={this.updatePlacement} />
      </td>
      <td>
        <Result team={team} place={2} fnUpdate={this.updatePlacement} />
      </td>
      <td>
        <Result team={team} place={3} fnUpdate={this.updatePlacement} />
      </td>
      <td>
        <Result team={team} place={null} fnUpdate={this.updatePlacement} />
      </td>
      <td>{(team.players || []).length}</td>
      <td>
        {team.UserName_CoachSigned && team.UserName_CoachSigned !== null && <div className="d-flex flex-row">
          <img alt="" className="smallPic align-self-center margin-right-half" src="/images/defaults/male.png" />
          <span className="align-self-center margin-right-half underlined" onClick={this.showSignature}>{team.UserName_CoachSigned}</span>
          {/*<img alt="" className="align-self-center bg-white" src={team.Signature} style={{ height: 50 }} />*/}
        </div>}
        {!team.UserName_CoachSigned && <span className="text-center" onClick={this.popSignature}>Click here</span>}
      </td>
    </tr>
  }
}

export default Item;