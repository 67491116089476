import React from 'react';
import FullCalendarWrapper from './FullCalendarWrapper';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import './calendar.css';

class Slots extends React.Component {

  state = {
    bootstrapped: false,
    allTeams: [],
    modal: false,
    selectedGame: null,
    selectedFlight: null,
    activeTab: 1,
    adjustingGames: false,
    deletingGames: false,
    selectedDate: null, // moment(),
    idDivision: 0,
    selectedDivision: null,
    idLocation: 0,
    selectedLocation: null,
    gamesDuration: 90,
    locationsFetch: false
  }

  // Lifecycle
  componentWillMount = () => {

    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Slots',
      main: this.props.season.Name || null
    });

    this.props.seasonActions &&
      this.props.seasonActions.fetchSlots &&
      this.props.seasonActions.fetchSlots(params.idSeason);

    // Fetch the divisions of the season (it also brings the coaches)
    this.props.scheduleManagerDivisionsActions &&
      this.props.scheduleManagerDivisionsActions.fetchDivisions &&
      this.props.scheduleManagerDivisionsActions.fetchDivisions(params.idSeason);

    this.props.flightsActions &&
      this.props.flightsActions.fetchFlights(params.idSeason);

    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
  }
  componentWillUnmount = () => {

    this.props.seasonActions &&
      this.props.seasonActions.clearCoaches &&
      this.props.seasonActions.clearCoaches();

    this.props.scheduleManagerDivisionsActions &&
      this.props.scheduleManagerDivisionsActions.clearDivisions &&
      this.props.scheduleManagerDivisionsActions.clearDivisions();

    this.props.seasonActions &&
      this.props.seasonActions.clearSlots &&
      this.props.seasonActions.clearSlots();

  }
  componentWillReceiveProps = (nextProps) => {

    const { setTitle } = nextProps;

    if (nextProps.locations && nextProps.locations.length &&
      nextProps.divisions && nextProps.divisions.length &&
      nextProps.slots && this.state.locationsFetch) {
      // Notify         
      this.setState({ bootstrapped: true });
    }

    if (nextProps.season && this.props.season && nextProps.season.IdSeason !== this.props.season.IdSeason) {
      setTitle && setTitle({
        sub: 'Slots',
        main: (nextProps.season || {}).Name || null
      });
    }

    if ((nextProps.season.IdLeague || this.props.season.IdLeague) && !this.state.locationsFetch) {
      this.setState({ locationsFetch: true }, () => {
        this.props.fetchLocations && this.props.fetchLocations(nextProps.season.IdLeague);
      });
    }
  }
  componentWillUnmount = () => {
    this.props.clearLocations && this.props.clearLocations();
  }
  //

  // CRUD


  // Toggles and Selection  
  changeTab = activeTab => this.setState({ activeTab });

  divisionChange = (obj) => {
    const { divisions } = this.props
    let idDivision = obj ? obj.value : 0;
    this.setState({
      idDivision: idDivision,
      selectedDivision: _.find(divisions, function (d) {
        return d.IdDivision === idDivision
      })
    });
  }
  locationChange = (obj) => {
    const { divisions } = this.props
    let idDivision = obj ? obj.value : 0;

    this.setState({
      idDivision: idDivision,
      selectedDivision: _.find(divisions, function (d) {
        return d.IdDivision === idDivision
      })
    });
  }

  render() {

    const { locations, } = this.props,
      { bootstrapped, } = this.state;

    let fields = [];
    (locations || []).forEach(function (location) {
      fields = [...fields, {
        Name: location.Name,
        IdLocation: parseInt(location.IdLocation, 10)
      }, ..._.map(location.Fields || location.fields, function (f) {
        return {
          Name: f.Name,
          IdLocation: parseInt(f.IdLocation, 10)
        };
      })];
    });

    return (<div className="bg-gray-dark">
      { /* CALENDAR */}
      {locations && locations.length && bootstrapped && <FullCalendarWrapper {...this.props} />}
      { /* CREATE OR UPDATE GAMES */}
      {!bootstrapped && <Loader key="loader" message={`Loading Locations and Slots`} />}
    </div>)
  }
}

export default Slots;
