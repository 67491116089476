import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { generateRandomId } from '../../../../../helpers';
import _ from 'lodash';
import GameDayRule from '../game_days/GameDayRule';

class GameDayRuleIcon extends React.Component {
    state = {
        popupverOpen: false,
        removed: false,
        randomId: generateRandomId(10)
    }

    removeFromElement = () => {
        const { rule, gameDayRulesActions } = this.props;
        gameDayRulesActions.removeAssociation && gameDayRulesActions.removeAssociation(rule.Id);
        this.setState({ popoverOpen: false });
    }

    toggle = () => this.setState({ popoverOpen: !this.state.popoverOpen });

    componentDidUpdate = () => {
        const { rule } = this.props;

        // Shake feedback for when the Rule is re-droped on the same Flight
        if (rule.shake) {
            setTimeout(() => {
                rule.shake = false;
                this.forceUpdate();
            }, 1500)
        }
    }

    render() {
        const { rule, index, rules } = this.props, { randomId } = this.state,
            targetRule = _.find(rules, function (r) {
                return r.Id === rule.IdRule
            });

        const { removed } = this.state;

        if (targetRule) {
            return (
                <i onClick={this.toggle} className={`fas fa-calendar-day ${(!rule.Id || removed) ? 'gray' : 'blue'} ${rule.shake ? 'shake' : ''}`} 
                title={`${targetRule.Name || targetRule.RuleName}`} data-for={`rule${index}`} id={randomId}>
                    {/*<ReactTooltip effect="solid" place="top" id={`rule${index}`} />*/}
                    <Popover placement="top" isOpen={this.state.popoverOpen} target={randomId} toggle={this.toggle}>
                        <PopoverBody className="d-flex flex-column justify-content-end">
                            <button className="btn btn-link btn-sm blue no-margin no-padding text-right" onClick={this.toggle}>close</button>
                            <GameDayRule rule={targetRule} readOnly={true} />
                            <button className="btn btn-danger btn-sm btn-block font-8" onClick={this.removeFromElement}>Remove this Game Day from Flight ?</button>
                        </PopoverBody>
                    </Popover>
                </i>
            );
        } else {
            return (<i className="fa fa-calendar red" />)
        }
    }
}

export default GameDayRuleIcon;
