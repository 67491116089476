import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import accepted from './accepted_Reducer';
import adjustments from './adjustments_Reducer';
import afp from './afp_Reducer';
import atp from './atp_Reducer';
import ageGroups from './ageGroups_Reducer';
import answers from './answers_Reducer';
import apiProgress from './apiProgress';
import appliedQuestions from './appliedQuestions_Reducer';
import appPlans from './appPlans_Reducer';
import archived_teams from './archived_teams';
import auth from './auth';
import autoPaySettings from './autoPaySettings_Reducer';
import balance from './balance_Reducer';
import bracket_group_types from './bracket_group_types_Reducer';
import campaign from './campaign_Reducer';
import campaigns from './campaigns_Reducer';
import carriers from './carriers_Reducer';
import cart from './cart_Reducer';
import clips from './clips_Reducer';
import coaches from './coaches_Reducer';
import colors from './colors_Reducer';
import collaborators from './collaborators_Reducer';
import college_profile from './college_profile_Reducer';
import communities from './communities_Reducer';
import contest_type from './contest_type_Reducer';
import countries from './countries_Reducer';
import country_codes from './country_codes';
import coupons from './coupons_Reducer';
import couponTypes from './couponTypes_Reducer';
import customAttributesTemplates from './customAttributesTemplates_Reducer';
import crossFlightRules from './crossFlightRules_Reducer';
import customGroups from './customGroups_Reducer';
import details from './details_Reducer';
import defaultGameDuration from './defaultGameDuration_Reducer';
import developer from './developer_Reducer';
import disclaimer from './disclaimer_Reducer';
import divisions from './divisions_Reducer';
import division from './division_Reducer';
import dues from './dues_Reducer';
import emails from './emails_Reducer';
import events from './events_Reducer';
import exceptionRules from './schedule_manager/exceptionRules_Reducer';
import family from './family_Reducer';
import family_lastname from './family_lastname_Reducer';
import family_attendance from './family_attendance';
import familyAdjustments from './familyAdjustments_Reducer';
import familyRegistration from './familyRegistration_Reducer';
import family_subscriptions from './family_subscriptions_Reducer';
import family_videos from './family_videos_Reducer';
import fees from './fees_Reducer';
import feeTypes from './feeTypes_Reducer';
import fetchingSpotlight from './fetchingSpotlight_Reducer';
import fieldAvailabilityRules from './schedule_manager/fieldAvailabilityRules_Reducer';
import fields from './fields_Reducer';
import fields_layout from './fields_layout_Reducer';
import files from './files_Reducer';
import filters from './filters_Reducer';
import flightedTeams from './flightedTeams';
import flights from './flights_Reducer';
import gameDayRules from './schedule_manager/gameDayRules_Reducer';
import games from './games_Reducer';
import game from './game_Reducer';
import game_types from './game_types_Reducer';
import genders from './genders';
import generic from './generic_Reducer';
import highlights from './highlights_Reducer';
import information from './information_Reducer';
import isCartOpen from './isCartOpen_Reducer';
import isTeamSelectionOpen from './isTeamSelectionOpen_Reducer';
import isUserSelectionOpen from './isUserSelectionOpen_Reducer';
import network from './network_Reducer';
import network_coaches from './network_coaches_Reducer';
import leads from './leads_Reducer';
import league from './league';
import leagues from './leagues';
import leagueImage from './leagueImage_Reducer';
import leaguePoints from './leaguePoints_Reducer';
import library from './library_Reducer';
import limits from './limits_Reducer';
import locations from './locations';
import locks from './locks_Reducer';
import messages_received from './message_received_Reducer';
import messages_sent from './message_sent_Reducer';
import membership_programs from './membership_programs_Reducer';
import medals from './medals_Reducer';
import micro from './micro_Reducer';
import notes from './notes_Reducer';
import opponents from './opponents_Reducer';
import pastDues from './pastDues_Reducer';
import paymentPlans from './paymentPlans_Reducer';
import payments from './payments_Reducer';
import permissions from './permissions_Reducer';
import phones from './phones_Reducer';
import players from './players_Reducer';
import positions from './positions_Reducer';
import points from './points_Reducer';
import pouch from './pouch_Reducer';
import processes from './processes_Reducer';
import questionTexts from './questionTexts_Reducer';
import questionGroups from './questionGroups_Reducer';
import questions from './questions_Reducer';
import questionaries from './questionaries_Reducer';
import referal from './referal_Reducer';
import report from './report_Reducer';
import registrations from './registrations_Reducer';
import rights from './rights_Reducer';
import roles from './roles_Reducer';
import roster from './roster_Reducer';
import rosterTeams from './rosterTeams_Reducer';
import scout_registrations from './scout_registrations_Reducer';
import season from './season_Reducer';
import seasons from './seasons_Reducer';
import selectedAnswers from './selectedAnswers_Reducer';
import selectedTeam from './selectedTeam_Reducer';
import selectedUser from './selectedUser_Reducer';
import scoutRegistration from './scoutRegistration_Reducer';
import settings from './settings_Reducer';
import sizes from './sizes_Reducer';
import slots from './slots';
import spotlight from './spotlight_Reducer';
import spotlightOn from './spotlightOn_Reducer';
import spread from './spread_Reducer';
import states from './states_Reducer';
import statuses from './statuses_Reducer';
import topics from './topics_Reducer';
import tournamentPointsTypes from './tournamentPointsTypes_Reducer';
import tournamentSeasons from './tournamentSeasons_Reducer';
import teamRegistration from './teamRegistration_Reducer';
import team_subscriptions from './team_subscriptions_Reducer';
import team_evaluations from './team_evaluations_Reducer';
import teams from './teams_Reducer';
import templates from './templates_Reducer';
import time_zones from './time_zones_Reducer';
import title from './title';
import tryouts from './tryouts_Reducer';
import uniform_bag from './uniform_bag_Reducer';
import uniform_bags from './uniform_bags_Reducer';
import user from './user_Reducer';
import users from './users_Reducer';
import validations from './validations_Reducer';
import violations from './violations_Reducer';
import violation_types from './violation_types_Reducer';
import validations_summary from './validations_summary_Reducer';
import videos from './videos_Reducer';
import video from './video_Reducer';
import waiver from './waiver_Reducer';
import waivers from './waivers_Reducer';
import waitlists from './waitlists_Reducer';
import zipCodes from './zipCodes_Reducer';
import zipCodesAdjustments from './zipCodesadjustments_Reducer';
import depth_charts from './depth_charts_Reducer';
import lineups from './lineups_Reducer';

const loggedIn = (state = [], action) => {
    return state;
}

const rootReducer = combineReducers({
    accepted,
    adjustments,
    afp,
    atp,
    ageGroups,
    answers,
    appliedQuestions,
    appPlans,
    archived_teams,
    auth,
    autoPaySettings,
    balance,
    bracket_group_types,
    carriers,
    cart,
    campaign,
    campaigns,
    clips,
    coaches,
    communities,
    colors,
    collaborators,
    college_profile,
    contest_type,
    countries,
    country_codes,
    coupons,
    couponTypes,
    customAttributesTemplates,
    crossFlightRules,
    customGroups,
    details,
    defaultGameDuration,
    depth_charts,
    developer,
    disclaimer,
    divisions,
    division,
    dues,
    emails,
    events,
    exceptionRules,
    family,
    family_lastname,
    family_attendance,
    familyAdjustments,
    familyRegistration,
    family_subscriptions,
    family_videos,
    fees,
    feeTypes,
    fetchingData: apiProgress,
    fetchingSpotlight: fetchingSpotlight,
    fieldAvailabilityRules,
    fields,
    fields_layout,
    files,
    filters,
    flightedTeams,
    flights,
    gameDayRules,
    games, game,
    game_types,
    genders,
    generic,
    highlights,
    information,
    isCartOpen,
    isTeamSelectionOpen,
    isUserSelectionOpen,
    network,
    network_coaches,
    leads,
    league,
    leagues,
    leagueImage,
    leaguePoints,
    library,
    limits,
    lineups,
    locations,
    locks,
    loggedIn,
    messages_received,
    messages_sent,
    membership_programs,
    medals,
    micro,
    notes,
    opponents,
    pastDues,
    paymentPlans,
    payments,
    permissions,
    phones,
    players,
    points,
    positions,
    pouch,
    processes,
    questionTexts,
    questionGroups,
    questions,
    questionaries,
    referal,
    report,
    registrations,
    rights,
    roles,
    roster,
    rosterTeams,
    routing: routerReducer,
    scout_registrations,
    season,
    seasons,
    selectedAnswers,
    selectedTeam,
    selectedUser,
    settings,
    sizes,
    slots,
    spotlight,
    spotlightOn,
    spread,
    states,
    statuses,
    scoutRegistration,
    teamRegistration,
    team_subscriptions,
    team_evaluations,
    teams,
    templates,
    time_zones,
    title,
    topics,
    tournamentPointsTypes,
    tournamentSeasons,
    tryouts,
    uniform_bag,
    uniform_bags,
    users,
    user,
    validations,    
    validations_summary,
    violations,
    violation_types,
    videos,
    video,
    waiver,
    waivers,
    waitlists,
    zipCodes,
    zipCodesAdjustments,
});

export default rootReducer;
