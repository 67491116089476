import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import './fields.css';
import Loader from '../../../common/Loader';
import LocationListItem from './LocationListItem';
import AddLocationModal from './Partials/AddLocationModal';
import { Alert } from 'reactstrap';
import Navigation from '../navigation';
import _ from 'lodash';

class FieldsManager extends React.Component {

    state = {
        bootstrapped: false,
        adding: false,
        selectedCommunity: null,
        newLocation: null,
        search_text: null
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, params, seasonActions, season } = this.props;
        setTitle && setTitle({
            sub: 'Manage Locations',
            main: this.props.season.Name || null
        });
        seasonActions.fetchSeason(params.idSeason);
        this.props.geoActions && this.props.geoActions.fetchCountries();
        if (season && season.IdLeague) this.props.locationsActions.fetchLocations(season.IdLeague);
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Manage Locations',
                main: nextProps.season.Name || null
            });
            this.props.locationsActions && this.props.locationsActions.fetchLocations(nextProps.season.IdLeague);
        }
        if (nextProps.locations) {            
            this.setState({ bootstrapped: true });
        }
    }
    componentWillUnmount() {
        this.props.locationsActions &&
            this.props.locationsActions.clearLocations();
    }
    //

    toggleAdding = () => {
        this.setState({
            adding: !this.state.adding
        });
    }

    addLocation = () => {
        this.setState({
            newLocation: {
                fields: []
            },
            adding: true
        });
    }

    selectLocation = (newLocation) => {
        this.setState({
            newLocation,
            adding: true
        })
    }

    onChange = () => { this.setState({ search_text: this.txtSearch.value }); }

    render() {
        const { locations, season, league } = this.props,
            { adding, bootstrapped, newLocation, error, updated, search_text } = this.state;

        let filtered_locations = _.chain(locations)
            .filter(l => l.Name.toLowerCase().indexOf((search_text || '').toLowerCase()) !== -1).value();

        return (
            <section>
                <div className="w3-container mt-4">
                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                        <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
                            Season updated!
                        </Alert>
                    </div>

                    {this.props.season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                        <div className="w-100 black">
                            <h3>{this.props.season.Name || this.props.season.SeasonName}</h3>
                            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
                        </div>
                    </div>}

                    <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
                        <h5>Administration</h5>
                        {this.props.season && <Navigation season={this.props.season} />}
                    </div>

                    {season && bootstrapped && <Animated animationIn="slideInUp" className="my-3 card shadow w3-content pb-4" style={{ maxWidth: 1285, minHeight: 500 }}>

                        <div className="d-flex flex-row montserrat p-3" style={{ maxWidth: 1285 }}>
                            <div className="black">
                                <h1>Fields and Locations</h1>
                            </div>
                            <div className="ml-auto d-flex flex-row">
                                <input placeholder="Search" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />
                                <button onClick={this.addLocation} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 200 }} className="green ml-2 p-2 align-self-center block">
                                    <i className="fas fa-plus margin-right-half" />Add</button>
                            </div>
                        </div>

                        {bootstrapped && filtered_locations && !filtered_locations.length && (
                            <div className="jumbotron text-center margin-top">
                                <h1 className="display-3">There are no locations / fields registered</h1>
                                {search_text && <h3>under '{search_text}'</h3>}
                                <p className="lead">Click the plus sign above to create a Location</p>
                            </div>
                        )}
                        {bootstrapped && filtered_locations.length > 0 && (
                            <div className="clearfix px-2">
                                {filtered_locations.map((l, i) => (
                                    <LocationListItem key={l.IdLocation} location={l} fnSelect={this.selectLocation} />
                                ))}
                            </div>
                        )}
                    </Animated>}
                </div>

                {!bootstrapped && <Loader message={`Loading Fields, please wait`} />}

                {adding && <AddLocationModal loc={newLocation} {...this.props} isOpen={adding} toggle={this.toggleAdding} />}

            </section>
        )
    }
}

export default FieldsManager;
