import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

class PlayerInspection extends React.Component {
    state = {
        family: []
    }

    componentWillMount() {
        const { family } = this.props;
        // Clear
        family.forEach(u => u.selected = false);
        this.setState({ family: [...family] });
    }
    selectUser = (idUser) => {
        const { family } = this.state;
        family.forEach(f => f.selected = f.IdUser === idUser);
        this.setState({ family });
    }
    onOk = () => {
        const { family } = this.state, { season, familyRegistration } = this.props;
        let selectedUser = _.find(family, f => f.selected);
        if (selectedUser) {
            this.props.onOk && this.props.onOk({ selectedUser, mode: 1, 
                selectedDivision: _.find(familyRegistration, fr => fr.IdUser === selectedUser.IdUser && fr.IdRole === 3 && fr.IdSeason === season.IdSeason)
             });
        }
    }
    selectUser = (idUser) => {
        const { family } = this.state;
        family.forEach(f => f.selected = f.IdUser === idUser);
        this.setState({ family });
    }
    render() {
        const { familyRegistration, season } = this.props, { family } = this.state;
        let alreadyRegistered = [
            ..._.filter(family, user => (_.find(familyRegistration, fr => fr.IdUser === user.IdUser && fr.IdRole === 3 && fr.IdSeason === season.IdSeason)))
        ];

        return <div className=" px-4 pb-4 pt-2 h-100 montserrat">

            <div className="pointer tile card shadow-box p-4 d-flex flex-column justify-content-center m-auto bg-blue-ish" style={{ height: 160 }}>
                <i className="icon-family_registration white font-40 align-self-center" />
                <span className="white font-20 toBold align-self-center">PLAYER</span>
                <span className="font-10 white align-self-center">Payments</span>
            </div>
            
            <div style={{ height: 30 }} />
            
            <Animated animationIn="fadeInRight" className="d-flex flex-column">

                {alreadyRegistered.length > 0 && <section className="d-flex flex-column">
                    {alreadyRegistered.map((user, i) => (
                        <div key={i} className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-2 ${user.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}
                            onClick={() => this.selectUser(user.IdUser)}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <span className="align-self-center font-12">{user.NameFirst} {user.NameLast}</span>
                            <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                        </div>))}
                </section>}

                <button onClick={this.onOk} className={`btn btn-${_.find(family, u => u.selected) ? 'success' : 'default'} mt-4`}>Continue</button>

                <div style={{ height: 100 }} />

            </Animated>
        </div>
    }
}

export default PlayerInspection;
