import React from 'react';
import { Animated } from 'react-animated-css';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerFullLabel';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import CreateOpponent from './CreateOpponent';

class AddOpponent extends React.Component {
    state = {

    }
    componentWillMount() {
        // TODO: api call to bring opponents and locations
        const { opponents } = this.props;
        this.setState({ opponents });
        setTimeout(() => {
            this.setState({ bootstrapped: true });
        }, 200);
    }
    componentWillReceiveProps = nextProps => {
        const { opponents } = nextProps;
        this.setState({ opponents });
    }
    render() {
        const { opponents } = this.state;
        return <section>

            <section className="fullscreen" style={{ zIndex: 10003 }}></section>
            <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={this.state.bootstrapped ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10004, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">
                {/* HEADER */}
                <div className="bg-dark-blue px-4 py-3 d-flex flex-row">
                    <i className="fas fa-arrow-left white font-16 pointer align-self-center" onClick={() => {
                        this.setState({ bootstrapped: false }, () => {
                            setTimeout(this.props.toggle, 200);
                        });
                    }} />
                    <span className="montserrat white font-14 ml-4 align-self-center">OPPONENT</span>
                </div>
                <div onClick={() => this.setState({ creating: true })} className="pointer mx-2 card tile mt-2 px-2 py-4 d-flex flex-row justify-content-center">
                    <i className="fas fa-circle-plus black" />
                    <span className="align-self-center ml-2 line1">Create new Opponent</span>
                </div>
                {opponents && opponents.map((o, i) => <div onClick={() => this.props.fn(o)} className="pointer mx-2 card tile mt-2 p-2 d-flex flex-row" key={o.IdTeamOther || o.IdTeam}>
                    <img src="/images/defaults/generic_crest.png" style={{ height: 40 }} className="align-self-center" />
                    <span className="align-self-center ml-2 line1">{o.TeamOtherName}</span>
                </div>)}
            </Animated>

            {this.state.creating && <CreateOpponent
                toggle={() => this.setState({ creating: false })}
                fnAdded={(team) => this.setState({ creating: false, }, () => {
                    this.props.fn(team);
                    this.props.teamActions.fetchOpponents();
                })}
            />}
        </section>
    }
}

export default AddOpponent;