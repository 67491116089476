import _ from 'lodash';

function reducer(state = [], action) {

    switch (action.type) {
        case 'CART/SET':
            localStorage.setItem('sportslogic.cart', JSON.stringify([...action.cart]));
            return [...action.cart];
        case 'CART/REMOVE/ITEM':
            localStorage.setItem('sportslogic.cart', JSON.stringify(_.reject(state, (i) => {
                return i.Id === action.id;
            })));
            return _.reject(state, (i) => {
                return i.Id === action.id;
            });
        case 'CART/REMOVE/LEAGUE':
            localStorage.setItem('sportslogic.cart', JSON.stringify(_.reject(state, (i) => {
                return i.selectedLeague.IdLeague === action.idLeague;
            })));
            return _.reject(state, (i) => {
                return i.selectedLeague.IdLeague === action.idLeague;
            });
        case 'AUTH/LOGOUT':
            localStorage.setItem('sportslogic.cart', JSON.stringify([]));  
            return [];          
        default:
            return state;
    }
}

export default reducer;
