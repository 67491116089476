import React from 'react';
import { DropTarget } from 'react-dnd';
import FeesIcon from '../registration_programs/icon';
import ZipCodeIcon from '../zip/icon';
import TryoutIcon from '../tryouts/icon';
import AdjustmentIcon from '../adjustments/icon';
import FamilyAdjustmentIcon from '../family/icon';
import { ItemTypes } from '../../../../../../helpers';
import _ from 'lodash';

const target = {
    canDrop(props) {
        // TODO: evaluate props to see if it can be dropped
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another a child component that also accepts this type of items
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }

        const { division, seasonActions, divisionsActions } = props;

        // Evaluate the type of program that's being dropped
        const { program, type } = monitor.getItem();
        switch (type) {
            case ItemTypes.REGISTRATIONFEE:

                // update the store (optimistic)
                seasonActions && seasonActions.updateProgram(program, {
                    IdCatalogItem: program.IdCatalogItem,
                    DivisionName: division.Name,
                    IdDivision: division.IdDivision
                });
                // call the api to update the server (optimistic)
                divisionsActions && divisionsActions.assignProgram(division.IdDivision, program.IdCatalogItem);

                break;

            case ItemTypes.TRYOUT:
                program.divisions.push({
                    IdCatalogItem: program.IdCatalogItem,
                    DivisionName: division.Name,
                    IdDivision: division.IdDivision
                });
                // update the store (optimistic)
                seasonActions && seasonActions.updateTryoutProgram(program);
                // call the api to update the server (optimistic)
                divisionsActions && divisionsActions.assignTryoutProgram(division.IdDivision, program.Id);
                break;

            case ItemTypes.ADJUSTMENT:
                program.divisions.push({
                    IdCatalogItemPriceAdjustmentByDate: program.IdCatalogItemPriceAdjustmentByDate,
                    DivisionName: division.Name,
                    IdDivision: division.IdDivision
                });
                // update the store (optimistic)
                seasonActions && seasonActions.updateAdjustmentProgram(program);
                // call the api to update the server (optimistic)
                divisionsActions && divisionsActions.assignAdjustment(division.IdDivision, program.IdCatalogItemPriceAdjustmentByDate);
                break;

            case ItemTypes.ZIPCODE:
                program.divisions.push({
                    IdZipCodeGroup: program.IdZipCodeGroup,
                    DivisionName: division.Name,
                    IdDivision: division.IdDivision
                });
                // update the store (optimistic)
                seasonActions && seasonActions.updateZipCodeProgram(program);
                // call the api to update the server (optimistic)
                divisionsActions && divisionsActions.assignZipCode(division.IdDivision, program.IdZipCodeGroup);
                break;

            case ItemTypes.FAMILYADJUSTMENT:
                program.divisions.push({
                    IdCatalogItemPriceAdjustmentByItemIndexGroup: program.IdCatalogItemPriceAdjustmentByItemIndexGroup,
                    DivisionName: division.Name,
                    IdDivision: division.IdDivision
                });
                // update the store (optimistic)
                seasonActions && seasonActions.updateFamilyAdjustment(program);
                // call the api to update the server (optimistic)
                divisionsActions && divisionsActions.assignFamilyAdjustment(division.IdDivision, program.IdCatalogItemPriceAdjustmentByItemIndexGroup);
                break;

            default: break;
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class DivisionListItem extends React.Component {

    state = {
        collapsed: true,
        programs: [],
        zipCodesAdjustments: [],
        tryouts: [],
        adjustments: [],
        genderIcon: 'question'
    }

    componentWillMount() {
        let { genderIcon } = this.state;
        switch (this.props.division.IdGender) {
            case 1: genderIcon = 'male'; break;
            case 2: genderIcon = 'female'; break;
            default: break;
        }
        this.setState({ genderIcon }, () => {
            this.setup(this.props);
        });
    }
    componentWillReceiveProps = (nextProps) => {
        this.setup(nextProps);
    }

    onShowcase = () => {
        const { division } = this.props, { programs, zipCodesAdjustments, tryouts, adjustments, familyAdjustments } = this.state;
        this.props.onDivision && this.props.onDivision({ division, programs, zipCodesAdjustments, tryouts, adjustments, familyAdjustments });
    }

    setup = (props) => {
        const { paymentPlans, division, zipCodesAdjustments, tryouts, adjustments, familyAdjustments } = props;

        this.setState({
            programs: _.filter(paymentPlans || [], program => {
                return _.find(program.divisions, div => { return parseInt(div.IdDivision, 10) === parseInt(division.IdDivision, 10); });
            }),
            zipCodesAdjustments: _.filter(zipCodesAdjustments || [], program => {
                return _.find(program.divisions, div => { return parseInt(div.IdDivision, 10) === parseInt(division.IdDivision, 10); });
            }),
            tryouts: _.filter(tryouts || [], program => {
                return _.find(program.divisions, div => { return parseInt(div.IdDivision, 10) === parseInt(division.IdDivision, 10); });
            }),
            adjustments: _.filter(adjustments || [], program => {
                return _.find(program.divisions, div => { return parseInt(div.IdDivision, 10) === parseInt(division.IdDivision, 10); });
            }),
            familyAdjustments: _.filter(familyAdjustments || [], program => {
                return _.find(program.divisions, div => { return parseInt(div.IdDivision, 10) === parseInt(division.IdDivision, 10); });
            }),
        });
    }

    render() {
        const { connectDropTarget, isOverCurrent, canDrop, division } = this.props,
            { collapsed, programs, zipCodesAdjustments, tryouts, adjustments, familyAdjustments } = this.state;

        return connectDropTarget(
            <li style={{ borderTop: '1px solid #e5e5e5' }} className={`${collapsed ? 'off' : 'on'} division p-1 block font-10 droppable ${canDrop ? 'action' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>

                <span onClick={this.onShowcase} className="ml-2 mr-2 align-self-center pointer zoom">{division.Name} ({division.IdDivision})</span>
                {programs && programs.map((program, i) => <FeesIcon key={i} index={i} program={program} {...this.props} />)}
                {adjustments && adjustments.map((program, i) => <AdjustmentIcon key={i} index={i} program={program} {...this.props} />)}
                {zipCodesAdjustments && zipCodesAdjustments.map((program, i) => <ZipCodeIcon key={i} index={i} program={program} {...this.props} />)}
                {familyAdjustments && familyAdjustments.map((program, i) => <FamilyAdjustmentIcon key={i} index={i} program={program} {...this.props} />)}
                {tryouts && tryouts.map((program, i) => <TryoutIcon key={i} index={i} program={program} {...this.props} />)}

            </li>)
    }
}

export default DropTarget([ItemTypes.REGISTRATIONFEE, ItemTypes.TRYOUT, ItemTypes.ADJUSTMENT, ItemTypes.ZIPCODE, ItemTypes.FAMILYADJUSTMENT], target, collect)(DivisionListItem);