//membership_programs
function reducer(state = [], action) {

    switch (action.type) {
        case 'LEAGUE/MEMBERSHIP_PROGRAMS/SET':            
            return action.membership_programs;
        default:
            return state;
    }
}

export default reducer;