import React from 'react';
import _ from 'lodash';

class Player extends React.Component {

    // Lifecycle
    componentWillMount() {
        this.setup(this.props);
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps);
    }
    //
    setup = (props) => {
        const { game, player } = props;
        if (player.inactiveGames && _.find(player.inactiveGames.find(ig => ig.IdGame === parseInt(game.IdGame, 10)))) player.IsInactive = true;
        else player.IsInactive = false;
        this.setState({ player });
    }

    onRemoveGuest = () => {
        const { rosterActions, team, game } = this.props, { player } = this.state;
        rosterActions && rosterActions.removeGuestFromGame({ IdUser: player.IdUser, IdGame: game.IdGame, IdTeamDivision: team.IdTeamDivision });
    }

    onToggleActive = () => {
        const { player } = this.state, { rosterActions, team, game } = this.props;
        // IdTeamUserRole, IdTeamDivision, IdGame, IdInactivePlayer
        rosterActions && rosterActions.togglePlayerActiveInGame({
            IdUser: player.IdUser,
            IdTeamDivision: team.IdTeamDivision,
            IdGame: game.IdGame,
            IdInactivePlayer: (_.find(player.inactiveGames, ig => ig.IdGame === parseInt(game.IdGame, 10)) || { IdInactivePlayer: null }).IdInactivePlayer
        });
        player.IsInactive = !player.IsInactive;
        this.setState({ player });
    }

    render() {
        const { player, width = 'w-100' } = this.props;
        return (
            <figure className={`px-2 ${width} h-auto align-self-start`} >
                <div className="card tile shadow-box w3-card d-flex flex-row" style={{ overflow: 'hidden', borderRadius: 10, position: 'relative' }}>

                    <div style={{ position: 'relative', height: 80, width: 100, background: `url(${player.UserImage || player.Image || player.ImageUrl}?full=80) no-repeat center center black` }} className="cover" />

                    <div className="w-100 d-flex flex-row bg-white px-4">
                        <span className="align-self-center font-16">{`${player.NameFirst} ${player.NameLast}`}</span>
                    </div>

                    {!player.IdGuestPlayer && <div style={{ width: 100 }} className="d-flex flex-column justify-content-center" onClick={this.onToggleActive}>
                        <span className="font-8 black align-self-center">{player.IsInactive ? 'Inactive' : 'Active'}</span>
                        <i className={`fas fa-toggle${player.IsInactive ? '-off black' : '-on green'} align-self-center font-20`} />
                    </div>}

                    {player.IdGuestPlayer && <div style={{ width: 100 }} className="d-flex flex-column justify-content-center" onClick={this.onRemoveGuest}>
                        <span className="font-8 black align-self-center">Remove</span>
                        <i className="fas fa-trash red align-self-center font-20" />
                    </div>}

                </div>
            </figure>
        );
    }
}

export default Player;
