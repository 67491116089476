import React from 'react';
import { Popover, PopoverBody, Modal, ModalBody, ModalFooter, Jumbotron, } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { Animated } from 'react-animated-css';
import CustomDatePicker from '../CustomDatePicker';
import moment from 'moment';
import Select from 'react-select';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../config';
import Loader from '../../common/LineLoader';


class DeleteMultipleGames extends React.Component {

    state = {
        saved: false,
        division: {
            value: 0,
            label: 'All divisions'
        },
        location: {
            value: 0,
            label: 'All locations'
        },
        newIdLocation: null,
        selectedDate: moment(),
        selectedTime: null,
        newSelectedDate: moment(),
        gamesDuration: 90,
        minutesToGameDuration: null,
        minutesToGameStart: null,
        games: [],
        allSelected: false,
        datesFilter: true,
        actions: {
            moveDate: false,
            moveLocation: false,
            changeDuration: false,
            changeMinutesToGameDuration: false,
            changeMinutesToGameStart: false,
            keepGameSpacing: true
        },
        locationOptions: []
    }

    // Lifecycle
    componentWillMount() {
        const { games, divisions, locations, selectedDate } = this.props;
        games.forEach(game => {
            game.DivisionName = _.find(divisions, d => {
                return parseInt(d.value, 10) === parseInt(game.IdDivision, 10);
            }).label;
        });
        this.setState({
            selectedDate: selectedDate || (games.length ? moment(_.chain(games).sortBy('GameDate').first().value().GameDate) : moment()),
            games: [...games],
            locationOptions: [{
                label: 'All Locations',
                value: 0
            }, ..._.filter(locations, l => _.find(games, g => parseInt(g.IdLocation, 10) === parseInt(l.value, 10)))]
        });
    }
    componentWillReceiveProps = nextProps => {
        const { games, divisions } = nextProps;
        games.forEach(game => {
            game.DivisionName = _.find(divisions, d => {
                return parseInt(d.value, 10) === parseInt(game.IdDivision, 10);
            }).label;
        });
        this.setState({ games: [...games] });
    }
    //

    divisionChange = (obj) => {
        const { games } = this.state;
        games.forEach(g => {
            g.markedForAction = false;
        });

        const { divisions } = this.props;
        let idDivision = obj ? obj.value : 0;
        this.setState({
            division: _.find(divisions, d => parseInt(d.value, 10) === parseInt(idDivision, 10)),
            games,
            allSelected: false
        });
    }

    locationChange = (obj) => {
        const { games } = this.state;
        games.forEach(g => {
            g.markedForAction = false;
        });
        const { locationOptions } = this.state;
        let idLocation = obj ? obj.value : 0;
        this.setState({
            location: _.find(locationOptions, l => parseInt(l.value, 10) === parseInt(idLocation, 10)),
            games,
            allSelected: false
        });
    }

    newLocationChange = (obj) => {
        const { actions } = this.state;
        actions.moveLocation = true;
        let idLocation = obj ? obj.value : 0;
        this.setState({
            newIdLocation: idLocation,
            actions
        });
    }

    handleChangeSelectedDate = (date) => {
        const { games } = this.state;
        games.forEach(function (g) {
            g.markedForAction = false;
        });
        this.setState({
            selectedDate: moment(date),
            games,
            allSelected: false,
            datesFilter: true
        });
    }

    getFilteredGames = () => {
        const { games } = this.props,
            { selectedDate, location, division, datesFilter } = this.state;

        return _.chain(games)
            .filter((game) => {
                if (datesFilter)
                    return game.GameDate.format('YYYYMMDD') === selectedDate.format('YYYYMMDD');
                return true;
            })
            .filter((game) => {
                if (location.value) {
                    return parseInt(game.Location.IdLocation, 10) === parseInt(location.value, 10);
                }
                return true;
            })
            .filter((game) => {
                if (division.value) {
                    return parseInt(game.IdDivision, 10) === parseInt(division.value, 10);
                }
                return true;
            })
            .orderBy('GameDate')
            .value();
    }

    toggleSelectAll = () => {
        this.setState({
            allSelected: !this.state.allSelected
        }, function () {
            const { allSelected, games, selectedDate, division, location, datesFilter } = this.state;

            _.chain(games)
                .filter((game) => {
                    if (datesFilter)
                        return game.GameDate.format('YYYYMMDD') === selectedDate.format('YYYYMMDD');
                    return true;
                })
                .filter((game) => {
                    if (location.value) {
                        return game.Location.IdLocation === location.value;
                    } else {
                        return true;
                    }
                })
                .filter((game) => {
                    if (division.value) {
                        return game.IdDivision === division.value;
                    } else {
                        return true;
                    }
                })
                .value().forEach(g => {
                    g.markedForAction = allSelected;
                });
            this.setState({ games });
        })
    }

    markGameForAction = (index) => {
        const { games, location, division, selectedDate, datesFilter } = this.state;


        // TODO: change this, this is wrong!
        _.chain(games)
            .filter((game) => {
                if (datesFilter)
                    return game.GameDate.format('YYYYMMDD') === selectedDate.format('YYYYMMDD');
                return true;
            })
            .filter((game) => {
                if (location.value) {
                    return game.Location.IdLocation === location.value;
                } else {
                    return true;
                }
            })
            .filter((game) => {
                if (division.value) {
                    return game.IdDivision === division.value;
                } else {
                    return true;
                }
            })
            .orderBy('GameDate')
            .value()[index].markedForAction = !_.chain(games)
                .filter((game) => {
                    if (datesFilter)
                        return game.GameDate.format('YYYYMMDD') === selectedDate.format('YYYYMMDD');
                    return true;
                })
                .filter((game) => {
                    if (location.value) {
                        return game.Location.IdLocation === location.value;
                    } else {
                        return true;
                    }
                })
                .filter((game) => {
                    if (division.value) {
                        return game.IdDivision === division.value;
                    } else {
                        return true;
                    }
                })
                .orderBy('GameDate')
                .value()[index].markedForAction;
        this.setState({
            games,
            allSelected: _.find(this.getFilteredGames(), function (g) {
                return !g.markedForAction;
            }) ? false : true
        });
    }
    toggleAction = (i) => {
        const { actions } = this.state;
        switch (i) {
            case 1:
                actions.moveDate = !actions.moveDate;
                break;
            case 2:
                actions.moveLocation = !actions.moveLocation;
                break;
            case 3:
                actions.changeDuration = !actions.changeDuration;
                break;
            case 4:
                actions.changeMinutesToGameDuration = !actions.changeMinutesToGameDuration;
                break;
            case 5:
                actions.changeMinutesToGameStart = !actions.changeMinutesToGameStart;
                break;
            case 6:
                actions.keepGameSpacing = !actions.keepGameSpacing;
                break;
            default: break;
        }
        this.setState({ actions });
    }

    deleteAll = (DeletePairings) => {
        const { toggle } = this.props;
        this.setState({ microTransac: true, deleted: false }, () => {
            request.post(`${config.apiEndpoint}/api/v4/games/delete/all/${this.props.idSeason}`)
                .send({ DeletePairings })
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(() => {
                    toggle && toggle();
                    this.props.seasonActions &&
                        this.props.seasonActions.fetchGames(this.props.idSeason);
                });
        });
    }

    save = () => {
        const { games, microTransac } = this.state;
        if (microTransac) return;
        this.setState({ microTransac: true, deleted: false }, () => {
            const payload = { ids: _.chain(games).filter(g => g.markedForAction === true).map(g => g.IdGame).value() };
            request.post(`${config.apiEndpoint}/api/v4/games/delete/bulk`)
                .send(payload)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(data => {
                    //toggle && toggle();
                    this.setState({ microTransac: false, deleted: true });
                    this.props.seasonActions &&
                        this.props.seasonActions.fetchGames(this.props.idSeason);
                });
        });
    }

    toggleTimes = () => this.setState({ isTimesOpen: !this.state.isTimesOpen });
    selectTime = selectedTime => this.setState({ selectedTime, datesFilter: true });
    toggleDeleteAllConfirm = () => this.setState({ deletingAll: !this.state.deletingAll });
    toggleDatesFilter = () => this.setState({ datesFilter: !this.state.datesFilter });

    render() {
        const { isOpen, toggle, divisions } = this.props,
            { division, location, selectedDate, selectedTime,
                games, allSelected, locationOptions, datesFilter, microTransac, deleted, deletingAll } = this.state;

        const highlightWithRanges = [{ "highlighted-date": _.map(games, 'GameDate') }];
        const filteredGames = this.getFilteredGames();

        const TimeFilters = _.chain(filteredGames).map(s => moment(s.GameDate)).uniq(u => u.format('HHmm')).value();

        return <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody className="">
                <div className="d-flex flex-row w-100 pt-2 pb-2 pl-4 pr-4">
                    <i className="fa fa-cogs align-self-center font-16" />
                    <span className="idented-half align-self-center font-16">Adjust Games</span>
                    {!deletingAll && <button id="btnDeleteAll" className="ml-auto btn btn-sm btn-link red align-self-center" onClick={this.toggleDeleteAllConfirm}>Delete ALL games</button>}
                    <i className="fa fa-times black pl-4 align-self-center pointer" onClick={toggle} />
                </div>
            </ModalBody>
            {!deletingAll && <ModalBody>
                {microTransac && <Loader />}
                <div className="d-flex flex-row padding-top-half padding-bottom-half under-ish">

                    <span className="align-self-center margin-left margin-right-half">Division:</span>
                    <Select clearable={false} style={{ width: '180px' }} className={`align-self-center`} options={divisions} value={division.value} name="selectDivision"
                        onChange={this.divisionChange} placeholder="Select a division" />
                    <span className="align-self-center margin-left margin-right-half">Location:</span>
                    <Select clearable={false} style={{ width: '180px' }} className={`align-self-center`} options={locationOptions} value={location.value} name="selectLocation"
                        onChange={this.locationChange} placeholder="Select a location" />

                    <button className="btn btn-link align-self-center idented" onClick={this.toggleDatesFilter}><i className={`fa-${datesFilter ? 'check-square fas' : 'square far'}`} /></button>

                    <div className="align-self-center ">
                        <DatePicker highlightDates={highlightWithRanges} showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.selectedDate}
                            startDate={this.state.selectedDate} onChange={this.handleChangeSelectedDate} />
                    </div>

                    <div className="align-self-center idented-half">
                        {/* Time */}
                        <button id="btn-times-filters" onClick={this.toggleTimes} className="btn btn-info white idented font-10">
                            <i className="fa fa-clock" />
                            <span className="idented-half">{selectedTime ? selectedTime.Time : 'Time'}</span>
                            <i className="fa fa-caret-down idented-half" />
                        </button>
                        <Popover placement="bottom" isOpen={this.state.isTimesOpen} target="btn-times-filters" toggle={this.toggleTimes}>
                            <PopoverBody className="no-margin no-padding" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                {/* All */}
                                <button onClick={() => this.selectTime()} className={`btn btn-link btn-sm btn-block text-left`}>All</button>
                                {TimeFilters && TimeFilters.map((time, i) =>
                                    <button onClick={() => this.selectTime(time)}
                                        className={`btn btn-${((selectedTime && selectedTime === time) ? 'info' : 'link')} btn-sm btn-block text-left`}
                                        key={i}>{time.format('H:mmA')}</button>)}
                            </PopoverBody>
                        </Popover>

                    </div>

                </div>
                <div style={{ height: '300px', overflowY: 'auto' }}>
                    {filteredGames.length > 0 && <table className="table table-sm font-8 centered">
                        <thead>
                            <tr className="bg-info">
                                <th>
                                    <button className="btn btn-sm btn-info"
                                        onClick={this.toggleSelectAll}><i className={`fa-${allSelected ? 'check-square fas' : 'square far'}`} /></button>
                                </th>
                                <th>ID</th>
                                <th>When</th>
                                <th>Division</th>
                                <th>Flight</th>
                                <th className="text-center">Teams</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredGames.map((game, i) => (
                                <tr key={i}>
                                    <td>
                                        <button className="btn btn-sm" onClick={() => this.markGameForAction(i)}>
                                            <i className={`fa-${game.markedForAction ? 'check-square fas' : 'square far'}`} /></button>
                                    </td>
                                    <td>
                                        {game.IdGame}
                                    </td>
                                    <td>
                                        <div>
                                            {game.GameDate.format('MMM Do YYYY @ h:mmA')}
                                        </div>
                                        <div>({game.GameDurationMinutes} minutes)</div>
                                    </td>
                                    <td>
                                        {game.DivisionName}
                                    </td>
                                    <td>
                                        {game.FlightName}
                                    </td>
                                    <td className="text-center">
                                        {[game.HomeTeam.Name || game.HomeTeam.TeamName, game.AwayTeam.Name || game.AwayTeam.TeamName].join(' VS ')}
                                    </td>
                                    <td>
                                        {game.Location.LocationName || game.Location.Name || game.LocationName}
                                    </td>
                                </tr>))}
                        </tbody>
                    </table>}
                    {filteredGames.length === 0 && (
                        <div className="jumbotron text-center margin-top margin-left margin-right">
                            <h1 className="display-3">No games found!</h1>
                            <p>
                                {['on', selectedDate.format('MMM Do YYYY'), 'for', division.label, 'at', location.label].join(' ')}
                            </p>
                        </div>)}
                </div>
            </ModalBody>}
            {deletingAll && microTransac && <ModalBody>
                {microTransac && <Loader />}
                <Jumbotron className="m-0">
                    <h1>Deleting all season games.</h1>
                    <h4>Please wait....</h4>
                </Jumbotron>
            </ModalBody>}
            {deletingAll && !microTransac && <ModalBody>
                <Jumbotron className="d-flex flex-row justify-content-center m-0">

                    <div className="tile bg-white card shadow-box mx-2 align-self-center" style={{ borderRadius: 6, height: 150, width: 150 }}>
                        <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                            <button onClick={() => this.deleteAll(true)} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                <i className="fas fa-cogs align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                <span className=" align-self-center margin-top-half">Pairings and<br />Games</span>
                            </button>
                        </Animated>
                    </div>

                    <div className="tile bg-white card shadow-box mx-2 align-self-center" style={{ borderRadius: 6, height: 150, width: 150 }}>
                        <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                            <button onClick={() => this.deleteAll(false)} className="jeff_blue align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                <i className="fa fa-calendar fas align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                <span className=" align-self-center margin-top-half line1">Just Games</span>
                            </button>
                        </Animated>
                    </div>

                    <div className="tile bg-danger white card shadow-box mx-2 align-self-center" style={{ borderRadius: 6, height: 150, width: 150 }}>
                        <Animated animationIn="bounceIn" animationInDelay={150} className="w-100 h-100">
                            <button onClick={() => this.setState({ deletingAll: false })} className="align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                                <i className="white fa fa-times fas align-self-center bigest-font" style={{ fontSize: '7em', lineHeight: '64px' }} />
                                <span className="white align-self-center margin-top-half">No, cancel</span>
                            </button>
                        </Animated>
                    </div>

                </Jumbotron>
            </ModalBody>}
            {deleted && <ModalBody className="bg-danger text-center white">Deleted!</ModalBody>}
            {!deletingAll && <ModalFooter className="d-flex flex-row">
                <button className="btn btn-sm btn-danger" onClick={this.save}>Delete selected
                    {' '}
                    {_.filter(filteredGames, function (g) {
                        return g.markedForAction
                    }).length || 'zero'} game(s) </button>
            </ModalFooter>}
        </Modal>
    }
}

export default DeleteMultipleGames;