import React from 'react';

const Comp = (props) => {
    return (
      <div className="d-flex justify-content-center bg-success p-2">
       <span className="white align-self-center block text-center">{props.message}</span>
      </div>
      );
  
};

export default Comp;