import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

class RegistrationTeamSelection extends React.Component {

  state = {
    step: 1,
    bootstrapped: true,
    alreadyRegister: [],
    selectedTeam: null,
    selectedUser: null,
    searchTerm: null
  }

  onClose = () => {
    this.setState({ bootstrapped: false }, () => {
      const { toggle } = this.props;
      setTimeout(() => { toggle && toggle() }, 500);
    });
  }

  selectTeam = (team) => {
    const { teamRegistration, season } = this.props;
    this.props.fnOk && this.props.fnOk({
      selectedTeam: team,
      selectedSeason: this.props.season,
      selectedLeague: this.props.league,
      selectedDivision: _.find(teamRegistration, c => c.IdTeam === team.IdTeam && c.IdSeason === season.IdSeason)
    });
  }

  filter = (e) => this.setState({ searchTerm: e.target.value });

  render() {
    const { teams, season, teamRegistration } = this.props,
      { bootstrapped, searchTerm } = this.state;

    let registeredTeams = _.values(_.groupBy(
      _.chain(teams)
        .filter(team => {
          return _.find(teamRegistration, c => c.IdTeam === team.IdTeam && c.IdSeason === season.IdSeason)
        })
        .filter(team => {
          if (searchTerm) return (team.Name || team.TeamName).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
          return true;
        })
        .sortBy('IdTeam').value().reverse(), t => t.IdTeam));

    return (

      <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
        <div className="dark-overlay" onClick={this.onClose} />
        
        <Animated style={{ width: 400, position: 'absolute', top: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray d-flex flex-column montserrat"
          isVisible={bootstrapped} animationOut="slideOutRight" animationIn='slideInRight' animationInDelay={200} animationInDuration={500} animationOutDuration={500}>

          <div style={{ height: 50 }} className="w-100 p-2 d-flex flex-row">
            <i className="icon-arrow-left jeff_blue font-20 align-self-center" onClick={this.onBack} />
            <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Registered Teams</span>
            <i className="shopping_cart white-ish font-24 ml-auto align-self-center" />
          </div>

          <div className="d-flex flex-column dp-2 h-100 ">
            <div className="h-100 pl-4 pr-4">
              <div className="card tile shadow-box form-group no-margin h-auto w-100">
                <input defaultValue={searchTerm} onChange={this.filter} type="text" className="form-control" style={{ borderRadius: 0 }} placeholder="Search team..." ref={(i) => this.txtSearch = i} />
              </div>

              <div className="d-flex flex-wrap justify-content-center mt-4">
                {registeredTeams && registeredTeams.reverse().map(([team], i) => (
                  <div onClick={() => this.selectTeam(team)} style={{ width: 152, height: 150 }} className="m-2 align-self-center card tile shadow-box d-flex flex-column justify-content-center" key={i} >
                    <img alt="" src={team.TeamImageUrl || team.ImageUrl} className="align-self-center" style={{ width: 50 }} />
                    <span className="align-self-center font-8 idented margin-right text-center mt-2 line1">{team.Name || team.TeamName}</span>
                    <span className="green align-self-center font-8 line1">Registered</span>
                  </div>))}
              </div>

              <div style={{ height: 100 }} />

            </div>
          </div>
        </Animated>
      </section>
    )
  }
}

export default RegistrationTeamSelection;
