import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as teamActions from '../redux-sagas/actions/teamActions';
import * as cartActions from '../redux-sagas/actions/cartActions';
import * as rosterActions from '../redux-sagas/actions/rosterActions';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import Main from '../components/Main.User';

function mapStateToProps(state) {
    return {
        /*loggedIn: state.loggedIn,
        auth: state.auth,
        title: state.title,
        teams: state.teams,
        archived_teams: state.archived_teams,
        family: state.family,
        division: state.division,
        roster: state.roster,
        disclaimer: state.disclaimer,
        permissions: state.permissions,
        cart: state.cart,
        selectedTeam: state.selectedTeam,
        selectedUser: state.selectedUser,
        isCartOpen: state.isCartOpen,
        spotlight: null,
        limits: state.limits,
        roles: state.roles,
        micro: state.micro,
        validations_summary: state.validations_summary,
        user: state.user,
        notes: state.notes,
        college_profile: state.college_profile,
        information: state.information,
        registrations: state.registrations,
        family_subscriptions: state.family_subscriptions,
        afp: state.afp*/
        ...state
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        seasonActions: bindActionCreators(seasonActions, dispatch),
        familyActions: bindActionCreators(familyActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch),
        leagueActions: bindActionCreators(leagueActions, dispatch),
        cartActions: bindActionCreators(cartActions, dispatch)
    }, bindActionCreators(actionCreators, dispatch))
}

const App_User = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App_User;
