function reducer(state = [], action) {

  switch (action.type) {
    case 'POSITIONS/SET':
      return action.positions;
    case 'POSITIONS/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;