import React from 'react';

class PlayerSquare extends React.Component {

  state = {
    player: null
  }
  componentWillMount() {
    this.setState({ player: this.props.player });
  }
  errorImage = () => {
    const { player } = this.state;
    player.ImageUrl = '/images/defaults/' + (player.Gender.IdGender === 2 ? 'female' : 'male') + '.png';
    this.setState({
      player
    });
  }
  render() {
    const { player } = this.state;
    return (

      <div className={`col-4 no-padding p-1`} style={{ height: 60, position: 'relative' }}>
        <div className={`${player.Number === '.' ? 'hidden-content' : null}`} style={{ border: '1px solid black', borderRadius: 5, position: 'relative', overflow: 'hidden', height: 55 }}>
          <div className="cover" style={{ position: 'absolute', left: 0, width: 60, height: 60, background: 'url(' + (player.ImageUrl || '/images/defaults/male.png') + ') no-repeat center center', }}></div>
          <div className="p-2 font-7" style={{ position: 'absolute', right: 0, left: 60, top: 0, bottom: 0 }}>
            <div style={{ lineHeight: 1 }}>
              {player.Name}
            </div>
            <div className="blue" style={{ position: 'absolute', bottom: 1, right: 5 }}>
              {player.IdUser}
            </div>
          </div>
          <img alt="" style={{ width: 1, height: 1 }} src={player.ImageUrl || '/images/defaults/male.png'} onError={this.errorImage} />
        </div>
      </div>
    )
  }
}

export default PlayerSquare;