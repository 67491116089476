import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../../common/CustomDatePicker';
import request from 'superagent';
import config from '../../../../../../config';


class Comp extends React.Component {

  state = {
    saving: false,
    isOpenFrom: false,
    isOpenTo: false,
    from: moment(),
    to: moment().add(1, 'day')
  }

  // Lifecycle
  componentWillMount() {
    if (this.props.generic) {
      const { generic } = this.props;
      this.setState({
        from: generic.start,
        to: generic.end
      });
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.generic) {
      const { generic } = nextProps;
      this.setState({
        from: generic.start,
        to: generic.end
      });
    }
  }
  //

  toggleCalendarFrom = () => {
    this.setState({
      isOpenFrom: !this.state.isOpenFrom
    })
  }
  toggleCalendarTo = () => {
    this.setState({
      isOpenTo: !this.state.isOpenTo
    });
  }

  handleChangeFrom = (date) => {
    const { to } = this.state;
    this.setState({
      from: moment(date),
      to: moment(date).isAfter(moment(to)) ? moment(date).add(1, 'day') : moment(to) // range validation
    });
  }
  handleChangeTo = (date) => {
    const { from } = this.state;
    this.setState({
      to: moment(date),
      from: moment(date).isBefore(moment(from)) ? moment(date).add(-1, 'day') : moment(from) // range validation
    });
  }

  handleChangeBase = (e) => {
    const { generic } = this.props;
    generic.base = (e.target.value && parseFloat(e.target.value) > 0) ? parseFloat(e.target.value) : 0;
    this.props.setGeneric && this.props.setGeneric(generic);
    this.forceUpdate(); // If I don't do this, the change wont reflect :(
  }

  save = () => {
    const { season, generic } = this.props, { saving, from, to } = this.state;
    if (saving) return;

    this.setState({ saving: true }, () => {
      request[generic.id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/paymentPlans/tryout${generic.id ? '/' + generic.id : ''}`)
        .send({
          name: this.txtName.value,
          from, to, idSeason: season.IdSeason
        })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then(resolve => {
          if (resolve.body.success) {
            this.props.paymentsActions &&
              this.props.paymentsActions.fetchPrograms(season.IdSeason);
            this.props.toggle && this.props.toggle();
          }
        }, reject => {
          this.setState({ saving: false });
        });
    });
  }

  render() {
    const { isOpen, toggle, generic } = this.props,
      { from, to, saving } = this.state;

    return <Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
      <ModalHeader className="bg-danger white">New Tryout Program</ModalHeader>
      <ModalBody className="container-fluid p-2">
        {generic && (
          <div className="row">
            { /* Name and Dates */}
            <div className="col-12 d-flex flex-row">
              <input className="form-control align-self-center" type="text" placeholder="Program Name" defaultValue={generic.name} ref={(i) => this.txtName = i} />
            </div>
            <div className="col-12 d-flex flex-row justify-content-center margin-top-half">
              <span className="idented-half align-self-center margin-right-half">Effective from</span>
              <div className="align-self-center">
                <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={from} selectsStart
                  startDate={from} endDate={to} onChange={this.handleChangeFrom} />
              </div>
              <span className="idented-half align-self-center margin-right-half">@ 12:01am EST</span>
            </div>
            <div className="col-12 d-flex flex-row justify-content-center margin-top-half">
              <span className=" idented-half align-self-center margin-right-half">to</span>
              <div className="align-self-center">
                <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={to}
                  selectsEnd startDate={from} endDate={to} onChange={this.handleChangeTo} />
              </div>
              <span className="idented-half align-self-center margin-right-half">@ 11:59pm EST</span>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {!saving && <button className="btn btn-link" onClick={toggle}>Cancel</button>}
        <button className="btn btn-success" onClick={this.save}>{saving ? 'Saving, please wait...' : 'Save program'}</button>
      </ModalFooter>
    </Modal>
  }
}

Comp.defaultProps = {
  generic: {
    start: moment(),
    end: moment().add(1, 'month'),
    base: 0,
    fee: 0,
    initial: 0,
    late: 0,
    usePP: false
  },
}

export default Comp;