import React from 'react';
import TeamItem from './cart_items/Team';
import UserItem from './cart_items/User';
import CoachItem from './cart_items/Coach';
import _ from 'lodash';
import { formatPrice } from '../../../helpers';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import ScoutItem from './cart_items/Scout';

class CartPopup extends React.Component {

  state = { bootstrapped: true }    

  onClose = () => {
    const { toggle } = this.props;
    this.setState({ bootstrapped: false }, () => {
      setTimeout(() => { toggle && toggle() }, 350);
    });
  }

  deleteItem = (id) => {
    this.props.cartActions && this.props.cartActions.removeItem(id);
  }

  render() {
    const { cart, league } = this.props, { bootstrapped } = this.state;

    const filteredCart = _.filter(cart, (item) => {
      return item.selectedLeague && item.selectedLeague.IdLeague === league.IdLeague;
    });

    let total = 0;
    filteredCart && filteredCart.forEach(item => {
      if (item.selectedDivision && (item.selectedDivision.IsDivisionTryout || item.selectedDivision.IsDivisionWaitlisted)) total += 0;
      else
        total += parseInt(((item.selectedPlan && item.selectedPlan.Dues.length) ? item.selectedPlan.Dues[0].Total : 0), 10) +
          _.reduce(_.map(item.questions, function (q) {
            return (q.answer && q.answer.Amount) ? q.answer.Amount : 0;
          }), function (memo, num) {
            return memo + num;
          }, 0);
    });

    return (
      <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
        
        <div className="dark-overlay" onClick={this.onClose} />
        
        <Animated style={{ position: 'absolute', top: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray montserrat right-menu"
          isVisible={bootstrapped} animationOut="slideOutRight" animationIn='slideInRight' animationInDelay={200} animationInDuration={350} animationOutDuration={350}>

          <div style={{ height: 50 }} className="w-100 p-2 d-flex flex-row">
            <i className="fa fa-times jeff_blue font-20 align-self-center" onClick={this.onClose} />
            <span className="jeff_blue ml-auto font-16 sqwad-font toUpper align-self-center">Shopping Cart</span>
            <i className="shopping_cart white-ish font-24 ml-auto align-self-center" />
          </div>

          <div className="dp-2 h-100 p-4">

            {filteredCart && filteredCart.map((item, i) => (
              <div key={i} className="card tile shadow-box w-100 mb-4 mx-0">
                {item.selectedTeam && <TeamItem item={item} fnDelete={this.deleteItem} />}
                {!item.selectedTeam && item.mode === 1 && <UserItem item={item} fnDelete={this.deleteItem} />}
                {!item.selectedTeam && item.mode === 2 && <CoachItem item={item} fnDelete={this.deleteItem} />}
                {!item.selectedTeam && item.mode === 5 && <ScoutItem item={item} fnDelete={this.deleteItem} />}
              </div>)
            )}
            {(!filteredCart || !filteredCart.length > 0) && (
              <div style={{ height: 400 }} className="d-flex flex-column p-4 justify-content-center">
                <img alt="" src="/images/defaults/red_card.png" style={{ width: 100 }} className="align-self-center" />
                <span className="align-self-center">Your cart seems to be empty</span>
              </div>
            )}

            <hr className="no-margin no-padding" />
            <div className="">
              <div className="d-flex flex-row margin-top-half">
                <span>Subtotal</span><span className="ml-auto ">{formatPrice(total * 100)}</span>
              </div>
              <div className="d-flex flex-row">
                <span>Taxes</span><span className="ml-auto ">{formatPrice(0)}</span>
              </div>
              <div className="d-flex flex-row big-font">
                <span>Total</span><span className="ml-auto ">{formatPrice(total * 100)}</span>
              </div>
            </div>

            {!(!filteredCart || !filteredCart.length > 0) && <Link to={`/registration/checkout/${league.IdLeague}`} className={`btn btn-block btn-${(!filteredCart || !filteredCart.length > 0) ? 'link' : 'success'}`}>Checkout</Link>}
            <div style={{ height: 100 }} />
          </div>

        </Animated>
      </section>
    )
  }
}

export default CartPopup;
