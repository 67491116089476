import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Sheet3v3 from '../../seasons/manage_schedule/game_cards/Sheet3v3';
import FullSheet from '../../seasons/manage_schedule/game_cards/Sheetx1';

class GameCard extends React.Component {

    state = {
        game: null,
        mode: 2
    }

    componentWillMount() {
        const { idGame, idSeason, gamesActions } = this.props;
        // TODO: should look for a suitable game feature
        gamesActions && gamesActions.fetchGameDetails(idGame, idSeason);

    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.game && !this.state.game) {
            this.setState({ game: nextProps.game });
        }
    }
    //

    toggleMode = mode => this.setState({ mode });

    render() {
        const { isOpen, toggle } = this.props, { game, mode } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: 923 }} >
                <ModalHeader toggle={toggle}>
                    Game Card
                </ModalHeader>
                <ModalBody id="printarea">
                    {game && mode === 1 && <Sheet3v3 game={game} community={1} rosterCap={6} />}
                    {game && mode === 2 && <FullSheet game={game} community={1} />}
                </ModalBody>
                <ModalFooter className="d-flex flex-row">
                    <button className="btn mr-auto btn-link" onClick={() => window.print()}><i className="fas fa-print" />{' '}Print</button>
                    <button className={`btn btn-${mode === 2 ? 'info' : 'link'}`} onClick={() => this.toggleMode(2)}>Full</button>
                    <button className={`btn btn-${mode === 1 ? 'info' : 'link'}`} onClick={() => this.toggleMode(1)}>3v3</button>
                </ModalFooter>

            </Modal>
        )
    }
}

export default GameCard;