import React from 'react';
import './menu.css';
import { Link } from 'react-router';
import _ from 'lodash';

class SQWAD_Team_Menu extends React.Component {

    state = {
        tree: null,
        lastPath: ''
    }

    render() {
        const { routes = [], selectedTeam = {}, params, /*family_lastname*/ } = this.props;        

        // This routine will get the leaf of the url: 
        // Example: api/v4/seasons/bola => bola
        let leafPath = _.last(_.chain(routes).filter(route => {
            return route.path;
        }).last().value().path.split('/'));

        return (<section id="left-menu" className="bg-dark-blue">

            <div className="d-flex flex-column ">
                <div style={{ height: 30 }} />
                <div style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid black', background: `url("https://api.sportslogic.net/api/v4/teams/image/decode/${params.idTeam}.png") no-repeat center center`, backgroundSize: 'cover' }} className="align-self-center"></div>
                {selectedTeam && <span className="white font-14 align-self-center text-center ml-2 mr-2">{selectedTeam.Name || selectedTeam.TeamName}</span>}
                <div style={{ height: 30 }} />
            </div>

            <div className={`menu-wrapper`}>
                <Link key={1} className={`btn d-flex flex-row drop btn-off btn-block`} to={`/roster/${params.idTeam}`} activeClassName="btn-on" onlyActiveOnIndex={true}>
                    <i className={`white align-self-center big-font icon fa fa-arrow-left`} />
                    <span className="white align-self-center font-8 toUpper name">Seasons</span>
                </Link>
            </div>

            {(selectedTeam.IsTeamAdmin || selectedTeam.IsTeamOwner) && <div className={`menu-wrapper`}>
                <Link key={2} className={`btn d-flex flex-row drop ${leafPath === 'dashboard' ? 'btn-on' : 'btn-off'} btn-block`} to={`/videos/team/${this.props.params.idTeam}/dashboard`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon fa fa-chart-pie`} />
                    <span className="white align-self-center font-8 toUpper name">Dashboard</span>
                </Link>
            </div>}

            <div className={`menu-wrapper`}>
                <Link key={3} className={`btn d-flex flex-row drop ${leafPath === 'videos' ? 'btn-on' : 'btn-off'} btn-block`} to={`/videos/team/${this.props.params.idTeam}/videos`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon icon-RetroVideoRecorder_icon`} />
                    <span className="white align-self-center font-8 toUpper name">Videos</span>
                </Link>
            </div>

            {(selectedTeam.IsTeamAdmin || selectedTeam.IsTeamOwner) && <div className={`menu-wrapper ${false ? 'on' : 'off'}`}>
                <Link key={5} className={`btn d-flex flex-row drop btn-off btn-block`} to={`/videos/team/${this.props.params.idTeam}/brochure`} activeClassName="btn-on" onlyActiveOnIndex={true} >
                    <i className={`white align-self-center big-font icon icon-book-open`} />
                    <span className="white align-self-center font-8 toUpper name">Team Brochure</span>
                </Link>
            </div>}

        </section>)
    }
}

export default SQWAD_Team_Menu;
