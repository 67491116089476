import React from 'react';
import './loader.css';
import { Animated } from 'react-animated-css';

const Loader = ({ className, message, textColor }) => {
  return (
    <Animated animationIn="pulse" className={`d-flex justify-content-center centered-message ${className}`}>
      <div className="align-self-center d-flex flex-row">
        <span className={`${textColor || className || 'black'} align-self-center font-16`} style={{ marginRight: '1.3em' }}>{message}</span>
        <div className="loader align-self-center">
          <span className="dot dot_1"></span>
          <span className="dot dot_2"></span>
          <span className="dot dot_3"></span>
          <span className="dot dot_4"></span>
        </div>
      </div>
    </Animated>
  );

};

export default Loader;
