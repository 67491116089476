function reducer(state = [], action) {
  switch (action.type) {
    case 'GENERIC/SET':
      return action.generic;
    case 'GENERIC/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;
