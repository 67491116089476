function reducer(state = [], action) {
    switch (action.type) {
        case 'CAMPAIGNS/ADD/LAZY':        
            return [...state, {...action.campaign}];
        case 'CAMPAIGNS/SET':
            return action.campaigns;        
        default:
            return state;
    }
}

export default reducer;
