import React from 'react';
import { Animated } from 'react-animated-css';
import Entity from './partials/CollegeProfile.js';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import _ from 'lodash';
import Wizzard from './Wizzard.js';
import { getMaskedValue } from '../../helpers.js';
import config from '../../config.js';

class CollegeProfile extends React.Component {

    state = {
        bootstrapped: false,
        modal: false,
        selectedUser: null,
        college_profile: null
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, selectedTeam, family_lastname } = this.props;

        setTitle && setTitle({
            main: 'College Profile',
            sub: (selectedTeam && selectedTeam.Name) ? selectedTeam.Name : `${family_lastname}'s`
        });

        this.props.fetchCountries && this.props.fetchCountries();
        this.props.fetchCommunities && this.props.fetchCommunities();
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.family && nextProps.communities) {
            this.setState({ bootstrapped: true });
        }
    }
    //    

    toggleModal = () => {
        this.setState({ modal: !this.state.modal }, () => {
            if (!this.state.modal) this.setState({ selectedUser: null });
        });
    }

    unselectUser = () => this.setState({ selectedUser: null });

    onSaveProfile = () => {
        /*const { roster_actions } = this.props,
            { selectedUser } = this.state;

        roster_actions.updateCollegeProfile({
            IdCollegeProfile: selectedUser.IdCollegeProfile,
            IdUser: selectedUser.IdUser,
            GraduationYear: this.txtGraduationYear.value,
            SchoolName: this.txtSchoolName.value,
            GPA: this.txtGPA.value,
            SAT: this.txtSAT.value,
            ACT: this.txtACT.value,
            Notes: this.txtNotes.value
        });*/
        this.setState({ selectedUser: null });
    }
    doUpdate = (selectedUser) => {
        const { roster_actions } = this.props;
        roster_actions.updateCollegeProfile(_.pick(selectedUser,
            'IdCollegeProfile',
            'GraduationYear',
            'SchoolName',
            'GPA',
            'SAT',
            'ACT',
            'Notes',
            'ClubName',
            'PreferredJerseyNumber',
            'PreferredPosition',
            'IsPublic',
            'IsActive',
            'IsLookingForTransfer',
            'Height',
            'HeightUnit',
            'Nationality1',
            'Nationality2',
            'sports',
            'CommitedTo',
            'IsCommited',
            'InstitutionImage',
            'doUpdateImage',
            'Weight',
            'WeightUnit',
            'EducationalInterestPrimary',
            'EducationalInterestSecondary',
            'IdUser',
        ));
    }
    onActivate = () => {
        const { selectedUser } = this.state;
        selectedUser.IsActive = !selectedUser.IsActive;
        this.setState({ selectedUser, activating: false }, () => {
            this.doUpdate(selectedUser);
        });

        /*
        if (!profile.IsActive) {

            // If there's a family plan already happening, just toggle things up: 
            if (current_family_plan && false) {
                doActivate();
            } else {
                NAVIGATION.goTo('family/activate_profile', {
                    animated: true, fnActivate: doActivate
                });
            }
        } else {
            profile.IsActive = false;
            $.lbl_active.text = `Inactive ${CONFIG.keys.circle}`;
            $.lbl_active.color = COLORS.red;
            $.sw_active.value = false;
            call_api_to_update();
        }*/
    }

    selectNationality1 = (c) => {
        const { selectedUser } = this.state;
        selectedUser.Nationality1 = c.CountryName || c.Name;
        this.setState({ selectedUser });
    }
    selectNationality2 = (c) => {
        const { selectedUser } = this.state;
        selectedUser.Nationality2 = c.CountryName || c.Name;
        this.setState({ selectedUser });
    }

    onAddSport = () => { }

    render() {
        const { family, countries, communities = [] } = this.props, { bootstrapped, selectedUser, modal, adding_sport } = this.state;

        // To set US and Canada first on the Countries selection
        const splitCountries = _.partition(countries, c => {
            return c.ISOCode === 'US' || c.ISOCode === 'CA' || c.ISOCode === 'UK';
        });

        let img_nat_1 = null, img_nat_2 = null;
        if (selectedUser) {
            if (selectedUser.Nationality1) {
                img_nat_1 = (_.find(countries, c => c.CountryName === selectedUser.Nationality1) || {}).image;
            }
            if (selectedUser.Nationality2) {
                img_nat_2 = (_.find(countries, c => c.CountryName === selectedUser.Nationality2) || {}).image;
            }
        }

        return (
            <section>
                {bootstrapped && family && family.length > 0 && <Animated animationIn="fadeIn" className="container margin-bottom">
                    <ul id="roster" className="row" style={{ paddingTop: '3em' }}>
                        {family && family.map && family.map((entity, i) => (
                            <li key={entity.IdUser || i} className={` col-sm-12 col-md-6 col-lg-4 hoverable pointer mt-4`} style={{ flexBasis: 'auto' }}>
                                <Entity entity={entity} index={i}
                                    onClick={({ player, entity }) => this.setState({ selectedUser: { ...player, ...entity }, modal: true, activating: false }, () => {

                                    })} />
                            </li>))}
                    </ul>
                </Animated>}

                {/* OVERLAY */}
                {modal && <div onClick={() => this.setState({ selectedUser: null, modal: false })} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'black', opacity: 0.5 }} />}

                {/* SELECTED USER VIEW */}
                <Animated animationInDuration={200} animationOutDuration={200} className="bg-blue-dark" animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" isVisible={modal ? true : false} style={{ position: 'fixed', top: 0, right: 0, bottom: 0, width: 400, overflowY: 'auto', borderLeft: '1px solid #e5e5e5' }}>
                    {selectedUser && bootstrapped && <section className="flex-column justify-content-start">

                        <div className="w-100 d-flex flex-column">
                            <button className="btn btn-link white ml-auto mr-2 mt-2 underlined" onClick={() => this.setState({ college_profile: selectedUser })}>Edit</button>
                            <div style={{ height: 30 }} />
                            <div className="cover mt-2 align-self-center" style={{ border: `1px solid white`, width: 160, height: 160, borderRadius: '50%', background: `url(${(selectedUser || {}).UserImage}) no-repeat center center black` }}></div>
                            <h4 className="white align-self-center text-center mt-2">{selectedUser.NameLast}</h4>
                            <h6 className="white align-self-center text-center">{selectedUser.NameFirst}</h6>
                            <div style={{ height: 20 }} />
                            <div className="align-self-center d-flex justify-content-center"><span className="white font-14 align-self-center">Active Profile</span><i onClick={() => {
                                const { selectedUser } = this.state;
                                if (!selectedUser.sports.length) return;
                                if (selectedUser.IsActive) this.onActivate();
                                else this.setState({ activating: true });
                            }} className={`pointer align-self-center font-16 ml-2 ms-2 fas fa-toggle-${selectedUser.IsActive ? 'on green' : 'off white'}`} /></div>
                            <div style={{ height: 25 }} />
                        </div>

                        {!selectedUser.sports.length && <div className="bg-warning px-3 py-2 d-flex flex-row mb-4">
                            <i className="fas fa-warning mr-3 align-self-center font-30" /><span className="align-self-center">Remember to add at least one (1) sport to your profile so coaches|recruiters can find you</span></div>}

                        {/* Commitment */}
                        {selectedUser.CommitedTo && <div className="card tile bg-white p-3 mx-4 mb-4 d-flex flex-row">
                            <div className="align-self-center">
                                <div className="font-10">{selectedUser.IsCommited ? 'Commited' : (selectedUser.IsLookingForTransfer ? 'Looking for Transfer' : 'Club')}</div>
                                <div className="font-14 montserrat">{selectedUser.CommitedTo}</div>
                            </div>
                            <div className="align-self-center ml-auto cover" style={{ width: 50, height: 50, background: `url(${(selectedUser || {}).InstitutionImage}) no-repeat center center white` }} ></div>
                        </div>}

                        {/* Sports */}
                        {selectedUser.sports.map((sport, index) => {

                            let sport_name = _.find(this.props.communities || [], c => c.IdCommunity === sport.IdCommunity);
                            let height = selectedUser.Height ? getMaskedValue(selectedUser.Height,
                                _.find(config.units, u => u.value === parseInt(selectedUser.HeightUnit || 1, 10)).mask) : 'N/A';
                            let weight = selectedUser.Weight ? `${selectedUser.Weight}${_.find(config.weight_units, u => u.value === parseInt(selectedUser.WeightUnit || 1, 10)).ext}` : 'N/A';

                            return <div key={index} className="card tile bg-white mx-4 mb-4">
                                <span className="montserrat-bold font-14 px-3 py-2">{sport_name.Name || sport_name.CommunityName}</span>
                                <hr className='p-0 m-0' />
                                <div className="mx-3 my-2">
                                    <div className="d-flex flex-row">
                                        <div className="d-flex flex-column w-100">
                                            <span className="montserrat-bold font-8">Primary Position</span>
                                            <span className="montserrat font-12 mb-4">{sport.Position1 || 'N/A'}</span>
                                            <span className="montserrat-bold font-8">Secondary Position</span>
                                            <span className="montserrat font-12 mb-4">{sport.Position2 || 'N/A'}</span>
                                            <span className="montserrat-bold font-8">Highest Level Played</span>
                                            <span className="montserrat font-12 mb-4">{sport.LevelPlayed}</span>
                                            <span className="montserrat-bold font-8">Height</span>
                                            <span className="montserrat font-12 mb-4">{height}</span>
                                        </div>
                                        <div className="d-flex flex-column w-50">
                                            <div className="text-center" style={{ position: 'relative' }}>
                                                <i className="icon-apparel text-center blue font-60 mt-1 mb-4"></i>
                                                <span style={{ position: 'absolute', top: 15, left: 0, right: 0 }} className="white font-28 text-center">{sport.JerseyNumber}</span>
                                            </div>
                                            <span className="montserrat-bold font-8 text-center mt-4">Foot</span>
                                            <span className="montserrat font-12 mb-4 text-center">{sport.DominantFoot}</span>
                                            <span className="montserrat-bold font-8 text-center">Weight</span>
                                            <span className="montserrat font-12 mb-4 text-center">{weight}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}

                        {/* Educational */}
                        <div className="card tile bg-white p-3 mx-4 d-flex flex-row mb-4">
                            <div className="d-flex flex-column w-100">
                                <span className="montserrat-bold font-8">Graduation Year</span>
                                <span className="montserrat font-12 mb-4">{selectedUser.GraduationYear}</span>
                                <span className="montserrat-bold font-8">School Name</span>
                                <span className="montserrat font-12 mb-4">{selectedUser.SchoolName}</span>
                                <span className="montserrat-bold font-8">Club Name</span>
                                <span className="montserrat font-12 mb-4">{selectedUser.Club || selectedUser.ClubName}</span>
                                <span className="montserrat-bold font-8">Nationalities</span>
                                <span className="montserrat font-12 mb-4">
                                    <img src={img_nat_1} style={{ height: 30 }} />
                                    <img src={img_nat_2} style={{ height: 30 }} />
                                </span>
                            </div>
                            <div className="d-flex flex-column w-50">
                                <span className="montserrat-bold font-8">GPA</span>
                                <span className="montserrat font-12 mb-4">{selectedUser.GPA}</span>
                                <span className="montserrat-bold font-8">SAT</span>
                                <span className="montserrat font-12 mb-4">{selectedUser.SAT}</span>
                                <span className="montserrat-bold font-8">ACT</span>
                                <span className="montserrat font-12 mb-4">{selectedUser.ACT}</span>
                            </div>
                        </div>

                        <div className="card tile bg-white p-3 mx-4 mb-4 d-flex flex-row">
                            <div className="d-flex flex-column w-100">
                                <span className="montserrat-bold font-8">Educational Interests</span>
                                <span className="montserrat font-12 mb-2">{(selectedUser.EducationalInterestPrimary || selectedUser.EducationalInterestSecondary) ? _.compact([selectedUser.EducationalInterestPrimary, selectedUser.EducationalInterestSecondary]).join(', ') : 'N/a'}</span>
                            </div>
                        </div>

                        <div className="card tile bg-white p-3 mx-4 d-flex flex-row">
                            <div className="d-flex flex-column w-100">
                                <span className="montserrat-bold font-8">Notes</span>
                                <span className="montserrat font-12 mb-2">{selectedUser.Notes}</span>
                            </div>
                        </div>
                        <div style={{ height: 100 }} />

                    </section>}
                </Animated>

                {/* ACTIVATING */}
                <Animated animationInDuration={200} animationOutDuration={200} className="bg-whiteish" animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" isVisible={(this.state.activating && modal) ? true : false} style={{ position: 'fixed', top: 0, right: 0, bottom: 0, width: 400, overflowY: 'auto', borderLeft: '1px solid #e5e5e5' }}>
                    <div className="d-flex flex-column">
                        <div style={{ height: 80 }} />
                        <i className="fas fa-search font-60 black align-self-center" />
                        <h2 className="text-center">Activate Profile</h2>
                        <p className='text-center mx-4 px-4'>Activating your profile will add you to the SQWAD RC directory for Recruiters, Scouts and Coaches. By selecting Activate, you're giving consent to be on this list</p>
                        <button className="mx-4 btn mt-4 btn-success" onClick={this.onActivate} style={{ borderRadius: 30 }}>Activate!</button>
                        <button className="btn btn-link black" onClick={() => this.setState({ activating: false })}>No, do not activate</button>
                    </div>
                </Animated>

                {/* WIZZARD */}
                <Wizzard isVisible={this.state.college_profile ? true : false}
                    toggle={() => this.setState({ college_profile: null })}
                    selectedUser={this.state.selectedUser}
                    communities={this.props.communities}
                    countries={this.props.countries}
                    roster_actions={this.props.roster_actions} />

            </section >)
    }
}

export default CollegeProfile;
