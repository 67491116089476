export function fetchLeaguesForRegistration(term) { return { type: 'LEAGUES/REGISTRATION/FETCH', term } }
export function fetchLeaguesForPayments() { return { type: 'LEAGUES/PAYMENTS/FETCH' } }

export function setLeague(league) { return { type: 'LEAGUE/SET', league } }
export function clearLeague() { return { type: 'LEAGUE/SET', league: null } }
export function clearLeagues() { return { type: 'LEAGUES/SET', leagues: null } }
export function fetchLeagues() { return { type: 'LEAGUES/FETCH' } }
export function fetchLeaguesAdmin() { return { type: 'LEAGUES/ADMIN/FETCH' } }
export function fetchCurrency(idLeague) { return { type: 'LEAGUE/CURRENCY/FETCH', idLeague } }
export function fetchLeague(idLeague) { return { type: 'LEAGUE/FETCH', idLeague } }
export function fetchLeagueNonSecure(idLeague) { return { type: 'LEAGUE/NON_SECURE/FETCH', idLeague } }

export function fetchWaiverNonSecure(idLeague, idUser) { return { type: 'WAIVER/NON_SECURE/FETCH', idLeague, idUser } }
export function fetchTeamRegistration(idLeague) { return { type: 'TEAM/REGISTRATION/FETCH', idLeague } }
export function fetchFamilyRegistration(idLeague) { return { type: 'FAMILY/REGISTRATION/FETCH', idLeague } }
export function fetchScoutRegistration({ IdLeague, IdSeason }) { return { type: 'SCOUT/REGISTRATION/FETCH', IdSeason, IdLeague } }
export function fetchRoles(idLeague) { return { type: 'LEAGUE/ROLES/FETCH', idLeague } }
export function fetchLeagueFees(idLeague) { return { type: 'LEAGUE/FEES/FETCH', idLeague } }
export function clearLeagueFees() { return { type: 'LEAGUE/FEES/CLEAR' } }
export function fetchSeasonsForRegistration(idLeague) { return { type: 'SEASONS/REGISTRATION/FETCH', idLeague } }
export function changeDOB(idUser, dob) { return { type: 'USER/DOB/CHANGE', idUser, dob } }

// Date of Birth validation at league level
export function fetchLeagueValidations(idLeague) { return { type: 'LEAGUE/VALIDATIONS/FETCH', idLeague } }
export function fetchValidationsSummary(idLeague) { return { type: 'VALIDATIONS/SUMMARY/FETCH', idLeague } }
export function fetchSummaryByType(idLeague, validation_type) { return { type: 'VALIDATIONS/SUMMARY/TYPE/FETCH', idLeague, validation_type } }

export function validateDOBDocument({ user }) { return { type: 'LEAGUE/DOB/DOCUMENT/VALIDATE', user } }
export function unvalidateDOBDocument({ user }) { return { type: 'LEAGUE/DOB/DOCUMENT/UNVALIDATE', user } }

export function validateDocument({ user, validation_type }) { return { type: 'LEAGUE/DOCUMENT/VALIDATE', user, validation_type } }
export function unvalidateDocument({ user, validation_type }) { return { type: 'LEAGUE/DOCUMENT/UNVALIDATE', user, validation_type } }

export function validateDocumentSeason({ user, validation_type }) { return { type: 'LEAGUE/DOCUMENT/SEASON/VALIDATE', user, validation_type } }
export function unvalidateDocumentSeason({ user, validation_type }) { return { type: 'LEAGUE/DOCUMENT/SEASON/UNVALIDATE', user, validation_type } }

export function fetchUser(idUser, idLeague) { return { type: 'USER/LEAGUE/FETCH', idUser, idLeague } }
export function clearUser() { return { type: 'USER/CLEAR' } }


export function fetchDOBs(idLeague, letter) { return { type: 'LEAGUE/DOB/FETCH', idLeague, letter } }
export function fetchValidationsByLetter(idLeague, letter, validation_type) { return { type: 'LEAGUE/VALIDATIONS/TYPE/FETCH', idLeague, letter, validation_type } }
export function validateDOB(idUser, idLeague, dob) { return { type: 'LEAGUE/DOB/VALIDATE', idUser, idLeague, dob } }
export function unValidateDOB(idUser, idLeague) { return { type: 'LEAGUE/DOB/UNVALIDATE', idUser, idLeague } }
export function fetchTournamentSeasons(idLeague) { return { type: 'TOURNAMENT/TOURNAMENT_SEASONS/FETCH', idLeague } }
export function createTournamentSeason(idLeague, entity) { return { type: 'TOURNAMENT/TOURNAMENT_SEASONS/CREATE', idLeague, entity } }
export function updateTournamentSeason(entity) { return { type: 'TOURNAMENT/TOURNAMENT_SEASONS/UPDATE', entity } }
export function deleteTournamentSeason(idTournamentSeason) { return { type: 'TOURNAMENT/TOURNAMENT_SEASONS/DELETE', idTournamentSeason } }
export function updateLeaguePoints(idLeague, points, idTournamentType, idTournamentSeason) { return { type: 'LEAGUE_POINTS/UPDATE', idLeague, points, idTournamentType, idTournamentSeason } }
export function fetchImage(idLeague) { return { type: 'LEAGUE/IMAGE/FETCH', idLeague } }
export function updateImage(idLeague, leagueImage) { return { type: 'LEAGUE/IMAGE/UPDATE', idLeague, leagueImage } }
export function fetchRankingTeams(idTournamentSeason) { return { type: 'RANKING/TEAMS/FETCH', idTournamentSeason } }
export function clearUsers() { return { type: 'USERS/CLEAR' } }
export function fetchPCVs(idLeague, letter) { return { type: 'LEAGUE/PCV/FETCH', idLeague, letter } }
export function validatePCV(idUser, idLeague) { return { type: 'LEAGUE/PCV/VALIDATE', idUser, idLeague } }
export function unValidatePCV(idUser, idLeague) { return { type: 'LEAGUE/PCV/UNVALIDATE', idUser, idLeague } }
export function fetchDivisionTemplates(idLeague) { return { type: 'LEAGUE/DIVISION/TEMPLATES/FETCH', idLeague } }
export function fetchPendingRegistrations() { return { type: 'REGISTRATIONS/PENDING/FETCH' } }
export function fetchPendingValidations() { return { type: 'VALIDATIONS/PENDING/FETCH' } }
export function rejectPendingRegistration(IdPendingRegistration) { return { type: 'REGISTRATIONS/PENDING/REJECT', IdPendingRegistration } }

// Ads Campaigns
export function addAdCampaign(campaign) { return { type: 'AD/CAMPAIGN/CREATE', campaign } }
export function fetchCampaigns() { return { type: 'CAMPAIGNS/FETCH' } }
export function fetchCampaign(idCampaign) { return { type: 'CAMPAIGN/FETCH', idCampaign } }

// User Notes
export function fetchUserNotes(idUser, idLeague) { return { type: 'LEAGUE/USER/NOTES/FETCH', idUser, idLeague } }
export function addUserNote(note) { return { type: 'LEAGUE/USER/NOTES/ADD', note } }
export function removeUserNote(note) { return { type: 'LEAGUE/USER/NOTES/DEL', note } }

// Coupons
export function fetchCoupons(idLeague) { return { type: 'LEAGUE/COUPONS/FETCH', idLeague } }

// Leads
export function fetchLeads() { return { type: 'LEADS/FETCH' } }

// Picture Replacement
export function request_picture_replacement(idUser, idLeague, idSeason) { return { type: 'USER/PICTURE/REPLACE/CREATE', idUser, idLeague, idSeason } }
export function enforce_picture_replacement(idUser, idSeason) { return { type: 'USER/PICTURE/REPLACE/ENFORCE', idUser, idSeason } }
export function delete_picture_replacement(idUser, idLeague, idSeason) { return { type: 'USER/PICTURE/REPLACE/DELETE', idUser, idLeague, idSeason } }
export function deny_picture_replacement(idUser, idSeason) { return { type: 'USER/PICTURE/REPLACE/DENY', idUser, idSeason } }

// Membership Programs
export function fetchMembershipPrograms(idLeague) { return { type: 'LEAGUE/MEMBERSHIP_PROGRAMS/FETCH', idLeague } }
export function createMembershipProgram(membership_program) { return { type: 'LEAGUE/MEMBERSHIP_PROGRAM/CREATE', membership_program } }
export function updateMembershipProgram(membership_program) { return { type: 'LEAGUE/MEMBERSHIP_PROGRAM/UPDATE', membership_program } }
export function deleteMembershipProgram(IdMembership, IdLeague) { return { type: 'LEAGUE/MEMBERSHIP_PROGRAM/DELETE', IdMembership, IdLeague } }