function reducer(state = [], action) {

    switch (action.type) {
      case 'VIOLATION/TYPES/SET':          
        return action.violation_types;
      case 'VIOLATION/TYPES/CLEAR':
        return null;
      default:
        return state;
    }
  }
  
  export default reducer;