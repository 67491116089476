import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../config';
import { Animated } from 'react-animated-css';
import { getBase64, generateRandomId } from '../../../helpers';

class CreateTeam extends React.Component {

    state = {
        step: 1,
        isGuardian: false,
        popoverOpen: false,
        validations: {
            txtName: false,
            ddlistGender: false,
            ddlistCommunity: false,
        },
        selected: {
            community: null,
            gender: 0
        },
        genderOpened: false,
        ageOpened: false,
        communityOpened: false,
        bootstrapped: true,
        logo: null,
        team: null
    }

    componentWillMount() {
        this.props.familyActions && this.props.familyActions.fetchAgeGroups();
        this.props.familyActions && this.props.familyActions.fetchCommunities();
        const { team } = this.props;
        if (team) {
            this.setState({
                team: { ...this.props.team },
                selected: {
                    community: {
                        IdCommunity: team.IdCommunity,
                        CommunityName: team.Community
                    },
                    gender: team.IdGender
                },
                validations: {
                    txtName: true,
                    ddlistGender: true,
                    ddlistCommunity: true
                },
                logo: team.TeamImageUrl
            });
        }
    }

    // Toggles
    toggleGender = () => this.setState(prevState => ({ genderOpened: !prevState.genderOpened }));

    toggleCommunity = () => this.setState(prevState => ({ communityOpened: !prevState.communityOpened }));

    toggleAge = () => this.setState(prevState => ({ ageOpened: !prevState.ageOpened }));

    togglePopover1 = () => this.setState({ popoverOpen: false });

    validate = (key) => {
        const { validations, selected } = this.state;
        var valid = true;
        switch (key) {
            case 'ddlistCommunity':
                validations.ddlistCommunity = selected.community ? true : false;
                valid = !validations.ddlistCommunity ? false : valid;
                break;
            case 'txtName':
                validations.txtName = (this[key].value && this[key].value.length > 0) ? true : false;
                valid = !validations.txtName ? false : valid;
                break;
            case 'ddlistGender':
                validations.ddlistGender = selected.gender ? true : false;
                valid = !validations.ddlistGender ? false : valid;
                break;
            default:
                break;
        }
        this.setState({ validations });

        return valid;
    }

    selectGender = (i) => {
        const { selected, validations } = this.state;
        selected.gender = i;
        validations.ddlistGender = i ? true : false;
        this.setState({
            selected,
            validations
        });
    }
    selectCommunity = (i) => {
        const { selected, validations } = this.state;
        selected.community = i;
        validations.ddlistCommunity = i ? true : false;
        this.setState({
            selected,
            validations
        });
    }
    selectAgeGroup = (i) => {
        const { selected, validations } = this.state;
        selected.ageGroup = i;
        validations.ddlistAgeGroup = i ? true : false;
        this.setState({
            selected,
            validations
        });
    }

    create = () => {

        const { validations } = this.state;
        if (_.chain(validations).values().filter((i) => {
            return i === false;
        }).value().length) {
            this.setState({ popoverOpen: true });
        } else {
            const { team } = this.state;
            this.setState({ step: 2 }, () => {
                request[(team && team.IdTeam) ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/family/team${(team && team.IdTeam) ? `/${team.IdTeam}` : ''}`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .send({
                        teamName: this.txtName.value,
                        idCommunity: this.state.selected.community.IdCommunity,
                        idGender: this.state.selected.gender,
                        idTeam: (team && team.IdTeam) ? team.IdTeam : null,
                        logo: this.state.logo && this.state.logo.indexOf('base64') !== -1 ? this.state.logo : null,
                        ..._.pick(team || {}, 'IsChatSupervised', 'IsEvaluationsHiddenToTeamManagers'),
                    })
                    .then((data) => {
                        if (data.body.success) {
                            // re-fetch family members                            
                            this.props.familyActions && this.props.familyActions.fetchTeams();
                            team && team.IdTeam && this.props.familyActions && this.props.familyActions.updateSelectedTeam({
                                ...team,
                                TeamName: this.txtName.value,
                                Name: this.txtName.value,
                                IdCommunity: this.state.selected.community.IdCommunity,
                                Community: this.state.selected.community.CommunityName,
                                IdGender: this.state.selected.gender,
                                Gender: this.state.selected.gender === 1 ? 'Male' : (this.state.selected.gender === 2 ? 'Female' : 'Coed'),
                                TeamImageUrl: this.state.logo ? team.TeamImageUrl + `?rand=${generateRandomId(4)}` : team.TeamImageUrl,
                                ..._.pick(team || {}, 'IsChatSupervised', 'IsEvaluationsHiddenToTeamManagers'),
                            });
                            this.props.toggle && this.props.toggle();
                        }
                    }, () => { this.setState({ step: 1 }); });
            });
        }
    }

    toggleIsGuardian = () => this.setState({ isGuardian: !this.state.isGuardian });

    close = () => {
        const { toggle } = this.props;
        this.setState({ bootstrapped: false }, () => {
            setTimeout(() => { toggle && toggle(); }, 300);
        });
    }

    handleAvatar = e => {
        getBase64(e.target.files[0]).then(data => {
            this.setState({ logo: data });
        });
    }

    render() {

        const { selected, step, bootstrapped, logo, team } = this.state,
            { ageGroups, communities, } = this.props;

        return <section>

            <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>
                <div className="dark-overlay" onClick={this.close} />
                {/*<div className="overlay" onClick={this.close} style={{ zIndex: -1 }} />*/}
                <Animated animationIn="fadeInRight" animationOut="fadeOutRight" animationOutDuration={300} animationInDuration={300} isVisible={bootstrapped} style={{
                    position: 'fixed', right: 0, top: 0, bottom: 0,
                    width: 450, borderLeft: '1px solid gray'
                }} className="bg-gray">
                    <div className="d-flex flex-row p-3">
                        <i className="fas fa-arrow-left font-12 pointer black" onClick={this.close} />
                        <span className="black montserrat font-12 ml-auto">CREATE A TEAM</span>
                        <i className=" font-16 pointer ml-auto" />
                    </div>
                    {ageGroups && communities && (
                        <Animated animationIn="fadeIn" class="w-100">
                            <div className="card shadow-box p-4 mx-4 d-flex flex-column" style={{ width: 'auto', border: this.state.popoverOpen ? '1px solid red' : '' }}>
                                <span className="montserrat font-14">Basic Information</span>

                                <input className="form-control mt-2" type="text" name="teamname" placeholder="Team's Name " ref={(input) => this.txtName = input} onBlur={() => this.validate('txtName')} defaultValue={(team || {}).TeamName} />

                                <Dropdown isOpen={this.state.communityOpened} toggle={this.toggleCommunity} className="form-control no-padding w-100 mt-2">
                                    <DropdownToggle className="bg-white black text-left w-100 d-flex flex-row" style={{ border: 0 }}>
                                        {selected.community ? selected.community.CommunityName : 'Community'}
                                        <i className="fas fa-chevron-down ml-auto align-self-center" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {this.props.communities.map((c) => <DropdownItem key={c.IdCommunity} onClick={() => this.selectCommunity(c)}>
                                            {c.CommunityName}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>

                                <Dropdown isOpen={this.state.genderOpened} toggle={this.toggleGender} className="form-control no-padding white w-100 mt-2">
                                    <DropdownToggle className="bg-white black text-left w-100 d-flex flex-row" style={{ border: 0 }}>
                                        {selected.gender !== 0 ? (selected.gender === 1 ? 'Male' : (selected.gender === 2 ? 'Female' : 'Coed')) : "Gender"}
                                        <i className="fas fa-chevron-down ml-auto align-self-center" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        <DropdownItem onClick={() => this.selectGender(1)}><i className="fa fa-male" />
                                            {' '}Male</DropdownItem>
                                        <DropdownItem onClick={() => this.selectGender(2)}><i className="fa fa-female" />
                                            {' '}Female</DropdownItem>
                                        <DropdownItem onClick={() => this.selectGender(3)}><i className="fa fa-male" /><i className="fa fa-female" />
                                            {' '}COED</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                            </div>

                            {/* SUPERVISED CHAT */}
                            {team && team.IdTeam && <div className="card shadow-box px-4 py-3 mx-4 d-flex flex-row mt-2" style={{ width: 'auto' }}>
                                <div className="d-flex flex-row justify-content-center" onClick={() => {
                                    let { team = {} } = this.state;
                                    if (!team) team = {};
                                    team.IsChatSupervised = !team.IsChatSupervised;
                                    this.setState({ team });
                                }}>
                                    <i className={`fas fa-toggle-${(team && team.IsChatSupervised) ? 'on green' : 'off black'} font-20 align-self-center pointer`} />
                                </div>
                                <div className="d-flex flex-column mx-2">
                                    <span className="font-16 line1 montserrat-bold black">Supervised Chat</span>
                                    <span className="font-10 line1 montserrat gray">Admins are included automatically in team chats</span>
                                </div>
                            </div>}

                            {/* TEAM EVALUATIONS */}
                            {team && team.IdTeam && <div className="card shadow-box px-4 py-3 mx-4 d-flex flex-row mt-2" style={{ width: 'auto' }}>
                                <div className="d-flex flex-row justify-content-center" onClick={() => {
                                    let { team = {} } = this.state;
                                    if (!team) team = {};
                                    if (team.rights && team.rights.IsTeamCoach) {
                                        team.IsEvaluationsHiddenToTeamManagers = !team.IsEvaluationsHiddenToTeamManagers;
                                        this.setState({ team });
                                    } else {
                                        alert('This action can only be done by Team Coaches');
                                    }
                                }}>
                                    <i className={`fas fa-toggle-${team && team.IsEvaluationsHiddenToTeamManagers ? 'on green' : 'off black'} font-20 align-self-center`} />
                                </div>
                                <div className="d-flex flex-column mx-2">
                                    <span className="font-16 line1 montserrat-bold black">Player Evaluations</span>
                                    <span className="font-10 line1 montserrat gray">Limit Team Manager Access</span>
                                </div>
                            </div>}

                            <label htmlFor="fi" className="card tile shadow-box p-4 mx-4 mt-2 d-flex flex-column" style={{ position: 'relative', width: 'auto', }}>
                                <span className="montserrat font-14">Team Logo (optional)</span>
                                <div style={{ height: 150 }} className="w-100 d-flex flex-column justify-content-center">
                                    {!logo && <i className="blue font-60 align-self-center icon-camera" />}
                                    {!logo && <i className="blue fa fa-plus font-20" style={{ position: 'absolute', top: 80, left: 150 }} />}
                                    {logo && <img alt="" style={{ height: 100 }} src={logo} className="align-self-center" />}
                                </div>
                            </label>
                            <div className="m-4">
                                <button className="btn btn-block btn-success" id="btnSubmit" onClick={this.create}>
                                    {step === 1 && <span>Save</span>}
                                    {step === 2 && <i className="fa fa-spin fa-cog" />}
                                </button>
                                <Popover placement="bottom" isOpen={this.state.popoverOpen} target="btnSubmit" toggle={this.togglePopover1}>
                                    <PopoverHeader>Hey!</PopoverHeader>
                                    <PopoverBody>Please complete the required fields <i className="fa fa-asterisk red" /></PopoverBody>
                                </Popover>
                                {step === 1 && <button className="btn btn-block btn-link" onClick={this.close}>Cancel</button>}
                            </div>

                            <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />

                        </Animated>
                    )}
                </Animated>
            </div>
        </section>
    }
}

export default CreateTeam;
