import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';


// v4
export function* getFlightsSaga() {
    yield* takeEvery("FLIGHTS/FETCH", function* (action) {
        try {
            const result = yield call(({ idSeason, idDivision }) => {
                return request.get(`${config.apiEndpoint}/api/v4/flights/${idSeason}${idDivision ? `/${idDivision}` : ''}`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => JSON.parse(data.text));
            }, action);

            if (result && result.success) {
                yield put({ type: 'FLIGHTED_TEAMS/SET', teams: result.teams });
                yield put({ type: 'FLIGHTS/SET', flights: result.flights });
            } else {
                yield put({ type: 'FLIGHTS/FETCH_FAILED', result });
            }

        } catch (e) {
            yield put({ type: 'FLIGHTS/FETCH_FAILED', e });
        }
    });
}

export function* flightedTeamsMoveSaga() {
    yield* takeEvery("FLIGHTED_TEAMS/MOVE", function* (action) {
        try {
            const { source, to, of, flightedTeams = [] } = action;            
            
            let index = flightedTeams.findIndex(item => (item.IdTeam === source.IdTeam));           
            

            if (index !== -1) {                

                // Get all the flighted teams from source and target flights
                let teamsFromTargetFlight = _.filter(flightedTeams, team => {
                    return team.IdFlight === of;
                }),
                    teamsFromSourceFlight = _.filter(flightedTeams, team => {
                        return team.IdFlight === source.IdFlight && team.Id !== source.Id
                    }), otherTeams = _.filter(flightedTeams, team => {
                        return team.IdFlight !== of && team.IdFlight !== source.IdFlight;
                    });

                // Place the source team as flighted into the target flight (and in the right position)
                teamsFromTargetFlight.splice(teamsFromTargetFlight.length + 1, 1);
                teamsFromTargetFlight.splice(to, 0, _.extend(source, { IdFlight: of }));

                // Update positions for teams in both flights
                teamsFromTargetFlight.forEach((t, i) => { t.Position = i + 1; });
                teamsFromSourceFlight.forEach((t, i) => { t.Position = i + 1; });                

                // Call reducer
                yield put({
                    type: 'FLIGHTED_TEAMS/SET',
                    teams: _.union(teamsFromSourceFlight,
                        teamsFromTargetFlight, otherTeams)
                });

                // CALL API
                const url = _.compact([config.apiEndpoint, 'api/v4/flights/sort/teams']).join('/');
                let data = _.union(
                    _.map(teamsFromTargetFlight, function (t, index) {
                        return {
                            Id: t.Id,
                            IdFlight: of,
                            Position: index+1
                        }
                    }),
                    _.map(teamsFromSourceFlight, function (t, index) {
                        return {
                            Id: t.Id,
                            IdFlight: source.IdFlight,
                            Position: index+1
                        }
                    }));                    

                request.post(url)
                    .send(data)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken')).then((data) => {
                        
                        // TODO: call to resort flights in it?                        
                        request.post(`${config.apiEndpoint}/api/v4/flights/force_sorting/${of}`)
                            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                            .then((data) => { });
                        
                        request.post(`${config.apiEndpoint}/api/v4/flights/force_sorting/${source.IdFlight}`)
                            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                            .then((data) => { });

                    });
            }
        } catch (e) {
            yield put({
                type: 'FLIGHTS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* flightedTeamsSortSaga() {
    /* Receives: flightedTeams, from (index), to (index) */
    yield* takeEvery("FLIGHTED_TEAMS/SORT", function* (action) {

        try {

            const teams = _.chain(action.args.flightedTeams)
                .filter(function (t) {
                    return t.IdFlight === action.args.IdFlight;
                })
                .sortBy('Position').value(),
                element = teams[action.args.from];

            // Move items
            teams.splice(action.args.from, 1);
            teams.splice(action.args.to, 0, element);

            // Update positions
            teams.forEach(function (team, position) {
                team.Position = (position + 1);
            });

            // Call Reducer
            yield put({
                type: 'FLIGHTED_TEAMS/SET',
                teams: _.union(
                    _.reject(action.args.flightedTeams, function (t) {
                        return t.IdFlight === action.args.IdFlight;
                    }),
                    teams)
            });

            // CALL API
            const url = _.compact([config.apiEndpoint, 'api/v4/flights/sort/teams']).join('/');
            var data = _.map(teams, t => {
                return { Id: t.Id, IdFlight: t.IdFlight }
            });
            data.forEach((t, i) => { t.Position = (i + 1); });
            request.post(url)
                .send(data)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken')).then((data) => {
                });

        } catch (e) {
            yield put({
                type: 'FLIGHTS/FETCH_FAILED',
                e
            });
        }
    });
}

export function* flightsSortSaga() {
    yield* takeEvery("FLIGHTS/SORT", function* (action) {
        try {
            const result = yield call(({ idDivision, from, to }) => {
                return request.patch(`${config.apiEndpoint}/api/v4/flights/${idDivision}/${from}/${to}`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => JSON.parse(data.text));
            }, action);
            if (result && result.success) {
                // Optimistic
            } else {
                yield put({ type: 'FLIGHTS/SORT_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'FLIGHTS/SORT_FAILED', e });
        }
    });
}

export function* flightForceSort() {
    yield* takeEvery("FLIGHT/SORT/FORCE", function* (action) {
        try {
            yield call(({ idFlight }) => {
                return request.post(`${config.apiEndpoint}/api/v4/flights/force_sorting/${idFlight}`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => JSON.parse(data.text));
            }, action);
        } catch (e) {

        }
    });
}