import React from 'react';
import { Modal, ModalBody, Jumbotron } from 'reactstrap';
import _ from 'lodash';
import UserCard from '../UserCard';
import TeamCard from '../TeamCard';
import LineLoader from '../LineLoader';
import COLORS from '../../../colors';

class Spotlight extends React.Component {

  state = {
    bootstrapped: false,
    spotlight: null,
    selectedEntity: null,
    keyword: '',
    temp: null,
    length: 0
  }
  //

  componentWillReceiveProps = (nextProps) => {
    const { spotlight } = nextProps;
    this.setState({ spotlight });
  }
  componentWillUnmount = () => {
    this.props.spotlightActions &&
      this.props.spotlightActions.clear();
  }

  onChange = () => {
    this.setState({ temp: this.txtSearch.value });
  }

  onSearch = e => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    let searchText = this.txtSearch.value ? this.txtSearch.value.trim() : null;
    if (searchText === this.state.keyword) return; // prevent double fetchs
    if (!searchText || !searchText.length) {
      this.setState({ spotlight: null });
    }
    if (searchText.length > 1) {
      this.setState({ bootstrapped: true });
      this.props.spotlightActions &&
        this.props.spotlightActions.search(_.extend(this.props.params, {
          keyword: searchText
        }));
    } else if (searchText.length <= 1) {
      this.setState({ bootstrapped: false });
      this.props.spotlightActions &&
        this.props.spotlightActions.clear();
    }

    this.setState({ keyword: searchText });
  }

  selectUser = selectedUser => this.setState({ selectedEntity: selectedUser });

  selectTeam = selectedTeam => this.setState({ selectedEntity: selectedTeam });

  render() {

    const { isOpen, toggle, fetchingSpotlight } = this.props,
      { spotlight, keyword, temp, selectedEntity } = this.state;

    // TODO: keep filtering after receive
    let filteredUsers = [],
      filteredTeams = [];

    if (spotlight) {
      filteredTeams = _.filter(spotlight.teams, (t) => {
        return true;
      });
      filteredUsers = _.filter(spotlight.users, (u) => {
        return true;
      });
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody id="spotlight">
          <form className="d-flex flex-row bordered">
            <input defaultValue={keyword} ref={(i) => this.txtSearch = i} type="text" onChange={this.onChange}
              className="p-4 w-100 font-20 " placeholder={`${this.props.params.idLeague ? 'League' : 'Season'} search for Teams or Users...`}
            />
            <button className="py-4 pl-4 pr-2" type="submit" onClick={this.onSearch}>
              <div style={{ width: 60, height: 60, backgroundColor: (temp && temp.length) ? COLORS.green : COLORS.transparent, borderRadius: 30 }} className="d-flex flex-row justify-content-center">
                <i className={`fas fa-search font-18 ${(temp && temp.length) ? 'white' : 'black'} align-self-center`} />
              </div>
            </button>
          </form>
          {fetchingSpotlight && <LineLoader />}
        </ModalBody>

        {spotlight && <ModalBody className="container-fluid no-padding no-margin">
          {(filteredUsers.length > 0 || filteredTeams.length > 0) && <div className="row no-padding no-margin">
            <div className={`col-${selectedEntity ? 7 : 12} no-margin no-padding`} style={{ maxHeight: 600, overflowY: 'auto' }}>
              { /* Users */}
              {spotlight.users && filteredUsers.length > 0 && (
                <section>
                  <div className="p-3 font-16 bg-info white">
                    <i className="icon-users mr-2" /><span>Users ({filteredUsers.length})</span>
                  </div>
                  <ul>
                    {filteredUsers.map((u, i) => (
                      <li key={i} onClick={() => this.selectUser(u)} className={`p-2 pointer no-margin text-left btn-block btn-sm d-flex flex-row ${(selectedEntity && selectedEntity.IdDivision === u.IdDivision && selectedEntity.IdUser === u.IdUser) ? 'bg-warning' : ''}`} style={{ borderBottom: '1px solid #e0e0e0' }}>
                        <div className="align-self-center cover" style={{ width: 60, height: 60, borderRadius: 30, border: '1px solid #ccc', background: `url(${u.UserImageUrl}?full=80) no-repeat center center` }} />
                        <div className="d-flex flex-column align-self-center ml-3">
                          <span className={`montserrat-bold font-${u.UserName.length > 20 ? '12' : '16'}`}>{u.UserName}</span>
                          {!this.props.params.idSeason && <span className="montserrat font-8 line1">{u.SeasonName}</span>}
                          <span className="montserrat font-10">{`${u.DivisionName} |`}<span className="font-10">{` ${u.RoleName || u.RoleOverride} @ ${u.TeamName}`}</span></span>
                        </div>
                        <i className="fa fa-chevron-right ml-auto align-self-center font-18" />
                      </li>
                    ))}
                  </ul>
                </section>)}


              { /* Teams */}
              {spotlight.teams && filteredTeams.length > 0 && (
                <section>
                  <div className="p-3 font-16 bg-info white">
                    <i className="icon-CrestOnly_icon mr-2" /><span>Teams ({filteredTeams.length})</span>
                  </div>
                  <ul>
                    {filteredTeams.map((t, i) => (
                      <li key={i} onClick={() => this.selectTeam(t)} className={`p-2 pointer no-margin text-left btn-block btn-sm d-flex flex-row ${(selectedEntity && selectedEntity.IdDivision === t.IdDivision && selectedEntity.IdTeam === t.IdTeam) ? 'bg-warning' : ''}`} style={{ borderBottom: '1px solid #e0e0e0' }}>
                        <div className="align-self-center cover" style={{ width: 60, height: 60, background: `url(${t.TeamImageUrl}?full=80) no-repeat center center` }} />
                        <div className="d-flex flex-column align-self-center ml-3">
                          <span className={`montserrat-bold font-${t.TeamName.length > 20 ? '12' : '16'}`}>{t.TeamName}</span>
                          {!this.props.params.idSeason && <span className="montserrat font-8 line1">{t.SeasonName}</span>}
                          <span className="montserrat font-10">{[t.DivisionName].join(' - ')}</span>
                        </div>
                        <i className="fa fa-chevron-right ml-auto align-self-center font-18" />
                      </li>
                    ))}
                  </ul>
                </section>
              )}
            </div>
            {selectedEntity && <div className="col-5 no-margin no-padding" style={{ borderLeft: '1px solid #e0e0e0' }}>
              {selectedEntity && selectedEntity.IdUser && <UserCard user={selectedEntity} toggle={toggle} />}
              {selectedEntity && !selectedEntity.IdUser && <TeamCard team={selectedEntity} toggle={toggle} />}
            </div>}
          </div>}
          {!filteredTeams.length && !filteredUsers.length && <Jumbotron className="m-0 text-center">
            {!fetchingSpotlight && <h1><i className="fas fa-search mr-2" />Sorry, could not find Users or Teams named <span className="red">'{keyword}'</span> on this {this.props.params.idLeague ? 'League' : 'Season'}</h1>}
            {fetchingSpotlight && <h1>Fetching, please wait...</h1>}
          </Jumbotron>}
        </ModalBody>}
      </Modal>
    )
  }
}

export default Spotlight;