// a reducer takes in 2 things:

// 1: the action (info about what happened)

// 2: a copy of the current state

function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'SPOTLIGHT/OPEN':
            return true;
        case 'SPOTLIGHT/CLOSE':
            return false;
        default:
            return state;
    }
}

export default reducer;