import React from 'react';
import { Link } from 'react-router';

class Navigation extends React.Component {
    render() {
        const { season } = this.props;
        return <div className="d-flex flex-row w-100 mt-2" style={{ height: 50, backgroundColor: '#4469C7', borderRadius: 5, overflow: 'hidden' }}>

            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/teams`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Teams</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/set_rosters`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Rosters</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/lock_rosters`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center line1">Locks</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/tryouts`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Tryouts</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/waitlists`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Waitlists</Link>
            
        </div>
    }
}

export default Navigation;