export function fetchFamilyTeams() { return { type: 'TEAMS/FAMILY/FETCH' } }
export function fetchTeamSeasons(idTeam) { return { type: 'TEAMS/SEASONS/FETCH', idTeam } }
export function fetchRoster(idTeam, idSeason) { return { type: 'TEAMS/ROSTER/SEASON/FETCH', idTeam, idSeason } }
export function clearFamilyTeams() { return { type: 'TEAMS/CLEAR' } }
export function fetchTeam(idTeam) { return { type: 'TEAM/FETCH', idTeam } }
export function selectTeam(team) { localStorage.setItem('sportslogic.team', JSON.stringify(team)); return { type: 'SELECTED_TEAM/SET', team } }
export function clearTeams() { return { type: 'TEAMS/CLEAR' } }
export function clearTeamSelected() { return { type: 'SELECTED_TEAM/CLEAR' } }
export function toggleTeamSelection(isTeamSelectionOpen) { return { type: 'IS_TEAM_SELECTION_OPEN/SET', isTeamSelectionOpen } }
export function updateTeam(idTeamDivision, name, idGender) { return { type: 'TEAM/UPDATE', idTeamDivision, name, idGender } }
export function updateName(idTeam, name) { return { type: 'TEAM/UPDATE/NAME', idTeam, name } }
export function updateResults(idTeamDivision, idTournamentTypeDivisionPoints, bonus, idSeason) { return { type: 'TEAM/UPDATE/RESULTS', idTeamDivision, idTournamentTypeDivisionPoints, bonus, idSeason } }
export function updateAwards(team) { return { type: 'AWARDS/UPDATE', team } }
export function updateUniformSwag(team) { return { type: 'SHIRTS/SIGNATURE/UPDATE', team } }
export function setRankingAgeGroup(idTeam, idTournamentSeason, idTournamentRankAgeGroup, idTournamentRank, randomId) { return { type: 'TEAM/RANKING_AGE_GROUP/SET', idTeam, idTournamentSeason, idTournamentRankAgeGroup, idTournamentRank, randomId } }
export function fetchSchedule(idTeamDivision) { return { type: 'TEAM/SCHEDULE/FETCH', idTeamDivision } }
export function fetchOtherEvents(idTeamDivision) { return { type: 'TEAM/OTHER_EVENTS/FETCH', idTeamDivision } }
export function fetchVideos(idTeam) { return { type: 'TEAM/VIDEOS/FETCH', idTeam } }
export function setVideo(video) { return { type: 'TEAM/VIDEO/SET', video } }
export function fetchVideo(idVideo) { return { type: 'TEAM/VIDEO/FETCH', idVideo } }
export function updateVideo(video) { return { type: 'VIDEOS/UPDATE', video } }
export function deleteVideo(video, idTeam) { return { type: 'VIDEOS/DELETE', video, idTeam } }
export function archiveVideo(idVideo, idTeam) { return { type: 'VIDEOS/ARCHIVE', idVideo, idTeam } }
export function fetchSubscriptions(idTeam) { return { type: 'TEAM/SUBSCRIPTIONS/FETCH', idTeam } }
export function subscribeToSQWADTrial(IdTeam, IdPlan, card) { return { type: 'TEAM/SUBSCRIBE/SQWAD', IdTeam, IdPlan, card } }
export function clearFiles() { return { type: 'FILES/CLEAR' } }
export function clearSelectedTeam() { localStorage.removeItem('sportslogic.team'); return { type: 'SELECTED_TEAM/CLEAR' } }
export function clearSeasons() { return { type: 'SEASONS/CLEAR' } }
export function fetchOpponents() { return { type: 'OPPONENTS/FETCH' } }
export function fetchLocations() { return { type: 'LOCATIONS/FAMILY/FETCH' } }
export function fetchRights(IdTeam) { return { type: 'TEAM/RIGHTS/FETCH', IdTeam } }

// Evaluations
export function fetchTeamEvaluations(IdTeam) { return { type: 'TEAM/EVALUATIONS/FETCH', IdTeam } }
export function save_match(match) { return { type: 'GENERIC_MATCH/SAVE', match } }
export function delete_match(IdMatch, IdTeam) { return { type: 'GENERIC_MATCH/DELETE', IdMatch, IdTeam } }
export function unfollow_match(IdMatch) { return { type: 'GENERIC_MATCH/UNFOLLOW', IdMatch } }
export function unfollow_generic_match({ IdMatch, IdUser }) { return { type: 'GENERIC_MATCH/UNFOLLOW', IdMatch, IdUser } }
export function follow_match({ IdUser, IdMatch }) { return { type: 'GENERIC_MATCH/FOLLOW', IdMatch, IdUser } }
export function fetch_questionaries() { return { type: 'QUESTIONARIES/FETCH' } }
export function fetch_topics(IdCommunity) { return { type: 'TOPICS/FETCH', IdCommunity } }
export function fetch_custom_attributes_templates() { return { type: 'CUSTOM_ATTRIBUTES_TEMPLATES/FETCH' } }
export function fetch_match_collaborators(IdMatch) { return { type: 'MATCH/COLLABORATORS/FETCH', IdMatch } }

// Attendance
export function fetchTeamAttendance(IdGame) { return { type: 'TEAM/ATTENDANCE/FETCH', IdGame } }
export function fetchFamilyAttendance(IdGame) { return { type: 'TEAM/FAMILY/ATTENDANCE/FETCH', IdGame } }
export function updateFamilyAttendance({ idEvent, idUser, idEventAttendanceReply }) { return { type: 'TEAM/FAMILY/ATTENDANCE/SET', idEvent, idUser, idEventAttendanceReply } }

// Uniforms
export function fetchUniformByGame(IdGame, IdTeam) { return { type: 'TEAM/UNIFORM/GAME/FETCH', IdGame, IdTeam } }
export function fetchUniformBags(IdTeam) { return { type: 'TEAM/UNIFORMS/FETCH', IdTeam } }
export function setUniformToGame(IdGame, items, IdTeam) { return { type: 'TEAM/UNIFORM/GAME/SET', IdGame, items, IdTeam } }

// Lineups
export function fetchLineups(IdTeamDivision) { return { type: 'TEAM/LINEUPS/FETCH', IdTeamDivision } }
export function saveLineup(match) { return { type: 'TEAM/LINEUPS/SAVE', match } }
export function deleteLineup(IdMatch, IdTeamDivision) { return { type: 'LINEUP/DELETE', IdMatch, IdTeamDivision } }