import React from 'react';
import TeamListItem from './TeamListItem';
import _ from 'lodash';
import { calculateBWContrast } from '../../../../helpers';

class DivisionListItem extends React.Component {

    state = {
        open: false
    }

    toggle = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    renderTeam = (team, index) => {
        const { flightedTeams } = this.props;

        // If the team is flighted, add the flight as a prop to the child component
        const flight = _.find(flightedTeams, (t) => {
            return parseInt(t.IdTeam, 10) === parseInt(team.IdTeam, 10);
        }) || false;
        team.Flight = flight ? flight.IdFlight : null;
        team.Division = this.props.division;

        return (
            <TeamListItem key={team.IdTeam} index={index} team={team} flight={flight} />
        )
    }

    render() {
        const { open } = this.state,
            { division } = this.props;

        let teams = _.chain(division.Teams).filter(t => t.IdRoleStatus === 1 || t.IdRoleStatus === 2).sortBy(t => t.Name.toLowerCase()).value();

        const style = { color: calculateBWContrast(division.Color), backgroundColor: division.Color }
        const hasActiveOrPendingTeams = teams.length ? true : false;

        return (
            <div className={`pt-2 pr-2 pl-2 ${open ? 'on' : 'off'}`}>
                <div className="p-2 btn-block division-list-item d-flex flex-row">
                    <i className={`pointer  font-14 fa fa-caret-${open ? 'down' : 'right'} ${(division.Teams && division.Teams.length && hasActiveOrPendingTeams) ? 'black' : 'white'} align-self-center`} onClick={this.toggle} />
                    <div style={{ width: 30, height: 30, ...style, borderRadius: 15 }} className="pointer d-flex flex-row justify-content-center align-self-center" onClick={this.toggle}>
                        {_.filter(teams, t => !t.Color && (t.IdRoleStatus === 1 || t.IdRoleStatus === 2)).length > 0 && teams.length > 0 && <span className="align-self-center font-8">{_.filter(teams, t => t.Color).length}|{teams.length}</span>}
                        {_.filter(teams, t => !t.Color).length === 0 && teams.length > 0 && <i className="fas fa-check align-self-center font-8" />}
                    </div>                    
                    <span onClick={() => this.props.onDivision && this.props.onDivision(division)} className=" align-self-center black font-10 text-left line1">{division.Name}</span>
                </div>
                <div className="division-list-teams-wrapper d-flex flex-column">
                    {teams && teams.map((team, index) => this.renderTeam(team, index)
                    )}
                </div>
            </div>
        )
    }
}

export default DivisionListItem;