function apiProgress(state = [], action) {
    switch (action.type) {
        case "LEAGUES/FETCH":
            return true;
        case "LEAGUES/REGISTRATION/FETCH":
            return true;
        case "LEAGUES/PAYMENTS/FETCH":
            return true;
        case "LEAGUES/SET":
            return false;
        case "LEAGUES/FETCH_FAILED":
            return false;
        case "SEASONS/FETCH":
            return true;
        case "SEASONS/REGISTRATION/FETCH":
            return true;
        case "SEASONS/PAYMENTS/FETCH":
            return true;
        case "SEASONS/SET":
            return false;
        case "SEASONS/FETCH_FAILED":
            return false;
        case "DIVISIONS/FETCH":
            return true;
        case "QUESTIONS/FETCH":
            return true;
        case "QUESTIONS/SET":
            return false;
        case "PAYMENT_PLANS/FETCH":
            return true;
        case "PAYMENT_PLANS/SET":
            return false;
        case "DIVISIONS/SET":
            return false;
        case "GENDERS/SET":
            return false;
        case "DIVISIONS/FETCH_FAILED":
            return false;
        case "FLIGHTS/FETCH":
            return true;
        case "FLIGHTS/SET":
            return false;
        case "FLIGHTS/FETCH_FAILED":
            return false;
        case "LOCATIONS/FETCH":
            return true;
        case "LOCATIONS/SET":
            return false;
        case "PAYMENTS/FETCH":
            return true;
        case "PAYMENTS/SET":
            return false;
        case "LOCATIONS/FETCH_FAILED":
            return false;
        default:
            return state;
    }
}

export default apiProgress;
