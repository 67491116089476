import { generateRandomId } from '../../helpers';

function reducer(state = [], action) {

    switch (action.type) {
        case 'ROSTER/SET':            
            action.roster.forEach((user) => {                
                user.Image = user.Image + `?rand=${generateRandomId(3)}`
            });            
            return action.roster;
        case 'ROSTER/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;