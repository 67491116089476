import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, toTitleCase, formatPrice } from '../../../../../helpers';
import moment from 'moment';
import { Link } from 'react-router';

const source = {
  beginDrag(props) {
    return props;
  }
};

const target = {
  canDrop(props) {
    return false;
  },
  drop(props, monitor) { }
}

const NonRosteredPlayer = (props) => {
  const { connectDragSource, item, connectDropTarget, season } = props; // isDragging  

  return connectDragSource(connectDropTarget(
    <tr>
      <td><i className="fas fa-arrows-alt gray pointer"></i></td>
      <td>
        {item.IdUser}
      </td>
      <td className="font-14">
        <Link className="btn btn-link blue underlined" to={`/season/${season.IdSeason}/player/${item.IdUser}${item.IdTeamUserRole ? `/${item.IdTeamUserRole}` : ''}`}>
          {toTitleCase(item.UserName || '')}
        </Link>
      </td>
      <td>
        <i className={`${item.RoleStatus} fas fa-circle`}></i>
        {' '}
        <span>{item.RoleStatus}</span>
      </td>
      <td>{item.RoleName || item.RoleOverride}</td>
      <td>
        {moment(item.DateBirth).format('MMM Do YYYY')}
      </td>
      <td>
        {item.Team}
      </td>
      <td>
        {item.Gender && item.Gender.substring(0, 1)}
      </td>
      <td>
        <span className="block">{item.PhoneNumber}</span>
        <span className="block">{item.EmailAddress}</span>
      </td>
      <td>N/A</td>
      <td>
        {item.City}
      </td>
      <td>
        {item.State}
      </td>
      <td>
        {formatPrice(item.BalanceDue * 100)}
      </td>
      <td>
        {formatPrice(item.AmountPaid * 100)}
      </td>
      {props.questions && props.questions.map((q, i) => (
        <td key={i}>
          {item[`question-${q.IdQuestion}`]}
        </td>))}

    </tr>
  ));

}

export default flow(
  DropTarget(ItemTypes.ROSTEREDPLAYER, target, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver()
  })),
  DragSource(ItemTypes.NONROSTEREDPLAYER, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(NonRosteredPlayer);