import React from 'react';

class FamilyCard extends React.Component {
  
  render() {
    const { user, family } = this.props;    
    return (
      <div className="font-9 bg-white">
        <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>Master Account</h3>        
        <div className="d-flex flex-row margin-top montserrat">
          <i className="fa fa-phone idented align-self-center" />
          <span className="idented-half align-self-center">{user.FullPhoneNumber}</span>
        </div>
        <div className="d-flex flex-row montserrat">
          <i className="fa fa-at idented align-self-center" />
          <a href={`mailto:${user.PersonalEmailAddress}`}><span className="idented-half align-self-center">{user.PersonalEmailAddress}</span></a>
        </div>
        <hr />
        <div className="d-flex flex-row margin-bottom montserrat">
          <i className="fa fa-map-pin idented align-self-center" />
          <span className="idented-half align-self-center">{[user.AddressStreet, user.City, user.StateCode, user.ZipCode + ' - ' + user.Country].join(' ')}</span>
        </div>
        <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>Family Members</h3>
        <ul>
          {family && family.map((user, i) => (
            <li key={i}>
              <button className="btn btn-block d-flex flex-row">
                <div className="align-self-center" style={{ position: 'relative', width: 30, height: 30 }}>
                  { /* Avatar Default */}
                  <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 30 }} className="d-flex flex-row justify-content-center">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '1px solid #e5e5e5', background: `url('/images/defaults/${user.IdGender === 2 ? 'female' : 'male'}.png') no-repeat center center` }} className="align-self-center cover" />
                  </div>
                  { /* Avatar */}
                  <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 30 }} className="d-flex flex-row justify-content-center">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '1px solid #e5e5e5', background: `url(${user.UserImageUrl}) no-repeat center center` }} className="align-self-center cover" />
                  </div>
                </div>
                <span className="idented-half align-self-center font-10">{user.UserName}</span>
              </button>
            </li>))}
        </ul>
      </div>)
  }
}

export default FamilyCard;
