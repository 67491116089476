import React from 'react';
import Card from './card';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { ItemTypes } from '../../../../../../helpers';

class Comp extends React.Component {

  render() {
    const { tryouts, fnAddTryoutAdjustment, fnEditTryoutAdjustment } = this.props;
    return <section>
      <div className="d-flex flex-row">
        <button className="btn btn-success ml-auto margin-right" onClick={fnAddTryoutAdjustment}>
          <i className="fa fa-plus" />
        </button>
      </div>
      <ReactCSSTransitionGroup component="ul" className="child clearfix registration-fees text-center" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
        transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
        {tryouts && tryouts.map((program, i) => <Card program={program} key={i} type={ItemTypes.TRYOUT} fnEdit={fnEditTryoutAdjustment} />)}
      </ReactCSSTransitionGroup>
      {(!tryouts || !tryouts.length) && (
        <div className="jumbotron text-center margin-top">
          <h1 className="display-3">There are no Tryout Adjustments</h1>
          <p className="lead">Click the plus sign above to create your first Tryout Adjustments</p>
        </div>)}
    </section>
  }
}

export default Comp;