import React from 'react';
import { DropTarget } from 'react-dnd';
import FlightListItem from './FlightListItem';
import TeamListItem from './TeamListItem';
import GameDayRuleIcon from '../game_days/GameDayRuleIcon';
import _ from 'lodash';

import './divisions.css';

const divisionTarget = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)        
        if (monitor.didDrop())
            return;

        const { division } = props;

        switch (monitor.getItem().rule.type) {
            case 'GameDay':
                division.Flights.forEach(function (f) {
                    // Evaluate if the rule isn't already there
                    if (!_.find(f.GameDayRules, function (r) {
                        return (r.IdRule === monitor.getItem().rule.Id);
                    })) {
                        monitor.getItem().gameDayRulesActions &&
                            monitor.getItem().gameDayRulesActions.associateWithFlight(monitor.getItem().rule.Id, f.IdFlight);
                        f.GameDayRules.push({
                            IdFlight: f.IdFlight,
                            FlightName: (f.Name || f.FlightName),
                            IdRule: monitor.getItem().rule.Id,
                            RuleName: monitor.getItem().rule.Name
                        });
                    }

                });
                break;
            case 'exception/time':
                division.Flights.forEach(function (f) {
                    f.FlightedTeams.forEach(function (team) {

                        // Evaluate if the rule isn't already there
                        if (!_.find(team.ExceptionRules, function (r) {
                            return (r.IdRule === monitor.getItem().rule.Id);
                        })) {

                            team.ExceptionRules.push({
                                IdScheduleFlightTeam: team.IdScheduleFlightTeam,
                                TeamName: team.TeamName,
                                IdRule: monitor.getItem().rule.Id,
                                RuleName: monitor.getItem().rule.Name,
                                Type: monitor.getItem().rule.type
                            });

                            monitor.getItem().exceptionRulesActions &&
                                monitor.getItem().exceptionRulesActions.associateDateWithTeam(monitor.getItem().rule.Id, team.IdScheduleFlightTeam);
                        }
                    });
                });
                break;
            case 'exception/matchup':
                division.Flights.forEach(function (f) {
                    f.FlightedTeams.forEach(function (team) {
                        team.ExceptionRules.push({
                            IdTeamDivision: team.IdTeamDivision,
                            TeamName: team.TeamName,
                            IdRule: monitor.getItem().rule.Id,
                            RuleName: monitor.getItem().rule.Name,
                            Type: monitor.getItem().rule.type
                        });
                    });
                });
                break;
            case 'FieldAvailability':

                division.Flights.forEach(function (f) {

                    // Evaluate if the rule isn't already there
                    if (!_.find(f.FieldAvailabilityRules, function (r) {
                        return ((r.IdScheduleFieldAvailability || r.IdRule) === monitor.getItem().rule.IdScheduleFieldAvailability);
                    })) {
                        monitor.getItem().fieldAvailabilityRulesActions &&
                            monitor.getItem().fieldAvailabilityRulesActions.associateWithFlight(monitor.getItem().rule.IdScheduleFieldAvailability, f.IdFlight);
                        f.FieldAvailabilityRules.push({
                            IdFlight: f.IdFlight,
                            FlightName: (f.Name || f.FlightName),
                            IdRule: monitor.getItem().rule.IdScheduleFieldAvailability,
                            RuleName: 'Field Availability Rule'
                        });
                    }
                });
                break;
            default:
                break;
        }

        // This will open the tree when dropped, so the user can see all the flights animated.
        component.setState && component.setState({
            collapsed: false
        });
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop()
    };
}

class DivisionListItem extends React.Component {

    state = {
        collapsed: false
    }

    preview = (e) => {
        e.stopPropagation();
        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed });
    }

    render() {
        const { connectDropTarget, isOverCurrent, canDrop, division, accept, currentDivisionsListMode } = this.props,
            { collapsed } = this.state;

        return connectDropTarget(
            <li style={{ borderBottom: '1px solid #e5e5e5' }} className={`py-2 ${collapsed ? 'off' : 'on'} division block font-10 droppable ${canDrop ? 'action' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>
                <span>
                    <i onClick={(e) => this.preview(e)} className={`fas font-12 fa-angle${collapsed ? '-right' : '-down'} ${(division.Flights && division.Flights.length) ? '' : 'caret-hidden'}`} />
                    <span title={division.Name} className="font-12 ml-2">{division.Name.length > 30 ? `${division.Name.substring(0, 30)}...` : division.Name}</span>
                    {division.GameDayRules && division.GameDayRules.map((rule, i) => <GameDayRuleIcon key={i} index={i} rule={rule} />)}
                </span>

                {currentDivisionsListMode === 1 && ( // GameDays, Fields
                    <ul className="flights-wrapper">
                        {division.Flights && division.Flights.map((flight, i) => <FlightListItem accept={accept} key={i} index={i} flight={flight} {...this.props} />)}
                    </ul>)}

                {currentDivisionsListMode === 2 && ( // Exceptions
                    <ul className="flights-wrapper">
                        {_.chain(division.Flights).map((f) => {
                            return f.FlightedTeams;
                        }).flatten().value().map((team, i) => <TeamListItem accept={accept} key={i} team={team} {...this.props} />)}
                    </ul>
                )}

            </li>)
    }
}

export default DropTarget(props => props.accept, divisionTarget, collect)(DivisionListItem);
