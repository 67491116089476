import React from 'react';
import SelectableUser from './login/SelectableUser';
import fakeAuth from '../fakeAuth';

class FamilyPicker extends React.Component {    
    selectUser = user => {
        if (user.Token) {
            fakeAuth.authenticate(() => {
                this.props.login({
                    token: user.Token,
                    isAdmin: user.IsLeagueAdmin
                });
                this.props.toggle && this.props.toggle();
                this.props.router.push('/family');
            }, user.Token, user.IsLeagueAdmin, user);
        }
    }
    render() {
        const { open, family = [], toggle } = this.props;
        return <div style={{ borderLeft: '1px solid gray', zIndex: 10, width: 500, right: open ? 0 : -500, position: 'fixed', top: 0, bottom: 0, overflowY: 'auto' }} className="ease bg-white p-4 ">
            <div className="d-flex flex-row justify-content-center">
                <span className="black font-16 anton">Switch family member</span>
                <i className="fa fa-times black font-18 ml-auto" onClick={toggle} />
            </div>
            <ul className="w-100 mt-4">
                {family && family.map && family.map((user, i) => <li key={i} className="selectable-user pointer">
                    <button onClick={() => this.selectUser(user)}>
                        <SelectableUser user={user} />
                    </button>
                </li>)}
            </ul>
        </div>
    }
}

export default FamilyPicker;