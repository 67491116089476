import React from 'react';
import _ from 'lodash';
import Hls from "hls.js";
import VideoSlider from './VideoSlider';
import { toSeconds } from '../../../helpers';
import config from '../../../config';

class VideoClipPreview extends React.Component {

    state = {
        microTransac: false,
        seasons: [],
        bootstrapped: false,
        coteA: 0,
        coteB: 60
    }

    componentWillMount() {
        const { video, height = 240, length, middle } = this.props;

        // Calculate
        let start = parseInt(middle - (length / 2), 10);
        if (start < 0) start = 0;
        let coteA = (start - 10) < 0 ? 0 : (start - 10), coteB = coteA + 60
        this.setState({
            video, height, length, middle, start,
            coteA,
            coteB
        });
    }

    componentDidMount() {

        if (!this.state.bootstrapped) {

            this.setState({ bootstrapped: true }, () => {
                const { start } = this.state;
                const video = this.player;
                const hls = new Hls();
                video.onpause = (e) => {

                }

                video.ontimeupdate = ({ target }) => {
                    try {
                        const { values } = this.state;
                        this.setState({ seek: parseInt(target.currentTime, 10) - values[0] });
                        if (target.currentTime > _.first(values) + (_.last(values) - _.first(values))) {
                            video.pause();
                        }
                    } catch (e) { }
                }

                // This is when I have information of the video (specially the duration)
                video.onloadedmetadata = ({ target }) => {
                    const video = this.player;
                    if (video && !this.state.times) {
                        let { coteA, coteB, start, length } = this.state;
                        if (target.duration < coteB) coteB = Math.round(target.duration);
                        if (coteB - coteA < 60 && coteA > 0) coteA = 0;
                        this.setState({
                            domain: [coteA, coteB],
                            // HERE we set the values!
                            values: [start, Math.round(start + length)],
                            times: true,
                            coteA,
                            coteB
                        });
                    }
                }
                hls.loadSource(`${config.wowzaEndpoint}/dispatcher/${this.state.video.description}/playlist.m3u8`);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    video.currentTime = start;
                    //video.play && video.play();                
                });
            });
        }
    }

    onSaveClip = () => {
        // TODO: call API
        // TODO: feedback
        const { values } = this.state;
        this.props.onSaveClip && this.props.onSaveClip({ start: _.first(values), duration: (_.last(values) - _.first(values)), description: this.txtName.value });
    }

    // SLIDER COMPONENT
    onChange = values => {
        /*this.setState(prevState => {
            const video = this.player;
            video.currentTime = toSeconds(values[0] || 0);
            return { isSeeking: false, values, isPlaying: false };
        });*/
    };

    onUpdate = ([x0, y0]) => {        
        const { values, length } = this.state;
        let x = _.first(values), y = _.last(values);        
        if (length === 10 || length === 30) {
            if (x0 !== x) {
                // Moving x axis
                let difference = x0 - x;
                this.setState({ values: [x0, y0 + difference], isPlaying: false, isSeeking: false }, () => {
                    this.player.currentTime = toSeconds(values[0] || 0);
                });
            } else {
                // Moving y axis
                let difference = y0 - y;
                this.setState({ values: [x0 + difference, y0], isPlaying: false, isSeeking: false }, () => {
                    this.player.currentTime = toSeconds(values[0] || 0);
                });
            }
        }
        else {
            this.setState({ values: [x0, y0], isPlaying: false, isSeeking: false }, () => {
                this.player.currentTime = toSeconds(values[0] || 0);
            });
        }
    };

    onSlideStart = () => {
        this.setState({ isSeeking: true }, () => {
            this.player.pause();
        });
    };

    onPlay = () => {
        this.player.play();
        this.setState({ isPlaying: true });
    }
    //


    render() {
        const { length } = this.props;
        const { microTransac, video = {}, height } = this.state;
        const { domain, values = [0, length], isPlaying, times } = this.state;
        let title = `${values[1] - values[0]}SEC CLIP`
       
        return (
            <div className="d-flex flex-column">
                <h3 className="pl-2">{title}</h3>
                <div className="w-100 p-2" style={{ position: 'relative', background: 'black' }}>
                    <video
                        poster={video.thumbnail}
                        ref={player => (this.player = player)}
                        controls={false}
                        className="bg-black w-100"                        
                        height={height}
                    />
                    {isPlaying && <span className="white font-60" style={{ position: 'absolute', right: 20, bottom: 10 }}>{this.state.seek}</span>}
                    {!isPlaying && <i onClick={this.onPlay} className="white font-60 fas fa-play" style={{ position: 'absolute', right: 20, bottom: 30 }} />}
                </div>
                <div className="pl-4 pr-4">
                    {times && <VideoSlider
                        domain={domain}
                        values={values}
                        step={1}
                        mode={2}
                        onChange={this.onChange}
                        onUpdate={this.onUpdate}
                        onSlideStart={this.onSlideStart}
                    />}
                </div>
                <div className="mb-2 ml-2 mr-2" style={{ border: '1px solid gray', borderRadius: 2 }}>
                    <input className="input100" type="text" placeholder="Video Name" ref={(input) => this.txtName = input} />
                </div>
                <button type="button" className="btn btn-success align-self-end mr-2 mb-4" onClick={this.onSaveClip} >
                    {!microTransac && <span>Save Clip</span>}
                    {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                </button>
            </div>)

    }
}

export default VideoClipPreview;
