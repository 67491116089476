

import React from 'react';
import moment from 'moment';

class CustomDatePicker2 extends React.Component {
    state = {
        value: this.props.value
    }
    componentWillReceiveProps = nextProps => {
        const { value } = nextProps;        
        this.setState({ value });
    }
    render() {
        const { className } = this.props, { value } = this.state;
        return (
            <button onClick={this.props.onClick} className={`d-flex flex-row justify-content-center p-2 ${className}`} >
                <div className="d-flex flex-column justify-content-center" style={{ width: 80 }}>
                    <i className="far fa-calendar-alt font-20 align-self-center" />
                    <span className="font-8 line1 mt-2 text-center w-100">{value ? moment(value).format('MMM Do') : 'Select'}</span>
                    <span className="font-8 line1 text-center w-100">{value ? moment(value).format('YYYY') : 'Date'}</span>
                </div>
                {/*<span className="font-12 align-self-center">@</span>
                <div className="align-self-center mx-3 d-flex flex-column">
                    <span className="white font-12">{value ? moment(value).format('hh:mmA') : 'Time'}</span>
                    <span className="white font-8">{value ? moment(value).format() : 'Time'}</span>
        </div>*/}
            </button>
        )
    }
}

export default CustomDatePicker2;
