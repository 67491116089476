import {
  call,
  put
} from 'redux-saga/effects';
import {
  takeEvery
} from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getWaiverSaga() {
  yield* takeEvery("WAIVER/NON_SECURE/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague, idUser) {
        const url = [config.apiEndpoint, 'api/v4/waivers', idLeague, idUser].join('/');

        return request.get(url)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague, action.idUser);

      if (result && result.success) {
        yield put({
          type: 'WAIVER/SET',
          waiver: result.waiver || null
        });

      } else {
        yield put({
          type: 'WAIVER/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'WAIVER/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getDisclaimerSaga() {
  yield* takeEvery("DISCLAIMER/NON_SECURE/FETCH", function* (action) {

    try {
      const result = yield call(({ idOrder, idOrderItem }) => {

        const url = `${config.apiEndpoint}/api/external/disclaimers/${idOrder ? 'by_order' : 'by_order_item'}/${idOrder || idOrderItem}`;
        return request.get(url)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'DISCLAIMER/SET',
          disclaimer: result.disclaimer || null
        });

      } else {
        yield put({
          type: 'DISCLAIMER/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'DISCLAIMER/FETCH_FAILED',
        e
      });
    }
  });
}