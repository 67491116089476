import React from 'react';
import RegistrationProgram from './registration_programs/card';
import Adjustments from './adjustments/card';
import ZipCodeAdjustment from './zip/card';
import FamilyAdjustment from './family/card';
import Tryout from './tryouts/card';

class Bundle extends React.Component {
    state = {
        bundle: {}
    }
    componentWillMount() {
        const { bundle } = this.props;
        this.setState({ bundle })
    }
    componentWillReceiveProps = nextProps => {
        const { bundle } = nextProps;
        this.setState({ bundle });
    }

    onDeleteProgram = (index) => {
        const { bundle } = this.state, { programs, division } = bundle;
        this.props.divisionsActions.removeProgram(programs[index], division);
        bundle.programs = [...bundle.programs.slice(0, index), ...bundle.programs.slice(index + 1)];
        this.setState({ bundle });
    }
    onDeleteAdjustment = (index) => {
        const { bundle } = this.state, { adjustments, division } = bundle;
        this.props.divisionsActions.removeAdjustment(adjustments[index], division);
        bundle.adjustments = [...bundle.adjustments.slice(0, index), ...bundle.adjustments.slice(index + 1)];
        this.setState({ bundle });
    }
    onDeleteZip = index => {
        const { bundle } = this.state, { zipCodesAdjustments, division } = bundle;
        this.props.divisionsActions.removeZipCode(zipCodesAdjustments[index], division);
        bundle.zipCodesAdjustments = [...bundle.zipCodesAdjustments.slice(0, index), ...bundle.zipCodesAdjustments.slice(index + 1)];
        this.setState({ bundle });
    }
    onDeleteFamilyAdjustment = index => {
        const { bundle } = this.state, { familyAdjustments, division } = bundle;
        this.props.divisionsActions.removeFamilyAdjustment(familyAdjustments[index], division);
        bundle.familyAdjustments = [...bundle.familyAdjustments.slice(0, index), ...bundle.familyAdjustments.slice(index + 1)];
        this.setState({ bundle });
    }
    onDeleteTryout = index => {
        const { bundle } = this.state;
        bundle.tryouts = [...bundle.tryouts.slice(0, index), ...bundle.tryouts.slice(index + 1)];
        this.setState({ bundle });
        // TODO: Call API and update store
    }

    render() {
        const { bundle = {} } = this.state, { division, programs, adjustments, zipCodesAdjustments, familyAdjustments, tryouts } = bundle
        return <div className="w-100 h-100 p-4" style={{ overflowY: 'auto' }}>
            <div className="card tile shadow-box rounded-item align-self-center w-100">
                {division && <div className="d-flex flex-row justify-content-start montserrat" style={{ height: 60 }}>
                    <div style={{ width: 80 }} className="d-flex flex-row justify-content-center">
                        <i className={`icon-${division.IdGender === 1 ? 'man' : 'woman'} ${division.IdGender === 1 ? 'blue' : 'red'} font-20 align-self-center`} />
                    </div>
                    <div className="d-flex flex-column align-self-center">
                        <span className="black font-12 line1">{division.DivisionName || division.Name}</span>
                    </div>
                </div>}
            </div>

            {programs && programs.map((program, index) => <RegistrationProgram program={program} key={index} unmovable={true} fnDelete={() => this.onDeleteProgram(index)} />)}
            {adjustments && adjustments.map((adjustment, index) => <Adjustments program={adjustment} key={index} unmovable={true} fnDelete={() => this.onDeleteAdjustment(index)} />)}
            {zipCodesAdjustments && zipCodesAdjustments.map((zip, index) => <ZipCodeAdjustment program={zip} key={index} unmovable={true} fnDelete={() => this.onDeleteZip(index)} />)}
            {familyAdjustments && familyAdjustments.map((fa, index) => <FamilyAdjustment program={fa} key={index} unmovable={true} fnDelete={() => this.onDeleteFamilyAdjustment(index)} />)}
            {tryouts && tryouts.map((to, index) => <Tryout program={to} key={index} unmovable={true} fnDelete={() => this.onDeleteTryout(index)} />)}
        </div>
    }
}

export default Bundle;