import React from 'react';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import LineLoader from '../../../common/LineLoader';
import moment from 'moment';
import Game from './partials/game';
import { Popover, PopoverBody, } from 'reactstrap';
import { Animated } from 'react-animated-css';

import './css.css';
import EmptyPayload from '../../../EmptyPayload';

class Comp extends React.Component {

  state = {
    bootstrapped: false,
    isDivisionsOpen: false,
    isLocationsOpen: false,
    isDatesOpen: false,
    isTimesOpen: false,
    fetchingGames: false,
    searchText: null,
    typeOfGame: 1, // [1: all, 2: poolplay, 3: playoff]
    selected: {
      division: null,
      date: null,
      time: null,
      location: null
    }
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Score Entry',
      main: this.props.season.Name || null
    });
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.seasonActions && this.props.seasonActions.fetchFilters(params.idSeason);
  }
  componentWillReceiveProps = (nextProps) => {
    // The sequence of this is always tricky
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Score Entry',
        main: nextProps.season.Name || null
      });
    }
    if (nextProps.filters && !this.state.fetchingGames && !this.state.selected.date) {
      const { selected } = this.state, { filters } = nextProps;
      if (filters.DateFilters && filters.DateFilters.length) {
        selected.date = filters.DateFilters[0];
      }
      this.setState({ selected }, () => {
        if (selected.date) this.fetchGames();
      });
    }
    if (nextProps.games && this.state.fetchingGames) {
      this.setState({ fetchingGames: false, bootstrapped: true });
    }
  }
  //

  fetchGames = () => {
    const { selected } = this.state, { params } = this.props;
    this.setState({ fetchingGames: true }, () => {
      this.props.seasonActions.fetchScoreEntryGames(params.idSeason, moment(selected.date.DateStart, 'MM/DD/YYYY').format('YYYYMMDD'));
    });
  }

  toggleDivisions = () => this.setState({ isDivisionsOpen: !this.state.isDivisionsOpen });
  toggleLocations = () => this.setState({ isLocationsOpen: !this.state.isLocationsOpen });
  toggleDates = () => this.setState({ isDatesOpen: !this.state.isDatesOpen });
  toggleTimes = () => this.setState({ isTimesOpen: !this.state.isTimesOpen });
  changeGameType = (typeOfGame) => this.setState({ typeOfGame });

  selectDivision = (division) => {
    const { selected } = this.state;
    selected.division = division;
    this.setState({ selected, isDivisionsOpen: false });
  }
  selectLocation = (location) => {
    const { selected } = this.state;
    selected.location = location;
    this.setState({ selected, isLocationsOpen: false });
  }
  selectDate = (date) => {
    const { selected } = this.state;
    selected.date = date;
    this.setState({ selected, isDatesOpen: false }, () => {
      this.fetchGames();
    });
  }
  selectTime = (time) => {
    const { selected } = this.state;
    selected.time = time;
    this.setState({ selected, isTimesOpen: false });
  }
  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  }
  clearSearch = () => {
    this.txtSearch.value = null;
    this.setState({ searchText: null });
  }
  refresh = () => {
    this.fetchGames();
  }

  render() {
    const { bootstrapped, typeOfGame, selected, fetchingGames, searchText } = this.state, { filters, games } = this.props;

    let filteredGames = _.chain(games || [])
      .filter(g => {
        switch (typeOfGame) {
          case 1: return true;
          case 2: return !g.IsPlayoff;
          case 3: return g.IsPlayoff;
          default: return false;
        }
      })
      .filter(g => {
        if (selected.division) {
          return g.IdDivision === selected.division.IdDivision;
        }
        return true;
      })
      .filter(g => {
        if (selected.location) {
          return parseInt(g.IdLocation, 10) === parseInt(selected.location.IdLocation, 10);
        }
        return true;
      })
      .filter(g => {
        if (selected.time) {
          return g.DateTimeStart_Time_Format === selected.time.Time;
        }
        return true;
      })
      .filter(g => {
        if (searchText) {
          return (
            ((g.IdGame + '').indexOf(searchText) !== -1)
            ||
            ((g.home || { Name: '' }).Name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            ||
            ((g.away || { Name: '' }).Name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1))
        }
        return true
      })
      .orderBy('DateTimeStart')
      .value();

    return <section id="score-entry" className="p-2 " style={{ backgroundColor: '#333'}}>
      {fetchingGames && <LineLoader />}
      {bootstrapped &&
        <div className="filters-wrapper d-flex flex-column white">
          <div className="d-flex flex-row">
            {/* Division */}
            <button onClick={this.toggleDivisions} className="btn font-10 btn-link white" id="btn-divisions-filters">
              <i className="fa fa-list" />
              <span className="idented-half">{selected.division ? selected.division.Name : 'Division'}</span>
              <i className="fa fa-caret-down idented-half" />
            </button>
            <Popover placement="bottom" isOpen={this.state.isDivisionsOpen} target="btn-divisions-filters" toggle={this.toggleDivisions}>
              <PopoverBody className="no-margin no-padding" style={{ maxHeight: 200, overflowY: 'auto' }}>
                {/* All */}
                <button onClick={() => this.selectDivision()} className={`btn btn-${(!selected.division ? 'info' : 'link')} btn-sm btn-block text-left`}>All</button>
                {/* Items */}
                {filters.DivisionFilters && filters.DivisionFilters.map((division, i) => <button onClick={() => this.selectDivision(division)} className={`btn btn-${((selected.division && selected.division.IdDivision === division.IdDivision) ? 'info' : 'link')} btn-sm btn-block text-left`} key={i}>{division.Name}</button>)}
              </PopoverBody>
            </Popover>

            {/* Location */}
            <button onClick={this.toggleLocations} className="btn font-10 btn-link white" id="btn-locations-filters">
              <i className="fa fa-map-marker" />
              <span className="idented-half">{selected.location ? selected.location.Name : 'Location'}</span>
              <i className="fa fa-caret-down idented-half" />
            </button>
            <Popover placement="bottom" isOpen={this.state.isLocationsOpen} target="btn-locations-filters" toggle={this.toggleLocations}>
              <PopoverBody className="no-margin no-padding" style={{ maxHeight: 200, overflowY: 'auto' }}>
                {/* All */}
                <button onClick={() => this.selectLocation()} className={`btn btn-${(!selected.location ? 'info' : 'link')} btn-sm btn-block text-left`}>All</button>
                {filters.LocationFilters && filters.LocationFilters.map((location, i) => <button onClick={() => this.selectLocation(location)} className={`btn btn-${((selected.location && selected.location.IdLocation === location.IdLocation) ? 'info' : 'link')} btn-sm btn-block text-left`} key={i}>{location.Name}</button>)}
              </PopoverBody>
            </Popover>

            {/* Calendar */}
            <button id="btn-dates-filters" onClick={this.toggleDates} className="btn btn-link white idented font-10">
              <i className="fa fa-calendar" />
              <span className="idented-half">{selected.date ? moment(selected.date.DateStart, 'MM/DD/YYYY').format('MMM Do YYYY') : 'No dates available'}</span>
              <i className="fa fa-caret-down idented-half" />
            </button>
            <Popover placement="bottom" isOpen={this.state.isDatesOpen} target="btn-dates-filters" toggle={this.toggleDates}>
              <PopoverBody className="no-margin no-padding" style={{ maxHeight: 200, overflowY: 'auto' }}>
                {filters.DateFilters && filters.DateFilters.map((date, i) => <button onClick={() => this.selectDate(date)} className={`btn btn-${((selected.date && selected.date.DateStart === date.DateStart) ? 'info' : 'link')} btn-sm btn-block text-left`} key={i}>{moment(date.DateStart, 'MM/DD/YYYY').format('MMM Do YYYY')}</button>)}
              </PopoverBody>
            </Popover>

            {/* Time */}
            <button id="btn-times-filters" onClick={this.toggleTimes} className="btn btn-link white idented font-10">
              <i className="fa fa-clock" />
              <span className="idented-half">{selected.time ? selected.time.Time : 'Time'}</span>
              <i className="fa fa-caret-down idented-half" />
            </button>
            <Popover placement="bottom" isOpen={this.state.isTimesOpen} target="btn-times-filters" toggle={this.toggleTimes}>
              <PopoverBody className="no-margin no-padding" style={{ maxHeight: 200, overflowY: 'auto' }}>
                {/* All */}
                <button onClick={() => this.selectTime()} className={`btn btn-link btn-sm btn-block text-left`}>All</button>
                {filters.TimeFilters && filters.TimeFilters.map((time, i) =>
                  <button onClick={() => this.selectTime(time)} className={`btn btn-${((selected.time && selected.time.Time === time.Time) ? 'info' : 'link')} btn-sm btn-block text-left`} key={i}>{time.Time}</button>)}
              </PopoverBody>
            </Popover>



            {/* Right */}
            <div className="d-flex flex-row ml-auto">
              <button onClick={() => this.changeGameType(2)} className="btn font-10 btn-link white idented">
                <i className={`${typeOfGame === 2 ? 'fa fa-circle green' : 'far fa-circle'}`} />
                <span className="idented-half">Poolplay</span>
              </button>
              <button onClick={() => this.changeGameType(3)} className="btn font-10 btn-link white idented">
                <i className={`${typeOfGame === 3 ? 'fa fa-circle green' : 'far fa-circle'}`} />
                <span className="idented-half">Playoffs</span>
              </button>
              <button onClick={() => this.changeGameType(1)} className="btn font-10 btn-link white idented">
                <i className={`${typeOfGame === 1 ? 'fa fa-circle green' : 'far fa-circle'}`} />
                <span className="idented-half">All</span>
              </button>
            </div>
          </div>
          <div>
            {/* Search BAR */}
            <div className="input-group align-self-center">
              <div className="input-group-prepend btn-success" onClick={this.refresh}>
                <span className="input-group-text"><i className="fas fa-sync margin-right-half" />Refresh</span>
              </div>
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-search" /></span>
              </div>
              <input ref={(i) => this.txtSearch = i} type="text" className="form-control" placeholder="Search" onChange={this.handleSearch} />
              <div className="input-group-append" onClick={this.clearSearch}>
                <span className="input-group-text"><i className="fa fa-times" /></span>
              </div>
            </div>
          </div>
        </div>}

      <Animated animationIn="fadeUp" className="work-area">
        {bootstrapped && games && games.length && (
          <div key="with-data" className=" d-flex align-items-stretch">

            <table style={{ borderBottom: '1px solid white' }} className="table table-sm w-100">
              <thead className="bg-info white">
                <tr>
                  <th>Game Id</th>
                  <th>Flight</th>
                  <th>Type</th>
                  <th>Location</th>
                  <th>Date</th>
                  <th className="text-right">Home</th>
                  <th className="text-center">Score</th>
                  <th>Away</th>
                </tr>
              </thead>
              <tbody className="white">
                {games && filteredGames.map((game, i) => <Game game={game} key={game.IdGame} index={i} gamesActions={this.props.gamesActions} />)}
              </tbody>
            </table>
          </div>
        )}
        {!bootstrapped && <Loader key="loader" message={`Loading Season Assets`} />}
        {bootstrapped && (!games || (games && !games.length)) && <EmptyPayload message="Currently there are no Games Scheduled" />}
      </Animated>
    </section>
  }
}

export default Comp;
