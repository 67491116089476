import React from 'react';

class Comp extends React.Component {
  componentWillMount() {
    this.setState({ team: this.props.team });
  }
  errorImage = () => {
    const { team } = this.state;
    team.Logo = '/images/defaults/teamLogo.png';
    this.setState({ team });
  }
  render() {
    const { team } = this.state;
    return (
      <table className="w-100" style={{ height: 40 }} border="1">
        <tbody>
          <tr>
            <td className="text-center p-1" style={{ verticalAlign: 'top', width: 70 }}>
              <div className="d-flex flex-column justify-content-center">
                <strong>{team.HomeAway}</strong>
                <img alt="" src={team.Logo} width="30px" onError={this.errorImage} className="align-self-center" />
              </div>
            </td>
            <td className="p-1" style={{ verticalAlign: 'top' }}>
              <div>
                <strong>{team.Name}</strong>
                {' '}
                <span className="font-7">({team.IdTeamDivision})</span>
              </div>
              <div className="font-8">Coach:
                {team.Coach ? team.Coach.Name : null}
              </div>
            </td>
            <td className="text-center p-1" style={{ verticalAlign: 'top', width: 80 }}>
              <span>Final Score</span>
              {team.Score !== null && <h2>{team.Score}</h2>}
            </td>
          </tr>
        </tbody>
      </table>)
  }
}

export default Comp;