
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as spotlightActions from '../redux-sagas/actions/sportlightActions';
import * as usersActions from '../redux-sagas/actions/usersActions';
import * as geoActions from '../redux-sagas/actions/geoActions';
import * as rosterActions from '../redux-sagas/actions/rosterActions';
import Main from '../components/Main.Family';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        auth: state.auth,
        carriers: state.carriers,
        family: state.family,
        emails: state.emails,
        loggedIn: state.loggedIn,
        phones: state.phones,
        spotlight: state.spotlight,
        spotlightOn: state.spotlightOn,
        title: state.title,
        users: state.users,
        limits: state.limits,
        information: state.information,
        countries: state.countries,
        states: state.states,
        validations_summary: state.validations_summary,
        user: state.user,
        college_profile: state.college_profile,
        country_codes: state.country_codes,
        violations: state.violations,
        violation_types: state.violation_types,
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        familyActions: bindActionCreators(familyActions, dispatch),
        leagueActions: bindActionCreators(leagueActions, dispatch),
        spotlightActions: bindActionCreators(spotlightActions, dispatch),
        usersActions: bindActionCreators(usersActions, dispatch),
        geoActions: bindActionCreators(geoActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
