import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, formatPrice, getOrdinal } from '../../../../../../helpers';
import moment from 'moment';
import _ from 'lodash';

const source = {
  beginDrag(props) {
    return props;
  }
}

class RegistrationProgramCard extends React.Component {

  renderInfo = () => {
    const { isDragging, program, unmovable, fnEdit, fnDelete } = this.props;

    return (
      <li style={{ marginRight: unmovable ? 0 : '1em' }} className={`program-card shadow p-4 bg-danger d-flex flex-column ${isDragging ? 'dragging' : ''} ${unmovable ? 'w-100' : ''}`}>
        <span className="white font-16 text-left toBold">{program.Name}</span>
        <span className="white font-10">From {moment(program.DateRegistrationStart).format('MMM Do YYYY')} to {moment(program.DateRegistrationEnd).format('MMM Do YYYY')}</span>
        <hr className="bg-white white" />
        
        {program.paymentPlans.length === 0 && <div className='d-flex flex-row'>
          <span className="white">Full Payment</span>
          <span className="ml-auto white">{formatPrice(program.Price * 100)}</span>
        </div>}
        
        {program.paymentPlans.length > 0 && <section>
          <div className='d-flex flex-row'>
            <span className="white">Full Payment</span>
            <span className="ml-auto white">{formatPrice(program.Price * 100)}</span>
          </div>
          <hr className="bg-white white" />
          <div className='d-flex flex-row'>
            <span className="white">Payment Plan Fee</span>
            <span className="ml-auto white">{formatPrice(_.first(program.paymentPlans).FeeTransactionInitial * 100)}</span>
          </div>
          <div className='d-flex flex-row'>
            <span className="white">Initial Payment</span>
            <span className="ml-auto white">{formatPrice(program.InitialPayment * 100)}</span>
          </div>
          {program.paymentPlans.map((due, i) => (
            <div className='d-flex flex-row font-8' key={i}>
              <span className="white">{getOrdinal(i + 1)} Payment (Due {moment(due.Date).utc().format('MM/DD/YYYY')})</span>
              <span className="ml-auto white">{formatPrice(due.Price * 100)}</span>
            </div>
          ))}
        </section>}

        {!unmovable && <button className="white font-8 pointer hint" style={{ padding: '0 0.5em', borderRadius: 10, border: '1px solid white', height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnEdit(program)}>
          <i className="fas fa-edit" />{' '}Edit</button>}

        {!unmovable && <span className="fas fa-grip-vertical white font-14 grab" style={{ position: 'absolute', top: 2, left: 4 }} />}

        {fnDelete && <button className="white font-8 pointer  btn-danger" style={{ padding: '0 0.5em', borderRadius: 10, height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnDelete()}>
          <i className="fas fa-trash" />{' '}Remove</button>}
      </li>)
  }

  render() {
    const { connectDragPreview, connectDragSource, unmovable } = this.props;
    if (!unmovable) {
      return connectDragPreview(connectDragSource(
        this.renderInfo()))
    } else {
      return this.renderInfo();
    }
  }

}

export default flow(
  DragSource(ItemTypes.REGISTRATIONFEE, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(RegistrationProgramCard);
