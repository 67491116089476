import React from 'react';
import DivisionsList from './divisions';
import { DropTarget } from 'react-dnd';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { ItemTypes } from '../../../../../helpers';
import { Popover, PopoverBody, } from 'reactstrap';

const target = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)
        if (monitor.didDrop()) { return; }

        const { divisions, divisionsActions, params } = props;
        // Evaluate if the division already has a registration program. If it does, prompt for confirmation of replace.
        const { lock, type, } = monitor.getItem();
        switch (type) {
            case ItemTypes.ROSTERLOCK:
                divisions.forEach(division => {
                    divisionsActions &&
                        divisionsActions.assignRosterLock(division.IdDivision, lock.IdTeamUserRoleLock, params.idSeason);
                });
                break;
            default: break;
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class LeftSide extends React.Component {

    state = {
        locked: false,
        settingLimit: false,
        season: null,
    }

    componentWillMount() {
        const { season, divisions } = this.props;
        let numbers = _.chain(divisions).map(d => parseInt(d.RosterLimit, 10)).uniq().value();
        if (numbers.length > 1) {
            season.RosterLimit = 0;
        } else {
            season.RosterLimit = _.first(numbers);
        }

        this.setState({ season });
    }
    componentWillReceiveProps = (nextProps) => {
        const { season, divisions } = nextProps;
        let numbers = _.chain(divisions).map(d => parseInt(d.RosterLimit, 10)).uniq().value();
        if (numbers.length > 1) {
            season.RosterLimit = 0;
        } else {
            season.RosterLimit = _.first(numbers);
        }

        this.setState({ season });
    }

    toggleLock = () => {
        this.setState({ locked: !this.state.locked }, () => {
            const { locked } = this.state, { divisions, divisionsActions } = this.props;
            divisions.forEach(d => {
                divisionsActions && divisionsActions[locked ? 'lock' : 'unlock'](d.IdDivision);
                d.IsLocked = locked;
            });
            this.forceUpdate();
        });
    }

    setlimit = (e) => {
        e.preventDefault && e.preventDefault();
        const { divisions, divisionsActions } = this.props, { season } = this.state;
        season.RosterLimit = this.txtLimit.value;
        divisionsActions && divisionsActions.setLimit({ idSeason: season.IdSeason, limit: this.txtLimit.value });
        divisions.forEach(division => {
            division.RosterLimit = this.txtLimit.value;
        });
        this.setState({ settingLimit: false });
    }

    toggleSetLimit = () => this.setState({ settingLimit: !this.state.settingLimit });

    render() {
        const { connectDropTarget, isOverCurrent, canDrop } = this.props, { locked, settingLimit, season } = this.state;
        return connectDropTarget(
            <div className="bg-white full-height left-side">
                <h3 className="bg-info text-center">Divisions</h3>
                <ul className="seasons-wrapper w-100 no-margin no-padding">
                    <li className={`${canDrop ? 'debug' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>
                        <span>{canDrop ? 'Drop here to apply to all the divisions' : season.Name}
                            {canDrop && <i className="idented-half fas fa-arrow-left red" />} <i onClick={this.toggleLock} className={`margin-right-half fas fa-lock${locked ? ' red' : '-open black'}`} data-tip={`${locked ? 'Locked' : 'Open'}`} data-for={`lock-${season.IdSeason}`}>
                                <ReactTooltip effect="solid" place="top" id={`lock-${season.IdSeason}`} />
                            </i>
                            <button onClick={this.toggleSetLimit} id={`id-${season.IdSeason}-season-limit`} className={`btn btn-sm font-8 no-padding align-self-center ${season.RosterLimit ? 'red' : 'blue'}`}>{season.RosterLimit ? `Limit ${season.RosterLimit} players` : 'Set Limit'}</button>
                            <Popover placement="bottom" isOpen={settingLimit} target={`id-${season.IdSeason}-season-limit`} toggle={this.toggleSetLimit}>
                                <PopoverBody>
                                    <form className="d-flex flex-row">
                                        <input defaultValue={season.RosterLimit || 0} ref={(i) => this.txtLimit = i} style={{ width: 80 }} type="number" className="form-control" />
                                        <button type="submit" onClick={this.setlimit} className="btn btn-success"><i className="fa fa-check white" /></button>
                                        <button type="button" onClick={this.toggleSetLimit} className="btn btn-danger"><i className="fa fa-times white" /></button>
                                    </form>
                                </PopoverBody>
                            </Popover>
                        </span>
                        <DivisionsList {...this.props} />
                    </li>
                </ul>
            </div>
        )
    }
}

export default DropTarget([ItemTypes.ROSTERLOCK], target, collect)(LeftSide);
