import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../helpers';

const bracketPlacementSource = {
    beginDrag(props) { return props; }
}

class BracketPlacementDraggable extends React.Component {
    render() {
        const { connectDragSource, connectDragPreview, game, placements } = this.props;

        const selected = placements.indexOf(`Loser of game ${game.IdGame}`) !== -1;

        return connectDragPreview(connectDragSource(
            <li className="placement-item d-flex flex-row">
                {selected && <i className="fas fa-check green margin-right-half" />}
                <span className="align-self-center font-10">{`Loser of game ${game.IdGame}`}</span>
                <i className="align-self-center ml-auto fas fa-arrows-alt font-8 blue"></i>
            </li>)
        )
    }
}

export default flow(
    DragSource(ItemTypes.BRACKETPLACEMENT, bracketPlacementSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }))
)(BracketPlacementDraggable)
