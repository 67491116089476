import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getLibrarySaga() {
  yield* takeEvery("LIBRARY/FETCH", function* (action) {

    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v4/questions/library/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { answers_texts, question_texts, questions, groups } = result;
        yield put({
          type: 'ANSWERS/SET',
          answers: answers_texts
        });
        yield put({
          type: 'QUESTION_TEXTS/SET',
          questions: question_texts
        });
        yield put({
          type: 'QUESTIONS/SET',
          questions
        });
        yield put({
          type: 'QUESTION_GROUPS/SET',
          groups
        });

        // A flag to tell the store that the library is set
        yield put({
          type: 'LIBRARY/SET',
          library: true
        });

      } else {
        yield put({
          type: 'LIBRARY/FETCH_FAILED',
          result
        });
        // A flag to tell the store that the library is set
        yield put({
          type: 'LIBRARY/CLEAR'
        });
      }
    } catch (e) {
      yield put({
        type: 'LIBRARY/FETCH_FAILED',
        e
      });
      // A flag to tell the store that the library is set
      yield put({
        type: 'LIBRARY/CLEAR'
      });
    }
  });
}

// /APPLIED_QUESTIONS/FETCH
export function* getAppliedQuestionsSaga() {
  yield* takeEvery("APPLIED_QUESTIONS/FETCH", function* (action) {

    try {
      const result = yield call(({ idSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v4/questions/season/${idSeason}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { groups } = result;
        yield put({
          type: 'APPLIED_QUESTIONS/SET',
          groups
        });
      }
    } catch (e) {
      yield put({
        type: 'APPLIED_QUESTIONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* createQandASaga() {
  yield* takeEvery("QUESTIONS/ADD", function* (action) {
    try {
      const result = yield call(({ question, idLeague }) => {
        return request.post(`${config.apiEndpoint}/api/v4/questions/library/question/${idLeague}`)
          .send(question)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'LIBRARY/FETCH',
          idLeague: action.idLeague
        });
      } else {
        yield put({
          type: 'QUESTIONS/ADD/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'QUESTIONS/ADD/FETCH_FAILED',
        e
      });
    }
  });
}

export function* deleteQandASaga() {
  yield* takeEvery("QUESTIONS/REMOVE", function* (action) {
    try {
      const result = yield call(({ idQuestion }) => {
        return request.del(`${config.apiEndpoint}/api/v4/questions/library/question/${idQuestion}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
      } else {
        yield put({
          type: 'QUESTIONS/REMOVE_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'QUESTIONS/REMOVE_FAILED',
        e
      });
    }
  });
}

//QUESTION_TEXT/REMOVE
export function* deleteQuestionTextSaga() {
  yield* takeEvery("QUESTION_TEXT/REMOVE", function* (action) {
    try {
      const result = yield call(({ id }) => {
        return request.del(`${config.apiEndpoint}/api/v4/questions/library/question_text/${id}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        // Optimistic. Nothing to do here
      } else {
        yield put({
          type: 'QUESTION_TEXT/REMOVE_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'QUESTION_TEXT/REMOVE_FAILED',
        e
      });
    }
  });
}

//ANSWERS/REMOVE
export function* deleteAnswerTextSaga() {
  yield* takeEvery("ANSWERS/REMOVE", function* (action) {
    try {
      const result = yield call(({ id }) => {
        return request.del(`${config.apiEndpoint}/api/v4/questions/library/answer_text/${id}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        // Optimistic. Nothing to do here
      } else {
        yield put({
          type: 'ANSWERS/REMOVE_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'ANSWERS/REMOVE_FAILED',
        e
      });
    }
  });
}

//QUESTION_GROUPS/ADD
export function* createQuestionGroupSaga() {
  yield* takeEvery("QUESTION_GROUPS/ADD", function* (action) {
    try {
      const result = yield call(({ group }) => {
        return request.post(`${config.apiEndpoint}/api/v4/questions/library/group/${group.IdLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send(group)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        action.group.IdQuestionLibraryGroup = result.IdQuestionLibraryGroup
        yield put({
          type: 'QUESTION_GROUPS/UPDATE',
          group: action.group
        });
        // Optimistic. Nothing to do here
      } else {
        yield put({
          type: 'QUESTION_GROUPS/ADD_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'QUESTION_GROUPS/ADD_FAILED',
        e
      });
    }
  });
}

//QUESTION_GROUPS/UPDATE
export function* updateQuestionGroupSaga() {
  yield* takeEvery("QUESTION_GROUPS/EDIT", function* (action) {
    try {
      yield call(({ group }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/questions/library/group/${group.IdQuestionLibraryGroup}/${group.IdLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send(group)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
    } catch (e) {
      yield put({
        type: 'QUESTION_GROUPS/UPDATE_FAILED',
        e
      });
    }
  });
}

export function* deleteQuestionGroupSaga() {
  yield* takeEvery("QUESTION_GROUPS/REMOVE", function* (action) {
    try {
      yield call(({ id }) => {
        return request.del(`${config.apiEndpoint}/api/v4/questions/library/group/${id}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))          
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      yield put({
        type: 'LIBRARY/FETCH',
        idLeague: action.idLeague
      });

    } catch (e) {
      yield put({
        type: 'QUESTION_GROUPS/REMOVE_FAILED',
        e
      });
    }
  });
}