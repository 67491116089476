import React from 'react';
import { Modal, ModalBody, ModalFooter, Input, ModalHeader } from 'reactstrap';
import { Link } from 'react-router';
import _ from 'lodash';
import LineLoader from '../LineLoader';

class EditVideo extends React.Component {

    state = {
        bootstrapped: false,
        video: null,
        mode: 1,
        family: []
    }

    // Lifecycle
    componentWillMount() {
        const { video, family } = this.props;
        this.setState({ video, family: [...family] }, () => {
            const { family } = this.state;
            family.forEach(user => { user.selected = video.playerTaggedId === user.IdUser ? true : false });
            this.setState({ family });
        });
    }

    //   

    save = () => {
        const { video, params } = this.props, { family } = this.state;
        this.props.teamActions && this.props.teamActions.updateVideo({
            ...video, name: this.txtName.value,
            idTeam: params.idTeam || 1,
            playerTaggedId: (_.find(family, u => u.selected) || {}).IdUser,
            isSubmittedTop10: (_.find(family, u => u.selected) || {}).IdUser ? true : false
        });
        this.props.toggle();
    }

    onSelectSeason = (e) => {
        const { video } = this.state, { seasons } = this.props;
        const season = _.find(seasons, c => c.IdSeason === parseInt(e.target.value, 10));
        video.IdSeason = season ? season.IdSeason : null;
        video.seasonName = season ? (season.SeasonName || season.Name) : null;
        video.idTeamDivision = season ? season.IdTeamDivision : null;
        this.setState({ video });
    }

    onToggleUser = IdUser => {
        const { family } = this.state, { family_subscriptions } = this.props;
        if (_.find(family_subscriptions, s => s.IdUser === IdUser && !s.Expired)) {
            family.forEach(u => {
                if (u.IdUser === IdUser) {
                    u.selected = !u.selected;
                } else {
                    u.selected = false;
                }
            });
            this.setState({ family });
        } else {
            // TODO: propmt premium enroll
            this.setState({ mode: 2 });
        }
    }

    onDelete = () => {
        const { toggle, params } = this.props, { video } = this.state;
        this.props.teamActions && this.props.teamActions.deleteVideo(video, params.idTeam || 1);
        toggle && toggle();
    }

    render() {
        const { isOpen, toggle, seasons, family_subscriptions } = this.props,
            { video = {}, microTransac, family, mode } = this.state;

        let familyChunks = _.chunk(family || [], 4);
        
        return (
            <Modal isOpen={isOpen} style={{ width: 400 }}>
                <ModalHeader toggle={toggle}>
                    Edit Video
                </ModalHeader>
                {(mode === 1 || mode === 4) && <ModalBody className="container-fluid no-margin no-padding">
                    {microTransac && <LineLoader />}
                    <div className="margin-bottom margin-top pl-4 pr-4 d-flex flex-column">
                        <div className="input-group w-100 align-self-center">
                            <input type="text" defaultValue={video.name} className="form-control"
                                placeholder={`${video.isHighlight ? 'Clip' : 'Video'} Name`} ref={(i) => this.txtName = i} />
                        </div>
                        {!video.isHighlight && !video.isClip && <div className="input-group w-100 align-self-center mt-2">
                            <Input className="" type="select" value={video.IdSeason} onChange={this.onSelectSeason}>
                                <option value={null}>Select Season</option>
                                {seasons && seasons.map((c, i) => <option key={i} value={c.IdSeason}>{c.Name || c.SeasonName}</option>)}
                            </Input>
                        </div>}
                        {(video.isHighlight === 1 || video.isClip === 1 || true) && <div className="mt-2">

                            <span className="black font-10">Assign video | highlight</span>

                            {familyChunks.map((chunk, i) => <div key={i} style={{ height: 100 }} className=" d-flex flex-row justify-content-middle">
                                {chunk.map((user, j) => (
                                    <div onClick={() => this.onToggleUser(user.IdUser)} className="tile card w-100 d-flex flex-column justify-content-middle" key={j}>
                                        <div style={{ opacity: user.selected ? 1 : 0.6, width: 50, height: 50, borderRadius: 25, border: `${user.selected ? 2 : 1}px solid ${user.selected ? 'green' : 'black'}`, background: `url(${user.UserImage}) no-repeat center center`, backgroundSize: 'cover' }} className="ml-auto mr-auto mt-auto d-flex flex-row justify-content-center" />
                                        <span className="black font-10 text-center line1 mt-1">{user.NameFirst}</span>
                                        <span className={`${_.find(family_subscriptions, s => s.IdUser === user.IdUser && !s.Expired) ? 'black' : 'white'} font-10 sqwad-font text-center`}>PRO</span>
                                    </div>
                                ))}
                            </div>)}

                        </div>}
                    </div>
                </ModalBody>}
                {mode === 2 && <ModalBody className="container-fluid no-margin no-padding">
                    <div style={{ height: 500 }} className="d-flex flex-row justify-content-center">
                        <div className="d-flex flex-column align-self-center montserrat p-4">
                            <img src="/images/defaults/red_card.png" style={{ height: 100 }} className="align-self-center" alt="" />
                            <span className="align-self-center font-20">Jeff</span>
                            <span className="align-self-center p-4 text-center">Adding a video or highlight to your profile is a premium service</span>
                            <Link className="btn btn-success align-self-center w-100" to="/sqwad/family/subscriptions">Purchase</Link>
                            <button className="btn btn-link align-self-center w-100" onClick={() => this.setState({ mode: 1 })}>Will do later</button>
                        </div>
                    </div>
                </ModalBody>}
                {!microTransac && mode === 1 && (<ModalFooter className="d-flex flex-row">
                    <button className="btn btn-link align-self-center red" onClick={() => this.setState({ mode: 4 })}>Delete</button>
                    <button className="btn btn-success align-self-center ml-auto" onClick={this.save}>Save</button>
                </ModalFooter>)}
                {microTransac && mode === 1 && <ModalFooter className="d-flex flex-row">
                    <button className="btn btn-success align-self-center ml-auto" >Saving, please wait...</button>
                </ModalFooter>}
                {mode === 4 && <ModalFooter className="d-flex flex-row">
                    <button className="btn btn-link align-self-center" onClick={() => this.setState({ mode: 1 })}>Cancel</button>
                    <button className="btn btn-error align-self-center ml-auto" onClick={this.onDelete}>Yes, confirm</button>
                </ModalFooter>}
            </Modal>
        )
    }
}

export default EditVideo;
