import React from 'react';
import { ItemTypes } from '../../../../helpers';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';

const teamSource = {
    beginDrag(props) {
        return props;
    }
};

const teamTarget = {
    canDrop(props) {
        // TODO: look if this can be customized
        return true;
    },
    drop(props, monitor) {
        // PROPS is the target, MONITOR is the source        

        // If its on the same flight and different position, sort
        if (props.team.IdFlight === monitor.getItem().team.IdFlight && props.index !== monitor.getItem().index) {
            props.sortTeams && props.sortTeams({
                from: monitor.getItem().index, // source
                to: props.index, // target
            });
            return;
        }

        // If its on a different flight, move
        if (props.team.IdFlight !== monitor.getItem().team.IdFlight) {

            props.moveTeam && props.moveTeam({
                source: monitor.getItem().team,
                to: props.index,
                of: props.team.IdFlight
            });
            return;
        }

    }
}

class FlightedTeam extends React.Component {

    render() {
        const { connectDragSource, team, isOver, removeTeam, index, canDrop, connectDropTarget } = this.props; // isDragging

        return connectDragSource(connectDropTarget(
            <div>
                {canDrop && isOver && <div className="p-2 mx-2 d-flex flex-row justify-content-center" style={{ backgroundColor: isOver ? '#f5f5f5' : '#e5e5e5', border: '2px dashed gray', borderRadius: 4 }}>
                    <span className="align-self-center font-10">Drop Here</span>
                </div>}
                <div className={`d-flex flex-row ${(isOver&&false) ? "drop-target" : ''}`} key={team.IdTeam}>
                    <span className="align-self-center gray font-8 margin-right-half">({index + 1})</span>
                    <span className="align-self-center">{team.Name || team.TeamName}</span>
                    {team.Id && (<button type="button" className="btn-link align-self-center btn ml-auto" onClick={() => removeTeam(team)}>
                        <i className="fa fa-times red" />
                    </button>)}
                    {!team.Id && (<button type="button" className="btn-link align-self-center btn ml-auto">
                        <i className="fa fa-spin fa-2x fa-hourglass-o font-12" />
                    </button>)}
                </div>
            </div>))
    }
}

export default flow(
    DropTarget(ItemTypes.FLIGHTEDTEAMS, teamTarget, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver()
    })),
    DragSource(ItemTypes.FLIGHTEDTEAMS, teamSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }))
)(FlightedTeam);