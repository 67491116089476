import React from 'react';
import ScoreSection from './Partials/ScoreSection';
import RosterSection from './Partials/RosterSection';
import SignatureSection from './Partials/SignatureSection';
import config from '../../../../config';

class Comp extends React.Component {

  render() {
    const { game, rosterCap } = this.props;

    return <section className="letter sheet margin-top ml-auto mr-auto container-fluid black font-10" style={{ overflow: 'hidden' }}>
      <div className="row margin-bottom">
        <div className="col-6 container-fluid no-margin no-padding">
          <div className="row no-margin no-padding">
            <div className="col-3" style={{ background: 'url(' + game.LeagueImage + ') no-repeat center center', backgroundSize: 'contain' }}>
              <img alt="" src={game.LeagueImage} style={{ width: 1, height: 1 }} />
            </div>
            <div className="col-9">
              <div className="font-12" style={{ lineHeight: 1 }}>
                <strong>{game.Season}</strong>
              </div>
              <div className="margin-top-half"><img alt="" src={[config.apiEndpoint, 'api/v4/common/barcode', game.IdGame].join('/')} style={{ width: 120 }} /></div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div><strong>Location:</strong>
            {' '}<span>{game.Location.Name}</span></div>
          <div><strong>Division:</strong>
            {' '}<span>{game.Division}</span></div>
          <div className="container-fluid no-margin no-padding">
            <div className="row">
              <div className="col-6"><strong>Date:</strong>
                {' '}<span>{game.DateTimeStart_Date_Format}</span></div>
              <div className="col-6"><strong>Time:</strong>
                {' '}<span>{game.DateTimeStart_Time_Format}</span></div>
            </div>
            <div className="row">
              <div className="col-6"><strong>Game ID:</strong>
                {' '}<span>{game.IdGame}</span></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {game.Teams.map((team, i) => <div className="col-6" key={i}>
          <ScoreSection team={team} />
        </div>)}
      </div>
      <div className="row margin-top-half">
        {game.Teams.map((team, i) => <div className="col-6" key={i}>
          <RosterSection team={team} rosterCap={rosterCap} />
        </div>)}

      </div>
      <div className="row margin-top-half">
        {game.Teams.map((team, i) => <div className="col-6" key={i}>
          <SignatureSection team={team} signature={game.signatures ? (team.HomeAway.toLowerCase() === 'home' ? game.signatures.CoachHomeTeam_Signature : game.signatures.CoachAwayTeam_Signature) : null} />
        </div>)}
      </div>
      <table border="1" className="w-100 margin-top">
        <tbody>
          <tr>
            <td className="w-50" style={{ paddingLeft: 10 }}>
              Referees
                   </td>
            <td style={{ paddingLeft: 10 }}>
              Comments
                   </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: 10 }}>- {game.Referees.length > 0 && game.Referees[0] && <span>{game.Referees[0].RefereeType}: {`${game.Referees[0].NameFirst} ${game.Referees[0].NameLast}`}</span>}</td>
            <td rowSpan="4">
              {game.signatures && <div className="pl-2 pr-2">{game.signatures.Notes}</div>}
              <div className="w-100 d-flex flex-row" style={{ height: 20 }}>
                {game.signatures && game.signatures.Referee_Signature && <div className="ml-auto" style={{ width: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundImage: `url(${config.apiEndpoint}/api/v4/games/signature/coach/${game.IdGame})`, height: 35 }} />}
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: 10 }}>- {game.Referees.length > 1 && game.Referees[1] && <span>{game.Referees[1].RefereeType}: {`${game.Referees[1].NameFirst} ${game.Referees[1].NameLast}`}</span>}</td>
          </tr>
          <tr>
            <td style={{ paddingLeft: 10 }}>- {game.Referees.length > 2 && game.Referees[2] && <span>{game.Referees[2].RefereeType}: {`${game.Referees[2].NameFirst} ${game.Referees[2].NameLast}`}</span>}</td>
          </tr>
          <tr>
            <td style={{ paddingLeft: 10 }}>- {game.Referees.length > 3 && game.Referees[3] && <span>{game.Referees[3].RefereeType}: {`${game.Referees[3].NameFirst} ${game.Referees[3].NameLast}`}</span>}</td>
          </tr>
        </tbody>
      </table>
      <div className="row margin-top">
        <div className="col-4">
          <img alt="" src="https://matchreports.thesqwad.com/images/logos/logo_black.png" width="100px" />
        </div>
        <div className="col-4 text-center">
          <img alt="" src={[config.apiEndpoint, 'api/v4/common/barcode', game.IdGame].join('/')} />
        </div>
        <div className="col-4 text-right">
          {game.Season}
        </div>
      </div>
    </section>
  }
}

export default Comp;