import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, formatPrice } from '../../../../../../helpers';

const source = {
  beginDrag(props) {
    return props;
  }
}

class ZipCodeAdjustment extends React.Component {
  renderThing = () => {
    const { program, unmovable, isDragging, fnEdit, fnDelete } = this.props;
    return <li style={{ marginRight: unmovable ? 0 : '1em' }} className={`program-card bg-info p-4 d-flex flex-column ${isDragging ? 'dragging' : ''} ${unmovable ? 'w-100' : ''}`}>
      <span className="white font-16 text-left toBold">{program.ZipCodeGroupName}</span>
      {program.codes.length > 0 && <div className='d-flex flex-row'>
        <span className="white">Adjustment</span>
        <span className="ml-auto white">{formatPrice(program.codes[0].Amount * 100)}</span>
      </div>}
      <hr />
      <div className="container-fluid no-margin no-padding">
        <div className="row no-margin no-padding">
          {program.codes.map((code, i) => <span className="white col-4" key={i}><i className="fas fa-map-marked-alt white align-self-center font-10 mr-1" />{code.ZipCode}</span>)}
        </div>
      </div>

      {!unmovable && <button className="white font-8 pointer hint" style={{ padding: '0 0.5em', borderRadius: 10, border: '1px solid white', height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnEdit(program)}>
        <i className="fas fa-edit" />{' '}Edit</button>}

      {!unmovable && <span className="fas fa-grip-vertical white font-14 grab" style={{ position: 'absolute', top: 2, left: 4 }} />}

      {fnDelete && <button className="white font-8 pointer btn-danger" style={{ padding: '0 0.5em', borderRadius: 10, height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnDelete()}>
        <i className="fas fa-trash" />{' '}Remove</button>}

    </li>
  }
  render() {
    const { connectDragPreview, connectDragSource, unmovable } = this.props;
    if (unmovable) return this.renderThing();
    else return connectDragPreview(connectDragSource(this.renderThing()));
  }
}

export default flow(
  DragSource(ItemTypes.REGISTRATIONFEE, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(ZipCodeAdjustment);
