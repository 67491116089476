function reducer(state = [], action) {

    switch (action.type) {
        case 'OPPONENTS/SET':
            return action.opponents;
        default:
            return state;
    }
}

export default reducer;