import React from 'react';
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';

class FieldName extends React.Component {

  state = {
    field: null
  }

  constructor(props) {
    super(props);
    this.state.field = props.field;
  }


  componentWillReceiveProps = (nextProps) => {
    this.setState({
      field: nextProps.field
    });
  }


  handleChange = (e) => {
    const { field } = this.state;
    field.Name = e.target.value;
    this.setState({ field });    
  }

  render() {
    const { fnRemove, index } = this.props,
      { field } = this.state;
    return <InputGroup className="mb-2">
      {field && <Input placeholder="Field name" value={this.state.field.Name} onChange={this.handleChange} />}
      {!field.IdLocation && (
        <InputGroupAddon addonType="append">
          <Button onClick={() => fnRemove && fnRemove(index)} color="default"><i className="fa fa-times red" /></Button>
        </InputGroupAddon>)}
    </InputGroup>
  }
}

export default FieldName;
