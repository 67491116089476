import React from 'react';
import Card from './card';
import Masonry from 'react-masonry-component';
import { ItemTypes } from '../../../../../../helpers';
import _ from 'lodash';

const masonryOptions = {
  transitionDuration: 0
};

class RegistrationPrograms extends React.Component {

  render() {
    const { paymentPlans, fnEditRegistrationProgram, fnAddRegistrationProgram } = this.props;

    const filtered_pps = _.filter(paymentPlans, p => !p.ForTeams);
    
    return <section>

      <div id="registration-fees">
        <Masonry
          className={'registration-fees'}
          elementType={'ul'}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}>
          {filtered_pps && filtered_pps.map((program, i) => <Card program={program} key={i} type={ItemTypes.REGISTRATIONFEE} fnEdit={fnEditRegistrationProgram} />)}
        </Masonry>

        {(!filtered_pps || !filtered_pps.length) && (
          <div className="jumbotron text-center margin-top">
            <h1 className="display-3">There are no Registration Programs</h1>
            <p className="lead">Click the plus sign above to create your first Registration Program</p>
          </div>
        )}
      </div>

      <div className="d-flex flex-row" style={{ position: 'absolute', top: 10, right: 15 }}>
        <button onClick={fnAddRegistrationProgram} type="button" style={{ border: '2px solid #2cad3c', borderRadius: 20, width: 40, height: 40 }} className="green align-self-center btn-block d-flex flex-row justify-content-center">
          <i className="fa fa-plus font-20 align-self-center" /></button>
      </div>

    </section>
  }
}

export default RegistrationPrograms;
