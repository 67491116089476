import React from 'react';
import _ from 'lodash';
import TeamListItem from './item';


class TeamsTable extends React.Component {

    state = {
        up: true,
        by: 'Name'
    }

    sort = (newBy) => {
        const { up, by } = this.state;
        if (by === newBy) {
            this.setState({
                up: !up
            });
        } else {
            this.setState({
                up: false,
                by: newBy
            });
        }
    }

    add = () => {
        const { division } = this.props;
        division.Teams.push({
            editing: true,
            IdDivision: division.IdDivision
        });
        this.forceUpdate();
    }

    cancel = (index) => {
        const { division } = this.props;
        division.Teams = [...division.Teams.splice(0, index), ...division.Teams.splice(index + 1)];
        this.forceUpdate();
    }

    render() {
        const { mode, division, fnSelectTeam, positions, leaguePoints, season, search } = this.props,
            { Teams } = division,
            { up, by } = this.state;


        // sorting
        let filteredTeams = _.filter(Teams, t => (t.Name || t.TeamName || '').toLowerCase().indexOf((search || '').toLowerCase()) !== -1)
        let sortedTeams = this.state.up ? _.sortBy(filteredTeams, this.state.by) : _.sortBy(filteredTeams, this.state.by).reverse();

        return (
            <ul className={`${mode === 1 ? 'list' : 'tiles'} no-margin no-padding no-style-list`}>
                {Teams && Teams.length > 0 && mode === 1 &&
                    (<li className="container-fluid no-margin no-padding">
                        <div className="row font-9">
                            <div className="col-3">
                                <button className={`btn btn-link btn-sm ${by === 'Name' ? 'green' : ''}`} onClick={() => this.sort('Name')}>TEAM
                                    {by === 'Name' && <i className={`fa half-idented fa-caret-${up ? 'up' : 'down'}`}></i>}
                                </button>
                            </div>

                            <div className="col-1 text-center">
                                <button className="align-self-center btn btn-link btn-sm black">GENDER</button>
                            </div>

                            <div className="col-2">
                                <button className="align-self-center btn btn-link btn-sm black">RESULTS</button>
                            </div>

                            <div className="col-1 text-center">
                                <button className={`btn btn-link btn-sm ${by === 'IdTeamDivision' ? 'green' : ''}`} onClick={() => this.sort('IdTeamDivision')}>ID
                                    {by === 'IdTeamDivision' && <i className={`fa half-idented fa-caret-${up ? 'up' : 'down'}`}></i>}
                                </button>
                            </div>
                            <div className="col-5 ">
                                <button className="align-self-center btn btn-link btn-sm black">CONTACT</button>
                            </div>
                        </div>
                    </li>)}
                { /* Item */}
                {leaguePoints && sortedTeams.map((team, i) => <TeamListItem index={i} key={team.IdTeam || i} team={team}
                    teamActions={this.props.teamActions}
                    idDivision={division.IdDivision} division={division} fnSelectTeam={fnSelectTeam}
                    fnCancel={this.cancel} positions={positions} leaguePoints={this.props.leaguePoints} season={season} />)}
                { /* Add team button at the bottom */}
                <div style={{ height: 20 }} />

                {(sortedTeams && sortedTeams.length === 0) && (
                    <li className="m-4 black text-center no-games-found">
                        <h2>{`No teams registered on ${division.Name || division.DivisionName} division ${search ? `under the name '${search}'` : ''}`}</h2>
                    </li>)}
            </ul>
        )
    }
}

export default TeamsTable;