import React from 'react';
import BlueishHeader from '../../../common/things/BlueishHeader';
import moment from 'moment';
import _ from 'lodash';
import Card from './card';

class Sheet extends React.Component {

  state = { team: null, qualifies: true }
  componentWillMount() {
    const { team, season } = this.props;
    this.setState({ team, season }, () => {
      const { team } = this.state;
      if ((!team.players.length || team.players.length < 3 || _.find(team.players, p => { return !p.UserNameSignature || !p.IsValid })) && team.IdLeague === 11826) {
        this.setState({ qualifies: false });
      }
    });
  }
  errorImage = () => {
    const { team } = this.state;
    team.TeamImage = `/images/defaults/teamLogo.png`;
    this.setState({ team });
  }
  render() {
    const { team } = this.state;

    return <section className="letter sheet margin-top ml-auto mr-auto container-fluid black font-10" style={{ overflow: 'hidden', width: 680 }}>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column">
          <img alt="" onError={this.errorImage} src={team.TeamImage} style={{ width: 50 }} />
          <span>{team.TeamName}</span>
        </div>
        <div className="text-center">
          <div>{team.LeagueName}</div>
          <div>{team.SeasonName}</div>
          <div>{team.DivisionName}</div>
        </div>
        <div>
          {moment().format('MMM Do YYYY')}
        </div>
      </div>
      <BlueishHeader message="Player Cards" />
      <section className="clearfix" style={{ paddingTop: '1em' }}>
        {team.players && team.players.map((player, i) => <Card key={i} player={player} />)}
      </section>
      <div className="d-flex flex-row-reverse" style={{ marginTop: '5em' }} />
    </section>
  }
}

export default Sheet;
