import React from 'react';

class CoachScrobbler extends React.Component {

    state = {
        coaches: [],
        index: 0
    }

    componentWillMount() {
        this.setState({ coaches: this.props.coaches });
    }

    up = () => {
        let { index, coaches } = this.state;
        index = (coaches.length - 1 === index) ? 0 : index + 1;
        this.setState({ index });
    }
    down = () => {}

    render() {
        const { coaches, index } = this.state;
        return (
            <div style={{ height: 250 }} className="w-100 d-flex flex-column">
                <div className="h-100  cover" style={{ position: 'relative', background: `url(${coaches[index].Image || coaches[index].ImageUrl}) no-repeat center center` }}>
                    <div style={{ position: 'absolute', bottom: 10, left: 10, right: 10 }} className="d-flex flex-column">
                        <span className="font-14 toBold white shadow-text">{coaches[index].NameFirst} {coaches[index].NameLast}</span>
                        <span className="font-10 white shadow-text">{(coaches[index].RoleOverrideName || coaches[index].Role)}</span>
                    </div>
                    {coaches.length > 1 && <div style={{ width: 50, height: 50, position: 'absolute', left: 0, top: 80 }} className="d-flex flex-row justify-content-center ">
                        <i className="align-self-center fas fa-chevron-left white shadow-text" />
                    </div> }
                    { coaches.length > 1 && <div onClick={this.up} style={{ width: 50, height: 50, position: 'absolute', right: 0, top: 80 }} className="d-flex flex-row justify-content-center ">
                        <i className="align-self-center fas fa-chevron-right white shadow-text" />
                    </div> }
                </div>
                <div style={{ height: 'auto' }} className="d-flex flex-column montserrat p-2">
                    <div className="w-100 align-self-center">
                        <span className="block font-10 toBold black">{coaches[index].PhoneNumber_Format}</span>
                        <span className="block font-8 black line1">Phone</span>
                    </div>
                    <div className="w-100 align-self-center">
                        <span className="block font-10 toBold black">{coaches[index].EmailAddress}</span>
                        <span className="block font-8 black line1">Email</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default CoachScrobbler;