import React from 'react';
import { Link } from 'react-router';
import fakeAuth from '../../../fakeAuth';

class Header extends React.Component {
  state = {
    user: null
  }

  // Lifecycle
  componentWillMount() {
    this.setState({
      user: fakeAuth.getUser()
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      user: fakeAuth.getUser()
    });
  }
  //

  logout = () => {
    const { router } = this.props;
    fakeAuth.signout(() => {
      this.props.logout && this.props.logout();
      router.push('/login');
    });
  }

  openSpotlight = () => {
    this.props.spotlightActions &&
      this.props.spotlightActions.open();
  }

  errorUserImage = () => {
    const { user } = this.state;
    user.UserImage = '/images/defaults/male.png';
    this.setState({
      user
    });
  }

  render() {

    const { auth, title } = this.props, { user } = this.state;

    return (
      <header className="d-flex flex-row align-items-center bg-dark-blue">
        <div key="1" className="mr-auto d-flex flex-row align-items-center">
          {/* Logo */}
          <div id="logo" />
          {/* Title */}
          <div>
            {title && title.sub && <h5 className="gray toUpper no-margin no-padding">{title.main}</h5>}
            {title && <h2 className="white toUpper no-margin no-padding">{title.sub || title.main}</h2>}
            {title && title.under && <div className="white toUpper no-margin no-padding text-nowrap d-flex flex-row">
              <span className="font-10 align-self-center">{title.under}</span>
            </div>}
          </div>
        </div>
        {/* Right side */}
        {user && (
          <Link to='/family' className="btn btn-link blue d-flex flex-row">
            <img alt="" onError={this.errorUserImage} src={user.UserImage} className="smallPic margin-right align-self-center" />
            <span className="align-self-center">{'Hi ' + user.NameFirst}</span>
          </Link>)}
        { /* Separator */}
        {user && <span key="separator1" className="big-font">|</span>}
        {auth && auth.loggedIn && <Link key="2" className="btn btn-link green" to="/family"> Family Center
                                   </Link>}
        {auth && auth.loggedIn && <span key="3" className="big-font">|</span>}
        {auth && auth.loggedIn && <button key="4" onClick={this.logout} className="btn btn-link green">
          Logout
                                   </button>}
        <button key="5" className="btn btn-info square" onClick={this.openSpotlight}>
          <i className="fa fa-search bigest-font" /></button>
        {/*<button className="btn btn-success square">
          <i className="fa fa-question bigest-font" /></button>*/}
      </header>
    );
  }
}

export default Header;
