import React from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';


class DisclaimerTab extends React.Component {
    state = {
        roster: null,
        coaches: null
    }

    render() {
        const { disclaimer = {} } = this.props;
        return <div className="d-flex flex-column p-4">
            <ReactToPrint trigger={() => <button className="btn btn-info btn-sm ml-auto">Print</button>}
                content={() => this.componentRef} />

            {disclaimer && <div id="printarea" className="d-flex flex-column p-4" ref={el => (this.componentRef = el)}>
                <img alt="" src={disclaimer.SeasonImage || disclaimer.LeagueImage} className="m-auto" style={{ height: 80 }} />
                <h2 className="m-auto text-center">{disclaimer.SeasonName}</h2>
                <hr />
                {disclaimer.SeasonDisclaimer && <div>
                    <h4 className="blue">Season Disclaimer</h4>
                    <hr />
                    <div dangerouslySetInnerHTML={{ __html: disclaimer.SeasonDisclaimer }} />
                    <hr />
                </div>}
                <h4 className="blue">Terms and Conditions</h4>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: disclaimer.DisclaimerText }} />

                {disclaimer.FeeDisclaimer && <div>
                    <hr />
                    <h4 className="blue">Fees Disclaimer</h4>
                    <hr />
                    <div dangerouslySetInnerHTML={{ __html: disclaimer.FeeDisclaimer }} />
                </div>}
                {disclaimer && disclaimer.SignatureImage && disclaimer.SignatureImage.indexOf('<svg') !== -1 && <div dangerouslySetInnerHTML={{ __html: disclaimer.SignatureImage }} />}
                {disclaimer && disclaimer.SignatureImage && disclaimer.SignatureImage.indexOf('base64') !== -1 && <div className="m-auto">
                    <img alt="" src={disclaimer.SignatureImage} className="m-auto" style={{ height: 80 }} />
                </div>}
                <div className="text-center">Signed by{' '}{disclaimer.UserNameSignature}<br />on{' '}{moment(disclaimer.DateCreated).format('MMM Do YYYY')}</div>
            </div>}
        </div>
    }
}

export default DisclaimerTab;