import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../../../helpers';
import Switch from "react-switch";
import _ from 'lodash';
import fakeAuth from '../../../../../fakeAuth';

// To include the default styles
import 'react-rangeslider/lib/index.css'

class AddReview extends React.Component {
    state = {
        bootstrapped: false,
        volume: 5,
        match: {
            CreatorNameFirst: fakeAuth.getUser().NameFirst,
            CreatorNameLast: fakeAuth.getUser().NameLast,
            EvaluationDate: moment(),
            IdFolder: null, // TODO
            IdGender: 1,
            IdPlayerEvaluation: generateRandomId(10),
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: this.props.selected_user ? this.props.selected_user.NameFirst : '',
            NameLast: this.props.selected_user ? this.props.selected_user.NameLast : '',
            ReportType: 12,
            IdUserTarget: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdUserFollow: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
        }
    }

    componentWillReceiveProps = nextProps => {
        const { topics } = nextProps;
        if (!this.state.bootstrapped && topics) {

            const { match } = this.state;
            match.Topics = _.chain(topics)
                .map(t => {
                    return {
                        IdTopic: t.IdTopic,
                        Value: 0,
                        TopicName: t.TopicName,
                        SubTopics: _.map(t.subtopics, s => {
                            return {
                                IdTopic: s.IdTopic,
                                Value: 0,
                                TopicName: s.TopicName,
                            }
                        })
                    }
                }).value();
            this.setState({ match, bootstrapped: true });
        }
    }

    onColor = (color) => {
        const { match } = this.state;
        match.Color = color;
        this.setState({ match });
    }

    onSave = () => {
        this.setState({ dirty: true }, () => {
            const { match } = this.state;
            if (!this.txtName.value) {
                alert('Please complete the missing fields');
                return;
            }
            this.props.scout_actions.save_match({
                ...match,
                Overview: this.txtOverview.value,
                TechnicalStrenghts: this.txtTechnicalStrenghts.value,
                TechnicalImprove: this.txtTechnicalImprove.value,
                TacticalStrenghts: this.txtTacticalStrenghts.value,
                TacticalImprove: this.txtTacticalImprove.value,
                PhysicalStrenghts: this.txtPhysicalStrenghts.value,
                PhysicalImprove: this.txtPhysicalImprove.value,
                MentalStrenghts: this.txtMentalStrenghts.value,
                MentalImprove: this.txtMentalImprove.value,
                Notes: this.txtNotes.value,
                EvaluationDate: moment().format(),
                NameFirst: this.txtName.value.trim(),
                NameLast: '',
                IdTeam: this.props.IdTeam
            });
            this.props.toggle();
        });
    }

    onToggleSubTopic = index => {
        const { match } = this.state;
        match.Topics[index].toggled = !match.Topics[index].toggled;
        if (match.Topics[index].toggled) {
            // Recalculate full topics value
            match.Topics[index].Value = _.chain(match.Topics[index].SubTopics)
                .map(i => i.Value)
                .reduce((a, b) => a + b, 0)
                .value() / match.Topics[index].SubTopics.length;
        }
        this.setState({ match });
    }

    onTopicChange = (index, value) => {
        const { match } = this.state;
        if (match.Topics[index].toggled) { return; }
        match.Topics[index].Value = value;
        this.setState({ match });
    }

    onSubTopicChange = (i, j, value) => {
        const { match } = this.state;
        match.Topics[i].SubTopics[j].Value = value;

        // Recalculate full topics value
        match.Topics[i].Value = _.chain(match.Topics[i].SubTopics)
            .map(i => i.Value)
            .reduce((a, b) => a + b, 0)
            .value() / match.Topics[i].SubTopics.length;

        this.setState({ match });
    }

    /*
    { IdTopic: stat.IdTopic, Value: $.slider.value, SubTopics: _.chain($.sub_wrapper.children).filter(view => view.isActive()).map(view => view.getValue()).value() }*/
    render() {
        const { match, colors, profile, volume, dirty } = this.state, { topics } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.adding_review ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-row w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                    {match && <section className="w3-container w3-content mx-auto w-100">
                        <div className="d-flex flex-column black align-self-start w-100">
                            <div className="w3-container bg-white p-4">
                                <label>Athlete's Name</label>
                                <input type="text" defaultValue={match.NameFirst ? `${match.NameFirst} ${match.NameLast}` : match.NameFirst} ref={i => this.txtName = i} className={`w3-input ${(dirty && !this.txtName.value) ? 'bg-danger' : ''}`} />                                
                            </div>

                            <div className="w3-card mt-3">
                                <div className="w3-container bg-white p-4">
                                    <label>Overview</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.Overview} ref={i => this.txtOverview = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">TECHNICAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.TechnicalStrenghts} ref={i => this.txtTechnicalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.TechnicalImprove} ref={i => this.txtTechnicalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">TACTICAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.TacticalStrenghts} ref={i => this.txtTacticalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.TacticalImprove} ref={i => this.txtTacticalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">PHYSICAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.PhysicalStrenghts} ref={i => this.txtPhysicalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.PhysicalImprove} ref={i => this.txtPhysicalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">PSYCHOLOGICAL | SOCIAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.MentalStrenghts} ref={i => this.txtMentalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.MentalImprove} ref={i => this.txtMentalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">NEXT STEPS</h2>
                                </div>
                                <div className="w3-container bg-white p-4">

                                    <div className="d-flex flex-row">
                                        <Switch onChange={() => {
                                            const { review } = this.state;
                                            match.NextSteps = 'Sign';
                                            this.setState({ review });
                                        }} checked={match.NextSteps === 'Sign' ? true : false} />
                                        <span className="align-self-center ml-2">Sign</span>
                                    </div>
                                    <div className="d-flex flex-row mt-2">
                                        <Switch onChange={() => {
                                            const { review } = this.state;
                                            match.NextSteps = 'Continue to monitor';
                                            this.setState({ review });
                                        }} checked={match.NextSteps === 'Continue to monitor' ? true : false} />
                                        <span className="align-self-center ml-2">Continue to monitor</span></div>
                                    <div className="d-flex flex-row mt-2">
                                        <Switch onChange={() => {
                                            const { review } = this.state;
                                            match.NextSteps = 'Discard';
                                            this.setState({ review });
                                        }} checked={match.NextSteps === 'Discard' ? true : false} />
                                        <span className="align-self-center ml-2">Discard</span>
                                    </div>

                                    <label className="mt-2">Notes</label>
                                    <textarea className="w3-input" type="text" defaultValue={match.Notes} ref={i => this.txtNotes = i} />

                                </div>
                            </div>

                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Attributes saved!' : 'Save Attributes'}</button>
                            <div className="p-4" />
                        </div>
                    </section>}

                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default AddReview;