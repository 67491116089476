import React from 'react';
import Sheet3v3 from './Sheet3v3';

class Comp extends React.Component {
    render() {
        const { games, community } = this.props;
        return <section className="sheet postcard  margin-top ml-auto mr-auto no-padding">
            {games.map((game, i) => <div key={i}><Sheet3v3 community={community} game={game} rosterCap={6} /><hr className="no-margin no-padding" /></div>)}
        </section>
    }
}

export default Comp;