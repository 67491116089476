import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import { Popover, PopoverBody } from 'reactstrap';
import request from 'superagent';
import config from '../../config';
import Loader from '../common/Loader';
import _ from 'lodash';
import AddChargeModal from './partials/AddChargeModal';
import AddCreditModal from './partials/AddCreditModal';
import MoveTeamModal from '../common/modals/MoveTeamModal';
import MakePaymentModal from '../common/modals/MakePayment';
import MakeRefundModal from '../common/modals/MakeRefund';
import ChangePlanModal from '../common/modals/ChangePlanModal';
import fakeAuth from '../../fakeAuth';
import DisclaimerTab from './partials/DisclaimerTab';
import RosterTab from './partials/RosterTab';
import RegistrationTab from './partials/RegistrationTab2';
import AccountingTab from './partials/AccountingTab';
import CoachScrobbler from './partials/CoachScrobbler';
import EditableName from './partials/EditableName';
import { humanize_user } from '../../helpers';

class TeamProfile extends React.Component {

    state = {
        selectedPayment: null,
        team: null,
        bootstrapped: false,
        family: null,
        currentTab: '3',
        selectedSeason: null,
        seasons: null,
        allSeasons: null,
        questions: null,
        addingCharge: false,
        addingCredit: false,
        payments: null,
        balance: null,
        pastDue: null,
        dues: null,
        futurePayments: null,
        idOrderItem: null,
        fetchingPayments: false,
        roster: null,
        totalAmount: null,
        coaches: null,
        moving: false,
        makingPayment: false,
        paying: false,
        editing: false,
        tab: 3
    }

    // Lifecycle
    componentWillMount() {
        const { params, setTitle, router } = this.props, { location } = router;
        if (location && location.query && location.query.tab) this.setState({ currentTab: `${location.query.tab}`, tab: parseInt(location.query.tab, 10) })
        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
        this.props.fetchStatuses && this.props.fetchStatuses();
        setTitle && setTitle({ main: 'Profile' });
    }

    componentWillReceiveProps = (nextProps) => {
        const { setTitle } = nextProps;
        if (nextProps.season !== this.props.season || this.props.params.idTeam !== nextProps.params.idTeam) {
            const { params } = nextProps;
            this.setup(params.idTeam, nextProps.season);
        }

        // TODO: this is a hack (I know) to update the name of the team in the title if updated from a child.
        if (nextProps.generic !== this.props.generic) {
            setTitle && setTitle({
                main: 'Profile',
                sub: nextProps.generic,
                under: nextProps.season ? nextProps.season.Name : null,
            });
        }
    }

    componentWillUnmount() {
        this.props.genericActions && this.props.genericActions.clear();
    }
    //

    setup = (idTeam, season) => {

        const { setTitle } = this.props;

        this.setState({ bootstrapped: false, team: null }, () => {

            // Fetch the team info based on the IdLeague
            request.get(`${config.apiEndpoint}/api/v4/teams/accounting/${idTeam}/${season.IdSeason}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(data => {
                    if (data.body.success && data.body.team) {
                        this.setState({
                            team: data.body.team
                        }, () => {

                            // Also fetch the seasons where that team is in
                            request.get(`${config.apiEndpoint}/api/v4/teams/seasons/${idTeam}/${season.IdLeague || this.props.season.IdLeague}`)
                                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                                .then(data => {

                                    if (data.body.success) {
                                        this.setState({
                                            //bootstrapped: true,
                                            seasons: data.body.seasons,
                                            allSeasons: data.body.allSeasons,
                                            selectedSeason: _.find(data.body.seasons, (s) => {
                                                return s.IdSeason === parseInt(season.IdSeason, 10)
                                            }) || _.first(data.body.seasons)
                                        }, () => {
                                            this.fetchPayments();
                                            this.fetchRoster();
                                            this.fetchQuestions();
                                        });
                                    }

                                });
                        });

                        setTitle && setTitle({
                            main: 'Profile',
                            sub: data.body.team.TeamName,
                            under: season.Name || null,
                        });

                        // Scrobbler between division teams
                        request.get(`${config.apiEndpoint}/api/v4/divisions/teams/${data.body.team.IdDivision}`)
                            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                            .then(data => {

                                let teams = data.body.teams;
                                let total_teams = teams.length;
                                let this_index = _.findIndex(teams, t => parseInt(t.IdTeam, 10) === parseInt(this.state.team.IdTeam, 10));
                                let next_index = this_index + 1, prev_index = this_index - 1;
                                if (next_index >= total_teams) next_index = 0;
                                if (prev_index < 0) prev_index = total_teams - 1;
                                this.setState({
                                    next_team: teams[next_index].IdTeam,
                                    prev_team: teams[prev_index].IdTeam
                                });

                            });
                    }
                }, error => {
                    this.setState({ bootstrapped: true });
                });

            request.get(`${config.apiEndpoint}/api/v4/teams/accounting/${idTeam}/${season.IdSeason}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(data => { })


        })

    }

    fetchQuestions = () => {

        const { selectedSeason, team } = this.state;

        // Fetch the questions at the moment of registration
        request.get(`${config.apiEndpoint}/api/v4/registration/questions/team_summary/${team.IdTeamDivision}/${selectedSeason.IdSeason}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
                if (data.body.success) {
                    this.setState({ questions: data.body.questions });
                }
            });
    }

    fetchPayments = () => {

        const { params, seasonActions } = this.props;

        this.setState({ fetchingPayments: true }, () => {
            request.get(`${config.apiEndpoint}/api/v6/payments/balance/${this.state.selectedSeason.IdTeamDivision}/${this.state.selectedSeason.IdSeason}/2`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    if (data.body.success) {
                        

                        // Decrypt
                        [...data.body.dues, ...data.body.payments].forEach(u => humanize_user(u));
                        //

                        seasonActions && seasonActions.fetchDisclaimerNonSecure(null,
                            params.idUser, data.body.balance.IdOrderItem);

                        this.setState({
                            autoPaySettings: data.body.autoPaySettings,
                            dues: data.body.dues,
                            fetchingPayments: false,
                            payments: data.body.payments,
                            balance: data.body.balance.TotalDue,
                            pastDue: data.body.balance.IsPaymentPastDue ? data.body.balance.TodayBalance : 0,
                            futurePayments: _.filter(data.body.payments, (due) => {
                                return (due.IsPaymentDue && !due.IsPaymentPastDue);
                            }),
                            fullBalance: data.body.balance,
                            totalAmount: data.body.balance.TotalAmount,
                            idOrderItem: data.body.balance.IdOrderItem
                        });
                    }
                });
        });
    }

    fetchRoster = () => {
        this.setState({ fetchingPayments: true }, () => {
            request.get([config.apiEndpoint, 'api/v4/roster',
            fakeAuth.getUser().IdUser, this.state.selectedSeason.IdTeamDivision].join('/'))
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    if (data.body.success) {
                        this.setState({
                            roster: _.filter(data.body.roster, (u) => {
                                return u.IdRole === 3;
                            }),
                            coaches: _.filter(data.body.roster, (u) => {
                                return u.IdRole === 4;
                            }),
                            bootstrapped: true
                        });
                    }
                });
        });
    }

    toggleTab = (tab) => {
        if (this.state.currentTab !== tab) {
            this.setState({ currentTab: tab });
        }
    }

    selectSeason = (selectedSeason) => {
        this.setState({
            selectedSeason,
            payments: null,
            balance: null,
            pastDue: null,
            futurePayments: null
        }, () => {
            this.fetchPayments();
            this.fetchRoster();
            this.fetchQuestions();
        });
    }

    fnMoved = (newSeason) => {
        // TODO: show loader and update the hole thing
        const { params } = this.props;
        this.setState({
            moving: false,
            updating: true
        }, () => {
            this.setup(params.idTeam, newSeason);
        });
    }

    toggleAddCharge = () => this.setState({ addingCharge: !this.state.addingCharge });
    toggleAddCredit = () => this.setState({ addingCredit: !this.state.addingCredit });
    toggleMoving = () => this.setState({ moving: !this.state.moving });
    toggleMakePayment = () => this.setState({ makingPayment: !this.state.makingPayment });
    togglePaying = () => this.setState({ paying: !this.state.paying });
    toggleRefund = () => this.setState({ makeRefund: !this.state.makeRefund });

    fnMakeRefund = (selectedPayment) => {
        this.setState({ selectedPayment }, () => {
            this.toggleRefund();
        });
    }

    fnChangePlan = (plan) => {
        const { params, season } = this.props;
        this.setState({ changingPlan: false });
        request.post(`${config.apiEndpoint}/api/v4/registration/changePlan/${this.state.idOrderItem}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({ newPlan: plan })
            .then(() => {
                this.setup(params.idTeam, season);
            });
    }

    fnDeletePayment = (payment, index) => {
        const { paymentsActions } = this.props;
        paymentsActions && paymentsActions.deletePayment(payment);

        // TODO: handle this with redux
        const { payments } = this.state;
        this.setState({ payments: [...payments.slice(0, index), ...payments.slice(index + 1)] });
    }

    assignStatus = (status) => {
        var { team, user, selectedSeason } = this.state;

        if (team) {
            team.IdRoleStatus = status.IdRoleStatus;
            team.RoleStatus = status.RoleStatusName;
            team.RegistrationStatus = status.RoleStatusName;
        }
        if (user) {
            selectedSeason.IdRoleStatus = status.IdRoleStatus;
            selectedSeason.RegistrationStatus = status.RoleStatusName;
        }

        this.setState({
            team,
            user,
            selectedSeason,
            changing_status: false
        });

        // Call API
        // TODO: Might need to refactor this for using on other places ?
        if (team) {
            request.patch([config.apiEndpoint, 'api/v4/teams/status',
            team.IdTeamDivision, status.IdRoleStatus].join('/'))
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    // Nothing to do here ?
                });
        } else {
            request.patch([config.apiEndpoint, 'api/v4/registration/status',
            user.IdUser, selectedSeason.IdDivision, status.IdRoleStatus].join('/'))
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    // Nothing to do here ?
                });
        }
    }

    render() {

        const { disclaimer } = this.props, { team, roster, coaches, bootstrapped, selectedSeason, seasons,
            questions, addingCharge, addingCredit, payments, balance, moving, pastDue, allSeasons,
            makingPayment, makeRefund, selectedPayment, fullBalance,
            tab, changingPlan, autoPaySettings } = this.state;

        return <section className="">

            {team && bootstrapped && questions && (
                <Animated animationIn="fadeInDown" className="w-100 p-2 bg-blue-dark d-flex flex-row">
                    <Link to={`/season/${selectedSeason.IdSeason}/team/${this.state.prev_team}`} style={{ width: 100 }} className="d-flex flex-column justify-content-center">
                        <i className="fas fa-chevron-left font-40 white align-self-center" />
                    </Link>
                    <div className="d-flex flex-row w-100 p-2 sans">

                        <div className="cover align-self-center" style={{ width: 120, height: 120, borderRadius: 60, background: `url(${(team.TeamImageUrl || team.Logo)}?full=120) no-repeat center center` }} />

                        <div className="d-flex flex-column justify-content-center ml-3">
                            <EditableName team={team} teamActions={this.props.teamActions} />
                            <div className="d-flex flex-row mt-2">
                                {/*<button style={{ width: 120, border: '2px solid white', borderRadius: 20 }} className="white p-2 align-self-center">Edit</button>*/}
                                <button onClick={this.toggleMoving} style={{ width: 120, border: '2px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center">Move Team</button>
                            </div>
                        </div>

                    </div>
                    <div className="w-100 p-2">

                        <div className="ml-4 d-flex flex-column justify-content-center pl-4 h-100" style={{ borderLeft: '1px solid white' }}>
                            <span className="blue">{selectedSeason.SeasonName || selectedSeason.Name}</span>
                            <span className="white">ID: {team.IdTeam}</span>
                            <span className="white">ID Team Division: {team.IdTeamDivision}</span>
                            <span className="white">Auto-Pay: {autoPaySettings && autoPaySettings.IsAutoPay ? 'ON' : 'OFF'}</span>
                            <div className="w-100">

                                { /* Status */}
                                <button className="btn btn-link d-flex flex-row align-self-center pl-0" id="btnStatus" onClick={() => this.setState({ changing_status: true })}>
                                    <i className={`fas fa-circle align-self-center`} style={{ color: (_.find(config.roleStatuses, (st) => { return st.IdRoleStatus === (team || selectedSeason).IdRoleStatus }) || { color: 'white' }).color }} />
                                    <span className="idented-half align-self-center white">Status: {(team || selectedSeason).RegistrationStatus || (team || selectedSeason).RoleStatus}</span>
                                    <i className="fa fa-caret-down ml-2 align-self-center white" />
                                </button>

                                { /* Status Popover */}
                                <Popover placement="bottom" isOpen={this.state.changing_status} target="btnStatus" toggle={() => this.setState({ changing_status: false })}>
                                    <PopoverBody className="d-flex flex-column">
                                        {this.props.statuses && this.props.statuses.map((s, i) => (
                                            <button key={i} className="btn btn-link btn-sm btn-block d-flex flex-row" onClick={() => this.assignStatus(s)}>
                                                <i className={`fas fa-circle align-self-center margin-right`} style={{ color: _.find(config.roleStatuses, (st) => { return st.IdRoleStatus === s.IdRoleStatus }).color }} />
                                                {' '}
                                                {s.RegistrationStatus}
                                            </button>))}
                                    </PopoverBody>
                                </Popover>
                            </div>
                        </div>

                    </div>
                    <Link to={`/season/${selectedSeason.IdSeason}/team/${this.state.next_team}`} style={{ width: 100 }} className="d-flex flex-column justify-content-center">
                        <i className="fas fa-chevron-right font-40 white align-self-center" />
                    </Link>
                </Animated>
            )}

            {team && bootstrapped && questions && coaches && (
                <div className="w3-container mt-2  mb-4">
                    <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
                        <div className="d-flex flex-row mt-3">
                            <Animated animationIn="fadeInUp" style={{ overflow: 'hidden', borderRadius: 5, minWidth: 300, maxHeight: (coaches && coaches.length) ? 500 : 250 }} className="card shadow">

                                {coaches && coaches.length > 0 && <CoachScrobbler coaches={coaches} />}

                                <div onClick={() => this.setState({ tab: 1 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 1 ? 'gray-darkish' : 'white'}`}>
                                    <span className="black font-14 ml-3 align-self-center">Registration</span>
                                    <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                </div>
                                <div onClick={() => this.setState({ tab: 2 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 2 ? 'gray-darkish' : 'white'}`}>
                                    <span className="black font-14 ml-3 align-self-center">Accounting</span>
                                    <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                </div>
                                <div onClick={() => this.setState({ tab: 3 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 3 ? 'gray-darkish' : 'white'}`}>
                                    <span className="black font-14 ml-3 align-self-center">Roster</span>
                                    <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                </div>
                                <div onClick={() => this.setState({ tab: 4 })} style={{ height: 50, borderTop: `1px solid #eee` }} className={`d-flex pointer flex-row bg-${tab === 4 ? 'gray-darkish' : 'white'}`}>
                                    <span className="black font-14 ml-3 align-self-center">Disclaimer</span>
                                    <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                </div>
                                <div onClick={() => this.setState({ tab: 5 })} style={{ height: 50 }} className={`d-flex pointer flex-row bg-${tab === 5 ? 'gray-darkish' : 'white'}`}>
                                    <span className="black font-14 ml-3 align-self-center">Schedule</span>
                                    <i className="ml-auto mr-3 fas fa-chevron-right black font-14 align-self-center" />
                                </div>
                            </Animated>

                            <div style={{ width: 30 }} className="" />

                            <Animated animationIn="fadeInRight" className="w-100 d-flex flex-column card shadow" style={{ borderRadius: 5 }}>
                                {tab === 1 && <RegistrationTab seasons={seasons} selectedSeason={selectedSeason} questions={questions} statuses={this.props.statuses} team={team} />}
                                {tab === 2 && <AccountingTab {...this.props} team={team} payments={payments} balance={balance} pastDue={pastDue} fnSelectSeason={this.selectSeason} 
                                selectedSeason={selectedSeason} seasons={seasons}  fnAddCharge={this.toggleAddCharge} 
                                fnAddCredit={this.toggleAddCredit} fnMakePayment={this.toggleMakePayment} fnDeletePayment={this.fnDeletePayment} 
                                fnMakeRefund={this.fnMakeRefund} fnChangePlan={() => this.setState({ changingPlan: true })} 
                                fullBalance={fullBalance} dues={this.state.dues} />}
                                {tab === 3 && <RosterTab roster={roster} coaches={coaches} seasons={seasons} selectedSeason={selectedSeason} />}
                                {tab === 4 && <DisclaimerTab disclaimer={disclaimer} />}
                            </Animated>
                        </div>
                    </div>

                </div>
            )}

            { /* Loader */}
            {!(team && bootstrapped && questions && coaches) && <Loader message="Loading Profile" />}

            {moving && <MoveTeamModal idOrderItem={this.state.idOrderItem} fnMoved={this.fnMoved} {...this.props} seasons={allSeasons} team={team} balance={this.state.totalAmount}
                isOpen={moving} toggle={this.toggleMoving} selectedSeason={selectedSeason} fullBalance={fullBalance} />}

            { /* Modal */}
            {addingCharge && <AddChargeModal isOpen={addingCharge} toggle={this.toggleAddCharge} idOrderItem={this.state.idOrderItem} fnRefreshPayments={this.fetchPayments} />}

            {addingCredit && <AddCreditModal isOpen={addingCredit} toggle={this.toggleAddCredit} idOrderItem={this.state.idOrderItem} fnRefreshPayments={this.fetchPayments} />}

            {makingPayment && <MakePaymentModal fnUpdate={this.fetchPayments} isOpen={makingPayment}
                toggle={this.toggleMakePayment} idOrderItem={this.state.idOrderItem} idSeason={this.state.selectedSeason.IdSeason}
                idLeague={this.props.season.IdLeague} idTeam={team.IdTeam} max={balance} />}

            {makeRefund && <MakeRefundModal fnUpdate={this.fetchPayments}
                isOpen={makeRefund} toggle={this.toggleRefund} selectedPayment={selectedPayment}
                fnMakeRefund={this.fnMakeRefund} fnDeletePayment={this.fnDeletePayment} />}

            {changingPlan && <ChangePlanModal fnChangePlan={this.fnChangePlan} isOpen={changingPlan} toggle={() => this.setState({ changingPlan: false })} season={selectedSeason} fullBalance={fullBalance} />}

        </section>
    }
}

export default TeamProfile;
