import React from 'react';
import './menu.css';
import paths from '../../ioc/seasonPaths';
import { Link } from 'react-router';
import _ from 'lodash';

class SeasonMenu extends React.Component {

    state = {
        tree: null,
        lastPath: ''
    }

    handlePath = () => {
        const { routes = [] } = this.props;
        if (routes.length) {

            // This routine will get the leaf of the url: 
            // Example: api/v4/seasons/bola => bola
            let leafPath = _.last(_.chain(routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'));

            Object.keys(paths).forEach(key => {
                const path = paths[key];
                if (path.sub_nav) {
                    let subFound = _.find(_.values(path.sub_nav), item => item.validPaths && item.validPaths.includes(leafPath));
                    if (subFound) {
                        path.on = true;
                        subFound.on = true;
                    } else {
                        path.on = false;
                    }
                }
            });
        }

        localStorage.setItem('sportslogic.seasonMenuState', JSON.stringify(paths));
    }

    // Lifecycle
    componentWillMount = () => {

        const { routes = [], season } = this.props;        
        this.handlePath();

        // Determine which of the 'paths' should be 'on'
        this.setState({
            tree: paths,
            leafPath: _.last(_.chain(routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'))
        });

    }
    componentWillReceiveProps = () => {
        this.handlePath();
        const { routes = [], season } = this.props;        
        this.setState({
            tree: paths,
            leafPath: _.last(_.chain(routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'))
        });
    }
    //

    openMenu = key => {
        // TODO: Should I close all other branches of the tree ?
        const { tree } = this.state,
            leaf = tree[key];
        Object.keys(tree).forEach(k => {
            if (k !== key) {
                tree[k].on = false;
            }
        })
        leaf.on = !leaf.on;
        this.setState({ tree });
        localStorage.setItem('sportslogic.seasonMenuState', JSON.stringify(tree));
    }

    closeAllMenu = () => {
        const { tree } = this.state;
        Object.keys(tree).forEach(k => { tree[k].on = false; });
    }

    renderSubButton = (key, subKey) => {
        const { tree } = this.state,
            { idSeason } = this.props,
            { season } = this.props,
            path = tree[key],
            subPath = path.sub_nav[subKey];
        if (subPath) {
            if (subPath.idLeague && parseInt(season.IdLeague, 10) !== parseInt(subPath.idLeague, 10)) {
                return <span key={subKey}></span>
            }
            if (subPath.idLeagues && !_.find(subPath.idLeagues, p => parseInt(p, 10) === parseInt(season.IdLeague, 10))) {
                return <span key={subKey}></span>
            }
            if (season && subPath.block && subPath.block.indexOf(season.IdSeasonType) === -1) {
                return <span key={subKey}></span>
            }
            return (
                <Link key={subKey} to={`/season/${idSeason}${subPath.path || ''}`} className="btn btn-link d-flex flex-row"
                    activeStyle={{ borderTop: subPath.on ? '1px solid #ccc' : '0px', borderBottom: subPath.on ? '1px solid #ccc' : '0px' }}>
                    <i className={` align-self-center big-font icon ${subPath.color || 'black'} fa fa-${subPath.icon}`} />
                    <span className=" align-self-center font-8 toUpper black name">{subPath.name}</span>
                </Link>
            )
        }
    }

    renderButton = (key) => {

        const { idSeason } = this.props,
            { tree } = this.state,
            path = tree[key];

        if (path.sub_nav) {
            return (
                <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'}`}>
                    <button className={`btn d-flex flex-row drop btn-${path.on ? 'on' : 'off'} btn-block`} onClick={() => this.openMenu(key)}>
                        <i className={`white align-self-center big-font icon fa fa-${path.icon}`} />
                        <span className="white align-self-center font-8 toUpper name">{path.name}</span>
                        <i className={`white align-self-center fa fa-caret-${path.on ? 'up' : 'down'} ml-auto`} />
                    </button>
                    <div className="sub-menu-wrapper">
                        {Object.keys(path.sub_nav).map((subKey) => {
                            return this.renderSubButton(key, subKey);
                        })}
                    </div>
                </div>
            );
        } else if (path.to) {
            return (
                <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'}`}>
                    <Link className={`btn d-flex flex-row drop btn-off btn-block`} to={`/season/${idSeason}/${path.to}`} activeClassName="btn-on" onlyActiveOnIndex={true} onClick={() => this.closeAllMenu()}>
                        <i className={`white align-self-center big-font icon fa fa-${path.icon}`} />
                        <span className="white align-self-center font-8 toUpper name">{path.name}</span>
                    </Link>
                </div>
            );
        } else {
            return (
                <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'}`}>
                    <Link className={`btn d-flex flex-row drop btn-off btn-block`} to={`/season/${idSeason}`} activeClassName="btn-on" onlyActiveOnIndex={true} onClick={() => this.closeAllMenu()}>
                        <i className={`white align-self-center big-font icon fa fa-${path.icon}`} />
                        <span className="white align-self-center font-8 toUpper name">{path.name}</span>
                    </Link>
                </div>
            );
        }

    }



    render() {
        const { season } = this.props;
        return (<section id="left-menu" className="bg-blue-dark">
            <div key="seasons" className={`menu-wrapper off`}>
                <Link className={`btn d-flex flex-row drop btn-off btn-block`} to={`/leagues/${season.IdLeague || 1}`} activeClassName="btn-on" onlyActiveOnIndex={true}>
                    <i className={`white align-self-center big-font icon fa fa-arrow-left`} />
                    <span className="white align-self-center font-8 toUpper name">Seasons</span>
                </Link>
            </div>
            {Object.keys(paths)
                .map(this.renderButton)}
        </section>)
    }
}

export default SeasonMenu;
