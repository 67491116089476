import React from 'react';
import { Link } from 'react-router';

class TeamCard extends React.Component {

  render() {

    const { team } = this.props;

    return (<div className="d-flex flex-column w-100 bg-white" style={{ position: 'relative' }}>
      <div key="header" className={`bg-${'Tournament' || 'danger'}`} style={{ height: 80 }} />
      <div key="teamInfo" style={{ marginTop: "3.5em" }} className="d-flex flex-column p-0">
        <span className={`m-auto font-${(team.Name || team.TeamName).length > 20 ? '12' : '16'} text-center`}>{team.Name || team.TeamName}</span>
        <span className="m-auto font-10">Id: {team.IdTeam}</span>
        <span className="m-auto font-10">Id Team Division: {team.IdTeamDivision}</span>
        <div className="d-flex flex-row font-12 margin-top-half justify-content-center mb-4">
          <i className={`fas fa-${team && team.Gender && team.Gender.toLowerCase()} idented align-self-center`} />
          <span className="idented-half align-self-center">{team.Gender} team</span>
        </div>
        <hr className="no-margin" />
        <Link style={{ width: 'auto', border: '1px solid #2cad3c', borderRadius: 20 }} onClick={this.props.toggle} to={`/season/${team.IdSeason}/team/${team.IdTeam}`} className="green mx-auto my-2 p-2 align-self-center"> Go to Profile <i className="fa fa-chevron-right" /></Link>
      </div>

      { /* Team Logo */}
      <div key="avatar" style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 160 }} className="d-flex flex-row justify-content-center">
        <div className="align-self-center cover bg-black" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid #e5e5e5', background: `url(${team.TeamImageUrl || team.Logo}) no-repeat center center` }} />
      </div>
    </div>)
  }
}

export default TeamCard;