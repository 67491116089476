// a reducer takes in 2 things:

// 1: the action (info about what happened)

// 2: a copy of the current state
import { generateRandomString } from "../../helpers";

function leagues(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'LEAGUES/SET':
            return action.leagues;
        case 'LEAGUE/FORCE/IMAGE/UPDATE':
            let idLeague = action.idLeague;            
            state.forEach(league => {
                if (league.IdLeague === idLeague){                    
                    // This looks like a hack, i know. But otherwise the UI wont refresh
                    league.LeagueImage = `https://api.sportslogic.net/api/v4/leagues/image/decode/${league.IdLeague}.png?rand=${generateRandomString(2)}`;                    
                }
            });
            return [...state];
        default:
            return state;
    }
}

export default leagues;