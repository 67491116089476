import React from 'react';
import Season from '../registration/partials/Season';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import CreateTeam from '../teams/CreateTeam';
import CreateSeason from '../teams/CreateSeason';
import { Jumbotron } from 'reactstrap';
import _ from 'lodash';
import TeamServices from './partials/TeamServices';
import TeamPremiumModal from '../../sqwad/modals/premium';

class RosterSeasons extends React.Component {
    state = {
        seasons: null, adding_season: false
    }
    componentWillMount() {
        const { setTitle, selectedTeam, teamActions, params } = this.props,
            { idTeam } = params;

        setTitle && setTitle({
            main: 'Seasons',
            sub: (selectedTeam || {}).TeamName
        });

        //if (!selectedTeam) {
        teamActions && teamActions.fetchTeam(idTeam);
        //}
        teamActions && teamActions.fetchTeamSeasons(idTeam);
        teamActions && teamActions.fetchSubscriptions(idTeam);
        teamActions && teamActions.fetchRights(idTeam);
    }

    componentWillReceiveProps = nextProps => {
        this.setState({ seasons: nextProps.seasons });
    }

    onPremiumSuccess = () => {
        const { familyActions, params } = this.props;
        familyActions.getFamilySubscriptions && familyActions.getFamilySubscriptions();
        familyActions.getTeamSubscriptions && familyActions.getTeamSubscriptions(params.idTeam);
    }

    renderTeamVideosButton = () => {
        const { selectedTeam, team_subscriptions, rights } = this.props;
        return <Animated animationIn="fadeInRight" isVisible={selectedTeam ? true : false} className="card tile shadow-box px-4 d-flex flex-row justify-content-start mt-2">
            {_.find(team_subscriptions, t => !t.Expired) && <Link to={(selectedTeam.IsTeamAdmin || selectedTeam.IsTeamOwner || rights.IsTeamManager) ? `/videos/team/${selectedTeam.IdTeam}/dashboard` : `/videos/team/${selectedTeam.IdTeam}/videos`} className="jeff_blue align-self-center justify-content-center w-100 h-100">
                <div className="d-flex flex-row justify-content-center p-2">
                    <img alt="" src="/images/icons/camera.png" className="align-self-center mr-4" style={{ maxWidth: 50 }} />
                    <span className="text-left align-self-center line1 font-18 montserrat">Team Videos</span>
                </div>
            </Link>}
            {!_.find(team_subscriptions, t => !t.Expired) && <button onClick={() => this.setState({ upgrading: true })} className="jeff_blue align-self-center justify-content-center w-100 h-100">
                <div className="d-flex flex-row justify-content-center p-2">
                    <img alt="" src="/images/icons/camera.png" className="align-self-center mr-4" style={{ maxWidth: 50 }} />
                    <span className="text-left align-self-center line1 font-18 montserrat">Team Videos</span>
                </div>
            </button>}
        </Animated>
    }

    render() {
        const { seasons, isEditing, upgrading } = this.state, { params, selectedTeam, rights, atp } = this.props, { idTeam } = params;
        const { team_subscriptions } = this.props;
        return (
            <section>
                {selectedTeam && rights && <Animated animationIn="fadeIn" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                    <div style={{ height: 20 }} />

                    {/* TEAM INFORMATION */}
                    <Animated animationIn="fadeInRight" isVisible={selectedTeam ? true : false} className="card tile shadow-box p-4 d-flex flex-row justify-content-start">
                        <div className="contains contain" style={{ width: 80, height: 80, background: `url(${selectedTeam.TeamImageUrl}) no-repeat center center white` }} />
                        <div className="d-flex flex-column align-self-center pl-4 montserrat w-100">
                            <span className="blue-dark font-14 toBold align-self-start">{selectedTeam.Name || selectedTeam.TeamName}</span>
                            <span className="blue-dark font-10 align-self-start">Sport: {selectedTeam.Community || selectedTeam.CommunityName}</span>
                            <span className="blue-dark font-10 align-self-start">Gender: {selectedTeam.Gender}</span>
                            <div className="d-flex flex-row">
                                <span className="blue-dark font-10 align-self-center">ID: {selectedTeam.IdTeam}</span>
                                {_.find(team_subscriptions, s => !s.Expired) && <span className="sqwad-font font-12 align-self-center jeff_blue ml-auto">SQWAD PRO</span>}
                            </div>
                        </div>
                        {(selectedTeam.IsTeamAdmin || selectedTeam.IsTeamOwner || rights.IsTeamManager) && <span onClick={() => this.setState({ isEditing: true })} className="blue underlined pointer hoverable" style={{ position: 'absolute', top: 10, right: 10 }}>Edit</span>}
                    </Animated>

                    {/* TEAM VIDEOS */}
                    {this.renderTeamVideosButton()}

                    {/* TEAM SERVICES */}
                    <TeamServices {...this.props} />

                    {/* SEASONS */}
                    <span className="font-18 toBold black mt-4">Seasons & Tournaments</span>
                    {seasons && seasons.length === 0 && <div key="no-leagues" className="d-flex flex-col justify-content-center" style={{ height: 300 }}>
                        <Jumbotron>
                            <h1 className="align-self-center block text-center">Seems like your team is not participating in any seasons yet!</h1>
                            <div className="d-flex flex-row justify-content-center mt-4">
                                <button className="align-self-center d-flex flex-row card shadow-box btn" onClick={() => this.setState({ adding_season: true })}><i className="fas fa-plus blue mr-2 align-self-center" /><span className="align-self-center">Add a season</span></button>
                                <Link to="/registration" className="align-self-center d-flex flex-row ml-4 card shadow-box btn"><i className="fas fa-plus blue mr-2 align-self-center" /><span className="align-self-center">Register your Team</span></Link>
                            </div>
                        </Jumbotron>
                    </div>}
                    {seasons && seasons.map((s, i) => <Link to={`/roster/${idTeam}/${s.IdSeason}`} className="card tile shadow-box mt-2 h-auto hoverable pointer" key={i}>
                        <Season season={s} dates={true} />
                    </Link>)}

                    <div style={{ height: 50 }} />
                </Animated>}

                {selectedTeam && (selectedTeam.IsTeamAdmin || selectedTeam.IsTeamOwner) && <div style={{ width: 80, height: 80, borderRadius: 40, border: `1px solid black`, position: 'fixed', bottom: 30, right: 20 }} className="btn-info d-flex flex-row justify-content-center" onClick={() => this.setState({ adding_season: true })}>
                    <i className="font-30 fas fa-plus white align-self-center" />
                </div>}

                {isEditing && <CreateTeam team={{ ...selectedTeam, rights }} {...this.props} isOpen={isEditing} toggle={() => this.setState({ isEditing: false })} />}

                {this.state.adding_season && <CreateSeason seasons={seasons} team={selectedTeam} {...this.props} isOpen={this.state.adding_season} teamActions={this.props.teamActions} toggle={() => this.setState({ adding_season: false })} />}

                {upgrading && <TeamPremiumModal
                    toggle={() => this.setState({ upgrading: false })}
                    isOpen={upgrading ? true : false}
                    selectedTeam={selectedTeam}
                    disclaimer={this.props.disclaimer}
                    family_actions={this.props.familyActions}
                    success={this.onPremiumSuccess}
                    target={_.find(atp || [], p => p.Amount)} />}


            </section>
        )
    }
}

export default RosterSeasons;