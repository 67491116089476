import React from 'react';

class Avatar extends React.Component {
    state = {}
    componentWillMount() {
        const { width, height, url, defaultImage, borderColor = 'black' } = this.props;
        this.setState({ width, height, url, defaultImage, borderColor });
    }
    onError = () => this.setState({ url: this.props.defaultImage });
    render() {
        const { width, height, url, borderColor } = this.state;
        return <div style={{ width, height, borderRadius: width / 2, borderColor, background: `url("${url}") no-repeat center center`, backgroundSize: 'cover' }}>
            <img alt="" src={url} onError={this.onError} style={{ width: 1, height: 1, opacity: 0 }} />
        </div>
    }
}

export default Avatar;
