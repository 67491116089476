import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux-sagas/actions/familyActions';
import Main from '../components/public/layout';

function mapStateToProps(state) {
    return {
        emails: state.emails
    }
}

function mapDispatchToProps(dispatch) {
    return { familyActions: bindActionCreators(actions, dispatch) }
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
