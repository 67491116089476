import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import _ from 'lodash';
import { formatPriceWithoutCurrency, formatPrice } from '../../../helpers';

class SuggestedAmountToPay extends React.Component {

    state = {
        step: 1,
        total: 0,
        nope: false,
        max: 0
    }

    componentWillMount() {      
        this.setState({
            total: this.props.total,
            max: Math.round((this.props.max + Number.EPSILON) * 100) / 100,
        });
    }

    handleChange = () => {
        // tODO: validate that what's in is a number
        this.setState({
            total: parseFloat(this.txtAmount.value),
            nope: !_.isNumber(parseFloat(this.txtAmount.value)) 
            || parseFloat(this.txtAmount.value) > Math.round((this.props.max + Number.EPSILON) * 100) / 100
            || parseFloat(this.txtAmount.value) === 0
            || !this.txtAmount.value
        });
    }

    continue = () => {
        const {nope} = this.state;
        if (!nope) {
            this.props.fnOk && this.props.fnOk(parseFloat(this.txtAmount.value));
        }
    }

    render() {
        const {isOpen, toggle} = this.props,
            { nope } = this.state;

            let remaining = Math.round((this.props.max + Number.EPSILON) * 100) / 100;
        return (
            <Modal style={ { width: 480 } } isOpen={ isOpen } toggle={ toggle } keyboard={ false } backdrop={ 'static' }>
              <ModalHeader toggle={ toggle }>
                <span>How much would you like to pay?</span>
              </ModalHeader>
              <ModalBody>
                <ReactCSSTransitionGroup transitionName="example" transitionAppear={ true } transitionAppearTimeout={ 500 } transitionEnterTimeout={ 500 }
                  transitionEnter={ true } transitionLeave={ false }>
                  <div className="jumbotron no-margin text-center" key="step1">
                    <div className="d-flex flex-row m-auto">
                      <i className="fa fa-dollar-sign align-self-center bigest-font margin-right-half" />
                      <input ref={ (i) => this.txtAmount = i } onChange={ this.handleChange } defaultValue={ formatPriceWithoutCurrency(remaining * 100) } type="number" className="form-control control-lg align-self-center text-center big-font margin-right"
                        style={ { height: 60, width: 200 } } />
                      <button onClick={ this.continue } className={ `btn btn-${nope ? 'default' : 'success'} btn-lg idented` }>Continue</button>
                    </div>
                  </div>
                </ReactCSSTransitionGroup>
              </ModalBody>
              { nope && (
                <ModalBody className="bg-danger white p-2">
                  { (!_.isNumber(this.state.total) || !this.state.total || this.state.total === 0) && <span>Invalid amount, please check.</span> }
                  { this.state.total > remaining && <span>You cannot pay more than { formatPrice(remaining * 100) }</span> }
                </ModalBody>) }
            </Modal>
        )
    }
}

export default SuggestedAmountToPay;