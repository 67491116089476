function reducer(state = [], action) {

  switch (action.type) {
    case 'USER/SET':
      return action.user;
    case 'USER/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;