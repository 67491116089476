function reducer(state = [], action) {
  // call saga to fetch the leagues from API ?
  switch (action.type) {
    case 'ZIP_CODES/SET':
      return action.zipCodes;
    case 'ZIP_CODES/CLEAR':
      return null;
    case 'ZIP_CODE/ADD':
      return [...state, action.code];
    case 'ZIP_CODE/UPDATE':
      return [...state.slice(0, action.index), action.code, ...state.slice(action.index + 1)];
    default:
      return state;
  }
}

export default reducer;