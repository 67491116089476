import React from 'react';
import { Modal, ModalBody, FormGroup, Label, Jumbotron } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { generateRandomId, getBase64 } from '../../../helpers';
import SeasonTournament from '../SeasonTournament';
import MembershipProgram from '../MembershipProgram';
import TournamentPosition from '../TournamentPosition';
import fakeAuth from '../../../fakeAuth';
import config from '../../../config';
import Fees from './superadmin/fees';
import Gateways from './superadmin/gateways';
import Validations from './admin/validations';
import LeagueAdministrators from './superadmin/administrators';

class LeagueSettings extends React.Component {

  state = {
    bootstrapped: false,
    league: null,
    selectedType: false,
    positions: [],
    selectedTournamentSeason: null,
  }

  // Lifecycle
  componentWillMount() {
    const { selectedLeague, leagueActions, seasonActions } = this.props;
    this.setState({ league: selectedLeague }, () => {
      this.props.fetchPositions && this.props.fetchPositions();

      //leagueActions && leagueActions.fetchImage(selectedLeague.IdLeague);
      leagueActions.fetchLeagueValidations(selectedLeague.IdLeague);
      leagueActions.fetchTournamentSeasons(selectedLeague.IdLeague);
      leagueActions.fetchMembershipPrograms(selectedLeague.IdLeague);
      seasonActions.fetchTournamentPoints(selectedLeague.IdCommunity);

      if (config.adminUsers.indexOf(fakeAuth.getUser().IdUser) !== -1) leagueActions.fetchLeagueFees(selectedLeague.IdLeague);
    });
  }
  componentWillReceiveProps = (nextProps) => {
    const { league } = this.state;
    const { fees, positions, feeTypes, membership_programs } = nextProps;
    this.setState({ fees, positions, feeTypes, membership_programs });

    if (nextProps.tournamentSeasons) {
      league.TournamentSeasons = nextProps.tournamentSeasons;
      this.setState({ league });
    }

    if (nextProps.membership_programs) {
      league.MembershipPrograms = nextProps.membership_programs;
      this.setState({ league });
    }
  }
  componentWillUnmount() {
    this.props.leagueActions && this.props.leagueActions.clearLeagueFees();
  }
  // 

  handleAvatar = e => {
    const { league } = this.state, { leagueActions } = this.props;
    getBase64(e.target.files[0]).then(data => {
      league.LeagueImage = league.NewLeagueImage = data;
      leagueActions && leagueActions.updateImage(league.IdLeague, data);
      this.setState({ league, leagueImage: data });
    });
  }

  addSeasonTournament = () => {
    const { league } = this.state;
    league.TournamentSeasons.push({
      IdLeague: league.IdLeague,
      DateStart: moment(),
      DateEnd: moment().add(1, 'month'),
      randomId: generateRandomId(19),
    });
    this.setState({ league });
  }

  addMembership = () => {
    const { league } = this.state;
    if (!league.MembershipPrograms) league.MembershipPrograms = [];
    league.MembershipPrograms.push({
      IdLeague: league.IdLeague,
      StartDate: moment(),
      EndDate: moment().add(6, 'months'),
      randomId: generateRandomId(12),
      Amount: 0
    });
    this.setState({ league });
  }

  removeSeasonTournament = (i) => {
    const { league } = this.state;
    league.TournamentSeasons = [...league.TournamentSeasons.slice(0, i), ...league.TournamentSeasons.slice(i + 1)];
    this.setState({ league });
  }

  removeMembershipProgram = (i) => {
    const { league } = this.state;
    league.MembershipPrograms = [...league.MembershipPrograms.slice(0, i), ...league.MembershipPrograms.slice(i + 1)];
    this.setState({ league });
  }

  configurePositions = selectedType => {
    // We override the non-related global positions with the values of the league-ones
    const { positions, selectedTournamentSeason } = this.state;

    //leaguePoints
    positions.forEach(position => {
      let found = _.find(selectedTournamentSeason.points, lp => (lp.IdTournamentDivisionResults === position.IdTournamentDivisionResults && lp.IdTournamentType === selectedType.IdTournamentType));
      position.randomId = generateRandomId(10);
      position.Points = (found || {}).Points;
      position.IdTournamentTypeDivisionPoints = (found || {}).IdTournamentTypeDivisionPoints;
    });

    this.setState({ selectedType, positions });
  };

  savePositions = () => {
    const { positions, league, selectedType, selectedTournamentSeason } = this.state, { leagueActions } = this.props;
    leagueActions && leagueActions.updateLeaguePoints(league.IdLeague, [...positions], selectedType.IdTournamentType, selectedTournamentSeason.IdTournamentSeason);
    this.setState({ selectedType: null, selectedTournamentSeason: null }, () => { });
  }

  onConfigureTournamentSeason = (ts) => {
    this.setState({ selectedTournamentSeason: ts });
  }

  render() {

    const { isOpen, toggle, tournamentPointsTypes, leagueImage, leagueActions, validations, processes } = this.props,
      { league, selectedType, positions, fees, feeTypes, selectedTournamentSeason } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>

        <ModalBody id="league-settings-popup" className="container-fluid no-margin no-padding">

          {/* LEAGUE LOGO AND NAME */}
          <div className="d-flex" style={{ height: 200, position: 'relative' }}>

            <div style={{ position: 'absolute', top: 0, height: 100, left: 0, right: 0, backgroundColor: '#121b32' }} />

            <div className=" d-flex flex-row justify-content-center w-25 " style={{ zIndex: 1, position: 'relative' }}>
              <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />
              <label htmlFor="fi" className="align-self-center">
                {(leagueImage || league.NewLeagueImage || league.LeagueImage) && <div className="cover" style={{
                  width: 120, height: 120, borderRadius: 60, border: '1px solid black',
                  background: `url(${leagueImage || league.NewLeagueImage || league.LeagueImage}) no-repeat center center white`,
                }} />}
                {!(leagueImage || league.NewLeagueImage || league.LeagueImage) && <i className="fa fa-shield-alt align-self-center gray" style={{ fontSize: '10em' }} />}
                <i className="fas fa-camera green font-24" style={{ position: 'absolute', bottom: 30, right: 20 }} htmlFor="fi" />
              </label>
            </div>

            <div style={{ zIndex: 1, height: 100 }} className="mx-auto  w-50 d-flex justify-content-center">
              <span className="white font-20 sqwad-font align-self-center text-center line1">{(league.Name || league.LeagueName).toUpperCase()}</span>
            </div>

            <div className="ml-auto  d-flex flex-row w-25" style={{ zIndex: 1, }}>
              <div style={{ width: 50 }} className="ml-auto">
                <button className="btn btn-link w-100" onClick={toggle}><i className="fa fa-times white font-18" /></button>
              </div>
            </div>

          </div>

          <div className="row m-0">

            <hr className="col-12" />

            {/* TOURNAMENT SEASON TYPES */}
            {!selectedType && <div className="col-12">

              <FormGroup className="margin-top-half">
                <div className="d-flex flex-row">
                  <h2 className="blue align-self-center">Tournament Seasons</h2>
                  <button onClick={this.addSeasonTournament} style={{ width: 100, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2 align-self-center"><i className="fa fa-plus" />{' '}Add</button>
                </div>
              </FormGroup>

              {league.TournamentSeasons && !league.TournamentSeasons.length && <Jumbotron>
                <h2>{league.LeagueName} has no Tournament Seasons</h2>
                <p className="font-10">You can create the 1st Tournament season by clicking the add button at the top</p>
              </Jumbotron>}

              {league.TournamentSeasons && league.TournamentSeasons.length > 0 && league.TournamentSeasons.map((ts, i) =>
                <SeasonTournament
                  positions={positions}
                  selected={selectedTournamentSeason && selectedTournamentSeason.IdTournamentSeason === ts.IdTournamentSeason}
                  entity={ts}
                  key={ts.IdTournamentSeason || ts.randomId}
                  fnRemove={() => this.removeSeasonTournament(i)}
                  tournamentPointsTypes={tournamentPointsTypes}
                  leagueActions={this.props.leagueActions}
                  league={league}
                  onConfigure={() => this.onConfigureTournamentSeason(ts)} />)}
            </div>}

            <hr />

            {/* MEMBERSHIP PROGRAMS */}
            <div className="col-12 mt-4">
              <FormGroup className="margin-top-half">
                <div className="d-flex flex-row">
                  <h2 className="blue align-self-center">Membership Programs</h2>
                  <button onClick={this.addMembership} style={{ width: 100, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2 align-self-center"><i className="fa fa-plus" />{' '}Add</button>
                </div>
              </FormGroup>
              {(!league.MembershipPrograms || !league.MembershipPrograms.length) && <Jumbotron>
                <h2>{league.LeagueName} has no Membership Programs</h2>
                <p className="font-10">You can create the 1st Membership Program by clicking the add button at the top</p>
              </Jumbotron>}
              {league.MembershipPrograms && league.MembershipPrograms.length > 0 && league.MembershipPrograms.map((membership, i) =>
                <MembershipProgram
                  entity={membership}
                  key={membership.IdMembership || membership.randomId}
                  fnRemove={() => this.removeMembershipProgram(i)}
                  leagueActions={this.props.leagueActions}
                  league={league}
                />)}
            </div>



            {selectedType && <div className="col-8 divider-vertical">
              <FormGroup className="margin-top-half">
                <Label>Configure {selectedType.TournamentType} for <span className="red">{selectedTournamentSeason.TournamentSeasonName}</span></Label>
                {positions && positions.map((position, i) => <TournamentPosition key={position.randomId} entity={position} />)}
                <button onClick={this.savePositions} className="btn btn-success btn-block">Save</button>
              </FormGroup>
            </div>}

          </div>
        </ModalBody>

        <Validations leagueActions={leagueActions} league={league} validations={validations} processes={processes} />

        <div style={{ height: 50 }} />

        <Jumbotron>
          <h2>Referral Registration Link</h2>
          <p className="font-10">Create this hyperlink into your organization's webpage for your customers to register into our system and find your seasons and tournaments with ease.</p>
          <p className="font-14 blue">https://sl.sportslogic.net/login/{league.IdLeague}</p>
        </Jumbotron>

        {/* SUPER ADMIN SETTINGS */}
        {config.adminUsers.indexOf(fakeAuth.getUser().IdUser) !== -1 && fees && (
          <Fees league={league} fees={fees} feeTypes={feeTypes} leagueActions={leagueActions} />
        )}

        {config.adminUsers.indexOf(fakeAuth.getUser().IdUser) !== -1 && (
          <Gateways league={league} />
        )}

        {config.adminUsers.indexOf(fakeAuth.getUser().IdUser) !== -1 && (
          <LeagueAdministrators league={league} />
        )}

      </Modal>
    )
  }
}

export default LeagueSettings;
