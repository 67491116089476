import React from 'react';
import './css.css';

class Terms_SQWAD extends React.Component {

    state = {
        league: null,
        waiver: null
    }

    componentWillMount() {

    }

    componentWillReceiveProps = (nextProps) => {

    }

    renderUnsigned = () => {        
        return (<section id="disclaimer" style={{ height: '100%' }}>
            <div className="d-flex flex-column">

                <h4 className="blue">SQWAD LLC TERMS OF SERVICE</h4>
                <hr />
                <div>

                    <p>To all participants these terms of service (the “Terms”) set out the terms and conditions by which
                    The SQWAD offers you access to use and enjoy all our services. The SQWAD is a sports
software app that welcomes all users.</p>
                    <p>Please read these Terms carefully and make sure you understand them all before using.</p>

                    <hr />
                    <h4>1. YOUR ACCOUNT</h4>
                    <hr />
                    <p>1.1. You either must be an adult or have permission from your parents or legal guardian.
                    To create an account and use The SQWAD services, you must: (i) be an adult; (ii) be an
                    emancipated minor; or (iii) have valid parent or legal guardian consent to be bound by these
                    Terms. If you’re not an adult or emancipated minor, or don’t understand this section, please talk
                    to your parents or legal guardian and ask for help. If you are the parent or legal guardian of a
                    minor who creates an account with us, you and the minor accept and agree to be bound by these
                    Terms. You are also responsible for all use of the account and compliance with these Terms by
                    the minor, including all purchases made on the account. You may not create or use an account or
                    use any of The SQWAD services on behalf of any other person or other legal entity or for a
commercial purpose.</p>

                    <p>1.2. You must always supply us with accurate and complete info including your real name.</p>
                    <p>1.3. What email do I use?:
                    We send account, legal and service related notices to the email address registered to your
                    account. These notifications may be really important (e.g., a notification of upcoming events,
                    schedule information ) so you must keep the email address associated with your account current
and (if we ask) verified.</p>
                    <p>1.4. Account login:
                    You must keep your Login Credentials private. You must notify us immediately if you become aware of any breach of security, including any
                    unauthorized account access or any loss, theft or unauthorized use or disclosure of your Login
                    Credentials so we can take appropriate steps. You are responsible for all losses on your account
                    where you have shared your Login Credentials or have failed to keep your account or Login
Credentials secure.</p>


                    <hr />
                    <h4>2. ACCOUNT TERMINATION</h4>
                    <hr />

                    <p>2.1 Can my account be suspended or terminated?</p>
                    <p>2.1.1 You. You may terminate or suspend your account at any time by contacting us at
info@thesqwad.com</p>
                    <p>2.1.2. Us. We may terminate or suspend your account without notice to you if we reasonably
                    determine, that:</p>
                    <p>You have breached any part of these Terms (including the User Rules);
                    You have (or we have reasonable grounds for suspecting that you have) made unauthorized use
                    of someone else’s account.</p>
                    <p>You have ( or we suspect that you are posting unacceptable content on The SQWAD app or
                    services.</p>
                    <p>If you think that we have made a mistake, please Contact Us with details and we will review
your case, though we may suspend your account during our review.</p>

                    <p>2.2. Will The SQWAD maintain my account forever, even if I stop using it? (If you abandon
                    your account for a very long period of time, we may terminate it.)
                    If you do not use your account for a prolonged period of time, The SQWAD has the right to
terminate the account.</p>


                    <hr />
                    <h4>3. LIMITED LICENSE</h4>
                    <hr />

                    <p>3.1. We grant you a limited, non-exclusive, non-transferable, revocable license to use and enjoy
                    The SQWAD services (and any Virtual Content) for your individual, non-commercial,
                    informational purposes only and expressly conditioned upon your compliance with these Terms.
                    If we terminate your account, any license granted by us to you in The SQWAD services and any
                    Virtual Content ends immediately. Unless otherwise expressly authorized by us in a signed
                    written contract, you may not sell, copy, exchange, loan, reverse engineer, decompile, derive
                    source code from, translate, lease, grant a security interest in, transfer, publish, assign or
otherwise distribute any content. Xxxxxxxxxxxxxxxx look this up</p>


                    <hr />
                    <h4>4. FEES &amp; TAXES</h4>
                    <hr />

                    <p>4.1 Some features of The SQWAD services may require you to pay a fee, and you agree that
                    you’ll provide accurate and complete payment info to us or the third-party payment provider
                    used by us. You further agree to pay all fees and applicable taxes incurred on your account. We
                    may revise the pricing for any part of The SQWAD services at any time. All fees and charges are
                    payable in accordance with payment terms in effect at the time the fee or the charge becomes due
                    and payable. We may, from time to time, modify, amend, or supplement our fees and fee-billing
                    methods, and such changes shall be effective immediately upon posting in these Terms or
                    elsewhere on our websites or apps. Except in cases of our gross negligence or willful
                    misconduct, if you fail to pay any fees you owe to us or we have to refund someone due to
                    unauthorized use of a payment made from your account, we may suspend or terminate your
account. See Section 2 (Account Termination) for more info.</p>

                    <p><strong>Subject to applicable law, any applicable fees and other charges for fee-based services are
                    payable in advance and aren’t refundable in whole or in part except as expressly provided in
                    these Terms. Except in cases of The SQWAD gross negligence, or willful misconduct or to the
                    extent these Terms say otherwise, you’re fully liable for all charges to your account, including
any unauthorized charges.</strong></p>


                    <hr />
                    <h4>5. USER RULES</h4>
                    <hr />

                    <p>5.1 You must also comply with the acceptable use and behavioral policies that we publish from
                    time to time on our websites, apps and the behavioral rules listed below (collectively the “User
                    Rules”). The User Rules posted to our website and apps or set out in this section are not meant to
                    be exhaustive, and we reserve the right to modify them, as well as take appropriate disciplinary
                    measures including temporary bans, account suspension or termination and deletion to protect
                    the integrity and spirit of The SQWAD services, regardless of whether a specific behavior is
                    listed in the User Rules as inappropriate.</p>

                    <p>The following are examples of behavior that warrant disciplinary measures:
                    Impersonating any person, business or entity, , or communicating in any way that makes it
                    appear that the communication originates from that user;</p>
                    <p>Publicly posting identifying info about other users;</p>
                    <p>Harassing, stalking or threatening other users;</p>
                    <p>Removing, altering or concealing any copyright, trademark, patent or other proprietary rights
                    notice of The SQWAD services. You also may not transmit content that violates or infringes the
                    rights of others, including patent, trademark, trade secret, copyright, publicity, personal rights or
                    other rights;</p>
                    <p>Transmitting or communicating any content which we reasonably believe to be offensive to users
                    including language that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar,
                    obscene, sexually explicit, or racially, ethically, or otherwise objectionable;</p>
                    <p>Selecting an account or user name that that is offensive, defamatory, vulgar, obscene, sexually
                    explicit, racially, ethnically, or otherwise objectionable. You may not use a misspelling or an
                    alternative spelling to circumvent this restriction on name choices, like user names. The
                    SQWAD may modify any name which The SQWAD reasonably believes violates this provision,
                    without notification to you, and may take further disciplinary measures, including account
                    termination for repeated violations;</p>


                    <hr />
                    <h4>6. USER GENERATED CONTENT</h4>
                    <hr />

                    <p>6.1 Users are responsible for any communications, user names, images, sounds, or other material
                    and info that you create, upload, use or transmit with or through The SQWAD (“Your Content”).
                    You should upload or transmit Your Content only if you agree that:
                    You grant us, from the time of uploading or transmission of Your Content, a worldwide,
                    perpetual, irrevocable, sub-licenseable, non-exclusive and royalty-free right and license to use,
                    reproduce, distribute, adapt, modify, translate, create derivative works based upon, publicly
                    perform, publicly display, digitally perform, make, have made, and import Your Content,
                    including, all copyrights, publicity rights, trademarks, trade secrets, patents, industrial rights and
                    all other intellectual and proprietary rights related to them, for the purpose of providing The
SQWAD without any compensation to you;</p>

                    <p>You waive any moral rights you may have in Your Content with respect to our use of Your
                    Content to the maximum extent permitted by the laws of your jurisdiction. If local laws do not
                    allow for waiver of moral rights, instead you grant The SQWAD the right to use Your Content
                    and all elements of Your Content with or without your name or pseudonym, as well as to freely
edit Your Content.</p>

                    <p>You represent, warrant and agree that none of Your Content will be subject to any obligation,
                    whether of confidentiality, attribution or otherwise, on the part of The SQWAD and The
SQAWD won’t be liable for any use or disclosure of Your Content.</p>

                    <p>You further represent, warrant and agree that Your Content shall not violate any third-party
rights.</p>

                    <hr />
                    <h4>7. MONITORING</h4>
                    <hr />

                    <p>7.1 We may actively monitor the use of The SQWAD services (but have no obligation to do so),
                    both on our own servers and on your computer or device, for a wide variety of different
                    purposes. Please be sure to read our Privacy Notice for important details about how we obtain
and process info in connection with your use of The SQWAD services.</p>

                    <hr />
                    <h4>8. UPDATES AND MODIFICATIONS</h4>
                    <hr />

                    <p>8.1 We may (and probably will) create updated versions of these Terms in the future as The
                    SQWAD services and applicable laws evolve. When we do, we’ll inform you of the new
                    agreement which will supersede and replace these Terms. You’ll be given an opportunity to
                    review any new agreement we present to you and decide whether you wish to agree to the
                    revised terms. If you accept the new agreement, you’ll be able to continue using The SQWAD
                    services. If you decline the new agreement, you won’t be able to use The SQWAD services
anymore.</p>

                    <p>8.2. In an effort to constantly improve The SQWAD services we develop, update and add new
                    services and features , you agree that we may change, modify, update, suspend, or restrict your
                    access to any features or parts of the features and may require that you download and install
                    updates to any software required to support The SQWAD services at any time without liability to
                    you. You also understand and agree that any such changes or updates to The SQWAD services
might change the system specifications necessary needed to use The SQWAD services .</p>


                    <hr />
                    <h4>9. WARRANTY DISCLAIMER</h4>
                    <hr />
                    <p>9.1. You assume all responsibility for the installation and use of, and results obtained from The
SQWAD services (no matter where you are based).</p>

                    <p>9.1.1 The SQWAD services are provided to you on an “as is” and “as available” basis without
                    warranties or representations of any kind, express or implied. You assume all responsibility for
                    your use of The SQWAD services. To the fullest extent permitted by applicable law, The
                    SQWAD disclaims all warranties, express or implied, which might apply to The SQWAD
                    services, including implied warranties of title, non-infringement, merchantability, fitness for a
                    particular purpose, any warranties that may arise from course of dealing, course of performance
                    or usage of trade, and any warranties as to the accuracy, reliability or quality of any content or
info contained within The SQWAD services.</p>

                    <p>9.1.2 The SQWAD is not responsible for the loss, transmission or delivery of content in The
SQWAD services via third party systems.</p>


                    <hr />
                    <h4>10. INDEMNIFICATION</h4>
                    <hr />

                    <p>10.1 To the extent permitted by law, except in cases involving our gross negligence or willful
                    misconduct, you hereby agree to indemnify, defend and hold harmless The SQWAD and its
                    subsidiaries, affiliates, officers and directors from and against any and all claims, lawsuits,
                    losses, liabilities and costs that arise or result from your misuse of The SQWAD services, any
                    violation by you of any of the provisions of these Terms (for example, if you infringe our IP
                    rights), or any infringement by you of any third party’s rights. The SQWAD reserves the right,
                    at its own expense and in its sole and absolute discretion, to assume the exclusive defense and
                    control of any matter otherwise subject to indemnification by you, in which event you’ll
cooperate with The SQWAD in asserting any available defenses.</p>

                    <p>For example, if you use The SQWAD services as part of a business operation (something you’re
                    not allowed to do) and we get sued because of something you do in breach of these Terms, you’ll
have to defend us in court and pay for any damages we incur.</p>


                    <hr />
                    <h4>11. LIMITATION OF LIABILITY</h4>
                    <hr />

                    <p>11.1. To the extent permitted by law, our total liability to you (whether for breach of this
                    contract, negligence or for any other reason whatever) for any loss, harm or damage suffered by
                    you in connection with your downloading, use and/or access of the The SQWAD services is
                    limited to the total amounts paid by you to The SQWAD during the six (6) months immediately
                    prior to the time your cause of action first arose.</p>
                    <p>We only make The SQWAD services available for domestic and private use. We are not liable
                    for business losses (such as any loss of profit, loss of business, business interruption, loss of
                    goodwill or loss of business opportunity). We also won’t have responsibility for any damage
                    which arises because you failed to install any update when it is made available by The SQWAD
                    or where you fail to maintain the minimum system specifications required. Separately, we are not
                    responsible for: (i) any loss that was not foreseeable at the time you entered into these Terms; or
                    (ii) any loss that we both knew might happen only because of your special circumstances
                    existing at the time you entered into these Terms (including all indirect damages).
                    Despite anything else we say in these Terms, WE DO NOT EXCLUDE OR LIMIT IN ANY
                    WAY OUR LIABILITY TO YOU WHERE IT WOULD BE UNLAWFUL TO DO SO,
                    including our liability for: (i) our fraud or fraudulent misrepresentation; (ii) willful or grossly
                    negligent behavior; (iii) for damage arising from a negligent breach of an obligation that is
                    essential for the performance of the contract by The SQWAD (“Cardinal Duties”) to the extent
                    that is typical and foreseeable; (iv) for any guarantee given by us to you; or (v) for any liability
                    under a jurisdiction’s applicable product liability legislation.</p>
                    <p>You may also have specific rights under local law in addition to those set out above.</p>

                    <hr />
                    <h4>12. GOVERNING LAW</h4>
                    <hr />

                    <p>12.1 These Terms and any action arising out of or in connection with these Terms will be
governed by the laws of the State of Florida,</p>

                    <hr />
                    <h4>13. ADDITIONAL TERMS APPLICABLE TO THE
SQWAD SERVICES</h4>
                    <hr />

                    <p>13.1. General Terms</p>
                    <p>You may access The SQWAD services we make available from certain authorized third party
                    stores or platforms (an authorized third party provider platform each a “Third Party Platform”),
                    only if you agree that:</p>
                    <p>These Terms are an agreement between us and you, and not between you and the Third Party
                    Platform. As between The SQWAD and the Third Party Platform, The SQWAD is solely
                    responsible for any The SQWAD services.</p>
                    <p>The Third Party Platform has no obligation to provide any maintenance and support services for
                    The SQWAD services.</p>
                    <p>The Third Party Platform is not responsible for addressing any claims you have relating to The
                    SQWAD services or your possession and use of The SQWAD Service.</p>
                    <p>If a third party claims any of The SQWAD services infringes another party&#39;s intellectual property
                    rights, as between the Third Party Platform and The SQWAD, the Third Party Platform will not
                    be responsible for the investigation, defense, settlement and discharge of any such claim.</p>
                    <p>The Third Party Platform and its subsidiaries are third-party beneficiaries of these Terms as they
                    relate to your license to The SQWAD services. Upon your acceptance of these Terms, the Third
                    Party Platform will have the right (and will be deemed to have accepted the right) to enforce
                    your license of The SQWAD services against you as a third-party beneficiary.
                    </p>
                    <p>You must also comply with all applicable third-party terms of service and licenses when
                    accessing or using the Third Party Platform including any additional terms that apply when you
                    download, stream, access or use any The SQWAD, Virtual Content or other The SQWAD
Services.</p>

                </div>
            </div>
        </section >)
    }


    render() {
        return this.renderUnsigned();
    }
}

export default Terms_SQWAD;