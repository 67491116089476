function reducer(state = [], action) {

    switch (action.type) {
        case 'REFERAL/SET': return action.referal;
        case 'AUTH/LOGOUT': return null;
        case 'REFERAL/CLEAR': return null;
        default: return state;
    }
}

export default reducer;