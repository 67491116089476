import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import { Link } from 'react-router';
import _ from 'lodash';
import Lists from './partials/Lists';
import Navigation from '../navigation';
import { Animated } from 'react-animated-css';

class SetRosters extends React.Component {

  state = {
    bootstrapped: false,
    selectedDivision: null,
    selectedTeam: null,
    isDivisionsOpen: false,
    isTeamsOpen: false,
    fetchingPlayers: false,
    players: null
  }

  // Lifecycle
  componentWillMount() {

    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Set Rosters',
      main: this.props.season ? this.props.season.Name : null
    });

    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {

    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Set Rosters',
        main: nextProps.season.Name || null
      });
    }

    if (nextProps.divisions && !this.state.bootstrapped) {
      this.setState({
        bootstrapped: true,
        selectedDivision: _.first(nextProps.divisions) || {},
        selectedTeam: (nextProps.divisions && nextProps.divisions.length) ? _.first(_.first(nextProps.divisions).Teams) : null
      }, () => {
        this.fetchPlayersOfDivision();
        this.fetchQuestionsOfDivision();
      });
    }

    if (nextProps.players) {
      this.setState({
        players: nextProps.players,
        fetchingPlayers: false
      });
    }
  }

  componentWillUnmount = () => {
    this.props.clearDivisions && this.props.clearDivisions();
    this.props.divisionsActions.clearPlayers && this.props.divisionsActions.clearPlayers();
    this.props.divisionsActions.clearQuestions && this.props.divisionsActions.clearQuestions();
  }
  //

  fetchPlayersOfDivision = () => {
    const { selectedDivision } = this.state;
    if (this.selectDivision) {
      this.setState({
        fetchingPlayers: true
      }, () => {
        this.props.divisionsActions &&
          this.props.divisionsActions.fetchPlayers(selectedDivision.IdDivision);
      });
    }
  }

  fetchQuestionsOfDivision = () => {
    const { selectedDivision } = this.state;
    this.props.divisionsActions && this.props.divisionsActions.fetchQuestions && this.props.divisionsActions.fetchQuestions(selectedDivision.IdDivision, 1);
  }

  toggleDivisions = () => {
    this.setState({
      isDivisionsOpen: !this.state.isDivisionsOpen
    });
  }

  toggleTeams = () => {
    this.setState({
      isTeamsOpen: !this.state.isTeamsOpen
    })
  }

  selectDivision = (selectedDivision) => {
    this.props.divisionsActions &&
      this.props.divisionsActions.clearPlayers();

    this.setState({
      players: [],
      selectedDivision,
      selectedTeam: _.first(selectedDivision.Teams),
      isDivisionsOpen: false
    }, () => {
      this.fetchPlayersOfDivision();
      this.fetchQuestionsOfDivision();
    });
  }

  selectTeam = (selectedTeam) => {
    this.setState({
      selectedTeam,
      isTeamsOpen: false
    });
  }

  render() {

    const { bootstrapped, selectedDivision, selectedTeam, error, updated } = this.state,
      { divisions, players, season } = this.props;

    let filteredDivisions = divisions;

    return (
      <section>

        <div className="w3-container mt-4">

          <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
            <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
              {this.state.error}
            </Alert>
            <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
              Season updated!
            </Alert>
          </div>

          {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
            <div className="w-100 black">
              <h3>{season.Name || season.SeasonName}</h3>
              <Link to={`/leagues/${season.IdLeague}`} className="blue"><h6 className="block">{season.LeagueName} Seasons</h6></Link>
            </div>
          </div>}

          <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
            <h5>Team Managements</h5>

            {this.props.season && <Navigation season={this.props.season} />}

            {(bootstrapped) && <Animated isVisible={bootstrapped ? true : false} animationIn="fadeIn" className="card shadow mt-3" style={{ position: 'absolute', top: 170, bottom: -300, left: 20, right: 20 }}>

              <div className="d-flex flex-row montserrat p-3">
                <div className="black">
                  <h1>Rosters</h1>
                </div>
                <div className="d-flex flex-row w-100">
                  <span className="align-self-center black ml-auto">Division</span>
                  <Dropdown className="ml-2 align-self-center" isOpen={this.state.isDivisionsOpen} toggle={this.toggleDivisions}>
                    <DropdownToggle caret className="btn-white black bordered btn align-self-center">
                      {selectedDivision.Name}
                    </DropdownToggle>
                    <DropdownMenu>
                      {filteredDivisions && filteredDivisions.map((division, i) => (
                        <DropdownItem key={i} onClick={() => this.selectDivision(division)}>
                          {division.Name} (
                          {division.Teams.length} teams)
                        </DropdownItem>))}
                    </DropdownMenu>
                  </Dropdown>

                  { /* Select team */}
                  <span className="ml-2 align-self-center black">Team</span>

                  <Dropdown className="ml-2 align-self-center" isOpen={this.state.isTeamsOpen} toggle={this.toggleTeams}>
                    <DropdownToggle caret className="btn-white black bordered btn align-self-center">
                      {selectedTeam ? `${selectedTeam.Name}` : 'N/A'}
                    </DropdownToggle>
                    <DropdownMenu>
                      {selectedDivision && selectedDivision.Teams && _.chain(selectedDivision.Teams)
                        .sortBy('Name')
                        .value().map((team, i) => (
                          <DropdownItem key={i} onClick={() => this.selectTeam(team)}>
                            {`${team.Name} (${team.TotalPlayers || '0'} players)`}
                          </DropdownItem>))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>

              { /* Divisions */}
              <div className="ml-4 mr-4" style={{ position: 'absolute', top: 70, bottom: 0, left: 0, right: 0 }}>
                {bootstrapped && selectedTeam && players && (
                  <Lists {...this.props} selectedTeam={selectedTeam} fetchingPlayers={this.state.fetchingPlayers} />)}
              </div>

              <div key="scrollTo" ref={(el) => { this.bracketsEnd = el; }} />
            </Animated>}
          </div>
        </div>
      </section>)
  }
}

export default SetRosters;