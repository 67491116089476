import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../../helpers';
import moment from 'moment';
import { Link } from 'react-router';
import config from '../../../../../config';
import request from 'superagent';

const source = {
  beginDrag(props) {
    return props;
  }
};

class Accepted extends React.Component {

  state = {
    popoverTeamsOpen: false,
    popoverRoleOpen: false
  }

  togglePopoverTeams = () => {
    this.setState({
      popoverTeamsOpen: !this.state.popoverTeamsOpen
    })
  }

  togglePopoverRole = () => {
    this.setState({
      popoverRoleOpen: !this.state.popoverRoleOpen
    })
  }

  assignTeam = (team) => {
    const { item } = this.props;

    item.Team = team.Name;
    item.IdTeamDivision = team.IdTeamDivision;
    item.IdTeam = team.IdTeam;

    // Update the store right away
    this.props.divisionsActions &&
      this.props.divisionsActions.updateCoachRole(item);
    this.setState({
      popoverTeamsOpen: false
    });

    // Call API with new IdDivision (and IdTeamDivision if team was selected), IdUser and the Calculated Fee Difference
    const url = `${config.apiEndpoint}/api/v4/coaches/assign/${team.IdTeamDivision}/${item.IdTeamUserRole}`;
    request.patch(url)
      .send({})
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then((data) => {
        // TODO: update the store
      });
  }

  assignRole = (role) => {
    const { item } = this.props;
    item.IdRoleOverride = role.IdRoleOverride;
    item.RoleOverride = role.RoleName;

    // Update the store right away
    this.props.divisionsActions &&
      this.props.divisionsActions.updateCoachRole(item);
    this.setState({
      popoverRoleOpen: false
    });

    // Call API 
    const url = `${config.apiEndpoint}/api/v4/roles/coach/${item.IdTeamUserRole}/${item.IdRoleOverride}`;
    request.patch(url)
      .send({})
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then((data) => {
        // TODO: update the store 
      });
  }

  render() {
    const 
      { connectDragSource, item, season } = this.props; // isDragging    

    return connectDragSource(
      <tr className={`${item.New ? 'bg-success white' : ''}`}>
        <td>
          {item.IdUser}
        </td>
        <td className="font-14">
          <Link className={`btn btn-link ${item.New ? 'white' : 'blue'} underlined`} to={`/season/${season.IdSeason}/player/${item.IdUser}`}>
            {item.LastName}
          </Link>
        </td>
        <td className="font-14">
          <Link className={`btn btn-link ${item.New ? 'white' : 'blue'} underlined`} to={`/season/${season.IdSeason}/player/${item.IdUser}`}>
            {item.FirstName}
          </Link>
        </td>
        <td>
          {item.RoleStatus}
        </td>
        <td>
          {moment(item.RegistrationDate).format('MMM Do YYYY')}
        </td>
        <td><span className="block">{item.EmailAddress}</span>
          <span className="block">{item.PhoneNumber}</span></td>
      </tr>
    );
  }
}

export default flow(
  DragSource(ItemTypes.ROSTEREDPLAYER, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(Accepted);