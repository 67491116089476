import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { toTitleCase } from '../../../helpers';
import fakeAuth from '../../../fakeAuth';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../common/CustomDatePicker2';

class SafeItem extends React.Component {

    state = {
        user: null,
        dropdownOpen: false
    }

    componentWillMount() {
        const { user } = this.props;
        this.setState({ user });
    }

    toggleEdit = () => {
        const { user } = this.state;
        user.editing = !user.editing;
        this.setState({ user });
    }

    saveDOB = () => {
        const { user } = this.state;
        user.editing = !user.editing;
        this.setState({ user });
    }

    toggleValidating = () => {
        const { user } = this.state;
        user.validating = !user.validating;
        this.setState({ user });
    }

    toggleUnvalidate = () => {
        const { user } = this.state;
        user.unvalidating = !user.unvalidating;
        this.setState({ user });
    }

    doConfirm = () => {

        const { user } = this.state, { leagueActions } = this.props;
        if (user.ExpirationDate) {
            //user.ExpirationDate = moment(user.ExpirationDate).format('YYYY-MM-DDTHH:mm:ss');
            user.validating = false;
            user.valid = true;
            this.setState({ user });
            leagueActions && leagueActions.validateDocument({ user, validation_type: 'SAFE' })
        } else {
            alert('Please select the expiration date')
        }

    }

    doUnvalidate = () => {
        const { user } = this.state, { leagueActions } = this.props;
        leagueActions && leagueActions.unvalidateDocument({ user, validation_type: 'SAFE' });
        user.ExpirationDate = null;
        user.unvalidating = false;
        user.valid = false;
        if (user.ValidationProcess) {
            user.ValidationProcess.TotalFiles = 0;
            user.ValidationProcess.instruments = [];
        }
        this.setState({ user });
    }

    toggleCategory = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

    assignCategory = cat => {
        const { user } = this.state;
        user.CoachCertificateCategory = cat === 'None' ? null : cat;
        this.setState({ user, dropdownOpen: false });
    }

    pending = () => {
        const { idLeague, onFile, tile } = this.props, { user } = this.state;

        return tile ? <figure className="card shadow d-flex flex-column p-4 h-100 w-100">
            <h2 className="mb-2 anton">Safe Sports</h2>
            <button onClick={() => onFile && user.ValidationProcess.instruments.length > 0 && onFile(user.ValidationProcess)} className="w-100  ml-auto d-flex flex-row">
                <span className="font-10 align-self-center">{user.ValidationProcess.instruments.length} new File(s)</span>
                <i className={`fas fa-file${user.ValidationProcess.instruments.length ? '-upload' : ''} font-20 ${user.ValidationProcess.instruments.length ? 'green' : 'gray'} align-self-center ml-2`} />
            </button>
            <div className="w-100 d-flex flex-row mt-auto">
                <button onClick={this.toggleUnvalidate} className="ml-auto btn btn-sm btn-link red bordered align-self-center">Reject</button>
                <button onClick={this.toggleValidating} className="btn btn-sm btn-success ml-1 align-self-center">Validate</button>
            </div>
        </figure> : <li className="p-2 d-flex flex-row card shadow mt-2">
            {/* SMALL */}
            <div className="w-100 d-flex flex-row align-self-center">
                <div className="cover" style={{ width: 40, height: 40, backgroundColor: 'black', borderRadius: 20, background: `url(${user.UserImageUrl}) no-repeat center center` }}></div>
                <div className="d-flex flex-column">
                    <Link title={user.EmailAddress} to={`/validations/${idLeague}/user/${user.IdUser}`} className="font-10 ml-2 blue montserrat align-self-center">{`${toTitleCase(user.NameLast)}, ${toTitleCase(user.NameFirst)}`}</Link>
                    <span className="black font-10 ml-2 ">{user.Gender}</span>
                    <span className="black font-8 ml-2 ">ID: {user.IdUser}</span>
                </div>
            </div>
            {user.ValidationProcess && user.ValidationProcess.TotalFiles > 0 && <button onClick={() => onFile && onFile(user.ValidationProcess)} className="w-100  ml-auto d-flex flex-row">
                <span className="font-10 align-self-center">{user.ValidationProcess.TotalFiles} new File(s)</span>
                <i className={`fas fa-file${user.ValidationProcess.TotalFiles ? '-upload' : ''} font-20 ${user.ValidationProcess.TotalFiles ? 'green' : 'gray'} align-self-center ml-2`} />
            </button>}
            {(!user.ValidationProcess || user.ValidationProcess.instruments.length === 0) && <div className="w-100 ml-auto d-flex flex-row">
                {user.UserModifiedName && <div className="d-flex flex-column  justify-content-center">
                    <span className="font-8 align-self-start">Last updated by {user.UserModifiedName}</span>
                    <span className="font-8 align-self-start">{moment(user.DateTimeModified || moment()).format('MMM Do YYYY')}</span>
                </div>}
                {!user.UserModifiedName && <div className="w-100 ml-auto d-flex flex-row">
                    <span className="font-10 align-self-center">No new File(s)</span>
                    <i className={`fas fa-file font-20 gray align-self-center ml-2`} />
                </div>}
            </div>}
            <div className="w-50 ml-auto d-flex flex-row  justify-content-end">
                <button onClick={this.toggleUnvalidate} className="ml-auto btn btn-sm btn-link red bordered align-self-center">Reject</button>
                <button onClick={this.toggleValidating} className="btn btn-sm btn-success ml-1 align-self-center">Validate</button>
            </div>
        </li>
    }

    valid = () => {
        const { idLeague, tile } = this.props, { user } = this.state;

        return tile ? <figure className="card shadow d-flex flex-column p-4 h-100 w-100">
            <h2 className="mb-2 anton">Safe Sports</h2>
            <div className="d-flex flex-column">
                <span className="font-8 align-self-start">Validated by {user.UserModifiedName || (fakeAuth.getUser() ? `${fakeAuth.getUser().NameFirst} ${fakeAuth.getUser().NameLast}` : 'YOU')}</span>
                <span className="font-8 align-self-start">{moment(user.DateTimeModified || moment()).format('MMM Do YYYY')}</span>
            </div>
            <button onClick={this.toggleUnvalidate} className="w-100 mt-auto d-flex flex-row">
                <span className="font-8 align-self-center">Valid until {moment(user.ExpirationDate).utc().format('MMM Do YYYY')}</span>
                <span className="green ml-auto font-20">Valid</span>
                <i className="ml-2 far fa-check-square font-20 green align-self-center" />
            </button>
        </figure> : <li className="p-2 d-flex flex-row card shadow mt-2">
            {/* SMALL */}
            <div className="w-100 d-flex flex-row align-self-center">
                <div className="cover" style={{ width: 40, height: 40, backgroundColor: 'black', borderRadius: 20, background: `url(${user.UserImageUrl}) no-repeat center center` }}></div>
                <div className="d-flex flex-column">
                    <Link title={user.EmailAddress} to={`/validations/${idLeague}/user/${user.IdUser}`} className="font-10 ml-2 blue montserrat align-self-center">{`${toTitleCase(user.NameLast)}, ${toTitleCase(user.NameFirst)}`}</Link>
                    <span className="black font-10 ml-2 ">{user.Gender}</span>
                    <span className="black font-8 ml-2 ">ID: {user.IdUser}</span>
                </div>
            </div>
            <div className="w-100 d-flex flex-row align-self-center ml-auto">
                Valid until {moment(user.ExpirationDate).utc().format('MMM Do YYYY')}
            </div>
            <div className="w-100 ml-auto d-flex flex-row">
                <div className="d-flex flex-column  justify-content-center">
                    <span className="font-8 align-self-start">Validated by {user.UserModifiedName || `${fakeAuth.getUser().NameFirst} ${fakeAuth.getUser().NameLast}`}</span>
                    <span className="font-8 align-self-start">{moment(user.DateTimeModified || moment()).format('MMM Do YYYY')}</span>
                </div>
            </div>
            <button onClick={this.toggleUnvalidate} className="w-50 ml-auto d-flex flex-row">
                <span className="green font-20 ml-auto">Valid</span>
                <i className="ml-2 far fa-check-square font-20 green align-self-center ml-2" />
            </button>
        </li>
    }

    handleChangeDate = (date) => {
        const { user } = this.state;
        user.ExpirationDate = date;
        this.setState({ user });
    }

    confirm = () => {
        const { user } = this.state, { idLeague, tile } = this.props;
        return tile ? <figure className="card shadow d-flex flex-column p-4 h-100 w-100">
            <h2 className="mb-2 anton">Safe Sports</h2>
            <div className="m-auto d-flex flex-column">
                <span>Expires on:</span>
                <DatePicker customInput={<CustomDatePicker />} selected={user.ExpirationDate} showYearDropdown scrollableYearDropdown
                    yearDropdownItemNumber={15} onChange={this.handleChangeDate} className="jeff_blue bordered align-self-center font-16"
                />
            </div>
            <div className="mt-auto d-flex flex-row">
                <button onClick={this.toggleValidating} className="btn btn-lg btn-link">No, cancel</button>
                <button onClick={this.doConfirm} className="btn btn-lg btn-success ml-2">Confirm</button>
            </div>
        </figure> : <li className="p-3 d-flex flex-row card shadow mt-2">
            <div className="cover" style={{ width: 80, height: 80, backgroundColor: 'black', borderRadius: 40, background: `url(${user.UserImageUrl}) no-repeat center center` }} />
            <div className="d-flex flex-column align-self-center ml-3">
                <Link to={`/validations/${idLeague}/user/10`} className="font-18 blue montserrat">{`${toTitleCase(user.NameLast)}, ${toTitleCase(user.NameFirst)}`}</Link>
                <span className="font-12 black montserrat">{moment(user.DateOfBirth.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</span>
                <span className="font-12 black montserrat">({moment().diff(moment(user.DateOfBirth.split('T')[0], 'YYYY-MM-DD'), 'years')} Years)</span>
            </div>
            <div className="ml-auto d-flex flex-column">
                <span>Expires on:</span>
                <DatePicker customInput={<CustomDatePicker />} selected={user.ExpirationDate} showYearDropdown scrollableYearDropdown
                    yearDropdownItemNumber={15} onChange={this.handleChangeDate} className="jeff_blue bordered align-self-center font-16"
                />
            </div>
            <div className="ml-auto d-flex flex-row">
                <button onClick={this.toggleValidating} className="btn btn-lg btn-link">No, cancel</button>
                <button onClick={this.doConfirm} className="btn btn-lg btn-success ml-2">Confirm</button>
            </div>
        </li>
    }

    unvalidate = () => {
        const { user } = this.state, { idLeague, tile } = this.props;
        return tile ? <figure className="card shadow d-flex flex-column p-4 h-100 w-100">
            <h2 className="mb-2 anton">Safe Sports</h2>
            <div className="ml-auto d-flex flex-row">
                <button onClick={this.toggleUnvalidate} className="btn btn-lg btn-link">No, cancel</button>
                <button onClick={this.doUnvalidate} className="btn btn-lg btn-danger ml-2">Yes, reject it</button>
            </div>
        </figure> : <li className="p-3 d-flex flex-row card shadow mt-2">
            <div className="cover" style={{ width: 80, height: 80, backgroundColor: 'black', borderRadius: 40, background: `url(${user.UserImageUrl}) no-repeat center center` }} />
            <div className="d-flex flex-column align-self-center ml-3">
                <Link to={`/validations/${idLeague}/user/10`} className="font-18 blue montserrat">{`${toTitleCase(user.NameLast)}, ${toTitleCase(user.NameFirst)}`}</Link>
                <span className="font-12 black montserrat">{moment(user.DateOfBirth.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}</span>
                <span className="font-12 black montserrat">({moment().diff(moment(user.DateOfBirth.split('T')[0], 'YYYY-MM-DD'), 'years')} Years)</span>
            </div>
            <div className="ml-auto d-flex flex-row">
                <button onClick={this.toggleUnvalidate} className="btn btn-lg btn-link">No, cancel</button>
                <button onClick={this.doUnvalidate} className="btn btn-lg btn-danger ml-2">Yes, reject it</button>
            </div>
        </li>
    }

    edit = () => {
        const { idLeague } = this.props, { user } = this.state;
        return <li className="p-2 d-flex flex-row card shadow mt-2">
            {/* SMALL */}
            <div className="w-100 d-flex flex-row align-self-center">
                <div className="cover" style={{ width: 40, height: 40, backgroundColor: 'black', borderRadius: 20, background: `url(${user.UserImageUrl}) no-repeat center center` }}></div>
                <Link to={`/validations/${idLeague}/user/10`} className="font-10 ml-2 blue montserrat align-self-center">{`${toTitleCase(user.NameLast)}, ${toTitleCase(user.NameFirst)}`}</Link>
            </div>
            <div className="w-100 d-flex flex-row align-self-center ml-auto">
                <input placeholder="MM" style={{ width: 60 }} className="form-control align-self-center" />
                <input placeholder="DD" style={{ width: 60 }} className="form-control align-self-center" />
                <input placeholder="YYYY" style={{ width: 80 }} className="form-control align-self-center" />
                <button className="btn btn-success" onClick={this.saveDOB}><i className="fas fa-check" /></button>
            </div>
            <div className="w-100 ml-auto d-flex flex-row" />
            <button onClick={this.toggleValidating} className="ml-auto d-flex flex-row">
                <span className="font-10 align-self-center">Validate</span>
                <i className="far fa-square font-20 gray align-self-center ml-2" />
            </button>
        </li>
    }

    render() {
        const { user } = this.state;
        if (user.validating) return this.confirm();
        if (user.editing) return this.edit();
        if (user.unvalidating) return this.unvalidate();
        if (user.valid) return this.valid();
        if (!user.valid) return this.pending();
        return
    }
}

export default SafeItem;
