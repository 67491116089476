module.exports = {
    dashboard: {
        name: 'Dashboard',
        icon: 'chart-pie',
        on: false
    },
    administration: {
        name: "Administration",
        icon: "cogs",
        on: false,
        sub_nav: {
            registration_fees: {
                validPaths: ['registration_fees'],
                name: "Registration / Fees",
                color: "blue",
                icon: "user",
                path: '/registration_fees'
            },
            seasons_edit: {
                validPaths: ['season'],
                name: "Season",
                color: "purple",
                icon: "cog",
                path: '/season'
            },
            divisions: {
                validPaths: ['divisions'],
                name: "Divisions",
                color: "purple",
                icon: "cogs",
                path: '/divisions'
            },
            custom_questions: {
                validPaths: ['custom_questions'],
                name: "Custom Questions",
                color: "red",
                icon: "question",
                path: '/custom_questions'
            },
            print_roster_manager: {
                validPaths: ['print_roster_manager'],
                name: 'Manage Roster',
                color: "blue",
                icon: "list-alt",
                path: '/print_roster_manager'
            },
            awards: {
                validPaths: ['awards', 'A', ':letter'],
                name: 'Awards',
                color: "yellow",
                icon: "medal",
                path: '/awards/A'
            },
            print_awards_manager: {
                validPaths: ['print_awards_manager'],
                name: 'Print Award Cards',
                color: "blue",
                icon: "print",
                path: '/print_awards_manager'
            },
            shirts: {
                name: 'T-Shirt',
                color: "green",
                icon: "tshirt",
                path: '/shirts/A'
            },
            player_cards: {
                validPaths: ['player_cards'],
                idLeague: '11826',
                name: 'Player Cards',
                color: "black",
                icon: "id-badge fas",
                path: '/player_cards'
            },
            say_cards: {
                validPaths: ['say_cards'],
                idLeagues: [25678, 28092, 25837],
                name: 'Player Cards (SAY)',
                color: "black",
                icon: "id-badge fas",
                path: '/say_cards'
            },            
            travel_roster: {
                validPaths: ['travel_rosters'],                
                name: 'Travel Rosters',
                color: "black",
                icon: "road fas",
                path: '/travel_rosters'
            }
        }
    },
    manage_schedule: {
        name: "Manage Schedule",
        icon: "far fa-calendar-alt",
        on: false,
        sub_nav: {
            flight_manager: {
                validPaths: ['flight_manager'],
                name: "Flight/Group Manager",
                color: 'blue',
                icon: 'plane',
                path: '/flight_manager'
            },
            manage_fields: {
                validPaths: ['manage_fields'],
                name: 'Manage Locations',
                color: 'green',
                icon: 'map-marker',
                path: '/manage_fields'
            },
            points: {
                validPaths: ['points'],
                name: 'Points / Tie Breakers',
                color: 'purple',
                icon: 'cog',
                path: '/points'
            },
            schedule_manager: {
                validPaths: ['schedule_manager', 'pairings', 'auto'],
                name: "Schedule Manager",
                color: "blue",
                icon: "far fa-clock",
                path: '/schedule_manager'
            },
            games_calendar: {
                validPaths: ['games_calendar'],
                name: "Games Calendar",
                color: "green",
                icon: "far fa-calendar-alt",
                path: '/games_calendar'
            },
            bracket_manager: {
                validPaths: ['bracket_manager'],
                name: "Bracket Manager",
                color: 'red',
                icon: 'share-alt',
                path: '/bracket_manager'
            },
            schedule_master_view: {
                validPaths: ['schedule_master_view'],
                name: "Schedule Master View",
                color: 'yellow',
                icon: 'sitemap',
                path: '/schedule_master_view'
            },
            print_game_cards: {
                validPaths: ['game_cards'],
                name: 'Print Game Cards',
                color: 'gray',
                icon: 'print',
                path: '/game_cards'
            },
            score_entry: {
                validPaths: ['score_entry'],
                name: 'Score Entry',
                color: 'black',
                icon: 'dice fas',
                path: '/score_entry'
            },
            field_layouts: {
                validPaths: ['field_layouts'],
                name: 'Fields Layout',
                color: 'green',
                icon: 'times fas',
                path: '/field_layouts'
            },
            slots: {
                validPaths: ['slots'],
                name: "Slots",
                color: "green",
                icon: "far fa-calendar-alt",
                path: '/slots'
            },
        }
    },
    team_management: {
        name: "Team Management",
        icon: "users",
        on: false,
        sub_nav: {
            teams: {
                validPaths: ['teams'],
                name: "Teams",
                color: "orange",
                icon: "fas fa-shield-alt",
                path: '/teams'
            },
            set_rosters: {
                validPaths: ['set_rosters'],
                name: "Set Rosters",
                color: "blue",
                icon: "list-ol",
                path: '/set_rosters'
            },
            lock_rosters: {
                validPaths: ['lock_rosters'],
                name: "Lock/Limit Rosters",
                color: 'red',
                icon: 'lock',
                path: '/lock_rosters'
            },
            coach_assign: {
                validPaths: ['coach_assign'],
                name: 'Coach Assign',
                color: 'purple',
                icon: 'bullhorn',
                path: '/coach_assign'
            },
            tryouts: {
                validPaths: ['tryouts'],
                name: 'Tryouts',
                color: 'black',
                icon: 'check',
                path: '/tryouts'
            },
            waitlists: {
                validPaths: ['waitlists'],
                name: 'waitlists',
                color: 'red',
                icon: 'list-ol',
                path: '/waitlists'
            }
        }
    },
    official_management: {
        name: "Ref/Official\nManagement",
        icon: "bullhorn",
        on: false,
        sub_nav: {
            team_edits: {
                name: "Teams Edit",
                color: "black",
                icon: "fas fa-shield-alt"
            },
        }
    },
    reports: {
        name: "Reports",
        icon: " fas fa-chart-bar",
        on: false,
        sub_nav: {
            registration: {
                validPaths: ['reports_registration'],
                name: "Registration",
                color: "black",
                icon: " fas fa-user-check",
                path: '/reports_registration'
            },
            reports_team_admins_reg: {
                validPaths: ['reports_team_admins_reg'],
                name: "Teams Adm. Reg.",
                color: "purple",
                icon: " fas fa-shield",
                path: '/reports_team_admins_reg'
            },
            reports_team_admins: {
                validPaths: ['reports_team_admins'],
                name: "Teams Admins",
                color: "orange",
                icon: " fas fa-shield",
                path: '/reports_team_admins'
            },
            team_registration: {
                validPaths: ['reports_team_registration'],
                name: "Teams Registration",
                color: "red",
                icon: " fas fa-shield",                
                path: '/reports_team_registration'
            },
            team_roster: {
                validPaths: ['reports_team_roster'],
                name: "Teams Rosters",
                color: "blue",
                icon: " fas fa-shield",
                block: [3],
                path: '/reports_team_roster'
            },
            donations: {
                validPaths: ['reports_donations'],
                name: "Donations",
                color: "purple",
                icon: " fas fa-donate",
                path: '/reports_donations'
            },
            accounting: {
                validPaths: ['reports_accounting'],
                name: "Accounting",
                color: "green",
                icon: " fas fa-file-invoice-dollar",
                path: '/reports_accounting'
            },
            master_transactions: {
                validPaths: ['master_transactions'],
                name: "Master Transactions",
                color: "blue",
                icon: " fas fa-file",
                path: '/master_transactions'
            },
            auto_pay: {
                validPaths: ['reports_auto_pay'],
                name: "Auto-Pay",
                color: "blue",
                icon: " far fa-credit-card",
                path: '/reports_auto_pay'
            },
            games: {
                validPaths: ['reports_games'],
                name: "Scheduled Games",
                color: "red",
                icon: " far fa-calendar-check",
                path: '/reports_games'
            },
            snapshot: {
                validPaths: ['reports_snapshot'],
                name: "Reg. Snapshot",
                color: "black",
                icon: " fas fa-camera",
                path: '/reports_snapshot'
            },
            revenue: {
                validPaths: ['reports_revenue'],
                name: 'Revenue',
                color: 'green',
                icon: " far fa-money-bill-alt",
                path: '/reports_revenue'
            },
            shirts: {
                validPaths: ['reports_shirts'],
                name: 'Shirts',
                color: 'yellow',
                icon: "tshirt",
                path: '/reports_shirts'
            },
            pcv: {
                validPaths: ['pcv'],
                name: 'Player Cards Delivered',
                color: 'red',
                icon: "address-card",
                path: '/reports/pcv/A'
            }
        }
    },
    validations: {
        name: "Validations",
        icon: " fas fa-check",
        on: false,
        to: 'validations',
        /*sub_nav: {
            photo: {
                validPaths: ['validations', 'profile_picture', ':letter_validation'],
                name: "Profile Picture",
                color: "black",
                icon: " fas fa-user-check",
                path: '/validations/profile_picture'
            }
        }*/
    }
};