import React from 'react';
import { DropTarget } from 'react-dnd';
import ListItem from './NonRosteredPlayer';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import _ from 'lodash';
import { ItemTypes } from '../../../../../helpers';

const target = {
  canDrop(props, monitor) {
    const {item} = monitor.getItem();
    return (item.IdRoleStatus === 2) ? true : false;
  },
  drop(props, monitor, component) {

    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }

    props.fnDrop && props.fnDrop(monitor.getItem().item);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class Comp extends React.Component {


  state = {
    filters: localStorage.getItem("COACHES.FILTERBY") ? JSON.parse(localStorage.getItem("COACHES.FILTERBY")) : {
      rostered: 1,
      nonRostered: 1
    }
  }

  toggleFilter = (args) => {
    const {filters} = this.state;
    filters[args.name] = !filters[args.name];
    localStorage.setItem("COACHES.FILTERBY", JSON.stringify(filters));
    this.setState({
      filters
    });
  }

  render() {

    const {connectDropTarget, isOver, canDrop, items} = this.props,
      {filters} = this.state;

    var filteredItems = _.filter(items, (item) => {
      if (!filters.rostered && item.IdTeamUserRole !== null) return false;
      if (!filters.nonRostered && item.IdTeamUserRole === null) return false;
      return true;
    });

    return connectDropTarget(
      <figure style={ { borderTop: '20px solid gray', position: 'absolute', bottom: 0, top: '50%', left: 0, right: 0, border: canDrop ? '3px solid red' : null } } className={ `bg-white no-margin ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}` }>
        { items && <div className="d-flex bg-info p-2 d-flex flex-row white" style={ { position: 'absolute', top: 0, width: '100%' } }>
                     <span>Coaches Requests</span>
                     <span className="idented-half">({ items.length } available out of { this.props.total })</span>
                     <span className="ml-auto"></span>
                   </div> }
        <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={ { position: 'absolute', top: 40, bottom: 0, overflow: 'auto' } }>
          <ReactCSSTransitionGroup component="tbody" transitionName="example" transitionAppear={ true } transitionLeaveTimeout={ 500 } transitionAppearTimeout={ 500 }
            transitionEnterTimeout={ 500 } transitionEnter={ true } transitionLeave={ false } className="w-100" style={ { display: 'table' } }>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Full Name</th>
              <th>Status</th>
              <th>DoB</th>
              <th>Coach Type</th>
              <th>Gender</th>
              <th>Contact</th>
              <th>City</th>
              <th>State</th>
              { this.props.questions && this.props.questions.map((q, i) => (
                  <th key={ i }>
                    { q.QuestionText }
                  </th>)) }
              <th></th>
            </tr>
            { this.props.items && this.props.selectedDivision && _.filter(filteredItems, (t) => {
                return t.IdDivision !== this.props.selectedDivision.IdDivision;
              }).map((item, index) => (
                <ListItem {...this.props} key={ index } item={ item } index={ index } />
              )) }
          </ReactCSSTransitionGroup>
        </table>
      </figure>

    )
  }
}

export default DropTarget(ItemTypes.ROSTEREDPLAYER, target, collect)(Comp);
