function reducer(state = [], action) {    
    switch (action.type) {
        case 'COLLABORATORS/SET':            
            return action.collaborators;
        case 'COLLABORATORS/CLEAR':
            return null;        
        default:
            return state;
    }
}

export default reducer;
