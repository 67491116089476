import _ from 'lodash';

export default function reducer(state = [], action) {
  switch (action.type) {
    case 'FIELDS/SET':
      return action.fields;
    case 'FIELDS/CLEAR':
      return null;
    case 'FIELDS/REMOVE':
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
    case 'FIELDS/ADD':
      return [...state, action.field];
    case 'FIELDS/UPDATE':
      return [...state.slice(0, action.index),
      _.extend(state[action.index], { Name: action.name }),
      ...state.slice(action.index + 1)]
    default:
      return state;
  }
}