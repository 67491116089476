function reducer(state = [], action) {
  switch (action.type) {
    case 'DEVELOPER/SET':
      return action.developer;
    case 'DEVELOPER/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;
