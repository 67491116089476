import React from 'react';
import moment from 'moment';
import _ from 'lodash';

class Sheet extends React.Component {

  state = { team: null, qualifies: true }
  componentWillMount() {
    const { team, season } = this.props;
    this.setState({ team, season }, () => {
      const { team } = this.state;
      if ((!team.players.length || team.players.length < 3 || _.find(team.players, p => { return !p.UserNameSignature || !p.IsValid })) && team.IdLeague === 11826) {
        this.setState({ qualifies: false });
      }
    });
  }

  render() {
    const { team } = this.state, { title } = this.props;

    return <section className="letter sheet margin-top ml-auto mr-auto container-fluid black font-10" style={{ overflow: 'hidden', width: 800 }}>
      <div className="black px-3 py-3 montserrat" style={{ minHeight: 1000, position: 'relative' }}>
        <div className="w-100 h-100" style={{ position: 'relative' }}>
          <div className="w-100">
            <div className="row clearfix">
              <div className="col-2 float-start">
                <img alt="" src={`${team.gb.GovernamentBodyImage}`}
                  style={{ height: 40, marginLeft: 'auto' }} />
              </div>
              <div className="col-10 float-end toBold">
                <div>{team.LeagueName}</div>
                <div>TEAM ROSTER - {title}</div>
              </div>
            </div>
            <hr />
            <div className="row clearfix font-2">
              <div className="col-6 float-start">
                <div><b>League:</b> {team.LeagueName}</div>
                <div><b>Club:</b> {team.Club || 'N/A'}</div>
                <div><b>Team:</b> {team.TeamName}</div>
              </div>
              <div className="col-6 float-end">
                <div><b>Team #:</b> {team.IdTeam}-{team.IdTeamDivision}</div>
                <div><b>Age Group:</b> {team.DivisionAgeGroupName} </div>
                <div><b>Competitive</b></div>
              </div>
            </div>
            <div className="text-center font-3 mt-4" style={{ borderBottom: '1px solid #013469' }}>Coaches ({team.coaches.length})</div>
            <table className="table-sm table font-2">
              <thead>
                <tr className="bold">
                  <th></th>
                  <th>Name (Last, First)</th>
                  <th>ID#</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Position</th>
                  <th>Lic. Lvl</th>
                </tr>
              </thead>
              <tbody>
                {team.coaches.map((coach, i) => <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{`${coach.NameLast}, ${coach.NameFirst}`}</td>
                  <td>{coach.IdUser}</td>
                  <td>{coach.PhoneNumber_Format}</td>
                  <td>{coach.EmailAddress}</td>
                  <td>{coach.RoleOverrideName}</td>
                  <td>{coach.CoachLicense}</td>
                </tr>)}
              </tbody>
            </table>

            <div className="text-center font-3 mt-4" style={{ borderBottom: '1px solid #013469' }}>Players ({team.players.length})</div>
            <table className="table-sm table font-2">
              <thead>
                <tr className="bold">
                  <th></th>
                  <th>Name (Last, First)</th>
                  <th>ID#</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Birth Year</th>
                  <th>Roster Date</th>
                </tr>
              </thead>
              <tbody>
                {team.players.map((player, i) => <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{`${player.NameLast}, ${player.NameFirst}`}</td>
                  <td>{player.IdUser}</td>
                  <td>{player.PhoneNumber_Format}</td>
                  <td>{player.EmailAddress}</td>
                  <td>{_.last(player.DateBirth.split('/'))}</td>
                  <td>{moment(player.RosterDate).utc().format('MM/DD/YYYY')}</td>
                </tr>)}
              </tbody>
            </table>
          </div>


        </div>
        <div style={{ position: 'absolute', bottom: 0 }} className="w-100 text-left">
          <span className="font-1">Printed: {moment().format('MM/DD/YYYY @HH:mm')}</span>
        </div>
        <div style={{ position: 'absolute', bottom: 0 }} className="w-100 text-right">
          <img alt="" src={`${team.gb.GovernamentBodyImage}`} style={{ height: 40, marginLeft: 'auto', marginRight: 25 }} />
        </div>
      </div>
    </section>
  }
}

export default Sheet;
