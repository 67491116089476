import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';

class Menu extends React.Component {
    render() {
        const { idLeague, option } = this.props;

        return <div>{/* LEFT MENU */}
            <Animated animationIn="fadeInLeft" style={{ position: 'fixed', top: '6em', left: 0, width: 90, bottom: 0, overflowY: 'auto' }} className="bg-blue-dark">
                <div className="d-flex flex-column justify-content-start">

                    <Link to={`/leagues/${idLeague}`} className="w-100 d-flex flex-row justify-content-center zoom" style={{ height: 90 }}>
                        <div className={`d-flex ${option === 'seasons' ? 'yellow' : 'white'}  flex-column justify-content-center align-self-center h-auto`}>
                            <i className="icon-triangle font-20 align-self-center" />
                            <span className="font-10 align-self-center text-center line1 mt-2">Seasons</span>
                        </div>
                    </Link>

                    <Link to={`/message_center/${idLeague}`} className="w-100 d-flex flex-row justify-content-center zoom" style={{ height: 90 }}>
                        <div className={`d-flex ${option === 'message_center' ? 'yellow' : 'white'}  flex-column justify-content-center align-self-center h-auto`}>
                            <i className="icon-chat font-20 align-self-center" />
                            <span className="font-10 align-self-center text-center line1 mt-2">Message Center</span>
                        </div>
                    </Link>

                    <Link to={`/rankings/${idLeague}`} className="w-100 d-flex flex-row justify-content-center zoom" style={{ height: 90 }}>
                        <div className={`d-flex ${option === 'rankings' ? 'yellow' : 'white'}  flex-column justify-content-center align-self-center h-auto`}>
                            <i className="icon-competitive font-20 align-self-center" />
                            <span className="font-10 align-self-center text-center line1 mt-2">Rankings</span>
                        </div>
                    </Link>

                    <Link to={`/coupons/${idLeague}`} className="w-100 d-flex flex-row justify-content-center zoom" style={{ height: 90 }}>
                        <div className={`d-flex ${option === 'coupons' ? 'yellow' : 'white'}  flex-column justify-content-center align-self-center h-auto`}>
                            <i className="fas fa-ticket-alt font-20 align-self-center" />
                            <span className="font-10 align-self-center text-center line1 mt-2">Coupons</span>
                        </div>
                    </Link>

                    <Link to={`/pcv/${idLeague}/A`} className="w-100 d-flex flex-row justify-content-center zoom" style={{ height: 90 }}>
                        <div className={`d-flex ${option === 'pcv' ? 'yellow' : 'white'}  flex-column justify-content-center align-self-center h-auto`}>
                            <i className="fas fa-id-card-alt font-20 align-self-center" />
                            <span className="font-10 align-self-center text-center line1 mt-2">Player<br />Cards</span>
                        </div>
                    </Link>

                    <Link to={`/ads/${idLeague}`} className="w-100 d-flex flex-row justify-content-center zoom" style={{ height: 90 }}>
                        <div className={`d-flex ${option === 'ads' ? 'yellow' : 'white'}  flex-column justify-content-center align-self-center h-auto`}>
                            <i className="fas fa-audio-description font-20 align-self-center" />
                            <span className="font-10 align-self-center text-center line1 mt-2">Ads</span>
                        </div>
                    </Link>

                    <Link to={`/validations/${idLeague}`} className="w-100 d-flex flex-row justify-content-center zoom" style={{ height: 90 }}>
                        <div className={`d-flex ${option === 'validations' ? 'yellow' : 'white'} flex-column justify-content-center align-self-center h-auto`}>
                            <i className="fas fa-check-square font-20 align-self-center" />
                            <span className=" font-10 align-self-center text-center line1 mt-2">Validations</span>
                        </div>
                    </Link>

                </div>
            </Animated>
        </div>
    }
}

export default Menu;
