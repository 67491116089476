import React from 'react';
import { formatPrice } from '../../../../helpers';
import { Animated } from 'react-animated-css';

const PaymentTypeItem = (props) => {
  const { item, index } = props;
  let icon = 'fas fa-question';
  switch (item.PaymentMethod) {
    case 'Discover': icon = 'fab fa-cc-discover black'; break;
    case 'Visa': icon = 'fab fa-cc-visa black'; break;
    case 'Master Card': icon = 'fab fa-cc-mastercard black'; break;
    case 'Amex': icon = 'fab fa-cc-amex black'; break;
    case 'Cash': icon = 'fas fa-money-bill-alt black'; break;
    case 'Check': icon = 'fas fa-money-check-alt black'; break;
    case 'Check Received': icon = 'fas fa-money-check-alt green'; break;
    case 'Cash Received': icon = 'fas fa-money-bill-alt green'; break;
    default: break;
  }
  return (
    <Animated animationIn="fadeIn" animationInDelay={index*300} className="d-flex flex-column">
      <span className="black font-10 align-self-center">{item.PaymentMethod}</span>
      <i className={`${icon}  bigest-font align-self-center`} />
      <span className="green font-12 align-self-center">{formatPrice(item.TotalReceived * 100)}</span>      
    </Animated>
  );
}

export default PaymentTypeItem;