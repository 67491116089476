import React from 'react';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import { formatPrice, detectCreditCardType } from '../../../helpers';
import PaymentHistory from './PaymentHistory';
import SuggestedAmountToPay from './SuggestedAmountToPay';
import PayingPlacebo from '../common/PayingPlacebo';
import Dues from './Dues';
import moment from 'moment';
import request from 'superagent';
import config from '../../../config';
import Season from '../registration/partials/Season';
import Division from '../registration/partials/Division';
import Person from '../registration/partials/Person';
import Team from '../registration/partials/Team';

//const Soon = () => {
class TeamPayments extends React.Component {

    state = {
        selectedTeam: null,
        selectedUser: null,
        selectedRole: null,
        selectedSeason: null,
        selectedLeague: null,
        bootstrapped: false,
        step: 1,
        mode: null,
        suggestAmount: false,
        willPay: 0,
        invalidCC: false,
        card: null,
        paying: false,
        isDuesOpen: false
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, pouch, league } = this.props;

        if (!pouch) {
            this.props.router.push('/payments');
            return;
        }

        setTitle && setTitle({
            main: 'Payments',
            sub: (pouch && pouch.selectedLeague) ? pouch.selectedLeague.LeagueName : null
        });

        this.setState(_.extend(this.state, this.props.pouch), function () {

            // TODO: fetch divisions and stuffs
            if (pouch && pouch.selectedSeason) {
                this.props.paymentsActions &&
                    this.props.paymentsActions.fetchPayments(pouch.selectedTeam.IdTeamDivision, pouch.selectedSeason.IdSeason, 2); // [1: users, 2: teams]
            }
            if ((!league || !league.IdLeague) && pouch && pouch.selectedLeague) {
                this.props.leagueActions &&
                    this.props.leagueActions.fetchLeague(pouch.selectedLeague.IdLeague)
            }
        });

        // if !pouch, redirect to /payments

    }

    componentWillReceiveProps = (nextProps) => {
        this.setState(_.extend(nextProps.pouch, {
            bootstrapped: true,
            autoPaySettings: nextProps.autoPaySettings
        }));
    }

    componentWillUnmount() {
        this.props.paymentsActions && this.props.paymentsActions.clearBalance();
        this.props.paymentsActions && this.props.paymentsActions.clearPayments();
        this.props.paymentsActions && this.props.paymentsActions.clearDues();
        this.props.paymentsActions && this.props.paymentsActions.clearPastDues();
    }
    //

    back = () => {
        const { step } = this.state,
            { league } = this.props;
        if (step === 1) {
            this.props.router && this.props.router.push('/payments/' + league.IdLeague);
        } else {
            this.setState({
                step: this.state.step - 1
            })
        }
    }

    leagueImageError = () => {
        const { selectedLeague } = this.state;
        selectedLeague.LeagueImage = '/images/defaults/teamLogo.png';
        this.setState({
            selectedLeague
        });
    }

    suggestAmountToPay = () => {
        this.setState({
            suggestAmount: !this.state.suggestAmount
        });
    }

    goPay = (amount) => {
        this.setState({
            willPay: amount,
            step: 2,
            suggestAmount: false
        });
    }

    toggleDues = () => {
        this.setState({
            isDuesOpen: !this.state.isDuesOpen
        });
    }

    validateNewCard = () => {
        var valid = true;
        if (!this.txtCardNumber.value ||
            !this.txtNameOnCard.value ||
            !this.txtExpDate.value ||
            !this.txtCVV.value ||
            !this.txtZipCode.value) {
            valid = false;
        } else if (!detectCreditCardType(this.txtCardNumber.value).Name) {
            valid = false;
        }
        return valid;
    }

    pay = () => {
        let valid = false;
        var selectedCard = {};
        if (this.validateNewCard()) {
            selectedCard = {
                number: this.txtCardNumber.value,
                name: this.txtNameOnCard.value,
                cvv: this.txtCVV.value,
                expdate: this.txtExpDate.value,
                zip: this.txtZipCode.value
            }
            valid = true;
        } else {
            // Invalid Credit Card
            document.getElementById('lblTotalWrapper').scrollIntoView();
        }

        valid && this.setState({
            paying: true,
            card: selectedCard,
            invalidCC: false
        });

        !valid && this.setState({
            invalidCC: true
        });

    }

    togglePayingPlacebo = () => {
        this.setState({
            paying: !this.state.paying
        })
    }

    successPayment = () => {
        const { pouch } = this.props;
        this.props.paymentsActions &&
            this.props.paymentsActions.fetchPayments(pouch.selectedTeam.IdTeamDivision, pouch.selectedSeason.IdSeason, 2); // [1: users, 2: teams]
        this.setState({
            step: 1,
            paying: false
        });
    }

    toggleAutoPay = () => {
        const { autoPaySettings, selectedSeason } = this.state;
        autoPaySettings.IsAutoPay = !autoPaySettings.IsAutoPay;
        this.setState({ autoPaySettings });
        const url = [config.apiEndpoint, 'api/v4/registration/autopay/set', selectedSeason.IdSeason].join('/');

        request.patch(url)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({}).then(() => { });
    }

    render() {
        const { paying, suggestAmount, selectedSeason, autoPaySettings, selectedDivision, selectedUser,
            selectedTeam, step, bootstrapped, willPay, invalidCC } = this.state;
        const { fetchingData, league, payments, balance, } = this.props;

        // Re-calculate
        var totalPaid = balance ? balance.TotalPaid : 0;
        var totalDue = balance ? balance.TotalDue : 0;
        var totalAmount = balance ? balance.TotalAmount : 0;
        var currentDue = balance ? (balance.IsPaymentPastDue ? balance.TodayBalance : balance.NextPayment) : 0;

        return (
            <div>

                <div style={{ height: 40 }} />
                <div className="m-auto card tile shadow-box rounded-item align-self-center w-100 " style={{ minWidth: 500, maxWidth: 600 }}>
                    <Season season={selectedSeason} />
                    <Team selectedTeam={selectedTeam} />
                    {selectedUser && <Person selectedUser={selectedUser} />}
                    {selectedDivision && <Division selectedDivision={selectedDivision} />}
                </div>

                {step === 1 && (
                    <section>

                        {bootstrapped && balance && (
                            <section key="balance" className="m-auto" style={{ minWidth: 500, maxWidth: 600 }}>
                                <span className="block font-18 toBold black mt-4">Current Balance</span>
                                <Animated animationIn="fadeIn" className="d-flex flex-row p-3 card tile shadow-box">
                                    <div className="align-self-center d-flex flex-column">
                                        <span className="">Total Paid: {formatPrice(totalPaid * 100)}</span>
                                        {totalDue > 0 && (
                                            <button onClick={this.toggleDues} className={`pointer underlined ${(balance && balance.IsPaymentPastDue) ? 'red' : 'green'}`}>
                                                {(balance && balance.IsPaymentPastDue) ? "Past Due: " : "Next Due: "}
                                                {formatPrice(currentDue * 100)}
                                                {' '} (
                                                {(balance && balance.IsPaymentPastDue) ? moment(balance.LastDateDue).format('MMM Do YYYY') : moment(balance.NextPaymentDate).format('MMM Do YYYY')})
                                            </button>)}
                                        <span className="font-16">Total Amount: {formatPrice(totalAmount * 100)}</span>
                                        {autoPaySettings !== null && autoPaySettings.LeagueAllowAutoPay === 1 && (
                                            <button onClick={this.toggleAutoPay} type="button" className={`btn btn-link text-left`}><i className={`margin-right-half ${autoPaySettings.IsAutoPay ? 'fa fa-check-circle green' : 'far fa-circle'}`} /><span>Auto-pay</span></button>)}
                                    </div>
                                    {totalDue > 0 && <div className="align-self-center ml-auto">
                                        <button className="btn btn-lg btn-success" style={{ borderRadius: 25 }} onClick={this.suggestAmountToPay}>Make a Payment</button>
                                    </div>}
                                    {totalDue === 0 && <i className="align-self-center fa fa-check green bigest-font ml-auto" />}
                                </Animated>
                            </section>
                        )}

                        {bootstrapped && payments && (
                            <section className="d-flex flex-column block m-auto" key="payments" style={{ maxWidth: 600 }}>
                                <span className="block font-18 toBold black mt-4">History</span>

                                <ul className="d-flex flex-column w-100">
                                    {payments.map((payment, i) => <li className="w-100" key={i}>
                                        <PaymentHistory payment={payment} key={i} />
                                    </li>)}
                                </ul>
                            </section>
                        )}

                        <div style={{ height: 100 }} />
                        
                    </section>
                )}


                {/* PAY */}
                {step === 2 && (
                    <section className="m-auto" style={{ maxWidth: 600, minWidth: 500, borderLeft: '1px solid #e5e5e5', borderRight: '1px solid #e5e5e5' }}>
                        <div className="card tile shadow-box p-2 mt-4 text-center" id="lblTotalWrapper">
                            <span className="jeff_blue bigest-font">Total: {formatPrice(willPay * 100)}</span>
                        </div>
                        {willPay > 0 && !invalidCC && <span className="block font-18 toBold black mt-4">Credit Card Information</span>}
                        {willPay > 0 && invalidCC && <div className="bg-warning p-2 big-font text-center">Invalid Credit Card!</div>}
                        <section className={`p-3 card tile shadow-box m-auto font-12 align-self-center ${willPay <= 0 ? 'hide' : null}`}>
                            <div className="margin-bottom-half d-flex flex-row">
                                <label className=" align-self-center">Card Number
                                    {' '}
                                    {(!this.txtCardNumber || !this.txtCardNumber.value) && <i className="red fa fa-asterisk" />}
                                </label>
                                <input className="form-control ml-auto align-self-center" maxLength="16" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtCardNumber = i} />
                            </div>
                            <div className="margin-bottom-half d-flex flex-row">
                                <label className=" align-self-center">Name on Card
                                    {' '}
                                    {(!this.txtNameOnCard || !this.txtNameOnCard.value) && <i className="red fa fa-asterisk" />}
                                </label>
                                <input className="form-control ml-auto align-self-center" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtNameOnCard = i} />
                            </div>
                            <div className="margin-bottom-half d-flex flex-row">
                                <label className=" align-self-center">Exp Date
                                    {' '}
                                    {(!this.txtExpDate || !this.txtExpDate.value) && <i className="red fa fa-asterisk" />}
                                </label>
                                <input className="form-control ml-auto align-self-center" maxLength="4" placeholder="MMYY" style={{ width: 100 }} type="text" ref={(i) => this.txtExpDate = i} />
                            </div>
                            <div className="margin-bottom-half d-flex flex-row">
                                <label className=" align-self-center">CVV
                                    {' '}
                                    {(!this.txtCVV || !this.txtCVV.value) && <i className="red fa fa-asterisk" />}
                                </label>
                                <input className="form-control ml-auto align-self-center" type="text" maxLength="4" style={{ width: 100 }} ref={(i) => this.txtCVV = i} />
                            </div>
                            <div className="d-flex flex-row">
                                <label className=" align-self-center">Zip / Postal
                                    {' '}
                                    {(!this.txtZipCode || !this.txtZipCode.value) && <i className="red fa fa-asterisk" />}
                                </label>
                                <input className="form-control ml-auto align-self-center" type="text" style={{ width: 100 }} ref={(i) => this.txtZipCode = i} />
                            </div>
                        </section>
                        {willPay > 0 && <button id="btnPay" className="btn btn-lg btn-block btn-success bigest-font" onClick={this.pay}>
                            Pay
                        </button>}
                    </section>
                )}


                { /* Loader */}
                {bootstrapped && fetchingData && (
                    <div className="d-flex justify-content-center centered-message" key="laoder">
                        <h1 className="white align-self-center block text-center"><i className="fa fa-spin fa-cog" /></h1> </div>)}
                {league && (
                    <button onClick={this.back} to={`/payments/${league.IdLeague}`} className="btn btn-link float-left" style={{ zIndex: 10, position: 'absolute', top: 0, left: 0 }}>
                        <i className="fa fa-arrow-left" />
                        {' '}
                        <span>Back</span>
                    </button>)}
                <SuggestedAmountToPay max={totalDue} isOpen={suggestAmount} toggle={this.suggestAmountToPay} total={currentDue} fnOk={this.goPay}
                />
                {paying && <PayingPlacebo card={this.state.card} total={willPay} toggle={this.togglePayingPlacebo} isOpen={paying} due={_.first(this.props.dues)} team={selectedTeam}
                    fnOk={this.successPayment} />}
                {this.state.isDuesOpen && <Dues isOpen={this.state.isDuesOpen} toggle={this.toggleDues} dues={this.props.dues} />}
            </div>
        );
    }
}

export default TeamPayments;