import React from 'react';
import _ from 'lodash';
import { formatPrice } from '../../helpers';

class SelectableTeam extends React.Component {

    state = {
        team: null,
        registered: false,
        inCart: false,
        registration: null
    }

    setup = (props) => {
        const { cart, season, team, teamRegistration } = props;
        var inCart = null,
            registered = null,
            registration = null;

        if (cart && season) {
            if (_.find(cart, (item) => {
                return item.selectedSeason.IdSeason === season.IdSeason && item.selectedTeam && item.selectedTeam.IdTeam === team.IdTeam;
            })) {
                inCart = true;
            }
            registration = _.find(teamRegistration, (item) => {
                return item.IdSeason === season.IdSeason && item.IdTeam === team.IdTeam;
            });
            //team.registration = registration;
            if (registration) {
                registered = true;
            }
        }

        this.setState({
            team,
            inCart,
            registered,
            registration
        });
    }

    componentWillMount() {
        this.setup(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.setup(nextProps);
    }

    noImage = () => {
        const { team } = this.state;
        team.TeamImageUrl = '/images/defaults/teamLogo.png';
        this.setState({ team })
    }

    thing = () => {
        const { team, inCart, registered } = this.state;
        return <div className="d-flex flex-row w-100">
            <div className="cover align-self-center ml-2" style={{ width: 40, height: 40, borderRadius: 20, background: `url(${(team.TeamImageUrl || team.Logo)}?full=50) no-repeat center center` }} />

            <section className="idented-half aligned-self-center justify-content-center d-flex flex-column pt-2 pb-2">
                <span className="line1 toUpper d-flex flex-row">{team.TeamName}{' '}{team.Status ? '(' + team.Status + ')' : ''} <span style={{ lineHeight: 1 }} className="font-8 ml-2 align-self-center">(ID: {team.IdTeam})</span></span>
                {team.DivisionName && <span className="font-8 line1 gray">{team.SeasonName}</span>}
                {team.DivisionName && <span className="font-8 line1 gray">{team.DivisionName}</span>}
            </section>

            {inCart && <span className="align-self-center margin-right ml-auto blue"><i className="fa fa-shopping-cart" />{' '}In Cart</span>}
            {registered && <span className="align-self-center margin-right ml-auto blue"><i className="fa fa-check" />{' '}Registered</span>}
            {team.Amount > 0 && <span className={`ml-auto align-self-center margin-right-half ${team.BLOCK}`}>{formatPrice(team.Amount * 100)}</span>}
        </div>
    }

    render() {
        const { inCart, registered } = this.state,
            { onClick, forceClick } = this.props;

        return onClick ? (
            <button onClick={(!(inCart || registered) || forceClick) ? onClick : null} style={{ height: '100%' }} className={`w-100 d-flex ${this.props.className || null} ${(inCart || registered) ? 'bg-gray' : ''}`}>
                {this.thing()}
            </button>) : (
            <span style={{ height: '100%' }} className={`w-100 d-flex ${this.props.className || null} ${(inCart || registered) ? 'bg-gray' : ''}`}>
                {this.thing()}
            </span>
        )

    }
}

export default SelectableTeam;