import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from './CustomDatePicker';
import { Popover, PopoverBody, PopoverHeader, FormGroup, Label } from 'reactstrap';
import TournamentPosition from '../common/TournamentPosition';
import _ from 'lodash';
import { generateRandomId } from '../../helpers';

class SeasonTournament extends React.Component {
  state = {
    entity: null,
    editMode: false,
    isOpenStartDatepicker: false,
    isOpenEndDatepicker: false,
    deleting: false
  }

  // Lifecycle
  componentWillMount() {
    const { entity, positions } = this.props;
    let editMode = false;
    if (!entity.IdTournamentSeason) {
      editMode = true;
    }
    this.setState({ entity, editMode, positions });

  }
  componentDidMount() {
    if (this.txt) {
      this.txt.focus();
      this.txt.select();
    }
  }
  //

  save = (e) => {
    e.preventDefault && e.preventDefault();
    const { entity } = this.state, { leagueActions } = this.props;
    entity.TournamentSeasonName = this.txt.value;
    // TODO: call api, generate an Id and assign it to this
    if (entity.IdTournamentSeason) {
      leagueActions && leagueActions.updateTournamentSeason(entity);
    } else
      leagueActions && leagueActions.createTournamentSeason(entity.IdLeague, entity);

    this.setState({ editMode: false });
  }
  edit = () => this.setState({ editMode: true });
  configure = () => { this.props.onConfigure && this.props.onConfigure(); }

  toggleCalendarStart = e => {
    e && e.preventDefault()
    this.setState({ isOpenStartDatepicker: !this.state.isOpenStartDatepicker })
  }
  toggleCalendarEnd = e => {
    e && e.preventDefault()
    this.setState({ isOpenEndDatepicker: !this.state.isOpenEndDatepicker })
  }

  handleChangeStart = date => {
    const { entity } = this.state;
    entity.DateStart = moment(date);
    entity.DateEnd = moment(date).isAfter(moment(entity.DateEnd)) ? moment(date).add(1, 'day') : moment(entity.DateEnd);
    this.setState({
      entity
    }, function () {
      this.toggleCalendarStart();
    });
  }
  handleChangeEnd = date => {
    const { entity } = this.state;
    entity.DateStart = moment(date).isBefore(moment(entity.DateStart)) ? moment(date).add(-1, 'day') : moment(entity.DateStart);
    entity.DateEnd = moment(date);
    this.setState({
      entity
    }, function () {
      this.toggleCalendarEnd();
    });
  }

  toggleDelete = () => this.setState({ deleting: !this.state.deleting });
  doDelete = () => {
    const { entity } = this.state, { leagueActions, fnRemove } = this.props;
    this.setState({ deleting: false }, () => {
      entity.IdTournamentSeason && leagueActions && leagueActions.deleteTournamentSeason(entity.IdTournamentSeason);
      fnRemove && fnRemove();
    });
  }

  configurePositions = selectedType => {
    // We override the non-related global positions with the values of the league-ones
    const { positions, entity } = this.state;

    //leaguePoints
    positions.forEach(position => {
      let found = _.find(entity.points, lp => (lp.IdTournamentDivisionResults === position.IdTournamentDivisionResults && lp.IdTournamentType === selectedType.IdTournamentType));
      position.randomId = generateRandomId(10);
      position.Points = (found || {}).Points;
      position.IdTournamentTypeDivisionPoints = (found || {}).IdTournamentTypeDivisionPoints;
    });

    this.setState({ selectedType, positions });
  }

  savePositions = () => {
    const { positions, selectedType, entity } = this.state, { leagueActions } = this.props;
    leagueActions && leagueActions.updateLeaguePoints(entity.IdLeague, [...positions], selectedType.IdTournamentType, entity.IdTournamentSeason);
    this.setState({ selectedType: null }, () => { });
  }

  render() {
    const { editMode, entity, configuring, selectedType } = this.state, { tournamentPointsTypes, positions } = this.props;
    
    return <form className={`${(editMode || configuring) ? 'card p-2' : ''}`}>

      

      {/* EDIT MODE */}
      {editMode && <div className="d-flex">

        <div className={`black-input ${false && !this.txt.value ? 'error-input' : ''} d-flex align-self-center w-50`}>
          <input className="w-100 p-3 " type="text" name="firstname" ref={(i) => this.txt = i} defaultValue={entity.TournamentSeasonName} placeholder="Name" />
        </div>

        <div className="align-self-center ml-auto">
          <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
            customInput={<CustomDatePicker />} selected={moment(entity.DateStart).utc()} selectsStart
            startDate={moment(entity.DateStart).utc()} endDate={moment(entity.DateEnd).utc()} onChange={this.handleChangeStart} />
        </div>
        <span className="align-self-center mx-2">to</span>
        <div className="align-self-center">
          <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={moment(entity.DateEnd).utc()} selectsStart
            startDate={moment(entity.DateStart).utc()} endDate={moment(entity.DateEnd).utc()} onChange={this.handleChangeEnd} />
        </div>

        <button type="submit" className="ml-auto btn btn-link green" onClick={this.save}><i className="fa fa-check font-30" /></button>
        <button id={`btn-delete-${entity.IdTournamentSeason || entity.randomId}`} type="button" className="btn btn-link red" onClick={this.toggleDelete}><i className="fa fa-times font-30" /></button>
        <Popover placement="bottom" isOpen={this.state.deleting} target={`btn-delete-${entity.IdTournamentSeason || entity.randomId}`} toggle={this.toggleDelete}>
          <PopoverHeader>Are you sure?</PopoverHeader>
          <PopoverBody className="d-flex flex-row justify-content-center">
            <button className="btn btn-success btn-sm" onClick={this.doDelete}>Yes, Delete it</button>
            <button className="btn btn-danger btn-sm" onClick={this.toggleDelete}>No, Cancel</button>
          </PopoverBody>
        </Popover>

      </div>}

      {/* SAVE MODE */}
      {!editMode && <div className={`d-flex flex-row p-2 ${this.props.selected ? 'bg-warning' : ''}`}>
        <span className="align-self-center font-16 toBold">{entity.TournamentSeasonName}</span>
        <span className="ml-auto align-self-center margin-right font-14">{moment(entity.DateStart).utc().format('MMM Do YYYY')}{'  -  '}{moment(entity.DateEnd).utc().format('MMM Do YYYY')}</span>
        <button onClick={() => this.setState({ configuring: !this.state.configuring })} type="button" className="btn btn-link align-self-center font-14">
          <i className={`icon-settings ${configuring ? 'green' : 'black'}`}></i></button>
        <button onClick={this.edit} type="button" className="btn btn-link align-self-center font-14"><span className="icon-edit-3 black"></span></button>
      </div>}

      {configuring && <div className="d-flex flex-row">
        <div className="w-100">
          {tournamentPointsTypes && tournamentPointsTypes.map((tp, i) =>
            <div key={i} className="d-flex flex-row w-100">

              <span className="align-self-center">{tp.TournamentType}</span>

              {/* Configure button */}
              <button type="button" onClick={() => this.configurePositions(tp)} className={`btn ${selectedType && selectedType.IdTournamentType === tp.IdTournamentType ? 'green' : 'blue'} btn-sm btn-default ml-auto mr-2 align-self-center font-8`}>{selectedType && selectedType.IdTournamentType === tp.IdTournamentType ? 'Configuring' : (_.find(entity.points, lp => lp.IdTournamentType === tp.IdTournamentType) ? 'Configure' : 'Add Points')} <i className={`idented-half fa fa-${selectedType && selectedType.IdTournamentType === tp.IdTournamentType ? 'caret-right' : 'cog'}`} /></button>
            </div>
          )}
        </div>

        {selectedType && <div className="w-100 divider-vertical">

          <FormGroup className="ml-2 pl-2">
            <Label className="font-16 blue">Configure {selectedType.TournamentType} {/*for <span className="red">{selectedTournamentSeason.TournamentSeasonName}</span>*/}</Label>
            {positions && positions.map((position, i) => <TournamentPosition key={position.IdTournamentDivisionResults} entity={position} />)}
            <button onClick={this.savePositions} type="button" className="btn btn-success btn-block">Save</button>
          </FormGroup>

        </div>}

      </div>
      }

      {!editMode && !configuring && <hr className="no-margin no-padding" />}
    </form>
  }
}

export default SeasonTournament;
