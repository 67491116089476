import React from 'react';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import fakeAuth from '../../fakeAuth';
import TeamItem from './partials/Brochure_TeamItem';
import BrochureInactive from './partials/Brochure_Inactive';
import Loader from '../common/Loader';
import './css.css';

class Index extends React.Component {

    state = {
        searchText: '',
        selectedTeam: null,
        teams: null,
        user: fakeAuth.getUser(),
        bootstrapped: false,
        overlay: false,
        not: null
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, familyActions } = this.props;
        familyActions && familyActions.fetchTeams();
        setTitle && setTitle({ main: 'SQWAD TEAM Brochures' });
    }

    componentWillReceiveProps = (nextProps) => {
        const { teams } = nextProps, { bootstrapped } = this.state;
        if (teams && !bootstrapped) {
            this.setState({
                teams: _.chain(teams || []).filter(t => t.subscription).groupBy('IdTeam').values().value().reverse(),
                not: _.chain(teams || []).filter(t => !t.subscription).groupBy('IdTeam').values().value().reverse(),
                bootstrapped: teams ? true : false
            }, () => {

            });
        }
    }
    //       


    onChange = () => this.setState({ searchText: this.txtSearch.value });

    render() {

        const { teamActions } = this.props,
            { bootstrapped, teams, selectedTeam, not } = this.state;

        let admin_teams = _.filter(teams || [], t => _.find(t, i => i.IsTeamAdmin || i.IsTeamOwner));

        const style = selectedTeam ? (!selectedTeam.TeamImageUrl ? {} : {
            background: `url(${selectedTeam.TeamImageUrl}) no-repeat center center`
        }) : {};

        return (
            <section>
                <Animated animateIn="fadeIn">
                    {bootstrapped && admin_teams && admin_teams.length === 0 && (
                        <div key="no-leagues" className="d-flex flex-col justify-content-center centered-message">
                            <h1 className="white align-self-center block text-center">There are no Teams registered for SQWAD Services</h1>
                        </div>
                    )}
                    {bootstrapped && admin_teams && (
                        <div style={{ maxWidth: 1065, margin: 'auto' }} className="">
                            <div key="teams" className="clearfix league-list margin-bottom mt-4">                                
                                {admin_teams.map((team, i) => <TeamItem key={i} index={i} team={team} teamActions={teamActions} />)}
                            </div>
                        </div>
                    )}
                    {!bootstrapped && <Loader key="loader" message={`Loading Leagues`} />}
                </Animated>

                {this.state.overlay && <div onClick={() => this.setState({ overlay: false })} className={`overlay ${this.state.overlay ? 'show' : ''}`} />}
                <Animated animationIn="slideInRight" animateOnMount={false} animationOut="slideOutRight" isVisible={this.state.overlay ? true : false} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: this.state.overlay ? 600 : 0, zIndex: 1041, overflowX: 'hidden' }} className="bg-white" >

                    <Animated animationInDuration={200} animationOutDuration={200} animationIn="slideInLeft" isVisible={this.state.selectedTeam ? false : true} animationOut="slideOutLeft" animateOnMount={false} className="w-100 h-100 p-4" style={{ overflowY: 'auto', position: 'absolute', right: 0, top: 0, bottom: 0, width: 600 }}>
                        <input placeholder="Search Team" className="w-100 form-control  align-self-center mb-4" ref={i => this.txtSearch = i} onChange={this.onChange} />
                        {this.state.not && _.chain(this.state.not).filter(([t]) => t.Name.toLowerCase().indexOf((this.txtSearch.value || '').toLowerCase()) !== -1).value().map((team, i) => <BrochureInactive key={i} index={i} team={team} teamActions={teamActions} onClick={() => this.setState({ selectedTeam: _.first(team) })} />)}
                    </Animated>

                    <Animated animationInDuration={200} animationOutDuration={200} animationIn="slideInRight" animateOnMount={false} animationOut="slideOutRight" isVisible={this.state.selectedTeam ? true : false} className="w-100 h-100 p-4" style={{ overflowY: 'auto', position: 'absolute', right: 0, top: 0, bottom: 0, width: 600 }}>

                        <div className="card shadow-box hoverable w-100 d-flex flex-row mb-4" style={{ height: 80 }} onClick={() => this.setState({ selectedTeam: null })}>
                            <div className="align-self-center ml-4 cover" style={{ ...style, width: 60, height: 60 }}>
                                {selectedTeam && !selectedTeam.TeamImageUrl && <i className="fa fa-shield-alt align-self-center" style={{ fontSize: '3em', color: '#e5e5e5' }} />}
                            </div>
                            {selectedTeam && <span className="align-self-center ml-4">{selectedTeam.Name}</span>}
                            <span className="align-self-center ml-auto mr-4 blue underlined">Change</span>
                        </div>

                        {selectedTeam && _.filter(not, ([t]) => t.IdTeam === selectedTeam.IdTeam).map((t, i) => <section>
                            {t.map((team, index) => <figure className="card shadow-box p-4 d-flex flex-row " key={i}>
                                <div style={{ width: 60, height: 60, background: `url(https://api.sportslogic.net/api/v4/seasons/image/decode/${team.IdSeason}.png) no-repeat center center` }} className="cover align-self-center" />
                                <span className="align-self-center ml-4">{team.SeasonName}</span>
                            </figure>)}
                        </section>)}

                    </Animated>

                </Animated>

                <div onClick={() => this.setState({ overlay: true })} style={{ position: 'fixed', right: 50, bottom: 50, width: 100, height: 100,  borderRadius: 50 }} className="bg-Recreational d-flex pointer flex-row justify-content-center">
                    <i className="font-40 white fas fa-plus align-self-center" />
                </div>

            </section>
        )
    }
}

export default Index;
