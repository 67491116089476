import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';

class TeamSkeak extends React.Component {

  state = {

  }

  changeSeason = () => {    
    this.props.router.push(`/registration/${this.props.league.IdLeague}`);
  }

  render() {
    const { isOpen, toggle, fnRegisterAnother, league, label="Player", hfo = false } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: 500 }}>
        <ModalBody className="container-fluid bg_jeff_blue">
          <div>
            <div style={{ height: 300 }} className="d-flex flex-row justify-content-center">
              <Animated animationIn="zoomIn" animationInDelay={500} className="h-auto">
                <i className="far fa-check-circle white align-self-center mt-4" style={{ fontSize: 150 }} />
              </Animated>
            </div>

            <div className="d-flex flex-row pl-4 pr-4 pb-4">
              {!hfo && <button className="btn bg-white w-100 d-flex flex-column justify-content-center" onClick={fnRegisterAnother} style={{ border: '1px solid white', height: 120, marginTop: 0 }}>
                <span className="icon-family_registration black font-20 align-self-center" />
                <span className="font-10 align-self-center line1">Add another</span>
                <span className="font-18 align-self-center line1 toBold">Family</span>
                <span className="font-14 toBold align-self-center line1">{label}</span>
              </button> }

              {!hfo && <div className="p-2" /> }

              <button className="btn bg-white w-100 btn-lg d-flex flex-column justify-content-center" onClick={this.changeSeason} style={{ border: '1px solid white', height: 120, marginTop: 0 }}>
                <span className="icon-CrestOnly_icon-2 black font-20 align-self-center" />
                <span className="font-10 align-self-center line1">Add another</span>
                <span className="font-18 align-self-center line1 toBold">Season</span>
                <span className="font-14 toBold align-self-center line1">registration</span>
              </button>
              
            </div>
            
            <div className="ml-4 mr-4 mb-4">
              <Link to={`/registration/checkout/${league.IdLeague}`} className="btn btn-success btn-block">Checkout</Link>
            </div>

          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default TeamSkeak;
