function reducer(state = [], action) {

    switch (action.type) {
        case 'TEAM/EVALUATIONS/SET':            
            return action.team_evaluations;
        default:
            return state;
    }
}

export default reducer;