import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

class PaymentsHybrid extends React.Component {

    state = {
        step: 1,
        mode: 1,
        bootstrapped: true,
        alreadyRegister: [],
        selectedUser: null,
        searchTerm: null,
        coachRoles: [
            {
                Id: 4,
                Name: 'Coach',
                selected: true
            },
            {
                Id: 4,
                Name: 'Assistant Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Team Manager',
                selected: false
            },
        ]
    }

    componentWillMount() {
        const { family } = this.props;
        // Clear
        family.forEach(u => u.selected = false);
        this.setState({ family: [...family] });
    }

    onClose = () => {
        this.setState({ bootstrapped: false }, () => {
            const { toggle } = this.props;
            setTimeout(() => { toggle && toggle() }, 500);
        });
    }

    selectMode = (mode) => {
        this.setState({
            mode,
            step: 2
        });
    }

    onOk = ({ selectedUser, selectedDivision }) => {
        const { season, league, } = this.props;

        this.props.fnOk && this.props.fnOk({
            selectedUser,
            selectedSeason: season,
            selectedLeague: league,
            selectedDivision: selectedDivision
        });
    }

    selectRole = (index) => {
        var { coachRoles } = this.state;
        coachRoles.forEach((r, i) => {
            r.selected = (i === index);
        });
        this.setState({
            coachRoles
        });
    }

    back = () => {
        const { step } = this.state.step;
        if (step === 1) {
            this.props.toggle && this.props.toggle();
        } else {
            this.setState({
                step: (this.state.step - 1)
            });
        }
    }

    selectTeam = (team) => {
        const { teamRegistration, season } = this.props;
        this.props.fnOk && this.props.fnOk({
            selectedTeam: team,
            selectedSeason: this.props.season,
            selectedLeague: this.props.league,
            selectedDivision: _.find(teamRegistration, c => c.IdTeam === team.IdTeam && c.IdSeason === season.IdSeason)
        });
    }

    selectUser = selectedUser => {
        const { familyRegistration, season } = this.props;        
        this.props.fnOk && this.props.fnOk({
            selectedUser,
            selectedSeason: this.props.season,
            selectedLeague: this.props.league,
            selectedDivision: _.find(familyRegistration, fr => fr.IdUser === selectedUser.IdUser && fr.IdSeason === season.IdSeason)
        });
    }



    ok = () => {
        // TODO: 'persist' selected options and go to the next step
        this.props.fnOk && this.props.fnOk({
            selectedUser: this.state.selectedUser,
            selectedRole: _.find(this.state.coachRoles, (r) => {
                return r.selected;
            }),
            selectedSeason: this.props.season,
            selectedLeague: this.props.league,
            mode: this.state.mode
        });
    }

    render() {

        const { bootstrapped, family } = this.state, { teams, season, teamRegistration, familyRegistration } = this.props;

        let registeredTeams = _.values(_.groupBy(
            _.chain(teams)
                .filter(team => {
                    return _.find(teamRegistration, c => c.IdTeam === team.IdTeam && c.IdSeason === season.IdSeason)
                })
                .sortBy('IdTeam').value().reverse(), t => t.IdTeam));

        let alreadyRegistered = [
            ..._.filter(family, user => (_.find(familyRegistration, fr => fr.IdUser === user.IdUser && fr.IdRole === 3 && fr.IdSeason === season.IdSeason)))
        ];

        return (
            <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
                <div className="dark-overlay" onClick={this.onClose} />
                <Animated style={{ width: 400, position: 'absolute', top: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray d-flex flex-column montserrat"
                    isVisible={bootstrapped} animationOut="slideOutRight" animationIn='slideInRight' animationInDelay={200} animationInDuration={500} animationOutDuration={500}>
                    <div style={{ height: 50 }} className="w-100 p-2 d-flex flex-row">
                        <i className="icon-arrow-left jeff_blue font-20 align-self-center" onClick={this.onClose} />
                        <i className="shopping_cart white-ish font-24 ml-auto align-self-center" />
                    </div>



                    {alreadyRegistered.length > 0 && <section className="d-flex flex-column mx-4 mb-4">

                        <div className="tile card shadow-box p-4 d-flex flex-column justify-content-center bg-blue-ish" style={{ height: 160 }}>
                            <i className="icon-family_registration white font-40 align-self-center" />
                            <span className="white font-20 toBold align-self-center">PLAYER</span>
                            <span className="font-10 white align-self-center">Payments</span>
                        </div>

                        {alreadyRegistered.map((user, i) => (
                            <div onClick={() => this.selectUser(user)} key={i} className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-2 ${user.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}>
                                <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                                <span className="align-self-center font-12">{user.NameFirst} {user.NameLast}</span>
                                <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                            </div>))}

                    </section>}


                    {registeredTeams.length > 0 && <section>
                        <div className=" mx-4 pointer tile card shadow-box p-4 d-flex flex-column justify-content-center bg-blue-ish" style={{ height: 160 }}>
                            <i className="icon-family_registration white font-40 align-self-center" />
                            <span className="white font-20 toBold align-self-center">TEAM</span>
                            <span className="font-10 white align-self-center">Payments</span>
                        </div>

                        <div className="d-flex flex-wrap justify-content-center mb-4 mx-4">
                            {registeredTeams && registeredTeams.reverse().map(([team], i) => (
                                <div onClick={() => this.selectTeam(team)} style={{ height: 150 }} className="mt-2 align-self-center w-50 card tile shadow-box d-flex flex-column justify-content-center" key={i} >
                                    <img alt="" src={team.TeamImageUrl || team.ImageUrl} className="align-self-center" style={{ width: 50 }} />
                                    <span className="align-self-center font-8 idented margin-right text-center mt-2 line1">{team.Name || team.TeamName}</span>
                                </div>))}
                        </div>
                    </section>}

                </Animated>

            </section>
        )

    }
}

export default PaymentsHybrid;