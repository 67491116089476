import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { generateRandomId } from '../../../../../helpers';
import Card from './card';

class Icon extends React.Component {

  state = {
    popupverOpen: false,
    removed: false,
    randomId: `ID-${generateRandomId(10)}`
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  toggleConfirming = () => {
    this.setState({
      confirming: !this.state.confirming
    });
  }

  remove = () => {
    const { program, division, location } = this.props;
    this.props.divisionsActions && this.props.divisionsActions.removeQuestionGroup(program, division, location);
  }

  render() {
    const { program, index, color } = this.props;

    // TODO: evaluate if the program still exists in the store. If not, removed = true , and gray it out

    const { popoverOpen, randomId, confirming } = this.state;

    return (
      <i onClick={this.toggle} className={`margin-right-half fas fa-clock ${color}`}
        data-tip={`Scheduled Lock`} data-for={`rule${index}`} id={`${randomId}`}>
        <ReactTooltip effect="solid" place="top" id={`rule${index}`} />
        <Popover placement="top" isOpen={popoverOpen} target={`${randomId}`} toggle={this.toggle}>
          <PopoverBody className="d-flex flex-column justify-content-end">
            <button className="btn btn-link btn-sm blue no-margin no-padding text-right" onClick={this.toggle}>close</button>
            {<Card group={program} unmovable={true} />}
            <button id={`confirm${index}`} className="btn btn-danger btn-sm btn-block font-8" onClick={this.remove}>Remove this group ?</button>

            <Popover placement="top" isOpen={confirming} target={`confirm${index}`} toggle={this.toggleConfirming}>
              <PopoverHeader>Are you sure?</PopoverHeader>
              <PopoverBody className="no-padding">
                <button className="btn btn-sm btn-block btn-success no-margin">Yes, remove it</button>
              </PopoverBody>
            </Popover>
          </PopoverBody>
        </Popover>
      </i>
    );
  }
}

export default Icon;
