import React from 'react';
import _ from 'lodash';

class PantoneClip extends React.Component {

    render() {
        const { clip, factor } = this.props;
        let frames = clip.seconds / 10 < 1 ? 1 : clip.seconds / 10;
        let thumbs = [];
        for (var i = 0; i < frames; i++) thumbs.push({ thumbnail: `${_.initial(clip.thumbnail.split('/')).join('/')}/${`thumb000${((i*(clip.seconds / 2 < 2 ? 2 : 1) +  1))}.jpg`}` })
        
        return (
        <div style={{ position: 'relative', width: clip.seconds * factor, borderRadius: 5, border: '2px solid black' }} 
        className="d-flex flex-row justify-content-center">
            { thumbs.map((t, i) => <div className="cover" key={i} style={{ width: 160, background: `url(${t.thumbnail}) no-repeat center center` }} />)}
            {/*<div className="pointer" style={{ position: 'absolute', top: 1, right: 5 }} onClick={this.props.onRemove}>
                <i className="fas fa-times white shadow" />
        </div>*/}
            <div className="pointer d-flex flex-row" style={{ position: 'absolute', bottom: 10, left: 5 }}>
                <i className="fas fa-arrow-left white shadow align-self-center" onClick={this.props.onMoveLeft} />
                <i className="fas fa-times white shadow ml-2 align-self-center" onClick={this.props.onRemove} />
                <i className="fas fa-arrow-right white shadow ml-2 align-self-center" onClick={this.props.onMoveRight} />
            </div>
            <span className="white shadow font-20 align-self-center" style={{ position: 'absolute', bottom: 1, right: 5 }}>{clip.seconds}s</span>
        </div>)
    }
}
export default PantoneClip;
