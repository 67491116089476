function reducer(state = [], action) {
    let index = -1;
    switch (action.type) {
        case 'COACHES/SET':
            return action.coaches;
        case 'COACHES/CLEAR':
            return null;
        case 'COACH/UPDATE':            
            return [...state.slice(0, action.index),
            action.coach,
            ...state.slice(action.index + 1)];
        case 'COACH/UPDATE/ROLE':
            index = state.findIndex(item => (item.IdUser === action.coach.IdUser));
            if (index >= 0) {
                return [...state.slice(0, index),
                action.coach,
                ...state.slice(index + 1)];
            } else return state;
        default:
            return state;
    }
}

export default reducer;
