import React from 'react';
import { Link } from 'react-router';

class Navigation extends React.Component {
    render() {
        const { season } = this.props;
        return <div className="d-flex flex-row w-100 mt-2" style={{ height: 50, backgroundColor: '#4469C7', borderRadius: 5, overflow: 'hidden' }}>

            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/flight_manager`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Flights</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/manage_fields`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Fields</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/points`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Standing Points</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/schedule_manager`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Scheduler</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/games_calendar`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Calendar</Link>
            <Link className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Brackets</Link>
            <Link activeStyle={{ backgroundColor: '#3756A6' }} to={`/season/${season.IdSeason}/schedule_master_view`} className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Master-View</Link>
            <Link className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Game Cards</Link>
            <Link className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Scores</Link>
            <Link className="w-100 h-100 white text-center d-flex flex-column justify-content-center">Layouts</Link>
        </div>
    }
}

export default Navigation;