import React from 'react';
import './css.css';
import moment from 'moment';

class Comp extends React.Component {

  state = {
    league: null,
    waiver: null
  }

  componentWillMount() {
    const { params, seasonActions } = this.props, { idOrder, idUser, idSeason } = params;
    if (idOrder)
      seasonActions && seasonActions.fetchDisclaimerNonSecure(idOrder, idUser, null);
    else
      seasonActions && seasonActions.fetchDisclaimer(idSeason);
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ disclaimer: nextProps.disclaimer });
  }

  renderUnsigned = () => {
    const { disclaimer } = this.state;
    return (<section id="disclaimer" style={{ backgroundColor: 'white' }}>
      {disclaimer &&
        (<div className="d-flex flex-column">

          {disclaimer.season && <div>
            <h4 className="blue">Season Disclaimer</h4>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: disclaimer.season }} />
            <hr />
          </div>}

          <h4 className="blue">Terms and Conditions</h4>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: disclaimer.sportslogic }} />

          {disclaimer.fee && <div>
            <hr />
            <h4 className="blue">Fees Disclaimer</h4>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: disclaimer.fee }} />
          </div>}
        </div>)}
    </section>)
  }

  renderSigned = () => {
    const { disclaimer } = this.state;
    return (<section id="disclaimer" style={{ backgroundColor: 'white' }}>
      {disclaimer &&
        (<div className="d-flex flex-column">
          <img alt="" src={disclaimer.SeasonImage || disclaimer.LeagueImage} className="m-auto" style={{ height: 80 }} />
          <h2 className="m-auto text-center">{disclaimer.SeasonName}</h2>

          {disclaimer.SeasonDisclaimer && <div>
            <h4 className="blue">Season Disclaimer</h4>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: disclaimer.SeasonDisclaimer }} />
            <hr />
          </div>}

          <h4 className="blue">Terms and Conditions</h4>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: disclaimer.DisclaimerText }} />

          {disclaimer.FeeDisclaimer && <div>
            <hr />
            <h4 className="blue">Fees Disclaimer</h4>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: disclaimer.FeeDisclaimer }} />
          </div>}

          {disclaimer && disclaimer.SignatureImage.indexOf('<svg') !== -1 && <div dangerouslySetInnerHTML={{ __html: disclaimer.SignatureImage }} />}
          {disclaimer && disclaimer.SignatureImage.indexOf('base64') !== -1 && <div className="m-auto">
            <img alt="" src={disclaimer.SignatureImage} className="m-auto" style={{ height: 80 }} />
          </div>}
          <div className="text-center">Signed by{' '}{disclaimer.UserNameSignature}<br />on{' '}{moment(disclaimer.DateCreated).format('MMM Do YYYY')}</div>
        </div>)}
    </section>)
  }

  render() {

    const { params } = this.props, { idSeason } = params;
    if (idSeason) return this.renderUnsigned();
    else return this.renderSigned();
  }
}

export default Comp;