import React from 'react';
import _ from 'lodash';
import config from '../../../../config';
import moment from 'moment';

class Sheet extends React.Component {

  state = { team: null, qualifies: true }
  componentWillMount() {
    const { team, season } = this.props;
    this.setState({ team, season }, () => {
      const { team } = this.state;
      if ((!team.players.length || team.players.length < 3 || _.find(team.players, p => { return !p.UserNameSignature || !p.IsValid })) && team.IdLeague === 11826) {
        this.setState({ qualifies: false });
      }
    });
  }
  componentWillReceiveProps = nextProps => {
    const { team, season } = nextProps;
    this.setState({ team, season }, () => {
      const { team } = this.state;
      if ((!team.players.length || team.players.length < 3 || _.find(team.players, p => { return !p.UserNameSignature || !p.IsValid })) && team.IdLeague === 11826) {
        this.setState({ qualifies: false });
      }
    });
  }
  errorImage = () => {
    const { team } = this.state;
    team.TeamImage = `/images/defaults/teamLogo.png`;
    this.setState({ team });
  }
  render() {
    const { team, qualifies, season } = this.state, { width = 920, fncp } = this.props;

    let actives = _.filter(team.players || [], p => !p.violations.length),
      suspended = _.filter(team.players || [], p => p.violations.length);

    return <section className="letter sheet margin-top ml-auto mr-auto container-fluid black font-10 montserrat" style={{ overflow: 'hidden', width }}>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row w-100">
          <img alt="" onError={this.errorImage} src={team.TeamImage} style={{ width: 50 }} className="align-self-center" />
          <span className="ml-2 align-self-center font-16 toBold line1">{team.TeamName}</span>
        </div>
        <div style={{ width: 200 }} />
        <div className="text-right w-100">
          <div className="font-8">{team.LeagueName}</div>
          <div className="font-8">{team.SeasonName}</div>
          <div className="font-8 toBold">{team.DivisionName}</div>
        </div>
      </div>


      {/* COACHES */}

      <div className="d-flex justify-content-center mt-2" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottom: '1px solid #013469' }}>
        <span className="align-self-center block text-center font-20" style={{ color: '#013469' }}>Coaches</span>
      </div>
      <section className="container">
        {team.coaches && team.coaches.map((coach, i) => <section className="d-flex flex-row" key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="align-self-center w-auto">
            <div className="bordered cover" style={{ position: 'relative', width: 60, height: 60, borderRadius: 30, background: `url(${coach.UserImage}) no-repeat center center` }}>

            </div>
          </div>
          <div className="d-flex flex-row w-100 ml-3">
            <div className="w-100 p-2">
              <div>Coach's Pass Number: {coach.IdUser}</div>
              <div>Name: {`${coach.NameFirst} ${coach.NameLast}`}</div>
              <div>Role: {`${coach.RoleOverrideName}`}</div>
            </div>
            <div className="w-100 p-2 font-8">
              <div>Phone: {coach.PhoneNumber_Format}</div>
              <div className="">Email: {coach.EmailAddress}</div>
            </div>
            <div className="w-100 py-2 pl-2 align-self-center">
              <div className="d-flex flex-row">
                <div className={`btn btn-sm ml-1 btn-link p-1 w-100 font-8 ${coach.SAFE === null ? 'opaque' : ''}`}><i className={`mr-1 fa-${coach.SAFE === null ? 'times fas black opaque' : (!coach.SAFE ? 'circle far' : 'circle-check fas green')}`} />SAFE</div>
                <div className={`btn btn-sm ml-1 btn-link p-1 w-100 font-8 ${coach.CONC === null ? 'opaque' : ''}`}><i className={`mr-1 fa-${coach.CONC === null ? 'times fas black opaque' : (!coach.CONC ? 'circle far' : 'circle-check fas green')}`} />CONC</div>
                <div className={`btn btn-sm ml-1 btn-link p-1 w-100 font-8 ${coach.BGC === null ? 'opaque' : ''}`}><i className={`mr-1 fa-${coach.BGC === null ? 'times fas black opaque' : (!coach.BGC ? 'circle far' : 'circle-check fas green')}`} />BGC</div>
              </div>
              <div className="d-flex flex-row mt-1">
                <div className={`btn btn-sm ml-1 btn-link p-1 w-100 font-8 ${coach.COACH === null ? 'opaque' : ''}`}><i className={`mr-1 fa-${coach.COACH === null ? 'times fas black opaque' : (!coach.COACH ? 'circle far' : 'circle-check fas green')}`} />C.CERT</div>
                <div className={`btn btn-sm ml-1 btn-link p-1 w-100 font-8 ${coach.FINGER === null ? 'opaque' : ''}`}><i className={`mr-1 fa-${coach.FINGER === null ? 'times fas black opaque' : (!coach.FINGER ? 'circle far' : 'circle-check fas green')}`} />FP</div>
                <div className={`p-1 ml-1 w-100 font-8`}></div>
              </div>
            </div>
          </div>
        </section>)}
      </section>

      {/* PLAYERS */}
      {actives.length > 0 && <section>
        <div className="d-flex justify-content-center mt-2" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottom: '1px solid #013469' }}>
          <span className="align-self-center block text-center font-20" style={{ color: '#013469' }}>Players ({actives.length})</span>
        </div>
        <table className="table-sm table w-100">
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>#</th>
              <th>Name</th>
              <th>Gender</th>
              <th>DOB</th>
              {parseInt(season.IdLeague, 10) === 11826 && <th className="text-center">Shirt</th>}
              <th className="text-center">Valid</th>
              {parseInt(season.IdLeague, 10) === 11826 && <th className="text-center">Waiver</th>}
              <th>Phone</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {team.players && _.chain(actives).value().map((player, i) => <tr key={i}>
              <td>
                <div onClick={() => fncp && fncp(player)} className="bordered cover pointer" style={{ position: 'relative', width: 20, height: 20, borderRadius: 10, background: `url(${player.UserImage}) no-repeat center center` }}>
                  {player.replacements && <div className="bordered bg-warning" style={{ position: 'absolute', borderRadius: 3, bottom: -3, right: -3, width: 6, height: 6 }} />}
                </div>
              </td>
              <td className="font-8">{player.IdUser}</td>
              <td>{player.JerseyNumber}</td>
              <td>{`${player.NameFirst} ${player.NameLast}`}</td>
              <td className="text-center">{player.Gender.substring(0, 1)}</td>
              <td className="font-8">{player.DateBirth}</td>
              {parseInt(season.IdLeague, 10) === 11826 && <td className="text-center">{(_.find(config.sizes, s => s.name === player.ShirtSize) || {}).abbr}</td>}
              <td className={`text-center ${player.IsValid ? 'green' : 'red'}`}>{player.IsValid ? 'Yes' : 'No'}</td>
              {parseInt(season.IdLeague, 10) === 11826 && <td className={`text-center ${player.IdUserSignature ? 'green' : 'red'}`}>{player.IdUserSignature ? 'Yes' : 'No'}</td>}
              <td className="font-8">{player.PhoneNumber_Format}</td>
              <td className="font-8">{player.EmailAddress}</td>
            </tr>)}
          </tbody>
        </table>
      </section>}

      {/* SUSPENDED */}
      {suspended.length > 0 && <section>
        <div className="d-flex justify-content-center mt-2" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottom: '1px solid #013469' }}>
          <span className="align-self-center block text-center font-20" style={{ color: '#013469' }}>Suspended ({suspended.length})</span>
        </div>
        <table className="table-sm table w-100">
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>#</th>
              <th>Name</th>
              {parseInt(season.IdLeague, 10) === 11826 && <th className="text-center">Shirt</th>}
              <th className="text-center">Valid</th>
              {parseInt(season.IdLeague, 10) === 11826 && <th className="text-center">Waiver</th>}
              <th>Reason</th>
              <th>Until</th>
            </tr>
          </thead>
          <tbody>
            {suspended && _.chain(suspended).value().map((player, i) => <tr key={i}>
              <td>
                <div onClick={() => fncp && fncp(player)} className="bordered cover pointer" style={{ width: 20, height: 20, borderRadius: 10, background: `url(${player.UserImage}) no-repeat center center` }} />
              </td>
              <td className="font-8">{player.IdUser}</td>
              <td>{player.JerseyNumber}</td>
              <td>{`${player.NameFirst} ${player.NameLast}`}</td>
              {parseInt(season.IdLeague, 10) === 11826 && <td className="text-center">{(_.find(config.sizes, s => s.name === player.ShirtSize) || {}).abbr}</td>}
              <td className={`text-center ${player.IsValid ? 'green' : 'red'}`}>{player.IsValid ? 'Yes' : 'No'}</td>
              {parseInt(season.IdLeague, 10) === 11826 && <td className={`text-center ${player.IdUserSignature ? 'green' : 'red'}`}>{player.IdUserSignature ? 'Yes' : 'No'}</td>}
              <td>{_.first(player.violations).Violation}</td>
              <td className="font-8">{_.first(player.violations).SuspendedUntilDate}</td>
            </tr>)}
          </tbody>
        </table>
      </section>}

      {/* EXPRESS CHECK-IN */}

      <div className="w-100 text-center gray font-16" style={{ marginTop: '5em' }}>{qualifies ? 'Qualifies for Express Check-in' : ''}</div>
      <div className="d-flex flex-row" style={{ marginTop: '5em' }}>
        <span className="align-self-center black font-8">{moment().format('MMM Do YYYY @ HH:mmA')}</span>
        <i className="ml-auto icon-logo-header font-20 jeff_blue" />
      </div>
    </section>
  }
}

export default Sheet;
