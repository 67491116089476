

import _ from 'lodash';

function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'SELECTED_ANSWERS/ADD':
            return [...state, action.answer];
        case 'SELECTED_ANSWERS/REMOVE':
            return [..._.reject(state, a => a.id === action.answer.id)];
        case 'SELECTED_ANSWERS/CLEAR':
            return [];
        default:
            return state;
    }
}

export default reducer;