import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SelectableTeam from '../../common/SelectableTeam';
import _ from 'lodash';

class RegistrationTeamSelection extends React.Component {

  state = {
    step: 1,
    bootstrapped: true,
    alreadyRegister: [],
    selectedTeam: null,
    selectedUser: null,
    searchTerm: null
  }

  selectTeam = (team) => {
    this.props.rosterActions && this.props.rosterActions.clearRoster();
    this.props.teamActions && this.props.teamActions.selectTeam(team);
    this.props.teamActions && this.props.teamActions.toggleTeamSelection(false);
  }

  filter = (e) => {
    this.setState({
      searchTerm: e.target.value
    });
  }


  render() {
    const { isOpen, toggle, rosterTeams, selectedTeam } = this.props,
      { bootstrapped, step, searchTerm } = this.state;

    // Only show teams that belongs to the league community
    var filteredTeams = _.chain(rosterTeams).filter((t) => {
      return searchTerm ? t.TeamName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 : true; // league.IdCommunity === t.IdCommunity;
    }).sortBy('IdTeamDivision').value().reverse();

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          SELECT YOUR TEAM
              </ModalHeader>
        {step === 1 && <ModalBody className={`${(filteredTeams && rosterTeams && rosterTeams.length > 10) ? null : 'hide'}`}>
          <div className="bg-info d-flex form-group no-margin">
            <input defaultValue={searchTerm} onChange={this.filter} type="text" className="form-control bg-gray" style={{ borderRadius: 0 }} placeholder="Search team..."
              ref={(i) => this.txtSearch = i} />
          </div>
        </ModalBody>}
        <ModalBody id="registration-team-selection" style={{ minHeight: (bootstrapped ? '0px' : '300px') }} className="container-fluid">
          { /* Loader */}
          {!bootstrapped && (
            <div className="d-flex justify-content-center centered-message" style={{ height: '300px' }}>
              <h1 className=" align-self-center block text-center"><i className="fa fa-spin fa-cog" /></h1>
            </div>)}
          { /* Teams */}
          {bootstrapped && step === 1 && filteredTeams.length > 0 && (
            <ul className="w-100">
              {filteredTeams.map((team, i) => <li key={i} className={`selectable-team pointer ${(selectedTeam && selectedTeam.IdTeam === team.IdTeam && selectedTeam.IdTeamDivision === team.IdTeamDivision) ? 'bg-warning' : team.IdTeam}`}>
                <SelectableTeam forceClick={true} onClick={() => this.selectTeam(team)} team={team} key={i} />
              </li>)}
            </ul>
          )}
        </ModalBody>
      </Modal>)
  }
}

export default RegistrationTeamSelection;