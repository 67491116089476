import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as teamActions from '../redux-sagas/actions/teamActions';
import * as videoActions from '../redux-sagas/actions/videoActions';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import * as rosterActions from '../redux-sagas/actions/rosterActions';
import Main from '../components/Main.SQWAD';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn,
        auth: state.auth,
        afp: state.afp,
        atp: state.atp,
        clips: state.clips,
        colors: state.colors,
        college_profile: state.college_profile,
        custom_attributes_templates: state.custom_attributes_templates,
        disclaimer: state.disclaimer,
        division: state.division,     
        countries: state.countries,
        communities: state.communities,
        family: state.family,   
        family_lastname: state.family_lastname,        
        family_subscriptions: state.family_subscriptions,
        family_videos: state.family_videos,
        games: state.games,
        files: state.files,
        highlights: state.highlights,        
        limits: state.limits,        
        permissions: state.permissions,
        registrations: state.registrations,
        roster: state.roster,        
        seasons: state.seasons,
        selectedTeam: state.selectedTeam || JSON.parse(localStorage.getItem('sportslogic.team') || '{}'),
        team_subscriptions: state.team_subscriptions,
        team_evaluations: state.team_evaluations,
        title: state.title,
        teams: state.teams,
        rights: state.rights,
        archived_teams: state.archived_teams,
        team: state.team,
        videos: state.videos,                
        video: state.video,  
        waivers: state.waivers,    
        lineups: state.lineups,
        depth_charts: state.depth_charts  
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        familyActions: bindActionCreators(familyActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        videoActions: bindActionCreators(videoActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch),
        roster_actions: bindActionCreators(rosterActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
