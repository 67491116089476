import _ from 'lodash';

export default function reducer(state = [], action) {
  switch (action.type) {
    case 'FILES/SET':
      return [...state, action.file];
    case 'FILES/UPDATE':
      let index = _.findIndex(state, i => i.id === action.file.id);
      if (index !== -1) {        
        return [...state.slice(0,index), action.file, ...state.slice(index + 1)]
      }
      return state;
    case 'FILES/UPLOAD/CANCEL':
      let b = state[action.index];
      b.canceled = true;
      b.cancelTokenSource && b.cancelTokenSource.cancel();      
      return [...state.slice(0, action.index), b, ...state.slice(action.index+1)];
    case 'FILES/CLEAR':
      return _.reject(state, file => file.canceled || file.progress === 100);
    default:
      return state;
  }
}