function reducer(state = [], action) {

    switch (action.type) {
        case 'TEAM/SUBSCRIPTIONS/SET':            
            return action.team_subscriptions;
        default:
            return state;
    }
}

export default reducer;