import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as spotlightActions from '../redux-sagas/actions/sportlightActions';
import * as usersActions from '../redux-sagas/actions/usersActions';
import * as geoActions from '../redux-sagas/actions/geoActions';
import * as rosterActions from '../redux-sagas/actions/rosterActions';
import Main from '../components/waivers/index';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        ...state
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        familyActions: bindActionCreators(familyActions, dispatch),
        leagueActions: bindActionCreators(leagueActions, dispatch),
        spotlightActions: bindActionCreators(spotlightActions, dispatch),
        usersActions: bindActionCreators(usersActions, dispatch),
        geoActions: bindActionCreators(geoActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
