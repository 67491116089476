module.exports = {
  members: {
    name: "Members",
    icon: "users",
    on: false,
    to: '/profile/members'
  },
  contact: {
    name: "Contact",
    icon: "phone fas",
    on: false,
    to: '/profile/contact'
  },
  credentials: {
    name: "Account",
    icon: "user fas",
    on: false,
    to: '/profile/account'
  },
  /*add_roster: {
    name: "Add",
    icon: "plus",
    on: false,
    sub_nav: {
      new: { name: "New", color: "blue", icon: "user-circle", path: 'new' },
      existing: { name: "Existing", color: "purple", icon: "undo", path: 'existing' },
    }
  }*/
};