import _ from 'lodash';

function reducer(state = [], action) {
  let index = 0;
  switch (action.type) {
    case 'LOCKS/ADD': return [...state, action.lock];
    case 'LOCKS/SET': return action.locks;
    case 'LOCKS/DELETE':
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1)
      ];
    case 'LOCKS/SAVE':
      index = _.findIndex(state, lock => {
        return lock.temporalId === action.lock.temporalId;
      });
      if (index !== -1) {
        return [
          ...state.slice(0, index),
          action.lock,
          ...state.slice(index + 1)
        ];
      } else
        return state;
    case 'LOCKS/COMPLETE':
      index = _.findIndex(state, lock => {
        return lock.temporalId === action.lock.temporalId;
      });
      if (index !== -1) {
        return [
          ...state.slice(0, index),
          action.lock,
          ...state.slice(index + 1)
        ];
      } else
        return state;
    default:
      return state;
  }
}

export default reducer;