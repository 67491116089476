import React from 'react';
import config from '../../../../config';
import { Animated } from 'react-animated-css';
import request from 'superagent';
import { calculateBWContrast } from '../../../../helpers';
import Coach from './coach';
import Player from './player';
import VideoPlayer from './video';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon,
    FacebookIcon,

} from "react-share";
import QRCODE from './qrcode';

class Season extends React.Component {

    state = { bootstrapped: false, toggled: false, overlay: false, highlights: false }

    componentWillMount() {
        const { season } = this.props;        
        // TODO: get the roster of this season (not in a react way)        
        this.setState({ season }, () => {
            request.get(`${config.apiEndpoint}/api/external/teams/roster/${season.IdTeamDivision}`)
                .set('Authorization', `Bearer ${config.apiKey}`)
                .then(data => {
                    let payload = JSON.parse(data.text);
                    this.setState({ roster: payload.roster, bootstrapped: true });
                });
        });
    }

    selectUser = () => { }

    renderCoach = (coach, index) => {
        return <Coach coach={coach} key={index} />
    }
    renderPlayer = (player, index) => {        
        return <Player team={this.props.selectedTeam} onClick={() => this.setState({ selectedUser: player, overlay: true })} {...this.props} editing={this.props.editing} color={this.props.bg2} player={player} key={index} select={this.selectUser} />
    }

    toggle = () => this.setState({ toggled: !this.state.toggled });
    handleAvatar = () => { }
    copyToClipboard = (e) => {
        this.txtClipboard.select();
        document.execCommand('copy');
        this.setState({ copySuccess: 'Copied!' });
    }

    render() {
        const { roster = [], season, toggled, copySuccess, selectedUser = {}, highlights } = this.state,
            { selectedTeam = {}, bg1, bg2, index, editing } = this.props,
            coaches_chunks = _.chain(roster).filter(r => r.IdRole === 4).chunk(4).value(),
            players_chunks = _.chain([...roster, { qr: true, IdRole: 3 }])
                .filter(r => r.IdRole === 3)
                .chunk(3).value();

        let shareUrl = `https://matchreports.thesqwad.com/brochure/${season.IdTeamDivision}/${selectedTeam.Name || selectedTeam.TeamName}`;

        return <section key={index} className={`noto w3-card card sheet no-padding ${editing ? 'ml-4' : 'm-auto'}`} style={{ width: 1020, position: 'relative', transition: 'ease' }}>

            {/* HEADER */}
            <header onClick={this.toggle} className="d-flex flex-column justify-content-center pointer" style={{ backgroundColor: `${bg1}`, borderBottom: `8px solid ${bg2}`, position: 'relative', height: 150 }}>
                <span className="align-self-center noto font-20" style={{ color: calculateBWContrast(bg1) }}>{selectedTeam.Name || selectedTeam.TeamName}</span>
                <span className="align-self-center noto font-12" style={{ color: calculateBWContrast(bg1) }}>{season.SeasonName || season.Name}</span>
                <div style={{ position: 'absolute', width: 100, height: 100, borderRadius: 50, backgroundColor: 'white', left: 80, top: 20, background: `url(https://api.sportslogic.net/api/v4/teams/image/decode/${selectedTeam.IdTeam}.png) no-repeat center center`, backgroundSize: 'contain' }} />

                {selectedTeam.subscription && <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ qr: true }) }} style={{ position: 'absolute', width: 100, height: 100, left: 80, top: 20 }} className="d-flex flex-column justify-content-end">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid black` }} className="pointer align-self-end bg-black d-flex flex-row justify-content-center">
                        <i className="white icon-new-qr-code font-18 align-self-center" />
                    </div>
                </button>}
            </header>


            {toggled && <div className="w3-white">
                <div className="w3-content p-4">
                    {!editing && selectedTeam.subscription && <div className="d-flex flex-row justify-content-end">

                        <TwitterShareButton className="mr-2 align-self-start" title="Team Brochure" url={shareUrl}>
                            <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton className="mr-2 align-self-start" title={`Check out our ${selectedTeam.Name || selectedTeam.TeamName}'s Brochure`} url={shareUrl}>
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton className="mr-2 align-self-start" quote={`Check out our ${selectedTeam.Name || selectedTeam.TeamName}'s Brochure`} url={shareUrl}>
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <div className="w-auto d-flex flex-column">
                            <input className={`${copySuccess ? 'bg-success' : ''} form-control text-input w-auto`} disabled="disabled" ref={(i) => this.txtClipboard = i} value={shareUrl} />
                            {this.state.copySuccess && <span className="black font-10">Link copied in your clipboard!</span>}
                        </div>

                        <CopyToClipboard text={shareUrl}
                            onCopy={() => this.setState({ copySuccess: true })}>
                            <button className="btn btn-link align-self-start"><i className="fas fa-clipboard blue" /></button>
                        </CopyToClipboard>

                    </div>}
                    {!editing && <h2 className="black text-center block mt-2 mb-4">Coaching Staff</h2>}
                    {!editing && coaches_chunks.map((chunk, index) => <div key={index} className="w3-container w3-content d-flex row-into-column justify-content-center">
                        {chunk.map((coach, j) => this.renderCoach(coach, j))}
                    </div>)}
                    
                    <h2 className="black text-center block m-4">Roster</h2>
                    {/* Chunks of 3 */}
                    {players_chunks.map((chunk, k) => {
                        if (chunk.length < 3) {
                            for (var i = 0; i < 3 - chunk.length + 1; i++) chunk.push({});
                        }
                        return <div key={k} className="w3-container w3-content d-flex row-into-column justify-content-start">
                            {chunk.map((player, j) => this.renderPlayer(player, j))}
                        </div>
                    })}
                </div>
            </div>}

            {this.state.overlay && <div onClick={() => this.setState({ overlay: false })} className={`overlay ${this.state.overlay ? 'show' : ''}`} style={{ position: 'fixed' }} />}


            <Animated animationIn="slideInRight" animateOnMount={false} animationOut="slideOutRight" isVisible={this.state.overlay ? true : false} style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: 500, zIndex: 1041, overflowX: 'hidden', overflowY: 'auto' }} className="bg-white">

                <div className="d-flex flex-row p-3 bg-dark-blue">
                    <i className="fas fa-arrow-left font-12 pointer white" onClick={() => { this.setState({ overlay: false }) }} />
                </div>

                <div className="d-flex flex-column justify-content-center bg-dark-blue">
                    <div style={{ width: 120, height: 120, position: 'relative' }} className="pointer hoverable align-self-center mt-4 d-flex flex-column">


                        <div className="cover align-self-center" style={{ width: 120, height: 120, borderRadius: 60, overflow: 'hidden', border: '1px solid white', background: `url(${selectedUser.Image || `/images/defaults/${selectedUser.IdGender === 2 ? 'female' : 'male'}.png`}) no-repeat center center black` }} />


                        <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ qr: selectedUser }) }} style={{ position: 'absolute', width: 120, height: 120 }} className="d-flex flex-column justify-content-end">
                            <div style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid black` }} className="pointer align-self-end bg-black d-flex flex-row justify-content-center">
                                <i className="white icon-new-qr-code font-18 align-self-center" />
                            </div>
                        </button>


                    </div>
                    <span className="font-16 montserrat white align-self-center m-3">{selectedUser.NameFirst} {selectedUser.NameLast}</span>
                </div>

                <div style={{ position: 'relative' }}>

                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 80, zIndex: -1 }} className="bg-dark-blue" />

                    <section className="">
                        <div style={{ borderRadius: 5 }} className=" card tile shadow-box bg-white p-4 ml-4 mr-4 d-flex flex-column">
                            <div className="d-flex flex-row">
                                <div className="w-100">
                                    <span className="block font-16line1">{selectedUser.JerseyNumber || selectedUser.PreferredJerseyNumber || 'N/A'}</span>
                                    <span className="font-10 line1">Jersey Number</span>
                                </div>
                                <div className="w-100 text-right">
                                    <span className="block font-16line1">{selectedUser.GraduationYear || 'N/A'}</span>
                                    <span className="font-10 line1">Graduation Year</span>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3">
                                <div className="w-100">
                                    <span className="block font-16line1">{selectedUser.Position || 'N/A'}</span>
                                    <span className="font-10 line1">Position</span>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3">
                                <div className="w-100">
                                    <span className="block font-16line1">{selectedUser.GPA || 'N/A'}</span>
                                    <span className="font-10 line1">GPA</span>
                                </div>
                                <div className="w-100 text-center">
                                    <span className="block font-16line1">{selectedUser.SAT || 'N/A'}</span>
                                    <span className="font-10 line1">SAT</span>
                                </div>
                                <div className="w-100 text-right">
                                    <span className="block font-16line1">{selectedUser.ACT || 'N/A'}</span>
                                    <span className="font-10 line1">ACT</span>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3">
                                <div className="w-100">
                                    <span className="block font-16line1">{selectedUser.SchoolName || 'N/A'}</span>
                                    <span className="font-10 line1">H.S. | Prepe</span>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3">
                                <div className="w-100">
                                    <span className="block blue font-16line1">{selectedUser.EmailAddress || 'N/A'}</span>
                                    <span className="font-10 line1">Email</span>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3">
                                <div className="w-100">
                                    <span className="block blue font-16line1">{selectedUser.PhoneNumber_Format || 'N/A'}</span>
                                    <span className="font-10 line1">Phone</span>
                                </div>
                            </div>
                            {/*JSON.stringify(selectedUser)*/}
                        </div>
                        <div style={{ height: 200 }} />
                    </section>
                </div>

                <div onClick={() => this.setState({ highlights: (selectedUser.Subscriptions && selectedUser.Subscriptions.length) ? true : false })} className="d-flex flex-row justify-content-center" style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: 50, backgroundColor: (selectedUser.Subscriptions && selectedUser.Subscriptions.length) ? '#00871B' : 'gray' }}>
                    <span className="white font-14 align-self-center">Highlights</span>
                </div>

                {highlights && <VideoPlayer user={selectedUser} player={selectedUser} {...this.props} toggle={() => this.setState({ highlights: false })} isOpen={highlights ? true : false} />}

            </Animated>

            {this.state.qr && <QRCODE team={selectedTeam} user={selectedUser} toggle={() => this.setState({ qr: null })} isOpen={this.state.qr ? true : false} />}
        </section>
    }
}

export default Season;
