import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as teamActions from '../redux-sagas/actions/teamActions';
import * as rosterActions from '../redux-sagas/actions/rosterActions';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import * as geoActions from '../redux-sagas/actions/geoActions';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import Main from '../components/Main.Family';
import _ from 'lodash';

function mapStateToProps(state) {
    return { ...state }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        familyActions: bindActionCreators(familyActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch),
        geoActions: bindActionCreators(geoActions, dispatch),
        leagueActions: bindActionCreators(leagueActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
