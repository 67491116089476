function reducer(state = [], action) {

  switch (action.type) {
    case 'CARRIERS/SET':     
      return action.carriers;
    case 'CARRIERS/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;
