import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../config';

class TeamItem extends React.Component {

    state = {
        menu: false
    }

    componentWillMount() {
        this.setState({ menu: false, error: false, archiving: false });
    }
    componentWillReceiveProps = () => {
        this.setState({ menu: false, error: false, archiving: false });
    }

    onRestore = () => {
        const { team } = this.props;
        const the_team = _.first(team);
        this.setState({ restoring: true }, () => {
            // CALL APIS
            request.post(`${config.apiEndpoint}/api/v5/teams/${the_team.BlOCK === 'Teams' ? 'archive' : 'archive_role'}/restore`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({ IdTeam: the_team.IdTeam })
                .then(({ body }) => {
                    this.props.familyActions &&
                        this.props.familyActions.fetchTeams();
                    this.props.familyActions &&
                        this.props.familyActions.fetchArchivedTeams();
                    this.setState({ menu: false, error: null });
                }, ({ status, response: { body } }) => {
                    // TODO: if status === 501
                    this.setState({ restoring: false });
                });
        });
    }

    renderNormal = () => {
        const { team, index } = this.props;
        const the_team = _.first(team);

        return (
            <Animated animationIn="fadeInRight" animationInDelay={20 * index} className="league-square float-left card tile shadow-box">
                <div onClick={this.onRestore}>
                    { /* Logo */}
                    <div className="league-logo text-center mt-4">
                        <i className="fa fa-shield-alt align-self-center" style={{ fontSize: '8em', color: '#e5e5e5' }} />
                    </div>
                    { /* Name */}
                    <span className="gray league-name d-flex flex-row align-items-center text-center justify-content-center toUpper"
                        style={{ lineHeight: 1 }}>{this.state.restoring ? 'RESTORING....' : (the_team.Name || the_team.TeamName)} ({the_team.IdTeam})</span>
                </div>
            </Animated>
        )
    }

    render() {
        return this.renderNormal();
    }
}

export default TeamItem;
