export function fetchGameDayRules(idSeason){
    return {
        type: 'SCHEDULE_MANAGER/GAMEDAY_RULES/FETCH',
        idSeason
    }
}

export function addGameDayRule(rule){
    return {
        type: 'SCHEDULE_MANAGER/GAMEDAY_RULES/ADD',
        rule
    }
}

export function updateGameDayRule(index, rule){
    return {
        type: 'SCHEDULE_MANAGER/GAMEDAY_RULES/UPDATE',
        index, rule
    }
}

export function deleteGameDayRule(index){
    return {
        type: 'SCHEDULE_MANAGER/GAMEDAY_RULES/DELETE',
        index
    }
}

export function associateWithFlight(idRule, idFlight){
    return {
        type: 'SCHEDULE_MANAGER/GAMEDAY_RULES/ASSOCIATE_WITH_FLIGHT',
        idRule, idFlight
    }
}

export function removeAssociation(idRuleAssociation){
    return {
        type: 'SCHEDULE_MANAGER/GAMEDAY_RULES/REMOVE_ASSOCIATION_FROM_FLIGHT', 
        idRuleAssociation
    }
}

