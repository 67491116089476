function reducer(state = [], action) {
    switch (action.type) {
        case 'FAMILY/INFORMATION/SET':
            return action.information;
        default:
            return state;
    }
}

export default reducer;
