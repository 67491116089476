import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { generateRandomId } from '../../helpers';
import _ from 'lodash';

class Comp extends React.Component {
  state = { team: null, isSelectingAgeGroup: false, randomId: generateRandomId(10) }
  componentWillMount() {
    this.setState({ team: this.props.team });
  }
  handleImageError = () => {
    const { team } = this.state;
    team.TeamImageUrl = '/images/defaults/teamLogo.png';
    this.setState({ team });
  }

  toggleSelectingAgeGroup = () => this.setState({ isSelectingAgeGroup: !this.state.isSelectingAgeGroup });
  selectAG = (ag) => {

    const { team } = this.state, { teamActions, idTournamentSeason } = this.props;
    team.IdTournamentRankAgeGroup = ag.IdTournamentRankAgeGroup;
    team.TournamentRankAgeGroup = ag.TournamentRankAgeGroup;
    team.randomId = this.state.randomId;
    this.setState({ team, isSelectingAgeGroup: false });

    // Call API
    teamActions && teamActions.setRankingAgeGroup(team.IdTeam,
      idTournamentSeason,
      team.IdTournamentRankAgeGroup,
      team.IdTournamentRank,
      this.state.randomId);
  }

  render() {
    const { team, randomId } = this.state, { ageGroups } = this.props;

    const leafAgeGroups = _.filter(ageGroups || [], ag => ag.IsLeaf);
    return <tr>
      <td>
        <div className="d-flex flex-row">
          <img alt="" className="smallPic align-self-center margin-right-half" src={team.TeamImageUrl} onError={this.handleImageError} />
          <span className="align-self-center">{team.TeamName}</span>
        </div>
      </td>
      <td>{team.Gender}</td>
      <td>
        <button id={`btn-${randomId}`} onClick={this.toggleSelectingAgeGroup} className="btn btn-link d-flex flex-row">
          {team.IdTournamentRankAgeGroup && <i className="fa fa-check green align-self-center margin-right-half" />}
          <span className=" align-self-center margin-right-half">{team.TournamentRankAgeGroup || 'Select'}</span>
          <i className="fa fa-caret-down align-self-center" />
        </button>
        <Popover placement="bottom" isOpen={this.state.isSelectingAgeGroup} target={`btn-${randomId}`} toggle={this.toggleSelectingAgeGroup}>
          <PopoverBody className="no-margin p-1 d-flex flex-column" style={{ maxHeight: 200, overflowY: 'auto' }}>
            <div>
              {leafAgeGroups && leafAgeGroups.map((ag, i) => <button key={i} onClick={() => this.selectAG(ag)} className={`btn-block btn`}>{ag.TournamentRankAgeGroup}</button>)}
            </div>
          </PopoverBody>
        </Popover>
      </td>
      <td>{_.map(team.divisions, d => d.DivisionName).join(', ')}</td>
    </tr>
  }
}

export default Comp;
