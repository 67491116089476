function reducer(state = [], action) {

    switch (action.type) {
      case 'TEMPLATES/SET':        
        return action.templates;
      default:
        return state;
    }
  }
  
  export default reducer;