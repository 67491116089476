import _ from 'lodash';
import moment from 'moment';

function games(state = [], action) {
    var index;
    switch (action.type) {
        case 'GAMES/SET':
            action.games && action.games.forEach((g) => {
                g.GameDate = moment((g.GameDate || g.DateTimeStart).split('T')[0] + ' ' + (g.GameStartHour || g.DateTimeStart_Time_Format), 'YYYY-MM-DD hmm');
                g.GameStartHour = moment(g.GameStartHour || g.DateTimeStart_Time_Format, 'hmm');
            });
            return [...action.games];
        case 'GAMES/ADD':
            action.game.GameDate = moment(action.game.GameDate + ' ' + action.game.GameStartHour, 'YYYY-MM-DD hmm');
            action.game.GameStartHour = moment(action.game.GameStartHour, 'hmm');
            return [...state, action.game];

        case 'GAMES/UPDATE':
            if (!moment.isMoment(action.game.GameDate)) {
                action.game.GameDate = moment(action.game.GameDate + ' ' + action.game.GameStartHour, 'YYYY-MM-DD Hmm');
                action.game.GameStartHour = moment(action.game.GameStartHour, 'Hmm');
            }
            index = _.findIndex(state, g => {
                return ((g.Id && g.Id === action.game.Id) || (g.IdGame === action.game.IdGame && g.IdGame))
            });
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    _.extend({}, state[index], action.game),
                    ...state.slice(index + 1)
                ];
            } else {
                return state;
            }
        case 'GAMES/DELETE':
            index = _.findIndex(state, (g) => {
                return (g.Id === action.Id || g.IdGame === action.Id)
            });
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    ...state.slice(index + 1)
                ];
            } else {
                return [...state];
            }
        case 'GAMES/MARK_AS_DELETED':
            index = _.findIndex(state, g => (g.Id === action.Id || g.IdGame === action.Id));
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    { ...state[index], markedAsDeleted: true },
                    ...state.slice(index + 1)
                ];
            }
            return state;
        case 'GAMES/CLEAR':            
            return null;
        default:
            return state;
    }
}

export default games;
