
function gameDayRules(state = [], action) {
    switch (action.type) {
        case 'SCHEDULE_MANAGER/GAMEDAY_RULES/SET':
            return action.rules;
        case 'SCHEDULE_MANAGER/GAMEDAY_RULES/ADD':
            return [...state, action.rule];
        case 'SCHEDULE_MANAGER/GAMEDAY_RULES/UPDATE':
            return [
                ...state.slice(0, action.index),
                { ...action.rule },
                ...state.slice(action.index + 1)
            ];
        case 'SCHEDULE_MANAGER/GAMEDAY_RULES/DELETE':
            return [
                ...state.slice(0, action.index),
                ...state.slice(action.index + 1)
            ];
        default:
            return state;
    }
}

export default gameDayRules;