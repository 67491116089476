import React from 'react';
import COLORS from '../../../../colors';

class LocationListItem extends React.Component {

  state = {
    expanded: false
  }

  expand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { location, fnSelect } = this.props,
      { expanded } = this.state;
    let maps_link = `${`https://maps.google.com/maps?daddr=${location.Latitude},${location.Longitude}`}`;
    return (
      <figure style={{ width: 202, height: 200, overflow: 'hidden' }} className={`shadow d-flex flex-column float-left ml-2 mb-2 card ${location.CommunityName.toLowerCase()} ${expanded ? 'active-field' : 'hoverable'}`}>

        <div className="h-100">
          <span style={{ backgroundColor: COLORS.jeff_blue, position: 'relative' }} className={`white block location-name d-flex flex-row align-items-center text-left justify-content-left ${expanded ? 'big-font' : ''}`}>{location.Name}
            { location.Latitude && <a href={maps_link} target="_blank" rel="noopener noreferrer" style={{ position: 'absolute', bottom: 5, right: 5 }} ><i className="fas fa-map-marked-alt white" />
            </a> }
          </span>
          <div className="p-3">
            <span className="black block font-10 text-left line1">{`${location.Line1}`}</span>
            <span className="black block font-10 text-left line1">{`${location.City} - ${location.StateCode} ${location.ZipCode}`}</span>
          </div>
        </div>

        <div className="option-buttons">
          <div className="buttons-wrapper d-flex flex-row">
            { /*<button type="button" className="btn col-2 font-12 btn-link season-comunity-edit-button text-center align-items-center"><i className="fa fa-times"></i></button>*/}
            <button type="button" className="btn w-100 font-12 btn-link text-left align-items-center" onClick={() => fnSelect(this.props.location)}> Fields (
              {location.fields.length})</button>
            <button type="button" className="btn px-2 font-12 btn-link text-center align-items-center" onClick={() => fnSelect(this.props.location)}><i className="fas fa-pencil-alt jeff_blue"></i></button>
          </div>
        </div>

      </figure >
    )
  }
}

export default LocationListItem;
