import React from 'react';

const BlackishHeader = (props) => {
  return (
    <div className="d-flex justify-content-center bg-yellow p-2">
      <span className="white align-self-center block text-center">{ props.message }</span>
    </div>
    );

};

export default BlackishHeader;