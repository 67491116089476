import React from 'react';
import _ from 'lodash';
import { Jumbotron } from 'reactstrap';
import config from '../../../config';
import request from 'superagent';
import { formatPrice } from '../../../helpers'

class RegistrationTab extends React.Component {

  state = {
    user: null,
    team: null,
    popoverOpen: false
  }

  // Lifecycle
  componentWillMount() {
    const { user, team, selectedSeason, questions } = this.props;
    this.setState({
      user,
      team,
      selectedSeason,
      questions
    });
  }
  componentWillReceiveProps = (nextProps) => {
    const { user, team, selectedSeason, questions } = nextProps;
    this.setState({
      user,
      team,
      selectedSeason,
      questions
    });
  }
  //

  togglePopover = () => this.setState({ popoverOpen: !this.state.popoverOpen });

  assignStatus = (status) => {
    var { team, user, selectedSeason } = this.state;

    if (team) {
      team.IdRoleStatus = status.IdRoleStatus;
      team.RoleStatus = status.RoleStatusName;
      team.RegistrationStatus = status.RoleStatusName;
    }
    if (user) {
      selectedSeason.IdRoleStatus = status.IdRoleStatus;
      selectedSeason.RegistrationStatus = status.RoleStatusName;
    }

    this.setState({
      team,
      user,
      selectedSeason,
      popoverOpen: false
    });

    // Call API
    // TODO: Might need to refactor this for using on other places ?
    if (team) {
      request.patch([config.apiEndpoint, 'api/v4/teams/status',
      team.IdTeamDivision, status.IdRoleStatus].join('/'))
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then((data) => {
          // Nothing to do here ?
        });
    } else {
      request.patch([config.apiEndpoint, 'api/v4/registration/status',
      user.IdUser, selectedSeason.IdDivision, status.IdRoleStatus].join('/'))
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then((data) => {
          // Nothing to do here ?
        });
    }
  }
  //

  render() {
    const { team, selectedSeason, questions } = this.state;

    return (
      <section>

        { /* Questions */}
        <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>Registration Questions</h3>

        {questions &&
          <div>
            {_.chain(questions).chunk(2).value().map((chunk, index) => (
              <div className="d-flex flex-row p-4 montserrat" key={index}>
                <div className="w-100">
                  <span className="block black toBold">{_.first(chunk).QuestionText}</span>
                  <span className="block">{_.first(chunk).QuestionResponseText} {_.first(chunk).Amount > 0 ? <span className="idented-half">({formatPrice(_.first(chunk).Amount * 100)})</span> : ''}</span>
                </div>
                <div style={{ width: 50 }} />
                <div className="w-100">
                  {chunk.length > 1 && <span className="block black toBold">{_.last(chunk).QuestionText}</span>}
                  {chunk.length > 1 && <span className="block">{_.last(chunk).QuestionResponseText} {_.last(chunk).Amount > 0 ? <span className="idented-half">({formatPrice(_.last(chunk).Amount * 100)})</span> : ''}</span>}
                </div>
              </div>
            ))}
            {questions.length === 0 && <Jumbotron className="bg-white d-flex flex-column justify-content-center">
              <i className="far fa-meh-blank font-30 black align-self-center" />
              <span className="black font-20 align-self-center">No questions were asked during this registration</span>
            </Jumbotron>}
          </div>
        }

      </section>)
  }
}

export default RegistrationTab;
