function reducer(state = [], action) {
    switch (action.type) {
        case 'GAME_DURATION/SET':
            localStorage.setItem('sportslogic.defaultGameDuration', action.defaultGameDuration + '');
            return action.defaultGameDuration;
        case 'GAME_DURATION/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;
