import React from 'react';
import Loader from '../../../common/Loader';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import NonTournamentList from './NonTournamentList';
import TournamentList from './TournamentList';

class Comp extends React.Component {

  state = {
    bootstrapped: false,
    mode: null // 1 -> Tournament, 2 -> Non-Tournament
  }

  // Lifecycle
  componentWillMount() {

    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    setTitle && setTitle({ main: 'Report | Registration' });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle, params } = nextProps;

      setTitle && setTitle({ sub: 'Report | Registration', main: nextProps.season.Name || null });

      this.setState({
        mode: nextProps.season.IdSeasonType === 3 ? 1 : 2
      });

      this.props.reportsActions && this.props.reportsActions.fetchRegistration(params.idSeason, nextProps.season.IdSeasonType === 3 ? 1 : 2); // tODO      
    }

    if (this.props.report !== nextProps.report && nextProps.report) {
      this.setState({
        bootstrapped: true
      });
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //

  render() {
    const { bootstrapped, mode } = this.state,
      { season } = this.props;

    return <section>
      <div className="filters-wrapper d-flex flex-row">
        <div className="ml-auto p-2">
          { /*<button className="btn btn-sm btn-warning"><i className="fas fa-file-excel" />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             { ' ' }Export</button>*/ }
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Registration - ${season.Name}`}
            sheet="Registration" buttonText="Export as XLS" />
        </div>
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && mode && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            {mode === 1 && <TournamentList {...this.props} />}
            {mode === 2 && <NonTournamentList {...this.props} />}
          </section>)}
      </div>
    </section>
  }
}

export default Comp;