import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as familyActions from '../redux-sagas/actions/familyActions';
import Main from '../components/Main.Family';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn,
        auth: state.auth,
        title: state.title,
        teams: state.teams,
        archived_teams: state.archived_teams,
        family: state.family,
        appPlans: state.appPlans
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        familyActions: bindActionCreators(familyActions, dispatch)
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;

