import React from 'react';

const TournamentList = ({ report }) => {
  return (
    <table id="table-to-xls" className="table table-sm font-10">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Division</th>
          <th>Team Name</th>
          <th>Position</th>

        </tr>
      </thead>
      <tbody>
        {report && report.map((record, i) => (
          <tr key={i}>
            <td>
              {record.FirstName}
            </td>
            <td>
              {record.LastName}
            </td>
            <td className="font-8">
              {record.EmailAddress}
            </td>
            <td>
              {record.FullPhoneNumber || record.PhoneNumber}
            </td>
            <td>
              {`${record.Address} - ${record.PostalCode} ${record.City} ${record.StateCode} `}
            </td>
            <td>
              {record.DivisionName}
            </td>
            <td>
              {record.TeamName}
            </td>
            <td>
              {record.Position}
            </td>

          </tr>)
        )}
      </tbody>
    </table>
  );
}

export default TournamentList;
