function reducer(state = [], action) {

    switch (action.type) {
        case 'REGISTRATIONS/SET':
            return action.registrations;
        case 'REGISTRATIONS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;