import React from 'react';
import moment from 'moment';
import config from '../../../../config';
import request from 'superagent';
import { UncontrolledTooltip } from 'reactstrap';

class Game extends React.Component {
    state = {}
    componentWillMount() {
        const { game } = this.props;
        this.setState({ game })        
    }

    download = () => {

        const { downloading } = this.state;
        if (downloading) return;

        this.setState({
            downloading: true
        }, () => {

            // Fetch games with filters applied
            request.get(`${config.apiEndpoint}/api/v4/roster/game_day/download/${this.state.game.IdGame}/roster.pdf`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .responseType('arraybuffer')
                .then((data) => {
                    let file = new Blob([data.body], { type: 'application/pdf' });
                    require("downloadjs")(file, 'GAME_DAY.pdf');
                    this.setState({ downloading: false, });
                });
        });
    }

    render() {
        const { game } = this.state;
        return <div className="card shadow mb-2 d-flex flex-row montserrat pointer">
            <div onClick={this.props.onClick} className="w-100 p-3 d-flex flex-column justify-content-center">
                <span className="font-10 jeff_blue align-self-center">Home</span>
                <img className='align-self-center my-2 ' src={`${game.home.TeamImageUrl}?full=80`} style={{ width: 100 }} alt="" />
                <span className="font-12 jeff_blue toBold align-self-center">{game.home.TeamName}</span>
            </div>
            <div onClick={this.props.onClick} className="w-100 d-flex flex-column justify-content-center">
                {game.home.Score !== null && game.home.Score !== undefined && <span className="font-30 jeff_blue align-self-center">{`${game.home.Score} - ${game.away.Score}`}</span>}
                <span className="font-14 black align-self-center">{moment(game.DateTimeStart_Date_Format, 'MM/DD/YYYY').format('MMM Do YYYY')}</span>
                <span className="font-12 toBold black align-self-center text-center">{game.LocationName}</span>
                <span className="font-20 black  align-self-center">{`${game.DateTimeStart_Time_Format} ${game.TimeZoneAbbreviation}`}</span>
                <span className="font-12 black  align-self-center">{`Game ID: ${game.IdGame}`}</span>
            </div>
            <div onClick={this.props.onClick} className="w-100 p-3 d-flex flex-column justify-content-center">
                <span className="font-10 jeff_blue align-self-center">Away</span>
                <img className='align-self-center my-2 ' src={`${game.away.TeamImageUrl}?full=80`} style={{ width: 100 }} alt="" />
                <span className="font-12 jeff_blue toBold align-self-center">{game.away.TeamName}</span>
            </div>
            <div style={{ width: 100, position: 'relative', display: 'block' }} className={`d-flex flex-column justify-content-end bg-${this.state.downloading ? 'success' : 'gray'}`}>
                <button id={`btn-print-${game.IdGame}`} onClick={this.download} className='btn btn-link'><i className={`fas fa-${this.state.downloading ? 'download' : 'print'} jeff_blue`} /></button>
                <UncontrolledTooltip placement="right" trigger="hover" target={`btn-print-${game.IdGame}`} >{this.state.downloading ? 'Downloading, please wait...' : 'Print Game Roster'}</UncontrolledTooltip>
            </div>
        </div>
    }
}

export default Game;