import React from 'react';
import config from '../../../../../config';

class Comp extends React.Component {

  /*

  width: 100%;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/91525/potofgold.svg);
  background-size: cover;
  */

  render() {
    const { team, signature } = this.props;
    return (
      <table className="w-100" style={{ height: 25 }}>
        <tbody>
          <tr>
            <td style={{ paddingLeft: '0.5em' }} className="d-flex flex-row">
              <strong className='w-100'>{team.HomeAway} Signature:</strong>
              <div className="w-100" style={{ height: 20, borderBottom: '1px solid black' }}>
                {signature && <div style={{ width: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${config.apiEndpoint}/api/v4/games/signature/${team.HomeAway.toLowerCase()}/${team.IdGame || 666089})`, height: 35 }} />}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

}

export default Comp;