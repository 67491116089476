import React from 'react';
import Sheet3v3 from './sheet';

class Comp extends React.Component {
    render() {
        const { teams, season } = this.props;
        return <section className="sheet postcard  margin-top ml-auto mr-auto no-padding">
            {teams.map((team, i) => <div key={i}><Sheet3v3 season={season} team={team}  /><hr className="no-margin no-padding" /></div>)}
        </section>
    }
}

export default Comp;