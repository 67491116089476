import React from 'react';
import PlayerInspection from '../registration/modals/PlayerInspection';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

class RegistrationPlayerSelection extends React.Component {

    state = {
        step: 1,
        mode: 1,
        bootstrapped: true,
        alreadyRegister: [],
        selectedUser: null,
        searchTerm: null,
        coachRoles: [
            {
                Id: 4,
                Name: 'Coach',
                selected: true
            },
            {
                Id: 4,
                Name: 'Assistant Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Team Manager',
                selected: false
            },
        ]
    }

    onClose = () => {
      this.setState({ bootstrapped: false }, () => {
          const { toggle } = this.props;
          setTimeout(() => { toggle && toggle() }, 500);
      });
  }

    selectMode = (mode) => {
        this.setState({
            mode,
            step: 2
        });
    }

    onOk = ({selectedUser, selectedDivision}) => {
        const {season, league,} = this.props;

        this.props.fnOk && this.props.fnOk({
            selectedUser,
            selectedSeason: season,
            selectedLeague: league,
            selectedDivision: selectedDivision
        });
    }

    selectRole = (index) => {
        var {coachRoles} = this.state;
        coachRoles.forEach((r, i) => {
            r.selected = (i === index);
        });
        this.setState({
            coachRoles
        });
    }

    back = () => {
        const {step} = this.state.step;
        if (step === 1) {
            this.props.toggle && this.props.toggle();
        } else {
            this.setState({
                step: (this.state.step - 1)
            });
        }
    }



    ok = () => {
        // TODO: 'persist' selected options and go to the next step
        this.props.fnOk && this.props.fnOk({
            selectedUser: this.state.selectedUser,
            selectedRole: _.find(this.state.coachRoles, (r) => {
                return r.selected;
            }),
            selectedSeason: this.props.season,
            selectedLeague: this.props.league,
            mode: this.state.mode
        });
    }

    addFamilyMember = () => {
        this.props.fnAddFamilyMember && this.props.fnAddFamilyMember();
    }

    render() {

        const {bootstrapped} = this.state;        

        return (
          <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
            <div className="dark-overlay" onClick={this.onClose} />
            <Animated style={{ width: 400, position: 'absolute', top: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray d-flex flex-column montserrat"
                isVisible={bootstrapped} animationOut="slideOutRight" animationIn='slideInRight' animationInDelay={200} animationInDuration={500} animationOutDuration={500}>
                <div style={{ height: 50 }} className="w-100 p-2 d-flex flex-row">
                    <i className="icon-arrow-left jeff_blue font-20 align-self-center" onClick={this.onClose} />
                    <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Family</span>
                    <i className="shopping_cart white-ish font-24 ml-auto align-self-center" />
                </div>                            
                <PlayerInspection {...this.props} onOk={this.onOk} />
                
            </Animated>

        </section>
        )
        
    }
}

export default RegistrationPlayerSelection;