import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, countCertainDays } from '../../../../../helpers';
import _ from 'lodash';
import config from '../../../../../config';
import request from 'superagent';
import moment from 'moment';

const fieldAvailabilityRuleSource = {
    beginDrag(props) { return props; }
};

class FieldAvailabilityRule extends React.Component {

    state = {
        editMode: false,
        microTransac: false,
        flights_with_this_rule: 0
    }

    setup = props => {
        const { divisions, rule } = props;
        if (divisions) {
            divisions.forEach(d => {
                d.Flights.forEach(f => f.DIVISION = d.DivisionName || d.Name)
            });
            let reduced_flights = _.chain(divisions).map(d => d.Flights).flatten()
                .filter(flight => _.find(flight.FieldAvailabilityRules, far => far.IdRule === rule.IdScheduleFieldAvailability))
                .value();
            this.setState({ flights_with_this_rule: reduced_flights });
        }
    }

    light = () => {
        const { rule, fieldAvailabilityRulesActions, index } = this.props;
        //if (readOnly) return;
        rule.LIGHT_UP = !rule.LIGHT_UP;
        fieldAvailabilityRulesActions && fieldAvailabilityRulesActions.updateFieldAvailabilityRule(index, rule);
    }

    componentWillMount() {
        this.setup(this.props);
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps);
    }

    renderInterval(interval, eachSegmentOfPixels, index, rule) {

        var minutesFromZeroToStart = moment(interval.TimeSlot_Start, 'Hmm').diff(moment('00:00', 'HH:mm'), 'minutes'),
            minutesFromStartToEnd = moment(interval.TimeSlot_End, 'Hmm').diff(moment(interval.TimeSlot_Start, 'Hmm'), 'minutes');

        var style = {
            width: (eachSegmentOfPixels * minutesFromStartToEnd) + 'px',
            left: (eachSegmentOfPixels * minutesFromZeroToStart) + 'px'
        }

        return (
            <div key={index} className="interval" style={style} data-tip={`${moment(interval.TimeSlot_Start, 'Hmm').format('h:mmA') + ' to ' + moment(interval.TimeSlot_End, 'Hmm').format('h:mmA')}`} data-for={`interval-${index}-${rule.IdScheduleFieldAvailability}`}>
                <div className="line" />
                <span className="from font-7 white">{moment(interval.TimeSlot_Start, 'Hmm').format('h:mmA')}</span>
                <span className="to font-7 white">{moment(interval.TimeSlot_End, 'Hmm').format('h:mmA')}</span>
            </div>
        )
    }

    deleteRule = () => {
        const { rule, index } = this.props;
        this.setState({ microTransac: true }, () => {
            // Save call API
            const url = [config.apiEndpoint, 'api/v4/schedule/rules/fields', rule.IdScheduleFieldAvailability].join('/');

            request.del(url)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    // TODO: Handle possible errors
                    this.setState({ microTransac: false });
                    this.props.fieldAvailabilityRulesActions &&
                        this.props.fieldAvailabilityRulesActions.deleteFieldAvailabilityRule(index)
                });
        });
    }

    render() {
        const { rule, connectDragSource, isDragging, connectDragPreview, readOnly } = this.props,
            { microTransac, flights_with_this_rule = 0 } = this.state;

        let fieldsNames = _.map(_.union(rule.Locations, rule.Fields), function (f) {
            return (f.Name || f.FieldName || f.LocationName);
        }).join(', '),
            fontClass = fieldsNames.length > 40 ? 'font-10' : '';
        if (fieldsNames.length > 55) fieldsNames = `${fieldsNames.substring(0, 54)}...`;

        let intervalsOfMinutes = 1,
            width = 350,
            howManySegments = 1440 / intervalsOfMinutes,
            eachSegmentOfPixels = width / howManySegments;

        let totalSlots = 0, totalDays = 0, totalFields = 0, playableDays = 0;

        if (rule) {
            totalDays = _.chain(rule.Days).pick('Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday').values().compact().value().length;
            totalFields = _.map(_.union(rule.Locations, rule.Fields), function (f) {
                return (f.Name || f.FieldName || f.LocationName);
            }).length;
            rule.TimeSlots.forEach(interval => {
                totalSlots += parseInt(moment(interval.TimeSlot_End, 'Hmm').diff(moment(interval.TimeSlot_Start, 'Hmm'), 'minutes') / rule.GameDurationMinutes, 10);
            });

            // Count playable days only
            let array_of_days = [
                rule.Days.Sunday ? 0 : null,
                rule.Days.Monday ? 1 : null,
                rule.Days.Tuesday ? 2 : null,
                rule.Days.Wednesday ? 3 : null,
                rule.Days.Thursday ? 4 : null,
                rule.Days.Friday ? 5 : null,
                rule.Days.Saturday ? 6 : null,
            ];
            playableDays = countCertainDays(_.filter(array_of_days, a => a !== null), moment(rule.Availability_Start), moment(rule.Availability_End));
        }

        //let WEEKS_RANGE = [moment(rule.Availability_Start).week(), moment(rule.Availability_End).week()];
        let TOTAL_WEEKS = (moment(rule.Availability_End).week() - moment(rule.Availability_Start).week()) + 1;

        // THIS IS A HACK for the step of building, trying to sort the flights by those that have less slots available
        rule.TOTAL_SLOTS = totalSlots * totalDays * totalFields * playableDays;

        return connectDragPreview(connectDragSource(
            <li className={` field-availability-rule d-flex flex-column card ${isDragging ? 'dragging' : ''}`} style={{ width: 350, borderRadius: 5, overflow: 'hidden', position: 'relative' }}>
                <section style={{ backgroundColor: '#2cad3c' }} className="d-flex flex-row flex-nowrap header white">
                    <div className={`align-self-center names-wrapper text-left mr-auto d-flex flex-row  pointer ${fontClass}`} style={{ height: 40 }}>
                        <span className="line1 align-self-center">{fieldsNames}</span>
                    </div>
                    {!microTransac && !readOnly && <button className="btn btn-link btn-sm" onClick={this.props.fnUpdate}><i className="fas fa-pencil-alt"></i></button>}
                    {!microTransac && !readOnly && <button className="btn btn-link btn-sm" onClick={this.deleteRule}><i className="fa fa-times"></i></button>}
                    {microTransac && !readOnly &&
                        <button className="btn-link align-self-center btn">
                            <i className="fa fa-spin fa-2x fa-hourglass-o font-12" />
                        </button>}
                </section>



                <span title={`${_.chain(flights_with_this_rule).map(flight => `${flight.DIVISION} - ${flight.FlightName || flight.Name}`).value().join(', ')}`} onClick={this.light} className={`${rule.LIGHT_UP ? 'red' : 'jeff_blue'} font-8 text-right line1 mt-2 mr-2 pointer`}><i className="fas fa-plane mr-1" />{flights_with_this_rule.length}</span>



                <span className="black font-10 text-center line1 px-2 mt-1">
                    <i className="fas fa-calendar-alt mr-2" /> <strong>{moment(rule.Availability_Start).format("MMM Do YYYY") + ' to ' + moment(rule.Availability_End).format("MMM Do YYYY")}</strong>
                </span>
                <div className="text-center black font-8 margin-top-half">
                    <span>Playable days: <strong>{playableDays}</strong> | Fields: <strong>{totalFields}</strong> | Weeks: <strong>{TOTAL_WEEKS}</strong></span>
                </div>
                <span className="black font-10 text-center margin-top-half line1 px-2">
                    <i className="fa fa-clock-o" /> for <strong>{rule.GameDurationMinutes}</strong> minutes duration games
                </span>

                <div className=" d-flex flex-row justify-content-center mt-2 black">
                    <button className={`btn btn-sm btn-${rule.Days.Sunday ? 'success' : 'default black'} day-button`}>S</button>
                    <button className={`btn btn-sm btn-${rule.Days.Monday ? 'success' : 'default black'} day-button`}>M</button>
                    <button className={`btn btn-sm btn-${rule.Days.Tuesday ? 'success' : 'default black'} day-button`}>T</button>
                    <button className={`btn btn-sm btn-${rule.Days.Wednesday ? 'success' : 'default black'} day-button`}>W</button>
                    <button className={`btn btn-sm btn-${rule.Days.Thursday ? 'success' : 'default black'} day-button`}>T</button>
                    <button className={`btn btn-sm btn-${rule.Days.Friday ? 'success' : 'default black'} day-button`}>F</button>
                    <button className={`btn btn-sm btn-${rule.Days.Saturday ? 'success' : 'default black'} day-button`}>S</button>
                </div>
                <div className="time-interval-graph margin-top-half d-flex flex-column mb-0">
                    <div className="intervals-wrapper ">
                        {rule.TimeSlots.map((t, i) => this.renderInterval(t, eachSegmentOfPixels, i, rule, isDragging))}
                    </div>
                    <div className="x-axis-wrapper container">
                        <div className="row">
                            <div className="col-12 x-axis" />
                        </div>
                        <div className="x-axis-labels row black">
                            <i className="col-4 text-left fa fa-angle-up no-margin no-padding" />
                            <i className="col-4 text-center fa fa-angle-up no-margin no-padding" />
                            <i className="col-4 text-right fa fa-angle-up no-margin no-padding" />
                        </div>
                        <div className="x-axis-labels row black">
                            <span className="font-6 col-4 text-left no-margin no-padding">00:00 AM</span>
                            <span className="font-6 col-4 text-center no-margin no-padding">12:00 PM</span>
                            <span className="font-6 col-4 text-right no-margin no-padding">11:59 PM</span>
                        </div>
                    </div>
                </div>
                <div className="text-center font-10 black">
                    <span>Slots per week: <strong>{totalSlots * totalDays * totalFields}</strong></span>
                </div>
                <div className="text-center black font-6 mb-2">
                    <span>Total Slots for season: <strong>{totalSlots * totalDays * totalFields * playableDays}</strong></span>
                </div>

            </li>
        ))
    }
}

export default flow(
    DragSource(ItemTypes.FIELDAVAILABILITYRULE, fieldAvailabilityRuleSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }))
)(FieldAvailabilityRule);
