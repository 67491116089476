import React from 'react';
import moment from 'moment';
import { Popover, PopoverBody } from 'reactstrap';
import fakeAuth from '../../fakeAuth';

class Item extends React.Component {

  state = {
    user: null, unvalidating: false, changingDOB: false, day: 1, month: 1, year: 2000, invalidDate: false
  }

  componentWillMount() {
    this.setState({ user: this.props.user }, () => {
      const { user } = this.state, { DateOfBirth } = user;
      this.setState({
        day: moment(DateOfBirth).utc().format('DD'),
        month: moment(DateOfBirth).utc().format('MM'),
        year: moment(DateOfBirth).utc().format('YYYY')
      });
    });
  }

  errorUserImage = () => {
    const { user } = this.state;
    user.UserImageUrl = `/images/defaults/${user.Gender.toLowerCase()}.png`;
    this.setState({
      user
    });
  }

  toggleValidated = () => {
    const { user } = this.state, { idLeague } = this.props, { NameFirst, NameLast } = fakeAuth.getUser();
    if (!user.IsValid) {
      user.IsValid = true;
      user.DateTimeModified = moment();
      user.UserModifiedName = `${NameLast.toUpperCase()}, ${NameFirst.toUpperCase()}`;

      // Update store?
      this.setState({ user });

      // call API
      this.props.leagueActions &&
        this.props.leagueActions.validateDOB(user.IdUser, idLeague, user.DateOfBirth);

    } else {
      this.toggleDeleteConfirm();
    }
  }

  toggleDeleteConfirm = () => {
    this.setState({ unvalidating: !this.state.unvalidating });
  }

  confirmDelete = () => {
    const { user } = this.state, { idLeague } = this.props;
    user.IsValid = false;
    user.DateTimeModified = null;
    user.UserModifiedName = null;
    this.setState({ user, unvalidating: false });
    this.props.leagueActions &&
      this.props.leagueActions.unValidateDOB(user.IdUser, idLeague);
  }

  toggleDOBChange = () => {
    this.setState({ changingDOB: !this.state.changingDOB, invalidDate: false }, () => {
      if (this.state.changingDOB && this.txtMonth) {
        this.txtMonth.focus()
        this.txtMonth.select();
      }
    });
  }

  saveDOB = () => {
    const { user } = this.state;
    const newDOB = moment(`${this.txtYear.value}-${this.txtMonth.value}-${this.txtDay.value}`, 'YYYY-MM-DD');
    if (newDOB.isValid()) {
      user.DateOfBirth = newDOB;
      //user.IsValid = false;
      this.setState({ changingDOB: !this.state.changingDOB, user, invalidDate: false });
      // Call API
      this.props.usersActions &&
        this.props.usersActions.changeDOB(user.IdUser, newDOB);
    } else {
      this.setState({ invalidDate: true });
    }
  }

  render() {
    const { user, day, month, year, invalidDate } = this.state;
    return (<tr>
      <td>
        <img alt="" onError={this.errorUserImage} className="smallPic margin-right-half" src={user.UserImageUrl} />{user.UserName}</td>
      <td>
        <button type='button' onClick={this.toggleDOBChange} id={`btnChangeDOB-${user.IdUser}`}
          className={`btn btn-link blue margin-left-half align-self-center underlined font-12`}>{moment(user.DateOfBirth).utc().format('MMM Do YYYY')}</button>
        <Popover placement="bottom" isOpen={this.state.changingDOB} target={`btnChangeDOB-${user.IdUser}`}
          toggle={this.toggleDOBChange}>
          <PopoverBody className="d-flex flex-column no-padding">
            <div className="d-flex flex-row justify-content-around p-2">
              <div className="form-group">
                <label>Month</label>
                <input defaultValue={month} min={1} max={12} type="number" style={{ width: 80 }} className="form-control" placeholder="MM" ref={(i) => this.txtMonth = i} />
              </div>
              <div className="form-group">
                <label>Day</label>
                <input defaultValue={day} min={1} max={31} type="number" style={{ width: 80 }} className="form-control" placeholder="DD" ref={(i) => this.txtDay = i} />
              </div>
              <div className="form-group">
                <label>Year</label>
                <input defaultValue={year} min={1} type="number" style={{ width: 80 }} className="form-control" placeholder="YYYY" ref={(i) => this.txtYear = i} />
              </div>
            </div>
            {invalidDate && <div className="bg-danger p-1 white">Invalid Date, please check.</div>}
            <div className="d-flex flex-row-reverse p-2">
              <button className="btn btn-success" onClick={this.saveDOB}>Save</button>
              <button className="btn btn-link red" onClick={this.toggleDOBChange}>Cancel</button>
            </div>
          </PopoverBody>
        </Popover>
      </td>
      <td className="font-8">
        <span className="block">{user.EmailAddress}</span>
        <span>{user.PhoneNumber}</span>
      </td>
      <td>
        <button id={`btnToggle-${user.IdUser}`} className="font-16" onClick={this.toggleValidated}>
          <i className={` fa-${user.IsValid ? 'check-square green fas' : 'square white far'}`} />
        </button>
        <Popover placement="left" isOpen={this.state.unvalidating} target={`btnToggle-${user.IdUser}`} toggle={this.toggleDeleteConfirm}>
          <PopoverBody className="d-flex flex-column justify-content-center">
            <span className="align-self-center">Are you sure?</span>
            <button className="btn btn-sm btn-danger" onClick={this.toggleDeleteConfirm}>No, cancel</button>
            <button className="btn btn-sm btn-success" onClick={this.confirmDelete}>Yes, un-check it!</button>
          </PopoverBody>
        </Popover>
      </td>
      <td>{user.UserModifiedName}</td>
      <td>{user.DateTimeModified ? moment(user.DateTimeModified).format('MMM Do YYYY') : ''}</td>
    </tr>)
  }
}

export default Item;
