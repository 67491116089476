import React from 'react';
import { ItemTypes, generateRandomId } from '../../../../../../helpers';
import { Jumbotron } from 'reactstrap';
import { DropTarget } from 'react-dnd';
import QandA from '../created_questions/item';
import SortableItem from './sortable';

const target = {
  canDrop(props) {
    return true;
  },
  drop(props, monitor, component) {
    // Dropped in another Flighted Team (to replace it position)
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }
    const { pouch, pouchActions } = props;
    // Evaluate if the division already has a registration program. If it does, prompt for confirmation of replace.
    const { question, type } = monitor.getItem();
    switch (type) {
      case ItemTypes.QANDA:
        pouch.qandas.push({ ...question, brandNew: true });
        pouchActions && pouchActions.set(pouch);
        break;
      default: break;
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({
      shallow: true
    }),
    canDrop: monitor.canDrop()
  };
}

class Preview extends React.Component {

  handleChange = (e) => {
    const { pouch } = this.props;
    pouch.name = e.target.value;
    this.props.pouchActions &&
      this.props.pouchActions.set(pouch);
  }

  delete = (index) => {
    const { pouch } = this.props;

    // If the group has an Id, it should not be deleted but marked as 'delete'
    if (pouch.IdQuestionLibraryGroup) {
      pouch.qandas = [...pouch.qandas.slice(0, index),
      { ...pouch.qandas[index], deleted: true },
      ...pouch.qandas.slice(index + 1)];
    } else {
      pouch.qandas = [...pouch.qandas.slice(0, index), ...pouch.qandas.slice(index + 1)];
    }
    //

    this.props.pouchActions &&
      this.props.pouchActions.set(pouch);
    this.forceUpdate(); // Damn this!
  }

  reRender = () => {
    this.forceUpdate(); // Damn this!
  }

  render() {
    const { connectDropTarget, canDrop, pouch } = this.props;
    return connectDropTarget(
      <div key={generateRandomId(10)} className={`${canDrop ? 'debug' : ''} flex-shrink-1 p-2 bg-gray`} style={{ width: 500 }}>
        <Jumbotron className="no-margin">
          <h2>Group Preview</h2>
          <p className="font-10">Drag and Drop questions from the left to add to the group</p>
        </Jumbotron>
        {/* Group Name */}
        <input defaultValue={pouch.name} onChange={this.handleChange} type="text" className="form-control margin-top-half margin-bottom-half" placeholder="Group Name" />
        {/* Qand As */}
        <ul>
          {pouch && pouch.qandas && pouch.qandas.map((group, i) =>
            <SortableItem fnUpdate={this.reRender} item={group} key={generateRandomId(5)} index={i} pouch={pouch} pouchActions={this.props.pouchActions}>
              <QandA question={group} index={i} className="margin-bottom-half" hideAnswers={false} deletable={true} fnRemove={this.delete} expandable={true} />
            </SortableItem>)}
        </ul>
      </div>)
  }
}

export default DropTarget([ItemTypes.QANDA], target, collect)(Preview);
