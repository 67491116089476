import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CardApplied from './card.applied';
import Masonry from 'react-masonry-component';
import { ItemTypes } from '../../../../../helpers';
import _ from 'lodash';

const masonryOptions = {
  transitionDuration: 1
};

class RightSide extends React.Component {
  state = {
    activeTab: 1,
    groupsInDivisions: []
  }
  componentWillMount() {
    const { divisions } = this.props;
    const groupsInDivisions = _.chain(divisions)
      .map(d => { return d.QuestionGroups; })
      .flattenDeep()
      .map(group => { return group.IdQuestionLibraryGroup }).value();
    this.setState({ groupsInDivisions });
  }
  redraw = () => this.forceUpdate();

  render() {
    const { appliedQuestions } = this.props, { activeTab, } = this.state;
    const filteredByTypeQuestions = _.filter(appliedQuestions, aq => !aq.Archived && aq.IdQuestionGroupLocation === activeTab);
    
    return (
      <div className="bg-white h-100" id="question-groups">

        {/* INNER TABS */}
        <ul className="nav nav-tabs font-16">
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.setState({ activeTab: 1 })}>
              <i className="far fa-question-circle blue"></i>
              <span className="half-idented font-12">Player / Team Questions</span></button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.setState({ activeTab: 2 })}>
              <i className="far fa-question-circle orange"></i>
              <span className="half-idented font-12">Coach / Admin Questions</span></button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.setState({ activeTab: 3 })}>
              <i className="far fa-question-circle green"></i>
              <span className="half-idented font-12">Scouts Questions</span></button>
          </li>
        </ul>

        {/* DISCLAIMER */}
        <div className="px-3 pt-1 text-right">
          <span className="font-8">
            <i className="fa fa-asterisk red"></i> - Required questions</span>
        </div>

        <Masonry
          key="masonry"
          className='d-flex flex-wrap mx-2'
          elementType={'ul'}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {/* Questions */}
          {filteredByTypeQuestions && filteredByTypeQuestions.map((group, i) => <div className="w-50 px-2" key={i}>
            <CardApplied group={group} key={i} type={ItemTypes.APPLIEDQUESTION} mode={activeTab} redraw={this.redraw} fnEdit={this.props.selectGroup} fnAction={this.props.archiveGroup} />
          </div>
          )}

          {/* No questions */}
          {(filteredByTypeQuestions && !filteredByTypeQuestions.length) && (
            <div className="p-4 w-100">
              <div className="jumbotron mx-auto my-0 text-center w-100">
                <i className="fas fa-exclamation-circle red font-60 mb-2" />
                <h2 className="line1">There are no Question Groups assigned to this season</h2>
                <p>Go to the library groups, then drag and drop some groups into the left section</p>
              </div>
            </div>
          )}
        </Masonry>

      </div>);
  }
}

export default RightSide;
