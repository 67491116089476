function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'BRACKET_GROUP_TYPES/SET':
            return action.groups;
        case 'BRACKET_GROUP_TYPES/CLEAR':
            return [];
        default:
            return state;
    }
}

export default reducer;
