import React from 'react';
import _ from 'lodash';
import fakeAuth from '../../../fakeAuth';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';

class Wizard extends React.Component {

    state = {
        bootstrapped: false,
        adding: 0,
        mode: 0,
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, teamActions, seasons } = this.props, { idTeam, idSeason } = params;
        let season = _.find(seasons, s => parseInt(s.IdSeason, 10) === parseInt(idSeason, 10));

        setTitle && setTitle({
            main: 'Wizard',
            sub: season ? (season.Name || season.SeasonName) : ''
        });

        this.setState({ season })
        teamActions && teamActions.fetchTeam(idTeam);
        teamActions && teamActions.fetchTeamSeasons(idTeam);
        this.setup();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.seasons && !this.state.bootstrapped && _.first(nextProps.seasons).IdTeamDivision) {
            this.setState({
                season: _.find(nextProps.seasons, s =>
                    parseInt(s.IdSeason, 10) === parseInt(this.props.params.idSeason, 10)
                )
            }, () => {
                const { rosterActions } = this.props;
                const { season } = this.state;
                rosterActions && rosterActions.fetchRoster(fakeAuth.getUser().IdUser, season.IdTeamDivision);
                rosterActions && rosterActions.fetchNetwork(season.IdTeamDivision);
                this.setup();
            })
        }
    }
    componentWillUnmount = () => {
    }

    setup = () => {
        if (
            this.props.selectedTeam &&
            this.props.permissions &&
            this.state.season && !this.state.bootstrapped
        ) {
            this.setState({ bootstrapped: true }, () => {
                const { setTitle } = this.props, { season } = this.state;
                setTitle && setTitle({
                    main: 'Roster',
                    sub: season.Name || season.SeasonName
                });
            });
        }
    }

    toggleAdding = (adding) => this.setState({ adding, mode: adding ? this.state.mode : 0 });

    onMode = mode => this.setState({ mode });

    deselectPlayer = () => this.setState({ selectedPlayer: null });

    selectPlayer = player => this.setState({ selectedPlayer: player });

    update = () => {
        this.deselectPlayer();
        const { rosterActions } = this.props, { season } = this.state;
        rosterActions && rosterActions.fetchRoster(fakeAuth.getUser().IdUser, season.IdTeamDivision);
    }

    onJerseys = () => this.setState({ jerseing: !this.state.jerseing });


    render() {

        const { season, bootstrapped } = this.state,
            { selectedTeam } = this.props;                

        return (
            <section className="" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>

                <div className={`bg-gray w-100`} style={{ position: 'absolute', top: 0, bottom: 0 }}>
                    <div style={{ height: 50 }} />
                    {bootstrapped && <Animated animationIn="fadeInRight" className="d-flex flex-row card tile shadow-box p-3 m-auto" style={{ maxWidth: 600 }}>
                        <div className="cover" style={{ width: 80, height: 80, background: `url(${selectedTeam.TeamImageUrl || selectedTeam.ImageUrl}) no-repeat center center` }} />
                        <div className="align-self-center d-flex flex-column ml-2">
                            <span className="black font-16 line1">{selectedTeam.TeamName || selectedTeam.Name}</span>
                            <span className="gray font-25 line1">{season.Name || season.SeasonName}</span>
                        </div>
                    </Animated>}
                    <div style={{ height: 20 }} />
                    {bootstrapped && <Animated animationIn="fadeInRight" className="d-flex flex-column card tile shadow-box p-3 m-auto" style={{ maxWidth: 600 }}>
                        <h3 className="block text-center mb-2">Important information to get Started!</h3>
                        <ul style={{ padding: '0 30px' }}>
                            <li style={{ listStyleType: 'initial' }} className="mt-2 font-12">All members of your roster must have a SQWAD Account</li>
                            <li style={{ listStyleType: 'initial' }} className="mt-2 font-14  toBold">Our suggestion is to have them download "THE SQWAD" app, which you can find on <a className="blue" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/the-sqwad/id1506954207">iOS</a> and <a className="blue" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.trycatch.squad.app">Android</a></li>
                        </ul>
                        <h3 className="block text-center mt-4 mb-2">Steps to roster your team</h3>
                        <ul style={{ padding: '0 30px' }}>
                            <li style={{ listStyleType: 'decimal' }} className="mt-2 font-12">Have players create an account via Coach invite or Proactively on their own</li>
                            <li style={{ listStyleType: 'decimal' }} className="mt-2 font-12">Go to your roster and select the 'Add Player' or 'Add Coach' button, based on your needs</li>
                            <li style={{ listStyleType: 'decimal' }} className="mt-2 font-12">Select Invite or Add Existing</li>
                            <li style={{ listStyleType: 'decimal' }} className="mt-2 font-12">Selecting Invite will allow you to enter in the user's Email Address witch will search if they're active in the system</li>
                            <li style={{ listStyleType: 'decimal' }} className="mt-2 font-12">Selecting Add Existing is a feature you'll be able to use after you've rostered your first team. These players will appear in your network for quick adding</li>
                        </ul>
                    </Animated>}
                    {bootstrapped && <Animated animationIn="fadeInRight" className="d-flex flex-column m-auto pt-3" style={{ maxWidth: 600 }}>
                        <span className="black font-10 m-auto">For additional questions, please contact your Club's Admin, or us at <a className="blue" href="mailto:info@thesqwad.com">info@thesqwad.com</a></span>
                    </Animated>}

                    <div style={{ height: 20 }} />
                    {bootstrapped && <Animated animationIn="fadeInRight" className="d-flex flex-column card tile shadow-box m-auto" style={{ maxWidth: 600 }}>
                        <Link to={`/roster/${selectedTeam.IdTeam}/${season.IdSeason}`} className="btn btn-success btn-block">Go to Roster</Link>
                    </Animated>}
                    <div style={{ height: 100 }} />


                </div>

            </section >
        )
    }
}

export default Wizard;
