function reducer(state = [], action) {

    switch (action.type) {
      case 'VALIDATIONS/SET':          
        return action.validations;
      case 'VALIDATIONS/CLEAR':
        return null;
      default:
        return state;
    }
  }
  
  export default reducer;