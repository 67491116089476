function reducer(state = [], action) {

    switch (action.type) {
        case 'FAMILY/SUBSCRIPTIONS/SET':            
            return action.family_subscriptions;
        default:
            return state;
    }
}

export default reducer;