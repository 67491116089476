import React from 'react';
import _ from 'lodash';
import moment from 'moment';

class PremierSubscriptions extends React.Component {

    state = { premier_subscriptions: [] }
    
    // Lifecycle
    componentWillMount() {
        const { premier_subscriptions } = this.props;
        this.setState({ premier_subscriptions: _.chain(premier_subscriptions).flatten().filter(s => s.IdAppPlan).value() });
    }
    componentWillReceiveProps = nextProps => {
        const { premier_subscriptions } = nextProps;
        this.setState({ premier_subscriptions: _.chain(premier_subscriptions).flatten().filter(s => s.IdAppPlan).value() });
    }
    //
    
    render() {
        const { premier_subscriptions } = this.state;
        return <section>
            {premier_subscriptions.map((s, i) => <div key={i} className="p-3 card shadow w-100 d-flex flex-row">
                <span className="align-self-center font-20 black">{s.TeamName}</span>
                <div className="d-flex flex-column ml-auto text-right align-self-center">
                    <span className="font-14 line1">Premier Coach</span>
                    <span className="font-8 line1">Expires at {moment(s.DateTimeEnd).format('MMM Do YYYY')}</span>
                    {!s.IsAutoRenew && <span className="font-8 line1 text-danger">Cancelled</span>}
                </div>
            </div>)}
        </section>
    }
}

export default PremierSubscriptions;
