import React from 'react';
import { ItemTypes } from '../../../helpers';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow'
import config from '../../../config';
import _ from 'lodash';

const teamSource = {
    beginDrag(props) { return props; }
};

class Drag extends React.Component {
    render() {
        const { connectDragSource, isDragging, canDrop, index, onClick, highlight, pantone } = this.props;

        return connectDragSource(
            <div onClick={() => onClick(index)} style={{
                height: 'auto', opacity: isDragging ? 0.5 : 1, cursor: 'move',
                border: isDragging ? '1px solid gray' : (canDrop ? '1px dotted gray' : (_.find(pantone, p => p.IdClip === highlight.idVideo) ? '4px solid green' : '1px solid gray'))
            }} className=" mt-2 mr-4 d-flex flex-column">
                <video
                    className=""
                    controls={false}
                    width="100%"
                    height="100px"
                    style={{ backgroundColor: 'black' }}
                    poster={highlight.thumbnail}
                    src={`${config.wowzaEndpoint}/dispatcher/${highlight.description}/playlist.m3u8`}
                />
                <div className="h-100 d-flex flex-row ">
                    <span className=" font-10 black p-2 align-self-center">{highlight.name || 'No Name'}</span>
                </div>
            </div>
        )
    }
}

export default flow(
    DragSource(ItemTypes.FLIGHT, teamSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }))
)(Drag);
