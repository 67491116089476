import React from 'react';
import { DropTarget } from 'react-dnd';
import ListItem from './NonRosteredPlayer';
import _ from 'lodash';
import { ItemTypes } from '../../../../../helpers';

const target = {
  canDrop(props, monitor) {
    const { selectedTeam } = props,
      { item } = monitor.getItem();

    return (item.IdTeamDivision && parseInt(item.IdTeamDivision, 10) === parseInt(selectedTeam.IdTeamDivision, 10)) ? true : false;
  },
  drop(props, monitor, component) {

    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }

    props.fnDrop && props.fnDrop(monitor.getItem().item);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class LowerList extends React.Component {

  state = {
    filters: localStorage.getItem("PLAYERS.FILTERBY") ? JSON.parse(localStorage.getItem("PLAYERS.FILTERBY")) : {
      actives: 1,
      pendings: 1,
      others: 0
    }
  }

  toggleFilter = (args) => {
    const { filters } = this.state;
    filters[args.name] = !filters[args.name];
    localStorage.setItem("PLAYERS.FILTERBY", JSON.stringify(filters));
    this.setState({ filters });
  }

  onSearch = e => {
    this.setState({ search_text: this.txtSearch.value })
  }

  render() {

    const { connectDropTarget, isOver, canDrop, items } = this.props,
      { filters, search_text } = this.state;

    let filteredItems = _.filter(items || [], item => {
      if (!filters.actives && item.IdRoleStatus === 2) return false;
      if (!filters.pendings && item.IdRoleStatus === 1) return false;
      if (!filters.others && item.IdRoleStatus !== 1 && item.IdRoleStatus !== 2) return false;
      return (item.IdRoleStatus === 1 || item.IdRoleStatus === 2);
    });

    let unique = _.chain(filteredItems)
      .groupBy('IdTeamUserRole')
      .values()
      .value();

    return connectDropTarget(
      <figure style={{ borderTop: '20px solid gray', position: 'absolute', bottom: 0, top: '50%', left: 0, right: 0, border: null }} className={`bg-white m-0 ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}`}>
        {items && <div className={`d-flex bg-${canDrop ? 'danger' : 'blue-dark'} px-3 d-flex flex-row white`} style={{ position: 'absolute', top: 0, height: 50, width: '100%' }}>
          <span className="align-self-center">Other Athletes</span>
          <span className="align-self-center ml-2">({items.length})</span>

          { /* Filters */}
          <div className="ml-auto d-flex flex-row align-self-center">
            <input placeholder='Search' type="text" ref={i => this.txtSearch = i} onChange={this.onSearch} className="form-control align-self-center mr-2" style={{ width: 250 }} />
            <button onClick={() => this.toggleFilter({
              name: 'actives'
            })} className="btn btn-sm btn-link">
              <i className={`${filters.actives ? 'fa fa-check-square' : 'fas fa-square'} margin-right-half`} />Actives (
              {_.filter(items, (i) => {
                return i.IdRoleStatus === 2
              }).length})</button>
            <button onClick={() => this.toggleFilter({
              name: 'pendings'
            })} className="btn btn-sm btn-link"><i className={`${filters.pendings ? 'fa fa-check-square' : 'fas fa-square'} margin-right-half`} />Pendings (
              {_.filter(items, (i) => {
                return i.IdRoleStatus === 1
              }).length})</button>
            {false && <button onClick={() => this.toggleFilter({
              name: 'others'
            })} className="btn btn-sm btn-link"><i className={`${filters.others ? 'fa fa-check-square' : 'fas fa-square'} margin-right-half`} />Others (
              {_.filter(items, (i) => {
                return i.IdRoleStatus !== 1 && i.IdRoleStatus !== 2
              }).length})</button>}
          </div>
        </div>}
        <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={{ position: 'absolute', top: 50, bottom: 0, overflow: 'auto' }}>
          <tbody className="w-100" style={{ display: 'table' }}>
            <tr className="bg-gray">
              <th></th>
              <th>Id</th>
              <th>Full Name</th>
              <th>Status</th>
              <th>Role</th>
              <th>DoB</th>
              <th>Current Team</th>
              <th>Gender</th>
              <th>Contact</th>
              <th>PCR</th>
              <th>City</th>
              <th>State</th>
              <th>Balance</th>
              <th>Paid</th>
              {this.props.questions && this.props.questions.map((q, i) => (
                <th key={i}>
                  {q.QuestionText}
                </th>))}

            </tr>
            {this.props.items && this.props.selectedTeam && _.filter(unique, ([t = {}]) => {
              return t.IdTeamDivision !== this.props.selectedTeam.IdTeamDivision;
            }).filter(([t]) => {
              if (search_text) return `${t.UserName.toLowerCase()}`.indexOf(search_text.toLowerCase()) !== -1;
              return true;
            }).map(([item], index) => (
              <ListItem {...this.props} key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>
      </figure>

    )
  }
}

export default DropTarget(ItemTypes.ROSTEREDPLAYER, target, collect)(LowerList);