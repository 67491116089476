import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CrossFlightRule from '../CrossFlightRule';


class CrossFlightsManager extends React.Component {

  state = {
    errorMessage: null
  }

  addRule = () => {
    const { selectedDivision, crossFlightRulesActions } = this.props;
    crossFlightRulesActions && crossFlightRulesActions.addCrossFlightRule({
      NumberOfGames: 1,
      IdDivision: (selectedDivision.Id || selectedDivision.IdDivision),
      DivisionName: selectedDivision.Name
    });
  }

  removeRule = (index) => {
    const { crossFlightRulesActions } = this.props;
    crossFlightRulesActions && crossFlightRulesActions.deleteCrossFlightRule(index);
  }

  noty = (message) => {
    this.setState({ errorMessage: message });
  }

  render() {
    const { isOpen, toggle, rules, selectedDivision, flights } = this.props,
      { errorMessage } = this.state;
    return (<Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="bg-blue-dark white">
        Cross-Flights for
                {' '}
        {selectedDivision.Name}
      </ModalHeader>
      <ModalBody id="cross-flight-manager">
        <ul className="no-style-list no-margin no-padding" style={{ minHeight: 300 }}>
          {rules.map((rule, i) => <CrossFlightRule noty={this.noty} flights={flights} selectedDivision={selectedDivision} rule={rule} key={i}
            index={i} fnCancel={() => this.removeRule(i)} />)}
        </ul>
        <div className="w-100 p-4">
        <button className="w-100 align-self-center green pl-3 pr-3" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} onClick={this.addRule}><i className="fa fa-plus green" />
          {' '}Add Cross-Flight Rule</button>
          </div>
      </ModalBody>
      {errorMessage && (<ModalBody className="bg-danger text-center white">
        <i className="fa fa-exclamation-triangle idented yellow"></i>
        {' '}
        {errorMessage}
      </ModalBody>)}
      <ModalFooter>
        <button className="align-self-center green pl-3 pr-3" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} onClick={toggle}>Close</button>
      </ModalFooter>
    </Modal>)
  }
}

export default CrossFlightsManager;
