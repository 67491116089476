import React from 'react';
import _ from 'lodash';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import request from 'superagent';
import config from '../../config';
import COLORS from '../../colors';

/*

The Body of a Rule:
Id uniqueIdentifier created with the insert operation of the _Set WS
IdDivision int
DivisionName string
IdFlight1 uniqueIdentifier
FlightName1 string
IdFlight2 uniqueIdentifier
FlightName2 string
NumberOfGames int
*/

class CrossFlightRule extends React.Component {
  state = {
    isFlight1Open: false,
    isFlight2Open: false,
    creating: false,
    microTransac: false,
    NumberOfGames: this.props.rule.NumberOfGames || 1
  }

  componentWillMount() {
    const { rule } = this.props;
    if (!rule.Id) {
      this.setState({ creating: true })
    }
  }

  selectFlight1 = (flight) => {
    const { rule } = this.props;
    rule.IdFlight1 = flight.IdFlight;
    rule.FlightName1 = flight.FlightName;
    this.setState({
      isFlight1Open: !this.state.isFlight1Open,
      creating: true
    });
  }

  selectFlight2 = (flight) => {
    const { rule } = this.props;
    rule.IdFlight2 = flight.IdFlight;
    rule.FlightName2 = flight.FlightName;
    this.setState({
      isFlight2Open: !this.state.isFlight2Open,
      creating: true
    });
  }

  setNumber = NumberOfGames => this.setState({ NumberOfGames, creating: true });

  toggleFlight1 = () => {
    this.setState({
      isFlight1Open: !this.state.isFlight1Open
    });
  }
  toggleFlight2 = () => {
    this.setState({
      isFlight2Open: !this.state.isFlight2Open
    });
  }
  delete = () => {
    const { rule, fnCancel } = this.props;
    if (rule.Id) {
      fnCancel && fnCancel();
      const url = [config.apiEndpoint, 'api/v3/schedule_manager/cross_flight_rules', rule.Id].join('/');

      request.del(url)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then((data) => {

        });
    }
  }
  save = () => {

    const { rule, flights, noty } = this.props;

    // TODO: validate the rule
    noty && noty(null);
    if (rule.IdFlight1 && rule.IdFlight2) {
      var flight1 = _.find(flights, (f) => {
        return f.IdFlight === rule.IdFlight1;
      }),
        flight2 = _.find(flights, (f) => {
          return f.IdFlight === rule.IdFlight2;
        });

      // RULE: Flights must be different
      if (flight1.IdFlight === flight2.IdFlight) {
        noty && noty('The flights must be different from each other');
        return;
      }
      // RULE: The number of teams must be the same on both flights
      if (flight1.teams.length !== flight2.teams.length) {
        noty && noty(['The number of teams on both flights must be the same'].join(' '));
        return;
      }

      rule.NumberOfGames = this.state.NumberOfGames;

      this.setState({ microTransac: true });

      const url = [config.apiEndpoint, 'api/v3/schedule_manager/cross_flight_rules'].join('/');

      request[rule.Id ? 'patch' : 'post'](url)
        .send(rule)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then((data) => {
          // This will replace the 'temporal flighted team' with the actual record
          if (!rule.Id) rule.Id = data.body.insertedId;

          this.setState({
            microTransac: false,
            creating: false
          });
        });

    } else {
      noty && noty('Please select both Flights');
    }

    // TODO: call the API
    // TODO: obtain an ID.
    // TODO: add to redux
    // TODO: change mode from edit to ?

  }
  cancel = () => {
    const { rule } = this.props;
    if (rule.Id) {
      this.setState({ creating: false });
    } else {
      this.props.fnCancel && this.props.fnCancel();
    }
  }

  changeNumberOfGames = () => {
    const { rule } = this.props;
    if (rule.Id) {
      this.setState({ creating: true });
    }
  }

  render() {

    const { selectedDivision, rule, flights } = this.props,
      { creating, microTransac, NumberOfGames } = this.state;
    return (
      <li className={`${(selectedDivision.IdDivision || selectedDivision.Id) === rule.IdDivision ? 'd-flex flex-row margin-top-half margin-left-half' : 'hide'}  `}>
        { /*<span className="black idented align-self-center">{ index }.</span> */}
        <Dropdown className="align-self-center btn-link" size="sm" style={{ border: '1px solid #CCC', borderRadius: 3 }} isOpen={this.state.isFlight1Open} toggle={this.toggleFlight1}>
          <DropdownToggle color="default" className="w-100" caret>
            {(rule.IdFlight1 && (_.find(flights, (f) => {
              return f.IdFlight === rule.IdFlight1;
            }))) ? ((_.find(flights, (f) => {
              return f.IdFlight === rule.IdFlight1;
            })).FlightName + ' (' + (_.find(flights, (f) => {
              return f.IdFlight === rule.IdFlight1;
            })).teams.length + ' teams)') : 'Select a Flight'}
          </DropdownToggle>
          
          <DropdownMenu>
            {flights.map((flight, i) => <DropdownItem key={i} onClick={() => this.selectFlight1(flight)}>
              {flight.FlightName} (
              {flight.teams.length} teams)
            </DropdownItem>)}
          </DropdownMenu>

        </Dropdown>

        <span className="black mx-2 align-self-center">VS</span>

        <Dropdown className="align-self-center btn-link" size="sm" style={{ border: '1px solid #CCC', borderRadius: 3 }} isOpen={this.state.isFlight2Open} toggle={this.toggleFlight2}>
          
          <DropdownToggle color="default" className="w-100" caret>
            {(rule.IdFlight2 && (_.find(flights, (f) => {
              return f.IdFlight === rule.IdFlight2;
            }))) ? ((_.find(flights, (f) => {
              return f.IdFlight === rule.IdFlight2;
            })).FlightName + ' (' + (_.find(flights, (f) => {
              return f.IdFlight === rule.IdFlight2;
            })).teams.length + ' teams)') : 'Select a Flight'}
          </DropdownToggle>
          
          <DropdownMenu>
            {flights.map((flight, i) => <DropdownItem key={i} onClick={() => this.selectFlight2(flight)}>
              {flight.FlightName} (
              {flight.teams.length} teams)
            </DropdownItem>)}
          </DropdownMenu>
        </Dropdown>

        <span className="black idented align-self-center"># Games</span>

        <button className={`ml-2 d-flex flex-row justify-content-center`} style={{ backgroundColor: NumberOfGames === 1 ? COLORS.jeff_blue : COLORS.transparent, color: NumberOfGames === 1 ? COLORS.white : COLORS.jeff_blue, border: `1px solid ${COLORS.jeff_blue}`, width: 36, height: 36, borderRadius: 18 }} onClick={() => this.setNumber(1)}><span className="align-self-center">1</span></button>

        <button className={`ml-2 d-flex flex-row justify-content-center`} style={{ backgroundColor: NumberOfGames === 3 ? COLORS.jeff_blue : COLORS.transparent, color: NumberOfGames === 3 ? COLORS.white : COLORS.jeff_blue, border: `1px solid ${COLORS.jeff_blue}`, width: 36, height: 36, borderRadius: 18 }} onClick={() => this.setNumber(3)}><span className="align-self-center">3</span></button>


        {creating && !microTransac && (
          <div className="d-flex flex-row ml-auto">
            <button className="mr-2 align-self-center red pl-3 pr-3" style={{ border: `2px solid ${COLORS.red}`, borderRadius: 20, height: 40 }} onClick={() => this.cancel()}>Cancel</button>
            <button className="mr-2 align-self-center green pl-3 pr-3" style={{ border: `2px solid ${COLORS.green}`, borderRadius: 20, height: 40 }} onClick={() => this.save()}>Save</button>
          </div>)}
        
        {!creating && !microTransac && rule.Id && (
          <div className="d-flex flex-row ml-auto">
            <button className="btn btn-sm btn-link font-12 mr-2" type="button" onClick={this.delete}><i className="fa fa-times red" /></button>
          </div>)}
      </li>)
  }
}

export default CrossFlightRule;