import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';

class Remaining extends React.Component {

    state = {
        plans: null
    }

    // Lifecycle
    componentWillMount() {
        const { plans } = this.props;        
        this.setState({ plans });
    }
    //

    upgrade = () => {
        this.props.upgrade && this.props.upgrade();
        this.props.toggle();
    }

    start = () => {        
        this.props.toggle();
    }

    render() {
        const { isOpen, toggle, current } = this.props,
            { plans } = this.state;
        let full = _.find(plans, p => p.Amount);        

        let remaining = current ? (current.Duration - moment().diff(moment(current.StartDate.split('T')[0], 'YYYY-MM-DD'), 'days')) : 0;
        if (remaining < 0) remaining = 0;        
        let slogan = remaining > 0 ?
            `Your ${current.PlanType === 2 ? 'Family' : 'Team'} is currently using the Trial version of Sqwad Studio. You get ${current.StorageLabel} for (${remaining}) more day(s)` :
            `Your ${current.PlanType === 2 ? 'Family' : 'Team'}'s ${current.Name} is about to expire. If you would like to continue using this services, please select the upgrade option`;

        return (
            <Modal isOpen={isOpen} style={{ width: 400 }} toggle={toggle}>
                <ModalBody className="container-fluid no-margin no-padding" style={{ height: 800, background: `url(/images/backgrounds/grass.png) no-repeat center center`, backgroundSize: 'cover', border: '1px solid white', borderRadius: 5 }}>
                    <div className="h-100 d-flex flex-row justify-content-center" style={{ position: 'relative' }}>
                        <div className="h-auto w-100 align-self-center d-flex flex-column justify-content-center">
                            <i className="fa icon-logo-header font-60 white block text-center"></i>
                            <span className="white toUpper font-16 align-self-center">{current.Name}</span>
                            <span className="white font-14 align-self-center">{remaining > 0 ? `Days Remaining (${remaining})` : 'Less than a day Remaining'}</span>
                            <span className="white font-12 text-center align-self-center p-2">{slogan}</span>
                            <span className="white font-12 align-self-center p-2">Upgrade to Premium and get {full.StorageLabel}</span>

                            <div style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 3, width: 150 }} className="m-auto p-2 text-center pointer" onClick={this.upgrade}><span className="white">Upgrade</span></div>
                            <div style={{ height: 30 }} />
                            <div style={{ width: 300 }} className="m-auto p-2 text-center pointer" onClick={toggle}><span className="white underlined">No thanks</span></div>
                        </div>
                        <i className="fa fa-times font-20 white" style={{ position: 'absolute', top: 10, right: 10 }} onClick={toggle} />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default Remaining;
