import React from 'react';
import _ from 'lodash';

class DivisionItem extends React.Component {
  render() {
    const { division, familyRegistration = [] } = this.props;    
    return (
      <figure className={`d-flex flex-row mt-1 mb-0 tile card shadow-box ${division.selected ? 'bg-success white' : ''}`} style={{ height: 50 }}>
        <div className="w-100 d-flex flex-column align-self-center idented">
          <span className="w-100 black align-self-center">
            {division.DivisionName || division.Name}
            {division.IsDivisionTryout ? ' - (Tryout)' : ''}
            {division.IsDivisionWaitlisted ? ' - (Waitlist)' : ''}
          </span>
          {familyRegistration && _.find(familyRegistration, fr => fr.IdDivision === division.IdDivision) && <span className="green font-8 line1">Already Registered</span>}
        </div>
        <i className={`ml-auto icon-check-circle mr-2 align-self-center white`} />
      </figure>)
  }
}

export default DivisionItem;