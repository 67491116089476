function reducer(state = [], action) {

    switch (action.type) {
        case 'FAMILY_ATTENDANCE/SET':            
            return action.family_attendance;
        default:
            return state;
    }
}

export default reducer;