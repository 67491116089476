import React from 'react';
import moment from 'moment';
import { Animated } from 'react-animated-css';

const DivisionListItem = (props) => {

  const { division, fnSelect, index, mode } = props;  

  // calculate gauge
  let activeGauge = mode === 2 ?
    (division.TotalTeams_Active * 100) / division.TotalTeams :
    (division.TotalPlayers_Active * 100) / division.TotalPlayers,
    pendingGauge = (mode === 2 ?
      ((division.TotalTeams_Pending * 100) / division.TotalTeams) :
      ((division.TotalPlayers_Pending * 100) / division.TotalPlayers)) + activeGauge,
    wailistGauge = (mode === 2 ?
      ((division.TotalTeams_Waiting * 100) / division.TotalTeams) :
      ((division.TotalPlayers_Waiting * 100) / division.TotalPlayers)) + pendingGauge,
    tryoutGauge = (mode === 2 ?
      ((division.TotalTeams_Tryout * 100) / division.TotalTeams) :
      ((division.TotalPlayers_Tryout * 100) / division.TotalPlayers)) + wailistGauge;

  return (
    <li key={division.IdDivision} className="ml-4 mr-4 p-3" onClick={() => fnSelect && fnSelect(division)}>
      <Animated animationIn="zoomIn" animationInDelay={(index * 50) + 300} className="font-10 no-margin no-padding d-flex flex-row">
        <div className="w-100 d-flex flex-row">
          <span className="block black w-100 toBold">{division.Name}</span>
          {division.DateBirthMin && <span className="font-10 w-100">{moment(division.DateBirthMin, 'MM/DD/YYYY').year()} - {moment(division.DateBirthMax, 'MM/DD/YYYY').year()} </span>}
          <span className="block font-10 black pointer clickable w-100">{mode === 2 ? division.TotalTeams : division.TotalPlayers}{' '}{mode === 2 ? 'Team(s)' : 'Player(s)'}</span>
        </div>
        {/*{(division.TotalPlayers > 0 || division.TotalTeams > 0) && (*/}
        <div className="w-100">
          <div className={`w-100 no-padding gauge ${(division.TotalTeams || division.TotalPlayers) ? '' : 'hide'}`} style={{ borderRadius: 10, overflow: 'hidden', height: 16 }}>
            {/* Red */}
            <div style={{ width: (division.gauge || '100%') }} className="inactive" />
            {/* Purple */}
            <div style={{ width: (division.gauge || tryoutGauge + '%') }} className="tryout" />
            {/* Yellow */}
            <div style={{ width: (division.gauge || wailistGauge + '%') }} className="waitlisted" />
            {/* Blue */}
            <div style={{ width: (division.gauge || pendingGauge + '%') }} className="pending" />
            {/* Green */}
            <div style={{ width: (division.gauge || activeGauge + '%') }} className="active" />
          </div>
        </div>
        {/*})}*/}
        <div style={{ width: 200 }} className="ml-auto text-right pointer green">
          View Details
        </div>
      </Animated>
    </li>)
}

export default DivisionListItem;
