import React from 'react';
import { Link } from 'react-router';
import _ from 'lodash';

import { Animated } from 'react-animated-css';
import fakeAuth from '../../fakeAuth';
import SelectableUser from './SelectableUser';
import request from 'superagent';
import config from '../../config';
import { humanize_user } from '../../helpers';

import './login.css';

class Login extends React.Component {

    state = {
        redirectToReferrer: false,
        mode: 0,
        invalid: false,
        selectedBg: null,
        message: null,
        email: null,
    }

    componentWillMount() {
        const { params } = this.props, { referal } = params;
        //if (referal) this.props.putReferal(referal);
        if (fakeAuth.isAuthenticated()) {
            this.onWhere();
        } else {

            const bgs = config.bgs;

            // Clear some things
            this.props.teamActions && this.props.teamActions.clearFamilyTeams();
            this.props.teamActions && this.props.teamActions.clearSelectedTeam();
            this.props.familyActions && this.props.familyActions.clearSelectedUser();
            this.props.familyActions && this.props.familyActions.clearFamily();

            this.setState({
                selectedBg: _.sample(bgs),
                email: localStorage.getItem('email')
            });

            if (params && params.idLeague) {
                // We set it here for the create account
                localStorage.setItem('sportslogic.referral.league', params.idLeague);
                this.setState({ mode: 10 });
                this.props.leagueActions && this.props.leagueActions.fetchLeagueNonSecure(params.idLeague);
                // TODO: the idea is to fetch the league info, and morph this page according to the league's preferences
            } else this.setState({ mode: 1 });
        }
    }
    componentWillReceiveProps = nextProps => {
        const { league } = nextProps;
        const bgs = config.bgs;
        if (league && !this.state.league) this.setState({
            league, mode: 1,
            selectedBg: _.sample(config[`bgs_${league.IdCommunity}`] || bgs)
        }, () => {
            localStorage.setItem('sportslogic.referral.country_code', league.CountryCode);
        });
    }

    doLogin = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { microTransac } = this.state, { params } = this.props;
        if (!microTransac) {
            this.setState({
                microTransac: true,
                invalid: false,
                email: this.txtEmail.value
            }, () => {

                // Save the email on the localstorage for future usage
                localStorage.setItem('email', this.txtEmail.value);

                // Call API
                request.post(`${config.apiEndpoint}/api/v6/membership/login`)
                    .send({
                        email: this.txtEmail.value,
                        pass: this.txtPassword.value,
                        idReferralLeague: parseInt(params.idLeague, 10) || 0
                    })
                    .then((data) => {
                        if (data.body.success) {

                            // Decrypt User Info
                            data.body.family.forEach(humanize_user);

                            this.setState({
                                mode: 2,
                                microTransac: false,
                                users: data.body.family
                            });
                        } else
                            this.setState({ microTransac: false, invalid: true });

                    }, ({ response: { body } }) => {
                        this.setState({
                            microTransac: false,
                            invalid: true,
                            message: 'Invalid Email or Password. Please check.', //body.error.message
                        });
                        this.props.setDeveloperMessage && this.props.setDeveloperMessage(body.error);
                    });
            });
        }
    }

    onWhere = () => {
        const { params } = this.props;
        // If the login works, we remove this from the local storage 
        if (params && params.idLeague) {
            localStorage.removeItem('sportslogic.referral.league');
            localStorage.removeItem('sportslogic.referral.country_code');
            this.props.router.push(`/registration/${params.idLeague}`);
        } else {
            this.props.router.push(`/family`);
        }
    }


    back = () => this.setState({ mode: this.state.mode === 1 ? 1 : (this.state.mode - 1) });

    selectUser = user => {
        if (user.Token) {
            fakeAuth.authenticate(() => {
                this.props.login({
                    token: user.Token,
                    isAdmin: user.IsLeagueAdmin
                });
                if (user.IsLeagueAdmin) {
                    this.setState({
                        mode: 3,
                        selectedUser: user
                    });
                } else {
                    this.onWhere();

                }
            }, user.Token,
                user.IsLeagueAdmin, user)
        }
    }

    render() {
        const { mode, users, microTransac, invalid, selectedBg, email, message, league } = this.state;
        const { params } = this.props, { idLeague } = params;

        return (
            <div className="limiter bg-gray">

                <div className="container-login100">

                    <Animated animationIn="fadeInRight" className="wrap-login100 shadow-box">
                        { /* Login Form */}
                        {mode === 1 && (
                            <form className="login100-form validate-form bg-white" key="login-form">
                                <div className="d-flex flex-row justify-content-center w-100 mb-4 pb-4">
                                    <div id="logo-sqwad" className="d-flex flex-column justify-content-center align-self-center ">
                                        {!league && <i className="icon-logo-header jeff_blue block text-center align-self-center line1" style={{ fontSize: 100 }}></i>}
                                        {league && <img src={`${league.LeagueImage}?full=80`} style={{ height: 100 }} className="align-self-center text-center" />}
                                    </div>
                                </div>


                                <div key="email" className="wrap-input100 rs1-wrap-input100 validate-input m-b-20" style={{ marginBottom: '20px' }} data-validate="Type user name">
                                    <input className="input100" defaultValue={email} type="text" name="email" placeholder="Email" style={{ borderRadius: '0', height: '55px' }} ref={(input) => this.txtEmail = input} />
                                    <span className="focus-input100"></span>
                                </div>

                                <div key="pass" className="wrap-input100 rs2-wrap-input100 validate-input m-b-20" style={{ marginBottom: '20px' }} data-validate="Type password">
                                    <input className="input100" type="password" name="pass" placeholder="Password" style={{ borderRadius: '0', height: '55px' }} ref={(input) => this.txtPassword = input} />
                                    <span className="focus-input100"></span>
                                </div>

                                <div key="login-button" className="container-login100-form-btn">
                                    <button type="submit" className="login100-form-btn" onClick={this.doLogin}>
                                        {!microTransac && <span>Login</span>}
                                        {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                    </button>
                                    { /* Error Message */}
                                    {invalid && <div className="d-flex flex-column p-2 bg-danger bd-highlight" style={{ width: '100%' }}>
                                        <span className="white align-self-center font-8">{message || 'Invalid Email or Password'}</span>
                                    </div>}
                                </div>

                                <div key="recover-password" className="w-full text-center p-t-27 p-b-239" style={{ paddingTop: '20px', paddingBottom: '100px' }}>
                                    <span className="txt1" style={{ marginRight: '5px' }}>Forgot your</span>
                                    <Link to='/recover' className="txt2 underlined jeff_blue">password?</Link>
                                </div>

                                <div key="registration-link" className="w-full text-center zoom">
                                    <Link to="/create_account" className="btn btn-success rounded">Create Account</Link>
                                </div>

                            </form>)}
                        { /* Pretty Image */}
                        <Animated animationIn="fadeIn" className={`login100-more ${selectedBg}`} key="picture" />
                        { /* Select User */}
                        {mode === 2 && (
                            <Animated animationIn="fadeInRight" className="login100-form bg-white" key="login-users">
                                <ul className="w-100">
                                    {users.map((user, i) => <li key={i} className="selectable-user pointer">
                                        <button onClick={() => this.selectUser(user)}>
                                            <SelectableUser user={user} />
                                        </button>
                                    </li>)}
                                    <li>
                                        <button type="button" className="btn btn-link" onClick={this.back}>
                                            <i className="fa fa-arrow-left" />
                                            {' '}<span>Back</span></button>
                                    </li>
                                </ul>
                            </Animated>)}
                        {mode === 3 && (
                            <Animated animationIn="fadeInRight" className="login100-form bg-white" key="login-users">
                                <ul className="w-100">
                                    <li>
                                        <SelectableUser user={this.state.selectedUser} />
                                    </li>
                                    <li className="tile hoverable p-2 bg-gray block margin-bottom">
                                        <Link className=" d-flex flex-rowblock" to={idLeague ? `/registration/${idLeague}` : `/family`}>
                                            <i className="fa fa-users margin-right align-self-center" />
                                            <span className="align-self-center">Go to Family Site</span>
                                            <i className="p-2 fa fa-arrow-right ml-auto align-self-center" />
                                        </Link>
                                    </li>
                                    <li className="tile hoverable p-2 bg-gray block margin-bottom">
                                        <Link className=" d-flex flex-rowblock" to="/admin">
                                            <i className="fa fa-star margin-right align-self-center" />
                                            <span className="align-self-center">Go to Admin Site</span>
                                            <i className="p-2 fa fa-arrow-right ml-auto align-self-center" />
                                        </Link>
                                    </li>
                                    <li>
                                        <button type="button" className="btn btn-link" onClick={this.back}>
                                            <i className="fa fa-arrow-left" />
                                            {' '}<span>Back</span></button>
                                    </li>
                                </ul>
                            </Animated>
                        )}
                    </Animated>
                    {/*<Animated animationInDelay={500} animationInDuration={300} animationIn="fadeInLeft" isVisible={true} animateOnMount={true} style={{ position: 'fixed', bottom: 20, left: 20 }} className="d-flex flex-row">
                        <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/the-sqwad/id1506954207"><img src="/images/icons/appstore.png" alt="" style={{ height: 30 }} /></a>
                        <a className="ml-2" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.trycatch.squad.app"><img src="/images/icons/playstore.png" alt="" style={{ height: 30 }} /></a>
                        </Animated>*/}
                </div>
                {league && <div style={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
                    <Animated animationIn="slideInDown" className="w3-content">
                        <i className="icon-logo-header jeff_blue block align-self-center line1" style={{ fontSize: 80 }}></i>
                    </Animated>
                </div>}
            </div>
        )
    }
}

export default Login;
