import React from 'react';
import DobItem from './partials/dob_item';
import ConcussionItem from './partials/concussion_item';
import SafeItem from './partials/safe_item';
import BcgItem from './partials/background_check_item';
import CoachItem from './partials/coach_item';
import FingerItem from './partials/fingerprint_item';
import FileModal from './partials/file_modal';
import _ from 'lodash';

class UserValidations extends React.Component {
    state = {
        user: null
    }
    componentWillMount() {
        const { idUser, idLeague, leagueActions } = this.props;
        if (!leagueActions) alert()
        leagueActions && leagueActions.fetchUser(idUser, idLeague);
        leagueActions && leagueActions.fetchValidationsSummary(idLeague);
    }
    componentWillReceiveProps = nextProps => {        
        this.setState({ user: nextProps.user });
    }

    bringFileUp = (file) => this.setState({ file });

    render() {
        const { user } = this.state, { idLeague } = this.props, { validations_summary = {} } = this.props, { totals } = validations_summary;
        return <div className="w3-container">
            <div className="w3-content" style={{ maxWidth: 1250 }}>
                <h1 className="mt-4 mb-4">Validations</h1>

                {user && <div className="d-flex flex-column">
                    {_.chain(user.validations)
                        .chunk(3).value().map(([a = {}, b = {}, c = {}], i) => <div className="d-flex flex-row justify-content-center" key={i}>
                            {[a,b,c].map((val, j) => <section key={val.IdUserValidation} className="m-1">
                                {val.ValidationProcessCode === 'DOBV' && <DobItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-DOBV-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}

                                {val.ValidationProcessCode === 'CONC' && <ConcussionItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-CONC-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}

                                {val.ValidationProcessCode === 'SAFE' && <SafeItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-SAFE-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}

                                {val.ValidationProcessCode === 'BGC' && <BcgItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-BGC-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}

                                {val.ValidationProcessCode === 'COACH' && <CoachItem licences={totals} tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-COACH-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}
                                
                                {val.ValidationProcessCode === 'FINGER' && <FingerItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-FINGER-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}

                                {!val.ValidationProcessCode && <div style={{ width: 250 }} />}
                            </section>)}
                        </div>)}

                        <div style={{ height: 80 }} />
                </div>}

                {!user && <section>Fetching, please wait...</section>}

            </div>

            <FileModal isOpen={this.state.file ? true : false} file={this.state.file} toggle={() => this.setState({ file: null })} {...this.props} />
        </div>
    }
}

export default UserValidations;