import React from 'react';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import fakeAuth from '../../fakeAuth';
import TeamItem from './partials/TeamItem.js';
import Loader from '../common/Loader';
import './css.css';

class Index extends React.Component {

    state = {
        searchText: '',
        selectedTeam: null,
        teams: null,
        user: fakeAuth.getUser(),
        bootstrapped: false
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, familyActions } = this.props;
        familyActions && familyActions.fetchTeams();
        setTitle && setTitle({ main: 'SQWAD TEAM Studio' });
    }

    componentWillReceiveProps = (nextProps) => {
        const { teams } = nextProps, { bootstrapped } = this.state;
        if (teams && !bootstrapped) {
            this.setState({ teams: _.chain(teams || []).groupBy('IdTeam').values().value().reverse(), bootstrapped: teams ? true : false });
        }
    }
    //        

    filter = () => this.setState({ searchText: this.txtSearch.value });

    render() {

        const { teamActions, /*family_lastname*/ } = this.props,
            { bootstrapped, teams } = this.state;

            let admin_teams = _.filter(teams || [], t => _.find(t, i => i.IsTeamAdmin || i.IsTeamOwner));

        return (
            <Animated animateIn="fadeIn">
                {bootstrapped && admin_teams && admin_teams.length === 0 && (
                    <div key="no-leagues" className="d-flex flex-col justify-content-center centered-message">
                        <h1 className="white align-self-center block text-center">There are no Teams registered for SQWAD Services</h1>
                    </div>
                )}
                {bootstrapped && admin_teams && (
                    <div style={{ maxWidth: 1065, margin: 'auto' }} className="">
                        <div key="teams" className="clearfix league-list margin-bottom mt-4">                            
                            {admin_teams.map((team, i) => <TeamItem key={i} index={i} team={team} teamActions={teamActions} />)}
                        </div>
                    </div>
                )}
                {!bootstrapped && <Loader key="loader" message={`Loading Leagues`} />}
            </Animated>
        )
    }
}

export default Index;
