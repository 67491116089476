import React from 'react';
import TeamDraggable from './TeamDraggable';

class Teams extends React.Component {    
    render() {
        const { teams, teamsInGames = [] } = this.props;
        return (
            <ul className="block d-flex flex-column no-style-lists no-margin no-padding bg-white margin-bottom-half">
                {teams.length > 0 && teams.map((team, i) => (
                    <TeamDraggable key={i} team={team} selected={teamsInGames.indexOf(team.IdTeamDivision) !== -1} />))}
                {!teams.length && <div className="jumbotron no-margin"><i className="fa fa-exclamation"></i> There are no Flighted Teams.</div>}
            </ul>
        )
    }
}

export default Teams;