function reducer(state = [], action) {

    switch (action.type) {
        case 'POINTS/SET':
            return action.points;
        case 'SEASON/CLEAR_POINT_SYSTEM':
            return null;
        default:
            return state;
    }
}

export default reducer;