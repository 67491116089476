import React from 'react';

const Comp = () => {
    return (<div style={{ padding: '0 1em' }}>
        <label className="font-10 margin-top-half white margin-left-half padding-right-half">Standings will be calculated on the games win and loss ratio differential among teams. For example:</label>
        <table className="table table-bordered padding-left-half padding-right-half">
            <thead>
                <tr className="blue">
                    <th>Team</th>
                    <th>P</th>
                    <th>W</th>
                    <th>L</th>
                    <th>%</th>
                    <th className="red">GB</th>
                </tr>
                <tr>
                    <td><i className="fa fa-shield"></i>
                        {' '}Team A</td>
                    <td>47</td>
                    <td>34</td>
                    <td>13</td>
                    <td>.723</td>
                    <td className="red">-</td>
                </tr>
                <tr>
                    <td><i className="fa fa-shield"></i>
                        {' '}Team B</td>
                    <td>45</td>
                    <td>31</td>
                    <td>14</td>
                    <td>.689</td>
                    <td className="red">2</td>
                </tr>
                <tr>
                    <td><i className="fa fa-shield"></i>
                        {' '}Team C</td>
                    <td>45</td>
                    <td>27</td>
                    <td>18</td>
                    <td>.600</td>
                    <td className="red">6</td>
                </tr>
            </thead>
        </table>
    </div>)
}

export default Comp;
