import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators.js';
import Login from '../components/login/Recover';

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn,
        carriers: state.carriers,
        auth: state.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch)
}

const App = connect(mapStateToProps, mapDispatchToProps)(Login);

export default App;
