import _ from 'lodash';

function reducer(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'QUESTIONARIES/SET':            
            return action.questionaries;        
        case 'QUESTIONARIES/CLEAR':
            return null;        
        default:
            return state;
    }
}

export default reducer;