import React from 'react';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import _ from 'lodash';
import moment from 'moment';
import { Animated } from 'react-animated-css';
import LocationsFilters from './LocationsFilters';
import DivisionsFilter from './DivisionsFilter';
import CoachesFilter from './CoachesFilter';
import Pairings from './Pairings';
import config from '../../../../config';
import request from 'superagent';
import Slider from 'react-rangeslider'
import LineLoader from '../../../common/LineLoader';
import { calculateBWContrastClass, calculateBWContrast } from '../../../../helpers';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerLabel';
import Tooltip from 'tooltip.js';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

class FullCalendarWrapper extends React.Component {

  calendarComponentRef = React.createRef();
  tooltip = null;

  state = {
    filters: {
      toggled: false,
      game_type: {
        all: true,
        poolplay: false,
        playoffs: false
      }
    },
    defaultDate: moment(), // Default to the 1st game of the season unless we're in the middle of the season in which case take me to the next date with games
    activeTab: 1,
    resources: null,
    mode: 'resourceTimeGridDay', //'dayGridMonth',
    games: [],
    coaches: [],
    allTeams: [],
    timeGap: '10',
    timeGapInteger: 10,
    defaultGameDuration: null,
    datesAboveResources: true,
    bootstrapped: false,
    showingPairings: false,
    havePairings: false,
    calendarObject: null,
    currentPairings: [],
    zoomFactor: 100,
    tooltip: [],
    tooltip_enabled: false
  }

  setup = props => {
    const { locations, games, divisions, season } = props;

    // Format locations to fit the requirements for FullCalendar as a resouce
    let fields = [];
    _.sortBy((locations || []), 'Name').forEach(location => {
      fields = [...fields, {
        Name: location.Name,
        IdLocation: location.IdLocation
      }, ..._.map(location.Fields || location.fields, f => {
        return { Name: f.Name, IdLocation: f.IdLocation, IdLocation_parent: f.IdLocation_parent };
      })];
    });
    // TODO: create a drop-down structure to group up the fields in a Parent / Child struct
    //       
    const formatedGames = this.formatGamesForFullCalendar([...games]);

    let havePairings = false;
    divisions.forEach(d => {
      d.Flights.forEach(f => {
        if (f.HasPairings === 1) havePairings = true;
      });
    });

    const divisionsWithTeams = _.filter(divisions, division => {
      if (
        _.chain(division.Flights).map((f) => {
          return f.FlightedTeams;
        }).flatten().value().length
      ) {
        return true;
      }
      return false;
    });
    const allTeams = _.chain(divisionsWithTeams).map(division => {
      return _.chain(division.Flights).map((f) => {
        return f.FlightedTeams;
      }).flatten().value();
    }).flatten().value();

    this.setState({
      resources: this.state.resources || _.chain(fields).map((f, index) => {
        return {
          id: `${f.Name}-${parseInt(f.IdLocation, 10)}`,
          title: f.Name,
          child: f.IdLocation_parent,
          checked: formatedGames.length ?
            (_.find(formatedGames, game => parseInt(game.IdLocation, 10) === parseInt(f.IdLocation, 10)) ? true : false) :
            (index < 5 ? true : false)
        }
      }).value(),//.sortBy('title').value(),
      havePairings,
      allTeams,
      games: formatedGames,
      hiddenGames: (this.state.bootstrapped ? this.state.hiddenGames : season.IsHidden),
      // The pre-selectd date of the calendar must be the date of the very 1st game (right ?) || today if there are no games
      defaultDate: this.state.bootstrapped ? this.state.defaultDate :
        (formatedGames.length ? (_.find(formatedGames, g => moment(g.GameDate).format('YYYYMMDD') === moment().format('YYYYMMDD')) ?
          moment() :
          moment(_.sortBy(formatedGames, 'start')[0].GameDate)) : moment())
    });
  }

  // Lifecycle
  componentWillMount = () => {
    this.setup(this.props);
  }

  componentWillReceiveProps = nextProps => {
    const { games } = nextProps;
    if (games && nextProps.coaches) {
      this.setState({
        bootstrapped: this.state.bootstrapped || (nextProps.games.length ? true : false)
      }, () => {
        this.setup(nextProps);
      });
    }
  }

  componentWillUnmount = () => {
    if (this.tooltip) this.tooltip.dispose();
  }
  //

  // IMPORTANT: This is the RENDER on the CALENDAR
  customEventRender = ({ event, view, el }) => {
    // This is an important one. 
    // It replaces the basic template of Fullcalendar for events and creates our own HTML   
    // If its a playoff, add a star at the beginning of the name string.
    // Added the round number
    const { extendedProps } = event;

    el.innerHTML =
      `<div style="border-bottom: 1px solid white;" class="bg-black pl-1 d-flex flex-row">
        <span class="white" style="white-space: nowrap;">${extendedProps.playoff ? '<i class="fa fa-star margin-right-half yellow"></i>' : ''}${extendedProps.division}</span>
        <span class="ml-auto white">${extendedProps.RoundNumber && !extendedProps.playoff ? `R${extendedProps.RoundNumber}` : ``}</span>
      </div>
      <div class="p-1 line1 h-100" style="background: rgb(2,0,36);
      background: linear-gradient(90deg, ${extendedProps.Home_DivisionColor} 0%, ${extendedProps.Away_DivisionColor} 100%);">
        <div>${moment(event.start).format("h:mmA")} - ${moment(event.start).add(extendedProps.GameDurationMinutes, 'minutes').format('h:mmA')} - ID: ${extendedProps.IdGame} - ${extendedProps.FlightName}</div>
        <div>${extendedProps.HomeTeam.TeamName || extendedProps.HomeTeam.Name || ((extendedProps.HomeTeam.Placement || { GameBracketPlacementName: '?' }).GameBracketPlacementName)} vs ${extendedProps.AwayTeam.TeamName || extendedProps.AwayTeam.Name || ((extendedProps.AwayTeam.Placement || { GameBracketPlacementName: '?' }).GameBracketPlacementName)}</div><div>${extendedProps.playoff ? extendedProps.EventText : ''}</div>        
      </div>`;

    if (extendedProps.playoff) {
      el.style.borderColor = 'yellow';
      el.style.borderWidth = '3px';
    } else {
      el.style.borderColor = 'black';
      el.style.borderWidth = '1px';
    }
  };

  customEventDrop = ({ event, revert, newResource, }) => {

    // This one handles events dragging and dropping inside of the calendar. (not from the outside)
    const { events, resources } = this.state;
    _.extend(_.find(events, function (e) {
      return (e.id === event.id);
    }), _.pick(event, 'start', 'end', 'resourceId'));
    this.setState({ events }, () => {

      // Update this fields through the API (basically IdLocation and GameStartHour)      
      const location = _.find(resources, r => {
        return parseInt(_.last(r.id.split('-')), 10) === (newResource ? parseInt(_.last(newResource.id.split('-')), 10) :
          parseInt((event.extendedProps.IdLocation || event.extendedProps.Location.IdLocation), 10));
      });

      let payload = _.extend({
        ...event.extendedProps
      }, {
        GameDate: moment(event.start).format('YYYY-MM-DD'),
        LocationName: location ? location.title : event.extendedProps.LocationName,
        Location: {
          IdLocation: location ? parseInt(_.last(location.id.split('-')), 10) : parseInt(event.extendedProps.IdLocation, 10),
          Name: location ? location.title : event.extendedProps.LocationName
        },
        GameStartHour: moment(event.start).format('Hmm'),
      });

      this.props.gamesActions && this.props.gamesActions.updateGame({ ...payload });

      // Smart Call aPI        
      request[(payload.Id) ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/games${payload.Id ? `/${payload.Id}` : ''}`)
        .send({ ...payload })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then((data) => {
          if (!data.body.success) {
            revert && revert();
          }
          // optimistic ?            
        });

    });
  }

  customDayClick = ({ date, resource }) => {
    // I hate this, but the calendar does not have a doubleClick handler    
    if (this.tooltip) this.tooltip.dispose();
    const { doubleClickControl } = this.state;
    if (doubleClickControl &&
      moment(doubleClickControl.date).format() === moment(date).format() &&
      doubleClickControl.resource === resource.id) {
      this.create(date, resource);
    } else {
      this.setState({ doubleClickControl: { date: moment(date), resource: resource.id } });
    }
  }

  customEventClick = ({ event }) => {
    if (this.tooltip) this.tooltip.dispose();
    this.edit(event);
  }

  customDrop = ({ resource, date, draggedEl: { id } }) => {

    // This handles the drop of outside element (pairings in this case)
    const { currentPairings } = this.state;
    const pairing = _.find(currentPairings, p => p.IdSchedulePairings === id);
    if (pairing) {
      if (!pairing.IdGame) {
        this.dropPairing(pairing, date, resource);
      } else {
        // TODO: popup the game that this is already in
        const { games } = this.props;
        const game = _.find(games, g => g.IdGame === pairing.IdGame);
        if (game) {
          this.edit(game);
        }
      }
    }
  }

  customEventMouseEnter = info => {

    if (this.tooltip) this.tooltip.dispose();

    const { extendedProps } = info.event, { HomeTeam, AwayTeam, IdGame, GameDurationMinutes/*, EventText*/ } = extendedProps;

    let html = `<div class="d-flex flex-column justify-content-start font-8">
    <span class="white block text-left">Division: ${extendedProps.division}</span>
    <span class="white block text-left">Flight: ${extendedProps.FlightName}</span>
    <span class="white block text-left">Date: ${moment(info.event.start).format('MMM Do YYYY')} @ ${moment(info.event.start).format('h:mmA')} -  ${moment(info.event.start).add(GameDurationMinutes, 'minutes').format('h:mmA')}</span>    
    <span class="white block text-left">${HomeTeam.TeamName || HomeTeam.Name || (HomeTeam.Placement || {}).GameBracketPlacementName} vs ${AwayTeam.TeamName || AwayTeam.Name || (AwayTeam.Placement || {}).GameBracketPlacementName}</span>
    <span class="white block text-left">Game ID: ${IdGame}</span>    
    </div>`

    // division, flight, dates/time, home and away
    /*this.ttt.tooltip({
      title: html,
      placement: 'bottom',      
      container: 'body',
      html: true
    })*/
    /*info.el*/
    this.tooltip = new Tooltip(this.ttt, {
      title: html,
      placement: 'bottom',
      container: 'body',
      html: true,
      trigger: 'manual'
    });
    if (this.state.tooltip_enabled)
      this.tooltip.show();
  }

  customEventDragStop = ({ event, jsEvent, view }) => {

    // This is a total HACK: Since the guys of fullcalendar don't have 'time' to implement
    // a dragOut function, the only way to do it right now is to capture de drag event coords
    // and compare it to a sensitive area (coords also), to see if it was droped 'inside' of it.
    // - or in this case, outside of the calendar itself        

    var isOverPairingsSection = function (x, y) {
      const calendar_section = document.getElementById('pairings_section').getBoundingClientRect();
      if (x >= calendar_section.left
        && y >= calendar_section.top
        && x <= calendar_section.right
        && y <= calendar_section.bottom) { return true; }
      return false;
    }

    // If the pairings section is open and the element is droped in there
    if (this.state.showingPairings && isOverPairingsSection(jsEvent.clientX, jsEvent.clientY)) {
      const game = event.extendedProps;

      // Delete straight up to prevent blink effect in the calendar
      this.props.gamesActions && this.props.gamesActions.deleteGame(game.Id || game.IdGame);

      // Call API
      this.setState({ microTransac: true }, () => {
        request.del(`${config.apiEndpoint}/api/v4/games/game/${game.IdGame || game.Id}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then(() => {
            this.setState({ microTransac: false });
          });
      });
    }
  }

  // IMPORTANT
  formatGamesForFullCalendar = games => {
    const { divisions } = this.props;

    games.forEach(game => {

      if (game.markedAsDeleted) return;
      let color = _.sample(_.values(config.palette)),
        division = _.find(divisions, (d) => {
          return parseInt(d.IdDivision, 10) === parseInt(game.IdDivision, 10);
        });

      let division_home = _.pick(game.HomeTeam, 'IdDivision', 'DivisionName', 'Color'),
        division_away = _.pick(game.AwayTeam, 'IdDivision', 'DivisionName', 'Color');

      let division_string = `${division_home.DivisionName}${division_home.IdDivision !== division_away.IdDivision ? ` vs ${division_away.DivisionName}` : ``}${game.IsFriendly ? ` (Friendly Game)` : ``}`;

      // Trying to fix the coloring issue here
      if (game.HomeTeam && !game.HomeTeam.Color) {
        game.HomeTeam.Color = (division || {}).Color || color;
        game.Home_DivisionColor = game.Away_DivisionColor = (division || {}).Color || color;
      }
      if (game.AwayTeam && !game.AwayTeam.Color) {
        game.AwayTeam.Color = (division || {}).Color || color;
        game.Home_DivisionColor = game.Away_DivisionColor = (division || {}).Color || color;
      }

      if (!game.IsFriendly) {
        game.Home_DivisionColor = game.Away_DivisionColor = (division || {}).Color || color;
      }

      //
      game = _.extend(game, {
        id: game.Id,
        resourceId: game.Location ? `${game.Location.Name || game.Location.LocationName}-${game.Location.IdLocation}` : null,
        start: moment(game.GameDate).format(),
        end: moment(game.GameDate).add(game.GameDurationMinutes, 'minutes').format(),
        title: '(' + (game.IdGame) + ') ' + [game.HomeTeam ? game.HomeTeam.Name || game.HomeTeam.TeamName : 'Unknown Home Team', game.AwayTeam ? game.AwayTeam.Name || game.AwayTeam.TeamName : 'Unknown Away Team'].join(' vs '),
        durationEditable: false,
        originalColor: (division || {}).Color || color,
        color: (division || {}).Color || color,
        description: (game && game.Location) ? '@ ' + game.Location.LocationName : '',
        division: game.IsFriendly ? division_string : (division ? (division.Name || division.DivisionName) : '?'),
        playoff: game.IsPlayoff ? true : false,
        home: game.HomeTeam,
        away: game.AwayTeam,
        friendly: (game.IsFriendly || division_home.IdDivision !== division_away.IdDivision) ? true : false,
        notes: game.Notes,
        className: calculateBWContrastClass((division || {}).Color || color),
        textColor: calculateBWContrast((division || {}).Color || color),
        extendedProps: _.pick(game, ['AwayTeam', 'AwayTeam_IdTeamDivision', 'DivisionName', 'FlightName', 'GameDate', 'GameDurationMinutes',
          'GameStartHour', 'HomeTeam', 'Id', 'IdDivision', 'IdFlight', 'IdGame', 'IdScheduleGameType', 'Location', 'LocationName', 'Notes', 'IsPlayoff'])
      });
    });

    return _.filter(games, g => g.Location.IdLocation);

  }

  create = (date, resource) => {
    const { createGame, games } = this.props;
    createGame && createGame({
      GameDate: moment(date),
      GameStartHour: moment(date),
      HomeTeam: {},
      AwayTeam: {},
      IdScheduleGameType: 1, // TODO: what is this number ?
      Location: {
        IdLocation: parseInt(_.last(resource.id.split('-')), 10),
        LocationName: resource.title
      },
      LocationName: resource.title,
      GameDurationMinutes: games.length ? _.last(games).GameDurationMinutes : 90
    });
  }

  createFriendly = (date) => {
    const { createFriendlyGame, games } = this.props;
    createFriendlyGame && createFriendlyGame({
      GameDate: moment(date),
      GameStartHour: moment(date).hour(9),
      DontUseForStandings: true, // starts with this turned off
      HomeTeam: {},
      AwayTeam: {},
      IdScheduleGameType: 1, // TODO: what is this number ?
      Location: {},
      //LocationName: resource.title,
      GameDurationMinutes: games.length ? _.last(games).GameDurationMinutes : 90
    });
  }

  edit = ({ extendedProps }) => {

    if (extendedProps.IsFriendly) {
      this.props.selectFriendly &&
        this.props.selectFriendly(extendedProps);
    } else {
      const { divisions } = this.props;
      const division = _.find(divisions, d => parseInt(d.IdDivision, 10) === parseInt(extendedProps.IdDivision, 10));
      if (division) {
        const flight = _.find(division.Flights, f => f.IdFlight === extendedProps.IdFlight);
        if (flight) {
          this.props.selectGame &&
            this.props.selectGame(extendedProps, flight);
        } else {
          this.props.selectGame &&
            this.props.selectGame(extendedProps);
        }
      } else {
        alert('Division not found');
      }
    }
  }

  createCalendarEventsArray = (props) => {
    const { filters, games = [] } = this.state,
      { game_type } = filters,
      { divisions, } = props;

    const checkedTeams = _.chain(divisions)
      .reject(d => d.checked)
      .map(division => {
        return _.chain(division.Flights).map(f => {
          return f.FlightedTeams;
        }).flatten().value();
      })
      .flatten()
      .filter((team) => {
        return team.checked;
      })
      .map((team) => {
        return parseInt(team.IdTeamDivision, 10);
      }).value();

    const checkedDivisions = _.chain(divisions)
      .filter(d => d.checked).map(d => d.IdDivision).value();

    //----------- Apply filters    
    const ret = _.chain([...games])
      .each((event) => {

        event.title = `${event.GameStartHour.format('H:mm')} . ${event.GameStartHour.add(event.GameDurationMinutes, 'minutes').format('H:mm')} - ID: ${event.IdGame}\n${(event.HomeTeam || {}).TeamName} vs ${(event.AwayTeam || {}).TeamName}`;
        event.color = event.originalColor;
        event.className = 'active';

        if (game_type.poolplay && event.extendedProps.IsPlayoff) {
          event.color = 'gray';
          event.className = 'inactive';
          return;
        }

        if (game_type.playoffs && !event.extendedProps.IsPlayoff) {
          event.color = 'gray';
          event.className = 'inactive';
          return;
        }

        if (checkedDivisions.length && !_.includes(checkedDivisions, parseInt(event.IdDivision, 10))) {
          event.color = 'gray';
          event.className = 'inactive';
          return;
        }

        if (checkedTeams.length &&
          !_.includes(checkedTeams, parseInt(event.extendedProps.HomeTeam.IdTeamDivision, 10)) &&
          !_.includes(checkedTeams, parseInt(event.extendedProps.AwayTeam.IdTeamDivision, 10))
        ) {
          event.color = 'gray';
          event.className = 'inactive';
          return;
        }

      })
      .value();
    //-------      

    return ret;
  }

  // Pairings
  updateLocalPairings = (currentPairings) => this.setState({ currentPairings });

  dropPairing = (pairing, date, location) => {
    const { defaultGameDuration = 30 } = this.props;

    // Create the game already
    const payload = {
      IdSchedulePairings: pairing.IdSchedulePairings,
      IdDivision: pairing.flight.IdDivision,
      IdLocation: parseInt(_.last(location.id.split('-')), 10),
      DateStart: moment(date).format(),
      DateEnd: moment(_.take((date + '').split(' '), 5).join(' ')).add(defaultGameDuration, 'minutes').format(),
      RoundNumber: pairing.Round,
      IdTeamDivisionHome: pairing.Home_IdTeamDivision,
      IdTeamDivisionAway: pairing.Away_IdTeamDivision
    };

    this.setState({ microTransac: true }, () => {
      request.post(`${config.apiEndpoint}/api/v4/pairings/game`)
        .send(payload)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then(data => {
          if (data.body.success) {

            // Update redux store
            this.props.gamesActions && this.props.gamesActions.addGame({
              GameDate: moment(date).format('YYYY-MM-DD'),
              GameStartHour: moment(date).format('HHmm'),
              IdGame: data.body.IdGame,
              Id: data.body.Id,
              IdScheduleFlight: data.body.IdScheduleFlight,
              HomeTeam: {
                TeamName: pairing.Home_Team,
                IdTeamDivision: pairing.Home_IdTeamDivision,
                IdTeam: pairing.Home_IdTeam
              },
              AwayTeam: {
                TeamName: pairing.Away_Team,
                IdTeamDivision: pairing.Away_IdTeamDivision,
                IdTeam: pairing.Away_IdTeam
              },
              IdScheduleGameType: 1, // TODO: what is this number ?
              Location: {
                IdLocation: parseInt(_.last(location.id.split('-')), 10),
                LocationName: location.title
              },
              IdDivision: pairing.flight.IdDivision,
              IdFlight: pairing.flight.IdFlight,
              LocationName: location.title,
              GameDurationMinutes: defaultGameDuration
            });

            this.setState({ microTransac: false });
          }
        });
    });
  }
  //

  // Filters
  changeTab = activeTab => this.setState({ activeTab });
  changeCalendarTimeGap = (timeGap, timeGapInteger) => this.setState({ timeGap, timeGapInteger });
  changeDefaultGameDuration = (defaultGameDuration) => this.setState({ defaultGameDuration }, () => {
    this.props.setDefaultGameDuration && this.props.setDefaultGameDuration(defaultGameDuration);
  });
  toggleResource = (id) => {
    const { resources } = this.state;
    this.tooltip && this.tooltip.dispose();
    _.find(resources, function (r) {
      return r.id === id;
    }).checked = !_.find(resources, function (r) {
      return r.id === id;
    }).checked;
    this.setState({ resources });

  }
  toggleFilters = () => {
    const { filters } = this.state;
    this.tooltip && this.tooltip.dispose();
    filters.toggled = !filters.toggled;
    this.setState({ filters });
  }
  toggleGameType = (i) => {
    const { filters, calendarObject } = this.state,
      { game_type } = filters;
    this.tooltip && this.tooltip.dispose();

    game_type.all = false;
    game_type.poolplay = false;
    game_type.playoffs = false;
    switch (i) {
      case 1:
        game_type.all = true;
        break;
      case 2:
        game_type.poolplay = true;
        break;
      case 3:
        game_type.playoffs = true;
        break;
      default:
        break;
    }
    this.setState({ filters, calendarObject });
  }
  //

  // Calendar controls
  today = () => {
    this.calendarComponentRef.current.getApi().today();
    this.setState({ defaultDate: moment() });
    this.tooltip && this.tooltip.dispose();
  }
  nextDate = () => {
    const { mode, defaultDate } = this.state;
    this.calendarComponentRef.current.getApi().next();
    defaultDate.add(1, mode === 'dayGridMonth' ? 'month' : 'day');
    this.setState({ defaultDate });
    this.tooltip && this.tooltip.dispose();
  }
  prevDate = () => {
    const { mode, defaultDate } = this.state;
    this.calendarComponentRef.current.getApi().prev();
    defaultDate.add(-1, mode === 'dayGridMonth' ? 'month' : 'day');
    this.setState({ defaultDate });
    this.tooltip && this.tooltip.dispose();
  }
  handleChangeDate = date => {
    this.calendarComponentRef.current.getApi().gotoDate(date.format());
    this.setState({ defaultDate: date });
    this.tooltip && this.tooltip.dispose();
  }
  toggleShowPairings = () => this.setState({ showingPairings: !this.state.showingPairings });
  //

  // Zoom slider handlers
  handleChangeZoom = newValue => {
    const { zoomFactor } = this.state;
    this.tooltip && this.tooltip.dispose();
    if (newValue !== zoomFactor) this.setState({ zoomFactor: newValue });
  };
  //

  showGames = () => {
    const { season } = this.props;
    request.patch(`${config.apiEndpoint}/api/v4/games/hide/${season.IdSeason}`)
      .send({ hide: false })
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then((data) => { this.setState({ hiddenGames: false }); });
  }
  hideGames = () => {
    const { season } = this.props;
    request.patch(`${config.apiEndpoint}/api/v4/games/hide/${season.IdSeason}`)
      .send({ hide: true })
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then((data) => { this.setState({ hiddenGames: true }); });
  }

  // TODO: check if this is needed at all
  notify = () => this.forceUpdate();

  enableTT = () => {
    this.setState({
      tooltip_enabled: !this.state.tooltip_enabled
    }, () => {
      this.tooltip && this.tooltip.dispose();
    })
  }

  renderFiltersTop(highlightWithRanges, defaultDate, games, hiddenGames, game_type, zoomFactor) {
    return <div className="d-flex flex-row no-margin pt-2 pl-0 pr-0 pb-0 idented " style={{ zIndex: 10 }}>

      {/* LEFT */}
      <Animated animationIn="fadeInLeft" className="filters-wrapper d-flex flex-row ml-2">
        <DatePicker highlightDates={highlightWithRanges} customInput={<CustomDatePicker />} selected={defaultDate} showYearDropdown scrollableYearDropdown
          yearDropdownItemNumber={15} onChange={this.handleChangeDate} className="white align-self-center font-12 underlined" />
        {/*<button className={`btn-default btn btn-sm white margin-left`} onClick={this.prevDate}><i className="fa fa-arrow-left" /></button>
        <button className={`btn-default btn btn-sm white`} onClick={this.today}>Today</button>
        <button className={`btn-default btn btn-sm white`} onClick={this.nextDate}><i className="fa fa-arrow-right" /></button>*/}
        <button className={`btn-default btn btn-sm blue`}>({_.filter(games, g => g.GameDate.format('YYYYMMDD') === defaultDate.format('YYYYMMDD')).length} Games)</button>
        <button onClick={this.enableTT} ref={i => this.ttt = i} className="btn-sm btn btn-link white"><i className={`far fa-comment-alt ${this.state.tooltip_enabled ? 'green' : 'white'}`} /></button>
      </Animated>

      {/* RIGHT */}
      <Animated animationIn="fadeInRight" className="ml-auto d-flex flex-row-reverse no-padding mr-2 ">

        {/* SOME ACTIONS */}
        <button className="btn btn-sm btn-danger margin-right-half" onClick={() => this.props.deleteGames(this.state.defaultDate)}><i className="fa fa-trash" /></button>
        <button className="btn btn-sm btn-success margin-right-half" onClick={this.createFriendly}><i className="fas fa-plus" />Friendly</button>
        <button className="btn btn-sm btn-info margin-right-half" onClick={() => this.props.adjustGames(this.state.defaultDate)}><i className="fa fa-cogs" /></button>
        <button className={`btn btn-sm white ${hiddenGames ? 'btn-link' : 'btn-info'} margin-right-half`} onClick={hiddenGames ? this.showGames : this.hideGames}><i className={`fa fa-${hiddenGames ? 'eye-slash' : 'thumbs-up'}`}></i> {hiddenGames ? ' Hidden' : ' Published'}</button>

        <button className={`btn btn-sm btn-default d-flex flex-row white`} onClick={() => this.toggleGameType(3)}>
          <i className={`fa-${game_type.playoffs ? 'check-circle green fas' : 'circle far'} align-self-center`} />
          <span className="ml-1 align-self-center">Playoffs</span></button>
        <button className={`btn btn-sm btn-default d-flex flex-row white`} onClick={() => this.toggleGameType(2)}>
          <i className={`fa-${game_type.poolplay ? 'check-circle green fas' : 'circle far'} align-self-center`} />
          <span className="ml-1 align-self-center">Poolplay</span></button>
        <button className={`btn btn-sm btn-default d-flex flex-row white`} onClick={() => this.toggleGameType(1)}>
          <i className={`fa-${game_type.all ? 'check-circle green fas' : 'circle far'} align-self-center`} />
          <span className="ml-1 align-self-center">All</span></button>
        <div className="slider-horizontal margin-right-half" style={{ width: 100 }}>
          <Slider min={1} max={100} value={zoomFactor} onChange={this.handleChangeZoom}></Slider>
        </div>
      </Animated>

    </div>;
  }

  render() {

    const { resources, mode, activeTab, filters, defaultDate, timeGap, timeGapInteger,
      showingPairings, havePairings, games, microTransac, zoomFactor, hiddenGames } = this.state,
      { divisions, flights, defaultGameDuration } = this.props,
      { game_type } = filters,
      resourcesChecked = _.filter(resources, r => r.checked).length,
      minWidth = (resourcesChecked > 4 && mode === 'resourceTimeGridDay') ? resourcesChecked * (timeGapInteger > 30 ? 180 : 180) : 600;
    //                                                ^ min with of every field in the resourceTimeGridDay mode

    const highlightWithRanges = [{ "highlighted-date": _.map(games, 'GameDate') }];

    return (
      <div id="full-calendar" className="container-fluid no-margin no-padding">
        {microTransac && <LineLoader />}
        
        {/* Top filters */}
        {this.renderFiltersTop(highlightWithRanges, defaultDate, games, hiddenGames, game_type, zoomFactor)}

        <div className="no-margin no-padding margin-top-half">

          {/* Pairings */}
          <div style={{ height: '12em', position: 'relative', overflowY: 'auto' }} className="bg-info" id="pairings_section">
            {showingPairings && <Pairings divisions={divisions} flights={flights} games={games}
              update={this.updateLocalPairings} defaultGameDuration={defaultGameDuration} setDefaultGameDuration={this.props.setDefaultGameDuration} />}
            <i className={`fas fa-angle-${showingPairings ? 'up' : 'down'} white`} style={{ position: 'absolute', top: 10, left: '2em' }}
              onClick={this.toggleShowPairings} />
            <Animated animationIn="fadeInLeft" animationInDelay={500} animationOut="fadeOutLeftBig" isVisible={!showingPairings} style={{ position: 'absolute', left: '3.5em', top: 2, right: 0 }} className="d-flex flex-row justify-content-center">
              <span className="font-16 white" onClick={this.toggleShowPairings}>PAIRINGS</span>
            </Animated>
          </div>

          { /* Calendar filters (Left Side) */}
          <div style={{ top: !havePairings ? '3em' : (showingPairings ? '15em' : '5em') }} id="calendar-filters" className={`${filters.toggled ? 'on' : ''} no-margin no-padding`}>
            <ul className="nav nav-tabs font-14">
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}><i className="fas fa-shield-alt blue"></i> {activeTab === 10 ? <span className="idented-half">Teams</span> : ''}</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}><i className="fa fa-bullhorn purple"></i> {activeTab === 20 ? <span className="idented-half">Coaches</span> : ''}</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}><i className="fa fa-map-marker green"></i> {activeTab === 30 ? <span className="idented-half">Locations</span> : ''}</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={() => this.changeTab(4)}><i className="fa fa-cog green"></i> {activeTab === 40 ? <span className="idented-half">Others</span> : ''}</button>
              </li>
            </ul>
            <div className="tab-content">
              {activeTab === 1 && <DivisionsFilter divisions={this.props.divisions} games={this.props.games} notify={this.notify} key="divisions" />}
              {activeTab === 2 && <CoachesFilter coaches={this.props.coaches} divisions={this.props.divisions} notify={this.notify} key="coaches" />}
              {activeTab === 3 && <LocationsFilters resources={resources} toggleResource={this.toggleResource} key="locations" />}
              {activeTab === 4 && (
                <div key="others">
                  <div className="d-flex flex-row bg-info font-10">
                    <span className="align-self-center idented-half">Calendar Settings</span>
                  </div>
                  <div className="d-flex flex-column margin-top-half">
                    <span className="align-self-center idented-half">Time Gaps (mins)</span>
                    <div className="btn-group btn-group-toggle align-self-center" data-toggle="buttons">
                      <button onClick={() => this.changeCalendarTimeGap('05', 5)} className={`btn btn-secondary btn-sm ${timeGap === '05' ? 'active' : ''}`}>5</button>
                      <button onClick={() => this.changeCalendarTimeGap('10', 10)} className={`btn btn-secondary btn-sm ${timeGap === '10' ? 'active' : ''}`}>10</button>
                      <button onClick={() => this.changeCalendarTimeGap('15', 15)} className={`btn btn-secondary btn-sm ${timeGap === '15' ? 'active' : ''}`}>15</button>
                      <button onClick={() => this.changeCalendarTimeGap('20', 20)} className={`btn btn-secondary btn-sm ${timeGap === '20' ? 'active' : ''}`}>20</button>
                      <button onClick={() => this.changeCalendarTimeGap('30', 30)} className={`btn btn-secondary btn-sm ${timeGap === '30' ? 'active' : ''}`}>30</button>
                      <button onClick={() => this.changeCalendarTimeGap('45', 45)} className={`btn btn-secondary btn-sm ${timeGap === '45' ? 'active' : ''}`}>45</button>
                      <button onClick={() => this.changeCalendarTimeGap('60', 60)} className={`btn btn-secondary btn-sm ${timeGap === '60' ? 'active' : ''}`}>60</button>
                    </div>
                  </div>
                  <div className="d-flex flex-column margin-top-half">
                    <span className="align-self-center idented-half">Default Game Duration</span>
                    <div className="btn-group btn-group-toggle align-self-center" data-toggle="buttons">
                      <button onClick={() => this.changeDefaultGameDuration(15)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 15 ? 'active' : ''}`}>15</button>
                      <button onClick={() => this.changeDefaultGameDuration(20)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 20 ? 'active' : ''}`}>20</button>
                      <button onClick={() => this.changeDefaultGameDuration(30)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 30 ? 'active' : ''}`}>30</button>
                      <button onClick={() => this.changeDefaultGameDuration(60)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 60 ? 'active' : ''}`}>60</button>
                      <button onClick={() => this.changeDefaultGameDuration(90)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 90 ? 'active' : ''}`}>90</button>
                    </div>
                  </div>
                </div>)}
            </div>
          </div>

          {/* Calendar itself (Right side) */}
          <div style={{ bottom: 0, top: !havePairings ? '3em' : (showingPairings ? '15em' : '5em') }} id="calendar-wrapper" className="on bg-white">
            { /* The calendar renders here with JQuery   */}
            <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, minWidth: minWidth * (zoomFactor / 100) + 'px' }}>
              <FullCalendar
                schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                defaultView={mode}
                droppable={true}
                height={"parent"}
                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin]}
                ref={this.calendarComponentRef}
                slotDuration={`00:${timeGap}:00`}
                allDaySlot={false}
                defaultDate={defaultDate.format()}
                header={{ left: 'title', right: '' }}
                minTime="05:00"
                editable={true}
                selectable={true}
                eventLimit={true}
                resources={(fetchInfo, successCallback, failureCallback) => { successCallback(_.chain(resources).filter(r => r.checked).sortBy('title').value()) }}
                events={(info, successCallback, failureCallback) => { successCallback(this.createCalendarEventsArray(this.props)) }}
                dateClick={this.customDayClick}
                drop={this.customDrop}
                eventRender={this.customEventRender}
                eventDrop={this.customEventDrop}
                eventClick={this.customEventClick}
                eventDragStop={this.customEventDragStop}
                eventMouseEnter={this.customEventMouseEnter}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }

  
}
/*
schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      defaultView: mode,
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin],
      droppable: true,
      defaultDate: defaultDate.format(),
      editable: true,
      selectable: true,
      height: 'parent', // () => { return 300 },
      slotDuration: (mode === 'dayGridMonth' ? '00:60:00' : ['00:', timeGap, ':00'].join('')),
      minTime: '05:00', // IDEA: I can determine which is the very min time in all games and place it here! 
      eventLimit: true, // WHAT's THIS: allow "more" link when too many events
      eventDurationEditable: false,
      allDaySlot: true,
      header: { left: 'title', right: '' },
      */

export default FullCalendarWrapper;
