import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import _ from 'lodash';

export function* getWaitlistsSaga() {
  yield* takeEvery("WAITLISTS/FETCH", function* (action) {

    try {
      const result = yield call(({ idDivision }) => {
        return request.get(`${config.apiEndpoint}/api/v5/waitlists/players/${idDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { waitlists, accepted } = result;
        yield put({
          type: 'WAITLISTS/SET',
          waitlists
        });
        yield put({
          type: 'ACCEPTED/SET',
          accepted
        });
      } else {
        yield put({
          type: 'WAITLISTS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'WAITLISTS/FETCH_FAILED',
        e
      });
    }
  });
}

//WAITLISTS/TEAMS/FETCH
export function* getWaitlistsTeamsSaga() {
  yield* takeEvery("WAITLISTS/TEAMS/FETCH", function* (action) {

    try {
      const result = yield call(({ idDivision }) => {
        return request.get(`${config.apiEndpoint}/api/v5/waitlists/teams/${idDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { waitlists, accepted } = result;
        yield put({
          type: 'WAITLISTS/SET',
          waitlists
        });
        yield put({
          type: 'ACCEPTED/SET',
          accepted
        });
      } else {
        yield put({
          type: 'WAITLISTS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'WAITLISTS/FETCH_FAILED',
        e
      });
    }
  });
}

//TRYOUTS/ACCEPT
export function* acceptWaitlistsSaga() {
  yield* takeEvery("WAITLISTS/ACCEPT", function* (action) {

    try {
      yield call(({ item }) => {
        return request.post(`${config.apiEndpoint}/api/v4/waitlists/accept/${item.IdOrderItem}/${item.IdTeamUserRole}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'WAITLIST/ACCEPT', item: _.extend(action.item, { RoleStatus: 'Pending' }) });
    } catch (e) {
      yield put({
        type: 'WAITLISTS/ACCEPT_FAILED',
        e
      });
    }
  });
}

//TRYOUTS/ACCEPT
export function* acceptWaitlistsTeamsSaga() {
  yield* takeEvery("WAITLISTS/ACCEPT/TEAM", function* (action) {

    try {
      yield call(({ item }) => {
        return request.post(`${config.apiEndpoint}/api/v5/waitlists/accept/team/${item.IdOrderItem}/${item.IdTeamDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'WAITLISTS/TEAMS/FETCH', idDivision: action.idDivision });      
    } catch (e) {
      yield put({
        type: 'WAITLISTS/ACCEPT_FAILED',
        e
      });
    }
  });
}

//WAITLISTS/RETURN
export function* returnToWaitlistsSaga() {
  yield* takeEvery("WAITLISTS/RETURN", function* (action) {
    try {
      yield call(({ item, idDivision }) => {
        return request.del(`${config.apiEndpoint}/api/v5/waitlists/remove/player/${item.IdOrderItem}/${item.IdTeamUserRole}/${idDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);
      yield put({ type: 'WAITLIST/RETURN', index: action.index });
    } catch (e) {
      yield put({
        type: 'WAITLISTS/RETURN_FAILED',
        e
      });
    }
  });
}

//WAITLISTS/TEAM/RETURN
export function* returnTeamToWaitlistsSaga() {
  yield* takeEvery("WAITLISTS/TEAM/RETURN", function* (action) {
    try {
      yield call(({ item }) => {
        return request.del(`${config.apiEndpoint}/api/v5/waitlists/remove/team/${item.IdOrderItem}/${item.IdTeamDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);      
      yield put({ type: 'WAITLISTS/TEAMS/FETCH', idDivision: action.idDivision });      
    } catch (e) {
      yield put({
        type: 'WAITLISTS/RETURN_FAILED',
        e
      });
    }
  });
}