
function moveElementInArray(arr, from, to, ) {
    var element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
    return arr;
}
function flights(state = [], action) {
    // call saga to fetch the leagues from API ?
    
    switch (action.type) {        
        case 'FLIGHTS/SET':                    
            return action.flights;
        case 'FLIGHTS/CLEAR':
            return [];
        case 'FLIGHTS/SORT':
            const { from, to } = action;
            return moveElementInArray([...state], from, to, [...state][from]);
        default:
            return state;
    }
}

export default flights;