import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import LeftWorkArea from './Partials/left';
import RightWorkArea from './Partials/right';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Loader from '../../../common/Loader';
import './css.css';

class LockRoster extends React.Component {

  state = {
    bootstrapped: false,
    creating: false,
    wizzard: false,
    selectedGroup: null,
    activeTab: 2
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params, season } = this.props;
    setTitle && setTitle({
      sub: 'Lock | Limit Rosters',
      main: season ? season.Name : null
    });
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
    this.props.locksActions && this.props.locksActions.fetchLocks(params.idSeason);
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Lock | Limit Rosters',
        main: nextProps.season.Name || null
      });

    }
    if (nextProps.divisions) {
      this.setState({ bootstrapped: true });
    }
  }
  //

  toggleCreating = () => this.setState({ creating: !this.state.creating });

  addLock = () => this.props.locksActions.addLock({ editing: true, idSeason: this.props.params.idSeason });

  render() {
    const { divisions } = this.props,
      { bootstrapped } = this.state;

    let filteredDivisions = divisions; // _.reject(divisions, d => d.IsTryout);

    return <section id="lock-rosters" className="p-2">
      <div className="filters-wrapper d-flex flex-row">
        <ReactCSSTransitionGroup className="leagues-actions d-flex flex-row-reverse ml-auto" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
          transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
          <button onClick={this.addLock} key="btnGroups" type='button' className="btn btn-success btn-sm">
            <i className="fa fa-plus margin-right-half" />Add Lock Schedule</button>
        </ReactCSSTransitionGroup>
      </div>
      <ReactCSSTransitionGroup className="work-area container-fluid" transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
        transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
        {filteredDivisions && filteredDivisions.length !== 0 && bootstrapped && (
          <div key="with-data" className="row d-flex align-items-stretch full-height">
            <div className="col-6 no-margin no-padding" style={{ borderRight: '5px solid #002549' }}>
              <LeftWorkArea {...this.props} />
            </div>
            <div className="col-6 no-margin no-padding">
              <ReactCSSTransitionGroup className="tab-content" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
                transitionEnter={true} transitionLeave={false}>
                <RightWorkArea {...this.props} />
              </ReactCSSTransitionGroup>
            </div>
          </div>
        )}
        {!bootstrapped && <Loader key="loader" message={`Loading Assets, please wait...`} />}
      </ReactCSSTransitionGroup>
    </section>
  }
}

export default DragDropContext(HTML5Backend)(LockRoster);
