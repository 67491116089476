

import React from 'react';
import moment from 'moment';

class CustomDatePicker2 extends React.Component {

    render() {
        const { value, className } = this.props;
        return (
            <button onClick={this.props.onClick} className={`d-flex flex-row justify-content-center p-2 ${className}`} style={{ width: 115 }}>
                <i className="far fa-calendar-alt font-14 align-self-center" />
                <span className="font-8 align-self-center ml-2 ">{value ? moment(this.props.value).format('MMM Do') : 'Select'} {value ? moment(this.props.value).format('YYYY') : 'Date'}</span>                
            </button>
        )
    }
}

export default CustomDatePicker2;
