import React from 'react';

const Team = ({ selectedTeam }) => {
    return (
        <div className="d-flex flex-row justify-content-start montserrat" style={{ height: 60 }}>
            <div style={{ width: 80 }} className="d-flex flex-row justify-content-center align-self-center">
                <div className="cover" style={{ width: 40, height: 40,  background: `url(${selectedTeam.TeamImageUrl || selectedTeam.ImageUrl || selectedTeam.TeamLogo}?full=80) no-repeat center center` }} />
            </div>
            <div className="d-flex flex-column align-self-center">
                <span className="black font-12 line1">{selectedTeam.TeamName}</span>                
            </div>
        </div>
    );
}

export default Team;
