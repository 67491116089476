import React from 'react';
import CoachListItem from './CoachListItem';
import _ from 'lodash';

class CoachesFilter extends React.Component {

    checkAll = () => {
        const { coaches, divisions } = this.props;
        _.values(coaches).forEach(coach => coach.checked = true);
        _.chain(divisions).map((division) => {
            return _.chain(division.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value();
        }).flatten().value().forEach((team) => {
            team.checked = true;
        });
        this.props.notify && this.props.notify();
    }

    uncheckAll = () => {
        const { coaches, divisions } = this.props;
        _.values(coaches).forEach(coach => coach.checked = false);
        _.chain(divisions).map((division) => {
            return _.chain(division.Flights).map((f) => {
                return f.FlightedTeams;
            }).flatten().value();
        }).flatten().value().forEach((team) => {
            team.checked = false;
        });
        this.props.notify && this.props.notify();
    }

    render() {
        const { coaches } = this.props;
        return (<div key="coaches" className="scrollable" style={{ top: '55px' }}>
            <div className="container-fluid">
                <div className="row">
                    <button className={`btn btn-sm btn-info d-flex flex-row col-6 justify-content-center`} onClick={this.checkAll}>
                        <i className={`fa fa-check-circle align-self-center`} />
                        <span className="margin-left align-self-center">Check all</span></button>
                    <button className={`btn btn-sm btn-info d-flex flex-row col-6 justify-content-center`} onClick={this.uncheckAll}>
                        <i className={`far fa-circle align-self-center`} />
                        <span className="margin-left align-self-center">Uncheck all</span></button>
                </div>
            </div>
            <ul className=" no-style-lists no-margin no-padding ">
                {coaches && coaches.map((coach, i) => <CoachListItem key={i} coach={coach} {...this.props} />)}
            </ul>
        </div>);
    }
}

export default CoachesFilter;
