import React from 'react';
import './menu.css';
import { Link } from 'react-router';
import fakeAuth from '../../fakeAuth';
import config from '../../config';

class LeftMenu extends React.Component {

  renderButton = (key) => {
    const path = this.props.paths[key], { params } = this.props;

    if (path.condition && !params[path.condition]) {
      return <div key={key} />
    }

    if (path.adminOnly) {
      const user = fakeAuth.getUser();
      if (config.adminUsers.indexOf(user.IdUser) === -1) return <div key={key} />
    }

    return (
      <Link key={key} to={`/${key}`} className="btn btn-off btn-block" activeClassName="btn-on">
        <i className={`fa fa-${path.icon} block big-font`} />
        <span className="block font-8 toUpper margin-top">{path.name}</span>
      </Link>
    );
  }

  render() {
    const { paths, params } = this.props;
    return (
      <section id="left-menu">
        {Object.keys(paths)
          .map(this.renderButton)}
        {params.idLeague && <Link to={`/message_center/${params.idLeague}`} className="btn btn-off btn-block" activeClassName="btn-on">
          <i className="fa fa-comments block big-font" />
          <span className="block font-8 toUpper margin-top" >Message Center</span>
        </Link>}
        {params.idLeague && <Link to={`/rankings/${params.idLeague}`} className="btn btn-off btn-block" activeClassName="btn-on">
          <i className="fa fa-medal block big-font" />
          <span className="block font-8 toUpper margin-top" >Rankings</span>
        </Link>}
        {params.idLeague && <Link to={`/coupons/${params.idLeague}`} className="btn btn-off btn-block" activeClassName="btn-on">
          <i className="fas fa-ticket-alt block big-font" />
          <span className="block font-8 toUpper margin-top" >Coupons</span>
        </Link>}
        {params.idLeague && <Link to={`/dob/${params.idLeague}/A`} className="btn btn-off btn-block" activeClassName="btn-on">
          <i className="fas fa-birthday-cake block big-font" />
          <span className="block font-8 toUpper margin-top" >DOB Validation</span>
        </Link>}
        {params.idLeague && <Link to={`/pcv/${params.idLeague}/A`} className="btn btn-off btn-block" activeClassName="btn-on">
          <i className="fas fa-address-card block big-font" />
          <span className="block font-8 toUpper margin-top" >Player Cards Validation</span>
        </Link>}
      </section>
    )
  }
}

export default LeftMenu;