export function fetchExceptionRules(idSeason){
    return {
        type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/FETCH',
        idSeason
    }
}

export function addTimeExceptionRule(rule){
    return {
        type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/TIME/ADD',
        rule
    }
}

export function updateExceptionRule(index, rule){
    return {
        type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/UPDATE',
        index, rule
    }
}

export function deleteExceptionRule(index){
    return {
        type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/DELETE',
        index
    }
}

export function associateDateWithTeam(idRule, idTeam){
    return {
        type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/DATE/ASSOCIATE_WITH_TEAM',
        idRule, idTeam
    }
}

export function removeAssociation(idRuleAssociation){
    return {
        type: 'SCHEDULE_MANAGER/EXCEPTION_RULES/REMOVE_ASSOCIATION_FROM_TEAM', 
        idRuleAssociation
    }
}

