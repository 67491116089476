import React from 'react';
import _ from 'lodash';

class Player extends React.Component {
    state = {
        player: null
    }
    constructor(props) {
        super(props);
        this.handleChangePosition = this.handleChangePosition.bind(this);
        this.handleChangeJersey = this.handleChangeJersey.bind(this);
    }

    componentWillMount() {
        const { player } = this.props;
        this.setState({ player });
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.editing !== this.props.editing && this.props.editing) {
            const { player } = this.state, { roster_actions } = this.props;
            player.Position = this.txtPosition ? this.txtPosition.value : player.Position;
            this.setState({ player }, () => {
                roster_actions && roster_actions.updatePositionAndJersey({
                    idTeamUserRole: player.IdTeamUserRole,
                    position: player.Position,
                    jerseyNumber: player.JerseyNumber
                });
            });
        }
    }
    handleChangePosition(event) {
        const { player } = this.state;
        player.Position = event.target.value;
        this.setState({ player });
    }
    handleChangeJersey(event) {
        const { player } = this.state;
        player.JerseyNumber = event.target.value;
        this.setState({ player });
    }

    render() {

        const { select, onClick, selectedTeam = {} } = this.props;
        const { player } = this.state;        
        
        if (!player.IdUser) return <div className="w-100" />        
        let s = _.find(player.sports, s => s.IdCommunity === selectedTeam.IdCommunity) || {};
        return (

            <figure onClick={onClick} className="card w3-card m-2 d-flex flex-column h-auto w-100 bg-dark-blue" style={{ border: '1px solid black', position: 'relative' }}>

                <div className="mx-auto mt-4 cover" style={{
                    border: '1px solid white', width: 200, height: 200,
                    borderRadius: 100, background: `url(${player.Image || player.ImageUrl}) no-repeat center center black`
                }} />
                <span className="text-center white font-16 montserrat">{player.NameFirst}</span>
                <span className="text-center white font-12 montserrat-bold mb-4">{player.NameLast}</span>

                <div className="mx-2 mb-2 bg-white" style={{ borderRadius: 4 }}>
                    <div className="px-3 py-2" style={{ borderBottom: '1px solid gray' }}>
                        <span className="gray font-14 toBold">{s.CommunityName || s.Community || selectedTeam.Community}</span>
                    </div>
                    <div className="d-flex flex-row px-3 pt-3">
                        <div className=" w-100">
                            <div className="d-flex flex-row">
                                <div className="w-100">
                                    <div className='w-100 d-flex flex-column align-self-center'>
                                        <span className="gray font-8">Primary Position</span>
                                        <span className="gray font-12 line1">{s.Position1 || '.'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row pt-3">
                                <div className="w-100">
                                    <div className='w-100 d-flex flex-column align-self-center'>
                                        <span className="gray font-8">Secondary Position</span>
                                        <span className="gray font-12 line1">{s.Position2 || '.'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" w-25" style={{ position: 'relative' }}>
                            <div className='w-100 d-flex flex-column align-self-center justify-content-center h-100' style={{ position: 'relative' }}>
                                <i className="icon-apparel font-60 align-self-center blue" />
                            </div>
                            <div className='w-100 d-flex flex-column align-self-center justify-content-center h-100' style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                                <span className="align-self-center white montserrat font-14">{s.JerseyNumber}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row px-3 pt-3" />
                </div>

                <div className="mx-2 mb-2 bg-white" style={{ borderRadius: 4 }}>
                    <div className="d-flex flex-row px-3 pt-3">
                        <div className=" w-100">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">Graduation Year</span>
                                <span className="gray font-12 line1">{player.GraduationYear || '.'}</span>
                            </div>
                        </div>
                        <div className=" w-50">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">GPA</span>
                                <span className="gray font-12 line1">{player.GPA || '.'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row px-3 pt-3">
                        <div className=" w-100">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">School Name</span>
                                <span className="gray font-10 line1">{player.SchoolName || '.'}</span>
                            </div>
                        </div>
                        <div className=" w-50">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">SAT</span>
                                <span className="gray font-12 line1">{player.SAT || '.'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-row px-3 pt-3">
                        <div className=" w-100">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">Club Name</span>
                                <span className="gray font-12 line1">{player.ClubName || '.'}</span>
                            </div>
                        </div>
                        <div className=" w-50">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">ACT</span>
                                <span className="gray font-12 line1">{player.ACT || '.'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-row px-3 pt-3">
                        <div className=" w-100">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">Nationality 1</span>
                                <div className='contains' style={{ width: 150, height: 20, background: `url(${player.Nationality1_flag}) no-repeat` }} />
                            </div>
                        </div>
                        <div className=" w-100">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">Nationality 2</span>
                                <div className='contains' style={{ width: 150, height: 20, background: `url(${player.Nationality2_flag}) no-repeat` }} />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-row px-3 pt-3">
                        <div className="w-100">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">Email</span>
                                <span className="gray font-12 line1">{player.EmailAddress}</span>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-row px-3 pt-3">
                        <div className="w-100">
                            <div className='w-100 d-flex flex-column align-self-center'>
                                <span className="gray font-8">Phone</span>
                                <span className="gray font-12 line1">{player.FullPhoneNumber}</span>
                            </div>
                        </div>
                    </div>


                    <div className="d-flex flex-row px-3 pt-3" />
                </div>


                {player.Notes && false && <div className="mx-2 mb-2 bg-white p-3" style={{ borderRadius: 4 }}>
                    <div className='w-100 d-flex flex-column align-self-center'>
                        <span className="gray font-8">Notes</span>
                        <span className="gray font-12 line1">{player.Notes}</span>
                    </div>
                </div>}

                <div className="mx-2 mb-2 bg-white p-3 d-flex flex-row" style={{ borderRadius: 4 }}>
                    <div className='w-75 d-flex flex-column align-self-center'>
                        <span className="gray font-8">{player.IsLookingForTransfer ? 'Looking for transfer' : 'Commited'}</span>
                        <span className="gray font-12 line1">{player.CommitedTo || '.'}</span>
                    </div>
                    <div className="align-self-center ml-auto cover" style={{ width: 40, height: 40, borderRadius: 20, border: '1px solid gray', background: `url(${player.InstitutionImage}) no-repeat center center black` }}></div>
                </div>

                {player && <div className="d-flex flex-row p-2 justify-content-center pointer mt-auto" style={{ backgroundColor: (player.Subscriptions && player.Subscriptions.length && player.Highlights && player.Highlights.length) ? '#00871B' : 'gray' }} onClick={() => select(player)}>
                    <span className="white align-self-center">Highlights <i className="fas fa-star white" /></span>
                </div>}                

            </figure>);        
    }
}

export default Player;