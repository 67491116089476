import React from 'react';
import Loader from '../../common/Loader';
import { formatPrice, detectCreditCardType } from '../../../helpers';
import BlueishHeader from '../../common/things/BlueishHeader';
import PayingPlacebo from './partials/PayingPlacebo';
import Team from './partials/Team';
import _ from 'lodash';
import Brochure from './Brochure';

import '../../externals/signature-pad.css';

var SignaturePad = require('react-signature-pad');

class Comp extends React.Component {

  state = {
    bootstrapped: false,
    fetching: false,
    plans: null,
    total: 0,
    paying: false,
    card: null,
    invalidCC: false,
    signatureError: false,
    signatureDataURL: null,
    signed: false
  }

  // Lifecycle
  componentWillMount() {

    const { setTitle } = this.props;

    setTitle && setTitle({
      main: 'App Services',
      sub: 'Enroll'
    });

    this.props.familyActions && this.props.familyActions.fetchTeamsWithAppPlans();
    this.props.fetchAppPlans && this.props.fetchAppPlans();
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      teams: nextProps.teams,
      plans: nextProps.appPlans,
      bootstrapped: (nextProps.teams && nextProps.appPlans) ? true : false
    });
  }
  //

  fnUpdateTotal = ({ IdTeam }, selectedPlan) => {
    const { teams } = this.state;
    var team = _.find(teams, (t) => {
      return t.IdTeam === IdTeam
    });
    if (team) team.selectedPlan = selectedPlan;
    this.setState({ teams });
  }

  validateNewCard = () => {
    var valid = true;
    if (!this.txtCardNumber.value ||
      !this.txtNameOnCard.value ||
      !this.txtExpDate.value ||
      !this.txtCVV.value ||
      !this.txtZipCode.value) {
      valid = false;
    } else if (!detectCreditCardType(this.txtCardNumber.value).Name) {
      valid = false;
    }
    return valid;
  }

  clearSignature = () => {
    var signature = this.refs.mySignature;
    signature.clear();
    this.setState({
      signatureDataURL: null,
      signed: false
    })
  }

  signatureEnd = () => {
    this.setState({
      signed: true,
      signatureError: false
    });
  }

  pay = () => {
    const { teams, signed } = this.state;
    // TODO: validate everything!
    var valid = false;

    var total = 0;
    if (teams) {
      teams.forEach((t) => {
        if (t.selectedPlan) {
          total = total + parseFloat(t.selectedPlan.Price);
        }
      })
    }

    if (total <= 0 || !signed) {
      if (!signed) {
        this.setState({ signatureError: true });
        document.getElementById('signature-header').scrollIntoView();
      }
      return;
    }

    var selectedCard = {};

    if (this.validateNewCard()) {
      selectedCard = {
        number: this.txtCardNumber.value,
        name: this.txtNameOnCard.value,
        cvv: this.txtCVV.value,
        expdate: this.txtExpDate.value,
        zip: this.txtZipCode.value
      }
      valid = true;
    } else {
      // Invalid Credit Card
      document.getElementById('lblTotalWrapper').scrollIntoView();
    }

    valid && this.setState({
      paying: true,
      card: selectedCard,
      invalidCC: false
    });

    !valid && this.setState({
      invalidCC: true
    });
  }

  togglePayingPlacebo = () => {
    this.setState({
      paying: false
    })
  }

  successPayment = () => {
    this.props.familyActions && this.props.familyActions.fetchTeamsWithAppPlans();
    // TODO: clear signature and form
    this.clearSignature();
    this.txtCardNumber.value = null;
    this.txtNameOnCard.value = null;
    this.txtCVV.value = null;
    this.txtExpDate.value = null;
    this.txtZipCode.value = null;
  }

  render() {
    const { plans, invalidCC, teams, paying, bootstrapped, signatureError, signed } = this.state;

    var total = 0;
    if (teams) {
      teams.forEach((t) => {
        if (t.selectedPlan) {
          total = total + parseFloat(t.selectedPlan.Price);
        }
      })
    }

    var cart = _.filter(teams, (t) => {
      return t.selectedPlan;
    });

    // I know there must be a way to chain this before the map function and not create another element.
    return (
      <section>
        { bootstrapped && (
          <div className="container m-auto padding-top">
            <Brochure />
            <hr className="bg-white" />
            <div className="row margin-top">
              { /* Teams */}
              <ul className="col-xl-6 col-md-12 d-flex flex-column white font-20">
                <li className="d-flex flex-row">
                  <span className="green">My Teams</span>
                  <span className="white ml-auto">Current Plan</span>
                </li>
                {teams && teams.map((team, i) => <Team key={i} index={i} team={team} plans={plans} fnUpdate={this.fnUpdateTotal} />)}
              </ul>

              { /* CC Form */}
              <div className="col-xl-6 col-md-12" id="signature-header" style={{ position: 'sticky' }}>
                { /* Signature */}
                {!signatureError && <BlueishHeader message="Please Sign Here" />}
                {signatureError && <div className="bg-warning p-2 big-font text-center">Please Sign!</div>}
                <div className="m-auto w-100" style={{ position: 'relative' }}>
                  <SignaturePad onEnd={this.signatureEnd} ref="mySignature" className='w-100' />
                  <button className="btn btn-link float-right" onClick={this.clearSignature} style={{ position: 'absolute', top: 0, right: 0 }}>Clear</button>
                </div>
                <div className="tile-rainbow p-2 text-center" id="lblTotalWrapper">
                  <span className="white bigest-font">Total: {formatPrice(total * 100)}</span>
                </div>
                {!invalidCC && <BlueishHeader message="Credit Card Information" />}
                {invalidCC && <div className="bg-warning p-2 big-font text-center">Invalid Credit Card!</div>}
                <div className={`p-2 m-auto font-12 align-self-center`}>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="white align-self-center">Card Number
                            {' '}
                      {(!this.txtCardNumber || !this.txtCardNumber.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" maxLength="16" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtCardNumber = i} />
                  </div>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="white align-self-center">Name on Card
                            {' '}
                      {(!this.txtNameOnCard || !this.txtNameOnCard.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtNameOnCard = i} />
                  </div>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="white align-self-center">Exp Date
                            {' '}
                      {(!this.txtExpDate || !this.txtExpDate.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" maxLength="4" placeholder="MMYY" style={{ width: 100 }} type="text" ref={(i) => this.txtExpDate = i} />
                  </div>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="white align-self-center">CVV
                            {' '}
                      {(!this.txtCVV || !this.txtCVV.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" type="text" maxLength="4" style={{ width: 100 }} ref={(i) => this.txtCVV = i} />
                  </div>
                  <div className="d-flex flex-row">
                    <label className="white align-self-center">Zip / Postal
                            {' '}
                      {(!this.txtZipCode || !this.txtZipCode.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" type="text" style={{ width: 100 }} ref={(i) => this.txtZipCode = i} />
                  </div>
                  <button onClick={this.pay} className={`btn btn-${signed && total > 0 ? 'success' : 'default bg-gray white'} btn-lg btn-block margin-top-half`}>
                    Purchase
                        </button>
                </div>
              </div>
            </div>
          </div>)}
        { !bootstrapped && <Loader message="Please wait..." />}
        { paying && <PayingPlacebo card={this.state.card} total={total} cart={cart} toggle={this.togglePayingPlacebo} isOpen={paying} fnOk={this.successPayment}
        />}
      </section>)
  }
}

export default Comp;