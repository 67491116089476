import React from 'react';
import { generateRandomId } from '../../../helpers';

class Pic extends React.Component {
    state = {};
    componentWillMount() {
        const { url } = this.props;
        this.setState({ url });
    }
    onError = () => {
        this.setState({ url: '/images/defaults/teamLogo.png' });
    }
    render() {
        return <div key={generateRandomId(5)} className="" style={{ width: 50, height: 50, borderRadius: 25, background: `url("${this.state.url}") no-repeat center center`, backgroundSize: 'cover', border: '1px solid white' }}>
            <img alt="" src={this.state.url} style={{ width: 0, height: 0 }} onError={this.onError} />
        </div>
    }
}

export default Pic;