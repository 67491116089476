import React from 'react';
import './menu.css';
import paths from '../../ioc/dobPaths';
import { Link } from 'react-router';
import _ from 'lodash';

class SeasonMenu extends React.Component {

  state = {
    tree: null,
    lastPath: ''
  }

  handlePath = () => {
    if (this.props.routes && this.props.routes.length) {
      const leafPath = _.last(_.chain(this.props.routes).filter(route => {
        return route.path;
      }).last().value().path.split('/'));
      Object.keys(paths).forEach(function (key) {
        var path = paths[key];
        if (path.sub_nav) {
          var subFound = path.sub_nav[leafPath];
          if (subFound) {
            path.on = true;
            subFound.on = true;
          } else {
            path.on = false;
          }
        }
      });
    }

    localStorage.setItem('sportslogic.dobMenuState', JSON.stringify(paths));
  }

  // Lifecycle
  componentWillMount = () => {
    this.handlePath();
    // Determine which of the 'paths' should be 'on'
    this.setState({
      tree: paths,
    })
  }
  componentWillReceiveProps = (nextProps) => {
    this.handlePath();
    this.setState({
      tree: paths,
    });
  }
  //

  renderButton = (key) => {
    const { tree } = this.state, { params, mod = 'dob' } = this.props,
      path = tree[key];

    return (
      <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'}`}>
        <Link style={{ height: '2em' }} className={`btn d-flex flex-row justify-content-center drop btn-off btn-block`} to={`/${mod}/${params.idLeague}/${key}`} activeClassName="btn-on" onlyActiveOnIndex={true}>
          <span className=" align-self-center font-12 toUpper">{key}</span>
        </Link>
      </div>
    );
  }

  render() {
    const { params } = this.props;
    return (<section id="left-menu">
      <div key="seasons" className={`menu-wrapper off`}>
        <Link className={`btn d-flex flex-row drop btn-off btn-block`} to={`/leagues/${params.idLeague || 1}`} activeClassName="btn-on" onlyActiveOnIndex={true}>
          <i className={` align-self-center big-font icon fa fa-arrow-left`} />
          <span className=" align-self-center font-8 toUpper name">Seasons</span>
        </Link>
      </div>
      {Object.keys(paths)
        .map(this.renderButton)}
    </section>)
  }
}

export default SeasonMenu;