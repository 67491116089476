function reducer(state = [], action) {

    switch (action.type) {
        case 'NOTES/SET':
            return action.notes;
        default:
            return state;
    }
}

export default reducer;