function reducer(state = [], action) {
    switch (action.type) {
        case 'COMMUNITIES/SET':
            return action.communities;
        case 'COMMUNITIES/CLEAR':
            return [];
        default:
            return state;
    }
}

export default reducer;
