import React from 'react';
import moment from 'moment';

class MessageListItem extends React.Component {
  render() {
    const { message, selected, onSelect, onDelete } = this.props;
    return (
      <li className={`d-flex flex-column margin-bottom-half padding-bottom-half pointer ${selected ? 'bg-warning' : ''}`} style={{ borderBottom: '1px solid #e5e5e5' }}>
        <div className={`d-flex flex-row p-2 ${message.WasRead ? (selected ? 'white' : 'white-ish') : 'white'}`}>
          <div className="p-2" onClick={onSelect}>
            <i className={`far fa-envelope${message.WasRead ? '-open' : ''} no-padding text-center`}></i>
          </div>
          <div className="font-8 d-flex flex-column w-100">
            <span className={`${message.read ? '' : 'toBold'} font-10`} onClick={onSelect}>{message.Subject}</span>
            <span className={`${message.read ? '' : 'toBold'}`} onClick={onSelect}>From: {message.From}</span>            
            <span className={`${message.read ? '' : 'toBold'} d-flex flex-row w-100`}>{moment(message.dateCreated).format('MMM Do YYYY @ H:mmA')} <i className="fa fa-trash align-self-center ml-auto" onClick={onDelete} /></span>
          </div>
        </div>
      </li>)
  }
}

export default MessageListItem;
