import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators.js';
import Reset from '../components/login/Reset';

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn,
        auth: state.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch)
}

const App = connect(mapStateToProps, mapDispatchToProps)(Reset);

export default App;
