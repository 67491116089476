import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, formatPrice } from '../../../../../../helpers';
import moment from 'moment';

const source = {
  beginDrag(props) {
    return props;
  }
}

class AdjustmentCard extends React.Component {

  renderThing = () => {
    const { isDragging, program, unmovable, fnEdit, fnDelete } = this.props;
    return <li style={{ marginRight: unmovable ? 0 : '1em' }} className={`program-card shadow bg-purple p-4 d-flex flex-column ${isDragging ? 'dragging' : ''} ${unmovable ? 'w-100' : ''}`}>
      <span className="white font-16 text-left toBold">{program.Name}</span>
      <span className="white font-10 text-left ">From {moment(program.DateStart).format('MMM Do YYYY')} to {moment(program.DateEnd).format('MMM Do YYYY')}</span>
      <hr className="bg-white white" />
      {true && <div className='d-flex flex-row'>
        <span className="white">Adjustment</span>
        <span className="ml-auto white">{formatPrice(program.Price * 100)}</span>
      </div>}

      {!unmovable && <button className="white font-8 pointer hint" style={{ padding: '0 0.5em', borderRadius: 10, border: '1px solid white', height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnEdit(program)}>
        <i className="fas fa-edit" />{' '}Edit</button>}

      {!unmovable && <span className="fas fa-grip-vertical white font-14 grab" style={{ position: 'absolute', top: 2, left: 4 }} />}

      {fnDelete && <button className="white font-8 pointer btn-danger" style={{ padding: '0 0.5em', borderRadius: 10, height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnDelete()}>
        <i className="fas fa-trash" />{' '}Remove</button>}
    </li>
  }

  render() {
    const { connectDragPreview, connectDragSource, unmovable } = this.props;
    if (unmovable) return this.renderThing();
    else return connectDragPreview(connectDragSource(this.renderThing()));
  }

}

export default flow(
  DragSource(ItemTypes.ADJUSTMENT, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(AdjustmentCard);
