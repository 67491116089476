import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../../../helpers';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import fakeAuth from '../../../../../fakeAuth';
import _ from 'lodash';

class AddEvaluation extends React.Component {
    state = {
        match: {
            CreatorNameFirst: fakeAuth.getUser().NameFirst,
            CreatorNameLast: fakeAuth.getUser().NameLast,
            EvaluationDate: moment(),
            IdFolder: null, // TODO
            IdGender: 1,
            IdPlayerEvaluation: generateRandomId(10),
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: this.props.selected_user ? this.props.selected_user.NameFirst : '',
            NameLast: this.props.selected_user ? this.props.selected_user.NameLast : '',
            ReportType: 1,
            IdUserTarget: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdUserFollow: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdTeam: this.props.IdTeam
        }
    }

    onColor = (color) => {
        const { match } = this.state;
        match.Color = color;
    }

    onSave = () => {

        this.setState({ dirty: true }, () => {
            const { match, profile } = this.state;
            if (!this.txtName.value || !profile) {
                alert('Please complete the missing fields');
                return;
            }

            this.props.scout_actions.save_match({
                ...match,
                NameFirst: this.txtName.value.trim(),
                NameLast: '',
                ...profile,
                ReportType: 1,
                IdMatch: null,
                IdTeam: this.props.IdTeam
            });

            // This is to clean up the form for future profiles
            this.setState({
                match: {
                    CreatorNameFirst: fakeAuth.getUser().NameFirst,
                    CreatorNameLast: fakeAuth.getUser().NameLast,
                    EvaluationDate: moment(),
                    IdFolder: null, // TODO
                    IdGender: 1,
                    IdPlayerEvaluation: generateRandomId(10),
                    IdUser: fakeAuth.getUser().IdUser,
                    NameFirst: this.props.selected_user ? this.props.selected_user.NameFirst : '',
                    NameLast: this.props.selected_user ? this.props.selected_user.NameLast : '',
                    ReportType: 1,
                    IdUserTarget: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
                    IdUserFollow: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
                    IdTeam: this.props.IdTeam
                }
            });

            this.props.toggle();
        });
    }

    toggleQuestion = (i, j) => {
        const { profile } = this.state;
        profile.tactical[i].questions[j].yes = profile.tactical[i].questions[j].parent ? profile.tactical[i].questions[j].yes : !profile.tactical[i].questions[j].yes;
        this.setState({ profile, saved: false });
    }

    pickPosition = (f) => {
        const { match } = this.state;
        match.Position = f.name;
        this.setState({ match, profile: f });
    }

    render() {
        const { match, colors, profile, dirty } = this.state, { questionaries } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.adding_evaluation ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-row w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                    {match && false && <div className="align-self-start d-flex flex-column justify-content-center" style={{ width: 60, paddingLeft: 80 }}>
                        {_.values(colors).map((color, i) => <i onClick={() => this.onColor(color)} key={i} className={`align-self-center icon-bookmark1 mb-2 font-${color === match.Color ? '50' : '30'}`} style={{ color: `${color}` }} />)}
                    </div>}
                    {match && <section className="w3-container w3-content mx-auto w-100">
                        <div className="d-flex flex-column black align-self-start w-100">
                            <div className="w3-container bg-white p-4">
                                <label>Athlete's Name</label>

                                <input type="text" defaultValue={match.NameFirst ? `${match.NameFirst} ${match.NameLast}` : match.NameFirst} ref={i => this.txtName = i} className={`w3-input ${(dirty && !this.txtName.value) ? 'bg-danger' : ''}`} />

                                <label>Position</label>

                                <Dropdown isOpen={this.state.pickPosition ? true : false} toggle={() => this.setState({ pickPosition: !this.state.pickPosition })} className="w3-input w-100">
                                    <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                        <span className="align-self-center w-100">{match.Position || 'Select'}</span>
                                        <i className="ms-auto fas fa-caret-down align-self-center " />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100" style={{ overflowY: 'auto', height: 600 }}>
                                        <DropdownItem onClick={() => this.pickPosition()}>Select a form</DropdownItem>
                                        {questionaries && _.filter(questionaries, s => !s.CUSTOM).map((c, i) => <DropdownItem key={i} onClick={() => this.pickPosition(c)}>{c.name}</DropdownItem>)}
                                        <DropdownItem divider />
                                        {questionaries && _.filter(questionaries, s => s.CUSTOM).map((c, i) => <DropdownItem key={i} onClick={() => this.pickPosition(c)}>{c.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                            {profile && <section className="w3-container-fluid mt-4" >
                                <div className="w3-content row mx-auto">
                                    {profile.tactical.map((tactical, index) => {
                                        return (<div className="col-md-6 col-sm-12 mb-3" key={index}>
                                            <div className="w3-container bg-white w3-card p-0">
                                                <div className="px-2 py-1 w3-gray white">
                                                    <h2 className="sqwad-font m-0">{tactical.name.toUpperCase()}</h2>
                                                </div>
                                                <div className="w3-container d-flex flex-column p-4">
                                                    {tactical.questions.map((question, j) => {
                                                        return (<button key={j} className={`btn btn-block text-left btn-${question.yes ? 'success' : `default ${question.parent ? 'toUpper sqwad-font' : 'bordered'}`}`} onClick={() => this.toggleQuestion(index, j)}>{question.text}</button>)
                                                    })}
                                                </div>
                                            </div>
                                        </div>)
                                    })}

                                    <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Profile saved!' : 'Save Profile'}</button>
                                    <div className="p-4" />
                                </div>
                            </section>}
                        </div>
                    </section>}

                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default AddEvaluation;