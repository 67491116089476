import React from 'react';
import GameWithGuests from '../../common/modals/partials/GameWithGuests';
import { Animated } from 'react-animated-css';
import Players from './partials/players';
import Network from './partials/GuestNetwork';
import Invite from './partials/InviteNetwork';
import _ from 'lodash';

class AddGuestPlayer extends React.Component {

    state = {
        step: 0,
        mode: 0
    }
    
    add = (users) => {
        const { rosterActions, team } = this.props, { game } = this.state;
        this.setState({ mode: 0, step: 1 });
        users.forEach(user => {
            rosterActions && rosterActions.addGuestPlayer({
                IdUser: user.IdUser,
                IdGame: game.IdGame,
                IdTeamDivision: team.IdTeamDivision
            });
        });
    }

    render() {
        const { games = [], guests, onClose, onSelect, players = [], network, division, limits, team } = this.props, { game, step, mode } = this.state;

        let availableSpots = null;
        if (limits && limits.Roster_Max && game) {
            let current_roster = [..._.filter(this.props.players, p => !_.find(p.inactiveGames, ig => ig.IdGame === parseInt(game.IdGame, 10))), ..._.filter(guests, g => _.find(g.games, g => g === parseInt(game.IdGame, 10)))].length;
            availableSpots = limits.Roster_Max - current_roster;
        }

        return <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>

            <div className="overlay" style={{ backgroundColor: 'black', opacity: 0.4, zIndex: -1 }} onClick={this.close} />

            <Animated animationInDuration={200} animationOutDuration={200} animationIn='slideInRight' animationOut='slideOutRight'
                style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: step < 2 ? 800 : 550, overflowY: 'auto', borderLeft: '1px solid black' }}
                className="ease bg-white d-flex flex-column">
                <div className="d-flex flex-row p-3">
                    {step === 0 && <i className="fas fa-times font-16 pointer" onClick={onClose} />}
                    {step > 0 && <i className="fas fa-arrow-left font-16 pointer" onClick={() => this.setState({ step: this.state.step - 1 })} />}
                    <span className="black montserrat font-12 ml-auto">GUESTS PLAYERS</span>
                    {game && step === 1 && availableSpots > 0 && <button className='btn btn-sm btn-info bordered ml-auto' onClick={() => this.setState({ step: 2 })}>+ Add</button>}
                </div>

                {step === 0 && <section>
                    <div className="bg-gray px-4 py-2 mb-3 bg-warning font-18 text-center">Select an Upcoming Game to add Guest Players</div>
                    <section className="px-4">
                        {games && games.map((game, i) => <GameWithGuests onClick={() => this.setState({ game, step: 1 })}
                            game={game}
                            key={game.IdGame}
                            guests={_.filter(guests, g => _.find(g.games, g => g === parseInt(game.IdGame, 10)))} />)}
                        <div style={{ height: 100 }} />
                    </section>
                </section>}

                {step === 1 && <section>
                    <GameWithGuests game={game}
                        key={game.IdGame}
                        guests={_.filter(guests, g => _.find(g.games, g => g === parseInt(game.IdGame, 10)))} />

                    {availableSpots === 0 && <div className="p-4 mb-3 bg-warning d-flex flex-column justify-content-center">
                        <i className="fas fa-warning mr-2 align-self-center font-30" />
                        <span className="black font-12 align-self-center text-center mx-4 mt-2">Your roster for this game is full.<br />You need to deactivate some players in order to add Guest Players</span>
                    </div>}

                    <Players long={true} {...this.props}
                        onClick={onSelect} game={game}
                        team={team}
                        players={[...this.props.players, ..._.filter(guests, g => _.find(g.games, g => g === parseInt(game.IdGame, 10)))]}
                    />
                </section>}

                {step === 2 && <section className="d-flex flex-column h-100 justify-content-center p-4">
                    <div onClick={() => this.setState({ mode: 2, step: 3 })} style={{ height: 'auto', border: '1px solid gray' }} className="align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                        <span className="icon-mail font-24 black align-self-center" />
                        <span className="font-16 toBold black align-self-center">ADD PLAYER</span>
                        <span className="font-10 black line1 align-self-center">using Email</span>
                    </div>
                </section>}

                {step === 3 && <section className="h-100">
                    {mode === 1 && <Network limits={limits} network={network} division={division} guests={guests} roster={_.filter(players, p => !p.IdGuestPlayer)} game={this.state.game} onSave={this.add} />}
                    {mode === 2 && <Invite team={team} limits={limits} guests={guests} roster={_.filter(players, p => !p.IsInactive)} game={this.state.game} onSave={this.add} />}
                </section>}
            </Animated>
        </section>
    }
}

export default AddGuestPlayer;
