
import React from 'react';
import formations from '../../../../../formations';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import fakeAuth from '../../../../../fakeAuth';
import config from '../../../../../config';
import { calculateBWContrastClass } from '../../../../../helpers';

class CreateLineup_1 extends React.Component {

    state = {
        match: {
            Home: {
                Formation: 0,
                Roster: []
            },
            DEFENSE: 0,
            Competition: '',
            Date: moment().format(),
            IdTeamDivision: 1,
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: fakeAuth.getUser().NameFirst,
            NameLast: fakeAuth.getUser().NameLast,
            LastUpdate: moment().format(),
            ReportType: 4,
            Setup: 11,
            Title: '',
            Venue: '',
            WeatherConditions: ''
        },
        tab: 1
    }

    componentWillMount = () => {
        const { match, setup, DEFENSE, roster } = this.props;
        let state_match = this.state.match;
        for (var i = 0; i < setup; i++) {
            state_match.Home.Roster.push({ Index: i });
        }
        this.setState({ DEFENSE, match: match ? { ...match } : state_match }, () => {
            this.setState({ aux: [...this.state.match.Home.Roster] })
        });
    }
    //

    onSelect = (u, p) => {
        const { aux } = this.state;
        // Remove duplicates
        aux.forEach(a => { if (a.IdUser !== u.IdUser && a.PitchPosition === p) a.PitchPosition = null; });
        (_.find(aux, a => a.IdUser === u.IdUser) || {}).PitchPosition = p;
        this.setState({ aux });
    }

    onPickHomeFormation = (formation, index) => {
        const { match } = this.state;
        match.Home.Formation = index;
        this.setState({ match });
    }

    onHomePitch = (index) => {
        const { roster } = this.props, { aux } = this.state;
        this.props.select(
            (_.find(aux, p => p.PitchPosition === index) || {}).IdUser, // Id of the user currently in that position
            _.chain(roster || [])
                .filter(u => u.IdRole === 3)
                .filter(u => _.find(this.state.aux, r => r.IdUser === u.IdUser))
                .value(),
            index,
        );
    }

    onSave = () => {
        const { match } = this.state, { selectedTeam, season } = this.props;
        match.IdTeamDivision = season.IdTeamDivision;
        match.Title = this.txtTitle.value;
        match.Setup = this.props.setup;
        match.Home = {
            Formation: match.Home.Formation, // This is variable on soccer
            Image: selectedTeam.TeamImageUrl,
            Manager: this.txtManager.value,
            Name: selectedTeam.Name,
            Roster: this.state.aux,
            Score: 0,
            COLOR: match.Home.COLOR
        };

        // CALL API
        this.props.teamActions.saveLineup(match);
        this.props.toggle();
    }

    render() {
        const { selectedTeam = {}, roster, DEFENSE, setup } = this.props, { match, tab } = this.state;
        let group = 'offense';
        switch (tab) {
            case 2: group = 'defense'; break;
            case 3: group = 'special_teams'; break;
            default: break;
        }
        let manager = _.find(roster, u => u.IdRole === 4);

        let selected_formation = _.filter(formations.soccer, i => i.setup === setup)[match.Home.Formation];

        return <section className="w3-container">
            <div className="w3-content p-2">

                <div className="d-flex flex-row mt-2">
                    <h3 className="montserrat black align-self-center ml-4">{`${DEFENSE ? 'DEFENSIVE' : 'OFFENSIVE'} LINEUP ${this.props.setup}V${this.props.setup}`}</h3>
                    <button onClick={this.onSave} type="button" style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green py-1 px-4 align-self-center ml-auto">
                        Save</button>
                </div>

                {/* TEAM INFO */}
                <figure className="p-3 card mt-2 pointer">
                    <div className="d-flex flex-row">
                        <img className="align-self-center" src={selectedTeam.TeamImageUrl} style={{ width: 30 }} />
                        <span className="ml-2 montserrat font-14 black align-self-center">{selectedTeam.Name || selectedTeam.TeamName}</span>
                    </div>
                </figure>

                {/* TITLE AND MANAGER */}
                <div className="d-flex flex-row mt-3">
                    <div className="w-50">
                        <div className={`black-input d-flex mr-1`}>
                            <input className="w-100 p-3" type="text" name="title" placeholder="Title" ref={(input) => this.txtTitle = input} defaultValue={match.Title} />
                        </div>
                    </div>
                    <div className="w-50">
                        <div className={`black-input d-flex ml-1`}>
                            <input className="w-100 p-3" type="text" name="manager" placeholder="Manager" ref={(input) => this.txtManager = input} defaultValue={manager ? `${manager.NameFirst} ${manager.NameLast}` : null} />
                        </div>
                    </div>
                </div>

                {/* TABS */}
                <section className="card p-0 mt-3 d-flex flex-row">

                    <div className="w-50">
                        <div className="w3-gray p-2 white montserrat font-16">{`STARTERS (${_.chain(roster || [])
                            .filter(u => u.IdRole === 3)
                            .filter(u => _.find(this.state.aux, r => r.IdUser === u.IdUser))
                            .value().length})`}</div>
                        <div className="w3-blue white montserrat font-10 d-flex flex-row">
                            <div className="w-25 p-1 d-flex flex-row">
                                <span className="font-10 montserrat line1 toBold align-self-center ml-auto mr-2">No</span>
                            </div>
                            <div className="w-75 d-flex flex-row">
                                <span className="font-10 montserrat line1 toBold align-self-center">PLAYER</span>
                            </div>
                        </div>
                        {/* STARTERS */}
                        {_.chain(roster || [])
                            .filter(u => u.IdRole === 3)
                            .filter(u => _.find(this.state.aux, r => r.IdUser === u.IdUser))
                            .sortBy('JerseyNumber')
                            .value().map((user, index) => <div onClick={() => {
                                const { aux } = this.state;
                                aux[_.findIndex(aux, r => r.IdUser === user.IdUser)] = { Index: _.findIndex(aux, r => r.IdUser === user.IdUser) };
                                this.setState({ aux });
                            }} key={index} className="d-flex flex-row" style={{ borderBottom: '1px solid gray' }}>
                                <div className="w-25 p-1 d-flex flex-row">
                                    <div className="align-self-center cover" style={{ width: 30, height: 30, border: `1px solid gray`, borderRadius: '50%', background: `url(${user.Image}) no-repeat center center` }}></div>

                                    <span className="font-10 montserrat line1 toBold align-self-center ml-auto mr-3">{`${user.JerseyNumber || '#'}`}</span>
                                </div>
                                <div className="w-75 d-flex flex-row">
                                    <span className="font-10 montserrat line1 toBold align-self-center">{`${user.NameFirst} ${user.NameLast}`}</span>
                                    <button className="ml-auto mr-2 btn btn-sm btn-link"><i className="fas fa-arrow-down red font-14" /></button>
                                </div>
                            </div>)}
                        <div className="bg-gray px-4 py-2 white montserrat font-14 text-center"><span className="line1 sqwad-font font-16 black">TAP PLAYERS TO MOVE THEM BETWEEN STARTERS AND RESERVE</span></div>
                        {/* RESERVE */}
                        <div className="w3-gray p-2 white montserrat font-16">{`RESERVES (${_.chain(roster || [])
                            .filter(u => u.IdRole === 3)
                            .reject(u => _.find(this.state.aux, r => r.IdUser === u.IdUser))
                            .value().length})`}</div>
                        {_.chain(roster || [])
                            .filter(u => u.IdRole === 3)
                            .reject(u => _.find(this.state.aux, r => r.IdUser === u.IdUser))
                            .sortBy('JerseyNumber')
                            .value().map((user, index) => <div onClick={() => {
                                const { aux } = this.state;
                                let index = _.find(aux, a => !a.IdUser);
                                if (index) {
                                    //TODO: validate that adding an additional user does not passes the setup max                                    
                                    aux[index.Index] = { Index: index.Index, ..._.pick(user, 'IdUser', 'NameFirst', 'NameLast', 'JerseyNumber', 'IdCollegeProfile', 'IdGender', 'Image', 'Position') };
                                    this.setState({ aux });
                                }
                            }} key={index} className="d-flex flex-row" style={{ borderBottom: '1px solid gray' }}>
                                <div className="w-25 p-1 d-flex flex-row">
                                    <div className="align-self-center cover" style={{ width: 30, height: 30, border: `1px solid gray`, borderRadius: '50%', background: `url(${user.Image}) no-repeat center center` }}></div>

                                    <span className="font-10 montserrat line1 toBold align-self-center ml-auto mr-3">{`${user.JerseyNumber || '#'}`}</span>
                                </div>
                                <div className="w-75 d-flex flex-row">
                                    <span className="font-10 montserrat line1 toBold align-self-center">{`${user.NameFirst} ${user.NameLast}`}</span>
                                    <button className="ml-auto mr-2 btn btn-sm btn-link"><i className="fas fa-arrow-up green font-14" /></button>
                                </div>
                            </div>)}
                    </div>

                    <div className="w-50">

                        <h3 className="sqwad-font mt-3 px-3">FORMATION</h3>
                        <div className="d-flex flex-row p-3">

                            <Dropdown isOpen={this.state.pickingHomeFormation ? true : false} toggle={() => this.setState({ pickingHomeFormation: !this.state.pickingHomeFormation })} className="form-control p-0 mt-1 w-75">
                                <DropdownToggle className="text-left btn btn-default w-100 " >
                                    <div className="d-flex flex-row w-100">
                                        <span className="align-self-center">{selected_formation.name}</span>
                                        <i className="ml-auto fas fa-caret-down align-self-center" />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                    {_.filter(formations.soccer, i => i.setup === setup).map((c, index) => <DropdownItem key={index} onClick={() => this.onPickHomeFormation(c, index)}>{c.name}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>

                            <Dropdown isOpen={this.state.pickingHomeColor ? true : false} toggle={() => this.setState({ pickingHomeColor: !this.state.pickingHomeColor })} className="form-control p-0 black mt-1 w-25">
                                <DropdownToggle className="text-left btn btn-link w-100 tile card" >
                                    <div className="d-flex w-100">
                                        <span className="align-self-center"><i className="fas fa-square me-2" style={{ color: match.Home.COLOR || 'transparent' }} />{match.Home.COLOR || 'Color'}</span>
                                        <i className="ml-auto fas fa-caret-down align-self-center " />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                    {config.wheel.map((c, index) => <DropdownItem key={index} onClick={() => {
                                        const { match } = this.state;
                                        match.Home.COLOR = c;
                                        this.setState({ match });
                                    }}><i className="fas fa-square me-2" style={{ color: c }} />{c}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>

                        </div>

                        <div style={{ position: 'relative', width: formations.canvas_width, height: formations.canvas_height, background: `url(/images/pitch/soccer.png) no-repeat center center` }} className=" mx-auto cover my-3 ">
                            {selected_formation.positions && selected_formation.positions.map((f, i) => <div key={i} style={{
                                width: formations.glosary.football.width, height: formations.canvas_width * 0.20,
                                position: 'absolute',
                                top: f.top, left: f.left
                            }} className=" d-flex flex-column justify-content-center ease" onClick={() => this.onHomePitch(i + 1)}>
                                <div style={{ width: 36, height: 36, borderRadius: '50%', border: `2px solid white`, backgroundColor: match.Home.COLOR || 'teal' }} className="d-flex flex-row justify-content-center align-self-center">
                                    <span style={{ color: calculateBWContrastClass(match.Home.COLOR || '#008080') }} className="align-self-center font-10">{(_.find(this.state.aux, r => r.PitchPosition === i + 1) || {}).JerseyNumber || f.name}</span>
                                </div>
                                {_.find(this.state.aux, r => r.PitchPosition === i + 1) && <span style={{ backgroundColor: 'black', border: `1px solid white`, borderRadius: 5 }} className="px-2 white text-center w-auto font-7 mt-0 max-lines-1">{`${_.find(this.state.aux, r => r.PitchPosition === i + 1).NameLast}`}</span>}
                            </div>)}
                        </div>

                    </div>
                </section>
            </div>
        </section>
    }
}

export default CreateLineup_1;