import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, toTitleCase, formatPrice } from '../../../../../helpers';
import moment from 'moment';
import { Link } from 'react-router';

const source = {
  beginDrag(props) {
    return props;
  }
};

const target = {
  canDrop(props) {
    return false;
  },
  drop(props, monitor) { }
}

const render = (item, props) => {
  const { season } = props;
  return <tr className={`${item.New ? 'bg-success white' : (item.IdRole === 4 ? 'bg-white' : 'bg-white')}`}>
    <td><i className="fas fa-arrows-alt pointer" /></td>
    <td>
      {item.IdUser}
    </td>
    <td className="font-14">
      <Link className={`btn w-100 text-left btn-link underlined ${item.New ? 'white' : 'blue'}`} to={`/season/${season.IdSeason}/player/${item.IdUser}${item.IdTeamUserRole ? `/${item.IdTeamUserRole}` : ''}`}>
        {toTitleCase(item.UserName)}
      </Link>
    </td>
    <td>
      <i className={`${item.New ? 'white' : item.RoleStatus} fas fa-circle`} />
      {' '}
      <span>{item.RoleStatus}</span>
    </td>
    <td>{item.RoleName || item.RoleOverride}</td>
    <td>
      {moment(item.DateBirth).format('MMM Do YYYY')}
    </td>
    <td>
      {item.Team}
    </td>
    <td>
      {item.Gender.substring(0, 1)}
    </td>
    <td>
      <span className="block">{item.PhoneNumber}</span>
      <span className="block">{item.EmailAddress}</span>
    </td>
    <td>N/A</td>
    <td>
      {item.City}
    </td>
    <td>
      {item.State}
    </td>
    <td>
      {formatPrice(item.BalanceDue * 100)}
    </td>
    <td>
      {formatPrice(item.AmountPaid * 100)}
    </td>
    {props.questions && props.questions.map((q, i) => (
      <td key={i}>
        {item[`question-${q.IdQuestion}`]}
      </td>))}
  </tr>
}

const RosteredPlayer = (props) => {
  const { connectDragSource, item, connectDropTarget } = props; // isDragging
  if (item.IdRole === 3 || true)
    return connectDragSource(connectDropTarget(
      render(item, props)
    ));
  else
    return connectDropTarget(
      render(item, props)
    );
}

export default flow(
  DropTarget(ItemTypes.NONROSTEREDPLAYER, target, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver()
  })),
  DragSource(ItemTypes.ROSTEREDPLAYER, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(RosteredPlayer);