import React from 'react';
import _ from 'lodash';

class LocationsFilters extends React.Component {

    checkAll = () => {
        const { resources, toggleResource } = this.props;
        _.filter(resources, (r) => { return !r.checked; }).forEach((r) => {
            toggleResource(r.id);
        });
    }

    uncheckAll = () => {
        const { resources, toggleResource } = this.props;
        _.filter(resources, (r) => { return r.checked; }).forEach((r) => {
            toggleResource(r.id);
        });
    }

    render() {
        const { resources, toggleResource } = this.props;
        return (
            <div key="locations" className="scrollable" style={{ top: '55px' }}>
                {resources && resources.map((r, i) => <button className={`btn btn-sm btn-default d-flex flex-row ${r.child ? 'ml-4' : ''}`} key={i} onClick={() => toggleResource(r.id)}>
                    <i className={`fa-${r.checked ? 'check-square fas green' : 'square far'} align-self-center`} />
                    <span className="margin-left align-self-center font-8">{r.title}</span></button>
                )}
            </div>
        )
    }
}

export default LocationsFilters;