function reducer(state = [], action) {

    switch (action.type) {
        case 'GAME_TYPES/SET':
            return action.game_types;
        case 'GAME_TYPES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;