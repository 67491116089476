import React from 'react';
import { formatPrice } from '../../../../helpers';
import { Animated } from 'react-animated-css';
import './css.css';

class RevenueChart extends React.Component {  
  state = {};
  
  render() {
    const { total, received, season, details, fnSelectDetails } = this.props;
    let receivedGauge = (received * 100) / total;

    // Conditions over when the gauge should be all green
    if (receivedGauge > 100 || total - received === 0) receivedGauge = 100;
    //

    return (
      <section id="revenue_chart" className="d-flex flex-column h-100">
        <h6 className="black toUpper text-left mt-4 ml-4 sqwad-font">Total Revenue</h6>
        <div className="d-flex flex-row justify-content-center flex-fill h-100 pl-3 pr-3 mt-2">
          
          <Animated animationIn="fadeIn" className="w-100 p-2 hoverable pointer">
            <div style={{ borderRadius: 5, position: 'relative' }} className="pl-2 pr-2 pt-3 pb-3 bg-success text-center white font-12"
              onClick={() => season.Received && fnSelectDetails(details.received)}>{formatPrice(season.Received * 100)}
              <i className="fas fa-eye font-10 pointer" style={{ position: 'absolute', bottom: 5, right: 5 }} />
            </div>
            <span className="black font-8 text-left block margin-top-half toUpper">Fees Received</span>
          </Animated>
          
          <Animated animationIn="fadeIn" className="w-100 p-2 hoverable pointer">
            <div style={{ borderRadius: 5, position: 'relative' }} className="pl-2 pr-2 pt-3 pb-3 bg-info text-center white font-12"
              onClick={() => season.Pending && fnSelectDetails(details.pendings)}>{formatPrice(season.Pending * 100)}
              <i className="fas fa-eye font-10 pointer" style={{ position: 'absolute', bottom: 5, right: 5 }} />
            </div>
            <span className="black font-8 text-left block margin-top-half toUpper">Fees Pending</span>
          </Animated>
          
          <Animated animationIn="fadeIn" className="w-100 p-2 hoverable pointer">
            <div style={{ borderRadius: 5, position: 'relative' }} className="pl-2 pr-2 pt-3 pb-3 bg-danger text-center white font-12"
              onClick={() => season.Refund && fnSelectDetails(details.refunds)}>{formatPrice(season.Refund * 100)}
              <i className="fas fa-eye font-10 pointer" style={{ position: 'absolute', bottom: 5, right: 5 }} />
            </div>
            <span className="black font-8 text-left block margin-top-half toUpper">Refunds</span>
          </Animated>          

          { season.Donation > 0 && <Animated animationIn="fadeIn" className="w-100 p-2">
            <div style={{ borderRadius: 5, position: 'relative' }} className="pl-2 pr-2 pt-3 pb-3 bg-purple bordered text-center white font-12">{formatPrice((season.Donation || 0) * 100)}</div>
            <span className="black font-8 text-left block margin-top-half toUpper">Donations</span>
          </Animated> }
          
          <Animated animationIn="fadeIn" className="w-100 p-2">
            <div style={{ borderRadius: 5, position: 'relative' }} className="pl-2 pr-2 pt-3 pb-3 bg-yellow text-center black font-12">{formatPrice(season.Revenue * 100)}</div>
            <span className="black font-8 text-left block margin-top-half toUpper">Projected Revenue</span>
          </Animated>
          
          
    
        </div>
      </section>
    );
  }
}

export default RevenueChart;