import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';
import { getBase64 } from '../../helpers';

class AdsModal extends React.Component {

    state = {
        bootstrapped: false,
        campaing: {},
    }

    // Lifecycle
    componentWillMount() {
        const { league, familyActions, leagueActions } = this.props;
        this.setState({ league });
        familyActions.fetchCommunities && familyActions.fetchCommunities();
        leagueActions && leagueActions.fetchLeaguesAdmin();
    }
    //   

    save = () => {

    }

    handleAvatar = e => {
        const { campaing } = this.state;
        getBase64(e.target.files[0]).then(data => {
            campaing.ContentImage = data;
            this.setState({ campaing });
        });
    }

    onSelectCommunity = (community) => {
        const { campaing } = this.state;
        campaing.IdCommunity = community.IdCommunity;
        this.setState({ campaing });
    }

    onSelectLeague = league => {
        const { campaing } = this.state;
        campaing.IdLeague = league.IdLeague;
        this.setState({ campaing }, () => {
            // fetch seasons 
            if (campaing.IdLeague) {

            }
        });
    }

    onSelectSeason = season => {
        const { campaing } = this.state;
        campaing.IdSeason = season.IdSeason;
        this.setState({ campaing }, () => {
            // fetch divisions? 
            if (campaing.IdSeason) {

            }
        });
    }

    render() {
        const { isOpen, toggle, communities = [], leagues = [], seasons =
            [] } = this.props,
            { campaing, } = this.state;

        return (
            <Modal isOpen={isOpen} style={{ width: 500 }}>
                <ModalHeader className="bg-blue-dark white" toggle={toggle}>
                    Add Campaing
                </ModalHeader>
                <ModalBody className="container-fluid no-margin no-padding" >
                    <div className="my-3 pl-4 pr-4 d-flex flex-column">

                        <h2>Basic Information</h2>

                        <div className="input-group w-100 align-self-center mt-2">
                            <input type="text" className="form-control p-4" placeholder="Campaing Name" ref={(i) => this.txtName = i} />
                        </div>

                        <div className="input-group w-100 align-self-center mt-2">
                            <input type="text" className="form-control p-4" placeholder="Link" ref={(i) => this.txtLink = i} />
                        </div>

                        <div className="d-flex flex-row justify-content-center">
                            <div className="mx-auto mt-2" style={{ position: 'relative', width: 160, height: 160 }}>
                                <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />
                                {/*htmlFor="fi"*/}
                                <label htmlFor="fi">
                                    {(campaing.ContentImage) && <div className="contain" style={{
                                        width: 160, height: 160,
                                        background: ['url(', (campaing.ContentImage), ') no-repeat center center'].join('')
                                    }} />}
                                    {!(campaing.ContentImage) && <i className="fas fa-audio-description align-self-center gray" style={{ fontSize: '10em' }} />}
                                    <i className="fas fa-camera green font-20" style={{ position: 'absolute', bottom: 0, right: 0 }} htmlFor="fi" />
                                </label>
                            </div>
                        </div>

                        <h2 className="mt-2">Potential Targets</h2>

                        <div className="input-group align-self-center mt-2">

                            <Dropdown isOpen={this.state.pickCommunity} toggle={() => this.setState({ pickCommunity: !this.state.pickCommunity })} className="form-control p-2 font-16 bg-white black">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                                    <span className="black align-self-center">{campaing.IdCommunity ? _.find(this.props.communities, c => c.IdCommunity === campaing.IdCommunity).CommunityName : 'All Communities'}</span>
                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {communities && communities.map((c) => <DropdownItem key={c.IdCommunity} onClick={() => this.onSelectCommunity(c)}>
                                        {c.CommunityName}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>

                        </div>

                        <div className="input-group align-self-center mt-2">

                            <Dropdown isOpen={this.state.pickLeague} toggle={() => this.setState({ pickLeague: !this.state.pickLeague })} className="form-control p-2 font-16 bg-white black">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                                    <span className="black align-self-center">{campaing.IdLeague ? _.find(this.props.leagues, c => c.IdLeague === campaing.IdLeague).LeagueName : 'All Leagues'}</span>
                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {leagues && leagues.map((c) => <DropdownItem key={c.IdLeague} onClick={() => this.onSelectLeague(c)}>
                                        {c.LeagueName}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>

                        </div>

                        {campaing.IdLeague && seasons && <div className="input-group align-self-center mt-2">
                            <Dropdown isOpen={this.state.pickSeason} toggle={() => this.setState({ pickSeason: !this.state.pickSeason })} className="form-control p-2 font-16 bg-white black">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                                    <span className="black align-self-center">{campaing.IdSeason ? _.find(this.props.seasons, c => c.IdSeason === campaing.IdSeason).SeasonName : 'All Seasons'}</span>
                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {seasons && seasons.map((c) => <DropdownItem key={c.IdSeason} onClick={() => this.onSelectSeason(c)}>
                                        {c.SeasonName}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>}

                    </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row">
                    <button style={{ width: 150, border: '1px solid #2cad3c', borderRadius: 20 }} className="green p-2" onClick={toggle}>Cancel</button>
                    <button style={{ width: 150, border: '1px solid #2cad3c', borderRadius: 20, backgroundColor: '#2cad3c' }} className="white ml-auto p-2" onClick={this.save}>Continue</button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AdsModal;
