import React from 'react';
import { Animated } from 'react-animated-css';
import config from '../../../../config';
import request from 'superagent';
import { humanize_user } from '../../../../helpers';
import _ from 'lodash';
import moment from 'moment';

class SubscriptionEntity extends React.Component {
    state = {
        entity: null
    }

    setup = (props) => {
        const { entity } = props;
        this.setState({ entity }, () => {
            request.get(`${config.apiEndpoint}/api/v6/college_profile/${entity.IdUser}`)
                .set('Authorization', `Bearer ${config.apiKey}`)
                .then(data => {
                    let { profile } = JSON.parse(data.text);
                    if (profile) humanize_user(profile);
                    this.setState({ player: profile });
                });
        });
    }

    // Lifecycle
    componentWillMount() {
        this.setup(this.props);
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps);
    }
    //    

    click = () => {
        const { onClick } = this.props, { player, entity } = this.state;
        onClick && onClick({ player, entity });
    }

    render() {
        const { entity, player } = this.state, { index, family_subscriptions = [] } = this.props;
        let entity_subscription = _.find(family_subscriptions || [], f => f.IdUser === entity.IdUser && !f.Expired);
        
        return <Animated animationIn="fadeInRight" animationInDelay={100 * index} className={`d-flex flex-row tile bg-white h-100`} style={{ position: 'relative' }}>
            <div onClick={this.click} className="cover h-100 bg-black" style={{ width: 150, background: ['url(', (entity.UserImage || `/images/defaults/${entity.IdGender === 2 ? 'female' : 'male'}.png`), ') no-repeat center center'].join('') }} />
            {player && (
                <div className={`white w-100 d-flex flex-column`} onClick={this.click}>
                    <div className="bg-black h-100 d-flex flex-column justify-content-center">
                        <span className="white font-14 line1 align-self-center">{player.NameFirst}</span>
                        <span className="white font-14 line1 align-self-center">{player.NameLast}</span>
                    </div>
                    <div className="d-flex flex-column justify-content-center h-100">
                        <span className={`align-self-center sqwad-font line1 font-30 ${entity_subscription ? 'black' : 'white'}`}>PRO</span>
                        {entity_subscription && <span className={`line 1 align-self-center montserrat font-8 ${entity_subscription ? 'gray' : 'white'}`}>{entity_subscription.Canceled ? 'Canceled - Valid until ' : 'Next Bill '}{moment(entity_subscription.StartDate.split('T')[0], 'YYYY-MM-DD').add(entity_subscription.Duration, 'days').format('MMM Do YYYY')} </span>}
                    </div>
                </div>)}
        </Animated>
    }
}

export default SubscriptionEntity;
