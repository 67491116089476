import React from 'react';
import GameDays from './game_days/GameDays';
import FieldsAvailability from './fields_availability';
import Exceptions from './exceptions/Exceptions';
import HomeFields from './home_fields/HomeFields';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class RightWorkArea extends React.Component {

  state = {
    activeTab: parseInt(localStorage.getItem('sportslogic.rules.tab') || 1)
  }

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
    localStorage.setItem('sportslogic.rules.tab', tab);
    this.props.notifyTabChange &&
      this.props.notifyTabChange(tab)
  }

  render() {
    const { activeTab } = this.state;
    return (<div className="bg-white h-100 right-side" style={{ marginLeft: 5, minHeight: 500 }}>
      <ul className="nav nav-tabs font-16">
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}><i className="fas fa-calendar-day blue"></i> {activeTab === 1 ? <span className="half-idented">Game Days</span> : ''}</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}><i className="fas fa-map-marked-alt green"></i> {activeTab === 2 ? <span className="half-idented">Location Availability</span> : ''}</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}><i className="fa fa-exclamation red"></i> {activeTab === 3 ? <span className="half-idented">Exceptions</span> : ''}</button>
        </li>
        {/*<li className="nav-item">
          <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={() => this.changeTab(4)}><i className="fa fa-home teal"></i> {activeTab === 4 ? <span className="half-idented">Home Fields Assignment</span> : ''}</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 5 ? 'active' : ''}`} onClick={() => this.changeTab(5)}><i className="fa fa-sitemap purple"></i> {activeTab === 5 ? <span className="half-idented">Playoff Availability</span> : ''}</button>
  </li>*/}
      </ul>
      <ReactCSSTransitionGroup className="tab-content" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
        transitionEnter={true} transitionLeave={false}>
        {activeTab === 1 && <GameDays key="gamedays" {...this.props} />}
        {activeTab === 2 && <FieldsAvailability key="fieldavailability" {...this.props} />}
        {activeTab === 3 && <Exceptions key="exceptions" {...this.props} />}
        {activeTab === 4 && <HomeFields key="homefields" {...this.props} />}
        {activeTab === 5 && <h3 key="playoffs" className="text-center">Playoff Availability</h3>}
      </ReactCSSTransitionGroup>
    </div>);
  }
}

export default RightWorkArea;