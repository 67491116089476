import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import { generateRandomId } from '../../helpers';

class LeagueItem extends React.Component {
  state = {
    league: null,
    communityIcon: null
  }

  componentWillMount() {
    const { league } = this.props;
    // TODO: complete all the communitites possible
    let communityIcon = '';
    switch (league.CommunityName) {
      case 'Soccer':
        communityIcon = 'fas fa-futbol'; break;
      case 'Football':
        communityIcon = 'fas fa-football-ball'; break;
      case 'Baseball':
        communityIcon = 'fas fa-baseball-ball'; break;
      default:
        break;
    }
    //
    this.setState({ league, communityIcon });
  }
  componentWillReceiveProps = nextProps => {
    const { league } = nextProps;
    this.setState({ league });
  }

  render() {
    const { selectLeague, index } = this.props, { league, communityIcon } = this.state;

    const style = league.fakeImage ? {} : {
      backgroundSize: '90%',
      background: `url(${league.LeagueImage}) no-repeat center center`,
    }

    return <Animated animationIn="bounceInUp" animationInDuration={600} animationInDelay={25 * index} className="">
      <div className="hoverable season-square card shadow sans" style={{ borderRadius: 10, overflow: 'hidden' }}>
        <Link to={`/leagues/${league.IdLeague}`}>

          { /* Name */}
          <span className="gray line1 league-name d-flex flex-row align-items-center text-center justify-content-center toUpper sqwad-font">{league.Name}</span>

          { /* Logo */}
          <div className="league-logo text-center ml-2 mr-2" style={style}>
            {league.fakeImage && <i className="fa fa-shield-alt align-self-center" style={{ fontSize: '8em', color: '#e5e5e5' }} />}
          </div>

        </Link>

        { /* Bottom options */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-9 d-flex flex-row">
              <i className={`${communityIcon} align-self-center black`} />
              <span className="align-self-center idented-half black">{league.CommunityName}</span>
            </div>
            <button onClick={() => {
              selectLeague(league)
            }} className="col-3 btn btn-link">
              <i className="fa fa-cog" />
            </button>
          </div>
        </div>
      </div>
    </Animated>
  }
}

export default LeagueItem;
