import React from 'react';

const Division = ({ selectedDivision, onClick }) => {
    return (
        <div className="d-flex flex-row justify-content-start montserrat" style={{ height: 60 }}>
            <div style={{ width: 80 }} className="d-flex flex-row justify-content-center">
                <i className={`icon-${selectedDivision.IdGender === 1 ? 'man' : 'woman'} ${selectedDivision.IdGender === 1 ? 'blue' : 'red'} font-20 align-self-center`} />
            </div>
            <div className="d-flex flex-column align-self-center">
                <span className="black font-12 line1">{selectedDivision.DivisionName || selectedDivision.Name}</span>                
            </div>
            { onClick && <span className="ml-auto blue mr-4 font-10 align-self-center pointer" onClick={onClick}>Change</span> }
        </div>
    );
}

export default Division;
