import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import fakeAuth from '../../../fakeAuth';
import TeamItem from './partials/TeamItem.js';
import ArchivedTeam from './partials/ArchivedTeam';
import Loader from '../../common/Loader';
import './css.css';
import CreateTeam from '../teams/CreateTeam';
import PendingValidations from '../../common/modals/FamilyPendingValidations';

class Roster extends React.Component {

    state = {
        searchText: '',
        selectedTeam: null,
        teams: null,
        validations: null,
        user: fakeAuth.getUser(),
        bootstrapped: false,
        folders: []
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle } = this.props;
        setTitle && setTitle({ main: 'Roster' });
        this.props.leagueActions && this.props.leagueActions.fetchPendingValidations();
    }

    componentWillReceiveProps = (nextProps) => {
        const { teams = [], validations } = nextProps;
        this.setState({ teams: _.chain(teams).groupBy('IdTeam').values().value().reverse(), bootstrapped: teams ? true : false, validations });
    }
    //        

    filter = () => this.setState({ searchText: this.txtSearch.value });

    addTeam = () => this.setState({ isCreatingTeam: !this.state.isCreatingTeam })

    onSearch = () => this.setState({ search_text: this.txtSearch.value });

    onAddFolder = () => {
        const { folders } = this.state;
        this.setState({ folders: [{ FolderName: this.txtNewFolder.value || 'New Folder' }, ...folders], adding_folder: false })
    }

    onArchived = () => { this.setState({ archived: true }); }

    render() {

        const { teamActions, familyActions, archived_teams } = this.props,
            { bootstrapped, teams, isCreatingTeam, validations, folders } = this.state;

        let filtered_teams = _.chain(teams || [])
            .filter(([t]) => {
                if (this.txtSearch && this.txtSearch.value) {
                    return (t.TeamName || t.Name).toLowerCase().indexOf(this.txtSearch.value.toLowerCase()) !== -1;
                }
                return true;
            }).value();

        return (
            <section>

                {/* Teams */}
                {!this.state.folder && <Animated isVisible={this.state.folder ? false : true} animationIn="fadeInLeft" animationOut="fadeOutLeft" animateOnMount={true} className="w3-container mt-4">
                    <div className="w3-content d-flex flex-column montserrat pb-4">

                        {/* Pending Validations */}
                        {validations && validations.length > 0 && <div className="pl-2 pr-2">
                            <div className="w3-container mt-4">
                                <div className="w3-content d-flex flex-column montserrat">
                                    <div className="w-100 black">
                                        <h1>Pending Validations</h1>
                                        <h6 className="block">You've some pending validations</h6>
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-center margin-bottom mt-4">
                                        <ul className="w-100">
                                            {_.chain(validations).groupBy('IdUser').values().value().map(([r], i) => <li key={i} className="card shadow-box mt-2 py-3 px-3 w-100">
                                                <div onClick={() => this.setState({ checking_validations: true })} className="d-flex flex-row w-100 pointer">
                                                    <div className="align-self-center cover d-flex flex-column" style={{ width: 80, height: 80, borderRadius: 40, background: `url(${r.user ? r.user.UserImage : ''}?full=80) no-repeat center center black` }} />
                                                    <div className="d-flex flex-column align-self-center ml-4">
                                                        <span className="font-12">{`${r.user.NameFirst} ${r.user.NameLast}`}</span>
                                                        <span className="font-10 black">Has some pending validations</span>
                                                    </div>
                                                    <i className="ml-auto fas fa-chevron-right font-20 black align-self-center" />
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>}

                        {/* Search bar */}
                        <div className="d-flex flex-row mb-2">
                            <div className="w-100 black align-self-center">
                                <h1>{`${this.state.archived ? 'Archived ' : ''}Teams`}</h1>
                                {!this.state.archived && <h6 className="block">Select a team you'd like to manage</h6>}
                                {this.state.archived && <h6 className="block">Click on a team to restore it</h6>}
                            </div>

                            <div className="w-100 d-flex flex-row align-self-center">
                                {/*<i title="Make a new folder" className="pointer fas fa-folder-plus blue mr-3 font-24 align-self-center" onClick={() => this.setState({ adding_folder: true })} />*/}
                                <input type="text" className="form-control align-self-center" onChange={this.onSearch} placeholder="Search for Teams" ref={i => this.txtSearch = i} />
                            </div>
                        </div>

                        {/* Folders */}
                        {this.state.adding_folder && <div className="d-flex flex-row p-3 pointer" style={{ borderBottom: '1px solid #666' }}>
                            <i className="fas fa-folder blue font-16 align-self-center" />
                            <input type="text" className="form-control ml-2" placeholder="New Folder" ref={i => this.txtNewFolder = i} />
                            <button className="btn btn-danger ml-auto" style={{ width: 80 }} onClick={() => this.setState({ adding_folder: false })}><i className="fas fa-times" /></button>
                            <button className="btn btn-success" style={{ width: 80 }} onClick={this.onAddFolder}><i className="fas fa-check" /></button>
                        </div>}
                        {folders && folders.map((folder, i) => <div onClick={() => this.setState({ folder })} key={i} className="d-flex flex-row p-3 pointer" style={{ borderBottom: '1px solid #666' }}>
                            <i className="fas fa-folder blue font-16 align-self-center" />
                            <span className="align-self-center ml-2">{folder.FolderName}</span>
                            <i className="fas fa-ellipsis-v ml-auto align-self-center"></i>
                        </div>)}
                        {/* // Folders */}

                        {bootstrapped && filtered_teams.length === 0 && !this.state.archived && (
                            <div key="no-leagues" className="d-flex flex-col justify-content-center" style={{ height: 300 }}>
                                <h1 className="align-self-center block text-center">Seems like you have no teams to roster{this.txtSearch.value ? ` named ${this.txtSearch.value}` : ``}</h1>
                            </div>
                        )}

                        {bootstrapped && !this.state.archived && (
                            <div key="teams" className="d-flex justify-content-start flex-wrap my-4">
                                <div className="league-square card" onClick={this.addTeam}>
                                    { /* ADD */}
                                    <div className="league-logo text-center mt-4" style={{ position: 'relative' }}>
                                        <div style={{ position: 'absolute' }} className="d-flex flex-row justify-content-center h-100 w-100">
                                            <i className="fa fa-plus jeff_blue font-120 align-self-center" />
                                        </div>
                                    </div>
                                    <span className="gray league-name d-flex flex-row align-items-center text-center justify-content-center" style={{ lineHeight: 1 }}>ADD TEAM</span>
                                </div>
                                {filtered_teams.map((team, i) => <TeamItem key={team.IdTeam || i} index={i} team={team} teamActions={teamActions} familyActions={familyActions} />)}
                                <div className="league-square card" onClick={this.onArchived}>

                                    <div className="league-logo text-center mt-4" style={{ position: 'relative' }}>
                                        <div style={{ position: 'absolute' }} className="d-flex flex-row justify-content-center h-100 w-100">
                                            <i className="fa fa-archive jeff_blue font-120 align-self-center" />
                                        </div>
                                    </div>
                                    <span className="gray league-name d-flex flex-row align-items-center text-center justify-content-center" style={{ lineHeight: 1 }}>ARCHIVED TEAMS</span>
                                </div>
                            </div>
                        )}

                        {bootstrapped && this.state.archived && (
                            <div key="archived_teams" className="d-flex justify-content-start flex-wrap my-4">
                                <div className="league-square card" onClick={() => this.setState({ archived: false })}>
                                    <div className="league-logo text-center mt-4" style={{ position: 'relative' }}>
                                        <div style={{ position: 'absolute' }} className="d-flex flex-row justify-content-center h-100 w-100">
                                            <i className="fa fa-arrow-left jeff_blue font-120 align-self-center" />
                                        </div>
                                    </div>
                                    <span className="gray league-name d-flex flex-row align-items-center text-center justify-content-center" style={{ lineHeight: 1 }}>BACK</span>
                                </div>
                                {archived_teams && archived_teams.map((team, i) => <ArchivedTeam key={team.IdTeam} index={i} team={[team]} teamActions={teamActions} familyActions={familyActions} />)}
                            </div>
                        )}

                        {!bootstrapped && <Loader key="loader" textColor="black" message={`Loading Teams`} />}

                    </div>
                </Animated>}
                <Animated isVisible={this.state.folder ? true : false} animationIn="fadeInRight" animationOut='fadeOutRight' animateOnMount={false} className="w3-container mt-4">
                    <div className="w3-content d-flex flex-column montserrat pb-4">
                        <div onClick={() => this.setState({ folder: null })} className="d-flex flex-row p-3 pointer" style={{ borderBottom: '1px solid #666' }}>
                            <i className="fas fa-folder blue font-16 align-self-center" />
                            <span className="align-self-center ml-2">{(this.state.folder || {}).FolderName}</span>
                            <i className="fas fa-ellipsis-v ml-auto align-self-center"></i>
                        </div>
                    </div>
                </Animated>
                {isCreatingTeam && <CreateTeam {...this.props} isOpen={isCreatingTeam} toggle={this.addTeam} />}

                {this.state.checking_validations && <PendingValidations
                    leagueActions={this.props.leagueActions}
                    validations={this.state.validations}
                    rosterActions={this.props.rosterActions}
                    toggle={() => this.setState({ checking_validations: false })} />}

            </section>
        )
    }
}

export default Roster;
