import React from 'react';
import { Link } from 'react-router';
import './seasons.css';
import Moment from 'react-moment';
import moment from 'moment';
import config from '../../config';
import _ from 'lodash';
import { Animated } from 'react-animated-css';

class SeasonItem extends React.Component {

  state = {
    closed: false,
    timezones: config.timezones
  }

  // Lifecycle
  componentWillMount() {
    this.setup(this.props.season);
  }
  componentWillReceiveProps = nextProps => {
    this.setup(nextProps.season);
  }
  //

  setup = season => {
    if (moment(season.RegistrationEnd).isBefore(moment())) {
      this.setState({ closed: true });
    } else {
      this.setState({ closed: false });
    }
  }

  render() {
    const { season, fnEdit, index, medals } = this.props,
      { closed } = this.state;

    const color = (config.seasonColors[season.SeasonType] || 'yellow'),
      styleSeasonType = {
        backgroundColor: color,
        color: 'white',
      };

    return (
      <Animated animationIn="bounceInUp" animationInDelay={50 * index} key={season.IdSeason}>
        <div className="hoverable season-square card shadow sans" style={{ borderRadius: 10, overflow: 'hidden' }}>

          <div className="white d-flex flex-row" style={styleSeasonType}>
            {season.BrandNew && (
              <div className="ribbon-wrapper">
                <div className="ribbon">NEW</div>
              </div>)}
            <strong className="w-100 text-center align-self-center sqwad-font">{season.SeasonType.toUpperCase()}</strong>
            <a target="_blank" rel="noopener noreferrer" href={`${[config.publicPageEndpoint, 'schedules?idSeason=' + season.IdSeason].join('/')}`} className="font-14 text-center ml-auto mr-2 white align-self-center"><i className="fa fa-link"></i></a>
          </div>

          <Link to={`/season/${season.IdSeason}`}>

            <div className="gray season-name d-flex flex-column align-items-center justify-content-center text-center" style={{ backgroundColor: '#cccccc' }}>
              <span className={`toUpper line1 black toBold sqwad-font ${season.Name.length > 30 ? 'font-10' : 'font-12'}`} style={{ lineHeight: '1' }}>{season.Name.toUpperCase()}</span>
              <span className="font-8 mt-2 line1 black">( ID: {season.IdSeason} )</span>
            </div>

            {/* DATES */}
            <div className="d-flex flex-column p-2">
              <span className="toBold black font-10">Date</span>
              <span className="black font-10 line1">
                <Moment format="MMM DD, YYYY">
                  {season.DateStart.utc()}
                </Moment>
                <span className="font-10 align-self-center ml-2 mr-2">to</span>
                <Moment format="MMM DD, YYYY">
                  {season.DateEnd.utc()}
                </Moment>
              </span>
              <span className="toBold black font-10 mt-2">Schedule</span>
              {season.ScheduleStartDate && <span className="black font-10 line1">
                <Moment format="MMM DD, YYYY">
                  {(season.ScheduleStartDate || season.DateStart)}
                </Moment>
                <span className="font-10 align-self-center ml-2 mr-2">to</span>
                <Moment format="MMM DD, YYYY">
                  {(season.ScheduleDateEnd || season.DateEnd)}
                </Moment>
              </span>}
              {!season.ScheduleStartDate && <span className="red font-10 line1">N/A</span>}





              <div className="d-flex flex-row">





                <div className="d-flex flex-column w-100" title={`${moment(season.RegistrationEnd).utc()} UTC`}>
                  <span className="toBold black font-10 mt-2">Registration {closed ? 'CLOSED' : 'OPEN'} <i className={`fa fa-${closed ? 'lock red' : 'lock-open green'}`} /></span>
                  <span className="black font-10 line1">
                    <Moment format=" MMM DD, YYYY @ hh:mmA">
                      {moment(season.RegistrationEnd).utc().add(season.TimeOffset || 0, 'minutes')}
                    </Moment>
                  </span>
                  <span className="font-8 line1 black">{((season.TimeOffset !== null && _.find(this.state.timezones, tz => tz[season.USE_DLS ? 'daylight_offset' : 'value'] === parseInt(season.TimeOffset, 10))) ? _.find(this.state.timezones, tz => tz[season.USE_DLS ? 'daylight_offset' : 'value'] === parseInt(season.TimeOffset, 10)).title : 'UTC')}{season.USE_DLS ? ' - Daylight Savings' : ''}</span>
                </div>








                {medals && <div style={{ width: 40 }} className="">
                  {_.find(medals, m => m.IdMedal === season.IdMedal) && <img alt="" style={{ height: 40 }} src={`/images/medals/${_.find(medals, m => m.IdMedal === season.IdMedal).MedalImage}_on.png`} className="align-self-center" />}
                </div>}
              </div>






            </div>

            {/* TOTALS */}
            <div className="p-2 d-flex flex-row">
              <div className="w-100 bg-gray d-flex flex-column justify-content-center black p-2" style={{ borderRadius: 5 }}>
                <span className="font-10 align-self-center line1">TEAMS</span>
                <span className="font-20 align-self-center line1">{season.TotalTeams}</span>
              </div>
              <div style={{ width: 15 }} />
              <div className="w-100 bg-gray d-flex flex-column justify-content-center black p-2" style={{ borderRadius: 5 }}>
                <span className="font-10 align-self-center line1">PARTS.</span>
                <span className="font-20 align-self-center line1">{season.IdSeasonType === 3 ? season.TotalParticipatingTeams : season.TotalPlayers}</span>
              </div>
            </div>

          </Link>

          <div className="d-flex flex-row p-2 mb-2">
            <div className="w-100">
              <button onClick={fnEdit} type="button" style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green w-100 p-2 align-self-center">
                Edit</button>
            </div>
            <div style={{ width: 15 }} />
            <Link to={`/season/${season.IdSeason}`} className="w-100">
              <button type="button" style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green w-100 p-2 align-self-center">
                See More</button>
            </Link>
          </div>

        </div>
      </Animated>
    )
  }
}

export default SeasonItem;
