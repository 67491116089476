import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { generateRandomId } from '../../../../../../helpers';
import Program from './card';

class AdjustmentBigIcon extends React.Component {

  state = {
    popupverOpen: false,
    removed: false,
    randomId: `ID-${generateRandomId(10)}`
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  toggleConfirming = () => {
    this.setState({
      confirming: !this.state.confirming
    });
  }

  remove = () => {
    const { program, division } = this.props;
    this.props.fnRemove && this.props.fnRemove();
    /*this.props.divisionsActions &&
      this.props.divisionsActions.removeAdjustment(program, division);*/
      // TODO: call api
  }

  render() {
    const { program, index } = this.props;
    const { popoverOpen, randomId, confirming } = this.state;

    return (
      <i onClick={this.toggle} className={`ml-2 mt-2 font-60 fas fa-wrench purple`} data-tip={`${(program || {}).Name}`} data-for={`rule${index}`} id={`${randomId}`}>
        <ReactTooltip effect="solid" place="top" id={`rule${index}`} />
        <Popover placement="top" isOpen={popoverOpen} target={`${randomId}`} toggle={this.toggle}>
          <PopoverBody className="d-flex flex-column justify-content-end">
            <button className="btn btn-link btn-sm blue no-margin no-padding text-right" onClick={this.toggle}>close</button>
            <Program program={program} unmovable={true} />
            <button id={`confirm${index}`} className="btn btn-danger btn-sm btn-block font-8" onClick={this.remove}>Remove this Adjustment ?</button>

            <Popover placement="top" isOpen={confirming} target={`confirm${index}`} toggle={this.toggleConfirming}>
              <PopoverHeader>Are you sure?</PopoverHeader>
              <PopoverBody className="no-padding">
                <button className="btn btn-sm btn-block btn-success no-margin">Yes, remove it</button>
              </PopoverBody>
            </Popover>
          </PopoverBody>
        </Popover>
      </i>
    );
  }
}

export default AdjustmentBigIcon;