import _ from 'lodash';

function scout_registrations(state = [], action) {
    switch (action.type) {
        case 'SCOUT_REGISTRATIONS/SET':
            return action.scout_registrations;
        case 'SCOUT_REGISTRATIONS/CLEAN':            
            return null;
        default:
            return state;
    }
}

export default scout_registrations;