import React from 'react';
import { DropTarget } from 'react-dnd';
import ListItem from './RosteredCoaches';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { ItemTypes } from '../../../../../helpers';

const target = {

  canDrop(props, monitor) {
    const {item} = monitor.getItem();
    return (item.IdRoleStatus !== 2) ? true : false;
  },

  drop(props, monitor) {

    // Dropped on one of the childs
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }
    //

    props.fnDrop && props.fnDrop(monitor.getItem().item, monitor.getItem().index);
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

class UpperList extends React.Component {
  render() {

    const {connectDropTarget, isOver, canDrop, items} = this.props;

    return connectDropTarget(
      <figure style={ { borderTop: '20px solid gray', position: 'absolute', top: 0, bottom: '50%', left: 0, right: 0, border: canDrop ? '3px solid red' : null } } className={ `bg-white no-margin ${isOver ? 'bg-warning' : (canDrop ? 'bg-danger' : 'none')}` }>
        { items && <div className="d-flex bg-info p-2 d-flex flex-row white" style={ { position: 'absolute', top: 0, width: '100%' } }>
                     <span>Accepted Coaches</span>
                     <span className="idented-half">({ items.length })</span>
                   </div> }
        <table className="table table-bordered table-sm font-8 table-hover block w-100 no-margin" style={ { position: 'absolute', top: 40, bottom: 0, overflow: 'auto' } }>
          <ReactCSSTransitionGroup component="tbody" transitionName="example" transitionAppear={ true } transitionLeaveTimeout={ 500 } transitionAppearTimeout={ 500 }
            transitionEnterTimeout={ 500 } transitionEnter={ true } transitionLeave={ false } className="w-100" style={ { display: 'table' } }>
            <tr className="bg-gray">
              <th>Id</th>
              <th>Full Name</th>
              <th>Status</th>
              <th>DoB</th>
              <th>Current Team</th>
              <th>Coach Type</th>
              <th>Gender</th>
              <th>Contact</th>
              <th>City</th>
              <th>State</th>
              { this.props.questions && this.props.questions.map((q, i) => (
                  <th key={ i }>
                    { q.QuestionText }
                  </th>)) }
              <th></th>
            </tr>
            { this.props.items && this.props.items.map((item, index) => (
                <ListItem {...this.props} key={ index } item={ item } index={ index } />
              )) }
          </ReactCSSTransitionGroup>
        </table>
      </figure>

    )
  }
}

export default DropTarget(ItemTypes.NONROSTEREDPLAYER, target, collect)(UpperList);
