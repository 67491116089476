import React from 'react';
import _ from 'lodash';
import CustomGroupPlacementDraggable from './CustomGroupPlacementDraggable';

class RegistrationPlacements extends React.Component {
    state = {};
    componentWillMount() {
        const { customGroups, teams = [] } = this.props;
        customGroups.forEach(group => {
            group.teams = _.filter(teams, t => t.IdCustomGroup === group.IdCustomGroup);
        })
        this.setState({ customGroups });
    }
    componentWillReceiveProps = nextProps => {
        const { customGroups, teams = [] } = nextProps;
        customGroups.forEach(group => {
            group.teams = _.filter(teams, t => t.IdCustomGroup === group.IdCustomGroup);
        })
        this.setState({ customGroups });
    }
    renderFuckingGroup = (group, index) => {
        const { placements } = this.props;

        return <section key={index}>
            <ul className="block d-flex flex-column no-style-lists no-margin no-padding bg-white margin-bottom-half">
                {group.teams && group.teams.map((team, i) => (
                    <CustomGroupPlacementDraggable key={i} index={i + 1} mode={1} group={group} isUsingNextBest={false}
                        placements={placements} id={group.IdCustomGroup} />))}
            </ul>
        </section>
    }
    render() {
        const { customGroups } = this.state;
        return (
            <section>
                {customGroups && customGroups.length > 0 && customGroups.map((t, index) => this.renderFuckingGroup(t, index))}
                {(!customGroups || !customGroups.length) && <div className="jumbotron no-margin"><i className="fa fa-exclamation" /> There are no Custom Groups.</div>}
            </section>
        )
    }
}

export default RegistrationPlacements;
