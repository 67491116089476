import React from 'react';
import { Animated } from 'react-animated-css';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerFullLabel';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import CreateLocation from './CreateLocation';

class AddLocation extends React.Component {
    state = {

    }
    componentWillMount() {
        const { locations } = this.props;
        this.setState({ locations });
        setTimeout(() => {
            this.setState({ bootstrapped: true });
        }, 200);
    }
    componentWillReceiveProps = nextProps => {
        const { locations } = nextProps;
        this.setState({ locations });
    }
    render() {
        const { locations } = this.state;
        return <section>

            <section className="fullscreen" style={{ zIndex: 10003 }}></section>
            <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={this.state.bootstrapped ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10004, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">
                {/* HEADER */}
                <div className="bg-dark-blue px-4 py-3 d-flex flex-row">
                    <i className="fas fa-arrow-left white font-16 pointer align-self-center" onClick={() => {
                        this.setState({ bootstrapped: false }, () => {
                            setTimeout(this.props.toggle, 200);
                        });
                    }} />
                    <span className="montserrat white font-14 ml-4 align-self-center">LOCATION</span>
                </div>
                <div onClick={() => this.setState({ creating: true })} className="pointer mx-2 card tile mt-2 px-2 py-4 d-flex flex-row justify-content-center">
                    <i className="fas fa-circle-plus black" />
                    <span className="align-self-center ml-2 line1">Create new Location</span>
                </div>
                {locations && locations.map((o, i) => <section key={i}>
                    <div className="pointer mx-2 card tile mt-2 px-2 py-4 d-flex flex-row" >
                        <div className="d-flex flex-row" onClick={() => this.props.fn(o)}>
                            <i className="ml-2 fas fa-map-marker green" />
                            <span className="align-self-center ml-3 line1">{o.LocationName}</span>
                        </div>
                        <button className={`btn btn-link ml-auto`}>
                            <i className={`fas fa-chevron-down ml-auto ${o.fields && o.fields.length ? 'black' : 'white'}`} />
                        </button>
                        <button onClick={() => {
                            this.setState({ entity: o, creating: true });
                        }} className={`${o.fields.length ? '' : `ml-auto`}`}>
                            <i className="fas fa-edit" />
                        </button>
                    </div>
                    {o.fields.map((field, fi) => <div key={fi} style={{ marginLeft: 80 }} className="pointer mr-2 card tile mt-2 px-2 py-3 d-flex flex-row" onClick={() => this.props.fn(field)}>
                        <div className="d-flex flex-row w-100" >
                            <i className="ml-2 fas fa-map-marker green" />
                            <span className="align-self-center ml-3 line1">{field.LocationName}</span>
                        </div>
                    </div>
                    )}
                </section>)}
            </Animated>

            {this.state.creating && <CreateLocation
                toggle={() => this.setState({ creating: false, entity: null })}
                entity={this.state.entity}
                fnAdded={(location) => this.setState({ creating: false, entity: null }, () => {
                    this.props.teamActions.fetchLocations();
                })}
            />}
        </section>
    }
}

export default AddLocation;