import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

class UserModules extends React.Component {

  state = {
    mode: 1, overlay: false
  }

  componentWillMount() {
    const { setTitle, params } = this.props;
    setTitle && setTitle({ main: '' });
    this.props.leagueActions && this.props.leagueActions.clearLeague();
    this.props.leagueActions && this.props.leagueActions.clearLeagues();
    this.props.familyActions && this.props.familyActions.clearSelectedUser();
    this.props.familyActions && this.props.familyActions.fetchWaivers();
    this.props.teamActions && this.props.teamActions.clearSelectedTeam();
    this.props.teamActions && this.props.teamActions.fetchFamilyTeams();
    this.props.rosterActions && this.props.rosterActions.clearRoster();
    this.props.leagueActions && this.props.leagueActions.fetchPendingRegistrations();
    this.props.familyActions && this.props.familyActions.getFamilySubscriptions();
    this.setState({ mode: (params && params.mode) ? 2 : 1 })
  }

  render() {
    const { mode } = this.state, { teams, registrations, family_subscriptions, waivers } = this.props;
    let unique_teams = _.values(_.groupBy(_.filter(teams || [], t => (t.IsTeamAdmin || t.IsTeamOwner)), 'IdTeam'));

    return (
      <div className="montserrat bg-dark-blue h-100 w-100" id="user-modules">

        {/* MAIN */}
        <Animated animationInDuration={300} animationOutDuration={300} isVisible={(mode === 1 && waivers) ? true : false} animateOnMount={false} animationIn="fadeInLeft" animationOut="fadeOutLeft" className="d-flex flex-column justify-content-center centered-message" style={{ overflowY: 'auto' }}>

          <h3 className="w3-hide-small white titan align-self-center mb-2 mx-4 text-center">For increased functionality, please<br />download "The Sqwad" App for <a className="blue" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/the-sqwad/id1506954207">iOS</a> and <a className="blue" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.trycatch.squad.app">Android</a></h3>

          <ul className="mx-auto  align-self-center d-flex flex-wrap mt-4 justify-content-center" >

            <li className="tile bg-white card shadow-box" style={{ borderRadius: 6, overflow: 'hidden' }}>
              <Animated animationIn="fadeIn" animationInDelay={0} className="w-100 h-100">
                <Link to="/roster" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                  <i className="icon-hands_top align-self-center" style={{ fontSize: '3.5em', lineHeight: '64px' }} />
                  <span className=" align-self-center margin-top-half line1 text-center">Teams and Roster</span>
                </Link>
              </Animated>
            </li>

            <li className="tile bg-white card shadow-box" style={{ borderRadius: 6, overflow: 'hidden' }}>
              <Animated animationIn="fadeIn" animationInDelay={50} className="w-100 h-100">
                <Link to="/registration" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                  <i className={`icon-enter align-self-center ${registrations && registrations.length ? 'mt-auto' : ''}`} style={{ fontSize: '4.5em', lineHeight: '64px' }} />
                  <span className=" align-self-center margin-top-half line1 text-center">Registration and Payments</span>
                  {registrations && registrations.length > 0 && <div className="bg-warning mt-auto"><span className="black line1 font-10">Pending registrations</span></div>}
                </Link>
              </Animated>
            </li>

            <li className="tile bg-white card shadow-box" style={{ borderRadius: 6, overflow: 'hidden' }}>
              <Animated animationIn="fadeIn" animationInDelay={150} className="w-100 h-100">
                <Link to="/profile/members" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                  <i className="icon-Family_icon align-self-center " style={{ fontSize: '7em', lineHeight: '64px' }} />
                  <span className=" align-self-center margin-top-half line1">Family & Profiles</span>
                </Link>
              </Animated>
            </li>

            {waivers && waivers.length > 0 && <li className="tile bg-white card shadow-box" style={{ borderRadius: 6, overflow: 'hidden' }}>
              <Animated animationIn="fadeIn" animationInDelay={150} className="w-100 h-100">
                <Link to="/family/waivers" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100">
                  <i className={`icon-report align-self-center ${(waivers && _.find(waivers, w => w.IsPending)) ? 'mt-auto' : ''}`} style={{ fontSize: '4em', lineHeight: '64px' }} />
                  <span className=" align-self-center margin-top-half line1">Waivers</span>
                  {waivers && _.find(waivers, w => w.IsPending) && <div className="bg-warning mt-auto"><span className="black line1 font-10">Pending waivers</span></div>}
                </Link>
              </Animated>
            </li>}

            <li className="tile premium-gradient card shadow-box" style={{ borderRadius: 6, overflow: 'hidden' }}>
              <Animated animationIn="fadeIn" animationInDelay={150} className="w-100 h-100">
                <button onClick={() => this.setState({ mode: 2 })} className="black align-self-center justify-content-center d-flex flex-column w-100 h-100">
                  <i className="icon-pito align-self-center " style={{ fontSize: '5em', lineHeight: '64px' }} />
                  <span className=" align-self-center margin-top-half line1">RECRUITMENT</span>
                </button>
              </Animated>
            </li>

          </ul>

        </Animated>

        {/* RECRUITMENT */}
        <Animated animationInDuration={300} animationOutDuration={300} isVisible={mode === 2} animateOnMount={false} animationIn="fadeInRight" animationOut="fadeOutRight" className=" centered-message-normal w3-container" style={{ overflowY: 'auto' }}>

          <div className="w3-content d-flex flex-row justify-content-center">
            <ul className="align-self-center d-flex flex-column ">

              <li className="mx-auto w-100">
                <h3 className="white d-flex justify-content-center" onClick={() => this.setState({ mode: 1 })}>
                  <i className="font-30 white fas fa-arrow-left aligns-self-center" />
                  <span className="align-self-center ml-auto">RECRUITMENT</span>
                  <i className="font-30 transparent ml-auto fas fa-arrow-left aligns-self-center" />
                </h3>
              </li>

              <li className="premium-gradient card shadow-box mt-3 recruitment-tile pointer mx-auto" style={{ borderRadius: 6, overflow: 'hidden', maxWidth: 455, height: 50 }}>
                <Link onClick={() => this.setState({ overlay: true, manage_subs: true })} className="align-self-center justify-content-center d-flex flex-column w-100 h-100 p-4">
                  <span className='font-16 black'>{(family_subscriptions && family_subscriptions.length) ? 'MANAGE SUBSCRIPTION' : 'UPGRADE TO PRO'}</span>
                </Link>
              </li>

              <li className="bg-white card shadow-box  mt-3 recruitment-tile pointer mx-auto" style={{ borderRadius: 6, overflow: 'hidden', maxWidth: 455, minHeight: 100 }}>
                <Link to="/sqwad/family/college_profile" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100 p-4">
                  <div className="d-flex flex-row">
                    <i className="icon-recreational_icon align-self-center green mr-4 line1" style={{ fontSize: '2.5em' }} />
                    <span className="text-left align-self-center line1 font-30">Athlete Profile</span>
                  </div>
                  <span className="text-left line1 mt-2">Update your personal recruitment profile to showcase</span>
                </Link>
              </li>

              <li className="bg-white card shadow-box  mt-3 recruitment-tile pointer mx-auto" style={{ borderRadius: 6, overflow: 'hidden', maxWidth: 455, minHeight: 100 }}>
                {unique_teams.length > 0 && <button onClick={() => this.setState({ overlay: true, studio: true })} className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100 p-4">
                  <div className="d-flex flex-row">
                    <img alt="" src="/images/icons/camera.png" className="align-self-center mr-4" style={{ maxWidth: 53 }} />
                    <span className="text-left align-self-center line1 font-24">Studio | Highlights</span>
                  </div>
                  <span className="text-left line1 mt-2">Manage your videos and highlights for recruitment purposes</span>
                </button>}
                {unique_teams.length === 0 && <Link to={`/sqwad/family`} className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100 p-4">
                  <div className="d-flex flex-row">
                    <img alt="" src="/images/icons/camera.png" className="align-self-center mr-4" style={{ maxWidth: 53 }} />
                    <span className=" align-self-center line1 font-30">Studio | Filming</span>
                  </div>
                  <span className="text-left line1 mt-2">Manage your videos and highlights for recruitment purposes</span>
                </Link>}
              </li>

              <li className="bg-white card shadow-box  mt-3 recruitment-tile pointer mx-auto" style={{ borderRadius: 6, overflow: 'hidden', maxWidth: 455, minHeight: 100 }}>
                <Link to="/sqwad/brochures" className="jeff_blue align-self-center justify-content-center d-flex flex-column w-100 h-100 p-4">
                  <div className="d-flex flex-row">
                    <i className="icon-book-open align-self-center blue mr-4 line1" style={{ fontSize: '2.5em' }} />
                    <span className=" align-self-center line1 font-30">Team Brochure</span>
                  </div>
                  <span className="text-left line1 mt-2">Review your team's brochures for sharing with others</span>
                </Link>
              </li>

            </ul>
          </div>

        </Animated>

        {this.state.overlay && <div onClick={() => this.setState({ overlay: false, manage_subs: false, studio: false })} className={`overlay ${this.state.overlay ? 'show' : ''}`} />}

        {/* PICK STUDIO */}
        <Animated animationIn="slideInRight" animationInDuration={300} animationOutDuration={300} animateOnMount={false} animationOut="slideOutRight" isVisible={this.state.studio ? true : false} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, zIndex: 1041 }} className="bg-white right-menu" >
          <div className="w-100 h-100 d-flex flex-column justify-content-center p-4">
            <Link to="/sqwad/family" style={{ height: 150, backgroundColor: '#e5e5e5' }} className="w-100 card shadow-box">
              <div className="d-flex flex-column justify-content-center h-100">
                <i className="icon-followers font-40 black align-self-center" />
                <span className="black font-20 align-self-center line1">Family</span>
                <span className="black font-12 align-self-center line1">Studio</span>
              </div>
            </Link>
            <Link to="/sqwad" style={{ height: 150, backgroundColor: '#e5e5e5' }} className="w-100 card shadow-box mt-4">
              <div className="d-flex flex-column justify-content-center h-100">
                <i className="icon-sportsman font-40 black align-self-center" />
                <span className="black font-20 align-self-center line1">Team</span>
                <span className="black font-12 align-self-center line1">Studio</span>
              </div>
            </Link>
          </div>
        </Animated>

        {/* PREMIUM */}
        <Animated animationIn='fadeInRight' animateOnMount={false} animationInDuration={300} animationOutDuration={300} animationOut='slideOutRight' isVisible={this.state.manage_subs ? true : false} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: 500, zIndex: 1041 }} className="bg-white">

          <div className="d-flex flex-column justify-content-center h-100">
            <Link to="/sqwad/family/subscriptions" className="mt-3 bg-white card shadow-box align-self-center" style={{ borderRadius: 6, overflow: 'hidden', width: 455 }}>
              <div className='p-4'>
                <div className="d-flex flex-row">
                  <i className="icon-recreational_icon align-self-center green mr-4 line1" style={{ fontSize: '2.5em' }} />
                  <div className="d-flex flex-column">
                    <span className="line1 font-24 toBold">Athlete</span>
                    <span className="line1 font-16 sqwad-font">SQWAD PRO</span>
                  </div>
                </div>
                <div className='mt-4'>Get Recruited!</div>
                <ul>
                  <li>- Create your own clips</li>
                  <li>- Create highlight reel for Digital Profile</li>
                  <li>- Video Review and Playback</li>
                  <li>- BOOST your profile increase your exposure</li>
                </ul>
              </div>
              <div className='bg-gray p-4 d-flex flex-column'>
                <span className="align-self-center sqwad-font font-16 line1">SQWAD PRO ATHLETE</span>
                <div className="d-flex flex-row align-self-center">
                  <span className='font-20 align-self-center line1'>$19.99</span>
                  <span className="font-10 align-self-center line1 ml-2">per month</span>
                </div>
                <span className="font-8 align-self-center line1">Auto-renewed every 30 days</span>
              </div>
            </Link>
            <Link className="mt-3 bg-white card shadow-box align-self-center" style={{ borderRadius: 6, overflow: 'hidden', width: 455 }}>
              <div className='p-4'>
                <div className="d-flex flex-row">
                  <i className="icon-hands_top align-self-center jeff_blue mr-4 line1" style={{ fontSize: '2.5em' }} />
                  <div className="d-flex flex-column">
                    <span className="line1 font-24 toBold">Team</span>
                    <span className="line1 font-16 sqwad-font">SQWAD PRO</span>
                  </div>
                </div>
                <div className='mt-4'>Coach | Team Managers Dream</div>
                <ul>
                  <li>- Video upload and playback</li>
                  <li>- No more PAPER brochures</li>
                  <li>- Digital Brochures always up to date</li>
                  <li>- QR Code for quick scanning</li>
                  <li>- Auto notification of coach list</li>
                </ul>
              </div>
              <div className='bg-gray p-4 d-flex flex-column'>
                <span className="align-self-center sqwad-font font-16 line1">SQWAD PRO TEAM</span>
                <div className="d-flex flex-row align-self-center">
                  <span className='font-20 align-self-center line1'>$19.99</span>
                  <span className="font-10 align-self-center line1 ml-2">per month</span>
                </div>
                <span className="font-8 align-self-center line1">Auto-renewed every 30 days</span>
              </div>
            </Link>
          </div>
        </Animated>

        {/* VERSION */}
        <Animated animationIn="fadeInRight" isVisible={true} animateOnMount={true} style={{ position: 'fixed', bottom: 20, right: 20 }} className="w3-hide-small white font-10 montserrat">v1.15.2</Animated>

        {/* STORE LINKS */}
        <Animated animationInDelay={500} animationInDuration={300} animationIn="fadeInLeft" isVisible={true} animateOnMount={true} style={{ position: 'fixed', bottom: 20, left: 20 }} className="w3-hide-small d-flex flex-row">
          <a className="w3-hide-small" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/the-sqwad/id1506954207"><img src="/images/icons/appstore.png" alt="" style={{ height: 30 }} /></a>
          <a className="ml-2 w3-hide-small" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.trycatch.squad.app"><img src="/images/icons/playstore.png" alt="" style={{ height: 30 }} /></a>
        </Animated>

      </div>
    )
  }
}

export default UserModules;
