import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import Item from './item';
import Alphabeth from './alphabeth_button';
import Signature from './signature';
import SignatureModal from '../../../common/modals/Signature';
import { Link } from 'react-router';

import './css.css';
import EmptyPayload from '../../../EmptyPayload';

class Awards extends React.Component {

  state = {
    bootstrapped: false,
    searchText: null, signing: false, selectedTeam: null,
    letter: 'A',
    showingSignature: false
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params } = this.props, { letter, idSeason } = params;
    setTitle && setTitle({
      sub: 'AWARDS',
      main: this.props.season.Name || null
    });
    this.props.fetchSeason && this.props.fetchSeason(idSeason);
    this.props.seasonActions && this.props.seasonActions.fetchAwards(idSeason, letter);
  }
  componentWillReceiveProps = (nextProps) => {
    // The sequence of this is always tricky

    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'AWARDS',
        main: nextProps.season.Name || null
      });
    }

    if (nextProps.params.letter !== this.props.params.letter) {
      const { params: { idSeason, letter } } = nextProps;
      this.props.seasonActions && this.props.seasonActions.fetchAwards(idSeason, letter);
    }
  }
  //

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  }
  clearSearch = () => {
    this.txtSearch.value = null;
    this.setState({ searchText: null });
  }

  sign = (selectedTeam) => this.setState({ selectedTeam, signing: true });
  fnShowSignature = (selectedTeam) => this.setState({ selectedTeam, showingSignature: true });

  doSign = (signature, coach) => {
    const { selectedTeam } = this.state, { teams, params } = this.props, { idSeason, letter } = params;
    const team = _.find(teams, t => t.IdTeam === selectedTeam.IdTeam);
    team.UserName_CoachSigned = coach.UserName;
    team.Signature = signature;
    this.props.teamActions && this.props.teamActions.updateAwards({
      idTeamDivision: team.IdTeamDivision,
      placement: team.Placement,
      awards: team.Awards,
      signature,
      idAwardsCoachSignature: team.IdAwardsCoachSignature,
      idSeason, letter, idCoach: coach.IdUser
    });
    this.setState({ teams, selectedTeam: null, signing: false });
  }

  toggleSigning = () => this.setState({ signing: !this.state.signing });
  toggleShowingSignature = () => this.setState({ showingSignature: !this.state.showingSignature });

  render() {
    const { bootstrapped, searchText, signing, selectedTeam, showingSignature } = this.state,
      { params: { letter }, season: { IdSeason }, teams } = this.props;

    // TODO: this is IOC
    let filteredTeams = _.chain(teams)
      .filter(team => {
        if (letter === 'ALL') return true;
        return team.TeamName.charAt(0) === letter;
      })
      .filter(team => {
        if (searchText) {
          return team.TeamName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || (team.IdTeam + '').indexOf(searchText.toLowerCase()) !== -1;
        }
        return true;
      })
      .sortBy('TeamName')
      .value();

    return <section id="awards" className="p-2 bg-gray-dark">
      {true && <div className="filters-wrapper d-flex flex-row white">
        <div className="form-group align-self-center margin-top">
          <Alphabeth letter={letter} alpha='A' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='B' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='C' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='D' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='E' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='F' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='G' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='H' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='I' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='J' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='K' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='L' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='M' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='N' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='O' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='P' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='Q' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='R' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='S' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='T' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='U' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='V' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='W' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='X' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='Y' idSeason={IdSeason} />
          <Alphabeth letter={letter} alpha='Z' idSeason={IdSeason} />
          <Link className={`btn btn-${letter === 'ALL' ? 'info' : 'link'} btn-sm`} to={`/season/${IdSeason}/awards/ALL`}>ALL</Link>
        </div>
        {/* Right */}
        < div className="d-flex flex-row ml-auto">
          <div className="input-group align-self-center">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fa fa-search" /></span>
            </div>
            <input ref={(i) => this.txtSearch = i} type="text" className="form-control" placeholder="Search" onChange={this.handleSearch} />
            <div className="input-group-append" onClick={this.clearSearch}>
              <span className="input-group-text"><i className="fa fa-times" /></span>
            </div>
          </div>
        </div>
      </div>}
      <ReactCSSTransitionGroup className="work-area " transitionName="example" transitionAppear={true} transitionAppearTimeout={500}
        transitionEnterTimeout={500} transitionEnter={true} transitionLeave={false}>
        {true && (
          <div key="with-data" className=" d-flex align-items-stretch full-height">
            <table style={{ borderBottom: '1px solid white' }} className="table table-sm w-100">
              <thead className="bg-info white">
                <tr>
                  <th>Name</th>
                  <th>Team Id</th>
                  <th>Division</th>
                  <th>1st</th>
                  <th>2nd</th>
                  <th>3rd</th>
                  <th>Part</th>
                  <th>Players</th>
                  <th>Signature</th>
                </tr>
              </thead>
              <tbody className="white">
                {filteredTeams.map((team, i) => <Item teamActions={this.props.teamActions} team={team} key={team.IdTeam} fnSign={this.sign}
                  fnShowSignature={this.fnShowSignature}
                  params={this.props.params} />)}
              </tbody>
            </table>
          </div>
        )}
        {!bootstrapped && false && <Loader key="loader" message={`Loading Season Assets`} />}
        {bootstrapped && false && <EmptyPayload message="Currently there are no Games Scheduled" />}
        {signing && <Signature team={selectedTeam} isOpen={signing} toggle={this.toggleSigning} doSign={this.doSign} />}
        {showingSignature && <SignatureModal awards={true} team={selectedTeam} isOpen={showingSignature} toggle={this.toggleShowingSignature} />}
      </ReactCSSTransitionGroup>
    </section>
  }
}

export default Awards;
