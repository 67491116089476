import React from 'react';
import _ from 'lodash';

class Player extends React.Component {
    render() {
        const { player, onClick, width = 'w-25', season } = this.props;        
        return (
            <figure onClick={onClick} className={`px-2 hoverable ${width} h-auto align-self-start`} >
                <div className="card tile shadow-box w3-card d-flex flex-column" style={{ overflow: 'hidden', borderRadius: 10, position: 'relative' }}>
                    <div style={{ position: 'relative', height: 220, background: `url(${player.UserImage || player.Image || player.ImageUrl}) no-repeat center center black` }}
                        className="cover w-100 d-flex flex-column justify-content-end">
                        {player.violations && player.violations.length === 0 && <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 30 }} className="w-100 d-flex flex-row justify-content-center">
                            <span className="white font-10 align-self-center">{(player.Position || player.RoleOverrideName).toUpperCase()}</span>
                        </div>}
                        {player.IdGuestPlayer && <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 30 }} className="w-100 d-flex flex-row justify-content-center">
                            <span className="white font-10 align-self-center">GUEST</span>
                        </div>}
                        {player.violations && player.violations.length > 0 && <div style={{ height: 30 }} className="w-100 bg-danger d-flex flex-row justify-content-center">
                            <span className="white font-10 align-self-center">{`Suspended until ${_.first(player.violations).SuspendedUntilDate}`}</span>
                        </div>}
                        {player.IsInactive && <div style={{ height: 30 }} className="w-100 bg-danger d-flex flex-row justify-content-center">
                            <span className="white font-10 align-self-center">{`Player Inactive`}</span>
                        </div>}
                        {player.IdRoleStatus === 7 && <div className="cover" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: `url(/images/overlays/pending.png) no-repeat center center` }} />}
                        {player.IdGuestPlayer && false && <i className="fas fa-bookmark font-20 red" style={{ position: 'absolute', top: 0, right: 20 }} />}
                        {player.IdGuestPlayer && false && <span className="font-8 white" style={{ position: 'absolute', top: 3, right: 28 }}>{player.games.length}</span>}
                    </div>
                    <div className="w-100 d-flex flex-row justify-content-center bg-white p-2">
                        <span className={`black align-self-center font-${(`${player.NameFirst} ${player.NameLast}`.length > 22 ? '8' : '10')}`}>{player.JerseyNumber ? `#${player.JerseyNumber} - ` : ''}{player.NameFirst} {player.NameLast}</span>
                        <i className="ml-auto" />
                        {player.validations && player.validations.length > 0 && <i className="fas fa-warning text-danger font-16" />}                        
                        {_.find(player.all_validations, v => !v.Solved && v.InstrumentImage && v.InstrumentImage.length > 0 && v.IdLeague === season.IdLeague) && <i className="fas fa-clock text-info ml-2 font-16" />}
                    </div>
                </div>
            </figure>
        );
    }
}

export default Player;
