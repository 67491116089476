import React from 'react';
import './build.css';

import FlightListItem from './FlightListItem';

class DivisionListItem extends React.Component {

    state = {
        collapsed: true,
        division: false
    }

    // Lifecycle
    componentWillMount() {
        const { division, results } = this.props;
        this.setState({ division, results })
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ results: nextProps.results })
    }
    //

    preview = (e) => {
        e.stopPropagation();
        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed });
    }

    toggleDivisionCheckedForSchedule = () => {
        const { division } = this.state;
        division.Flights.forEach(flight => {
            if (flight.GameDayRules.length)
                flight.checkedForSchedule = !division.checkedForSchedule;
        })
        division.checkedForSchedule = !division.checkedForSchedule;
        if (division.checkedForSchedule) {
            this.setState({ collapsed: false });
        }
        this.setState({ division });
    }

    render() {
        const { collapsed, division, results } = this.state;

        return (
            <li className={`${collapsed ? 'off' : 'on'} division no-margin no-padding block`}>

                {/* Division */}
                {division && <div className="d-flex flex-row p-2" style={{ backgroundColor: (division.Flights && division.Flights.length) > 0 ? '#e5e5e5' : 'transparent' }}>

                    {division.Flights && division.Flights.length > 0 && <button className="btn btn-link btn-sm" onClick={this.toggleDivisionCheckedForSchedule}>
                        <i className={`far fa-${division.checkedForSchedule ? 'check-square' : 'square'}`} />
                    </button>}

                    <span onClick={this.toggleDivisionCheckedForSchedule} className="ml-2 align-self-center">
                        {division.Name}</span>

                    {(division.Flights.length === 0) && (<i className="half-idented align-self-center fa fa-exclamation-triangle yellow" />)}

                    <i onClick={(e) => this.preview(e)} className={`ml-auto mr-2 font-14 align-self-center fas  fa-angle${collapsed ? '-right' : '-down'} ${(division.Flights && division.Flights.length) ? '' : 'caret-hidden'}`} />

                </div>}

                {/* Flights */}
                {division && <ul className="flights-wrapper">
                    {division.Flights && division.Flights.map((flight, i) => <FlightListItem 
                        results={results} key={i} index={i} flight={flight} {...this.props} />)}
                </ul>}
            </li>)
    }
}

export default DivisionListItem;