import React from 'react';

class Bye extends React.Component {
    render() {
        const { bye } = this.props;
        return <div className="margin-bottom-half d-flex flex-column draggable-game"
            style={{
                width: '100%',
                backgroundColor: 'black', border: '1px solid white', borderRadius: 2,
            }}>
            <div className="w-100 h-100 d-flex flex-row justify-content-center font-6">
                <span className="align-self-center white">BYE: {bye.TeamName}</span>
            </div>
        </div>
    }
}

export default Bye;
