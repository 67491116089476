function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'IS_USER_SELECTION_OPEN/SET':
            return action.isUserSelectionOpen;
        default:
            return state;
    }
}

export default reducer;