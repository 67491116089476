import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import SelectableTeam from '../SelectableTeam';

class FlightedTeamsModal extends React.Component {

    render() {
        const { flight, isOpen, toggle } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
                <ModalHeader toggle={toggle}>
                    Flighted Teams on{' '}{flight.Name || flight.FlightName}
                </ModalHeader>
                <ModalBody>
                    <ul>
                        {flight.FlightedTeams.map((team, i) => (
                            <li key={i} style={{ borderBottom: '1px solid #e5e5e5' }}>
                                <SelectableTeam team={team} />
                            </li>
                        ))}
                    </ul>
                </ModalBody>
            </Modal>
        )
    }
}

export default FlightedTeamsModal;
