const colors = {
    app_blue: '#001A3C',
    purple: '#5863ff',
    gray_tab: '#D8D8D8',
    white: '#FFFFFF',
    white_ish: '#e5e5e5',
    white_back: '#E5E5E5',
    light_gray: '#AAAAAA',
    pink: '#FF3571',
    green: '#00871B',
    greenDark: '#006600',
    blue: '#008db5',
    red: '#dc3545',
    darkRed: '#AA0000',
    gray: '#808080',
    grayLight: '#CFD0D4',
    blue_purpleish: '#617790',
    blue_purpleish_light: '#909dad',
    royal_blue: '#2882CB', //'#013469',  //'#2882CB', //013469,
    jeff_blue: '#013469',
    blue_dark: '#001A3C',
    black: 'black',
    orange: 'orange',
    blueLight: '#17a2b8',
    royalBlue: '#2882CB',
    yellow: '#fc0',    
    navy: '#00087B',
    blueNavy: '#00087B',
    transparent: 'transparent',
    male: '#0f7aa6',
    female: '#29aae3',
    coed: '#01a89e',
    AD: '#14616d',
}

export default colors;
