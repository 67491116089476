import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';


class Organization extends React.Component {
    state = {
        microtransact: false,
        saved: false
    }

    // Lifecycle
    componentDidMount = () => {
        const { setTitle } = this.props;
        setTitle && setTitle({ main: 'Organizational Settings', sub: 'Fees' });
    }
    componentWillReceiveProps = nextProps => {

    }
    componentWillUnmount = () => {

    }
    //

    save = (e) => {
        e.preventDefault && e.preventDefault();
        // TODO: call api
        const { router } = this.props;
        this.setState({ saved: true }, () => {
            setTimeout(() => {
                router.push('/organization_settings');
            }, 2000);
        });

    }

    render() {
        const { microtransact, saved } = this.state;
        return (
            <div className="container margin-bottom margin-top d-flex flex-column justify-content-center w-100">
                <form style={{ maxWidth: 400 }} className="align-self-center margin-top-double">
                    <Animated animationIn="fadeInRight" animationOut="fadeOutRightBig" isVisible={!saved} animationInDelay={500} animationOutDelay={500} className={`tile card Competitive-Background`} style={{ flexBasis: 'auto', width: 400, border: '1px solid white' }}>
                        <span className={`header white bg-Competitive w-100 block text-center`}>Competitive</span>
                        <div className="p-4">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                </div>
                                <input type="number" className="form-control" placeholder="Transaction Fee" ref={(i) => this.txtCompetitive = i} />
                            </div>
                        </div>
                    </Animated>
                    <Animated animationIn="fadeInRight" animationOut="fadeOutRightBig" isVisible={!saved} animationInDelay={700} animationOutDelay={700} className={`tile card Camp-Background margin-top`} style={{ flexBasis: 'auto', width: 400, border: '1px solid white' }}>
                        <span className={`header white bg-Camp w-100 block text-center`}>Camp</span>
                        <div className="p-4">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                </div>
                                <input type="number" className="form-control" placeholder="Transaction Fee" ref={(i) => this.txtCamp = i} />
                            </div>
                        </div>
                    </Animated>
                    <Animated animationIn="fadeInRight" animationOut="fadeOutRightBig" isVisible={!saved} animationInDelay={900} animationOutDelay={900} className={`tile card Tournament-Background margin-top`} style={{ flexBasis: 'auto', width: 400, border: '1px solid white' }}>
                        <span className={`header white bg-Tournament w-100 block text-center`}>Tournament</span>
                        <div className="p-4">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                </div>
                                <input type="number" className="form-control" placeholder="Transaction Fee" ref={(i) => this.txtTournament = i} />
                            </div>
                        </div>
                    </Animated>
                    <Animated animationIn="fadeInRight" animationOut="fadeOutRightBig" isVisible={!saved} animationInDelay={1100} animationOutDelay={1100} className={`tile card Recreational-Background margin-top`} style={{ flexBasis: 'auto', width: 400, border: '1px solid white' }}>
                        <span className={`header white bg-Recreational w-100 block text-center`}>Recreational</span>
                        <div className="p-4">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="fas fa-dollar-sign" /></span>
                                </div>
                                <input type="number" className="form-control" placeholder="Transaction Fee" ref={(i) => this.txtRecreational = i} />
                            </div>
                        </div>
                    </Animated>
                    <Animated animationIn="fadeInRight" animationInDelay={1200}>
                        <span className="white font-8 line1">(This rates are to be applied on each registration done through the sl team app)</span>
                    </Animated>
                    {!saved && <Animated animationIn="fadeIn" animationInDelay={1300} className="d-flex flex-row margin-top">
                        <Link to="/organization_settings" className="btn btn-link white btn-sm ml-auto" onClick={this.cancel}>Cancel</Link>
                        {!microtransact && <button type="submit" className="btn btn-success btn-sm" onClick={this.save}>Save</button>}
                        {microtransact && <button type="button" className="btn btn-success btn-sm">Saving...</button>}
                    </Animated>}

                    {saved && <Animated animationIn="zoomIn" className="text-center bg-success p-2 w-100 white margin-top">
                        <i className="fa fa-check margin-right-half" />Settings updated successfully!
                        </Animated>}
                </form>
            </div>
        )
    }
}

export default Organization;