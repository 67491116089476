import React from 'react';
import Loader from '../../../common/Loader';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TournamentList from './TournamentList';

class Snapshot extends React.Component {

  state = {
    bootstrapped: false,
    days: 1,
    mode: null // 1 -> Tournament, 2 -> Non-Tournament
  }

  // Lifecycle
  componentWillMount() {
    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    setTitle && setTitle({ main: 'Report | Registration Snapshot' });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle, params } = nextProps;
      setTitle && setTitle({ sub: 'Report | Registration Snapshot', main: nextProps.season.Name || null });
      this.setState({ mode: nextProps.season.IdSeasonType === 3 ? 1 : 2 });
      this.props.reportsActions && this.props.reportsActions.fetchSnapshot(params.idSeason, 1, this.state.days); // tODO      
    }

    if (this.props.report !== nextProps.report && nextProps.report) {
      this.setState({ bootstrapped: true });
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //

  onDays = (days) => {
    const { params } = this.props;
    this.props.reportsActions && this.props.reportsActions.clearReport()
    this.setState({ days }, () => {
      this.props.reportsActions && this.props.reportsActions.fetchSnapshot(params.idSeason, 1, this.state.days); // tODO      
    });
  }

  render() {
    const { bootstrapped, mode, days } = this.state,
      { season } = this.props;

    return <section>
      <div className="d-flex flex-row  px-2" style={{ height: '3em' }}>
        <button onClick={() => this.onDays(1)} className={`align-self-center btn btn-sm btn-${days === 1 ? 'info' : 'link'}`}>24 Hours</button>
        <button onClick={() => this.onDays(7)} className={`align-self-center btn btn-sm btn-${days === 7 ? 'info' : 'link'}`}>7 Days</button>
        <button onClick={() => this.onDays(30)} className={`align-self-center btn btn-sm btn-${days === 30 ? 'info' : 'link'}`}>30 Days</button>
        <ReactHTMLTableToExcel id="test-table-xls-button" className="ml-auto align-self-center download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Registration - ${season.Name}`}
          sheet="Registration" buttonText="Export as XLS" />
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && mode && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            {true && <TournamentList {...this.props} />}
          </section>)}
      </div>
    </section>
  }
}

export default Snapshot;