import React, { useMemo, useCallback } from 'react';
import { Animated } from 'react-animated-css';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router';
import _ from 'lodash';
import axios from 'axios';
import { getReadableFileSizeString, generateRandomId } from '../../helpers';
import PremiumModal from './modals/premium';


const baseStyle = {
    flex: 1,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'video/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container" style={{ position: 'relative' }}>

            <div {...getRootProps({ style })} className="pointer">
                <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    className=" d-flex flex-row justify-content-center w-100 h-100">
                    <div style={{ width: 420, height: 420, borderRadius: 210, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
                </div>
                <input {...getInputProps()} />
                <div style={{ height: 100 }} />
                {props.upload_type === 1 && <img alt="" src="/images/icons/camera.png" />}
                {props.upload_type === 2 && <i className='fas fa-star yellow font-60 mb-2' />}
                <p className="text-center">Drag 'n' drop some files here, or click to select files</p>
                <div style={{ border: '3px solid #2cad3c', borderRadius: 20, width: 200 }} className="p-2 text-center mt-4"><span className="green">UPLOAD VIDEOS</span></div>
                <div style={{ height: 100 }} />
            </div>

        </div>
    );
}

class Family extends React.Component {

    state = {
        files: [],
        upgrading: false,
        promo_shown: false,
        promo: false,
        remaining: false,
        remaining_shown: false,
        manage: false,
        manage_plan: null,
        upload_type: 1, // 1: video, 2: highlight, 3: external
        mode: 1, // [1: team, 2: family]
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle } = this.props;

        setTitle && setTitle({
            main: 'SQWAD Services',
            sub: `${this.props.family_lastname}'s`
        });

        const { familyActions, teamActions } = this.props;
        teamActions.fetchVideos && teamActions.fetchVideos(1);
        familyActions.getFamilySubscriptions && familyActions.getFamilySubscriptions();
        this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(1);
    }

    componentWillReceiveProps = nextProps => {

        const { family_subscriptions, afp } = nextProps;
        if (afp && family_subscriptions) this.setState({
            bootstrapped: true,
            afp,
            // SQWAD PRO ATHLETE
            current_family_plan: _.chain(family_subscriptions).filter(s => !s.Expired && s.IdUser && s.IdPlan === '42859E53-5A11-4304-97BB-F777D88DEE8B').sortBy('Amount').last().value(),
            mode: 2
        });
    }

    componentWillUnmount = () => {
        const { teamActions } = this.props;
        teamActions.clearFiles();
    }

    toggleUpgrading = () => this.setState({ upgrading: this.state.upgrading ? 0 : 1 });

    togglePromo = () => this.setState({ promo: !this.state.promo });

    toggleRemaining = () => this.setState({ remaining: !this.state.remaining });

    upgradeFamily = () => this.setState({ upgrading: 2 });

    upgradeTeam = () => this.setState({ upgrading: 1 });

    onMode = (mode) => this.setState({ mode });

    doDrop = () => {

        const { file } = this.state;
        const { videoActions, family_videos, clips, highlights, afp, familyActions } = this.props,
            { current_family_plan } = this.state;

        // TODO: validate if the upload is possible        
        let totalFamilyBytes = _.chain([...family_videos, ...clips, ...highlights])
            .map(f => f.fileSize || 0).reduce((a, b) => a + b, 0).value();

        if (totalFamilyBytes > (current_family_plan || _.find(afp, p => !p.Amount).StorageSpace)) {
            // TODO: alert that your quota is exceeded
            alert('Sorry, your Storage Quota is exceeded by this new video')
            return;
        }

        videoActions.upload({
            idTeam: 1,
            id: generateRandomId(10),
            actualFile: file,
            progress: 0,
            cancelTokenSource: axios.CancelToken.source(),
            videoActions,
            admin: false,

        });

        // TODO: if the team / family does not have a plan when doing this ? enroll them in Trial
        if (!current_family_plan) {
            // Enroll family into free trial
            familyActions.subscribeToSQWADTrial(_.find(afp, p => !p.Amount).IdPlan, {});
        }
    }

    manage = (plan) => {
        this.setState({
            manage: true,
            manage_plan: plan
        });
    }

    toggleManage = () => this.setState({ manage: !this.state.manage });

    onDrop = (file) => {
        const { promo_shown, current_family_plan } = this.state;
        if (!promo_shown && !current_family_plan) {
            this.setState({
                promo_shown: true,
                promo: true, file
            });
            return;
        }
        this.setState({ file });
        this.doDrop();
    }

    onCancelUpload = (index) => {
        const { videoActions } = this.props;
        videoActions.cancel(index);
    }

    onSaveExternalLink = () => {
        this.setState({ highlight_saved: true });

        //        
        this.props.videoActions.addExternal({
            idTeam: 1,
            name: this.txtExternalLinkName.value,
            link: this.txtExternalLink.value,
            videoActions: this.props.videoActions
        });

        // clean up the form
        this.txtExternalLink.value = null;
        this.txtExternalLinkName.value = null;
    }

    render() {

        const { files, clips, highlights, videos, family_videos } = this.props,
            { bootstrapped, afp, current_family_plan, upgrading, upload_type } = this.state;

        let wefiles = files; // _.reject(files, f => !f.progress);

        let totalFamilyBytes = _.chain([..._.filter(family_videos, v => v.IS_FAMILY_VIDEO === true), ...clips, ...highlights])
            .map(f => f.fileSize || 0).reduce((a, b) => a + b, 0).value();


        return <section>
            <div className="d-flex flex-row">
                {bootstrapped && videos && family_videos && <div className=" w-100 pt-4 pr-2 pb-4 pl-4 d-flex flex-column">
                    <div className="p-2 d-flex flex-row" style={{ height: 60 }}>
                        <h2><b>Welcome!</b> Upload your videos here</h2>
                    </div>
                    {/*<div className="mt-4 d-flex flex-row">
                        <div onClick={() => this.setState({ upload_type: 1 })} className={`tile shadow-box card p-4 w-100 d-flex flex-column pointer ${upload_type === 1 ? 'bordered' : ''}`}>
                            <img className="align-self-center" alt="" src="/images/icons/camera.png" style={{ height: 60 }} />
                            <span className="black align-self-center">VIDEO</span>
                        </div>
                        <div onClick={() => this.setState({ upload_type: 2 })} className={`tile shadow-box card p-4 w-100 d-flex flex-column pointer ${upload_type === 2 ? 'bordered' : ''}`}>
                            <i className="fas fa-star yellow font-40 align-self-center" />
                            <span className="black align-self-center mt-1">HIGHLIGHT</span>
                        </div>
                        <div onClick={() => this.setState({ upload_type: 3 })} className={`tile shadow-box card p-4 w-100 d-flex flex-column pointer ${upload_type === 3 ? 'bordered' : ''}`}>
                            <i className="fab fa-youtube red font-40 align-self-center" />
                            <span className="black align-self-center mt-1">EXTERNAL LINK</span>
                        </div>
    </div>*/}
                    {(upload_type === 1 || upload_type === 2) && <div className="tile shadow-box card pt-4 pb-4 mt-4">
                        <StyledDropzone onDrop={this.onDrop} upload_type={upload_type} />
                        <span className="font-8 gray text-center m-4">By submitting your videos, you acknowledge that you agree to Sports Logic / SQWAD Terms of Service </span>
                    </div>}
                    {upload_type === 3 && <div className="tile shadow-box card p-4 mt-4 d-flex flex-column">
                        <i className="fab fa-youtube red font-40 align-self-center" />
                        <span className="align-self-center text-center mx-4 mt-2">Athletes can add third party links of their highlights to their SQWAD profile. Put your link that you want coaches to see in the box below</span>
                        <input className="input100 mt-4 align-self-center bordered" type="text" name="external_link" placeholder="Hyperlink" style={{ borderRadius: '0', height: '55px' }} ref={(input) => this.txtExternalLink = input} />
                        <input className="input100 mt-4 align-self-center bordered" type="text" name="highlight_name" placeholder="Highlight Name" style={{ borderRadius: '0', height: '55px' }} ref={(input) => this.txtExternalLinkName = input} />
                        <button onClick={this.onSaveExternalLink} type="button" className="login100-form-btn mt-4">
                            <span>Save External Link</span>
                        </button>
                        {this.state.highlight_saved && <span className='align-self-center mt-1'>Highlight saved!</span>}
                    </div>}
                </div>}

                {bootstrapped && videos && family_videos && <Animated animationIn="slideInRight" animationInDelay={50} className=" w-100 pt-4 pr-4 pb-4 pl-2 d-flex flex-column">
                    <div className="d-flex flex-row">
                        <div className="w-25" />
                        <div className="tile shadow-box card p-2 d-flex flex-row w-75" style={{ height: 60, opacity: 0 }}>

                        </div>
                    </div>
                    <div className="tile shadow-box card mt-4">
                        <div className="w-100 h-100 p-4">

                            <h2 className="text-center">Here are your metrics</h2>
                            <div className="metric text-center white mt-4 hoverable">
                                <Link to={`/sqwad/family/videos`} className="white">
                                    <div style={{ height: 25 }} />
                                    <h1>{family_videos.length}</h1>
                                    <h4>Video{(family_videos.length) > 1 ? 's' : ''}</h4>
                                    <div style={{ height: 25 }} />
                                </Link>
                            </div>
                            <div className="metric text-center white mt-2 hoverable">
                                <Link to={`/sqwad/family/clips`} className="white">
                                    <div style={{ height: 25 }} />
                                    <h1>{clips.length}</h1>
                                    <h4>Clip{clips.length > 1 ? 's' : ''}</h4>
                                    <div style={{ height: 25 }} />
                                </Link>
                            </div>
                            <div className="metric text-center white mt-2 hoverable">
                                <Link to={`/sqwad/family/highlights`} className="white">
                                    <div style={{ height: 25 }} />
                                    <h1>{highlights.length}</h1>
                                    <h4>Highlight{clips.length > 1 ? 's' : ''}</h4>
                                    <div style={{ height: 25 }} />
                                </Link>
                            </div>
                            <div className="metric text-center white mt-2" style={{ overflow: 'hidden' }}>
                                <div style={{ height: 25 }} />
                                {/* FAMILY METRICS */}
                                {this.props.disclaimer && <div>
                                    <h1 className={totalFamilyBytes > (current_family_plan || _.find(afp, p => !p.Amount).StorageSpace) ? 'text-danger' : ''}>{getReadableFileSizeString(totalFamilyBytes)} out of {(current_family_plan || _.find(afp, p => !p.Amount) || { StorageLabel: '?GB' }).StorageLabel}</h1>
                                    <h4>Space Available</h4>
                                    <div style={{ height: 10 }} />
                                    <Link to="/sqwad/family/subscriptions" style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 20, width: 250 }} className="m-auto py-2 px-4 text-center pointer"><span className="white">{(current_family_plan && current_family_plan.Amount > 0) ? 'MANAGE' : 'UPGRADE'}</span></Link>
                                </div>
                                }
                                <div style={{ height: 25 }} />
                            </div>
                        </div>
                    </div>
                </Animated>}
            </div>

            <div style={{ position: "fixed", right: '4em', bottom: 20, left: '13em', height: 'auto' }}>
                {wefiles.map((file, i) => <div key={i} className={`tile card p-2 mt-2 d-flex flex-row ${file.canceled ? 'bg-danger' : 'bg-gray'}`} style={{ position: 'relative', height: 60, borderRadius: 30, overflow: "hidden" }}>
                    <div className={`${file.canceled ? 'bg-danger' : 'bg-success'}`} style={{ position: 'absolute', left: 0, width: `${file.progress}%`, top: 0, bottom: 0 }} />
                    {file.progress < 100 && <span className="ml-4 w-100 align-self-center font-16 white" style={{ zIndex: 1 }}>{file.actualFile.name}</span>}
                    {file.progress < 100 && !file.canceled && <span className="w-100 text-center align-self-center font-30 white" style={{ zIndex: 1 }}>{file.progress}%</span>}
                    {file.progress < 100 && !file.canceled && <span className="w-100 text-right mr-4 align-self-center white pointer" style={{ zIndex: 1 }} onClick={() => this.onCancelUpload(i)}>Cancel</span>}
                    {file.progress < 100 && file.canceled && <span className="w-100 text-right mr-4 align-self-center white" style={{ zIndex: 1 }}>Canceled</span>}

                    {file.progress >= 100 && <span className="ml-4 align-self-center white" style={{ zIndex: 1 }}><i className="far fa-check-circle" /> Video uploaded successfully. You can see it in the video section</span>}
                    {file.progress >= 100 && <Link className="ml-auto mr-4 align-self-center white pl-4 pr-4" style={{ zIndex: 1, border: '1px solid white', height: 30, borderRadius: 15 }} to={`/sqwad/family/videos`}>Go to Videos</Link>}
                </div>)}
            </div>

            {/* BOTTOM GAP */}
            {wefiles.map((a, i) => <div key={i} style={{ height: 80 }} />)}

            {upgrading && <PremiumModal {...this.props} toggle={this.toggleUpgrading} isOpen={upgrading} target={_.find(afp, p => p.Amount)} />}

        </section>
    }
}

export default Family;
