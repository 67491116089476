import React from 'react';
import { ModalBody } from 'reactstrap';
import Switch from "react-switch";
import config from '../../../../config';
import request from 'superagent';
import _ from 'lodash';

class Validations extends React.Component {

    state = {
        validations: {},
        processes: {}
    }

    // Lifecycle
    componentWillUnmount() {
        const { validations, processes } = this.props;        
        this.setState({
            dobv: { ..._.find(processes, p => p.ValidationProcessCode === 'DOBV'), ..._.find(validations, v => v.ValidationProcessCode === 'DOBV') },
            concussion: { ..._.find(processes, p => p.ValidationProcessCode === 'CONC'), ..._.find(validations, v => v.ValidationProcessCode === 'CONC') },
            coach_certificate: { ..._.find(processes, p => p.ValidationProcessCode === 'COACH'), ..._.find(validations, v => v.ValidationProcessCode === 'COACH') },
            safe: { ..._.find(processes, p => p.ValidationProcessCode === 'SAFE'), ..._.find(validations, v => v.ValidationProcessCode === 'SAFE') },
            background_check: { ..._.find(processes, p => p.ValidationProcessCode === 'BGC'), ..._.find(validations, v => v.ValidationProcessCode === 'BGC') },
            fingerprints: { ..._.find(processes, p => p.ValidationProcessCode === 'FINGER'), ..._.find(validations, v => v.ValidationProcessCode === 'FINGER') },
            photos: { ..._.find(processes, p => p.ValidationProcessCode === 'PHOTOS'), ..._.find(validations, v => v.ValidationProcessCode === 'PHOTOS') },
            medical: { ..._.find(processes, p => p.ValidationProcessCode === 'MEDICAL'), ..._.find(validations, v => v.ValidationProcessCode === 'MEDICAL') },
        });
    }
    componentWillReceiveProps = nextProps => {
        const { validations, processes } = nextProps;        
        this.setState({
            dobv: { ..._.find(processes, p => p.ValidationProcessCode === 'DOBV'), ..._.find(validations, v => v.ValidationProcessCode === 'DOBV') },
            concussion: { ..._.find(processes, p => p.ValidationProcessCode === 'CONC'), ..._.find(validations, v => v.ValidationProcessCode === 'CONC') },
            coach_certificate: { ..._.find(processes, p => p.ValidationProcessCode === 'COACH'), ..._.find(validations, v => v.ValidationProcessCode === 'COACH') },
            safe: { ..._.find(processes, p => p.ValidationProcessCode === 'SAFE'), ..._.find(validations, v => v.ValidationProcessCode === 'SAFE') },
            background_check: { ..._.find(processes, p => p.ValidationProcessCode === 'BGC'), ..._.find(validations, v => v.ValidationProcessCode === 'BGC') },
            fingerprints: { ..._.find(processes, p => p.ValidationProcessCode === 'FINGER'), ..._.find(validations, v => v.ValidationProcessCode === 'FINGER') },
            photos: { ..._.find(processes, p => p.ValidationProcessCode === 'PHOTOS'), ..._.find(validations, v => v.ValidationProcessCode === 'PHOTOS') },
            medical: { ..._.find(processes, p => p.ValidationProcessCode === 'MEDICAL'), ..._.find(validations, v => v.ValidationProcessCode === 'MEDICAL') },
        });
    }
    //

    save = () => {
        const { dobv, concussion, coach_certificate, safe, background_check, fingerprints, photos, medical } = this.state, { league, leagueActions } = this.props;

        this.setState({ microTransact: true }, () => {
            request.post(`${config.apiEndpoint}/api/v5/validations/to_league/${league.IdLeague}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({ dobv, concussion, coach_certificate, safe, background_check, fingerprints, photos, medical })
                .then(() => {
                    leagueActions.fetchLeagueValidations(league.IdLeague);
                    this.setState({ microTransact: false });
                });
        });

    }
    togglePlayers = (code) => {
        const { dobv, concussion, coach_certificate, safe, background_check, fingerprints, photos = {}, medical = {} } = this.state;
        switch (code) {
            case 'DOBV':
                dobv.Players = !dobv.Players; break;
            case 'CONC':
                concussion.Players = !concussion.Players; break;
            case 'COACH':
                coach_certificate.Players = !coach_certificate.Players; break;
            case 'SAFE':
                safe.Players = !safe.Players; break;
            case 'BGC':
                background_check.Players = !background_check.Players; break;
            case 'FINGER':
                fingerprints.Players = !fingerprints.Players; break;
            case 'PHOTOS':
                photos.Players = !photos.Players; break;
            case 'MEDICAL': 
                medical.Players = !medical.Players; break;
            default: break;
        }
        this.setState({ dobv, concussion, coach_certificate, safe, background_check, fingerprints, photos, medical }, () => {
            this.save();
        });
    }
    toggleCoaches = (code) => {
        const { dobv, concussion, coach_certificate, safe, background_check, fingerprints, photos = {}, medical = {} } = this.state;
        switch (code) {
            case 'DOBV':
                dobv.Coaches = !dobv.Coaches; break;
            case 'CONC':
                concussion.Coaches = !concussion.Coaches; break;
            case 'COACH':
                coach_certificate.Coaches = !coach_certificate.Coaches; break;
            case 'SAFE':
                safe.Coaches = !safe.Coaches; break;
            case 'BGC':
                background_check.Coaches = !background_check.Coaches; break;
            case 'FINGER':
                fingerprints.Coaches = !fingerprints.Coaches; break;
            case 'PHOTOS':
                photos.Coaches = !photos.Coaches; break;
            case 'MEDICAL':
                medical.Coaches = !medical.Coaches; break;
            default: break;
        }
        this.setState({ dobv, concussion, coach_certificate, safe, background_check, fingerprints, photos, medical }, () => {
            this.save();
        });
    }
    render() {
        const { dobv = {}, concussion = {},
            coach_certificate = {}, safe = {},
            background_check = {}, fingerprints = {}, photos = {}, medical = {} } = this.state;

        return <ModalBody className="container-fluid no-margin no-padding">
            <div className="d-flex flex-column px-4 mt-4">
                <h4>Validations across all seasons</h4>
                <div className="d-flex flex-row justify-content-center">

                    {/* DOB */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(dobv.Players || dobv.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>{dobv.ValidationProcessName}</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(dobv || {}).Players ? true : false} onChange={() => this.togglePlayers('DOBV')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(dobv || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('DOBV')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: 50 }} />

                    {/* DOB */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(concussion.Players || concussion.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>{concussion.ValidationProcessName}</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(concussion || {}).Players ? true : false} onChange={() => this.togglePlayers('CONC')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(concussion || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('CONC')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: 50 }} />

                    {/* DOB */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(coach_certificate.Players || coach_certificate.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>{coach_certificate.ValidationProcessName}</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(coach_certificate || {}).Players ? true : false} onChange={() => this.togglePlayers('COACH')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(coach_certificate || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('COACH')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="d-flex flex-row justify-content-center mt-2">

                    {/* DOB */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(safe.Players || safe.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>{safe.ValidationProcessName}</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(safe || {}).Players ? true : false} onChange={() => this.togglePlayers('SAFE')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(safe || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('SAFE')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: 50 }} />

                    {/* DOB */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(background_check.Players || background_check.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>{background_check.ValidationProcessName}</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(background_check || {}).Players ? true : false} onChange={() => this.togglePlayers('BGC')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(background_check || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('BGC')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: 50 }} />

                    {/* FINGER */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(fingerprints.Players || fingerprints.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>{fingerprints.ValidationProcessName}</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(fingerprints || {}).Players ? true : false} onChange={() => this.togglePlayers('FINGER')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(fingerprints || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('FINGER')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="d-flex flex-row justify-content-center mt-2">

                    {/* PHOTO */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(photos.Players || photos.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>Photo</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(photos || {}).Players ? true : false} onChange={() => this.togglePlayers('PHOTOS')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(photos || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('PHOTOS')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: 50 }} />

                    {/* MEDICAL */}
                    <div className="d-flex flex-row mt-2 w-100">
                        <div className={`card w-100 bg-gray`}>
                            <span className={`header white bg-${(medical.Players || medical.Coaches) ? 'success' : 'info'} w-100 text-left p-2`}>Medical | Others</span>
                            <div className="p-3">
                                <div className="d-flex flex-row mt-2 justify-content-center">
                                    <label className="d-flex flex-row w-100 justify-content-center mr-2">
                                        <Switch checked={(medical || {}).Players ? true : false} onChange={() => this.togglePlayers('MEDICAL')} />
                                        <span className="align-self-center margin-left-half">Players</span>
                                    </label>
                                    <label className="d-flex flex-row w-100 justify-content-center">
                                        <Switch checked={(medical || {}).Coaches ? true : false} onChange={() => this.toggleCoaches('MEDICAL')} />
                                        <span className="align-self-center margin-left-half">Coaches</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: 50 }} />

                    {/*  */}
                    <div className="d-flex flex-row mt-2 w-100">

                    </div>

                </div>
            </div>
        </ModalBody>
    }
}

export default Validations;