import React from 'react';
import { Popover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../CustomDatePicker';
import CustomTimeScroller from '../CustomTimeScroller';
import moment from 'moment';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import Team from './Team';
import config from '../../../config';
import request from 'superagent';
import Switch from "react-switch";


class FriendlyGame extends React.Component {

  state = {
    isOpenStartDatepicker: false,
    popoverStartTimeOpen: false,
    flight: null,
    deleting: false,
    activeTab: 1,
    microTransac: false,
    division: null,
    game: null
  }

  // Lifecycle
  componentWillMount() {

    this.setState({
      division: this.props.division,
      flight: this.props.flight,
      game: { ...this.props.game },
      team1: this.props.game.HomeTeam,
      team2: this.props.game.AwayTeam,
      teams: this.props.teams
    });
  }
  //

  validateForm = () => {
    const { game } = this.state;
    let valid = true;

    // Game Duration
    if (!this.txtMinutes.value) {
      this.setState({ dirty: 'Please select the duration in minutes' });
      return false;
    }

    // Location
    if (!game.Location || !game.Location.IdLocation) {
      this.setState({ dirty: 'Please select a location' });
      return false;
    }

    // Home Team
    if (!game.HomeTeam.IdTeamDivision) {
      this.setState({ dirty: 'Please select the Home Team' });
      return false;
    }

    // Away Team
    if (!game.AwayTeam.IdTeamDivision) {
      this.setState({ dirty: 'Plese select the Away Team' });
      return false;
    }

    if (game.HomeTeam.IdTeamDivision && game.AwayTeam.IdTeamDivision &&
      game.HomeTeam.IdTeamDivision === game.AwayTeam.IdTeamDivision) {
      this.setState({ dirty: 'Home and Away teams cannot be the same' });
      return false;
    }

    this.setState({ dirty: null });
    return valid;
  }

  // CRUD
  delete = () => {
    const { fnDelete } = this.props, { game } = this.state;

    // Smart Call aPI    
    request.del(`${config.apiEndpoint}/api/v4/games/game/${game.IdGame || game.Id}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then(() => { });

    // Delete the redux record and notify
    this.props.gamesActions && this.props.gamesActions.deleteGame(game.Id || game.IdGame);
    fnDelete && fnDelete(game.Id || game.IdGame);
  }
  save = () => {
    const { fnSave } = this.props, { game } = this.state;

    if (!this.validateForm()) {
      return;
    }

    let payload = _.extend({
      ...game
    }, {
      GameDate: game.GameDate.format('YYYY-MM-DD'),
      GameStartHour: game.GameStartHour.format('HHmm'), //game.GameStartHour.format('HHmm'),
      GameDurationMinutes: this.txtMinutes.value,
      IdDivision: parseInt(game.HomeTeam.IdDivision, 10),
      Home_DivisionColor: game.HomeTeam.Color || game.HomeTeam.DivisionColor,
      Away_DivisionColor: game.AwayTeam.Color || game.AwayTeam.DivisionColor
      // IdDivision: --- which one of the teams have the IdDivision ? //
    });

    this.setState({ microTransac: true });

    // Smart Call aPI    
    request[payload.Id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/games${payload.Id ? `/${payload.Id}` : ''}`)
      .send(payload)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then(data => {
        if (data.body.success) {
          if (payload.Id) {
            // UPDATE
            this.props.gamesActions && this.props.gamesActions.updateGame(payload);
          } else {
            // CREATE            
            payload.Id = data.body.Id;
            payload.IdGame = data.body.IdGame || '';
            this.props.gamesActions && this.props.gamesActions.addGame(payload);
          }
          fnSave && fnSave(payload);
        } else {
          alert((data.body.error || {}).message);
          this.setState({ microTransac: false });
        }
      }, ({ response: { body: { error } } }) => {
        alert(error.message);
        this.setState({ microTransac: false });
      });
  }

  //

  handleChangeStart = (date) => {
    const { game } = this.state;
    this.setState({ game: { ...game, GameDate: moment(date) } });
    this.toggleCalendarStart();
  }
  handleChangeNotes = (event) => {
    const { game } = this.state;
    this.setState({ game: { ...game, Notes: event.target.value } });
  }

  toggleCalendarStart = (e) => {
    e && e.preventDefault()
    this.setState({
      isOpenStartDatepicker: !this.state.isOpenStartDatepicker
    })
  }
  toggleTimeStart = () => {
    this.setState({
      popoverStartTimeOpen: !this.state.popoverStartTimeOpen
    });
  }

  selectLocation = (e) => {
    const { fields } = this.props, { game } = this.state;
    this.setState({
      game: {
        ...game,
        Location: _.find(fields, f => parseInt(f.IdLocation, 10) === parseInt(e.target.value, 10)),
        LocationName: (_.find(fields, f => parseInt(f.IdLocation, 10) === parseInt(e.target.value, 10)) || {}).Name
      }
    });
  }

  /* Change this */
  selectHomeTeam = (team) => {
    const { game } = this.state;
    this.setState({ team1: team, game: { ...game, HomeTeam: team } });
  }
  selectAwayTeam = (team) => {
    const { game } = this.state;
    this.setState({ team2: team, game: { ...game, AwayTeam: team } });
  }
  //

  swapTeams = () => {
    const { game } = this.state;
    this.setState({
      game: _.extend(game, {
        HomeTeam: { ...game.AwayTeam },
        AwayTeam: { ...game.HomeTeam }
      })
    });
  }

  handleChangeTime = d => {
    const { game } = this.state;
    this.setState({ game: { ...game, GameStartHour: d } });
  }

  changeTab = activeTab => this.setState({ activeTab });
  toggleDeleteConfirm = () => this.setState({ deleting: !this.state.deleting });

  toggleGameUserForStandings = () => {
    const { game } = this.state;
    if (game.IsFriendly) return;
    if (game.HomeTeam && game.HomeTeam.IdScheduleFlight &&
      game.AwayTeam && game.AwayTeam.IdScheduleFlight) {
      game.DoNotUseForStandings = !game.DoNotUseForStandings;
      this.setState({ game });
    } else alert('In order to use this game for Standings calculations, both teams selected must be on a Flight');
  }
  toggleIsFriendly = () => {
    const { game } = this.state;
    game.IsFriendly = !game.IsFriendly;
    if (game.IsFriendly) game.DoNotUseForStandings = true;
    this.setState({ game });
  }

  render() {
    const { isOpen, toggle, fields } = this.props,
      { activeTab, microTransac, division, flight, game, team1, team2, teams } = this.state;

    let teams_dropdown = [], teams_a = [], teams_b = [];
    if (teams) {

      // A
      _.chain(teams)
        .filter(team => {
          if (this.txtHomeSearch && this.txtHomeSearch.value) {
            return `${(team.Name || team.TeamName).toLowerCase()}`.indexOf(this.txtHomeSearch.value.toLowerCase()) !== -1;
          }
          return true;
        })
        .groupBy(d => d.IdDivision)
        .values()
        .value().forEach(g => {
          teams_a.push({ header: `${_.first(g).DivisionName} ${_.first(g).Color || _.first(g).DivisionColor}` });
          _.sortBy(g, t => t.Name).forEach(team => {
            teams_a.push(team);
          });
        });

      // B
      _.chain(teams)
        .filter(team => {
          if (this.txtAwaySearch && this.txtAwaySearch.value) {
            return `${(team.Name || team.TeamName).toLowerCase()}`.indexOf(this.txtAwaySearch.value.toLowerCase()) !== -1;
          }
          return true;
        })
        .groupBy(d => d.IdDivision)
        .values()
        .value().forEach(g => {
          teams_b.push({ header: `${_.first(g).DivisionName} ${_.first(g).Color || _.first(g).DivisionColor}` });
          _.sortBy(g, t => t.Name).forEach(team => {
            teams_b.push(team);
          });
        });



      _.chain(teams)
        .groupBy(d => d.IdDivision)
        .values()
        .value().forEach(g => {
          teams_dropdown.push({ header: `${_.first(g).DivisionName} ${_.first(g).Color || _.first(g).DivisionColor}` });
          _.sortBy(g, t => t.Name).forEach(team => {
            teams_dropdown.push(team);
          });
        });
    }

    return (<Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        {(game.Id || game.IdGame) ? 'Edit Friendly Game' : 'New Friendly Game'}
        {division ? ' for ' + division.Name : ''}
      </ModalHeader>
      <ModalBody className="container-fluid">
        <ul className="nav nav-tabs font-16">
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
              <i className="fa fa-cog purple"></i> <span className="half-idented">Game</span></button>
          </li>
          {game.HomeTeam.IdTeamDivision &&
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 2 ? 'active' : ''} d-flex flex-row`} onClick={() => this.changeTab(2)}>
                <span className="d-flex flex-column justify-content-center">
                  <i className="fa fa-home blue align-self-center line1"></i>
                  <span className="font-8 align-self-center line1">Home</span>
                </span>
                {(game.HomeTeam.TeamName || game.HomeTeam.Name) && (<span className="half-idented">{game.HomeTeam.TeamName || game.HomeTeam.Name}</span>)}
              </button>
            </li>}
          {game.AwayTeam.IdTeamDivision &&
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 3 ? 'active' : ''} d-flex flex-row`} onClick={() => this.changeTab(3)}>
                <span className="d-flex flex-column justify-content-center">
                  <i className="fa fa-road gray align-self-center line1"></i>
                  <span className="font-8 align-self-center line1">Away</span>
                </span>
                {(game.AwayTeam.TeamName || game.HomeTeam.Name) && (<span className="half-idented">{game.AwayTeam.TeamName || game.AwayTeam.Name}</span>)}
              </button>
            </li>}
        </ul>

        <Animated className="tab-content" animationIn="fadeIn">
          {activeTab === 1 && (
            <div className="row no-margin no-padding">
              <div className={`col-${flight ? '4' : '4'}`}>

                <FormGroup className="margin-top-half">
                  <Label>Date</Label>
                  <div className="d-flex flex-row">

                    <DatePicker customInput={<CustomDatePicker />} selected={game.GameDate} onChange={this.handleChangeStart} showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
                    />

                    <button onClick={this.toggleTimeStart} id="RandomIdStart" className={`btn btn-sm btn-info`}>
                      {"@ " + game.GameStartHour.format('h:mmA')}
                    </button>

                    <Popover placement="bottom" isOpen={this.state.popoverStartTimeOpen} target="RandomIdStart" toggle={this.toggleTimeStart}>
                      <PopoverBody className="d-flex flex-column justify-content-end">
                        <CustomTimeScroller time={game.GameStartHour} fnChange={this.handleChangeTime} />
                      </PopoverBody>
                    </Popover>
                  </div>
                </FormGroup>

                { /* LOCATION */}
                <div data-tip="Please select Location" data-for="modal-tooltip-right" ref="form_gd_location">
                  <FormGroup >
                    <Label>Location</Label>
                    <Input type="select" value={game.Location.IdLocation} onChange={this.selectLocation}>
                      <option value={null}>Select</option>
                      {fields.map((f, i) => <option value={f.IdLocation} key={i}>
                        {f.child && ' - '}
                        {f.Name}
                      </option>)}
                    </Input>
                  </FormGroup>
                </div>

                { /* GAME DURATION */}
                <FormGroup className="d-flex flex-row">
                  <input data-tip="Please fill this!" data-for="modal-tooltip-bottom" type="number" defaultValue={game.GameDurationMinutes} ref={(input) => this.txtMinutes = input} className="align-self-center form-control control-sm"
                    min="0" style={{ width: '80px' }} />
                  <span className=" align-self-center margin-left margin-right font-10">duration (minutes)</span>
                </FormGroup>

                {/* STANDINGS */}
                <label className="d-flex flex-row w-100 justify-content-start mr-2 mb-4">
                  <Switch checked={game.DoNotUseForStandings ? false : true} onChange={this.toggleGameUserForStandings} />
                  <span className=" align-self-center margin-left margin-right font-10">Use for Standings</span>
                </label>

                {/* FRIENDLY */}
                <label className="d-flex flex-row w-100 justify-content-start mr-2 mb-4">
                  <Switch checked={game.IsFriendly ? true : false} onChange={this.toggleIsFriendly} />
                  <span className=" align-self-center margin-left margin-right font-10">Friendly Game</span>
                </label>

              </div>
              <div className="col-8 no-padding divider-vertical d-flex flex-column container-fluid">
                <div className="row mt-2 mx-0 mb-0 no-padding">


                  { /* HOME TEAM */}
                  <div className="col-5" data-tip="Please select Home Team" data-for="modal-tooltip-left" ref="form_gd_home_team">
                    {game.HomeTeam && <FormGroup className="no-padding">
                      <Label className="blue">Home</Label>
                      <Dropdown className="align-self-center btn-link w-100" isOpen={this.state.isTeams1FilterOpen} toggle={() => this.setState({ isTeams1FilterOpen: !this.state.isTeams1FilterOpen })} style={{ border: '1px solid #CCC', borderRadius: 3 }}>
                        <DropdownToggle color="default" caret className="w-100 toUpper font-10" style={{ overflow: 'hidden' }}>
                          {(team1 && team1.IdTeam) ? `${(team1.Name || team1.TeamName)} (${team1.FlightName})` : 'Pick Home Team'}
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                          <DropdownItem header>
                            <input className="w-100 p-2 bordered black" type="text" placeholder="Search" ref={(input) => this.txtHomeSearch = input} onChange={() => {
                              this.forceUpdate();
                            }} />
                          </DropdownItem>
                          {teams_a.map((team, i) => {
                            if (team.header) return <DropdownItem className="blue" header key={i}>----------- {team.header}</DropdownItem>
                            else return <DropdownItem className="toUpper font-8" active={(team1 && team1.IdTeam === team.IdTeam)} key={i} onClick={() => this.selectHomeTeam(team)}>
                              {team.Name || team.TeamName} ({team.FlightName || 'NON-FLIGHTED'})
                            </DropdownItem>
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>}
                  </div>


                  <div className="col-2 align-self-center text-center d-flex flex-column justify-content-center" data-tip="Teams must be different" data-for="modal-tooltip-top" ref="form_gd_different_teams">
                    <span className="align-self-center">VS</span>
                    {game.Id && <button className="btn btn-link btn-sm align-self-center" title="Switch teams">
                      <i className="fa fa-refresh align-self-center blue" onClick={() => this.swapTeams()}></i>
                    </button>}
                  </div>


                  { /* AWAY TEAM */}
                  <div className="col-5" data-tip="Please select Away Team" data-for="modal-tooltip-right" ref="form_gd_away_team">
                    {game.AwayTeam && <FormGroup className="no-padding">
                      <Label className="blue text-right">Away</Label>
                      <Dropdown className="align-self-center btn-link w-100" isOpen={this.state.isTeams2FilterOpen} toggle={() => this.setState({ isTeams2FilterOpen: !this.state.isTeams2FilterOpen })} style={{ border: '1px solid #CCC', borderRadius: 3 }}>
                        <DropdownToggle color="default" caret className="w-100 toUpper font-10" style={{ overflow: 'hidden' }}>
                          {(team2 && team2.IdTeam) ? `${(team2.Name || team2.TeamName)} (${team2.FlightName})` : 'Pick Away Team'}
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                          <DropdownItem header>
                            <input className="w-100 p-2 bordered black" type="text" placeholder="Search" ref={(input) => this.txtAwaySearch = input} onChange={() => {
                              this.forceUpdate();
                            }} />
                          </DropdownItem>
                          {teams_b.map((team, i) => {
                            if (team.header) return <DropdownItem className="blue" header key={i}>----------- {team.header}</DropdownItem>
                            else return <DropdownItem className="toUpper font-8" active={(team2 && team2.IdTeam === team.IdTeam)} key={i} onClick={() => this.selectAwayTeam(team)}>
                              {team.Name || team.TeamName} ({team.FlightName || 'NON-FLIGHTED'})
                            </DropdownItem>
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>}
                  </div>

                </div>

                <div className="row no-margin no-padding">
                  <div className="col-12">
                    <FormGroup>
                      <Label>Notes</Label>
                      <Input type="textarea" name="notes" defaultValue={game.Notes} onChange={this.handleChangeNotes} />
                    </FormGroup>
                  </div>
                </div>

              </div>
            </div>
          )}
          {activeTab === 2 && <Team team={game.HomeTeam} />}
          {activeTab === 3 && <Team team={game.AwayTeam} />}
        </Animated>

        {/*<ReactTooltip effect="solid" place="left" id="modal-tooltip-left" />
        <ReactTooltip effect="solid" place="right" id="modal-tooltip-right" />
        <ReactTooltip effect="solid" place="top" id="modal-tooltip-top" />
                        <ReactTooltip effect="solid" place="bottom" id="modal-tooltip-bottom" />*/}

      </ModalBody>
      {this.state.dirty && <ModalBody className={`bg-danger white p-3`}>
        {this.state.dirty}
      </ModalBody>}
      {!microTransac && (
        <ModalFooter className="d-flex flex-row">
          {(game.Id || game.IdGame) && <button className="btn btn-sm btn-link red mr-auto" onClick={this.toggleDeleteConfirm} id={`btnDeleteGameModal-${game.Id || game.IdGame}`}>Delete</button>}
          {(game.Id || game.IdGame) && <Popover placement="bottom" isOpen={this.state.deleting} target={`btnDeleteGameModal-${game.Id || game.IdGame}`} toggle={this.toggleDeleteConfirm}>
            <PopoverBody className="d-flex flex-column justify-content-center">
              <span className="align-self-center">Are you sure?</span>
              <button className="btn btn-sm btn-danger" onClick={this.toggleDeleteConfirm}>No, cancel</button>
              <button className="btn btn-sm btn-success" onClick={this.delete}>Yes, delete it!</button>
            </PopoverBody>
          </Popover>}
          <button className="btn btn-sm btn-link" onClick={toggle}>Cancel</button>
          {' '}
          <button className="btn btn-sm btn-success" onClick={this.save}>Save</button>
        </ModalFooter>)}
      {microTransac && (
        <ModalFooter>
          <button className="btn btn-sm btn-success">Saving, please wait...</button>
        </ModalFooter>)}
    </Modal>)
  }
}


export default FriendlyGame;