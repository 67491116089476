import React from 'react';
import { Link } from 'react-router';
import { Modal, ModalBody } from 'reactstrap';

class ClipModal extends React.Component {

    state = {
        bootstrapped: false,
        coupon: null,
        mode: 1
    }

    // Lifecycle
    componentWillMount() {

    }
    componentWillReceiveProps = nextProps => {

    }
    //     

    render() {
        const { isOpen, toggle, params } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle} style={{ width: 400 }}>
                <ModalBody className="container-fluid no-margin no-padding white bg-white blue">
                    <div className="p-4 d-flex flex-column justify-content-center">
                        <i className="font-80 fas fa-check-circle m-auto align-self-center"></i>
                        <h1 className="text-center mt-2 align-self-center">Success</h1>
                        <h3 className="mt-2 text-center align-self-center line1">Your video is<br />being processed</h3>
                        <h5 className="mt-2 text-center align-self-center line1">It will be available in the<br /><Link className="underlined pointer blue" to={`/sqwad/team/${params.idTeam || params.IdTeam}/highlights`}>highlights section</Link> soon</h5>
                        <button className="btn btn-success mt-4" onClick={toggle}>Continue Creating</button>
                        <Link to={(params.idTeam || params.IdTeam) ? `/sqwad/team/${params.idTeam || params.IdTeam}/dashboard` : `/sqwad/family`} className="btn btn-link">Exit Studio</Link>
                        
                    </div>
                </ModalBody>
            </Modal >
        )
    }
}

export default ClipModal;
