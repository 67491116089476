import React from 'react';
import './css.css';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import request from 'superagent';
import config from '../../../../config';
import Sheet from './sheet';
import { Animated } from 'react-animated-css';
import PlayerCardSettings from '../../../common/modals/PlayerCardSettings';

class PlayerCards extends React.Component {

  state = {
    bootstrapped: false,
    filters: {},
    appliedFilters: {},
    ddDateOpen: false,
    ddTimeOpen: false,
    season: null,
    divisions: [],
    teams: [],
    fetchingGames: false,
    fetchedOnce: false,
    fullSheet: true,
    sheetMode: 1,
    downloading: false,
    settings: false
  }

  // Lifecycle
  componentWillMount() {

    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Print Roster',
      main: this.props.season.Name || null
    });

    // Fetch season
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.divisionsActions && this.props.divisionsActions.fetchPrintableDivisions(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Player Cards',
        main: nextProps.season.Name || null
      });
      this.setState({ season: nextProps.season });
    }
    if (nextProps.divisions) {
      this.setState({ bootstrapped: true, divisions: nextProps.divisions });
    }
  }

  preview = (e) => {

    e.preventDefault();
    const { divisions, fetchingGames } = this.state;
    if (fetchingGames) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    if (!teams.length) return;

    this.setState({ fetchingGames: true }, () => {
      request.post(`${config.apiEndpoint}/api/v4/roster/bulk`)
        .send({ teams })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then(data => {
          this.setState({ teams: data.body.teams, fetchingGames: false, fetchedOnce: true });
        });
    });
  }

  download = () => {

    const { divisions, downloading } = this.state;
    if (downloading) return;

    const teams = _.chain(divisions)
      .map(d => { return d.Teams })
      .flattenDeep()
      .filter(t => { return t.selected; })
      .value();

    if (!teams.length) return;

    this.setState({
      downloading: true
    }, () => {
      // Fetch games with filters applied
      request.post(`${config.apiEndpoint}/api/v4/roster/playercards/download`)
        .send({ teams })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .responseType('arraybuffer')
        .then((data) => {
          let file = new Blob([data.body], { type: 'application/pdf' });
          require("downloadjs")(file, 'Rosters.pdf');
          this.setState({ downloading: false, });
        });
    });
  }

  toggleSeason = () => {
    const { season, divisions } = this.state;
    season.selected = !season.selected;
    divisions.forEach(division => {
      division.selected = season.selected;
      division.Teams.forEach(team => {
        team.selected = season.selected;
      });
    });
    this.setState({ season, divisions });
  }
  toggleDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].selected = !divisions[index].selected;
    divisions[index].Teams.forEach(team => {
      team.selected = divisions[index].selected;
    });
    this.setState({ divisions });
  }
  toggleTeam = (di, ti) => {
    const { divisions } = this.state;
    divisions[di].Teams[ti].selected = !divisions[di].Teams[ti].selected;
    divisions[di].selected = _.find(divisions[di].Teams, (team) => !team.selected) ? false : true;
    this.setState({ divisions });
  }
  toggleExpandDivision = (index) => {
    const { divisions } = this.state;
    divisions[index].expanded = !divisions[index].expanded;
    this.setState({ divisions });
  }
  toggleSettings = () => this.setState({ settings: !this.state.settings });

  render() {

    const { season, fetchingGames, fetchedOnce, divisions, downloading, teams, settings } = this.state;

    return <section id="print-roster" className="canvas white">

      <Animated animationIn="fadeInRight" className="filters-wrapper d-flex flex-row" style={{ height: '3em' }} >
        <button onClick={this.toggleSettings} className="btn btn-sm btn-link ml-auto margin-right-half align-self-center">
          <i className="fa fa-cogs" />{' '}<span className="white">Settings</span>
        </button>
        <button type="submit" key="previewbtn" onClick={this.preview} className="btn btn-success btn-sm margin-right-half align-self-center">
          <i className="fa fa-eye" />
          {' '}
          <span>{fetchingGames ? 'Fetching' : 'Preview'}</span>
        </button>
      </Animated>

      {/* Tree Section */}
      <section className="tree-section" style={{ overflowY: 'auto', top: '3em' }}>
        { /* Season */}
        {season && <button onClick={() => this.toggleSeason()} className="btn btn-link text-left btn-sm d-flex flex-row" style={{ whiteSpace: 'normal' }}>
          <i className={`${season.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'} align-self-center`} />
          <span className="idented-half align-self-center line1">{season.Name || season.SeasonName}</span>
        </button>}
        { /* Divisions | Teams */}
        {divisions && _.filter(divisions, d => d.Teams.length).map((division, i) => (
          <section key={i}>
            <button onClick={() => this.toggleDivision(i)} className="btn btn-link text-left btn-sm d-flex flex-row no-padding block" style={{ whiteSpace: 'normal', marginLeft: '1em' }}>
              {division.Teams.length > 0 &&
                <button className="btn btn-sm btn-link white align-self-center" onClick={() => this.toggleExpandDivision(i)}>
                  <i className={`fa fa-caret-${division.expanded ? 'up' : 'down'}`} />
                </button>
              }
              <i className={`${division.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'} align-self-center`} />
              <span className="idented-half align-self-center line1">{division.Name || division.IdDivision}</span>
              {' '}
            </button>
            {division.expanded && division.Teams.map((team, j) => (
              <button onClick={() => this.toggleTeam(i, j)} key={j} className="btn btn-link text-left btn-sm block d-flex flex-row" style={{ whiteSpace: 'normal', marginLeft: '4em' }}>
                <i className={`${team.selected ? 'fa fa-check-circle blue' : 'far fa-circle white'} align-self-center`} />
                <span className="align-self-center idented-half">{team.Name || team.TeamName}</span>
              </button>))}
          </section>))}
      </section>

      { /* Sheets */}
      <section className={`sheet-section ${true ? 'bg-gray-dark inner-shadow' : null}`} style={{ overflowY: 'auto', padding: '0 0 100px 0', top: '3em' }}>

        {/* Standard Letter */}
        {teams.length > 0 && !fetchingGames && teams.map((team, i) => <Sheet key={i} team={team} season={season} />)}

        {fetchingGames && <Loader key="loader" message={`Fetching Games, please wait...`} />}
        {!fetchingGames && fetchedOnce && !teams.length && <div className="d-flex justify-content-center centered-message">
          <h1 className="white align-self-center block text-center">No roster were found!</h1>
        </div>}
      </section>

      { /* Print controls */}
      {teams && teams.length > 0 && (
        <Animated animationIn="fadeInRight" style={{ position: 'fixed', bottom: 0, right: 0 }} className="d-flex flex-column p-2">
          <button className="btn btn-success btn-lg margin-top" type="button" onClick={this.download}>
            <i className="fas fa-download" />
            {' '}<span>{downloading ? 'Downlading...' : 'Download'}</span> sheets</button>
        </Animated>)}

      <PlayerCardSettings isOpen={settings} toggle={this.toggleSettings} />
    </section>
  }
}

export default PlayerCards;
