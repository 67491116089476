import React from 'react';
import { Input, Popover, PopoverBody, PopoverHeader, } from 'reactstrap';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { generateRandomId, getBase64 } from '../../../helpers';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import config from '../../../config';
import { Animated } from 'react-animated-css';

const { clothingSizes, realGenders } = config;

class CreateUser extends React.Component {

    state = {
        user: null,
        shirtOpened: false,
        shortOpened: false,
        carriersOpened: false,
        deleteOpened: false,
        dirty: false,
        valid: false,
        avatar: null,// '/images/defaults/male.png',
        validations: {
            txtName: false,
            txtLastName: false,
            txtDob: false
        },
        bootstrapped: true,
        edit_cp: false,
        selected: {
            CountryCode: _.first(config.country_codes),
            country_code: null,
            mask: null
        }
    }

    // Lifecycle
    componentWillMount() {
        const { user } = this.props;
        this.props.geoActions && this.props.geoActions.fetchCountryCodes();
        user.IdUser && this.props.familyActions && this.props.familyActions.fetchCollegeProfile(user.IdUser);
        this.setState({ user });
    }
    componentWillReceiveProps = nextProps => {
        const { country_codes } = nextProps;
        if (!this.state.country_codes && country_codes && country_codes.northAmericanCountries) {

            this.setState({ country_codes }, () => {
                const { user, selected } = this.state;
                //selected.CountryCode = _.find(config.country_codes, cc => cc.value === user.CountryCode) || _.first(config.country_codes);
                selected.CountryCode = _.find(config.country_codes, cc => cc.value === user.CountryCode) || _.first(config.country_codes);

                // NEW Phone number handling
                selected.country_code = _.find([...country_codes.northAmericanCountries,
                ...country_codes.southAmericanCountries,
                ...country_codes.centralAmericanCountries,
                ...country_codes.europeanCountries,
                ...country_codes.africanCountries,
                ...country_codes.asianCountries,
                ...country_codes.oceaniaCountries], c => c.countryCode === user.CountryCode) || _.first(country_codes.northAmericanCountries);

                if (selected.country_code) {
                    selected.mask = _.chain(selected.country_code.phoneNumberMask)
                        .map(a => {
                            switch (a) {
                                case '/[2-9]/': return /[2-9]/;
                                case '/[1-9]/': return /[1-9]/;
                                case '/[0-9]/': return /[0-9]/;
                                case '/[5-9]/': return /[5-9]/;
                                case '/[2-5]/': return /[2-5]/;
                                case '/[2-6]/': return /[2-6]/;
                                case '/\d/': return /\d/;
                                default: return a;
                            }
                        }).value();
                }

                this.setState({
                    selected,
                    validations: {
                        txtName: user.NameFirst ? true : false,
                        txtLastName: user.NameLast ? true : false,
                        txtDob: user.DateBirth ? true : false
                    }
                });
            });
        }
        this.setState({ college_profile: nextProps.college_profile });
        if (nextProps.micro) {
            this.setState({ saving: true });
        }
        if (!nextProps.micro && this.state.saving) {
            this.props.toggle();
        }
    }
    //

    toggleGender = () => this.setState(prevState => ({ genderOpened: !prevState.genderOpened }));
    toggleShirt = () => this.setState(prevState => ({ shirtOpened: !prevState.shirtOpened }));
    toggleShort = () => this.setState(prevState => ({ shortOpened: !prevState.shortOpened }));
    toggleCarriers = () => this.setState(prevState => ({ carriersOpened: !prevState.carriersOpened }));
    toggleDelete = () => this.setState(prevState => ({ deleteOpened: !prevState.deleteOpened }));

    selectGender = e => {
        const { user, validations } = this.state;
        const gender = _.find(realGenders, gender => gender.IdGender === parseInt(e.target.value, 10));
        if (gender) {
            user.IdGender = gender.IdGender;
            user.Gender = gender.Name;
        } else {
            user.IdGender = null;
            user.Gender = null;
        }
        this.setState({ user, validations }, () => { this.isValid(); });
    }

    selectCarrier = e => {
        const { carriers } = this.props, { user } = this.state;
        const carrier = _.find(carriers, carrier => carrier.IdCarrier === parseInt(e.target.value, 10));
        if (carrier) {
            user.Carrier = carrier || null;
            user.IdCarrier = carrier ? carrier.IdCarrier : 0;
        } else {
            user.Carrier = null;
            user.IdCarrier = null;
        }
        this.setState({ user });
    }

    selectShirt = e => {
        const shirt = _.find(clothingSizes, cs => cs === e.target.value);
        const { user } = this.state;
        user.ShirtSize = shirt || null;
        this.setState({ user });
    }

    selectShort = e => {
        const short = _.find(clothingSizes, cs => cs === e.target.value);
        const { user } = this.state;
        user.ShortSize = short || null;
        this.setState({ user });
    }

    selectGraduationYear = e => {
        const { college_profile = {} } = this.state;
        college_profile.GraduationYear = e.target.value;
        this.setState({ college_profile });
    }

    validate = key => {
        const { validations } = this.state;
        var valid = true;
        switch (key) {
            case 'txtName':
                validations.txtName = (this[key].value && this[key].value.length > 0) ? true : false;
                valid = !validations.txtName ? false : valid;
                break;
            case 'txtLastName':
                validations.txtLastName = (this[key].value && this[key].value.length > 0) ? true : false;
                valid = !validations.txtLastName ? false : valid;
                break;
            case 'txtDob':
                validations.txtDob = (ReactDOM.findDOMNode(this[key]).value && moment(ReactDOM.findDOMNode(this[key]).value, 'MM/DD/YYYY').isValid()) ? true : false;
                valid = !validations.txtDob ? false : valid;
                break;
            default:
                break;
        }

        this.setState({ validations, valid });
        return valid;
    }

    isValid = () => {
        const { validations, dirty } = this.state;
        if (dirty) {
            this.setState({ valid: !_.values(validations).includes(false) });
            return !_.values(validations).includes(false);
        } else {
            return this.state.valid;
        }
    }

    save = () => {
        if (this.props.micro) return;
        this.setState({ dirty: true, valid: true }, () => {
            if (this.isValid()) {

                let user = {
                    IdTeamDivision: this.state.user.IdTeamDivision,
                    IdUser: this.state.user.IdUser,
                    NameFirst: this.txtName.value,
                    NameLast: this.txtLastName.value,
                    IdGender: this.state.user.IdGender || 1,
                    Gender: this.state.user.Gender || 'Male',
                    DateBirth: moment(ReactDOM.findDOMNode(this.txtDob).value, 'MM/DD/YYYY'),
                    IsAdult: true, // ?
                    IsLeagueAdmin: false,
                    UserImage: this.state.logo || this.state.user.UserImage,
                    Token: null,
                    PhoneNumber: ReactDOM.findDOMNode(this.txtPhone).value,
                    EmailAddress: this.txtEmail.value,
                    JerseyNumber: this.state.user ? this.state.user.JerseyNumber : null,
                    Shirt: this.state.user.ShirtSize,
                    Short: this.state.user.ShortSize,
                    doUpdateImage: this.state.logo ? true : false,
                    Carrier: this.state.user.Carrier,
                    IdCarrier: this.state.user.IdCarrier,
                    MedicalNotes: this.txtMedical.value,
                    country_code: this.state.selected.country_code.countryCode,
                    CountryCode: this.state.selected.country_code.countryCode,
                    temporalId: generateRandomId(10)
                }

                this.props.setMicro(true);

                // Insert or Update
                if (user.IdUser) {
                    this.props.familyActions &&
                        this.props.familyActions.updateFamilyMember(user);
                } else {
                    this.props.familyActions &&
                        this.props.familyActions.addFamilyMember(user);
                    this.props.fn && this.props.fn();
                }
                this.props.toggle();
            }
        });
    }

    remove = () => {
        const { user } = this.state;
        this.props.familyActions &&
            this.props.familyActions.removeFamilyMember(user);
        this.props.toggle();
    }

    close = () => {
        const { toggle } = this.props;
        this.setState({ bootstrapped: false }, () => {
            setTimeout(() => { toggle && toggle(); }, 300);
        });
    }

    handleAvatar = e => {
        getBase64(e.target.files[0]).then(data => {
            this.setState({ logo: data });
        });
    }

    save_cp = () => {
        const { user, college_profile } = this.state;
        this.props.rosterActions && this.props.rosterActions.updateCollegeProfile({
            IdUser: user.IdUser,
            IdCollegeProfile: (college_profile || {}).IdCollegeProfile,
            GraduationYear: (college_profile || {}).GraduationYear,
            SchoolName: this.txtSchoolName.value,
            GPA: this.txtGPA.value,
            SAT: this.txtSAT.value,
            ACT: this.txtACT.value,
            Notes: (college_profile || {}).Notes
        });
        this.setState({ edit_cp: false });
    }

    onGender = gender => {
        const { user } = this.state;
        user.IdGender = gender;
        user.Gender = gender === 1 ? 'Male' : 'Female';
        const { validations } = this.state;
        validations.ddlistGender = true;
        this.setState({ user, validations });
    }

    selectCountryCode = (c) => {
        const { selected } = this.state;
        selected.CountryCode = c;
        this.setState({ selected });
    }

    render() {
        const { preventDelete, micro } = this.props, { user, college_profile, validations, dirty, bootstrapped, microTransac, selected, country_codes } = this.state;
        return (
            <section>

                {user &&
                    <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1042 }}>
                        <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />
                        <div className={`overlay-full show`} />
                        {/*<div className="" style={{
                            position: 'fixed', right: 0, top: 0, bottom: 0,
                            left: 0, backgroundColor: '#000000CC'
                        }} />*/}
                        <Animated animationIn="fadeInRight" animationOut="fadeOutRight" animationOutDuration={300} animationInDuration={300} isVisible={bootstrapped} style={{
                            position: 'fixed', right: 0, top: 0, bottom: 0, zIndex: 1041,
                            width: 500, borderLeft: '1px solid gray', overflowY: 'auto'
                        }} className="bg-gray">

                            <div className="d-flex flex-row p-3 bg-dark-blue">
                                <i className="fas fa-arrow-left font-12 pointer white" onClick={this.close} />
                                <span className="white montserrat font-12 ml-auto">{user.IdUser ? `${user.NameFirst}` : 'New Family Member'}</span>
                                <i className=" font-16 pointer ml-auto" />
                            </div>

                            <div className="d-flex flex-column justify-content-center bg-dark-blue">

                                <label htmlFor="fi" style={{ width: 160, height: 120, position: 'relative' }} className="pointer hoverable align-self-center mt-4 d-flex flex-column">
                                    <div className="cover align-self-center" style={{ width: 120, height: 120, borderRadius: 60, overflow: 'hidden', border: '1px solid white', background: `url(${this.state.logo || user.UserImage || `/images/defaults/${user.IdGender === 2 ? 'female' : 'male'}.png`}) no-repeat center center black` }} />
                                    <i style={{ position: 'absolute', right: 10, bottom: 0 }} className="green font-20 align-self-center icon-camera" />
                                </label>

                                <span className="font-16 montserrat white align-self-center mr-3 ml-3 mt-2">{user.NameFirst} {user.NameLast}</span>
                                <span className="font-10 montserrat white-ish align-self-center mr-3 ml-3 mb-4">{user.IdUser ? `Player ID: ${user.IdUser}` : ''}</span>

                            </div>

                            <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 80, zIndex: -1 }} className="bg-dark-blue" />
                                {!this.state.edit_cp &&
                                    <section>
                                        <div style={{ borderRadius: 5 }} className="ml-4 mr-4 d-flex flex-column">
                                            <span className="montserrat font-14 white">Basic Information</span>
                                            <div className="p-4 card tile shadow-box">

                                                { /* First Name  */}
                                                <div className="margin-bottom">
                                                    <div className={`d-flex flex-row`}>
                                                        <span className="font-10 black align-self-center">FIRST NAME</span>
                                                        <i className={`idented-half fa fa-asterisk font-10 align-self-center ${(dirty && !validations.txtName) ? 'red' : 'gray'}`} />
                                                    </div>
                                                    <input defaultValue={user.NameFirst} className={`${(!validations.txtName && dirty) ? 'input-danger' : ''} form-control`} type="text" name="firstname" placeholder="Enter the Name " ref={(input) => this.txtName = input} onBlur={() => this.validate('txtName')} />
                                                </div>

                                                { /* Last Name */}
                                                <div className="margin-bottom">
                                                    <div className={`d-flex flex-row`}>
                                                        <span className="font-10 black align-self-center">LAST NAME</span>
                                                        <i className={`idented-half fa fa-asterisk font-10 align-self-center ${(dirty && !validations.txtLastName) ? 'red' : 'gray'}`} />
                                                    </div>
                                                    <input defaultValue={user.NameLast} className={`${(!validations.txtLastName && dirty) ? 'input-danger' : ''} form-control`} type="text" name="lastname" placeholder="Enter the Last Name " ref={(input) => this.txtLastName = input} onBlur={() => this.validate('txtLastName')} />
                                                </div>

                                                {/* Gender */}
                                                <div className="w-100 text-center d-flex flex-row margin-bottom" style={{ border: '1px solid #ced4da', borderRadius: 3 }} >
                                                    <div className="w-100 text-center d-flex flex-column p-2" onClick={() => this.onGender(1)}>

                                                        <div style={{ width: 50, height: 50, border: '2px solid black', borderRadius: 25, overflow: 'hidden' }} className="text-center m-auto d-flex flex-column justify-content-end">
                                                            <span className={`${user.IdGender === 1 ? 'icon-male_on' : 'icon-man black'} font-30`}>
                                                                <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span><span className="path12"></span><span className="path13"></span><span className="path14"></span><span className="path15"></span><span className="path16"></span><span className="path17"></span>
                                                            </span>
                                                        </div>
                                                        <span className="font-10 black">Male</span>

                                                    </div>
                                                    <div className="w-100 text-center d-flex flex-column p-2" onClick={() => this.onGender(2)}>
                                                        <div style={{ width: 50, height: 50, border: '2px solid black', borderRadius: 25, overflow: 'hidden' }} className="text-center m-auto d-flex flex-column justify-content-end">
                                                            <span className={`${user.IdGender === 2 ? 'icon-female_on' : 'icon-woman'} font-30 black`}>
                                                                <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span><span className="path12"></span><span className="path13"></span><span className="path14"></span><span className="path15"></span>
                                                            </span>
                                                        </div>
                                                        <span className="font-10 black">Female</span>

                                                    </div>
                                                </div>

                                                { /* Phone */}
                                                {country_codes && country_codes.northAmericanCountries && <div className={`p-0 margin-bottom-half col-12 d-flex flex-row justify-content-center`}>

                                                    <Dropdown isOpen={this.state.isCCO ? true : false} toggle={() => this.setState({ isCCO: !this.state.isCCO })} className="form-control font-16 no-padding bg-white black w-50">
                                                        <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block h-100 text-left no-border d-flex flex-row">
                                                            <img src={selected.country_code ? `https://api.sportslogic.net/images/flags/${selected.country_code.countryISOCode}.png` : ''} alt="" className="h-50 align-self-center mr-1" />
                                                            <span className="black align-self-center font-16">{selected.country_code ? selected.country_code.countryCode : 'Code'}</span>
                                                            <i className="ml-auto fas fa-caret-down align-self-center black font-16" />
                                                        </DropdownToggle>
                                                        <DropdownMenu>

                                                            <DropdownItem header>North America</DropdownItem>
                                                            <DropdownItem divider />
                                                            {country_codes.northAmericanCountries && country_codes.northAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                const { selected } = this.state;
                                                                selected.country_code = c;
                                                                selected.mask = _.chain(c.phoneNumberMask)
                                                                    .map(a => {
                                                                        switch (a) {
                                                                            case '/[2-9]/': return /[2-9]/;
                                                                            case '/[1-9]/': return /[1-9]/;
                                                                            case '/[0-9]/': return /[0-9]/;
                                                                            case '/[5-9]/': return /[5-9]/;
                                                                            case '/[2-5]/': return /[2-5]/;
                                                                            case '/[2-6]/': return /[2-6]/;
                                                                            case '/\d/': return /\d/;
                                                                            default: return a;
                                                                        }
                                                                    }).value();
                                                                this.setState({ selected });
                                                            }}>
                                                                <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                            </DropdownItem>)}

                                                            <DropdownItem header>South America</DropdownItem>
                                                            <DropdownItem divider />
                                                            {country_codes.southAmericanCountries && country_codes.southAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                const { selected } = this.state;
                                                                selected.country_code = c;
                                                                selected.mask = _.chain(c.phoneNumberMask)
                                                                    .map(a => {
                                                                        switch (a) {
                                                                            case '/[2-9]/': return /[2-9]/;
                                                                            case '/[1-9]/': return /[1-9]/;
                                                                            case '/[0-9]/': return /[0-9]/;
                                                                            case '/[5-9]/': return /[5-9]/;
                                                                            case '/[2-5]/': return /[2-5]/;
                                                                            case '/[2-6]/': return /[2-6]/;
                                                                            case '/\d/': return /\d/;
                                                                            default: return a;
                                                                        }
                                                                    }).value();
                                                                this.setState({ selected });
                                                            }}>
                                                                <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                            </DropdownItem>)}

                                                            <DropdownItem header>America Central</DropdownItem>
                                                            <DropdownItem divider />
                                                            {country_codes.centralAmericanCountries && country_codes.centralAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                const { selected } = this.state;
                                                                selected.country_code = c;
                                                                selected.mask = _.chain(c.phoneNumberMask)
                                                                    .map(a => {
                                                                        switch (a) {
                                                                            case '/[2-9]/': return /[2-9]/;
                                                                            case '/[1-9]/': return /[1-9]/;
                                                                            case '/[0-9]/': return /[0-9]/;
                                                                            case '/[5-9]/': return /[5-9]/;
                                                                            case '/[2-5]/': return /[2-5]/;
                                                                            case '/[2-6]/': return /[2-6]/;
                                                                            case '/\d/': return /\d/;
                                                                            default: return a;
                                                                        }
                                                                    }).value();
                                                                this.setState({ selected });
                                                            }}>
                                                                <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                            </DropdownItem>)}

                                                            <DropdownItem header>Europe</DropdownItem>
                                                            <DropdownItem divider />
                                                            {country_codes.europeanCountries && country_codes.europeanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                const { selected } = this.state;
                                                                selected.country_code = c;
                                                                selected.mask = _.chain(c.phoneNumberMask)
                                                                    .map(a => {
                                                                        switch (a) {
                                                                            case '/[2-9]/': return /[2-9]/;
                                                                            case '/[1-9]/': return /[1-9]/;
                                                                            case '/[0-9]/': return /[0-9]/;
                                                                            case '/[5-9]/': return /[5-9]/;
                                                                            case '/[2-5]/': return /[2-5]/;
                                                                            case '/[2-6]/': return /[2-6]/;
                                                                            case '/\d/': return /\d/;
                                                                            default: return a;
                                                                        }
                                                                    }).value();
                                                                this.setState({ selected });
                                                            }}>
                                                                <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                            </DropdownItem>)}

                                                            <DropdownItem header>Africa</DropdownItem>
                                                            <DropdownItem divider />
                                                            {country_codes.africanCountries && country_codes.africanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                const { selected } = this.state;
                                                                selected.country_code = c;
                                                                selected.mask = _.chain(c.phoneNumberMask)
                                                                    .map(a => {
                                                                        switch (a) {
                                                                            case '/[2-9]/': return /[2-9]/;
                                                                            case '/[1-9]/': return /[1-9]/;
                                                                            case '/[0-9]/': return /[0-9]/;
                                                                            case '/[5-9]/': return /[5-9]/;
                                                                            case '/[2-5]/': return /[2-5]/;
                                                                            case '/[2-6]/': return /[2-6]/;
                                                                            case '/\d/': return /\d/;
                                                                            default: return a;
                                                                        }
                                                                    }).value();
                                                                this.setState({ selected });
                                                            }}>
                                                                <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                            </DropdownItem>)}

                                                            <DropdownItem header>Asia</DropdownItem>
                                                            <DropdownItem divider />
                                                            {country_codes.asianCountries && country_codes.asianCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                const { selected } = this.state;
                                                                selected.country_code = c;
                                                                selected.mask = _.chain(c.phoneNumberMask)
                                                                    .map(a => {
                                                                        switch (a) {
                                                                            case '/[2-9]/': return /[2-9]/;
                                                                            case '/[1-9]/': return /[1-9]/;
                                                                            case '/[0-9]/': return /[0-9]/;
                                                                            case '/[5-9]/': return /[5-9]/;
                                                                            case '/[2-5]/': return /[2-5]/;
                                                                            case '/[2-6]/': return /[2-6]/;
                                                                            case '/\d/': return /\d/;
                                                                            default: return a;
                                                                        }
                                                                    }).value();
                                                                this.setState({ selected });
                                                            }}>
                                                                <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                            </DropdownItem>)}

                                                            <DropdownItem header>Oceania</DropdownItem>
                                                            <DropdownItem divider />
                                                            {country_codes.oceaniaCountries && country_codes.oceaniaCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                                const { selected } = this.state;
                                                                selected.country_code = c;
                                                                selected.mask = _.chain(c.phoneNumberMask)
                                                                    .map(a => {
                                                                        switch (a) {
                                                                            case '/[2-9]/': return /[2-9]/;
                                                                            case '/[1-9]/': return /[1-9]/;
                                                                            case '/[0-9]/': return /[0-9]/;
                                                                            case '/[5-9]/': return /[5-9]/;
                                                                            case '/[2-5]/': return /[2-5]/;
                                                                            case '/[2-6]/': return /[2-6]/;
                                                                            case '/\d/': return /\d/;
                                                                            default: return a;
                                                                        }
                                                                    }).value();
                                                                this.setState({ selected });
                                                            }}>
                                                                <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                            </DropdownItem>)}

                                                        </DropdownMenu>
                                                    </Dropdown>

                                                    {selected.country_code && <MaskedInput guide={true} ref={(i) => this.txtPhone = i} className="form-control p-2 font-16 w-100" placeholder="Phone Number (*)" defaultValue={user.PhoneNumber}
                                                        mask={selected.mask} />}

                                                </div>}

                                                {/* DOB */}
                                                <div className="margin-bottom-half">
                                                    <div className={`d-flex flex-row`}>
                                                        <span className="font-10 black  align-self-center">DATE OF BIRTH</span>
                                                        <i className={`idented-half fa fa-asterisk font-10 align-self-center ${(dirty && !validations.txtDob) ? 'red' : 'gray'}`} />
                                                    </div>
                                                    <MaskedInput className={`${(!validations.txtDob && dirty) ? 'input-danger' : ''} form-control`} defaultValue={user.DateBirth ? moment(user.DateBirth).utc().format('MM/DD/YYYY') : null} placeholder="MM / DD / YYYY" id="txtDoB" ref={(i) => this.txtDob = i} guide={true} mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} onBlur={() => this.validate('txtDob')}
                                                    />
                                                </div>

                                                { /* EMAIL */}
                                                <div>
                                                    <div className={`d-flex flex-row`}>
                                                        <span className="font-10 black align-self-center">EMAIL</span>
                                                    </div>
                                                    <input defaultValue={user.EmailAddress} className="form-control" type="text" name="email" placeholder="Enter the Email" ref={(input) => this.txtEmail = input} onBlur={() => this.validate('txtEmail')} />
                                                </div>
                                            </div>

                                            <span className="block mt-2 montserrat font-14">Additional</span>
                                            <div className="p-4 card tile shadow-box">
                                                <div className="d-flex flex-row">
                                                    { /* Shirt */}
                                                    <div className={`w-100`} style={{ marginRight: '0.25em' }}>
                                                        <div className={`d-flex flex-row gray`}>
                                                            <span className="font-10 gray align-self-center">SHIRT SIZE</span>
                                                        </div>
                                                        <Input type="select" value={user.ShirtSize} onChange={this.selectShirt}>
                                                            <option value={null}>Select</option>
                                                            {clothingSizes && clothingSizes.map((cs, i) => <option key={i} value={cs}>{cs}</option>)}
                                                        </Input>
                                                    </div>
                                                    { /* Short */}
                                                    <div className={`w-100`} style={{ marginRight: '0.25em' }}>
                                                        <div className={`d-flex flex-row gray`}>
                                                            <span className="font-10 gray align-self-center">SHORT SIZE</span>
                                                        </div>
                                                        <Input type="select" value={user.ShortSize} onChange={this.selectShort}>
                                                            <option value={null}>Select</option>
                                                            {clothingSizes && clothingSizes.map((cs, i) => <option key={i} value={cs}>{cs}</option>)}
                                                        </Input>
                                                    </div>
                                                </div>
                                            </div>

                                            <span className="block mt-2 montserrat font-14">Medical Notes <i className={`fas fa-heartbeat ${user.MedicalNotes ? 'red' : ''}`} /></span>
                                            <div className="p-4 card tile shadow-box">
                                                <div className="d-flex flex-row">
                                                    { /* Shirt */}
                                                    <div className={`w-100`}>
                                                        <input maxLength={200} defaultValue={user.MedicalNotes} className="form-control" type="text" name="medical"
                                                            placeholder="200 Characters Max." ref={(input) => this.txtMedical = input} />
                                                    </div>

                                                </div>
                                            </div>
                                            <span className="font-8 text-right mt-2">(<i className={`fa fa-asterisk gray`} />) Required fields</span>

                                            {user.IdUser && this.props.college_profile && <button onClick={() => this.setState({ edit_cp: true })} className="btn btn-info mt-4 btn-block">College Profile</button>}
                                        </div>
                                        <div className="m-4">
                                            <button className="btn btn-success btn-block" onClick={this.save}>
                                                {micro ? 'Saving...' : 'Save'}
                                            </button>
                                            {user.IdUser && !preventDelete && <button id="Popover1" className="btn btn-link red btn-block" onClick={this.toggleDelete}>Delete</button>}
                                            {user.IdUser && !preventDelete && <Popover placement="bottom" isOpen={this.state.deleteOpened} target="Popover1" toggle={this.togglePopover}>
                                                <PopoverHeader>Are you sure?</PopoverHeader>
                                                <PopoverBody className="d-flex flex-row justify-content-center">
                                                    <button className="btn btn-success btn-sm" onClick={this.remove}>Yes, Delete it</button>
                                                    <button className="btn btn-danger btn-sm" onClick={this.toggleDelete}>No, Cancel</button>
                                                </PopoverBody>
                                            </Popover>}
                                        </div>
                                    </section>}



                                {this.state.edit_cp &&
                                    <section>
                                        <div style={{ borderRadius: 5 }} className=" card tile shadow-box bg-white p-4 ml-4 mr-4 d-flex flex-column">
                                            <span className="block montserrat font-14">College Profile</span>
                                            <div className="p-4 card tile shadow-box">
                                                <div className="d-flex flex-column">
                                                    {/* Graduation Year */}
                                                    <div className={`w-100 margin-bottom`} style={{ marginRight: '0.25em' }}>
                                                        <div className={`d-flex flex-row`}>
                                                            <span className="font-10 gray align-self-center">HIGH SCHOOL / PREP SCHOOL</span>
                                                        </div>
                                                        <input defaultValue={(college_profile || {}).SchoolName} className={`form-control`} type="text" name="schoolname" placeholder="N/A" ref={(input) => this.txtSchoolName = input} />

                                                    </div>
                                                    <div className="d-flex flex-row margin-bottom">
                                                        { /* GPA */}
                                                        <div className={`w-100`} style={{ marginRight: '0.25em' }}>
                                                            <div className={`d-flex flex-row gray`}>
                                                                <span className="font-10 gray align-self-center">GRADUATION YEAR</span>
                                                            </div>
                                                            <Input type="select" value={(college_profile || {}).GraduationYear} onChange={this.selectGraduationYear}>
                                                                <option value={null}>Select</option>
                                                                {[moment().get('year'),
                                                                moment().add(1, 'years').get('year'),
                                                                moment().add(2, 'years').get('year'),
                                                                moment().add(3, 'years').get('year'),
                                                                moment().add(4, 'years').get('year'),
                                                                moment().add(5, 'years').get('year'),
                                                                moment().add(6, 'years').get('year'),
                                                                moment().add(7, 'years').get('year'),
                                                                moment().add(8, 'years').get('year'),
                                                                moment().add(9, 'years').get('year'),].map((cs, i) => <option key={i} value={cs}>{cs}</option>)}
                                                            </Input>
                                                        </div>
                                                        { /* SAT */}
                                                        <div className={`w-100`} style={{ marginRight: '0.25em' }}>
                                                            <div className={`d-flex flex-row gray`}>
                                                                <span className="font-10 gray align-self-center">SAT</span>
                                                            </div>
                                                            <input defaultValue={(college_profile || {}).SAT} className={`form-control`} type="text" name="sat" placeholder="N/A" ref={(input) => this.txtSAT = input} />
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row margin-bottom">
                                                        { /* GPA */}
                                                        <div className={`w-100`} style={{ marginRight: '0.25em' }}>
                                                            <div className={`d-flex flex-row gray`}>
                                                                <span className="font-10 gray align-self-center">GPA</span>
                                                            </div>
                                                            <input defaultValue={(college_profile || {}).GPA} className={`form-control`} type="text" name="gpa" placeholder="N/A" ref={(input) => this.txtGPA = input} />
                                                        </div>
                                                        { /* SAT */}
                                                        <div className={`w-100`} style={{ marginRight: '0.25em' }}>
                                                            <div className={`d-flex flex-row gray`}>
                                                                <span className="font-10 gray align-self-center">ACT</span>
                                                            </div>
                                                            <input defaultValue={(college_profile || {}).ACT} className={`form-control`} type="text" name="sat" placeholder="N/A" ref={(input) => this.txtACT = input} />
                                                        </div>
                                                    </div>

                                                    {/* Graduation Year */}
                                                    <div className={`w-100 margin-bottom`} style={{ marginRight: '0.25em' }}>
                                                        <div className={`d-flex flex-row`}>
                                                            <span className="font-10 gray align-self-center">NOTES / ACHIEVMENTS</span>
                                                        </div>
                                                        <textarea defaultValue={(college_profile || {}).Notes} className={`form-control`} rows="4" name="schoolname" placeholder="N/A" ref={(input) => this.txtNotes = input} />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="m-4">
                                            <button className="btn btn-success btn-block" onClick={this.save_cp}>
                                                {microTransac ? 'Saving...' : 'Save'}
                                            </button>
                                            <button className="btn btn-link black btn-block" onClick={() => this.setState({ edit_cp: false })}>
                                                Cancel
                                            </button>
                                        </div>
                                    </section>}


                            </div>
                        </Animated>

                    </div>}
            </section>
        )
    }
}

export default CreateUser;
