import _ from 'lodash';
import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import CoachItem from './partials/coach_item';
import { Jumbotron } from 'reactstrap';
import FileModal from './partials/file_modal';
import LineLoader from '../common/LineLoader';

class CoachCertificates extends React.Component {

    state = {
        all: false,
        filters: false,
        letter: 'A',
        year: null,
        gender: null,
        search_text: null,
        dates: [],
        dropdownOpen: false,
        date: null,
        other: false
    }

    // Lifecycle
    componentWillMount() {
        const { params } = this.props;
        const { dates } = this.state;
        for (var i = 0; i < 97; i++) {
            dates.push(moment().add((3 + i) * -1, 'years').format('YYYY'));
        }
        this.setState({ dates, letter: params.letter || 'A', year: params.year || null }, this.fetch);
    }

    componentWillReceiveProps = nextProps => {
        if ((nextProps.params.letter || 'A') !== this.state.letter) {
            this.txtSearch.value = '';
            this.setState({ letter: nextProps.params.letter || 'A', bootstrapped: false, search_text: null }, this.fetch);
        } else if (nextProps.users) {
            this.setState({ bootstrapped: true, softLoading: false });
        }
    }
    componentWillUnmount = () => {
        this.props.leagueActions.clearUsers();
    }
    //

    toggleOtherDates = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

    fetch = () => {
        const { letter } = this.state, { leagueActions, params } = this.props, { idLeague } = params;
        leagueActions && leagueActions.fetchValidationsByLetter(idLeague, letter, 'COACH');
        leagueActions && leagueActions.fetchSummaryByType(idLeague, 'COACH');
    }

    bringFileUp = (file) => this.setState({ file });

    render() {
        const { params, users, leagueActions, validations_summary = {}, spread } = this.props,
            { year } = params,
            { totals, league_summary = [] } = validations_summary,
            { idLeague } = params,
            { all, filters, letter, gender, search_text } = this.state;

        let sum = _.find(league_summary, v => v.ValidationProcessCode === 'COACH') || {};

        const filtered_users =
            _.chain(users)
                .filter(u => all ? true : !u.valid)
                .filter(u => letter === 'All' ? true : u.NameLast.substring(0, 1).toUpperCase() === letter)
                .filter(u => gender ? u.IdGender === gender : true)
                .filter(u => search_text ? `${u.NameFirst.toLowerCase()} ${u.NameLast.toLowerCase()}`.indexOf(search_text.toLowerCase()) !== -1 : true)
                .filter(u => year ? moment(u.DateOfBirth.split('T')[0], 'YYYY-MM-DD').format('YYYY') === year : true)
                .sortBy('NameLast').value();

        const [with_instruments, without_instruments] = _.partition(filtered_users, u => u.ValidationProcess && u.ValidationProcess.instruments && u.ValidationProcess.instruments.length)

        return <section>
            {this.props.micro && <LineLoader />}
            <Animated animateOnMount={true} animationIn="fadeInRight" animationOut="fadeOutRight" className="d-flex flex-column p-2">

                <div className="d-flex flex-row">
                    <button onClick={() => this.setState({ filters: !this.state.filters })} className={`btn btn-${filters ? 'default bordered' : 'link'} ml-auto`}><i className="fas fa-filter" /><span className="ml-2">Advanced Filters</span></button>
                    <input placeholder="Search" style={{ width: 300 }} className="form-control align-self-center" ref={i => this.txtSearch = i} onChange={() => this.setState({ search_text: this.txtSearch.value })} />
                    <button onClick={() => this.setState({ all: false })} className={`font-12 ${all ? 'black' : 'blue'} pointer btn btn-link align-self-center ml-3`} style={{ width: 'auto' }}>Pending ({this.props.micro ? '-' : _.filter(filtered_users, u => !u.valid).length} / {sum.TotalUsersWithoutValidation})</button>
                    <span className="mx-3 align-self-center">|</span>
                    <button onClick={() => this.setState({ all: true })} className={`font-12 ${all ? 'blue' : 'black'} pointer btn btn-link align-self-center`} style={{ width: 'auto' }}>All ({this.props.micro ? '-' : (users || []).length} / {sum.TotalUsers})</button>
                </div>

                <div className="d-flex flex-row justify-content-center mt-2 mx-2">
                    {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map((l, i) =>
                        <Link
                            title={`${(_.find(spread || [], s => s.Letter === l) || { TotalUsersWithoutValidation: 0, TotalUsers: 0 })[all ? 'TotalUsers' : 'TotalUsersWithoutValidation']}`}
                            to={`/validations/${idLeague}/coaches/${l}`}
                            key={i}
                            className={`btn mx-1 btn-sm btn-${l === letter ? 'default bg-blue-dark white' : `default ${(_.find(spread || [], s => s.Letter === l) || { TotalUsersWithoutValidation: 0, TotalUsers: 0 })[all ? 'TotalUsers' : 'TotalUsersWithoutValidation'] === 0 ? 'opaque' : ''}`} w-100`}
                            style={{ border: `solid ${(_.find(spread || [], s => s.Letter === l) || {}).TotalUsersWithoutValidationWithInstruments ? '2px green' : '1px black'}` }}
                        >{l}</Link>
                    )}
                </div>

                {filters && <section>
                    <div className="d-flex flex-row justify-content-center mt-2 mx-2">
                        <button onClick={() => this.setState({ gender: 1 })} className={`btn mx-1 btn-sm btn-${gender === 1 ? 'default bg-blue-dark white' : 'default bordered'} w-100`}><i className="fas fa-male mr-2" />Male</button>
                        <button onClick={() => this.setState({ gender: 2 })} className={`btn mx-1 btn-sm btn-${gender === 2 ? 'default bg-blue-dark white' : 'default bordered'} w-100`}><i className="fas fa-female mr-2" />Female</button>
                        <button onClick={() => this.setState({ gender: null })} className={`btn mx-1 btn-sm btn-${!gender ? 'default bg-blue-dark white' : 'default bordered'} w-100`}>All</button>
                    </div>
                </section>
                }

                {this.props.micro && <Jumbotron className="mt-4">
                    <h2>Fetching registered users with letter '{this.props.params.letter}'</h2>
                    <h4>Please wait a few seconds....</h4>
                </Jumbotron>}

                {!this.props.micro && <div className="p-2 mt-4">
                    <hr />
                    <h3>With Documents</h3>
                    {with_instruments.length > 0 && <section>
                        <ul>
                            {with_instruments.map((user, i) => <CoachItem licences={totals} leagueActions={leagueActions} key={`${user.IdUser}-COACH`} user={user}
                                idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />)}
                        </ul></section>}
                    {with_instruments.length === 0 && <Jumbotron className="mt-4 bg-warning text-center">
                        <h2>There are no new documents to validate with letter '{this.props.params.letter}'</h2>
                    </Jumbotron>}
                    <hr />
                    {without_instruments.length > 0 && <section><ul>
                        <h3>Without Documents</h3>
                        {without_instruments.map((user, i) => <CoachItem licences={totals} leagueActions={leagueActions} key={`${user.IdUser}-COACH`} user={user}
                            idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />)}
                    </ul></section>}
                </div>}

            </Animated>

            <FileModal isOpen={this.state.file ? true : false} file={this.state.file} toggle={() => this.setState({ file: null })} {...this.props} />
        </section>
    }
}

export default CoachCertificates;
