import React from 'react';
import Totals from './Totals';
import Season from '../../registration/partials/Season';
import Division from '../../registration/partials/Division';
import Person from '../../registration/partials/Person';

class CoachItem extends React.Component {

  state = {
    removing: false
  }

  toggleDelete = () => this.setState({ removing: !this.state.removing });

  render() {
    const { item, fnDelete } = this.props, { removing } = this.state;

    return (
      <figure className="d-flex flex-column no-margin h-auto" style={{ position: 'relative' }}>
        <div className="d-flex flex-row d-2">
          <button onClick={this.toggleDelete} className="ml-auto btn btn-link align-self-center font-10 underlined red ">Remove</button>
        </div>
        <Season season={item.selectedSeason} />        
        <Person selectedUser={item.selectedUser} selectedRole={item.selectedRole} />
        <Division selectedDivision={item.selectedDivision} />
        <Totals item={item} />
        {removing && (
          <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} className="d-flex flex-column justify-content-center p-4 bg-white">
            <button className="btn btn-danger" onClick={() => fnDelete(item.Id)}>Confirm Remove</button>
            <button className="btn btn-link" onClick={this.toggleDelete}>No, Cancel</button>
          </div>)}
      </figure>)
  }
}

export default CoachItem;