import React from 'react';
import GuestGame from './GuestGame';
import _ from 'lodash';

class GamesAsGuest extends React.Component {
    componentWillMount() {
        const { entity } = this.props;
        this.setState({ entity });
    }
    componentWillReceiveProps = nextProps => {
        const { entity } = nextProps;
        this.setState({ entity });
    }

    onClick = (game) => {
        // TODO: call APIS
        const { rosterActions, team } = this.props,
            { entity } = this.state;
        rosterActions && rosterActions.removeGuestFromGame({
            IdUser: entity.IdUser,
            IdGame: game.IdGame || game.IdEvent,
            IdTeamDivision: team.IdTeamDivision
        });

        // Update the local copy
        entity.games = _.filter(entity.games, g => g !== parseInt(game.IdGame || game.IdEvent, 10));
        this.setState({ entity });
    }

    render() {
        const { games } = this.props, { entity } = this.state;
        let poolplay_games = _.filter(games, g => entity.games.indexOf(parseInt(g.IdGame, 10)) !== -1 && !g.IsPlayOff),
            playoff_games = _.filter(games, g => entity.games.indexOf(parseInt(g.IdGame, 10)) !== -1 && g.IsPlayOff)
        return <section className="pt-2">
            {poolplay_games.length > 0 && <h4 className="p-2 text-center">Poolplay Games</h4>}
            {poolplay_games && poolplay_games.map((game, i) => <GuestGame entity={entity} game={game} key={game.IdGame} onClick={() => this.onClick(game)} />)}
            {playoff_games.length > 0 && <h4 className="p-2 text-center">Playoff Games</h4>}
            {playoff_games && playoff_games.map((game, i) => <GuestGame entity={entity} game={game} key={game.IdGame} onClick={() => this.onClick(game)} />)}
            <div style={{ height: 100 }} />
        </section>
    }
}

export default GamesAsGuest;
