import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';

class CoachSelection extends React.Component {
    state = {
        step: 1,
        the_user: {},
        family: [],
        coachRoles: [
            {
                Id: 4,
                Name: 'Coach',
                selected: true
            },
            {
                Id: 4,
                Name: 'Assistant Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Team Manager',
                selected: false
            },
        ]
    }

    // Lifecycle
    componentWillMount() {
        const { family, roles } = this.props;
        // Clear
        family.forEach(u => u.selected = false);
        this.setState({ family: [...family], roles: _.filter(roles || [], r => r.IdRole === 4) });
    }
    componentWillReceiveProps = nextProps => {
        const { roles } = nextProps;
        this.setState({ roles: _.filter(roles || [], r => r.IdRole === 4) });
    }
    //

    selectUser = (idUser) => {
        const { family } = this.state;
        family.forEach(f => f.selected = f.IdUser === idUser);
        this.setState({ family, the_user: _.find(family, f => f.selected) });
    }

    selectRole = index => {
        const { roles } = this.state;
        roles.forEach(r => r.selected = false);
        (roles[index] || {}).selected = true;
        this.setState({ roles });
    }

    onRole = () => {
        const { family } = this.state;
        if (_.find(family, u => u.selected))
            this.setState({ step: 2 });
    }

    onOk = () => {
        const { family, roles } = this.state;
        let selectedUser = _.find(family, f => f.selected), selectedRole = _.find(roles, r => r.selected);
        if (selectedUser && selectedRole) {
            this.props.onOk && this.props.onOk({
                selectedUser, mode: 2, selectedRole: {
                    Id: selectedRole.IdRole,
                    IdRoleOverride: selectedRole.IdRoleOverride,
                    Name: selectedRole.Name,
                    selected: true
                }
            });
        }
    }

    render() {
        const { familyRegistration, cart, season } = this.props, { family, the_user, step, roles } = this.state;
        let alreadyRegistered = [
            ..._.filter(family, user => (_.find(familyRegistration, fr => fr.IdUser === user.IdUser && fr.IdRole === 4)))
        ],
            inCart = [..._.filter(family, f => _.find(cart, c => c.selectedUser.IdUser === f.IdUser && c.selectedRole && c.selectedSeason.IdSeason === season.IdSeason))],
            available = _.difference(family, [...alreadyRegistered, ...inCart]);

        return <div className="p-4 h-100  montserrat">

            <div className="pointer tile card shadow-box p-4 d-flex flex-column justify-content-center m-auto bg-blue-ish" style={{ height: 160 }}>
                <i className="icon-coach_registration white font-40 align-self-center" />
                <span className="white font-20 toBold align-self-center">COACH</span>
                <span className="font-10 white align-self-center">Registration</span>
            </div>

            {step === 1 && <Animated animationIn="slideInRight" className="d-flex flex-column">

                {available.length > 0 && <section className="d-flex flex-column">

                    <span className="black mt-4 align-self-center">Available</span>

                    {available.map((user, i) => (
                        <div key={i} className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-2 ${user.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}
                            onClick={() => this.selectUser(user.IdUser)}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <span className="align-self-center font-12">{user.NameFirst} {user.NameLast}</span>
                            <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                        </div>))}

                </section>}

                {inCart.length > 0 && <section className="d-flex flex-column">

                    <span className="black mt-4 align-self-center">In Cart</span>

                    {inCart.map((user, i) => (
                        <div key={i} className="w-100 tile card shadow-box d-flex flex-row align-self-center mt-2" style={{ height: 60, marginRight: 0 }}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <div className="d-flex flex-column align-self-center">
                                <span className=" black font-12 line1">{user.NameFirst} {user.NameLast}</span>
                                <span className="green font-8 line1">In Cart</span>
                            </div>
                        </div>))}

                </section>}

                {alreadyRegistered.length > 0 && <section className="d-flex flex-column">

                    <span className="black mt-4 align-self-center">Already Registered</span>

                    {alreadyRegistered.map((user, i) => (
                        <div key={i} className="w-100 tile card shadow-box d-flex flex-row align-self-center mt-2" style={{ height: 60, marginRight: 0 }}>
                            <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${user.UserImage}) no-repeat center center` }} />
                            <div className="d-flex flex-column align-self-center">
                                <span className=" black font-12 line1">{user.NameFirst} {user.NameLast}</span>
                                <span className="green font-8 line1">Already Registered</span>
                            </div>
                        </div>))}

                </section>}

                <button onClick={this.onRole} className={`btn btn-${_.find(family, u => u.selected) ? 'success' : 'default'} mt-4`}>Continue</button>

                <div style={{ height: 100 }} />

            </Animated>}

            {step === 2 && <div className="d-flex flex-column">
                <div className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-4 ${the_user.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}
                    onClick={() => this.setState({ step: 1 })}>
                    <div className="cover align-self-center" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10, borderRadius: 20, background: `url(${the_user.UserImage}) no-repeat center center` }} />
                    <span className="align-self-center font-12">{the_user.NameFirst} {the_user.NameLast}</span>
                    <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                </div>

                {roles.length > 0 && <section className="d-flex flex-column">

                    <span className="black mt-4 align-self-center">Role</span>

                    {roles.map((role, i) => (
                        <div key={i} className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-2 ${role.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}
                            onClick={() => this.selectRole(i)}>
                            <span className="align-self-center ml-3 font-12">{role.Name}</span>
                            <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                        </div>))}

                </section>}

                <button onClick={this.onOk} className={`btn btn-${_.find(roles, u => u.selected) ? 'success' : 'default'} mt-4`}>Continue</button>

                <div style={{ height: 100 }} />
            </div>}
        </div>
    }
}

export default CoachSelection;
