import React from 'react';
import moment from 'moment';

const NetworkItem = ({ user, rostered, onSelect }) => {    
    return (
        <div onClick={() => !rostered && onSelect()} className={`card m-2 tile shadow-box d-flex flex-row justify-content-start montserrat font-12 ${user.selected ? 'white' : ''} ${rostered ? '' : 'pointer hoverable'}`} style={{ height: 60, width: 150, backgroundColor: user.selected ? 'green' : 'white', overflow: 'hidden' }}>
            <div style={{ width: 60 }} className="d-flex flex-row justify-content-center align-self-center">
                <div className="cover" style={{ width: 60, height: 60, background: `url(${user.Image || user.UserImage || user.ImageUrl}) no-repeat center center` }} />
            </div>
            <div className="d-flex flex-column align-self-center pl-2 pr-2 justify-content-center">
                <span className="font-8 line1 text-center align-self-center">{user.NameFirst} {user.NameLast}</span>
                <span className="font-6 line1 text-center align-self-center">{moment().diff(moment(user.DateBirth.split('T')[0], user.DateBirth.split('T')[0].indexOf('-') !== -1 ? 'YYYY-MM-DD' : 'MM/DD/YYYY'), 'years')} Years Old</span>
                {rostered && <span className="green font-6 line1 align-self-center">Already rostered</span>}
            </div>
        </div>
    );
}

export default NetworkItem;
