import React from 'react';
import { DropTarget } from 'react-dnd';
import FlightedTeam from './FlightedTeam';

const squareTarget = {
    canDrop({ flight }, monitor) {
        if (flight.IdFlight && monitor.getItem().team && monitor.getItem().team.IdFlight === flight.IdFlight) return false;
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)        
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }

        // Dropped on the same flight
        if (props.flight.IdFlight === monitor.getItem().IdFlight) {
            // TODO: Move to last!!!!
            return;
        }

        props.addTeam && props.addTeam(monitor.getItem());
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

class TeamDroppableArea extends React.Component {

    removeTeam = (teamFlighted) => {
        this.props.removeTeam && this.props.removeTeam(teamFlighted);
    }

    sortTeams = (args) => {
        const { flight } = this.props,
            { from, to, targetFlight } = args;

        this.props.sortTeams && this.props.sortTeams({
            idFlight: flight.IdFlight,
            from,
            to,
            targetFlightId: targetFlight
        });

    }

    moveTeam = (args) => {
        //const { source, to, of } = args;
        this.props.moveTeam && this.props.moveTeam(args)
    }

    render() {

        const { connectDropTarget, isOver, canDrop, flight } = this.props;

        return connectDropTarget(
            <div className={`d-flex flex-column flight-list-teams-wrapper ${isOver ? 'droppable-area' : (canDrop ? 'none' : 'none')}`}>
                {(flight.teams.length > 0) && <span className="watermark">{flight.teams.length}</span>}
                {(flight.MinimumGamesPerTeam % 2) === 1 && flight.teams && (flight.teams.length % 2) === 1 && <div className="bg-danger text-center white font-8" style={{ padding: '0.5em' }}>
                    You cannot have an odd number of teams playing an odd number of games. Please correct one of the two.
                </div>}
                {flight.teams && flight.teams.map((teamFlighted, index) => <FlightedTeam team={teamFlighted} index={index} key={teamFlighted.IdTeam} removeTeam={this.removeTeam} sortTeams={this.sortTeams} moveTeam={this.moveTeam}
                />)}
                {canDrop && <div className="p-3 m-2 d-flex flex-row justify-content-center" style={{ backgroundColor: isOver ? '#f5f5f5' : '#e5e5e5', border: '2px dashed gray', borderRadius: 4 }}>
                    <span className="align-self-center font-10">Drop Here</span>
                </div>}
            </div>)

    }
}

export default DropTarget(props => props.accept, squareTarget, collect)(TeamDroppableArea);