import React from 'react';
import request from 'superagent';
import config from '../../../../../../config';

class Comp extends React.Component {

  componentDidMount = () => {
    const { question } = this.props;
    if (question.editing) {
      this.txt.focus();
    }
  }
  handleChange = (e) => {
    const { question } = this.props;
    if (!question.editing) {
      question.editing = true;
      question.text = e.target.value;
      this.props.questionsActions &&
        this.props.questionsActions.updateQuestionText(question);
    }
  }

  delete = () => {
    const { question } = this.props;
    this.props.questionsActions &&
      this.props.questionsActions.removeQuestionText(question.IdQuestionLibrary || question.id);
  }

  select = () => {
    const { question } = this.props;
    question.selected = !question.selected;
    this.props.questionsActions &&
      this.props.questionsActions.selectAQuestion(question);
  }

  save = (e) => {
    e.preventDefault && e.preventDefault();
    const { question, season } = this.props;
    question.editing = false;
    question.text = this.txt.value;

    // call api to save the question
    // TODO: feedback of the api call ?
    request[question.IdQuestionLibrary ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/questions/library/question_text/${question.IdQuestionLibrary || season.IdLeague}`)
      .send({
        text: question.text,
        idQuestionType: question.question_type
      })
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then(({ body }) => {
        if (body.success) {
          question.IdQuestionLibrary = body.IdQuestionLibrary;
          this.props.questionsActions &&
            this.props.questionsActions.updateQuestionText(question);
        } else {
          alert('Something is wrong!')
        }
      }, reject => {
        // TODO: bubble the error!
        alert('Something is wrong!')
      });

    this.props.questionsActions &&
      this.props.questionsActions.updateQuestionText(question);
    this.txt.blur();
  }

  render() {
    const { question, lock, selectable } = this.props;

    return <form className="input-group" key={question.id}>
      {selectable && <div className="input-group-prepend">
        <button type="button" className="btn" onClick={this.select}><i className={`far fa-${question.selected ? 'check-square' : 'square'}`}></i></button>
      </div>}
      <input disabled={lock ? true : false} defaultValue={question.text || question.Text} placeholder="Type the question text here" type="text" className="form-control"
        onChange={this.handleChange} ref={(i) => this.txt = i} />
      {question.editing && !lock && <div className="input-group-append d-flex flex-row">
        <button type="submit" className="btn btn-sm ml-auto btn-success" onClick={this.save}><i className="fa fa-check"></i></button>
        <button type="button" className="btn btn-sm ml-auto btn-danger" onClick={this.delete}><i className="fa fa-times"></i></button>
      </div>}
      {!question.editing && !lock && <div className="input-group-append d-flex flex-row">
        <button type="button" className="btn btn-sm ml-auto" onClick={this.delete}><i className="fa fa-times red"></i></button>
      </div>}
    </form>
  }
}

export default Comp;
