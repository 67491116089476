import React from 'react';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Row from './row';


class Comp extends React.Component {
    state = {
        bootstrapped: false,
        sheetMode: 1
    }

    // Lifecycle
    componentWillMount() {

        const { params, setTitle } = this.props, { idSeason } = params;

        this.props.fetchSizes && this.props.fetchSizes();
        this.props.fetchSeason && this.props.fetchSeason(idSeason);
        this.props.reportsActions && this.props.reportsActions.fetchShirtsReport(idSeason);

        setTitle && setTitle({
            main: 'Report | Shirts'
        });
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;

            setTitle && setTitle({
                sub: 'Report | Shirts',
                main: nextProps.season.Name || null
            });
        }
        if (nextProps.report) {

            this.setState({ bootstrapped: true });
        }
    }
    componentWillUnmount() {
        this.props.reportsActions && this.props.reportsActions.clearReport();
    }
    //

    toggleSheetMode = mode => this.setState({ sheetMode: mode });


    render() {
        const { bootstrapped } = this.state,
            { report = {}, season } = this.props;

        const totals = {
            ys: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "Youth Small").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'Youth Small').value().length
            ) : 0) : 0,
            ym: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "Youth Medium").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'Youth Medium').value().length
            ) : 0) : 0,
            yl: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "Youth Large").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'Youth Large').value().length
            ) : 0) : 0,
            yxl: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "Youth XL").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'Youth XL').value().length
            ) : 0) : 0,
            s: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "Small").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'Small').value().length
            ) : 0) : 0,
            m: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "Medium").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'Medium').value().length
            ) : 0) : 0,
            l: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "Large").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'Large').value().length
            ) : 0) : 0,
            xl: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "X-Large").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'X-Large').value().length
            ) : 0) : 0,
            xxl: report ? _.chain(report.teams).map(t => t.Players).flattenDepth().filter(p => p.ShirtSize === "XX-Large").value().length + (this.state.sheetMode === 1 ? (
                _.chain(report.teams).map(t => t.Coaches).flattenDeep().filter(p => p.ShirtSize === 'XX-Large').value().length
            ) : 0) : 0,
        }


        return <section>
            <div className="filters-wrapper d-flex flex-row">
                <div className="ml-auto p-2">
                    <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-sm btn-warning" table="table-to-xls"
                        filename={`Shirts ${season.Name}`}
                        sheet="Shirts" buttonText="Export as XLS" />
                </div>
            </div>
            <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
                {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
                {bootstrapped && (
                    <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
                        <table id="table-to-xls" className="table table-sm font-10">
                            <thead>
                                <tr className="toBold">
                                    <td>Team Name</td>
                                    <td align="center">Division</td>
                                    <td align="center">YS</td>
                                    <td align="center">YM</td>
                                    <td align="center">YL</td>
                                    <td align="center">YX</td>
                                    <td align="center">S</td>
                                    <td align="center">M</td>
                                    <td align="center">L</td>
                                    <td align="center">XL</td>
                                    <td align="center">XXL</td>
                                    <td align="center">Signature</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-info font-12 white">
                                    <td colSpan="2">Total Shirts:</td>
                                    <td align="center">{totals.ys}</td>
                                    <td align="center">{totals.ym}</td>
                                    <td align="center">{totals.yl}</td>
                                    <td align="center">{totals.yxl}</td>
                                    <td align="center">{totals.s}</td>
                                    <td align="center">{totals.m}</td>
                                    <td align="center">{totals.l}</td>
                                    <td align="center">{totals.xl}</td>
                                    <td align="center">{totals.xxl}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <tbody>
                                {report && report.teams.map((team, i) => (
                                    <Row team={team} key={i} showCoaches={this.state.sheetMode === 1} />)
                                )}
                            </tbody>
                            <tfoot>
                                <tr className="bg-info font-12 white">
                                    <td colSpan="2">Total Shirts:</td>
                                    <td align="center">{totals.ys}</td>
                                    <td align="center">{totals.ym}</td>
                                    <td align="center">{totals.yl}</td>
                                    <td align="center">{totals.yxl}</td>
                                    <td align="center">{totals.s}</td>
                                    <td align="center">{totals.m}</td>
                                    <td align="center">{totals.l}</td>
                                    <td align="center">{totals.xl}</td>
                                    <td align="center">{totals.xxl}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </section>)}
            </div>

            { /* Print controls */}
            {report && report.teams && (
                <section style={{ position: 'fixed', bottom: 50, right: 20 }} className="d-flex flex-column p-2">
                    <button className="btn btn-link btn-sm text-right" onClick={() => this.toggleSheetMode(1)}>
                        <i className={`${this.state.sheetMode === 1 ? 'fa fa-check-circle green' : 'far fa-circle white'} shadow`} />
                        <span className="idented-half white shadow">Players and Coaches</span>
                    </button>
                    <button className="btn btn-link btn-sm text-right" onClick={() => this.toggleSheetMode(2)}>
                        <i className={`${this.state.sheetMode === 2 ? 'fa fa-check-circle green' : 'far fa-circle white'} shadow`} />
                        <span className="idented-half white shadow">Just Players</span>
                    </button>
                </section>)}
        </section>
    }
}

export default Comp;
