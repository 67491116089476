import React from 'react';
import { ItemTypes } from '../../../../helpers';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow'

const teamSource = {
    beginDrag(props) { return props; }
};

class TeamListItem extends React.Component {
    render() {
        const { connectDragSource, isDragging, team, canDrop } = this.props;
        const style = { color: team.Color || 'white' }

        if (team.IdRoleStatus === 1 || team.IdRoleStatus === 2) {
            return connectDragSource(
                <div className="pl-2" key={team.IdTeam} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move', borderBottom: isDragging ? '1px solid gray' : (canDrop ? '1px dotted gray' : '1px solid #ccc') }}>
                    {team.Color && <i className="fa fa-plane margin-right-half" style={style} />}
                    <span>{team.Name}<span className="font-8 margin-left-half">{team.IdRoleStatus === 1 ? '(Pending)' : ''}</span></span>
                </div>)
        } else {
            return <div key={team.IdTeam} style={{ opacity: 0.5, borderBottom: '1px solid #ccc' }}>
                <span>{team.Name}<span className="font-8 margin-left-half red">({team.Status})</span></span>
            </div>
        }
    }
}

export default flow(
    DragSource(ItemTypes.TEAM, teamSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }))
)(TeamListItem);
