import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { generateRandomId } from '../../../../../helpers';
import _ from 'lodash';
import ExceptionRule from '../exceptions/ExceptionRule';

class ExceptionRuleIcon extends React.Component {
    state = {
        popupverOpen: false,
        removed: false,
        randomId: generateRandomId(10)
    }

    removeFromElement = () => {
        const { rule, exceptionRulesActions } = this.props;
        exceptionRulesActions.removeAssociation && exceptionRulesActions.removeAssociation(rule.Id);
        this.setState({ popoverOpen: false });
    }

    toggle = () => this.setState({ popoverOpen: !this.state.popoverOpen });

    componentDidUpdate = () => {
        const { rule } = this.props;
        // Shake feedback for when the Rule is re-droped on the same Flight
        if (rule.shake) {
            setTimeout(() => {
                rule.shake = false;
                this.forceUpdate();
            }, 1500)
        }
    }

    render() {
        const { rule, index, rules } = this.props, { randomId } = this.state,
            targetRule = _.find(rules, function (r) {
                return r.Id === rule.IdRule
            });

        const { removed } = this.state;

        if (targetRule) {
            return (
                <i onClick={this.toggle} className={`fa fa-${(targetRule.type || targetRule.Type) === 'exception/time' ? "clock" : "random"} ${(!rule.Id || removed) ? 'gray' : 'red'} 
                    ${ rule.shake ? 'shake' : ''}`}
                    title={`${targetRule.Name || targetRule.RuleName}`} data-for={`rule${index}`} id={`btn-exception-${randomId}`}>
                    {/*<ReactTooltip effect="solid" place="top" id={`rule${index}`} />*/}
                    <Popover placement="top" isOpen={this.state.popoverOpen} target={`btn-exception-${randomId}`} toggle={this.toggle}>
                        <PopoverBody>
                            <ExceptionRule rule={targetRule} readOnly={true} />
                            <button className="btn btn-danger btn-sm btn-block font-8" onClick={this.removeFromElement}>Remove this Exception from Flight ?</button>
                        </PopoverBody>
                    </Popover>
                </i>
            );
        } else {
            return (<i className="fa fa-exclamation white hide" />)
        }
    }
}

export default ExceptionRuleIcon;