
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as spotlightActions from '../redux-sagas/actions/sportlightActions';
import * as usersActions from '../redux-sagas/actions/usersActions';
import Main from '../components/Main.DOB';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        auth: state.auth,
        loggedIn: state.loggedIn,
        spotlight: state.spotlight,
        spotlightOn: state.spotlightOn,
        title: state.title,
        micro: state.micro,
        users: state.users,
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        leagueActions: bindActionCreators(leagueActions, dispatch),
        spotlightActions: bindActionCreators(spotlightActions, dispatch),
        usersActions: bindActionCreators(usersActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
