import React from 'react';
import './menu.css';
import paths from '../../ioc/registrationPaths';
import { Link } from 'react-router';
import _ from 'lodash';

class RegistrationMenu extends React.Component {

    state = {
        tree: null,
        lastPath: ''
    }

    handlePath = () => {
        if (this.props.routes && this.props.routes.length) {

            let leafPath = _.last(_.chain(this.props.routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'));

            Object.keys(paths).forEach(function (key) {
                var path = paths[key];
                if (path.sub_nav) {
                    var subFound = path.sub_nav[leafPath];
                    if (subFound) {
                        path.on = true;
                        subFound.on = true;
                    } else {
                        path.on = false;
                    }
                }
            });
        }

        localStorage.setItem('sportslogic.registrationMenuState', JSON.stringify(paths));
    }

    // Lifecycle
    componentWillMount = () => {

        this.handlePath();

        // Determine which of the 'paths' should be 'on'
        this.setState({
            tree: paths,
            leafPath: _.last(_.chain(this.props.routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'))
        });

    }
    componentWillReceiveProps = (nextProps) => {
        this.handlePath();
        this.setState({
            tree: paths,
            leafPath: _.last(_.chain(this.props.routes).filter(route => {
                return route.path;
            }).last().value().path.split('/'))
        });
    }
    //

    openMenu = (key) => {
        const { tree } = this.state,
            leaf = tree[key];

        Object.keys(tree).forEach(k => {
            if (k !== key) { tree[k].on = false; }
        })
        leaf.on = !leaf.on;
        this.setState({ tree });
        localStorage.setItem('sportslogic.registrationMenuState', JSON.stringify(tree));
    }

    closeAllMenu = () => {
        const { tree } = this.state;
        Object.keys(tree).forEach(k => { tree[k].on = false; });
    }

    renderButton = (key) => {

        const { tree } = this.state,
            path = tree[key];

        if (path.sub_nav) {
            return (
                <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'} `}>
                    <button className={`btn d-flex flex-row drop btn-${path.on ? 'on' : 'off'} btn-block`} onClick={() => this.openMenu(key)}>
                        <i className={`white align-self-center big-font icon ${path.icon}`} />
                        <span className="white  align-self-center font-8 toUpper name">{path.name}</span>
                        <i className={`white align-self-center fa fa-caret-${path.on ? 'up' : 'down'} ml-auto`} />
                    </button>
                </div>
            );
        } else {
            return (
                <div key={key} className={`menu-wrapper ${path.on ? 'on' : 'off'}`}>
                    <Link className={`btn d-flex flex-row drop btn-off btn-block`} to={`${path.to}`} activeClassName="btn-on"
                        onClick={() => this.closeAllMenu()}>
                        <i className={`white align-self-center big-font icon ${path.icon}`} />
                        <span className="white align-self-center font-8 toUpper name">{path.name}</span>
                    </Link>
                </div>
            );
        }
    }

    render() {

        return (<section id="left-menu" className="bg-dark-blue montserrat">
            <div key="home" className={`menu-wrapper off`}>
                <Link className={`btn d-flex flex-row drop btn-off btn-block white`} to={`/family`} activeClassName="btn-on" onlyActiveOnIndex={true}>
                    <i className={` align-self-center big-font icon fa fa-arrow-left`} />
                    <span className=" align-self-center font-8 toUpper name">Home</span>
                </Link>
            </div>
            { Object.keys(paths)
                .map(this.renderButton)}
        </section>)

    }
}

export default RegistrationMenu;