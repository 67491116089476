import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../../helpers';
import moment from 'moment';
import { Link } from 'react-router';

const source = {
  beginDrag(props) {
    return props;
  }
};

const Comp = (props) => {
  const { connectDragSource, item, season } = props; // isDragging

  return connectDragSource(
    <tr>
      <td>
        {item.IdUser}
      </td>
      <td className="font-14">
        <Link className={`btn btn-link ${item.New ? 'white' : 'blue'} underlined`} to={`/season/${season.IdSeason}/player/${item.IdUser}`}>
          {item.LastName}
        </Link>
      </td>
      <td className="font-14">
        <Link className={`btn btn-link ${item.New ? 'white' : 'blue'} underlined`} to={`/season/${season.IdSeason}/player/${item.IdUser}`}>
          {item.FirstName}
        </Link>
      </td>
      <td>{item.RoleStatus}</td>
      <td>
        {moment(item.RegistrationDate).format('MMM Do YYYY')}
      </td>
      <td ><span className="block">{item.EmailAddress}</span>
        <span className="block">{item.PhoneNumber}</span></td>
    </tr>
  );

}

export default flow(
  DragSource(ItemTypes.NONROSTEREDPLAYER, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(Comp);
