function reducer(state = [], action) {
    switch (action.type) {
        case 'FEES/SET':
            return action.fees;
        case 'LEAGUE/FEES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;
