import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SelectableUser from '../../login/SelectableUser';

class RegistrationTeamSelection extends React.Component {

  state = {
    step: 1,
    bootstrapped: true,
    alreadyRegister: [],
    selectedTeam: null,
    selectedUser: null,
    searchTerm: null
  }

  selectUser = (user) => {
    const { selectedUser } = this.props;
    if ((selectedUser || {}).IdUser !== user.IdUser) {
      this.props.rosterActions && this.props.rosterActions.clearRoster();
      this.props.teamActions && this.props.teamActions.selectTeam(null);
      this.props.familyActions && this.props.familyActions.selectUser(user)
    }
    this.props.familyActions && this.props.familyActions.toggleUserSelection(false);
  }

  filter = (e) => this.setState({ searchTerm: e.target.value });

  render() {
    const { isOpen, toggle, selectedUser, family } = this.props,
      { step } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Select your Family Member
              </ModalHeader>

        <ModalBody className="container-fluid">

          { /* Family Members */}
          {step === 1 && family.length > 0 && (
            <ul className="w-100">
              {family.map((user, i) => <li onClick={() => this.selectUser(user)} key={i} className={`pointer p-2 ${(selectedUser && selectedUser.IdUser === user.IdUser) ? 'tile-rainbow white' : null}`}>
                <SelectableUser user={user} key={i} />
              </li>)}
            </ul>
          )}
        </ModalBody>
      </Modal>)
  }
}

export default RegistrationTeamSelection;