import React from 'react';
import request from 'superagent';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import config from '../../config';
import { Link } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './login.css';

class Recover extends React.Component {

  state = {
    redirectToReferrer: false,
    mode: 1,
    invalid: false,
    selectedBg: null,
    email: null,
    code: null,
    contact: null,
    phone: null,
    options: null
  }

  componentWillMount() {

    const bgs = config.bgs;

    this.setState({
      selectedBg: _.sample(bgs),
      email: localStorage.getItem('email')
    });

    this.props.fetchCarriers && this.props.fetchCarriers();
  }

  doRequestReset = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { microTransac } = this.state;
    if (!microTransac) {
      this.setState({
        microTransac: true,
        invalid: false,
        email: this.txtEmail.value
      }, () => {
        // Save the email on the localstorage for future usage
        localStorage.setItem('email', this.txtEmail.value);

        request.post(`${config.apiEndpoint}/api/v4/membership/request_reset`)
          .send({ email: this.txtEmail.value })
          .then(data => {
            if (data.body.success) {
              this.setState({
                options: true,
                email_address: data.body.EmailAddress,
                phone_number: data.body.PhoneNumber,
                IdCarrier: data.body.IdCarrier,
                microTransac: false
              });
            } else {
              this.setState({
                microTransac: false,
                invalid: true
              });
            }
          }, reject => {
            this.setState({
              microTransac: false,
              invalid: true,
            });
          });
      });
    }
  }

  doRecover = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { microTransac } = this.state;
    if (!microTransac) {

      this.setState({
        microTransac: true,
        invalid: false,
        email: this.txtEmail.value,

      }, () => {
        
        // Save the email on the localstorage for future usage
        localStorage.setItem('email', this.txtEmail.value);

        request.post(`${config.apiEndpoint}/api/v4/membership/recover`)
          .send({ email: this.txtEmail.value, phone_flag: this.state.phone, idCarrier: this.state.IdCarrier })
          .then(data => {
            if (data.body.success) {
              this.setState({
                mode: 2,
                microTransac: false
              });
            } else {
              this.setState({
                microTransac: false,
                invalid: true
              });
            }
          }, reject => {
            this.setState({
              microTransac: false,
              invalid: true,
            });
          });
      });
    }
  }

  back = () => this.setState({ mode: this.state.mode === 1 ? 1 : (this.state.mode - 1) });

  sendCode = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { microTransac } = this.state;
    if (!microTransac) {
      this.setState({
        microTransac: true,
        invalid: false,
        code: this.txtCode.value
      }, () => {
        request.post(`${config.apiEndpoint}/api/v4/membership/code_check`)
          .send({
            email: this.state.email,
            code: this.state.code
          })
          .then(data => {
            if (data.body.success) {
              this.props.router.push(`/reset/${data.body.id}`);
            } else {
              this.setState({
                microTransac: false,
                invalid: true
              });
            }
          }, reject => {
            this.setState({
              microTransac: false,
              invalid: true
            });
          });
      });
    }
  }

  render() {
    const { mode, code, microTransac, invalid, selectedBg, email, phone } = this.state;

    return (
      <div className="limiter">
        <div className="container-login100 bg-gray">
          <div className="wrap-login100 shadow-box">
            
            { /* Recover Form */}
            {mode === 1 && (
              <form className="login100-form validate-form bg-white" >
                <div className="d-flex flex-row justify-content-center w-100 mb-4 pb-4">
                  <div id="logo-sqwad" className="d-flex flex-column justify-content-center align-self-center ">
                    <i className="icon-logo-header jeff_blue block text-center align-self-center line1" style={{ fontSize: 100 }}></i>
                    <span className="font-12 jeff_blue align-self-end" style={{ marginTop: -30, marginRight: -27 }}>By Sports Logic</span>
                  </div>
                </div>
                <span key="title" className="w-100 jeff_blue margin-bottom-half">Please enter the Email you used to register </span>
                <div key="email" className="wrap-input100 validate-input m-b-20" style={{ marginBottom: '20px' }} data-validate="Type user name">
                  <input className="input100" defaultValue={email} type="text" name="email" placeholder="Email" style={{ borderRadius: '0', height: '55px' }}
                    ref={i => this.txtEmail = i} />
                  <span className="focus-input100"></span>
                </div>

                {this.state.options !== null && <div className="d-flex flex-column my-4">

                  <span className="w-100 jeff_blue text-center margin-bottom-half">Where would you like to receive your recovery code</span>

                  <button type="button" className="btn btn-link d-flex flex-row">
                    <i className="far fa-circle-check black align-self-center" />
                    <span className="black align-self-center ml-2">{this.state.email_address}</span>
                  </button>

                  <button type="button" onClick={() => this.setState({ phone: !this.state.phone })} className="btn btn-link d-flex flex-row">
                    <i className={`far fa-circle${this.state.phone ? '-check' : ''} black align-self-center`} />
                    <span className="black align-self-center ml-2">{this.state.phone_number}</span>
                  </button>

                  {phone && <Dropdown isOpen={this.state.pickCarrier} toggle={() => this.setState({ pickCarrier: !this.state.pickCarrier })} className="form-control p-2 font-16 bg-white black">
                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                      <span className="black align-self-center">{this.state.IdCarrier ? _.find(this.props.carriers, c => c.IdCarrier === this.state.IdCarrier).Name : 'Please select phone carrier'}</span>
                      <i className="ml-auto fas fa-caret-down align-self-center black" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {this.props.carriers && this.props.carriers.map(c => <DropdownItem key={c.IdCarrier} onClick={() => this.setState({ IdCarrier: c.IdCarrier, pickCarrier: false })}>
                        {c.Name}
                      </DropdownItem>)}
                    </DropdownMenu>
                  </Dropdown>}

                </div>}

                <div key="login-button" className="container-login100-form-btn">

                  <button type="submit" className="login100-form-btn" onClick={this.state.options ? this.doRecover : this.doRequestReset}>
                    {!microTransac && <span>Recover</span>}
                    {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                  </button>

                  <Link to="/login" className="btn btn-link btn-block">
                    Back
                  </Link>

                  { /* Error Message */}
                  {invalid && (
                    <div className="d-flex flex-column p-2 bg-danger bd-highlight" style={{ width: '100%' }}>
                      <span className="white align-self-center font-8">We could not find your account</span>
                    </div>)}
                </div>

                <div key="recover-password" className="w-full text-center p-t-27 p-b-239" style={{ paddingTop: '20px', paddingBottom: '100px' }}>
                </div>
              </form>
            )}
            { /* Pretty Image */}
            <Animated animationIn="fadeInRight" className={`login100-more ${selectedBg}`} key="picture" />
            { /* Code Entry */}
            {mode === 2 && (
              <form className="login100-form validate-form bg-white">
                <div className="d-flex flex-row justify-content-center w-100 mb-4 pb-4">
                  <div id="logo-sqwad" className="d-flex flex-column justify-content-center align-self-center ">
                    <i className="icon-logo-header jeff_blue block text-center align-self-center line1" style={{ fontSize: 100 }}></i>
                    <span className="font-12 jeff_blue align-self-end" style={{ marginTop: -30, marginRight: -27 }}>By Sports Logic</span>
                  </div>
                </div>
                <div key="recover-password" className="w-full text-center p-t-27 p-b-239" style={{ paddingTop: '20px', paddingBottom: '100px' }}>
                  <span key="title" className="w-100 jeff_blue text-center margin-bottom-half">A code has been sent to your email and phone. Please input that code here </span>
                  <div>
                    <input className="input100 form-control margin-top-half" defaultValue={code} type="text" name="code" placeholder="XXXX" style={{ borderRadius: '0', height: '55px' }}
                      ref={i => this.txtCode = i} />
                    <span className="focus-input100"></span>
                  </div>
                  <button type="submit" className="login100-form-btn margin-top" onClick={this.sendCode}>
                    {!microTransac && <span>Send Code</span>}
                    {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                  </button>
                </div>
                <div key="login-button" className="container-login100-form-btn">
                  { /* Error Message */}
                  {invalid && (
                    <div className="d-flex flex-column p-2 bg-danger bd-highlight" style={{ width: '100%' }}>
                      <span className="white align-self-center font-8">Invalid Code</span>
                    </div>)}
                  <button type="button" className="btn btn-link btn-sm btn-block jeff_blue" onClick={this.sendCode}>{microTransac ? 'Sending, please wait...' : "Didn't receive the code. Send it to me again."}</button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Recover;
