import React from 'react';
import { Link } from 'react-router';
import Loader from '../common/Loader';
import EmptyPayload from '../EmptyPayload';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Team from './team';
import _ from 'lodash';

import './css.css';

class Comp extends React.Component {

  state = {
    bootstrapped: false,
    isTSDDOpen: false,
    tournamentSeason: null,
    tournamentSeasons: null,
    searchText: null,
    league: null,
    teams: null
  }

  // Lifecycle
  componentDidMount = () => {
    const { setTitle, params } = this.props;
    setTitle && setTitle({ main: 'Leagues', sub: 'Rankings Setup' });
    this.props.leagueActions.fetchTournamentSeasons(params.idLeague);
    this.props.fetchSeasons(params.idLeague);
    this.props.fetchRankingAgeGroups();
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tournamentSeasons) {
      this.setState({ tournamentSeasons: nextProps.tournamentSeasons, bootstrapped: true });
    }
    if (nextProps.league && !this.state.league) {
      const { setTitle } = this.props;
      this.setState({ league: nextProps.league });
      setTitle && setTitle({ main: (nextProps.league.Name || nextProps.league.LeagueName), sub: 'Rankings Setup' });
    }
    if (nextProps.teams) {
      this.setState({ teams: nextProps.teams });
    }
  }
  componentWillUnmount = () => {
    this.props.clearTeams();
  }
  //

  selectSeasonTournament = (tournamentSeason) => {
    const { leagueActions } = this.props;
    this.setState({ tournamentSeason });
    leagueActions.fetchRankingTeams(tournamentSeason.IdTournamentSeason);
  };
  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  }
  clearSearch = () => {
    this.txtSearch.value = null;
    this.setState({ searchText: null });
  }

  toggleTS = () => this.setState({ isTSDDOpen: !this.state.isTSDDOpen });

  render() {
    const { bootstrapped, tournamentSeason, tournamentSeasons, teams } = this.state, { ageGroups, params } = this.props, { idLeague } = params;

    let filteredTeams = _.chain(teams)
      .filter(t => {
        if (this.state.searchText) {
          return t.TeamName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1;
        }
        return true;
      }).value();

    return <section id="rankings" className="p-2">

      <div style={{ position: 'fixed', top: '6em', left: 0, width: 90, bottom: 0 }} className="bg-blue-dark">
        <div className="d-flex flex-column">

          <Link to={`/message_center/${idLeague}`} className="w-100 d-flex flex-row justify-content-center  zoom" style={{ height: 90 }}>
            <div className="d-flex flex-column justify-content-center align-self-center h-auto">
              <i className="icon-chat white font-20 align-self-center" />
              <span className="white font-10 align-self-center text-center line1 mt-2">Message Center</span>
            </div>
          </Link>

          <Link to={`/rankings/${idLeague}`} className="w-100 d-flex flex-row justify-content-center  zoom" style={{ height: 90 }}>
            <div className="d-flex flex-column justify-content-center align-self-center h-auto">
              <i className="icon-competitive white font-20 align-self-center" />
              <span className="white font-10 align-self-center text-center line1 mt-2">Rankings</span>
            </div>
          </Link>

          {/*<Link to={`/coupons/${idLeague}`} className="w-100 d-flex flex-row justify-content-center  zoom" style={{ height: 90 }}>
            <div className="d-flex flex-column justify-content-center align-self-center h-auto">
              <i className="fas fa-ticket-alt white font-20 align-self-center" />
              <span className="white font-10 align-self-center text-center line1 mt-2">Coupons</span>
            </div>
    </Link>*/}

          <Link to={`/dob/${idLeague}/A`} className="w-100 d-flex flex-row justify-content-center  zoom" style={{ height: 90 }}>
            <div className="d-flex flex-column justify-content-center align-self-center h-auto">
              <i className="fas fa-birthday-cake white font-20 align-self-center" />
              <span className="white font-10 align-self-center text-center line1 mt-2">DOB Validation</span>
            </div>
          </Link>

          <Link to={`/pcv/${idLeague}/A`} className="w-100 d-flex flex-row justify-content-center  zoom" style={{ height: 90 }}>
            <div className="d-flex flex-column justify-content-center align-self-center h-auto">
              <i className="fas fa-id-card-alt white font-20 align-self-center" />
              <span className="white font-10 align-self-center text-center line1 mt-2">Player<br />Cards</span>
            </div>
          </Link>

        </div>
      </div>


      <div className="filters-wrapper d-flex flex-row white">
        <span className="align-self-center">Tournament Season:</span>
        <Dropdown className="align-self-center idented-half" isOpen={this.state.isTSDDOpen} toggle={this.toggleTS}>
          <DropdownToggle caret className="btn-info btn-sm btn">
            {tournamentSeason ? tournamentSeason.TournamentSeasonName : 'Select'}
          </DropdownToggle>
          <DropdownMenu>
            {tournamentSeasons && tournamentSeasons.map((ts, i) => <DropdownItem key={i} onClick={() => this.selectSeasonTournament(ts)}>{ts.TournamentSeasonName}</DropdownItem>)}
          </DropdownMenu>
        </Dropdown>
        <div className="d-flex flex-row ml-auto">
          <div className="input-group align-self-center">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fa fa-search" /></span>
            </div>
            <input ref={(i) => this.txtSearch = i} type="text" className="form-control" placeholder="Search" onChange={this.handleSearch} />
            <div className="input-group-append" onClick={this.clearSearch}>
              <span className="input-group-text"><i className="fa fa-times" /></span>
            </div>
          </div>
        </div>
      </div>

      <div className="work-area">

        <div key="with-data" className=" d-flex align-items-stretch full-height">
          {tournamentSeason && <table style={{ borderBottom: '1px solid white' }} className="table table-sm w-100 font-10">
            <thead className="bg-info white">
              <tr>
                <th>Name</th>
                <th>Gender</th>
                <th>Age Group</th>
                <th>Registered Divisions</th>
              </tr>
            </thead>
            <tbody className="white">
              {filteredTeams && filteredTeams.map((team, i) => <Team teamActions={this.props.teamActions}
                team={team} key={`${team.IdTeam}-${i}`} ageGroups={ageGroups} idTournamentSeason={tournamentSeason.IdTournamentSeason} />)}
            </tbody>
          </table>}
        </div>

        {!bootstrapped && <Loader key="loader" message={`Loading Season Assets`} />}
        {bootstrapped && !tournamentSeason && <EmptyPayload message={`${tournamentSeasons.length ? 'Select a Tournament Season' : 'There are no Tournament Seasons for Rankings on this League'}`} />}
      </div>
    </section>
  }
}

export default Comp;
