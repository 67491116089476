function reducer(state = [], action) {

    switch (action.type) {
      case 'TOPICS/SET':        
        return action.topics;
      default:
        return state;
    }
  }
  
  export default reducer;