import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getAppPlans() {
    yield* takeEvery("APP_PLANS/FETCH", function* (action) {

        try {
            const result = yield call(function () {
                const url = [config.apiEndpoint, 'api/v4/common/app_plans'].join('/');

                return request.get(url)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });

            if (result && result.success) {
                yield put({
                    type: 'APP_PLANS/SET',
                    plans: result.plans
                });

            } else {
                yield put({
                    type: 'APP_PLANS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'APP_PLANS/FETCH_FAILED',
                e
            });
        }
    });
}