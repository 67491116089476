import React from 'react';
import fakeAuth from '../../../fakeAuth';
import { Link } from 'react-router';
import _ from 'lodash';

class Header_User extends React.Component {

  state = {
    user: null
  }

  // Lifecycle
  componentWillMount() {
    this.setState({
      user: fakeAuth.getUser()
    });
  }
  componentWillReceiveProps() {
    this.setState({ user: fakeAuth.getUser() });
  }
  //

  logout = () => {
    const { router } = this.props;
    fakeAuth.signout(() => {
      this.props.logout && this.props.logout();
      router.push('/login');
    });
  }

  popCart = () => {
    this.props.cartActions && this.props.cartActions.open();
  }

  errorUserImage = () => {
    const { user } = this.state;
    user.UserImage = '/images/defaults/male.png';
    this.setState({
      user
    });
  }

  render() {

    const { auth, title, cart, league } = this.props,
      { user } = this.state;
    const cartFiltered = _.filter(cart, (item) => {
      return item.selectedLeague && league && item.selectedLeague.IdLeague === league.IdLeague;
    });

    let trim = (title.sub || title.main || '').length > 35 ? (title.sub || title.main || '').substring(0, 35) + '...' : (title.sub || title.main || '');

    return (
      <header className="d-flex flex-row align-items-center bg-dark-blue">
        { /* Left side */}
        <div key="left" className="mr-auto d-flex flex-row align-items-center">

          <button onClick={this.props.fnMenu} className="mr-2 ml-4 white font-20 w3-hide-medium w3-hide-large align-self-center">
            <i className="fas fa-bars " />
          </button>
          { /* Logo */}
          <Link to="/family" id="logo-sqwad" className="align-self-center d-flex justify-content-center">
            <i className="icon-logo-header font-36 white block text-center align-self-center line1" />
            {/*<span className="font-8 white align-self-end" style={{ marginTop: -15, marginRight: 27 }}>By Sports Logic</span>*/}
          </Link>

          { /* Title */}
          <div className="w3-hide-small">
            {title && title.sub && <h5 className="gray toUpper no-margin no-padding text-nowrap">{title.main}</h5>}
            {title && <h2 className="white toUpper no-margin no-padding text-nowrap">{trim}</h2>}
            {title && title.season && <div className="white toUpper no-margin no-padding text-nowrap d-flex flex-row">
              <span className="font-10 align-self-center white">{title.season}</span>
              <span className="font-10 align-self-center green idented white">{title.division}</span>
            </div>}
          </div>

        </div>
        { /* Right side */}
        <div key="right" className="w3-hide-small w3-hide-medium">
          <div className="d-flex align-items-center">
            {user && (
              <button onClick={this.props.onFamily} className="btn btn-link green d-flex flex-row">
                <img alt="" onError={this.errorUserImage} src={user.CURRENT_IMAGE_80} className="smallPic margin-right align-self-center bg-black" />
                <span className="align-self-center montserrat font-12">{`Hi ${user.NameFirst}`}</span>
              </button>)}
            { /* Separator */}
            {user && <i className="fas fa-circle font-6 align-self-center gray" />}
            {auth && auth.loggedIn && (
              <Link to='/' className="btn btn-link white font-12 montserrat">
                <span className="align-self-center">Family Site</span>
              </Link>
            )}
            { /* Separator */}
            {user && auth && auth.loggedIn && auth.isAdmin && <i className="fas fa-circle font-6 align-self-center gray" />}
            { /* Link to admin portal */}
            {auth && auth.loggedIn && auth.isAdmin && (
              <Link to='/admin' className="btn btn-link white font-12 montserrat">
                <span className="align-self-center">Admin Site</span>
              </Link>)}
            {/* Separator */}
            {auth && auth.loggedIn && <i className="fas fa-circle font-6 align-self-center gray" />}
            {auth && auth.loggedIn && (
              <a href="https://matchreports.thesqwad.com/faq" target="_blank" rel="noopener noreferrer" className="btn btn-link white">
                <span className="align-self-center font-12 montserrat">Help</span>
              </a>)}
            {auth && auth.loggedIn && <i className="fas fa-circle font-6 align-self-center gray" />}
            { /* Logout */}
            {auth && auth.loggedIn && <button key="logout-button" onClick={this.logout} className="btn btn-link blue font-12 montserrat">Logout</button>}
            { /* Search action */}
            { /*<button key="search-button" className="btn btn-info square">
                                <i className="fa fa-search bigest-font"></i></button>*/ }
            { /* Cart button */}
            {cartFiltered && cartFiltered.length > 0 && league && <button key="cart-button" className="btn btn-success square" style={{ width: 'auto' }} onClick={this.popCart}>
              <i className="icon-shopping_cart white bigest-font">{cartFiltered.length}</i></button>}
          </div>
        </div>

        <div className="w3-hide-large">
          { /* Cart button */}
          {cartFiltered && cartFiltered.length > 0 && league && <button key="cart-button" className="btn btn-success square" style={{ width: 'auto' }} onClick={this.popCart}>
            <i className="icon-shopping_cart white bigest-font">{cartFiltered.length}</i></button>}
        </div>
      </header>
    );
  }
}

export default Header_User;