import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon,
    FacebookIcon,

} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, ModalBody, } from 'reactstrap';

class QRCODE extends React.Component {

    state = {
        bootstrapped: false,
        copySuccess: false
    }

    // Lifecycle
    componentWillMount() {

    }
    componentWillReceiveProps = nextProps => {

    }
    //    

    render() {
        const { isOpen, toggle, team, user } = this.props, { copySuccess } = this.state;

        let shareUrl = (user && user.IdUser) ? `https://api.sportslogic.net/api/v5/qrcodes/profile/${user.IdUser}` : `https://matchreports.thesqwad.com/brochure/${team.IdTeamDivision}/${team.Name || team.TeamName}`;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalBody className="p-4 bg-white text-center w-100 d-flex flex-column justify-content-center" >
                    <img src={`https://api.sportslogic.net/api/v5/qrcodes/team/${team.IdTeamDivision}`} style={{ width: 300 }} alt="QR_Code" className="align-self-center mb-4" />
                    {(!user || !user.IdUser) && <div className="d-flex flex-row align-self-center ">

                        <TwitterShareButton className="mr-2 align-self-start" title="Team Brochure" url={shareUrl}>
                            <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton className="mr-2 align-self-start" title={`Check out our ${team.Name || team.TeamName}'s Brochure`} url={shareUrl}>
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton className="mr-2 align-self-start" quote={`Check out our ${team.Name || team.TeamName}'s Brochure`} url={shareUrl}>
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <div className="w-auto d-flex flex-column">
                            <input className={`${copySuccess ? 'bg-success' : ''} form-control text-input w-auto`} disabled="disabled" ref={(i) => this.txtClipboard = i} value={shareUrl} />
                            {this.state.copySuccess && <span className="black font-10">Link copied in your clipboard!</span>}
                        </div>

                        <CopyToClipboard text={shareUrl}
                            onCopy={() => this.setState({ copySuccess: true })}>
                            <button className="btn btn-link align-self-start"><i className="fas fa-clipboard blue" /></button>
                        </CopyToClipboard>

                    </div>}

                </ModalBody>
            </Modal>
        )
    }
}

export default QRCODE;
