function customGroupsReducer(state = [], action) {
    switch (action.type) {
        case 'CUSTOM_GROUPS/SET':
            return action.customGroups || [];
        case 'CUSTOM_GROUPS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default customGroupsReducer;
