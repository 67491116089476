import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import { formatPriceWithoutCurrency, formatPrice, detectCreditCardType } from '../../../helpers';
import BlueishHeader from '../../common/things/BlueishHeader';
import request from 'superagent';
import config from '../../../config';

class MakePayment extends React.Component {

  state = {
    card: null,
    failed: false,
    invalidCC: false,
    step: 1,
    total: this.props.total || Math.round((this.props.max + Number.EPSILON) * 100) / 100,
    nope: false,
    max: Math.round((this.props.max + Number.EPSILON) * 100) / 100,
    transactionId: null,
    ccType: 10,
    paying: false,
    mode: 0 // =[1:CC, 2:Cash, 3:Check, 4:Offline]
  }

  handleChange = () => {
    // tODO: validate that what's in is a number
    this.setState({
      total: parseFloat(this.txtAmount.value),
      nope: !_.isNumber(parseFloat(this.txtAmount.value))
        || parseFloat(this.txtAmount.value) > this.state.max
        || parseFloat(this.txtAmount.value) === 0
        || !this.txtAmount.value
    });
  }

  continue = (e) => {
    e.preventDefault && e.preventDefault();
    const { nope } = this.state;
    if (!nope) {
      this.setState({
        step: 2
      });
    }
  }

  validateNewCard = () => {
    var valid = true;
    if (!this.txtCardNumber.value ||
      !this.txtNameOnCard.value ||
      !this.txtExpDate.value ||
      !this.txtCVV.value ||
      !this.txtZipCode.value) {
      valid = false;
    } else if (!detectCreditCardType(this.txtCardNumber.value).Name) {
      valid = false;
    }
    return valid;
  }

  selectMode = (mode) => {
    this.setState({
      mode,
      failed: false
    });
  }

  selectCCType = (ccType) => {
    this.setState({
      ccType
    });
  }

  pay = () => {

    // tODO: evaluate what mode I'm in
    const { mode } = this.state;
    switch (mode) {
      case 1: // CC
        this.payWithCC();
        break;
      case 2: // Cash
        this.payWithCash();
        break;
      case 3: // Check
        this.payWithCheck();
        break;
      case 4: // Offline
        this.payOffline();
        break;
      default:
        break;
    }

  }

  payWithCC = () => {

    // TODO: validate everything!
    var valid = false;
    if (this.validateNewCard()) {
      valid = true;
    } else {
      // Invalid Credit Card
      document.getElementById('lblTotalWrapper').scrollIntoView();
    }

    valid && this.setState({
      paying: true,
      failed: false,
      invalidCC: false
    }, () => {

      // CALL API
      const url = [config.apiEndpoint, 'api/v4/registration/due'].join('/')
      request.post(url)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .send({
          idUser: this.props.idUser,
          idTeam: this.props.idTeam,
          card: {
            number: this.txtCardNumber.value,
            name: this.txtNameOnCard.value,
            cvv: this.txtCVV.value,
            expdate: this.txtExpDate.value,
            zip: this.txtZipCode.value
          },
          due: {
            IdOrderItem: this.props.idOrderItem,
            IdSeason: this.props.idSeason,
            IdLeague: this.props.idLeague
          },
          total: this.state.total
        })
        .then((data) => {
          if (data.body.success) {
            this.setState({
              paying: false,
              transactionId: data.body.transactionId
            });
            this.props.fnUpdate && this.props.fnUpdate();
          } else {
            this.setState({
              paying: false,
              failed: data.body.message
            });
          }
        }, (reject) => {
          this.setState({
            paying: false,
            failed: 'FAIL'
          });
        });
    });

    !valid && this.setState({
      invalidCC: true
    });

  }

  payWithCash = () => {

    this.setState({
      paying: true,
      failed: false,
      invalidCC: false
    }, () => {

      // CALL API
      const url = [config.apiEndpoint, 'api/v4/registration/payment/offline/2'].join('/')
      request.post(url)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .send({
          idUser: this.props.idUser,
          idTeam: this.props.idTeam,
          idOrderItem: this.props.idOrderItem,
          idSeason: this.props.idSeason,
          amount: this.state.total,
          notes: this.txtNotes.value
        })
        .then((data) => {
          if (data.body.success) {
            this.setState({
              paying: false,
              transactionId: 'Paid with Cash'
            });
            this.props.fnUpdate && this.props.fnUpdate();
          } else {
            this.setState({
              paying: false,
              failed: data.body.message
            });
          }
        }, (reject) => {
          this.setState({
            paying: false,
            failed: 'FAIL'
          });
        });
    });
  }

  payWithCheck = () => {

    if (!this.txtCheckNumber.value) {
      return;
    }

    this.setState({
      paying: true,
      failed: false,
      invalidCC: false
    }, () => {

      // CALL API
      const url = [config.apiEndpoint, 'api/v4/registration/payment/offline/3'].join('/')
      request.post(url)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .send({
          idUser: this.props.idUser,
          idTeam: this.props.idTeam,
          idOrderItem: this.props.idOrderItem,
          idSeason: this.props.idSeason,
          amount: this.state.total,
          notes: this.txtNotes.value,
          checkNumber: this.txtCheckNumber.value
        })
        .then((data) => {
          if (data.body.success) {
            this.setState({
              paying: false,
              transactionId: 'Paid with Check'
            });
            this.props.fnUpdate && this.props.fnUpdate();
          } else {
            this.setState({
              paying: false,
              failed: data.body.message
            });
          }
        }, (reject) => {
          this.setState({
            paying: false,
            failed: 'FAIL'
          });
        });
    });
  }

  payOffline = () => {

    if (!this.txtTransactionID.value) {
      return;
    }

    this.setState({
      paying: true,
      failed: false,
      invalidCC: false
    }, () => {

      // CALL API
      const url = [config.apiEndpoint, 'api/v4/registration/payment/offline/4'].join('/')
      request.post(url)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .send({
          idUser: this.props.idUser,
          idTeam: this.props.idTeam,
          idOrderItem: this.props.idOrderItem,
          idSeason: this.props.idSeason,
          amount: this.state.total,
          notes: this.txtNotes.value,
          transactionId: this.txtTransactionID.value,
          idPaymentMethod: this.state.ccType
        })
        .then((data) => {
          if (data.body.success) {
            this.setState({
              paying: false,
              transactionId: 'Paid with Offline Transaction'
            });
            this.props.fnUpdate && this.props.fnUpdate();
          } else {
            this.setState({
              paying: false,
              failed: data.body.message
            });
          }
        }, (reject) => {
          this.setState({
            paying: false,
            failed: 'FAIL'
          });
        });
    });
  }

  render() {
    const { isOpen, toggle } = this.props,
      { step, nope, total, mode, invalidCC, ccType, transactionId, max } = this.state;

    return (<Modal isOpen={isOpen} toggle={toggle} style={{ width: 500 }}>
      <ModalHeader toggle={toggle} className="bg-blue-dark white">
        {step === 1 && <span>How much would you like to pay?</span>}
        {step === 2 && <span>{transactionId ? 'Thanks for your payment' : 'How would you like to pay?'}</span>}
      </ModalHeader>
      <ModalBody>
        <div>


          {step === 1 && (
            <form>
              <div className="jumbotron no-margin text-center" key="step1">
                <span>Max Amount: {formatPrice((this.props.max || 0) * 100)}</span>
                <div className="d-flex flex-row justify-content-center">
                  <i className="fa fa-dollar-sign align-self-center bigest-font margin-right-half" />
                  <input placeholder="Amount" ref={(i) => this.txtAmount = i} onChange={this.handleChange} defaultValue={formatPriceWithoutCurrency(this.state.max * 100)} type="number" className="form-control control-lg align-self-center text-center big-font margin-right"
                    style={{ height: 60, width: 250 }} />
                </div>
              </div>
            </form>)}


          {step === 2 && (
            <div className="font-12">
              
              <div id="lblTotalWrapper" className="montserrat p-3 w-100 align-self-center white d-flex flex-column justify-content-center" style={{ backgroundColor: '#4469C7' }}>
                <span className={`bigest-font line1 align-self-center block`}>{formatPrice(total * 100)}</span>
                <span className={`font-10 line1 align-self-center block`}>New Balance: {formatPrice((max - total) * 100)}</span>
              </div>
              <div className="container">
                <div className="row p-2">
                  <div className={`col-3 tile btn btn-${mode === 1 ? 'success' : 'default'} p-2 d-flex flex-column pointer`} onClick={() => this.selectMode(1)}>
                    <i className="far fa-credit-card bigest-font  align-self-center"></i>
                    <span className="align-self-center mt-2">Credit Card</span>
                  </div>
                  <div className={`col-3 tile btn btn-${mode === 2 ? 'success' : 'default'}  p-2 d-flex flex-column pointer`} onClick={() => this.selectMode(2)}>
                    <i className="far fa-money-bill-alt bigest-font  align-self-center"></i>
                    <span className="align-self-center mt-2">Cash</span>
                  </div>
                  <div className={`col-3 tile btn btn-${mode === 3 ? 'success' : 'default'}  p-2 d-flex flex-column pointer`} onClick={() => this.selectMode(3)}>
                    <i className="fas fa-money-check-alt bigest-font  align-self-center"></i>
                    <span className="align-self-center mt-2">Check</span>
                  </div>
                  <div className={`col-3 tile btn btn-${mode === 4 ? 'success' : 'default'}  p-2 d-flex flex-column pointer`} onClick={() => this.selectMode(4)}>
                    <i className="fas fa-unlink bigest-font  align-self-center"></i>
                    <span className="align-self-center mt-2 line1">Offline Transaction</span>
                  </div>
                </div>
              </div>
              { /* Credit Card Info */}
              <div className={`${mode !== 1 ? 'hide' : ''}`}>
                {total > 0 && mode === 1 && !invalidCC && <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3`}>Credit Card Information</h3>}
                {total > 0 && mode === 1 && invalidCC && <div className="bg-warning p-2 big-font text-center">Invalid Credit Card!</div>}
                <section className={`idented-half margin-top-half margin-right-half montserrat`}>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="black align-self-center">Card Number
                      {' '}
                      {(!this.txtCardNumber || !this.txtCardNumber.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" maxLength="16" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtCardNumber = i} />
                  </div>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="black align-self-center">Name on Card
                      {' '}
                      {(!this.txtNameOnCard || !this.txtNameOnCard.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtNameOnCard = i} />
                  </div>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="black align-self-center">Exp Date
                      {' '}
                      {(!this.txtExpDate || !this.txtExpDate.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" maxLength="4" placeholder="MMYY" style={{ width: 100 }} type="text" ref={(i) => this.txtExpDate = i} />
                  </div>
                  <div className="margin-bottom-half d-flex flex-row">
                    <label className="black align-self-center">CVV
                      {' '}
                      {(!this.txtCVV || !this.txtCVV.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" type="text" maxLength="4" style={{ width: 100 }} ref={(i) => this.txtCVV = i} />
                  </div>
                  <div className="d-flex flex-row">
                    <label className="black align-self-center">Zip / Postal
                      {' '}
                      {(!this.txtZipCode || !this.txtZipCode.value) && <i className="red fa fa-asterisk" />}
                    </label>
                    <input className="form-control ml-auto align-self-center" type="text" style={{ width: 100 }} ref={(i) => this.txtZipCode = i} />
                  </div>
                </section>
              </div>
              {mode === 2 && <BlueishHeader message="Cash Payment Information" />}
              {mode === 3 && <BlueishHeader message="Check Payment Information" />}
              {mode === 3 && <div className="d-flex flex-row idented-half margin-right-half margin-top-half">
                <label className="black align-self-center">Check Number
                  {' '}
                  {(!this.txtCheckNumber || !this.txtCheckNumber.value) && <i className="red fa fa-asterisk" />}
                </label>
                <input className="form-control ml-auto align-self-center" type="text" style={{ width: 250 }} ref={(i) => this.txtCheckNumber = i} />
              </div>}
              {mode === 4 && <BlueishHeader message="Offline Payment Information" />}
              {mode === 4 && <div className="container">
                <div className="row p-2">
                  <div className={`col-3 tile btn btn-${ccType === 10 ? 'success' : 'default'} p-2 d-flex flex-column pointer`} onClick={() => this.selectCCType(10)}>
                    <i className="fab fa-cc-visa bigest-font  align-self-center"></i>
                    <span className=" align-self-center">Visa</span>
                  </div>
                  <div className={`col-3 tile btn btn-${ccType === 20 ? 'success' : 'default'}  p-2 d-flex flex-column pointer`} onClick={() => this.selectCCType(20)}>
                    <i className="fab fa-cc-mastercard bigest-font  align-self-center"></i>
                    <span className=" align-self-center">Mastercard</span>
                  </div>
                  <div className={`col-3 tile btn btn-${ccType === 30 ? 'success' : 'default'}  p-2 d-flex flex-column pointer`} onClick={() => this.selectCCType(30)}>
                    <i className="fab fa-cc-amex bigest-font  align-self-center"></i>
                    <span className=" align-self-center">Amex</span>
                  </div>
                  <div className={`col-3 tile btn btn-${ccType === 40 ? 'success' : 'default'}  p-2 d-flex flex-column pointer`} onClick={() => this.selectCCType(40)}>
                    <i className="fab fa-cc-discover bigest-font  align-self-center"></i>
                    <span className=" align-self-center">Discover</span>
                  </div>
                </div>
              </div>}
              {mode === 4 && <div className="d-flex flex-row idented-half margin-right-half margin-top-half  margin-bottom-half">
                <label className="black align-self-center">Transaction ID
                  {' '}
                  {(!this.txtTransactionID || !this.txtTransactionID.value) && <i className="red fa fa-asterisk" />}
                </label>
                <input className="form-control ml-auto align-self-center" type="text" style={{ width: 250 }} ref={(i) => this.txtTransactionID = i} />
              </div>}
              {mode > 1 && <div className="d-flex flex-row idented-half margin-right-half margin-top-half  margin-bottom-half">
                <label className="black align-self-center">Notes</label>
                <input className="form-control ml-auto align-self-center" type="text" style={{ width: 250 }} ref={(i) => this.txtNotes = i} />
              </div>}
            </div>
          )}
        </div>
      </ModalBody>
      {nope && (
        <ModalBody className="bg-danger white p-2">
          {(!_.isNumber(this.state.total) || !this.state.total || this.state.total === 0) && <span>Invalid amount, please check.</span>}
          {this.state.total > this.state.max && <span>You cannot pay more than {formatPrice(this.state.max * 100)}</span>}
        </ModalBody>)}
      {this.state.paying && <div className="jumbotron no-margin text-center" key="step1">
        <i className="fa fa-credit-card bigest-font heart" />
        <h1 className="margin-top-half">Processing, please wait...</h1>
      </div>}
      {this.state.transactionId && <div className="jumbotron no-margin text-center bg-success" key="step1">
        <i className="fa fa-check white bigest-font" />
        <h1 className="white">Payment successful</h1>
        <h3 className="white">{formatPrice(total * 100)}</h3>
        <h3 className="white font-8">{this.state.transactionId}</h3>
      </div>}
      {!this.state.paying && this.state.failed && <div className="jumbotron no-margin text-center bg-danger" key="step1">
        <i className="fa fa-times white bigest-font" />
        <h1 className="white">Payment failed</h1>
        <h3 className="white">{this.state.failed}</h3>
      </div>}
      {step === 1 && <ModalFooter>
        <button onClick={this.continue} style={{ width: 120, border: `2px solid ${nope ? 'grey' : '#2cad3c'}`, borderRadius: 20 }} className={`${nope ? 'gray' : 'green'} p-2 align-self-center mt-2`}>Continue</button>
      </ModalFooter>}
      {step === 2 && mode !== 0 && !this.state.paying && !this.state.transactionId && (
        <ModalFooter>
          <button onClick={this.pay} style={{ width: 120, border: `2px solid ${nope ? 'grey' : '#2cad3c'}`, borderRadius: 20 }} className={`${nope ? 'gray' : 'green'} p-2 align-self-center mt-2`}>Pay</button>
        </ModalFooter>)}
    </Modal>)
  }
}

export default MakePayment;