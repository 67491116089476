import React from 'react';
import moment from 'moment';
import { Popover, PopoverBody } from 'reactstrap';
import fakeAuth from '../../../../fakeAuth';

class Item extends React.Component {

  state = {
    user: null, unvalidating: false, changingDOB: false, day: 1, month: 1, year: 2000, invalidDate: false
  }

  componentWillMount() {
    this.setState({ user: this.props.user }, () => {
      const { user } = this.state, { DateOfBirth } = user;
      this.setState({
        day: moment(DateOfBirth).utc().format('DD'),
        month: moment(DateOfBirth).utc().format('MM'),
        year: moment(DateOfBirth).utc().format('YYYY')
      });
    });
  }
  componentWillReceiveProps = nextProps => {
    this.setState({ user: nextProps.user }, () => {
      const { user } = this.state, { DateOfBirth } = user;
      this.setState({
        day: moment(DateOfBirth).utc().format('DD'),
        month: moment(DateOfBirth).utc().format('MM'),
        year: moment(DateOfBirth).utc().format('YYYY')
      });
    });
  }

  errorUserImage = () => {
    const { user } = this.state;
    user.UserImageUrl = `/images/defaults/${user.Gender.toLowerCase()}.png`;
    this.setState({ user });
  }

  toggleValidated = () => {
    const { user } = this.state, { idSeason } = this.props, { NameFirst, NameLast } = fakeAuth.getUser();
    if (!user.CardDelivered) {
      user.CardDelivered = true;
      user.DateTimeModified = moment();
      user.UserModifiedName = `${NameLast.toUpperCase()}, ${NameFirst.toUpperCase()}`;

      // Update store?
      this.setState({ user });

      // call API
      this.props.seasonActions &&
        this.props.seasonActions.validatePCV(user.IdUser, idSeason);

    } else {
      this.toggleDeleteConfirm();
    }
  }

  toggleDeleteConfirm = () => {
    this.setState({ unvalidating: !this.state.unvalidating });
  }

  confirmDelete = () => {
    const { user } = this.state, { idSeason } = this.props;
    user.CardDelivered = false;
    user.DateTimeModified = null;
    user.UserModifiedName = null;
    this.setState({ user, unvalidating: false });
    this.props.seasonActions &&
      this.props.seasonActions.unValidatePCV(user.IdUser, idSeason);
  }

  render() {
    const { user } = this.state;
    return (<tr>
      <td>
        <img alt="" onError={this.errorUserImage} className="smallPic margin-right-half" src={user.UserImageUrl} />{user.UserName}</td>

      <td className="font-8">
        <span className="block">{user.EmailAddress}</span>
        <span>{user.PhoneNumber}</span>
      </td>
      <td>
        <button id={`btnToggle-${user.IdUser}`} className="font-16" onClick={this.toggleValidated}>
          <i className={` fa-${user.CardDelivered ? 'check-square green fas' : 'square white far'}`} />
        </button>
        <Popover placement="left" isOpen={this.state.unvalidating} target={`btnToggle-${user.IdUser}`} toggle={this.toggleDeleteConfirm}>
          <PopoverBody className="d-flex flex-column justify-content-center">
            <span className="align-self-center">Are you sure?</span>
            <button className="btn btn-sm btn-danger" onClick={this.toggleDeleteConfirm}>No, cancel</button>
            <button className="btn btn-sm btn-success" onClick={this.confirmDelete}>Yes, un-check it!</button>
          </PopoverBody>
        </Popover>
      </td>
      <td>{user.UserModifiedName}</td>
      <td>{user.DateTimeModified ? moment(user.DateTimeModified).format('MMM Do YYYY') : ''}</td>
    </tr>)
  }
}

export default Item;
