import _ from 'lodash';
import moment from 'moment';

function season(state = [], action) {
    switch (action.type) {
        case 'SEASON/SET':

            return _.extend(action.season, {
                Name: action.season.Name.toUpperCase(),
                IdTournamentPointsType: action.season.IdTournamentPointsType || 0,
                Payment: {
                    Cash: action.season.Cash ? true : false,
                    Check: action.season.Check ? true : false,
                    Visa: action.season.Visa ? true : false,
                    MasterCard: action.season.MasterCard ? true : false,
                    Amex: action.season.Amex ? true : false,
                    Discover: action.season.Discover ? true : false
                },
                Roles: {
                    Coach: (action.season.RolesCoach || action.season.RoleCoach) ? true : false,
                    Assistant: (action.season.RolesAssistantCoach || action.season.RoleAssistantCoach) ? true : false,
                    TeamManager: (action.season.RolesTeamManager || action.season.RoleTeamManager) ? true : false,
                    AllowOpenRegistration: action.season.AllowOpenRegistrationForThisRoles ? true : false
                },
                CoachCanChangeScore: action.season.AllowCoachChangeScores ? true : false,
                CoachCanAddGames: action.season.AllowCoachAddGames ? true : false,
                CoachCanAddPlayers: action.season.AllowCoachAddPlayers ? true : false,
                DateStart: moment(action.season.DateStart),
                DateEnd: moment(action.season.DateEnd),
                RegistrationEnd: moment(action.season.RegistrationEnd || action.season.DateEnd),
                Disclaimer: action.season.Disclaimer || ''
            });


        case 'SEASON/TOGGLE_CHECK_FOR_SCHEDULE':
            state.checkedForSchedule = !state.checkedForSchedule;
            return { ...state };
        default:
            return state;
    }
}

export default season;