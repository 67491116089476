function reducer(state = [], action) {

  switch (action.type) {
    case 'APPLIED_QUESTIONS/SET':
      return action.groups;
    default:
      return state;
  }
}

export default reducer;