import React from 'react';
import { Animated } from 'react-animated-css';
import JerseyPlayer from './partials/JerseyPlayer';

class Jerseys extends React.Component {

    state = {
        microTransac: false,
        bootstrapped: true
    }

    componentWillMount() {
        const { roster } = this.props;
        this.setState({ roster: [...roster] })        
    }

    save = () => {
        this.setState({ trigger: true }, () => { this.close(); });
    }

    close = () => {
        const { toggle } = this.props;
        this.setState({ bootstrapped: false }, () => {
            setTimeout(() => { toggle && toggle(); }, 300);
        });
    }

    renderPlayer = (user, index) => {
        return <JerseyPlayer key={index} trigger={this.state.trigger} {...this.props} user={user} />
    }

    render() {
        const { bootstrapped, roster } = this.state;

        return (
            <section>

                <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>

                    <div className="overlay" onClick={this.close} style={{ zIndex: -1 }} />
                    <Animated animationIn="fadeInRight" animationOut="fadeOutRight" animationOutDuration={300} animationInDuration={300} isVisible={bootstrapped} style={{
                        position: 'fixed', right: 0, top: 0, bottom: 0,
                        width: 450, borderLeft: '1px solid gray', overflowY: 'auto'
                    }} className="bg-gray">
                        <div className="d-flex flex-row p-3">
                            <i className="fas fa-arrow-left font-12 pointer" onClick={this.close} />
                            <span className="montserrat font-12 ml-auto">Jerseys</span>
                            <i className=" font-16 pointer ml-auto" />
                        </div>
                        {roster && roster.map((user, i) => this.renderPlayer(user, i))}
                        <div className="p-4">
                            <button className="btn btn-success btn-block" onClick={this.save}>Save</button>
                            <button className="btn btn-link btn-block">Cancel</button>
                        </div>
                    </Animated>
                </div>

            </section>)
    }
}

export default Jerseys;
