function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'AGE_GROUPS/SET':
            return action.ageGroups;
        case 'AGE_GROUPS/CLEAR':
            return [];
        default:
            return state;
    }
}

export default reducer;
