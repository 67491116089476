import React from 'react';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import _ from 'lodash';
import moment from 'moment';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Animated } from 'react-animated-css';
import LocationsFilters from './LocationsFilters';
import DivisionsFilter from './DivisionsFilter';
import config from '../../../../config';
import Slider from 'react-rangeslider'
import LineLoader from '../../../common/LineLoader';
import { calculateBWContrastClass, calculateBWContrast } from '../../../../helpers';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePickerLabel';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

class FullCalendarWrapper extends React.Component {

  calendarComponentRef = React.createRef();
  tooltip = null;

  state = {
    filters: {
      toggled: false,
      game_type: {
        all: true,
        poolplay: false,
        playoffs: false
      }
    },
    defaultDate: moment(), // Default to the 1st game of the season unless we're in the middle of the season in which case take me to the next date with games
    activeTab: 2,
    resources: [],
    mode: 'resourceTimeGridDay', //'dayGridMonth',
    games: [],
    coaches: [],
    allTeams: [],
    timeGap: '30',
    timeGapInteger: 30,
    defaultGameDuration: null,
    datesAboveResources: true,
    bootstrapped: false,
    showingPairings: false,
    havePairings: false,
    calendarObject: null,
    currentPairings: [],
    zoomFactor: 100,
    tooltip: []
  }

  // Lifecycle
  componentWillMount = () => {   

    const { locations, slots } = this.props;

    // Format locations to fit the requirements for FullCalendar as a resouce
    let fields = [];
    (locations || []).forEach(location => {
      fields = [...fields, {
        Name: location.Name,
        IdLocation: location.IdLocation
      }, ..._.map(location.Fields || location.fields, f => {
        return { Name: f.Name, IdLocation: f.IdLocation };
      })];
    });
    //       
    const formatedSlots = this.formatSlotsForFullCalendar([...slots]);

    /*let havePairings = false;
    divisions.forEach(d => {
      d.Flights.forEach(f => {
        if (f.HasPairings === 1) havePairings = true;
      });
    });*/

    this.setState({
      resources: _.map(fields, (f, index) => {
        return {
          id: parseInt(f.IdLocation, 10),
          title: f.Name,
          checked: formatedSlots.length ?
            (_.find(formatedSlots, slot => parseInt(slot.IdLocation, 10) === parseInt(f.IdLocation, 10)) ? true : false) :
            (index < 5 ? true : false)
        }
      }),
      defaultGameDuration: this.state.defaultGameDuration || (_.last(slots) || { GameDurationMinutes: 90 }).GameDurationMinutes,
      havePairings: false, // TODO: check this logic?
      slots: formatedSlots,
      // The pre-selectd date of the calendar must be the date of the very 1st game (right ?) || today if there are no games
      defaultDate: (slots.length) ? moment(_.sortBy(slots, 'start')[0].GameDate) : moment()
    });

  }

  componentWillReceiveProps = nextProps => {
    const { divisions } = this.props;
    if (nextProps.slots) {
      const divisionsWithTeams = _.filter(divisions, division => {
        if (
          _.chain(division.Flights).map((f) => {
            return f.FlightedTeams;
          }).flatten().value().length
        ) {
          return true;
        }
        return false;
      });
      const allTeams = _.chain(divisionsWithTeams).map(division => {
        return _.chain(division.Flights).map((f) => {
          return f.FlightedTeams;
        }).flatten().value();
      }).flatten().value();

      this.setState({
        slots: this.formatSlotsForFullCalendar(nextProps.slots),
        allTeams,
        defaultGameDuration: this.state.defaultGameDuration || (_.last(nextProps.slots) || { GameDurationMinutes: 90 }).GameDurationMinutes,
        bootstrapped: this.state.bootstrapped || (nextProps.slots.length ? true : false),
        // The pre-selectd date of the calendar must be the date of the very 1st game right ?
        defaultDate: this.state.defaultDate || ((nextProps.slots.length && !this.state.bootstrapped) ?
          moment(_.sortBy(nextProps.slots, 'start')[0].GameDate) : this.state.defaultDate)
      });
    }
  }
  //

  customEventRender = ({ event, view, el }) => {
    // This is an important one. 
    // It replaces the basic template of Fullcalendar for events and creates our own HTML   
    const { extendedProps } = event;
    el.innerHTML =
      `<div style="border-bottom: 1px solid white;" class="bg-black pl-1"><span class="white" style="white-space: nowrap;">INFORMATION</span></div><div class="p-1 line1"><div>${moment(event.start).format("h:mmA")} - ${moment(event.start).add(extendedProps.DurationMinutes, 'minutes').format('h:mmA')} - ID: ${extendedProps.IdSlot}</div></div>`;
  };

  customEventDrop = ({ event, revert, newResource, }) => { }
  customDayClick = ({ date, resource }) => { }
  customEventClick = ({ event }) => { }
  customDrop = ({ resource, date, draggedEl: { id } }) => { }
  customEventMouseEnter = info => { }
  customEventDragStop = ({ event, jsEvent, view }) => { }

  formatSlotsForFullCalendar = slots => {
    const { divisions } = this.props;

    slots.forEach(slot => {
      let color = _.sample(_.values(config.palette)),
        division = _.find(divisions, (d) => {
          return parseInt(d.IdDivision, 10) === parseInt(slot.IdDivision, 10);
        });

      slot = _.extend(slot, {
        id: slot.IdSlot,
        resourceId: slot.IdLocation,
        start: moment(slot.GameDate).format(),
        end: moment(slot.GameDate).add(slot.GameDurationMinutes, 'minutes').format(),
        title: slot.DivisionName,
        durationEditable: false,
        originalColor: (division || {}).Color || color,
        color: (division || {}).Color || color,
        description: 'Description',
        division: division ? division.Name : 'Unknown Division',
        className: calculateBWContrastClass((division || {}).Color || color),
        textColor: calculateBWContrast((division || {}).Color || color),
        extendedProps: _.pick(slot, ['IdSlot', 'IdFlight', 'IdDivision', 'DivisionColor', 'DivisionName', 'FlightName', 'IdLocation', 'GameDate', 'GameDurationMinutes'])
      });
    });

    return slots;
  }

  create = (date, resource) => { }
  edit = ({ extendedProps }) => { }

  createCalendarEventsArray = (props) => {
    const { slots = [] } = this.state;
    //----------- Apply filters
    const ret = _.chain([...slots])
      .each((event) => {
        event.color = event.originalColor;
        event.className = 'active';
      })
      .value();
    //-------      

    return ret;
  }

  // Filters
  changeTab = activeTab => this.setState({ activeTab });
  changeCalendarTimeGap = (timeGap, timeGapInteger) => this.setState({ timeGap, timeGapInteger });
  changeDefaultGameDuration = (defaultGameDuration) => this.setState({ defaultGameDuration });

  toggleResource = (id) => {
    const { resources } = this.state;
    _.find(resources, function (r) {
      return r.id === id;
    }).checked = !_.find(resources, function (r) {
      return r.id === id;
    }).checked;
    this.setState({ resources });
  }

  toggleFilters = () => {
    const { filters } = this.state;
    filters.toggled = !filters.toggled;
    this.setState({ filters });
  }

  toggleGameType = (i) => { }
  //

  // Calendar controls
  today = () => {
    this.calendarComponentRef.current.getApi().today();
    this.setState({ defaultDate: moment() });
  }
  nextDate = () => {
    const { mode, defaultDate } = this.state;
    this.calendarComponentRef.current.getApi().next();
    defaultDate.add(1, mode === 'dayGridMonth' ? 'month' : 'day');
    this.setState({ defaultDate });
  }
  prevDate = () => {
    const { mode, defaultDate } = this.state;
    this.calendarComponentRef.current.getApi().prev();
    defaultDate.add(-1, mode === 'dayGridMonth' ? 'month' : 'day');
    this.setState({ defaultDate });
  }
  handleChangeDate = date => {
    this.calendarComponentRef.current.getApi().gotoDate(date.format());
    this.setState({ defaultDate: date });
  }
  //

  // Zoom slider handlers
  handleChangeZoom = newValue => {
    const { zoomFactor } = this.state;
    if (newValue !== zoomFactor) this.setState({ zoomFactor: newValue });
  };
  //

  // TODO: check if this is needed at all
  notify = () => this.forceUpdate();

  render() {

    const { resources, mode, activeTab, filters, defaultDate, timeGap, timeGapInteger, defaultGameDuration,
      showingPairings, havePairings, slots, microTransac, zoomFactor } = this.state,
      resourcesChecked = _.filter(resources, r => r.checked).length,
      minWidth = (resourcesChecked > 4 && mode === 'resourceTimeGridDay') ? resourcesChecked * (timeGapInteger > 30 ? 180 : 180) : 600;
    //                                                ^ min with of every field in the resourceTimeGridDay mode

    const highlightWithRanges = [{ "highlighted-date": _.map(slots, 'GameDate') }];

    return (
      <div id="full-calendar" className="container-fluid no-margin no-padding">
        {microTransac && <LineLoader />}
        {/* Top actions */}
        <div className="d-flex flex-row no-margin pt-2 pl-0 pr-0 pb-0 idented">
          <Animated animationIn="fadeIn" className="filters-wrapper d-flex flex-row" style={{ marginLeft: 30 }}>
            <DatePicker highlightDates={highlightWithRanges} customInput={<CustomDatePicker />} selected={defaultDate} showYearDropdown scrollableYearDropdown
              yearDropdownItemNumber={15} onChange={this.handleChangeDate} className="white align-self-center font-16 underlined"
            />
            <button className={`btn-default btn btn-sm white margin-left`} onClick={this.prevDate}><i className="fa fa-arrow-left" /></button>
            <button className={`btn-default btn btn-sm white`} onClick={this.today}>Today</button>
            <button className={`btn-default btn btn-sm white`} onClick={this.nextDate}><i className="fa fa-arrow-right" /></button>

            <button className={`btn-default btn btn-sm blue`}>({_.filter(slots, g => g.GameDate.format('YYYYMMDD') === defaultDate.format('YYYYMMDD')).length} Slots)</button>
          </Animated>
          <Animated animationIn="fadeInRight" className="ml-auto d-flex flex-row-reverse no-margin no-padding margin-right">
            <div className="slider-horizontal margin-right-half" style={{ width: 100 }}>
              <Slider min={20} max={100} value={zoomFactor} onChange={this.handleChangeZoom}></Slider>
            </div>
          </Animated>
        </div>
        <div className="no-margin no-padding margin-top-half">
          { /* Calendar filters (Left Side) */}
          <div style={{ top: !havePairings ? '3em' : (showingPairings ? '15em' : '5em') }} id="calendar-filters" className={`${filters.toggled ? 'on' : ''} no-margin no-padding`}>
            <ul className="nav nav-tabs font-14">
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}><i className="fas fa-shield-alt blue"></i> {activeTab === 10 ? <span className="idented-half">Teams</span> : ''}</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}><i className="fa fa-map-marker green"></i> {activeTab === 30 ? <span className="idented-half">Locations</span> : ''}</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={() => this.changeTab(4)}><i className="fa fa-cog green"></i> {activeTab === 40 ? <span className="idented-half">Others</span> : ''}</button>
              </li>
            </ul>
            <ReactCSSTransitionGroup className="tab-content" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
              transitionEnter={true} transitionLeave={false}>
              {activeTab === 1 && <DivisionsFilter divisions={this.props.divisions} games={this.props.games} notify={this.notify} key="divisions" />}
              {activeTab === 3 && <LocationsFilters resources={resources} toggleResource={this.toggleResource} key="locations" />}
              {activeTab === 4 && (
                <div key="others">
                  <div className="d-flex flex-row bg-info font-10">
                    <span className="align-self-center idented-half">Calendar settings</span>
                  </div>
                  <div className="d-flex flex-column margin-top-half">
                    <span className="align-self-center idented-half">Time Gaps (mins)</span>
                    <div className="btn-group btn-group-toggle align-self-center" data-toggle="buttons">
                      <button onClick={() => this.changeCalendarTimeGap('05', 5)} className={`btn btn-secondary btn-sm ${timeGap === '05' ? 'active' : ''}`}>5</button>
                      <button onClick={() => this.changeCalendarTimeGap('10', 10)} className={`btn btn-secondary btn-sm ${timeGap === '10' ? 'active' : ''}`}>10</button>
                      <button onClick={() => this.changeCalendarTimeGap('15', 15)} className={`btn btn-secondary btn-sm ${timeGap === '15' ? 'active' : ''}`}>15</button>
                      <button onClick={() => this.changeCalendarTimeGap('20', 20)} className={`btn btn-secondary btn-sm ${timeGap === '20' ? 'active' : ''}`}>20</button>
                      <button onClick={() => this.changeCalendarTimeGap('30', 30)} className={`btn btn-secondary btn-sm ${timeGap === '30' ? 'active' : ''}`}>30</button>
                      <button onClick={() => this.changeCalendarTimeGap('45', 45)} className={`btn btn-secondary btn-sm ${timeGap === '45' ? 'active' : ''}`}>45</button>
                      <button onClick={() => this.changeCalendarTimeGap('60', 60)} className={`btn btn-secondary btn-sm ${timeGap === '60' ? 'active' : ''}`}>60</button>
                    </div>
                  </div>
                  <div className="d-flex flex-column margin-top-half">
                    <span className="align-self-center idented-half">Default Game Duration</span>
                    <div className="btn-group btn-group-toggle align-self-center" data-toggle="buttons">
                      <button onClick={() => this.changeDefaultGameDuration(15)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 15 ? 'active' : ''}`}>15</button>
                      <button onClick={() => this.changeDefaultGameDuration(20)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 20 ? 'active' : ''}`}>20</button>
                      <button onClick={() => this.changeDefaultGameDuration(30)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 30 ? 'active' : ''}`}>30</button>
                      <button onClick={() => this.changeDefaultGameDuration(60)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 60 ? 'active' : ''}`}>60</button>
                      <button onClick={() => this.changeDefaultGameDuration(90)} className={`btn btn-secondary btn-sm ${defaultGameDuration === 90 ? 'active' : ''}`}>90</button>
                    </div>
                  </div>
                </div>)}
            </ReactCSSTransitionGroup>
          </div>
          {/* Calendar itself (Right side) */}
          <div style={{ bottom: 0, top: !havePairings ? '3em' : (showingPairings ? '15em' : '5em') }} id="calendar-wrapper" className="on bg-white">
            { /* The calendar renders here with JQuery   */}
            <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, minWidth: minWidth * (zoomFactor / 100) + 'px', /*zoom: (zoomFactor / 100)*/ }}>
              <FullCalendar
                schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                defaultView={mode}
                droppable={false}
                height={"parent"}
                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin]}
                ref={this.calendarComponentRef}
                slotDuration={`00:${timeGap}:00`}
                allDaySlot={false}
                defaultDate={defaultDate.format()}
                header={{ left: 'title', right: '' }}
                minTime="05:00"
                editable={false}
                selectable={false}
                eventLimit={true}
                resources={(fetchInfo, successCallback, failureCallback) => { successCallback(_.filter(resources, r => r.checked)) }}
                events={(info, successCallback, failureCallback) => { successCallback(this.createCalendarEventsArray(this.props)) }}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default FullCalendarWrapper;
