import React from 'react';
import { Alert } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import TopMenu from './top_menu';
import DOBValidations from './dob';
import CoachCertificates from './coach_certificates';
import SafeSport from './safesport';
import Concussions from './concussions';
import BackgroundCheck from './background_check';
import Fingerprints from './fingerprints';
import PhotosValidation from './photos';
import Medical from './medical';

class Validations extends React.Component {

    state = {
        bootstrapped: false,
        league: null,
        mode: null,
        all: true,        
    }

    // Lifecycle
    componentWillMount = () => {
        const { params } = this.props;        
        this.setState({ mode: params.mode });
        this.props.seasonActions && this.props.seasonActions.fetchDashboard(params.idSeason);
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ mode: nextProps.params.mode });
        const { season, seasonActions } = nextProps;
        if (season && season.IdLeague && !this.state.bootstrapped) {
            this.setState({ bootstrapped: true }, () => {                
                seasonActions && seasonActions.fetchValidationsSummary({
                    idLeague: season.IdLeague, idSeason: season.IdSeason
                });
            });
        }
        this.setState({ mode: nextProps.params.mode });
    }
    //

    render() {

        const { idSeason, letter } = this.props.params, { season = {} } = this.props, { mode, error } = this.state;        

        return (
            <section>                

                <div className="w3-container mt-4">

                    <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                        <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                            {this.state.error}
                        </Alert>
                    </div>

                    {season && <div className="w3-content  d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
                        <div className="w-100 black">
                            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
                        </div>
                    </div>}

                    <div className="montserrat w3-content  w-100" style={{ maxWidth: 1285 }}>
                        <TopMenu idSeason={idSeason} mode={mode} letter={letter} />
                        {mode === 'dob' && <DOBValidations {...this.props} />}
                        {mode === 'coaches' && <CoachCertificates {...this.props} />}
                        {mode === 'safe' && <SafeSport {...this.props} />}
                        {mode === 'concussion' && <Concussions {...this.props} />}
                        {mode === 'background' && <BackgroundCheck {...this.props} />}
                        {mode === 'fingerprints' && <Fingerprints {...this.props} />}
                        {mode === 'photos' && <PhotosValidation {...this.props} />}
                        {mode === 'medical' && <Medical {...this.props} />}
                    </div>
                </div>


            </section>
        )
    }
}

export default Validations;
