import React from 'react';
import FieldAvailabilityRule from './FieldAvailabilityRule'
import moment from 'moment';
import { Animated } from 'react-animated-css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../common/CustomDatePicker3';
import CustomTimeScroller from '../../../../common/CustomTimeScroller';
import 'react-times/css/material/default.css';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../../../config';
import COLORS from '../../../../../colors';
import ReactTooltip from 'react-tooltip';
import { findDOMNode } from 'react-dom';

import './fields_availability.css';

class FieldsAvailability extends React.Component {

    state = {
        modal: false,
        microTransac: false,
        Availability_Start: moment(),
        Availability_End: moment().add(1, 'day'),
        isOpenStartDatepicker: false,
        isOpenEndDatepicker: false,
        selectedFieldAvailabilityRule: {
            minutes: 90,
            Days: {
                Sunday: 0,
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
            },
            TimeSlots: []
        },
        fields: []
    }

    setup = props => {
        if (props.locations) {

            let fields = [];
            (props.locations || []).forEach(location => {
                fields = [...fields, {
                    Name: location.Name,
                    IdLocation: location.IdLocation,
                    selected: false
                }, ..._.map(location.Fields || location.fields, f => {
                    return {
                        Name: f.Name,
                        IdLocation: f.IdLocation,
                        selected: false,
                        child: true
                    };
                })];
            });
            this.setState({ fields });
        }
    }

    // Lifecycle 
    componentWillMount = () => {
        this.setup(this.props)
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps)
    }
    //

    //
    addRule = () => {
        const { selectedFieldAvailabilityRule, fields } = this.state, { season } = this.props;
        fields.forEach(f => {
            f.selected = false;
        });

        this.setState({
            Availability_Start: moment(season.ScheduleDateStart || season.DateStart).utc(),
            Availability_End: moment(season.ScheduleDateEnd || season.DateEnd).utc(),
            selectedFieldAvailabilityRule: {
                minutes: selectedFieldAvailabilityRule.minutes || 90,
                Days: {
                    Sunday: 0,
                    Monday: 0,
                    Tuesday: 0,
                    Wednesday: 0,
                    Thursday: 0,
                    Friday: 0,
                    Saturday: 0,
                },
                TimeSlots: []
            },
            fields
        });
        this.toggleModal();
    }
    editRule = (index) => {
        const { fields } = this.state,
            { fieldAvailabilityRules } = this.props;
        const rule = fieldAvailabilityRules[index];
        fields.forEach(f => {
            if (_.find(_.union(rule.Locations, rule.Fields), function (lof) {
                return lof.IdLocation === f.IdLocation;
            })) {
                f.selected = true;
            } else {
                f.selected = false;
            }
        });

        this.setState({
            Availability_Start: moment(rule.Availability_Start),
            Availability_End: moment(rule.Availability_End),
            selectedFieldAvailabilityRule: {
                index: index,
                minutes: rule.GameDurationMinutes,
                Days: rule.Days,
                TimeSlots: rule.TimeSlots,
                IdScheduleFieldAvailability: rule.IdScheduleFieldAvailability
            },
            fields
        });
        this.toggleModal();
    }
    //

    toggleModal = () => this.setState({ modal: !this.state.modal });

    validateForm = () => {
        let valid = true;
        const { selectedFieldAvailabilityRule, fields } = this.state;


        // Validate select at least one (1) field
        if (!_.chain(fields).filter((f) => {
            return f.selected;
        }).value().length) {
            ReactTooltip.show(findDOMNode(this.refs.form_fa_fields));
            valid = false;
        } else {
            ReactTooltip.hide(findDOMNode(this.refs.form_fa_fields));
        }

        // Validate at least select one (1) day of the week
        if (!_.find(_.values(selectedFieldAvailabilityRule.Days), function (v) {
            return v === 1;
        })) {
            ReactTooltip.show(findDOMNode(this.refs.form_fa_weekdays));
            valid = false;
        } else {
            ReactTooltip.hide(findDOMNode(this.refs.form_fa_weekdays));
        }

        // Add at least one TimeSlot
        if (!selectedFieldAvailabilityRule.TimeSlots.length) {
            ReactTooltip.show(findDOMNode(this.refs.form_fa_add_timeslot));
            valid = false;
        } else {
            ReactTooltip.hide(findDOMNode(this.refs.form_fa_add_timeslot));
        }

        // Validate all timeslots
        for (var i = 0; i < selectedFieldAvailabilityRule.TimeSlots.length; i++) {
            if (moment(selectedFieldAvailabilityRule.TimeSlots[i].TimeSlot_Start).isAfter(moment(selectedFieldAvailabilityRule.TimeSlots[i].TimeSlot_End))) {
                selectedFieldAvailabilityRule.TimeSlots[i].invalid = true;
                valid = false;
            } else {
                selectedFieldAvailabilityRule.TimeSlots[i].invalid = false;
            }
        }

        if (!valid) {
            this.forceUpdate();
        }

        return valid;
    }

    save = () => {
        const { selectedFieldAvailabilityRule, Availability_Start, Availability_End, fields } = this.state;

        if (this.validateForm()) {

            selectedFieldAvailabilityRule.minutes = this.txtMinutes.value;

            let newRule = {
                Availability_End: Availability_End.format(),
                Availability_Start: Availability_Start.format(),
                Days: selectedFieldAvailabilityRule.Days,
                Fields: _.chain(fields).filter((f) => {
                    return f.selected;
                }).map((f) => {
                    return {
                        Name: f.Name,
                        IdLocation: f.IdLocation
                    };
                }).value(),
                Locations: [],
                GameDurationMinutes: selectedFieldAvailabilityRule.minutes,
                Name: 'Field Availability Rule',
                TimeSlots: _.chain(selectedFieldAvailabilityRule.TimeSlots).map((h) => {
                    return {
                        TimeSlot_Start: moment(h.TimeSlot_Start, 'Hmm').format('Hmm'), // <- Not sure if needed this doble conversion
                        TimeSlot_End: moment(h.TimeSlot_End, 'Hmm').format('Hmm')
                    }
                }).value(),
                type: 'FieldAvailability',
                IdScheduleFieldAvailability: selectedFieldAvailabilityRule.IdScheduleFieldAvailability,
                IdSeason: this.props.params.idSeason,
                IdScheduleGameType: 1 // Pool play
            };

            this.setState({ microTransac: true });


            // Smart Call aPI
            // TODO: convert to v4
            const url = [config.apiEndpoint, 'api/v4/schedule/rules/fields', selectedFieldAvailabilityRule.IdScheduleFieldAvailability || this.props.params.idSeason].join('/');
            request[(selectedFieldAvailabilityRule.IdScheduleFieldAvailability ? 'patch' : 'post')](url)
                .send(newRule)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {

                    // Redux to update the UI
                    if (selectedFieldAvailabilityRule.IdScheduleFieldAvailability) {
                        this.props.fieldAvailabilityRulesActions &&
                            this.props.fieldAvailabilityRulesActions.updateFieldAvailabilityRule &&
                            this.props.fieldAvailabilityRulesActions.updateFieldAvailabilityRule(selectedFieldAvailabilityRule.index, newRule)
                    } else {
                        newRule.IdScheduleFieldAvailability = data.body.InsertedId;
                        this.props.fieldAvailabilityRulesActions &&
                            this.props.fieldAvailabilityRulesActions.addFieldAvailabilityRule &&
                            this.props.fieldAvailabilityRulesActions.addFieldAvailabilityRule(newRule)
                    }

                    // TODO: Save the availability rule parameters for consecutive insertion of similar rules (specially the minutes)
                    this.setState({
                        selectedFieldAvailabilityRule,
                        microTransac: false
                    }, function () {
                        this.toggleModal();
                    });
                });
        }
    }

    // Calendar for the Season
    handleChangeStart = (date) => {
        const { Availability_End } = this.state;
        this.setState({
            Availability_Start: moment(date),
            Availability_End: moment(date).isAfter(moment(Availability_End)) ? moment(date).add(1, 'day') : moment(Availability_End) // range validation
        }, function () {
            this.toggleCalendarStart()
        });
    }
    handleChangeEnd = (date) => {
        const { Availability_Start } = this.state;
        this.setState({
            Availability_Start: moment(date).isBefore(moment(Availability_Start)) ? moment(date).add(-1, 'day') : moment(Availability_Start), // range validation
            Availability_End: moment(date)
        }, function () {
            this.toggleCalendarStart()
        });
    }
    toggleCalendarStart = (e) => {
        e && e.preventDefault()
        this.setState({
            isOpenStartDatepicker: !this.state.isOpenStartDatepicker
        })
    }
    toggleCalendarEnd = (e) => {
        e && e.preventDefault()
        this.setState({
            isOpenEndDatepicker: !this.state.isOpenEndDatepicker
        })
    }
    //

    // Timeslots
    addTimeslot = () => {
        const { selectedFieldAvailabilityRule } = this.state;
        selectedFieldAvailabilityRule.TimeSlots.push({
            TimeSlot_Start: moment('00:00', 'HH:mm'),
            TimeSlot_End: moment('01:00', 'HH:mm')
        });
        this.setState({
            selectedFieldAvailabilityRule
        });
    }
    removeTimeslot = (index) => {
        const { selectedFieldAvailabilityRule } = this.state;
        selectedFieldAvailabilityRule.TimeSlots = [
            ...selectedFieldAvailabilityRule.TimeSlots.slice(0, index),
            ...selectedFieldAvailabilityRule.TimeSlots.slice(index + 1)
        ]
        this.setState({
            selectedFieldAvailabilityRule
        });
    }
    //

    toggleDay = (index) => {
        const { selectedFieldAvailabilityRule } = this.state;
        selectedFieldAvailabilityRule.Days[index] = selectedFieldAvailabilityRule.Days[index] ? 0 : 1;
        this.setState({ selectedFieldAvailabilityRule });
    }
    toggleField = (index) => {
        const { fields } = this.state;
        fields[index].selected = !fields[index].selected;
        this.setState({ fields });
    }
    deselectField = (IdLocation) => {
        const { fields } = this.state;
        const field = _.find(fields, f => f.IdLocation === IdLocation);
        if (field) field.selected = false;
        this.setState({ fields });
    }

    render() {
        const { fieldAvailabilityRules, divisions } = this.props,
            { selectedFieldAvailabilityRule, microTransac, fields } = this.state;

        const selectedFields = _.filter(fields, f => f.selected);

        return (
            <div id="fields-availability" className=''>
                <Animated animationIn="fadeInRight" className="d-flex flex-row">
                    <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: COLORS.jeff_blue }} className="ml-auto py-2 px-3 align-self-center block" onClick={() => this.addRule()}><i className="fa fa-plus" /> Add Rule</button>
                </Animated>
                <div>
                    <ul className="child clearfix field-availability-rule-list text-center pb-4">
                        {fieldAvailabilityRules &&
                            _.chain(fieldAvailabilityRules)
                                .sortBy(rule => {
                                    let fieldsNames = _.map(_.union(rule.Locations, rule.Fields), function (f) {
                                        return (f.Name || f.FieldName || f.LocationName);
                                    }).join(', ');                                    
                                    return fieldsNames;
                                })
                                .value().map((rule, i) => <FieldAvailabilityRule key={i} index={i}
                                    divisions={_.filter(divisions, d => d.Flights.length > 0)} rule={rule}
                                    fieldAvailabilityRulesActions={this.props.fieldAvailabilityRulesActions} fnUpdate={() => this.editRule(i)}
                                />)}
                    </ul>
                    {!fieldAvailabilityRules.length && (
                        <div className="jumbotron text-center margin-top">
                            <h1 className="display-3">There are no Field Availability rules</h1>
                            <p className="lead">Click the plus sign above to create your first Field Availability rule</p>
                        </div>)}
                </div>


                {/* Modal for creating / updating fa rules */}
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="field-availability-modal">
                    <ModalHeader toggle={this.toggle} style={{ backgroundColor: '#2cad3c', color: 'white' }}>
                        {selectedFieldAvailabilityRule.IdScheduleFieldAvailability ? 'Edit' : 'New'} Location Availability Rule</ModalHeader>
                    <ModalBody>
                        <div className="container-fluid">
                            <div className="row no-margin no-padding">
                                <div className="col-5 no-margin no-padding" style={{ maxHeight: 600, overflowY: 'auto' }}>
                                    <div className="margin-top-half" data-tip="Select at least one (1) field" data-for="modal-tooltip-top" ref="form_fa_fields" />
                                    {fields && fields.map((f, i) => (
                                        <button style={{ border: '1px solid #c3c3c3' }} key={i} className={`w-100 btn block btn-${f.selected ? 'success' : 'link'} ${f.child ? 'ml-3' : ''} btn-sm margin-top-half text-left font-8`} onClick={() => this.toggleField(i)}>
                                            {f.selected && <i className="fa fa-check margin-right-half" />}
                                            {f.Name}
                                        </button>
                                    ))}
                                </div>
                                <div className="col-7 d-flex flex-column no-margin no-padding" style={{ borderLeft: '1px solid white' }}>
                                    <h5 className="p-2">Selected Locations</h5>
                                    <div className="idented-half">
                                        {selectedFields.map((field, i) =>
                                            <button onClick={() => this.deselectField(field.IdLocation)} key={i} className="btn font-8 btn-sm btn-success margin-right-half margin-top-half">
                                                <i className="fas fa-map-marked-alt" />{' '}{field.Name}</button>)}
                                    </div>
                                    <hr />
                                    <div className="d-flex flex-row margin-top-half idented-half margin-right">
                                        <i className="far fa-calendar-alt align-self-center" />
                                        <span className=" align-self-center idented-half margin-right">Available from</span>
                                        <div className="align-self-center">
                                            <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.Availability_Start} selectsStart startDate={this.state.Availability_Start} endDate={this.state.Availability_End} onChange={this.handleChangeStart}
                                            />
                                        </div>
                                        <span className=" align-self-center margin-left margin-right">to</span>
                                        <div className="align-self-center">
                                            <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.Availability_End} selectsEnd startDate={this.state.Availability_Start} endDate={this.state.Availability_End} onChange={this.handleChangeEnd}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row idented-half margin-right">
                                        <i className="fa fa-clock align-self-center" ref="form_fa_minutes"
                                            data-tip="Fix this!" data-for="modal-tooltip-left"></i>
                                        <span className=" align-self-center margin-left-half margin-right-half">for</span>
                                        <input type="number" defaultValue={selectedFieldAvailabilityRule.minutes}
                                            ref={(input) => this.txtMinutes = input}
                                            className="align-self-center form-control control-sm"
                                            min="0" style={{ width: '80px' }}
                                        />
                                        <span className=" align-self-center margin-left margin-right">minutes duration games</span>
                                    </div>
                                    <div ref="form_fa_weekdays" className="idented-half d-flex flex-row my-2" data-tip="Select at least one (1) week day" data-for="modal-tooltip-left">
                                        <button onClick={() => this.toggleDay("Sunday")} className={`btn btn-sm btn-${selectedFieldAvailabilityRule.Days.Sunday ? 'success' : 'default'} day-button`}>Su</button>
                                        <button onClick={() => this.toggleDay("Monday")} className={`btn btn-sm btn-${selectedFieldAvailabilityRule.Days.Monday ? 'success' : 'default'} day-button`}>Mo</button>
                                        <button onClick={() => this.toggleDay("Tuesday")} className={`btn btn-sm btn-${selectedFieldAvailabilityRule.Days.Tuesday ? 'success' : 'default'} day-button`}>Tu</button>
                                        <button onClick={() => this.toggleDay("Wednesday")} className={`btn btn-sm btn-${selectedFieldAvailabilityRule.Days.Wednesday ? 'success' : 'default'} day-button`}>We</button>
                                        <button onClick={() => this.toggleDay("Thursday")} className={`btn btn-sm btn-${selectedFieldAvailabilityRule.Days.Thursday ? 'success' : 'default'} day-button`}>Th</button>
                                        <button onClick={() => this.toggleDay("Friday")} className={`btn btn-sm btn-${selectedFieldAvailabilityRule.Days.Friday ? 'success' : 'default'} day-button`}>Fr</button>
                                        <button onClick={() => this.toggleDay("Saturday")} className={`btn btn-sm btn-${selectedFieldAvailabilityRule.Days.Saturday ? 'success' : 'default'} day-button`}>Sa</button>
                                    </div>


                                    {selectedFieldAvailabilityRule.TimeSlots.map((h, i) => (
                                        <div key={i} style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: COLORS.jeff_blue }} className="ml-auto py-0 px-3 align-self-center block d-flex flex-row w-100 mt-1 mb-1">
                                            <span className="align-self-center half-idented font-14">Starts at</span>
                                            <div className="align-self-center half-idented" style={{ width: '110px' }} ref={`timeslot-${i}`} data-tip="Invalid time slot" data-for={`modal-tooltip-${i}`}>
                                                <CustomTimeScroller time={h.TimeSlot_Start} fnChange={(d) => {
                                                    h.TimeSlot_Start = d;
                                                }} />
                                            </div>
                                            <span className="align-self-center half-idented font-14">and ends at</span>
                                            <div className="align-self-center half-idented" style={{ width: '110px' }}>
                                                <CustomTimeScroller time={h.TimeSlot_End} fnChange={(d) => {
                                                    h.TimeSlot_End = d;
                                                }} />
                                            </div>
                                            {h.invalid && <i className="fa fa-warning yellow align-self-center half-idented font-16" data-tip="Invalid time slot" data-for={`modal-tooltip-${i}`} />}
                                            <button className="btn btn-link ml-auto btn-sm" onClick={() => this.removeTimeslot(i)}><i className="fa fa-times" /></button>
                                            {h.invalid && <ReactTooltip effect="solid" place="top" id={`modal-tooltip-${i}`} />}
                                        </div>
                                    ))}
                                    <ReactTooltip effect="solid" place="left" id="modal-tooltip-left" />
                                    <ReactTooltip effect="solid" place="right" id="modal-tooltip-right" />
                                    <ReactTooltip effect="solid" place="top" id="modal-tooltip-top" />
                                    <ReactTooltip effect="solid" place="bottom" id="modal-tooltip-bottom" />


                                    <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: COLORS.jeff_blue }} className="ml-auto py-1 px-3 align-self-center block d-flex flex-row w-100 mt-3 mb-3" ref="form_fa_add_timeslot" data-form="modal-tooltip-bottom" onClick={this.addTimeslot}
                                        data-tip="Add at least one (1) Timeslot" data-for="modal-add-time-slot">
                                        <span className="align-self-center half-idented">Timeslots</span>
                                        <span className="btn btn-link ml-auto btn-sm"><i className="fa fa-plus" /> Add</span>
                                    </button>

                                    {(selectedFieldAvailabilityRule.TimeSlots.length === 0) && <ReactTooltip effect="solid" place="left" id="modal-add-time-slot" />}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    {!microTransac && (
                        <ModalFooter>
                            <button style={{ border: `1px solid ${COLORS.gray}`, borderRadius: 20, color: COLORS.gray }} className="ml-auto py-2 px-3 align-self-center block" onClick={this.toggleModal}>Cancel</button>
                            {' '}
                            <button style={{ border: `1px solid ${COLORS.green}`, borderRadius: 20, color: COLORS.green }} className="py-2 px-3 align-self-center block" onClick={this.save}>Save rule</button>
                        </ModalFooter>
                    )}
                    {microTransac && (
                        <ModalFooter>
                            <button className="btn btn-sm btn-success"><i className="fa fa-spin fa-2x fa-cog font-10" /> Saving, please wait...</button>
                        </ModalFooter>
                    )}
                </Modal>
            </div>
        )
    }
}

export default FieldsAvailability;