import React from 'react';
import { Link } from 'react-router';

class RosterCoach extends React.Component {
  state = { user: null }

  componentWillMount() {
    this.setState({ user: this.props.user });    
  }

  imageError = () => {
    const { user } = this.state;
    user.Image = '/images/defaults/' + (user.IdGender === 1 ? 'male.png' : 'female.png');
    this.setState({ user });
  }
  render() {
    const { user } = this.state, { season } = this.props;
    return <tr>
      <td className="font-10 pt-4 pb-4">
        {user.IdUser}
      </td>
      <td className="">
        <div className="d-flex flex-row">
          <img alt="" src={user.Image} className="smallPic align-self-center" onError={this.imageError} />
          <div className="d-flex flex-column align-self-center">
          <Link to={`/season/${season.IdSeason}/player/${user.IdUser}/${user.IdTeamUserRole}`} className=" ml-2 font-12 blue">{[user.NameLast, user.NameFirst].join(', ')}</Link>
          <span className="font-8 black ml-2">{user.RoleOverrideName}</span>
          </div>
        </div>
      </td>
      <td></td>
      <td className="font-10 text-center">
        {user.Gender.substring(0,1)}
      </td>
      <td className={`text-center ${user.CONC ? 'green' : (user.CONC === null ? 'opaque' : 'red')}`}>{user.CONC ? 'Yes' : (user.CONC === null ? 'N/a' : 'No')}</td>
      <td className={`text-center ${user.COACH ? 'green' : (user.COACH === null ? 'opaque' : 'red')}`}>{user.COACH ? 'Yes' : (user.COACH === null ? 'N/a' : 'No')}</td>
      <td className={`text-center ${user.SAFE ? 'green' : (user.SAFE === null ? 'opaque' : 'red')}`}>{user.SAFE ? 'Yes' : (user.SAFE === null ? 'N/a' : 'No')}</td>
      <td className={`text-center ${user.BGC ? 'green' : (user.BGC === null ? 'opaque' : 'red')}`}>{user.BGC ? 'Yes' : (user.BGC === null ? 'N/a' : 'No')}</td>
      <td className={`text-center ${user.FINGER ? 'green' : (user.FINGER === null ? 'opaque' : 'red')}`}>{user.FINGER ? 'Yes' : (user.FINGER === null ? 'N/a' : 'No')}</td>
    </tr>
  }
}

export default RosterCoach;