import React from 'react';
import { ModalBody, Input } from 'reactstrap';
import request from 'superagent';
import config from '../../../../config';

class Gateways extends React.Component {
    state = {};
    componentWillMount() {
        const { league } = this.props;
        this.setState({ league });
        request.get(`${config.apiEndpoint}/api/v4/leagues/gateway/${league.IdLeague || league.Id}`)
            .then(({ body }) => { this.setState({ gateway: body.gateway, currencies: body.currencies }); });
    }
    onSave = () => {
        const { gateway, league } = this.state;
        if (this.state.microTransac) return;
        let payload = {
            Id: gateway.Id,
            IdCurrency: gateway.IdCurrency,
        }
        switch (gateway.Id) {
            case 1: // PayPal
                payload.User = this.txtUser.value;
                payload.Password = this.txtPassword.value;
                payload.Signature = this.txtSignature.value;
                break;
            case 3: // Authorize.net
                payload.apiLoginKey = this.txtApiLogin.value;
                payload.transactionKey = this.txtTransactionKey.value;
                break;
            default: break;
        }

        this.setState({ microTransac: true }, () => {
            request.post(`${config.apiEndpoint}/api/v4/leagues/gateway/${league.IdLeague || league.Id}`)
                .send(payload)
                .then(() => {
                    // CALL API            
                    this.setState({ good: true }, () => {
                        setTimeout(() => {
                            this.setState({ good: false, microTransac: false });
                        }, 1000);
                    });
                });
        });
    }
    onSelectPaymentGateway = e => {
        const { gateway } = this.state;
        gateway.Id = parseInt(e.target.value, 10);
        this.setState({ gateway });
    }
    onCurrency = e => {
        const { gateway } = this.state;
        gateway.IdCurrency = parseInt(e.target.value, 10);
        this.setState({ gateway });
    }
    render() {
        const { league, gateway, currencies } = this.state;
        return <ModalBody>
            <hr className="w-100" />
            {gateway && <div className="p-4">
                <h4>Payment Gateway</h4>
                <div className="input-group w-100 align-self-center mt-4">
                    {/* className={`${(!validations.ddlistGender && dirty) ? 'input-danger' : ''}`} */}
                    <Input type="select" className="black-input" value={gateway.Id} onChange={this.onSelectPaymentGateway}>
                        <option value={null}>Select Payment Gateway</option>
                        <option value={1}>Paypal</option>
                        <option value={3}>Authorize.NET</option>
                    </Input>
                </div>
                <div className="input-group w-100 align-self-center mt-4">
                    {/* className={`${(!validations.ddlistGender && dirty) ? 'input-danger' : ''}`} */}
                    <Input type="select" className="black-input" value={gateway.IdCurrency} onChange={this.onCurrency}>
                        <option value={null}>Select Currency</option>
                        {currencies && currencies.map((currency, i) => <option key={i} value={currency.Id}>{currency.Code}</option>)}
                    </Input>
                </div>
                {gateway.Id === 1 && <section>
                    <div className="d-flex flex-row margin-top-half">
                        <div className="w-100">
                            <label className="font-10 gray">User</label>
                            <div className={`black-input ${false && !this.txtUser.value ? 'error-input' : ''} d-flex align-self-center`}>
                                <input className="w-100 p-3 " type="text" name="user" ref={(i) => this.txtUser = i} defaultValue={gateway.User} placeholder="User" />
                            </div>
                        </div>
                        <div className="p-2" />
                        <div className="w-100">
                            <label className="font-10 gray">Password</label>
                            <div className={`black-input ${false && !this.txtPassword.value ? 'error-input' : ''} d-flex align-self-center`}>
                                <input className="w-100 p-3 " type="text" name="password" ref={(i) => this.txtPassword = i} defaultValue={gateway.Password} placeholder="Password" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row margin-top-half">
                        <div className="w-100">
                            <label className="font-10 gray">Signature</label>
                            <div className={`black-input ${false && !this.txtSignature.value ? 'error-input' : ''} d-flex align-self-center`}>
                                <input className="w-100 p-3 " type="text" name="vendor" ref={(i) => this.txtSignature = i} defaultValue={gateway.Signature} placeholder="Signature" />
                            </div>
                        </div>
                        <div className="p-2" />
                        <div className="w-100" />
                    </div>
                </section>}
                {gateway.Id === 3 && <section>
                    <div className="d-flex flex-row margin-top-half">
                        <div className="w-100">
                            <label className="font-10 gray">API Login Key</label>
                            <div className={`black-input ${false && !this.txtApiLogin.value ? 'error-input' : ''} d-flex align-self-center`}>
                                <input className="w-100 p-3 " type="text" name="user" ref={(i) => this.txtApiLogin = i} defaultValue={gateway.apiLoginKey} placeholder="API Login Key" />
                            </div>
                        </div>
                        <div className="p-2" />
                        <div className="w-100">
                            <label className="font-10 gray">Transaction Key</label>
                            <div className={`black-input ${false && !this.txtTransactionKey.value ? 'error-input' : ''} d-flex align-self-center`}>
                                <input className="w-100 p-3 " type="text" name="password" ref={(i) => this.txtTransactionKey = i} defaultValue={gateway.transactionKey} placeholder="Transaction Key" />
                            </div>
                        </div>
                    </div>
                </section>}
                <div className="d-flex flex-row mt-4">
                    <button onClick={this.onSave} style={{ width: 230, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-auto p-2">
                        {this.state.microTransac && <i className={`${this.state.good ? 'fas fa-thumbs-up horizontal-shake' : 'fas fa-cog fa-spin'} black`} />}
                        {!this.state.microTransac && <span>Save Gateway Information</span>}
                    </button>
                </div>
            </div>}
        </ModalBody>
    }
}

export default Gateways;
