import _ from 'lodash';

function reducer(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'QUESTIONS/SET':            
            return action.questions;
        case 'QUESTIONS/ADD':
            return [action.question, ...state];
        case 'QUESTIONS/CLEAR':
            return null;
        case 'QUESTIONS/REMOVE':            
            index = _.findIndex(state, element => element.IdQuestion === action.idQuestion);
            if (index !== -1){
                return [...state.slice(0, index), ...state.slice(index + 1)];
            }
            return state;
        default:
            return state;
    }
}

export default reducer;