import React from 'react';
import _ from 'lodash';
import DivisionList from '../common/divisions/List';
import PaymentPlansList from '../common/payment_plans/List';
import SelectQuestion from '../common/questions/Select';
import RadioQuestion from '../common/questions/Radio';
import TextQuestion from '../common/questions/Text';
import CheckQuestion from '../common/questions/Check';
import CheckListQuestion from '../common/questions/CheckList';
import CheckoutActionsPopup from '../common/CheckoutActionsPopup';
import RegistrationPlayerSelection from '../common/RegistrationPlayerSelection';
import PersonSelection from './modals/PersonSelection';
import { generateRandomId } from '../../../helpers';
import CartPopup from '../common/CartPopup';
import config from '../../../config';
import request from 'superagent';
import Season from './partials/Season';
import Person from './partials/Person';
import Division from './partials/Division';


//const Soon = () => {
class CampRegistration extends React.Component {

    state = {
        questions: null,
        paymentPlans: null,
        divisions: null,
        selectedDivision: null,
        selectedPlan: null,
        selectedTeam: null,
        selectedUser: null,
        selectedRole: null,
        selectedSeason: null,
        selectedLeague: null,
        bootstrapped: false,
        step: 1,
        isCheckoutActionsOpen: false,
        isPlayerSelectionOpen: false,
        mode: null,
        fetchingDataLocally: false
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, pouch, league } = this.props;

        setTitle && setTitle({
            main: 'Registration',
            sub: (pouch && pouch.selectedLeague) ? pouch.selectedLeague.LeagueName : null
        });

        this.setState(_.extend(this.state, this.props.pouch))
        this.props.divisionsActions && this.props.divisionsActions.clearDivisions();

        // TODO: fetch divisions and stuffs
        if (pouch && pouch.selectedSeason) {
            this.props.divisionsActions &&
                this.props.divisionsActions.fetchForRegistrationOfUsers(pouch.selectedSeason.IdSeason, pouch.selectedUser.IdUser, pouch.mode === 1 ? 3 : 4)
        }

        if ((!league || !league.IdLeague) && pouch && pouch.selectedLeague) {
            this.props.leagueActions &&
                this.props.leagueActions.fetchLeague(pouch.selectedLeague.IdLeague)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState(_.extend(nextProps.pouch, {
            divisions: nextProps.divisions,
            bootstrapped: true
        }));
    }
    //

    back = () => {
        const { step, divisions } = this.state,
            { league } = this.props;
        if (step === 1) {
            this.props.router && this.props.router.push('/registration/' + league.IdLeague);
        } else {
            this.props.divisionsActions && this.props.divisionsActions.clearQuestions();
            this.props.divisionsActions && this.props.divisionsActions.clearPaymentPlans();

            divisions.Male && divisions.Male.forEach((d) => {
                d.selected = false;
            });
            divisions.Female && divisions.Female.forEach((d) => {
                d.selected = false;
            });
            divisions.Coed && divisions.Coed.forEach((d) => {
                d.selected = false;
            });

            this.setState({
                step: 1,
                divisions
            });
        }
    }

    selectDivision = (division) => {
        let { divisions } = this.state;

        _.find([...divisions.Male || [], ...divisions.Female || [], ...divisions.Coed || []], d => d.IdDivision === division.IdDivision).selected =
            !_.find([...divisions.Male || [], ...divisions.Female || [], ...divisions.Coed || []], d => d.IdDivision === division.IdDivision).selected

        this.setState({
            divisions,
            fetchingDataLocally: false,
            selectedDivision: division,
            selectedDivisions: _.filter([...divisions.Male || [], ...divisions.Female || [], ...divisions.Coed || []], d => d.selected)
        });
    }

    next = () => {
        const { step, selectedDivisions } = this.state, { pouch } = this.props;
        if (step === 1) {

            let all_plans = [];
            let all_questions = [];

            let got_the_plans = _.after(selectedDivisions.length * 2, () => {

                // WORKFLOW:
                //if there are plans, show them, then go to questions
                //if there's no plans, show the questions straight
                //if there are no questions, add to the cart and continue

                let the_questions = _.chain(all_questions).flatten().value();
                let the_plans = _.chain(all_plans).flatten().value();

                this.setState({
                    questions: _.chain(the_questions)
                        .sortBy('IdQuestion')
                        .uniqBy(s => parseInt(s.IdQuestion, 10)).value(),
                    paymentPlans: _.chain(the_plans)
                        .groupBy(plan => (plan.PlanName || plan.Name))
                        .values()
                        .map(group => {
                            return {
                                ..._.first(group),
                                Total: _.chain(group).map(g => g.Total).reduce((a, b) => a + b, 0).value(), // TODO: should be average this?
                                Dues: _.chain(group).map(g => g.Dues).flatten().value(),
                            }
                        })
                        .value(),
                    step: (the_plans.length) ? 2 : (the_questions.length ? 3 : 1)
                }, function () {
                    // TODO: still 1 ? then add to cart and continue
                    if (this.state.step === 1) {
                        this.addToCart(); //?
                        this.setState({ isCheckoutActionsOpen: true });
                    }
                });
            });

            all_plans = [];
            for (var i = 0; i < selectedDivisions.length; i++) all_plans.push({});
            selectedDivisions.forEach((selected_division, index) => {

                // Get payment plans        
                request.get([config.apiEndpoint, 'api/v4/paymentPlans',
                selected_division.IdDivision, pouch.mode === 1 ? 3 : 4].join('/'))
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        let plans = data.body.plans;
                        plans.forEach(plan => {
                            plan.Dues.forEach(due => due.Name = `${due.Name} for ${selected_division.Name || selected_division.DivisionName} session`);
                        });
                        all_plans[index] = plans;
                        got_the_plans();
                    });

                // Get questions
                request.get([config.apiEndpoint, 'api/v4/registration/questions',
                selected_division.IdDivision, pouch.mode === 1 ? 3 : 4].join('/'))
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        all_questions.push(data.body.questions);
                        got_the_plans();
                    });
            });

        } else if (step === 2) {
            if (this.state.questions && this.state.questions.length) {
                this.setState({ step: 3 });
            } else {
                this.addToCart();
                this.setState({ isCheckoutActionsOpen: true });
            }
        }
    }

    backToDivisions = () => {
        this.setState({ step: 1 });
    }

    selectPaymentPlan = (pp) => {
        const { paymentPlans } = this.state;
        paymentPlans.forEach((plan) => {
            plan.selected = (plan.IdPlan === pp.IdPlan && plan.Name === pp.Name);
        });
        this.setState({
            selectedPlan: pp,
            paymentPlans
        });
    }

    toggleCheckoutActions = () => {
        this.setState({
            isCheckoutActionsOpen: !this.state.isCheckoutActionsOpen
        });
    }

    tooglePlayerSelection = () => {
        this.setState({
            isPlayerSelectionOpen: !this.state.isPlayerSelectionOpen,
            isCheckoutActionsOpen: false
        });
    }

    cancelRegisterAnother = () => {
        this.setState({
            isPlayerSelectionOpen: false,
            isCheckoutActionsOpen: true
        });
    }

    restart = (newPouch) => {

        this.props.divisionsActions && this.props.divisionsActions.clearDivisions();
        this.setState(_.extend(this.state, newPouch, {
            step: 1,
            isCheckoutActionsOpen: false,
            isPlayerSelectionOpen: false,
            selectedDivision: null,
            selectedPlan: null,
            questions: null,
            paymentPlans: null,
            divisions: null
        }), function () {
            this.props.pouchActions &&
                this.props.pouchActions.set(newPouch);

            this.props.divisionsActions &&
                this.props.divisionsActions.fetchForRegistrationOfUsers(newPouch.selectedSeason.IdSeason, newPouch.selectedUser.IdUser, newPouch.mode === 1 ? 3 : 4)

            // Scroll to the top !
            //document.getElementById('top-of').scrollIntoView()
        })

    }

    toggleCart = () => {
        this.props.cartActions &&
            this.props.cartActions.close();
    }

    addToCart = () => {

        let { cart } = this.props,
            { questions, selectedDivision, selectedDivisions, selectedUser, mode, selectedPlan } = this.state;

        // 1st validate all the required questions
        if (_.find(questions, (q) => {
            return q.Required && !q.answer;
        })) {
            alert('Please answer all the required questions');
        } else {

            this.setState({
                isCheckoutActionsOpen: true
            }, function () {
                if (!cart)
                    cart = [];

                if (!_.find(cart, (item) => {
                    return (item.selectedUser.IdUser === selectedUser.IdUser &&
                        item.selectedDivision.IdDivision === selectedDivision.IdDivision &&
                        item.mode === mode);
                })) {

                    // Add to the cart!
                    selectedDivisions.forEach((selected_division, index) => {
                        cart.push(
                            _.extend({
                                Date: new Date(),
                                Id: generateRandomId(10),
                                questions: _.map(this.state.questions, (q) => {
                                    return _.omit(q, 'Answers')
                                }),
                                selectedDivision: selected_division,
                                selectedPlan: {
                                    ...selectedPlan,
                                    Total: selectedPlan.Dues[0].Total,
                                    //Dues: [selectedPlan.Dues[index]] // WHY ARE WE DOING THIS?
                                }
                            }, _.pick(this.state, 'selectedUser', 'selectedLeague',
                                'selectedSeason', 'selectedRole', 'mode'))
                        );
                    });
                    //

                    this.props.cartActions && this.props.cartActions.set([...cart]);
                }
            });
        }
    }

    render() {
        const { fetchingDataLocally, isCheckoutActionsOpen, isPlayerSelectionOpen, selectedPlan, divisions, selectedRole,
            selectedDivision, selectedDivisions = [], selectedSeason, selectedUser, step, bootstrapped, questions, paymentPlans } = this.state;
        const { fetchingData, league, cart, family, familyRegistration, isCartOpen, pouch } = this.props;

        return (
            <section>
                {league && <div className="card shadow-box w-100 px-2 py-2 d-flex flex-row" onClick={this.back}>
                    <i className="fa fa-arrow-left align-self-center" />
                    <span className="align-self-center ml-2">Back</span>
                </div>}

                <section className="w3-container">
                    <div className="w3-content">

                        <div className="tile card shadow-box d-flex flex-column mx-auto mt-4" style={{ maxWidth: 600 }}>
                            <Season season={selectedSeason} />
                            <Person selectedUser={selectedUser} selectedRole={selectedRole || { Name: 'Player ' }} />
                            {step !== 1 && (selectedDivisions || []).map((selectedDivision, i) => <Division key={i} selectedDivision={selectedDivision} onClick={this.backToDivisions} />)}
                        </div>

                        { /* Divisions */}
                        {step === 1 && bootstrapped && (
                            <section key="step1" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Select your division</span>

                                {divisions && divisions.Male && <DivisionList title="Male Divisions" familyRegistration={_.filter(pouch.familyRegistration, fr => fr.IdUser === selectedUser.IdUser && fr.IdSeason === selectedSeason.IdSeason)} divisions={divisions.Male} fnSelect={this.selectDivision} />}

                                {divisions && divisions.Female && <DivisionList title="Female Divisions" familyRegistration={_.filter(pouch.familyRegistration, fr => fr.IdUser === selectedUser.IdUser && fr.IdSeason === selectedSeason.IdSeason)} divisions={divisions.Female} fnSelect={this.selectDivision} />}

                                {divisions && divisions.Coed && <DivisionList title="Coed Divisions" familyRegistration={_.filter(pouch.familyRegistration, fr => fr.IdUser === selectedUser.IdUser && fr.IdSeason === selectedSeason.IdSeason)} divisions={divisions.Coed} fnSelect={this.selectDivision} />}

                                {divisions && !divisions.Male && !divisions.Female && !divisions.Coed && <div style={{ height: 400 }} className="d-flex flex-column p-4 justify-content-center">
                                    <img alt="" src="/images/defaults/red_card.png" style={{ width: 100 }} className="align-self-center" />
                                    <span className="align-self-center mt-2">Sorry, there are no divisions available for registration right now</span>
                                </div>}
                            </section>
                        )}

                        { /* Payment Plans */}
                        {step === 2 && bootstrapped && selectedDivision && (
                            <section key="step2" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Select your Payment Plan</span>
                                <PaymentPlansList paymentPlans={paymentPlans} fnSelect={this.selectPaymentPlan} />
                            </section>)}

                        { /* Questions */}
                        {step === 3 && bootstrapped && (
                            <section key="step3" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Please answer some questions</span>
                                {questions && questions.map((q, i) => (
                                    <figure className="card tile shadow-box w-100 mt-2 mb-2 ml-auto mr-auto" key={i} style={{ maxWidth: 600 }}>
                                        {q.IdQuestionType === 5 && <SelectQuestion question={q} />}
                                        {q.IdQuestionType === 6 && <RadioQuestion question={q} />}
                                        {q.IdQuestionType === 2 && <TextQuestion question={q} />}
                                        {q.IdQuestionType === 3 && <CheckQuestion question={q} />}
                                        {q.IdQuestionType === 4 && <CheckListQuestion question={q} />}
                                    </figure>))}
                            </section>)}

                    </div>
                    <div style={{ height: 200 }} />
                </section>


                { /* Loader */}
                {bootstrapped && (fetchingData || fetchingDataLocally) && (
                    <div className="d-flex justify-content-center centered-message" key="laoder">
                        <h1 className="white align-self-center block text-center"><i className="fa fa-spin fa-cog" /></h1> </div>)}
                { /* Checkout actions */}
                {isCheckoutActionsOpen && <CheckoutActionsPopup router={this.props.router}
                    league={league} isOpen={isCheckoutActionsOpen} toggle={this.toggleCheckoutActions}
                    fnRegisterAnother={this.tooglePlayerSelection} fnOk={this.toggleCheckoutActions} label="Player"
                />}

                { /* Register Another */}

                {isPlayerSelectionOpen && <PersonSelection close={this.togglePlayerSelection} cart={cart}
                    league={league} season={selectedSeason} family={family} fnAddFamilyMember={() => { }}
                    fnOk={this.restart}
                    familyRegistration={familyRegistration} />}

                {isPlayerSelectionOpen && false && <RegistrationPlayerSelection cart={cart} league={league}
                    season={selectedSeason} family={family} isOpen={isPlayerSelectionOpen}
                    toggle={this.cancelRegisterAnother} fnOk={this.restart} familyRegistration={familyRegistration} />}

                {isCartOpen && <CartPopup {...this.props} toggle={this.toggleCart} fnOk={this.toggleCart} isOpen={this.props.isCartOpen} />}

                {league && (
                    <button onClick={this.back} to={`/registration/${league.IdLeague}`} className="btn btn-link float-left" style={{ zIndex: 10, position: 'absolute', top: 0, left: 0 }}>
                        <i className="fa fa-arrow-left" />
                        {' '}
                        <span>Back</span>
                    </button>)}
                {((step === 1 && selectedDivision) || (step === 2 && selectedPlan)) && (
                    <button className="btn btn-success btn-lg margin-top" style={{ position: 'fixed', bottom: '1em', right: '1em' }} onClick={this.next}>
                        Next
                        {' '}<i className="fa fa-arrow-right" /></button>)}
                {step === 3 && <button className="btn btn-success btn-lg margin-top" style={{ position: 'fixed', bottom: '1em', right: '1em' }} onClick={this.addToCart}><i className="fa fa-plus" />
                    {' '}Add to Cart</button>}
            </section>
        );
    }
}

export default CampRegistration;