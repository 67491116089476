function reducer(state = [], action) {

    switch (action.type) {
        case 'LEAGUE/VALIDATIONS/TYPE/FETCH': return true;
        case 'USERS/SET': return false;
        case 'MICRO/SET':
            return action.micro;
        default:
            return state;
    }
}

export default reducer;