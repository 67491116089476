import React from 'react';
import DivisionListItem from './item';
import _ from 'lodash';

const Divisions = (props) => {
  const { divisions } = props;

  return (
    <ul className="divisions-wrapper d-flex flex-column mt-1">
      {_.chain(divisions).value().map((division, i) => (
        <DivisionListItem key={i} index={i} division={division} {...props} />))}
    </ul>)
}

export default Divisions;