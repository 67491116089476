import React from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import _ from 'lodash';
import { formatPrice } from '../../../../helpers';

class Comp extends React.Component {

    state = {
        answer: null
    }

    handleChange = (value) => {
        const { question } = this.props;
        question.answer = _.find(question.Answers, (a) => {
            return a.IdAnswer === value
        });
        this.setState({
            answer: value
        });
    }

    render() {
        const { question } = this.props,
            { answer } = this.state;
        return <div className="idented margin-right margin-top-half d-flex flex-column">
            <div className="d-flex flex-row">
                <span className="text-left w-100 align-self-center">{question.QuestionText}</span>
                {question.Required && <i className="ml-auto fa fa-asterisk red align-self-center" />}
            </div>
            <RadioGroup className="d-flex flex-column margin-top-half" name={`answer-${question.IdQuestion || question.Id}`} selectedValue={answer} onChange={this.handleChange}>
                {question.Answers.map((a, i) => (
                    <label key={i}>
                        <Radio value={a.IdAnswer} />
                        { ' '}
                        { a.AnswerText + (a.Amount !== 0 ? ' (' + formatPrice(a.Amount * 100) + ')' : '')}
                    </label>))}
            </RadioGroup>
            <div style={{ height: 10 }} />
        </div>
    }
}

export default Comp;