export function fetchTeams(idUser) { return { type: 'ROSTER/TEAMS/FETCH', idUser } }
export function clearTeams() { return { type: 'ROSTER/TEAMS/CLEAR' } }
export function fetchRoster(idUser, idTeamDivision) { return { type: 'ROSTER/FETCH', idUser, idTeamDivision } }
export function fetchRoles(idSeason) { return { type: 'ROLES/FETCH', idSeason } }
export function clearRoster() { return { type: 'ROSTER/CLEAR' } }
export function clearLineups() { return { type: 'LINEUPS/CLEAR' } }
export function updateShirtSize(idUser, shirtSize) { return { type: 'SHIRT_SIZE/UPDATE', idUser, shirtSize } }
export function fetchPublic(idTeamDivision) { return { type: 'ROSTER/PUBLIC/FETCH', idTeamDivision } }
export function fetchVideosByUser(idUser) { return { type: 'VIDEOS/USER/FETCH', idUser } }
export function updatePositionAndJersey({ idTeamUserRole, position, jerseyNumber }) { return { type: 'ROSTER/JERSEY/UPDATE', idTeamUserRole, position, jerseyNumber } }
export function updateBrochureColors({ idTeam, color1, color2 }) { return { type: 'ROSTER/COLORS/UPDATE', idTeam, color1, color2 } }
export function clearColors() { return { type: 'COLORS/CLEAR' } }
export function updateCollegeProfile(args) { return { type: 'ROSTER/COLLEGE_PROFILE/UPDATE', ...args } }
export function fetchNetwork(idTeamDivision) { return { type: 'ROSTER/NETWORK/FETCH', idTeamDivision } }
export function fetchNetworkCoaches(idTeamDivision) { return { type: 'ROSTER/NETWORK/COACHES/FETCH', idTeamDivision } }
export function updateJersey({ IdUser, JerseyNumber, Position, IdTeamDivision, IdTeamUserRole }) { return { type: 'ROSTER/JERSEY/POSITION/UPDATE', IdUser, IdTeamUserRole, JerseyNumber, Position, IdTeamDivision } }
export function updateRole({idTeamUserRole, idRoleOverride, idTeamDivision}) { return { type: 'ROSTER/ROLE/UPDATE', idTeamUserRole, idRoleOverride, idTeamDivision } }
export function removeGuestFromGame({ IdUser, IdGame, IdTeamDivision }) { return { type: 'ROSTER/GUEST/REMOVE', IdUser, IdGame, IdTeamDivision }}
export function addGuestPlayer({ IdUser, IdGame, IdTeamDivision }) { return { type: 'ROSTER/GUEST/ADD', IdUser, IdGame, IdTeamDivision }}
export function togglePlayerActiveStatus({ IdTeamUserRole, IdTeamDivision }) { return { type: 'ROSTER/PLAYER/STATUS/TOGGLE', IdTeamUserRole, IdTeamDivision }}
export function togglePlayerActiveInGame({ IdUser, IdTeamDivision, IdGame, IdInactivePlayer }) { return { type: 'ROSTER/PLAYER/GAME/STATUS/TOGGLE', IdUser, IdTeamDivision, IdGame, IdInactivePlayer }}

export function save_match(match) { return { type: 'GENERIC_MATCH/SAVE', match } }
