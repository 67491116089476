import React from 'react';
import './divisions.css';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';
import GameDayRuleIcon from '../game_days/GameDayRuleIcon';
import ExceptionRuleIcon from '../exceptions/ExceptionRuleIcon';
import FieldAvailabilityRuleIcon from '../fields_availability/FieldAvailabilityRuleIcon';
import { Animated } from 'react-animated-css';

const flightTarget = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }

        const { flight } = props;

        switch (monitor.getItem().rule.type) {
            case 'GameDay':
                // Evaluate what type of rule is being dropped!
                if (!_.find(flight.GameDayRules, function (r) {
                    return (r.IdRule === monitor.getItem().rule.Id);
                })) {

                    monitor.getItem().gameDayRulesActions.associateWithFlight(monitor.getItem().rule.Id, flight.IdFlight);

                    // The idea is to add it inmediatelly, and then the saga / reducer will update the record with the association id ?
                    flight.GameDayRules.push({
                        IdFlight: flight.IdFlight,
                        FlightName: (flight.Name || flight.FlightName),
                        IdRule: monitor.getItem().rule.Id,
                        RuleName: monitor.getItem().rule.Name
                    });

                } else {

                    // Shake feedback
                    _.find(flight.GameDayRules, function (r) {
                        return r.IdRule === monitor.getItem().rule.Id
                    }).shake = true;

                }
                break;
            case 'Exception':
                break;
            case 'FieldAvailability':

                // Evaluate if the rule isn't already there
                if (!_.find(flight.FieldAvailabilityRules, function (r) {
                    return ((r.IdScheduleFieldAvailability || r.IdRule) === monitor.getItem().rule.IdScheduleFieldAvailability);
                })) {
                    monitor.getItem().fieldAvailabilityRulesActions &&
                        monitor.getItem().fieldAvailabilityRulesActions.associateWithFlight(monitor.getItem().rule.IdScheduleFieldAvailability, flight.IdFlight);
                    flight.FieldAvailabilityRules.push({
                        IdFlight: flight.IdFlight,
                        FlightName: (flight.Name || flight.FlightName),
                        IdRule: monitor.getItem().rule.IdScheduleFieldAvailability,
                        RuleName: 'Field Availability Rule'
                    });
                } else {
                    _.find(flight.FieldAvailabilityRules, function (r) {
                        return r.IdRule === monitor.getItem().rule.IdScheduleFieldAvailability
                    }).shake = true;
                }
                break;
            default:
                break;
        }

    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class FlightListItem extends React.Component {   

    render() {
        const { connectDropTarget, isOverCurrent, canDrop, flight, divisions } = this.props;
        const { gameDayRules, gameDayRulesActions, exceptionRules, exceptionRulesActions, fieldAvailabilityRules, fieldAvailabilityRulesActions } = this.props;
        return connectDropTarget(
            <li className={`flight no-margin no-padding block ${canDrop ? '' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'}`}>
                <span title={flight.FlightName} className="idented">{flight.FlightName.length > 25 ? `${flight.FlightName.substring(0, 25)}...` : flight.FlightName}<span className="blue font-8 idented-half">({flight.MinimumGamesPerTeam} gms)</span>
                    <Animated animationIn="fadeInRight" className="icons-wrapper">
                        {flight.GameDayRules && flight.GameDayRules.map((rule, i) => <GameDayRuleIcon key={i} index={i} rule={rule} rules={gameDayRules} gameDayRulesActions={gameDayRulesActions}
                        />)}
                        {flight.FieldAvailabilityRules && flight.FieldAvailabilityRules.map((rule, i) => <FieldAvailabilityRuleIcon key={i} index={i} 
                        rule={rule} rules={fieldAvailabilityRules} fieldAvailabilityRulesActions={fieldAvailabilityRulesActions} divisions={_.filter(divisions, d => d.Flights.length > 0)}
                        />)}
                        {flight.ExceptionRules && flight.ExceptionRules.map((rule, i) => <ExceptionRuleIcon key={i} index={i} rule={rule} rules={exceptionRules} exceptionRulesActions={exceptionRulesActions}
                        />)}
                    </Animated>
                </span>
            </li>
        )
    }
}

export default DropTarget(props => props.accept, flightTarget, collect)(FlightListItem);
