import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { formatPrice } from '../../../../helpers';
import request from 'superagent';
import config from '../../../../config';

class PayingPlacebo extends React.Component {

    state = {
        step: 1,
        message: null,
        total: 0
    }

    componentWillMount() {
        this.setState({
            total: this.props.total
        });
    }

    componentDidMount() {

        const { card, total, cart } = this.props;

        const url = [config.apiEndpoint, 'api/v4/appPlans/pay'].join('/');
        request.post(url)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({
                card,
                cart,
                total
            })
            .then((data) => {
                if (data.body.success) {
                    this.setState({
                        step: 2,
                        message: data.body.transactionId
                    }, function () {
                        this.props.fnOk && this.props.fnOk();
                    });

                    // Remove that cart
                } else {
                    this.setState({
                        step: 3,
                        message: data.body.message
                    })
                }
            }, ({ response: { body } }) => {                    
                this.setState({
                    step: 3,
                    message: body.error.message
                });
            });

    }

    render() {
        const { isOpen, toggle } = this.props,
            { step, total } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} keyboard={false} backdrop={'static'}>
                <ModalBody>
                    <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
                        transitionEnter={true} transitionLeave={false}>
                        {step === 1 && <div className="jumbotron no-margin text-center" key="step1">
                            <i className="fa fa-credit-card bigest-font heart" />
                            <h1 className="margin-top-half">Processing, please wait...</h1>
                        </div>}
                        {step === 2 && <div onClick={toggle} className="jumbotron no-margin text-center bg-success" key="step1">
                            <i className="fa fa-check white bigest-font" />
                            <h1 className="white">Registration successful</h1>
                            <h3 className="white">{formatPrice(total * 100)}</h3>
                            <h3 className="white font-8">{this.state.message}</h3>
                        </div>}
                        {step === 3 && <div onClick={toggle} className="jumbotron no-margin text-center bg-danger" key="step1">
                            <i className="fa fa-times white bigest-font" />
                            <h1 className="white">Registration Failed</h1>
                            <h3 className="white">{this.state.message}</h3>
                        </div>}
                    </ReactCSSTransitionGroup>
                </ModalBody>
            </Modal>
        )
    }
}

export default PayingPlacebo;