import React from 'react';
import Select from 'react-select';
import request from 'superagent';
import config from '../../../../config';
import _ from 'lodash';

class TieBreaker extends React.Component {
    state = {
        selectedOption: 0
    }

    // Lifecycle
    componentWillMount() {
        const { tiebreaker } = this.props;
        this.setState({ tiebreaker });        
    }
    componentWillUnmount() {
        this.props.seasonActions && this.props.seasonActions.clearPoints();
    }
    //

    change = (obj) => {
        let { settings, idSeason } = this.props, { tiebreaker } = this.state;
        let selectedOption = obj ? obj.value : 0;

        if (!obj) {
            // call API for delete
            if (tiebreaker.Id) {
                // CALL API
                request.del(`${config.apiEndpoint}/api/v4/seasons/tiebreaker/${tiebreaker.Id}`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then(() => { });
            }
            tiebreaker.Id = null;
            tiebreaker.NameOverride = null;
            tiebreaker.IdTournamentPointContestType = null;
            this.setState({ tiebreaker });
        } else {
            let setting = _.find(settings, s => parseInt(s.Id, 10) === parseInt(selectedOption, 10));
            if (setting) {
                tiebreaker.IdTournamentPointContestType = setting.Id;
                tiebreaker.NameOverride = setting.Name;
                // call API to [save if tiebreaker.Id is null, update otherwise]
                this.setState({ saving: true }, () => {

                    request.post(`${config.apiEndpoint}/api/v4/seasons/tiebreaker/${idSeason}`)
                        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                        .send(tiebreaker)
                        .then(({ body: { Id } }) => {
                            if (!tiebreaker.Id) tiebreaker.Id = Id;
                            this.setState({ saving: false, tiebreaker });
                        });
                });
            } else {
                alert('NO SETTING FOUND WITH ID' + selectedOption)                
            }
        }
    }

    render() {

        const { settings = [] } = this.props, { tiebreaker = {} } = this.state;
        const options = _.map(settings, s => {
            return { label: s.Name, value: s.Id }
        });

        return (<li className={`d-flex flex-row justify-content-center tie`}>
            <Select
                value={tiebreaker.IdTournamentPointContestType}
                style={{ minWidth: '300px' }}
                className={`align-self-center idented-half margin-top-half ${(tiebreaker && tiebreaker.Id) ? 'bg-success white' : ''}`}
                options={options}
                onChange={this.change}
                placeholder="Select an option..."
            />
        </li>)
    }
}

export default TieBreaker;