import React from 'react';
import { Link } from 'react-router';
import '../common/style.css';
import { Animated } from 'react-animated-css';

class Main extends React.Component {

  state = {
    microTransac: false,
    bootstrapped: false
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle } = this.props;
    setTitle && setTitle({ main: 'Payments' });
    this.props.leagueActions && this.props.leagueActions.clearLeague();
    this.props.leagueActions && this.props.leagueActions.clearLeagues();
    this.props.leagueActions && this.props.leagueActions.fetchLeaguesForPayments();
    this.props.family && !this.props.family.length && this.props.familyActions && this.props.familyActions.fetchMembers();
    this.props.familyActions && this.props.familyActions.fetchTeams();

  } //

  componentDidMount() {
    this.setState({ bootstrapped: true });
  }
  //

  setLeague = (league) => {
    this.props.leagueActions &&
      this.props.leagueActions.setLeague &&
      this.props.leagueActions.setLeague(league);

      this.props.leagueActions && this.props.leagueActions.fetchCurrency(league.IdLeague);
  }

  search = (e) => {
    e.preventDefault();
    this.props.leagueActions && this.props.leagueActions.fetchLeaguesForRegistration(this.txtSearch.value);
  }

  render() {
    const { fetchingData, leagues } = this.props,
      { bootstrapped } = this.state;

    return (

      <section id="registration-leagues" component="section">
        <div className="right-actions d-flex flex-row-reverse" key="rightActions">
          <form>
            <div className="input-group shadow-box">
              <input type="text" className="form-control" placeholder="Search for Leagues" ref={(i) => this.txtSearch = i} />
              <span className="input-group-btn">
                <button className="btn btn-info" type="submit" onClick={this.search}>Search</button>
              </span>
            </div>
          </form>
        </div>
        { /* Loader */}
        <div className="d-flex justify-content-center centered-message" key="content" >

          {bootstrapped && fetchingData &&
            <h1 className="jeff_blue align-self-center block text-center" key="loader"><i className="fa fa-spin fa-cog" style={{ fontSize: 100 }} /></h1>}

          {bootstrapped && !fetchingData &&
            <div className="d-flex flex-column">
              <ul className="white text-center d-flex flex-wrap pb-4 justify-content-center" key="list">
                {leagues && leagues.map((league, i) => <li className="tile card shadow-box" key={i}>
                <Animated animationIn="bounceIn" animationInDelay={i * 50} className="w-100 h-100">
                  <Link onClick={() => this.setLeague(league)} to={`/payments/${league.Id || league.IdLeague}`} className="white align-self-center justify-content-center d-flex flex-column" style={{ width: '100%', height: '100%' }}>
                    <img alt="" src={league.LeagueImage} style={{ width: '100px' }} className="align-self-center margin-bottom-half" />
                    <span className="m-2 montserrat black align-self-center margin-left-half margin-right-half line1" style={{ fontSize: (league.Name || league.LeagueName).length > 20 ? 12 : 16 }}>{league.Name || league.LeagueName}</span>
                  </Link>
                  </Animated>
                </li>)}
              </ul>
              <div style={{ height: 100 }} />
            </div>}

          {bootstrapped && !fetchingData && !(leagues || []).length &&
            <h1 className="black   align-self-center block text-center" key="none">There are no Leagues available for Payments{this.txtSearch.value ? ' that matches your search criteria' : '. Use the search tool to find one'}</h1>}
        </div>
      </section>
    )
  }
}

export default Main;