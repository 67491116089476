import React from 'react';
import Loader from '../../../common/Loader';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TournamentList from './TournamentList';

class Donations extends React.Component {

  state = {
    bootstrapped: false,
    days: 1,
    mode: null // 1 -> Tournament, 2 -> Non-Tournament
  }

  // Lifecycle
  componentWillMount() {
    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    setTitle && setTitle({ main: 'Report | Donations' });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle, params } = nextProps;
      setTitle && setTitle({ sub: 'Report | Donations', main: nextProps.season.Name || null });
      this.props.reportsActions && this.props.reportsActions.fetchDonations(params.idSeason); // tODO      
    }

    if (this.props.report !== nextProps.report && nextProps.report) {
      this.setState({ bootstrapped: true });
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //  

  onDays = (d) => { }

  render() {
    const { bootstrapped } = this.state,
      { season } = this.props;

    return <section>
      <div className="d-flex flex-row  px-2" style={{ height: '3em' }}>        
        <ReactHTMLTableToExcel id="test-table-xls-button" className="ml-auto align-self-center download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Donations - ${season.Name}`}
          sheet="Donations" buttonText="Export as XLS" />
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            <TournamentList {...this.props} />
          </section>)}
      </div>
    </section>
  }
}

export default Donations;