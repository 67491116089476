import React from 'react';
import GameDayRule from './GameDayRule';
import './game_days.css';
import { Animated } from 'react-animated-css';
import COLORS from '../../../../../colors';

class GameDays extends React.Component {
  // Lifecycle

  //

  addRule = () => {
    this.props.gameDayRulesActions.addGameDayRule &&
      this.props.gameDayRulesActions.addGameDayRule({
        IdSeason: this.props.params.idSeason,
        Name: 'Game Day Rule',
        PossibleGamesPerWeek: 0,
        Days: {
          Monday: 0,
          Tuesday: 0,
          Wednesday: 0,
          Thusrday: 0,
          Friday: 0,
          Saturday: 0,
          Sunday: 0
        }
      });
  }

  render() {
    const { gameDayRules, gameDayRulesActions } = this.props;
    return (
      <div id="game-days">
        <Animated animationIn="fadeInRight" className="d-flex flex-row">
          <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: COLORS.jeff_blue }} 
          className="ml-auto py-2 px-3 align-self-center block" onClick={() => this.addRule()}><i className="fa fa-plus" /> Add Rule</button>
        </Animated>
        <div>
          <ul className="child clearfix game-day-rule-list text-center">
            {gameDayRules.map((rule, i) => <GameDayRule key={i} index={i} rule={rule} gameDayRulesActions={gameDayRulesActions} />)}
          </ul>
          {!gameDayRules.length && (
            <div className="jumbotron text-center margin-top">
              <h1 className="display-3">There are no Game Day rules</h1>
              <p className="lead">Click the plus sign above to create your first Game Day rule</p>
            </div>)}
        </div>
      </div>
    )
  }
}

export default GameDays;