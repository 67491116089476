function country_codes(state = [], action) {
    switch (action.type) {
        case 'COUNTRY_CODES/SET':
            return action.country_codes;
        default:
            return state;
    }
}

export default country_codes;
