import React from 'react';
import _ from 'lodash';
import fakeAuth from '../../../../fakeAuth';

class Uniforms extends React.Component {
    state = {}
    componentWillMount = () => {
        const { event, team } = this.props;
        this.props.teamActions && this.props.teamActions.fetchUniformByGame(event.IdGame || event.IdEvent, team.IdTeam);
        this.props.teamActions && this.props.teamActions.fetchUniformBags(team.IdTeam);
    }
    componentWillReceiveProps = nextProps => {

        if (nextProps.uniform_bag && !this.state.bootstrapped) {
            this.setState({
                bootstrapped: true,
                shirt: _.find(nextProps.uniform_bag.items, b => b.IdUniformType === 1),
                short: _.find(nextProps.uniform_bag.items, b => b.IdUniformType === 2),
                socks: _.find(nextProps.uniform_bag.items, b => b.IdUniformType === 3),
            });
        }
    }
    callAPI = () => {
        const { event, team } = this.props, { shirt, short, socks } = this.state;
        this.props.teamActions.setUniformToGame(event.IdEvent || event.IdGame, [shirt, short, socks], team.IdTeam);
    }
    render() {
        const { team_subscriptions } = this.props, family = fakeAuth.getFamily();
        if (_.find(team_subscriptions, s => !s.Expired)) {
            return <section className=" p-2 d-flex flex-column">
                <h4 className="montserrat-bold mb-2 font-16 mx-3">Uniforms | Kits</h4>
                <div className="tile card mb-2">
                    <div className="d-flex flex-row p-2" onClick={() => {
                        const { team } = this.props;
                        if (team.IsTeamAdmin || team.IsTeamOwner) this.setState({ building: true });
                    }}>
                        <div className="w-100 px-1 align-self-center ">
                            <button className={`btn btn-link bordered btn-block bg-whiteish`}><i className={`icomoon-t-shirt font-40`} style={{ color: (this.state.shirt || { UniformColor: '#000000' }).UniformColor }} /></button>
                        </div>
                        <div className="w-100 px-1 align-self-center ">
                            <button className={`btn btn-link bordered btn-block bg-whiteish`}><i className={`icomoon-pants font-40`} style={{ color: (this.state.short || { UniformColor: '#000000' }).UniformColor }} /></button>
                        </div>
                        <div className="w-100 px-1 align-self-center ">
                            <button className={`btn btn-link bordered btn-block bg-whiteish`}><i className={`icomoon-socks font-40`} style={{ color: (this.state.socks || { UniformColor: '#000000' }).UniformColor }} /></button>
                        </div>
                    </div>
                    {this.state.building && <section>
                        <hr />
                        <div className="d-flex flex-row">
                            <h4 className="montserrat-bold mb-2 font-16 mx-3 align-self-center">Build your combination</h4>
                            <button onClick={() => this.setState({ building: false })} className="btn btn-link ml-auto align-self-center"><i className="fas fa-times black" /></button>
                        </div>
                        {this.props.uniform_bags && this.props.uniform_bags.map((bag, i) => <div key={i} className="p-2">
                            <span>{bag.UniformBagName}</span>
                            <div className="d-flex flex-row">
                                {/* SHIRT */}
                                <div className="w-100 px-1 align-self-center ">
                                    <button className={`btn btn-link btn-block bg-whiteish`} style={{ border: (this.state.shirt && this.state.shirt.IdUniform === (_.find(bag.items, i => i.IdUniformType === 1) || {}).IdUniform) ? `3px solid green` : `1px solid gray` }}
                                        onClick={() => {
                                            this.setState({ shirt: _.find(bag.items, i => i.IdUniformType === 1) }, this.callAPI);
                                        }}>
                                        <i className={`icomoon-t-shirt font-40`} style={{ color: (_.find(bag.items, i => i.IdUniformType === 1) || { UniformColor: '#000000' }).UniformColor }} />
                                    </button>
                                </div>
                                {/* SHORT */}
                                <div className="w-100 px-1 align-self-center ">
                                    <button className={`btn btn-link btn-block bg-whiteish`} style={{ border: (this.state.short && this.state.short.IdUniform === (_.find(bag.items, i => i.IdUniformType === 2) || {}).IdUniform) ? `3px solid green` : `1px solid gray` }}
                                        onClick={() => {
                                            this.setState({ short: _.find(bag.items, i => i.IdUniformType === 2) }, this.callAPI);
                                        }}>
                                        <i className={`icomoon-pants font-40`} style={{ color: (_.find(bag.items, i => i.IdUniformType === 2) || { UniformColor: '#000000' }).UniformColor }} />
                                    </button>
                                </div>
                                {/* SOCKS */}
                                <div className="w-100 px-1 align-self-center ">
                                    <button className={`btn btn-link btn-block bg-whiteish`} style={{ border: (this.state.socks && this.state.socks.IdUniform === (_.find(bag.items, i => i.IdUniformType === 3) || {}).IdUniform) ? `3px solid green` : `1px solid gray` }}
                                        onClick={() => {
                                            this.setState({ socks: _.find(bag.items, i => i.IdUniformType === 3) }, this.callAPI);
                                        }}>
                                        <i className={`icomoon-socks font-40`} style={{ color: (_.find(bag.items, i => i.IdUniformType === 3) || { UniformColor: '#000000' }).UniformColor }} />
                                    </button>
                                </div>
                            </div>
                        </div>)}
                        <div style={{ height: 10 }} />
                    </section>}
                </div>
            </section>
        } else return <section />
    }
}

export default Uniforms;