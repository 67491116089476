import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class PlayerCardSettings extends React.Component {

  state = {
    bootstrapped: false,
    validations: {
      name: false
    },
    league: {},
    dirty: false
  }

  // Lifecycle
  componentWillMount() {
    const { league } = this.props;
    this.setState({ league });
  }
  //   

  save = () => { }
  handleChangeName = e => { }
  render() {
    const { isOpen, toggle, } = this.props,
      { validations, dirty, league = {} } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{ width: 400 }}>
        <ModalHeader toggle={toggle}>
          Player Card Settings
        </ModalHeader>
        <ModalBody className="no-margin no-padding p-2">
          <div className="w-100">
            <label>Header<span className="font-8 idented-half">(16 Characters max)</span></label>
            <input maxLength="16" className={`${(!validations.name && dirty) ? 'input-danger' : ''} form-control`} type="text"
              defaultValue={league.Name} onChange={this.handleChangeName}
              placeholder="" ref={i => this.txtHeader = i} />
          </div>
          <div className="d-flex flex-row margin-top-half">
            <span className="align-self-center margin-right-half">Color</span>
            <button className="btn btn-sm bg-danger align-self-center margin-right-half"><i className="fa fa-square" /></button>
            <button className="btn btn-sm bg-success align-self-center margin-right-half"><i className="fa fa-square" /></button>
            <button className="btn btn-sm bg-info align-self-center margin-right-half"><i className="fa fa-square" /></button>
            <button className="btn btn-sm bg-warning align-self-center margin-right-half"><i className="fa fa-square" /></button>
            <button className="btn btn-sm bg-purple align-self-center margin-right-half"><i className="fa fa-square" /></button>
          </div>
          <div className="d-flex flex-row margin-top-half">
            <button className="btn btn-sm align-self-center"><i className="far fa-circle" /></button>
            <span className="align-self-center idented-half margin-right-half">Show Season Name</span>
            <input maxLength="16" className={`form-control ml-auto`} type="text"
              placeholder="" ref={i => this.txtSeasonName = i} style={{ width: 100 }} />
          </div>
          <div className="d-flex flex-row margin-top-half">
            <button className="btn btn-sm align-self-center"><i className="far fa-circle" /></button>
            <span className="align-self-center idented-half margin-right-half">Show Member Since</span>
            <input maxLength="16" className={`form-control ml-auto`} type="text"
              placeholder="" ref={i => this.txtMemberSince = i} style={{ width: 100 }} />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-row">
          <button className="btn btn-link align-self-center" onClick={toggle}>Cancel</button>
          <button className="btn btn-success align-self-center ml-auto" onClick={this.save}>Continue</button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default PlayerCardSettings;
