import React from 'react';

class FullScore extends React.Component {
    render() {
        return <table className='w-100 margin-top-half text-center' border="1">
            <thead>
                <tr>
                    <th>Teams</th>
                    <th style={{ width: 50 }}>1</th>
                    <th style={{ width: 50 }}>2</th>
                    <th style={{ width: 50 }}>3</th>
                    <th style={{ width: 50 }}>4</th>
                    <th style={{ width: 50 }}>5</th>
                    <th style={{ width: 50 }}>6</th>
                    <th style={{ width: 50 }}>7</th>
                    <th style={{ width: 50 }}>8</th>
                    <th style={{ width: 50 }}>9</th>
                    <th style={{ width: 120 }}>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="text-center idented-half">V</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="text-center idented-half">H</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    }
}

export default FullScore;