function lineups(state = [], action) {
    switch (action.type) {
        case 'LINEUPS/SET':
            return action.lineups;
        case 'LINEUPS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default lineups;
