import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';

class Comp extends React.Component {

  render() {

    const { user } = this.props;

    return (
      <div className="d-flex flex-column bg-white w-100 black" style={{ position: 'relative' }}>
        <div className={`bg-warning`} style={{ height: 80 }} />
        <div style={{ marginTop: "3.5em" }} className="d-flex flex-column font-9">
          <span className="m-auto font-14">{user.UserName}</span>
          <span className="m-auto font-8">Id: {user.IdUser}</span>
          <div className="d-flex flex-row margin-top-half">
            <span className="idented-half align-self-center">{user.Gender}</span>
          </div>
          <div className="d-flex flex-row">
            <i className="fas fa-calendar-alt idented align-self-center" />
            <span className="idented-half align-self-center">{moment(user.DateBirth.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}{' '}<span className="font-7 align-self-center">({moment().diff(user.DateBirth, 'years')} yrs)</span></span>
          </div>
          <hr />
          <div className="d-flex flex-row">
            <i className="fa fa-phone idented align-self-center" />
            <span className="idented-half align-self-center">{user.PhoneNumber}</span>
          </div>
          <div className="d-flex flex-row">
            <i className="fa fa-at idented align-self-center" />
            <span className="idented-half align-self-center">{user.EmailAddress}</span>
          </div>
          <hr style={{ marginBottom: 0 }} />
          <span className="m-auto" style={{ fontSize: 72 }}>#{user.ShirtNumber}</span>
          <div className="d-flex flex-row margin-bottom-half">
            <i className="fa fa-tshirt idented align-self-center" />
            <span className="idented-half align-self-center">Shirt{' '}{user.ShirtSize || 'Unknown'}{' '}Size<br />Short{' '}{user.ShortSize || 'Unknown'}{' '}Size</span>
          </div>
          <hr className="no-margin" />
          <Link style={{ width: 'auto', border: '1px solid #2cad3c', borderRadius: 20 }} onClick={this.props.toggle} to={`/season/${user.IdSeason}/player/${user.IdUser}${user.IdTeamUserRole ? `/${user.IdTeamUserRole}` : ''}`} className="green mx-auto my-2 p-2 align-self-center"> Go to Profile <i className="fa fa-chevron-right" /></Link>          
        </div>
        { /* Avatar */}
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 160 }} className="d-flex flex-row justify-content-center">
          <div className="align-self-center cover" style={{ width: 80, height: 80, borderRadius: 40, border: '1px solid #e5e5e5', background: `url(${user.UserImageUrl}?full=80) no-repeat center center` }} />
        </div>
      </div>)
  }
}

export default Comp;