import React from 'react';

class PantoneMarker extends React.Component {    
    doClick = (marker) => {          
        this.props.onClickMarker && this.props.onClickMarker(marker);
    }
    render() {
        const { clip, factor, markers } = this.props;
        let thumbs = [];
        for (var i = 0; i < clip.seconds; i++)
            thumbs.push({})

        return (
            <div style={{ position: 'relative', width: clip.seconds * factor, borderRadius: 5, border: '2px solid black' }}
                className="d-flex flex-row justify-content-start">
                { thumbs.map((t, i) => <div key={i} style={{ borderRight: i < clip.seconds - 1 ? '1px solid black' : null, width: clip.seconds * factor / 10 }} />
                )}                
                { markers.map((marker, i) =>
                    <span className="icon-VideoCirclePointer font-16" onClick={() => this.doClick(marker)} key={i} style={{ position: 'absolute', left: (marker.offset.x - 8), top: 10, color: marker.color }}  />
                )}
            </div>)
    }
}
export default PantoneMarker;
