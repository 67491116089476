import React from 'react';
import './css.css';

class Privacy extends React.Component {

    state = {
        league: null,
        waiver: null
    }    

    renderUnsigned = () => {        
        return (<section id="disclaimer" style={{ height: '100%' }}>
            <div className="d-flex flex-column">

                <h4 className="blue">Privacy Policy</h4>
                <hr />
                <div>

                    <p>Welcome to The SQWAD. Our privacy notice explains what info The SQWAD collects when
                    you visit or use The SQWAD services, as well as ways we might use or share it. We’re only
                    describing our own practices – this notice doesn’t apply to third parties we don’t own or control,
                    including other companies you might interact with on or through The SQWAD When we say
                    “The SQWAD” (or similar things pke “we” or “us”), we’re referring to The SQWAD services,
                    entity or, where apppcable, entities that control and are responsible for “The SQWAD,” we mean
                    any apps, websites, apppcations, and services that pnk to this notice, regardless of how you
                    access or use them or engage with us, including through mobile devices or offpne such as by
                    phone. Your use of The SQWAD services is also subject to the Terms of Service (including its
                    provisions regarding pmitations on damages, resolution of disputes, and apppcation of governing
                    law). Please read this entire notice to make sure you understand it. You should also read
                    the <b>Terms of Service carefully</b>, and make sure you understand and agree to them before using
The SQWAD services.</p>

                    <p>The SQWAD is also committed to protecting the privacy of children who use our sites and
                    apppcations. We follow (“COPPA”) in our information collection, disclosure, and parental
                    consent practices with respect to information provided by children under the age of 13 (“child”
                    or “children”), and uses terms that are defined in our general Privacy Popcy. This popcy is in
                    accordance with the U.S. Children’s Onpne Privacy Protection Act (“COPPA”), and outpnes the
                    practices we follow regarding children’s personal information. For more information about
                    COPPA and general tips about protecting children’s onpne privacy please visit the (Children’s
Onpne Privacy Protection Act)</p>

                    <hr />
                    <h4>I. THE COLLECTING OF INFORMATION</h4>
                    <hr />
                    <p>We collect info in two main ways:</p>

                    <p><h5>A. Info you provide</h5>: We collect info you choose to provide or give to us in any manner. You don’t have to share info
when we ask you for it, but if you decpne, you might not be able to access certain services of
The SQWAD or take advantage of their full functionapty.</p>

                    <p><h5>B. Info we collect from Third Parties</h5>: Sometimes we obtain info from third parties to use along with the info we collect. For example,
gather advertising and analytics info from third parties to help support our marketing initiatives,
improve The SQWAD services, and better manage our ad campaigns.</p>


                    <hr />
                    <h4>II. HOW WE COLLECT INFO</h4>
                    <hr />

                    <p>In addition to info you provide, we and our partners automatically record some info about how
                    you use and navigate The SQWAD services. We may combine this info with other info we
gather from or about you as described in this notice, including to enforce our <b>Terms of
Service</b> and for analytics and advertising purposes.</p>


                    <p><h5>A. Cookies &amp; Related Technologies</h5><br />
    We use our own servers and technologies pke cookies to automatically collect and store info
about how you interact with The SQWAD services, as well as the device you use to do so.
    </p>

                    <p><h5>B. Analytics &amp; Interest-Based Ads</h5><br />
    We partner with third parties (pke content providers, analytics companies, and advertising
networks) to help us improve The SQWAD services and better understand how you interact with
them, as well as support our marketing initiatives. These companies may collect info from you
automatically in connection with your visit.
    </p>

                    <p><h5>C. Facebook Fan Pages</h5><br />
    The SQWAD has Facebook pages. Facebook collects data from these pages according to its
privacy popcy and provides statistics and insights to The SQWAD services to help us understand
the types of actions people take on our pages (<b>“insights data”</b>).
    </p>

                    <hr />
                    <h4>III. HOW WE USA AND SHARE INFO</h4>
                    <hr />

                    <p>We use and share your info in accordance with the legal bases set out below to help us provide,
operate, improve, understand, customize, support and market The SQWAD services.</p>


                    <p><h5>A. Legal Basis</h5><br />
We use and share your info (i) to provide the services under the Terms, (ii) with your consent,
(iii) in our or others’ legitimate interests (such as safety and security, and providing a tailored
service), and (iv) for other legal reasons.
    </p>

                    <p><h5>B. Using Info</h5><br />
    We use the info we collect (both individually and in combination with other info collected under
this notice) to help us operate our business, provide and improve our products and services,
communicate with you, and advertise effectively. The particular ways we use your info often
depend on how you choose to use The SQWAD services.
    </p>

                    <p><h5>C. Sharing Info</h5><br />
    We don’t share your contact info (such as your email or home address) with independent third
parties without your knowledge except as described in this notice (like to enforce the Terms of
Service, secure The SQWAD services, or if we’re required to do so by law or respond to legal
process). We do share non-contact info (like your jersey number, player stats, images)
If we ever stop providing The SQWAD services or certain parts of the service, we may share
your info with the buyer. Lastly, remember the stuff you share in social features (like chat and
forums) is public -- please be careful about how you use them!
    </p>

                    <p><h5>D. Chat &amp; User Behavior</h5><br />
    We record and store, and reserve the right (but have no obligation) to monitor, chat and similar
details interactions for a period we determine is appropriate to help us, among other things,
detect and prevent toxicity, enforce our rules and policies and Terms of Services, and foster a
more positive community for our users. We engage in these activities to manage our contractual
relationship with you, comply with a legal obligation (e.g. to keep The SQWAD services safe
and secure), and/or because we have a legitimate interest (e.g. understanding how the The
SQWAD services are used, keep them secure and improve them).

We use both manual (like enabling you to report such behaviour through contact Support
process) and automated (such as machine learning) tools and techniques to support these efforts.
To enable us to monitor compliance with our policies, we may develop template patterns of
inappropriate behaviour (e.g. verbal abuse, hate speech, cheating, offensive or inappropriate
name calling), which we consider indicative of prohibited behaviours. We would do this based
upon an analysis of actual behaviours on The SQWAD services. We grade these patterns of
inappropriate behaviour based upon their severity with different consequences (e.g. temporary or
permanent account restrictions or chat restrictions) for users based upon how serious their
conduct is. If another user makes a complaint about your behaviour, our automated tools use
these patterns of inappropriate behaviour and grading system to compare your behaviour over
time with these patterns of inappropriate behaviour and, if you have engaged in inappropriate
behaviour, make a decision as to the consequences for you of your behaviour. This primarily
helps us enforce our rules and policies, secure The SQWAD services, detect and prevent
unauthorized activity, and maintain the competitive integrity of our services.

Our complaints procedure allow you to appeal a decision. In this case, we will provide you a
report showing the thought process and method behind the decision along with some tips on how
to avoid repeating such behaviour in the future. If you are still unhappy, you can request one of
our agents to review your case who will make a final determination as to the correct outcome
manually, based on the available evidence.

Please be kind to other users, observe our policies related to user behavior (including our Terms
of Service), and always be careful what you share in chat!
    </p>



                    <hr />
                    <h4>IV. THIRD-PARTY WEBSITES AND SERVICES</h4>
                    <hr />

                    <p>We allow interactions with third parties to make your experience better, but our notice doesn’t
                    and can’t apply to entities we don’t own, control, or instruct. We’re unable to guarantee that
                    independent third parties adhere to the same practices as us, so please be sure to read their
policies and terms before sharing info with them.</p>

                    <hr />
                    <h4>V. YOUR CHOICES AND CONTROLS</h4>
                    <hr />

                    <p>You have choices regarding your use of The SQWAD services and the ways we process info.
                    Since you can interact with us in many different ways, the particular controls available to you
often depend on the nature of our relationship.</p>


                    <hr />
                    <h4>VI. SECURITY</h4>
                    <hr />
                    <p>We’re committed to securing user info and complying with our obligations towards applicable
                    data protection laws, but can’t absolutely guarantee the security of any data we collect from you.
                    By choosing to share info with us (or anybody else online these days), you’re acknowledging this
risk.</p>


                    <hr />
                    <h4>VII. UPDATES AND REVISIONS</h4>
                    <hr />

                    <p>We’ll make changes to this policy over time to keep you better informed about how we handle
player info.</p>

                    <hr />
                    <h4>VIII. CONTACT US</h4>
                    <hr />

                    <p>For user-related requests or concerns (e.g., trouble accessing your account, billing matters,
                    reporting bugs or similar technical issues, discussing a ban, deleting an account, reporting a user,
etc.), please contact our user Support team at info@thesqwad.com</p>
                </div>
            </div>
        </section>)
    }

    render() {
        return this.renderUnsigned();
    }
}

export default Privacy;
