import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';

class RosterPlayer extends React.Component {
  state = { user: null }

  componentWillMount() {
    this.setState({ user: this.props.user });
  }

  imageError = () => {
    const { user } = this.state;
    user.Image = '/images/defaults/' + (user.IdGender === 1 ? 'male.png' : 'female.png');
    this.setState({ user });
  }
  render() {
    const { user } = this.state, { season } = this.props;
    return <tr>
      <td className="font-10 pt-4 pb-4 ">
        {user.IdUser}
      </td>
      <td className="">
        <div className="d-flex flex-row h-100">
          <img alt="" src={user.Image} className="align-self-center smallPic align-self-center" onError={this.imageError} />
          <Link to={`/season/${season.IdSeason}/player/${user.IdUser}/${user.IdTeamUserRole}`} className="align-self-center ml-2 font-12 blue">{[user.NameLast, user.NameFirst].join(', ')}</Link>          
        </div>
      </td>
      <td className="font-10">{user.JerseyNumber}</td>
      <td className="font-10 text-center">
        <span className="align-self-center">{user.Gender.substring(0, 1)}</span>
      </td>
      <td className="text-center">
        {moment(user.DateBirth, 'MM/DD/YYYY').format('MMM Do YYYY')}
      </td>
      <td className={`${user.IsValid ? 'green' : 'red'} text-center`}>
        {user.IsValid ? 'Yes' : 'No'}
      </td>
      {user.IdLeague === 11826 && <td className={`${user.IdWaiver ? 'green' : 'red'} text-center`}>
        {user.IdWaiver ? 'Yes' : 'No'}
      </td>}
    </tr >
  }
}

export default RosterPlayer;