import React from 'react';
import { Animated } from 'react-animated-css';
import Moment from 'react-moment';

class Campaign extends React.Component {
    render() {
        const { campaign } = this.props;        
        return <section style={{ width: 233, overflow: 'hidden', opacity: campaign.IdMarketingCampaign ? 1 : 0.4 }} className="card shadow mx-2">
            <div className="white d-flex flex-row bg-success">
                <strong className="w-100 text-center align-self-center">Ad Campaign</strong>
                <a target="_blank" rel="noopener noreferrer" href={`${campaign.ContentLink}`} className="font-14 text-center ml-auto mr-2 white align-self-center"><i className="fa fa-link"></i></a>
            </div>
            <div className="cover mt-4" style={{ background: `url('/images/pictures/phone.jpeg') no-repeat center center`, height: 450, position: 'relative' }}>
                {campaign && <div className="" style={{ backgroundColor: '#000000CC', position: 'absolute', top: 53, left: 17, right: 17, bottom: 61, overflow: 'hidden' }}>
                    {campaign.Position === 1 && <Animated className="card contain" animationIn="fadeInLeft" style={{ position: 'absolute', top: 5, left: 5, right: 5, height: 35, background: `url('${campaign.ContentImage}') no-repeat center center ${!campaign.ContentImage ? 'white' : ''}` }} />}
                    {(campaign.Position === 2 || !campaign.Position) && <Animated className="card contain" animationIn="fadeInLeft" style={{ position: 'absolute', top: 5, bottom: 5, left: 5, right: 5, background: `url('${campaign.ContentImage}') no-repeat center center ${!campaign.ContentImage ? 'white' : ''}` }} />}
                    {campaign.Position === 3 && <Animated className="card contain" animationIn="fadeInLeft" style={{ position: 'absolute', bottom: 5, left: 5, right: 5, height: 35, background: `url('${campaign.ContentImage}') no-repeat center center ${!campaign.ContentImage ? 'white' : ''}` }} />}
                </div>}
            </div>
            <div className="d-flex flex-column p-2">
                <span className="toBold black font-10">Name</span>
                <span className="black font-10 line1">
                    {campaign.MarketingCampaignName}
                </span>
                <span className="toBold black font-10 mt-2">Date</span>
                <span className="black font-10 line1">
                    <Moment format="MMM DD, YYYY">
                        {campaign.CampaignStart}
                    </Moment>
                    <span className="font-10 align-self-center ml-2 mr-2">to</span>
                    <Moment format="MMM DD, YYYY">
                        {campaign.CampaignEnd}
                    </Moment>
                </span>
            </div>

             {/* TOTALS */}
             <div className="p-2 d-flex flex-row">
              <div className="w-100 bg-gray d-flex flex-column justify-content-center black p-2" style={{ borderRadius: 5 }}>
                <span className="font-10 align-self-center line1">VIEWS</span>
                <span className="font-20 align-self-center line1">{0}</span>
              </div>
              <div style={{ width: 15 }} />
              <div className="w-100 bg-gray d-flex flex-column justify-content-center black p-2" style={{ borderRadius: 5 }}>
                <span className="font-10 align-self-center line1">CLICKS</span>
                <span className="font-20 align-self-center line1">{0}</span>
              </div>
            </div>
        </section>
    }
}

export default Campaign;