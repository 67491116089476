import _ from 'lodash';

function reducer(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'PAYMENT_PLANS/SET':
            return action.plans;
        case 'PAYMENT_PLANS/CLEAR':
            return null;
        case 'PROGRAM/UPDATE':

            // Remove the division from all the programs
            state.forEach(program => {
                if (program.ForTeams === action.program.ForTeams)
                    program.divisions = _.reject(program.divisions, (d) => { return d.IdDivision === action.division.IdDivision; });
            });

            // Add the division to the new program
            action.program.divisions.push(action.division);

            // Update the store
            index = _.findIndex(state, program => { return program.IdCatalogItem === action.program.IdCatalogItem });
            return [...state.slice(0, index), action.program, ...state.slice(index + 1)];

        case 'DIVISION/REMOVE_PROGRAM':
            action.program.divisions = _.reject(action.program.divisions, (d) => { return d.IdDivision === action.division.IdDivision });
            // Update the store
            index = _.findIndex(state, program => { return program.IdCatalogItem === action.program.IdCatalogItem });
            return [...state.slice(0, index), action.program, ...state.slice(index + 1)];
        default:
            return state;
    }
}

export default reducer;