function reducer(state = [], action) {

    switch (action.type) {
        case 'PROCESSES/SET':            
            return action.processes;
        default:
            return state;
    }
}

export default reducer;