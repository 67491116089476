import React from 'react';
import Layout from '../layouts/base.dark';
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import BlackOverlay from '../partials/black_overlay';
import { Animated } from 'react-animated-css';
import fakeAuth from '../../fakeAuth';
import request from 'superagent';
import config from '../../config';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { validateEmail } from '../../helpers';
import _ from 'lodash';

import './crap.css';
import '../externals/signature-pad.css';
let SignaturePad = require('react-signature-pad');

class CreateAccount extends React.Component {

    state = {
        redirectToReferrer: false,
        mode: 1,
        invalid: false,
        selectedBg: null,
        message: null,
        email: null,
        toggle: true,
        code: '',
        obj: {},
        selected: {
            country: null,
            state: null,
            CountryCode: _.first(config.country_codes)
        }
    }

    componentWillMount() {
        this.props.geoActions && this.props.geoActions.fetchCountries();
        this.props.geoActions && this.props.geoActions.fetchCountryCodes();
        this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(1);
        if (this.props.params && this.props.params.idLeague)
            localStorage.setItem('sportslogic.referral.league', this.props.params.idLeague);
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.country_codes) this.setState({ country_codes: nextProps.country_codes }, () => {
            if (this.state.country_codes && this.state.country_codes.northAmericanCountries && !this.state.selected.country_code) {
                const { selected } = this.state;

                selected.country_code = localStorage.getItem('sportslogic.referral.country_code') ?
                    (_.find(_.chain(this.state.country_codes).values().flatten().value(), c => c.countryCode === localStorage.getItem('sportslogic.referral.country_code')) ||
                        _.first(this.state.country_codes.northAmericanCountries)) : //selected.country_code.countryCode
                    _.first(this.state.country_codes.northAmericanCountries);

                selected.mask = _.chain(selected.country_code.phoneNumberMask)
                    .map(a => {
                        switch (a) {
                            case '/[2-9]/': return /[2-9]/;
                            case '/[1-9]/': return /[1-9]/;
                            case '/[0-9]/': return /[0-9]/;
                            case '/[5-9]/': return /[5-9]/;
                            case '/[2-5]/': return /[2-5]/;
                            case '/[2-6]/': return /[2-6]/;
                            case '/\d/': return /\d/;
                            default: return a;
                        }
                    }).value();

                this.setState({ selected, bootstrapped: true });
            }
        });
        if (nextProps.countries && nextProps.countries.length && !this.state.selected.country) {
            this.selectCountry(
                _.find(nextProps.countries, c => {
                    if (localStorage.getItem('sportslogic.referral.country_code')) {
                        switch (localStorage.getItem('sportslogic.referral.country_code')) {
                            case '+44': return c.ISOCode === 'UK'; break;
                            default: return c.ISOCode === 'US'; break;
                        }
                    } else return c.ISOCode === 'US';
                })
            );
        }
    }
    componentWillUnmount() {
        if (this.props.params && this.props.params.idLeague)
            localStorage.removeItem('sportslogic.referral.league');
    }

    back = () => this.setState({ toggle: false }, () => {
        setTimeout(() => {
            this.setState({
                mode: 1,
                microTransac: false,
                users: null,
            }, () => {
                this.setState({ toggle: true });
            });
        }, 400)
    });

    onCountryCode = (c) => {
        const { selected } = this.state;
        selected.CountryCode = c;
        this.setState({ selected });
    }

    onGender = i => {
        const { obj } = this.state;
        obj.idGender = i;
        this.setState({ obj });
    }

    onStep1 = (e) => {
        e && e.preventDefault && e.preventDefault();

        this.setState({ invalid: false }, () => {
            const { obj } = this.state;
            obj.email = this.txtEmail.value;
            obj.phoneNumber = (ReactDOM.findDOMNode(this.txtPhone).value);
            obj.phoneWithMask = this.txtPhone.inputElement.value;
            obj.password = this.txtPassword.value;

            if (!validateEmail(obj.email)) {
                this.setState({ invalid: true, message: 'Invalid email, please check' });
                return;
            }

            if (obj.email && obj.phoneNumber && obj.password) {
                this.setState({ microTransac: true }, () => {
                    request.post(`${config.apiEndpoint}/api/v4/registration/checkEmail`)
                        .send({ email: this.txtEmail.value })
                        .then((data) => {
                            if (!data.body.success) {
                                this.setState({ toggle: false }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            microTransac: false,
                                            toggle: true,
                                            mode: 2,
                                            obj
                                        });
                                    }, 400)
                                });
                            } else {
                                this.setState({ invalid: true, message: 'Email address is unavailable. Perhaps you already have an account?', microTransac: false });
                            }
                        });
                });
            } else {
                alert('Please, complete the required fields');
            }
        });
    }

    onStep2 = (e) => {
        e && e.preventDefault && e.preventDefault();

        this.setState({ invalid_date: false }, () => {
            if (moment(this.txtDob.inputElement.value, 'MM/DD/YYYY').isValid()) {

                const { obj } = this.state;
                obj.firstName = this.txtNameFirst.value;
                obj.lastName = this.txtNameLast.value;
                obj.dob = moment(this.txtDob.inputElement.value, 'MM/DD/YYYY').utc().format();
                if (obj.firstName && obj.lastName && obj.idGender && obj.dob) {
                    this.setState({ toggle: false }, () => {
                        setTimeout(() => {
                            this.setState({
                                microTransac: false,
                                toggle: true,
                                mode: 3,
                                obj
                            });
                        }, 400);
                    });
                } else {
                    alert('Please complete the missing fields');
                }
            }
            else this.setState({ invalid_date: true });
        });
    }

    onStep3 = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { obj } = this.state;
        obj.address = this.txtAddress.value;
        obj.idState = (this.state.selected.state || {}).IdState;
        obj.city = this.txtCity.value;
        obj.zip = this.txtZip.value;
        this.setState({ obj }, () => {
            this.onTerms();
        });
    }

    onCountry = (c) => {
        const { selected } = this.state;
        selected.country = c;
        this.props.geoActions && this.props.geoActions.clearStates && this.props.geoActions.clearStates();
        this.props.geoActions && this.props.geoActions.fetchStates && this.props.geoActions.fetchStates(c.IdCountry);
        this.setState({ selected });
    }

    selectCountry = (c) => {
        if (!c) return;
        const { selected } = this.state;
        selected.country = c;
        this.props.geoActions && this.props.geoActions.clearStates && this.props.geoActions.clearStates();
        this.props.geoActions && this.props.geoActions.fetchStates && this.props.geoActions.fetchStates(c.IdCountry);
        this.setState({ selected });
    }

    onState = (s) => {
        const { selected } = this.state;
        selected.state = s;
        this.setState({ selected });
    }

    onTerms = () => this.setState({ termsModal: !this.state.termsModal });

    signatureEnd = () => {
        var signature = this.refs.mySignature;
        this.setState({
            signed: true,
            signatureUrl: signature.toDataURL()
        });
    }

    clearSignature = () => {
        var signature = this.refs.mySignature;
        signature.clear();
        this.setState({
            signed: false,
            signatureUrl: null
        });
    }

    onCreateAccount = () => {

        this.setState({ step: 2 });
        const { obj, selected } = this.state, { email, password, firstName,
            lastName, idGender, dob, idState = 4708,
            city, address, zip, phoneNumber, phoneWithMask } = obj;


        request.post(`${config.apiEndpoint}/api/v4/membership/create`)
            .send({
                email,
                password,
                firstName,
                lastName,
                idGender,
                dob,
                idState,
                city,
                address,
                zip,
                phoneNumber,
                phoneWithMask,
                signature: this.state.signatureUrl,
                idReferralLeague: localStorage.getItem('sportslogic.referral.league'),
                country_code: selected.country_code.countryCode,
                CountryCode: selected.country_code.countryCode,
            })
            .then((data) => {
                if (data.body.success) {

                    const user = _.first(data.body.family);
                    this.setState({ toggle: false }, () => {
                        fakeAuth.authenticate(() => {
                            this.props.login && this.props.login({ token: user.Token, isAdmin: false });
                            if (localStorage.getItem('sportslogic.referral.league')) {
                                let referal_league = localStorage.getItem('sportslogic.referral.league');
                                localStorage.removeItem('sportslogic.referral.league');
                                this.props.router.push(`/registration/${referal_league}`);
                            } else {
                                this.props.router.push('/family');
                            }

                        }, user.Token,
                            user.IsLeagueAdmin, user)
                    });

                } else {
                    this.setState({
                        step: 3,
                        message: (data.body.error && data.body.error.message) ? _.last(data.body.error.message.split(':')) : null
                    });
                }
            }, reject => {
                this.setState({ step: 3 });
            });
    }

    selectCountryCode = (c) => {
        const { selected } = this.state;
        selected.CountryCode = c;
        this.setState({ selected });
    }

    onChange = () => { this.forceUpdate(); }

    render() {
        const { mode, users, microTransac, email, toggle, code, selected, obj, step = 1, country_codes, invalid, message, bootstrapped } = this.state;

        const { countries, states } = this.props;

        // To set US and Canada first on the Countries selection
        const splitCountries = _.partition(countries || [], c => {
            return c.ISOCode === 'US' || c.ISOCode === 'CA' || c.ISOCode === 'UK';
        });

        return (
            <Layout className="bg-black">
                <section>
                    <section style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        <div className="cover h-100 w-100 d-flex justify-content-end" style={{ position: 'relative', background: `url(images/backgrounds/3balls.jpg) no-repeat center center` }}>

                            <BlackOverlay />

                            {/* CREDENTIALS */}
                            {mode === 1 && bootstrapped && <Animated isVisible={(toggle && mode === 1) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                                animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 800, zIndex: 1, borderRadius: 6 }}
                                className="bg-white align-self-center d-flex px-4">

                                <div className="w-100 text-center w3-hide-small">
                                    <img src="/images/backgrounds/screenshots/app.png" className="w-75" />
                                </div>

                                <div className="w-100 d-flex flex-row px-4">
                                    <form key="login-form" className='align-self-center w-100'>
                                        <h1 className="sqwad-font black text-center mt-4">CREDENTIALS</h1>

                                        <div className="w-100 mt-4 card tile" data-validate="Type user name">
                                            <input className="w-100 p-2" defaultValue={email} type="text" name="email" placeholder="Email" ref={(input) => this.txtEmail = input} />
                                        </div>

                                        { /* Phone */}
                                        {country_codes && country_codes.northAmericanCountries && <div className={`d-flex mt-2`}>

                                            <Dropdown isOpen={this.state.isCountryCodeOpen ? true : false} toggle={() => this.setState({ isCountryCodeOpen: !this.state.isCountryCodeOpen })} className=" p-0 card tile" style={{ width: 200 }}>
                                                {/*<DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="h-100 w-100 text-left d-flex">
                                                <img src={selected.CountryCode ? selected.CountryCode.image : ''} alt="" className="align-self-center mr-1" style={{ height: 20 }} />
                                                <span className="black align-self-center font-12">{selected.CountryCode ? selected.CountryCode.value : 'Code'}</span>
        </DropdownToggle>*/}
                                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="h-100 w-100 text-left d-flex">
                                                    <img src={selected.country_code ? `https://api.sportslogic.net/images/flags/${selected.country_code.countryISOCode}.png` : ''} alt="" className="align-self-center mr-1" style={{ height: 20 }} />
                                                    <span className="black align-self-center font-12">{selected.country_code ? selected.country_code.countryCode : 'Code'}</span>
                                                    <i className="ml-auto fas fa-caret-down align-self-center black font-16" />
                                                </DropdownToggle>
                                                <DropdownMenu>

                                                    <DropdownItem header>North America</DropdownItem>
                                                    <DropdownItem divider />
                                                    {country_codes.northAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                        const { selected } = this.state;
                                                        selected.country_code = c;
                                                        selected.mask = _.chain(c.phoneNumberMask)
                                                            .map(a => {
                                                                switch (a) {
                                                                    case '/[2-9]/': return /[2-9]/;
                                                                    case '/[1-9]/': return /[1-9]/;
                                                                    case '/[0-9]/': return /[0-9]/;
                                                                    case '/[5-9]/': return /[5-9]/;
                                                                    case '/[2-5]/': return /[2-5]/;
                                                                    case '/[2-6]/': return /[2-6]/;
                                                                    case '/\d/': return /\d/;
                                                                    default: return a;
                                                                }
                                                            }).value();
                                                        this.setState({ selected });
                                                    }}>
                                                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                    </DropdownItem>)}

                                                    <DropdownItem header>South America</DropdownItem>
                                                    <DropdownItem divider />
                                                    {country_codes.southAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                        const { selected } = this.state;
                                                        selected.country_code = c;
                                                        selected.mask = _.chain(c.phoneNumberMask)
                                                            .map(a => {
                                                                switch (a) {
                                                                    case '/[2-9]/': return /[2-9]/;
                                                                    case '/[1-9]/': return /[1-9]/;
                                                                    case '/[0-9]/': return /[0-9]/;
                                                                    case '/[5-9]/': return /[5-9]/;
                                                                    case '/[2-5]/': return /[2-5]/;
                                                                    case '/[2-6]/': return /[2-6]/;
                                                                    case '/\d/': return /\d/;
                                                                    default: return a;
                                                                }
                                                            }).value();
                                                        this.setState({ selected });
                                                    }}>
                                                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                    </DropdownItem>)}

                                                    <DropdownItem header>America Central</DropdownItem>
                                                    <DropdownItem divider />
                                                    {country_codes.centralAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                        const { selected } = this.state;
                                                        selected.country_code = c;
                                                        selected.mask = _.chain(c.phoneNumberMask)
                                                            .map(a => {
                                                                switch (a) {
                                                                    case '/[2-9]/': return /[2-9]/;
                                                                    case '/[1-9]/': return /[1-9]/;
                                                                    case '/[0-9]/': return /[0-9]/;
                                                                    case '/[5-9]/': return /[5-9]/;
                                                                    case '/[2-5]/': return /[2-5]/;
                                                                    case '/[2-6]/': return /[2-6]/;
                                                                    case '/\d/': return /\d/;
                                                                    default: return a;
                                                                }
                                                            }).value();
                                                        this.setState({ selected });
                                                    }}>
                                                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                    </DropdownItem>)}

                                                    <DropdownItem header>Europe</DropdownItem>
                                                    <DropdownItem divider />
                                                    {country_codes.europeanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                        const { selected } = this.state;
                                                        selected.country_code = c;
                                                        selected.mask = _.chain(c.phoneNumberMask)
                                                            .map(a => {
                                                                switch (a) {
                                                                    case '/[2-9]/': return /[2-9]/;
                                                                    case '/[1-9]/': return /[1-9]/;
                                                                    case '/[0-9]/': return /[0-9]/;
                                                                    case '/[5-9]/': return /[5-9]/;
                                                                    case '/[2-5]/': return /[2-5]/;
                                                                    case '/[2-6]/': return /[2-6]/;
                                                                    case '/\d/': return /\d/;
                                                                    default: return a;
                                                                }
                                                            }).value();
                                                        this.setState({ selected });
                                                    }}>
                                                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                    </DropdownItem>)}

                                                    <DropdownItem header>Africa</DropdownItem>
                                                    <DropdownItem divider />
                                                    {country_codes.africanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                        const { selected } = this.state;
                                                        selected.country_code = c;
                                                        selected.mask = _.chain(c.phoneNumberMask)
                                                            .map(a => {
                                                                switch (a) {
                                                                    case '/[2-9]/': return /[2-9]/;
                                                                    case '/[1-9]/': return /[1-9]/;
                                                                    case '/[0-9]/': return /[0-9]/;
                                                                    case '/[5-9]/': return /[5-9]/;
                                                                    case '/[2-5]/': return /[2-5]/;
                                                                    case '/[2-6]/': return /[2-6]/;
                                                                    case '/\d/': return /\d/;
                                                                    default: return a;
                                                                }
                                                            }).value();
                                                        this.setState({ selected });
                                                    }}>
                                                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                    </DropdownItem>)}

                                                    <DropdownItem header>Asia</DropdownItem>
                                                    <DropdownItem divider />
                                                    {country_codes.asianCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                        const { selected } = this.state;
                                                        selected.country_code = c;
                                                        selected.mask = _.chain(c.phoneNumberMask)
                                                            .map(a => {
                                                                switch (a) {
                                                                    case '/[2-9]/': return /[2-9]/;
                                                                    case '/[1-9]/': return /[1-9]/;
                                                                    case '/[0-9]/': return /[0-9]/;
                                                                    case '/[5-9]/': return /[5-9]/;
                                                                    case '/[2-5]/': return /[2-5]/;
                                                                    case '/[2-6]/': return /[2-6]/;
                                                                    case '/\d/': return /\d/;
                                                                    default: return a;
                                                                }
                                                            }).value();
                                                        this.setState({ selected });
                                                    }}>
                                                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                    </DropdownItem>)}

                                                    <DropdownItem header>Oceania</DropdownItem>
                                                    <DropdownItem divider />
                                                    {country_codes.oceaniaCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                                                        const { selected } = this.state;
                                                        selected.country_code = c;
                                                        selected.mask = _.chain(c.phoneNumberMask)
                                                            .map(a => {
                                                                switch (a) {
                                                                    case '/[2-9]/': return /[2-9]/;
                                                                    case '/[1-9]/': return /[1-9]/;
                                                                    case '/[0-9]/': return /[0-9]/;
                                                                    case '/[5-9]/': return /[5-9]/;
                                                                    case '/[2-5]/': return /[2-5]/;
                                                                    case '/[2-6]/': return /[2-6]/;
                                                                    case '/\d/': return /\d/;
                                                                    default: return a;
                                                                }
                                                            }).value();
                                                        this.setState({ selected });
                                                    }}>
                                                        <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                                                    </DropdownItem>)}

                                                </DropdownMenu>
                                            </Dropdown>
                                            <div className="card tile w-100 ml-2">
                                                {selected.country_code && <MaskedInput guide={true} ref={(i) => this.txtPhone = i} className="p-2 font-12 w-100" placeholder="Phone Number (*)" defaultValue={obj.phoneNumber}
                                                    mask={selected.mask} />}
                                            </div>
                                        </div>}

                                        <div className="w-100 mt-2 card tile" data-validate="Password">
                                            <input className="w-100 p-2" type="password" name="pass" placeholder="Password" ref={(input) => this.txtPassword = input} />
                                        </div>

                                        <button type="submit" className="btn btn-info w-100 mt-4" onClick={this.onStep1}>
                                            {!microTransac && <span>Continue</span>}
                                            {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                        </button>
                                        { /* Error Message */}
                                        {invalid && <div className="d-flex flex-column p-2 bg-danger bd-highlight" style={{ width: '100%' }}>
                                            <span className="white align-self-center text-center mx-2 font-8">{message || 'Invalid Email or Password'}</span>
                                        </div>}
                                        <Link to="/login" className="btn btn-link w-100 text-center mt-4">
                                            <span className="txt1" style={{ marginRight: '5px' }}>I already have an</span>
                                            <span className="txt2 underlined jeff_blue">account</span>
                                        </Link>


                                        <div style={{ height: 50 }} />
                                    </form>
                                </div>

                            </Animated>}

                            {/* PERSONAL INFO */}
                            {mode === 2 && <Animated isVisible={(toggle && mode === 2) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                                animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 800, zIndex: 1, borderRadius: 6 }}
                                className="bg-white align-self-center d-flex px-4">

                                <div className="w-100 text-center w3-hide-small">
                                    <img src="/images/backgrounds/screenshots/rc.png" className="w-75" />
                                </div>

                                <div className="w-100 d-flex flex-row px-4">
                                    <form key="login-form" className='align-self-center w-100'>
                                        <h1 className="sqwad-font black text-center mt-4 line1">PERSONAL INFORMATION</h1>

                                        <div className="w-100 mt-4 card tile">
                                            <input className="w-100 p-2" type="text" name="firstname" placeholder="First name" ref={(input) => this.txtNameFirst = input} />
                                        </div>

                                        <div className="w-100 mt-2 card tile">
                                            <input className="w-100 p-2" type="text" name="lastname" placeholder="Last name" ref={(input) => this.txtNameLast = input} />
                                        </div>

                                        <div className="card tile mt-2 bordered w-100">
                                            <div className="w-100 text-center">
                                                <div className="d-flex">
                                                    <div className="w-100 text-center d-flex flex-column p-2" onClick={() => this.onGender(1)}>

                                                        <div style={{ width: 50, height: 50, border: `2px solid ${obj.idGender === 1 ? '#013469' : 'gray'}`, borderRadius: 25, overflow: 'hidden', backgroundColor: obj.idGender === 1 ? '#013469' : 'transparent' }} className="text-center m-auto d-flex flex-column justify-content-end">
                                                            <span className={`icon-man icon-men ${obj.idGender === 1 ? 'white' : 'gray'} font-40`}>
                                                                <span className="path1" />
                                                                <span className="path2" />
                                                                <span className="path3" />
                                                                <span className="path4" />
                                                                <span className="path5" />
                                                                <span className="path6" />
                                                                <span className="path7" />
                                                                <span className="path8" />
                                                                <span className="path9" />
                                                                <span className="path10" />
                                                                <span className="path11" />
                                                                <span className="path12" />
                                                                <span className="path13" />
                                                                <span className="path14" />
                                                                <span className="path15" />
                                                                <span className="path16" />
                                                                <span className="path17" />
                                                            </span>
                                                        </div>
                                                        <span className="font-10 black toUpper sqwad-font">Male</span>

                                                    </div>
                                                    <div className="w-100 text-center d-flex flex-column p-2" onClick={() => this.onGender(2)}>
                                                        <div style={{
                                                            width: 50, height: 50, border: `2px solid ${obj.idGender === 2 ? '#013469' : 'gray'}`, borderRadius: 25, overflow: 'hidden',
                                                            backgroundColor: obj.idGender === 2 ? '#013469' : 'transparent'
                                                        }} className="text-center m-auto d-flex flex-column justify-content-end">
                                                            <span className={`icon-woman ${obj.idGender === 2 ? 'white' : 'gray'} font-40`}>
                                                                <span className="path1" />
                                                                <span className="path2" />
                                                                <span className="path3" />
                                                                <span className="path4" />
                                                                <span className="path5" />
                                                                <span className="path6" />
                                                                <span className="path7" />
                                                                <span className="path8" />
                                                                <span className="path9" />
                                                                <span className="path10" />
                                                                <span className="path11" />
                                                                <span className="path12" />
                                                                <span className="path13" />
                                                                <span className="path14" />
                                                                <span className="path15" />
                                                            </span>
                                                        </div>
                                                        <span className="font-10 black toUpper sqwad-font">Female</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        { /* DoB */}

                                        {/*<div className={`d-flex flex-row`}>
                      <span className="font-10 black align-self-center">DATE OF BIRTH (MM/DD/YYYY)</span>
                      <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
          </div>*/}
                                        <div className={`${this.state.invalid_date ? 'bg-danger' : ''} card tile mt-2 `}>
                                            <MaskedInput placeholder="DOB (MM/DD/YYYY)" id="txtDoB" ref={(i) => this.txtDob = i} guide={true} className="p-2 font-12" mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />
                                        </div>
                                        <div className={`d-flex flex-row`}>
                                            <span className="font-10 align-self-center">(MM/DD/YYYY) {this.state.invalid_date && <i className="red">INVALID DATE. PLEASE FOLLOW THE FORMAT MM/DD/YYYY</i>}</span>
                                        </div>


                                        <button type="submit" className="btn btn-info w-100 mt-4" onClick={this.onStep2}>
                                            {!microTransac && <span>Continue</span>}
                                            {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                        </button>
                                        <Link to="/login" className="btn btn-link w-100 text-center mt-4">
                                            <span className="txt1" style={{ marginRight: '5px' }}>I already have an</span>
                                            <span className="txt2 underlined jeff_blue">account</span>
                                        </Link>


                                        <div style={{ height: 50 }} />
                                    </form>
                                </div>

                            </Animated>}

                            {/* CONTACT */}
                            {mode === 3 && <Animated isVisible={(toggle && mode === 3) ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight"
                                animationOutDuration={400} animationInDelay={400} animationInDuration={400} style={{ width: 800, zIndex: 1, borderRadius: 6 }}
                                className="bg-white align-self-center d-flex px-4">

                                <div className="w-100 text-center w3-hide-small">
                                    <img src="/images/backgrounds/screenshots/rc.png" className="w-75" />
                                </div>

                                <div className="w-100 d-flex flex-row px-4">
                                    <form key="login-form" className='align-self-center w-100'>
                                        <h1 className="sqwad-font black text-center mt-4 line1">CONTACT INFORMATION</h1>

                                        <div className="w-100 mt-4 card tile">
                                            <input className="w-100 p-2" onChange={this.onChange} defaultValue={obj.address} type="text" name="address" placeholder="Address" ref={(input) => this.txtAddress = input} />
                                        </div>
                                        <div className="w-100 mt-2 card tile">
                                            <Dropdown isOpen={this.state.isCountryOpen ? true : false} toggle={() => this.setState({ isCountryOpen: !this.state.isCountryOpen })} className=" card tile w-100">
                                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="h-100 w-100 text-left d-flex p-2">
                                                    <span className="black">{selected.country ? selected.country.CountryName : 'Country'}</span>
                                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                                </DropdownToggle>
                                                <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                    {splitCountries.length >= 1 && splitCountries[0].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.onCountry(c)}>
                                                        {c.CountryName || c.Name}
                                                    </DropdownItem>)}
                                                    <DropdownItem divider />
                                                    {splitCountries.length > 1 && splitCountries[1].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.onCountry(c)}>
                                                        {c.CountryName || c.Name}
                                                    </DropdownItem>)}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                        {selected.country !== null && <div className="w-100 mt-2 card tile">
                                            <Dropdown isOpen={this.state.isStateOpen ? true : false} toggle={() => this.setState({ isStateOpen: !this.state.isStateOpen })} className=" card tile w-100">
                                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="h-100 w-100 text-left d-flex p-2">
                                                    <span className="black">{selected.state ? selected.state.StateName : 'State'}</span>
                                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                                </DropdownToggle>
                                                <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                    {states && states.length >= 1 && states.map((s) => <DropdownItem key={s.IdState} onClick={() => this.onState(s)}>
                                                        {s.StateName}
                                                        {' ('}
                                                        {s.StateCode}
                                                        {') '}</DropdownItem>)}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>}
                                        <div className="w-100 mt-2 card tile">
                                            <input className="w-100 p-2" defaultValue={obj.address} type="text" name="address" placeholder="City" ref={(input) => this.txtCity = input} />
                                        </div>
                                        <div className="w-100 mt-2 card tile">
                                            <input className="w-100 p-2" defaultValue={obj.address} type="text" name="address" placeholder="Postal Code" ref={(input) => this.txtZip = input} />
                                        </div>

                                        <button type="submit" className="btn btn-info w-100 mt-4" onClick={this.onStep3}>
                                            {!microTransac && <span>{((this.txtAddress && this.txtAddress.value) ||
                                                (selected.state) ||
                                                (this.txtCity && this.txtCity.value) ||
                                                (this.txtZip && this.txtZip.value)) ? 'Continue' : 'Skip'}</span>}
                                            {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                                        </button>
                                        <button type="button" className="btn btn-link w-100 mt-4">
                                            <span>Back</span>
                                        </button>

                                        <div style={{ height: 50 }} />
                                    </form>
                                </div>

                            </Animated>}

                            <span className="sqwad-font white font-14 w3-hide-small" style={{ position: 'absolute', top: 20, left: 20 }}>THE SQWAD</span>

                            <Animated animationInDelay={500} animationInDuration={300} animationIn="fadeInLeft" isVisible={true} animateOnMount={true} className="d-flex flex-row justify-content-center" style={{ position: 'absolute', bottom: 20, left: 20 }}>
                                <a className="align-self-center" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/the-sqwad/id1506954207"><img src="/images/icons/appstore.png" alt="" style={{ height: 30 }} /></a>

                                {/*<img alt="" className='ml-2 align-self-center' style={{ height: 70, width: 70 }} src="https://api.sportslogic.net/api/v5/qrcodes/url/10" />*/}

                                <a className="ml-2 align-self-center" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.trycatch.squad.app"><img src="/images/icons/playstore.png" alt="" style={{ height: 30 }} /></a>
                            </Animated>
                        </div>
                    </section>

                    { /* Modal for Terms and Conditions */}
                    <Modal isOpen={this.state.termsModal ? true : false}>
                        {step === 1 && <ModalHeader className="p-0 m-0">
                            <div className="d-flex flex-row w-100 px-2 m-0" style={{ height: 50 }}>
                                <span className="align-self-center sqwad-font font-14">TERMS AND CONDITIONS</span>
                                <div onClick={() => this.setState({ termsModal: false })} style={{ width: 40, height: 40, borderRadius: 20, }} className="ml-auto bg-gray d-flex flex-column justify-content-center align-self-center">
                                    <i className="fas fa-times black font-20 align-self-center" />
                                </div>
                            </div>
                        </ModalHeader>}
                        {step === 1 && <ModalBody style={{ overflowY: 'auto', height: 300 }}>
                            <div className="">
                                {this.props.disclaimer && <div id="terml-and-conditions" className="black p-2 m-auto font-8" dangerouslySetInnerHTML={{ __html: this.props.disclaimer ? (this.props.disclaimer.sportslogic || this.props.disclaimer.sportslogic) : null }}></div>}
                            </div>
                        </ModalBody>}
                        {step === 1 && <ModalBody className="bordered">
                            <h3 className="toUpper sqwad-font font-14 px-2">Sign Here please</h3>
                            <SignaturePad onEnd={this.signatureEnd} ref="mySignature" className='w-100' />
                        </ModalBody>}
                        {step === 1 && <ModalFooter>
                            <button className="btn btn-link" onClick={this.clearSignature}>Clear Signature</button>
                            {' '}
                            {this.state.signed && <button className="btn btn-success" onClick={this.onCreateAccount}>Create Account
                                {' '}<i className="fa fa-arrow-right m-l-7" /></button>}
                        </ModalFooter>}
                        {step === 2 && <ModalBody>
                            <div className="jumbotron no-margin text-center p-4" >
                                <i className="fa fa-users bigest-font heart" />
                                <h3 className="mt-2 toUpper sqwad-font">Creating account, please wait...</h3>
                            </div>
                        </ModalBody>}
                        {step === 3 && <ModalBody>
                            <div onClick={this.onTerms} className="jumbotron no-margin text-center bg-danger p-4" >
                                <i className="fa fa-times white bigest-font" />
                                <h1 className="white">Registration failed</h1>
                                <h3 className="white">{this.state.message}</h3>
                            </div>
                        </ModalBody>}
                    </Modal>
                </section>
            </Layout>
        )
    }
}

export default CreateAccount;
