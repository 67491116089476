import React from 'react';
import { generateRandomId } from '../../../../../../helpers';

class Comp extends React.Component {

  state = {
    randomId: generateRandomId(10)
  }

  handleChange = (e) => {
    const { code, index } = this.props;
    code.code = e.target.value;
    this.props.paymentsActions && this.props.paymentsActions.updateCode(code, index);
  }

  render() {
    const { code, } = this.props;
    return <div className="col-12 d-flex flex-row margin-bottom-half" key={this.state.randomId}>
      <i className="fas fa-map-marked-alt blue align-self-center font-18 mr-2" /><input onChange={this.handleChange} defaultValue={code.code} className="form-control align-self-center" type="text" placeholder="Zip Code" />
    </div>
  }
}

export default Comp;
