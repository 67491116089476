function title(state = [], action) {

  switch (action.type) {
    case 'TORUNAMENT/POINT_TYPES/SET':      
      return action.types;
    default:
      return state;
  }
}

export default title;