import React from 'react';
import SeasonItem from '../seasons/SeasonItem';
import Loader from '../common/Loader';
import { Link } from 'react-router';
import _ from 'lodash';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Jumbotron } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Season from '../common/modals/Season';
import Menu from './menu';

class League extends React.Component {

    state = {
        modal: false,
        step: 1,
        bootstrapped: false,
        league: null,
        seasons: null,
        isOpenStartDatepicker: false,
        isOpenEndDatepicker: false,
        selectedSeason: null,
        filterBy: localStorage.getItem("SEASONS.FILTERBY"),
        sortBy: localStorage.getItem("SEASONS.SORTBY") || 'DateEnd',
        sortByAlias: localStorage.getItem("SEASONS.SORTBY_ALIAS") || 'End Date',
        filterByOpen: false,
        sortByOpen: false
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, league } = this.props;

        setTitle && setTitle({
            main: 'Seasons',
            sub: league ? league.Name : null
        });       

        this.props.fetchSeasons(params.idLeague);
        this.props.seasonActions && this.props.seasonActions.fetchMedals()
        this.props.leagueActions && this.props.leagueActions.fetchCurrency(params.idLeague);
        this.props.divisionsActions.clearDivisions();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.league !== this.props.league) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                main: 'Seasons',
                sub: nextProps.league.Name || nextProps.league.LeagueName
            });
        }
        if (nextProps.seasons) {
            this.setState({ bootstrapped: true, seasons: nextProps.seasons });
        }

        if (!this.props.tournamentPointsTypes && nextProps.seasons && nextProps.seasons.length) {
            this.props.seasonActions && this.props.seasonActions.fetchTournamentPoints(nextProps.seasons[0].IdCommunity);
        }
    }

    componentWillUnmount = () => {
        this.props.clearSeasons && this.props.clearSeasons();
    }
    //

    addSeason = () => {
        const { params } = this.props;
        this.setState({
            modal: true,
            // Scafold of a season
            selectedSeason: {
                IdSeasonType: 0,
                IdTournamentPointsType: 0,
                DateStart: moment(),
                DateEnd: moment().add(1, 'month'),
                RegistrationEnd: moment().add(1, 'month'),
                Disclaimer: '',
                Divisions: [],
                IdLeague: params.idLeague,
                Payment: {
                    Cash: false,
                    Check: false,
                    Visa: true,
                    MasterCard: true,
                    Amex: true,
                    Discover: true
                },
                Roles: {
                    Coach: false,
                    Assistant: false,
                    TeamManager: false,
                    AllowOpenRegistration: false
                },
                CoachCanChangeScore: false,
                CoachCanAddGames: false,
                CoachCanAddPlayers: false
            }
        });
    }

    editSeason = (season) => {
        this.setState({
            modal: true,
            step: 1,
            selectedSeason: {
                ...season
            }
        });
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
            step: 1
        }, function () {
            if (!this.state.modal) {
                this.setState({
                    selectedSeason: null
                });
            }
        });
    }

    toggleFilterBy = () => {
        this.setState({
            filterByOpen: !this.state.filterByOpen
        });
    }

    filterBy = (something) => {
        something && localStorage.setItem("SEASONS.FILTERBY", something);
        !something && localStorage.removeItem("SEASONS.FILTERBY");
        this.setState({
            filterBy: something
        });
    }

    toggleSortBy = () => {
        this.setState({
            sortByOpen: !this.state.sortByOpen
        });
    }

    sortBy = (something, alias) => {
        localStorage.setItem("SEASONS.SORTBY", something);
        localStorage.setItem("SEASONS.SORTBY_ALIAS", alias);
        this.setState({
            sortBy: something,
            sortByAlias: alias
        });
    }

    onChange = () => { this.setState({ searchText: this.txtSearch.value }); }

    render() {

        const { selectedSeason, filterBy, sortBy, bootstrapped, modal, seasons, searchText } = this.state,
            { league, params } = this.props, { idLeague } = params;

        let orderedSeasons = _.sortBy(seasons || [], s => {
            return s[sortBy];
        }).reverse();

        if (filterBy) {
            orderedSeasons = _.filter(orderedSeasons, function (s) {
                return s.SeasonType === filterBy;
            });
        }

        let upcoming = _.chain(orderedSeasons)
            .filter(d => {
                if (searchText && (d.Name || d.SeasonName).toLowerCase().indexOf(searchText.toLowerCase()) === -1) return false;
                return true;
            })
            .filter(s => moment(s.DateEnd).isAfter(moment()))
            .value();

        let past = _.chain(orderedSeasons)
            .filter(d => {
                if (searchText && (d.Name || d.SeasonName).toLowerCase().indexOf(searchText.toLowerCase()) === -1) return false;
                return true;
            })
            .filter(s => moment(s.DateEnd).isBefore(moment()))
            .value();            

        return (
            <section className="">
                {/* LEFT MENU */}
                <Menu option="seasons" idLeague={idLeague} />

                {/* TOP FILTERS */}
                <div style={{ position: 'absolute', top: 0, left: 90, right: 0, height: '3em' }} >
                    <div className="w3-container mt-4">

                        <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>

                            <div className="w-50 black">
                                <h1>Seasons</h1>
                                <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
                            </div>

                            <div className="w-100 d-flex flex-row">

                                <input placeholder="Filter by Season's Name" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />

                                <Dropdown isOpen={this.state.filterByOpen} toggle={this.toggleFilterBy} className="align-self-center ml-2">
                                    <DropdownToggle caret className="btn-info btn">
                                        Filter by
                                        {' '}
                                        {this.state.filterBy}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.filterBy()}>All</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => this.filterBy('Tournament')}>Tournaments</DropdownItem>
                                        <DropdownItem onClick={() => this.filterBy('Recreational')}>Recreational</DropdownItem>
                                        <DropdownItem onClick={() => this.filterBy('Camp')}>Camps</DropdownItem>
                                        <DropdownItem onClick={() => this.filterBy('Competitive')}>Competitive</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                                <Dropdown isOpen={this.state.sortByOpen} toggle={this.toggleSortBy} className="align-self-center ml-2">
                                    <DropdownToggle caret className="btn-info btn">
                                        Sort by
                                        {' '}
                                        {this.state.sortByAlias}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.sortBy('DateEnd', 'End Date')}>End Date</DropdownItem>
                                        <DropdownItem onClick={() => this.sortBy('SeasonType', 'Season Type')}>Season Type</DropdownItem>
                                        <DropdownItem onClick={() => this.sortBy('TotalTeams', 'Teams')}>Teams</DropdownItem>
                                        <DropdownItem onClick={() => this.sortBy('TotalPlayers', 'Participants')}>Participants</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                                <button onClick={this.addSeason} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 130 }} className="green ml-2 p-2 align-self-center block">
                                    <i className="fas fa-plus margin-right-half" />Add</button>
                                    
                            </div>
                        </div>
                    </div>
                </div>


                {bootstrapped && (!seasons || seasons.length === 0) &&
                    <div key="leagues" className="d-flex flex-wrap justify-content-center league-list margin-bottom" style={{ paddingTop: 90, paddingLeft: 90 }}>
                        <div className="w3-container mt-4">
                            <div className="w3-content" style={{ maxWidth: 1330 }}>
                                <Jumbotron className="bg-white d-flex flex-column justify-content-center">
                                    <i className="far fa-meh-blank font-30 black align-self-center" />
                                    <span className="black font-20 align-self-center">You don't have upcoming events{this.txtSearch.value ? ` named like '${this.txtSearch.value}'` : ''}</span>
                                    <button onClick={this.addSeason} style={{ width: 130 }} className="green ml-2 p-2 align-self-center block">
                                        <i className="fas fa-plus margin-right-half" />Add Season</button>
                                </Jumbotron>
                            </div></div></div>}



                {bootstrapped && seasons && seasons.length > 0 && (
                    <div key="leagues" className="d-flex flex-wrap justify-content-center league-list margin-bottom" style={{ paddingTop: 90, paddingLeft: 90 }}>
                        <div className="w3-container mt-4">
                            <div className="w3-content" style={{ maxWidth: 1330 }}>

                                <h3 style={{ display: 'block' }} className="black w-100 p-4">Upcoming Events</h3>
                                {upcoming.length === 0 && <Jumbotron className="bg-white d-flex flex-column justify-content-center">
                                    <i className="far fa-meh-blank font-30 black align-self-center" />
                                    <span className="black font-20 align-self-center">You don't have upcoming events{this.txtSearch.value ? ` named like '${this.txtSearch.value}'` : ''}</span>
                                    <button onClick={this.addSeason} style={{ width: 130 }} className="green ml-2 p-2 align-self-center block">
                                        <i className="fas fa-plus margin-right-half" />Add Season</button>
                                </Jumbotron>}
                                <div className="d-flex flex-wrap  justify-content-start">
                                    {upcoming.map((season, i) => <SeasonItem key={i} index={i} season={season} medals={this.props.medals} fnEdit={() => this.editSeason(season)} />)}
                                </div>

                                {past.length > 0 && <h3 style={{ display: 'block' }} className="black w-100 p-4">Past Events</h3>}
                                <div className="d-flex flex-wrap  justify-content-start">
                                    {past.map((season, i) => <SeasonItem key={i} index={i} season={season} medals={this.props.medals} fnEdit={() => this.editSeason(season)} />)}
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                { /* Modal for Creating / Edit a season (TODO: refactor this) */}
                {modal && selectedSeason && <Season key="season" isOpen={modal} toggle={this.toggleModal} season={selectedSeason} {...this.props} />}

                { /* Loader */}
                {!bootstrapped && <Loader key="loader" message={`Loading Seasons, please wait...`} />}
                {bootstrapped && orderedSeasons.length === 0 && false && <div key="no-seasons" className="d-flex flex-col justify-content-center centered-message">
                    <h1 className="white align-self-center block text-center">There are no{filterBy ? ` ${filterBy} ` : ' '}Seasons available</h1>
                </div>}
            </section>
        )
    }
}

export default League;
