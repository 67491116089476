import React from 'react';
import { formatPrice } from '../../../../helpers';
import _ from 'lodash';

class Totals extends React.Component {
    state = {}
    setup = item => {
        let totalTotal = ((item.selectedPlan ? item.selectedPlan.Total : 0) +
            (item.questions ? _.reduce(_.map(item.questions, q => {
                if (q.IdQuestionType === 4) {
                    // multiple
                    return _.chain(q.Answers || [])
                        .filter(a => a.checked)
                        .map(a => a.Amount)
                        .reduce((a, b) => a + b, 0)
                        .value() || 0;
                }
                return q.answer ? q.answer.Amount : 0;
            }), function (memo, num) {
                return memo + num;
            }, 0) : 0) + (
                item.adjustments ? _.reduce(_.map(item.adjustments, function (q) {
                    return q.Adjustment || 0;
                }), function (memo, num) {
                    return memo + num;
                }, 0) : 0
            ));

        if (totalTotal < 0) totalTotal = 0;

        // Answers that affects the total
        let answersWithValue = _.filter([...item.questions], (q) => {
            if (q.IdQuestionType === 4) {
                // multiple
                return _.chain(q.Answers || [])
                    .filter(a => a.checked)
                    .map(a => a.Amount)
                    .reduce((a, b) => a + b, 0)
                    .value() !== 0;
            }
            return q.answer && q.answer.Amount !== 0;
        });

        answersWithValue.forEach(a => {
            if (a.IdQuestionType === 4) {
                a.answer.AnswerText = _.chain([...a.Answers])
                    .filter(o => o.checked)
                    .map(o => o.AnswerText).value().join(' -> ');
                a.answer.Amount = _.chain([...a.Answers])
                    .filter(o => o.checked)
                    .map(o => o.Amount)
                    .reduce((a, b) => a + b, 0).value();
            }
        });

        // Adjustments that affects the total
        let adjustmentsWithValue = _.filter((item.adjustments || []), adjustment => {
            return adjustment.Adjustment !== 0;
        });

        this.setState({ answersWithValue, adjustmentsWithValue, totalTotal })
    }
    
    componentWillMount() {
        const { item } = this.props;
        this.setup(item);
    }
    componentWillReceiveProps = nextProps => {
        const { item } = nextProps;
        this.setup(item);
    }

    render() {
        const { item } = this.props, { answersWithValue, adjustmentsWithValue, totalTotal } = this.state;

        return (
            <div>
                <div className="pt-2 pb-2 pr-4 pl-4">

                    {item.selectedPlan && (
                        <div className="d-flex flex-row w-100 ">
                            <span className="font-10">{item.selectedPlan.Dues[0].Name}</span>
                            <span className="font-10 ml-auto ">{formatPrice(item.selectedPlan.Dues[0].Total * 100)}</span>
                        </div>
                    )}

                    {totalTotal > 0 && answersWithValue.map((q, i) => (
                        <div key={i} className="d-flex flex-row w-100 ">
                            <span className="font-8">{q.QuestionText}:{' '}{q.answer.AnswerText}</span>
                            <span className="font-8 ml-auto ">{formatPrice(q.answer.Amount * 100)}</span>
                        </div>))}

                    {adjustmentsWithValue.map((a, i) => (
                        <div key={i} className="d-flex flex-row w-100 ">
                            <span className="font-8">{a.ProgramName}</span>
                            <span className="font-8 ml-auto ">{formatPrice(a.Adjustment * 100)}</span>
                        </div>))}

                    <div className="d-flex flex-row w-100 ">
                        <span className="font-12 toBold">Subtotal</span><span className="font-12 ml-auto">{formatPrice(totalTotal * 100)}</span>
                    </div>

                </div>
                {item.membership_program && <div className="bg-warning black font-10 text-center">There's a membership fee attached to this item on the cart</div>}
                {(item.selectedDivision && (item.selectedDivision.IsDivisionTryout === 1 || item.selectedDivision.IsDivisionTryout === true)) && <div className="bg-warning black font-10 text-center">Tryout - No payment needed at this time. Please check out to be processed</div>}
                {(item.selectedDivision && (item.selectedDivision.IsDivisionWaitlisted === 1 || item.selectedDivision.IsDivisionWaitlisted === true)) && <div className="bg-warning black font-10 text-center">Waitlist - No payment needed at this time. Please check out to be processed</div>}
            </div>)
    }
}

export default Totals;