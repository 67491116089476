import React from 'react';
import Roster from './roster';
import _ from 'lodash';

class Team extends React.Component {

  state = {
    team: null,
    sizes: []
  }
  componentWillMount() {
    const { sizes } = this.props;
    const localSizes = [];

    // For some reason, if I don't do this shit, they get overwritten every iteration of team.
    sizes.forEach(size => localSizes.push({ Abbr: size.Abbr, Size: size.Size, total: 0 }));
    this.setState({ team: this.props.team, sizes: localSizes }, () => {
      this.countSizes();
    });
  }

  countSizes = () => {
    const { sizes, team: { Players } } = this.state;
    sizes.forEach(size => {
      size.total = _.filter(Players, p => p.ShirtSize === size.Size || p.ShirtSize === size.Abbr).length;
    });
    this.setState({ sizes });
  }

  popSignature = () => {
    const { fnSign } = this.props, { team } = this.state;
    fnSign && fnSign(team);
  }
  showSignature = () => {
    const { fnShowSignature } = this.props, { team } = this.state;
    fnShowSignature && fnShowSignature(team);
  }

  handleErrorImage = () => {
    const { team } = this.state;
    team.TeamImageUrl = '/images/defaults/teamLogo.png';
    this.setState({ team });
  }

  render() {
    const { team, sizes } = this.state;
    return <tr>
      <td>
        <div className="d-flex flex-row">
          <img alt="" src={team.TeamImageUrl} onError={this.handleErrorImage} className="smallPic align-self-center margin-right-half" style={{ backgroundColor: 'white' }} />
          <div className="align-self-center d-flex flex-column">
            <span>{team.TeamName}</span>
            <span className="font-6">{team.IdTeam}</span>
          </div>
        </div>
      </td>
      <td className="font-8">{team.DivisionName}</td>
      <td onClick={() => this.props.fnSelectRoster(team)}>
        {team.Coaches.map((c, i) => <Roster key={i} header="(C)" value={_.chain((c.ShirtSize || '').split(' ')).map(l => l.charAt(0)).value().join('')} cn="bg-info white" />)}
      </td>
      <td onClick={() => this.props.fnSelectRoster(team)}>
        <div className="d-flex flex-row">
          {sizes.map((size, i) => <div className="d-flex flex-column justify-content-center" key={i}>
            <Roster key={i} header={size.total ? size.total : ''} value={size.total} cn={`bg-${size.total > 0 ? 'success' : 'x'} white align-self-center`} />
            <span className="font-8 white align-self-center">{size.Abbr}</span>
          </div>)}
        </div>
      </td>
      <td><div className="bg-success total-box text-center d-flex flex-row justify-content-center">
        <span className="align-self-center">{team.Coaches.length + team.Players.length}</span></div></td>
      <td >
        {team.UserName_CoachSigned && team.UserName_CoachSigned !== null && <div className="d-flex flex-row">
          <img alt="" className="smallPic align-self-center margin-right-half" src="/images/defaults/male.png" />
          <span className="align-self-center margin-right-half underlined" onClick={this.showSignature}>{team.UserName_CoachSigned}</span>
          {/*<img alt="" className="align-self-center bg-white" src={team.Signature} style={{ height: 50 }} />*/}
        </div>}
        {!team.UserName_CoachSigned && <span className="text-center underlined" onClick={this.popSignature}>Click here</span>}
      </td>
    </tr>
  }
}

export default Team;