import React from 'react';
import _ from 'lodash';
import DivisionList from '../common/divisions/List';
import PaymentPlansList from '../common/payment_plans/List';
import SelectQuestion from '../common/questions/Select';
import RadioQuestion from '../common/questions/Radio';
import TextQuestion from '../common/questions/Text';
import CheckQuestion from '../common/questions/Check';
import CheckListQuestion from '../common/questions/CheckList';
import CheckoutActionsPopup from '../common/CheckoutActionsPopup';
import CartPopup from '../common/CartPopup';
import { generateRandomId } from '../../../helpers';
import CreateTeam from '../teams/CreateTeam';
import config from '../../../config';
import request from 'superagent';
import TeamSelection from './modals/TeamSelection';
import Season from './partials/Season';
import Person from './partials/Person';
import Division from './partials/Division';
import Team from './partials/Team';

//const Soon = () => {
class TeamCheckout extends React.Component {

    state = {
        questions: null,
        paymentPlans: null,
        divisions: null,
        selectedDivision: null,
        selectedPlan: null,
        selectedTeam: null,
        selectedUser: null,
        selectedRole: null,
        selectedSeason: null,
        selectedLeague: null,
        bootstrapped: false,
        step: 1,
        isCheckoutActionsOpen: false,
        isTeamSelectionOpen: false,
        isCreatingTeam: false,
        fetchingDataLocally: false
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, pouch, league } = this.props;

        setTitle && setTitle({
            main: 'Registration',
            sub: (pouch && pouch.selectedLeague) ? pouch.selectedLeague.LeagueName : null
        });

        this.setState(_.extend(this.state, this.props.pouch))
        this.props.divisionsActions && this.props.divisionsActions.clearDivisions();

        if (pouch && pouch.selectedSeason) {
            this.props.divisionsActions &&
                this.props.divisionsActions.fetchForRegistrationOfTeams(pouch.selectedSeason.IdSeason, pouch.selectedTeam.IdTeam)
        }

        if ((!league || !league.IdLeague) && pouch && pouch.selectedLeague) {
            this.props.leagueActions &&
                this.props.leagueActions.fetchLeague(pouch.selectedLeague.IdLeague)
        }

        if (pouch && pouch.selectedSeason) {
            this.props.seasonActions && this.props.seasonActions.fetchRoles(pouch.selectedSeason.IdSeason || pouch.selectedSeason.idSeason);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            divisions: nextProps.divisions,
            bootstrapped: true
        });
    }
    //

    back = () => {
        const { step, divisions } = this.state,
            { league } = this.props;
        if (step === 1) {
            this.props.router && this.props.router.push('/registration/' + league.IdLeague);
        } else {
            this.props.divisionsActions && this.props.divisionsActions.clearQuestions();
            this.props.divisionsActions && this.props.divisionsActions.clearPaymentPlans();
            divisions.Male && divisions.Male.forEach((d) => {
                d.selected = false;
            });
            divisions.Female && divisions.Female.forEach((d) => {
                d.selected = false;
            });
            divisions.Coed && divisions.Coed.forEach((d) => {
                d.selected = false;
            });
            this.setState({
                step: 1,
                divisions
            });
        }
    }

    selectDivision = (division) => {
        const { divisions, } = this.state;
        divisions.Male && divisions.Male.forEach((d) => {
            d.selected = d.IdDivision === division.IdDivision;
        });
        divisions.Female && divisions.Female.forEach((d) => {
            d.selected = d.IdDivision === division.IdDivision;
        });
        divisions.Coed && divisions.Coed.forEach((d) => {
            d.selected = d.IdDivision === division.IdDivision;
        });

        // After fetching Payment plans and Questions
        var exit = _.after(2, () => {
            this.setState({
                fetchingDataLocally: false,
                selectedDivision: division
            });
        });

        // Spinner
        this.setState({
            fetchingDataLocally: true,
            divisions
        });



        // NEW RULE: if its a 'hybrid season', no payment plans and no questions at this time
        // NEWER RULE: if its hybrid, fetch coaches questions
        // NEWER RULE: there might 
        if (this.props.pouch.selectedSeason.IdSeasonType === 5) {

            // Fetch questions        
            request.get(`${config.apiEndpoint}/api/v4/registration/questions/${division.IdDivision}/4`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    this.setState({ questions: data.body.questions });
                    exit();
                }, () => {
                    this.setState({ questions: null });
                    exit();
                });

            // Payment plans
            request.get(`${config.apiEndpoint}/api/v4/paymentPlans/hybrid_team/${division.IdDivision}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    this.setState({ paymentPlans: data.body.plans });
                    exit();
                }, () => {
                    this.setState({ paymentPlans: null });
                    exit();
                });

        } else {

            // Fetch questions        
            request.get(`${config.apiEndpoint}/api/v4/registration/questions/${division.IdDivision}/3`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    this.setState({ questions: data.body.questions });
                    exit();
                }, () => {
                    this.setState({ questions: null });
                    exit();
                });

            // Payment plans
            request.get(`${config.apiEndpoint}/api/v4/paymentPlans/${division.IdDivision}/3`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then((data) => {
                    this.setState({ paymentPlans: data.body.plans });
                    exit();
                }, () => {
                    this.setState({ paymentPlans: null });
                    exit();
                });
        }
    }

    next = () => {
        const { step } = this.state;
        if (step === 1) {
            this.setState({
                step: (this.state.paymentPlans && this.state.paymentPlans.length) ? 2 : (this.state.questions && this.state.questions.length ? 3 : 1)
            }, function () {
                // still 1 ? then add to cart and continue
                if (this.state.step === 1) {
                    this.addToCart(); //?
                    this.setState({ isCheckoutActionsOpen: true });
                }
            });
        } else if (step === 2) {
            if (this.state.questions && this.state.questions.length) {
                this.setState({
                    step: 3
                }); // TODO: if there are questions
            } else {
                this.addToCart();
                this.setState({
                    isCheckoutActionsOpen: true
                });
            }
        }
    }

    backToDivisions = () => {
        const { divisions } = this.state;
        divisions.Male && divisions.Male.forEach((d) => {
            d.selected = false;
        });
        divisions.Female && divisions.Female.forEach((d) => {
            d.selected = false;
        });
        divisions.Coed && divisions.Coed.forEach((d) => {
            d.selected = false;
        });
        this.setState({
            selectedDivision: null,
            questions: null,
            selectedPlan: null,
            step: 1,
            divisions
        });
    }

    selectPaymentPlan = (pp) => {
        const { paymentPlans } = this.state;
        paymentPlans.forEach((plan) => {
            plan.selected = (plan.IdPlan === pp.IdPlan && plan.Name === pp.Name);
        });
        this.setState({
            selectedPlan: pp,
            paymentPlans
        });
    }

    addTeam = () => {
        this.setState({
            isCreatingTeam: !this.state.isCreatingTeam,
            isTeamSelectionOpen: false
        })
    }

    toggleCheckoutActions = () => {
        this.setState({
            isCheckoutActionsOpen: !this.state.isCheckoutActionsOpen
        });
    }

    toogleTeamSelection = () => {
        this.setState({
            isTeamSelectionOpen: !this.state.isTeamSelectionOpen,
            isCheckoutActionsOpen: false
        });
    }

    cancelRegisterAnother = () => {
        this.setState({
            isTeamSelectionOpen: false,
            isCheckoutActionsOpen: true
        });
    }

    restart = (args) => {

        this.props.divisionsActions && this.props.divisionsActions.clearDivisions();
        this.setState(_.extend(this.state, args, {
            step: 1,
            isCheckoutActionsOpen: false,
            isTeamSelectionOpen: false,
            selectedDivision: null,
            selectedPlan: null,
            questions: null,
            paymentPlans: null,
            divisions: null
        }), function () {
            this.props.pouchActions &&
                this.props.pouchActions.set(args);

            this.props.divisionsActions &&
                this.props.divisionsActions.fetchForRegistrationOfTeams(args.selectedSeason.IdSeason, args.selectedTeam.IdTeam);

            // Scroll to the top !
            //document.getElementById('top-of').scrollIntoView();
        })


    }

    toggleCart = () => {
        this.props.cartActions &&
            this.props.cartActions.close();
    }

    addToCart = () => {

        var { cart } = this.props,
            { questions, selectedTeam, selectedDivision } = this.state;

        // 1st validate all the required questions
        if (_.find(questions, (q) => {
            return q.Required && !q.answer;
        })) {
            alert('Please answer all the required questions');

        } else {
            if (!cart)
                cart = [];
            // TODO: evaluate if the item is not already in the cart
            if (!_.find(cart, (item) => {
                return (item.selectedTeam && item.selectedTeam.IdTeam === selectedTeam.IdTeam && item.selectedDivision.IdDivision ===
                    selectedDivision.IdDivision);
            })) {
                cart.push(_.extend({
                    Date: new Date(),
                    Id: generateRandomId(10),
                    questions: this.state.questions
                }, _.pick(this.state, 'selectedDivision', 'selectedPlan', 'selectedTeam', 'selectedUser', 'selectedLeague', 'selectedSeason', 'selectedRole')));
                this.props.cartActions && this.props.cartActions.set(cart);
                this.setState({
                    isCheckoutActionsOpen: true
                });
            }
        }
    }


    render() {
        const { isCheckoutActionsOpen, isTeamSelectionOpen, divisions, selectedDivision, selectedTeam, selectedPlan, selectedSeason, selectedUser, selectedRole, step, bootstrapped, questions, paymentPlans, isCreatingTeam, fetchingDataLocally } = this.state;
        const { fetchingData, league, cart, teams, family, teamRegistration, isCartOpen } = this.props;

        return (
            <section>
                {league && <div className="card shadow-box w-100 px-2 py-2 d-flex flex-row" onClick={this.back}>
                    <i className="fa fa-arrow-left align-self-center" />
                    <span className="align-self-center ml-2">Back</span>
                </div>}
                <section className="w3-container">
                    <div className="w3-content">
                        <div className="tile card shadow-box d-flex flex-column mx-auto mt-4" style={{ maxWidth: 600 }}>
                            <Season season={selectedSeason} />
                            <Team selectedTeam={selectedTeam} />
                            <Person selectedUser={selectedUser} selectedRole={selectedRole} />
                            {selectedDivision && <Division selectedDivision={selectedDivision} onClick={this.backToDivisions} />}
                        </div>

                        { /* Divisions */}
                        {step === 1 && bootstrapped && (
                            <section key="step1" style={{ maxWidth: 600 }} className="mx-auto mb-4 d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Select your division</span>
                                {divisions && divisions.Male && <DivisionList title="Male Divisions" divisions={divisions.Male} fnSelect={this.selectDivision} />}
                                {divisions && divisions.Female && <DivisionList title="Female Divisions" divisions={divisions.Female} fnSelect={this.selectDivision} />}
                                {divisions && divisions.Coed && <DivisionList title="Coed Divisions" divisions={divisions.Coed} fnSelect={this.selectDivision} />}
                                {divisions && !divisions.Male && !divisions.Female && !divisions.Coed && <div style={{ height: 400 }} className="d-flex flex-column p-4 justify-content-center">
                                    <img alt="" src="/images/defaults/red_card.png" style={{ width: 100 }} className="align-self-center" />
                                    <span className="align-self-center mt-2">Sorry, there are no divisions available for registration right now</span>
                                </div>}
                            </section>)}

                        { /* Payment Plans */}
                        {step === 2 && bootstrapped && selectedDivision && (
                            <section key="step2" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Select your Payment Plan</span>
                                <PaymentPlansList paymentPlans={paymentPlans} fnSelect={this.selectPaymentPlan} />
                            </section>)}
                        { /* Questions */}

                        {step === 3 && bootstrapped && (
                            <section key="step3" style={{ maxWidth: 600 }} className="m-auto d-flex flex-column">
                                <span className="font-18 toBold black mt-2">Please answer some questions</span>
                                {questions && questions.map((q, i) => (
                                    <figure className="card tile shadow-box w-100 mt-2 mb-2 ml-auto mr-auto" key={i} style={{ maxWidth: 600 }}>
                                        {q.IdQuestionType === 2 && <TextQuestion question={q} />}
                                        {q.IdQuestionType === 3 && <CheckQuestion question={q} />}
                                        {q.IdQuestionType === 4 && <CheckListQuestion question={q} />}
                                        {q.IdQuestionType === 5 && <SelectQuestion question={q} />}
                                        {q.IdQuestionType === 6 && <RadioQuestion question={q} />}
                                    </figure>))}
                            </section>)}
                    </div>
                    <div style={{ height: 200 }} />
                </section>
                { /* Loader */}

                {bootstrapped && (fetchingData || fetchingDataLocally) && (
                    <div className="d-flex justify-content-center centered-message" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#00333333' }} key="loader">
                        <h1 className="white align-self-center block text-center"><i className="fa fa-spin fa-cog" /></h1> </div>)}
                { /* Checkout actions */}
                {isCheckoutActionsOpen && <CheckoutActionsPopup router={this.props.router} league={league} isOpen={isCheckoutActionsOpen} toggle={this.toggleCheckoutActions}
                    fnRegisterAnother={this.toogleTeamSelection} fnOk={this.toggleCheckoutActions} label="Team"
                />}

                {isTeamSelectionOpen && <TeamSelection roles={this.props.roles} cart={cart} league={league} season={selectedSeason} teams={teams} family={family}
                    fnAddTeam={this.addTeam} close={this.cancelRegisterAnother} fnOk={this.restart} teamRegistration={teamRegistration} />}

                {isCartOpen && <CartPopup {...this.props} toggle={this.toggleCart} fnOk={this.toggleCart} isOpen={this.props.isCartOpen} />}



                {isCreatingTeam && <CreateTeam {...this.props} isOpen={isCreatingTeam} toggle={() => this.setState({ isCreatingTeam: false, isTeamSelectionOpen: true })}
                />}
                {((step === 1 && selectedDivision) || (step === 2 && selectedPlan)) && (
                    <button className="btn btn-success btn-lg margin-top" style={{ position: 'fixed', bottom: '1em', right: '1em', borderRadius: 30 }} onClick={this.next}>
                        Next
                        {' '}<i className="fa fa-arrow-right" /></button>)}

                {step === 3 && <button className="btn btn-success btn-lg margin-top" style={{ position: 'fixed', bottom: '1em', right: '1em' }} onClick={this.addToCart}><i className="fa fa-plus" />
                    {' '}Add to Cart</button>}
            </section>
        );
    }
}

export default TeamCheckout;