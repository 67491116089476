import React from 'react';
import { ItemTypes, formatPrice } from '../../../../../../helpers';
import flow from 'lodash/flow';
import { DragSource } from 'react-dnd';

const source = {
  beginDrag(props) {
    return props;
  }
}

class Comp extends React.Component {
  state = { icon: null, expanded: false }

  // Lifecycle
  componentWillMount() {
    const { question } = this.props;
    // TODO: complete icons
    let icon = 'fa fa-question';
    switch (question.question.question_type + '') {
      case ItemTypes.FREETEXT: icon = 'fa fa-font blue'; break;
      case ItemTypes.RADIO: icon = 'far fa-dot-circle purple'; break;
      case ItemTypes.CHECK: icon = 'far fa-check-square red'; break;
      case ItemTypes.SELECT: icon = 'far fa-caret-square-down black'; break;
      case ItemTypes.CHECKLIST: icon = 'fa fa-list green'; break;
      default: break;
    }
    this.setState({ icon });
  }

  removeQuestion = (index) => {    
    const { fnRemove, question } = this.props;
    if (fnRemove) {
      fnRemove(index, question.IdQuestion);
    } else {
      this.props.questionsActions &&
        this.props.questionsActions.removeQuestion(index, question.IdQuestion);
    }
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  renderThing = () => {
    const { index, question, deletable, className, hideAnswers, expandable } = this.props,
      { icon, expanded } = this.state;

    return <figure className={`card p-1 ${className}`} key={question.IdQuestion}>
      <div className="d-flex flex-row">
        <i className={`align-self-center ${icon} margin-right-half`} />
        <span className="align-self-center">{index} - {question.question.text}</span>
        {(question.required || question.IsRequired) && <i className="fas fa-asterisk red align-self-center idented-half font-8" />}
        {(question.IsDonation) && <i className="fas fa-dollar-sign blue align-self-center idented-half font-8" />}
        {deletable && <button onClick={() => this.removeQuestion(index)} className="btn btn-link btn-sm ml-auto align-self-center"><i className="fa fa-times" /></button>}
        {expandable && hideAnswers && false && <button onClick={this.toggleExpanded} className="btn btn-link btn-sm ml-auto align-self-center"><i className={`fa fa-caret-${this.state.expanded ? 'up' : 'down'}`} /></button>}
      </div>
      {(!hideAnswers || expanded) && question.answers && question.answers.length > 0 &&
        <div>
          <hr className="no-margin no-padding" />
          <ul>
            {question.answers.map((answer, i) => <li key={i} className="d-flex flex-row font-8">
              <span>{answer.Text || answer.text || answer.QuestionOptionText}</span>
              <span className="ml-auto">{formatPrice(answer.Amount * 100)}</span>
            </li>)}
          </ul>
        </div>}
    </figure>
  }
  render() {
    if (this.props.question && this.props.question.deleted) { return <div /> }
    const { connectDragPreview, connectDragSource, draggable } = this.props;
    if (draggable) return connectDragPreview(connectDragSource(this.renderThing()));
    else return this.renderThing();
  }
}

export default flow(
  DragSource(ItemTypes.QANDA, source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }))
)(Comp);
