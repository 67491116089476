import React from 'react';
import { Animated } from 'react-animated-css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import _ from 'lodash';
import config from '../../config';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { getMaskedValue } from '../../helpers';


class Wizzard extends React.Component {
    state = {
        page: 1,
        selectedUser: null,
        modal: false,
        the_sport: {}
    }
    componentWillReceiveProps = nextProps => {
        const { selectedUser, } = nextProps;
        this.setState({ selectedUser, });
    }
    onBack = () => {
        const { page } = this.state;
        switch (page) {
            case 1: this.props.toggle(); break;
            default: this.setState({ page: page - 1 }); break;
        }
    }
    onAddSport = () => {
        const { the_sport, selectedUser } = this.state;
        if (_.findIndex(selectedUser.sports, s => s.IdCommunity === the_sport.IdCommunity) !== -1) {
            selectedUser.sports[_.findIndex(selectedUser.sports, s => s.IdCommunity === the_sport.IdCommunity)] = {
                ...selectedUser.sports[_.findIndex(selectedUser.sports, s => s.IdCommunity === the_sport.IdCommunity)], ...the_sport
            }
        } else selectedUser.sports.push(the_sport);
        // TODO: replace the sport in the profile, or add a new one
        this.setState({ adding_sport: false, the_sport: null, modal: null });

    }
    saveChanges = () => {
        const { selectedUser } = this.state;
        
        const { roster_actions } = this.props;
        roster_actions.updateCollegeProfile(_.pick(selectedUser,
            'IdCollegeProfile',
            'GraduationYear',
            'SchoolName',
            'GPA',
            'SAT',
            'ACT',
            'Notes',
            'ClubName',
            'PreferredJerseyNumber',
            'PreferredPosition',
            'IsPublic',
            'IsActive',
            'IsLookingForTransfer',
            'Height',
            'HeightUnit',
            'Nationality1',
            'Nationality2',
            'sports',
            'CommitedTo',
            'IsCommited',
            'InstitutionImage',
            'doUpdateImage',
            'Weight',
            'WeightUnit',
            'EducationalInterestPrimary',
            'EducationalInterestSecondary',
            'IdUser',
        ));
        this.setState({ page: 5 });
    }
    render() {
        const { page, selectedUser, adding_sport } = this.state, { communities } = this.props;

        let graduation_years = [{
            title: 'Graduated Highschool/Prep',
            value: 'Graduated Highschool/Prep'
        }, {
            title: 'Graduated College/University',
            value: 'Graduated College/University'
        }]
        for (var i = moment().get('year'); i <= moment().get('year') + 10; i++) {
            graduation_years.push({ title: i, value: i });
        }

        return <Animated isVisible={this.props.isVisible}
            animationIn='slideInRight' animationInDuration={200}
            animationOut='slideOutRight' animationOutDuration={200}
            style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
            className="bg-white">

            {/* HEADER */}
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, height: 80 }} className="bg-blue-dark bg-dark-blue d-flex flex-row" >
                <i className="fas fa-arrow-left white font-20 ml-4 pointer align-self-center pointer" onClick={this.onBack} />
                <span className="white font-16 montserrat ml-4 align-self-center">RECRUITING PROFILE: {(selectedUser || {}).NameLast}</span>
                {/*<button className="btn btn-success ml-auto mr-4 align-self-center" style={{ borderRadius: 30 }}>SAVE</button>*/}
            </div>

            {/* BODY */}
            {page === 1 && selectedUser && !selectedUser.sports.length && <div style={{ position: 'fixed', top: 80, left: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column justify-content-center">
                <i className="icon-recreational font-50 black align-self-center" />
                <span className="align-self-center font-30 line1 black montserrat">Lets Start!</span>
                <span className="align-self-center font-12 line1 black montserrat">by adding a sport</span>
                <button onClick={() => this.setState({ adding_sport: true, modal: true, the_sport: {} })} className="btn btn-success mt-4 align-self-center" style={{ borderRadius: 30, minWidth: 300 }}>Add Sport</button>
            </div>}

            {/* PAGE 1: SPORTS */}
            {page === 1 && selectedUser && selectedUser.sports.length > 0 && <div style={{ position: 'fixed', top: 80, left: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column p-4">

                <h2 className="sqwad-font">SPORTS</h2>

                {selectedUser.sports.map((sport, index) => {
                    let sport_name = _.find(this.props.communities || [], c => c.IdCommunity === sport.IdCommunity);
                    return <div onClick={() => this.setState({ adding_sport: sport, the_sport: { ...sport, ...sport_name }, modal: true })} key={index} className="card tile bg-white mx-auto mt-4" style={{ width: 400 }}>
                        <span className="montserrat-bold font-14 px-3 py-2">{sport_name.Name || sport_name.CommunityName}</span>
                        <hr className='p-0 m-0' />
                        <div className="mx-3 my-2">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column w-100">
                                    <span className="montserrat-bold font-8">Primary Position</span>
                                    <span className="montserrat font-12 mb-4">{sport.Position1 || 'N/A'}</span>
                                    <span className="montserrat-bold font-8">Secondary Position</span>
                                    <span className="montserrat font-12 mb-4">{sport.Position2 || 'N/A'}</span>
                                    <span className="montserrat-bold font-8">Highest Level Played</span>
                                    <span className="montserrat font-12 mb-4">{sport.LevelPlayed}</span>
                                </div>
                                <div className="d-flex flex-column w-50">
                                    <div className="text-center" style={{ position: 'relative' }}>
                                        <i className="icon-apparel text-center blue font-60 mt-1 mb-4"></i>
                                        <span style={{ position: 'absolute', top: 15, left: 0, right: 0 }} className="white font-28 text-center">{sport.JerseyNumber}</span>
                                    </div>
                                    <span className="montserrat-bold font-8 text-center mt-4">Foot</span>
                                    <span className="montserrat font-12 mb-4 text-center">{sport.DominantFoot}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                })}

                <button onClick={() => this.setState({ adding_sport: true, modal: true })} className="btn btn-info mx-auto mt-4" style={{ borderRadius: 30 }}>+ Add Another Sport</button>
                <button onClick={() => this.setState({ page: 2 })} className="btn btn-link mx-auto font-20 toBold">Next</button>

            </div>}

            {/* PAGE 2: EDUCATIONAL */}
            {page === 2 && selectedUser && <div style={{ position: 'fixed', top: 80, left: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column p-4">

                <h2 className="sqwad-font">EDUCATION</h2>

                <div className="mx-auto mt-4" style={{ width: 400 }}>
                    {/* Graduation Year */}
                    <div className="mt-1">
                        <div className={`d-flex flex-row`}>
                            <span className="font-10 black align-self-center">Graduation Year</span>
                        </div>
                        <Dropdown isOpen={this.state.gy_open} toggle={() => this.setState({ gy_open: !this.state.gy_open })} className="font-16 no-padding bg-white black mt-2">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                <span className="black align-self-center">{this.state.selectedUser.GraduationYear || 'Select'}</span>
                                <i className="ml-auto fas fa-caret-down align-self-center black" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {(graduation_years || []).map((c) => <DropdownItem key={c.value} onClick={() => {
                                    const { selectedUser } = this.state;
                                    selectedUser.GraduationYear = c.value;
                                    this.setState({ selectedUser });
                                }}>
                                    {c.title}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    {/* SchoolName */}
                    <div className="mt-1">
                        <div className={`d-flex flex-row`}>
                            <span className="font-10 black align-self-center">High School | Prep School</span>
                        </div>
                        <input defaultValue={this.state.selectedUser.SchoolName} className={`form-control`} type="text" placeholder="High School | Prep School" onChange={e => {
                            const { selectedUser } = this.state;
                            selectedUser.SchoolName = e.target.value;
                            this.setState({ selectedUser });
                        }} />
                    </div>
                    {/* ACT, SAT, etc... */}
                    <div className="mt-1 d-flex flex-row">
                        <div className="w-100">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">GPA</span>
                            </div>
                            <input defaultValue={this.state.selectedUser.GPA} className={`form-control`} type="text" placeholder="GPA" onChange={e => {
                                const { selectedUser } = this.state;
                                selectedUser.GPA = e.target.value;
                                this.setState({ selectedUser });
                            }} />
                        </div>
                        <div className="m-1" />
                        <div className="w-100">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">SAT</span>
                            </div>
                            <input defaultValue={this.state.selectedUser.SAT} className={`form-control`} type="text" placeholder="SAT" onChange={e => {
                                const { selectedUser } = this.state;
                                selectedUser.SAT = e.target.value;
                                this.setState({ selectedUser });
                            }} />
                        </div>
                        <div className="m-1" />
                        <div className="w-100">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">ACT</span>
                            </div>
                            <input defaultValue={this.state.selectedUser.ACT} className={`form-control`} type="text" placeholder="ACT" onChange={e => {
                                const { selectedUser } = this.state;
                                selectedUser.ACT = e.target.value;
                                this.setState({ selectedUser });
                            }} />
                        </div>
                    </div>
                    {/* Primary Interest */}
                    <div className="mt-1">
                        <div className={`d-flex flex-row`}>
                            <span className="font-10 black align-self-center">Primary Interest</span>
                        </div>
                        <Dropdown isOpen={this.state.pi_open} toggle={() => this.setState({ pi_open: !this.state.pi_open })} className="font-16 no-padding bg-white black mt-2">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                <span className="black align-self-center">{this.state.selectedUser.EducationalInterestPrimary || 'Select'}</span>
                                <i className="ml-auto fas fa-caret-down align-self-center black" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {(config.educational_interest || []).map((c) => <DropdownItem key={c.value} onClick={() => {
                                    const { selectedUser } = this.state;
                                    selectedUser.EducationalInterestPrimary = c.value;
                                    this.setState({ selectedUser });
                                }}>
                                    {c.title}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    {/* Secondary Interest */}
                    <div className="mt-1">
                        <div className={`d-flex flex-row`}>
                            <span className="font-10 black align-self-center">Secondary Interest</span>
                        </div>
                        <Dropdown isOpen={this.state.si_open} toggle={() => this.setState({ si_open: !this.state.si_open })} className="font-16 no-padding bg-white black mt-2">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                <span className="black align-self-center">{this.state.selectedUser.EducationalInterestSecondary || 'Select'}</span>
                                <i className="ml-auto fas fa-caret-down align-self-center black" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {(config.educational_interest || []).map((c) => <DropdownItem key={c.value} onClick={() => {
                                    const { selectedUser } = this.state;
                                    selectedUser.EducationalInterestSecondary = c.value;
                                    this.setState({ selectedUser });
                                }}>
                                    {c.title}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>

                <button onClick={() => this.setState({ page: 3 })} className="btn btn-link mx-auto font-20 toBold">Next</button>

            </div>
            }

            {/* PAGE 3: ADDITIONAL */}
            {
                page === 3 && selectedUser && <div style={{ position: 'fixed', top: 80, left: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column p-4">

                    <h2 className="sqwad-font">ADDITIONAL</h2>
                    <div className="mx-auto mt-4" style={{ width: 400 }}>
                        {/* Academy | Club */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Academy | Club</span>
                            </div>
                            <input defaultValue={this.state.selectedUser.ClubName} className={`form-control`} type="text" placeholder="Academy | Club" onChange={e => {
                                const { selectedUser } = this.state;
                                selectedUser.ClubName = e.target.value;
                                this.setState({ selectedUser });
                            }} />
                        </div>

                        {/* Nationality 1 */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Nationality 1</span>
                            </div>
                            <Dropdown isOpen={this.state.nat_1_open} toggle={() => this.setState({ nat_1_open: !this.state.nat_1_open })} className="font-16 no-padding bg-white black mt-2">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                    <span className="black align-self-center">{this.state.selectedUser.Nationality1 || 'Select'}</span>
                                    <img src={(_.find(this.props.countries, c => c.CountryName === this.state.selectedUser.Nationality1) || {}).image} style={{ height: 15 }} className="ml-auto align-self-center" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {(this.props.countries || []).map((c) => <DropdownItem className="d-flex" key={c.IdCountry} onClick={() => {
                                        const { selectedUser } = this.state;
                                        selectedUser.Nationality1 = c.CountryName;
                                        this.setState({ selectedUser });
                                    }}>
                                        <span>{c.CountryName}</span>
                                        <img src={c.image} style={{ height: 15 }} className="ml-auto align-self-center" />
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        {/* Nationality 2 */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Nationality 2</span>
                            </div>
                            <Dropdown isOpen={this.state.nat_2_open} toggle={() => this.setState({ nat_2_open: !this.state.nat_2_open })} className="font-16 no-padding bg-white black mt-2">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                    <span className="black align-self-center">{this.state.selectedUser.Nationality2 || 'Select'}</span>
                                    <img src={(_.find(this.props.countries, c => c.CountryName === this.state.selectedUser.Nationality2) || {}).image} style={{ height: 15 }} className="ml-auto align-self-center" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {(this.props.countries || []).map((c) => <DropdownItem className="d-flex" key={c.IdCountry} onClick={() => {
                                        const { selectedUser } = this.state;
                                        selectedUser.Nationality2 = c.CountryName;
                                        this.setState({ selectedUser });
                                    }}>
                                        <span>{c.CountryName}</span>
                                        <img src={c.image} style={{ height: 15 }} className="ml-auto align-self-center" />
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        {/* Height */}
                        <div className="mt-1 d-flex flex-row">
                            <div className="w-100">
                                <div className={`d-flex flex-row`}>
                                    <span className="font-10 black align-self-center">Height</span>
                                </div>
                                <MaskedInput className={`form-control mt-2`} defaultValue={selectedUser.Height} placeholder="Height" id="txtHeight" ref={(i) => this.txtHeight = i} guide={true} mask={(_.find(config.units, u => u.value === selectedUser.HeightUnit) || _.first(config.units)).input} />
                            </div>
                            <div className="m-1" />
                            <div className="w-100">
                                <div className={`d-flex flex-row`}>
                                    <span className="font-10 black align-self-center">Unit</span>
                                </div>
                                <Dropdown isOpen={this.state.unit_1_open} toggle={() => this.setState({ unit_1_open: !this.state.unit_1_open })} className="font-16 no-padding bg-white black mt-2">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                        <span className="black align-self-center">{selectedUser.HeightUnit ? _.find(config.units, u => u.value === selectedUser.HeightUnit).title : 'Select'}</span>
                                        <i className="ml-auto fas fa-caret-down align-self-center black" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {config.units.map((c) => <DropdownItem className="d-flex" key={c.value} onClick={() => {
                                            const { selectedUser } = this.state;
                                            selectedUser.HeightUnit = c.value;
                                            this.setState({ selectedUser });
                                        }}>
                                            <span>{c.title}</span>
                                        </DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>

                        {/* Weight */}
                        <div className="mt-1 d-flex flex-row">
                            <div className="w-100">
                                <div className={`d-flex flex-row`}>
                                    <span className="font-10 black align-self-center">Weight</span>
                                </div>
                                <MaskedInput className={`form-control mt-2`} defaultValue={selectedUser.Weight} placeholder="Weight" id="txtWeight" ref={(i) => this.txtWeight = i} guide={true} mask={(_.find(config.weight_units, u => u.value === selectedUser.WeightUnit) || _.first(config.weight_units)).input} onChange={e => {
                                    
                                }} />
                                {/* this.txtWeight.inputElement.value */}
                            </div>
                            <div className="m-1" />
                            <div className="w-100">
                                <div className={`d-flex flex-row`}>
                                    <span className="font-10 black align-self-center">Unit</span>
                                </div>
                                <Dropdown isOpen={this.state.unit_2_open} toggle={() => this.setState({ unit_2_open: !this.state.unit_2_open })} className="font-16 no-padding bg-white black mt-2">
                                    <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                        <span className="black align-self-center">{selectedUser.WeightUnit ? _.find(config.weight_units, u => u.value === selectedUser.WeightUnit).title : 'Select'}</span>
                                        <i className="ml-auto fas fa-caret-down align-self-center black" />
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                        {config.weight_units.map((c) => <DropdownItem className="d-flex" key={c.value} onClick={() => {
                                            const { selectedUser } = this.state;
                                            selectedUser.WeightUnit = c.value;
                                            this.setState({ selectedUser });
                                        }}>
                                            <span>{c.title}</span>
                                        </DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>


                    <button onClick={() => this.setState({ page: 4 })} className="btn btn-link mx-auto font-20 toBold">Next</button>

                </div>
            }

            {/* PAGE 4: COMMITMENTS */}
            {
                page === 4 && selectedUser && <div style={{ position: 'fixed', top: 80, left: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column p-4">

                    <h2 className="sqwad-font">COMMITMENTS</h2>
                    <div className="mx-auto mt-4" style={{ width: 400 }}>
                        {/* Notes */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Notes</span>
                            </div>
                            <textarea rows={5} defaultValue={this.state.selectedUser.Notes} className={`form-control`} type="text" placeholder="Notes" onChange={e => {
                                const { selectedUser } = this.state;
                                selectedUser.Notes = e.target.value;
                                this.setState({ selectedUser });
                            }} />
                        </div>
                        {/* Commitment */}
                        <div className="w-100 mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Commitment</span>
                            </div>
                            <Dropdown isOpen={this.state.comm_open} toggle={() => this.setState({ comm_open: !this.state.comm_open })} className="font-16 no-padding bg-white black mt-2">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                    <span className="black align-self-center">{selectedUser.IsCommited ? 'Commited' : (selectedUser.IsLookingForTransfer ? 'Looking for Transfer' : 'None')}</span>
                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    <DropdownItem className="d-flex" onClick={() => {
                                        const { selectedUser } = this.state;
                                        selectedUser.IsCommited = false;
                                        selectedUser.IsLookingForTransfer = false;
                                        this.setState({ selectedUser });
                                    }}><span>None</span>
                                    </DropdownItem>

                                    <DropdownItem className="d-flex" onClick={() => {
                                        const { selectedUser } = this.state;
                                        selectedUser.IsCommited = true;
                                        selectedUser.IsLookingForTransfer = false;
                                        this.setState({ selectedUser });
                                    }}><span>Commited</span>
                                    </DropdownItem>

                                    <DropdownItem className="d-flex" onClick={() => {
                                        const { selectedUser } = this.state;
                                        selectedUser.IsCommited = false;
                                        selectedUser.IsLookingForTransfer = true;
                                        this.setState({ selectedUser });
                                    }}><span>Looking for Transfer</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        {/* Institution */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Institution</span>
                            </div>
                            <input defaultValue={this.state.selectedUser.CommitedTo} className={`form-control`} type="text" placeholder="Institution" onChange={e => {
                                const { selectedUser } = this.state;
                                selectedUser.CommitedTo = e.target.value;
                                this.setState({ selectedUser });
                            }} />
                        </div>
                    </div>
                    <button onClick={this.saveChanges} style={{ borderRadius: 30 }} className="btn btn-success mx-auto font-20 toBold mt-4 px-4">SAVE CHANGES</button>

                </div>
            }

            {/* PAGE 5: DONE */}
            {
                page === 5 && selectedUser && selectedUser.sports.length > 0 && <div style={{ position: 'fixed', top: 80, left: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column justify-content-center">
                    <i className="fas fa-check font-50 green align-self-center" />
                    <span className="align-self-center font-30 line1 black montserrat">Congratulations!</span>
                    <span className="align-self-center font-12 line1 black montserrat">You're all set</span>
                    <button onClick={() => { this.props.toggle(); this.setState({ page: 1 }); }} className="btn btn-success mt-4 align-self-center" style={{ borderRadius: 30, minWidth: 300 }}>Close</button>
                </div>
            }


            {/* PAGER */}
            <div style={{ position: 'fixed', bottom: 25, width: 220, height: 1, right: 15 }} className="bg-black" />
            <div style={{ position: 'fixed', bottom: 0, right: 0, height: 50, width: 250 }} className="d-flex flex-row">
                <div style={{ width: 50, height: 50 }} className="pointer d-flex flex-row justify-content-center ">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid black` }} className="align-self-center d-flex flex-row justify-content-center bg-white">
                        <span className={`align-self-center ${this.state.page === 1 ? 'font-14 blue' : 'font-10 black'}`}>1</span>
                    </div>
                </div>
                <div style={{ width: 50, height: 50 }} className="pointer d-flex flex-row justify-content-center ">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid black` }} className="align-self-center d-flex flex-row justify-content-center bg-white">
                        <span className={`align-self-center ${this.state.page === 2 ? 'font-14 blue' : 'font-10 black'}`}>2</span>
                    </div>
                </div>
                <div style={{ width: 50, height: 50 }} className="pointer d-flex flex-row justify-content-center ">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid black` }} className="align-self-center d-flex flex-row justify-content-center bg-white">
                        <span className={`align-self-center ${this.state.page === 3 ? 'font-14 blue' : 'font-10 black'}`}>3</span>
                    </div>
                </div>
                <div style={{ width: 50, height: 50 }} className="pointer d-flex flex-row justify-content-center ">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid black` }} className="align-self-center d-flex flex-row justify-content-center bg-white">
                        <span className={`align-self-center ${this.state.page === 4 ? 'font-14 blue' : 'font-10 black'}`}>4</span>
                    </div>
                </div>
                <div style={{ width: 50, height: 50 }} className="pointer d-flex flex-row justify-content-center ">
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: `1px solid black` }} className="align-self-center d-flex flex-row justify-content-center bg-white">
                        <span className={`align-self-center ${this.state.page === 5 ? 'font-14 blue' : 'font-10 black'}`}>5</span>
                    </div>
                </div>
            </div>

            {/* OVERLAY */}
            {this.state.modal && <div onClick={() => this.setState({ modal: false, adding_sport: false })} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'black', opacity: 0.5 }} />}

            {/* MODAL ADD SPORT */}
            <Animated animationInDuration={200} animationOutDuration={200} className="bg-white" animateOnMount={false} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.state.adding_sport ? true : false} style={{ position: 'fixed', top: 0, right: 0, bottom: 0, width: 400, overflowY: 'auto' }}>
                {selectedUser && adding_sport && <section className="flex-column justify-content-start">

                    <section className="p-4">
                        <h3>Sport Profile</h3>
                        <Dropdown isOpen={this.state.communities_open} toggle={() => this.setState({ communities_open: !this.state.communities_open })} className="font-16 no-padding bg-white black mt-2">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                <span className="black align-self-center">{(this.state.the_sport || { CommunityName: 'Select' }).CommunityName}</span>
                                <i className="ml-auto fas fa-caret-down align-self-center black" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {communities.map((c) => <DropdownItem key={c.IdCommunity} onClick={() => {
                                    const { the_sport } = this.state;
                                    this.setState({ the_sport: { ...the_sport, ...c, Position1: null, Position2: null } });
                                }}>
                                    {c.CommunityName || c.Name}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                        {/* Primary Position */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Primary Position</span>
                            </div>
                            <Dropdown isOpen={this.state.position1_open} toggle={() => this.setState({ position1_open: !this.state.position1_open })} className="font-16 no-padding bg-white black mt-2">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                    <span className="black align-self-center">{(this.state.the_sport || { Position1: 'Select' }).Position1 || 'Select'}</span>
                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {(this.state.the_sport.roles || []).map((c) => <DropdownItem key={c.Id} onClick={() => {
                                        const { the_sport } = this.state;
                                        the_sport.Position1 = c.Name;
                                        this.setState({ the_sport });
                                    }}>
                                        {c.Name}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        {/* Secondary Position */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Secondary Position</span>
                            </div>
                            <Dropdown isOpen={this.state.position2_open} toggle={() => this.setState({ position2_open: !this.state.position2_open })} className="font-16 no-padding bg-white black mt-2">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                    <span className="black align-self-center">{(this.state.the_sport || { Position2: 'Select' }).Position2 || 'Select'}</span>
                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {(this.state.the_sport.roles || []).map((c) => <DropdownItem key={c.Id} onClick={() => {
                                        const { the_sport } = this.state;
                                        the_sport.Position2 = c.Name;
                                        this.setState({ the_sport });
                                    }}>
                                        {c.Name}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Jersey Number</span>
                            </div>
                            {adding_sport && <input defaultValue={this.state.the_sport.JerseyNumber} className={`form-control`} type="text" placeholder="Jersey Number" onChange={e => {
                                const { the_sport } = this.state;
                                the_sport.JerseyNumber = e.target.value;
                                this.setState({ the_sport });
                            }} />}
                        </div>

                        {/* Highest Level Played */}
                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Highest Level Played</span>
                            </div>
                            <Dropdown isOpen={this.state.hlp_open} toggle={() => this.setState({ hlp_open: !this.state.hlp_open })} className="font-16 no-padding bg-white black mt-2">
                                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="form-control d-flex flex-row">
                                    <span className="black align-self-center">{(this.state.the_sport || { LevelPlayed: 'Select' }).LevelPlayed || 'Select'}</span>
                                    <i className="ml-auto fas fa-caret-down align-self-center black" />
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {(this.state.the_sport.levels_played || []).map((c) => <DropdownItem key={c.IdCommunityLevelPlayed} onClick={() => {
                                        const { the_sport } = this.state;
                                        the_sport.LevelPlayed = c.LevelPlayed;
                                        this.setState({ the_sport });
                                    }}>
                                        {c.LevelPlayed}
                                    </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <div className="mt-1">
                            <div className={`d-flex flex-row`}>
                                <span className="font-10 black align-self-center">Dominant Foot</span>
                            </div>
                            <div className="d-flex flex-row ">
                                <div onClick={() => {
                                    const { the_sport } = this.state;
                                    the_sport.DominantFoot = 'Left';
                                    this.setState({ the_sport });
                                }} className="w-100 d-flex flex-row justify-content-center pointer" style={{ position: 'relative' }}>
                                    <i className={`icon-x8-Foot-Left ${this.state.the_sport.DominantFoot === 'Left' ? 'green' : 'black'} font-60 align-self-center`} />
                                    <div style={{ position: 'absolute', left: 0, right: 0, top: 20 }} className="d-flex flex-column ">
                                        <span className="white align-self-center">L</span>
                                    </div>
                                </div>
                                <div onClick={() => {
                                    const { the_sport } = this.state;
                                    the_sport.DominantFoot = 'Right';
                                    this.setState({ the_sport });
                                }} className="w-100 d-flex flex-row justify-content-center pointer" style={{ position: 'relative' }}>
                                    <i className={`icon-x8-Foot-Right ${this.state.the_sport.DominantFoot === 'Right' ? 'green' : 'black'} font-60 align-self-center`} />
                                    <div style={{ position: 'absolute', left: 0, right: 0, top: 20 }} className="d-flex flex-column ">
                                        <span className="white align-self-center">R</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-4">
                            <button className="btn btn-info w-100" onClick={this.onAddSport}>Save Sport</button>
                            <button className="btn btn-link white w-100" onClick={() => this.setState({ adding_sport: null })}>Cancel</button>
                        </div>
                    </section>
                </section>}
            </Animated>

        </Animated >
    }
}

export default Wizzard;