import React from 'react';
import QuestionCard from './card';
import Masonry from 'react-masonry-component';
import { ItemTypes } from '../../../../../helpers';

const masonryOptions = {
  transitionDuration: 1
};

class RightSide extends React.Component {
  state = {
    activeTab: 1
  }
  changeTab = activeTab => this.setState({ activeTab });
  redraw = () => {
    this.forceUpdate();
  }
  render() {
    const { questionGroups } = this.props, { activeTab } = this.state;
    return (
      <div className="bg-white h-100" id="question-groups">
        <div className="px-3 pt-1 text-right">
          <span className="font-8">
            <i className="fa fa-asterisk red"></i> - Required questions</span>
        </div>

        <Masonry
          key="masonry"
          elementType={'ul'}
          className='d-flex flex-wrap mx-2'
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >

          {/* Questions */}
          {questionGroups && questionGroups.map((group, i) =>
            <div className="w-50 px-2" key={i}>
              <QuestionCard group={group} key={i} type={ItemTypes.QUESTIONGROUP} mode={activeTab} redraw={this.redraw} fnEdit={this.props.selectGroup}>{JSON.stringify(group)}</QuestionCard>
            </div>)}

          {/* No questions */}
          {(questionGroups && !questionGroups.length) && (
            <div className="p-4 w-100">
              <div className="jumbotron mx-auto my-0 text-center w-100">
                <i className="fas fa-exclamation-circle red font-60 mb-2" />
                <h2 className="line1">There are no Question Groups created</h2>
                <p>Click the plus sign above to create your first Registration Program</p>
              </div>
            </div>
          )}
        </Masonry>

      </div>);
  }
}

export default RightSide;
