import React from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { Animated } from 'react-animated-css';
import Menu from '../leagues/menu';

class Snapshot extends React.Component {

    state = {
        bootstrapped: true,
        league: null,
        mode: 2,
        all: true
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, league } = this.props;
        setTitle && setTitle({
            main: 'Validations',
            sub: league ? league.Name : null
        });
        this.props.fetchSeasons(params.idLeague);
        this.props.leagueActions && this.props.leagueActions.fetchValidationsSummary(params.idLeague);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.league !== this.props.league) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                main: 'Validations',
                sub: nextProps.league.Name || nextProps.league.LeagueName
            });
        }
    }
    //

    render() {

        const { idLeague } = this.props.params, { mode } = this.state, { validations_summary = {} } = this.props,
            { totals, league_summary = [] } = validations_summary;
        // TODO: save this 'mode' on localstorage so the back action takes us to the previous mode ? (or on the quuerystring ?)        

        let DOB = _.find(league_summary || [], v => v.ValidationProcessCode === 'DOBV') || {},
            COACH = _.find(league_summary || [], v => v.ValidationProcessCode === 'COACH') || {},
            SAFE = _.find(league_summary || [], v => v.ValidationProcessCode === 'SAFE') || {},
            CONC = _.find(league_summary || [], v => v.ValidationProcessCode === 'CONC') || {},
            FINGER = _.find(league_summary || [], v => v.ValidationProcessCode === 'FINGER') || {},
            BGC = _.find(league_summary || [], v => v.ValidationProcessCode === 'BGC') || {},
            MEDICAL = _.find(league_summary || [], v => v.ValidationProcessCode === 'MEDICAL') || {};

        return (
            <section className="">
                {/* LEFT MENU */}
                <Menu idLeague={idLeague} option="validations" />

                {/* TOP FILTERS */}
                <div className="w3-container">
                    <div className="montserrat w3-content centered-message d-flex flex-column justify-content-center h-100 p-4">
                        <h1>Validations</h1>
                        <ul className="align-self-center text-center d-flex flex-row mt-4 w-100">

                            <li className={`tile card shadow-box w-100 mx-1 py-4 ${mode === 0 ? 'bg-blue-dark white' : 'bg-white jeff_blue'}`} style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={DOB.ValidationProcessCode ? true : false} animationIn="bounceIn" animationInDelay={0} >
                                    <Link to={`/validations/${idLeague}/dob/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="fas fa-calendar font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">DOB Validations</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(DOB || {}).TotalUsersWithoutValidation}</span>
                                        <span className="font-8 align-self-center line1">Pending Approval</span>
                                        <div className="d-flex flex-row justify-content-center font-14 mt-4">
                                            {(DOB || {}).TotalUsers > 0 && <span className="align-self-center ml-auto mr-3 line1 font-10">{(DOB || {}).TotalUsers} Total</span>}
                                            {(DOB || {}).TotalUsers === 0 && <span className="align-self-center ml-auto mr-3 line1 font-10 red">Not applicable</span>}
                                        </div>
                                    </Link>
                                </Animated>
                            </li>

                            <li className="tile bg-white card shadow-box w-100 mx-1 py-3" style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={COACH.ValidationProcessCode ? true : false} animationIn="bounceIn" animationInDelay={200} >
                                    <Link to={`/validations/${idLeague}/coaches/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="fas fa-id-card font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">Coach's Certificate</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(COACH || {}).TotalUsersWithoutValidation}</span>
                                        <span className="font-8 align-self-center line1">Pending Approval</span>
                                        <div className="d-flex flex-row justify-content-center font-14 mt-4">
                                            {(COACH || {}).TotalUsers > 0 && <span className="align-self-center ml-auto mr-3 line1 font-10">{(COACH || {}).TotalUsers} Total</span>}
                                            {(COACH || {}).TotalUsers === 0 && <span className="align-self-center ml-auto mr-3 line1 font-10 red">Not applicable</span>}
                                        </div>
                                    </Link>
                                </Animated>
                            </li>

                            <li className="tile bg-white card shadow-box w-100 mx-1 py-3" style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={SAFE.ValidationProcessCode ? true : false} animationIn="bounceIn" animationInDelay={400} >
                                    <Link to={`/validations/${idLeague}/safe/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="fas fa-user-shield font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">Safe Sport</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(SAFE || {}).TotalUsersWithoutValidation}</span>
                                        <span className="font-8 align-self-center line1">Pending Approval</span>
                                        <div className="d-flex flex-row justify-content-center font-14 mt-4">
                                            {(SAFE || {}).TotalUsers > 0 && <span className="align-self-center ml-auto mr-3 line1 font-10">{(SAFE || {}).TotalUsers} Total</span>}
                                            {(SAFE || {}).TotalUsers === 0 && <span className="align-self-center ml-auto mr-3 line1 font-10 red">Not applicable</span>}
                                        </div>
                                    </Link>
                                </Animated>
                            </li>
                        </ul>

                        <ul className="align-self-center text-center d-flex flex-row mt-2 w-100">

                            <li className="tile bg-white card shadow-box w-100 mx-1 py-3" style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={CONC.ValidationProcessCode ? true : false} animationIn="bounceIn" animationInDelay={600}>
                                    <Link to={`/validations/${idLeague}/concussion/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="icon-concussion font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">Intro C&C</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(CONC || {}).TotalUsersWithoutValidation}</span>
                                        <span className="font-8 align-self-center line1">Pending Approval</span>
                                        <div className="d-flex flex-row justify-content-center font-14 mt-4">
                                            {(CONC || {}).TotalUsers > 0 && <span className="align-self-center ml-auto mr-3 line1 font-10">{(CONC || {}).TotalUsers} Total</span>}
                                            {(CONC || {}).TotalUsers === 0 && <span className="align-self-center ml-auto mr-3 line1 font-10 red">Not applicable</span>}
                                        </div>
                                    </Link>
                                </Animated>
                            </li>

                            <li className="tile bg-white card shadow-box w-100 mx-1 py-3" style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={BGC.ValidationProcessCode ? true : false} animationIn="bounceIn" animationInDelay={800} >
                                    <Link to={`/validations/${idLeague}/background/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="fas fa-search font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">Background Check</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(BGC || {}).TotalUsersWithoutValidation}</span>
                                        <span className="font-8 align-self-center line1">Pending Approval</span>
                                        <div className="d-flex flex-row justify-content-center font-14 mt-4">
                                            {(BGC || {}).TotalUsers > 0 && <span className="align-self-center ml-auto mr-3 line1 font-10">{(BGC || {}).TotalUsers} Total</span>}
                                            {(BGC || {}).TotalUsers === 0 && <span className="align-self-center ml-auto mr-3 line1 font-10 red">Not applicable</span>}
                                        </div>
                                    </Link>
                                </Animated>
                            </li>

                            <li className="tile bg-white card shadow-box w-100 mx-1 py-3" style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={FINGER.ValidationProcessCode ? true : false} animationIn="bounceIn" animationInDelay={1000} >
                                    <Link to={`/validations/${idLeague}/fingerprints/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="fas fa-fingerprint font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">Fingerprints</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(FINGER || {}).TotalUsersWithoutValidation}</span>
                                        <span className="font-8 align-self-center line1">Pending Approval</span>
                                        <div className="d-flex flex-row justify-content-center font-14 mt-4">
                                            {(FINGER || {}).TotalUsers > 0 && <span className="align-self-center ml-auto mr-3 line1 font-10">{(FINGER || {}).TotalUsers} Total</span>}
                                            {(FINGER || {}).TotalUsers === 0 && <span className="align-self-center ml-auto mr-3 line1 font-10 red">Not applicable</span>}
                                        </div>
                                    </Link>
                                </Animated>
                            </li>

                        </ul>

                        <ul className="align-self-center text-center d-flex flex-row mt-2 w-100">

                            <li className="tile bg-white card shadow-box w-100 mx-1 py-3" style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={totals ? true : false} animationIn="bounceIn" animationInDelay={1200} >
                                    <Link to={`/validations/${idLeague}/photos/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="fas fa-camera font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">Profile Images</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(totals || {}).UsersRegistered_Total}</span>
                                        <span className="font-8 align-self-center line1">Total Players and Coaches</span>
                                    </Link>
                                </Animated>
                            </li>

                            <li className="tile bg-white card shadow-box w-100 mx-1 py-3" style={{ borderRadius: 6 }}>
                                <Animated animateOnMount={false} isVisible={MEDICAL.ValidationProcessCode ? true : false} animationIn="bounceIn" animationInDelay={1400} >
                                    <Link to={`/validations/${idLeague}/medical/A`} className="w-100 h-100 d-flex flex-column justify-content-center jeff_blue">
                                        <div className="d-flex flex-row justify-content-center">
                                            <i className="fas fa-notes-medical font-20 align-self-center line1" />
                                            <span className="font-14 ml-2 align-self-center line1">Medical</span>
                                        </div>
                                        <span className="font-50 align-self-center line1 toBold mt-3">{(MEDICAL || {}).TotalUsersWithoutValidation}</span>
                                        <span className="font-8 align-self-center line1">Pending Approval</span>
                                        <div className="d-flex flex-row justify-content-center font-14 mt-4">
                                            {(MEDICAL || {}).TotalUsers > 0 && <span className="align-self-center ml-auto mr-3 line1 font-10">{(MEDICAL || {}).TotalUsers} Total</span>}
                                            {(MEDICAL || {}).TotalUsers === 0 && <span className="align-self-center ml-auto mr-3 line1 font-10 red">Not applicable</span>}
                                        </div>
                                    </Link>
                                </Animated>
                            </li>

                            <li className="w-100 mx-1 py-3" style={{ borderRadius: 6 }}>

                            </li>

                        </ul>

                    </div>
                </div>
            </section>
        )
    }
}

export default Snapshot;
