import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import LeftWorkArea from './Partials/left';
import LeftApplied from './Partials/left.questions';
import RightLibraryGroups from './Partials/right-library';
import RightAppliedGroups from './Partials/right-applied';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Loader from '../../../common/Loader';
import Wizzard from './Partials/wizzard';
import Grouper from './Partials/grouper';
import Navigation from '../navigation';
import './css.css';

class CustomQuestions extends React.Component {

  state = {
    bootstrapped: false,
    creating: false,
    wizzard: false,
    selectedGroup: null,
    activeTab: 1
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params, season } = this.props;
    setTitle && setTitle({
      sub: 'Custom Questions',
      main: season ? season.Name : null
    });
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Custom Questions',
        main: nextProps.season.Name || null
      });
      this.props.questionsActions &&
        this.props.questionsActions.fetchLibrary(nextProps.season.IdLeague);
      this.props.questionsActions && this.props.questionsActions.fetchAppliedQuestions(nextProps.season.IdSeason);
    }
    if (nextProps.appliedQuestions /* TODO: evaluate if there are questions also*/) {
      this.setState({ bootstrapped: true });
    }
  }
  //

  toggleCreating = () => this.setState({ creating: !this.state.creating, selectedGroup: null });
  toggleWizzard = () => this.setState({ wizzard: !this.state.wizzard });
  selectGroup = (group) => this.setState({ selectedGroup: group, creating: true });

  onArchive = (group) => {
    const { seasonActions, season } = this.props;
    seasonActions && seasonActions.archiveQuestionGroup(group.IdQuestionGroup, season.IdSeason);
  }

  renderTabs = () => {
    const { library } = this.props,
      { activeTab } = this.state;
    return <ul className="nav nav-tabs font-16">
      <li className="nav-item">
        <button className={`nav-link d-flex flex-row justify-content-center h-100 ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.setState({ activeTab: 1 })}>
          <i className="fas fa-arrow-left green align-self-center"></i>
          <span className="half-idented font-12 align-self-center">Applied Questions</span></button>
      </li>
      <li className="nav-item">
        <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => this.setState({ activeTab: 2 })}>
          <i className="fas fa-book"></i>
          {true ? <span className="half-idented font-12">Library Question Groups</span> : ''}</button>
      </li>
      {library && activeTab === 2 && <li className="ml-auto nav-item d-flex flex-row px-0">

        <button key="btnWizzard" type='button' className="btn btn-link blue" onClick={this.toggleWizzard}><i className="fas fa-book margin-right-half" />Library</button>

        <button key="btnGroups" type='button' className="btn btn-link px-0" onClick={this.toggleCreating}>
          <div className="btn btn-success" style={{ borderRadius: 20 }}><i className="fa fa-plus mr-2" />Add Question Group</div></button>

      </li>}
    </ul>
  }
  render() {
    const { divisions, library, season, league } = this.props,
      { bootstrapped, wizzard, creating, selectedGroup, activeTab } = this.state;

    return <section id="custom-questions" className="p-2">

      <div className="w3-container mt-4 h-100" style={{ position: 'relative' }}>

        {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
          <div className="w-100 black">
            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
          </div>
        </div>}

        <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
          <h5>Custom Questions</h5>
          {season && <Navigation season={season} />}
        </div>

        <div className=" m-auto p-0" style={{ maxWidth: 1285, position: 'absolute', top: 150, bottom: 0, left: 0, right: 0 }}>

          {divisions && bootstrapped && (
            <div key="with-data" className="d-flex align-items-stretch p-0">

              {/* LEFT */}
              <Animated animationIn='fadeInRightBig' animationInDuration={200} className={`bg-white w-${activeTab === 1 ? 50 : 50} p-0 m-0`} style={{ borderRight: '1px solid #002549', minHeight: 500 }}>
                <button className="btn w-100 btn-link btn-sm white sqwad-font toUpper" style={{ backgroundColor: '#4469c7' }}>This is your season</button>
                {activeTab === 1 && <LeftWorkArea {...this.props} />}
                {activeTab === 2 && <LeftApplied {...this.props} archiveGroup={this.onArchive} />}

              </Animated>

              {/* RIGHT */}
              <Animated animationInDelay={600} animationIn="fadeInRightBig" animationInDuration={200} className={`bg-white w-${activeTab === 1 ? 100 : 100} p-0 m-0`} style={{ minHeight: 500 }}>
                <button className="btn w-100 btn-link btn-sm white sqwad-font toUpper" style={{ backgroundColor: '#4469c7' }}>This is your library of questions</button>

                {/* TABS */}
                {this.renderTabs()}

                {/* RIGHT SECTIONS */}
                <div className="tab-content">

                  {activeTab === 1 && <RightAppliedGroups {...this.props} selectGroup={this.selectGroup}
                    archiveGroup={this.onArchive} />}
                  {activeTab === 2 && <RightLibraryGroups {...this.props} selectGroup={this.selectGroup} />}

                </div>
              </Animated>

            </div>
          )}
          {!bootstrapped && <Loader key="loader" message={`Loading Assets for Custom Questions building`} />}
        </div>
      </div>

      {wizzard && <Wizzard isOpen={wizzard} toggle={this.toggleWizzard} {...this.props} />}
      {creating && <Grouper group={selectedGroup} isOpen={creating} toggle={this.toggleCreating} {...this.props} modal={true} />}

    </section>
  }
}

export default DragDropContext(HTML5Backend)(CustomQuestions);
