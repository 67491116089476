import React from 'react';
import _ from 'lodash';
import TeamSelection from './TeamSelection';
import UserSelection from './UserSelection';
import Jersey from './Jersey';
import './css.css';
import config from '../../../config';

class Comp extends React.Component {

    state = {
        searchText: '',
        selectedPlayer: null
    }

    // Lifecycle
    componentWillMount() {
        const {setTitle, selectedTeam, selectedUser, family} = this.props;
        setTitle && setTitle({
            main: 'Roster',
            sub: selectedTeam ? selectedTeam.TeamName : null
        });

        // Fetch family teams for roster
        if (selectedUser && !selectedTeam && family && family.length) {
            this.props.familyActions && this.props.familyActions.selectUser(_.first(family));
            this.props.rosterActions && this.props.rosterActions.fetchTeams(_.first(family).IdUser);
        } else if (!selectedUser) {
            this.props.familyActions && this.props.familyActions.fetchMembers();
        }
    }

    componentWillReceiveProps = (nextProps) => {


        const {setTitle} = this.props;
        
        // If the selectedUser has changed, search the teams of that user
        if ((!this.props.selectedUser && nextProps.selectedUser) ||
            (this.props.selectedUser && this.props.selectedUser.IdUser !== nextProps.selectedUser.IdUser)) {
            this.props.rosterActions && this.props.rosterActions.fetchTeams(nextProps.selectedUser.IdUser);
        }

        // If the selectedTeam has changed, search its roster
        if (nextProps.selectedTeam &&
            ((this.props.selectedTeam || {}).IdTeamDivision !== nextProps.selectedTeam.IdTeamDivision)) {
               
            this.props.rosterActions && this.props.rosterActions.fetchRoster(_.first(nextProps.family).IdUser, nextProps.selectedTeam.IdTeamDivision);

            setTitle && setTitle({
                main: 'Roster',
                sub: nextProps.selectedTeam ? nextProps.selectedTeam.TeamName : null
            });
        }

    }
    //

    toogleTeamSelection = () => {
        this.props.teamActions && this.props.teamActions.toggleTeamSelection(false);
    }
    toggleUserSelection = () => {
        this.props.familyActions && this.props.familyActions.toggleUserSelection(false);
    }

    filter = () => {
        this.setState({
            searchText: this.txtSearch.value
        });
    }

    deselectPlayer = () => {
        this.setState({selectedPlayer: null});
    }
    selectPlayer = (player) => {
        this.setState({selectedPlayer: player});
    }

    render() {
        const {isTeamSelectionOpen, isUserSelectionOpen, roster, selectedUser, selectedTeam} = this.props,
            {searchText} = this.state;

        // Filtered roster against the search tool
        var filteredRoster = _.filter(roster || [], (item) => {
            if (!searchText) return true;
            return item.NameFirst.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                item.NameLast.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        });

        // This is because if the roster is locked, the API returns a single record with a HideRoster flag and a message.... (;...)
        var lockMessage = _.find(roster || [], (i) => {
            return i.HideRoster;
        });

        return (
            <section>
              { !roster && <div className="d-flex justify-content-center centered-message">
                             <h1 className="white align-self-center block text-center" key="loader"><i className="fa fa-spin fa-cog" /></h1></div> }
              { roster && roster.length && !lockMessage && (
                <div style={{marginBottom: '5em'}}>
                  <div className="right-actions d-flex flex-row-reverse" key="rightActions">
                    <div className="input-group input-group-sm">
                      <input defaultValue={ searchText } type="text" onChange={ this.filter } className="form-control" placeholder="Search..." ref={ (i) => this.txtSearch = i } />
                    </div>
                    
                    { selectedUser && selectedTeam && <a className=" btn btn-sm btn-link blue underlined" href={[config.apiEndpoint, 'api/v4/roster/download', selectedUser.IdUser, selectedTeam.IdTeamDivision].join('/')} download><i className="fa fa-download" /> Download Roster</a> }
                  </div>
                  <div className="container margin-bottom">
                    <ul id="roster" className="row" style={ { paddingTop: '3em' } }>
                      { filteredRoster.map((entity, i) => (
                            <li key={ i } className={ ` col-xs-12 col-sm-6 col-md-4 col-lg-3 hoverable pointer` } style={ { flexBasis: 'auto' } }>
                              <button onClick={() => this.selectPlayer(entity)}><Jersey entity={ entity } /></button>
                            </li>)) }
                    </ul>
                  </div>
                </div>) }
              { lockMessage && <div className="d-flex justify-content-center centered-message">
                                 <h1 className="white align-self-center block text-center" key="loader">{ lockMessage.HideRosterMessage }</h1></div> }
              { /* Modals */ }
              { isTeamSelectionOpen && <TeamSelection {...this.props} isOpen={ isTeamSelectionOpen } toggle={ this.toogleTeamSelection } /> }
              { isUserSelectionOpen && <UserSelection {...this.props} isOpen={ isUserSelectionOpen } toggle={ this.toggleUserSelection } /> }
              { roster && roster.length && !lockMessage && <button className="btn btn-success" style={{position: 'fixed', bottom: '1em', right: '1em'}}>Save Numbers</button> }
            </section>)
    }
}

export default Comp;