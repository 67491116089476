import {
    bindActionCreators
} from 'redux';
import {
    connect
} from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import Main from '../components/disclaimers/index';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        league: state.league,
        disclaimer: state.disclaimer,
        micro: state.micro,
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        leagueActions: bindActionCreators(leagueActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;