import React from 'react';
import './css.css';
import Email from './email';
import Phone from './phone';
import Loader from '../../common/Loader';
import BlackishHeader from '../../common/things/BlackishHeader';
import { generateRandomId } from '../../../helpers';
import fakeAuth from '../../../fakeAuth';

class Profile extends React.Component {

  state = {
    bootstrapped: false
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, family } = this.props;

    setTitle && setTitle({
      main: 'Profile',
      sub: 'Contact'
    });
    
    this.props.geoActions && this.props.geoActions.fetchCountryCodes();

    // Fetch family teams for roster
    if (!family) this.props.familyActions && this.props.familyActions.fetchMembers();
    this.props.familyActions && this.props.familyActions.fetchContact();
    this.props.fetchCarriers && this.props.fetchCarriers();
    
  }
  componentWillReceiveProps = (nextProps) => {
    const { country_codes } = nextProps;
    this.setState({ country_codes });
    if (nextProps.family && nextProps.emails && nextProps.phones) {
      this.setState({ bootstrapped: true });
    }
  }
  //

  addEmail = () => {
    this.props.familyActions &&
      this.props.familyActions.addEmail({
        Address: '',
        CanDelete: true,
        users: [],
        temporalId: generateRandomId(10)
      });
  }

  addPhone = () => {
    this.props.familyActions &&
      this.props.familyActions.addPhone({
        CanDelete: true,
        users: [{ IdUser: fakeAuth.getUser().IdUser }],
        temporalId: generateRandomId(10)
      });
  }

  render() {
    const { family, emails, phones, familyActions } = this.props, { bootstrapped, country_codes } = this.state;
    return <div id="profile">
      
      <div className="p-3 d-flex flex-row font-10 white bg-danger" key="leftActions">
        <i className="align-self-center fas fa-exclamation-triangle margin-right-half text-warning font-20" />
        <span className="align-self-center">Only select roster athletes, coaches and admins for communication. If you're not part of a team, even though you might be adding 'your' email and/or phone number, do NOT select yourself but rather the person in your family that's rostered to a team</span>
      </div>

      {bootstrapped && emails && phones && country_codes && <div style={{ top: '4.4em' }} className="bottom-action-area d-flex flex-row">
        <div className=" w-100">
          <BlackishHeader message="Emails" />
          <div className="p-2">
            <button onClick={this.addEmail} className="btn btn-info ml-auto margin-bottom-half"><i className="fa fa-plus margin-right-half" />Add Email</button>
            {emails && emails.map && emails.map((email, i) => <Email email={email} key={i} family={family} familyActions={familyActions} />)}
          </div>
        </div>
        <div className=" w-100" style={{ borderLeft: '1px solid gray' }}>
          <BlackishHeader message="Phones" />
          <div className="p-2">
            <button onClick={this.addPhone} className="btn btn-info ml-auto margin-bottom-half"><i className="fa fa-plus margin-right-half" />Add Phone</button>
            {phones && phones.map && phones.map((phone, i) => <Phone country_codes={country_codes} carriers={this.props.carriers} family={family} phone={phone} key={i} familyActions={familyActions} />)}
          </div>
        </div>
      </div>}
      {!bootstrapped && <Loader message="Please wait" />}
    </div>
  }
}

export default Profile;
