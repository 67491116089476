import React from 'react';

class Roster extends React.Component {
  render() {
    const { header, value, cn } = this.props;
    return <div className={`roster-box margin-right-half d-flex flex-row justify-content-center ${cn}`}>
      <span className="align-self-center">{value || header}</span>
    </div>
  }
}

export default Roster;