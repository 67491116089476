import React from 'react';
import Item from './Item';
import _ from 'lodash';

class List extends React.Component {
  render() {
    const { title, divisions, fnSelect, familyRegistration = [] } = this.props;
    return (
      <div style={{ maxWidth: 600 }} className="m-auto w-100">
        <span className="block black font-12 mt-2 toBold">{title}</span>
        <ul>
          {divisions && divisions.map((division, i) => {
            if (_.find(familyRegistration, r => r.IdDivision === division.IdDivision)) { 
              return (
                <li key={i}>
                  <div className="w-100 text-left">
                    <Item division={division} familyRegistration={familyRegistration} />
                  </div>
                </li>)
            } else {
              return (
                <li key={i}>
                  <button className="w-100 text-left" onClick={() => fnSelect && fnSelect(division)}>
                    <Item division={division} familyRegistration={familyRegistration} />
                  </button>
                </li>)
            }
          })}</ul>
      </div>)
  }
}

export default List;