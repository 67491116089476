import _ from 'lodash';
function reducer(state = [], action) {
    switch (action.type) {
        case 'CLIPS/SET':
            return action.clips;
        case 'CLIPS/CLEAR':
            return null;
        case 'CLIPS/UPDATE':
            let index = _.findIndex(state, v => v.idVideo === action.clip.idVideo);
            if (index !== -1)
                return [...state.slice(0, index), action.clip, ...state.slice(index + 1)]
            return [...state, action.clip];
        case 'VIDEOS/UPDATE':
            let index2 = _.findIndex(state, v => v.idVideo === action.video.idVideo);
            if (index2 !== -1)
                return [...state.slice(0, index2), action.video, ...state.slice(index2 + 1)]
            return [...state];
        default:
            return state;
    }
}

export default reducer;
