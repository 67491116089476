import React from 'react';
import LeftMenu from './menu/LeftMenu';
import paths from '../ioc/paths';
import Header from './common/headers/Header.Admin2';
import fakeAuth from '../fakeAuth';
//import Nav from './navigation/Nav';
import Spotlight from './common/modals/Spotlight';

class Main extends React.Component {

  state = {
    menuHidden: localStorage.getItem("MENU.HIDDEN") === 'true' || false
  }

  componentWillMount = () => {
    if (fakeAuth.isAuthenticated()) {
      this.props.login(fakeAuth.getCredentials());
      this.props.familyActions &&
        this.props.familyActions.fetchMembers();
    } else {
      fakeAuth.signout(() => {
        this.props.router.push('/login'); //from.pathname);
      });
    }
  }

  hideMenu = () => {
    const { menuHidden } = this.state;
    this.setState({ menuHidden: !menuHidden }, () => {
      localStorage.setItem("MENU.HIDDEN", this.state.menuHidden);
    });
  }

  toggleSpotlight = () => {
    const { spotlightOn } = this.props;
    this.props.spotlightActions &&
      this.props.spotlightActions[spotlightOn ? 'close' : 'open']();
  }

  render() {
    const { menuHidden } = this.state,
      { spotlightOn, params } = this.props, { idLeague } = params;       

    return (
      <section className="">
        <Header {...this.props} paths={paths} spot={idLeague ? true : false} />
        {/*<Nav />*/}
        <section id="main-content">
          <LeftMenu paths={paths} {...this.props} />
          <section id="content-wrapper" className={`${menuHidden ? 'hidden' : ''}`}>
            {React.cloneElement(this.props.children, this.props)}
          </section>
        </section>
        
        {spotlightOn && <Spotlight isOpen={spotlightOn} toggle={this.toggleSpotlight} {...this.props} />}
      </section>

    );
  }
}

export default Main;
