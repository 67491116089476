import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getCountriesSaga() {
    yield* takeEvery("COUNTRIES/FETCH", function* (action) {

        try {
            const result = yield call(function () {
                const url = [config.apiEndpoint, 'api/v4/geo/countries'].join('/');

                return request.get(url)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });

            if (result && result.success) {
                yield put({
                    type: 'COUNTRIES/SET',
                    countries: result.countries
                });

            } else {
                yield put({
                    type: 'COUNTRIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getCountrieCodesSaga() {
    yield* takeEvery("COUNTRY_CODES/FETCH", function* (action) {

        try {
            const result = yield call(function () {
                const url = [config.apiEndpoint, 'api/v1/ioc/country_codes'].join('/');

                return request.get(url)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            });

            if (result && result.success) {
                yield put({
                    type: 'COUNTRY_CODES/SET',
                    country_codes: result.country_codes
                });

            } else {
                yield put({
                    type: 'COUNTRY_CODES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COUNTRY_CODES/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getStatesSaga() {
    yield* takeEvery("STATES/FETCH", function* (action) {

        try {
            const result = yield call(function (idCountry) {
                const url = [config.apiEndpoint, 'api/v4/geo/states', idCountry].join('/');

                return request.get(url)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action.idCountry);

            if (result && result.success) {
                yield put({
                    type: 'STATES/SET',
                    states: result.states
                });

            } else {
                yield put({
                    type: 'STATES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'STATES/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getTimeZonesSaga() {
    yield* takeEvery("TIME_ZONES/FETCH", function* (action) {

        try {
            const result = yield call(() => {
                const url = [config.apiEndpoint, 'api/v4/geo/time_zones'].join('/');

                return request.get(url)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action);

            if (result && result.success) {                
                yield put({
                    type: 'TIME_ZONES/SET',
                    time_zones: result.time_zones
                });

            } else {
                yield put({
                    type: 'TIME_ZONES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TIME_ZONES/FETCH_FAILED',
                e
            });
        }
    });
}