import React from 'react';
import moment from 'moment';

class CustomDatePickerLabel extends React.Component {

    render() {
        const { value, className, format = 'ddd, MMM Do YYYY' } = this.props;
        return (
            <div onClick={this.props.onClick} className={`card tile bg-white p-3 d-flex flex-row ${className}`}>
                <span className="w-100">
                    {value ? moment(this.props.value, format).format(format) : 'Select Date'}
                </span>
                <i className="icon-calendar ml-auto gray font-16" />
            </div>
        )
    }
}

/*CustomDatePicker.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string
};*/

export default CustomDatePickerLabel;