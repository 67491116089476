import React from 'react';
import Card from './card';
import Masonry from 'react-masonry-component';
import { ItemTypes } from '../../../../../../helpers';

const masonryOptions = {
  transitionDuration: 0
};

class Zip extends React.Component {

  render() {
    const { zipCodesAdjustments, fnAddZipCodeAdjustment, fnEditZipCodeAdjustment } = this.props;
    return <section>
      
      <div id="registration-fees">
        <Masonry
          className={'registration-fees'} // default ''
          elementType={'ul'} // default 'div'
          options={masonryOptions} // default {}
          disableImagesLoaded={false} // default false
          updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        >
          {zipCodesAdjustments && zipCodesAdjustments.map((program, i) => <Card program={program} key={i} type={ItemTypes.ZIPCODE} fnEdit={fnEditZipCodeAdjustment} />)}
        </Masonry>
        {(!zipCodesAdjustments || !zipCodesAdjustments.length) && (
          <div className="jumbotron text-center margin-top">
            <h1 className="display-3">There are no Zip Code Adjustments</h1>
            <p className="lead">Click the plus sign above to create your first Zip Code Adjustments</p>
          </div>)}
      </div>

      <div className="d-flex flex-row" style={{ position: 'absolute', top: 10, right: 15 }}>
        <button onClick={fnAddZipCodeAdjustment} type="button" style={{ border: '2px solid #2cad3c', borderRadius: 20, width: 40, height: 40 }} className="green align-self-center btn-block d-flex flex-row justify-content-center">
          <i className="fa fa-plus font-20 align-self-center" /></button>
      </div>
    </section>
  }
}

export default Zip;
