import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import request from 'superagent';
import config from '../../config';
import 'react-datepicker/dist/react-datepicker.css';
import { Animated } from 'react-animated-css';
import Menu from '../leagues/menu';
import DobItem from './partials/dob_item';
import ConcussionItem from './partials/concussion_item';
import SafeItem from './partials/safe_item';
import BgcItem from './partials/background_check_item';
import CoachItem from './partials/coach_item';
import FingerItem from './partials/fingerprint_item';
import FileModal from './partials/file_modal';

class UserValidations extends React.Component {

    state = {
        bootstrapped: true,
        league: null,
        mode: 2,
        user: null,
        picture_request: false
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, league } = this.props;
        setTitle && setTitle({
            main: 'Validations',
            sub: league ? league.Name : null
        });
        this.props.fetchSeasons(params.idLeague);
        this.props.leagueActions && this.props.leagueActions.fetchUser(params.idUser, params.idLeague);
        this.props.leagueActions && this.props.leagueActions.fetchValidationsSummary(params.idLeague);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.league !== this.props.league) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                main: 'Validations',
                sub: nextProps.league.Name || nextProps.league.LeagueName
            });
        }
        this.setState({ user: nextProps.user });
    }

    componentWillUnmount = () => {
        this.props.leagueActions && this.props.leagueActions.clearUser()
    }
    //

    bringFileUp = (file) => this.setState({ file });

    onBack = () => {
        this.props.router.goBack && this.props.router.goBack();
        //this.props.router.push('/family');
        //this.props.router.pop();
    }

    onRequestPictureChange = () => {
        const { params } = this.props;
        this.setState({ picture_request: {} }, () => {
            request.post(`${config.apiEndpoint}/api/v4/users/request/replacement/${params.idUser}/${params.idLeague}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({})
                .then(() => { });
        });
    }

    onChangeName = () => {
        const { user } = this.state;
        user.NameFirst = this.txtName.value || user.NameFirst;
        user.NameLast = this.txtLastName.value || user.NameLast;
        user.AliasNameFirst = this.txtAKAName.value || user.AliasNameFirst;
        user.AliasNameLast = this.txtAKALastName.value || user.AliasNameLast;
        this.setState({ changing_name: false, user }, () => {
            request.patch(`${config.apiEndpoint}/api/v4/users/name/${this.props.params.idUser}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({
                    firstName: user.NameFirst,
                    lastName: user.NameLast,
                    aliasNameFirst: user.AliasNameFirst,
                    aliasNameLast: user.AliasNameLast,
                    idLeague: this.props.params.idLeague
                })
                .then(() => { });
        });
    }

    render() {

        const { idLeague } = this.props.params,
            { user, picture_request } = this.state,
            { validations_summary = {} } = this.props,
            { totals } = validations_summary;

        return (
            <section className="">
                {/* LEFT MENU */}
                <Menu idLeague={idLeague} option="validations" />

                {user && <div style={{ marginLeft: 90 }}>
                    {/* Back Arrow */}
                    <div className="bg-blue-dark">
                        <button className="btn btn-sm btn-link white" onClick={this.onBack}><i className="fas fa-arrow-left mr-2" />Back</button>
                    </div>

                    {/* User Info */}
                    <Animated animationIn="fadeInDown" className="w-100 p-2 bg-blue-dark d-flex flex-row">

                        <div className="d-flex flex-row h-100 p-2 sans w-100">

                            {/* User Image */}
                            <div style={{ width: 120 }} className="align-self-center">
                                <div className="cover align-self-center" style={{ width: 120, height: 120, borderRadius: 60, background: `url(${(user.UserImage || user.UserImageUrl)}?full=120) no-repeat center center` }} />
                                {!picture_request && <div className="mt-1" onClick={this.onRequestPictureChange}><i className="white fas fa-refresh pointer" title="Request a photo change" /></div>}
                                {picture_request && <div className="d-flex flex-row mt-1"><i className="yellow fas fa-refresh align-self-center" title={`Photo change requested by ${picture_request.NameFirst} on ${moment(picture_request.DateCreated).format('MMM Do YYYY')}`} /><span className="ml-2 align-self-center yellow font-8">Change Requested</span></div>}
                            </div>

                            {/* User Info */}
                            <div className="d-flex flex-column justify-content-center ml-3">

                                {!this.state.changing_name && <span className="toUpper toBold font-16 white">{user.NameFirst} {user.NameLast} <i className="fas fa-pencil font-8 align-self-center white ml-2" onClick={() => this.setState({ changing_name: true })} /></span>}
                                {this.state.changing_name && <div className="d-flex flex-row mb-1">
                                    <input className="form-control" ref={i => this.txtName = i} defaultValue={user.NameFirst} />
                                    <input className="form-control" ref={i => this.txtLastName = i} defaultValue={user.NameLast} />
                                    <button className="btn btn-success" onClick={this.onChangeName}><i className="white fas fa-check" /></button>
                                </div>}
                                {this.state.changing_name && <div className="d-flex flex-row mb-1">
                                    <input className="form-control" ref={i => this.txtAKAName = i} defaultValue={user.AliasNameFirst} />
                                    <input className="form-control" ref={i => this.txtAKALastName = i} defaultValue={user.AliasNameLast} />
                                    <button className="btn btn-link font-6 white" onClick={this.onChangeName}>AKA</button>
                                </div>}

                                {!this.state.changing_name && <span className="font-10 white">Legal: {`${user.AliasNameFirst || user.NameFirst} ${user.AliasNameLast || user.NameLast}`}</span>}

                                <span className="font-10 white">Gender: {user.Gender}</span>
                                <span className="font-10 white">DOB: {moment(user.DateBirth.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}{' '}<span className="font-7 align-self-center">({moment().diff(user.DateBirth, 'years')} yrs)</span></span>
                                <span className="white">{user.FullPhoneNumber || user.PhoneNumber_Format || user.IdPhoneNumber}</span>
                                {user.EmailAddress && <a href={`mailto:${user.EmailAddress}`}><span className="blue">{user.EmailAddress}</span></a>}
                            </div>
                        </div>
                    </Animated>

                    <div className="w3-container">
                        <div className="w3-content " style={{ maxWidth: 1250 }}>
                            <h1 className="mt-4 mb-4">Validations</h1>

                            <div className="d-flex flex-row">
                                {_.chain(user.validations)
                                    .filter(v => ['DOBV', 'CONC', 'SAFE'].indexOf(v.ValidationProcessCode) !== -1)
                                    .value().map((val, i) => <section className="w-100 px-1" key={i}>
                                        {val.ValidationProcessCode === 'DOBV' && <DobItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-DOBV-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}
                                        {val.ValidationProcessCode === 'CONC' && <ConcussionItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-CONC-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}
                                        {val.ValidationProcessCode === 'SAFE' && <SafeItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-SAFE-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}
                                    </section>)}
                            </div>

                            <div className="d-flex flex-row mt-2">
                                {_.chain(user.validations)
                                    .filter(v => ['BGC', 'COACH', 'FINGER'].indexOf(v.ValidationProcessCode) !== -1)
                                    .value().map((val, i) => <section className="w-100 px-1" key={i}>
                                        {val.ValidationProcessCode === 'BGC' && <BgcItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-BGC-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}
                                        {val.ValidationProcessCode === 'COACH' && <CoachItem licences={totals} tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-COACH-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}
                                        {val.ValidationProcessCode === 'FINGER' && <FingerItem tile={true} leagueActions={this.props.leagueActions} key={`${user.IdUser}-FINGER-${i}`} user={val} idLeague={idLeague} onFile={(file) => this.bringFileUp(file)} />}
                                    </section>)}
                            </div>

                        </div>
                    </div>
                </div>}

                <FileModal isOpen={this.state.file ? true : false} file={this.state.file} toggle={() => this.setState({ file: null })} {...this.props} />
            </section>
        )
    }
}

export default UserValidations;
