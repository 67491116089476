import React from 'react';
import { ItemTypes } from '../../../../helpers';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';

const teamSource = {
    beginDrag(props) {
        return props;
    }
};

const teamTarget = {
    canDrop(props) {
        // TODO: look if this can be customized
        return true;
    },
    drop(props, monitor) {
        // PROPS is the target, MONITOR is the source

        // If its on the same flight and different position, sort
        /*if (props.team.IdFlight === monitor.getItem().team.IdFlight && props.index !== monitor.getItem().index) {
            props.sortTeams && props.sortTeams({
                from: monitor.getItem().index, // source
                to: props.index, // target
            });
            return;
        }

        // If its on a different flight, move
        if (props.team.IdFlight !== monitor.getItem().team.IdFlight) {

            props.moveTeam && props.moveTeam({
                source: monitor.getItem().team,
                to: props.index,
                of: props.team.IdFlight
            });
            return;ß
        }*/

    }
}

class GroupedTeam extends React.Component {

    render() {
        const { connectDragSource, team, isOver, removeTeam, index, connectDropTarget, groupIndex } = this.props; // isDragging

        return connectDragSource(connectDropTarget(
            <div className={`d-flex flex-row p-1 ${isOver ? "drop-target" : ''}`} key={team.IdTeam}>
                <span className="align-self-center font-10">{team.Name || team.TeamName}</span>
                {team.IdTeamDivision && (
                    <i className="fa fa-times red ml-auto align-self-center" onClick={() => removeTeam && removeTeam(groupIndex, index)} />
                )}
            </div>))
    }
}

export default flow(
    DropTarget(ItemTypes.FLIGHTEDTEAMS, teamTarget, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver()
    })),
    DragSource(ItemTypes.FLIGHTEDTEAMS, teamSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }))
)(GroupedTeam);