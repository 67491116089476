import React from 'react';

const Soon = () => {
  return (
    <div className="d-flex justify-content-center centered-message">
      <h1 className="align-self-center block text-center white">This section is under construction!<br />Will be ready soon.</h1>
    </div>
  );
}

export default Soon;
