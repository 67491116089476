module.exports = {
  leagues: {
    path: '/leagues',
    name: "Leagues",
    icon: "shield-alt",
    subs: {
      league: {
        name: "Leagues"
      }
    }
  },
  /*message_center: {
    path: '/message_center',
    name: "Message Center",
    icon: "comments",
    condition: 'idLeague',
    conditions: ['idLeague']
  },*/
  /*my_documents: {
    path: '/my_documents',
    name: "My Documents",
    icon: "folder-open"
  },*/
  /*dob: {
    path: '/dob',
    name: 'DoB Validation',
    icon: 'fas fa-birthday-cake',
    condition: 'idLeague',
    conditions: ['idLeague', 'letter'],
  },*/
  /*reports: {
    path: '/reports',
    name: "Reports",
    icon: 'chart-bar'
  },*/
  organization_settings: {
    path: '/organization_settings',
    name: "Organization Settings",
    icon: "cogs",
    adminOnly: true
  }/*, coupons: {
    path: '/coupons',
    name: "Coupons",
    icon: "ticket-alt"
  }/*,
  leads: {
    path: '/leads',
    name: 'Leads',
    icon: 'list-ul'
  }*/
};