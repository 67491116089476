import _ from 'lodash';

function reducer(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'ANSWERS/SET':
            return action.answers;
        case 'ANSWERS/ADD':
            return [...(state || []), action.answer];
        case 'ANSWERS/REMOVE':
            index = _.findIndex(state, answer => { return (answer.IdQuestionLibraryOption || answer.id) === action.id });
            if (index !== -1) {
                return [...state.slice(0, index), ...state.slice(index + 1)];
            } else return state;
        case 'ANSWERS/UPDATE':
            index = _.findIndex(state, answer => { return answer.id === (action.answer.id || action.answer.IdQuestionLibraryOption) });
            if (index !== -1) {
                return [...state.slice(0, index), action.answer, ...state.slice(index + 1)];
            } else return state;
        case 'QUESTIONS/UNSELECT_ALL':
            state && state.forEach(q => {
                q.selected = false;
            });
            return state;
        default:
            return state;
    }
}

export default reducer;
