import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as teamActions from '../redux-sagas/actions/teamActions';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import Login from '../components/login/Login';
import _ from 'lodash';

const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn,
        auth: state.auth, 
        league: state.league
    }
}

const mapDispatchToProps = dispatch => {
    return _.extend({
        familyActions: bindActionCreators(familyActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        leagueActions: bindActionCreators(leagueActions, dispatch),
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Login);

export default App;
