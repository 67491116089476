export function fetchAutoPay(idSeason) { return { type: 'REPORT/AUTO_PAY/FETCH', idSeason } }
export function fetchRegistration(idSeason, mode) { return { type: 'REPORT/REGISTRATION/FETCH', idSeason, mode } }
export function fetchDonations(idSeason) { return { type: 'REPORT/DONATIONS/FETCH', idSeason } }
export function fetchSnapshot(idSeason, mode, days) { return { type: 'REPORT/REGISTRATION/SNAPSHOT/FETCH', idSeason, mode, days } }
export function fetchAccounting(idSeason) { return { type: 'REPORT/ACCOUNTING/FETCH', idSeason } }
export function fetchTeams(idSeason) { return { type: 'REPORT/TEAMS/FETCH', idSeason } }
export function fetchTeamsRosters(idSeason) { return { type: 'REPORT/TEAMS/ROSTERS/FETCH', idSeason } }
export function fetchRosters(idSeason) { return { type: 'REPORT/ROSTERS/FETCH', idSeason } }
export function fetchGames(idSeason) { return { type: 'REPORT/GAMES/FETCH', idSeason } }
export function fetchTransactions(idSeason) { return { type: 'REPORT/TRANSACTIONS/FETCH', idSeason } }
export function fetchShirtsReport(idSeason) { return { type: 'REPORT/SHIRTS/FETCH', idSeason } }
export function fetchAdminsRegistration(idSeason) { return { type: 'REPORT/TEAM_ADMINS/FETCH', idSeason } }
export function fetchMasterTransactions(idSeason) { return { type: 'REPORT/MASTER_TRANSACTIONS/FETCH', idSeason } }
export function clearReport() { return { type: 'REPORT/CLEAR' } }