

import React from 'react';
import moment from 'moment';

class CustomDatePicker extends React.Component {

    render() {
        const { value, className } = this.props;
        return (
            <button type="button" className={`btn btn-info ${className || 'btn-sm'}`} onClick={this.props.onClick}>
                {value ? moment(this.props.value).format('MMM Do YYYY') : 'Select Date'}
            </button>
        )
    }
}

/*CustomDatePicker.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string
};*/

export default CustomDatePicker;