import React from 'react';
import config from '../../../config';
import { Input } from 'reactstrap';
import _ from 'lodash';

const { genders } = config;

class EditableName extends React.Component {
  state = {
    editing: false,
    team: null
  }
  componentWillMount() {
    this.setState({ team: this.props.team });
  }
  toggle = () => {
    this.setState({ editing: !this.state.editing });
  }
  save = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { team } = this.state;
    if (this.txt.value) {
      team.Name = team.TeamName = this.txt.value;
      // TODO: call api
      this.props.teamActions && this.props.teamActions.updateTeam(team.IdTeamDivision, team.Name, team.IdGender);
      this.toggle();
    }
  }

  selectGender = e => {
    const { team } = this.state;
    const gender = _.find(genders, gender => gender.IdGender === parseInt(e.target.value, 10));
    if (gender) {
      team.IdGender = gender.IdGender;
      team.Gender = gender.Name;
    }
    this.setState({ team }, () => { });
  }

  render() {
    const { editing, team } = this.state;
    return <div className="w-100 d-flex flex-row justify-content-center">
      {!editing && <div className="d-flex flex-column">
        <span className="toUpper toBold font-16 white">{team.TeamName} <i onClick={this.toggle} className="fa fa-pencil-alt blue margin-left-half" /></span>
        <span className="font-10 white">Gender: {team.Gender}</span>        
      </div>}
      {editing && (
        <form>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Team's Name" defaultValue={team.Name || team.TeamName} ref={(i) => this.txt = i} />
            <div className="input-group-append">
              <button onClick={this.save} className="btn btn-success" type="submit" ><i className="fa fa-check white" /></button>
            </div>
          </div>
          <div className="input-group mb-3">
            <Input type="select" value={team.IdGender} onChange={this.selectGender}>
              {genders && genders.map((gender, i) => <option key={i} value={gender.IdGender}>{gender.Name}</option>)}
            </Input>
          </div>
        </form>)
      }
    </div>
  }
}

export default EditableName;
