import React from 'react';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import LeftWorkArea from './partials/left';
import RightWorkArea from './partials/right';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Loader from '../../../common/Loader';
import AddRegistrationProgram from './partials/registration_programs/popup';
import AddRegistrationProgramTeam from './partials/registration_programs_teams/popup';

import AddAdjustment from './partials/adjustments/popup';
import AddZipCodeAdjustment from './partials/zip/popup';
import AddTryoutAdjustment from './partials/tryouts/popup';
import AddFamilyAdjustment from './partials/family/popup';
import Navigation from '../navigation';
import moment from 'moment';
import { Alert } from 'reactstrap';
import Bundle from './partials/bundle';

import './css.css';

class RegistrationFees extends React.Component {

  state = {
    bootstrapped: false,
    addingRegistrationProgram: false,
    addingAdjustment: false,
    addingZipAdjustment: false,
    addingTryoutAdjustment: false,
    addingFamilyAdjustment: false,
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Registration | Fees',
      main: this.props.season ? this.props.season.Name : null
    });

    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
    this.props.paymentsActions && this.props.paymentsActions.fetchPrograms(params.idSeason);
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Registration | Fees',
        main: nextProps.season.Name || null
      });
    }
    if (nextProps.divisions && nextProps.paymentPlans) {
      this.setState({ bootstrapped: true });
    }
  }
  componentWillUnmount() {
    this.props.paymentsActions.clearPrograms();
  }
  //

  toggleAddRegistrationProgram = () => {
    const { season } = this.props;
    this.props.setGeneric && this.props.setGeneric({
      start: moment(season.DateStart),
      end: moment(season.DateEnd),
      base: 0,
      fee: 0,
      late: 0,
      initial: 0
    });
    this.props.paymentsActions.setDues([]);
    this.setState({
      addingRegistrationProgram: !this.state.addingRegistrationProgram
    });
  }
  toggleAddRegistrationProgramTeam = () => {
    const { season } = this.props;
    this.props.setGeneric && this.props.setGeneric({
      start: moment(season.DateStart),
      end: moment(season.DateEnd),
      base: 0,
      fee: 0,
      late: 0,
      initial: 0
    });
    this.props.paymentsActions.setDues([]);
    this.setState({
      addingRegistrationProgramTeam: !this.state.addingRegistrationProgramTeam
    });
  }
  toggleEditRegistrationProgram = (program) => {
    this.props.setGeneric && this.props.setGeneric({
      id: program.IdCatalogItem,
      start: moment(program.DateRegistrationStart),
      end: moment(program.DateRegistrationEnd),
      base: program.Price,
      fee: program.paymentPlans.length ? _.first(program.paymentPlans).FeeTransactionInitial : 0, // TODO
      late: program.FeeLate,
      initial: program.InitialPayment, // TODO,
      name: program.Name
    });
    this.props.paymentsActions.setDues(_.map(program.paymentPlans, (pp) => {
      return {
        DateDue: moment(pp.Date),
        ...pp
      }
    }));
    this.setState({
      addingRegistrationProgram: !this.state.addingRegistrationProgram
    });
  }

  toggleAddAdjustment = () => {
    this.props.setGeneric && this.props.setGeneric({
      start: moment(),
      end: moment().add(1, 'month'),
      base: 0
    });
    this.setState({
      addingAdjustment: !this.state.addingAdjustment
    });
  }
  toggleEditAdjustment = (program) => {
    this.props.setGeneric && this.props.setGeneric({
      start: moment(program.DateStart),
      end: moment(program.DateEnd),
      plus: program.Price < 0 ? false : true,
      base: program.Price < 0 ? program.Price * -1 : program.Price,
      name: program.Name,
      id: program.IdCatalogItemPriceAdjustmentByDate
    });
    this.setState({
      addingAdjustment: !this.state.addingAdjustment
    });
  }

  toggleAddZipCodeAdjustment = () => {
    this.props.setGeneric && this.props.setGeneric({
      base: 0,
      fee: 0
    });
    this.props.paymentsActions && this.props.paymentsActions.setZipCodes([{}]);
    this.setState({
      addingZipAdjustment: !this.state.addingZipAdjustment
    });
  }
  toggleEditZipCodeAdjustment = (program) => {
    this.props.setGeneric && this.props.setGeneric({
      id: program.IdZipCodeGroup,
      name: program.ZipCodeGroupName,
      base: program.codes && program.codes.length ? (_.first(program.codes).Amount * (_.first(program.codes).Amount < 0 ? -1 : 1)) : 0,
      fee: 0,
      selected: (program.codes && program.codes.length) ? true : false,
      plus: (program.codes && program.codes.length) ? (_.first(program.codes).Amount < 0 ? false : (_.first(program.codes).Amount > 0 ? true : null)) : null
    });
    this.props.paymentsActions && this.props.paymentsActions.setZipCodes(_.map(program.codes, code => { return { code: code.ZipCode, ...code } }));
    this.setState({
      addingZipAdjustment: !this.state.addingZipAdjustment
    });
  }


  toggleAddFamilyAdjustment = () => {
    this.props.setGeneric && this.props.setGeneric({

    });
    this.props.paymentsActions.setDues([]);
    this.setState({
      addingFamilyAdjustment: !this.state.addingFamilyAdjustment
    });
  }
  toggleEditFamilyAdjustment = (program) => {

    this.props.setGeneric && this.props.setGeneric({
      name: program.Name,
      id: program.IdCatalogItemPriceAdjustmentByItemIndexGroup
    });
    this.props.paymentsActions.setDues(_.map(program.items, item => {
      return { ...item, base: Math.abs(item.Amount), child: item.ItemIndex, id: item.IdCatalogItemPriceAdjustmentByItemIndex }
    }));
    this.setState({
      addingFamilyAdjustment: !this.state.addingFamilyAdjustment
    });
  }

  toggleAddTryoutAdjustment = () => {
    this.props.setGeneric && this.props.setGeneric({
      start: moment(),
      end: moment().add(1, 'month'),
      base: 0,
      fee: 0
    });
    this.setState({
      addingTryoutAdjustment: !this.state.addingTryoutAdjustment
    });
  }
  toggleEditTryoutAdjustment = (program) => {
    this.props.setGeneric && this.props.setGeneric({
      id: program.Id,
      start: moment(program.StartDate),
      end: moment(program.EndDate),
      name: program.Name
    });
    this.setState({
      addingTryoutAdjustment: !this.state.addingTryoutAdjustment
    });
  }
  onDivision = (args) => {
    this.setState({ overlay: true, bundle: args })
  }

  render() {
    const { divisions, season, league } = this.props,
      { bootstrapped, addingRegistrationProgram, addingAdjustment,
        addingZipAdjustment, addingTryoutAdjustment, addingFamilyAdjustment,
        error, updated } = this.state;

    return <section id="registration-fees" style={{ overflow: 'hidden' }}>

      <div className="w3-container mt-4 h-100" style={{ position: 'relative' }}>

        {/* ALERT */}
        <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
          <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
            {this.state.error}
          </Alert>
          <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
            Season updated!
          </Alert>
        </div>

        {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
          <div className="w-100 black">
            <h3 style={{ display: 'block', maxLines: 1, height: 35, overflow: 'hidden', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{season.Name || season.SeasonName}</h3>
            <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
          </div>
        </div>}

        <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
          <h5>Administration</h5>
          {season && <Navigation season={season} />}
        </div>

        <div className="m-auto pt-4 w-100" style={{ maxWidth: 1285, position: 'absolute', top: 150, bottom: 0, left: 0, right: 0 }}>
          {divisions && bootstrapped && (
            <div key="with-data" className="d-flex flex-row h-100" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>

              <Animated animationIn="fadeInRightBig" animationInDuration={200} className="no-margin no-padding bg-white" style={{ width: 500, overflowY: 'auto' }}>
                <LeftWorkArea {...this.props} onDivision={this.onDivision} />
              </Animated>

              <Animated animationIn="fadeInRightBig" animationInDelay={600} animationInDuration={200} className="w-100 no-margin no-padding bg-white" style={{ borderLeft: '1px solid gray' }}>
                <RightWorkArea {...this.props}
                  fnAddRegistrationProgramTeams={this.toggleAddRegistrationProgramTeam}
                  fnAddRegistrationProgram={this.toggleAddRegistrationProgram}
                  fnAddAdjustment={this.toggleAddAdjustment}
                  fnAddZipCodeAdjustment={this.toggleAddZipCodeAdjustment}
                  fnAddFamilyAdjustment={this.toggleAddFamilyAdjustment}
                  fnAddTryoutAdjustment={this.toggleAddTryoutAdjustment}
                  fnEditRegistrationProgram={this.toggleEditRegistrationProgram}
                  fnEditAdjustment={this.toggleEditAdjustment}
                  fnEditZipCodeAdjustment={this.toggleEditZipCodeAdjustment}
                  fnEditTryoutAdjustment={this.toggleEditTryoutAdjustment}
                  fnEditFamilyAdjustment={this.toggleEditFamilyAdjustment} />
              </Animated>

            </div>
          )}
          {!bootstrapped && <Loader key="loader" message={`Loading Assets for Schedule Building`} />}
        </div>
      </div>

      { /* Popup for Programs */}
      {addingRegistrationProgram && <AddRegistrationProgram {...this.props} isOpen={addingRegistrationProgram} toggle={this.toggleAddRegistrationProgram} />}
      {this.state.addingRegistrationProgramTeam && <AddRegistrationProgramTeam {...this.props} isOpen={this.state.addingRegistrationProgramTeam} toggle={() => this.setState({ addingRegistrationProgramTeam: false })} />}
      {addingAdjustment && <AddAdjustment {...this.props} isOpen={addingAdjustment} toggle={this.toggleAddAdjustment} />}
      {addingZipAdjustment && <AddZipCodeAdjustment {...this.props} isOpen={addingZipAdjustment} toggle={this.toggleAddZipCodeAdjustment} />}
      {addingTryoutAdjustment && <AddTryoutAdjustment {...this.props} isOpen={addingTryoutAdjustment} toggle={this.toggleAddTryoutAdjustment} />}
      {addingFamilyAdjustment && <AddFamilyAdjustment {...this.props} isOpen={addingFamilyAdjustment} toggle={this.toggleAddFamilyAdjustment} />}



      {this.state.overlay && <div onClick={() => this.setState({ overlay: false })} className={`overlay ${this.state.overlay ? 'show' : ''}`} />}
      <Animated animationIn="slideInRight" animateOnMount={false} animationOut="slideOutRight" isVisible={this.state.overlay ? true : false} style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: 500, zIndex: 1041 }} className="bg-white" >
        <Bundle bundle={this.state.bundle} {...this.props} />
      </Animated>

    </section>
  }
}

export default DragDropContext(HTML5Backend)(RegistrationFees);
