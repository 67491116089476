function reducer(state = [], action) {

    switch (action.type) {
        case 'ROSTER/TEAMS/SET':
            return action.teams;
        case 'ROSTER/TEAMS/CLEAR':
        return null;
        default:
            return state;
    }
}

export default reducer;