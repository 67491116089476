import React from 'react';
import _ from 'lodash';


class Comp extends React.Component {
    state = {
        entity: null
    }

    // Lifecycle
    componentWillMount() {
        const {entity} = this.props;
        this.setState({
            entity
        });
    }
    componentWillReceiveProps = (newProps) => {
        this.setState({
            entity: newProps.entity
        });
    }
    //

    errorImage = () => {
        const {entity} = this.state;
        entity.Image = entity.ImageUrl = (entity.IdGender === 2 ? '/images/defaults/female.png' : '/images/defaults/male.png');
        this.setState({
            entity
        });
    }

    handleChangeNumber = () => {
        const { entity } = this.state;
        entity.JerseyNumber = this.txtNumber.value;
        this.setState({entity});
    }

    render() {
        const {entity} = this.state;
        var isCoach = false;
        return (
        <div className={ `d-flex flex-row tile ${isCoach ? 'bg-success' : 'bg-gray-dark'}` } style={ { position: 'relative' } }>
                 <div className="cover" style={ { width: '100px', height: '100px', background: ['url(',(entity.Image || entity.ImageUrl), ') no-repeat center center'].join('') } } />
                 <div className={ ` white p-2 d-flex flex-column justify-content-center` }>
                   <span className="font-12 margin-bottom-half" style={ { lineHeight: '14px' } }>{ [_.first(entity.NameFirst.split(' ')), _.first(entity.NameLast.split(' '))].join(' ') }</span>
                   <div className="d-flex flex-row justify-content-center">
                    <i className="fa fa-hashtag big-font blue" />
                    <input defaultValue={entity.JerseyNumber} onChange={this.handleChangeNumber} type="number" className="form-control control-lg text-center" style={{width: 80}} ref={(i) => this.txtNumber = i } />
                   </div>
                   </div>
                 { /* This is a hack */ }
                 <img alt="" onError={ this.errorImage } className="m-auto" src={ (entity.Image || entity.ImageUrl) } style={ { width: '1px', height: '1px', position: 'absolute' } } />
               </div>)
    }
}

export default Comp;