import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Loader from '../../../common/Loader';
import _ from 'lodash';
import Lists from './partials/Lists';

class Comp extends React.Component {

  state = {
    bootstrapped: false,
    selectedDivision: null,
    isDivisionsOpen: false,
    fetchingCoaches: false,
    coaches: null
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Coach Assign',
      main: this.props.season ? this.props.season.Name : null
    });

    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {

    if (nextProps.season !== this.props.season) {

      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Coach Assign',
        main: nextProps.season.Name || null
      });

      // Fetch the league roles
      this.props.leagueActions &&
        this.props.leagueActions.fetchRoles(nextProps.season.IdLeague);
    }

    if (nextProps.divisions && !this.state.bootstrapped) {
      this.setState({
        bootstrapped: true,
        selectedDivision: _.first(nextProps.divisions)
      }, () => {
        this.fetchQuestionsOfDivision();
        this.fetchCoachesOfDivision();
      });
    }

    if (nextProps.coaches) {
      this.setState({
        coaches: nextProps.coaches,
        fetchingCoaches: false
      });
    }
  }

  componentWillUnmount = () => {
    this.props.clearDivisions && this.props.clearDivisions();
    this.props.divisionsActions.clearCoaches && this.props.divisionsActions.clearCoaches(); // TODO: change this one to season actions
    this.props.divisionsActions.clearQuestions && this.props.divisionsActions.clearQuestions();
  }
  //

  fetchQuestionsOfDivision = () => {
    const { selectedDivision } = this.state;
    this.props.divisionsActions && this.props.divisionsActions.fetchQuestions && this.props.divisionsActions.fetchQuestions(selectedDivision.IdDivision, 2);
  }

  fetchCoachesOfDivision = () => {
    const { selectedDivision } = this.state;
    this.props.divisionsActions &&
      this.props.divisionsActions.fetchCoaches(selectedDivision.IdDivision);
  }

  toggleDivisions = () => {
    this.setState({
      isDivisionsOpen: !this.state.isDivisionsOpen
    });
  }

  selectDivision = (selectedDivision) => {
    this.setState({
      selectedDivision,
      isDivisionsOpen: false
    }, () => {
      this.fetchCoachesOfDivision();
      this.fetchQuestionsOfDivision();
    });
  }

  render() {

    const { bootstrapped, selectedDivision, fetchingCoaches } = this.state,
      { divisions, coaches } = this.props;

    let filteredDivisions = divisions; //_.reject(divisions, d => d.IsTryout);

    return (
      <ReactCSSTransitionGroup component="section" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
        transitionEnter={true} transitionLeave={false}>
        { /* Top left actions */}
        {bootstrapped && (
          <div className="left-actions d-flex flex-row">
            { /* Select division */}
            <span className="align-self-center white margin-right-half">Division</span>
            <Dropdown isOpen={this.state.isDivisionsOpen} toggle={this.toggleDivisions}>
              <DropdownToggle caret className="btn-info btn-sm btn">
                {selectedDivision.Name} (
                {selectedDivision.TotalCoaches} requests)
              </DropdownToggle>
              <DropdownMenu>
                {filteredDivisions && filteredDivisions.map((division, i) => (
                  <DropdownItem key={i} onClick={() => this.selectDivision(division)}>
                    {division.Name} ({division.TotalCoaches} requests)
                    </DropdownItem>))}
              </DropdownMenu>
            </Dropdown>
          </div>)}
        { /* Top Right Actions */}
        {bootstrapped && (
          <div className="right-actions d-flex flex-row-reverse">

          </div>)}
        { /* List */}
        {bootstrapped && selectedDivision && coaches && (
          <Lists {...this.props} selectedDivision={selectedDivision} fetchingCoaches={fetchingCoaches} />)}
        {bootstrapped && coaches && !coaches.length && (
          <div key="no-teams" className="d-flex flex-col justify-content-center centered-message">
            <h1 className="white align-self-center block text-center">{selectedDivision.Name}{' '}have Coaches in it.</h1>
          </div>)}
        { /* Loader */}
        {(!bootstrapped || fetchingCoaches) && <Loader message="Fetching Assets" />}
      </ReactCSSTransitionGroup>)
  }
}

export default Comp;
