import React from 'react';
import RegistrationCards from './registration_programs';
import RegistrationCardsTeams from './registration_programs_teams';
import AdjustmentCards from './adjustments';
import ZipCodeAdjustmentsCards from './zip';
import FamilyAdjustmentsCards from './family';
import TryoutAdjustmentsCards from './tryouts';

class Right extends React.Component {

  state = {
    activeTab: 1
  }

  changeTab = (tab) => {
    this.setState({
      activeTab: tab
    });
    this.props.notifyTabChange &&
      this.props.notifyTabChange(tab)
  }

  render() {
    const { activeTab } = this.state, { season } = this.props;
    return (<div className="bg-white full-height right-side">
      <ul className="nav nav-tabs font-16">
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}><i className="fas fa-address-card blue-dark"></i> {activeTab === 1 ? <span className="half-idented">Registration Programs</span> : ''}</button>
        </li>
        {season && season.IdSeasonType === 5 && <li className="nav-item">
          <button className={`nav-link ${activeTab === 6 ? 'active' : ''}`} onClick={() => this.changeTab(6)}><i className="fas fa-shield red"></i> {activeTab === 6 ? <span className="half-idented">Registration Programs (Teams)</span> : ''}</button>
        </li>}
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}><i className="fas fa-wrench purple"></i> {activeTab === 2 ? <span className="half-idented">Adjustments</span> : ''}</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}><i className="fas fa-map-marked-alt blue"></i> {activeTab === 3 ? <span className="half-idented">Zip Code Discounts</span> : ''}</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={() => this.changeTab(4)}><i className="fas fa-users-cog green"></i> {activeTab === 4 ? <span className="half-idented">Family Discounts</span> : ''}</button>
        </li>
      </ul>
      <div className="tab-content">
        {activeTab === 1 && <RegistrationCards {...this.props} />}
        {activeTab === 6 && <RegistrationCardsTeams {...this.props} />}
        {activeTab === 2 && <AdjustmentCards {...this.props} />}
        {activeTab === 3 && <ZipCodeAdjustmentsCards {...this.props} />}
        {activeTab === 4 && <FamilyAdjustmentsCards {...this.props} />}
        {activeTab === 5 && <TryoutAdjustmentsCards {...this.props} />}
      </div>
    </div>);
  }
}

export default Right;
