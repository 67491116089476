import _ from 'lodash';
function reducer(state = [], action) {
    switch (action.type) {
        case 'FAMILY/VIDEOS/SET':            
            return action.videos;
        case 'FAMILY/VIDEOS/CLEAR':
            return null;
        case 'FAMILY/VIDEOS/UPDATE':
            let index = _.findIndex(state, v => v.idVideo === action.video.idVideo);
            if (index !== -1)
                return [...state.slice(0, index), action.video, ...state.slice(index + 1)]
            return [...state, action.video];
        default:
            return state;
    }
}

export default reducer;
