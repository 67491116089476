import React from 'react';
import _ from 'lodash';
import moment from 'moment';

class Sheet extends React.Component {

  state = { team: null, qualifies: true }

  // Lifecycle
  componentWillMount() {
    const { team, season } = this.props;
    this.setState({ team, season });
  }
  componentWillReceiveProps = nextProps => {
    const { team, season } = nextProps;
    this.setState({ team, season });
  }
  //

  render() {
    const { team } = this.state, { exp, title, league } = this.props;
    let chunks = _.chunk([...team.coaches, ...team.players], 5);
    return <section>
      {chunks.map((chunk, i) => <section key={i} className="letter sheet margin-top ml-auto mr-auto black font-10 p-3" style={{ overflow: 'hidden', width: 800 }}>
        {chunk.map((player, j) => <div className="d-flex flex-row bordered" key={j}>
          <div className="w-50 p-1">
            <div className="w-100 d-flex flex-row" style={{ position: 'relative' }}>
              <div className="w-25 bordered" style={{ height: 110, background: `url('${player.Image}?full=90') center center no-repeat`, backgroundSize: 'cover' }} />
              <div className="w-75 px-2 d-flex flex-column">
                <span className="pl-2  w-100 font-3 mt-1"><b>{`${player.NameFirst} ${player.NameLast}`.toUpperCase()}</b></span>
                <span className="pl-2  w-100 font-2 mt-2"><b>ID:</b> {player.IdUser}</span>
                <span className="pl-2  w-100 font-2"><b>DOB:</b> {moment(player.DateBirth, 'MM/DD/YYYY').format('YYYY')}</span>
                <span className="pl-2  w-100 font-2"><b>TEAM:</b> {`${team.Name || team.TeamName}`.toUpperCase()}</span>
                <span className="pl-2  w-100 font-2"><b>AGE GROUP:</b> {team.DivisionAgeGroupName || 'N/A'}</span>
                <span className="pl-2  w-100 font-2"><b>EXPIRES:</b> {exp && exp.format && exp.format('MM/DD/YYYY')}</span>
              </div>
              <img alt="" src="https://api.sportslogic.net/images/SAY/logo.png" style={{ height: 30, position: 'absolute', bottom: 0, right: 0 }} />
            </div>
            <div className="w-100 pt-1 d-flex flex-column">
              <span className=" w-100 text-center font-4 toBold">{title || '<MISSING SEASON TITLE>'}</span>
              <span className=" w-100 text-right font-1 toBold">{league || ''}</span>
              <span className=" w-100 text-right font-1 toBold">{`${player.RoleOverrideName}`.toUpperCase()}</span>
            </div>
          </div>
          <div className="w-50 px-3 py-1">
            <div className="text-center">
              <img alt="" src="https://api.sportslogic.net/images/SAY/logo.png" style={{ height: 50, margin: 'auto' }} />
            </div>
            <p className="text-center font-1 line1">
              This pass is valid for any SAY Soccer event including participation in unrestricted USSF event at or
              above this age division and accompanied by an official roster. A player signature is not required.
              Valid only for SAY Soccer approved purposes.
            </p>
            <div className="d-flex flex-row">
              <div className="w-50 text-center">
                <img alt="" src="https://api.sportslogic.net/images/SAY/signature.png" style={{ height: 70, margin: 'auto' }} />
              </div>
              <div className="w-50 text-center">
                <img alt="" src="https://api.sportslogic.net/images/SAY/USSF.png" style={{ height: 60, margin: 'auto' }} />
              </div>
            </div>
          </div>
        </div>)}
      </section>)
      }
    </section>
  }
}

export default Sheet;
