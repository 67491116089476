function reducer(state = [], action) {    
    switch (action.type) {
        case 'COLLEGE_PROFILE/SET':
            return action.profile;
        case 'COLLEGE_PROFILE/CLEAR':
            return null;        
        default:
            return state;
    }
}

export default reducer;
