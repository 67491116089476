import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RosterSize from './roster_size';

class RosterPopup extends React.Component {

  state = {
    sizes: null
  }

  componentWillMount() {
    this.setState({ sizes: this.props.sizes });
  }
  
  render() {
    const { isOpen, toggle, team, sizes } = this.props;
    return <Modal isOpen={isOpen} style={{ width: 600 }}>
      <ModalHeader toggle={toggle} className="bg-info white">{(team || {}).TeamName}</ModalHeader>
      <ModalBody className="d-flex flex-column p-2">
        {team.Coaches.map((coach, i) => <RosterSize sizes={sizes} entity={coach} key={i} rosterActions={this.props.rosterActions} />)}
        <hr />
        {team.Players.map((player, i) => <RosterSize sizes={sizes} entity={player} key={i} rosterActions={this.props.rosterActions} />)}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-success">Save{' '}<i className="fa fa-arrow-right m-l-7" /></button>
      </ModalFooter>
    </Modal>
  }
}

export default RosterPopup;
