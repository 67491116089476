import React from 'react';
import { Link } from 'react-router';
import fakeAuth from '../../../fakeAuth';
import { Popover, PopoverBody, } from 'reactstrap';
import config from '../../../config';

class Header extends React.Component {
  state = {
    user: null
  }

  // Lifecycle
  componentWillMount() {
    this.setState({ user: fakeAuth.getUser() });
  }
  componentWillReceiveProps() {
    this.setState({ user: fakeAuth.getUser() });
  }
  //

  logout = () => {
    const { router } = this.props;
    fakeAuth.signout(() => {
      this.props.logout && this.props.logout();
      router.push('/login');
    });
  }

  openSpotlight = () => {
    this.props.spotlightActions &&
      this.props.spotlightActions.open();
  }

  errorUserImage = () => {
    const { user } = this.state;
    user.UserImage = '/images/defaults/male.png';
    this.setState({ user });
  }

  renderButton = (key) => {
    const { params, paths } = this.props, path = paths[key];

    if (path.condition && !params[path.condition]) {
      return <span key={key} />
    }

    if (path.adminOnly) {
      const user = fakeAuth.getUser();
      if (config.adminUsers.indexOf(user.IdUser) === -1) return <span key={key} />
    }

    return (
      <Link key={key} to={`/${key}`} className="link-off" activeClassName="link-on">
        {/*<i className={`fa fa-${path.icon} block big-font`} />*/}{path.name}
      </Link>
    );
  }

  render() {

    const { auth, title, spot, paths } = this.props, { user } = this.state;

    return (
      <header className="d-flex flex-row align-items-center montserrat bg-blue-dark">
        <div key="1" className="mr-auto d-flex flex-row align-items-center">
          { /* Logo */}
          <Link to="/leagues" id="logo-sqwad" className="d-flex flex-column justify-content-center">
            <i className="icon-logo-header bigest-font white block text-center align-self-center line1"></i>
            <span className="font-8 white align-self-end" style={{ marginTop: -15, marginRight: 27 }}>By Sports Logic</span>
          </Link>

          {Object.keys(paths)
            .map(this.renderButton)}
        </div>

        {/* Right side */}
        {auth && auth.loggedIn && <Link key="2" className="btn btn-link white mr-2" to="/family">Family Center</Link>}

        {/* Search */}
        {spot && <button onClick={this.openSpotlight} style={{ width: 50, height: 50, borderRadius: 25, border: `2px solid white` }} className="d-flex flex-row justify-content-center">
          <i className="fa fa-search white font-18 align-self-center" />
        </button>}

        <button onClick={() => this.setState({ sub: !this.state.sub })} id="btn-avatar-top" style={{ width: 50, height: 50, borderRadius: 25, border: `2px solid white`, background: `url(${user.UserImage}) no-repeat center center` }} className="d-flex flex-row justify-content-center cover ml-4 mr-4" />

        <Popover placement="bottom" isOpen={this.state.sub} target={`btn-avatar-top`} toggle={() => this.setState({ sub: !this.state.sub })}>
          <PopoverBody className="d-flex flex-column justify-content-center">
            <hr />
            <button className="btn btn-link btn-link" onClick={this.logout}>Logout</button>
          </PopoverBody>
        </Popover>

      </header>
    );
  }
}

export default Header;
