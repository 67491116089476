// a reducer takes in 2 things:

// 1: the action (info about what happened)

// 2: a copy of the current state

function states(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'STATES/SET':
            return action.states;
        case 'STATES/CLEAR':
            return [];
        default:
            return state;
    }
}

export default states;