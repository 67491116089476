import React from 'react';
import _ from 'lodash';
import moment from 'moment';

class FamilySubscriptions extends React.Component {

    state = { family_subscriptions: [] }

    // Lifecycle
    componentWillMount() {
        const { family_subscriptions } = this.props;
        this.setState({ family_subscriptions: _.flatten(family_subscriptions) })
    }
    componentWillReceiveProps = nextProps => {
        const { family_subscriptions } = nextProps;
        this.setState({ family_subscriptions: _.flatten(family_subscriptions) })
    }
    //

    render() {
        const { family_subscriptions } = this.state;
        return <section>
            {family_subscriptions.map((s, i) => <div key={i} className="p-3 card shadow w-100 d-flex flex-row">
                <span className="align-self-center font-20 black">{s.FamilyLastName}</span>
                <div className="d-flex flex-column ml-auto text-right align-self-center">
                    <span className="font-14 line1">{s.PlanName}</span>
                    <span className="font-8 line1">Expires at {moment(s.EndDate).format('MMM Do YYYY')}</span>
                </div>
            </div>)}
        </section>
    }
}

export default FamilySubscriptions;
