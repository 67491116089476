import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Masonry from 'react-masonry-component';
import { ItemTypes } from '../../../../../helpers';
import Card from './card';

const masonryOptions = {
  transitionDuration: 1
};

class RightSide extends React.Component {

  state = {
    activeTab: 1
  }

  changeTab = activeTab => this.setState({ activeTab });

  redraw = () => this.forceUpdate();

  render() {
    const { locks } = this.props;
    return (
      <div className="bg-white full-height right-side" id="lock-rosters">

        <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
          transitionEnter={true} transitionLeave={false}>

          <Masonry
            key="masonry"
            className={'lock-rosters p-2'}
            elementType={'ul'}
            options={masonryOptions}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
          >
            {/* Questions */}
            {locks && locks.length > 0 && locks.map((lock, i) =>
              <Card locksActions={this.props.locksActions} index={i} lock={lock} key={i} type={ItemTypes.ROSTERLOCK}></Card>)}

            {/* No questions */}
            {locks && locks.length === 0 && (
              <div className="jumbotron text-center margin-top">
                <h1 className="display-3">There are no scheduled Locks for now</h1>
              </div>
            )}
          </Masonry>

        </ReactCSSTransitionGroup>
      </div>);
  }
}

export default RightSide;
