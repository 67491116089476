import React from 'react';
import ExceptionRule from './ExceptionRule';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import _ from 'lodash';

class TimeExceptions extends React.Component {

    addRule = () => {
        this.props.exceptionRulesActions.addTimeExceptionRule &&
        this.props.exceptionRulesActions.addTimeExceptionRule({
            IdSeason: this.props.params.idSeason,
            Name: 'Matchup Exception',
            TeamA: {},
            TeamB: {},
            type: 'exception/matchup'
        });
    }

    render() {
        const { exceptionRules } = this.props;
        var matchupExceptions = _.filter(exceptionRules, function(rule){ return rule.type === 'exception/matchup'; });
        return (
            <div>
              <div className="d-flex flex-row">
                {/*<button className="btn btn-success ml-auto align-self-center" onClick={ () => this.addRule() }><i className="fa fa-plus" /></button>*/}
              </div>
              <div>
                <ReactCSSTransitionGroup component="ul" className="child clearfix exceptions-rule-list text-center" transitionName="example" transitionAppear={ true }
                  transitionAppearTimeout={ 500 } transitionEnterTimeout={ 500 } transitionEnter={ true } transitionLeave={ false }>
                  { matchupExceptions && matchupExceptions.map((rule, i) => <ExceptionRule key={ i } index={ i } rule={ rule } exceptionRulesActions={this.props.exceptionRulesActions} flightedTeams={this.props.flightedTeams} />) }
                </ReactCSSTransitionGroup>
                { !matchupExceptions.length && (
                  <div className="jumbotron text-center margin-top">
                    {/*<h1 className="display-3">There are no Matchup Exception rules</h1>
                    <p className="lead">Click the plus sign above to create your first Matchup Exception rule</p>*/}
                    <h1>Soon</h1>
                  </div>) }
              </div>
              
            </div>
        )
    }
}

export default TimeExceptions;