import React from 'react';
import _ from 'lodash';
import { Animated } from 'react-animated-css';

class Entity extends React.Component {
    state = {
        entity: null
    }

    // Lifecycle
    componentWillMount() {
        const { entity } = this.props;
        this.setState({ entity });
    }
    componentWillReceiveProps = (newProps) => {
        this.setState({ entity: newProps.entity });
    }
    //

    errorImage = () => {
        const { entity } = this.state;
        entity.Image = entity.ImageUrl = (entity.IdGender === 2 ? '/images/defaults/female.png' : '/images/defaults/male.png');
        this.setState({ entity });
    }

    render() {
        const { entity } = this.state, { selectedTeam, index } = this.props;
        const isCoach = entity.IdRole === 4 || (entity.overrides && _.find(entity.overrides, (o) => { return o.IdRole === 4; }));
        return (
            <Animated animationIn="fadeInLeftBig" animationInDelay={50 * index} className={`d-flex flex-row tile ${isCoach ? 'bg-success' : 'bg-darker-blue'}`} style={{ minHeight: 120 }}>

                {/* Avatar */}
                <div className="cover" style={{ width: '150px', background: ['url(', (entity.Image || entity.ImageUrl), ') no-repeat center center'].join('') }} />

                {/* Information */}
                <div className={`line1 white p-2 d-flex flex-column justify-content-center w-100`}>
                    <span className="font-12 toUpper">{[_.first(entity.NameFirst.split(' ')), _.first(entity.NameLast.split(' '))].join(' ')} {(entity.JerseyNumber !== null) && <span className="font-14">
                        <i className="fa fa-hashtag white font-8" /><span className="white font-8">{entity.JerseyNumber}</span>
                    </span>}</span>
                    {(entity.RoleOverrideName || entity.Role) && !entity.roles && <span className={`font-8 ${isCoach ? 'white' : 'green'}`}>{(entity.RoleOverrideName || entity.Role)}</span>}
                    {entity.roles && entity.roles.length > 0 && <span className={`font-8 ${isCoach ? 'white' : 'green'}`} >{entity.roles.join(', ')}</span>}
                    <i className="margin-top-half" />
                    {entity.PhoneNumber_Format && <span className={`margin-top-half font-8 ${isCoach ? 'white' : 'white'}`}>{entity.PhoneNumber_Format}</span>}
                    {entity.EmailAddress && <span className={`font-8 ${isCoach ? 'white' : 'white'}`}>{entity.EmailAddress}</span>}
                    {entity.IsArchived && <span className={`font-8 white pt-2`}>(ARCH)</span>}
                    {!isCoach && <div className="d-flex flex-row font-8 margin-top">
                        <span>Valid DOBs: {entity.IsValid ? 'Yes' : 'No'}</span>
                        {selectedTeam && selectedTeam.IdLeague === 11826 && <span className="ml-auto">Waiver: {entity.IdWaiver ? 'Yes' : 'No'}</span>}
                    </div>}
                </div>
                { /* This is a hack to load placeholders on image error ! */}
                <img alt="" onError={this.errorImage} className="m-auto" src={(entity.Image || entity.ImageUrl)} style={{ width: '1px', height: '1px', position: 'absolute' }} />
            </Animated>)
    }
}

export default Entity;