import React from 'react';
import moment from 'moment';
import fakeAuth from '../../../fakeAuth';
import _ from 'lodash';

class PhotoItem extends React.Component {
    state = {}
    componentWillMount() {
        const { user } = this.props;
        if (user.replacements && user.replacements.length) {
            user.InvalidPhoto = true;
        }
        this.setState({ user });
    }
    requestReplacement = () => {
        const { user } = this.state, { idLeague } = this.props;

        user.InvalidPhoto = !user.InvalidPhoto;
        user.replacements = [{
            NameFirst: fakeAuth.getUser().NameFirst,
            NameLast: fakeAuth.getUser().NameLast,
            DateCreated: moment()
        }]
        this.props.leagueActions &&
            this.props.leagueActions[user.InvalidPhoto ? 'request_picture_replacement' : 'delete_picture_replacement'](user.IdUser, idLeague);
        this.setState({ user });
    }

    render_one = () => {
        const { user } = this.state;
        return <figure className="float-left card shadow d-flex flex-column h-100 mx-2 mt-4" style={{ width: 190, overflow: 'hidden', borderRadius: 10 }}>
            <div style={{ width: 190, height: 190, backgroundColor: 'black', overflow: 'hidden' }}>
                <img src={`${user.ImageUrl}?full=100`} className="w-100" loading="lazy" />
            </div>            
            <div className="p-2 d-flex flex-column justify-content-center" style={{ height: 80 }}>
                <span className="font-8 align-self-center line1 text-center">{`${user.NameFirst}`}</span>
                <span className="font-10 align-self-center line1 text-center">{user.NameLast}</span>
                <span className="font-8 align-self-center line1 text-center gray">ID: {user.IdUser}</span>
                {user.RoleName && <span className="font-8 mt-1 align-self-center line1 text-center">({user.RoleName})</span>}
            </div>
            {!user.InvalidPhoto && <button style={{ height: 50 }} className="btn btn-warning d-flex flex-column justify-content-center" onClick={this.requestReplacement}><span className="font-10 line1 align-self-center">Request Update</span></button>}
            {user.InvalidPhoto && <button onClick={this.requestReplacement} style={{ height: 50 }} className="btn btn-danger d-flex flex-column justify-content-center">
                <span className="font-6 line1 align-self-center">Replacement Requested </span>
                <span className="font-6 line1 align-self-center">by {`${_.first(user.replacements).NameFirst} ${_.first(user.replacements).NameLast} `}on{` ${moment(_.first(user.replacements).DateCreated).format('MMM Do YYYY')}`}</span>
                <span className="font-6 line1 align-self-end mt-2">(Click to dismiss)</span>
            </button>}
        </figure>
    }
    render() {
        const { validations = [] } = this.props;
        let photo_validations = _.find(validations, v => v.ValidationProcessCode === 'PHOTOS');
        return this.render_one();

    }
}

export default PhotoItem;