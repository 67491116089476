import React from 'react';
import moment from 'moment';

class CustomTimeScroller extends React.Component {

    state = {
        hour: 0,
        minute: 0,
        meridiem: 'AM',
        mouseHold: false,
        utc: this.props.utc
    }

    notify = () => {
        const { meridiem, hour, minute, utc } = this.state;
        var date = moment((hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + meridiem, 'HH:mmA');                
        this.props.fnChange && this.props.fnChange(utc ? date.utc() : date);
    }

    toggleMeridiem = () => {
        const { meridiem } = this.state;
        this.setState({
            meridiem: (meridiem === 'AM' ? 'PM' : 'AM')
        }, function () {
            const { hour, meridiem } = this.state;
            var newHour = 0;
            // if new meridiem == 'am' and hour == 12 => hour = 0
            // if new meridiem == 'pm' and hour == 0 => hour = 12
            if (meridiem === 'AM' && hour === 12) {
                newHour = 0;
            } else if (meridiem === 'PM' && hour === 0) {
                newHour = 12;
            } else {
                newHour = hour + 0;
            }

            this.setState({
                hour: newHour
            }, function () {
                this.notify();
            });

        });

    }

    fixMeridiem = () => {
        // if new hour is 12, meridiem must be PM
        // if new hour is 0, meridiem must be AM
        const { hour, meridiem } = this.state;
        var newMeridiem = 'AM';
        if (hour === 12) {
            newMeridiem = 'PM';
        } else if (hour === 0) {
            newMeridiem = 'AM';
        } else {
            newMeridiem = meridiem + '';
        }
        this.setState({
            meridiem: newMeridiem
        }, function () {
            this.notify();
        })
    }

    toggleHour = (up) => {
        const { hour } = this.state;

        if (up) {

            this.setState({
                hour: (hour + 1 > 12 ? 0 : hour + 1)
            }, function () {
                this.fixMeridiem();
            })
        } else {
            this.setState({
                hour: (hour - 1 < 0 ? 12 : hour - 1)
            }, function () {
                this.fixMeridiem();
            })
        }

    }

    toggleMinute = (up) => {
        const { minute } = this.state;

        this.setState({
            mouseHold: false
        });
        if (up) {
            this.setState({
                minute: (minute + 5 > 59 ? 0 : minute + 5)
            }, function () {
                this.notify();
            })
        } else {
            this.setState({
                minute: (minute - 5 < 0 ? 55 : minute - 5)
            }, function () {
                this.notify();
            })
        }

    }

    // Lifecycle
    componentWillMount = () => {
        if (this.props.time) {
            var time = moment(this.props.time, 'Hmm');

            this.setState({
                minute: time.minute(),
                hour: time.hour() > 12 ? parseInt(time.hour() - 12, 10) : time.hour(),
                meridiem: time.format('A')
            });
        }
    }
    //

    render() {
        const { meridiem, hour, minute } = this.state;
        return (<div className="container-fluid text-center bg-dark-blue white no-margin no-padding custom-time-scroller">
            <div className="row no-margin no-padding">
                <div className="col-4 no-margin no-padding">
                    <button className="btn btn-link btn-sm no-padding" onClick={() => this.toggleHour(true)}><i className="fa fa-angle-up"></i></button>
                </div>
                <div className="col-4 no-margin no-padding">
                    <button className="btn btn-link btn-sm no-padding" onMouseDown={() => this.toggleMinute(true)}><i className="fa fa-angle-up"></i></button>
                </div>
                <div className="col-4 no-margin no-padding">
                    <button className="btn btn-link btn-sm no-padding" onClick={this.toggleMeridiem}><i className="fa fa-angle-up"></i></button>
                </div>
            </div>
            <div className="row no-margin no-padding">
                <div className="col-4 no-margin no-padding"><span className="">{hour < 10 ? ('0' + hour) : hour}</span></div>
                <div className="col-4 no-margin no-padding"><span className="">:{minute < 10 ? ('0' + minute) : minute}</span></div>
                <div className="col-4 no-margin no-padding"><span className="">{meridiem}</span></div>
            </div>
            <div className="row no-margin no-padding">
                <div className="col-4 no-margin no-padding">
                    <button className="btn btn-link btn-sm no-padding" onClick={() => this.toggleHour(false)}><i className="fa fa-angle-down"></i></button>
                </div>
                <div className="col-4 no-margin no-padding">
                    <button className="btn btn-link btn-sm no-padding" onMouseDown={() => this.toggleMinute(false)}><i className="fa fa-angle-down"></i></button>
                </div>
                <div className="col-4 no-margin no-padding">
                    <button className="btn btn-link btn-sm no-padding" onClick={this.toggleMeridiem}><i className="fa fa-angle-down"></i></button>
                </div>
            </div>
        </div>)
    }
}

export default CustomTimeScroller;