function reducer(state = [], action) {

  switch (action.type) {
    case 'USERS/SET':
      return action.users;
    case 'USERS/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;