export function fetchCountries() {
    return {
        type: 'COUNTRIES/FETCH'
    }
}
export function fetchTimeZones() { return { type: 'TIME_ZONES/FETCH' } }
export function fetchCountryCodes() { return { type: 'COUNTRY_CODES/FETCH' } }

export function clearStates() {
    return {
        type: 'STATES/CLEAR'
    }
}

export function fetchStates(idCountry) {
    return {
        type: 'STATES/FETCH',
        idCountry
    }
}