function reducer(state = [], action) {

    switch (action.type) {
      case 'VIOLATIONS/SET':          
        return action.violations;
      case 'VIOLATIONS/CLEAR':
        return null;
      default:
        return state;
    }
  }
  
  export default reducer;