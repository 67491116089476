import React from 'react';
import Loader from '../../../common/Loader';
import LineLoader from '../../../common/LineLoader';
import moment from 'moment';
import User from './item';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class PCV extends React.Component {

  state = {
    dates: [],
    mode: 1,
    date: null,
    bootstrapped: true,
    softLoading: false,
    dropdownOpen: false,
    other: false,
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params, seasonActions } = this.props;
    setTitle && setTitle({
      sub: 'Player Cards (Season)',
      main: 'Validation'
    });
    const { dates } = this.state;
    for (var i = 0; i < 97; i++) {
      dates.push(moment().add((3 + i) * -1, 'years').format('YYYY'));
    }
    this.setState({ dates, letter: params.letter });
    seasonActions && seasonActions.fetchPCVs(params.idSeason, params.letter || 'A');
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.params.letter !== this.props.params.letter) {
      this.setState({ softLoading: true, letter: nextProps.params.letter })
      this.props.seasonActions && this.props.seasonActions.fetchPCVs(nextProps.params.idSeason, nextProps.params.letter);
    } else if (nextProps.users) { this.setState({ bootstrapped: true, softLoading: false }); }
  }
  //

  toggleMode = (mode) => {
    this.setState({ mode });
  }
  selectDate = (date) => {
    this.setState({ date, other: false });
  }
  selectOtherDate = (date) => {
    this.setState({ date, other: true });
  }

  toggleOtherDates = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  go = letter => {
    const { router, params } = this.props;
    if (this.state.letter !== letter) {
      router.push(`/season/${params.idSeason}/reports/pcv/${letter}`);
    }
  }

  render() {
    const { dates, mode, bootstrapped, date, softLoading, other } = this.state, { users, params } = this.props, { letter } = params;

    const filteredUsers = _.chain(users || [])
      .filter(u => { return u.IdGender === mode; })
      .filter(u => {
        if (date)
          return moment(u.DateOfBirth).format('YYYY') === date;
        return true;
      })
      .value();

    return (<section className="p-2 d-flex h-100 w-100 " style={{ position: 'relative' }}>
      {softLoading && <LineLoader key="soft" />}
      {bootstrapped &&
        <div style={{ position: 'absolute', left: 0, width: 200, top: 0, bottom: 0, overflowY: 'auto' }} className="d-flex flex-column">
          <button onClick={() => this.go('A')} className={`btn btn-sm btn-${letter === 'A' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>A</button>
          <button onClick={() => this.go('B')} className={`btn btn-sm btn-${letter === 'B' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>B</button>
          <button onClick={() => this.go('C')} className={`btn btn-sm btn-${letter === 'C' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>C</button>
          <button onClick={() => this.go('D')} className={`btn btn-sm btn-${letter === 'D' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>D</button>
          <button onClick={() => this.go('E')} className={`btn btn-sm btn-${letter === 'E' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>E</button>
          <button onClick={() => this.go('F')} className={`btn btn-sm btn-${letter === 'F' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>F</button>
          <button onClick={() => this.go('G')} className={`btn btn-sm btn-${letter === 'G' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>G</button>
          <button onClick={() => this.go('H')} className={`btn btn-sm btn-${letter === 'H' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>H</button>
          <button onClick={() => this.go('I')} className={`btn btn-sm btn-${letter === 'I' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>I</button>
          <button onClick={() => this.go('J')} className={`btn btn-sm btn-${letter === 'J' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>J</button>
          <button onClick={() => this.go('K')} className={`btn btn-sm btn-${letter === 'K' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>K</button>
          <button onClick={() => this.go('L')} className={`btn btn-sm btn-${letter === 'L' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>L</button>
          <button onClick={() => this.go('M')} className={`btn btn-sm btn-${letter === 'M' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>M</button>
          <button onClick={() => this.go('N')} className={`btn btn-sm btn-${letter === 'N' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>N</button>
          <button onClick={() => this.go('O')} className={`btn btn-sm btn-${letter === 'O' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>O</button>
          <button onClick={() => this.go('P')} className={`btn btn-sm btn-${letter === 'P' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>P</button>
          <button onClick={() => this.go('Q')} className={`btn btn-sm btn-${letter === 'Q' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>Q</button>
          <button onClick={() => this.go('R')} className={`btn btn-sm btn-${letter === 'R' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>R</button>
          <button onClick={() => this.go('S')} className={`btn btn-sm btn-${letter === 'S' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>S</button>
          <button onClick={() => this.go('T')} className={`btn btn-sm btn-${letter === 'T' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>T</button>
          <button onClick={() => this.go('U')} className={`btn btn-sm btn-${letter === 'U' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>U</button>
          <button onClick={() => this.go('V')} className={`btn btn-sm btn-${letter === 'V' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>V</button>
          <button onClick={() => this.go('W')} className={`btn btn-sm btn-${letter === 'W' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>W</button>
          <button onClick={() => this.go('X')} className={`btn btn-sm btn-${letter === 'X' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>X</button>
          <button onClick={() => this.go('Y')} className={`btn btn-sm btn-${letter === 'Y' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>Y</button>
          <button onClick={() => this.go('Z')} className={`btn btn-sm btn-${letter === 'Z' ? 'success' : 'info'} ml-2 mr-2 mt-2`}>Z</button>

        </div>
      }
      {bootstrapped &&
        <div style={{ position: 'absolute', left: 200, right: 0, top: 0, bottom: 0, overflowY: 'auto' }} className="">
          <div key="modes" className="d-flex justify-content-center mt-2">
            <button onClick={() => this.toggleMode(1)} className={`btn btn-${mode === 1 ? 'success' : 'link blue'} margin-right-half`}><i className="fas fa-male margin-right-half"></i>Males</button>
            <button onClick={() => this.toggleMode(2)} className={`btn btn-${mode === 2 ? 'success' : 'link blue'}`}><i className="fas fa-female margin-right-half"></i>Females</button>
          </div>
          <div key="with-data" className="d-flex flex-row justify-content-around margin-top">
            <div className="no-margin no-padding" style={{ borderRight: '5px solid #002549' }}>
              {/* All */}
              <button onClick={() => this.selectDate(null)} className={`btn btn-${date ? 'info' : 'success'} btn-sm margin-right-half margin-bottom-half`}>All</button>
              { /* Dates */}
              {dates && _.take(dates, 20).map((year, i) => <button onClick={() => this.selectDate(year)} key={i} className={`btn btn-${(date && date) === year ? 'success' : 'info'} btn-sm margin-right-half margin-bottom-half`}>{year}</button>)}
              {/* Other Dates */}
              <Dropdown style={{ display: 'inline' }} isOpen={this.state.dropdownOpen} toggle={this.toggleOtherDates}>
                <DropdownToggle className={`btn btn-${other ? 'success' : 'info'} btn-sm margin-right-half margin-bottom-half`} caret>
                  {other ? date : 'Other'}
                </DropdownToggle>
                <DropdownMenu>
                  {dates && _.takeRight(dates, 77).map((date, i) => <DropdownItem key={i} onClick={() => this.selectOtherDate(date)}>{date}</DropdownItem>)}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <Animated animationIn="fadeIn">
            <table className="table white">
              <thead>
                <tr>
                  <th>Users <span className="yellow">({filteredUsers.length})</span></th>
                  <th>Contact</th>
                  <th>Delivered</th>
                  <th>Delivered By</th>
                  <th>Deliver Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, i) => <User key={user.IdUser} user={user} seasonActions={this.props.seasonActions}
                  idSeason={this.props.params.idSeason} />)}
              </tbody>
            </table>
          </Animated>
        </div>
      }
      {!bootstrapped && <Loader key="loader" message={`Loading Assets...`} />}
    </section>)
  }
}

export default PCV;