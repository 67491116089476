import React from 'react';
import './divisions.css';
import DivisionListItem from './DivisionListItem';
import _ from 'lodash';

class DivisionsList extends React.Component {

    render() {
        const { divisions } = this.props;
        let divisions_with_fligths = _.chain(divisions).filter(d => d.Flights && d.Flights.length).value(),
        divisions_without_flights = _.chain(divisions).reject(d => d.Flights && d.Flights.length).value();

        return (<ul className="divisions-wrapper d-flex flex-column w-100">
            <li className="gray">-- With Flights --</li>
            {divisions_with_fligths.map((division, i) => <DivisionListItem accept={this.props.accept} key={i} index={i} division={division} {...this.props} />)}
            <li className="gray mt-4">-- Without Flights --</li>
            {divisions_without_flights.map((division, i) => <DivisionListItem accept={this.props.accept} key={i} index={i} division={division} {...this.props} />)}
        </ul>)
    }
}

export default DivisionsList;