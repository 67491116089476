import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import request from 'superagent';
import config from '../../../config';
import COLORS from '../../../colors';
import _ from 'lodash';

const options = {
    scales: {
        y: {
            beginAtZero: true
        }
    }
};

class Vendors extends React.Component {

    state = {
        total: 0
    }

    componentWillMount() {
        const { yearFrom, yearTo, from, to, activeTab } = this.props;
        this.setState({ yearFrom, yearTo, from, to, activeTab }, () => {
            this.fetch()
        });
    }
    componentWillReceiveProps = nextProps => {
        const { yearFrom, yearTo, from, to, activeTab } = this.props;
        if (yearFrom !== this.state.yearFrom ||
            yearTo !== this.state.yearTo ||
            activeTab !== this.state.activeTab ||
            from !== this.state.from ||
            to !== this.state.to) {
            this.setState({ yearFrom, yearTo, from, to, activeTab }, () => {
                this.fetch();
            });
        }
    }

    fetch = () => {
        const { yearFrom, yearTo, activeTab, from, to } = this.state;
        request.post(`${config.apiEndpoint}/api/v5/system/app_installments/yearly`)
            .send({
                start: (activeTab === '3' && from && to) ? `${from.format('YYYY-MM-DD')}` : `${yearFrom}-01-01`,
                end: (activeTab === '3' && from && to) ? `${to.format('YYYY-MM-DD')}` : `${yearTo}-12-31`,
                include_daily: activeTab === '3'
            })
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then(({ body: { app, rf, rc, ad } }) => {

                let ios = activeTab === '3' ?
                    _.chain(_.zip(app.ios.daily, rf.ios.daily, rc.ios.daily, ad.ios.daily))
                        .map(m => {
                            return {
                                MOMENT: m[0].MOMENT,
                                label: '',
                                value: _.chain(m).map(m => m.NumberOfAccounts || 0).reduce((a, b) => a + b, 0).value()
                            }
                        }).value()
                    :
                    _.chain(_.zip(app.ios.monthly_totals, rf.ios.monthly_totals, rc.ios.monthly_totals, ad.ios.monthly_totals))
                        .map(m => {
                            return {
                                friendly: m[0].friendly,
                                label: m[0].label,
                                value: _.chain(m).map((m = { value: 0 }) => m.value || 0).reduce((a, b) => a + b, 0).value()
                            }
                        }).value(),


                    android = activeTab === '3' ?
                        _.chain(_.zip(app.android.daily, rf.android.daily, rc.android.daily, ad.android.daily))
                            .map(m => {
                                return {
                                    MOMENT: m[0].MOMENT,
                                    label: '',
                                    value: _.chain(m).map(m => m.NumberOfAccounts || 0).reduce((a, b) => a + b, 0).value()
                                }
                            }).value()
                        : _.chain(_.zip(app.android.monthly_totals, rf.android.monthly_totals, rc.android.monthly_totals, ad.android.monthly_totals))
                            .map(m => {
                                return {
                                    friendly: m[0].friendly,
                                    label: m[0].label,
                                    value: _.chain(m).map((m = { value: 0 }) => m.value || 0).reduce((a, b) => a + b, 0).value()
                                }
                            }).value();

                const data = {
                    labels: activeTab === '3' ? _.map(app.ios.daily, d => d.MOMENT) : _.map(ios, i => i.friendly),
                    datasets: [
                        {
                            label: 'iOS',
                            data: _.map(ios, i => i.value),
                            fill: false,
                            backgroundColor: COLORS.gray,
                            borderColor: COLORS.gray,
                        },
                        {
                            label: 'Android',
                            data: _.map(android, i => i.value),
                            fill: false,
                            backgroundColor: COLORS.green,
                            borderColor: COLORS.green,
                        },
                    ],
                };


                // apps

                let app_t = _.chain(_.zip(app.android[activeTab === '3' ? 'daily' : 'monthly_totals'], app.ios[activeTab === '3' ? 'daily' : 'monthly_totals']))
                    .map(m => {
                        if (activeTab === '3') return {
                            MOMENT: m[0].MOMENT,
                            label: '',
                            value: _.chain(m).map(m => m.NumberOfAccounts || 0).reduce((a, b) => a + b, 0).value()
                        };
                        return {
                            friendly: m[0].friendly,
                            label: m[0].label,
                            value: _.chain(m).map(m => m.value).reduce((a, b) => a + b, 0).value()
                        }
                    }).value(),
                    rc_t = _.chain(_.zip(rc.android[activeTab === '3' ? 'daily' : 'monthly_totals'], rc.ios[activeTab === '3' ? 'daily' : 'monthly_totals']))
                        .map(m => {
                            if (activeTab === '3') return {
                                MOMENT: m[0].MOMENT,
                                label: '',
                                value: _.chain(m).map(m => m.NumberOfAccounts || 0).reduce((a, b) => a + b, 0).value()
                            };
                            return {
                                friendly: m[0].friendly,
                                label: m[0].label,
                                value: _.chain(m).map(m => m.value).reduce((a, b) => a + b, 0).value()
                            }
                        }).value(),
                    rf_t = _.chain(_.zip(rf.android[activeTab === '3' ? 'daily' : 'monthly_totals'], rf.ios[activeTab === '3' ? 'daily' : 'monthly_totals']))
                        .map(m => {
                            if (activeTab === '3') return {
                                MOMENT: m[0].MOMENT,
                                label: '',
                                value: _.chain(m).map(m => m.NumberOfAccounts || 0).reduce((a, b) => a + b, 0).value()
                            };
                            return {
                                friendly: m[0].friendly,
                                label: m[0].label,
                                value: _.chain(m).map(m => m.value).reduce((a, b) => a + b, 0).value()
                            }
                        }).value(),
                    ad_t = _.chain(_.zip(ad.android[activeTab === '3' ? 'daily' : 'monthly_totals'], ad.ios[activeTab === '3' ? 'daily' : 'monthly_totals']))
                        .map(m => {
                            if (activeTab === '3') return {
                                MOMENT: m[0].MOMENT,
                                label: '',
                                value: _.chain(m).map(m => m.NumberOfAccounts || 0).reduce((a, b) => a + b, 0).value()
                            };
                            return {
                                friendly: m[0].friendly,
                                label: m[0].label,
                                value: _.chain(m).map(m => m.value).reduce((a, b) => a + b, 0).value()
                            }
                        }).value();

                const data2 = {
                    labels: activeTab === '3' ? _.map(app.ios.daily, d => d.MOMENT) : _.map(app_t, i => i.friendly), //['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN'],
                    datasets: [
                        {
                            label: 'SQWAD',
                            data: _.map(app_t, o => o.value),
                            fill: false,
                            backgroundColor: COLORS.jeff_blue,
                            borderColor: COLORS.jeff_blue,
                        },
                        {
                            label: 'RC',
                            data: _.map(rc_t, o => o.value),
                            fill: false,
                            backgroundColor: COLORS.navy,
                            borderColor: COLORS.navy,
                        },
                        {
                            label: 'RF',
                            data: _.map(rf_t, o => o.value),
                            fill: false,
                            backgroundColor: COLORS.black,
                            borderColor: COLORS.black,
                        },
                        {
                            label: 'AD',
                            data: _.map(ad_t, o => o.value),
                            fill: false,
                            backgroundColor: COLORS.AD,
                            borderColor: COLORS.AD,
                        }
                    ]
                }

                this.setState({ data, data2 });
            });
    }

    render() {
        const { data, data2 } = this.state;
        return <section>
            <div className="w3-content sheet shadow card p-4 mt-4">
                <div className="d-flex flex-row">
                    <h2>Vendor</h2>
                    {/*<button className="btn btn-info ml-auto"><i className="fab fa-android font-16" /></button>
                    <button className="btn btn-info ml-2"><i className="fab fa-apple font-16" /></button>*/}
                </div>

                <div className="d-flex flex-row">
                    <div className="w-100">
                        {data && <Line data={data} options={options} />}
                    </div>
                </div>
            </div>

            <div className="w3-content sheet shadow card p-4 mt-4">
                <div className="d-flex flex-row">
                    <h2>Apps</h2>
                </div>

                <div className="d-flex flex-row">
                    <div className="w-100">
                        {data2 && <Bar data={data2} options={options} />}
                    </div>
                </div>
            </div>
        </section>
    }
}

export default Vendors;
