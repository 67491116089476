const fakeAuth = {
  getCredentials() {
    return {
      token: localStorage.getItem('sportslogic.authtoken'),
      isAdmin: localStorage.getItem('sportslogic.isAdmin') === 'true',
      email: localStorage.getItem('email'),
    }
  },
  isAdmin() {
    return localStorage.getItem('sportslogic.isAdmin') === 'true' ? true : false;
  },
  isAuthenticated() {
    return localStorage.getItem('sportslogic.isAuthenticated') || false;
  }, //|| false, // this should be persisted ?
  authenticate(cb, token, isAdmin, user) {
    localStorage.setItem('sportslogic.isAuthenticated', '1'); // put here the auth token ?
    localStorage.setItem('sportslogic.authtoken', token);
    localStorage.setItem('authtoken', token);
    localStorage.setItem('sportslogic.isAdmin', isAdmin);
    if (user)
      localStorage.setItem('sportslogic.user', JSON.stringify(user))
    cb && cb();
  },
  signout(cb) {
    localStorage.removeItem('sportslogic.isAuthenticated');
    localStorage.removeItem('sportslogic.authtoken');
    localStorage.removeItem('authtoken');
    localStorage.removeItem('sportslogic.isAdmin');
    localStorage.removeItem('sportslogic.user');
    cb && cb();
  },
  getUser() {
    var s = localStorage.getItem('sportslogic.user');
    if (s) {
      return JSON.parse(s);
    } else {
      return null;
    }
  },
  getFamily() {
    var s = localStorage.getItem('sportslogic.family');
    if (s) {
      return JSON.parse(s);
    } else {
      return null;
    }
  },
  addMember(m) {
    var s = localStorage.getItem('sportslogic.family');
    if (s) {
      let family_array = JSON.parse(s);
      family_array.push(s);
      localStorage.setItem('sportslogic.family', JSON.stringify(family_array));
    }
  }
}

export default fakeAuth;
