function reducer(state = [], action) {  
  switch (action.type) {
    case 'ACCEPTED/SET':
      return action.accepted;
    case 'ACCEPTED/CLEAR':
      return null;
    case 'WAITLIST/ACCEPT':
      return [...state, action.item];
    case 'TRYOUTS/RETURN':
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
    case 'WAITLIST/RETURN':
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
    case 'TRYOUTS/CLEAN':
      return null;
    default:
      return state;
  }
}

export default reducer;
