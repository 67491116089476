import React from 'react';
import Slider from 'react-rangeslider'

class Filter extends React.Component {
    state = { toggle: false, min_players: 0 }
    componentWillMount(){
        const { filter } = this.props;
        this.setState({ filter })
    }
    handleMinPlayers = newValue => {
        const { min_players, filter } = this.state;
        filter.min_players = min_players;
        this.props.fnUpdate && this.props.fnUpdate(this.state.filter);
        if (newValue !== min_players) this.setState({ min_players: newValue });
    }
    render() {
        const { toggle, min_players, filter } = this.state;
        return <div style={{ borderBottom: '1px solid gray' }}>
            <button onClick={() => this.setState({ toggle: !this.state.toggle })} className="w-100 btn btn-link d-flex flex-row pointer">
                <span className="align-self-center ml-3">Filter</span>
                <div className="btn btn-link ml-auto"><i className={`fas fa-chevron-${toggle ? 'up' : 'down'}`} /></div>
            </button>
            {toggle && <div style={{ height: 'auto' }} className="pt-2">

                <div className="form-group px-3">
                    <label className="8">Min. # Players ({min_players})</label>
                    <Slider min={0} max={10} value={min_players} onChange={this.handleMinPlayers} />
                </div>


                <button className="btn btn-link d-flex flex-row w-100" onClick={() => {
                    const { filter } = this.state;
                    filter.coach_any_validation = !filter.coach_any_validation;
                    this.setState({ filter }, () => {
                        this.props.fnUpdate && this.props.fnUpdate(this.state.filter);
                    });
                }}>
                    <span className="align-self-center">Coaches that need any validation</span>
                    <i className={`fa-${filter.coach_any_validation ? 'circle-check fas' : 'circle far'} ml-auto align-self-center`} />
                </button>

                <button className="btn btn-link d-flex flex-row w-100" onClick={() => {
                    const { filter } = this.state;
                    filter.coach_conc_validation = !filter.coach_conc_validation;
                    this.setState({ filter }, () => {
                        this.props.fnUpdate && this.props.fnUpdate(this.state.filter);
                    });
                }}>
                    <span className="align-self-center">Coaches without Intro C&C validation</span>
                    <i className={`fa-${filter.coach_conc_validation ? 'circle-check fas' : 'circle far'} ml-auto align-self-center`} />
                </button>

                <button className="btn btn-link d-flex flex-row w-100" onClick={() => {
                    const { filter } = this.state;
                    filter.coach_coach_validation = !filter.coach_coach_validation;
                    this.setState({ filter }, () => {
                        this.props.fnUpdate && this.props.fnUpdate(this.state.filter);
                    });
                }}>
                    <span className="align-self-center">Coaches without Coach Cert. validation</span>
                    <i className={`fa-${filter.coach_coach_validation ? 'circle-check fas' : 'circle far'} ml-auto align-self-center`} />
                </button>

                <button className="btn btn-link d-flex flex-row w-100" onClick={() => {
                    const { filter } = this.state;
                    filter.coach_safe_validation = !filter.coach_safe_validation;
                    this.setState({ filter }, () => {
                        this.props.fnUpdate && this.props.fnUpdate(this.state.filter);
                    });
                }}>
                    <span className="align-self-center">Coaches without Safe Sport validation</span>
                    <i className={`fa-${filter.coach_safe_validation ? 'circle-check fas' : 'circle far'} ml-auto align-self-center`} />
                </button>

                <button className="btn btn-link d-flex flex-row w-100" onClick={() => {
                    const { filter } = this.state;
                    filter.coach_bgc_validation = !filter.coach_bgc_validation;
                    this.setState({ filter }, () => {
                        this.props.fnUpdate && this.props.fnUpdate(this.state.filter);
                    });
                }}>
                    <span className="align-self-center">Coaches without Background Check</span>
                    <i className={`fa-${filter.coach_bgc_validation ? 'circle-check fas' : 'circle far'} ml-auto align-self-center`} />
                </button>

                <button className="btn btn-link d-flex flex-row w-100" onClick={() => {
                    const { filter } = this.state;
                    filter.player_dob_validation = !filter.player_dob_validation;
                    this.setState({ filter }, () => {
                        this.props.fnUpdate && this.props.fnUpdate(this.state.filter);
                    });
                }}>
                    <span className="align-self-center">Players without DOB. validation</span>
                    <i className={`fa-${filter.player_dob_validation ? 'circle-check fas' : 'circle far'} ml-auto align-self-center`} />
                </button>

                <button className="btn btn-link d-flex flex-row w-100">
                    <span className="align-self-center">Players with Invalid Picture</span>
                    <i className={`far fa-circle ml-auto align-self-center`} />
                </button>

            </div>}
        </div>
    }
}

export default Filter;