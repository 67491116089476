import React from 'react';
import DivisionPlacementDraggable from './DivisionPlacementDraggable';

class DivisionPlacements extends React.Component {
    render() {
        const { teams, division, isUsingNextBest, placements } = this.props;        
        return (
            <ul className="block d-flex flex-column no-style-lists no-margin no-padding bg-white margin-bottom-half">
                {teams.length > 0 && teams.map((game, i) => (
                    <DivisionPlacementDraggable key={i} index={i + 1} mode={1} division={division} isUsingNextBest={isUsingNextBest} placements={placements} />))}
                {!teams.length && <div className="jumbotron no-margin"><i className="fa fa-exclamation" /> There are no Flighted Teams.</div>}
            </ul>
        )
    }
}

export default DivisionPlacements;