function flights(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'LEADS/SET':
            return action.leads;
        case 'LEADS/CLEAR':
            return [];
        case 'LEADS/ADD': 
            return [...state, action.lead];
        default:
            return state;
    }
}

export default flights;