import React from 'react';
import UserNote from './UserNote';
import _ from 'lodash';
import { generateRandomId } from '../../../helpers';

class UserNotes extends React.Component {
    state = {
        notes: []
    }

    componentWillMount() {
        const { leagueActions, idUser, idLeague, notes } = this.props;
        leagueActions && leagueActions.fetchUserNotes(idUser, idLeague)
        notes && notes.forEach(note => { note.LocalId = generateRandomId(5); });
        this.setState({ notes });
    }
    componentWillReceiveProps = nextProps => {
        const { notes } = nextProps;
        notes && notes.forEach(note => { note.LocalId = generateRandomId(5); });
        this.setState({ notes });
    }


    onAddNote = () => {
        const { notes } = this.state, { idUser, idLeague } = this.props;        
        this.setState({ notes: [{ LocalId: generateRandomId(5), Color: 'red', editing: true, IdUser: idUser, IdLeague: idLeague  }, ...notes] })
    }

    onDeleteNote = (note) => {
        const { notes } = this.state, { leagueActions } = this.props;
        leagueActions && leagueActions.removeUserNote(note);
        this.setState({ notes: _.reject(notes, n => n.LocalId === note.LocalId) });
    }

    render() {
        const { notes } = this.state;

        return <section className="p-4">
            <h3 className="d-flex flex-row">
                <span>User Notes ({(notes || []).length})</span>
                <button onClick={this.onAddNote} style={{ width: 'auto', border: '2px solid #2cad3c', borderRadius: 20 }} className="green p-2 align-self-center ml-auto font-10 px-3">Add Note</button>
            </h3>

            {notes && <div className="d-flex flex-column mt-3">
                {_.chunk(notes, 2).map(([a = {}, b = {}], i) => <div className="d-flex flex-row" key={`${i}-${a.LocalId}-${b.LocalId}`}>
                    <UserNote note={a} fnDelete={this.onDeleteNote} leagueActions={this.props.leagueActions} />
                    <UserNote note={b} fnDelete={this.onDeleteNote} leagueActions={this.props.leagueActions} />
                </div>)}
            </div>}

        </section>
    }
}

export default UserNotes;