import React from 'react';
import { Jumbotron } from 'reactstrap';
import request from 'superagent';
import config from '../../../../../config';
import PairingsDetails from '../../../../common/modals/PairingsDetails';
import _ from 'lodash';
import moment from 'moment';

class Summary extends React.Component {

    state = {
        bootstrapped: false,
        tab: 1,
        fields: null
    }

    componentWillMount() {
        const { params, season } = this.props, { idSeason } = params;

        request.get(`${config.apiEndpoint}/api/v4/schedule/summary/${idSeason}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then(({ body }) => {
                this.setState({
                    bootstrapped: true,
                    summary: _.values(_.groupBy(body.summary, 'IdDivision')),
                    divisions: body.divisions
                });
            });

        this.props.fetchLocations &&
            this.props.fetchLocations(season.IdLeague);
    }
    componentWillReceiveProps = nextProps => {

        if (nextProps.locations &&
            nextProps.locations.length &&
            !this.state.fields) {

            let fields = [];

            (nextProps.locations || []).forEach(location => {
                fields = [...fields, {
                    Name: location.Name,
                    IdLocation: location.IdLocation,
                    selected: false
                }, ..._.map(location.Fields || location.fields, function (f) {
                    return {
                        Name: f.Name,
                        IdLocation: f.IdLocation,
                        selected: false,
                        child: true
                    };
                })];
            });

            this.setState({ fields });
        }
    }

    save = (payload) => {

        const { divisions } = this.state;
        let the_division = _.find(divisions, d => d.IdDivision === payload.IdDivision);
        if (the_division) {

            let the_flight = _.find(the_division.flights, f => f.IdFlight === payload.IdFlight);
            if (the_flight) {

                (_.find(the_flight.pairings, p => p.IdSchedulePairings === payload.IdSchedulePairings) || {}).NewIdGame = payload.IdGame;
                this.setState({ divisions });

            }
        }
        this.setState({ modal: false, selectedPairing: null, selectedFlight: null, selectedDivision: null })
    }

    onAddGame = pairing => {
        this.setState({
            modal: true,
            selectedPairing: { ...pairing, GameStartHour: moment(9, 'h') },
            selectedFlight: pairing,
            selectedDivision: pairing
        });
    }

    render() {
        const { bootstrapped, divisions, fields } = this.state;

        let REVIEW_DIVISIONS = _.filter(divisions || [], d => d.REVIEW),
            OK_DIVISIONS = _.filter(divisions || [], d => d.OK);

        return <section>
            {!(bootstrapped && fields) && <Jumbotron className="m-0">
                <h1>Fetching data from previous builds</h1>
                <h4>Please wait....</h4>
            </Jumbotron>}
            {(bootstrapped && fields) && <div>
                <div className="d-flex flex-row mb-4">
                    <div onClick={() => this.setState({ tab: 1 })} className={`w-100 font-20 p-3 pointer text-center bg-${this.state.tab === 1 ? 'white' : 'whiteish'}`}>
                        Divisions to Review ({REVIEW_DIVISIONS.length})
                    </div>
                    <div onClick={() => this.setState({ tab: 2 })} className={`w-100 font-20 p-3 pointer text-center bg-${this.state.tab === 2 ? 'white' : 'whiteish'}`}>
                        Divisions Ready ({OK_DIVISIONS.length})
                    </div>
                </div>
                {this.state.tab === 1 && REVIEW_DIVISIONS.length === 0 && <Jumbotron>
                    <h1>No divisions are pending for review</h1>
                    <h4>Here you will only see divisions that had problems during schedule build</h4>
                </Jumbotron>}

                {this.state.tab === 2 && OK_DIVISIONS.length === 0 && <Jumbotron>
                    <h1>No divisions are fully build</h1>
                    <h4>Here you will only see divisions that had NO problems during schedule build</h4>
                </Jumbotron>}
                {this.state.tab === 1 && REVIEW_DIVISIONS.map((division, i) => <div key={i} className="p-4 mb-4">

                    <div className="d-flex flex-row">
                        <h2 className="align-self-center">{division.DivisionName}</h2>
                        <span className="ml-auto red font-10 align-self-center">( {_.chain(division.flights).filter(f => f.pairings.length).map(p => _.filter(p.pairings, a => !a.IdGame && !a.NewIdGame).length).reduce((a, b) => a + b, 0).value()} Unallocated Pairings )</span>
                    </div>

                    {_.filter(division.flights, f => f.pairings && f.pairings.length > 0 && _.find(f.pairings, p => !p.IdGame)).map((flight, j) =>
                        <div key={flight.IdFlight}>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Flight</th>
                                        <th className="text-center">Round</th>
                                        <th className="w-50">Home Team</th>
                                        <th className="w-50">Away Team</th>

                                        <th className="text-center">Id Game Assigned</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.filter(flight.pairings, p => !p.IdGame).map((pairing, p) => <tr key={pairing.IdSchedulePairings}>
                                        <td>{flight.FlightName}</td>
                                        <td className="text-center">{pairing.Round}</td>
                                        <td>{pairing.Home_Team}</td>
                                        <td>{pairing.Away_Team}</td>

                                        <td className="text-center">{pairing.NewIdGame || 'N/A'}</td>
                                        <td className="text-right">
                                            {!pairing.NewIdGame && <button onClick={() => this.onAddGame(pairing)} style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green font-8 py-1 px-3 ml-auto">Add Game</button>}
                                            {pairing.NewIdGame && <i className="fas fa-check green ml-auto" />}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <hr />
                </div>)}

                {this.state.tab === 2 && OK_DIVISIONS.map((division, i) => <div key={i} className="d-flex flex-column px-4">
                    <span className="font-16"><i className="fas fa-check green mr-2" />{division.DivisionName}</span>
                </div>)}
                <div style={{ height: 100 }} />
            </div>}

            {this.state.modal && (
                <PairingsDetails fnSave={this.save} flight={this.state.selectedFlight} fields={this.state.fields} isOpen={this.state.modal ? true : false}
                    toggle={() => this.setState({ modal: false })}
                    game={this.state.selectedPairing} division={this.state.selectedDivision}
                    divisions={[this.state.selectedDivision]}
                    gamesActions={this.props.gamesActions} />)}
        </section>
    }
}

export default Summary;
