function locations(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'LOCATIONS/SET':
            return action.locations;
        case 'LOCATIONS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default locations;
