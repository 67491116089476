function reducer(state = [], action) {

    switch (action.type) {
        case 'FAMILY/LASTNAME/SET':
            localStorage.setItem('sportslogic.family.lastname', action.FamilyLastName);
            return action.FamilyLastName;
        case 'DUES/ADD':
            return [...state, action.due];
        case 'DUES/REMOVE':
            return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        case 'DUES/UPDATE':
            return [...state.slice(0, action.index), action.due, ...state.slice(action.index + 1)];
        case 'DUES/POP':
            state.pop();
            return [...state];
        default:
            return state;
    }
}

export default reducer;
