function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'IS_TEAM_SELECTION_OPEN/SET':
            return action.isTeamSelectionOpen;
        default:
            return state;
    }
}

export default reducer;