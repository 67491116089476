function auth(state = [], action) {

    // listening
    switch (action.type) {

        case "AUTH/LOGIN":
            return {
                token: action.token,
                loggedIn: action.token ? true : false,
                isAdmin: action.isAdmin
            }
        case "AUTH/LOGOUT":
            return {
                loggedIn: false
            }
        case "AUTH/FAIL":
            const statusCode = action.message.response.statusCode
            if (statusCode === 401) {
                return {
                    body: action.message.response.body,
                    statusCode: statusCode,
                    token: state.token,
                    loggedIn: false
                };
            }
            return state;
        default:
            return state;
    }
}

export default auth;
