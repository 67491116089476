import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import Item from './due';
import { generateRandomId } from '../../../../../../helpers';
import request from 'superagent';
import config from '../../../../../../config';

class Comp extends React.Component {
  state = {
    saving: false
  }

  // Lifecycle
  componentWillMount() {
    const { dues } = this.props;
    if (!dues.length) {
      this.addDue();
    }
    if (this.props.generic) {
      const { generic } = this.props;
      this.setState({
        from: generic.start,
        to: generic.end
      });
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.generic) {
      const { generic } = nextProps;
      this.setState({
        from: generic.start,
        to: generic.end
      });
    }
    if (nextProps.dues && nextProps.dues.length !== this.props.dues.length) {
      this.forceUpdate();
    }
  }
  //

  addDue = () => {
    this.props.paymentsActions &&
      this.props.paymentsActions.addDue({
        id: generateRandomId(5),
        base: 0,
        child: 1
      });
  }

  save = () => {
    // ill use the dues as the placeholder
    const { generic, dues, season } = this.props, { saving } = this.state;
    if (saving) return;

    this.setState({ saving: true }, () => {
      request[generic.id ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/paymentPlans/family${generic.id ? '/' + generic.id : ''}`)
        .send({
          adjustments: dues,
          name: this.txtName.value,
          idSeason: season.IdSeason
        })
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then((data) => {
          if (data.body.success) {
            this.props.paymentsActions &&
              this.props.paymentsActions.fetchPrograms(season.IdSeason);
            this.props.toggle && this.props.toggle();
          }
        }, reject => {
          this.setState({ saving: false });
        });
    });

  }

  render() {
    const { isOpen, toggle, dues, generic } = this.props,
      { saving } = this.state;

    return <Modal isOpen={isOpen} toggle={toggle} style={{ width: 600 }}>
      <ModalHeader className="bg-success white"><i className="fas fa-users-cog margin-right-half" />New Family Discount Program</ModalHeader>
      <ModalBody className="container-fluid p-4">
        {generic && <div className="row">
          { /* Name  */}
          <div className="col-12 d-flex flex-row">
            <input className="form-control align-self-center" type="text" placeholder="Program Name" defaultValue={generic.name} ref={(i) => this.txtName = i} />
          </div>
          { /* Items (I'm using the dues store for this, since - well -, lazy...) */}
          <div className="col-12">
            {dues && dues.map((due, i) => <Item key={i} index={i} due={due} {...this.props} />)}
            <button style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} className="green pl-3 pr-3 w-100 block mt-4" onClick={this.addDue}>+ Add Discount</button>
            
          </div>
        </div>}
      </ModalBody>
      <ModalFooter className="">
        {!saving && <button className="btn btn-link" onClick={toggle}>Cancel</button>}
        <button style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} className="green pl-3 pr-3" onClick={this.save}>{saving ? 'Saving, please wait...' : 'Save program'}</button>        
      </ModalFooter>
    </Modal>
  }
}

Comp.defaultProps = {
  generic: {
    start: moment(),
    end: moment().add(1, 'month'),
    base: 0,
    fee: 0,
    initial: 0,
    late: 0,
    usePP: false
  },
}

export default Comp;