import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import fakeAuth from '../fakeAuth';

class MobileMenu extends React.Component {

    logout = () => {
        const { router } = this.props;
        fakeAuth.signout(() => {
          this.props.logout && this.props.logout();
          router.push('/login');
        });
      }

    render() {
        const { auth, } = this.props, user = fakeAuth.getUser();
        return <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
            <div className="dark-overlay" onClick={this.props.toggle} />
            <Animated style={{ position: 'absolute', top: 0, left: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray montserrat left-menu d-flex flex-column"
                isVisible={this.props.menu_bootstrapped ? true : false} animationOut="slideOutLeft" animationIn='slideInLeft' animationInDelay={200} animationInDuration={350}
                animationOutDuration={350}>
                <i className="icon-logo-header font-36 black block text-center align-self-center line1" />

                <button onClick={this.props.onFamily} className="btn btn-link green d-flex flex-row">
                    <img alt="" src={user.CURRENT_IMAGE_80} className="smallPic margin-right align-self-center bg-black" />
                    <span className="align-self-center montserrat font-12">{`Hi ${user.NameFirst}`}</span>
                </button>

                <Link to='/' className="btn text-left btn-link black font-12 montserrat p-4">
                    <span className="align-self-start">Family Site</span>
                </Link>
                { /* Link to admin portal */}
                {auth && auth.loggedIn && auth.isAdmin && (
                    <Link to='/admin' className="btn text-left btn-link black font-12 montserrat p-4">
                        <span className="align-self-start">Admin Site</span>
                    </Link>)}

                <a href="https://matchreports.thesqwad.com/faq" target="_blank" rel="noopener noreferrer" className="btn text-left btn-link black font-12 montserrat p-4">
                    <span>Help</span>
                </a>
                <button key="logout-button" onClick={this.logout} className="btn text-left btn-link black font-12 montserrat p-4">Logout</button>
            </Animated>
        </section>
    }
}

export default MobileMenu;