import React from 'react';
import Game from './Game';
import _ from 'lodash';
import { getFunName, } from '../../../../helpers';
import moment from 'moment';
import request from 'superagent';
import config from '../../../../config';
import { Menu, Item, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { generateRandomId } from '../../../../helpers';
import { Popover, PopoverBody, PopoverHeader, } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class TournamentBracket extends React.Component {

  state = {
    randomId: null
  }

  componentWillMount() {
    this.setState({ randomId: generateRandomId(10) });    
  }

  // Context Menu  
  clickOnContextMenu = ({ props: { paddingFromTheRoot, group } }) => {
    const { tournament, idDivision, defaultGameDuration } = this.props;
    const bracket = {
      paddingFromTheRoot,
      IdBracket: getFunName(),
      Groups: []
    }

    // Add empty groups until the target group for consolation game
    for (var i = 0; i < paddingFromTheRoot; i++) {
      bracket.Groups.push({ Group: i, Games: [] });
    }

    const newGame = {
      Group: (paddingFromTheRoot),
      Ghost: false,
      GameTeams: [{ comesFrom: {} }, { comesFrom: {} }],
      Location: {},
      deletable: true,
      GameDate: moment(900, 'hmm'),
      GameStartTime: moment(900, 'hmm'),
      GameStartTimeFormated: moment(900, 'hmm').format('HH:mm:ss'),
      GameEndTime: moment(900, 'hmm').add(defaultGameDuration, 'minutes').format('HH:mm:ss'),
      IdDivision: idDivision,
      RoundNumber: (paddingFromTheRoot)
    }

    // Then add the group with the consolation root game
    bracket.Groups.push({
      Group: (paddingFromTheRoot + 1),
      Games: [newGame]
    });

    // Create the game
    request.post(`${config.apiEndpoint}/api/v4/games/placeholder`) //${idDivision}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .send(newGame)
      .then(({ body: { IdGame } }) => {
        newGame.IdGame = IdGame;

        // Create the 1st bracket ?
        request.post(`${config.apiEndpoint}/api/v4/brackets/bracket/${tournament.IdTournament}`) //${idDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({ IdGame })
          .then(({ body: { Id } }) => {
            // TODO: not sure what to do with this Id
            tournament.Brackets.push(bracket);
            this.props.notify && this.props.notify();
          });
      });
  }
  //

  fnDeleteParentGame = ({ idGame, indexGroup, indexBracket }) => {

    /* ALGORITHM
    1. Look for the game in the group.
    2. create a recursive function that for each team in the game, looks to see if they have a parentIdGame.
    3. If they do, call the function again with tne new game and the next group
    4. If not, replace this game with a Phanthom one.
    5. Finally if the group only have phantom games, might be good to remove it.
    */

    const
      { tournament } = this.props;

    let bracket = tournament.Brackets[indexBracket],
      { Groups } = bracket;

    const recursiveFunction = (Groups, indexGroup, idGame) => {

      const group = Groups[indexGroup],
        game = _.find(group.Games, game => parseInt(game.IdGame, 10) === parseInt(idGame, 10));

      if (game) {

        if (game.GameTeams[0].comesFrom.Id || game.GameTeams[1].comesFrom.Id) {
          if (game.GameTeams[0].comesFrom.Id) recursiveFunction(Groups, indexGroup + 1, game.GameTeams[0].comesFrom.Id);
          if (game.GameTeams[1].comesFrom.Id) recursiveFunction(Groups, indexGroup + 1, game.GameTeams[1].comesFrom.Id);
        }

        // Replace this game with a Phantom
        group.Games[_.findIndex(group.Games, game => parseInt(game.IdGame, 10) === parseInt(idGame, 10))] = {
          phantom: true,
          GameTeams: [{ comesFrom: {} }, { comesFrom: {} }]
        }

        // If the group is just Phanthom Games, remove it
        if (!_.find(group.Games, game => !game.phantom)) {
          Groups = Groups.splice(indexGroup, 1);
        }

        // call API to delete game        
        request.del(`${config.apiEndpoint}/api/v4/games/game/${game.IdGame}`) //${idDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then(() => {
            this.props.notify && this.props.notify();
          });


      } else {
        alert('not game found!')
      }
    }

    recursiveFunction(Groups, indexGroup, idGame);
  }

  fnAddParentGame = ({ idGame, indexTeam, indexGroup, indexGame, indexBracket }) => {
    const { tournament, idDivision, defaultGameDuration } = this.props;

    // indexGroup represents the group from where I'm comming from
    let bracket = tournament.Brackets[indexBracket],
      { Groups } = bracket;

    let group = Groups[indexGroup],
      game = _.find(group.Games, (game) => {
        return game.IdGame === idGame;
      }),
      team = game.GameTeams[indexTeam];

    // check if the bracket already has group for the parent
    if (!Groups[indexGroup + 1]) {

      Groups.push({
        Group: indexGroup + 1,
        Games: []
      });

      // Add phantom games based on the group index      
      for (var i = 0; i < Math.pow(2, ((indexGroup + 1) - bracket.paddingFromTheRoot)); i++) {
        Groups[indexGroup + 1].Games.push({
          phantom: true,
          GameTeams: [{ comesFrom: {} }, { comesFrom: {} }]
        });
      }
      
    } else {

      if (Groups[indexGroup + 1].Games.length === 0) {
        
        // Add phantom games based on the group index        
        for (var j = 0; j < Math.pow(2, ((indexGroup + 1) - bracket.paddingFromTheRoot)); j++) {
          Groups[indexGroup + 1].Games.push({
            phantom: true,
            GameTeams: [{ comesFrom: {} }, { comesFrom: {} }]
          });
        }
      }
    }

    // The new game should replace a phantom game based on the team of the game that's parenting
    const newGame = {
      Group: indexGroup + 1,
      Ghost: false,
      GameTeams: [{ comesFrom: {} }, { comesFrom: {} }],
      Location: {}, GameDate: moment(900, 'hmm'),
      GameStartTime: moment(900, 'hmm'),
      GameStartTimeFormated: moment(900, 'hmm').format('HH:mm:ss'),
      GameEndTime: moment(900, 'hmm').add(defaultGameDuration, 'minutes').format('HH:mm:ss'),
      IdDivision: idDivision,
      RoundNumber: indexGroup + 1,
      IdGameBracket: idGame
    }

    Groups[indexGroup + 1].Games[(2 * indexGame) + indexTeam] = newGame;

    // Create the 1st game
    request.post(`${config.apiEndpoint}/api/v4/games/placeholder`) //${idDivision}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .send(newGame)
      .then(({ body: { IdGame } }) => {

        Groups[indexGroup + 1].Games[(2 * indexGame) + indexTeam].IdGame = IdGame;
        team.comesFrom = { Id: IdGame };

        // Create the bracket association
        request.post(`${config.apiEndpoint}/api/v4/brackets/bracket/${tournament.IdTournament}`) //${idDivision}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({ IdGame, Index: indexTeam, IdGameComesFrom: idGame })
          .then(({ body: { Id } }) => {
            this.props.notify && this.props.notify();
          });
      });
  }

  setDirty = () => this.setState({ dirty: true });

  saveName = () => {
    const { tournament } = this.props;
    request.patch(`${config.apiEndpoint}/api/v4/brackets/group/${tournament.IdTournament}`) //${idDivision}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .send({ Name: this.txtName.value, IdDivision: tournament.IdDivision })
      .then(({ body: { IdGame } }) => {
        this.props.notify && this.props.notify();
        this.setState({ dirty: false });
      });
  }  

  onRemove = () => this.setState({ deleting: !this.state.deleting });

  toggleDivisionPicker = () => this.setState({ isBracketTagOpen: !this.state.isBracketTagOpen });

  selectTag = (tag) => {
    const { tournament } = this.props;
    request.patch(`${config.apiEndpoint}/api/v4/brackets/group/${tournament.IdTournament}`) //${idDivision}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .send({ Name: this.txtName.value, IdDivision: tournament.IdDivision, IdGameBracketGroupType: tag.IdGameBracketGroupType })
      .then(({ body: { IdGame } }) => {
        this.props.notify && this.props.notify();
        this.setState({ dirty: false });
      });
  }

  renderBracket = (bracket, indexBracket) => {

    const { Groups } = bracket, { fields, idDivision, index } = this.props;

    // The height of this must be calculated based on the number of groups //
    // Minimun of 100 + 50 padding top and bottom (maybe)
    let heightOfGames = 205,
      calculatedBracketHeight = 100 + 25 + _.max([100, heightOfGames * Math.pow(2, (_.filter(Groups, group => {
        if (group.Games && group.Games.length) return true;
        return false;
      }).length - 1))]);     
      

    return (
      <div key={indexBracket} className="tournament-bracket d-flex flex-row-reverse" style={{ height: calculatedBracketHeight + 'px' }}>
        {Groups.map((group, i) => (
          <section key={i} className="">
            <MenuProvider className={`bracket-group d-flex flex-column justify-content-around`} id={`menuId-${index}`} data={{ paddingFromTheRoot: i, group }}>
              <div className="bracket-gray-column" />
              {group.Games.map((game, j) => (
                <Game positions={this.props.positions} game_types={this.props.game_types} fields={fields} key={j} game={game} index={j} idDivision={idDivision}
                  indexBracket={indexBracket} fnAddParentGame={this.fnAddParentGame} setDefaultGameDuration={this.props.setDefaultGameDuration} defaultGameDuration={this.props.defaultGameDuration}
                  fnDeleteParentGame={this.fnDeleteParentGame} fnNotify={this.props.notify}
                />
              ))}
            </MenuProvider>
            <span className="white round-number block">{(Groups.length - i) === Groups.length ? 'Final Round' : `Round ${Groups.length - i}`}</span>
          </section>
        ))}
      </div>
    );
  }

  render() {
    const { tournament, zoomFactor, index, bracketGroupTypes } = this.props, { dirty, randomId } = this.state;

    return (
      <section className="">
        
        { /* HEADER */}
        <div className="d-flex flex-row bg-info" style={{ height: '50px' }}>
          <span className="white idented-half align-self-center margin-right-half">Bracket Group Name</span>
          <input type="text" className="form-control control-sm align-self-center " defaultValue={tournament.Name} ref={i => this.txtName = i}
            style={{ width: '200px' }} placeholder={tournament.Name} onChange={this.setDirty} />
          {dirty && <button className="btn btn-link white font-10" onClick={this.saveName}><i className='fa fa-save' /> Save Name</button>}
          {/* Bracket group Type */}
          <Dropdown className="align-self-center" size="sm" isOpen={this.state.isBracketTagOpen}
            toggle={this.toggleDivisionPicker}>
            <DropdownToggle color="info" caret>
              {(tournament && tournament.IdGameBracketGroupType) ? (tournament.GameBracketGroupType) : 'Select Type'}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem active={tournament && tournament.IdGameBracketGroupType === null} onClick={() => this.selectTag({})}>None</DropdownItem>
              {bracketGroupTypes && bracketGroupTypes.map((t, i) => <DropdownItem key={i} active={tournament && tournament.IdGameBracketGroupType === t.IdGameBracketGroupType} onClick={() => this.selectTag(t)}>{t.GameBracketGroupType}</DropdownItem>)}
            </DropdownMenu>
          </Dropdown>
          <button className="btn btn-link ml-auto" id={`delete-bracket-${randomId}`} onClick={this.onRemove}>
            <i className="fa fa-times white" />
          </button>
          {/* TODO: confirm this delete */}
          <Popover placement="bottom" isOpen={this.state.deleting} target={`delete-bracket-${randomId}`} toggle={this.onRemove}>
            <PopoverHeader>Are you sure?</PopoverHeader>
            <PopoverBody className="d-flex flex-row justify-content-center">
              <button className="btn btn-success btn-sm" onClick={this.props.removeTournament}>Yes, Delete it</button>
              <button className="btn btn-danger btn-sm" onClick={this.onRemove}>No, Cancel</button>
            </PopoverBody>
          </Popover>
        </div>

        
        { /* Brackets down here */}
        <div className="bracket-container" style={{ overflowX: 'scroll', zoom: (zoomFactor / 100) }}>
          {tournament.Brackets.map((bracket, indexBracket) => this.renderBracket(bracket, indexBracket))}
        </div>

        
        { /* Global Context Menu */}
        <Menu id={`menuId-${index}`}>
          <Item onClick={(elements) => { this.clickOnContextMenu(elements) }}><i className="fas fa-medal margin-right-half" />Add Consolation Game</Item>
        </Menu>
      </section>
    );
  }
}

export default TournamentBracket;
