function reducer(state = [], action) {
    // call saga to fetch the leagues from API ?
    switch (action.type) {
        case 'SETTINGS/SET':
            return action.settings;
        case 'SEASON/CLEAR_POINT_SYSTEM':
            return null;
        default:
            return state;
    }
}

export default reducer;