function reducer(state = [], action) {
  // call saga to fetch the leagues from API ?
  switch (action.type) {
    case 'LIBRARY/SET':
      return action.library;
    case 'LIBRARY/CLEAR':
      return null;
    default:
      return state;
  }
}

export default reducer;
