
import _ from 'lodash';

function reducer(state = [], action) {
    switch (action.type) {
        case 'WAITLISTS/SET':
            return action.waitlists;
        case 'WAITLISTS/CLEAR':
            return null;
        case 'WAILISTS/ADD':
            return [...state, action.item];
        case 'WAITLISTS/ACCEPT':
            return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        case 'WAITLISTS/RETURN':
            return [...state, _.extend(action.item, { RoleStatus: 'Waiting' })];
        default:
            return state;
    }
}

export default reducer;