import React from 'react';
import { Animated } from 'react-animated-css';
import ClipListItem from './partials/ClipListItem';
import Loader from '../common/Loader';
import EditModal from '../common/modals/EditVideo';
import _ from 'lodash';
import './css.css';

class Clips extends React.Component {
    state = {
        bootstrapped: true,
        videos: null,
        editing: false,
        selectedVideo: null
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, selectedTeam, family_lastname } = this.props;

        setTitle && setTitle({
            main: 'Clips',
            sub: (selectedTeam && selectedTeam.Name) ? selectedTeam.Name : `${family_lastname}'s`
        });

        const { params } = this.props;
        this.props.familyActions && this.props.familyActions.fetchMembers();
        params.idTeam && this.props.teamActions && this.props.teamActions.fetchTeamSeasons && this.props.teamActions.fetchTeamSeasons(params.idTeam);
        this.props.teamActions && this.props.teamActions.fetchVideos(params.idTeam || 1);
        this.props.familyActions && this.props.familyActions.getFamilySubscriptions();
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ videos: nextProps.clips, family: nextProps.family })
    }
    //

    toggleEdit = () => this.setState({ editing: !this.state.editing });
    onEdit = video => this.setState({ editing: true, selectedVideo: video });

    render() {
        const { videos = [], bootstrapped, editing } = this.state, { params, selectedTeam, family } = this.props;

        return (<Animated animateIn="fadeIn">

            {bootstrapped && videos && videos.length === 0 && (
                <div key="no-leagues" className="d-flex flex-col justify-content-center centered-message">
                    <h1 className="white align-self-center block text-center">There are no Clips registered for this team</h1>
                </div>
            )}
            {bootstrapped && videos && videos.length > 0 && (
                <div style={{ margin: 'auto' }} className="p-4">
                    <div className="w3-content mx-auto">
                        {videos.length > 0 &&
                            <div className="mt-4 d-flex flex-wrap">
                                {_.chain(videos)
                                    .sortBy('createDate').value().reverse().map((video, i) => <ClipListItem family={family} key={video.idVideo} onEdit={this.onEdit} index={i} video={video} idTeam={params.idTeam} />)}
                            </div>
                        }
                    </div>
                </div>
            )
            }

            {!bootstrapped && <Loader key="loader" message={`Loading Videos`} />}

            {
                editing && <EditModal {...this.props} family={this.state.family} toggle={this.toggleEdit} isOpen={editing} video={this.state.selectedVideo}
                    selectedTeam={selectedTeam} />
            }

        </Animated >)
    }
}

export default Clips;
