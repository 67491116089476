import React from 'react';
import { Redirect } from 'react-router';
import _ from 'lodash';
import request from 'superagent';
import { Link } from 'react-router';

import config from '../../config';

import './login.css';

class Reset extends React.Component {

  state = {
    redirectToReferrer: false,
    mode: 1,
    invalid: false,
    selectedBg: null,
    email: null,
    token: null,
    message: null
  }

  // Lifecycle
  componentWillMount() {
    const { params } = this.props;
    if (!params.token) {
      // Redirect to login
      this.props.router.push('/login');
    } else {
      this.setState({
        selectedBg: _.sample(config.bgs),
        email: localStorage.getItem('email'),
        token: params.token
      });
    }
  }
  //

  doReset = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { microTransac } = this.state;
    if (!microTransac) {

      this.setState({
        microTransac: true,
        invalid: false
      });

      request.post(`${config.apiEndpoint}/api/v4/membership/reset`)
        .send({
          password: this.txtPassword.value,
          token: this.state.token
        })
        .then((data) => {
          if (data.body.success) {
            this.setState({ mode: 2 });
          } else {
            this.setState({
              microTransac: false,
              invalid: true,
              message: data.body.message
            });
          }
        }, error => {
          this.setState({
            microTransac: false,
            invalid: true,
          });
        });
    }
  }

  back = () => this.setState({ mode: this.state.mode === 1 ? 1 : (this.state.mode - 1) });

  render() {
    const { mode, microTransac, invalid, selectedBg, email, token } = this.state;
    if (!token) {
      return (<Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />)
    }
    return (
      <div className="limiter">
        <div className="container-login100 bg-gray">
          <div className="wrap-login100  shadow-box bg-white" >
            { /* Recover Form */}
            {mode === 1 && (
              <form className="login100-form validate-form" key="login-form" >
                <div className="d-flex flex-row justify-content-center w-100 mb-4 pb-4">
                                    <div id="logo-sqwad" className="d-flex flex-column justify-content-center align-self-center ">
                                        <i className="icon-logo-header jeff_blue block text-center align-self-center line1" style={{ fontSize: 100 }}></i>
                                        <span className="font-12 jeff_blue align-self-end" style={{ marginTop: -30, marginRight: -27 }}>By Sports Logic</span>
                                    </div>
                                </div> 
                <span key="title" className="w-100 jeff_blue text-center margin-bottom-half">Please enter your new password for {email}</span>
                <div key="email" className="wrap-input100 validate-input no-margin" data-validate="Type user name">
                  <input maxLength="15" className="input100" type="password" name="password" placeholder="Password" style={{ borderRadius: '0', height: '55px' }} ref={(input) => this.txtPassword = input} />
                </div>
                <span className="font-8 m-auto">NOTE: Password must be between 6 and 15 characters</span>
                <div key="login-button" className="container-login100-form-btn" style={{ marginTop: '20px' }}>
                  <button type="submit" className="login100-form-btn" onClick={this.doReset}>
                    {!microTransac && <span>Reset</span>}
                    {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                  </button>
                  { /* Error Message */}
                  {invalid && <div className="d-flex flex-column p-2 bg-danger bd-highlight" style={{ width: '100%' }}>
                    <span className="white align-self-center font-8">{this.state.message || 'Your password could not be reset'}</span>
                  </div>}
                </div>
                <div key="recover-password" className="w-full text-center p-t-27 p-b-239" style={{ paddingTop: '20px', paddingBottom: '100px' }} />
                <div key="registration-link" className="w-full text-center">
                  <Link to="/login" className="underlined jeff_blue">Back to Login <i className="fa fa-sign-in-alt" /></Link>
                </div>
              </form>)}
            { /* Pretty Image */}
            <div className={`login100-more ${selectedBg}`} key="picture"></div>
            { /* Select User */}
            {mode === 2 && (
              <div className="login100-form" key="login-users">
                <div key="logo" id="logo" className="text-center" style={{ margin: 'auto', width: '15em' }} />
                <div key="recover-password" className="w-full text-center p-t-27 p-b-239" style={{ paddingTop: '20px', paddingBottom: '100px' }}>
                  <span key="title" className="w-100 jeff_blue text-center margin-bottom-half">Your password has been reset successfully </span>
                </div>
                <div key="registration-link" className="w-full text-center">
                  <Link to="/login" className="underlined">Back to Login <i className="fa fa-sign-in-alt" /></Link>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    )
  }
}

export default Reset;
