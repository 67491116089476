import React from 'react';

class TeamServices extends React.Component {
    state = {}
    render() {
        return <section>

            {/*<div className="card tile shadow-box mt-2">
                <div className="d-flex flex-row py-2 px-4 pointer" onClick={() => this.setState({ toggle_services: !this.state.toggle_services })}>
                    <span className="align-self-center font-16 montserrat black">Team Services</span>
                    <i className={`fas fa-chevron-${this.state.toggle_services ? 'up' : 'down'} black ml-auto font-16 align-self-center`} />
                </div>
    </div>*/}


        </section>
    }
}

export default TeamServices;