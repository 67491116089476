import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as geoActions from '../redux-sagas/actions/geoActions';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import Main from '../components/create_account/index2';

function mapStateToProps(state) {
    return {
        countries: state.countries,
        country_codes: state.country_codes,
        states: state.states,
        carriers: state.carriers,
        disclaimer: state.disclaimer,
        spotlight: null,
        spotlightOn: state.spotlightOn
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        geoActions: bindActionCreators(geoActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch)
    }, {});
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
