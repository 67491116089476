function reducer(state = [], action) {

    switch (action.type) {
        case 'DIVISION/SET':
            return action.division || null;
        case 'DIVISION/CLEAR':
            return null;        
        default:
            return state;
    }
}

export default reducer;
