import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import request from 'superagent';
import config from '../../../config';
import COLORS from '../../../colors';
import _ from 'lodash';
import { Link } from 'react-router';

class BuildPreview extends React.Component {

  state = {
    activeTab: 1,
    bootstrapped: false,
    summary: []
  }

  componentWillMount() {
    const { params } = this.props, { idSeason } = params;
    if (!idSeason) return;

    request.get(`${config.apiEndpoint}/api/v4/schedule/summary/${idSeason}`)
      .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
      .then(data => {
        this.setState({
          bootstrapped: true,
          summary: _.values(_.groupBy(data.body.summary, 'IdDivision'))
        });
      });
  }

  changeTab = (activeTab) => this.setState({ activeTab });

  render() {
    const { isOpen, toggleBuildPreview } = this.props,
      { activeTab, summary } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggleBuildPreview} style={{ width: 1000 }}>
        <ModalHeader toggle={toggleBuildPreview} className="bg-blue-dark white">
          Build Results
        </ModalHeader>
        <ModalBody id="build-summary">
          <ul className="nav nav-tabs font-16">
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
                <i className="fa fa-cog purple"></i> <span className="half-idented">Poolplay Games</span>
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
                <i className="fa fa-cog"></i> <span className="half-idented">Playoff Games (*soon*)</span>
              </button>
            </li>
          </ul>
          <div className="tab-content">
            {activeTab === 1 && (
              <div className="row no-margin no-padding">
                <table className="table font-10">
                  <thead>
                    <tr>
                      <th>Division / Flight</th>
                      <th className="text-center">Min. Games x Team</th>
                      <th className="text-center">Teams</th>
                      <th className="text-center">Req. Pairings
                        <br />
                        <span className="font-8">((G x T) / 2)</span></th>
                      <th className="text-center">Scheduled Pairings</th>
                      <th className="text-center">Missing Pairings</th>
                    </tr>
                  </thead>
                  {summary.map((division, i) => (
                    <tbody key={i}>
                      {division.map((item, j) => (
                        <tr key={j} className={`${(item.RequiredPairings - item.ScheduledPairings) > 0 ? 'bg-danger white' : ((item.RequiredPairings - item.ScheduledPairings) < 0 ? 'bg-warning' : '')}`}>
                          <td style={{ whiteSpace: 'normal' }}>
                            {item.DivisionName},
                            {item.FlightName}
                          </td>
                          <td className="text-center">
                            {item.MinimumGamesPerTeam}
                          </td>
                          <td className="text-center">
                            {item.NumberOfTeams}
                          </td>
                          <td className="text-center">
                            {item.RequiredPairings}
                          </td>
                          <td className="text-center">
                            {item.ScheduledPairings}
                          </td>
                          <td className="text-center">
                            {item.MissingPairings}
                          </td>
                        </tr>))}
                    </tbody>))}
                </table>
              </div>
            )}
            {activeTab === 2 && (
              <div className="row no-margin no-padding">
              </div>)}
          </div>
          <ReactTooltip effect="solid" place="left" id="modal-tooltip-left" />
          <ReactTooltip effect="solid" place="right" id="modal-tooltip-right" />
          <ReactTooltip effect="solid" place="top" id="modal-tooltip-top" />
          <ReactTooltip effect="solid" place="bottom" id="modal-tooltip-bottom" />
        </ModalBody>
        <ModalFooter>
          <button style={{ border: `1px solid ${COLORS.gray}`, borderRadius: 20, color: COLORS.gray }} className="ml-auto py-2 px-3 align-self-center block" onClick={toggleBuildPreview}>Close</button>
          {' '}
          <Link to={`/season/${this.props.params.idSeason}/games_calendar`} style={{ border: `1px solid ${COLORS.green}`, borderRadius: 20, color: COLORS.green }} className="ml-2 py-2 px-3 align-self-center block">Go to Calendar</Link>
        </ModalFooter>
      </Modal>)
  }
}

export default BuildPreview;
