import React from 'react';
import { Animated } from 'react-animated-css';
import moment from 'moment';
import { generateRandomId } from '../../../../../helpers';
import fakeAuth from '../../../../../fakeAuth';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Slider from 'react-rangeslider';
import _ from 'lodash';

// To include the default styles
import 'react-rangeslider/lib/index.css'
import BlackOverlay from '../../../../partials/black_overlay';
import { positions } from 'react-alert';
import Network from '../network';
import Invite from '../invite';
import InviteCollaborator from '../InviteCollaborator';
import NetworkCollaborator from '../NetworkCollaborator';
import ShareMatch from '../ShareMatch';

class AddAttributes extends React.Component {
    state = {
        bootstrapped: false,
        template: null,
        collaborator_step: 0,
        match: {
            CreatorNameFirst: fakeAuth.getUser().NameFirst,
            CreatorNameLast: fakeAuth.getUser().NameLast,
            EvaluationDate: moment(),
            IdFolder: null, // TODO
            IdGender: 1,
            IdPlayerEvaluation: generateRandomId(10),
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: this.props.selected_user ? this.props.selected_user.NameFirst : '',
            NameLast: this.props.selected_user ? this.props.selected_user.NameLast : '',
            ReportType: 11,
            Topics: [],
            IdUserTarget: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdUserFollow: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdTeam: this.props.IdTeam
        },
        template: null,
        new_template: null,
        collaborators: [],
        adding_attributes: null
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.adding_attributes !== this.state.adding_attributes && !this.state.pick_template && nextProps.adding_attributes) this.setState({ pick_template: true });
        this.setState({ adding_attributes: nextProps.adding_attributes });
        this.setup(nextProps);
    }

    setup = (p = this.props) => {
        const { topics, customAttributesTemplates, collaborators } = p;
        const { match, template } = this.state;
        this.setState({ collaborators });
        if (topics) {
            this.setState({
                match: {
                    ...match,
                    Topics: _.chain(template ? template.topics : topics)
                        .map(t => {
                            return {
                                IdTopic: t.IdTopic,
                                Value: 0,
                                TopicName: t.TopicName,
                                SubTopics: _.map(t.subtopics, s => {
                                    return {
                                        IdTopic: s.IdTopic,
                                        Value: 0,
                                        TopicName: s.TopicName,
                                    }
                                })
                            }
                        }).value()
                }, bootstrapped: true
            });
        }
    }

    onColor = (color) => {
        const { match } = this.state;
        match.Color = color;
        this.setState({ match });
    }

    onSave = () => {
        const { match, template } = this.state;        
        this.setState({ dirty: true }, () => {
            if (!this.txtName.value) {
                alert('Please complete the missing fields');
                return;
            }
            this.props.scout_actions.save_match({
                ...match,
                NameFirst: this.txtName.value.trim(),
                NameLast: '',
                Scale: template ? template.Scale : 10,
                Note: this.txtNotes.value,
                Notes: this.txtNotes.value
            });
            this.props.toggle();
        });
    }

    onToggleSubTopic = index => {
        const { match } = this.state;
        match.Topics[index].toggled = !match.Topics[index].toggled;
        if (match.Topics[index].toggled) {
            // Recalculate full topics value
            match.Topics[index].Value = _.chain(match.Topics[index].SubTopics)
                .map(i => i.Value)
                .reduce((a, b) => a + b, 0)
                .value() / match.Topics[index].SubTopics.length;
        }
        this.setState({ match });
    }

    onTopicChange = (index, value) => {
        const { match } = this.state;
        if (match.Topics[index].toggled) { return; }
        match.Topics[index].Value = value;
        this.setState({ match });
    }

    onSubTopicChange = (i, j, value) => {
        const { match } = this.state;
        match.Topics[i].SubTopics[j].Value = value;

        // Recalculate full topics value
        match.Topics[i].Value = _.chain(match.Topics[i].SubTopics)
            .map(i => i.Value)
            .reduce((a, b) => a + b, 0)
            .value() / match.Topics[i].SubTopics.length;

        this.setState({ match });
    }

    onSaveTemplate = () => {
        const { new_template } = this.state;
        // clear up DELETED topics;
        new_template.topics = _.reject(new_template.topics, t => (t.DELETED || !t.TopicName));
        new_template.topics.forEach(topic => {
            topic.subtopics = _.reject(topic.subtopics, s => (s.DELETED || !s.TopicName));
        });
        let payload = {
            Title: this.txtTemplateName.value,
            Scale: this.txtTemplateScale.value,
            ReportType: 86,
            EvaluationDate: moment().format(),
            IdUser: fakeAuth.getUser().IdUser,
            name: this.txtTemplateName.value.toUpperCase(),
            topics: new_template.topics
        }
        if (new_template.IdMatch) payload.IdMatch = new_template.IdMatch;
        this.props.scout_actions.save_match({ ...payload });
        this.setState({ adding_template: false });
    }

    onDeleteTemplate = () => {
        const { edit_template } = this.state;
        this.setState({ edit_template: null });
        if (edit_template.IdUser === fakeAuth.getUser().IdUser) this.props.scout_actions.delete_match(edit_template.IdMatch);
        else this.props.scout_actions.unfollow_match(edit_template.IdMatch);
        // TODO: call APIS
    }

    render() {
        const { match, dirty, template, new_template, adding_template, collaborator_step } = this.state, { customAttributesTemplates } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.adding_attributes ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-row w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                    {match && <section className="w3-container w3-content mx-auto w-100">
                        <div style={{ maxWidth: 600 }} className="d-flex flex-column black mx-auto align-self-start">

                            <div className="tile card p-3">
                                <label>Athlete's Name</label>
                                <input type="text" defaultValue={match.NameFirst ? `${match.NameFirst} ${match.NameLast}` : match.NameFirst} ref={i => this.txtName = i} className={`w3-input ${(dirty && !this.txtName.value) ? 'bg-danger' : ''}`} />
                                <button onClick={() => this.setState({ pick_template: true })} className="btn btn-link text-left w-100 d-flex flex-row">
                                    <span className="align-self-center w-100">Template: {(template || { Title: 'Standard (Soccer | Football)' }).Title}</span>
                                    <i className="ml-auto fas fa-chevron-circle-down blue align-self-center" />
                                </button>
                            </div>
                            <div className="m-2" />
                            <div className="tile card p-3">
                                {match.Topics && match.Topics.map((topic, i) => <section className="my-1" key={i}>
                                    <Slider
                                        min={0}
                                        max={template ? parseInt(template.Scale || 10, 10) : 10}
                                        step={0.1}
                                        value={topic.Value}
                                        orientation="horizontal"
                                        onChange={(value) => this.onTopicChange(i, value)}
                                        tooltip={false}
                                    />
                                    <div className="d-flex flex-row pt-2">
                                        <span className="align-self-center montserrat line1">{topic.TopicName}</span>
                                        {topic.SubTopics.length > 0 && <i onClick={() => this.onToggleSubTopic(i)} className={`blue pointer ml-auto mr-2 align-self-center fas fa-chevron-circle-${topic.toggled ? 'up' : 'down'}`} />}
                                        <span className={`align-self-center montserrat-bold ${topic.SubTopics.length > 0 ? '' : 'ml-auto'}`}>{topic.Value.toFixed(1)}</span>
                                    </div>
                                    {topic.toggled && <section className="pl-4 ml-4 pt-2">
                                        {topic.SubTopics && topic.SubTopics.map((subtopic, j) => <section key={j}>
                                            <Slider
                                                min={0}
                                                max={template ? parseInt(template.Scale || 10, 10) : 10}
                                                step={0.1}
                                                value={subtopic.Value}
                                                orientation="horizontal"
                                                onChange={(value) => this.onSubTopicChange(i, j, value)}
                                                tooltip={false}
                                            />
                                            <div className="d-flex flex-row pt-2">
                                                <span className="align-self-center line1">{subtopic.TopicName}</span>
                                                <span className="ml-auto align-self-center">{subtopic.Value.toFixed(1)}</span>
                                            </div>
                                        </section>
                                        )}
                                    </section>}
                                </section>
                                )}
                            </div>
                            <div className="w-100 d-flex flex-row mt-2">
                                <span className="montserrat black font-12 ml-auto mr-2">(Scale: 1 to {template ? template.Scale : 10})</span>
                            </div>
                            <div className="tile card p-3">
                                <label>Notes</label>
                                <input type="text" defaultValue={match.Notes} ref={i => this.txtNotes = i} className={`w3-input`} />
                            </div>

                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Attributes Evaluation saved!' : 'Save Attributes Evaluation'}</button>
                            <div className="p-4" />
                        </div>
                    </section>}

                </section>

                {/* Add template */}
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>

            {/* TEMPLATE PICKER */}
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.pick_template ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <BlackOverlay />
                <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ pick_template: false })} />
                <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                    <div className="d-flex flex-row">
                        <button className="btn btn-link align-self-center" onClick={() => this.setState({ pick_template: false })}><i className="fas fa-arrow-left black font-16 align-self-center"></i></button>
                        <h4 className="ml-auto blue">POSITION TEMPLATES</h4>
                    </div>

                    <div onClick={() => this.setState({ template: null, pick_template: false }, this.setup)} className="card tile pl-4 pr-1 py-2 pointer mt-2 d-flex flex-row" style={{ height: 80 }}>
                        <i className="icon-recreational black font-20 align-self-center" />
                        <div className="d-flex flex-column montserrat ml-2 align-self-center">
                            <span className="black font-14 line1">Standard (Soccer | Football)</span>
                            <span className="gray font-10">Created by SQWAD for general use</span>
                        </div>
                    </div>

                    <h4 className="black mt-3">CUSTOM TEMPLATES</h4>


                    {customAttributesTemplates && customAttributesTemplates.map((c, i) => <div onClick={() => this.setState({ template: c, pick_template: false }, this.setup)} className="card tile pl-4 pr-1 py-2 pointer mt-2 d-flex flex-row" key={i} style={{ height: 80 }}>
                        <i className="icon-recreational black font-20 align-self-center" />
                        <div className="d-flex flex-column montserrat ml-2 align-self-center">
                            <span className="black font-14 line1">{c.Title}</span>
                            <span className="gray font-10">{`Created${c.CreatorNameFirst ? ` by ${c.CreatorNameFirst} ` : ' '}on ${moment(c.EvaluationDate).format('MMM Do YYYY')}`}</span>
                        </div>
                        <button className="ml-auto pl-4 pr-3 align-self-start" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({ edit_template: c });
                        }}><i className="fas black fa-ellipsis-v"></i></button>
                    </div>)}



                    <div onClick={() => this.setState({ adding_template: !this.state.adding_template, new_template: { topics: [] } })} style={{ width: 70, height: 70, borderRadius: '50%', border: `1px solid black`, position: 'fixed', bottom: 30, right: 20 }} className="btn-info d-flex flex-row justify-content-center" >
                        <i className="font-30 fas fa-plus white align-self-center" />
                    </div>
                </div>
            </Animated>

            {/* ADD TEMPLATE */}
            {new_template && adding_template && <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.adding_template ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ adding_template: false })} />
                <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                    <div className="d-flex flex-row">
                        <button className="btn btn-link align-self-center" onClick={() => this.setState({ adding_template: false })}><i className="fas fa-arrow-left black font-16 align-self-center"></i></button>
                        <h4 className="blue align-self-center ml-auto">ADD CUSTOM FORM</h4>
                        <button onClick={this.onSaveTemplate} className="btn btn-success ml-auto align-self-center" style={{ borderRadius: 25 }}>{new_template.IdMatch ? 'Save Changes' : 'Save'}</button>
                    </div>
                    <hr />
                    <label>Title (Example: 'U10 Soccer Template')</label>
                    <input defaultValue={new_template.Title} type="text" ref={i => this.txtTemplateName = i} className={`w3-input bordered`} />
                    <div className="d-flex flex-row mt-2">
                        <label className="align-self-center line1">SCALE From Zero (0)</label>
                        <input defaultValue={new_template.Scale} type="text" placeholder='- TO -' ref={i => this.txtTemplateScale = i} className={`w3-input bordered ml-2`} />
                    </div>
                    <hr />
                    <div className="d-flex flex-column">
                        {new_template.topics.map((t, i) => <figure className={t.DELETED ? 'hide' : `card tile mx-2 mt-1 p-2`} key={i}>
                            <div className="d-flex flex-row">
                                <input type="text" defaultValue={t.TopicName} onChange={(e) => {
                                    const { new_template } = this.state;
                                    new_template.topics[i].TopicName = e.target.value;
                                    this.setState({ new_template });
                                }} className={`w3-input bordered`} />
                                <button onClick={() => {
                                    const { new_template } = this.state;
                                    new_template.topics[i].DELETED = true;
                                    this.setState({ new_template });
                                }} className="btn btn-link ml-auto"><i className="fas fa-times" /></button>
                            </div>
                            <div className="d-flex flex-column">
                                {t.subtopics && t.subtopics.map((s, j) => <div className={s.DELETED ? 'hide' : `d-flex flex-row pl-4`} key={j}>
                                    <input type="text" defaultValue={s.Title} onChange={(e) => {
                                        const { new_template } = this.state;
                                        new_template.topics[i].subtopics[j].TopicName = e.target.value;
                                        this.setState({ new_template });
                                    }} className={`w3-input bordered ml-4`} />
                                    <button onClick={() => {
                                        const { new_template } = this.state;
                                        new_template.topics[i].subtopics[j].DELETED = true;
                                        this.setState({ new_template });
                                    }} className="btn btn-link ml-auto"><i className="fas fa-times" /></button>
                                </div>)}
                            </div>
                            <div className="d-flex flex-row">
                                <button onClick={() => {
                                    const { new_template } = this.state;
                                    new_template.topics[i].subtopics.push({});
                                    this.setState({ new_template });
                                }} className="btn btn-link ml-auto">Click here to add sub-attributes</button>
                            </div>
                        </figure>)}
                    </div>
                    <button onClick={() => {
                        const { new_template } = this.state;
                        new_template.topics.push({ subtopics: [] });
                        this.setState({ new_template });
                    }} className="btn btn-info mt-2" style={{ borderRadius: 25 }}>Add Attribute</button>
                </div>
            </Animated>}

            {/* EDIT TEMPLATE MENU */}
            {this.state.edit_template && <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ edit_template: false })} />
                <div className="d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                    <BlackOverlay position='absolute' />
                    <div style={{ position: 'absolute', top: 0, bottom: 300, left: 0, right: 0 }} onClick={() => this.setState({ edit_template: false })} />
                    <Animated animationInDuration={200} animationIn='slideInUp' animationOut='slideOutDown'
                        className="w3-white d-flex flex-column" style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: 300, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                        <div className="d-flex flex-row mt-2">

                            <button className="btn btn-link align-self-center" onClick={() => this.setState({ edit_template: false })}><i className="fas fa-times black font-16 align-self-center"></i></button>
                            <button className="ml-auto btn btn-link">{this.state.edit_template.Title}</button>
                            <button className="ml-auto btn btn-link align-self-center" ><i className="fas fa-arrow-left white font-16 align-self-center"></i></button>
                        </div>

                        <hr />
                        {this.state.edit_template.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ new_template: this.state.edit_template, edit_template: false, adding_template: true })} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-edit" /></div>Edit template</button>}
                        {this.state.edit_template.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ listing_collaborators: true, collaborators: null }, () => {
                            this.props.scout_actions && this.props.scout_actions.fetch_match_collaborators(this.state.edit_template.IdMatch);
                        })} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-user-plus" /></div>Collaborators</button>}
                        <button onClick={() => this.props.onShare(this.state.edit_template)} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-share" /></div>Share template</button>


                        {!this.state.deleting_template && <button onClick={() => this.setState({ deleting_template: true })} className="btn btn-link text-left"><div className="btn btn-link"><i className="icon-x" /></div>{this.state.edit_template.IdUser === fakeAuth.getUser().IdUser ? 'Delete template' : 'Unfollow template'}</button>}
                        {this.state.deleting_template && <div className="d-flex flex-row">
                            <button className="ml-2 btn btn-link text-left">Are you sure?</button>
                            <button onClick={this.onDeleteTemplate} className="ml-auto btn btn-link"><i className="fas fa-check green" /></button>
                            <button onClick={() => this.setState({ deleting_template: false })} className="btn btn-link mr-2"><i className="fas fa-times red" /></button>
                        </div>}
                    </Animated>
                </div>
            </div>}

            {/* COLLABORATORS */}
            {this.state.listing_collaborators && <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.listing_collaborators ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ listing_collaborators: false })} />
                <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                    <div className="d-flex flex-row">
                        <button className="btn btn-link align-self-center" onClick={() => this.setState({ listing_collaborators: false })}><i className="fas fa-arrow-left black font-16 align-self-center"></i></button>
                        <h4 className="blue ml-auto align-self-center">COLLABORATORS</h4>
                    </div>
                    <figure className="card tile mt-2 p-3 d-flex flex-row">
                        <div className="cover align-self-center" style={{ width: 30, height: 30, borderRadius: 15, background: `url(${fakeAuth.getUser().UserImage}?full=80) no-repeat center center black` }} />
                        <div className="d-flex flex-column align-self-center ml-2">
                            <span>{`${fakeAuth.getUser().NameFirst} ${fakeAuth.getUser().NameLast}`}</span>
                        </div>
                        <span className="ml-auto align-self-center">Owner</span>
                    </figure>
                    <h4 className="black align-self-center">VIEWERS</h4>

                    {this.state.collaborators && this.state.collaborators.map((collaborator, i) => <figure key={i} className="card tile my-1 p-3 d-flex flex-row">
                        <div className="cover align-self-center" style={{ width: 30, height: 30, borderRadius: 15, background: `url(${collaborator.UserImage}?full=80) no-repeat center center black` }} />
                        <div className="d-flex flex-column align-self-center ml-2">
                            <span className="line1">{`${collaborator.NameFirst} ${collaborator.NameLast}`}</span>
                            <span className="font-8 line1 gray">{collaborator.EmailAddress}</span>
                        </div>
                        <button onClick={() => {
                            this.props.scout_actions.unfollow_generic_match({
                                IdUser: collaborator.IdUser,
                                IdMatch: this.state.edit_template.IdMatch
                            });
                        }} className="ml-auto align-self-center btn btn-link"><i className="fas fa-times gray" /></button>
                    </figure>)}

                    <div onClick={() => this.setState({ adding_collaborator: !this.state.adding_collaborator })} style={{ width: 70, height: 70, borderRadius: '50%', border: `1px solid black`, position: 'fixed', bottom: 30, right: 20 }} className="btn-info d-flex flex-row justify-content-center" >
                        <i className="font-30 fas fa-plus white align-self-center" />
                    </div>
                </div>
            </Animated>}


            {/* ADD COLLABORATOR */}
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.adding_collaborator ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <div style={{ position: 'fixed', top: 0, bottom: 0, right: 500, left: 0, zIndex: 200 }} onClick={() => this.setState({ adding_collaborator: false, collaborator_step: 0 })} />
                <div className="w3-white d-flex flex-column p-3" style={{ position: 'fixed', top: 0, bottom: 0, right: 0, width: 500, zIndex: 200, overflowY: 'auto' }}>
                    <div className="d-flex flex-row p-3">
                        {collaborator_step === 0 && <i className="fas fa-arrow-left font-16 pointer" onClick={() => this.setState({ adding_collaborator: false, collaborator_step: 0 })} />}
                        {collaborator_step !== 0 && <i className="fas fa-arrow-left font-16 pointer" onClick={() => this.setState({ collaborator_step: 0 })} />}

                        <h4 className="blue ml-auto align-self-center">ADD COLLABORATOR</h4>
                    </div>
                    {collaborator_step === 0 && <div className="d-flex flex-column h-100 justify-content-center p-4">
                        <div onClick={() => this.setState({ collaborator_step: 1 })} style={{ height: 'auto', border: '1px solid gray' }} className="align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                            <span className="icon-mail font-24 black align-self-center" />
                            <span className="font-16 toBold black align-self-center">INVITE</span>
                            <span className="font-10 black line1 align-self-center">using their Email</span>
                        </div>
                        <div onClick={() => this.setState({ collaborator_step: 2 })} style={{ height: 'auto', border: '1px solid gray' }} className="mt-4 align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                            <span className="icon-coach_registration font-24 black align-self-center" />
                            <span className="font-16 toBold black align-self-center">INVITE</span>
                            <span className="font-10 black line1 align-self-center">from an Existing Roster</span>
                        </div>
                    </div>}

                    {collaborator_step === 1 && <InviteCollaborator {...this.props} match={this.state.edit_template}
                        onSave={(users) => {
                            users.forEach(user => {
                                if (user.IdUser !== fakeAuth.getUser().IdUser)
                                    this.props.scout_actions.follow_match({
                                        IdUser: user.IdUser,
                                        IdMatch: this.state.edit_template.IdMatch
                                    });
                            });
                            this.setState({ adding_collaborator: false, collaborator_step: 0 });
                        }} />}
                    {collaborator_step === 2 && <NetworkCollaborator {...this.props} match={this.state.edit_template} onSave={(users) => {
                        users.forEach(user => {
                            if (user.IdUser !== fakeAuth.getUser().IdUser)
                                this.props.scout_actions.follow_match({
                                    IdUser: user.IdUser,
                                    IdMatch: this.state.edit_template.IdMatch
                                });
                        });
                        this.setState({ adding_collaborator: false, collaborator_step: 0 });
                    }} />}
                </div>
            </Animated>

            {/* SHARE */}
            <ShareMatch {...this.state} scout_actions={this.props.teamActions} toggle={() => this.setState({ share_match: false })} />
        </section >
    }
}

export default AddAttributes;