function reducer(state = [], action) {

    switch (action.type) {
        case 'MESSAGES/RECEIVED/SET':
            return action.messages;
        default:
            return state;
    }
}

export default reducer;