import React from 'react';
import _ from 'lodash';


class List extends React.Component {
  state = {
    sort: 'PlayerLastName',
    up: true
  };
  componentWillMount() {
    const { report } = this.props;
    this.setState({ report });
  }
  componentWillReceiveProps = nextProps => {
    const { report } = nextProps;
    this.setState({ report });
  }
  onSort = (sort) => {
    if (this.state.sort === sort) {
      this.setState({ up: !this.state.up });
    } else {
      this.setState({ sort });
    }
  }
  render() {

    const { report, sort, up } = this.state;
    let sorted = _.chain(report)
      .sortBy(sort).value();
    if (up) sorted = sorted.reverse();

    return (
      <table id="table-to-xls" className="table table-sm font-10">
        <thead>
          <tr>
            <th>Id Order</th>
            <th>Gift Date</th>
            <th>Transaction Id</th>
            <th>Payee Name</th>
            <th>Player ID</th>
            <th>Player First Name</th>
            <th>Player Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Street Address</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>Donation Amt</th>
            <th>Payment Method</th>
            {/*<th className={`pointer underlined ${sort === 'RegistrationDate' ? 'blue' : 'black'}`} onClick={() => this.onSort('RegistrationDate')}>{sort === 'RegistrationDate' && <i className={`fas fa-caret-${up ? 'up' : 'down'}`} />}Reg. Date</th>*/}
          </tr>
        </thead>
        <tbody>
          {sorted && sorted.map((record, i) => (
            <tr key={i}>
              <td>{record.IdOrder}</td>
              <td>{record.GiftDate}</td>
              <td>{record.TransactionID}</td>
              <td>{record.PayeeName}</td>
              <td>{record.PlayerID}</td>
              <td>{record.PlayerFirstName}</td>
              <td>{record.PlayerLastName}</td>
              <td>{record.Email}</td>
              <td>{record.Phone}</td>
              <td>{record.StreetAddress}</td>
              <td>{record.City}</td>
              <td>{record.State}</td>
              <td>{record.Zip}</td>
              <td>${record.DonationAmt}</td>
              <td>{record.PaymentMethod}</td>
            </tr>)
          )}
        </tbody>
      </table>
    )
  }
}

export default List;
