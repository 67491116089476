import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';


class OrganizationSessions extends React.Component {
    state = {
        microtransact: false,
        saved: false,
        sessions: [
            {
                id: 1,
                day: 'TUE',
                date: 'APR 30th',
                time: '2:00PM'
            },
            {
                id: 3,
                day: 'THU',
                date: 'MAY 2nd',
                time: '2:00PM'
            },
            {
                id: 5,
                day: 'MON',
                date: 'MAY 6th',
                time: '2:00PM'
            },
            {
                id: 7,
                day: 'WED',
                date: 'MAY 8th',
                time: '2:00PM'
            },
            {
                id: 2,
                day: 'TUE',
                date: 'APR 30th',
                time: '8:00PM'
            },

            {
                id: 4,
                day: 'THU',
                date: 'MAY 2nd',
                time: '8:00PM'
            },

            {
                id: 6,
                day: 'MON',
                date: 'MAY 6th',
                time: '8:00PM'
            },

            {
                id: 8,
                day: 'WED',
                date: 'MAY 8th',
                time: '8:00PM'
            },
        ]
    }

    // Lifecycle
    componentDidMount = () => {
        const { setTitle } = this.props;
        setTitle && setTitle({ main: 'SQWAD', sub: 'Sessions LFE' });
        this.props.leagueActions.fetchLeads();
    }
    componentWillReceiveProps = nextProps => {
        const { leads } = nextProps;        
        this.setState({ leads: _.filter(leads, l => l.Organization === 'lfe') });
    }
    componentWillUnmount = () => {

    }
    //    

    render() {
        const { leads, selected, mode } = this.state;
        return (
            <div className="container margin-bottom margin-top d-flex flex-column justify-content-center w-100">
                <h1 className="sqwad-font mx-auto mt-4">LFE SESSIONS</h1>
                <Animated animationIn="fadeInUp" isVisible={leads ? true : false} className="w3-content d-flex flex-md-row flex-column mt-4">

                    <div className="d-flex flex-wrap w-100">

                        {this.state.sessions.map((session, key) => <div key={key} className="w-25 tile card shadow-box p-4 ">
                            <span className="mx-auto line1 font-10 hind toUpper ">Session {session.id}</span>
                            <div className="d-flex flex-row justify-content-center">
                                <i className="icon-calendar font-30 blue align-self-center"></i>
                                <span className="align-self-center font-30 ml-2">{session.day}</span>
                                <div className="d-flex flex-column ml-2 align-self-center">
                                    <span className="font-10">{session.date}</span>
                                    <span className="font-10">{session.time}</span>
                                </div>
                            </div>
                            <button className={`btn btn-${mode === session.id ? 'success' : 'link bordered'}`} style={{ borderRadius: 20 }}
                                onClick={() => this.setState({
                                    mode: session.id,
                                    selected: _.filter(leads, l => l.Notes.id === session.id)
                                })}>LEADS ({leads ? _.filter(leads, l => l.Notes.id === session.id).length : 0})</button>
                        </div> )}
                    </div>                    
                </Animated>
                <hr />
                {selected && selected.length > 0 && <table className="table w3-content mx-auto bg-white my-4 shadow-box rounded">
                    <thead>
                        <tr>
                            <th>Name</th>                            
                            <th>Email</th>                                                        
                        </tr>
                    </thead>
                    <tbody>
                        {selected.map((s, i) => <tr key={i}>
                            <td>{`${s.FirstName} ${s.LastName}`}</td>
                            <td>{s.EmailAddress}</td>                            
                        </tr>)}
                    </tbody>
                </table>}
            </div>
        )
    }
}

export default OrganizationSessions;
