function reducer(state = [], action) {

    switch (action.type) {
      case 'TIME_ZONES/SET':          
        return action.time_zones;
      case 'TIME_ZONES/CLEAR':
        return null;
      default:
        return state;
    }
  }
  
  export default reducer;