export function addQuestion(question) { return { type: 'QUESTION_TEXTS/ADD', question } }
export function fetchQuestions(idLeague) { return { type: 'QUESTION_TEXT/SET', questions: [] } }
export function removeQuestionText(id) { return { type: 'QUESTION_TEXT/REMOVE', id } }
export function updateQuestionText(question) { return { type: 'QUESTION_TEXT/UPDATE', question } }
export function selectAQuestion(question) { return { type: 'QUESTION_TEXT/SELECT', question } }
export function addAnswer(answer) { return { type: 'ANSWERS/ADD', answer } }
export function updateAnswer(answer) { return { type: 'ANSWERS/UPDATE', answer } }
export function fetchAnswers(idLeague) { return { type: 'ANSWERS/SET', answers: [] } }
export function removeAnswer(id) { return { type: 'ANSWERS/REMOVE', id } }
export function addToGroup(question, idLeague) { return { type: 'QUESTIONS/ADD', question, idLeague } }
export function removeQuestion(index, idQuestion) { return { type: 'QUESTIONS/REMOVE', index, idQuestion } }
export function unselectAll() { return { type: 'QUESTIONS/UNSELECT_ALL' } }
export function addGroup(group) { return { type: 'QUESTION_GROUPS/ADD', group } }
export function fetchLibrary(idLeague) { return { type: 'LIBRARY/FETCH', idLeague } }
export function updateGroup(group) { return { type: 'QUESTION_GROUPS/EDIT', group } }
export function removeGroup(id, idLeague) { return { type: 'QUESTION_GROUPS/REMOVE', id, idLeague }}
export function setGroup(group) { return { type: 'QUESTION_GROUP/SET', group } }
export function fetchAppliedQuestions(idSeason) { return { type: 'APPLIED_QUESTIONS/FETCH', idSeason } }
export function selectAnswer(answer) { return { type: 'SELECTED_ANSWERS/ADD', answer } }
export function unselectAnswer(answer) { return { type: 'SELECTED_ANSWERS/REMOVE', answer } }
export function clearSelectedAnswers() { return { type: 'SELECTED_ANSWERS/CLEAR' } }