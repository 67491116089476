import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Free from './free';
import Single from './single';
import { ItemTypes } from '../../../../../../helpers';

class Library extends React.Component {

  state = {
    activeTab: 1,
    error: null,
  }

  changeTab = tab => {
    this.setState({ activeTab: tab });
    this.props.questionsActions &&
      this.props.questionsActions.unselectAll();
    this.props.questionsActions.clearSelectedAnswers();
    this.props.notifyTabChange &&
      this.props.notifyTabChange(tab)
  }

  renderModal = () => {
    const { isOpen, toggle } = this.props,
      { error } = this.state;
    return <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody >
        {this.renderTheThing()}
      </ModalBody>
      {error && <ModalBody className="bg-danger white p-2 font-10">{error.message || 'An error has occurred'}</ModalBody>}
      <ModalFooter>
        <button className="btn btn-link" onClick={toggle}>Close</button>
      </ModalFooter>
    </Modal>
  }

  renderTheThing = () => {
    const { activeTab } = this.state;

    return <div className="d-flex flex-row no-margin no-padding clearfix h-100" >
      <div className="p-2 flex-shrink-1 bg-info d-flex flex-column white justify-content-center">
        <span className="align-self-center">L</span>
        <span className="align-self-center">I</span>
        <span className="align-self-center">B</span>
        <span className="align-self-center">R</span>
        <span className="align-self-center">A</span>
        <span className="align-self-center">R</span>
        <span className="align-self-center">Y</span>
      </div>
      <div className="w-100">
        <ul className="nav nav-tabs font-16">
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
              <i className="fas fa-font blue"></i> {activeTab === 1 ? <span className="half-idented">Free Text</span> : ''}</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}>
              <i className="far fa-dot-circle purple"></i> {activeTab === 2 ? <span className="half-idented">Radio Button</span> : ''}</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}>
              <i className="far fa-check-square red"></i> {activeTab === 3 ? <span className="half-idented">Check Box</span> : ''}</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={() => this.changeTab(4)}>
              <i className="far fa-caret-square-down black"></i> {activeTab === 4 ? <span className="half-idented">Dropdown</span> : ''}</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === 5 ? 'active' : ''}`} onClick={() => this.changeTab(5)}>
              <i className="fas fa-list green"></i> {activeTab === 5 ? <span className="half-idented">Check List</span> : ''}</button>
          </li>
        </ul>
        <ReactCSSTransitionGroup className="tab-content" transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
          transitionEnter={true} transitionLeave={false}>
          {/* Free Text */}
          {activeTab === 1 && <Free {...this.props} itemType={ItemTypes.FREETEXT} />}
          {/* Radio */}
          {activeTab === 2 && <Single {...this.props} itemType={ItemTypes.RADIO} />}
          {/* Check */}
          {activeTab === 3 && <Free {...this.props} itemType={ItemTypes.CHECK} />}
          {/* Select */}
          {activeTab === 4 && <Single {...this.props} itemType={ItemTypes.SELECT} />}
          {/* Check List */}
          {activeTab === 5 && <Single {...this.props} itemType={ItemTypes.CHECKLIST} />}
          {/*activeTab === 3 && <Multiple {...this.props} />*/}
        </ReactCSSTransitionGroup>
      </div>
    </div>
  }

  render() {
    const { modal } = this.props;

    if (modal) {
      return this.renderModal();
    }
    return this.renderTheThing();
  }
}

export default Library;
