import React from 'react';
import QandA from './item';
import { generateRandomId } from '../../../../../../helpers';
import _ from 'lodash';

class Comp extends React.Component {

  render() {
    const { questions } = this.props;
    let orderedQuestions = _.orderBy(questions, 'position');
    return <section>
      {(orderedQuestions || []).map((question, i) =>
        <QandA className="margin-bottom-half" deletable={true} {...this.props} question={question} key={generateRandomId(10)} index={i} />)}
    </section>
  }
}

export default Comp;
