function reducer(state = [], action) {

    switch (action.type) {
        case 'PERMISSIONS/SET':
            return action.permissions;
        case 'ROSTER/CLEAR':
            return null;
        case 'PERMISSIONS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;