function reducer(state = [], action) {
    switch (action.type) {
        case 'DISCLAIMER/SET':
            return action.disclaimer;
        default:
            return state;
    }
}

export default reducer;
