import React from 'react';

const Role = ({ role, onSelect }) => {
    return (
        <div onClick={() => onSelect()} className={`card tile shadow-box d-flex flex-row justify-content-start montserrat font-12 ${false ? '' : 'pointer hoverable'}`} style={{ height: 60 }}>
            <i className={`align-self-center ml-2 font-16 fa-circle fa${role.selected ? 's green' : 'r'}`} />
            <div className="d-flex flex-column align-self-center ml-2">
                <span className="black font-12 line1">{role.RoleName}</span>
            </div>
        </div>
    );
}

export default Role;
