
import React from 'react';
import formations from '../../../../../formations';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import moment from 'moment';
import fakeAuth from '../../../../../fakeAuth';

class CreateDepthChart_2 extends React.Component {
    state = {
        match: {
            Home: {},
            Competition: '',
            Date: moment().format(),
            IdTeamDivision: 1,
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: fakeAuth.getUser().NameFirst,
            NameLast: fakeAuth.getUser().NameLast,
            LastUpdate: moment().format(),
            ReportType: 44,
            Setup: 11,
            Title: '',
            Venue: '',
            WeatherConditions: '',
            Offense: [
                ..._.chain(formations.glosary.football.offense)
                    .map((position, Index) => {
                        return {
                            Index,
                            starter: null,
                            backup: null,
                            reserve: null,
                            ...position
                        }
                    })
                    .value()
            ],
            Defense: [
                ..._.chain(formations.glosary.football.defense)
                    .map((position, Index) => {
                        return {
                            Index,
                            starter: null,
                            backup: null,
                            reserve: null,
                            ...position
                        }
                    })
                    .value()
            ],
            Special: [
                ..._.chain(formations.glosary.football.special_teams)
                    .map((position, Index) => {
                        return {
                            Index,
                            starter: null,
                            backup: null,
                            reserve: null,
                            ...position
                        }
                    })
                    .value()
            ]
        },
        tab: 1
    }

    componentWillMount = () => {
        const { match } = this.props;
        this.setState({ match: match || this.state.match });
    }
    componentWillReceiveProps = nextProps => {
        const { match, roster } = nextProps;
        this.setState({ match: match || this.state.match });        
    }

    onSelect = u => {
        const { index, place, tab, match } = this.state;
        // Determine group based on tab
        const group = tab === 2 ? 'Defense' : tab === 3 ? 'Special' : 'Offense';
        // Determine player position based on place
        const abc = place === 1 ? 'backup' : place === 2 ? 'reserve' : 'starter';
        // Clean up existing user in any position
        ['starter', 'backup', 'reserve'].forEach(role => {
            if (match[group][index][role] === u.IdUser) {
                match[group][index][role] = null;
            }
        });
        // Assign new position to the selected user
        match[group][index][abc] = u.IdUser;
        // Update the state with the modified match object
        this.setState({ match });
    }

    onSave = () => {
        const { match } = this.state, { selectedTeam, season } = this.props;
        match.IdTeamDivision = season.IdTeamDivision;
        match.Title = this.txtTitle.value;
        match.Home = {
            Formation: 0, // This is variable on lineups
            Image: selectedTeam.TeamImageUrl,
            Manager: this.txtManager.value,
            Name: selectedTeam.Name,
            Roster: [],
            Score: 0
        };        
        // CALL API
        this.props.teamActions.saveLineup(match);
        this.props.toggle();
    }
    renderPosition = (p, index) => {
        let group = { starter: null, backup: null, reserve: null }, { tab, match } = this.state, { roster } = this.props;
        if (match && match.Offense) {
            switch (tab) {
                case 1: group = match.Offense[index]; break;
                case 2: group = match.Defense[index]; break;
                case 3: group = match.Special[index]; break;
            }
        }
        let starter = _.find(roster, r => r.IdUser === group.starter),
            backup = _.find(roster, r => r.IdUser === group.backup),
            reserve = _.find(roster, r => r.IdUser === group.reserve);

        return <figure className="p-3 card mt-1 d-flex flex-row" style={{ position: 'relative' }}>

            <div className="w-100 p-3 d-flex flex-column pointer" onClick={() => this.setState({
                position: p,
                index,
                place: 0
            }, () => {
                this.props.select(group.starter)
            })}>
                <span className="font-8 montserrat align-self-center">Starter</span>
                <div className="align-self-center cover" style={{ width: 60, height: 60, border: `1px solid gray`, borderRadius: '50%', background: `url(${starter ? starter.Image : ''}) no-repeat center center` }} ></div>
                <span className="font-10 montserrat line1 toBold align-self-center">{(starter || { NameFirst: 'TBD' }).NameFirst}</span>
                <span className="font-8 montserrat line1 align-self-center">{(starter || { NameLast: '' }).NameLast}</span>
            </div>

            <div className="w-100  p-3 d-flex flex-column pointer" onClick={() => this.setState({
                position: p,
                index,
                place: 1
            }, () => {
                this.props.select(group.backup)
            })}>
                <span className="font-8 montserrat align-self-center">Backup</span>
                <div className="align-self-center cover" style={{ width: 60, height: 60, border: `1px solid gray`, borderRadius: '50%', background: `url(${backup ? backup.Image : ''}) no-repeat center center` }} ></div>
                <span className="font-10 montserrat line1 toBold align-self-center">{(backup || { NameFirst: 'TBD' }).NameFirst}</span>
                <span className="font-8 montserrat line1 align-self-center">{(backup || { NameLast: '' }).NameLast}</span>
            </div>

            <div className="w-100  p-3 d-flex flex-column pointer" onClick={() => this.setState({
                position: p,
                index,
                place: 2
            }, () => {
                this.props.select(group.reserve)
            })}>
                <span className="font-8 montserrat align-self-center">Reserve</span>
                <div className="align-self-center cover" style={{ width: 60, height: 60, border: `1px solid gray`, borderRadius: '50%', background: `url(${reserve ? reserve.Image : ''}) no-repeat center center` }} ></div>
                <span className="font-10 montserrat line1 toBold align-self-center">{(reserve || { NameFirst: 'TBD' }).NameFirst}</span>
                <span className="font-8 montserrat line1 align-self-center">{(reserve || { NameLast: '' }).NameLast}</span>
            </div>

            <span className="montserrat toUpper gray toBold font-14" style={{ position: 'absolute', bottom: -5, right: 0, opacity: 0.5 }}>{p.name}</span>
        </figure>
    }

    render() {
        const { selectedTeam = {}, roster } = this.props, { match, tab } = this.state;
        let group = 'offense';
        switch (tab) {
            case 2: group = 'defense'; break;
            case 3: group = 'special_teams'; break;
            default: break;
        }
        let manager = _.find(roster, u => u.IdRole === 4);

        return <section className="w3-container">
            <div className="w3-content p-2">
                <div className="d-flex flex-row mt-2">
                    <h3 className="montserrat black align-self-center">DEPTH CHART</h3>
                    <button onClick={this.onSave} type="button" style={{ border: '1px solid #2cad3c', borderRadius: 20 }} className="green py-1 px-4 align-self-center ml-auto">
                        Save</button>
                </div>
                {/* TEAM INFO */}
                <figure className="p-3 card mt-2 pointer">
                    <div className="d-flex flex-row">
                        <img className="align-self-center" src={selectedTeam.TeamImageUrl} style={{ width: 30 }} />
                        <span className="ml-2 montserrat font-14 black align-self-center">{selectedTeam.Name || selectedTeam.TeamName}</span>
                    </div>
                </figure>

                {/* TITLE AND MANAGER */}
                <div className="d-flex flex-row mt-3">
                    <div className="w-50">
                        <div className={`black-input d-flex mr-1`}>
                            <input className="w-100 p-3" type="text" name="title" placeholder="Title" ref={(input) => this.txtTitle = input} defaultValue={match.Title} />
                        </div>
                    </div>
                    <div className="w-50">
                        <div className={`black-input d-flex ml-1`}>
                            <input className="w-100 p-3" type="text" name="manager" placeholder="Manager" ref={(input) => this.txtManager = input} defaultValue={manager ? `${manager.NameFirst} ${manager.NameLast}` : null} />
                        </div>
                    </div>
                </div>

                {/* TABS */}
                <section className="card p-0 mt-3">
                    <div className="d-flex flex-row">
                        <div onClick={() => this.setState({ tab: 1 })} className={`w-100 pointer p-3 text-center ${tab === 1 ? '' : 'bg-gray'}`}><span className="montserrat font-14">OFFENSE</span></div>
                        <div onClick={() => this.setState({ tab: 2 })} className={`w-100 pointer p-3 text-center ${tab === 2 ? '' : 'bg-gray'}`}><span className="montserrat font-14">DEFENSE</span></div>
                        <div onClick={() => this.setState({ tab: 3 })} className={`w-100 pointer p-3 text-center ${tab === 3 ? '' : 'bg-gray'}`}><span className="montserrat font-14">SPECIAL TEAMS</span></div>
                    </div>

                    <div className="d-flex flex-wrap mt-2 mx-3">
                        {formations.glosary.football[group].map((p, index) => <div key={index} className={`w-50 p${index % 2 ? 'l' : 'r'}-1`}>
                            {this.renderPosition(p, index)}
                        </div>)}
                    </div>
                </section>
            </div>
        </section>
    }
}

export default CreateDepthChart_2;