import _ from 'lodash';
import moment from 'moment';

function seasons(state = [], action) {
    let index = 0;
    switch (action.type) {
        case 'SEASONS/SET':
            if (!action.seasons) return null;            
            action.seasons.forEach(s => {
                s = _.extend(s, {
                    Name: (s.Name || s.SeasonName).toUpperCase(),
                    IdTournamentPointsType: s.IdTournamentPointsType || 0,
                    Payment: {
                        Cash: s.Cash ? true : false,
                        Check: s.Check ? true : false,
                        Visa: s.Visa ? true : false,
                        MasterCard: s.MasterCard ? true : false,
                        Amex: s.Amex ? true : false,
                        Discover: s.Discover ? true : false
                    },
                    Roles: {
                        Coach: (s.RolesCoach || s.RoleCoach) ? true : false,
                        Assistant: (s.RolesAssistantCoach || s.RoleAssistantCoach) ? true : false,
                        TeamManager: (s.RolesTeamManager || s.RoleTeamManager) ? true : false,
                        AllowOpenRegistration: s.AllowOpenRegistrationForThisRoles ? true : false
                    },
                    CoachCanChangeScore: s.AllowCoachChangeScores ? true : false,
                    CoachCanAddGames: s.AllowCoachAddGames ? true : false,
                    CoachCanAddPlayers: s.AllowCoachAddPlayers ? true : false,
                    DateStart: moment(s.DateStart),
                    DateEnd: moment(s.DateEnd),
                    RegistrationEnd: moment(s.RegistrationEnd || s.DateEnd),
                    Disclaimer: s.Disclaimer || ''
                });
            });
            return _.uniqBy(action.seasons, season => { return season.IdSeason });
        case 'SEASONS/ADD':
            return [...state, action.season];
        case 'SEASONS/UPDATE':
            index = _.findIndex(state, s => {
                return parseInt(s.IdSeason, 10) === parseInt(action.idSeason, 10);
            });
            if (index !== -1) {
                return [...state.slice(0, index), action.season, ...state.slice(index + 1)];
            } else {
                return [...state];
            }
        case 'SEASONS/CLEAR':            
            return null;
        default:
            return state;
    }
}

export default seasons;