import React from 'react';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import Person from '../partials/Person';
import Team from '../partials/Team';

class RoleSelection extends React.Component {
    state = {        
        coachRoles: [
            {
                Id: 4,
                Name: 'Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Assistant Coach',
                selected: false
            },
            {
                Id: 4,
                Name: 'Team Manager',
                selected: false
            },
        ]
    }

    componentWillMount() {
        const { roles } = this.props;
        this.setState({ coachRoles: _.filter(roles, r => r.IdRole === 4) });
    }    
    selectRole = (index) => {
        const { coachRoles } = this.state;
        coachRoles.forEach((f, i) => f.selected = i === index);
        this.setState({ coachRoles });
    }
    onOk = () => {
        const { coachRoles } = this.state;
        let selectedRole = _.find(coachRoles, f => f.selected);
        if (selectedRole) {
            this.props.onOk && this.props.onOk({
                selectedRole, mode: 3
            });
        }
    }
    render() {
        const { team, user } = this.props, { coachRoles } = this.state;
        return <div className="p-4 h-100 montserrat">

            <div className="pointer tile card shadow-box d-flex flex-column justify-content-center m-auto">
                <Team selectedTeam={team} />
                <Person selectedUser={user} selectedRole={_.find(coachRoles, c => c.selected)} />
            </div>

            <Animated animationIn="fadeInRight" className="d-flex flex-column mt-4">

                {coachRoles.length > 0 && <section className="d-flex flex-column">

                    {coachRoles.map((role, i) => (
                        <div key={i} className={`w-100 pointer tile card shadow-box d-flex flex-row align-self-center mt-2 ${role.selected ? 'bg-success white' : ''}`} style={{ height: 60, marginRight: 0 }}
                            onClick={() => this.selectRole(i)}>                            
                            <span className="align-self-center font-12 ml-2">{role.Name || role.RoleName}</span>
                            <i className="ml-auto icon-check-circle white mr-2 align-self-center" />
                        </div>))}

                </section>}

                <button onClick={this.onOk} className={`btn btn-${_.find(coachRoles, u => u.selected) ? 'success' : 'default'} mt-4`}>Continue</button>

                <div style={{ height: 100 }} />

            </Animated>
        </div>
    }
}

export default RoleSelection;
