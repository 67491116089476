export function toggleCheckForSchedule(flag) {
    return {
        type: 'DIVISIONS/TOGGLE_CHECK_FOR_SCHEDULE',
        flag: flag
    }
}

export function setDivisionGroupName(idDivision, DivisionAgeGroupName, idSeason) { return { type: 'DIVISIONS/AGE_GROUP_NAME/SET', idDivision, DivisionAgeGroupName, idSeason } }

export function fetchDivisions(idSeason) {
    return {
        type: 'DIVISIONS/FETCH',
        idSeason,
        module: 'Divisions'
    }
}

export function fetchPrintableDivisions(idSeason) {
    return {
        type: 'DIVISIONS/FETCH',
        idSeason,
        module: 'Print'
    }
}

export function fetchForRegistrationOfTeams(idSeason, idTeam) {
    return {
        type: 'DIVISIONS/FETCH',
        idSeason,
        idTeam,
        module: 'Registration'
    }
}

export function fetchForRegistrationOfUsers(idSeason, idUser, idRole) {
    return {
        type: 'DIVISIONS/FETCH',
        idSeason,
        idUser,
        idRole,
        module: 'Registration'
    }
}

export function clearDivisions() {
    return {
        type: 'DIVISIONS/CLEAR'
    }
}

export function fetchPaymentPlans(idDivision, mode) {
    return {
        type: 'PAYMENT_PLANS/FETCH',
        idDivision,
        mode
    }
}

export function clearPaymentPlans() {
    return {
        type: 'PAYMENT_PLANS/SET',
        plans: null
    }
}

export function fetchQuestions(idDivision, mode) {
    return {
        type: 'QUESTIONS/FETCH',
        idDivision,
        mode
    }
}

export function clearQuestions() {
    return {
        type: 'QUESTIONS/SET',
        questions: null
    }
}

export function fetchTeams(idDivision) {

    return {
        type: 'TEAMS/DIVISION/FETCH',
        idDivision
    }
}

export function clearTeams() {
    return {
        type: 'TEAMS/CLEAR'
    }
}

export function fetchPlayers(idDivision, partial) {
    return {
        type: 'PLAYERS/DIVISION/FETCH',
        idDivision, partial
    }
}

export function updatePlayers(player, index) {
    return {
        type: 'PLAYERS/UPDATE',
        player,
        index
    }
}

export function clearPlayers() {
    return {
        type: 'PLAYERS/CLEAR'
    }
}

export function fetchCoaches(idDivision) {
    return {
        type: 'COACHES/DIVISION/FETCH',
        idDivision
    }
}

export function addDivision(division) {
    return { type: 'DIVISIONS/ADD', division }
}

export function updateDivision(division, index) {
    return { type: 'DIVISIONS/UPDATE', division, index }
}

export function removeDivision(index) {
    return { type: 'DIVISIONS/REMOVE', index }
}

export function updateCoaches(coach, index) {

    return {
        type: 'COACHES/UPDATE',
        coach,
        index
    }
}

export function updateCoach(coach, index) {    
    return {
        type: 'COACH/UPDATE',
        coach,
        index
    }
}

export function updateCoachRole(coach) {
    return { type: 'COACH/UPDATE/ROLE', coach }
}

export function clearCoaches() {
    return {
        type: 'COACHES/CLEAR'
    }
}

// Programs
export function assignProgram(idDivision, idCatalogItem) {
    return {
        type: 'DIVISION/ASSIGN_PROGRAM', idDivision, idCatalogItem
    }
}
export function removeProgram(program, division) {
    return {
        type: 'DIVISION/REMOVE_PROGRAM', program, division
    }
}

// Tryouts
export function assignTryoutProgram(idDivision, id) {
    return {
        type: 'DIVISION/ASSIGN_TRYOUT', idDivision, id
    }
}
export function removeTryoutProgram(program, division) {
    return {
        type: 'DIVISION/REMOVE_TRYOUT', program, division
    }
}

// Adjustments
export function assignAdjustment(idDivision, idCatalogItemPriceAdjustmentByDate) {
    return {
        type: 'DIVISION/ASSIGN_ADJUSTMENT', idDivision, idCatalogItemPriceAdjustmentByDate
    }
}
export function removeAdjustment(program, division) {
    return { type: 'DIVISION/REMOVE_ADJUSTMENT', program, division }
}

// Zip Codes
export function assignZipCode(idDivision, idZipCodeGroup) {
    return {
        type: 'DIVISION/ASSIGN_ZIPCODE', idDivision, idZipCodeGroup
    }
}
export function removeZipCode(program, division) {
    return { type: 'DIVISION/REMOVE_ZIPCODE', program, division }
}

// Family Adjustments
export function assignFamilyAdjustment(idDivision, idCatalogItemPriceAdjustmentByItemIndexGroup) {
    return {
        type: 'DIVISION/ASSIGN_FAMILY_ADJUSTMENT', idDivision, idCatalogItemPriceAdjustmentByItemIndexGroup
    }
}
export function removeFamilyAdjustment(program, division) {
    return { type: 'DIVISION/REMOVE_FAMILY_ADJUSTMENT', program, division }
}

// Question Groups
export function assignQuestionGroup(idDivision, idQuestionGroup, idQuestionGroupLocation, idSeason) {
    return {
        type: 'DIVISION/ASSIGN_QUESTION_GROUP', idDivision, idQuestionGroup,
        idQuestionGroupLocation, idSeason
    }
}

export function removeQuestionGroup(program, division, location, IdQuestionGroupDivision) {
    return {
        type: 'DIVISION/REMOVE_QUESTION_GROUP',
        idDivision: division.IdDivision,
        IdQuestionGroupDivision,
        idSeason: division.IdSeason
    }
}

// Roster locks
export function assignRosterLock(idDivision, IdTeamUserRoleLock, idSeason) {
    return { type: 'DIVISION/ASSIGN_ROSTER_LOCK', idDivision, IdTeamUserRoleLock, idSeason }
}

export function lock(idDivision) {
    return { type: 'DIVISION/LOCK', idDivision }
}

export function unlock(idDivision) {
    return { type: 'DIVISION/UNLOCK', idDivision }
}

export function setLimit({ idSeason, limit, idDivision }) {
    return { type: idDivision ? 'DIVISION/SET_ROSTER_LIMIT' : 'SEASON/SET_ROSTER_LIMIT', idSeason, limit, idDivision }
}