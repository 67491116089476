/* Cleaned up */

import React from 'react';
import LeagueItem from './LeagueItem';
import League from './League';
import Loader from '../common/Loader';
import './css.css';
import LeagueSettings from '../common/modals/LeagueSettings';
import AddLeague from '../common/modals/AddLeague';
import { Jumbotron } from 'reactstrap';
import config from '../../config';
import fakeAuth from '../../fakeAuth';
import _ from 'lodash';


class Leagues extends React.Component {
    state = {
        bootstrapped: false,
        selectedLeague: null,
        isLeagueSettingsOpen: false,
        addingLeague: false
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, leagueActions, leagues } = this.props;
        setTitle && setTitle({ main: 'Leagues' });
        this.setState({ leagues });
        leagueActions && leagueActions.fetchLeaguesAdmin();
        this.props.clearSeasons();
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.leagues) { this.setState({ leagues: nextProps.leagues, bootstrapped: true }); }
    }
    componentWillUnmount = () => {
        const { leagueActions } = this.props;
        leagueActions && leagueActions.clearLeagues();
    }
    //

    selectLeague = selectedLeague => this.setState({ selectedLeague, isLeagueSettingsOpen: true }, () => {
        this.props.leagueActions && this.props.leagueActions.fetchCurrency(selectedLeague.IdLeague);
    });
    toggleSelectedLeague = () => this.setState({ isLeagueSettingsOpen: !this.state.isLeagueSettingsOpen });

    onAddedLeague = (selectedLeague) => {
        const { leagueActions } = this.props;
        leagueActions && leagueActions.fetchLeaguesAdmin();
        this.setState({ selectedLeague, isLeagueSettingsOpen: true, addingLeague: false });
    }

    onChange = () => { this.setState({ searchText: this.txtSearch.value }); }

    render() {
        const { params } = this.props,
            { bootstrapped, selectedLeague, isLeagueSettingsOpen, addingLeague, searchText, leagues } = this.state,
            { idLeague } = params;

        const filtered_leagues = _.chain(leagues).filter(d => {
            if (searchText && (d.Name).toLowerCase().indexOf(searchText.toLowerCase()) === -1) return false;
            return true;
        }).value();

        return (
            <section>

                <div className="pl-2 pr-2" >

                    <div className="w3-container mt-4">
                        <div className="w3-content d-flex flex-row montserrat">
                            <div className="w-100 black">
                                <h1>Leagues</h1>
                                <h6 className="block">Select a league you'd like to setup</h6>
                            </div>

                            <div className="w-100  d-flex flex-row">
                                <input placeholder="Search" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />
                                {config.adminUsers.indexOf(fakeAuth.getUser().IdUser) !== -1 &&
                                    <button onClick={() => this.setState({ addingLeague: true })} style={{ width: 200, border: '1px solid #2cad3c', borderRadius: 20 }} className="green ml-4 p-2 align-self-center">
                                        <i className="fas fa-plus margin-right-half" />Add League</button>}
                            </div>
                        </div>
                    </div>

                </div>

                {/* LIST */}
                {bootstrapped && leagues && filtered_leagues.length > 0 && !idLeague && (
                    <section className="w3-container">
                        <div className="w3-content d-flex flex-wrap justify-content-start mt-4 pt-4" style={{ maxWidth: 1330 }}>
                            {filtered_leagues.map((league, i) => <LeagueItem key={league.IdLeague} index={i} league={league} selectLeague={this.selectLeague} />)}
                        </div>
                        <div style={{ height: 100 }} />
                    </section>
                )}

                {bootstrapped && leagues && filtered_leagues.length === 0 && !idLeague && (
                    <section className="w3-container">
                        <div className="w3-content d-flex flex-wrap justify-content-start mt-4 pt-4">
                            <Jumbotron className="bg-white d-flex flex-column justify-content-center">
                                <i className="far fa-meh-blank font-60 black align-self-center" />
                                <span className="black font-20 align-self-center text-center">Seems like the administrator has not configured any leagues {searchText ? ` named '${searchText}' for you!` : ' for you yet!'}</span>
                            </Jumbotron>
                        </div>
                    </section>
                )}

                {bootstrapped && idLeague && <League key={idLeague} {...this.props} />}

                {isLeagueSettingsOpen && <LeagueSettings isOpen={isLeagueSettingsOpen} toggle={this.toggleSelectedLeague} selectedLeague={selectedLeague}
                    {...this.props} />}

                {addingLeague && <AddLeague isOpen={addingLeague} toggle={() => this.setState({ addingLeague: !this.state.addingLeague })}
                    onAdded={this.onAddedLeague} familyActions={this.props.familyActions} {...this.props} />}

                { /* Loader */}
                {!bootstrapped && <Loader key="loader" message={`Loading Leagues, please wait...`} />}

                {bootstrapped && leagues && leagues.length === 0 && (
                    <div key="no-leagues" className="d-flex flex-col justify-content-center centered-message">
                        <h1 className="white align-self-center block text-center">There are no Leagues registered</h1>
                    </div>
                )}
            </section>
        )
    }
}

export default Leagues;
