import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ReactDOM from 'react-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import _ from 'lodash';
import request from 'superagent';
import config from '../../../config';
import moment from 'moment';
import MaskedInput from 'react-text-mask';

class CreateMember extends React.Component {

  state = {
    step: 1,
    isGuardian: false,
    popoverOpen: false,
    validations: {
      txtName: false,
      txtLastName: false,
      ddlistGender: false,
      txtDob: false
    },
    selected: {
      country: null,
      state: null,
      gender: 0
    },
    genderOpened: false
  }

  // Toggles
  toggleGender = () => {
    this.setState(prevState => ({
      genderOpened: !prevState.genderOpened
    }));
  }

  togglePopover1 = () => {
    this.setState({
      popoverOpen: false
    });
  }

  validate = (key) => {
    const { validations, selected } = this.state;
    var valid = true;
    switch (key) {

      case 'txtName':
        validations.txtName = (this[key].value && this[key].value.length > 0) ? true : false;
        valid = !validations.txtName ? false : valid;
        break;
      case 'txtLastName':
        validations.txtLastName = (this[key].value && this[key].value.length > 0) ? true : false;
        valid = !validations.txtLastName ? false : valid;
        break;
      case 'ddlistGender':
        validations.ddlistGender = selected.gender ? true : false;
        valid = !validations.ddlistGender ? false : valid;
        break;
      case 'txtDob':
        validations.txtDob = (ReactDOM.findDOMNode(this[key]).value && moment(ReactDOM.findDOMNode(this[key]).value, 'MM/DD/YYYY').isValid()) ? true : false;
        valid = !validations.txtDob ? false : valid;
        break;
      default:
        break;
    }
    this.setState({
      validations
    });

    return valid;
  }

  selectGender = (i) => {
    const { selected, validations } = this.state;
    selected.gender = i;

    validations.ddlistGender = i ? true : false;

    this.setState({
      selected,
      validations
    });
  }

  create = () => {

    const { validations } = this.state;
    if (_.chain(validations).values().filter((i) => {
      return i === false;
    }).value().length) {
      this.setState({
        popoverOpen: true
      });
    } else {

      this.setState({
        step: 2
      })
      const url = [config.apiEndpoint, 'api/v4/family/member'].join('/');
      request.post(url)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .send({
          firstName: this.txtName.value,
          lastName: this.txtLastName.value,
          idGender: this.state.selected.gender,
          dob: moment(this.txtDob.inputElement.value, 'MM/DD/YYYY').format(),
          isGuardian: this.state.isGuardian ? true : false
        })
        .then((data) => {
          if (data.body.success) {
            // re-fetch family members
            this.props.familyActions && this.props.familyActions.fetchMembers();
            this.props.toggle && this.props.toggle();
          }
        }, () => {
          this.setState({ step: 3 });
        });
    }
  }

  toggleIsGuardian = () => {
    this.setState({
      isGuardian: !this.state.isGuardian
    });
  }

  render() {

    const { validations, selected, step, isGuardian } = this.state,
      { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'}>
        <ModalHeader toggle={toggle}>
          Add Family Member
        </ModalHeader>
        <ModalBody>
          <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={500} transitionEnterTimeout={500}
            transitionEnter={true} component="div" transitionLeave={false} className="container no-margin no-padding">
            {step === 1 && <form className="row no-margin p-2">
              { /* Name */}
              <div className={`p-2 col-6`}>
                <div className={`d-flex flex-row ${(validations.txtName && validations.txtName === true) ? 'gray' : ((validations.txtName === false) ? 'red' : null)}`}>
                  <span className="font-8 gray align-self-center">FIRST NAME</span>
                  <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                </div>
                <input className="form-control" type="text" name="firstname" placeholder="Family member Name " ref={(input) => this.txtName = input} onBlur={() => this.validate('txtName')} />
              </div>
              { /* Last Name */}
              <div className={`p-2 col-6`}>
                <div className={`d-flex flex-row ${(validations.txtLastName && validations.txtLastName === true) ? 'gray' : ((validations.txtLastName === false) ? 'red' : null)}`}>
                  <span className="font-8 gray align-self-center">LAST NAME</span>
                  <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                </div>
                <input className="form-control" type="text" name="lastname" placeholder="Family member Last Name " ref={(input) => this.txtLastName = input} onBlur={() => this.validate('txtLastName')} />
              </div>
              { /* Gender */}
              <div className={`p-2 col-6`}>
                <div className={`d-flex flex-row ${(validations.ddlistGender && validations.ddlistGender === true) ? 'gray' : ((validations.ddlistGender === false) ? 'red' : null)}`}>
                  <span className="font-8 gray align-self-center">GENDER</span>
                  <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                </div>
                <Dropdown isOpen={this.state.genderOpened} toggle={this.toggleGender} className="form-control no-padding bg-info white">
                  <DropdownToggle className="btn-link btn-block text-left" caret>
                    {selected.gender !== 0 ? (selected.gender === 1 ? 'Male' : 'Female') : "Please select"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.selectGender(1)}><i className="fa fa-male" />
                      {' '}Male</DropdownItem>
                    <DropdownItem onClick={() => this.selectGender(2)}><i className="fa fa-female" />
                      {' '}Female</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              { /* DoB */}
              <div className={`p-2 col-6`}>
                <div className={`d-flex flex-row ${(validations.txtDob && validations.txtDob === true) ? 'gray' : ((validations.txtDob === false) ? 'red' : null)}`}>
                  <span className="font-8 gray align-self-center">DATE OF BIRTH</span>
                  <i className='idented-half fa fa-asterisk font-8 align-self-center'></i>
                </div>
                <MaskedInput placeholder="MM / DD / YYYY" id="txtDoB" ref={(i) => this.txtDob = i} guide={true} className="form-control" mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} onBlur={() => this.validate('txtDob')}
                />
              </div>
              { /* Guardian */}
              <div className={`p-2 col-12`} onClick={this.toggleIsGuardian}>
                <div className={`d-flex flex-row gray`}>
                  <i className={`fa-${isGuardian ? 'check-circle fa green' : 'circle far black'} align-self-center`}></i><span className="idented-half gray align-self-center">Guardian ?</span>
                </div>
              </div>
            </form>}
            {step === 2 && <div className="jumbotron no-margin text-center" key="step2">
              <i className="fa fa-users bigest-font heart" />
              <h1 className="margin-top-half">Adding family member, please wait...</h1>
            </div>}
            {step === 3 && <div onClick={toggle} className="jumbotron no-margin text-center bg-danger" key="step3">
              <i className="fa fa-times white bigest-font" />
              <h1 className="white">Registration failed</h1>
              <h3 className="white">{this.state.message}</h3>
            </div>}
          </ReactCSSTransitionGroup>
        </ModalBody>
        {step === 1 && <ModalFooter>
          <button id="btnSubmit" className="btn btn-sm btn-success" onClick={this.create}>Add Member</button>
          <Popover placement="bottom" isOpen={this.state.popoverOpen} target="btnSubmit" toggle={this.togglePopover1}>
            <PopoverHeader>Hey!</PopoverHeader>
            <PopoverBody>Please complete the required fields <i className="fa fa-asterisk red" /></PopoverBody>
          </Popover>
        </ModalFooter>}
      </Modal>
    )
  }
}

export default CreateMember;