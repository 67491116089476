import React from 'react';
import fakeAuth from '../../../fakeAuth';
import { Popover, PopoverBody, PopoverHeader, } from 'reactstrap';
import moment from 'moment';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import config from '../../../config';
import { getBase64 } from '../../../helpers';
import request from 'superagent';
import PendingValidations from './PendingValidations';
import GamesAsGuest from './partials/GamesAsGuest';

class PlayerDetails extends React.Component {

  state = {
    entity: null,
    isPlayer: false,
    isCoach: 0,
    roles: null,
    microTransac: false,
    errorMessage: null,
    popoverOpen: false,
    removing: false,
    bootstrapped: true,
    rolling: false
  }

  // Lifecycle
  componentWillMount() {
    const { entity, roles } = this.props;
    this.setState({
      entity,
      roles,
      isPlayer: entity.IdGuestPlayer ? true : (entity.overrides && _.find(entity.overrides, o => o.IdRole === 3) ? _.find(entity.overrides, o => o.IdRole === 3).IdRoleOverride : 0),
      isCoach: entity.overrides && _.find(entity.overrides, o => o.IdRole === 4) ? _.find(entity.overrides, o => o.IdRole === 4).IdRoleOverride : 0,
      isFamily: _.find(fakeAuth.getFamily() || [], u => u.IdUser === entity.IdUser) ? true : false
    });
  }
  //

  toggleCoach = (IdRoleOverride) => {
    const { isCoach } = this.state;
    this.setState({
      isCoach: isCoach === IdRoleOverride ? IdRoleOverride : IdRoleOverride
    });
  }

  togglePlayer = () => {
    const { roles } = this.state, { limits } = this.props;
    if (!this.state.isPlayer && limits && limits.Roster_Max && limits.Roster_Count >= limits.Roster_Max) {
      alert(limits.Reason || 'Maximun number of player has been reached');
      return;
    }
    this.setState({
      isPlayer: this.state.isPlayer ? 0 : ((_.find(roles, (o) => {
        return o.IdRole === 3;
      }) || {}).IdRoleOverride)
    });
  }

  togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  removeFromRoster = () => {
    const { entity, microTransac, removing } = this.state;
    if (!microTransac && !removing) {
      this.setState({
        removing: true,
        errorMessage: null,
        popoverOpen: false
      }, () => {

        request.delete(`${config.apiEndpoint}/api/v4/roster/${entity.IdUser}/${this.props.selectedTeam.IdTeamDivision}/${entity.IdRoleOverride}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            if (data.body.success) {
              this.props.fnSuccess && this.props.fnSuccess(_.extend(entity, {
                roles: [],
                overrides: []
              }));
            } else {
              this.setState({
                removing: false,
                errorMessage: data.body.error ? data.body.error.message : 'Something is wrong'
              });
            }
          }, ({ response }) => {
            // 500            
            this.setState({
              microTransac: false,
              errorMessage: (response.body && response.body.error) ? response.body.error.message : 'Something is wrong'
            });
          });

      });
    }
  }

  save = () => {

    const { entity, microTransac, removing } = this.state;
    if (!microTransac && !removing) {
      this.setState({
        microTransac: true,
        errorMessage: null
      }, () => {
        request.post(`${config.apiEndpoint}/api/v4/roster/add`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({
            idUser: entity.IdUser,
            idTeamDivision: this.props.selectedTeam.IdTeamDivision,
            playerRoleOverride: this.state.isPlayer,
            coachRoleOverride: this.state.isCoach,
            idTeamUserRole: entity.IdTeamUserRole
          })
          .then((data) => {
            if (data.body.success) {
              var newOverrides = _.filter(this.state.roles, (role) => {
                return (role.IdRoleOverride === this.state.isPlayer || role.IdRoleOverride === this.state.isCoach);
              });
              this.props.fnSuccess && this.props.fnSuccess(_.extend(entity, {
                roles: _.map(newOverrides, o => { return o.RoleName; }),
                overrides: newOverrides
              }));
            } else {
              this.setState({
                microTransac: false,
                errorMessage: data.body.error ? data.body.error.message : 'Something is wrong'
              });
            }
          }, ({ response }) => {
            // 500
            this.setState({
              microTransac: false,
              errorMessage: (response.body && response.body.error) ? response.body.error.message : 'Something is wrong'
            });
          });
      });
    }
  }

  close = () => {
    const { toggle } = this.props;
    this.setState({ bootstrapped: false }, () => {
      setTimeout(() => { toggle && toggle(); }, 300);
    });
  }

  togglePersonalInfo = () => {
    const { entity } = this.state;
    entity.PersonalInfoIsNotVisible = !entity.PersonalInfoIsNotVisible;
    this.setState({ entity });
    this.props.familyActions && this.props.familyActions.togglePersonalInfo({
      IsHidden: entity.PersonalInfoIsNotVisible,
      IdTeamUserRole: entity.IdTeamUserRole
    })
  }

  editProfile = () => {
    const { entity } = this.state, { family } = this.props;
    const familyMember = _.find(family, u => u.IdUser === entity.IdUser);
    if (familyMember)
      this.props.onFamily && this.props.onFamily({ ...familyMember, IdTeamDivision: entity.IdTeamDivision });
  }

  onSelectRole = role => {

    const { entity } = this.state;

    this.props.rosterActions && this.props.rosterActions.updateRole({
      idTeamUserRole: entity.IdTeamUserRole,
      idRoleOverride: role.IdRoleOverride,
      idTeamDivision: this.props.selectedTeam.IdTeamDivision
    });

    entity.IdRoleOverride = role.IdRoleOverride;
    entity.RoleOverrideName = role.RoleName;
    this.setState({ entity });
  }

  onAvatar = () => {
    const { permissions } = this.props;
    if (permissions.IsTeamAdmin) {
    }
  }

  handleAvatar = e => {
    getBase64(e.target.files[0]).then(data => {
      const { entity } = this.state;

      let user = {
        ..._.pick(entity, 'IdUser', 'NameFirst', 'NameLast', 'IdGender', 'DateBirth'),
        UserImage: data,
        doUpdateImage: true
      }

      entity.Image = data;
      this.setState({ entity });

      request.patch(`${config.apiEndpoint}/api/v4/family/member/partial/${user.IdUser}`)
        .send(user)
        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
        .then(({ body }) => {
          const { fnRefresh } = this.props;
          fnRefresh && fnRefresh();
        }).finally(() => {
          this.setState({ uploading: false, files: [] })
        });

    });
  }

  toggleActive = () => {
    const { entity } = this.state, { rosterActions } = this.props;
    entity.IsInactive = !entity.IsInactive;
    this.setState({ entity });
    rosterActions.togglePlayerActiveStatus({ IdTeamUserRole: entity.IdTeamUserRole, IdTeamDivision: this.props.selectedTeam.IdTeamDivision });
  }

  render() {
    const { permissions, limits, season } = this.props,
      { entity, microTransac, removing, bootstrapped, isFamily } = this.state;

    return (
      <section>

        {((permissions && permissions.IsTeamAdmin) || isFamily) && <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />}
        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>

          <div className="overlay" style={{ backgroundColor: 'black', opacity: 0.4, zIndex: -1 }} onClick={this.close} />

          <Animated animationIn="fadeInRight" animationOut="fadeOutRight" animationOutDuration={300} animationInDuration={300} isVisible={bootstrapped} style={{
            position: 'fixed', right: 0, top: 0, bottom: 0,
            width: entity.IdGuestPlayer ? 800 : 600, borderLeft: '1px solid gray', overflowY: 'auto', overflowX: 'hidden',
          }} className="bg-gray">

            {/* Top Actions */}
            <div className="d-flex flex-row p-3 bg-dark-blue">
              <i className="fas fa-arrow-left font-12 pointer white" onClick={this.close} />
              <span className="white montserrat font-12 ml-auto">{entity.NameFirst.toUpperCase()}</span>
              {entity.PersonalInfoCanBeToggled && entity.IdRole === 3 && <span onClick={this.editProfile} className="font-12 align-self-center pointer ml-auto underlined white hoverable">Edit</span>}
              {entity.PersonalInfoCanBeToggled && entity.IdRole === 4 && <span onClick={() => this.setState({ rolling: true })} className="font-12 align-self-center pointer ml-auto underlined white hoverable">Edit</span>}
              {!entity.PersonalInfoCanBeToggled && <i className=" font-16 ml-auto " />}
            </div>

            {/* Basic info (name, photo, id) */}
            <div className="d-flex flex-column justify-content-center bg-dark-blue">

              <label htmlFor='fi' style={{ width: 160, height: 120, position: 'relative' }} className={`align-self-center mt-4 d-flex flex-column ${(permissions.IsTeamAdmin || isFamily) ? 'pointer' : ''}`}>
                <div className="cover align-self-center" style={{ width: 120, height: 120, borderRadius: 60, border: '1px solid white', background: `url(${(entity.UserImage || entity.Image || entity.ImageUrl)}) no-repeat center center` }} />
                <span className="white montserrat font-12" style={{ position: 'absolute', top: 0, left: 0 }}>
                  {entity.IdRole === 3 ? `#${entity.JerseyNumber || ''}` : `${_.chain(entity.RoleOverrideName || '').split(' ').map(s => s.substring(0, 1)).value().join('')}`}</span>
              </label>

              <span className="font-16 montserrat white align-self-center mr-3 ml-3 mt-2">{entity.NameFirst} {entity.NameLast}</span>
              <span className="font-10 montserrat white-ish align-self-center mr-3 ml-3">Player ID: {entity.IdUser}</span>
              <span className="font-8 montserrat white-ish align-self-center mr-3 ml-3 mb-4">{entity.Position}</span>
              {!entity.IdGuestPlayer && <div className="d-flex flex-row w-100 px-4 mb-1 font-18" onClick={this.toggleActive}>
                <span className="ml-auto pointer"><span className="white">{entity.IsInactive ? 'INACTIVE' : 'ACTIVE'}</span>
                  <i className={`fas fa-toggle-${entity.IsInactive ? 'off red' : 'on green'} ml-2`} /></span>
              </div>}
            </div>

            {/* Card info */}
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 80, zIndex: -1 }} className="bg-dark-blue" />

              <div style={{ borderRadius: 5 }} className=" card tile shadow-box bg-white p-4 ml-4 mr-4 d-flex flex-column">
                <div className="d-flex flex-row montserrat">
                  <div className="w-100 d-flex flex-column">
                    <span className="gray font-8">DOB <span className={`${entity.IsValid ? 'green' : 'red'}`}>{(entity.IsValid ? '(Valid)' : '(Not verified)')}</span></span>
                    {entity.PersonalInfoIsNotVisible && <span>N/A</span>}
                    {!entity.PersonalInfoIsNotVisible && <span>{moment(entity.DateBirth, 'MM/DD/YYYY').format('MMMM Do YYYY')}</span>}
                  </div>
                  <div className="w-50 d-flex flex-column">
                    <span className="gray font-8">Gender</span>
                    <span>{entity.Gender}</span>
                  </div>
                </div>

                <div className="d-flex flex-row montserrat mt-3">
                  <div className="w-100 d-flex flex-column">
                    <span className="gray font-8">Email</span>
                    <span className="font-10 blue">{entity.PersonalInfoIsNotVisible ? 'N/A' : entity.EmailAddress}</span>
                  </div>
                  <div className="w-50 d-flex flex-column">
                    <span className="gray font-8">Shirt</span>
                    <span>{entity.Shirt || entity.ShirtSize}</span>
                  </div>
                </div>

                <div className="d-flex flex-row montserrat mt-3">
                  <div className="w-100 d-flex flex-column">
                    <span className="gray font-8">Phone</span>
                    <span className="font-10 blue">{entity.PersonalInfoIsNotVisible ? 'N/A' : entity.PhoneNumber_Format}</span>
                  </div>
                  <div className="w-50 d-flex flex-column">
                    <span className="gray font-8">Short</span>
                    <span>{entity.Short || entity.ShortSize || entity.ShortsSize}</span>
                  </div>
                </div>

                <div className="d-flex flex-row montserrat mt-3">
                  <div className="w-100 d-flex flex-column">
                    <span className="gray font-8">Valid DOB</span>
                    <span className="font-10">{(entity.IsValid ? 'Yes' : 'No')}</span>
                  </div>
                  {((permissions && permissions.IsTeamAdmin) || isFamily) &&
                    <div className="w-50 d-flex flex-column">
                      <span className="gray font-8">Medical Notes</span>
                      <span className="font-10">{entity.MedicalNotes}</span>
                    </div>}
                </div>

                <div className="d-flex flex-row montserrat mt-3">
                  <div className="w-100 d-flex flex-column">
                  </div>
                  {entity.IdRole === 3 && <div className="w-50 d-flex flex-column pointer" onClick={this.props.fnEvaluations}>
                    <span className="gray font-8">Evaluations</span>
                    <span><i className={`icon-bar-chart ${_.find(this.props.team_evaluations, e => (e.IdUserTarget || e.IdAssignedUser) === entity.IdUser && e.VISIBLE) ? 'blue' : 'gray'} font-16 ml-auto align-self-center`} /></span>
                  </div>}
                </div>

              </div>

              {entity && entity.violations && entity.violations.length > 0 && <div className="bg-danger p-3 line1 white font-12 mt-4 text-center">{`Suspended for ${_.first(entity.violations).Violation} until ${_.first(entity.violations).SuspendedUntilDate}`}</div>}

              {entity && entity.validations && entity.validations.length > 0 && <button onClick={() => this.setState({ validations: true })} className="w-100 mt-4 d-flex flex-row bg-warning p-4">
                <i className="fas fa-warning black font-20 align-self-center" />
                <span className="font-12 black align-self-center ml-4 line1">{`${entity.NameFirst} has some required validations`}</span>
              </button>}

              {entity && _.find(entity.all_validations, v => !v.Solved && v.InstrumentImage && v.InstrumentImage.length > 0 && v.IdLeague === season.IdLeague) && <div className="w-100 mt-4 d-flex flex-row bg-info p-4">
                <i className="fas fa-clock white font-20 align-self-center" />
                <span className="font-12 white align-self-center ml-4 line1">{`${entity.NameFirst} has some validations pending for review`}</span>
              </div>}

              {/* Toggle personal Info */}
              {entity && entity.PersonalInfoCanBeToggled && <div className="p-4">
                <button onClick={this.togglePersonalInfo} className="btn btn-link btn-block d-flex flex-row justify-content-center">
                  <i className={`fas fa-toggle-${entity.PersonalInfoIsNotVisible ? 'on green' : 'off'} font-16 align-self-center`} />
                  <span className="align-self-center ml-2">Hide personal profile information</span>
                </button>
              </div>}

              {/* Delete  actions */}
              {(entity.overrides || entity.CanDelete === true) && (
                <div className={`${entity && entity.PersonalInfoCanBeToggled ? 'ml-4 mr-4' : 'm-4'}`}>
                  {permissions && permissions.CanEditRoster_CanDelete === true && permissions.CanEditRoster_Allowed &&
                    <button onClick={this.togglePopover} className="btn btn-danger btn-block w-100" id="Popover1"><i className="fa fa-trash" />
                      {' '}
                      {removing ? 'Removing...' : 'Remove from roster'}
                    </button>}
                  {permissions && permissions.CanEditRoster_CanDelete === true && permissions.CanEditRoster_Allowed &&
                    <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover1" toggle={this.togglePopover}>
                      <PopoverHeader>Are you sure?</PopoverHeader>
                      <PopoverBody className="d-flex flex-row justify-content-center">
                        <button className="btn btn-success btn-sm" onClick={this.removeFromRoster}>Yes, Remove it</button>
                        <button className="btn btn-danger btn-sm" onClick={this.togglePopover}>No, Cancel</button>
                      </PopoverBody>
                    </Popover>}
                  {entity.overrides && limits && (
                    <button className="btn btn-success btn-sm" onClick={this.save}>
                      {microTransac ? 'Saving...' : 'Save'}
                    </button>)}
                </div>)}

              <div style={{ height: 50 }} />
            </div>

            {/* Edit Profile or Role popup */}
            <Animated animationIn="fadeInRight" animationInDuration={300} animationOutDuration={300} animationOut="fadeOutRight" isVisible={this.state.rolling} style={{
              position: 'fixed', right: 0, top: 0, bottom: 0,
              width: 450, borderLeft: '1px solid gray'
            }} className="d-flex flex-row justify-content-center">
              <div className="overlay" onClick={() => this.setState({ rolling: false })} style={{ zIndex: -1 }} />
              <div style={{ width: 300 }} className="align-self-center card tile shadow-box bg-white">
                {!this.state.editingRole && <section className="p-4">
                  <button className="btn btn-info btn-block" onClick={this.editProfile}>Edit Profile</button>
                  <button className="btn btn-info btn-block mt-4" onClick={() => this.setState({ editingRole: true })} >Edit Team Role</button>
                </section>}
                {this.state.editingRole && <section className="p-2">
                  <div className="d-flex flex-row">
                    <i className="fas fa-arrow-left font-12 pointer jeff_blue align-self-center" onClick={() => this.setState({ editingRole: false })} />
                    <span className="jeff_blue montserrat font-12 ml-auto align-self-center">Role Change</span>
                    <i className=" font-16 ml-auto " />
                  </div>
                  <section className="p-2">
                    {_.filter(this.props.roles, r => r.IdRole === 4).map((role, i) => (
                      <div key={i} onClick={() => this.onSelectRole(role)} className={`card tile shadow-box d-flex flex-row justify-content-start montserrat font-12 ${false ? '' : 'pointer hoverable'}`} style={{ height: 60 }}>
                        <i className={`align-self-center ml-2 font-16 fa-circle fa${role.IdRoleOverride === entity.IdRoleOverride ? 's green' : 'r'}`} />
                        <div className="d-flex flex-column align-self-center ml-2">
                          <span className="black font-12 line1">{role.RoleName}</span>
                        </div>
                      </div>
                    ))}
                  </section>
                </section>}
              </div>

            </Animated>

            {entity.games && <section className="px-4">
              <GamesAsGuest team={this.props.selectedTeam} games={this.props.games} entity={entity} rosterActions={this.props.rosterActions} />
            </section>}

          </Animated>

          {entity.IdGuestPlayer && <i className="fas fa-bookmark red font-16" style={{ position: 'fixed', top: 0, right: 50 }} />}
        </div>

        {entity && this.state.validations && entity.validations && <PendingValidations 
          user={entity}
          validations={entity.validations} 
          rosterActions={this.props.rosterActions}
          team={this.props.season}
          toggle={() => this.setState({ validations: false })} />}

      </section>)
  }
}

export default PlayerDetails;
