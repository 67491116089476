import React from 'react';
import { Popover, PopoverBody, Label } from 'reactstrap';
import { generateRandomId } from '../../../../helpers';

class Comp extends React.Component {
  state = {
    number: 0,
    firstOpen: false,
    randomId: generateRandomId(10)
  }
  toggleFirst = () => this.setState({ firstOpen: !this.state.firstOpen });
  selectNumber = (number) => {
    const { team, place, fnUpdate } = this.props;
    team.Awards = number;
    team.Placement = place;
    fnUpdate && fnUpdate(place, number);
    this.setState({ firstOpen: false });
  };

  render() {
    const { team, place } = this.props, { randomId } = this.state;
    return <div>
      <button onClick={this.toggleFirst} className="btn btn-sm btn-default white" id={`btn-1st-${randomId}`}>{team.Placement === place ? (team.Awards || '-') : '-'}</button>
      <Popover placement="bottom" isOpen={this.state.firstOpen} target={`btn-1st-${randomId}`} toggle={this.toggleFirst}>
        <PopoverBody className="no-margin p-1 d-flex flex-column" style={{ maxHeight: 200, overflowY: 'auto' }}>
          <Label className="m-auto align-self-center">{team.TeamName}</Label>
          <div className="d-flex flex-row">
            <button onClick={() => this.selectNumber(0)} className={`btn-circle margin-right-half ${team.Placement === place && team.Awards === 0 ? 'bg-green white' : 'bg-white black'}`}>-</button>
            <button onClick={() => this.selectNumber(1)} className={`btn-circle margin-right-half ${team.Placement === place && team.Awards === 1 ? 'bg-green white' : 'bg-white black'}`}>1</button>
            <button onClick={() => this.selectNumber(2)} className={`btn-circle margin-right-half ${team.Placement === place && team.Awards === 2 ? 'bg-green white' : 'bg-white black'}`}>2</button>
            <button onClick={() => this.selectNumber(3)} className={`btn-circle margin-right-half ${team.Placement === place && team.Awards === 3 ? 'bg-green white' : 'bg-white black'}`}>3</button>
            <button onClick={() => this.selectNumber(4)} className={`btn-circle margin-right-half ${team.Placement === place && team.Awards === 4 ? 'bg-green white' : 'bg-white black'}`}>4</button>
            <button onClick={() => this.selectNumber(5)} className={`btn-circle margin-right-half ${team.Placement === place && team.Awards === 5 ? 'bg-green white' : 'bg-white black'}`}>5</button>
            <button onClick={() => this.selectNumber(6)} className={`btn-circle ${team.Placement === place && team.Awards === 6 ? 'bg-green white' : 'bg-white black'}`}>6</button>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  }
}

export default Comp;