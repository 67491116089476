import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from './CustomDatePicker';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { formatPriceWithoutCurrency } from '../../helpers';


class MembershipProgram extends React.Component {
  state = {
    entity: null,
    editMode: false,
    isOpenStartDatepicker: false,
    isOpenEndDatepicker: false,
    deleting: false
  }

  // Lifecycle
  componentWillMount() {
    const { entity } = this.props;
    let editMode = false;
    if (!entity.IdMembership) {
      editMode = true;
    }
    this.setState({ entity, editMode });

  }
  componentDidMount() {
    if (this.txt) {
      this.txt.focus();
      this.txt.select();
    }
  }
  //

  save = (e) => {
    e.preventDefault && e.preventDefault();
    const { entity } = this.state, { leagueActions } = this.props;
    entity.Description = this.txt.value;
    entity.Amount = this.txtAmount.value;
    // TODO: call api, generate an Id and assign it to this
    if (entity.IdMembership) {
      leagueActions && leagueActions.updateMembershipProgram && leagueActions.updateMembershipProgram(entity);
    } else
      leagueActions && leagueActions.createMembershipProgram && leagueActions.createMembershipProgram(entity);

    this.setState({ editMode: false });
  }
  edit = () => this.setState({ editMode: true });
  configure = () => { this.props.onConfigure && this.props.onConfigure(); }

  toggleCalendarStart = e => {
    e && e.preventDefault()
    this.setState({ isOpenStartDatepicker: !this.state.isOpenStartDatepicker })
  }
  toggleCalendarEnd = e => {
    e && e.preventDefault()
    this.setState({ isOpenEndDatepicker: !this.state.isOpenEndDatepicker })
  }
  handleChange = () => {
    // tODO: validate that what's in is a number
    this.setState({
      total: parseFloat(this.txtAmount.value),
    });
  }

  handleChangeStart = date => {
    const { entity } = this.state;
    entity.StartDate = moment(date);
    entity.EndDate = moment(date).isAfter(moment(entity.EndDate)) ? moment(date).add(1, 'day') : moment(entity.EndDate);
    this.setState({ entity }, () => { this.toggleCalendarStart(); });
  }
  handleChangeEnd = date => {
    const { entity } = this.state;
    entity.StartDate = moment(date).isBefore(moment(entity.StartDate)) ? moment(date).add(-1, 'day') : moment(entity.StartDate);
    entity.EndDate = moment(date);
    this.setState({ entity }, () => { this.toggleCalendarEnd(); });
  }

  toggleDelete = () => this.setState({ deleting: !this.state.deleting });

  doDelete = () => {
    const { entity } = this.state, { leagueActions, fnRemove } = this.props;
    this.setState({ deleting: false }, () => {
      entity.IdMembership && leagueActions && leagueActions.deleteMembershipProgram && leagueActions.deleteMembershipProgram(entity.IdMembership, entity.IdLeague);
      fnRemove && fnRemove();
    });
  }

  render() {
    const { editMode, entity, configuring } = this.state;
    return <form className={`${(editMode || configuring) ? 'card p-4' : ''}`}>

      {/* EDIT MODE */}
      {editMode && <div className="d-flex">                
        <input placeholder="Name" ref={(i) => this.txt = i} defaultValue={this.state.entity.Description} className="form-control control-lg align-self-center text-center big-font margin-right"
          style={{ height: 60, width: 250 }} />
        <input placeholder="Amount" ref={(i) => this.txtAmount = i} onChange={this.handleChange} defaultValue={formatPriceWithoutCurrency(this.state.entity.Amount * 100)} type="number" className="form-control control-lg align-self-center text-center big-font margin-right"
          style={{ height: 60, width: 250 }} />
        <div className="d-flex flex-row idented-half margin-right-half">
          <div className="align-self-center">
            <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
              customInput={<CustomDatePicker />} selected={moment(entity.StartDate).utc()} selectsStart
              startDate={moment(entity.StartDate).utc()} endDate={moment(entity.EndDate).utc()} onChange={this.handleChangeStart} />
          </div>
          <span className=" align-self-center margin-left-half margin-right-half">to</span>
          <div className="align-self-center">
            <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={moment(entity.EndDate).utc()} selectsStart
              startDate={moment(entity.StartDate).utc()} endDate={moment(entity.EndDate).utc()} onChange={this.handleChangeEnd} />
          </div>
        </div>
        <button type="submit" className="btn btn-link green btn-sm" onClick={this.save}><i className="fa fa-check font-10" /></button>
        <button id={`btn-delete-${entity.IdMembership || entity.randomId}`} type="button" className="btn btn-link red btn-sm" onClick={this.toggleDelete}><i className="fa fa-times font-10" /></button>
        <Popover placement="bottom" isOpen={this.state.deleting} target={`btn-delete-${entity.IdMembership || entity.randomId}`} toggle={this.toggleDelete}>
          <PopoverHeader>Are you sure?</PopoverHeader>
          <PopoverBody className="d-flex flex-row justify-content-center">
            <button className="btn btn-success btn-sm" onClick={this.doDelete}>Yes, Delete it</button>
            <button className="btn btn-danger btn-sm" onClick={this.toggleDelete}>No, Cancel</button>
          </PopoverBody>
        </Popover>
      </div>}

      {/* SAVE MODE */}
      {!editMode && <div className={`d-flex p-2 ${this.props.selected ? 'bg-warning' : ''}`}>
        <span className="align-self-center font-16 toBold w-100">{entity.Description}</span>
        <span className="ml-auto align-self-center font-16 toBold w-100">USD {formatPriceWithoutCurrency(this.state.entity.Amount * 100)}</span>
        <span className="ml-auto align-self-center margin-right font-12 w-100">{moment(entity.StartDate).utc().format('MMM Do YYYY')}{' - '}{moment(entity.EndDate).utc().format('MMM Do YYYY')}</span>        
        <button onClick={this.edit} type="button" className="btn btn-link align-self-center font-14"><span className="icon-edit-3 black"></span></button>
      </div>}

      {!editMode && !configuring && <hr className="no-margin no-padding" />}
    </form>
  }
}

export default MembershipProgram;
