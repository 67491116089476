import React from 'react';
import RightWorkArea from './RightWorkArea';
import LeftWorkArea from './LeftWorkArea';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../common/CustomDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ItemTypes } from '../../../../helpers';
import Loader from '../../../common/Loader';
import config from '../../../../config';
import request from 'superagent';
import { Badge } from 'reactstrap';
import _ from 'lodash';
import BuildPreview from '../../../common/modals/BuildPreview';
import BuildSchedule from '../../../common/modals/BuildSchedule';
import { Link } from 'react-router';

import './css.css';

class ScheduleManager extends React.Component {

    state = {
        modal: false,
        isBuildPreviewOpen: false,
        buildingSchedule: false,
        bootstrapped: false,
        seasonStartDate: moment(),
        seasonEndDate: moment().add(1, 'day'),
        isOpenStartDatepicker: false,
        isOpenEndDatepicker: false,
        datesUpdated: false,
        activeTab: parseInt(localStorage.getItem('sportslogic.rules.tab') || 1),
        currentDivisionsListMode: parseInt(localStorage.getItem('sportslogic.rules.tab') || 1) === 3 ? 2 : 1
    }

    // Lifecycle
    componentWillMount = () => {
        const { setTitle, params } = this.props;
        setTitle && setTitle({
            sub: 'Schedule Manager | Automatic',
            main: this.props.season.Name || null
        });

        this.props.scheduleManagerDivisionsActions &&
            this.props.scheduleManagerDivisionsActions.fetchDivisions(params.idSeason)

        this.props.gameDayRulesActions &&
            this.props.gameDayRulesActions.fetchGameDayRules(params.idSeason);

        this.props.exceptionRulesActions &&
            this.props.exceptionRulesActions.fetchExceptionRules(params.idSeason);

        this.props.fieldAvailabilityRulesActions &&
            this.props.fieldAvailabilityRulesActions.fetchFieldAvailabilityRules(params.idSeason);

        this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
    }

    componentWillUnmount = () => {
        this.props.scheduleManagerDivisionsActions.clearDivisions &&
            this.props.scheduleManagerDivisionsActions.clearDivisions();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.season !== this.props.season) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                sub: 'Schedule Manager | Automatic',
                main: nextProps.season.Name || null
            });

            this.props.fetchLocations && this.props.fetchLocations(nextProps.season.IdLeague);

            this.setState({
                seasonStartDate: moment(nextProps.season.DateStart),
                seasonEndDate: moment(nextProps.season.DateEnd)
            });
        }
        if (nextProps.divisions && nextProps.locations) {
            this.setState({ bootstrapped: true });
        }
    }
    //

    updateSeasonDates = () => {
        const { seasonStartDate, seasonEndDate } = this.state,
            { season } = this.props;
        // Save call API        
        request.patch(`${config.apiEndpoint}/api/v4/seasons/dates/${season.IdSeason}`)
            .send({
                dateStart: seasonStartDate.format(),
                dateEnd: seasonEndDate.format()
            })
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
                // "Updated" badge feedback
                this.setState({ datesUpdated: true });
                setTimeout(() => {
                    this.setState({ datesUpdated: false });
                }, 1500)
            });
    }

    // Handlers
    handleChangeStart = (date) => {
        const { seasonEndDate } = this.state;
        this.setState({
            seasonStartDate: moment(date),
            seasonEndDate: moment(date).isAfter(moment(seasonEndDate)) ? moment(date).add(1, 'day') : moment(seasonEndDate) // range validation
        }, function () {
            this.toggleCalendarStart();
            this.updateSeasonDates();
        });
    }
    handleChangeEnd = (date) => {
        const { seasonStartDate } = this.state;
        this.setState({
            seasonStartDate: moment(date).isBefore(moment(seasonStartDate)) ? moment(date).add(-1, 'day') : moment(seasonStartDate), // range validation
            seasonEndDate: moment(date)
        }, function () {
            this.toggleCalendarStart();
            this.updateSeasonDates();
        });
    }
    //

    // Toggles
    toggleCalendarStart = (e) => {
        e && e.preventDefault()
        this.setState({ isOpenStartDatepicker: !this.state.isOpenStartDatepicker })
    }
    toggleCalendarEnd = (e) => {
        e && e.preventDefault()
        this.setState({ isOpenEndDatepicker: !this.state.isOpenEndDatepicker })
    }
    toggleSeasonCheckedForSchedule = () => {
        const { season } = this.props;
        this.props.divisionsActions && this.props.divisionsActions.toggleCheckForSchedule(!season.checkedForSchedule);
        this.props.seasonActions && this.props.seasonActions.toggleCheckForSchedule();
    }
    toggleModal = () => this.setState({ modal: !this.state.modal });
    toggleBuildPreview = () => this.setState({ isBuildPreviewOpen: !this.state.isBuildPreviewOpen });
    //

    handleDivisionsListMode = tabNumber => {
        this.setState({ currentDivisionsListMode: (tabNumber === 3) ? 2 : 1 })
    };

    buildSchedule = () => {
        const { divisions } = this.props;
        this.setState({ buildingSchedule: true }, () => {

            const flightsChecked = _.chain(_.map(divisions, (division) => {
                return division.Flights
            })).flatten().filter((flight) => {
                return flight.checkedForSchedule;
            }).value();

            const url = [config.apiEndpoint, 'api/v4/schedule/build'].join('/');
            request.post(url)
                .send({
                    flights: _.map(flightsChecked, (flight) => {
                        return {
                            IdFlight: flight.IdFlight
                        };
                    })
                })
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(({ body: { rules } }) => {

                    this.setState({
                        buildingSchedule: false,
                        modal: false
                    }, () => { this.toggleBuildPreview(); });
                    // TODO:

                    //this.props.router.push('/season/' + season.IdSeason + '/games_calendar'); //from.pathname);
                }, reject => {                    
                    alert('ERROR: Check the console!')
                    this.setState({ buildingSchedule: false });
                });
        });
    }

    goToCalendar = () => {
        const { season } = this.props;
        this.props.router.push('/season/' + season.IdSeason + '/games_calendar');
    }

    render() {
        const accept = [ItemTypes.GAMEDAYRULE, ItemTypes.EXCEPTIONRULE, ItemTypes.FIELDAVAILABILITYRULE /*,..., ItemTypes.TEAM*/],
            { season } = this.props,
            { datesUpdated, currentDivisionsListMode, bootstrapped, isBuildPreviewOpen } = this.state;

        return (
            <section id="schedule-manager" className="bg-gray-dark">
                <div className="filters-wrapper d-flex flex-row ">
                    <ReactCSSTransitionGroup className="w-100" transitionName="example" transitionAppear={true} transitionAppearTimeout={200}
                        transitionEnterTimeout={200} transitionEnter={true} transitionLeave={true} transitionLeaveTimeout={200}>
                        {bootstrapped &&
                            <div className="d-flex flex-row w-100 align-self-center">
                                <span key="1" className="white align-self-center margin-left margin-right">From</span>
                                <div key="2" className="align-self-center">
                                    <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.seasonStartDate} selectsStart
                                        startDate={this.state.seasonStartDate} endDate={this.state.seasonEndDate} onChange={this.handleChangeStart} />
                                </div>
                                <span key="3" className="white align-self-center margin-left margin-right">to</span>
                                <div key="4" className="align-self-center">
                                    <DatePicker showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={this.state.seasonEndDate}
                                        selectsStart startDate={this.state.seasonStartDate} endDate={this.state.seasonEndDate} onChange={this.handleChangeEnd} />
                                </div>
                                {datesUpdated && <Badge key="5" className="align-self-center margin-left" color="success">Updated!</Badge>}
                                <div className="d-flex flex-row ml-auto " key="topActions">
                                    <button className="btn-link white btn btn-sm" onClick={this.toggleBuildPreview}><i className="fa fa-list"></i> Schedule Summary</button>
                                    <button className="btn-success btn btn-sm" onClick={this.toggleModal}><i className="fa fa-cog"></i> Build Schedule</button>
                                    <button className="btn btn-sm tile-rainbow margin-left-half white" style={{ border: '1px solid white' }} ><i className="fa fa-cogs" /></button>
                                    <Link to={`/season/${season.IdSeason}/schedule_manager/pairings`} className="btn btn-sm tile-rainbow margin-left-half white" ><i className="fas fa-hand-paper" /></Link>
                                </div>
                            </div>}
                    </ReactCSSTransitionGroup>
                </div>

                <div className="work-area container-fluid">
                    {bootstrapped && (
                        <div key="with-data" className="row d-flex align-items-stretch full-height">
                            <div className="col-4 no-margin no-padding">
                                <LeftWorkArea {...this.props} accept={accept} currentDivisionsListMode={currentDivisionsListMode} />
                            </div>
                            <div className="col-8 no-padding no-margin">
                                <RightWorkArea {...this.props} notifyTabChange={this.handleDivisionsListMode} />
                            </div>
                        </div>
                    )}
                    {!bootstrapped && <Loader key="loader" message={`Loading Assets for Schedule Building`} />}
                </div>

                {/* Modal */}
                {(this.state.modal || this.state.buildingSchedule) && <BuildSchedule isOpen={this.state.modal || this.state.buildingSchedule}
                    toggle={this.toggleModal} season={season}
                    onToggleSeasonCheckedForSchedule={this.toggleSeasonCheckedForSchedule}
                    onBuildSchedule={this.buildSchedule}
                    buildingSchedule={this.state.buildingSchedule}
                    {...this.props} />}
                
                { /* Build Preview / Summary */}
                {isBuildPreviewOpen && <BuildPreview isOpen={isBuildPreviewOpen} toggleBuildPreview={this.toggleBuildPreview} 
                fnOk={this.goToCalendar} season={season} params={this.props.params} />}
                
            </section>
        )
    }
}

export default DragDropContext(HTML5Backend)(ScheduleManager);
