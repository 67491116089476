function reducer(state = [], action) {  
  switch (action.type) {
    case 'TEAM/PLANS/SET':
      return action.atp;    
    default:
      return state;
  }
}

export default reducer;
