import React from 'react';
import DivisionsList from './divisions';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from '../../../../../helpers';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import _ from 'lodash';
import RegistrationProgramBigIcon from './registration_programs/big_icon';
import AdjustmentBigIcon from './adjustments/big_icon';


const target = {
    canDrop(props) {
        return true;
    },
    drop(props, monitor, component) {

        // Dropped in another Flighted Team (to replace it position)
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }
        let { tab } = component.state;

        // Player / Team registration
        if (tab === 1) {
            const { divisions, seasonActions, divisionsActions } = props;

            // Evaluate if the division already has a registration program. If it does, prompt for confirmation of replace.
            const { program, type } = monitor.getItem();
            switch (type) {

                case ItemTypes.REGISTRATIONFEE:

                    divisions.forEach(division => {
                        // update the store (optimistic)
                        seasonActions && seasonActions.updateProgram(program, {
                            IdCatalogItem: program.IdCatalogItem,
                            DivisionName: division.Name,
                            IdDivision: division.IdDivision
                        });
                        // call the api to update the server (optimistic)
                        divisionsActions && divisionsActions.assignProgram(division.IdDivision, program.IdCatalogItem);
                    });

                    break;

                case ItemTypes.TRYOUT:
                    divisions.forEach(division => {
                        program.divisions.push({ IdCatalogItem: program.IdCatalogItem, DivisionName: division.Name, IdDivision: division.IdDivision });
                        // update the store (optimistic)
                        seasonActions && seasonActions.updateTryoutProgram(program);
                        // call the api to update the server (optimistic)
                        divisionsActions && divisionsActions.assignTryoutProgram(division.IdDivision, program.Id);
                    });
                    break;

                case ItemTypes.ADJUSTMENT:
                    divisions.forEach(division => {
                        program.divisions.push({
                            IdCatalogItemPriceAdjustmentByDate: program.IdCatalogItemPriceAdjustmentByDate,
                            DivisionName: division.Name,
                            IdDivision: division.IdDivision
                        });
                        // update the store (optimistic)
                        seasonActions && seasonActions.updateAdjustmentProgram(program);
                        // call the api to update the server (optimistic)
                        divisionsActions && divisionsActions.assignAdjustment(division.IdDivision, program.IdCatalogItemPriceAdjustmentByDate);

                    });
                    break;

                case ItemTypes.ZIPCODE:
                    divisions.forEach(division => {
                        program.divisions.push({
                            IdZipCodeGroup: program.IdZipCodeGroup,
                            DivisionName: division.Name,
                            IdDivision: division.IdDivision
                        });
                        // update the store (optimistic)
                        seasonActions && seasonActions.updateZipCodeProgram(program);
                        // call the api to update the server (optimistic)
                        divisionsActions && divisionsActions.assignZipCode(division.IdDivision, program.IdZipCodeGroup);
                    });
                    break;

                case ItemTypes.FAMILYADJUSTMENT:
                    divisions.forEach(division => {
                        program.divisions.push({
                            IdCatalogItemPriceAdjustmentByItemIndexGroup: program.IdCatalogItemPriceAdjustmentByItemIndexGroup,
                            DivisionName: division.Name,
                            IdDivision: division.IdDivision
                        });
                        // update the store (optimistic)
                        seasonActions && seasonActions.updateFamilyAdjustment(program);
                        // call the api to update the server (optimistic)
                        divisionsActions && divisionsActions.assignFamilyAdjustment(division.IdDivision, program.IdCatalogItemPriceAdjustmentByItemIndexGroup);
                    });
                    break;

                default: break;
            }
        }

        // Scout registration
        if (tab === 2) {
            // TODO: call API here
            const { season, seasonActions } = props;

            // Evaluate if the division already has a registration program. If it does, prompt for confirmation of replace.
            const { program, type } = monitor.getItem();
            switch (type) {

                case ItemTypes.REGISTRATIONFEE:

                    //divisions.forEach(division => {
                    // update the store (optimistic)
                    /*seasonActions && seasonActions.updateProgram(program, {
                        IdCatalogItem: program.IdCatalogItem,
                        DivisionName: division.Name,
                        IdDivision: division.IdDivision
                    });*/
                    // call the api to update the server (optimistic)
                    seasonActions && seasonActions.assignScoutProgram({ IdSeason: season.IdSeason, IdCatalogItem: program.IdCatalogItem });
                    //});

                    break;

                case ItemTypes.ADJUSTMENT:
                    //divisions.forEach(division => {
                    /*program.divisions.push({
                        IdCatalogItemPriceAdjustmentByDate: program.IdCatalogItemPriceAdjustmentByDate,
                        DivisionName: division.Name,
                        IdDivision: division.IdDivision
                    });*/
                    // update the store (optimistic)
                    //seasonActions && seasonActions.updateAdjustmentProgram(program);
                    // call the api to update the server (optimistic)                        
                    seasonActions && seasonActions.assignScoutAdjustment({ IdSeason: season.IdSeason, IdCatalogItemPriceAdjustmentByDate: program.IdCatalogItemPriceAdjustmentByDate });

                    //});
                    break;

                default: break;
            }

        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({
            shallow: true
        }),
        canDrop: monitor.canDrop()
    };
}

class Left extends React.Component {
    state = {
        tab: 1
    }
        
    render() {
        const { season, connectDropTarget, isOverCurrent, canDrop } = this.props, { tab } = this.state;
        return connectDropTarget(
            <div className="bg-white h-100 left-side">
                <div className="d-flex flex-row">
                    <button onClick={() => this.setState({ tab: 1 })} className={`w-50 btn btn-${tab === 1 ? 'info' : 'link'} sqwad-font`}>PLAYERS</button>
                    <button onClick={() => this.setState({ tab: 2 })} className={`w-50 btn btn-${tab === 2 ? 'info' : 'link'} sqwad-font`}>SCOUTS</button>
                </div>
                {tab === 1 && <section>
                    <div className="d-flex flex-row justify-content-center" style={{ backgroundColor: '#c8c8c8', height: 48 }}>
                        <h3 className="align-self-center black">Divisions</h3>
                    </div>
                    <div className="seasons-wrapper no-margin p-3">
                        <div className={`droppable ${canDrop ? 'action' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'} d-flex flex-row`}>
                            {canDrop && <span>Drop here to apply to all the divisions<i className="ml-2 fas fa-arrow-left red" /></span>}
                            {!canDrop && <span>{season.Name}</span>}
                            {!canDrop && <div className="d-flex flex-column ml-auto align-self-center pl-2">
                                <div className="d-flex flex-row">

                                    {/* REMOVE ALL REGISTRATION PROGRAMS */}
                                    <i id="icon_programs" onClick={() => this.setState({ programs: true })} className={`fas fa-address-card ${_.find(this.props.paymentPlans, p => (p.divisions && p.divisions.length)) ? 'blue-dark' : 'gray'} w-50 pointer`}>
                                        <Popover placement="top" isOpen={this.state.programs ? true : false} target={`icon_programs`} toggle={() => this.setState({ programs: !this.state.programs })}>
                                            <PopoverBody className="d-flex flex-column justify-content-end">
                                                <button id={`confirm_programs`} className="btn btn-danger btn-sm btn-block font-8" onClick={() => {
                                                    const { divisions } = this.props, { paymentPlans } = this.props;
                                                    divisions.forEach(division => {
                                                        paymentPlans.forEach(program => {
                                                            this.props.divisionsActions &&
                                                                this.props.divisionsActions.removeProgram(program, division);
                                                        });
                                                    });
                                                    this.setState({ programs: false });
                                                }}>Click here to remove Registration Programs<br />from ALL the divisions</button>
                                            </PopoverBody>
                                        </Popover></i>

                                    {/* REMOVE ALL ADJUSTMENTS */}
                                    <i id="icon_adjustments" onClick={() => this.setState({ deleting_adjustments: true })} className={`fas fa-wrench ml-1 ${_.find(this.props.adjustments, p => (p.divisions && p.divisions.length)) ? 'purple' : 'gray'} w-50 pointer`}>
                                        <Popover placement="top" isOpen={this.state.deleting_adjustments ? true : false} target={`icon_adjustments`} toggle={() => this.setState({ deleting_adjustments: !this.state.deleting_adjustments })}>
                                            <PopoverBody className="d-flex flex-column justify-content-end">
                                                <button className="btn btn-danger btn-sm btn-block font-8" onClick={() => {
                                                    const { divisions } = this.props, { adjustments } = this.props;
                                                    divisions.forEach(division => {
                                                        adjustments.forEach(program => {
                                                            this.props.divisionsActions &&
                                                                this.props.divisionsActions.removeAdjustment(program, division);
                                                        });
                                                    });
                                                    this.setState({ deleting_adjustments: false });
                                                }}>Click here to remove Adjustments<br />from ALL the divisions</button>
                                            </PopoverBody>
                                        </Popover></i>
                                </div>
                                <div className="d-flex flex-row mt-1">

                                    {/* REMOVE ALL ZIP CODE ADJUSTMENTS */}
                                    <i id="icon_zip" onClick={() => this.setState({ deleting_zip: true })} className={`fas fa-map-marked-alt ${_.find(this.props.zipCodesAdjustments, p => (p.divisions && p.divisions.length)) ? 'blue' : 'gray'} w-50 pointer`}>
                                        <Popover placement="top" isOpen={this.state.deleting_zip ? true : false} target={`icon_zip`} toggle={() => this.setState({ deleting_zip: !this.state.deleting_zip })}>
                                            <PopoverBody className="d-flex flex-column justify-content-end">
                                                <button className="btn btn-danger btn-sm btn-block font-8" onClick={() => {
                                                    const { divisions } = this.props, { zipCodesAdjustments } = this.props;
                                                    divisions.forEach(division => {
                                                        zipCodesAdjustments.forEach(program => {
                                                            this.props.divisionsActions &&
                                                                this.props.divisionsActions.removeZipCode(program, division);
                                                        });
                                                    });
                                                    this.setState({ deleting_zip: false });
                                                }}>Click here to remove Zip Code Discounts<br />from ALL the divisions</button>
                                            </PopoverBody>
                                        </Popover></i>



                                    {/* REMOVE ALL FAMILY DISCOUNTS */}
                                    <i id="icon_family" onClick={() => this.setState({ deleting_family_discounts: true })} className={`fas fa-users-cog ml-1 ${_.find(this.props.familyAdjustments, p => (p.divisions && p.divisions.length)) ? 'green' : 'gray'} w-50 pointer`}>
                                        <Popover placement="top" isOpen={this.state.deleting_family_discounts ? true : false} target={`icon_zip`} toggle={() => this.setState({ deleting_family_discounts: !this.state.deleting_family_discounts })}>
                                            <PopoverBody className="d-flex flex-column justify-content-end">
                                                <button className="btn btn-danger btn-sm btn-block font-8" onClick={() => {
                                                    const { divisions } = this.props, { familyAdjustments } = this.props;
                                                    divisions.forEach(division => {
                                                        familyAdjustments.forEach(program => {
                                                            this.props.divisionsActions &&
                                                                this.props.divisionsActions.removeFamilyAdjustment(program, division);
                                                        });
                                                    });
                                                    this.setState({ deleting_family_discounts: false });
                                                }}>Click here to remove Family Discounts<br />from ALL the divisions</button>
                                            </PopoverBody>
                                        </Popover></i>
                                </div>
                            </div>}
                        </div>
                        <DivisionsList {...this.props} />
                        <div style={{ height: 100 }} />
                    </div>
                </section>}
                {tab === 2 && <section>
                    <div className={`tile card p-4 m-2 d-flex flex-row justify-content-center drop-area ${canDrop ? 'action' : ''} ${isOverCurrent ? 'toBold' : 'toNormal'} `} style={{ borderStyle: 'dashed', borderWidth: '3px' }} >
                        <span className="black font-14 line1 text-center">Drop here registration fees (<i className="fas fa-address-card blue-dark" />) and adjustments (<i className="fas fa-wrench purple" />)</span>
                    </div>
                    {this.props.adjustments && this.props.paymentPlans && <div className="d-flex pt-2 flex-wrap px-2 justify-content-center">
                        {season.IdCatalogItemForScouts && _.find(this.props.paymentPlans, pp => pp.IdCatalogItem === season.IdCatalogItemForScouts) && <RegistrationProgramBigIcon {...this.props} program={_.find(this.props.paymentPlans, pp => pp.IdCatalogItem === season.IdCatalogItemForScouts)} index={0} fnRemove={() => {
                            // TODO: call API to remove
                            this.props.seasonActions && this.props.seasonActions.removeScoutProgram({ IdSeason: season.IdSeason });
                        }} />}
                        {season && season.scout_registration_adjustments && season.scout_registration_adjustments.map((program, i) => <AdjustmentBigIcon key={i} {...this.props} program={_.find(this.props.adjustments, a => a.IdCatalogItemPriceAdjustmentByDate === program.IdCatalogItemPriceAdjustmentByDate)} index={i}
                            fnRemove={() => {
                                // TODO: call API to remove
                                this.props.seasonActions && this.props.seasonActions.removeScoutAdjustment({ IdSeason: season.IdSeason, IdCatalogItemPriceAdjustmentByDate: program.IdCatalogItemPriceAdjustmentByDate });
                            }} />)}
                    </div>}
                </section>}
            </div >
        )
    }
}

export default DropTarget([ItemTypes.REGISTRATIONFEE, ItemTypes.TRYOUT, ItemTypes.ADJUSTMENT, ItemTypes.FAMILYADJUSTMENT], target, collect)(Left);