import _ from 'lodash';

function reducer(state = [], action) {
  let index = 0;
  switch (action.type) {
    case 'ADJUSTMENTS/FAMILY_ADJUSTMENT/SET':
      return action.familyAdjustments;
    case 'ADJUSTMENTS/FAMILY_ADJUSTMENT/CLEAR':
      return null;
    case 'ADJUSTMENTS/FAMILY_ADJUSTMENTS/UPDATE':
      index = _.findIndex(state, program => { return program.IdCatalogItemPriceAdjustmentByItemIndexGroup === action.program.IdCatalogItemPriceAdjustmentByItemIndexGroup });
      return [...state.slice(0, index), action.program, ...state.slice(index + 1)];
    case 'FAMILY_ADJUSTMENT/UPDATE':
      index = _.findIndex(state, program => { return program.IdCatalogItemPriceAdjustmentByItemIndexGroup === action.program.IdCatalogItemPriceAdjustmentByItemIndexGroup });
      return [...state.slice(0, index), action.program, ...state.slice(index + 1)];
    case 'DIVISION/REMOVE_FAMILY_ADJUSTMENT':
      action.program.divisions = _.reject(action.program.divisions, (d) => { return d.IdDivision === action.division.IdDivision });
      // Update the store
      index = _.findIndex(state, program => { return program.IdCatalogItemPriceAdjustmentByItemIndexGroup === action.program.IdCatalogItemPriceAdjustmentByItemIndexGroup });
      return [...state.slice(0, index), action.program, ...state.slice(index + 1)];
    default:
      return state;
  }
}

export default reducer;
