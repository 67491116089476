import React from 'react';
import FullCalendarWrapper from './FullCalendarWrapper';
import _ from 'lodash';
import { Link } from 'react-router';
import moment from 'moment';
import Loader from '../../../common/Loader';
import GameDetails from '../../../common/modals/GameDetails';
import AdjustMultipleGames from '../../../common/modals/AdjustMultipleGames';
import DeleteMultipleGames from '../../../common/modals/DeleteMultipleGames';
import './calendar.css';
import FriendlyGame from '../../../common/modals/FriendlyGameDetails';

class Calendar extends React.Component {

  state = {
    bootstrapped: false,
    allTeams: [],
    modal: false,
    selectedGame: null,
    selectedFlight: null,
    activeTab: 1,
    adjustingGames: false,
    deletingGames: false,
    selectedDate: null, // moment(),
    idDivision: 0,
    selectedDivision: null,
    idLocation: 0,
    selectedLocation: null,
    gamesDuration: 90,
    locationsFetch: false
  }

  // Lifecycle
  componentWillMount = () => {

    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Games Calendar',
      main: this.props.season.Name || null
    });

    this.props.seasonActions &&
      this.props.seasonActions.fetchGames &&
      this.props.seasonActions.fetchGames(params.idSeason);

    // Fetch the divisions of the season (it also brings the coaches)
    this.props.scheduleManagerDivisionsActions &&
      this.props.scheduleManagerDivisionsActions.fetchDivisions &&
      this.props.scheduleManagerDivisionsActions.fetchDivisions(params.idSeason);

    this.props.flightsActions &&
      this.props.flightsActions.fetchFlights(params.idSeason);

    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
  }
  componentWillUnmount = () => {
    this.props.seasonActions &&
      this.props.seasonActions.clearCoaches &&
      this.props.seasonActions.clearCoaches();

    this.props.scheduleManagerDivisionsActions &&
      this.props.scheduleManagerDivisionsActions.clearDivisions &&
      this.props.scheduleManagerDivisionsActions.clearDivisions();

    this.props.seasonActions &&
      this.props.seasonActions.clearGames &&
      this.props.seasonActions.clearGames();
    this.props.clearLocations && this.props.clearLocations();
  }
  componentWillReceiveProps = (nextProps) => {

    const { setTitle } = nextProps;

    if (nextProps.locations && nextProps.locations.length &&
      nextProps.divisions && nextProps.divisions.length &&
      nextProps.games && this.state.locationsFetch) {
      // Notify         
      this.setState({ bootstrapped: true, no_locations: false });
    }
    if (nextProps.locations && !nextProps.locations.length) {
      this.setState({ no_locations: true });
    }

    if (nextProps.season && this.props.season && nextProps.season.IdSeason !== this.props.season.IdSeason) {
      setTitle && setTitle({
        sub: 'Games Calendar',
        main: (nextProps.season || {}).Name || null
      });
    }

    if ((nextProps.season.IdLeague || this.props.season.IdLeague) && !this.state.locationsFetch) {
      this.setState({ locationsFetch: true }, () => {
        this.props.fetchLocations && this.props.fetchLocations(nextProps.season.IdLeague);
      });
    }
  }
  //

  // CRUD
  selectGame = (selectedGame, selectedFlight) => {
    // TODO: try to find the flight the game is in (I wonder if this should always be)
    if (!selectedFlight) {
      let { divisions } = this.props;
      let division = _.find(divisions, d => _.find(d.Flights, f => f.IdFlight === selectedGame.IdFlight));
      if (division) {
        selectedFlight = _.find(division.Flights, f => f.IdFlight === selectedGame.IdFlight);
      }
    }
    //
    // HOT FIX: there's some problem with the GameStartHour that gets a random date everytime. I'm forcing it to be the same as GameDate
    this.setState({
      selectedGame: { ...selectedGame, GameStartHour: moment(selectedGame.GameDate) },
      friendlyGame: null,
      selectedFlight,
      modal: true
    });
  }
  selectFriendly = selectedFriendly => {
    // HOT FIX: there's some problem with the GameStartHour that gets a random date everytime. I'm forcing it to be the same as GameDate
    this.setState({
      friendlyGame: { ...selectedFriendly, GameStartHour: moment(selectedFriendly.GameDate) },
      selectedGame: null,
      selectedFlight: null,
      modal: true
    });
  }
  createGame = selectedGame => {
    this.setState({
      selectedGame,
      friendlyGame: null,
      selectedFlight: null,
      modal: true
    });
  }
  createFriendlyGame = friendlyGame => this.setState({ friendlyGame, selectedGame: null, selectedFlight: null, modal: true });

  adjustGames = (selectedDate) => {
    this.setState({
      adjustingGames: !this.state.adjustingGames,
      modal: true,
      selectedDate: selectedDate || this.state.selectedDate
    })
  }
  deleteGames = selectedDate => {
    this.setState({
      deletingGames: !this.state.deletingGames,
      modal: true,
      selectedDate: selectedDate || this.state.selectedDate
    })
  }
  saveGame = game => {
    this.setState({
      modal: false,
      selectedGame: null,
      selectedFlight: null
    });
  }
  delete = id => {
    this.setState({
      modal: false,
      selectedGame: null,
      selectedFlight: null
    });
  }

  // Toggles and Selection
  toggleModal = () => this.setState({ modal: !this.state.modal, selectedGame: null, selectedFriendlyGame: null });
  closeModal = () => this.setState({ modal: false, adjustingGames: false });
  changeTab = activeTab => this.setState({ activeTab });

  divisionChange = (obj) => {
    const { divisions } = this.props
    let idDivision = obj ? obj.value : 0;
    this.setState({
      idDivision: idDivision,
      selectedDivision: _.find(divisions, function (d) {
        return d.IdDivision === idDivision
      })
    });
  }
  locationChange = (obj) => {
    const { divisions } = this.props
    let idDivision = obj ? obj.value : 0;

    this.setState({
      idDivision: idDivision,
      selectedDivision: _.find(divisions, function (d) {
        return d.IdDivision === idDivision
      })
    });
  }

  render() {

    const { locations, divisions } = this.props,
      { bootstrapped, selectedGame, friendlyGame, selectedFlight,
        adjustingGames, deletingGames, modal } = this.state;

    const divisionsOptions = divisions ? [{
      label: "All Divisions",
      value: 0
    }, ...divisions.map(division => ({
      label: division.Name,
      value: division.IdDivision
    }))] : [{
      label: "All Divisions",
      value: 0
    }];

    let fields = [];
    (locations || []).forEach(function (location) {
      fields = [...fields, {
        Name: location.Name,
        IdLocation: parseInt(location.IdLocation, 10)
      }, ..._.map(location.Fields || location.fields, function (f) {
        return {
          Name: f.Name,
          IdLocation: parseInt(f.IdLocation, 10)
        };
      })];
    });

    let fieldsOptions = [...fields.map(field => ({
      label: field.Name,
      value: parseInt(field.IdLocation, 10)
    }))]

    let division = selectedGame ? _.find(divisions, function (d) {
      return d.IdDivision === selectedGame.IdDivision;
    }) : _.first(divisions);

    let teams = _.chain(divisions || []).map(d => d.Teams).flattenDeep().value(), all_teams = this.props.teams;

    return (<div className="bg-gray-dark">
      { /* CALENDAR */}
      {bootstrapped && !this.state.no_locations && <FullCalendarWrapper {...this.props} selectedGame={selectedGame}
        selectGame={this.selectGame} selectFriendly={this.selectFriendly} createGame={this.createGame}
        createFriendlyGame={this.createFriendlyGame} adjustGames={this.adjustGames} deleteGames={this.deleteGames} />}
      { /* CREATE OR UPDATE GAMES */}
      {selectedGame && modal && (
        <GameDetails fnSave={this.saveGame} fnDelete={this.delete} flight={selectedFlight} fields={fields} isOpen={modal} toggle={this.toggleModal}
          game={selectedGame} division={division} gamesActions={this.props.gamesActions} divisions={divisions} />)}
      {friendlyGame && modal && (
        <FriendlyGame fnSave={this.saveGame} fnDelete={this.delete} teams={this.props.teams} fields={fields} isOpen={modal} toggle={this.toggleModal}
          game={friendlyGame} gamesActions={this.props.gamesActions} divisions={divisions} />)}
      { /* ADJUSTING GAMES */}
      {adjustingGames && modal && divisions && divisions.length > 0 && (
        <AdjustMultipleGames isOpen={this.state.adjustingGames} toggle={this.adjustGames} divisions={divisionsOptions} locations={fieldsOptions} games={this.props.games}
          gamesActions={this.props.gamesActions} teams={teams} all={all_teams} selectedDate={this.state.selectedDate} seasonActions={this.props.seasonActions} idSeason={this.props.params.idSeason} />)}
      {deletingGames && modal && (
        <DeleteMultipleGames isOpen={this.state.deletingGames} toggle={this.deleteGames} divisions={divisionsOptions} locations={fieldsOptions} games={this.props.games}
          gamesActions={this.props.gamesActions} selectedDate={this.state.selectedDate} seasonActions={this.props.seasonActions} idSeason={this.props.params.idSeason} />)}
      { /* LOADER */}
      {!bootstrapped && !this.state.no_locations && <Loader key="loader" message={`Loading Locations and Games`} />}
      
      {/* NO LOCATIONS */}
      {this.state.no_locations && <div className="jumbotron text-center">
        <h1 className="display-3 line1">There are no locations / fields registered on this season</h1>
        <p className="lead">Navigate to <Link className="blue" to={`/season/${this.props.params.idSeason}/manage_fields`}>Locations Manager</Link> and add some locations first</p>
      </div>}
    </div>)
  }
}

export default Calendar;
