import React from 'react';
import { DropTarget, DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../../../helpers';

const source = {
  beginDrag(props) {
    return props;
  }
}

const target = {
  canDrop(props) {
    return true;
  },
  drop(props, monitor, component) {
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild) {
      return;
    }

    const { pouch, pouchActions } = props;
    const from = monitor.getItem().index, to = props.index;
    pouch.qandas.splice(to, 0, pouch.qandas.splice(from, 1)[0]);

    pouchActions &&
      pouchActions.set(pouch);

    // I know this is fucking wrong.
    props.fnUpdate && props.fnUpdate();
  }
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({
    shallow: true
  }),
  itemDropping: monitor.getItem(),
});

const spread = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

class SortableItem extends React.Component {

  render() {
    const { connectDropTarget, isOverCurrent, connectDragSource, itemDropping } = this.props;
    return connectDragSource(connectDropTarget(<li>
      {isOverCurrent && itemDropping.index !== this.props.index && <figure className='card p-2 text-center margin-bottom-half bg-gray'>Drop here</figure>}
      {this.props.children}
    </li>))
  }
}

export default
  flow(
    DropTarget([ItemTypes.ADJUSTMENT], target, collect),
    DragSource(ItemTypes.ADJUSTMENT, source, spread)
  )
    (SortableItem);