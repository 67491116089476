import React from 'react';
import Loader from '../../../common/Loader';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TournamentList from './TournamentList';

class TeamsRegistration extends React.Component {

  state = {
    bootstrapped: false,
  }

  // Lifecycle
  componentWillMount() {

    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    this.props.reportsActions && this.props.reportsActions.fetchRosters(params.idSeason)
    setTitle && setTitle({ main: 'Report | Teams Registration' });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;
      setTitle && setTitle({ sub: 'Report | Teams Registration', main: nextProps.season.Name || null });
    }

    if (this.props.report !== nextProps.report && nextProps.report) {
      this.setState({ bootstrapped: true });
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //

  render() {
    const { bootstrapped } = this.state,
      { season } = this.props;

    return <section>
      <div className="filters-wrapper d-flex flex-row">
        <div className="ml-auto p-2">
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Registration - ${season.Name}`}
            sheet="Registration" buttonText="Export as XLS" />
        </div>
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            <TournamentList {...this.props} />
          </section>)}
      </div>
    </section>
  }
}

export default TeamsRegistration;