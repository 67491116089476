export function login(args) {
    return {
        type: "AUTH/LOGIN",
        token: args.token,
        isAdmin: args.isAdmin
    }
}
export function logout() { return { type: "AUTH/LOGOUT", } }
export function setTitle(args) { return { type: 'TITLE/SET', args } }
export function updateTitle(args) { return { type: 'TITLE/UPDATE', args } }
export function setMicro(micro) { return { type: 'MICRO/SET', micro } }
export function putReferal(referal) { return { type: 'REFERAL/SET', referal } }
export function clearReferal() { return { type: 'REFERAL/CLEAR' } }

export function setGeneric(generic) {
    return {
        type: 'GENERIC/SET',
        generic
    }
}
export function clearGeneric() {
    return {
        type: 'GENERIC/CLEAR'
    }
}
export function setBackPath(args) {
    return {
        type: 'BACKPATH/SET',
        args
    }
}
export function fetchSeasons(idLeague) {
    return {
        type: 'SEASONS/FETCH',
        idLeague
    }
}
export function fetchSeason(idSeason) {
    return {
        type: 'SEASON/DASHBOARD/FETCH',
        idSeason
    }
}
export function clearSeasons() {
    return {
        type: 'SEASONS/SET',
        seasons: null
    }
}
export function clearLeagues() {
    return {
        type: 'LEAGUES/SET',
        leagues: []
    }
}
export function clearLocations() { return { type: 'LOCATIONS/CLEAR' } }
export function clearGenders() { return { type: 'GENDERS/CLEAR' } }
export function fetchDivisionsSummary(idSeason) {
    return {
        type: 'DIVISIONS/FETCH',
        idSeason,
        module: 'Dashboard'
    }
}
export function fetchDivisions(idSeason) {
    return {
        type: 'DIVISIONS/FETCH',
        idSeason,
        module: 'Flight_Manager'
    }
}
export function clearDivisions() {
    return {
        type: 'DIVISIONS/SET',
        divisions: null
    }
}
export function fetchFlights(idSeason, idDivision) {
    return {
        type: 'FLIGHTS/FETCH',
        idDivision,
        idSeason
    }
}
export function fetchCrossFlightRules(idDivision) {
    return {
        type: 'CROSS_FLIGHT_RULES/FETCH',
        idDivision
    }
}
export function clearFlights() {
    return {
        type: 'FLIGHTS/SET',
        flights: []
    }
}
export function setFlights(flights) {
    return {
        type: 'FLIGHTS/SET',
        flights
    }
}
export function moveFlightedTeamsInFlight(args) {
    return {
        type: 'FLIGHTED_TEAMS/SORT',
        args
    }
}
export function moveFlightedTeamToAnotherFlight(args) {
    return {
        type: 'FLIGHTED_TEAMS/MOVE',
        args
    }
}

export function fetchCountries() { return { type: 'COUNTRIES/FETCH' } }
export function fetchCommunities() { return { type: 'COMMUNITIES/FETCH' } }


export function addFlightedTeam(flightedTeam) {
    return {
        type: 'FLIGHTED_TEAMS/ADD',
        flightedTeam
    }
}
export function removeFlightedTeam(flightedTeam) {
    return {
        type: 'FLIGHTED_TEAMS/REMOVE',
        flightedTeam
    }
}
export function fetchLocations(idLeague) {
    return { type: 'LOCATIONS/FETCH', idLeague }
}
export function fetchAppPlans() {
    return {
        type: 'APP_PLANS/FETCH'
    }
}
export function fetchStatuses() {
    return {
        type: 'STATUS/FETCH'
    }
}
export function fetchCarriers() {
    return { type: 'CARRIERS/FETCH' }
}

export function fetchBracketGroupTypes() { return { type: 'BRACKET_GROUP_TYPES/FETCH' } }

export function fetchPositions() { return { type: 'POSITIONS/FETCH' } }
export function fetchGameTypes() { return { type: 'GAME_TYPES/FETCH' } }
export function fetchSizes() {
    return { type: 'SIZES/FETCH' }
}
export function setDeveloperMessage(developer) {
    return { type: 'DEVELOPER/SET', developer }
}
export function clearDeveloperMessage() {
    return { type: 'DEVELOPER/CLEAR' }
}
export function fetchRankingAgeGroups() { return { type: 'AGE_GROUPS/RANKINGS/FETCH' } }
export function clearTeams() { return { type: 'TEAMS/CLEAR' } }
export function setDefaultGameDuration(defaultGameDuration) { return { type: 'GAME_DURATION/SET', defaultGameDuration } }