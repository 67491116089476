import React from 'react';
import _ from 'lodash';
import Loader from '../../../common/Loader';
import { formatPrice } from '../../../../helpers';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class Comp extends React.Component {

  state = {
    bootstrapped: false
  }

  // Lifecycle
  componentWillMount() {

    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    this.props.reportsActions && this.props.reportsActions.fetchAutoPay(params.idSeason);

    setTitle && setTitle({
      main: 'Report | Auto Pay'
    });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;

      setTitle && setTitle({
        sub: 'Report | Auto Pay',
        main: nextProps.season.Name || null
      });

      this.setState({
        bootstrapped: true
      });
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //

  render() {
    const { bootstrapped } = this.state,
      { report, season } = this.props;

    return <section>
      <div className="filters-wrapper d-flex flex-row">
        <div className="ml-auto p-2">
          { /*<button className="btn btn-sm btn-warning"><i className="fas fa-file-excel" />
                                                                                                                                                                                                                                                                 { ' ' }Export</button>*/ }
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Auto-Pay ${season.Name}`}
            sheet="AutoPay" buttonText="Export as XLS" />
        </div>
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            <table id="table-to-xls" className="table table-sm font-10">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Set Date</th>
                  <th>Date Processed</th>
                  <th>Transaction Id</th>
                  <th align="right">Amount</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {report && report.map((record, i) => (
                  <tr key={i}>
                    <td className="font-8">
                      {record.IdUser}
                    </td>
                    <td>
                      {record.FirstName}
                    </td>
                    <td>
                      {record.LastName}
                    </td>
                    <td>
                      {record.EmailAddress}
                    </td>
                    <td>
                      {moment(record.DateCreated).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      {moment(record.DateProcessed).format('MM/DD/YYYY')}
                    </td>
                    <td className="font-8">
                      {record.IdTransaction}
                    </td>
                    <td align="right">
                      {formatPrice(record.Amount * 100)}
                    </td>
                    <td className={`"font-8" ${record.MessageFromGateway}`}>
                      {record.MessageFromGateway}
                    </td>
                  </tr>)
                )}
              </tbody>
              <tfoot>
                <tr className="bg-info font-12 white">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td align="right">Total:</td>
                  <td align="right">
                    {formatPrice(_.reduce(_.map(report, (i) => {
                      return i.Amount;
                    }), function (sum, n) {
                      return sum + n;
                    }, 0) * 100)}
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </section>)}
      </div>
    </section>
  }
}

export default Comp;