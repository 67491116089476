import React from 'react';

const TournamentList = ({ report }) => {  
  return (
    <table id="table-to-xls" className="table table-sm font-10">
      <thead>
        <tr>
          <th>Id Team</th>          
          <th>Team Name</th>
          <th>Id Div.</th>
          <th>Team Gender</th>
          <th>Division</th>
          <th>#Players</th>
          <th>#Coaches</th>
        </tr>
      </thead>
      <tbody>
        {report && report.map((record, i) => (
          <tr key={i}>
            <td className="font-8">
              {record.IdTeam}
            </td>
            <td>
              {record.TeamName}
            </td>
            <td className="font-8">
              {record.IdTeamDivision}
            </td>
            <td>
              {record.TeamGender || record.Gender}
            </td>
            <td>
              {record.DivisionName}
            </td>
            <td className="text-center">{record.NumberOfPlayers}</td>
            <td className="text-center">{record.NumberOfCoaches}</td>
          </tr>)
        )}
      </tbody>
    </table>
  );
}

export default TournamentList;
