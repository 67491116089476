import React from 'react';
import { Animated } from 'react-animated-css';
import Entity from './partials/entity.js';
import PremiumModal from '../../sqwad/modals/family'
import _ from 'lodash';

class FamilySubscriptions extends React.Component {

    state = {
        bootstrapped: false,
        modal: false,
        selectedUser: null,
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, } = this.props;

        setTitle && setTitle({
            main: 'Family',
            sub: 'Subscriptions'
        });

        this.props.familyActions && this.props.familyActions.getFamilySubscriptions();
        this.props.fetchCountries && this.props.fetchCountries();
        this.props.fetchCommunities && this.props.fetchCommunities();
        this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(1);
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.family_subscriptions && nextProps.family) {
            this.setState({ bootstrapped: true });
        }
    }
    //    

    
    onSaveProfile = () => {
        const { roster_actions } = this.props,
            { selectedUser } = this.state;

        roster_actions.updateCollegeProfile({
            IdCollegeProfile: selectedUser.IdCollegeProfile,
            IdUser: selectedUser.IdUser,
            GraduationYear: this.txtGraduationYear.value,
            SchoolName: this.txtSchoolName.value,
            GPA: this.txtGPA.value,
            SAT: this.txtSAT.value,
            ACT: this.txtACT.value,
            Notes: this.txtNotes.value
        });
        this.setState({ selectedUser: null });
    }    

    render() {
        const { family, family_subscriptions, afp } = this.props, { bootstrapped, upgrading } = this.state;       

        return (
            <section>
                {bootstrapped && family && family.length > 0 && <Animated animationIn="fadeIn" className="container margin-bottom">
                    <ul id="roster" className="row" style={{ paddingTop: '3em' }}>
                        {family && family.map && family.map((entity, i) => (
                            <li key={entity.IdUser || i} className={` col-sm-12 col-md-6 col-lg-4 hoverable pointer mt-4`} style={{ flexBasis: 'auto' }}>
                                <Entity entity={entity} index={i} family_subscriptions={family_subscriptions} 
                                onClick={() => this.setState({ selectedUser: entity, upgrading: true  })} />
                            </li>))}
                    </ul>
                </Animated>}

                {upgrading && <PremiumModal user={this.state.selectedUser} {...this.props} toggle={() => this.setState({ upgrading: !this.state.upgrading })} isOpen={upgrading} target={_.find(afp, p => p.Amount)} />}
                
            </section >)
    }
}

export default FamilySubscriptions;
