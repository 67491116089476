import React from 'react';
import NetworkItem from './network_item';
import request from 'superagent';
import config from '../../../../config';
import _ from 'lodash';
import fakeAuth from '../../../../fakeAuth';
import Player from './network_item';
import Role from './role';
import moment from 'moment';
import { humanize_user } from '../../../../helpers';

class Network extends React.Component {
    state = {
        searchTerm: null,
        searched: false,
        searching: false,
        users: null
    }
    componentWillMount() {
        this.setState({ roster: [...this.props.roster], roles: [...this.props.roles] });
    }

    search = (e) => {
        e && e.preventDefault && e.preventDefault();
        const { season } = this.props;
        this.setState({
            searching: true,
            selectedPlayer: null
        }, () => {
            if (season) {
                request.get(`${config.apiEndpoint}/api/v6/roster/search/${season.IdTeamDivision}/${this.txtSearch.value}`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then(({ body: { users } }) => {
                        users.forEach(u => humanize_user(u));
                        this.setState({ searching: false, users });
                    }, reject => {
                        this.setState({ searching: false, users: null });
                    });
            } else {
                this.setState({ bootstrapped: true, users: null })
            }
        });
    }
    onChange = () => this.setState({ searchTerm: this.txtSearch.value });

    select = (id) => {
        const { users } = this.state, { adding, division } = this.props;
        let user = _.find(users, r => r.IdUser === id);
        if (adding === 3) {
            if (division && (division.DateBirthMax || division.DateBirthMin)) {

                let user_dob = user.DateBirth.split('T')[0],
                    mask = user_dob.indexOf('/') !== -1 ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
                if (division.DateBirthMin && moment(division.DateBirthMin.split('T')[0], 'YYYY-MM-DD')
                    .isAfter(moment(user_dob, mask))) {
                    alert(`This person is too OLD to be rostered on this division`);
                    return;
                } else if (division.DateBirthMax && moment(division.DateBirthMax.split('T')[0], 'YYYY-MM-DD')
                    .isBefore(user_dob, mask)) {
                    alert(`This person is too YOUNG to be rostered on this division`);
                    return;
                }
            }
            if (user.IsPlayerInThisSeason) {
                alert(`${user.NameFirst} already belongs to a roster on this season`);
                return;
            }

            if (user) user.selected = !user.selected;
            this.setState({ users });

        } else {
            this.setState({ selectedUser: user, rolling: true })
        }
    }

    createPlayer = ({ idUser,
        playerRoleOverride,
        coachRoleOverride,
        idTeamDivision, }) => {
        const { rosterActions, season } = this.props;

        request.post(`${config.apiEndpoint}/api/v4/roster/simple/add`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({
                idUser,
                playerRoleOverride,
                coachRoleOverride,
                idTeamDivision,
            })
            .then(data => {
                rosterActions && rosterActions.fetchRoster(fakeAuth.getUser().IdUser, season.IdTeamDivision);
            });
    }

    onSave = () => {
        // TODO: call API
        const { season, adding, roles } = this.props, { users } = this.state;

        this.setState({ microTransact: true }, () => {
            if (adding === 3) {
                _.filter(users, u => u.selected).forEach(user => {
                    this.createPlayer({
                        idUser: user.IdUser,
                        playerRoleOverride: _.find(roles, r => r.IdRole === 3).IdRoleOverride,
                        idTeamDivision: season.IdTeamDivision
                    });
                })
            } else {

            }
            this.props.onSave && this.props.onSave();
        });
    }

    onSaveCoach = () => {
        const { season } = this.props;
        this.createPlayer({
            idUser: this.state.selectedUser.IdUser,
            coachRoleOverride: this.state.selectedRole.IdRoleOverride,
            idTeamDivision: season.IdTeamDivision
        });
        this.setState({ selectedUser: null, selectedRole: null, rolling: false })
        this.props.onSave && this.props.onSave();
    }

    proceedToAsk = () => {
        this.setState({ asking: true, to: this.txtSearch.value, invitation_sent: false });
    }

    selectRole = role => {
        const { roles } = this.state;
        roles.forEach(r => r.selected = r.IdRoleOverride === role.IdRoleOverride);
        this.setState({ selectedRole: role, roles });
    }

    doSendInvite = () => {
        const { season } = this.props;
        request.post(`${config.apiEndpoint}/api/v4/roster/invite_new`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({
                idTeamDivision: season.IdTeamDivision,
                email: this.state.to,
                name: this.txtWhom.value
            }).end();
        this.setState({ invitation_sent: true });
    }

    render() {
        const { users, searching, microTransact, rolling, roles, roster, asking, invitation_sent } = this.state,
            { adding, division } = this.props;

        let filteredRoster = _.chain(users)
            .filter(u => {
                if (division.IdGender === 2 && adding === 3) return u.IdGender === 2;
                return true;
            })
            .filter(user => {
                if (this.state.searchTerm) return `${user.NameFirst.toLowerCase()} ${user.NameLast.toLowerCase()}`.indexOf(this.state.searchTerm.toLowerCase()) !== -1;
                return true;
            }).value();

        return <section>
            {!asking && <section className="">
                {!rolling && <section>

                    <form className="pr-4 pl-4">
                        <div className="input-group shadow-box">
                            <input type="text" className="form-control" placeholder="Search for players" ref={(i) => this.txtSearch = i} />
                            <button type="submit" className="btn btn-success" onClick={this.search}>Search</button>
                        </div>
                    </form>

                    <section className="d-flex flex-wrap justify-content-center mt-2">
                        {users && filteredRoster.map((user, i) => <NetworkItem onSelect={() => this.select(user.IdUser)}
                            user={user} key={i} rostered={_.find(roster, r => r.IdUser === user.IdUser && r.IdRole === adding)} />)}
                    </section>

                    {users && !filteredRoster.length && !searching && <div style={{ position: 'fixed', top: 200, bottom: 0 }} className="h-100  d-flex flex-column justify-content-start">
                        <img alt="" src="/images/defaults/red_card.png" style={{ height: 170 }} className="align-self-center mt-4" />
                        <span className="mt-2 px-4 montserrat black text-center font-10 line1">Sorry, no users were found{this.txtSearch ? ` under an email address like '${this.txtSearch.value}'` : ''}</span>
                        <button className="btn btn-success m-4" onClick={this.proceedToAsk}>Ask them to Join!</button>
                    </div>}

                    {/* SAVE PLAYER */}
                    {_.filter(users, u => u.selected).length > 0 && adding === 3 && (
                        <div style={{ position: 'fixed', bottom: 0, height: 100, width: 350 }} className="ease bg-black d-flex flex-row justify-content-center p-4">
                            <button className={`btn btn-success align-self-center w-100`} style={{ width: 100 }} onClick={this.onSave}>
                                {!microTransact && `Add (${_.filter(users, u => u.selected).length}) Players`}
                                {microTransact && <i className="fa fa-spin fa-cog" />}</button>
                        </div>)}

                </section>}

                {rolling && <section>

                    <div className="pr-3 pl-3">
                        {/* TODO: CHANGE THIS */}
                        <Player rostered={false} user={this.state.selectedUser} onSelect={() => this.setState({ rolling: false, selectedUser: null })} />
                        <div style={{ height: 50 }} />
                        {_.filter(roles, r => r.IdRole === 4).map((role, i) => <Role role={role} key={i} onSelect={() => this.selectRole(role)} />)}
                    </div>

                    {/* SAVE COACH */}
                    {this.state.selectedUser && this.state.selectedRole && (
                        <div style={{ position: 'fixed', bottom: 0, height: 100, width: 350 }} className="ease bg-black d-flex flex-row justify-content-center p-4">
                            <button className={`btn btn-success align-self-center w-100`} style={{ width: 100 }} onClick={this.onSaveCoach}>
                                {!microTransact && `Save`}
                                {microTransact && <i className="fa fa-spin fa-cog" />}</button>
                        </div>)}
                </section>}
            </section>}

            {asking && <section className="d-flex flex-column ">
                <h2 className="align-self-center">Ask them to Join</h2>
                <span className="font-12 align-self-center">Please enter the name of whom you're inviting</span>
                <div className="p-4">
                    <div className="input-group shadow-box">
                        <input type="text" className="form-control" placeholder="Name" ref={(i) => this.txtWhom = i} />
                    </div>
                    {!invitation_sent && <button className={`btn btn-success align-self-center w-100 mt-4`} onClick={this.doSendInvite}>Do Send Invite</button>}
                    {!invitation_sent && <button className={`btn btn-link align-self-center w-100`} onClick={() => this.setState({ asking: false, to: null, users: null })}>Cancel</button>}
                    {invitation_sent && <section>
                        <h3 className="mt-4 w-100 text-center green">Invitation Sent</h3>
                        <button className={`btn btn-link align-self-center w-100`} onClick={() => this.setState({ asking: false, to: null, users: null })}><span className="underlined">Back</span></button>
                    </section>}
                </div>
            </section>}

        </section>
    }
}

export default Network;
