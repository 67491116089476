import React from 'react';
import { Animated } from 'react-animated-css';
import config from '../../../config';
import request from 'superagent';
import moment from 'moment';
import { humanize_user } from '../../../helpers';

class CollegeProfile extends React.Component {
    state = {
        entity: null
    }

    setup = (props) => {
        const { entity } = props;
        this.setState({ entity }, () => {
            request.get(`${config.apiEndpoint}/api/v6/college_profile/${entity.IdUser}`)
                .set('Authorization', `Bearer ${config.apiKey}`)
                .then(data => {
                    let { profile } = JSON.parse(data.text);
                    if (profile) humanize_user(profile);
                    this.setState({ player: profile });
                });
        });
    }

    // Lifecycle
    componentWillMount() {
        this.setup(this.props);
    }
    componentWillReceiveProps = nextProps => {
        this.setup(nextProps);
    }
    //    

    click = () => {
        const { onClick } = this.props, { player, entity } = this.state;
        onClick && onClick({ player, entity });
    }

    render() {
        const { entity, player } = this.state, { index } = this.props;

        return <Animated animationIn="fadeInRight" animationInDelay={100 * index} className={`d-flex flex-row tile bg-dark-blue h-100`} style={{ position: 'relative' }}>
            <div onClick={this.click} className="cover" style={{ maxWidth: '50%', width: 160, height: '100%', background: ['url(', (entity.UserImage || `/images/defaults/${entity.IdGender === 2 ? 'female' : 'male'}.png`), ') no-repeat center center black'].join('') }} />
            <div onClick={this.click} style={{ lineHeight: 1 }} className={`px-3 d-flex flex-column justify-content-center w-100`}>
                <span className="block white  font-20">{entity.NameFirst}</span>
                <span className="block white ">{entity.NameLast}</span>
                <span className="block white  margin-top-half">{moment(entity.DateBirth, 'MM/DD/YYYY').utc().format('MMM Do YYYY')}</span>
                <span className="block white  font-10">{entity.Gender}</span>
                {/*<span className="block white mt-2  font-10">{entity.FullPhoneNumber}</span>*/}
                {player && player.sports && player.sports.length > 0 && <span className="block white mt-2 font-12 text-right"><i className={`fas fa-toggle-${player.IsActive ? 'on green' : 'off white'}`} /> Active</span>}
            </div>
        </Animated>
    }

    renderOld() {
        const { entity, player } = this.state, { index } = this.props;
        return <Animated animationIn="fadeInRight" animationInDelay={100 * index} className={`d-flex flex-row tile bg-white h-100`} style={{ position: 'relative' }}>
            <div onClick={this.click} className="cover h-100 bg-black" style={{ width: 150, background: ['url(', (entity.UserImage || `/images/defaults/${entity.IdGender === 2 ? 'female' : 'male'}.png`), ') no-repeat center center'].join('') }} />
            {player && (
                <div className={`white w-100`} onClick={this.click}>
                    <div className="d-flex flex-row p-2 bg-black">
                        <div className="w-100 d-flex flex-column">
                            <span className="white font-14">{player.NameFirst}</span>
                            <span className="gray font-8">First Name</span>
                        </div>
                        <div className="w-100 d-flex flex-column">
                            <span className="white font-14">{player.NameLast}</span>
                            <span className="gray font-8">Last Name</span>
                        </div>
                    </div>

                    <div className="d-flex flex-row bg-white p-2">
                        <div className="w-100 d-flex flex-column">
                            <span className="black font-12">{player.GPA || 'N/A'}</span>
                            <span className="gray font-10">GPA</span>
                        </div>
                        <div className="w-100 d-flex flex-column">
                            <span className="black font-12 align-self-center">{player.SAT || 'N/A'}</span>
                            <span className="gray font-10 align-self-center">SAT</span>
                        </div>
                        <div className="w-100 d-flex flex-column">
                            <span className="black font-12 align-self-end">{player.ACT || 'N/A'}</span>
                            <span className="gray font-10 align-self-end">ACT</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row bg-white p-2">
                        <div className="w-100 d-flex flex-column">
                            <span className={`black font-${`${player.SchoolName || 'Archbishop McCarty High'}`.length > 25 ? 6 : (`${player.SchoolName || 'Archbishop McCarty High'}`.length > 15 ? 8 : 10)}`}>{player.SchoolName || 'Archbishop McCarty High'}</span>
                            <span className="gray font-10">H.S./Prep</span>
                        </div>

                        <div className="w-100 d-flex flex-column">
                            <span className="black font-10 align-self-end">{entity.PersonalInfoIsNotVisible ? 'N/A' : entity.PhoneNumber}</span>
                            <span className="gray font-10 align-self-end">Phone</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row bg-white p-2">
                        <div className="w-100 d-flex flex-column">
                            <span className="black font-10">{entity.PersonalInfoIsNotVisible ? 'N/A' : entity.EmailAddress}</span>
                            <span className="gray font-10">Email</span>
                        </div>
                    </div>
                </div>)}
        </Animated>
    }
}

export default CollegeProfile;
