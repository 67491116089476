export function fetchLocations(idLeague) {
    return {
        type: 'LOCATIONS/FETCH',
        idLeague
    }
}

export function addLocation(location) {
    return {
        type: 'LOCATIONS/ADD',
        location
    }
}

export function clearLocations() {
    return {
        type: 'LOCATIONS/CLEAR'
    }
}

export function setFields(fields) {
    return {
        type: 'FIELDS/SET', fields
    }
}

export function addField(field) {
    return {
        type: 'FIELDS/ADD', field
    }
}

export function removeField(index) {
    return {
        type: 'FIELDS/REMOVE', index
    }
}

export function updateField(index, name) {
    return {
        type: 'FIELDS/UPDATE', index, name
    }
}