import React from 'react';
import { Animated } from 'react-animated-css';
import { Input } from 'reactstrap';
import ReactHlsPlayer from 'react-hls-player';

class VideoTile extends React.Component {
    state = {
        microTransac: false,
        seasons: []
    }
    componentWillMount() {
        const { video, seasons } = this.props;
        this.setState({ video, seasons });
    }

    selectSeason = e => { }
    render() {
        const { microTransac, video = {}, seasons } = this.state;
        return <div className="tile card mr-4" style={{ width: 280 }}>
            <Animated animationIn="fadeIn" animationInDelay={50} className="w-100 h-100 p-2 d-flex flex-row justify-content-center">
                {video.url && video.url.toLowerCase().indexOf('.mp4') !== -1 && (
                    <video className="align-self-center" style={{ backgroundColor: 'black' }} height="220" width="260" controls>
                        <source src={video.url} type="video/mp4" />
                            Your browser does not support the video tag.
                    </video>
                )}
                {video.url.indexOf('.m3u8') !== -1 && (<ReactHlsPlayer
                    url={video.url}
                    autoplay={false}
                    controls={true}
                    height={240}
                />)}
            </Animated>
            <div className="d-flex flex-column w-100">
                {video.url.indexOf('.mp4') !== -1 && <span className="gray font-8">This video is being processed in the background.</span>}
                <div className="mb-2 ml-2 mr-2" style={{ border: '1px solid gray', borderRadius: 2 }}>
                    <input className="input100" type="text" placeholder="Video Name" ref={(input) => this.txtName = input} />
                </div>
                <div className="mb-2 ml-2 mr-2" style={{ border: '1px solid gray', borderRadius: 2 }}>
                    <Input style={{ border: '0px solid white' }} type="select" value={video.IdSeason} onChange={this.selectSeason}>
                        <option value={null}>Select a Season</option>
                        {seasons && seasons.map((cs, i) => <option key={i} value={cs.IdSeason}>{cs.SeasonName || cs.Name}</option>)}
                    </Input>
                </div>
                <button type="button" className="btn btn-success m-2" >
                    {!microTransac && <span>Save</span>}
                    {microTransac && <i className="fa fa-spin fa-2x fa-cog" />}
                </button>
            </div>
        </div>
    }
}
export default VideoTile;