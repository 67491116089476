import React from 'react';
import ExceptionRule from './ExceptionRule';
import moment from 'moment';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import COLORS from '../../../../../colors';

class TimeExceptions extends React.Component {

  addRule = () => {
    this.props.exceptionRulesActions.addTimeExceptionRule &&
      this.props.exceptionRulesActions.addTimeExceptionRule({
        IdSeason: this.props.params.idSeason,
        Name: 'Time Exception',
        DateStart: moment(),
        DateEnd: moment().add(1, 'hour'),
        TimeStart: moment('01:00', 'HH:mm'),
        TimeEnd: moment('23:50', 'HH:mm'),
        FullDay: false,
        type: 'exception/time'
      });
  }

  render() {
    const { exceptionRules } = this.props;
    exceptionRules.forEach((r, i) => { r.innerIndex = i; });
    const timeRules = _.filter(exceptionRules, function (rule) { return rule.type === 'exception/time'; });
    return (
      <div >
        <Animated animationIn="fadeInRight" className="d-flex flex-row">
          <button style={{ border: `1px solid ${COLORS.jeff_blue}`, borderRadius: 20, color: COLORS.jeff_blue }} 
          className="ml-auto py-2 px-3 align-self-center block" onClick={() => this.addRule()}><i className="fa fa-plus" /> Add Rule</button>
        </Animated>
        <Animated animationIn="fadeIn">
          <ul className="child clearfix exceptions-rule-list text-center" >
            {timeRules && timeRules.map((rule, i) => <ExceptionRule key={i} index={i} rule={rule} exceptionRulesActions={this.props.exceptionRulesActions} />)}
          </ul>
          {!timeRules.length && (
            <div className="jumbotron text-center margin-top">
              <h1 className="display-3">There are no Time Exception rules</h1>
              <p className="lead">Click the plus sign above to create your first Time Exception rule</p>
            </div>)}
        </Animated>

      </div>
    )
  }
}

export default TimeExceptions;