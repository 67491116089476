import React from 'react';
import Header from './common/headers/Header.SQWAD';
import { Animated } from 'react-animated-css';
import fakeAuth from '../fakeAuth';
import FamilyPicker from './FamilyPicker';
import MobileMenu from './MobileMenu';

class Main extends React.Component {

  state = {
    picking_family: false
  }

  componentWillMount = () => {
    if (fakeAuth.isAuthenticated()) {
      this.props.login(fakeAuth.getCredentials());
      this.props.family && this.props.family.length && this.props.familyActions && this.props.familyActions.fetchMembers();
    } else {
      fakeAuth.signout(() => {
        this.props.router.push('/login'); //from.pathname);
      });
    }
  }

  render() {
    return (
      <section>

        <Header {...this.props} fnMenu={() => this.setState({ mobile_menu: true, menu_bootstrapped: true })} onFamily={() => this.setState({ picking_family: !this.state.picking_family })} />

        <section id="main-content" style={{ top: '6em' }}>
          <section id="content-wrapper" className={`hidden`} style={{ backgroundColor: '#ECECEC' }}>
            {React.cloneElement(this.props.children, this.props)}
          </section>
        </section>

        <FamilyPicker {...this.props} open={this.state.picking_family} toggle={() => this.setState({ picking_family: false })} />
        {this.state.mobile_menu && <MobileMenu {...this.props} {...this.state} toggle={() => this.setState({ menu_bootstrapped: false }, () => {
          setTimeout(() => this.setState({ mobile_menu: false }), 350);
        })} />}

      </section>
    );
  }
}

export default Main;
