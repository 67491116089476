import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../../helpers';
import ReactTooltip from 'react-tooltip';
import { findDOMNode } from 'react-dom';
import _ from 'lodash';
import config from '../../../../../config';
import request from 'superagent';

const gameDayRuleSource = {
    beginDrag(props) {
        return props;
    }
};

class GameDayRule extends React.Component {

    state = {
        editMode: false,
        validMinimunGamesPerWeek: true,
        microTransac: false,
        doubleHeadersChecked: false
    }

    toggleEditMode = () => {
        const { editMode } = this.state,
            { readOnly } = this.props;
        if (!readOnly) {
            this.setState({
                editMode: !editMode
            });
        }
    }

    // Lifecycle 
    componentWillMount = () => {
        const { rule } = this.props;
        this.setState({
            editMode: rule.Id ? false : true,
            doubleHeadersChecked: rule.AllowDoubleHeaders ? true : false
        });
    }

    componentDidMount = () => {
        const { editMode } = this.state;
        if (editMode) {
            this.txtRuleName.focus();
            this.txtRuleName.select();
        }
    }
    //

    validateForm = () => {
        const { rule } = this.props;
        var valid = true;

        // Validate at least select one (1) day of the week
        if (!_.find(_.values(rule.Days), function (v) {
            return v === 1;
        })) {
            ReactTooltip.show(findDOMNode(this.refs.foo));
            valid = false;
        } else {
            ReactTooltip.hide(findDOMNode(this.refs.foo));
        }

        // Minimun Games per week must be > 0
        if (this.txtPossibleGamesPerWeek.value <= 0) {
            this.setState({
                validMinimunGamesPerWeek: false
            });
            valid = false;
        } else {
            this.setState({
                validMinimunGamesPerWeek: true
            });
        }

        return valid;
    }

    saveRule = (e) => {
        e.preventDefault();
        const { rule, index } = this.props;
        var data;

        if (this.validateForm()) {

            // Fill the object
            rule.Name = this.txtRuleName.value;
            rule.PossibleGamesPerWeek = this.txtPossibleGamesPerWeek.value;
            rule.AllowDoubleHeaders = this.state.doubleHeadersChecked ? 1 : 0;
            rule.type = 'GameDay';

            this.setState({ editMode: false, microTransac: true });

            if (rule.Id) {

                // Update call API                
                data = _.extend(_.pick(rule, 'Name', 'PossibleGamesPerWeek', 'IdSeason', 'AllowDoubleHeaders'), rule.Days);
                request.patch(`${config.apiEndpoint}/api/v4/schedule/rules/gamedays/${rule.Id}`)
                    .send(data)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        // TODO: Handle possible errors
                        this.setState({ microTransac: false });

                        // Update with redux
                        this.props.gameDayRulesActions &&
                            this.props.gameDayRulesActions.updateGameDayRule(index, rule)
                    });

            } else {

                // Create call API                
                data = _.extend(_.pick(rule, 'Name', 'PossibleGamesPerWeek', 'AllowDoubleHeaders'), rule.Days);
                request.post(`${config.apiEndpoint}/api/v4/schedule/rules/gamedays/${rule.IdSeason}`)
                    .send(data)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        // TODO: Handle possible errors

                        rule.Id = data.body.Id;
                        this.setState({ microTransac: false });

                        // Update with redux                        
                        this.props.gameDayRulesActions &&
                            this.props.gameDayRulesActions.updateGameDayRule(index, rule)
                    });
            }
        }
    }

    deleteRule = () => {

        const { rule } = this.props;
        this.setState({ microTransac: true });

        // Save call API
        request.del(`${config.apiEndpoint}/api/v4/schedule/rules/gamedays/${rule.Id}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
                // TODO: Handle possible errors
                this.setState({ microTransac: false });
                this.props.gameDayRulesActions &&
                    this.props.gameDayRulesActions.deleteGameDayRule(this.props.index);
            });
    }

    cancelRule = () => {
        this.props.gameDayRulesActions &&
            this.props.gameDayRulesActions.deleteGameDayRule(this.props.index);
    }

    toggleDay = (index) => {
        const { rule } = this.props,
            { editMode } = this.state;
        if (editMode) {
            rule.Days[index] = rule.Days[index] ? 0 : 1;
            // TODO: avoid using this. When the rules are on Redux, this will update automatically
            this.forceUpdate();
        }
    }

    toggleDoubleHeaders = () => this.setState({ doubleHeadersChecked: !this.state.doubleHeadersChecked });

    render() {
        const { index, rule, connectDragSource, isDragging, connectDragPreview, readOnly } = this.props,
            { editMode, validMinimunGamesPerWeek, microTransac } = this.state;

        return connectDragPreview(connectDragSource(
            <li className={`game-day-rule d-flex flex-column card ${isDragging ? 'dragging' : ''}`} style={{ width: 350, borderRadius: 5, overflow: 'hidden' }}>
                {!editMode && (
                    <section style={{ backgroundColor: '#008db5' }} className="d-flex flex-row header white">
                        <button className="btn btn-link mr-auto align-self-center" onClick={this.toggleEditMode}>
                            {rule.Name}
                        </button>
                        {!microTransac && !readOnly && <button className="btn btn-link btn-sm" onClick={this.toggleEditMode}><i className="fas fa-pen"></i></button>}
                        {!microTransac && !readOnly && <button className="btn btn-link btn-sm" onClick={this.deleteRule}><i className="fa fa-times"></i></button>}
                        {microTransac && !readOnly &&
                            <button className="btn-link align-self-center btn ">
                                <i className="fa fa-spin fa-2x fa-hourglass-o font-12" />
                            </button>}
                    </section>)}
                {editMode && (
                    <form style={{ backgroundColor: '#008db5' }} className="d-flex flex-row header white">
                        <input type="text" defaultValue={rule.Name} ref={(input) => {
                            this.txtRuleName = input;
                        }} className="form-control align-self-center control-sm" placeholder="Name of the rule"
                        />
                        {!rule.Id && <button type="button" className="btn btn-danger btn-sm" onClick={this.cancelRule}>Cancel</button>}
                        <button type="submit" className="btn btn-success btn-sm" onClick={this.saveRule}>Save</button>
                    </form>
                )}
                <div className="d-flex flex-row justify-content-center">
                    {editMode && <div className="col-3 no-margin no-padding">
                        <input data-tip="This cannot be zero (0)" data-for={`${index}-tooltip`} className={`form-control align-self-center text-center ${validMinimunGamesPerWeek ? '' : 'bg-danger'}`} type="number" min="0" defaultValue={rule.PossibleGamesPerWeek} ref={(input) => {
                            this.txtPossibleGamesPerWeek = input;
                        }}
                        />
                    </div>}
                    <div className={`${editMode ? 'col-9' : 'col-12 text-center'} no-margin no-padding align-self-center`}>
                        <button className="black btn btn-link">
                            {!editMode ? rule.PossibleGamesPerWeek : ''} Min. Games per week - on</button>
                    </div>
                </div>
                <div ref='foo' data-tip="Select at least one day of the week" data-for={`${index}-gameday-week-days-tooltip`} className=" d-flex flex-row justify-content-center">
                    <button onClick={() => this.toggleDay("Sunday")} className={`btn btn-sm btn-${rule.Days.Sunday ? 'success' : 'default'} day-button`}>S</button>
                    <button onClick={() => this.toggleDay("Monday")} className={`btn btn-sm btn-${rule.Days.Monday ? 'success' : 'default'} day-button`}>M</button>
                    <button onClick={() => this.toggleDay("Tuesday")} className={`btn btn-sm btn-${rule.Days.Tuesday ? 'success' : 'default'} day-button`}>T</button>
                    <button onClick={() => this.toggleDay("Wednesday")} className={`btn btn-sm btn-${rule.Days.Wednesday ? 'success' : 'default'} day-button`}>W</button>
                    <button onClick={() => this.toggleDay("Thursday")} className={`btn btn-sm btn-${rule.Days.Thursday ? 'success' : 'default'} day-button`}>T</button>
                    <button onClick={() => this.toggleDay("Friday")} className={`btn btn-sm btn-${rule.Days.Friday ? 'success' : 'default'} day-button`}>F</button>
                    <button onClick={() => this.toggleDay("Saturday")} className={`btn btn-sm btn-${rule.Days.Saturday ? 'success' : 'default'} day-button`}>S</button>
                </div>
                {editMode && <ReactTooltip effect="solid" place="right" id={`${index}-gameday-week-days-tooltip`} />}
                {editMode && <ReactTooltip effect="solid" place="left" id={`${index}-tooltip`} />}
                <div className="d-flex flex-row justify-content-center check-wrapper black">
                    {editMode && <input className="align-self-center" type="checkbox" defaultValue={this.state.doubleHeadersChecked} onChange={this.toggleDoubleHeaders} checked={this.state.doubleHeadersChecked} />}
                    <span>{!editMode ? (rule.AllowDoubleHeaders ? 'With ' : 'Without ') : ' '}Double Header</span>
                </div>
                {!editMode && !readOnly && connectDragSource(<span className="fas fa-grip-vertical gray font-14 grab" style={{ position: 'absolute', bottom: 4, left: 4 }} />)}
            </li>
        ))
    }
}

export default flow(
    DragSource(ItemTypes.GAMEDAYRULE, gameDayRuleSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }))
)(GameDayRule);
