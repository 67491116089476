import React from 'react';

class Comp extends React.Component {

  state = {
    plus: false, //always a discount
    due: this.props.due
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ due: nextProps.due });
  }

  handleChangeBase = (e) => {
    const { index, due } = this.props;
    due.base = e.target.value * -1;
    this.props.paymentsActions && this.props.paymentsActions.updateDue(due, index);
  }
  handleChangeChild = (e) => {
    const { index, due } = this.props;
    due.child = e.target.value;
    this.props.paymentsActions && this.props.paymentsActions.updateDue(due, index);
  }

  remove = () => {
    const { index, due } = this.props;

    // If the item has an Id, call the api to remove from the database
    if (due.IdCatalogItemPriceAdjustmentByItemIndex) {
      this.props.paymentsActions &&
        this.props.paymentsActions.deleteFamilyIndex(due.IdCatalogItemPriceAdjustmentByItemIndex);
    }
    this.props.paymentsActions &&
      this.props.paymentsActions.removeDue(index);
  }

  render() {
    const { due } = this.state;

    return <div className="margin-top-half container">
      <div className="row">
        <div className="col-12 d-flex flex-row no-padding">
          <span className="align-self-center idented-half">Discount</span>
          <div className="d-flex flex-row align-self-center">
            <div key={due.id} className="input-group idented-half" style={{ width: 180 }}>
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <div className="input-group-prepend">
                <span className={`input-group-text bg-${this.state.plus ? 'success' : 'danger'}`}><i className={`fas fa-${this.state.plus ? 'plus' : 'minus'}`} /></span>
              </div>
              <input onChange={this.handleChangeBase} defaultValue={due.base} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
            </div>
          </div>
          <span className="align-self-center idented margin-right-half">starting at child #</span>
          <input style={{ width: 100 }} onChange={this.handleChangeChild} defaultValue={due.child} type="number" className="form-control align-self-center" aria-label="Amount (to the nearest dollar)" />
          <button onClick={this.remove} className="btn btn-sm align-self-center ml-auto btn-link"><i className="fa fa-times red" /></button>
        </div>
        <div className="col-6 d-flex flex-row"></div>
      </div>

    </div>
  }
}

export default Comp;
