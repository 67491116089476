import React from 'react';
import Header from './common/headers/Header.Admin';
import Nav from './navigation/Nav.User';
import fakeAuth from '../fakeAuth';
import DOBMenu from './menu/Alphabet';
import Spotlight from './common/modals/Spotlight';

class Main extends React.Component {

  state = {
    menuHidden: false,
    section: null
  }

  componentWillMount() {

    this.setState({
      section: this.props.route.path
    });

    if (fakeAuth.isAuthenticated()) {
      this.props.login &&
        this.props.login(fakeAuth.getCredentials());

    } else {
      fakeAuth.signout(() => {
        this.props.router.push('/login'); //from.pathname);
      });
    }

  }

  toggleSpotlight = () => {
    const { spotlightOn } = this.props;
    this.props.spotlightActions &&
      this.props.spotlightActions[spotlightOn ? 'close' : 'open']();
  }

  render() {

    const { menuHidden, section } = this.state,
      { spotlightOn } = this.props;

    return (

      <section>
        <Header {...this.props} />
        <Nav />
        <section id="main-content">
          <DOBMenu {...this.props} />
          <section id="content-wrapper" style={{ left: menuHidden ? 0 : '12em' }} className={`${menuHidden ? 'hidden' : ''}`}>
            {React.cloneElement(this.props.children, this.props)}
          </section>
        </section>
        {spotlightOn && <Spotlight isOpen={spotlightOn} toggle={this.toggleSpotlight} {...this.props} />}
      </section>

    );
  }
}

export default Main;