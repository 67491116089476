function reducer(state = [], action) {

    switch (action.type) {
        case 'PLAYERS/SET':
            return action.players;
        case 'PLAYERS/UPDATE':
            return [...state.slice(0, action.index), action.player, ...state.slice(action.index + 1)];
        case 'PLAYERS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default reducer;