import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux-sagas/actions/leadsActions';
import Leads from '../components/leads/index';

function mapStateToProps(state) {
    return {
        leads: state.leads
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch)
}

const App = connect(mapStateToProps, mapDispatchToProps)(Leads);

export default App;
