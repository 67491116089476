import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import _ from 'lodash';
import FieldName from './FieldName';
import request from 'superagent';
import config from '../../../../../config.js';

class AddLocationModal extends React.Component {

  state = {
    countryOpened: false,
    stateOpened: false,
    location: {},
    saving: false,
    error: null,
    selected: {
      country: null,
      state: null
    },
    fields: null
  }

  // Lifecycle
  componentWillMount() {
    const { loc, countries } = this.props;

    if (loc.IdLocation) {
      this.selectCountry(_.find(countries, (c) => { return c.IdCountry === loc.IdCountry; }));
    } else {
      this.selectCountry(_.find(countries, (c) => { return c.ISOCode === 'US' }));
    }
    this.props.locationsActions &&
      this.props.locationsActions.setFields(loc.fields || loc.Fields);
  }
  componentWillReceiveProps = (nextProps) => {
    const { selected } = this.state,
      { loc } = this.props;

    if (nextProps.states && loc.IdLocation && !selected.state) {
      selected.state = _.find(nextProps.states, (s) => {
        return s.IdState === loc.IdState;
      });
      if (selected.state) {
        this.setState({
          selected
        });
      }
    }
  }
  //

  toggleCountry = () => {
    this.setState({
      countryOpened: !this.state.countryOpened
    });
  }

  toggleState = () => {
    this.setState(prevState => ({
      stateOpened: !prevState.stateOpened
    }));
  }

  selectCountry = (c) => {
    const { selected } = this.state;
    selected.country = c;

    this.props.geoActions && this.props.geoActions.clearStates && this.props.geoActions.clearStates();
    this.props.geoActions && this.props.geoActions.fetchStates && this.props.geoActions.fetchStates(c.IdCountry);
    this.setState({
      selected
    });
  }

  selectState = (s) => {
    const { selected } = this.state;
    selected.state = s;
    this.setState({
      selected
    });
  }

  addField = () => {
    this.props.locationsActions &&
      this.props.locationsActions.addField({});
  }

  removeField = (index) => {
    this.props.locationsActions &&
      this.props.locationsActions.removeField(index);
  }

  save = () => {
    if (this.state.saving) return;
    const { fields, loc } = this.props;
    // Validate
    this.setState({
      saving: true
    }, () => {
      // Call API
      if (loc.IdLocation) {

        request.patch(`${config.apiEndpoint}/api/v4/locations`)
          .send({
            idLocation: loc.IdLocation,
            name: this.txtLocationName.value,
            idLeague: this.props.season.IdLeague,
            idCommunity: this.props.season.IdCommunity,
            idState: this.state.selected.state.IdState,
            address: this.txtLine1.value,
            city: this.txtCity.value,
            zipCode: this.txtZipCode.value,
            fields: fields
          })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then(data => {
            if (data.body.success) {
              // TODO: call action for update locations on the store
              this.props.locationsActions && this.props.locationsActions.fetchLocations(this.props.season.IdLeague);
              this.props.toggle && this.props.toggle();
            }
          }, (reject) => {
            this.setState({
              saving: false,
              error: reject
            });
          });

      } else {

        request.post(`${config.apiEndpoint}/api/v4/locations`)
          .send({
            name: this.txtLocationName.value,
            idLeague: this.props.season.IdLeague,
            idCommunity: this.props.season.IdCommunity,
            idState: this.state.selected.state.IdState,
            address: this.txtLine1.value,
            city: this.txtCity.value,
            zipCode: this.txtZipCode.value,
            fields: fields
          })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then(data => {
            if (data.body.success) {
              // TODO: call action for update locations on the store
              this.props.locationsActions && this.props.locationsActions.fetchLocations(this.props.season.IdLeague);
              this.props.toggle && this.props.toggle();
            }
          }, (reject) => {
            this.setState({
              saving: false,
              error: reject
            });
          });

      }


    });

  }

  render() {
    const { isOpen, toggle, countries, states, fields, loc } = this.props,
      { selected } = this.state;

    // To set US and Canada first on the Countries selection
    let splitCountries = _.partition(countries, function (c) {
      return c.ISOCode === 'US' || c.ISOCode === 'CA';
    });

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="w-500">
        <ModalHeader toggle={toggle} className="bg-blue-dark white">
          Location
        </ModalHeader>
        <ModalBody>
          <div className="row no-padding no-margin">
            <div className="col-12">
              <FormGroup className="margin-top-half">
                <Label>Name</Label>
                <input type="text" defaultValue={loc.Name} ref={(input) => {
                  this.txtLocationName = input;
                }} className="form-control" placeholder="Name of the Location" />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup>
                <Label>Address Line</Label>
                <input type="text" defaultValue={loc.Line1} ref={(input) => {
                  this.txtLine1 = input;
                }} className="form-control" placeholder="Address Line" />
              </FormGroup>
              <div className="container-fluid no-margin no-padding">
                <div className="row no-margin no-padding">
                  <div className="col-6 no-margin no-padding">
                    <FormGroup className="margin-right-half">
                      <Label>Country</Label>
                      <Dropdown isOpen={this.state.countryOpened} toggle={this.toggleCountry} className="form-control no-padding" style={{ borderColor: 'transparent' }}>
                        <DropdownToggle className="bg-white black btn-block text-left w-100" caret style={{ borderColor: '#ced4da' }}>
                          {selected.country ? selected.country.CountryName : 'Please select'}
                        </DropdownToggle>
                        <DropdownMenu>
                          {splitCountries.length >= 1 && splitCountries[0].map((c) => (
                            <DropdownItem key={c.IdCountry} onClick={() => this.selectCountry(c)}>
                              {c.CountryName || c.Name}
                            </DropdownItem>))}
                          <DropdownItem divider />
                          {splitCountries.length > 1 && splitCountries[1].map((c) => (
                            <DropdownItem key={c.IdCountry} onClick={() => this.selectCountry(c)}>
                              {c.CountryName || c.Name}
                            </DropdownItem>))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                    <FormGroup className="margin-right-half">
                      <Label>City</Label>
                      <input type="text" defaultValue={loc.City} ref={(input) => {
                        this.txtCity = input;
                      }} className="form-control" placeholder="City" />
                    </FormGroup>
                  </div>
                  <div className="col-6 no-margin no-padding">
                    <FormGroup className="idented-half">
                      <Label>State | Provice</Label>
                      <Dropdown isOpen={this.state.stateOpened} toggle={this.toggleState} className="form-control no-padding" style={{ borderColor: 'transparent' }}>
                        <DropdownToggle className="bg-white black btn-block text-left w-100" caret style={{ borderColor: '#ced4da' }}>
                          {selected.state ? selected.state.StateName + ' (' + selected.state.StateCode + ')' : 'Please select'}
                        </DropdownToggle>
                        <DropdownMenu>
                          {states.length >= 1 && states.map((s) => (
                            <DropdownItem key={s.IdState} onClick={() => this.selectState(s)}>
                              {s.StateName}
                              {' ('}
                              {s.StateCode}
                              {') '}</DropdownItem>))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                    <FormGroup className="idented-half">
                      <Label>Zip Code</Label>
                      <input type="text" defaultValue={loc.ZipCode} ref={(input) => {
                        this.txtZipCode = input;
                      }} className="form-control" placeholder="Zip Code" />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="black">Fields</h2>
              <div className="mt-2">
                {fields && fields.map((field, i) => (
                  <FieldName field={field} key={i} index={i} fnRemove={this.removeField} locationsActions={this.props.locationsActions} />
                ))}
                <button className="w-100 align-self-center green pl-3 pr-3 mb-2" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} onClick={this.addField}><i className="fa fa-plus green" />
                  {' '}Add Field</button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="align-self-center green pl-3 pr-3 mb-2" style={{ border: '2px solid #2cad3c', borderRadius: 20, height: 40 }} onClick={this.save}>
            {this.state.saving ? 'Saving....' : 'Save'}
          </button>
        </ModalFooter>
      </Modal >
    )
  }
}

export default AddLocationModal;
