import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import FamilyMember from './entity';
import { Input, Popover, PopoverBody } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Comp extends React.Component {
  state = {
    phone: null, editing: false, deleting: false, selected: {}
  }

  // Lifecycle
  componentWillMount() {
    this.setState({ phone: this.props.phone, editing: this.props.phone.IdPhoneNumber ? false : true, country_codes: this.props.country_codes }, () => {
      const { phone, selected, country_codes } = this.state;

      // NEW Phone number handling
      selected.country_code = _.find([...country_codes.northAmericanCountries,
      ...country_codes.southAmericanCountries,
      ...country_codes.centralAmericanCountries,
      ...country_codes.europeanCountries,
      ...country_codes.africanCountries,
      ...country_codes.asianCountries,
      ...country_codes.oceaniaCountries], c => c.countryCode === phone.CountryCode) || _.first(country_codes.northAmericanCountries);
      selected.mask = _.chain(selected.country_code.phoneNumberMask)
        .map(a => {
          switch (a) {
            case '/[2-9]/': return /[2-9]/;
            case '/[1-9]/': return /[1-9]/;
            case '/[0-9]/': return /[0-9]/;
            case '/[5-9]/': return /[5-9]/;
            case '/[2-5]/': return /[2-5]/;
            case '/[2-6]/': return /[2-6]/;
            case '/\d/': return /\d/;
            default: return a;
          }
        }).value();

      this.setState({ selected });
    });
  }
  componentWillReceiveProps = newProps => this.setState({ phone: newProps.phone });

  componentDidUpdate = () => {
    if (this.txtPhone) {
      ReactDOM.findDOMNode(this.txtPhone).focus();
      ReactDOM.findDOMNode(this.txtPhone).select();
    }
  }
  componentDidMount() {
    ReactDOM.findDOMNode(this.txtPhone)
      && ReactDOM.findDOMNode(this.txtPhone).focus();
  }
  //

  toggleEdit = () => this.setState(prevState => ({ editing: !prevState.editing }));
  toggleDelete = () => this.setState(prevState => ({ deleting: !prevState.deleting }));

  saveNumber = e => {
    e.preventDefault && e.preventDefault();

    const { phone, selected } = this.state, { familyActions } = this.props;
    phone.PhoneNumber = ReactDOM.findDOMNode(this.txtPhone).value;
    phone.CountryCode = selected.country_code.countryCode;

    if (phone.IdPhoneNumber) {
      familyActions && familyActions.updatePhone(phone, ReactDOM.findDOMNode(this.txtPhone).value.match(/\d+/g).map(Number).join(''))
    } else {
      phone.IdPhoneNumber = ReactDOM.findDOMNode(this.txtPhone).value.match(/\d+/g).map(Number).join('');
      familyActions && familyActions.savePhone(phone);
    }
    // TODO:


    this.setState({ phone });
    this.toggleEdit();
  }

  selectCarrier = e => {
    const { carriers } = this.props, { phone } = this.state;
    const carrier = _.find(carriers, carrier => carrier.IdCarrier === parseInt(e.target.value, 10));
    if (carrier) {
      phone.CarrierName = carrier.Name || null;
      phone.IdCarrier = carrier ? carrier.IdCarrier : 0;
    } else {
      phone.CarrierName = null;
      phone.IdCarrier = null;
    }
    this.setState({ phone });
  }

  doDelete = e => {
    const { phone } = this.state;
    this.props.familyActions.deletePhone(phone.IdPhoneNumber);
    this.toggleDelete();
  }

  render() {
    const { phone, editing, country_codes, selected } = this.state,
      { family, carriers } = this.props;    

    return (
      <div className={`card tile shadow-box p-2 margin-bottom-half`} style={{ position: 'relative' }}>

        {!editing && <span className="font-16 margin-bottom-half bg-darker-blue white p-2 d-flex flex-row">
          {phone.CanDelete === false && <i className="fas fa-lock margin-right-half  align-self-center" />}
          <span className="align-self-center margin-right-half">{phone.PhoneNumber_Format}</span>
          {phone.IdPhoneNumber && phone.CanDelete && <button onClick={this.toggleEdit} className="btn btn-link btn-sm ml-auto"><i className="fas fa-pen" /></button>}
          {phone.IdPhoneNumber && phone.CanDelete === true && <button id={`btnDeletePhone-${phone.IdPhoneNumber}`} onClick={this.toggleDelete} className="btn btn-link btn-sm white"><i className="fa fa-times" /></button>}
          {phone.IdPhoneNumber && phone.CanDelete === true && <Popover placement="bottom" isOpen={this.state.deleting} target={`btnDeletePhone-${phone.IdPhoneNumber}`} toggle={this.toggleDelete}>
            <PopoverBody className="d-flex flex-column justify-content-center">
              <span className="align-self-center">Are you sure?</span>
              <button className="btn btn-sm btn-danger" onClick={this.toggleDelete}>No, cancel</button>
              <button className="btn btn-sm btn-success" onClick={this.doDelete}>Yes, delete it!</button>
            </PopoverBody>
          </Popover>}
        </span>}

        {editing && <form>
          <div className="input-group margin-bottom-half">


            { /* Phone */}
            {country_codes && <div className={`p-0 margin-bottom-half col-12 d-flex flex-row justify-content-center`}>

              <Dropdown isOpen={this.state.isCCO ? true : false} toggle={() => this.setState({ isCCO: !this.state.isCCO })} className="form-control font-16 no-padding bg-white black w-50">
                <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block h-100 text-left no-border d-flex flex-row">
                  <img src={selected.country_code ? `https://api.sportslogic.net/images/flags/${selected.country_code.countryISOCode}.png` : ''} alt="" className="h-50 align-self-center mr-1" />
                  <span className="black align-self-center font-16">{selected.country_code ? selected.country_code.countryCode : 'Code'}</span>
                  <i className="ml-auto fas fa-caret-down align-self-center black font-16" />
                </DropdownToggle>
                <DropdownMenu>

                  <DropdownItem header>North America</DropdownItem>
                  <DropdownItem divider />
                  {country_codes.northAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                    const { selected } = this.state;
                    selected.country_code = c;
                    selected.mask = _.chain(c.phoneNumberMask)
                      .map(a => {
                        switch (a) {
                          case '/[2-9]/': return /[2-9]/;
                          case '/[1-9]/': return /[1-9]/;
                          case '/[0-9]/': return /[0-9]/;
                          case '/[5-9]/': return /[5-9]/;
                          case '/[2-5]/': return /[2-5]/;
                          case '/[2-6]/': return /[2-6]/;
                          case '/\d/': return /\d/;
                          default: return a;
                        }
                      }).value();
                    this.setState({ selected });
                  }}>
                    <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                  </DropdownItem>)}

                  <DropdownItem header>South America</DropdownItem>
                  <DropdownItem divider />
                  {country_codes.southAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                    const { selected } = this.state;
                    selected.country_code = c;
                    selected.mask = _.chain(c.phoneNumberMask)
                      .map(a => {
                        switch (a) {
                          case '/[2-9]/': return /[2-9]/;
                          case '/[1-9]/': return /[1-9]/;
                          case '/[0-9]/': return /[0-9]/;
                          case '/[5-9]/': return /[5-9]/;
                          case '/[2-5]/': return /[2-5]/;
                          case '/[2-6]/': return /[2-6]/;
                          case '/\d/': return /\d/;
                          default: return a;
                        }
                      }).value();
                    this.setState({ selected });
                  }}>
                    <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                  </DropdownItem>)}

                  <DropdownItem header>America Central</DropdownItem>
                  <DropdownItem divider />
                  {country_codes.centralAmericanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                    const { selected } = this.state;
                    selected.country_code = c;
                    selected.mask = _.chain(c.phoneNumberMask)
                      .map(a => {
                        switch (a) {
                          case '/[2-9]/': return /[2-9]/;
                          case '/[1-9]/': return /[1-9]/;
                          case '/[0-9]/': return /[0-9]/;
                          case '/[5-9]/': return /[5-9]/;
                          case '/[2-5]/': return /[2-5]/;
                          case '/[2-6]/': return /[2-6]/;
                          case '/\d/': return /\d/;
                          default: return a;
                        }
                      }).value();
                    this.setState({ selected });
                  }}>
                    <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                  </DropdownItem>)}

                  <DropdownItem header>Europe</DropdownItem>
                  <DropdownItem divider />
                  {country_codes.europeanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                    const { selected } = this.state;
                    selected.country_code = c;
                    selected.mask = _.chain(c.phoneNumberMask)
                      .map(a => {
                        switch (a) {
                          case '/[2-9]/': return /[2-9]/;
                          case '/[1-9]/': return /[1-9]/;
                          case '/[0-9]/': return /[0-9]/;
                          case '/[5-9]/': return /[5-9]/;
                          case '/[2-5]/': return /[2-5]/;
                          case '/[2-6]/': return /[2-6]/;
                          case '/\d/': return /\d/;
                          default: return a;
                        }
                      }).value();
                    this.setState({ selected });
                  }}>
                    <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                  </DropdownItem>)}

                  <DropdownItem header>Africa</DropdownItem>
                  <DropdownItem divider />
                  {country_codes.africanCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                    const { selected } = this.state;
                    selected.country_code = c;
                    selected.mask = _.chain(c.phoneNumberMask)
                      .map(a => {
                        switch (a) {
                          case '/[2-9]/': return /[2-9]/;
                          case '/[1-9]/': return /[1-9]/;
                          case '/[0-9]/': return /[0-9]/;
                          case '/[5-9]/': return /[5-9]/;
                          case '/[2-5]/': return /[2-5]/;
                          case '/[2-6]/': return /[2-6]/;
                          case '/\d/': return /\d/;
                          default: return a;
                        }
                      }).value();
                    this.setState({ selected });
                  }}>
                    <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                  </DropdownItem>)}

                  <DropdownItem header>Asia</DropdownItem>
                  <DropdownItem divider />
                  {country_codes.asianCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                    const { selected } = this.state;
                    selected.country_code = c;
                    selected.mask = _.chain(c.phoneNumberMask)
                      .map(a => {
                        switch (a) {
                          case '/[2-9]/': return /[2-9]/;
                          case '/[1-9]/': return /[1-9]/;
                          case '/[0-9]/': return /[0-9]/;
                          case '/[5-9]/': return /[5-9]/;
                          case '/[2-5]/': return /[2-5]/;
                          case '/[2-6]/': return /[2-6]/;
                          case '/\d/': return /\d/;
                          default: return a;
                        }
                      }).value();
                    this.setState({ selected });
                  }}>
                    <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                  </DropdownItem>)}

                  <DropdownItem header>Oceania</DropdownItem>
                  <DropdownItem divider />
                  {country_codes.oceaniaCountries.map((c, i) => <DropdownItem key={i} onClick={() => {
                    const { selected } = this.state;
                    selected.country_code = c;
                    selected.mask = _.chain(c.phoneNumberMask)
                      .map(a => {
                        switch (a) {
                          case '/[2-9]/': return /[2-9]/;
                          case '/[1-9]/': return /[1-9]/;
                          case '/[0-9]/': return /[0-9]/;
                          case '/[5-9]/': return /[5-9]/;
                          case '/[2-5]/': return /[2-5]/;
                          case '/[2-6]/': return /[2-6]/;
                          case '/\d/': return /\d/;
                          default: return a;
                        }
                      }).value();
                    this.setState({ selected });
                  }}>
                    <img src={`https://api.sportslogic.net/images/flags/${c.countryISOCode}.png`} alt="" style={{ height: 15 }} className="align-self-center mr-1" /> {c.countryName}
                  </DropdownItem>)}

                </DropdownMenu>
              </Dropdown>

              {selected.country_code && <MaskedInput guide={true} ref={(i) => this.txtPhone = i} className="form-control p-2 font-16 w-100" placeholder="Phone Number (*)" defaultValue={phone.PhoneNumber}
                mask={selected.mask} />}

              <button onClick={this.saveNumber} className="btn btn-success" type="submit"><i className="fa fa-check" /></button>

            </div>}
          </div>
        </form>}

        <div className={`clearfix ${phone.IdPhoneNumber ? '' : 'transparent'}`}>
          {phone.IdPhoneNumber && family && family.map && family.map((user, i) =>
            <div className="float-left margin-right-half margin-bottom-half" key={i}>
              <FamilyMember phone IdPhoneNumber={phone.IdPhoneNumber} familyActions={this.props.familyActions} entity={user}
                isActive={_.find(phone.users, p => { return p.IdUser === user.IdUser; })} />
            </div>)}
        </div>
      </div>)
  }
}

export default Comp;
