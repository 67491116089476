export function fetch(idDivision) {
    return { type: 'TRYOUTS/FETCH', idDivision }
}
export function accept(item, index) {
    return { type: 'TRYOUTS/ACCEPT', item, index }
}
export function returnToTryout(item, index, idDivision) {
    return { type: 'TRYOUTS/RETURN', item, index, idDivision }
}
export function cleanup() {
    return { type: 'TRYOUTS/CLEAN' }
}