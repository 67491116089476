import React from 'react';
import Switch from "react-switch";
import { Link } from 'react-router';
import request from 'superagent';
import config from '../../../config';
import _ from 'lodash';

class UnsubscribeEmail extends React.Component {
    state = {
        step: 1
    }
    componentWillMount() {
        const { params, familyActions } = this.props, { idFamily, idMessage } = params;
        familyActions && familyActions.getEmailsToUnsubscribe(idFamily, idMessage);
    }
    componentWillReceiveProps = nextProps => {
        const { emails } = nextProps;
        this.setState({ emails });
    }
    toggle = (index) => {
        const { emails } = this.state;
        let target = emails[index];
        target.checked = !target.checked;
        this.setState({ emails });
    }
    save = () => {
        this.setState({ step: 2 });        
        request.post(`${config.apiEndpoint}/api/v4/messages/unsubscribe`)
            .send({ emails: _.filter(this.state.emails, e => !e.checked) })
            .then((data) => { });
    }
    render() {
        const { emails = [], step } = this.state;
        return <section className="viewport">
            <div className="d-flex flex-row justify-content-center h-100">
                {step === 1 && <div className="align-self-center d-flex flex-column">
                    <img alt="" src="/images/logos/logo_white.png" style={{ maxHeight: 200, maxWidth: 200 }} className="align-self-center" />
                    <p className="white font-10 mt-4 line1" style={{ maxWidth: 350 }}>You've reached this page because you want to opt some of your account's
                    email out of out automatic messaging system. Feel free to switch off any email and click Save Changes</p>
                    <div>
                        {emails && emails.map((email, i) => <div className="w-100" key={i}>
                            <label className="d-flex flex-row">
                                <Switch onChange={() => this.toggle(i)} checked={email.checked} />
                                <span className="align-self-center margin-left-half">{email.email}</span>
                            </label>
                        </div>)}
                        <button className="btn btn-success btn-block margin-top" onClick={this.save}>Save Changes</button>
                    </div>
                    <p className="white font-8 margin-top line1" style={{ maxWidth: 300 }}>(If you did not performed this action, please discard this page and click <Link to="/login" className="blue">here</Link>)</p>
                </div>}
                {step === 2 && <div className="d-flex flex-column align-self-center">
                    <Link to="/login"><img alt="" src="/images/logo-white.png" style={{ maxHeight: 200, maxWidth: 200 }} className="align-self-center" /></Link>
                    <i className="green fas fa-check align-self-center" style={{ fontSize: '6em', marginTop: '1em' }} />
                    <span className="white font-18 align-self-center">Success</span>
                </div>}
            </div>
        </section>
    }
}

export default UnsubscribeEmail;