import {
    bindActionCreators
} from 'redux';
import {
    connect
} from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import Main from '../components/disclaimers/privacy';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
    }, bindActionCreators(actionCreators, dispatch))
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;