import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes, formatPrice } from '../../../../../../helpers';

class FamilyAdjustmentCard extends React.Component {
  renderTheThing = () => {
    const { isDragging, program, unmovable, fnEdit, fnDelete } = this.props;
    return <li style={{ marginRight: unmovable ? 0 : '1em' }} className={`program-card bg-success p-4 d-flex flex-column ${isDragging ? 'dragging' : ''} ${unmovable ? 'w-100' : ''}`}>
      <span className="white font-16 text-left toBold">{program.Name}</span>
      <hr />
      {program.items.map((item, i) => <div className='d-flex flex-row' key={i}>
        <span className="white">Starting at Registrant #{item.ItemIndex}</span>
        <span className="ml-auto white">{formatPrice(item.Amount * 100)}</span>
      </div>)}
      
      {!unmovable && <button className="white font-8 pointer hint" style={{ padding: '0 0.5em', borderRadius: 10, border: '1px solid white', height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnEdit(program)}>
        <i className="fas fa-edit" />{' '}Edit</button>}

      {!unmovable && <span className="fas fa-grip-vertical white font-14 grab" style={{ position: 'absolute', top: 2, left: 4 }} />}

      {fnDelete && <button className="white font-8 pointer btn-danger" style={{ padding: '0 0.5em', borderRadius: 10, height: 20, position: 'absolute', top: 5, right: 5 }} onClick={() => fnDelete()}>
        <i className="fas fa-trash" />{' '}Remove</button>}

    </li>
  }
  render() {
    const { connectDragSource, unmovable } = this.props;
    if (unmovable) return this.renderTheThing();
    else return connectDragSource(this.renderTheThing());
  }
}

export default flow(
  DragSource(ItemTypes.FAMILYADJUSTMENT, {
    beginDrag(props) {
      return props;
    }
  }, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))
)(FamilyAdjustmentCard);
