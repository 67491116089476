import React from 'react';
import { DragSource } from 'react-dnd';
import flow from 'lodash/flow';
import { ItemTypes } from '../../../../helpers';

const flightSource = {
    beginDrag(props) { return props; }
}

class FlightPlacementDraggable extends React.Component {
    render() {
        const { connectDragSource, connectDragPreview, index, flight, placements = [] } = this.props;

        const selected = placements.indexOf(`# ${index} in ${flight.FlightName || flight.Name}`) !== -1;
        return connectDragPreview(connectDragSource(
            <li className="placement-item d-flex flex-row">
                {selected && <i className="fas fa-check green margin-right-half" />}
                <span className="align-self-center font-10">{`# ${index} in ${flight.FlightName || flight.Name}`}</span>
                <i className="align-self-center ml-auto fas fa-arrows-alt font-8 blue"></i>
            </li>)
        )
    }
}

export default flow(
    DragSource(ItemTypes.FLIGHT, flightSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }))
)(FlightPlacementDraggable)