import React from 'react';
import { Link } from 'react-router';
import Loader from '../../../common/Loader';
import DivisionListItem from './item';
import { Jumbotron } from 'reactstrap';
import _ from 'lodash';
import { Animated } from 'react-animated-css';
import config from '../../../../config';

import './css.css';

class Divisions extends React.Component {

  state = {
    mode: parseInt(localStorage.getItem('app:divisions:mode') || 1, 10)
  }

  // Lifecycle
  componentWillMount = () => {
    const { params, mode } = this.props;
    this.setState({ mode });
    this.props.fetchDivisionsSummary && this.props.fetchDivisionsSummary(params.idSeason);
  }
  componentWillReceiveProps = nextProps => {
    const { season, mode } = nextProps;
    this.setState({ mode: mode || this.state.mode || ([3, 5].indexOf(season.IdSeasonType) !== -1 ? parseInt(localStorage.getItem('app:divisions:mode') || 2, 10) : 1) })
  }
  //

  selectDivision = division => {
    const { fnSelectDivision } = this.props;
    fnSelectDivision && fnSelectDivision(division, this.state.mode);
  }

  onMode = mode => {
    if (mode !== 3)
      localStorage.setItem('app:divisions:mode', mode);
    this.setState({ mode });
  }

  renderList = () => {
    const { genders } = this.props, { search } = this.state;
    return <div className="d-flex flex-column w-100" >
      {Object.keys(genders).map(gender => (
        <div key={gender} className={`w-100 no-margin no-padding gender-col`}>
          <h3 className={`bg-gray-darkish black font-12 text-left division-title no-margin pl-3 sqwad-font toUpper`}>{gender}</h3>
          <ul className="division-list">
            {genders[gender] && _.filter(genders[gender], division => {
              if (search) return (division.Name || division.DivisionName).toLowerCase().indexOf(search.toLowerCase()) !== -1;
              return true;
            }).map((division, index) => (
              <DivisionListItem mode={this.state.mode} season={this.props.season} key={division.IdDivision} index={index} division={division} fnSelect={this.selectDivision} />
            ))}
          </ul>
        </div>
      ))}
    </div>
  }

  renderScouts = () => {
    const { scout_registrations } = this.props, { search } = this.state;

    return <div className="d-flex flex-column w-100">
      <div className={`w-100 no-margin no-padding gender-col`}>
        <ul className="division-list">
          {_.filter(scout_registrations, i => {
            if (search) return (`${i.NameFirst}-${i.NameLast}-${i.SchoolName}`).toLowerCase().indexOf(search.toLowerCase()) !== -1;
            return true;
          }).map((user, index) => {
            let status = _.find(config.roleStatuses, s => s.IdRoleStatus === user.IdRoleStatus) || _.first(config.roleStatuses);
            return <li key={index} className="ml-0 mr-0 p-3" onClick={() => { }}>
              <Animated animationIn="zoomIn" animationInDelay={(index * 50) + 300} className="font-10 no-margin no-padding d-flex flex-row">
                <div className="w-100 d-flex flex-row">
                  <div className="d-flex flex-row w-50">
                    <div className="cover align-self-center" style={{ width: 30, height: 30, borderRadius: '50%', border: `1px solid gray`, background: `url(${user.UserImage}?full=60) no-repeat center center black` }} />
                    <div className="d-flex flex-column align-self-center montserrat">
                      <span className="block black toBold ml-2 font-14 line1">{`${user.UserName}`}</span>
                      {user.SchoolName !== null && <span className="block gray ml-2 font-10 line1">{`${user.RoleName} @ ${user.SchoolName}`}</span>}
                    </div>
                  </div>
                  <div className="w-25 d-flex flex-row">
                    <i className="fas fa-circle align-self-center" style={{ color: status.color }} />
                    <span className="ml-2 align-self-center">{status.RoleStatusName}</span>
                  </div>
                  <Link to={`/season/${user.IdSeason}/scout/${user.IdUser}`} className="btn btn-sm btn-link ml-auto green">View Details</Link>
                </div>
              </Animated>
            </li>
          })}
        </ul>
      </div>
    </div>
  }

  onChange = () => { this.setState({ search: this.txtSearch.value }) }

  render() {

    const { genders, fetchingData, params, season, scout_registrations } = this.props, { mode } = this.state;

    return (
      <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
        <div className="d-flex flex-row w-100">
          {/*<button onClick={() => this.onMode(1)} className={`sqwad-font toUpper align-self-center btn btn-link ${mode === 1 ? 'blue' : 'black'}`}>Players</button>
          <span className="align-self-center ">|</span>
          <button onClick={() => this.onMode(2)} className={`sqwad-font toUpper align-self-center btn btn-link ${mode === 2 ? 'blue' : 'black'}`}>Teams</button>
          {season && season.AllowsScoutRegistration === 1 && <span className="align-self-center ">|</span>}
          {season && season.AllowsScoutRegistration === 1 && <button onClick={() => this.onMode(3)} className={`sqwad-font toUpper align-self-center btn btn-link ${mode === 3 ? 'blue' : 'black'}`}>Scouts</button>}*/}
          <input placeholder={`Search ${mode === 3 ? 'Scout' : 'Division'}`} className="w-50 form-control align-self-center mx-auto" ref={i => this.txtSearch = i} onChange={this.onChange} />
        </div>
        <div className="card mt-3 mb-4">
          <section className="d-flex flex-column w-100 no-margin no-padding card" style={{ overflow: 'hidden' }}>

            <div className="h-100">

              {!fetchingData && [1, 2].indexOf(mode) !== -1 && !Object.keys(genders).length &&
                <Jumbotron className="no-margin bg-white d-flex flex-column justify-content-center w-100">
                  <i className="far fa-meh-blank font-30 black align-self-center" />
                  <span className="black font-20 align-self-center">You don't have divisions created yet!</span>
                  <Link to={`/season/${params.idSeason}/divisions`} style={{ width: 130 }} className="green ml-2 p-2 align-self-center block">
                    <i className="fas fa-plus margin-right-half" />Add Divisions</Link>
                </Jumbotron>}

              {!fetchingData && [3].indexOf(mode) !== -1 && scout_registrations && !scout_registrations.length &&
                <Jumbotron className="mx-auto my-0 bg-white d-flex flex-column justify-content-center w-100">
                  <i className="far fa-meh-blank font-30 black align-self-center" />
                  <span className="black font-20 align-self-center">No Scouts registrations yet!</span>
                </Jumbotron>
              }

              {!fetchingData && [1, 2].indexOf(mode) !== -1 && Object.keys(genders).length > 0 && this.renderList()}
              {!fetchingData && [3].indexOf(mode) !== -1 && scout_registrations && scout_registrations.length > 0 && this.renderScouts()}

              {fetchingData && <Loader message={`Loading Divisions`} />}
            </div>

          </section>
        </div>
      </div>
    )
  }
}

export default Divisions;
