import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import Loader from '../../../common/Loader';
import _ from 'lodash';
import Lists from './partials/list';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import Navigation from '../navigation';

class Tryouts extends React.Component {

  state = {
    bootstrapped: false,
    selectedDivision: null,
    isDivisionsOpen: false,
    fetchingTryouts: false,
    coaches: null
  }

  // Lifecycle
  componentWillMount() {
    const { setTitle, params } = this.props;
    setTitle && setTitle({
      sub: 'Tryouts',
      main: this.props.season ? this.props.season.Name : null
    });

    this.props.fetchDivisions && this.props.fetchDivisions(params.idSeason);
    this.props.fetchSeason && this.props.fetchSeason(params.idSeason);
  }

  componentWillReceiveProps = (nextProps) => {

    if (nextProps.season !== this.props.season) {

      const { setTitle } = nextProps;
      setTitle && setTitle({
        sub: 'Tryouts',
        main: nextProps.season.Name || null
      });
    }

    if (nextProps.divisions && !this.state.bootstrapped) {
      this.setState({
        bootstrapped: true,
        divisions: _.filter(nextProps.divisions, d => { return d.SelectedAsTryout; }),
        selectedDivision: _.find(nextProps.divisions, d => { return d.SelectedAsTryout; }),
      }, () => {
        if (this.state.divisions.length)
          this.fetchTryouts();
        else {
          this.setState({ error: 'Sorry, there are no divisions tryout divisions in this season' });
        }
      });
    }

    if (nextProps.tryouts && nextProps.accepted) {
      this.setState({ fetchingTryouts: false });
    }
  }

  componentWillUnmount = () => {
    this.props.clearDivisions && this.props.clearDivisions();
    this.props.tryoutActions && this.props.tryoutActions.cleanup();
  }
  //

  fetchTryouts = () => {
    const { selectedDivision } = this.state;
    selectedDivision &&
      this.props.tryoutActions && this.props.tryoutActions.fetch(selectedDivision.IdDivision);
  }

  toggleDivisions = () => {
    this.setState({
      isDivisionsOpen: !this.state.isDivisionsOpen
    });
  }

  selectDivision = (selectedDivision) => {
    this.setState({
      selectedDivision,
      isDivisionsOpen: false
    }, () => {
      this.fetchTryouts();
    });
  }

  render() {

    const { bootstrapped, selectedDivision, fetchingTryouts, divisions, error, updated } = this.state,
      { tryouts, season } = this.props;

      let filteredDivisions = _.filter(divisions, d => d.IsTryout);

    return (
      <section>

        <div className="w3-container mt-4">

          <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
            <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
              {this.state.error}
            </Alert>
            <Alert color="success" isOpen={updated ? true : false} toggle={() => { this.setState({ updated: null }) }}>
              Season updated!
            </Alert>
          </div>

          {season && <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
            <div className="w-100 black">
              <h3>{season.Name || season.SeasonName}</h3>
              <Link to={`/leagues/${season.IdLeague}`} className="blue"><h6 className="block">{season.LeagueName} Seasons</h6></Link>
            </div>
          </div>}

          <div className="w3-content mt-4" style={{ maxWidth: 1285 }}>
            <h5>Team Management</h5>

            {this.props.season && <Navigation season={this.props.season} />}

            {bootstrapped && selectedDivision && <Animated isVisible={bootstrapped ? true : false} animationIn="fadeIn" className="card shadow mt-3">

              <div className="d-flex flex-row montserrat p-3" style={{ maxWidth: 1285 }}>

                <div className="black">
                  <h1>Tryouts</h1>
                </div>

                <div className="d-flex flex-row w-100">
                  <span className="align-self-center black ml-auto">Division</span>
                  <Dropdown className="ml-2 align-self-center" isOpen={this.state.isDivisionsOpen} toggle={this.toggleDivisions}>
                    <DropdownToggle caret className="btn-white black bordered btn align-self-center">
                      {selectedDivision.Name}
                    </DropdownToggle>
                    <DropdownMenu>
                      {filteredDivisions && filteredDivisions.map((division, i) => (
                        <DropdownItem key={i} onClick={() => this.selectDivision(division)}>
                          {division.Name} (
                          {division.Teams.length} teams)
                        </DropdownItem>))}
                    </DropdownMenu>
                  </Dropdown>

                  {/*<button className="ml-2 btn-warning btn-sm btn" onClick={this.addSeason}><i className="fas fa-filter"></i>
                    {' '}Select Columns</button>
                  <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 300 }} className="green ml-2 p-2 align-self-center block">
                    <i className="fas fa-plus margin-right-half" />Add Team</button>*/}
                </div>
              </div>

              { /* Divisions */}
              <div className="h-100 ml-4 mr-4" style={{ position: 'relative', minHeight: 650 }}>
                {bootstrapped && tryouts && (
                  <Lists {...this.props} selectedDivision={selectedDivision} />)}                
              </div>

              <div key="scrollTo" ref={(el) => { this.bracketsEnd = el; }} />
            </Animated>}
          </div>

        </div>
        { /* Top left actions */}
        {/*bootstrapped && (
          <div className="left-actions d-flex flex-row">            
            <span className="align-self-center white margin-right-half">Division</span>
            <Dropdown isOpen={this.state.isDivisionsOpen} toggle={this.toggleDivisions}>
              <DropdownToggle caret className="btn-info btn-sm btn">
                {selectedDivision ? selectedDivision.Name : 'Please select a division.'}
              </DropdownToggle>
              <DropdownMenu>
                {divisions && divisions.map((division, i) => (
                  <DropdownItem key={i} onClick={() => this.selectDivision(division)}>
                    {division.Name}
                  </DropdownItem>))}
              </DropdownMenu>
            </Dropdown>
          </div>)*/}
        
        
        {/*
        {bootstrapped && selectedDivision && tryouts && (
          <Lists {...this.props} selectedDivision={selectedDivision} />)}
        {bootstrapped && false && (
          <div key="no-teams" className="d-flex flex-col justify-content-center centered-message">
            <h1 className="white align-self-center block text-center">{selectedDivision.Name}{' '}have Tryouts in it.</h1>
          </div>)}
        */}
        
        {(!bootstrapped || fetchingTryouts) && <Loader message="Fetching Assets" />}
      </section>)
  }
}

export default Tryouts;
