const
    canvas_width = 380, // * 0.95,
    canvas_height = 570, // * 0.95,
    w = 60,
    DW = 380,
    LB = ((DW * 0.125)),
    RB = ((DW) - (DW * 0.125)),
    IDW = DW * 0.75;

module.exports = {
    canvas_height,
    canvas_width,        
    glosary: {
        football: {
            offense: [
                {
                    name: "Quarterback",
                    abbr: "QB",
                    desc: "Leader of the offense, responsible for calling plays, passing, and sometimes running the ball."
                },
                {
                    name: "Halfback",
                    abbr: "HB",
                    desc: "Main ball carrier."
                },
                {
                    name: "Fullback",
                    abbr: "FB",
                    desc: "Blocker and occasional ball carrier."
                },
                {
                    name: "Wide Receiver",
                    abbr: "WR",
                    desc: "Wide receiver positioned on the line of scrimmage."
                },
                {
                    name: "Split End | X Receiver",
                    abbr: "SE",
                    desc: "Wide receiver positioned on the line of scrimmage."
                },
                {
                    name: "Slot Receiver",
                    abbr: "SR",
                    desc: "Wide receiver positioned on the line of scrimmage."
                },
                {
                    name: "Z Receiver | Flanker",
                    abbr: "FL",
                    desc: "Wide receiver positioned off the line of scrimmage."
                },
                {
                    name: "Tight End",
                    abbr: "TE",
                    desc: "Hybrid player who can block like an offensive lineman and catch passes like a receiver."
                },
                {
                    name: "Left Tackle",
                    abbr: "LT",
                    desc: "Protects the quarterback’s blind side."
                },
                {
                    name: "Left Guard",
                    abbr: "LG",
                    desc: "Blocks for the running back and protects the quarterback."
                },
                {
                    name: "Center",
                    abbr: "C",
                    desc: "Snaps the ball to the quarterback and blocks."
                },
                {
                    name: "Right Guard",
                    abbr: "RG",
                    desc: "Blocks for the running back and protects the quarterback."
                },
                {
                    name: "Right Tackle",
                    abbr: "RT",
                    desc: "Protects the quarterback and blocks for the running back."
                }
            ],
            defense: [
                {
                    name: "Defensive Tackle",
                    abbr: "DT",
                    desc: "Inside linemen focused on stopping the run and rushing the passer."
                },
                {
                    name: "Nose Tackle",
                    abbr: "NT",
                    desc: "Specific to 3-4 defenses, lines up directly over the center."
                },
                {
                    name: "Defensive End",
                    abbr: "DE",
                    desc: "Outside linemen responsible for containing the edge and rushing the passer."
                },
                {
                    name: "Middle Linebacker",
                    abbr: "MLB",
                    desc: "Also known as the 'Mike' linebacker, the leader of the defense."
                },
                {
                    name: "Strong-Side Linebacker",
                    abbr: "SLB",
                    desc: "Also known as the 'Sam' linebacker, often covers the tight end."
                },
                {
                    name: "Weak-Side Linebacker",
                    abbr: "WLB",
                    desc: "Also known as the 'Will' linebacker, often more focused on pass coverage."
                },
                {
                    name: "Cornerback",
                    abbr: "CB",
                    desc: "Covers wide receivers, often in man-to-man or zone coverage."
                },
                {
                    name: "Free Safety",
                    abbr: "FS",
                    desc: "Generally focuses on pass coverage."
                },
                {
                    name: "Strong Safety",
                    abbr: "SS",
                    desc: "Typically more involved in stopping the run and covering tight ends."
                }
            ],
            special_teams: [
                {
                    name: "Kicker",
                    abbr: "K",
                    desc: "Handles kickoffs, field goals, and extra points."
                },
                {
                    name: "Punter",
                    abbr: "P",
                    desc: "Kicks the ball on fourth down to the other team, aiming to pin them deep in their own territory."
                },
                {
                    name: "Long Snapper",
                    abbr: "LS",
                    desc: "Snaps the ball on punts, field goals, and extra points."
                },
                {
                    name: "Holder",
                    abbr: "H",
                    desc: "Catches the snap on field goals and extra points, setting it up for the kicker."
                },
                {
                    name: "Kick Returner",
                    abbr: "KR",
                    desc: "Returns kickoffs."
                },
                {
                    name: "Punt Returner",
                    abbr: "PR",
                    desc: "Returns punts."
                },
                {
                    name: "Gunner",
                    abbr: 'GN',
                    desc: "Specializes in sprinting downfield to tackle the kick or punt returner."
                },
                {
                    name: "Personal Protector",
                    abbr: 'PP',
                    desc: "Helps protect the punter on punt plays, sometimes also serves as a lead blocker for the punt returner."
                }
            ],
            width: w
        }
    },
    football: [
        {
            name: 'Singleback formation', defense: 0, setup: 11, positions: [

                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2), name: 'WR', full: 'Wide Receiver' },
                { top: '25%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (1 / 9)), name: 'WR', full: 'Wide Receiver' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (2 / 9)), name: 'LT', full: 'Left Tackle' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'G', full: 'Guard' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'C', full: 'Center' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'G', full: 'Guard' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (6 / 9)), name: 'RT', full: 'Right Tackle' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (7 / 9)), name: 'TE', full: 'Tight End' },
                { top: '25%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (8 / 9)), name: 'WR', full: 'Wide Receiver' },

                { top: '30%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'QB', full: 'Quarter-Back' },

                { top: '40%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'HB', full: 'Half-Back' },
            ]
        },
        {
            name: 'Shotgun formation', defense: 0, setup: 11, positions: [

                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2), name: 'WR', full: 'Wide Receiver' },
                { top: '25%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (1 / 9)), name: 'WR', full: 'Wide Receiver' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (2 / 9)), name: 'LT', full: 'Left Tackle' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'G', full: 'Guard' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'C', full: 'Center' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'G', full: 'Guard' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (6 / 9)), name: 'RT', full: 'Right Tackle' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (7 / 9)), name: 'TE', full: 'Tight End' },
                { top: '25%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (8 / 9)), name: 'WR', full: 'Wide Receiver' },

                { top: '40%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'QB', full: 'Quarter-Back' },

                { top: '40%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'HB', full: 'Half-Back' },
            ]
        },
        {
            name: 'I formation, fullback offset strongside', defense: 0, setup: 11, positions: [

                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + LB, name: 'LT', full: 'Left Tackle' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (1 / 6)) + LB, name: 'G', full: 'Guard' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (2 / 6)) + LB, name: 'C', full: 'Center' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (3 / 6)) + LB, name: 'G', full: 'Guard' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (4 / 6)) + LB, name: 'RT', full: 'Right Tackle' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (5 / 6)) + LB, name: 'TE', full: 'Tight End' },

                { top: '25%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'WR', full: 'Wide Receiver' },
                { top: '25%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'WR', full: 'Wide Receiver' },

                { top: '30%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (2 / 6)) + LB, name: 'QB', full: 'Quarter-Back' },

                { top: '40%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (3 / 6)) + LB, name: 'FB', full: 'Full-Back' },
                { top: '50%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (2 / 6)) + LB, name: 'HB', full: 'Half-Back' },
            ]
        },
        {
            name: 'I formation, fullback offset weakside', defense: 0, setup: 11, positions: [

                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + LB, name: 'LT', full: 'Left Tackle' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (1 / 6)) + LB, name: 'G', full: 'Guard' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (2 / 6)) + LB, name: 'C', full: 'Center' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (3 / 6)) + LB, name: 'G', full: 'Guard' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (4 / 6)) + LB, name: 'RT', full: 'Right Tackle' },
                { top: '20%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (5 / 6)) + LB, name: 'TE', full: 'Tight End' },

                { top: '25%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'WR', full: 'Wide Receiver' },
                { top: '25%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'WR', full: 'Wide Receiver' },

                { top: '30%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (2 / 6)) + LB, name: 'QB', full: 'Quarter-Back' },

                { top: '40%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (1 / 6)) + LB, name: 'FB', full: 'Full-Back' },
                { top: '50%', left: (IDW * (1 / 6) * 0.5) - (w / 2) + (IDW * (2 / 6)) + LB, name: 'HB', full: 'Half-Back' },
            ]
        },
        {
            name: 'Spread formation', defense: 0, setup: 11, positions: [

                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2), name: 'WR', full: 'Wide Receiver' },
                { top: '25%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (1 / 9)), name: 'WR', full: 'Wide Receiver' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (2 / 9)), name: 'LT', full: 'Left Tackle' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'G', full: 'Guard' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'C', full: 'Center' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'G', full: 'Guard' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (6 / 9)), name: 'RT', full: 'Right Tackle' },
                { top: '25%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (7 / 9)), name: 'WR', full: 'Wide Receiver' },
                { top: '20%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (8 / 9)), name: 'WR', full: 'Wide Receiver' },

                { top: '30%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'QB', full: 'Quarter-Back' },

                { top: '40%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'HB', full: 'Half-Back' },
            ]
        },
        // DEFENSE FORMATIONS
        {
            name: 'Basic 4-3', defense: 1, setup: 11, positions: [ // CHECK

                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + 20, name: 'CB', full: 'Corner Back' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (3 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (6 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (9 / 10)) - 20, name: 'CB', full: 'Corner Back' },

                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '30%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'LB', full: 'Line-Backer' },

                { top: '20%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'SS', full: 'Safety' },
                { top: '10%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'FS', full: 'Safety' },
            ]
        },
        {
            name: '4-3 under defense, LB strong-side', defense: 1, setup: 11, positions: [ // CHECK

                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + 20, name: 'CB', full: 'Corner Back' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (3 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (6 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (9 / 10)) - 20, name: 'CB', full: 'Corner Back' },

                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '40%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (7 / 10)), name: 'LB', full: 'Line-Backer' },

                { top: '20%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'SS', full: 'Safety' },
                { top: '10%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'FS', full: 'Safety' },
            ]
        },
        {
            name: '4-3 over defense, LB weak-side', defense: 1, setup: 11, positions: [ // CHECK

                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + 20, name: 'CB', full: 'Corner Back' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (3 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (6 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (9 / 10)) - 20, name: 'CB', full: 'Corner Back' },

                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '40%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (2 / 10)), name: 'LB', full: 'Line-Backer' },

                { top: '20%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'SS', full: 'Safety' },
                { top: '10%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'FS', full: 'Safety' },
            ]
        },

        {
            name: 'Basic 3-4', defense: 1, setup: 11, positions: [ // CHECK

                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + 20, name: 'CB', full: 'Corner Back' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (9 / 10)) - 20, name: 'CB', full: 'Corner Back' },

                { top: '40%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (2 / 10)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '40%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (7 / 10)), name: 'LB', full: 'Line-Backer' },

                { top: '20%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'SS', full: 'Safety' },
                { top: '10%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'FS', full: 'Safety' },
            ]
        },

        {
            name: 'The 46', defense: 1, setup: 11, positions: [ // CHECK

                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + 20, name: 'CB', full: 'Corner Back' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (6 / 10)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (7 / 10)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (9 / 10)) - 20, name: 'CB', full: 'Corner Back' },

                { top: '40%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (2 / 10)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (7 / 10)), name: 'SS', full: 'Line-Backer' },

                { top: '10%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'FS', full: 'Safety' },
            ]
        },

        {
            name: 'The 5-2', defense: 1, setup: 11, positions: [ // CHECK

                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2), name: 'CB', full: 'Corner Back' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (2 / 9)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'NT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'DT', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (6 / 9)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (8 / 9)), name: 'CB', full: 'Corner Back' },

                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'LB', full: 'Line-Backer' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'LB', full: 'Line-Backer' },

                { top: '20%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'SS', full: 'Safety' },
                { top: '10%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (5 / 10)), name: 'FS', full: 'Safety' },
            ]
        },

        {
            name: 'Punt return', defense: 1, setup: 11, positions: [ // CHECK

                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2), name: 'CB', full: 'Corner Back' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (2 / 9)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'DT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'NT', full: 'Defensive Tackle' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'DT', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (6 / 9)), name: 'DE', full: 'Defensive End' },
                { top: '45%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (8 / 9)), name: 'CB', full: 'Corner Back' },

                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (3 / 9)), name: 'B', full: '' },
                { top: '30%', left: (DW * (1 / 10) * 0.5) - (w / 2) + (DW * (4 / 10)), name: 'PP', full: '' },
                { top: '35%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (5 / 9)), name: 'B', full: '' },

                { top: '10%', left: (DW * (1 / 9) * 0.5) - (w / 2) + (DW * (4 / 9)), name: 'P', full: '' },
            ]
        }

    ],
    soccer: [
        // 11 v 11
        {
            name: '4-4-2 (A)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '34%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CM', full: 'Center Midfielder' },
                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CM', full: 'Center Midfielder' },
                { top: '34%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RM', full: 'Right Midfielder' },

                { top: '15%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Forward' },
                { top: '15%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '4-4-2 (B)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '35%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '45%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.33), name: 'DM', full: 'Defensive Midfielder' },
                { top: '35%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'RM', full: 'Right Midfielder' },

                { top: '15%', left: (DW * 0.50 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '25%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.33), name: 'CF', full: 'Center Forward' },
                { top: '15%', left: (DW * 0.50 * 0.5) - (w / 2) + (DW * 0.50), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '4-5-1', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '34%', left: (DW * 0.20 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '35%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.2), name: 'CM', full: 'Center Midfielder' },
                { top: '35%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.4), name: 'CM', full: 'Center Midfielder' },
                { top: '35%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.6), name: 'CM', full: 'Center Midfielder' },
                { top: '34%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.8), name: 'RM', full: 'Right Midfielder' },

                { top: '15%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '4-1-4-1', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '50%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'DM', full: 'Defensive Midfielder' },

                { top: '34%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CM', full: 'Center Midfielder' },
                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CM', full: 'Center Midfielder' },
                { top: '34%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RM', full: 'Right Midfielder' },

                { top: '15%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '4-4-1-1', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '41%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CM', full: 'Center Midfielder' },
                { top: '41%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CM', full: 'Center Midfielder' },
                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RM', full: 'Right Midfielder' },

                { top: '25%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'AM', full: 'Attack Midfielder' },

                { top: '12%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '4-2-4', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'DM', full: 'Defensive Midfielder' },
                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'DM', full: 'Defensive Midfielder' },

                { top: '14%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '15%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CF', full: 'Center Forward' },
                { top: '15%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CF', full: 'Center Forward' },
                { top: '14%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '4-2-3-1', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '45%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'DM', full: 'Defensive Midfielder' },
                { top: '45%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'DM', full: 'Defensive Midfielder' },

                { top: '27%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '28%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'AM', full: 'Attack Midfielder' },
                { top: '27%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'RF', full: 'Right Forward' },

                { top: '12%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '4-3-3', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '35%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '45%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.33), name: 'DM', full: 'Defensive Midfielder' },
                { top: '35%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'RM', full: 'Right Midfielder' },

                { top: '15%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '18%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CM', full: 'Center Midfielder' },
                { top: '15%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '3-4-3 (A)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'CB', full: 'Center Back' },

                { top: '43%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '44%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CM', full: 'Center Midfielder' },
                { top: '44%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CM', full: 'Center Midfielder' },
                { top: '43%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RM', full: 'Right Midfielder' },

                { top: '25%', left: (DW * 0.5 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '15%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
                { top: '25%', left: (DW * 0.5 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '3-4-3 (B)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'CB', full: 'Center Back' },

                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '36%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CM', full: 'Center Midfielder' },
                { top: '36%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CM', full: 'Center Midfielder' },
                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RM', full: 'Right Midfielder' },

                { top: '15%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '16%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
                { top: '15%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '3-5-2 (A)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'CB', full: 'Center Back' },

                { top: '40%', left: (DW * 0.20 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '41%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.2), name: 'CM', full: 'Center Midfielder' },
                { top: '41%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.4), name: 'CM', full: 'Center Midfielder' },
                { top: '41%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.6), name: 'CM', full: 'Center Midfielder' },
                { top: '40%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.8), name: 'RM', full: 'Right Midfielder' },


                { top: '15%', left: (DW * 0.5 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '15%', left: (DW * 0.5 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '3-5-2 (B)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'CB', full: 'Center Back' },

                { top: '40%', left: (DW * 0.20 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '30%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.2), name: 'CM', full: 'Center Midfielder' },
                { top: '41%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },
                { top: '30%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.6), name: 'CM', full: 'Center Midfielder' },
                { top: '40%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.8), name: 'RM', full: 'Right Midfielder' },


                { top: '15%', left: (DW * 0.5 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '15%', left: (DW * 0.5 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '3-5-2 (C)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'CB', full: 'Center Back' },

                { top: '40%', left: (DW * 0.20 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '41%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.2), name: 'DM', full: 'Defensive Midfielder' },
                { top: '30%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.4), name: 'AM', full: 'Attack Midfielder' },
                { top: '41%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.6), name: 'DM', full: 'Defensive Midfielder' },
                { top: '40%', left: (DW * 0.20 * 0.5) - (w / 2) + (DW * 0.8), name: 'RM', full: 'Right Midfielder' },


                { top: '15%', left: (DW * 0.5 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '15%', left: (DW * 0.5 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '5-4-1 (A)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.2 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.8), name: 'RB', full: 'Right Back' },

                { top: '37%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'RM', full: 'Right Midfielder' },
                { top: '38%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CM', full: 'Center Midfielder' },
                { top: '38%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.50), name: 'CM', full: 'Center Midfielder' },
                { top: '37%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'LM', full: 'Left Midfielder' },

                { top: '15%', left: (DW * 1 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '5-4-1 (B)', setup: 11, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.2 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.8), name: 'RB', full: 'Right Back' },

                { top: '45%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LM', full: 'Left Midfielder' },
                { top: '50%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },
                { top: '45%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RM', full: 'Right Midfielder' },

                { top: '40%', left: (DW * 1 * 0.5) - (w / 2), name: 'AM', full: 'Attack Midfielder' },

                { top: '15%', left: (DW * 1 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },

        // 9 v 9
        {
            name: '3-3-2', setup: 9, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '55%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'CB', full: 'Center Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '55%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'CB', full: 'Center Back' },


                { top: '35%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LM', full: 'Left Midfielder' },
                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },
                { top: '35%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RM', full: 'Right Midfielder' },

                { top: '15%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Forward' },
                { top: '15%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '3-2-3', setup: 9, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '55%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'CB', full: 'Center Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '55%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'CB', full: 'Center Back' },


                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'DM', full: 'Defensive Midfielder' },
                { top: '35%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'DM', full: 'Defensive Midfielder' },


                { top: '15%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '18%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
                { top: '15%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '2-3-2-1 | 2-3-3', setup: 9, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },

                { top: '45%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LM', full: 'Left Midfielder' },
                { top: '50%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },
                { top: '45%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RM', full: 'Right Midfielder' },

                { top: '30%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'AM', full: 'Attacking Midfielder' },
                { top: '30%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'AM', full: 'Attacking Midfielder' },

                { top: '15%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },

            ]
        },
        {
            name: '3-1-3-1', setup: 9, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CB', full: 'Center Back' },
                { top: '64%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'CB', full: 'Center Back' },


                { top: '50%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },


                { top: '30%', left: (DW * 0.33 * 0.5) - (w / 2), name: 'LF', full: 'Left Forward' },
                { top: '35%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CB', full: 'Center Midfielder' },
                { top: '30%', left: (DW * 0.33 * 0.5) - (w / 2) + (DW * 0.66), name: 'RF', full: 'Right Forward' },

                { top: '15%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },

            ]
        },
        {
            name: '2-4-2', setup: 9, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },


                { top: '43%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LM', full: 'Left Midfielder' },
                { top: '44%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CM', full: 'Center Midfielder' },
                { top: '44%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CM', full: 'Center Midfielder' },
                { top: '43%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RM', full: 'Right Midfielder' },


                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Forward' },
                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },

            ]
        },
        {
            name: '4-3-1', setup: 9, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },
                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.75), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LM', full: 'Left Midfielder' },
                { top: '45%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },
                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RM', full: 'Right Midfielder' },

                { top: '20%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },

            ]
        },

        // 8 v 8
        {
            name: '3-2-2', setup: 8, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'CB', full: 'Center Back' },


                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LM', full: 'Left Midfielder' },
                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RM', full: 'Right Midfielder' },


                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Forward' },
                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },
            ]
        },
        {
            name: '2-3-2', setup: 8, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LB', full: 'Left Back' },
                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LM', full: 'Left Midfielder' },
                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CM', full: 'Center Midfielder' },
                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RM', full: 'Right Midfielder' },


                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Forward' },
                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },

            ]
        },
        {
            name: '3-3-1', setup: 8, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LM', full: 'Left Midfielder' },
                { top: '45%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CM', full: 'Center Midfielder' },
                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RM', full: 'Right Midfielder' },

                { top: '20%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '2-4-1 (Diamond)', setup: 8, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '75%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'CB', full: 'Center Back' },
                { top: '75%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'CB', full: 'Center Back' },

                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },

                { top: '45%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LM', full: 'Left Midfielder' },
                { top: '45%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RM', full: 'Right Midfielder' },

                { top: '30%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'AM', full: 'Attacking Midfielder' },

                { top: '15%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },

            ]
        },

        // 7 v 7
        {
            name: '2-3-1', setup: 7, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LB', full: 'Left Back' },
                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LM', full: 'Left Midfielder ' },
                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CM', full: 'Center Midfielder' },
                { top: '40%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RM', full: 'Right Midfielder' },


                { top: '20%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '2-1-2-1', setup: 7, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RB', full: 'Right Back' },

                { top: '55%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },

                { top: '45%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LM', full: 'Left Midfielder' },
                { top: '45%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RM', full: 'Right Midfielder' },

                { top: '25%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '3-1-2', setup: 7, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LB', full: 'Left Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CM', full: 'Center Midfielder' },

                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Forward' },
                { top: '20%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },

            ]
        },
        {
            name: '3-1-1-1', setup: 7, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LB', full: 'Left Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '65%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RB', full: 'Right Back' },

                { top: '45%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'DM', full: 'Defensive Midfielder' },

                { top: '30%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CM', full: 'Center Midfielder' },
                { top: '15%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '3-2-1', setup: 7, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.2), name: 'LB', full: 'Left Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },
                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.6), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Midfielder' },
                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RM', full: 'Right Midfielder' },

                { top: '20%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },

            ]
        },

        // 5 v 5
        {
            name: '1-2-1 (Diamond)', setup: 5, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.2 * 0.5) - (w / 2) + (DW * 0.4), name: 'CB', full: 'Center Back' },

                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LM', full: 'Left Midfielder' },
                { top: '40%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RM', full: 'Right Midfielder' },

                { top: '20%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },
            ]
        },
        {
            name: '2-2 (Square)', setup: 5, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LB', full: 'Left Back' },
                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RB', full: 'Right Back' },

                { top: '30%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LF', full: 'Left Forward' },
                { top: '30%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RF', full: 'Right Forward' },

            ]
        },
        {
            name: '2-1-1 (Pyramid)', setup: 5, positions: [
                { top: '80%', left: (DW / 2) - (w / 2), name: 'GK', full: 'Goal Keeper' },

                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.25), name: 'LB', full: 'Left Back' },
                { top: '60%', left: (DW * 0.25 * 0.5) - (w / 2) + (DW * 0.5), name: 'RB', full: 'Right Back' },

                { top: '40%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CM', full: 'Center Midfielder' },

                { top: '20%', left: (DW * 1.0 * 0.5) - (w / 2), name: 'CF', full: 'Center Forward' },

            ]
        },
    ]
}