import React from 'react';
import QandA from '../created_questions/item';
import _ from 'lodash';
import { Jumbotron } from 'reactstrap';
import { ItemTypes } from '../../../../../../helpers';

class Tab extends React.Component {

  render() {
    const { questions, itemType, questionsActions } = this.props;
    const parsedQuestions = _.filter(questions, q => {
      return (parseInt(q.question.question_type || q.question.IdQuestionType, 10)) === parseInt(itemType, 10);
    });
    return <section className="p-2">
      {parsedQuestions && parsedQuestions.map((q, i) =>
        <QandA className="margin-bottom-half" question={q} questionsActions={questionsActions} key={i} selectable={true} draggable={true} type={ItemTypes.QANDA} />
      )}
      {(!parsedQuestions || !parsedQuestions.length) && <Jumbotron>
        <h2>You have no question on this sub-type</h2>
        <p className="font-10">You can go to the Library and add questions</p>
      </Jumbotron>}
    </section>
  }
}

export default Tab;
