import React from 'react';
import _ from 'lodash';
import fakeAuth from '../../../../fakeAuth';

class Attendance extends React.Component {
    state = {}
    componentWillMount = () => {
        const { event } = this.props;
        this.props.teamActions && this.props.teamActions.fetchFamilyAttendance(event.IdGame || event.IdEvent);
    }
    onReplyAttendance = ({ IdUser, IdEventAttendanceReply }) => {
        const { event } = this.props;
        this.props.teamActions && this.props.teamActions.updateFamilyAttendance({
            idEvent: event.IdEvent,
            idUser: IdUser,
            idEventAttendanceReply: IdEventAttendanceReply
        });
    }
    render() {
        const { team_subscriptions } = this.props, family = fakeAuth.getFamily();
        if (_.find(team_subscriptions, s => !s.Expired)) {
            return <section className=" p-2 d-flex flex-column">
                <h4 className="montserrat-bold mb-2 font-16 mx-3">Your Attendance</h4>
                {this.props.family_attendance &&
                    _.chain(this.props.family_attendance)
                        .filter(u => _.map(family, f => f.IdUser).indexOf(u.IdUser) !== -1)
                        .value().map((attendance, i) => <div key={i} className="tile card mb-2 d-flex flex-row p-2">
                            <div style={{ width: 120 }} className=" d-flex flex-row justify-content-center">
                                <div className="align-self-center cover" style={{ width: 80, height: 80, borderRadius: '50%', border: `1px solid gray`, background: `url('${attendance.UserImage}') no-repeat center center black` }} />
                            </div>
                            <div className="w-100 d-flex flex-column">
                                <div className="h-100 px-2 montserrat font-14 d-flex flex-row"><span className="align-self-center">{attendance.UserName}</span></div>
                                <div className="h-100 d-flex flex-row">
                                    <div className="w-100 px-1">
                                        <button onClick={() => this.onReplyAttendance({
                                            IdUser: attendance.IdUser,
                                            IdEventAttendanceReply: 1
                                        })} className={`btn btn-${attendance.IdEventAttendanceReply === 1 ? 'success' : 'link bordered'} btn-block`}>YES</button>
                                    </div>
                                    <div className="w-100 px-1">
                                        <button onClick={() => this.onReplyAttendance({
                                            IdUser: attendance.IdUser,
                                            IdEventAttendanceReply: 2
                                        })} className={`btn btn-${attendance.IdEventAttendanceReply === 2 ? 'danger' : 'link bordered'} btn-block`}>NO</button>
                                    </div>
                                    <div className="w-100 px-1">
                                        <button onClick={() => this.onReplyAttendance({
                                            IdUser: attendance.IdUser,
                                            IdEventAttendanceReply: 3
                                        })} className={`btn btn-${attendance.IdEventAttendanceReply === 3 ? 'warning' : 'link bordered'} btn-block`}>MAYBE</button>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                <h4 className="montserrat-bold my-2 font-16 mx-3">Team Attendance</h4>
                <div className="tile card mb-2 d-flex flex-row p-2">

                    <div className="w-100 px-1 align-self-center">
                        <button className={`btn btn-link bordered btn-block`}>YES ({_.filter(this.props.family_attendance, a => a.IdEventAttendanceReply === 1).length})</button>
                    </div>
                    <div className="w-100 px-1 align-self-center">
                        <button className={`btn btn-link bordered btn-block`}>NO ({_.filter(this.props.family_attendance, a => a.IdEventAttendanceReply === 2).length})</button>
                    </div>
                    <div className="w-100 px-1 align-self-center">
                        <button className={`btn btn-link bordered btn-block`}>MAYBE ({_.filter(this.props.family_attendance, a => a.IdEventAttendanceReply === 3).length})</button>
                    </div>

                </div>
            </section>
        } else {
            return <section />
        }
    }
}

export default Attendance;