import React, { useMemo, useCallback } from 'react';
import { Animated } from 'react-animated-css';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import axios from 'axios';
import config from '../../config';
import VideoTile from './partials/VideoTile';
import { getReadableFileSizeString } from '../../helpers';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });
    }, [props]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'video/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <div style={{ width: 200, height: 200, borderRadius: 100, backgroundColor: 'black' }} />
                <input {...getInputProps()} />
                <p className="text-center">Drag 'n' drop some files here, or click to select files</p>
            </div>
        </div>
    );
}

class Team extends React.Component {

    state = {
        files: []
    }
    // Lifecycle
    componentWillMount() {
        const { setTitle, team } = this.props;

        setTitle && setTitle({
            main: 'SQWAD Services',
            sub: (team || { TeamName: 'The Selected Team' }).TeamName
        });

        const { params } = this.props;
        this.props.teamActions && this.props.teamActions.fetchTeamSeasons && this.props.teamActions.fetchTeamSeasons(params.idTeam);        
    }    
    //

    onDrop = (file) => {
        let index = this.state.files.length;
        this.setState({
            files: [...this.state.files, { actualFile: file, progress: 0 }]
        }, () => {
            const formData = new FormData();

            // Update the formData object 
            formData.append("myFile", file, file.name);
            axios.post(`${config.apiEndpoint}/api/v4/video/`, formData, {
                data: {
                    bola: 1,
                    vaca: 2
                },
                headers: { 'auth_token': localStorage.getItem('sportslogic.authtoken') },
                onUploadProgress: (progress) => {
                    const { files } = this.state;
                    files[index].progress = Math.round(progress.loaded * 100 / progress.total);
                    files[index].total = progress.loaded;
                    this.setState({ files });
                }
            });
        })
    }
    render() {
        const { files } = this.state, { seasons } = this.props;
        let doneFiles = _.filter(files, f => f.progress === 100);
        let totalbytes = _.reduce(_.map(files, f => f.total || 0), function (sum, n) {
            return sum + n;
        }, 0)
        return <section className="d-flex flex-row">

            <div className=" w-100 pt-4 pr-2 pb-4 pl-4 d-flex flex-column">


                <div className="tile card pt-4 pb-4">
                    <StyledDropzone onDrop={this.onDrop} />
                    <span className="font-8 gray text-center m-4">By submitting your videos, you acknowledge that you agree to Sports Logic / SQWAD Terms of Service </span>
                </div>
                {files.map((file, i) => <div key={i} className="tile card p-2 mt-2 d-flex flex-row">
                    <span>{file.actualFile.name}</span>
                    <span className="ml-auto">{file.progress}%</span>
                </div>)}

            </div>

            {doneFiles.length && <div className=" w-100 pt-4 pr-2 pb-4 pl-2 d-flex flex-column">
                {doneFiles.map((file, i) => <VideoTile video={file} key={i} seasons={seasons} />)}
            </div>}

            <div className=" w-100 pt-4 pr-4 pb-4 pl-2 d-flex flex-column">
                <div className="tile card">
                    <Animated animationIn="bounceIn" animationInDelay={50} className="w-100 h-100 p-2">
                        <div className="d-flex flex-row w-100">
                            <span>Metrics ? Quota on the server ?</span>
                            {files.length > 0 && <span className="ml-auto">{getReadableFileSizeString(totalbytes)}</span>}
                        </div>
                        <div className="d-flex flex-column black">
                            <span>Videos: 4</span>
                            <span>Space available: 1GB out of 4GB</span>
                        </div>
                    </Animated>
                </div>
            </div>

        </section>
    }
}

export default Team;
