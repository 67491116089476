import _ from 'lodash';
import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import FileModal from './partials/file_modal';
import PhotoItem from './partials/photo_item';

class PhotosValidation extends React.Component {
    state = {
        all: true,
        filters: false,
        letter: 'A',
        year: null,
        gender: null,
        search_text: null,
        dates: [],
        dropdownOpen: false,
        date: null,
        other: false
    }

    // Lifecycle
    componentWillMount() {
        const { params, seasonActions } = this.props;
        const { dates } = this.state;
        for (var i = 0; i < 97; i++) {
            dates.push(moment().add((3 + i) * -1, 'years').format('YYYY'));
        }
        this.setState({ dates, letter: params.letter || 'A', year: params.year || null }, this.fetch);
        if (params.idSeason) {
            seasonActions.fetchSeasonValidations(params.idSeason);
        }
    }

    componentWillReceiveProps = nextProps => {
        if ((nextProps.params.letter || 'A') !== this.state.letter) {
            this.setState({ letter: nextProps.params.letter || 'A' }, this.fetch);
        } else if (nextProps.users) {
            this.setState({ bootstrapped: true, softLoading: false });
        }
    }
    componentWillUnmount = () => {
        this.props.leagueActions.clearUsers();
    }
    //

    toggleOtherDates = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

    fetch = () => {
        const { letter } = this.state, { params, seasonActions } = this.props, { idSeason } = params;
        seasonActions && seasonActions.fetchValidationsByLetter(idSeason, letter, 'PHOTOS');
        seasonActions && seasonActions.fetchSummaryByType(idSeason, 'PHOTOS');
    }

    bringFileUp = (file) => this.setState({ file });

    render() {
        const { params, users, leagueActions, validations_summary = {}, spread, season } = this.props,
            { totals } = validations_summary,
            { idSeason } = params,
            { all, filters, letter, gender, search_text } = this.state;

        const filtered_users =
            _.chain(users)
                .filter(u => all ? true : u.IsImageEmpty)
                .filter(u => letter === 'All' ? true : u.NameLast.substring(0, 1).toUpperCase() === letter)
                .filter(u => gender ? u.IdGender === gender : true)
                .filter(u => search_text ? `${u.NameFirst.toLowerCase()} ${u.NameLast.toLowerCase()}`.indexOf(search_text.toLowerCase()) !== -1 : true)
                .sortBy('NameLast').value();

        const pristine_users = _.filter(filtered_users, u => !_.find(u.replacements, r => r.IdSeason === parseInt(idSeason, 10))),
            dirty_users = _.filter(filtered_users, u => _.find(u.replacements, r => r.IdSeason === parseInt(idSeason, 10)))

        return <section>
            <Animated animateOnMount={false} isVisible={totals ? true : false} animationIn="fadeInRight" animationOut="fadeOutRight" className="d-flex flex-column p-2">

                {totals && <div className="d-flex flex-row">
                    <button onClick={() => this.setState({ filters: !this.state.filters })} className={`btn btn-${filters ? 'default bordered' : 'link'} ml-auto`}><i className="fas fa-filter" /><span className="ml-2">Advanced Filters</span></button>
                    <input placeholder="Search" style={{ width: 300 }} className="form-control align-self-center" ref={i => this.txtSearch = i} onChange={() => this.setState({ search_text: this.txtSearch.value })} />
                    <button onClick={() => this.setState({ all: false })} className={`font-12 ${all ? 'black' : 'blue'} pointer btn btn-link align-self-center ml-3`} style={{ width: 'auto' }}>No Image ({_.filter(filtered_users, u => u.IsImageEmpty).length} / {totals.UsersRegistered_Total})</button>
                    <span className="mx-3 align-self-center">|</span>
                    <button onClick={() => this.setState({ all: true })} className={`font-12 ${all ? 'blue' : 'black'} pointer btn btn-link align-self-center`} style={{ width: 'auto' }}>All ({(users || []).length} / {totals.UsersRegistered_Total})</button>
                </div>}

                <div className="d-flex flex-row justify-content-center mt-2 mx-2">
                {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map((l, i) =>
                        <Link
                            title={`${(_.find(spread || [], s => s.Letter === l) || { TotalUsersWithoutValidation: 0, TotalUsers: 0 })[all ? 'TotalUsers' : 'TotalUsersWithoutValidation']}`}
                            to={`/season/${idSeason}/validations/photos/${l}`}
                            key={i}
                            className={`btn mx-1 btn-sm btn-${l === letter ? 'default bg-blue-dark white' : `default ${(_.find(spread || [], s => s.Letter === l) || { TotalUsersWithoutValidation: 0, TotalUsers: 0 })[all ? 'TotalUsers' : 'TotalUsersWithoutValidation'] === 0 ? 'opaque' : ''}`} w-100`}
                            style={{ border: `solid ${(_.find(spread || [], s => s.Letter === l) || {}).TotalUsersWithoutValidationWithInstruments ? '2px green' : '1px black'}` }}
                        >{l}</Link>
                    )}
                </div>

                {filters && <section>
                    <div className="d-flex flex-row justify-content-center mt-2 mx-2">
                        <button onClick={() => this.setState({ gender: 1 })} className={`btn mx-1 btn-sm btn-${gender === 1 ? 'default bg-blue-dark white' : 'default bordered'} w-100`}><i className="fas fa-male mr-2" />Male</button>
                        <button onClick={() => this.setState({ gender: 2 })} className={`btn mx-1 btn-sm btn-${gender === 2 ? 'default bg-blue-dark white' : 'default bordered'} w-100`}><i className="fas fa-female mr-2" />Female</button>
                        <button onClick={() => this.setState({ gender: null })} className={`btn mx-1 btn-sm btn-${!gender ? 'default bg-blue-dark white' : 'default bordered'} w-100`}>All</button>
                    </div>
                </section>
                }
                <div className="py-4">
                    {dirty_users.length > 0 && <section>
                        <h3>Pending Users</h3>
                        <div className="clearfix">
                            {dirty_users.map((user) => <PhotoItem idLeague={season.IdLeague} validations={this.props.validations} leagueActions={leagueActions} key={`${user.IdUser}-PHOTO`} user={user}
                                idSeason={idSeason} />)}
                        </div>
                    </section>}
                    {pristine_users.length > 0 && <section>
                        <h3>Valid Users</h3>
                        <div className="clearfix">
                            {pristine_users.map((user) => <PhotoItem idLeague={season.IdLeague} validations={this.props.validations} leagueActions={leagueActions} key={`${user.IdUser}-PHOTO`} user={user}
                                idSeason={idSeason} />)}
                        </div>
                    </section>}

                </div>
            </Animated>

            <FileModal isOpen={this.state.file ? true : false} file={this.state.file} toggle={() => this.setState({ file: null })} {...this.props} />
        </section>
    }
}

export default PhotosValidation;
