import React from 'react';
import Loader from '../../../../common/Loader';
import request from 'superagent';
import config from '../../../../../config';

class Field extends React.Component {
    state = {
        editMode: false
    }
    componentWillMount() {
        const { field } = this.props;
        this.setState({ field });
    }
    componentDidUpdate = (prevProps, prevState) => {
        const { editMode } = this.state;
        if (editMode) {
            this.txtFlightName.focus();
            this.txtFlightName.select();
        }
    }

    toggleEditMode = () => this.setState({ editMode: !this.state.editMode });
    headerButtonAction = () => {
        // TODO: call api to save changes        
        const { field, editMode } = this.state, { fnRemove, index } = this.props;
        if (editMode) {
            field.ImageLabel = this.txtFlightName.value;
            this.setState({ editMode: !this.state.editMode, field });
            // CALL API
            request.patch(`${config.apiEndpoint}/api/v4/seasons/field_layout/${field.Id}`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({ ImageLabel: this.txtFlightName.value })
                .then((data) => {

                });
        } else {
            fnRemove && fnRemove(index);
        }
    }
    render() {
        const { field, editMode, microTransact } = this.state, { fnRemove, index } = this.props;
        return (
            <figure key={field.Id || field.temporalId || index} className={`location-square float-left card d-flex flex-column`}
                style={{ height: 100, }}>
                <div style={{ height: 45 }} className={`d-flex flex-row bg-Competitive ${!field.Id ? 'debug' : null}`}>
                    <div className="d-flex flex-column hundred-percent">
                        {!editMode && (
                            <button type="button" className="btn btn-link text-left d-flex flex-row" onClick={this.toggleEditMode}>
                                <span>{field.ImageLabel}</span>
                                {!microTransact && <i className={`fas fa-pencil-alt align-self-center ml-auto font-12`} />}
                            </button>
                        )}
                        {editMode &&
                            <input type="text" defaultValue={field.ImageLabel} ref={(input) => {
                                this.txtFlightName = input;
                            }} className="form-control align-self-center control-sm" placeholder="Name of the flight"
                            />}
                    </div>
                    <div className="ml-auto">
                        {!microTransact &&
                            <button type={editMode ? `submit` : `button`} className={`btn-${editMode ? 'success' : 'link'} align-self-center btn ml-auto `}
                                onClick={this.headerButtonAction}>
                                <i className={`font-12 fa fa-${editMode ? "check" : "times"}`} />
                            </button>}
                        {microTransact &&
                            <button className="btn-link align-self-center btn ml-auto ">
                                <i className="font-12 fa fa-spin fa-2x fa-hourglass-o" />
                            </button>}
                    </div>
                </div>
                <div className="h-100" style={{
                    background: `url("${field.ImageFile}") no-repeat center center`, backgroundSize: 'contain'
                }}></div>
                {false && field.Id && <button onClick={() => fnRemove(index)} className="btn btn-danger align-self-end"><i className="fas fa-times" /></button>}
                {!field.Id && <Loader />}
            </figure>
        );
    }
}

export default Field;