
import _ from 'lodash';

function reducer(state = [], action) {
  let index = 0;
  switch (action.type) {
    case 'TRYOUTS/SET':
      return action.tryouts;
    case 'TRYOUTS/ACCEPT':
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
    case 'TRYOUTS/RETURN':
      return [...state, _.extend(action.item, { RoleStatus: 'Tryout' })];
    case 'TRYOUTS/CLEAN':
      return null;
    case 'ADJUSTMENTS/TRYOUTS/SET':
      return action.tryouts;
    case 'TRYOUT/UPDATE':
      index = _.findIndex(state, program => { return program.Id === action.program.Id });
      return [...state.slice(0, index), action.program, ...state.slice(index + 1)];
    case 'DIVISION/REMOVE_TRYOUT':
      action.program.divisions = _.reject(action.program.divisions, (d) => { return d.IdDivision === action.division.IdDivision });
      // Update the store
      index = _.findIndex(state, program => { return program.Id === action.program.Id });
      return [...state.slice(0, index), action.program, ...state.slice(index + 1)];
    default:
      return state;
  }
}

export default reducer;